import { _, helpers } from '_core';

import { DraftParams } from './types';

export const validate = (params: DraftParams) => {
  const rhErrors = helpers.validations.validSelectedReturnHorizons(params?.return_horizons);
  if (!_.isEmpty(rhErrors)) return rhErrors;
  if (_.isEmpty(params.analysis_time_series_ids)) return ['You need to add at least one time series.'];
  if (params.analysis_time_series_ids.length > 3) return ['You can only add a max of three time series.'];
  return [];
};
