import { _, hooks, mui, React, ts, ui } from '_core';

import { DraftParams } from './types';
import Variables from './variables-field';
import { getBucketDefaultValue } from '../../common/utils/attribution-utils';

type FormProps = {
  params: DraftParams;
  setParams: (_p: DraftParams) => void;
  hasBenchmark: boolean;
  contextRiskModel?: number;
  strategyType?: 'ALPHA_MODEL' | 'BASKET' | 'PORTFOLIO_ANALYSIS' | '';
};

const Form: React.FC<FormProps> = ({
  params,
  setParams,
  hasBenchmark,
  contextRiskModel,
  strategyType,
}): React.ReactElement => {
  const uiStyles = hooks.useUiStyles();

  const BUCKET_TYPES_ENUM = ts.enums.BUCKET_TYPES_ENUM;

  const [buckets, setBuckets] = React.useState(
    // Check if params.buckets is an array for backward compatibility.
    (Array.isArray(params?.buckets) ? params?.buckets[0] : params?.buckets) ||
      (strategyType != 'PORTFOLIO_ANALYSIS'
        ? { type: ts.enums.BUCKET_TYPES_ENUM.QUANTILES, value: 5 }
        : ({
            type: ts.enums.BUCKET_TYPES_ENUM.INDUSTRY,
            value: 'rbics_l1_name',
          } as ts.types.widgets.common.BucketValue))
  );

  const [variables, setVariables] = React.useState(
    // Check if params.variables is an array for backward compatibility.
    Array.isArray(params?.variables) ? params?.variables[0] : params?.variables
  );

  const [includeBenchmark, setIncludeBenchmark] = React.useState(
    _.isNil(params.include_benchmark) ? hasBenchmark || false : params.include_benchmark
  );
  const [benchmarkNeutral, setBenchmarkNeutral] = React.useState(
    _.isNil(params.benchmark_neutral) ? hasBenchmark || false : params.benchmark_neutral
  );

  const [intercept, setIntercept] = React.useState(params.intercept || false);

  const [removeResiduals, setRemoveResiduals] = React.useState(params.without_residuals || false);

  const bucketOptions = [
    {
      value: BUCKET_TYPES_ENUM.QUANTILES,
      title: 'Quantiles',
    },
    {
      value: BUCKET_TYPES_ENUM.INDUSTRY,
      title: 'Industry',
    },
    {
      value: BUCKET_TYPES_ENUM.RISK_MODEL,
      title: 'Risk Model',
    },
    {
      value: BUCKET_TYPES_ENUM.ASSET_MASTER,
      title: 'Asset Master',
    },
  ];

  const filteredBucketOptions =
    strategyType === 'PORTFOLIO_ANALYSIS'
      ? bucketOptions.filter((option) => option.value !== BUCKET_TYPES_ENUM.QUANTILES)
      : bucketOptions;

  const assetsOptions = ['quotation_country', 'issuer_country', 'currency'];

  const industryOptions = ['rbics_l1_name', 'rbics_l2_name', 'rbics_l3_name', 'rbics_l4_name'];

  React.useEffect(() => {
    const data = {
      buckets,
      variables,
      intercept,
      include_benchmark: includeBenchmark,
      benchmark_neutral: benchmarkNeutral,
      without_residuals: removeResiduals,
    };

    setParams({ ...params, ...data });
  }, [buckets, variables, intercept, includeBenchmark, benchmarkNeutral, removeResiduals]);

  const handleBucketsChange = (val: ts.types.widgets.common.BucketValue) => {
    setBuckets(val);
  };

  return (
    <mui.core.Box style={{ padding: '8px' }} sx={uiStyles.widgetEditMode}>
      <mui.core.Box mt={4}></mui.core.Box>
      <mui.core.Box mt={4}>
        <mui.core.FormControlLabel
          control={
            <mui.core.Switch
              size="small"
              checked={benchmarkNeutral}
              onChange={() => setBenchmarkNeutral(!benchmarkNeutral)}
            />
          }
          label="Benchmark Relative"
        />
      </mui.core.Box>

      <mui.core.Box mt={4}>
        <mui.core.Typography variant="body2" fontWeight="fontWeightBold">
          Bucket Analysis
        </mui.core.Typography>
      </mui.core.Box>
      <mui.core.Box mt={4}>
        <ui.Buckets
          value={buckets}
          setValue={handleBucketsChange}
          options={filteredBucketOptions}
          assetMasterOptions={assetsOptions}
          industryOptions={industryOptions}
          getBucketDefaultValue={(value: ts.enums.BUCKET_TYPES_ENUM) => getBucketDefaultValue(value, contextRiskModel)}
        />
      </mui.core.Box>

      <mui.core.Box mt={5}>
        <mui.core.Typography variant="body2" fontWeight="fontWeightBold">
          Linear Decomposition
        </mui.core.Typography>
      </mui.core.Box>
      <mui.core.Box mt={2}>
        <Variables variables={variables} setVariables={setVariables} />
      </mui.core.Box>
      {!_.isEmpty(variables) && (
        <mui.core.FormControlLabel
          control={
            <mui.core.Switch
              size="small"
              checked={removeResiduals}
              onChange={() => setRemoveResiduals(!removeResiduals)}
            />
          }
          label="Exclude Residuals"
        />
      )}
      <mui.core.Box mt={2}>
        <mui.core.FormControlLabel
          control={<mui.core.Switch size="small" checked={intercept} onChange={() => setIntercept(!intercept)} />}
          label="Include Intercept"
        />
      </mui.core.Box>
      <mui.core.Box mt={2}>
        <mui.core.FormControlLabel
          control={
            <mui.core.Switch
              size="small"
              checked={includeBenchmark}
              onChange={() => setIncludeBenchmark(!includeBenchmark)}
            />
          }
          label="Include Benchmark"
        />
      </mui.core.Box>
    </mui.core.Box>
  );
};

Form.defaultProps = {
  hasBenchmark: false,
};

export default Form;
