import { ts } from '_core';

import { DraftParams, INTERACTION_PARAMS } from './types';

export default [
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.BASKET_DRAWDOWN,
    title: 'Drawdown',
    helpLabel: 'bjge3ul7',
    defaultParams: { isBasket: true } as DraftParams,
    interactionParams: Object.values(INTERACTION_PARAMS),
    form: false,
    neededFiles: ['backtest_drawdown'],
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.BASKET],
  },
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.BACKTEST_DRAWDOWN,
    title: 'Drawdown',
    helpLabel: 'fgpxf664',
    defaultParams: { isBasket: false } as DraftParams,
    interactionParams: Object.values(INTERACTION_PARAMS),
    form: false,
    neededFiles: ['backtest_drawdown'],
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.BACKTEST],
  },
] satisfies ts.types.analysis.WidgetConfig[];
