import React from 'react';

import { mui } from '../libs';

type EditableFieldProps = {
  children: React.ReactNode;
  value: string;
  disabled: boolean;

  showEditIcon?: boolean;
  setValue?: (_s: string) => Promise<void>;
  resetValue?: () => Promise<void>;
};

/**
 * EditableField component that displays a field that can be edited.
 * 
 * @param children - The child components to be rendered within the EditableField.
 * @param value - The value of the field.
 * @param setValue - The function to set the value of the field. 
 * @param resetValue - The function to reset the value of the field.
 * @param showEditIcon - Whether to show the edit icon.
 * @param disabled - Whether the field is disabled.
 * 
 * @returns A React component that displays a field that can be edited.
 */
const EditableField: React.FC<EditableFieldProps> = ({
  children,
  value,
  setValue,
  resetValue,
  showEditIcon,
  disabled,
}): React.ReactElement => {
  const [editing, setEditing] = React.useState(false);
  const [tempValue, setTempValue] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    setTempValue(value);
  }, [value]);

  const onEdit = (e: any) => {
    e.stopPropagation();

    setTempValue(value);
    setEditing(true);
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setTempValue(value);
  };

  const handleSave = async () => {
    setLoading(true);
    await setValue(tempValue);
    setEditing(false);
    setLoading(false);
  };

  const handleReset = async (e: any) => {
    e.stopPropagation();
    setLoading(true);
    await resetValue();
    setEditing(false);
    setLoading(false);
  };

  const onKeyUp = (e: any) => {
    if (e.key == 'Escape') setEditing(false);
    if (e.key === 'Enter' || e.which == 9) handleSave();
  };

  const editableDiv = (
    <mui.core.Box display="flex">
      <mui.core.Tooltip title="Edit title" arrow>
        <mui.core.Box
          sx={{
            display: 'flex',
            minWidth: 0,
            alignItems: 'center',
            padding: '0.25rem',
            border: '1px solid transparent',
            borderRadius: '4px',
            '& h5': {
              fontSize: '1rem',
            },
            '&:hover': {
              cursor: 'pointer',
              border: '1px dashed #ccc',
            },
          }}
          role="button"
          onClick={onEdit}
        >
          {children}
        </mui.core.Box>
      </mui.core.Tooltip>
      {showEditIcon && (
        <mui.core.IconButton onClick={onEdit} size="small">
          <mui.icons.Edit style={{ fontSize: 17 }} />
        </mui.core.IconButton>
      )}
    </mui.core.Box>
  );

  const disabledDiv = (
    <mui.core.Box display="flex">
      <mui.core.Box
        sx={{
          display: 'flex',
          minWidth: 0,
          alignItems: 'center',
          padding: '0.25rem 0.5rem',
          border: '1px solid transparent',
          borderRadius: '4px',
          '& h5': {
            fontSize: '1rem',
          },
        }}
      >
        {children}
      </mui.core.Box>
    </mui.core.Box>
  );

  return (
    <>
      {editing ? (
        <mui.core.ClickAwayListener onClickAway={() => setEditing(false)}>
          <mui.core.Stack gap={2} flexDirection="row">
            <mui.core.TextField
              autoFocus
              onChange={onChange}
              onKeyDown={onKeyUp}
              value={tempValue}
              variant="outlined"
              sx={{
                fontSize: '0.8rem',
                minWidth: '200px',
                '& input': {
                  fontSize: '0.75rem !important',
                  fontWeight: '500',
                },
              }}
              size="small"
            />
            <mui.core.Button
              onClick={(e: any) => {
                e.stopPropagation();
                handleSave();
              }}
              variant="contained"
              color="primary"
              disabled={!tempValue || loading}
              size="small"
            >
              Save
            </mui.core.Button>
            {resetValue && (
              <mui.core.Button onClick={handleReset} variant="outlined" disabled={loading} color="primary" size="small">
                Restore to default
              </mui.core.Button>
            )}
          </mui.core.Stack>
        </mui.core.ClickAwayListener>
      ) : disabled ? (
        disabledDiv
      ) : (
        editableDiv
      )}
    </>
  );
};

EditableField.defaultProps = {
  showEditIcon: true,
  resetValue: null,
  disabled: false,
};

export default EditableField;
