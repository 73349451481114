import React from 'react';

import ContextSingleton from '../__singletons__/context-singleton';
import { _, Link, mui, useDispatch, useSelector } from '../libs';
import { BaseStore } from '../types';

/**
 * Component for entering a passcode to continue
 *
 * @requires Redux Store:
 * - state.auth.invalidPasscode: Indicates if the entered passcode is invalid
 *
 * @requires Redux Store Action:
 * - auth.setInvalidPasscode: Action to set the invalid passcode state
 *
 * @returns Rendered component for entering a passcode to continue
 */

const AuthDialogWithoutRedux = (): React.ReactElement => {
  const dispatch = useDispatch();
  const actions = ContextSingleton.getInstance().actions;

  const invalidPasscode = useSelector((state: BaseStore) => state.auth.invalidPasscode);
  const setInvalidPasscode = (i: boolean) => dispatch(actions.auth.setInvalidPasscode(i));

  const [passcode, setPasscode] = React.useState('');
  const [passcodeSet, setPasscodeSet] = React.useState<boolean>();

  React.useEffect(() => {
    setPasscodeSet(!invalidPasscode);
  }, [invalidPasscode]);

  const handleSubmitPasscode = () => {
    setInvalidPasscode(false);

    (_ as any).cookie('_internal', passcode, {
      // Expire in Max time
      expires: new Date(new Date().setFullYear(new Date().getFullYear() + 10)),
      domain: window.location.hostname.replace(/^[^.]+\./g, ''),
    });
    setPasscode('');
    setPasscodeSet(true);
  };

  return (
    <mui.core.Dialog open={!passcodeSet}>
      <mui.core.DialogTitle>Enter the passcode to continue</mui.core.DialogTitle>
      <mui.core.DialogContent>
        <mui.core.Box mb={5}>
          <mui.core.Typography variant="body2">
            Please enter the secret registration passcode for this enviroment
          </mui.core.Typography>
        </mui.core.Box>
        <mui.core.TextField
          autoFocus
          variant="outlined"
          id="name"
          label="Enter passcode"
          type="text"
          fullWidth
          size="small"
          value={passcode}
          onChange={(e) => {
            setPasscode(e.target.value);
          }}
        />
      </mui.core.DialogContent>
      <mui.core.DialogActions>
        <mui.core.Button
          variant="contained"
          color="primary"
          onClick={handleSubmitPasscode}
          style={{ margin: '0rem 1rem 1rem' }}
          fullWidth
        >
          Submit
        </mui.core.Button>
      </mui.core.DialogActions>
    </mui.core.Dialog>
  );
};

const AuthDialog = AuthDialogWithoutRedux;

type NonAuthLayoutProps = {
  children: React.ReactNode;
};

const NonAuthLayout: React.FC<NonAuthLayoutProps> = ({ children }): React.ReactElement => {
  return (
    <>
      <AuthDialog />
      <mui.core.CssBaseline />
      <mui.core.Box>{children}</mui.core.Box>
    </>
  );
};

export const Copyright = () => (
  <mui.core.Typography variant="body2" color="textSecondary" align="center">
    {'Copyright © '}
    <Link to="/" style={{ color: '#4574BA' }}>
      Finsera
    </Link>
    {` ${new Date().getFullYear()} `}
  </mui.core.Typography>
);

export default NonAuthLayout;
