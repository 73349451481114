import { mui, React, ts } from '_core';

import { getConstraintType } from '../../optimizer-utils/constraint-type';
import Bounds from '../bounds';
import SharedConstraintFields from '../shared-fields';
import Target from '../target';

type NetLeverageConstraintProps = {
  params: ts.types.optimizer.ConstraintDraft;
  setParams: (_params: ts.types.optimizer.ConstraintDraft) => void;
  readOnly: boolean;
  validationDef: ts.types.common.ValidationErrors;
};

const NetLeverageConstraint: React.FC<NetLeverageConstraintProps> = ({
  params,
  setParams,
  readOnly,
  validationDef,
}): React.ReactElement => {
  const [showMore, setShowMore] = React.useState(false);

  const form = () => {
    if (getConstraintType(params) == 'BOUNDS') {
      if (showMore) {
        return (
          <Bounds params={params} setParams={setParams} readOnly={readOnly} validationDef={validationDef}>
            <SharedConstraintFields
              params={params}
              setParams={setParams}
              readOnly={readOnly}
              validationDef={validationDef}
            />
          </Bounds>
        );
      }

      return <Bounds params={params} setParams={setParams} readOnly={readOnly} validationDef={validationDef} />;
    }

    if (showMore) {
      return (
        <Target params={params} setParams={setParams} readOnly={readOnly}>
          <SharedConstraintFields
            params={params}
            setParams={setParams}
            readOnly={readOnly}
            validationDef={validationDef}
          />
        </Target>
      );
    }

    return <Target params={params} setParams={setParams} readOnly={readOnly} />;
  };

  return (
    <>
      {/* {!readOnly && (
        <ui.Button
          fullWidth
          sx={uiStyles.fullCardButton}
          color="primary"
          size="small"
          disabled={readOnly}
          onClick={() => setParams({
            type: params.type == 'BOUNDS' ? 'TARGET' : 'BOUNDS',
            target_penalty: params.type == 'BOUNDS' ? 1.0 : undefined,
          })}
        >
          { params.type == 'BOUNDS' ? 'Change to target mode' : 'Change to bounds mode' }
        </ui.Button>
      )} */}
      <mui.core.Box p={2}>
        {form()}
        <mui.core.Button
          onClick={() => {
            setShowMore(!showMore);
          }}
          color="primary"
          style={{ marginTop: '0.5rem' }}
        >
          {showMore ? (
            <>
              <mui.icons.Remove style={{ fontSize: '14px', marginRight: '0.5rem' }} />
              Show Less
            </>
          ) : (
            <>
              <mui.icons.Add style={{ fontSize: '14px', marginRight: '0.5rem' }} />
              Show More
            </>
          )}
        </mui.core.Button>
      </mui.core.Box>
    </>
  );
};

export default NetLeverageConstraint;
