import { ts } from '_core';

import { validate } from './utils';

export default [
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.METRIC_SIGNAL_RETURNS,
    title: 'Signal Returns',
    helpLabel: '81h6v0pa',
    form: false,
    neededFiles: ['sp_returns'],
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.SIGNAL_ADHOC],

    defaultParams: {
      return_horizons: [
        {
          signature: '1 Period Return',
          periods: {
            amount: 1,
            frequency: '$frequency',
          },
        },
      ],
    },
    validate,
    getAdhocData: (pathFile, params, _context) => {
      const selectedReturnHorizon = params.return_horizons?.[0] as ts.types.returnHorizon.ReturnHorizon;

      return {
        name: 'Signal Returns',
        file_name: pathFile,
        description: 'This file contains the returns of the signal/factor.',
        columns: [
          {
            key: 'period_start',
            name: 'start',
            description: 'Start date of the period.',
            type: 'date',
          },
          { key: 'period_end', name: 'end', description: 'End date of the period.', type: 'date' },
          {
            key: selectedReturnHorizon.signature,
            name: 'returns',
            description: 'Returns of the signal.',
            type: 'float',
          },
        ],
      };
    },
  },
] satisfies ts.types.analysis.WidgetConfig[];
