import expandBacktest from './expand-backtest';
import expandBasket from './expand-basket';
import expandDataSource from './expand-data-source';
import expandGeneralAnalysis from './expand-general-analysis';
import expandPipeline from './expand-pipeline';
import expandPortfolio from './expand-portfolio';
import expandSignal from './expand-signal';
import expandSignalSet from './expand-signal-set';
import expandStrategy from './expand-strategy';
import expandTimeSeries from './expand-time-series';
import expandUniverse from './expand-universe';
import { GeneralAnalysisExpanded } from '../../../types/analysis';
import { BacktestExpanded } from '../../../types/backtest';
import { BaseStore } from '../../../types/base-store';
import { BasketExpanded } from '../../../types/basket';
import { ResourceExpanded } from '../../../types/common';
import { DataSourceExpanded } from '../../../types/data-source';
import { PipelineExpanded } from '../../../types/pipeline';
import { PortfolioExpanded } from '../../../types/portfolio';
import { SignalExpanded } from '../../../types/signal';
import { SignalSetExpanded } from '../../../types/signal-set';
import { StrategyExpanded } from '../../../types/strategy';
import { TimeSeriesExpanded } from '../../../types/time-series';
import { UniverseExpanded } from '../../../types/universe';

export type ReturnType =
  | ResourceExpanded
  | BacktestExpanded
  | SignalExpanded
  | TimeSeriesExpanded
  | StrategyExpanded
  | SignalSetExpanded
  | UniverseExpanded
  | PipelineExpanded
  | DataSourceExpanded
  | GeneralAnalysisExpanded
  | BasketExpanded
  | PortfolioExpanded;

const expandWords = (resource: any, resources: BaseStore['resources']): ReturnType => {
  if (resource.resourceType == 'backtest') return expandBacktest(resource, resources);
  if (resource.resourceType == 'signal') return expandSignal(resource, resources);
  if (resource.resourceType == 'signal_set') return expandSignalSet(resource, resources);
  if (resource.resourceType == 'alpha_model') return expandSignal(resource, resources);
  if (resource.resourceType == 'basket') return expandBasket(resource);
  if (resource.resourceType == 'universe') return expandUniverse(resource, resources);
  if (resource.resourceType == 'time_series') return expandTimeSeries(resource, resources);
  if (resource.resourceType == 'strategy') return expandStrategy(resource, resources);
  if (resource.resourceType == 'pipeline') return expandPipeline(resource);
  if (resource.resourceType == 'data_source') return expandDataSource(resource);
  if (resource.resourceType == 'general_analysis') return expandGeneralAnalysis(resource);
  if (resource.resourceType == 'portfolio') return expandPortfolio(resource);
  return {
    id: resource.id,
    name: resource.name,
    handle: resource.handle,
    resourceType: resource.resourceType,
    definition: resource.definition,
    created_by_id: resource.created_by_id,
    label_ids: resource.label_ids,
    is_deprecated: resource.is_deprecated || false,
    is_valid: resource.is_valid || true,
    is_published: resource.is_published || false,
    revoked: resource.revoked || false,
    created_at: resource.created_at,
    organization_id: resource.organization_id,
    has_description: resource.has_description,
    short_description: resource.short_description,
    source_org_id: resource.source_org_id,
    shared: resource.shared,
  } as ResourceExpanded;
};

export default expandWords;
