
import { Close, ExpandLess, ExpandMore } from '@mui/icons-material';
import { Box, Theme, Typography, useTheme } from '@mui/material';

import HelpContent from './help-content';
import * as types from '../types';

/**
 * Collapsable table component with expandable/collapsible sections
 *
 * @param title - Header title text or element
 * @param expanded - Whether the table is expanded
 * @param onChange - Callback for expansion state change
 * @param onClose - Callback for close action
 * @param children - Table content to be collapsible
 * @param headerContent - Custom header content
 * @param closedHeader - Content to show when collapsed
 * @param helpContent - Help content configuration
 * @param color - Color variant for header background
 * 
 * @returns A collapsible table component with header and expandable content
 */

type CollapsableTableProps = {
  title: string | React.ReactElement;
  expanded?: boolean;
  onChange?: () => void;
  onClose?: () => void;
  children?: React.ReactNode;
  icon?: React.ReactNode;
  headerContent?: React.ReactNode;
  closedHeader?: React.ReactNode;
  helpContent?: types.helpItem.HelpItemContent;
  color?: string;
};
const CollapsableTable: React.FC<CollapsableTableProps> = ({
  expanded,
  onChange,
  title,
  onClose,
  children,
  headerContent,
  closedHeader,
  helpContent,
  color,
}): React.ReactElement => {
  const theme = useTheme() as Theme;

  return (
    <Box
      sx={{
        position: 'relative',
        border: `1px solid ${(theme.palette as any).grayLight.main}`,
        marginBottom: '-1px',
        display: 'flex',
      }}
    >
      <Box
        sx={{
          minWidth: '23vw',
          width: '23vw',
          alignItems: 'flex-start',
          display: 'flex',
          minHeight: '0',
          padding: '8px',
          cursor: 'pointer',
          borderRight: `1px solid ${(theme.palette as any).grayLight.main}`,
          background: color == 'error' ? (theme.palette as any).dangerLight.light : '#fff',
        }}
      >
        {headerContent}
        <Box flex={1} onClick={onChange}>
          {helpContent ? (
            <HelpContent helpContent={helpContent}>
              <Typography color={color} variant="body2" fontWeight="500">
                {title}
              </Typography>
            </HelpContent>
          ) : (
            <Typography color={color} variant="body2" fontWeight="500">
              {title}
            </Typography>
          )}
        </Box>

        <Box flex="1" display="flex" justifyContent="flex-end">
          {onClose && (
            <Close
              onClick={(event) => {
                event.stopPropagation();
                onClose();
              }}
              sx={{ fontSize: '1rem', mt: '2px', mr: 2, color: (theme.palette as any).text.secondary }}
            />
          )}
          <Box onClick={onChange} sx={{ textAlign: 'right' }}>
            {expanded ? (
              <ExpandMore sx={{ color: (theme.palette as any).text.secondary }} />
            ) : (
              <ExpandLess sx={{ color: (theme.palette as any).text.secondary }} />
            )}
          </Box>
        </Box>
      </Box>
      <Box sx={{ flex: 1 }}>
        {expanded ? (
          children
        ) : (
          <Box p={1} display="flex" sx={{ height: '100%' }}>
            {closedHeader}
          </Box>
        )}
      </Box>
    </Box>
  );
};

CollapsableTable.defaultProps = {
  onChange: () => undefined,
  expanded: false,
  children: null,
};

export default CollapsableTable;
