import { ts } from '_core';

import { DraftParams, INTERACTION_PARAMS } from './types';

export default [
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.BASKET_TABULAR_OUTPUT,
    title: 'Tabular Summary and Details',
    defaultParams: { isBasket: true } as DraftParams,
    interactionParams: Object.values(INTERACTION_PARAMS),
    clearInteractionOnRegenerate: ['table_dtypes'],
    form: false,
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.BASKET],
    neededFiles: ['backtest_tabular_data_analysis'],

    disableAdd: true,
    helpLabel: 'zkjeq8y1',
  },
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.BACKTEST_TABULAR_OUTPUT,
    title: 'Tabular Summary and Details',
    defaultParams: { isBasket: false } as DraftParams,
    interactionParams: Object.values(INTERACTION_PARAMS),
    clearInteractionOnRegenerate: ['table_dtypes'],
    form: false,
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.BACKTEST],
    neededFiles: ['backtest_tabular_data_analysis', 'backtest_alpha_exposures'],

    disableAdd: true,
    helpLabel: 'db9tt736',
  },
] satisfies ts.types.analysis.WidgetConfig[];
