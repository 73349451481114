import { hooks, mui, React, ts, ui } from '_core';

import { DraftParams } from './types';

type FormProps = {
  params: DraftParams;
  setParams: (_p: DraftParams) => void;
  context?: ts.types.signal.SignalAnalysisContext;
};

const Form: React.FC<FormProps> = ({ params, setParams, context }) => {
  const [returnHorizon, setReturnHorizon] = React.useState<ts.types.returnHorizon.ReturnHorizon>(params.return_horizon);
  const [buckets, setBuckets] = React.useState(
    params.buckets || ({ type: 'LONG_SHORT' } as ts.types.widgets.common.BucketValue)
  );
  const [selectedHistories, setSelectedHistories] = React.useState(
    params.histories || ([] as ts.enums.HISTORY_ENUM_EXTENDED[])
  );

  const uiStyles = hooks.useUiStyles();

  React.useEffect(() => {
    const data = {
      return_horizon: returnHorizon,
      histories: selectedHistories,
      buckets,
    };

    setParams({
      ...params,
      statistics_type: params.statistics_type,
      weights: params.weights,
      ...data,
    });
  }, [returnHorizon, buckets, selectedHistories]);

  return (
    <mui.core.Box sx={uiStyles.widgetEditMode}>
      <mui.core.Box mt={4}>
        <ui.WidgetReturnHorizon<false>
          value={returnHorizon}
          setValue={(val) => setReturnHorizon(val)}
          context={context}
        />
      </mui.core.Box>
      <mui.core.Box mt={2}>
        <ui.WidgetHistories<true>
          multiple
          value={selectedHistories}
          setValue={setSelectedHistories}
          context={context}
        />
      </mui.core.Box>
      <mui.core.Box mt={4}>
        <ui.Buckets value={buckets} setValue={setBuckets} />
      </mui.core.Box>
    </mui.core.Box>
  );
};

export default Form;
