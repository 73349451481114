import { _, mui, React, ts } from '_core';

type VariablesProps = {
  variables: ts.types.widgets.common.ExplanatoryVariablesValue;
  setVariables: (_v: ts.types.widgets.common.ExplanatoryVariablesValue) => void;
};

const variablesOptions = [
  ts.enums.EXPLANATORY_VARIABLE_TYPES_ENUM.RISK_FACTORS,
  ts.enums.EXPLANATORY_VARIABLE_TYPES_ENUM.ALPHA_SIGNALS,
];

const Variables: React.FC<VariablesProps> = ({ variables, setVariables }): React.ReactElement => {
  const [selectedVariables, setSelectedVariables] = React.useState(variables.type || variablesOptions[0]);

  return (
    <mui.core.TextField
      select
      label="Variables"
      variant="outlined"
      size="small"
      value={selectedVariables}
      onChange={(e) => {
        setVariables({ type: e.target.value as ts.enums.EXPLANATORY_VARIABLE_TYPES_ENUM });
        setSelectedVariables(e.target.value as ts.enums.EXPLANATORY_VARIABLE_TYPES_ENUM);
      }}
      fullWidth
      required
      style={{ margin: '1rem 0' }}
    >
      {variablesOptions.map((opt) => (
        <mui.core.MenuItem key={opt} value={opt}>
          {_.startCase(opt)}
        </mui.core.MenuItem>
      ))}
    </mui.core.TextField>
  );
};

export default Variables;
