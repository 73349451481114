import { hooks, mui, React, ui } from '_core';

import { FixedCostContext } from './fixed-costs-context';
import FixedCostsTable from './fixed-costs-table';

const FixedCosts: React.FC = (): React.ReactElement => {
  const context = React.useContext(FixedCostContext);
  const { alert, setAlert, addNewRow, saveRows, saving, loadingNewCountry, validating, validationDef, rowsChanged } =
    context;

  const uiStyles = hooks.useUiStyles();
  return (
    <mui.core.Box display="flex" height="100%" flexDirection="column">
      <mui.core.Box flex="1" sx={uiStyles.settingsContainer}>
        {alert && (
          <mui.lab.Alert severity={alert.severity} onClose={() => setAlert(null)}>
            {alert.message}
          </mui.lab.Alert>
        )}
        <FixedCostsTable />
      </mui.core.Box>
      <mui.core.Box display="flex">
        <ui.SaveBar
          validationDef={validationDef}
          updateDefinition={saveRows}
          definitionChanged={rowsChanged}
          validating={validating}
          customAction={
            <ui.Button
              loading={validating || saving || loadingNewCountry}
              variant="outlined"
              progressText="Loading"
              color="primary"
              onClick={addNewRow}
              size="small"
            >
              <mui.icons.Add />
              Add Country
            </ui.Button>
          }
        />
      </mui.core.Box>
    </mui.core.Box>
  );
};

export default FixedCosts;
