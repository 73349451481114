import { Link, mui, React, ts, ui, useSelector } from '_core';

import { DashboardOutlined, PlayCircleOutline } from '@mui/icons-material';

import { helpItems } from './config';

type ContentProps = {
  resource?: ts.types.dashboard.HelpItem;
};

const Onboarding = () => {
  const theme = mui.styles.useTheme();
  const [openFeedback, setOpenFeedback] = React.useState(false);
  const currentUser = useSelector((state) => state.auth.currentUser);

  return (
    <mui.core.Card
      sx={{
        height: 'calc(100% - 32px)',
        overflow: 'auto',
        m: 1,
      }}
    >
      <mui.core.Box
        p={3}
        sx={{
          maxWidth: '1000px',
          margin: '0 auto',
        }}
      >
        <mui.core.Box pt={1} pb={3}>
          <mui.core.Typography variant="h5" paragraph>
            Hi {currentUser.name}!
          </mui.core.Typography>
          <mui.core.Typography variant="body1">
            Unsure where to start? Explore{' '}
            <mui.core.Link href="https://help.finsera.com/en/home" target="_blank">
              our docs
            </mui.core.Link>{' '}
            to learn everything about the platform...
          </mui.core.Typography>
        </mui.core.Box>
        <mui.core.Grid container>
          {helpItems.map((r) => (
            <mui.core.Grid item xs={12} lg={6} key={r.key}>
              {r.url ? (
                <mui.core.Link
                  href={r.url}
                  target="_blank"
                  sx={{
                    display: 'flex',
                    outline: 'none',
                    alignItems: 'center',
                    textDecoration: 'none',
                    padding: theme.spacing(2),
                    borderBottom: `1px solid ${theme.palette.background.default}`,
                    transition: 'all ease-in-out 0.15s',
                    maxWidth: 'calc(100vw - 20px)',
                    marginRight: '0.66rem',
                    marginBottom: '0.66rem',
                    borderRadius: '4px',
                    background: theme.palette.grayLight.main,
                    '&:hover': {
                      transform: 'translate(0px, -1px)',
                    },
                  }}
                >
                  <LinkContent resource={r} />
                </mui.core.Link>
              ) : (
                <Link
                  to={r.to}
                  style={{
                    display: 'flex',
                    outline: 'none',
                    alignItems: 'center',
                    textDecoration: 'none',
                    padding: theme.spacing(2),
                    borderBottom: `1px solid ${theme.palette.background.default}`,
                    transition: 'all ease-in-out 0.15s',
                    maxWidth: 'calc(100vw - 20px)',
                    marginRight: '0.66rem',
                    marginBottom: '0.66rem',
                    borderRadius: '4px',
                    background: theme.palette.grayLight.main,
                  }}
                >
                  <LinkContent resource={r} />
                </Link>
              )}
            </mui.core.Grid>
          ))}
        </mui.core.Grid>
        <mui.core.Box textAlign="center" my={4}>
          <mui.core.Typography variant="body2" color="textSecondary">
            Have any questions or feedback?
            <ui.FeedbackDialog
              isOpen={openFeedback}
              handleClose={() => setOpenFeedback(false)}
              currentUser={currentUser}
            />{' '}
            <mui.core.Link onClick={() => setOpenFeedback(true)} href="#">
              Contact us
            </mui.core.Link>
          </mui.core.Typography>
        </mui.core.Box>
      </mui.core.Box>
    </mui.core.Card>
  );
};

const LinkContent: React.FC<ContentProps> = ({ resource }): React.ReactElement => {
  const icon = resource.icon == 'video' ? <PlayCircleOutline /> : <DashboardOutlined />;
  const theme = mui.styles.useTheme() as mui.core.Theme;
  return (
    <>
      <mui.core.Box
        sx={{
          fontWeight: 'inherit',
          flexGrow: 1,
          minWidth: '0',
          width: '65%',
        }}
      >
        <mui.core.Box display="flex">
          {resource.resourceType ? (
            <mui.core.Box
              px={2}
              sx={{
                '& span': {
                  padding: '0',
                  display: 'flex',
                },
                '& svg': {
                  fontSize: '12px',
                },
              }}
            >
              <ui.ResourceIcon resource={resource.resourceType} />
            </mui.core.Box>
          ) : (
            <mui.core.Box
              sx={{
                padding: '4px 8px',
                '& svg': {
                  fontSize: '12px',
                },
              }}
            >
              {icon}
            </mui.core.Box>
          )}
          <mui.core.Box>
            <mui.core.Typography
              variant="body2"
              sx={{
                color: theme.palette.secondary.main,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                minWidth: '0',
                marginBottom: '-8px',
              }}
            >
              {resource.label}
            </mui.core.Typography>
            <mui.core.Typography
              variant="caption"
              sx={{
                color: '#7F8D93',
                textDecoration: 'none',
                // whiteSpace: 'nowrap',
                // overflow: 'hidden',
                // textOverflow: 'ellipsis',
                minWidth: '0',
              }}
            >
              {resource.description}
            </mui.core.Typography>
          </mui.core.Box>
        </mui.core.Box>
      </mui.core.Box>
      <mui.icons.ArrowForward style={{ color: '#D6DCDF', fontSize: '0.9rem' }} />
    </>
  );
};

export default Onboarding;
