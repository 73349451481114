import { api, config, mui, React, ts, ui,useHistory, useSelector } from '_core';

import * as utils from './general-analysis-utils';

type GeneralAnalysisProps = {
  open: boolean;
  setOpen: (_o: boolean) => void;
};

const GeneralAnalysis: React.FC<GeneralAnalysisProps> = ({ open, setOpen }): React.ReactElement => {
  const history = useHistory();
  const generalAnalyses = useSelector(
    (state) => state.resources.general_analyses as ts.types.analysis.GeneralAnalysis[]
  );

  const workspaces = useSelector((state) => state.resources.workspaces as ts.types.workspace.Workspace[]);

  const currentWorkspaceId = useSelector((state) => state.ui.currentWorkspaceId);

  const [errorMessage, setErrorMessage] = React.useState('');
  const [name, setName] = React.useState('');
  const [loadingCreate, setLoadingCreate] = React.useState(false);

  const [template, setTemplate] = React.useState<ts.types.analysis.GeneralAnalysis>();

  const [workspace, setWorkspace] = React.useState(workspaces.find((w) => w.id == currentWorkspaceId) || workspaces[0]);

  const addToWorkspace = async (generalAnalysisId: number) => {
    const updatedData = {} as Record<string, any>;
    updatedData.general_analysis_ids = [generalAnalysisId, ...workspace.general_analysis_ids];
    await api.workspaces.update(workspace.id, updatedData);
  };

  const handleSubmit = async () => {
    setErrorMessage('');

    // Validation
    if (!name) {
      setErrorMessage('All attributes are required.');
      setLoadingCreate(false);
      return;
    }

    setLoadingCreate(true);

    const nullDefinition = {
      widgets: [],
      mode: ts.enums.ANALYSIS_MODE_ENUM.GRID,
    } as ts.types.analysis.AnalysisDefinition;

    // Check if we will create the backtest with a premade definition
    const newGeneralAnalysis = {
      name,
      report_definition: nullDefinition,
      start_date: config.features.start_date,
      end_date: config.features.end_date,
    } as ts.types.analysis.GeneralAnalysis;

    if (template && template.id) {
      newGeneralAnalysis.report_definition = template.report_definition;
      newGeneralAnalysis.start_date = template.start_date;
      newGeneralAnalysis.end_date = template.end_date;
    }

    utils.apiProcedures.createGeneralAnalysis(
      newGeneralAnalysis,
      async (data) => {
        await addToWorkspace(data.id);
        setOpen(false);
        history.push(`/general_analyses/${data.id}`);
      },
      async (err) => {
        setErrorMessage(err);
        setLoadingCreate(false);
      }
    );
  };

  const formContent = () => (
    <>
      <mui.core.Box py={4}>
        <mui.core.TextField
          autoFocus
          id="name"
          label="Report name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          fullWidth
          variant="outlined"
          required
          size="small"
          inputProps={{
            autocomplete: 'off',
          }}
        />
      </mui.core.Box>
      <mui.core.Box pb={4}>
        <ui.ResourceAutocomplete<ts.types.analysis.GeneralAnalysisExpanded>
          enableNone
          value={template?.id}
          setValue={(id) => setTemplate(generalAnalyses.find((u) => u.id == id))}
          type={ts.enums.RESOURCES_TYPES_ENUM.GENERAL_ANALYSIS}
          inputProps={{
            fullWidth: true,
            label: 'Template',
            variant: 'outlined',
            required: true,
            size: 'small',
          }}
        />
      </mui.core.Box>
      <ui.WorkspaceSelector value={workspace} setValue={setWorkspace} />
    </>
  );

  return (
    <mui.core.Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="sm"
      fullWidth
    >
      {errorMessage && (
        <mui.lab.Alert onClose={() => setErrorMessage('')} severity="error">
          {errorMessage}
        </mui.lab.Alert>
      )}
      <ui.DialogTitle closeAction={() => setOpen(false)}>Create Report</ui.DialogTitle>
      <mui.core.DialogContent>{formContent()}</mui.core.DialogContent>
      <mui.core.DialogActions>
        <ui.Button
          variant="contained"
          color="primary"
          loading={loadingCreate}
          onClick={handleSubmit}
          fullWidth
          style={{ margin: '0rem 1rem 1rem' }}
        >
          Save Report
        </ui.Button>
      </mui.core.DialogActions>
    </mui.core.Dialog>
  );
};

export default GeneralAnalysis;
