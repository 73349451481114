import React from 'react';

import { _, mui } from '../../../libs';
import * as types from '../../../types';
import AssetSelector from '../../asset-selector';
import Button from '../../button';
import CenteredLoader from '../../centered-loader';
import FinDataGrid from '../../fin-data-grid';
import { UploadContext } from '../upload-context';
import * as utils from '../utils';

const ConfirmStep = () => {
  const uploadContext = React.useContext(UploadContext);
  const { holdingsData, holdingsColumns, handleSubmitHoldings } = uploadContext;

  const theme = mui.styles.useTheme() as mui.core.Theme;

  const dialogContent = React.useRef<HTMLElement>(null);

  const [deletedRows, setDeletedRows] = React.useState([] as string[]);
  const [updatedRows, setUpdatedRows] = React.useState([] as typeof holdingsData);

  const [loadingConfirm, setLoadingConfirm] = React.useState(false);

  // Handle manual map
  const [mappingRow, setMappingRow] = React.useState<(typeof holdingsData)[0]>(null);
  const [mapping, setMapping] = React.useState(false);

  const orderedHoldingsData = React.useMemo(() => {
    const errors = holdingsData.filter((el) => el.error_message);
    const mapped = holdingsData.filter((el) => !el.error_message);
    return [...errors, ...mapped];
  }, [holdingsData]);

  const localHoldingsData = React.useMemo(() => {
    return orderedHoldingsData
      .filter((el) => !deletedRows.includes(el._row_number))
      .map((r) => {
        const foundRow = updatedRows.find((ur) => ur._row_number == r._row_number);
        if (foundRow) return foundRow;
        return r;
      });
  }, [orderedHoldingsData, deletedRows, updatedRows]);

  const hasErrors = React.useMemo(() => _.some(localHoldingsData, (data) => !!data.error_message), [localHoldingsData]);

  const rowInitialSearch = React.useMemo(() => {
    if (!mappingRow) return undefined;
    const countries = mappingRow.country ? [mappingRow.country] : [];
    if (mappingRow.ticker)
      return {
        searchTerm: mappingRow.ticker,
        identifier: 'ticker' as 'ticker' | 'isin' | 'cusip' | 'fid',
        countries,
      };

    if (mappingRow.isin)
      return {
        searchTerm: mappingRow.isin,
        identifier: 'isin' as 'ticker' | 'isin' | 'cusip' | 'fid',
        countries,
      };

    if (mappingRow.cusip)
      return {
        searchTerm: mappingRow.cusip,
        identifier: 'cusip' as 'ticker' | 'isin' | 'cusip' | 'fid',
        countries,
      };

    return undefined;
  }, [mappingRow]);

  const columns = React.useMemo(() => {
    return (
      holdingsColumns?.map((col) => ({
        field: col.field,
        headerName: col.title,
        flex: 1,
      })) || []
    );
  }, [holdingsColumns]);

  const actionColumn = {
    field: 'actions',
    headerName: '',
    width: 100,
    sortable: false,
    filterable: false,
    renderCell: (params: mui.dataGrid.GridRenderCellParams) => (
      <mui.core.Box sx={{ display: 'flex', gap: 1 }}>
        <mui.core.IconButton
          size="small"
          onClick={() => {
            setMapping(true);
            setMappingRow(params.row);
          }}
          title="Manual Map"
        >
          <mui.icons.MyLocation color="primary" sx={{ fontSize: 16 }} />
        </mui.core.IconButton>
        <mui.core.IconButton
          size="small"
          onClick={() => {
            setDeletedRows((dr) => [...dr, params.row._row_number]);
          }}
          title="Remove"
        >
          <mui.icons.Delete sx={{ color: (theme.palette as any).text.secondary, fontSize: 16 }} />
        </mui.core.IconButton>
      </mui.core.Box>
    ),
  };

  const columnsWithActions = React.useMemo(() => {
    return [actionColumn, ...columns];
  }, [columns]);

  if (loadingConfirm)
    return (
      <mui.core.Box height="450px">
        <CenteredLoader label="Preparing the file." />
      </mui.core.Box>
    );

  const handleSubmit = async () => {
    setLoadingConfirm(true);
    await handleSubmitHoldings(localHoldingsData);
    setLoadingConfirm(false);
  };

  return (
    <>
      <AssetSelector
        key={mappingRow?._row_number}
        value={null}
        setValue={(_val, metadata) => {
          utils.manualMap(mappingRow, metadata as types.assetSelector.AssetSelectorMetaData, setUpdatedRows, () =>
            setMappingRow(null)
          );
        }}
        fields={[
          { title: 'Ticker', key: 'ticker' },
          { title: 'Name', key: 'name' },
          { title: 'Country', key: 'quotation_country' },
        ]}
        externalOpen={!!mapping}
        externalSetOpen={() => setMapping(false)}
        onlyModal
        initialSearch={rowInitialSearch}
      />

      <mui.core.DialogContent sx={{ p: 0 }} ref={dialogContent}>
        <FinDataGrid
          rows={localHoldingsData}
          columns={columnsWithActions}
          autoHeight
          hideFooter
          disableColumnMenu
          disableColumnFilter
          initialState={{
            pagination: {
              paginationModel: { pageSize: 5 },
            },
          }}
          pageSizeOptions={[5]}
          getRowId={(row) => row._row_number || Math.random().toString()}
          getRowClassName={(params) => {
            if (params.row && params.row.error_message) {
              return 'error-row';
            }
            return '';
          }}
          sx={{
            '& .MuiDataGrid-columnHeaders': {
              borderBottom: '1px solid rgb(149, 151, 153)',
            },
            '& .MuiDataGrid-cell': {
              fontSize: '0.7rem',
            },
            '& .error-row': {
              backgroundColor: '#fdeded',
            },
          }}
        />
      </mui.core.DialogContent>

      <mui.core.DialogActions
        sx={{
          justifyContent: 'flex-end',
          marginTop: '0.2rem',
          padding: '0.75rem 1rem',
          borderTop: `1px solid ${(theme.palette as any).gray.main}`,
        }}
      >
        <mui.core.Tooltip title={hasErrors ? 'Please fix all errors before confirming' : ''}>
          <span>
            <Button variant="contained" color="primary" onClick={handleSubmit} disabled={hasErrors}>
              Confirm
            </Button>
          </span>
        </mui.core.Tooltip>
      </mui.core.DialogActions>
    </>
  );
};

export default ConfirmStep;
