/* eslint-disable no-unused-vars */

import { ts } from '_core';

export enum INTERACTION_PARAMS {
  CATEGORY_FACTORS = 'category_factors',
  RISK_FACTORS = 'risk_factors',
  TAB = 'tab',
}

export type InteractionParams = {
  tab: string;
  category_factors: ts.types.riskModel.CategoryFactor[];
};

export type Params = InteractionParams;
export type DraftParams = Partial<Params>;
