import { _, actions, api, hooks, mui, React, ts, ui, useDispatch, useSelector } from '_core';

import * as columns from 'views/preferences/columns-config';
import { PreferencesContext } from 'views/preferences/preferences-context';

import LayoutSetup from './layout-setup';
import columnsSelectConfig from '../select-columns-config';

const emptyPreference = (prefKey: ts.enums.PREFERENCES_KEY_ENUM): Partial<ts.types.userPreference.UserPreference> => {
  return {
    title: 'Default',
    key: prefKey,
    is_latest: true,
    definition: columns.columnDefaults?.[prefKey],
    type: ts.types.userPreference.UserPreferenceTypes.COLUMNS,
  };
};

const SelectColumns = () => {
  const preferencesContext = React.useContext(PreferencesContext);
  const { preferencesSelectedInnerTab, preferencesSelectedId, closePreferences, setAlertError } = preferencesContext;
  const uiStyles = hooks.useUiStyles();
  const dispatch = useDispatch();

  const [loading, setLoading] = React.useState(true);
  const [loadingSave, setLoadingSave] = React.useState(false);

  const [preferences, setPreferences] = React.useState([] as ts.types.userPreference.UserPreference[]);
  const [selectedPreference, setSelectedPreference] = React.useState<ts.types.userPreference.UserPreference>();

  const selectedColumnsConfig = React.useMemo(
    () => columnsSelectConfig[preferencesSelectedInnerTab],
    [preferencesSelectedInnerTab]
  );

  const preferencesSourceWidgetId = useSelector((state) => state.ui.preferencesOpen.source_widget_id ?? null);

  React.useEffect(() => {
    const loadPreferences = async () => {
      try {
        setLoading(true);
        const resp = await api.user_preferences.search({
          query: ['key', '=', selectedColumnsConfig.preferenceKey],
        });

        // If there is no preference, let's create the default
        if (_.isEmpty(resp.data)) {
          const respNew = await api.user_preferences.create(emptyPreference(selectedColumnsConfig.preferenceKey));
          setSelectedPreference(respNew.data);
          setPreferences([respNew.data]);
        } else {
          setSelectedPreference(resp.data.find((p) => p.id == preferencesSelectedId) ?? resp.data[0]);
          setPreferences(resp.data);
        }
      } catch {
        setAlertError('Unable to load preferences.');
      }
      setLoading(false);
    };

    loadPreferences();
  }, [selectedColumnsConfig]);

  const handleSave = async () => {
    try {
      setLoadingSave(true);
      const promises = [];
      preferences.forEach((p) => {
        if (p.is_latest && p.id !== selectedPreference.id)
          promises.push(api.user_preferences.update(p.id, { is_latest: false }));
      });
      promises.push(
        await api.user_preferences.update(selectedPreference.id, {
          ...selectedPreference,
          is_latest: true,
        })
      );

      const resp = await Promise.all(promises);
      const updatedPrefs = resp.map((r) => r.data);
      setPreferences(
        preferences.map((p) => {
          if (updatedPrefs.map((x) => x.id).includes(p.id)) return updatedPrefs.find((x) => x.id == p.id);
          return p;
        })
      );

      const latestPrefs = updatedPrefs.find((x) => x.is_latest);

      dispatch(
        actions.ui.setPreferencesLastUpdated({
          preferenceKey: selectedColumnsConfig.preferenceKey,
          preferenceView: latestPrefs.id,
          source_widget_id: preferencesSourceWidgetId,
        })
      );
      setLoadingSave(false);
    } catch (err) {
      setAlertError('Unable to save preference.');
      setLoadingSave(false);
    }
  };

  const handleSaveAndClose = async () => {
    await handleSave();
    closePreferences();
  };

  const defaultPref = React.useMemo(() => {
    return columns.columnDefaults?.[selectedColumnsConfig.preferenceKey] || {};
  }, [selectedColumnsConfig.preferenceKey]);

  if (loading) return <ui.CenteredLoader />;
  return (
    <>
      <LayoutSetup
        key={selectedColumnsConfig.preferenceKey}
        preferences={preferences}
        setPreferences={setPreferences}
        sheetsConfig={selectedColumnsConfig.sheetsConfig}
        configColumns={selectedColumnsConfig.columns}
        selectedPreference={selectedPreference}
        setSelectedPreference={setSelectedPreference}
        defaultPref={defaultPref}
      />

      <mui.core.Box display="flex" sx={uiStyles.settingsFooter}>
        <mui.core.Box mr={3}>
          <ui.Button
            variant="contained"
            color="primary"
            onClick={handleSave}
            progressText="Save"
            loading={loadingSave}
            disabled={loadingSave}
          >
            Save
          </ui.Button>
        </mui.core.Box>
        <ui.Button
          variant="outlined"
          color="primary"
          onClick={handleSaveAndClose}
          progressText="Save and close"
          loading={loadingSave}
          disabled={loadingSave}
        >
          Save and close
        </ui.Button>
      </mui.core.Box>
    </>
  );
};

SelectColumns.defaultProps = {
  sheetsConfig: [],
};

export default SelectColumns;
