import { hooks, mui, React, ts, ui } from '_core';

import { Params } from './types';

type DraftWidgetParams = Params;

type FormProps = {
  params: DraftWidgetParams;
  setParams: (_p: DraftWidgetParams) => void;
  context: ts.types.signal.SignalAnalysisContext;
};

const Form: React.FC<FormProps> = ({ params, setParams, context }) => {
  const [benchmark, setBenchmark] = React.useState(params.benchmark || null);

  const uiStyles = hooks.useUiStyles();

  React.useEffect(() => {
    const data = {
      ...params,
      benchmark,
    };
    setParams(data);
  }, [benchmark]);

  return (
    <mui.core.Box sx={uiStyles.widgetEditMode}>
      <mui.core.Box mt={4}>
        <mui.core.Typography
          variant="body2"
          fontWeight="fontWeightMedium"
          style={{ marginTop: '0.5rem', marginBottom: '1rem' }}
        >
          Benchmark
        </mui.core.Typography>
        <ui.Benchmark
          value={benchmark || undefined}
          setValue={setBenchmark}
          universeContextPath={'$universe.handle'}
          context={context}
          enableAnnualizedSignals
          universeIdPath="universe_id"
        />
      </mui.core.Box>
    </mui.core.Box>
  );
};

export default Form;
