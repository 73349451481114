/* eslint-disable no-unused-vars */
enum HOLDINGS_MAP_COLS {
  CUSIP = 'cusip',
  ISIN = 'isin',
  TICKER = 'ticker',
  BLOOMBERG_TICKER = 'bloomberg_ticker',
  COUNTRY = 'country',
  WEIGHTS = 'weights',
}

const COLS = HOLDINGS_MAP_COLS;

const validateColumns = (columns: Record<string, string>) => {
  const verifyColumn = (col: string) => columns[col] && columns[col] != 'None';

  if (
    !verifyColumn(COLS.CUSIP) &&
    !verifyColumn(COLS.ISIN) &&
    !verifyColumn(COLS.TICKER) &&
    !verifyColumn(COLS.BLOOMBERG_TICKER) &&
    !verifyColumn(COLS.COUNTRY)
  ) {
    return 'One of CUSIP, ISIN or Ticker + Country is required';
  }

  if (verifyColumn(COLS.TICKER) && !verifyColumn(COLS.COUNTRY)) {
    return 'If Ticker is selected, Country is a required column.';
  }

  if (!verifyColumn(COLS.WEIGHTS)) return 'Weights is a required column.';

  return '';
};

export default validateColumns;
