/* eslint-disable max-len */
import { _, config, ts } from '_core';

export const getBenchmarkTitle = (
  benchmark: Record<string, string | number>,
  title: string,
  universes: ts.StoreState['resources']['universes']
) => {
  if (benchmark?.type == 'INDEX') {
    const etfData = config.etfCodes.find((c) => c.FSYM_ID == benchmark.code);
    return `${title} Relative to ${etfData.name} (${etfData.ticker})`;
  }

  if (benchmark?.type == 'ANNUALIZED_RETURNS_SIGNAL') {
    if (benchmark.source == 'federal_funds_effective_rate.value') {
      return `${title} Relative to Federal Funds Effective Rate`;
    }
  }

  const universeName = universes.find((u) => u.handle == benchmark?.handle)?.name;
  if (benchmark?.weighting == 'EQUAL') return `${title} Relative to Equal Weights over ${universeName}`;
  if (benchmark?.weighting == 'MARKETCAP') return `${title} Relative to Market Capitalization over ${universeName}`;
  return title;
};

export const getBucketsTitle = (
  buckets: ts.types.widgets.common.BucketValue,
  title: string,
  resources: ts.StoreState['resources']
) => {
  // Let's remove the redundancy if needed
  title = title.replace('by Bucket', '');
  title = title.replace('Bucketed ', '');

  if (buckets?.type == 'QUANTILES') return `${title} by Quantiles 1 to ${buckets.value}`;
  if (buckets?.type == 'LONG_SHORT') return `${title} by Long/Short Side`;

  if (buckets?.type == 'SIGNAL') {
    const signal = resources.signals.find((s) => s.id == buckets.signal_id);
    return `${title} by ${signal.name}`;
  }

  if (buckets?.type == 'INDUSTRY') {
    const industryValue = buckets.value as string;
    if (industryValue.includes('rbics_l')) {
      let industry = 'RBICS Category';

      if (industryValue.includes('rbics_l1')) industry = 'Sector';
      if (industryValue.includes('rbics_l2')) industry = 'Sub-Sector';
      if (industryValue.includes('rbics_l3')) industry = 'Industry';
      if (industryValue.includes('rbics_l4')) industry = 'Sub-Industry';
      return `${title} by ${industry}`;
    }

    const industry = industryValue.split('_');
    return `${title} by ${_.startCase(industry[1])}`;
  }

  return `${title} by ${_.startCase(buckets.value as string)}`;
};

export const getResourceName = (
  resources: ts.StoreState['resources'],
  resourceId: number,
  resourceType: keyof ts.StoreState['resources']
) => {
  const currentResourceName = (resources?.[resourceType] as ts.types.common.Resource[])?.find(
    (el) => el.id === resourceId
  )?.name;

  if (currentResourceName) return `${currentResourceName} -`;
  return null;
};
