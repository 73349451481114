/* eslint-disable no-unused-vars */
import { ts } from '_core';

export enum INTERACTION_PARAMS {
  VARIANT = 'variant',
}

export type InteractionParams = {
  [INTERACTION_PARAMS.VARIANT]: 'horizons' | 'histories';
};

export type JobParams = {
  return_horizons?: ts.types.returnHorizon.ReturnHorizon[];
  histories?: ts.enums.HISTORY_ENUM_EXTENDED[];
};

export type Params = JobParams & InteractionParams;
export type DraftParams = Partial<Params>;
