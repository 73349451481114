import { ts } from '_core';

import { DraftParams, INTERACTION_PARAMS } from './types';

export default [
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.TIME_SERIES_PLOT,
    title: 'Time Series Plot',
    helpLabel: 'khd6fbsp',
    form: false,
    defaultParams: {
      plot_type: 'line',
    } as DraftParams,
    interactionParams: Object.values(INTERACTION_PARAMS),
    neededFiles: ['time_series_plot'],
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.TIME_SERIES],
  },
] satisfies ts.types.analysis.WidgetConfig[];
