import { _, helpers, mui, ts, ui } from '_core';

import { DraftParams } from './types';

export const validate = (params: DraftParams, _c: any, isGeneralAnalysys: boolean) => {
  if (isGeneralAnalysys && !(params as ts.types.signalSet.SignalSetAnalysisContext)?.signal_set_id)
    return ['Signal Set is required'];
  const rhErrors = helpers.validations.validSelectedReturnHorizons(params?.return_horizons);
  if (!_.isEmpty(rhErrors)) return rhErrors;
  return [];
};

export const getGeneralAnalysisPrefix = (params: DraftParams, resources: ts.StoreState['resources']) => (
  <mui.core.Box display="flex" gap={1}>
    <ui.ResourceIcon resource={ts.enums.UI_RESOURCE_TYPE_ENUM.SIGNAL_SET} hasTooltip />
    {helpers.reports.getResourceName(resources, params.signal_set_id, 'signal_sets')}&nbsp;
  </mui.core.Box>
);
