import { ts } from '_core';

import { getName, validate } from './helpers';
import { DraftParams } from './types';

export default [
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.BASKET_COMPARE_SIGNAL_EXPOSURE,
    title: 'Signal Exposures vs. Time',
    helpLabel: 'gb1o5mv5',
    form: true,
    defaultParams: {
      factors: [{ type: 'signal' }],
    } as DraftParams,
    neededFiles: ['basket_signal_exposure'],
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.BASKET_COMPARE],

    getName,
    validate,
  },
] satisfies ts.types.analysis.WidgetConfig[];
