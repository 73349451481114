import { ts } from '_core';

import { INTERACTION_PARAMS } from './types';

export default [
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.RISK_MODEL_TABULAR_SIGNIFICANCE,
    interactionParams: Object.values(INTERACTION_PARAMS),
    title: 'Significance Statistics Report',
    form: false,
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.RISK_MODEL_REPORT],
    neededFiles: ['risk_model_regression_stats'],

    helpLabel: 'jemzvtj5',
  },
] satisfies ts.types.analysis.WidgetConfig[];
