import { hooks, mui, React, ts, ui } from '_core';

import { DraftParams } from './types';

type FormProps = {
  params: DraftParams;
  setParams: (_p: DraftParams) => void;
};

const Form: React.FC<FormProps> = ({ params, setParams }) => {
  const [timeSeriesId, setTimeSeriesId] = React.useState(params.time_series_id);
  const [upperBound, setUpperBound] = React.useState(params.upper_bound);
  const [lowerBound, setLowerBound] = React.useState(params.lower_bound);
  const [daysBeforeEvent, setDaysBeforeEvent] = React.useState(params.days_before_event);
  const [daysAfterEvent, setDaysAfterEvent] = React.useState(params.days_after_event);
  const uiStyles = hooks.useUiStyles();

  React.useEffect(() => {
    const data = {
      ...params,
      time_series_id: timeSeriesId,
      upper_bound: upperBound,
      lower_bound: lowerBound,
      days_before_event: daysBeforeEvent,
      days_after_event: daysAfterEvent,
    };
    setParams(data);
  }, [upperBound, lowerBound, daysBeforeEvent, daysAfterEvent, timeSeriesId]);

  return (
    <mui.core.Box sx={uiStyles.widgetEditMode}>
      <ui.ResourceAutocomplete<ts.types.common.ResourceExpanded>
        value={timeSeriesId}
        setValue={(id) => setTimeSeriesId(id)}
        type={ts.enums.RESOURCES_TYPES_ENUM.TIME_SERIES}
        inputProps={{
          fullWidth: true,
          label: 'Time Series',
          variant: 'outlined',
          required: true,
          size: 'small',
        }}
      />

      <mui.core.Typography
        variant="body2"
        fontWeight="fontWeightMedium"
        sx={{
          marginBottom: '10px',
          marginTop: '5px',
        }}
      >
        Event Selection Bounds (at least one is needed)
      </mui.core.Typography>
      <mui.core.Grid container spacing={2}>
        <mui.core.Grid item xs={6}>
          <ui.NumericField
            textFieldProps={{
              label: 'Lower Bound',
              fullWidth: true,
              variant: 'outlined',
              size: 'small',
              required: true,
            }}
            value={lowerBound}
            setValue={(v) => setLowerBound(v)}
          />
        </mui.core.Grid>

        <mui.core.Grid item xs={6}>
          <ui.NumericField
            textFieldProps={{
              label: 'Upper Bound',
              fullWidth: true,
              variant: 'outlined',
              size: 'small',
              required: true,
            }}
            value={upperBound}
            setValue={(v) => setUpperBound(v)}
          />
        </mui.core.Grid>
      </mui.core.Grid>

      <mui.core.Typography
        variant="body2"
        fontWeight="fontWeightMedium"
        sx={{
          marginBottom: '10px',
          marginTop: '5px',
        }}
      >
        Event Window
      </mui.core.Typography>
      <mui.core.Grid container spacing={2}>
        <mui.core.Grid item xs={6}>
          <ui.NumericField
            textFieldProps={{
              label: 'Days Before Event (1 - 21)',
              fullWidth: true,
              variant: 'outlined',
              size: 'small',
              required: 'true',
            }}
            value={daysBeforeEvent}
            setValue={(v) => setDaysBeforeEvent(v)}
          />
        </mui.core.Grid>

        <mui.core.Grid item xs={6}>
          <ui.NumericField
            textFieldProps={{
              label: 'Days After Event (0 - 63)',
              fullWidth: true,
              variant: 'outlined',
              size: 'small',
            }}
            value={daysAfterEvent}
            setValue={(v) => setDaysAfterEvent(v)}
          />
        </mui.core.Grid>
      </mui.core.Grid>

      {/* <mui.core.Grid item xs={4}>
        <mui.core.FormControlLabel
          size="small"
          control={(
            <mui.core.Switch
              checked={inclusive}
              onChange={() => setInclusive(!inclusive)}
            />
          )}
          label="Incluvise"
        />
      </mui.core.Grid> */}
    </mui.core.Box>
  );
};

export default Form;
