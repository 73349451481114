/**
 * Generates the Cartesian product of multiple arrays
 * @param args - Variable number of arrays to compute the Cartesian product of
 * @returns Array containing all possible combinations of elements from the input arrays
 * @example
 * cartesian([1,2], ['a','b']) => [[1,'a'], [1,'b'], [2,'a'], [2,'b']]
 */
export default (...args: any[][]): any[][] => {
  const r: any[] = [];
  const max = args.length - 1;

  const helper = (arr: any[], i: number) => {
    for (let j = 0, l = args[i].length; j < l; j++) {
      const a = arr.slice(0); // clone arr
      a.push(args[i][j]);
      if (i == max) r.push(a);
      else helper(a, i + 1);
    }
  };

  helper([], 0);
  return r;
};
