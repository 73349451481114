import { mui, React, ts, ui } from '_core';

import RelaxOrder from './relax-order';
import constraintInputNames from '../optimizer-utils/input-names';

type MaximumTcostProps = {
  params: ts.types.optimizer.MaximumTcostDraft;
  setParams: (_params: ts.types.optimizer.MaximumTcostDraft) => void;
  readOnly: boolean;
};

const MaximumTcost: React.FC<MaximumTcostProps> = ({ params, setParams, readOnly }): React.ReactElement => {
  return (
    <mui.core.Box p={2}>
      <mui.core.Grid container spacing={4}>
        <mui.core.Grid item xs={12} md={4}>
          <ui.NumericField
            textFieldProps={{
              label: constraintInputNames.maximum_t_cost_upper_bound,
              fullWidth: true,
              variant: 'outlined',
              size: 'small',
              required: true,
            }}
            disabled={readOnly}
            value={params.upper_bound}
            setValue={(v) => setParams({ ...params, upper_bound: v })}
          />
        </mui.core.Grid>

        <mui.core.Grid item xs={12} md={4}>
          <RelaxOrder params={params} setParams={setParams} disabled={readOnly} />
        </mui.core.Grid>
      </mui.core.Grid>
    </mui.core.Box>
  );
};

export default MaximumTcost;
