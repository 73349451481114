import { React, ts } from '_core';

import Chart from './chart';
import { DraftParams, Params } from './types';

type PlotProps = {
  id: string;
  readOnly: boolean;
  widgetKey: string;
  params: Params | DraftParams;
  setParams: (_p: DraftParams) => void;
  title: string;
  widgetPaths: Record<'time_series_plot', string>;
  loadWidgetData: (_payload: ts.types.widgets.WidgetGetDataParams) => Promise<ts.types.widgets.WidgetGetDataResponse>;

  fullScreen?: boolean;
};

const Plot: React.FC<PlotProps> = ({
  id,
  readOnly,
  widgetKey,
  loadWidgetData,
  widgetPaths,
  fullScreen,
  title,
  params,
  setParams,
}) => {
  return (
    <Chart
      id={id}
      readOnly={readOnly}
      widgetKey={widgetKey}
      loadWidgetData={loadWidgetData}
      file={widgetPaths['time_series_plot']}
      params={params as Params}
      setParams={setParams}
      fullScreen={fullScreen}
      title={title}
    />
  );
};

export default Plot;
