import { _, mui, React, ts } from '_core';

import { FixedCostContext } from '../fixed-costs-context';

type ComissionUnitProps = {
  row: ts.types.fixedCosts.FixedCostDraft;
};

const ComissionUnit: React.FC<ComissionUnitProps> = ({ row }): React.ReactElement => {
  const [unit, setUnit] = React.useState(row.commission_unit);

  const fixedCostsContext = React.useContext(FixedCostContext);
  const { updatePropertyById } = fixedCostsContext;

  return (
    <mui.core.TextField
      select
      fullWidth
      value={unit}
      sx={{
        '&&&:before': {
          borderBottom: 'none',
        },
        '&&:after': {
          borderBottom: 'none',
        },
      }}
      onChange={(e) => {
        setUnit(e.target.value);
        updatePropertyById(row.id, 'commission_unit', e.target.value);
      }}
    >
      {Object.values(ts.enums.FIXED_COST_COMISSION_ENUM).map((option) => (
        <mui.core.MenuItem key={option} value={option}>
          {_.upperCase(option)}
        </mui.core.MenuItem>
      ))}
    </mui.core.TextField>
  );
};

export default ComissionUnit;
