import { custom, helpers, ts } from '_core';

export default {
  [ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.EXPOSURE]: [
    {
      key: 'label',
      name: '',
      formatter: helpers.tableFormatters.formatTo('string', { align: 'left' }),
      filter: 'string',
      isFixed: true,
    },
    {
      key: 'metric',
      name: 'Metric',
      formatter: helpers.tableFormatters.formatTo('string', { align: 'left' }),
      filter: 'string',
    },
    {
      key: 'net_long_buys_value',
      name: 'Net Long Buys - Value (US$)',
      nameFormatter: () => <custom.MultilineTableHeader name={['Net Long Buys', 'Value (US$)']} />,
      formatter: helpers.tableFormatters.formatTo('float', {
        align: 'right',
        roundDigits: 2,
        numericPrefix: '$ ',
        commasOnThousands: true,
      }),
      filter: 'number',
    },
    {
      key: 'initial_long_exposure',
      name: 'Initial Long - Exposure (%)',
      nameFormatter: () => <custom.MultilineTableHeader name={['Initial long ', 'exposure (%)']} />,
      formatter: helpers.tableFormatters.formatTo('float', {
        align: 'right',
        roundDigits: 3,
        multiplier: 100,
        suffix: '%',
        commasOnThousands: true,
      }),
      filter: 'number',
    },
    {
      key: 'optimal_long_exposure',
      name: 'Optimal Long - Exposure (%)',
      nameFormatter: () => <custom.MultilineTableHeader name={['Optimal long', 'exposure (%)']} />,
      formatter: helpers.tableFormatters.formatTo('float', {
        align: 'right',
        roundDigits: 3,
        multiplier: 100,
        suffix: '%',
        commasOnThousands: true,
      }),
      filter: 'number',
    },
    {
      key: 'net_long_buys',
      name: 'Net Long - Buys (%)',
      nameFormatter: () => <custom.MultilineTableHeader name={['Net Long', 'Buys (%)']} />,
      formatter: helpers.tableFormatters.formatTo('float', {
        align: 'right',
        roundDigits: 3,
        multiplier: 100,
        suffix: '%',
        commasOnThousands: true,
      }),
      filter: 'number',
    },
    {
      key: 'net_short_sell_value',
      name: 'Net Short Sell - Value (US$)',
      nameFormatter: () => <custom.MultilineTableHeader name={['Net Short Sell', 'Value (US$)']} />,
      formatter: helpers.tableFormatters.formatTo('float', {
        align: 'right',
        roundDigits: 2,
        numericPrefix: '$ ',
        commasOnThousands: true,
      }),
      filter: 'number',
    },
    {
      key: 'initial_short_exposure',
      name: 'Initial Short - Exposure (%)',
      nameFormatter: () => <custom.MultilineTableHeader name={['Initial Short', 'exposure (%)']} />,
      formatter: helpers.tableFormatters.formatTo('float', {
        align: 'right',
        roundDigits: 3,
        multiplier: 100,
        suffix: '%',
        commasOnThousands: true,
      }),
      filter: 'number',
    },
    {
      key: 'optimal_short_exposure',
      name: 'Optimal Short - Exposure (%)',
      nameFormatter: () => <custom.MultilineTableHeader name={['Optimal Short', 'exposure (%)']} />,
      formatter: helpers.tableFormatters.formatTo('float', {
        align: 'right',
        roundDigits: 3,
        multiplier: 100,
        suffix: '%',
        commasOnThousands: true,
      }),
      filter: 'number',
    },
    {
      key: 'net_short_sells',
      name: 'Net Short - Exposure (%)',
      nameFormatter: () => <custom.MultilineTableHeader name={['Net Short', 'Sells (%)']} />,
      formatter: helpers.tableFormatters.formatTo('float', {
        align: 'right',
        roundDigits: 3,
        multiplier: 100,
        suffix: '%',
        commasOnThousands: true,
      }),
      filter: 'number',
    },
    {
      key: 'net_buys_vs_sells',
      name: 'Net Buys vs Sells - Value (US$)',
      nameFormatter: () => <custom.MultilineTableHeader name={['Net Buys vs Sells', '(US$)']} />,
      formatter: helpers.tableFormatters.formatTo('float', {
        align: 'right',
        roundDigits: 2,
        numericPrefix: '$ ',
        commasOnThousands: true,
      }),
      filter: 'number',
    },
    {
      key: 'initial_net_exposure',
      name: 'Initial Net - Exposure',
      nameFormatter: () => <custom.MultilineTableHeader name={['Initial net', 'exposure']} />,
      formatter: helpers.tableFormatters.formatTo('float', {
        align: 'right',
        roundDigits: 3,
        multiplier: 100,
        suffix: '%',
        commasOnThousands: true,
      }),
      filter: 'number',
    },
    {
      key: 'optimal_net_exposure',
      name: 'Optimal Net - Exposure',
      nameFormatter: () => <custom.MultilineTableHeader name={['Optimal net', 'exposure']} />,
      formatter: helpers.tableFormatters.formatTo('float', {
        align: 'right',
        roundDigits: 3,
        multiplier: 100,
        suffix: '%',
        commasOnThousands: true,
      }),
      filter: 'number',
    },
    {
      key: 'net_buy_vs_sells_exposure',
      name: 'Net Buys vs Sells - Exposure',
      nameFormatter: () => <custom.MultilineTableHeader name={['Net Buys vs Sells', 'exposure']} />,
      formatter: helpers.tableFormatters.formatTo('float', {
        align: 'right',
        roundDigits: 3,
        multiplier: 100,
        suffix: '%',
        commasOnThousands: true,
      }),
      filter: 'number',
    },

    {
      key: 'forecast_cost',
      name: 'Forecast Cost',
      formatter: helpers.tableFormatters.formatTo('float', {
        align: 'right',
        roundDigits: 3,
        multiplier: 100,
        suffix: '%',
        commasOnThousands: true,
      }),
      filter: 'number',
    },
    {
      key: 'trade_count',
      name: 'Trade Count',
      formatter: helpers.tableFormatters.formatTo('integer', { align: 'right' }),
      filter: 'number',
    },
    {
      key: 'avg_trade',
      name: 'Avg Trade',
      formatter: helpers.tableFormatters.formatTo('float', {
        align: 'right',
        roundDigits: 3,
        multiplier: 100,
        suffix: '%',
        commasOnThousands: true,
      }),
      filter: 'number',
    },
    {
      key: 'total_gross_trade',
      name: 'Total Gross Trade',
      formatter: helpers.tableFormatters.formatTo('float', {
        align: 'right',
        roundDigits: 3,
        multiplier: 100,
        suffix: '%',
        commasOnThousands: true,
      }),
      filter: 'number',
    },
    {
      key: 'avg_days_adv',
      name: 'Avg Days ADV',
      formatter: helpers.tableFormatters.formatTo('float', {
        align: 'right',
        roundDigits: 3,
        multiplier: 100,
        suffix: '%',
        commasOnThousands: true,
      }),
      filter: 'number',
    },
  ] as ts.types.components.dataGrid.ColumnsData,
};
