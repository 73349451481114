import React from 'react';

import Button from './button';
import DialogTitle from './dialog-title';
import { mui } from '../libs';

type EditWidgetTitleProps = {
  open: boolean;
  params: Record<string, any>;
  setOpen: (_o: boolean) => void;
  setParams: (_p: Record<string, any>) => Promise<void>;

  resetTitle?: () => Promise<void>;
  enableSubtitle?: boolean;
};

/**
 * EditWidgetTitle component that displays a dialog for editing the title of a widget.
 * 
 * @param open - Whether the dialog is open.
 * @param params - The parameters of the widget.
 * @param setOpen - The function to set the open state of the dialog.
 * @param setParams - The function to set the parameters of the widget.
 * @param resetTitle - The function to reset the title of the widget.
 * @param enableSubtitle - Whether to enable the subtitle of the widget.
 * 
 * @returns A React component that displays a dialog for editing the title of a widget.
 */

const EditWidgetTitle: React.FC<EditWidgetTitleProps> = ({
  open,
  setOpen,
  params,
  setParams,
  resetTitle,
  enableSubtitle = true,
}): React.ReactElement => {
  const [loading, setLoading] = React.useState(false);

  const [title, setTitle] = React.useState(params.title);
  const [subtitle, setSubtitle] = React.useState(params.subtitle);

  const closeModal = () => {
    setOpen(false);
  };

  const handleSubmit = async () => {
    setLoading(true);
    await setParams({ ...params, title, subtitle });
    setLoading(false);
    setOpen(false);
  };

  const resetValue = async () => {
    setLoading(true);
    await resetTitle();
    setTitle('');
    setSubtitle('');
    setLoading(false);
    setOpen(false);
  };

  return (
    <mui.core.Dialog open={open} onClose={closeModal} maxWidth="sm" fullWidth>
      <DialogTitle closeAction={closeModal}>Update Title</DialogTitle>
      <mui.core.DialogContent>
        <mui.core.Box mt={4}>
          <mui.core.TextField
            required
            variant="outlined"
            id="description"
            label="Title"
            fullWidth
            value={title}
            size="small"
            onChange={(e) => setTitle(e.target.value)}
          />
        </mui.core.Box>
        {enableSubtitle && (
          <mui.core.Box my={3}>
            <mui.core.TextField
              required
              variant="outlined"
              id="description"
              label="Subtitle"
              fullWidth
              value={subtitle}
              size="small"
              onChange={(e) => setSubtitle(e.target.value)}
            />
          </mui.core.Box>
        )}
      </mui.core.DialogContent>

      <mui.core.DialogActions>
        {resetTitle && (
          <Button variant="outlined" color="primary" onClick={resetValue} loading={loading}>
            Reset
          </Button>
        )}
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          loading={loading}
          style={{ margin: '0.5rem 1rem' }}
        >
          Update
        </Button>
      </mui.core.DialogActions>
    </mui.core.Dialog>
  );
};

export default EditWidgetTitle;
