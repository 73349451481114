import { React, ts } from '_core';

import Chart from './chart';
import Form from './form';
import { DraftParams, Params } from './types';

type BacktestRiskReportProps = {
  id: string;
  mode: ts.enums.CHART_MODE_ENUM;
  params: Params;
  setParams: (_p: DraftParams) => void;

  loadWidgetData: (_payload: ts.types.widgets.WidgetGetDataParams) => Promise<ts.types.widgets.WidgetGetDataResponse>;
  widgetPaths?: Record<
    | 'backtest_risk_report_summary'
    | 'backtest_factors_contribution_to_total_risk'
    | 'backtest_factor_exposure_risk_report'
    | 'backtest_security_contribution_to_total_risk',
    string
  >;
  goFullScreen?: (_b: string) => void;
  fullScreen?: boolean;

  queryParams?: { initial?: string };
  context?: ts.types.backtest.BacktestAnaylsisContext | ts.types.basket.BasketAnalysisContext;
};

const BacktestRiskReport: React.FC<BacktestRiskReportProps> = ({
  id,
  mode,
  params,
  setParams,
  loadWidgetData,
  widgetPaths,
  context,
  fullScreen,
  goFullScreen,
  queryParams,
}) => {
  const chart = () => (
    <Chart
      id={id}
      loadWidgetData={loadWidgetData}
      params={params}
      setParams={setParams}
      riskSummary={widgetPaths['backtest_risk_report_summary']}
      factorContribution={widgetPaths['backtest_factors_contribution_to_total_risk']}
      securityContribution={widgetPaths['backtest_security_contribution_to_total_risk']}
      factorExposure={widgetPaths['backtest_factor_exposure_risk_report']}
      reportDate={params.end_date}
      isBasket={params.isBasket}
      fullScreen={fullScreen}
      queryParams={queryParams}
      goFullScreen={goFullScreen}
    />
  );

  const renderJobComponent = () => <>{chart()}</>;

  return (
    <>
      {mode == 'edit' ? <Form params={params} setParams={setParams} context={context} /> : <>{renderJobComponent()}</>}
    </>
  );
};

export default BacktestRiskReport;
