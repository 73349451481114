import React from 'react';

import { etfCodes } from '../../data';
import { mui } from '../../libs';
import * as types from '../../types';

type IndexSelectorProps = {
  value: types.benchmark.Benchmark;
  onChange: (_v: types.benchmark.Benchmark) => void;
  isError?: boolean;
};


/**
 * Component that renders an autocomplete selector for benchmark indices
 * 
 * @param value - The currently selected benchmark value
 * @param onChange - Callback function when selection changes
 * @param isError - Whether to show error state
 * 
 * @returns Rendered autocomplete component for benchmark selection
 */
const IndexSelector: React.FC<IndexSelectorProps> = ({ value, onChange, isError }): React.ReactElement => {
  return (
    <mui.core.Grid item xs={12} md={4}>
      <mui.core.Autocomplete
        options={etfCodes.map((c) => c.FSYM_ID)}
        value={value?.code}
        onChange={(_e, val) => onChange({ ...value, code: val || null })}
        getOptionLabel={(option) => etfCodes.find((v) => v.FSYM_ID == option)?.name}
        renderOption={(props, option) => {
          const etfData = etfCodes.find((v) => v.FSYM_ID == option);
          return (

            <li {...props}>
              <mui.core.Typography variant="body2" style={{ marginBottom: '-4px' }}>
                <strong style={{ fontWeight: 500 }}>{etfData.ticker}</strong> · {etfData.name}
              </mui.core.Typography>
            </li>
          );
        }}
        noOptionsText="Select Expression"
        groupBy={(option) => etfCodes.find((v) => v.FSYM_ID == option)?.group as string}
        renderInput={(params) => (
          <mui.core.TextField

            {...params}
            variant="outlined"
            required
            size="small"
            fullWidth
            label="Benchmark Index"
            error={isError}
          />
        )}
      />
    </mui.core.Grid>
  );
};

export default IndexSelector;
