import { mui, React, ts } from '_core';

import constraintInputNames from '../optimizer-utils/input-names';

type RelaxOrderProps = {
  params: ts.types.optimizer.ConstraintDraft;
  setParams: (_params: ts.types.optimizer.ConstraintDraft & { constraint_type?: any }) => void;
  disabled?: boolean;
};

const RelaxOrder: React.FC<RelaxOrderProps> = ({ params, setParams, disabled }): React.ReactElement => {
  return (
    <mui.core.Grid item xs={12} md={6}>
      <mui.core.TextField
        select
        label={constraintInputNames.relax_order}
        variant="outlined"
        disabled={disabled}
        value={params.relax_order || 'NEVER'}
        onChange={(e: any) =>
          setParams({
            ...params,
            relax_order: e.target.value as ts.types.optimizer.ConstraintDraft['relax_order'],
          })
        }
        fullWidth
        size="small"
      >
        <mui.core.MenuItem value={'FIRST'}>First</mui.core.MenuItem>
        <mui.core.MenuItem value={'SECOND'}>Second</mui.core.MenuItem>
        <mui.core.MenuItem value={'LAST'}>Last</mui.core.MenuItem>
        <mui.core.MenuItem value={'NEVER'}>Never</mui.core.MenuItem>
      </mui.core.TextField>
    </mui.core.Grid>
  );
};

export default RelaxOrder;
