import { _, mui, React, ts, ui, useSelector } from '_core';

import { formatData, prepareDataSummaryStats } from './helpers';
import { Params } from './types';
import ChartWrapper from '../../common/chart-wrapper';
import createRadarChart from '../../common/charts/create-radar-chart';
import { radarChartFormat } from '../../common/charts/formatters/radar-chart';

type ChartProps = {
  id: string;
  readOnly: boolean;
  widgetKey: string;
  loadWidgetData: (_payload: ts.types.widgets.WidgetGetDataParams) => Promise<ts.types.widgets.WidgetGetDataResponse>;
  portfolios: string;
  fullScreen: boolean;
  params: Params;
  visualization: 'chart' | 'table';
  context: ts.types.basket.BasketAnalysisContext;
  title: string;
};

const Chart: React.FC<ChartProps> = ({
  id,
  readOnly,
  widgetKey,
  loadWidgetData,
  portfolios,
  visualization,
  params,
  context,
  fullScreen,
  title,
}) => {
  const baskets = useSelector((state) => state.resources.baskets);

  const [data, setData] = React.useState<{
    chart: ts.types.widgets.RadarData;
    table: ts.types.widgets.TableData;
    dimensions: string[];
  }>(null);
  const [error, setError] = React.useState(null);

  const loadData = async () => {
    try {
      const response = await loadWidgetData({
        data: {
          file: portfolios,
          rename: { metric: 'spoke' },
        },
      });

      const formattedData = formatData(response.data);
      const radarChartData = radarChartFormat(formattedData);
      if (response) setData(prepareDataSummaryStats(radarChartData, params, context, baskets));
    } catch (err) {
      setError(JSON.stringify(err));
    }
  };

  React.useEffect(() => {
    if (portfolios) loadData();
    return () => setData(null);
  }, [portfolios]);

  React.useEffect(() => {
    // Create radar chart
    if (!_.isNil(data))
      createRadarChart({
        id,
        data: data.chart,
        fullScreen,
        labels: data.table as ts.types.widgets.RadarData,
        dimensions: data.dimensions,
        exportTitle: title,
      });
  }, [data]);

  const getColumns = React.useMemo(() => {
    if (!data?.table || !data?.dimensions) return [];

    return ['spoke', ...data.dimensions].map((field) => ({
      field,
      headerName: field,
      flex: 1,
    })) as mui.dataGrid.GridColDef[];
  }, [data]);

  return (
    <>
      <mui.core.Box display={visualization == 'chart' ? 'inline' : 'none'}>
        <ChartWrapper
          id={id}
          readOnly={readOnly}
          widgetKey={widgetKey}
          data={data}
          error={error}
          fullScreen={fullScreen}
        />
      </mui.core.Box>
      <mui.core.Box display={visualization == 'table' ? 'inline' : 'none'}>
        {!_.isNull(data) && (
          <ui.FinDataGrid
            rows={data?.table || []}
            columns={getColumns}
            autoHeight
            hideFooter
            disableColumnMenu
            disableColumnFilter
            sx={{
              '& .MuiDataGrid-cell': {
                fontSize: 14,
              },
            }}
          />
        )}
      </mui.core.Box>
    </>
  );
};

export default Chart;
