import { _, React, ts, useSelector } from '_core';

import { prepareData } from './helpers';
import { Params } from './types';
import ChartWrapper from '../../common/chart-wrapper';
import createLineChart from '../../common/charts/create-line-chart';

type ChartProps = {
  id: string;
  readOnly: boolean;
  widgetKey: string;
  loadWidgetData: (_payload: ts.types.widgets.WidgetGetDataParams) => Promise<ts.types.widgets.WidgetGetDataResponse>;
  coverageFile: string;
  fullScreen: boolean;
  title: string;
  params: Params;
  context: ts.types.riskModel.RiskModelAnalysisContext;
};

const Chart: React.FC<ChartProps> = ({
  id,
  readOnly,
  widgetKey,
  loadWidgetData,
  coverageFile,
  fullScreen,
  title,
  params,
  context,
}): React.ReactElement => {
  const [data, setData] = React.useState<ts.types.widgets.TableData>(null);
  const [error, setError] = React.useState<ts.types.common.ApiError>(null);
  const [showLegend, setShowLegend] = React.useState(false);

  const resources = useSelector((store) => store.resources);
  const universe = React.useMemo(() => resources.universes.find((u) => u.id == params.analysis_universe_id), []);
  const riskModel = React.useMemo(() => resources.risk_models.find((r) => r.id == context.id), []);

  const loadData = async () => {
    try {
      const response = await loadWidgetData({
        data: {
          file: coverageFile,
          rename: { build_date: 'date' },
        },
      });

      if (response) setData(prepareData(response.data, universe, riskModel));
    } catch (err) {
      setError(err as ts.types.common.ApiError);
    }
  };

  React.useEffect(() => {
    if (coverageFile) loadData();
    return () => setData(null);
  }, [coverageFile]);

  React.useEffect(() => {
    // Create line chart on data and scale changes
    if (!_.isNil(data))
      createLineChart({
        id,
        data,
        fullScreen,
        setShowLegend,
        yAxisLabel: 'Asset Count',
        xAxisLabel: 'Time',
        percent: false,
        aggregation: 'outlier',
        exportTitle: title,
      });
  }, [data]);

  return (
    <ChartWrapper
      id={id}
      readOnly={readOnly}
      widgetKey={widgetKey}
      data={data}
      showLegend={showLegend}
      error={error}
      fullScreen={fullScreen}
    />
  );
};

export default Chart;
