import { Button } from '@mui/material';

import ContextSingleton from '../__singletons__/context-singleton';
import { _, mui, useDispatch, useSelector } from '../libs';
import { APP_BAR_HEIGHT } from '../theme/layout-size';
import { BaseStore } from '../types';

interface ApiErrorsProps {
  mockApiErrors?: any;
}
/**
 * Component that displays and manages API error notifications
 *
 * @requires Redux Store:
 * - state.apiErrors.stack: Stack of API errors to display
 *
 * @requires Store Actions:
 * - apiErrors.clearErrors: Action to clear all API errors
 * - apiErrors.popError: Action to remove the latest API error
 *
 * @param mockApiErrors - Optional mock errors for testing/development purposes
 *
 * @returns Alert component with error display and management controls
 */

const ApiErrors = ({ mockApiErrors }: ApiErrorsProps): React.ReactElement => {
  const fromStore = useSelector((state: BaseStore) => state.apiErrors.stack);
  const actions = ContextSingleton.getInstance().actions;
  const apiErrors = mockApiErrors || fromStore;
  const dispatch = useDispatch();

  return (
    <>
      {_.isEmpty(apiErrors) ? (
        <></>
      ) : (
        <mui.lab.Alert
          sx={{
            position: mockApiErrors ? 'relative' : 'fixed',
            top: 0,
            zIndex: 1500,
            width: '100%',
            padding: '0 1rem',
            minHeight: APP_BAR_HEIGHT,
            '& .MuiAlert-message': {
              width: '100%',
            },
            display: 'flex',
            alignItems: 'center',
          }}
          icon={false}
          severity="error"
          action={
            <mui.core.Stack flexDirection="row" alignItems="center" gap={2}>
              <Button
                aria-label="delete"
                onClick={() => dispatch(actions.apiErrors.clearErrors)}
                color="error"
                variant="outlined"
                size="small"
              >
                Clear
              </Button>
              <mui.core.Tooltip title="Close" arrow>
                <mui.core.IconButton
                  aria-label="delete"
                  onClick={() => dispatch(actions.apiErrors.popError)}
                  size="small"
                  color="error"
                >
                  <mui.icons.Close />
                </mui.core.IconButton>
              </mui.core.Tooltip>
            </mui.core.Stack>
          }
        >
          <>
            <mui.core.Stack flexDirection="row" alignItems="center" gap={2}>
              {apiErrors[0].status && <mui.core.Typography variant="h6">{apiErrors[0].status}</mui.core.Typography>}
              <mui.core.Stack gap={4} flexDirection="row">
                {apiErrors[0].url && (
                  <mui.core.Chip label={`Endpoint: ${apiErrors[0].url}`} size="small" color="error" />
                )}
                {apiErrors[0].statusMessage && (
                  <mui.core.Chip label={`${apiErrors[0].statusMessage}`} size="small" color="error" />
                )}
                {apiErrors[0].response && (
                  <mui.core.Chip
                    label={`Response: ${JSON.stringify(apiErrors[0].response)}`}
                    size="small"
                    color="error"
                  />
                )}
              </mui.core.Stack>
            </mui.core.Stack>
          </>
        </mui.lab.Alert>
      )}
    </>
  );
};

export default ApiErrors;
