// CAUTION - THIS WIDGET IS ALSO RENDERED ON GENERAL ANALYSIS */

import { React, ts } from '_core';

import Chart from './chart';
import Form from './form';
import { DraftParams, Params } from './types';

type BasketCompareCumulativeReturnsProps = {
  id: string;
  readOnly: boolean;
  widgetKey: string;
  mode: ts.enums.CHART_MODE_ENUM;
  params: Params | DraftParams;
  setParams: (_p: DraftParams) => void;
  title: string;
  isGeneralAnalysis: boolean;
  setExternalEmbed: (_json: ts.types.analysis.EmbedChartData) => void;

  fullScreen?: boolean;
  context: ts.types.basket.BasketAnalysisContext | ts.types.analysis.GeneralAnalysisContext;
  loadWidgetData: (_payload: ts.types.widgets.WidgetGetDataParams) => Promise<ts.types.widgets.WidgetGetDataResponse>;
  widgetPaths?: Record<'backtest_returns', string>;
};

const BasketCompareCumulativeReturns: React.FC<BasketCompareCumulativeReturnsProps> = ({
  id,
  readOnly,
  widgetKey,
  loadWidgetData,
  widgetPaths,
  mode,
  params,
  setParams,
  fullScreen,
  context,
  title,
  isGeneralAnalysis,
  setExternalEmbed,
}) => {
  const renderJobComponent = () => (
    <Chart
      id={id}
      readOnly={readOnly}
      widgetKey={widgetKey}
      loadWidgetData={loadWidgetData}
      portfolios={widgetPaths['backtest_returns']}
      fullScreen={fullScreen}
      context={isGeneralAnalysis ? null : (context as ts.types.basket.BasketAnalysisContext)}
      params={params as Params}
      title={title}
      setExternalEmbed={setExternalEmbed}
    />
  );

  return <>{mode == 'edit' ? <Form params={params} setParams={setParams} /> : <>{renderJobComponent()}</>}</>;
};

export default BasketCompareCumulativeReturns;
