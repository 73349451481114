import { ts } from '_core';

import { validate } from './helpers';
import { INTERACTION_PARAMS } from './types';

export default [
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.BACKTEST_BUCKET_ANALYSIS,
    title: 'Bucket Analysis',
    form: true,
    defaultParams: {
      strategy_benchmark: true,
    },
    interactionParams: Object.values(INTERACTION_PARAMS),
    neededFiles: ['backtest_bucket_analysis'],
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.BACKTEST_PORTAN],

    validate,
    helpLabel: 'jmj6o9cl',
  },
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.PORTFOLIO_BUCKET_ANALYSIS,
    title: 'Bucket Analysis',
    form: true,
    defaultParams: {
      strategy_benchmark: true,
      is_portfolio: true,
    },
    interactionParams: Object.values(INTERACTION_PARAMS),
    neededFiles: ['portfolio_bucket_analysis'],
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.PORTFOLIO_ATTRIBUTION],

    validate,
    helpLabel: '8jam92ag',
  },
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.BASKET_BUCKET_ANALYSIS,
    title: 'Bucket Analysis',
    form: true,
    defaultParams: {
      strategy_benchmark: true,
      is_portfolio: true,
    },
    interactionParams: Object.values(INTERACTION_PARAMS),
    neededFiles: ['backtest_bucket_analysis'],
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.BASKET_ATTRIBUTION],

    validate,
    helpLabel: '8jam92ah',
  },
] satisfies ts.types.analysis.WidgetConfig[];
