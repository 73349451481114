import { _, actions, api, helpers, mui, React, ts, ui, useDispatch } from '_core';

import ConfirmDeleteModal from './confirm-delete-modal';
import EditModal from './edit-modal';

type DefaultsTableProps = {
  defaults: ts.types.analysis.ReportDefault[];
  layoutType: ts.enums.LAYOUT_TYPE;
  openEditModal: boolean;
  setOpenEditModal: (_open: boolean) => void;
  setAlert: (_v: string) => void;
};

const DefaultsTable: React.FC<DefaultsTableProps> = ({
  defaults,
  layoutType,
  openEditModal,
  setOpenEditModal,
  setAlert,
}) => {
  const dispatch = useDispatch();

  const [defaultToEdit, setDefaultToEdit] = React.useState<ts.types.analysis.ReportDefault>(null);
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const [localDefaults, setLocalDefaults] = React.useState(defaults);

  const theme = mui.styles.useTheme() as mui.core.Theme;

  const handleClose = () => {
    setDefaultToEdit(null);
    setOpenEditModal(false);
  };

  const handleEdit = (defaultEl: ts.types.analysis.ReportDefault) => {
    setOpenEditModal(true);
    setDefaultToEdit(defaultEl);
  };

  React.useEffect(() => {
    setLocalDefaults(
      defaults.map((r) => ({
        ...r,
        updated_at: new Date(r.updated_at).toDateString(),
      }))
    );
  }, [defaults]);

  const save = async (newDefault: ts.types.analysis.ReportDefault, oldDefault: ts.types.analysis.ReportDefault) => {
    try {
      await api.report_defaults.update(newDefault.id, newDefault);
      dispatch(actions.resources.updateReportDefault(newDefault));

      if (oldDefault) {
        await api.report_defaults.update(oldDefault.id, oldDefault);
        dispatch(actions.resources.updateReportDefault(oldDefault));
      }
    } catch (e) {
      setAlert(helpers.parseApiError(e as ts.types.common.ApiError));
    }
  };

  const handleDelete = (defaultEl: ts.types.analysis.ReportDefault) => {
    setDefaultToEdit(defaultEl);
    setOpenDeleteModal(true);
  };

  const handleSetPreferred = (rowData: ts.types.analysis.ReportDefault) => {
    let changedNewDefault = null;
    let changedOldDefault = null;

    const newPreferences = localDefaults.map((l) => {
      if (l.id == rowData.id) {
        changedNewDefault = { ...l, is_preferred: !l.is_preferred };
        return { ...changedNewDefault };
      }
      if (l.is_preferred && l.id != rowData.id) {
        changedOldDefault = { ...l, is_preferred: false };
        return { ...changedOldDefault };
      }

      return { ...l };
    });

    save(changedNewDefault, changedOldDefault);
    setLocalDefaults(newPreferences);
  };

  const columns: mui.dataGrid.GridColDef[] = [
    { field: 'title', headerName: 'Title', flex: 1 },
    { field: 'updated_at', headerName: 'Updated At', flex: 1 },
    {
      field: 'actions',
      headerName: '',
      width: 120,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <mui.core.Box sx={{ display: 'flex', gap: 3 }}>
          <div
            onClick={(e) => {
              e.stopPropagation();
              handleSetPreferred(params.row);
            }}
          >
            {params.row.is_preferred ? (
              <mui.icons.Star style={{ fontSize: 17, color: '#FCA239' }} />
            ) : (
              <mui.icons.StarBorder style={{ fontSize: 17, color: theme.palette.text.secondary }} />
            )}
          </div>
          <div
            onClick={(e) => {
              e.stopPropagation();
              handleEdit(params.row);
            }}
          >
            <mui.icons.Edit color="primary" style={{ fontSize: 17 }} />
          </div>
          <div
            onClick={(e) => {
              e.stopPropagation();
              handleDelete(params.row);
            }}
          >
            <mui.icons.Delete style={{ fontSize: 17, color: theme.palette.danger.main }} />
          </div>
        </mui.core.Box>
      ),
    },
  ];

  const CustomToolbar = () => (
    <mui.dataGrid.GridToolbarContainer>
      <mui.core.Typography variant="h6" sx={{ p: 1 }}>
        {`${_.startCase(layoutType)} Report Layouts`}
      </mui.core.Typography>
    </mui.dataGrid.GridToolbarContainer>
  );

  return (
    <mui.core.Box>
      {openEditModal && <EditModal defaultEl={defaultToEdit} onClose={handleClose} layoutType={layoutType} />}
      <ConfirmDeleteModal
        open={openDeleteModal}
        defaultEl={defaultToEdit}
        onClose={() => {
          setDefaultToEdit(null);
          setOpenDeleteModal(false);
        }}
      />
      <ui.FinDataGrid
        rows={localDefaults}
        columns={columns}
        autoHeight
        hideFooter
        disableColumnMenu
        slots={{
          toolbar: CustomToolbar,
        }}
        sx={{
          '& .MuiDataGrid-columnHeaders': { background: theme.palette.grayLight?.light || '#f5f5f5' },
        }}
      />
    </mui.core.Box>
  );
};

export default DefaultsTable;
