import { helpers, ts } from '_core';

export const summaryColumns = [
  {
    key: 'metric',
    name: '',
    formatter: helpers.tableFormatters.formatTo('string', { align: 'left' }),
    filter: 'string',
  },
  {
    key: 'long',
    name: 'Long',
    formatter: helpers.tableFormatters.formatTo('string', { align: 'right', showBlanks: true }),
    filter: 'number',
  },
  {
    key: 'short',
    name: 'Short',
    formatter: helpers.tableFormatters.formatTo('string', { align: 'right', showBlanks: true }),
    filter: 'number',
  },
  {
    key: 'value',
    name: 'Total',
    formatter: helpers.tableFormatters.formatTo('string', { align: 'right' }),
    filter: 'number',
  },
] as ts.types.components.dataGrid.ColumnsData;
