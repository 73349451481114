/* eslint-disable no-unused-vars */
import { ts } from '_core';

export type JobParams = {
  basket_ids: number[];
  analysis_pipelined_signals: {
    signal_id: number;
    pipeline_id?: number;
    sp_pipeline_id?: number;
    signal_label?: string;
  }[];
  basket_date?: string;
  daily_reporting: boolean;
};

export enum INTERACTION_PARAMS {
  TABLE_PARAMS = 'table_params',
  TABLE_DTYPES = 'table_dtypes',
  COMPONENT_ERRORS = 'component_errors',
  RUN_ON_DATE = 'run_on_date',
}

export type InteractionParams = {
  [INTERACTION_PARAMS.TABLE_PARAMS]: ts.types.components.dataGrid.TableParamsSheet;
  [INTERACTION_PARAMS.TABLE_DTYPES]: ts.types.components.dataGrid.TableDtypesSheet;
  [INTERACTION_PARAMS.COMPONENT_ERRORS]: string[];
  [INTERACTION_PARAMS.RUN_ON_DATE]: boolean;
};

export type Params = JobParams & InteractionParams & { fullWidth: boolean };
export type DraftParams = Partial<Params>;
