import { _, helpers, hooks, mui, React, ts, ui } from '_core';

import { layoutSize } from '@local/finsera-core/src/theme';

type ChartWrapperProps = {
  id: string;
  fullScreen: boolean;
  widgetKey: string;
  readOnly: boolean;
  data?: any; // There is no restriction on the data from for the wrapper, we only use it to know if is not empty
  showLegend?: boolean;
  warning?: string[];
  error?: ts.types.common.ApiError;
  customHeader?: React.ReactElement;
  tabs?: React.ReactElement;
  children?: React.ReactElement;
  disableProfiling?: boolean;
  showPeriodSelector?: boolean;
};

const ChartWrapper: React.FC<ChartWrapperProps> = ({
  id,
  data,
  showLegend,
  warning,
  error,
  customHeader,
  tabs,
  fullScreen,
  children,
  readOnly,
  widgetKey,
  disableProfiling,
  showPeriodSelector = false,
}) => {
  const TAB_HEIGHT = layoutSize.TAB_HEIGHT;
  const uiStyles = hooks.useUiStyles();
  const theme = mui.styles.useTheme() as mui.core.Theme;

  const [loadingAmchart, setLoadingAmchart] = React.useState<boolean>(undefined);

  const renderError = () => {
    if (readOnly)
      return (
        <mui.core.Box height="100%">
          <ui.EmptyMessage>
            This widget is read only. If the widget works on the owner account then it might reference a not
            published/shared resource. Please ask them to share the dependencies with you or to publish them in your
            organization.
          </ui.EmptyMessage>
        </mui.core.Box>
      );

    return (
      <mui.lab.Alert severity="error" sx={{ maxHeight: '50px' }}>
        {helpers.parseApiError(error)}
      </mui.lab.Alert>
    );
  };

  let gridTemplateRows = '1fr auto auto';
  if (!_.isEmpty(warning)) gridTemplateRows = 'auto' + gridTemplateRows;
  if (showPeriodSelector) gridTemplateRows = 'auto ' + gridTemplateRows;

  const renderChart = () => (
    <mui.core.Box
      sx={{
        height: fullScreen ? 'calc(100% - 40px)' : '100%',
        display: 'grid',
        gridTemplateRows,
      }}
    >
      {!_.isEmpty(warning) &&
        warning.map((w) => {
          return (
            <mui.lab.Alert key={w} severity="warning">
              {w}
            </mui.lab.Alert>
          );
        })}

      {showPeriodSelector && (
        <mui.core.Box id={`periods-selector-${id}-${fullScreen ? 'fs' : 'sw'}`} mt={3} mb={-1} mx={5} maxHeight={30} />
      )}

      <mui.core.Box
        id={`chart-${id}-${fullScreen ? 'fs' : 'sw'}`}
        sx={uiStyles.widgetContent}
        style={{ paddingLeft: 12 }}
      >
        <ui.OnMountUnmount
          virtualDom={false}
          performOnMount={() => setLoadingAmchart(true)}
          performOnUnmount={() => setLoadingAmchart(false)}
        />

        {children ? children : <ui.CenteredLoader label={'Preparing chart...'} />}
      </mui.core.Box>

      <mui.core.Box
        id={`legend-${id}-${fullScreen ? 'fs' : 'sw'}`}
        style={showLegend ? { marginRight: '0.5rem' } : { display: 'none' }}
        sx={fullScreen ? uiStyles.widgetLegendFullscreen : uiStyles.widgetLegend}
        className="widget-legend-container"
      />

      <div id={`widget-footer-${id}-${fullScreen ? 'fs' : 'sw'}`} />
    </mui.core.Box>
  );

  const profilerOn = React.useMemo(() => {
    if (disableProfiling) return false;
    if (_.isNil(data) && error) return false;
    if (!_.isNil(data) && loadingAmchart === false) return false;
    return true;
  }, [data, error, loadingAmchart]);

  const profilerRefs = hooks.useProfilerRefs([() => !_.isNil(data), () => loadingAmchart]);

  return (
    <>
      {profilerOn && widgetKey && (
        <ui.CustomProfiler
          profilerKey={widgetKey as any}
          parentStates={{ names: ['dataLoaded', 'amchartsLoad'], states: profilerRefs }}
          persist
        />
      )}

      <mui.core.Box
        display="flex"
        flexDirection="column"
        style={{ height: '100%', position: 'relative', overflow: 'hidden' }}
      >
        {tabs && (
          <mui.core.Box
            sx={{
              borderBottom: `1px solid ${theme.palette.gray.main}`,
            }}
          >
            {tabs}
          </mui.core.Box>
        )}

        <mui.core.Box
          display="grid"
          sx={{
            gridTemplateRows: customHeader || error ? 'auto 1fr' : '1fr',
            height: tabs ? `calc(100% - ${TAB_HEIGHT})` : '100%',
          }}
        >
          {customHeader}
          {_.isNil(data) ? (
            error ? (
              renderError()
            ) : (
              <mui.core.Box sx={uiStyles.widgetContent}>
                <ui.CenteredLoader label="Loading widget data..." top="-40px" />
              </mui.core.Box>
            )
          ) : (
            renderChart()
          )}
        </mui.core.Box>
      </mui.core.Box>
    </>
  );
};

ChartWrapper.defaultProps = {
  data: null,
  showLegend: false,
  warning: [],
  error: null,
  customHeader: null,
  tabs: null,
  fullScreen: false,
};

export default ChartWrapper;
