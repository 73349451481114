import { ts } from '_core';

import * as columns from 'views/preferences/columns-config';

import { SheetConfig } from './select-columns/pref-types';

const columnsSelectConfig = {
  // BACKTEST_SETS
  [ts.enums.PREFERENCES_INNER_TABS_ENUM.BACKTEST_SET_SUMMARY_COLS]: {
    columns: columns.backtestSetSummaryColumns,
    preferenceKey: ts.enums.PREFERENCES_KEY_ENUM.BACKTEST_SET_SUMMARY_COLS,
    sheetsConfig: [
      {
        title: ts.enums.REPORT_ENUMS.TABLE_SHEET_PREF_NAME_ENUM.BACKTEST_SET_SUMMARY,
        key: ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.SUMMARY,
        height: 400,
      },
    ],
  },

  // BACKTESTS
  [ts.enums.PREFERENCES_INNER_TABS_ENUM.BACKTEST_CONSTRAINTS_COLS]: {
    columns: columns.backtestConstraintsColumns,
    preferenceKey: ts.enums.PREFERENCES_KEY_ENUM.BACKTEST_CONSTRAINTS_COLS,
    sheetsConfig: [
      {
        title: ts.enums.REPORT_ENUMS.TABLE_SHEET_PREF_NAME_ENUM.MAIN,
        key: ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.MAIN,
        height: 400,
      },
    ],
  },
  [ts.enums.PREFERENCES_INNER_TABS_ENUM.BACKTEST_PREVIEW_COLS]: {
    columns: columns.backtestPreviewColumns,
    preferenceKey: ts.enums.PREFERENCES_KEY_ENUM.BACKTEST_PREVIEW_COLS,
    sheetsConfig: [
      {
        title: ts.enums.REPORT_ENUMS.TABLE_SHEET_PREF_NAME_ENUM.MAIN,
        key: ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.MAIN,
        height: 400,
      },
    ],
  },
  [ts.enums.PREFERENCES_INNER_TABS_ENUM.BACKTEST_DATE_COMPARISON]: {
    columns: columns.backtestDatesComparisonColumns,
    preferenceKey: ts.enums.PREFERENCES_KEY_ENUM.BACKTEST_DATE_COMPARISON_COLS,
    sheetsConfig: [
      {
        title: ts.enums.REPORT_ENUMS.TABLE_SHEET_PREF_NAME_ENUM.MAIN,
        key: ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.MAIN,
        height: 400,
      },
    ],
  },
  [ts.enums.PREFERENCES_INNER_TABS_ENUM.BACKTEST_PREVIEW_ALPHA_EXPOSURES_COLS]: {
    columns: columns.backtestPreviewAlphaExposuresColumns,
    preferenceKey: ts.enums.PREFERENCES_KEY_ENUM.BACKTEST_PREVIEW_ALPHA_EXPOSURES_COLS,
    sheetsConfig: [
      {
        title: ts.enums.REPORT_ENUMS.TABLE_SHEET_PREF_NAME_ENUM.MAIN,
        key: ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.MAIN,
        height: 400,
      },
    ],
  },
  [ts.enums.PREFERENCES_INNER_TABS_ENUM.BACKTEST_SUMMARY_AND_DETAILS_COLS]: {
    columns: columns.backtestTabularOutputColumns,
    preferenceKey: ts.enums.PREFERENCES_KEY_ENUM.BACKTEST_TABULAR_COLS,
    sheetsConfig: [
      {
        title: ts.enums.REPORT_ENUMS.TABLE_SHEET_PREF_NAME_ENUM.BACKTEST_TABULAR_YEARLY_SUMMARY,
        key: ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.BACKTEST_TABULAR_YEARLY_SUMMARY,
        height: 400,
      },
      {
        title: ts.enums.REPORT_ENUMS.TABLE_SHEET_PREF_NAME_ENUM.BACKTEST_TABULAR_MONTHLY_SUMMARY,
        key: ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.BACKTEST_TABULAR_MONTHLY_SUMMARY,
        height: 400,
      },
      {
        title: ts.enums.REPORT_ENUMS.TABLE_SHEET_PREF_NAME_ENUM.BACKTEST_TABULAR_DETAILS,
        key: ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.BACKTEST_TABULAR_DETAILS,
        height: 580,
      },
      {
        title: ts.enums.REPORT_ENUMS.TABLE_SHEET_PREF_NAME_ENUM.BACKTEST_TABULAR_ALPHA_DETAILS,
        key: ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.BACKTEST_TABULAR_ALPHA_DETAILS,
        height: 580,
      },
    ],
  },
  [ts.enums.PREFERENCES_INNER_TABS_ENUM.BACKTEST_PORTFOLIO_RISK_COLS]: {
    columns: columns.backtestPortfolioRiskColumns,
    preferenceKey: ts.enums.PREFERENCES_KEY_ENUM.BACKTEST_PORTFOLIO_RISK_COLS,
    sheetsConfig: [
      {
        title: ts.enums.REPORT_ENUMS.TABLE_SHEET_PREF_NAME_ENUM.BACKTEST_PORTFOLIO_RISK_FACTOR_CONTRIBUTION,
        key: ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.BACKTEST_PORTFOLIO_RISK_FACTOR_CONTRIBUTION,
        height: 400,
      },
      {
        title: ts.enums.REPORT_ENUMS.TABLE_SHEET_PREF_NAME_ENUM.BACKTEST_PORTFOLIO_RISK_SECURITY_CONTRIBUTION,
        key: ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.BACKTEST_PORTFOLIO_RISK_SECURITY_CONTRIBUTION,
        height: 400,
      },
    ],
  },
  [ts.enums.PREFERENCES_INNER_TABS_ENUM.BACKTEST_PERFORMANCE_ATTRIBUTION_CONTRIBUTOR_COLS]: {
    columns: columns.performanceAttributionContributorColumns,
    preferenceKey: ts.enums.PREFERENCES_KEY_ENUM.BACKTEST_PERFORMANCE_ATTRIBUTION_CONTRIBUTOR_COLUMNS,
    sheetsConfig: [
      {
        title: ts.enums.REPORT_ENUMS.TABLE_SHEET_PREF_NAME_ENUM.MAIN,
        key: ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.MAIN,
        height: 400,
      },
    ],
  },

  // BASKET
  [ts.enums.PREFERENCES_INNER_TABS_ENUM.BASKET_CONSTRAINTS_COLS]: {
    columns: columns.basketConstraintsColumns,
    preferenceKey: ts.enums.PREFERENCES_KEY_ENUM.BASKET_CONSTRAINTS_COLS,
    sheetsConfig: [
      {
        title: ts.enums.REPORT_ENUMS.TABLE_SHEET_PREF_NAME_ENUM.MAIN,
        key: ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.MAIN,
        height: 400,
      },
    ],
  },
  [ts.enums.PREFERENCES_INNER_TABS_ENUM.BASKET_PREVIEW_COLS]: {
    columns: columns.basketPreviewColumns,
    preferenceKey: ts.enums.PREFERENCES_KEY_ENUM.BASKET_PREVIEW_COLS,
    sheetsConfig: [
      {
        title: ts.enums.REPORT_ENUMS.TABLE_SHEET_PREF_NAME_ENUM.MAIN,
        key: ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.MAIN,
        height: 400,
      },
    ],
  },
  [ts.enums.PREFERENCES_INNER_TABS_ENUM.BASKET_DATE_COMPARISON]: {
    columns: columns.basketDatesComparisonColumns,
    preferenceKey: ts.enums.PREFERENCES_KEY_ENUM.BASKET_DATE_COMPARISON_COLS,
    sheetsConfig: [
      {
        title: ts.enums.REPORT_ENUMS.TABLE_SHEET_PREF_NAME_ENUM.MAIN,
        key: ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.MAIN,
        height: 400,
      },
    ],
  },
  [ts.enums.PREFERENCES_INNER_TABS_ENUM.BASKET_SUMMARY_AND_DETAILS_COLS]: {
    columns: columns.basketTabularOutputColumns,
    preferenceKey: ts.enums.PREFERENCES_KEY_ENUM.BASKET_TABULAR_COLS,
    sheetsConfig: [
      {
        title: ts.enums.REPORT_ENUMS.TABLE_SHEET_PREF_NAME_ENUM.BASKET_TABULAR_YEARLY_SUMMARY,
        key: ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.BASKET_TABULAR_YEARLY_SUMMARY,
        height: 400,
      },
      {
        title: ts.enums.REPORT_ENUMS.TABLE_SHEET_PREF_NAME_ENUM.BASKET_TABULAR_MONTHLY_SUMMARY,
        key: ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.BASKET_TABULAR_MONTHLY_SUMMARY,
        height: 400,
      },
      {
        title: ts.enums.REPORT_ENUMS.TABLE_SHEET_PREF_NAME_ENUM.BASKET_TABULAR_DETAILS,
        key: ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.BASKET_TABULAR_DETAILS,
        height: 580,
      },
    ],
  },
  [ts.enums.PREFERENCES_INNER_TABS_ENUM.BASKET_PORTFOLIO_RISK_COLS]: {
    columns: columns.backtestPortfolioRiskColumns,
    preferenceKey: ts.enums.PREFERENCES_KEY_ENUM.BASKET_PORTFOLIO_RISK_COLS,
    sheetsConfig: [
      {
        title: ts.enums.REPORT_ENUMS.TABLE_SHEET_PREF_NAME_ENUM.BASKET_PORTFOLIO_RISK_FACTOR_CONTRIBUTION,
        key: ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.BASKET_PORTFOLIO_RISK_FACTOR_CONTRIBUTION,
        height: 400,
      },
      {
        title: ts.enums.REPORT_ENUMS.TABLE_SHEET_PREF_NAME_ENUM.BASKET_PORTFOLIO_RISK_SECURITY_CONTRIBUTION,
        key: ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.BASKET_PORTFOLIO_RISK_SECURITY_CONTRIBUTION,
        height: 400,
      },
    ],
  },
  [ts.enums.PREFERENCES_INNER_TABS_ENUM.BASKET_PERFORMANCE_ATTRIBUTION_CONTRIBUTOR_COLS]: {
    columns: columns.performanceAttributionContributorColumns,
    preferenceKey: ts.enums.PREFERENCES_KEY_ENUM.BASKET_PERFORMANCE_ATTRIBUTION_CONTRIBUTOR_COLUMNS,
    sheetsConfig: [
      {
        title: ts.enums.REPORT_ENUMS.TABLE_SHEET_PREF_NAME_ENUM.MAIN,
        key: ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.MAIN,
        height: 400,
      },
    ],
  },

  // BASKET COMPARE

  [ts.enums.PREFERENCES_INNER_TABS_ENUM.BASKET_COMPARE_SUMMARY_STATS_COLS]: {
    columns: columns.basketSummaryStatsReportColumns,
    preferenceKey: ts.enums.PREFERENCES_KEY_ENUM.BASKET_COMPARE_SUMMARY_STATS_COLS,
    sheetsConfig: [
      {
        title: ts.enums.REPORT_ENUMS.TABLE_SHEET_PREF_NAME_ENUM.BACKTEST_SET_SUMMARY,
        key: ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.SUMMARY,
        height: 400,
      },
    ],
  },

  // PORTFOLIOS
  [ts.enums.PREFERENCES_INNER_TABS_ENUM.PORTFOLIOS_HOLDINGS_COLS]: {
    columns: columns.portfoliosInitialHoldingsColumns,
    preferenceKey: ts.enums.PREFERENCES_KEY_ENUM.PORTFOLIOS_HOLDINGS_COLS,
    sheetsConfig: [
      {
        title: ts.enums.REPORT_ENUMS.TABLE_SHEET_PREF_NAME_ENUM.MAIN,
        key: ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.MAIN,
        height: 400,
      },
    ],
  },
  [ts.enums.PREFERENCES_INNER_TABS_ENUM.PORTFOLIO_SUMMARY_AND_DETAILS_COLS]: {
    columns: columns.portfolioTabularOutputColumns,
    preferenceKey: ts.enums.PREFERENCES_KEY_ENUM.PORTFOLIO_TABULAR_COLS,
    sheetsConfig: [
      {
        title: ts.enums.REPORT_ENUMS.TABLE_SHEET_PREF_NAME_ENUM.PORTFOLIO_TABULAR_YEARLY_SUMMARY,
        key: ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.PORTFOLIO_TABULAR_YEARLY_SUMMARY,
        height: 400,
      },
      {
        title: ts.enums.REPORT_ENUMS.TABLE_SHEET_PREF_NAME_ENUM.PORTFOLIO_TABULAR_MONTHLY_SUMMARY,
        key: ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.PORTFOLIO_TABULAR_MONTHLY_SUMMARY,
        height: 400,
      },
      {
        title: ts.enums.REPORT_ENUMS.TABLE_SHEET_PREF_NAME_ENUM.PORTFOLIO_TABULAR_DETAILS,
        key: ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.PORTFOLIO_TABULAR_DETAILS,
        height: 580,
      },
    ],
  },
  [ts.enums.PREFERENCES_INNER_TABS_ENUM.PORTFOLIO_TRADE_LIST_COLS]: {
    columns: columns.portfolioTradeListColumns,
    preferenceKey: ts.enums.PREFERENCES_KEY_ENUM.PORTFOLIO_TRADE_LIST_COLS,
    sheetsConfig: [
      {
        title: ts.enums.REPORT_ENUMS.TABLE_SHEET_PREF_NAME_ENUM.MAIN,
        key: ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.MAIN,
        height: 400,
      },
    ],
  },
  [ts.enums.PREFERENCES_INNER_TABS_ENUM.PORTFOLIO_TRADE_LIST_SUMMARY_COLS]: {
    columns: columns.portfolioTradeListSummaryColumns,
    preferenceKey: ts.enums.PREFERENCES_KEY_ENUM.PORTFOLIO_TRADE_LIST_SUMMARY_COLS,
    sheetsConfig: [
      {
        title: ts.enums.REPORT_ENUMS.TABLE_SHEET_PREF_NAME_ENUM.PORTFOLIO_TRADE_LIST_SUMMARY_REBALANCE,
        key: 'rebalance',
        height: 400,
      },
      {
        title: ts.enums.REPORT_ENUMS.TABLE_SHEET_PREF_NAME_ENUM.PORTFOLIO_TRADE_LIST_SUMMARY_TRADE,
        key: 'trade',
        height: 400,
      },
    ],
  },
  [ts.enums.PREFERENCES_INNER_TABS_ENUM.PORTFOLIO_TRADE_LIST_BUCKET_EXPOSURE_COLS]: {
    columns: columns.portfolioTradeListBucketExposureColumns,
    preferenceKey: ts.enums.PREFERENCES_KEY_ENUM.PORTFOLIO_TRADE_LIST_BUCKET_EXPOSURE_COLS,
    sheetsConfig: [
      {
        title: ts.enums.REPORT_ENUMS.TABLE_SHEET_PREF_NAME_ENUM.EXPOSURE,
        key: ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.EXPOSURE,
        height: 400,
      },
    ],
  },
  [ts.enums.PREFERENCES_INNER_TABS_ENUM.PORTFOLIO_TRADE_LIST_HOLDINGS_COLS]: {
    columns: columns.portfolioTradelistHoldingsColumns,
    preferenceKey: ts.enums.PREFERENCES_KEY_ENUM.PORTFOLIO_TRADE_LIST_HOLDINGS_COLS,
    sheetsConfig: [
      {
        title: ts.enums.REPORT_ENUMS.TABLE_SHEET_PREF_NAME_ENUM.MAIN,
        key: ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.MAIN,
        height: 400,
      },
    ],
  },
  [ts.enums.PREFERENCES_INNER_TABS_ENUM.PORTFOLIO_TRADE_LIST_FACTOR_EXPOSURE_COLS]: {
    columns: columns.portfolioTradeListFactorExposureColumns,
    preferenceKey: ts.enums.PREFERENCES_KEY_ENUM.PORTFOLIO_TRADE_LIST_FACTOR_EXPOSURE_COLS,
    sheetsConfig: [
      {
        title: ts.enums.REPORT_ENUMS.TABLE_SHEET_PREF_NAME_ENUM.PORTFOLIO_TRADE_LIST_FACTOR_EXPOSURE_MODEL,
        key: 'model',
        height: 400,
      },
      {
        title: ts.enums.REPORT_ENUMS.TABLE_SHEET_PREF_NAME_ENUM.PORTFOLIO_TRADE_LIST_FACTOR_EXPOSURE_RISK,
        key: 'risk',
        height: 400,
      },
      {
        title: ts.enums.REPORT_ENUMS.TABLE_SHEET_PREF_NAME_ENUM.PORTFOLIO_TRADE_LIST_FACTOR_EXPOSURE_USER,
        key: 'factor',
        height: 400,
      },
    ],
  },
  [ts.enums.PREFERENCES_INNER_TABS_ENUM.PORTFOLIO_CONSTRAINTS_COLS]: {
    columns: columns.portfolioConstraintsColumns,
    preferenceKey: ts.enums.PREFERENCES_KEY_ENUM.PORTFOLIO_CONSTRAINTS_COLS,
    sheetsConfig: [
      {
        title: ts.enums.REPORT_ENUMS.TABLE_SHEET_PREF_NAME_ENUM.MAIN,
        key: ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.MAIN,
        height: 400,
      },
    ],
  },

  // RISK MODELS

  [ts.enums.PREFERENCES_INNER_TABS_ENUM.RISK_MODEL_PREVIEW_COLS]: {
    columns: columns.riskModelPreviewColumns,
    preferenceKey: ts.enums.PREFERENCES_KEY_ENUM.RISK_MODEL_SNAPSHOT_COLS,
    sheetsConfig: [
      {
        title: ts.enums.REPORT_ENUMS.TABLE_SHEET_PREF_NAME_ENUM.MAIN,
        key: ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.MAIN,
        height: 400,
      },
    ],
  },
  [ts.enums.PREFERENCES_INNER_TABS_ENUM.RISK_MODEL_ASSET_PERTINENCE_PREVIEW_COLS]: {
    columns: columns.riskModelAssetPertinencePreviewColumns,
    preferenceKey: ts.enums.PREFERENCES_KEY_ENUM.RISK_MODEL_ASSET_PERTINENCE_COLS,
    sheetsConfig: [
      {
        title: ts.enums.REPORT_ENUMS.TABLE_SHEET_PREF_NAME_ENUM.MAIN,
        key: ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.MAIN,
        height: 400,
      },
    ],
  },

  // SIGNALS

  [ts.enums.PREFERENCES_INNER_TABS_ENUM.SIGNAL_PREVIEW_COLS]: {
    columns: columns.signalPreviewColumns,
    preferenceKey: ts.enums.PREFERENCES_KEY_ENUM.SIGNAL_PREVIEW_COLS,
    sheetsConfig: [
      {
        title: ts.enums.REPORT_ENUMS.TABLE_SHEET_PREF_NAME_ENUM.MAIN,
        key: ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.MAIN,
        height: 400,
      },
    ],
  },
  [ts.enums.PREFERENCES_INNER_TABS_ENUM.SIGNAL_DATE_COMPARISON_COLS]: {
    columns: columns.signalDatesComparisonColumns,
    preferenceKey: ts.enums.PREFERENCES_KEY_ENUM.SIGNAL_DATE_COMPARISON_COLS,
    sheetsConfig: [
      {
        title: ts.enums.REPORT_ENUMS.TABLE_SHEET_PREF_NAME_ENUM.MAIN,
        key: ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.MAIN,
        height: 400,
      },
    ],
  },

  // SIGNAL SETS

  [ts.enums.PREFERENCES_INNER_TABS_ENUM.SIGNAL_SET_SUMMARY_STATS_COLS]: {
    columns: columns.signalSetSummaryStatsColumns,
    preferenceKey: ts.enums.PREFERENCES_KEY_ENUM.SIGNAL_SET_SUMMARY_STATS_COLS,
    sheetsConfig: [
      {
        title: ts.enums.REPORT_ENUMS.TABLE_SHEET_PREF_NAME_ENUM.BACKTEST_SET_SUMMARY,
        key: ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.SUMMARY,
        height: 400,
      },
    ],
  },

  // TIME SERIES

  [ts.enums.PREFERENCES_INNER_TABS_ENUM.TIME_SERIES_PREVIEW_COLS]: {
    columns: columns.timeSeriesPreviewColumns,
    preferenceKey: ts.enums.PREFERENCES_KEY_ENUM.TIME_SERIES_PREVIEW_COLS,
    sheetsConfig: [
      {
        title: ts.enums.REPORT_ENUMS.TABLE_SHEET_PREF_NAME_ENUM.MAIN,
        key: ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.MAIN,
        height: 400,
      },
    ],
  },
  [ts.enums.PREFERENCES_INNER_TABS_ENUM.TIME_SERIES_COMPARE_SUMMARY_STATS_COLS]: {
    columns: columns.timeSeriesSummaryStatsReportColumns,
    preferenceKey: ts.enums.PREFERENCES_KEY_ENUM.TIME_SERIES_COMPARE_SUMMARY_STATS_COLS,
    sheetsConfig: [
      {
        title: ts.enums.REPORT_ENUMS.TABLE_SHEET_PREF_NAME_ENUM.BACKTEST_SET_SUMMARY,
        key: ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.SUMMARY,
        height: 400,
      },
    ],
  },

  // UNIVERSE

  [ts.enums.PREFERENCES_INNER_TABS_ENUM.UNI_ASSET_PERTINENCE_COLS]: {
    columns: columns.universePreviewColumns,
    preferenceKey: ts.enums.PREFERENCES_KEY_ENUM.UNIVERSE_PERTINENC_COLS,
    sheetsConfig: [
      {
        title: ts.enums.REPORT_ENUMS.TABLE_SHEET_PREF_NAME_ENUM.MAIN,
        key: ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.MAIN,
        height: 400,
      },
    ],
  },
  [ts.enums.PREFERENCES_INNER_TABS_ENUM.UNI_DATE_COMPARISON_COLS]: {
    columns: columns.universePreviewColumns,
    preferenceKey: ts.enums.PREFERENCES_KEY_ENUM.UNIVERSE_COMPARISON_COLS,
    sheetsConfig: [
      {
        title: ts.enums.REPORT_ENUMS.TABLE_SHEET_PREF_NAME_ENUM.MAIN,
        key: ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.MAIN,
        height: 400,
      },
    ],
  },
  [ts.enums.PREFERENCES_INNER_TABS_ENUM.UNI_SNAPSHOT_COLS]: {
    columns: columns.universePreviewColumns,
    preferenceKey: ts.enums.PREFERENCES_KEY_ENUM.UNIVERSE_SNAPSHOT_COLS,
    sheetsConfig: [
      {
        title: ts.enums.REPORT_ENUMS.TABLE_SHEET_PREF_NAME_ENUM.MAIN,
        key: ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.MAIN,
        height: 400,
      },
    ],
  },
  [ts.enums.PREFERENCES_INNER_TABS_ENUM.UNI_LINKED_ASSETS_COLS]: {
    columns: columns.universeLinkedAssetsColumns,
    preferenceKey: ts.enums.PREFERENCES_KEY_ENUM.UNIVERSE_LINKED_ASSETS_COLS,
    sheetsConfig: [
      {
        title: ts.enums.REPORT_ENUMS.TABLE_SHEET_PREF_NAME_ENUM.MAIN,
        key: ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.MAIN,
        height: 400,
      },
    ],
  },
} as Record<
  ts.enums.PREFERENCES_INNER_TABS_ENUM,
  {
    columns: Record<ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM, ts.types.components.dataGrid.ColumnsData>;
    preferenceKey: ts.enums.PREFERENCES_KEY_ENUM;
    sheetsConfig: SheetConfig;
  }
>;

export default columnsSelectConfig;
