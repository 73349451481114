/* eslint-disable no-unused-vars */
import { ts } from '_core';

export enum INTERACTION_PARAMS {
  WINDOWS = 'windows',
}

export type JobParams = {
  weights: ts.enums.REPORT_ENUMS.WEIGHTS_ENUM;
  gross_leverage: number;
};

export type Params = JobParams;
export type DraftParams = Partial<Params>;
