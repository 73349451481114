import { React, ts } from '_core';

import Chart from './chart';
import Form from './form';
import { DraftParams, Params } from './types';

type TimeSeriesValueHistogramProps = {
  id: string;
  readOnly: boolean;
  widgetKey: string;
  mode: ts.enums.CHART_MODE_ENUM;
  params: Params | DraftParams;
  setParams: (_p: DraftParams) => void;
  title: string;

  subtitle?: string;
  setExternalEmbed: (_json: ts.types.analysis.EmbedChartData) => void;

  fullScreen?: boolean;
  loadWidgetData: (_payload: ts.types.widgets.WidgetGetDataParams) => Promise<ts.types.widgets.WidgetGetDataResponse>;
  widgetPaths?: Record<'time_series_histogram', string>;
  context?: ts.types.timeSeries.TimeSeriesAnalysisContext;
};

const TimeSeriesValueHistogram: React.FC<TimeSeriesValueHistogramProps> = ({
  id,
  readOnly,
  loadWidgetData,
  widgetPaths,
  mode,
  params,
  setParams,
  fullScreen,
  title,
  context,
  subtitle,
  setExternalEmbed,
}) => {
  const renderJobComponent = () => (
    <>
      <Chart
        id={id}
        readOnly={readOnly}
        loadWidgetData={loadWidgetData}
        file={widgetPaths['time_series_histogram']}
        params={params as Params}
        fullScreen={fullScreen}
        title={title}
        subtitle={subtitle}
        setExternalEmbed={setExternalEmbed}
      />
    </>
  );

  return (
    <>{mode == 'edit' ? <Form params={params} setParams={setParams} context={context} /> : renderJobComponent()}</>
  );
};

export default TimeSeriesValueHistogram;
