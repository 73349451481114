import { _, helpers, ts } from '_core';

import { DraftParams } from './types';
import { getBucketsTitle } from '../../common/utils/get-name';
import { getBucketName } from '../../common/utils/get-series-name';

export const prepareBucketedEventStudyData = (
  data: ts.types.widgets.ErrorLinearData | ts.types.widgets.NestedBarData | ts.types.widgets.HeatMapData,
  tab: 'error' | 'tstat' | 'count',
  buckets: ts.types.widgets.common.BucketValue,
  resources: ts.StoreState['resources']
): ts.types.widgets.ErrorLinearData | ts.types.widgets.NestedBarData | ts.types.widgets.HeatMapData => {
  if (tab == 'error') {
    return Object.fromEntries(
      Object.entries(data).map(([key, val]) => [
        getBucketName(key as string, buckets.type, buckets.value, resources),
        val,
      ])
    );
  }

  if (tab == 'tstat') {
    return (data as ts.types.widgets.HeatMapData).map((d) => ({
      days_from_event: d['days_from_event'],
      value: d['tstat'] || 0,
      category: getBucketName(d['category'] as string, buckets.type, buckets.value, resources),
    }));
  }

  if (tab == 'count') {
    return (data as ts.types.widgets.HeatMapData).map((d) => {
      const bucketKeys = _.without(Object.keys(d), 'category').sort();
      return Object.fromEntries([
        ['category', d.category],
        ...bucketKeys.map((b) => [getBucketName(b as string, buckets.type, buckets.value, resources), d[b]]),
      ] as any);
    });
  }

  return data;
};

export const validate = (params: DraftParams) => {
  if (_.isNil(params.lower_bound) && _.isNil(params.upper_bound))
    return ['Lower Bound or Upper Bound is a required field.'];
  if (!_.isNil(params.lower_bound) && !_.isNil(params.upper_bound))
    if (params.lower_bound > params.upper_bound) return ['Lower Bound must be less than Upper Bound.'];
  if (params.days_before_event < 1 || params.days_before_event > 21)
    return ['Days Before Event must be between 1 and 21.'];
  if (params.days_after_event < 0 || params.days_after_event > 63)
    return ['Days After Event must be between 0 and 63.'];
  if (!helpers.validations.validBuckets(params.buckets)) return ['Buckets are invalid.'];
  return [];
};

export const getName = (
  params: DraftParams,
  _context: ts.types.signal.SignalAnalysisContext,
  resources: ts.StoreState['resources']
) => {
  const init = getBucketsTitle(params.buckets, 'Bucketed Event Study', resources);
  return `${init}`;
};
