import { ts } from '_core';

import { validate } from './helpers';
import { DraftParams, INTERACTION_PARAMS } from './types';

export default [
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.RISK_MODEL_PREVIEW_SNAPSHOT,
    form: true,
    title: 'Snapshot',
    neededFiles: ['risk_model_preview'],
    interactionParams: Object.values(INTERACTION_PARAMS),
    clearInteractionOnRegenerate: ['table_dtypes'],
    defaultParams: { type: 'show_all' } as DraftParams,
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.RISK_MODEL_PREVIEW],

    validate,
    helpLabel: 'f35jypqy',
  },
] satisfies ts.types.analysis.WidgetConfig[];
