import React from 'react';

import { countries } from '../../../data';
import { _, mui } from '../../../libs';
import * as types from '../../../types';
import CheckboxesAutocomplete from '../../checkboxes-autocomplete';

type countryFilterType = {
  code: string;
  label: string;
};

type SearchFormProps = {
  params: types.assetSelector.AssetParams;
  setParams: (_def: types.assetSelector.AssetParams) => void;
  customCountries?: string[];
  openModal?: boolean;
};

/**
 * Component that renders a search form for filtering assets by name, identifier and country
 *
 * @param params - The current search parameters
 * @param setParams - Callback to update search parameters
 * @param customCountries - Optional list of allowed country codes to filter by
 * @param openModal - Whether the modal is currently open
 *
 * @returns Search form with text input, identifier selector and country filter
 */
const AssetSelectorSearchForm: React.FC<SearchFormProps> = ({
  params,
  setParams,
  customCountries,
  openModal,
}): React.ReactElement => {
  const theme = mui.styles.useTheme() as mui.core.Theme;
  const searchField = React.createRef<HTMLDivElement>();

  const countryFilter: countryFilterType[] = React.useMemo(() => {
    const mappedCountries = _.map(countries, (c) => ({
      ...c,
      label: `${c.name} (${c.code})`,
    }));

    if (!_.isEmpty(customCountries)) {
      return _.filter(mappedCountries, (cObj) => customCountries.includes(cObj.code));
    }
    return mappedCountries;
  }, [customCountries]);

  React.useEffect(() => {
    if (openModal) {
      setTimeout(() => {
        searchField.current.focus();
      }, 100);
    }
  }, [openModal]);

  const labelName = React.useMemo(() => {
    if (!params.identifier) return 'Search for Name, Ticker, Cusip, ISIN or Factset ID';
    if (params.identifier == 'fid') return 'Search for Factset ID';
    return `Search for ${_.startCase(params.identifier)}`;
  }, [params.identifier]);

  return (
    <mui.core.Grid container spacing={2}>
      <mui.core.Grid item md={5}>
        <mui.core.TextField
          variant="outlined"
          size="small"
          label={labelName}
          id="search_term"
          inputRef={searchField}
          value={params.search_text}
          onChange={(e) => setParams({ ...params, search_text: e.target.value })}
          sx={{ paddingRight: '5px' }}
          placeholder="e.g., AAPL, MSFT, GOOGL, AMZN"
          fullWidth
          InputProps={{
            startAdornment: (
              <mui.core.InputAdornment position="start">
                <mui.icons.Search
                  style={{
                    fontSize: '1rem',
                    color: (theme.palette as any).text.secondary,
                  }}
                />
              </mui.core.InputAdornment>
            ),
          }}
        />
      </mui.core.Grid>
      <mui.core.Grid item md={2}>
        <mui.core.TextField
          select
          label="Identifier"
          variant="outlined"
          size="small"
          value={params.identifier || 'all'}
          onChange={(e) => {
            if (e.target.value !== 'all')
              setParams({
                ...params,
                identifier: e.target.value as types.assetSelector.AssetParams['identifier'],
              });
            else setParams({ ...params, identifier: undefined });
          }}
          fullWidth
        >
          <mui.core.MenuItem value="all">All</mui.core.MenuItem>
          <mui.core.MenuItem value="ticker">Ticker</mui.core.MenuItem>
          <mui.core.MenuItem value="isin">Isin</mui.core.MenuItem>
          <mui.core.MenuItem value="cusip">Cusip</mui.core.MenuItem>
          <mui.core.MenuItem value="fid">Facset ID</mui.core.MenuItem>
        </mui.core.TextField>
      </mui.core.Grid>

      <mui.core.Grid item md={5}>
        <CheckboxesAutocomplete<string>
          value={params?.quotation_countries}
          setValue={(val) => {
            setParams({ ...params, quotation_countries: val });
          }}
          options={countryFilter}
          label={'Quotation Country'}
          objKey={'code'}
          groupByKey="continent"
          chipKey={'label'}
          optionLabel={(o) => o?.label as string}
          chipColor={'#e9eaed'}
        />
      </mui.core.Grid>
    </mui.core.Grid>
  );
};

export default AssetSelectorSearchForm;
