import { ts } from '_core';

import { INTERACTION_PARAMS } from './types';

export default [
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.PORTFOLIO_TABULAR_OUTPUT,
    title: 'Tabular Summary and Details',
    interactionParams: Object.values(INTERACTION_PARAMS),
    clearInteractionOnRegenerate: ['table_dtypes'],
    form: false,
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.PORTFOLIO],
    neededFiles: ['portfolio_report', 'portfolio_holdings_alpha_exposures'],

    disableAdd: true,
    helpLabel: 'llkcj91v',
  },
] satisfies ts.types.analysis.WidgetConfig[];
