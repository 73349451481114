import { ts } from '_core';

import { getName, validate } from './helpers';
import { DraftParams } from './types';

export default [
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.TIME_SERIES_REGIME,
    title: 'Time Series Regime Analysis',
    helpLabel: '3dpswsez',
    form: true,
    defaultParams: {
      format_for_ui: true,
      quantiles: 5,
      discrete: false,
      histories: ['1 Year', '3 Years', '5 Years', 'Full'],
      show_unknown: false,
    } as DraftParams,
    neededFiles: ['time_series_regime_analysis'],
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.TIME_SERIES],

    getName,
    validate,
  },
] satisfies ts.types.analysis.WidgetConfig[];
