import { _, mui, React, ts, ui, uitheme } from '_core';

import { ManagerContext } from './manager-context';
import ManagerSaveAsDefault from './manager-save-as-default';
import { filterByTags } from '../report/widgets/common/utils/report-widgets';

const ManagerHeader = (): React.ReactElement => {
  const theme = mui.styles.useTheme() as mui.core.Theme;
  const REPORT_BAR_HEIGHT = uitheme.layoutSize.REPORT_BAR_HEIGHT;
  const managerContext = React.useContext(ManagerContext);

  const {
    addCard,
    addOptions,
    noLayoutChanges,
    loadDefault,
    tags,
    widgetConf,
    noDefault,
    defaults,
    mode,
    setMode,
    managerState,
    setManagerState,
    handleSave,
    saving,
    maxWidgetsPerPageError,
    cards,
    formErrors,
    onClose,
    onlyFullscreen,
    errorMessage,
    validating,
    context,
    needsJob,
    isAdhoc,
    maxWidgetsPerPage,
  } = managerContext;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [layoutConfirmation, setLayoutConfirmation] = React.useState(false);
  const [autocompleteText, setAutocompleteText] = React.useState('');

  const getSelectedDefault = (): ts.types.analysis.ReportDefault => {
    const currentWidgets = cards.map((card) => card.id);

    let selectedDefault = null;
    defaults.forEach((def) => {
      const defaultWidgets = def.definition?.widgets.map((w) => w.id);

      if (_.isEqual(currentWidgets, defaultWidgets)) selectedDefault = def;
    });

    return selectedDefault;
  };

  const [layoutDef, setLayoutDef] = React.useState<ts.types.analysis.ReportDefault>();

  React.useEffect(() => {
    setLayoutDef(getSelectedDefault());
  }, [defaults, cards.length]);

  const handleLoadDefault = (def: ts.types.analysis.ReportDefault) => {
    setLayoutDef(def);
    const widgets = ((def.definition?.widgets as ts.types.analysis.Widget[]) || [])
      .filter((c) => widgetConf(c))
      .map((c) => ({ ...c, ...widgetConf(c) }))
      .map((c) => ({ ...c, params: { ...c.defaultParams, ...c.params } }));

    const filtered = filterByTags(tags, widgets);
    // If we are removing widgets on copy, let's bring the alert
    if (filtered.length !== widgets.length) {
      setLayoutConfirmation(true);
      setAnchorEl(null);
    } else {
      loadDefault(def);
      setAnchorEl(null);
    }
  };

  const widgetsToRemoveAmount = React.useMemo(
    () => cards.length - maxWidgetsPerPage,
    [cards.length, maxWidgetsPerPage]
  );

  const countCards = React.useMemo(() => {
    const count = {} as Record<string, number>;
    cards.forEach((c) => {
      count[c.key] = (count[c.key] || 0) + 1;
    });
    return count;
  }, [cards]);

  const handleModeChange = (event: any, newMode: ts.enums.ANALYSIS_MODE_ENUM) => {
    if (newMode !== null) {
      setMode(newMode);
    }
  };

  const handleManagerStateChange = (event: any, newManagerState: ts.enums.REPORT_ENUMS.MANAGER_STATE_ENUM) => {
    if (newManagerState !== null) {
      setManagerState(newManagerState);
    }
  };

  return (
    <mui.core.Card
      sx={{
        zIndex: 2,
        position: 'sticky',
        top: '0',
      }}
    >
      <mui.core.Grid
        container
        sx={{
          alignItems: 'stretch',
          justifyContent: 'space-between',
          minHeight: REPORT_BAR_HEIGHT,
          padding: '0.5rem',
          [theme.breakpoints.down('sm')]: {
            display: 'block',
          },
          '& > div': {
            padding: '0 6px',
            display: 'flex',
            alignItems: 'center',
            [theme.breakpoints.down('sm')]: {
              marginBottom: '1rem',
            },
          },
        }}
      >
        <ui.ConfirmationDialog
          open={layoutConfirmation}
          title={'Are you sure?'}
          content={
            'The selected layout has some widgets that are not applicable to this kind of resource. ' +
            "Those widgets won't be copied."
          }
          onSuccess={() => {
            loadDefault(layoutDef);
            setLayoutConfirmation(false);
          }}
          onCancel={() => setLayoutConfirmation(false)}
          successLabel="Confirm"
        />

        {!noLayoutChanges && (
          <>
            <mui.core.Grid item xs>
              <mui.core.Stack flexDirection="row" gap={3} width="100%">
                <mui.core.Box flex="1" style={{ maxWidth: 450 }}>
                  <mui.core.Autocomplete<ts.types.analysis.WidgetConfig, false>
                    value={null}
                    inputValue={autocompleteText}
                    style={{ flex: '1' }}
                    options={addOptions}
                    renderOption={(props, option) => (
                      <li {...props}>
                        <mui.core.Box display="flex" alignItems="center" width="100%">
                          <mui.core.Typography variant="body2" style={{ flex: 1 }}>
                            {option.subtitle ? `${option.title} - ${option.subtitle}` : option.title}
                          </mui.core.Typography>
                          <mui.core.Typography
                            variant="caption"
                            fontWeight="fontWeightMedium"
                            sx={{
                              background: theme.palette.gray.main,
                              padding: '2px',
                              textAlign: 'center',
                              marginLeft: '6px',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              borderRadius: '2px',
                              color: theme.palette.textLight.main,
                            }}
                          >
                            {countCards[option.key] || 0}
                          </mui.core.Typography>
                        </mui.core.Box>
                      </li>
                    )}
                    getOptionLabel={(w) => {
                      const wC = w as ts.types.analysis.WidgetConfig;
                      return wC.subtitle ? `${wC.title} - ${wC.subtitle}` : wC.title;
                    }}
                    disableCloseOnSelect
                    onChange={(e, val) => {
                      if (val) {
                        setAutocompleteText('');
                        addCard(val as ts.types.analysis.WidgetConfig);
                      }
                    }}
                    renderInput={(params) => (
                      <mui.core.TextField
                        {...params}
                        fullWidth
                        size="small"
                        variant="outlined"
                        onChange={(event) => {
                          setAutocompleteText(event.target.value);
                        }}
                        label={isAdhoc ? 'Add Metric' : 'Add Widget'}
                      />
                    )}
                  />
                </mui.core.Box>
                {!onlyFullscreen && !!cards.length && !isAdhoc && (
                  <mui.core.ToggleButtonGroup value={mode} exclusive onChange={handleModeChange}>
                    <mui.core.ToggleButton color="primary" value={ts.enums.ANALYSIS_MODE_ENUM.GRID} size="small">
                      <mui.core.Tooltip arrow title="Grid View">
                        <mui.icons.ViewModule />
                      </mui.core.Tooltip>
                    </mui.core.ToggleButton>
                    <mui.core.ToggleButton color="primary" value={ts.enums.ANALYSIS_MODE_ENUM.FULLSCREEN} size="small">
                      <mui.core.Tooltip arrow title="Tabs View">
                        <mui.icons.ViewList />
                      </mui.core.Tooltip>
                    </mui.core.ToggleButton>
                  </mui.core.ToggleButtonGroup>
                )}

                {!noLayoutChanges && (
                  <mui.core.ToggleButtonGroup value={managerState} exclusive onChange={handleManagerStateChange}>
                    <mui.core.ToggleButton
                      color="primary"
                      value={ts.enums.REPORT_ENUMS.MANAGER_STATE_ENUM.EXPANDED}
                      size="small"
                    >
                      Edit Params
                    </mui.core.ToggleButton>
                    <mui.core.ToggleButton
                      color="primary"
                      value={ts.enums.REPORT_ENUMS.MANAGER_STATE_ENUM.COLLAPSED}
                      size="small"
                    >
                      Edit Order
                    </mui.core.ToggleButton>
                  </mui.core.ToggleButtonGroup>
                )}
              </mui.core.Stack>
            </mui.core.Grid>
          </>
        )}
        <mui.core.Stack gap={2} direction="row">
          {maxWidgetsPerPageError && widgetsToRemoveAmount >= 1 && (
            <mui.core.Chip
              size="small"
              color="error"
              icon={<mui.icons.ErrorOutline />}
              label={`Max ${maxWidgetsPerPage} widgets. Remove ${widgetsToRemoveAmount}.`}
            />
          )}
          {!_.isEmpty(formErrors) && !formErrors.invalid && !maxWidgetsPerPageError && (
            <mui.core.Chip size="small" color="error" icon={<mui.icons.ErrorOutline />} label={errorMessage} />
          )}

          {!saving && !validating && context != null && needsJob && (
            <ui.Button
              disabled={validating || saving || !_.isEmpty(formErrors) || maxWidgetsPerPageError}
              loading={saving || validating}
              onClick={() => handleSave(true)}
              variant="contained"
              color="primary"
              progressText={validating ? 'Validating' : 'Saving'}
            >
              Save and Run
            </ui.Button>
          )}

          <ui.Button
            disabled={validating || saving || !_.isEmpty(formErrors) || maxWidgetsPerPageError}
            loading={saving || validating}
            onClick={() => handleSave(false)}
            variant="outlined"
            color="primary"
            progressText={validating ? 'Validating' : 'Saving'}
          >
            Save
          </ui.Button>
          {
            /* Restore to default */
            !noLayoutChanges && !noDefault && !_.isEmpty(defaults) && (
              <>
                <mui.core.Button
                  aria-controls="restore-menu"
                  aria-haspopup="true"
                  onClick={(e) => setAnchorEl(e.currentTarget)}
                  color="primary"
                  variant="outlined"
                >
                  Restore to
                  <mui.icons.ArrowDropDown />
                </mui.core.Button>
                <mui.core.Menu
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={() => setAnchorEl(null)}
                >
                  {defaults.map((def) => (
                    <mui.core.MenuItem
                      key={def.id}
                      onClick={() => handleLoadDefault(def)}
                      selected={layoutDef?.id == def.id}
                      color="primary"
                    >
                      {def.title}
                    </mui.core.MenuItem>
                  ))}
                </mui.core.Menu>
              </>
            )
          }
          {
            /* Save as default */
            !noDefault && <ManagerSaveAsDefault />
          }
        </mui.core.Stack>

        <mui.core.Grid item>
          {onClose && (
            <mui.core.Box>
              <mui.core.IconButton
                disabled={saving}
                onClick={onClose}
                style={{ color: theme.palette.text.secondary }}
                size="small"
              >
                <mui.icons.Close />
              </mui.core.IconButton>
            </mui.core.Box>
          )}
        </mui.core.Grid>
      </mui.core.Grid>
    </mui.core.Card>
  );
};

export default ManagerHeader;
