import { Provider, TypedUseSelectorHook, useDispatch, useSelector as baseUseSelector } from 'react-redux';
import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';

import { BaseStore } from '../../types';

/**
 * Creates a typed useSelector hook for a specific store type
 *
 * @template T The type of your Redux store state
 * @returns A useSelector hook typed to your store state
 * @example
 * // In your app:
 * export const useSelector = createTypedSelector<RootState>();
 */
export function createTypedSelector<T>() {
  return baseUseSelector as TypedUseSelectorHook<T>;
}

/**
 * @internal
 * This hook is only meant to be used inside the finsera-core package.
 * For external usage, use createTypedSelector to create a typed selector for your store.
 */
export const useSelector = baseUseSelector as TypedUseSelectorHook<BaseStore>;

export type { TypedUseSelectorHook } from 'react-redux';
export { combineReducers } from 'redux';
export { applyMiddleware, compose, createStore, Provider, thunk, useDispatch };
