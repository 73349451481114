import { React, ts } from '_core';

import Chart from './chart';
import Form from './form';
import { DraftParams, Params } from './types';
import ContextForm from '../../common/forms/signal-set-context-form';

type SummaryStatisticsProps = {
  id: string;
  readOnly: boolean;
  widgetKey: string;
  mode: ts.enums.CHART_MODE_ENUM;
  params: Params | DraftParams;
  setParams: (_p: DraftParams) => void;
  title: string;
  subtitle?: string;
  context: ts.types.signalSet.SignalSetAnalysisContext | ts.types.analysis.GeneralAnalysisContext;
  isGeneralAnalysis?: boolean;
  widgetPaths?: Record<'signal_set_annualized_statistics', string>;
  loadWidgetData?: (_payload: ts.types.widgets.WidgetGetDataParams) => Promise<ts.types.widgets.WidgetGetDataResponse>;
  setExternalEmbed: (_json: ts.types.analysis.EmbedChartData) => void;
};

const SummaryStatistics: React.FC<SummaryStatisticsProps> = ({
  id,
  readOnly,
  widgetKey,
  mode,
  params,
  setParams,
  context,
  isGeneralAnalysis,
  title,
  subtitle,
  widgetPaths,
  loadWidgetData,
  setExternalEmbed,
}) => {
  const [localContext, setLocalContext] = React.useState(context as ts.types.signalSet.SignalSetAnalysisContext);

  const file = React.useMemo(() => {
    if (!widgetPaths?.['signal_set_annualized_statistics']) return null;
    if (widgetPaths['signal_set_annualized_statistics'].includes('zip')) {
      return widgetPaths['signal_set_annualized_statistics'].split('.tsv')[0] + '_summary.tsv.zip';
    }
    return widgetPaths['signal_set_annualized_statistics'].split('.tsv')[0] + '_summary.tsv';
  }, [widgetPaths]);

  const renderJobComponent = () => {
    if (!localContext?.signal_set_id) return <></>;
    return (
      <>
        <Chart
          id={id}
          readOnly={readOnly}
          widgetKey={widgetKey}
          title={title}
          subtitle={subtitle}
          context={localContext as ts.types.signalSet.SignalSetAnalysisContext}
          loadWidgetData={loadWidgetData}
          // Adding summary due to the signal set job file structure
          file={file}
          params={params as Params}
          setParams={setParams}
          setExternalEmbed={setExternalEmbed}
        />
      </>
    );
  };

  return (
    <>
      <ContextForm
        params={params}
        setParams={setParams}
        setExternalContext={setLocalContext}
        mode={mode}
        isGeneralAnalysis={isGeneralAnalysis}
        originalContext={context}
      />
      {mode == 'edit' ? (
        <Form
          params={params}
          setParams={setParams}
          context={localContext as ts.types.signalSet.SignalSetAnalysisContext}
        />
      ) : (
        renderJobComponent()
      )}
    </>
  );
};

export default SummaryStatistics;
