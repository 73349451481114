import { _, React, ts, useSelector } from '_core';

import { Params } from './types';
import ChartWrapper from '../../common/chart-wrapper';
import createLineChart from '../../common/charts/create-line-chart';
import { prepareLinearBucketedNames } from '../../common/utils/get-series-name';

type ChartProps = {
  id: string;
  readOnly: boolean;
  widgetKey: string;
  loadWidgetData: (_payload: ts.types.widgets.WidgetGetDataParams) => Promise<ts.types.widgets.WidgetGetDataResponse>;
  returns: string;
  fullScreen: boolean;
  params: Params;
  title: string;
};

const Chart: React.FC<ChartProps> = ({
  id,
  readOnly,
  widgetKey,
  loadWidgetData,
  returns,
  fullScreen,
  params,
  title,
}) => {
  const [data, setData] = React.useState<ts.types.widgets.TableData>(null);
  const [error, setError] = React.useState<ts.types.common.ApiError>(null);
  const [showLegend, setShowLegend] = React.useState(false);
  const resources = useSelector((state) => state.resources);

  const loadData = async () => {
    try {
      const response = await loadWidgetData({
        data: {
          file: returns,
        },
      });
      if (response) setData(response.data);
    } catch (err) {
      setError(err as ts.types.common.ApiError);
    }
  };

  React.useEffect(() => {
    if (returns && params.scale) loadData();
    return () => setData(null);
  }, [returns, params.scale]);

  React.useEffect(() => {
    // Create line chart on data and scale changes
    if (!_.isNil(data))
      createLineChart({
        id,
        scale: params.scale,
        data,
        fullScreen,
        setShowLegend,
        aggregation: 'outlier',
        exportTitle: title,
        prepareData: (linearData) =>
          prepareLinearBucketedNames(linearData, params.buckets.type, params.buckets.value, resources),
      });
  }, [data]);

  return (
    <ChartWrapper
      id={id}
      readOnly={readOnly}
      widgetKey={widgetKey}
      data={data}
      showLegend={showLegend}
      error={error}
      fullScreen={fullScreen}
    />
  );
};

export default Chart;
