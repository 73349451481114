import { api, ts } from '_core';

export const signDownloadS3Path = async (info: ts.types.components.dataGrid.ResponseDownloadFullData) => {
  const path = info.path;
  const s3Filename = info.file_name;

  // Generate the download link to trigger download
  const s3FileObject = {} as Record<string, string>;
  const jobId = parseInt(path.split('/')[2]);
  s3FileObject[jobId] = s3Filename;
  const signed = await api.s3.signPaths(s3FileObject, jobId);
  let url;
  if (jobId) url = signed.data['jobs'][jobId][jobId];
  else url = signed.data['jobs'][jobId];

  return url;
};
