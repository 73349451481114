import { ts } from '_core';

import { validate } from './helpers';
import { DraftParams, INTERACTION_PARAMS } from './types';

export default [
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.REBALANCE_PORTFOLIO_FACTOR_EXPOSURE_SUMMARY,
    form: true,
    title: 'Factor Exposure Summary',
    defaultParams: {
      buckets: [{ type: ts.enums.BUCKET_TYPES_ENUM.RISK_MODEL_FACTORS, value: 5 }],
    } as DraftParams,
    interactionParams: Object.values(INTERACTION_PARAMS),
    clearInteractionOnRegenerate: ['table_dtypes'],
    neededFiles: ['portfolio_alpha_factor_exposure', 'portfolio_list_user_factor_exposure'],
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.REBALANCE_PORTFOLIO_HOLDINGS],

    validate,
    helpLabel: 'qylufd5k',
  },
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.PORTFOLIO_FACTOR_EXPOSURE_SUMMARY,
    form: true,
    title: 'Factor Exposure Summary',
    defaultParams: {
      buckets: [{ type: ts.enums.BUCKET_TYPES_ENUM.RISK_MODEL_FACTORS, value: 5 }],
    } as DraftParams,
    interactionParams: Object.values(INTERACTION_PARAMS),
    clearInteractionOnRegenerate: ['table_dtypes'],
    neededFiles: ['portfolio_alpha_factor_exposure_holdings', 'portfolio_list_user_factor_exposure_holdings'],
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.PORTFOLIO_HOLDINGS_ANALYSIS],

    validate,
    helpLabel: 'ih60g77j',
  },
] satisfies ts.types.analysis.WidgetConfig[];
