import { BaseStore } from '../../../types/base-store';
import { Resource } from '../../../types/common';
import { SignalExpanded } from '../../../types/signal';
import { Signal } from '../../../types/signal';

const getResourceWords = (id: number, resources: Resource[]) => {
  const resource = resources.find((r) => r.id == id);
  if (resources) return [resource?.name, resource?.handle];
  return [];
};

const expandSignal = (
  signal: Signal & { resourceType: string },
  resources: BaseStore['resources']
): SignalExpanded => {
  const expanded = [];
  expanded.push(signal.definition?.source);

  if (signal.signal_ids) {
    (signal.signal_ids || []).forEach((sId) => {
      expanded.push(getResourceWords(sId, resources.signals));
    });
  }

  if (signal.universe_ids) {
    (signal.universe_ids || []).forEach((uId) => {
      expanded.push(getResourceWords(uId, resources.universes));
    });
  }

  if (signal.pipeline_ids) {
    (signal.pipeline_ids || []).forEach((pId) => {
      expanded.push(getResourceWords(pId, resources.pipelines));
    });
  }

  return {
    id: signal.id,
    name: signal.name,
    handle: signal.handle,
    resourceType: signal.resourceType,
    created_by_id: signal.created_by_id,
    label_ids: signal.label_ids,
    type: signal.type,
    definition: expanded.flat(),
    user_provided: signal.user_provided,
    is_published: signal.is_published,
    is_deprecated: signal.is_deprecated,
    revoked: signal.revoked || false,
    is_valid: signal.is_valid,
    theme: signal.theme,
    created_at: signal.created_at,
    source_resource_id: signal.source_resource_id,
    organization_id: signal.organization_id,
    has_description: signal.has_description,
    short_description: signal.short_description,
    source_org_id: signal.source_org_id,
    shared: signal.shared,
  } as SignalExpanded;
};

export default expandSignal;
