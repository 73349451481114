import { ts } from '_core';

import { DraftParams, INTERACTION_PARAMS } from './types';

export default [
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.COVERAGE_BY_RBICS_LVL_THREE,
    form: false,
    title: 'Coverage by Industry',
    neededFiles: ['rbics_l3_id_code'],
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.UNIVERSE],
    defaultParams: {
      by: 'rbics_l3_id_code',
      parent: 'levelTwo',
      children: 'levelThree',
      chartType: 'top',
    } as unknown as DraftParams,
    interactionParams: Object.values(INTERACTION_PARAMS),

    helpLabel: '4f2eekaa',
  },
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.COVERAGE_BY_RBICS_LVL_FOUR,
    form: false,
    title: 'Coverage by Sub Industry',
    neededFiles: ['rbics_l4_id_code'],
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.UNIVERSE],
    defaultParams: {
      by: 'rbics_l4_id_code',
      parent: 'levelThree',
      children: 'levelFour',
      chartType: 'top',
    } as unknown as DraftParams,
    interactionParams: Object.values(INTERACTION_PARAMS),

    helpLabel: 'da90b6km',
  },
] satisfies ts.types.analysis.WidgetConfig[];
