import { mui, React, ui } from '_core';

type ApiDetailsProps = {
  label: string;
  url: string;
};

const ApiDetails: React.FC<ApiDetailsProps> = ({ label, url }): React.ReactElement => {
  const [loading, setLoading] = React.useState(true);

  return (
    <mui.core.Box
      sx={{
        marginTop: '1rem',
        '& .info': {
          display: 'none',
        },
      }}
    >
      <mui.core.Typography variant="h5" style={{ marginLeft: '1rem' }}>
        {label}
      </mui.core.Typography>
      {loading && <ui.CenteredLoader top="10vh" />}
      <ui.Swagger url={url} onComplete={() => setLoading(false)} />
    </mui.core.Box>
  );
};

export default ApiDetails;
