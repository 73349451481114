import Dropzone, { Accept } from 'react-dropzone';

import { mui } from '../libs';

type DragDropFilesProps<T extends boolean> = {
  setFile: (_file: T extends true ? File[] : File) => void;
  multiple?: T;
  subtitleText?: string;
  acceptedFileTypes?: Accept;
};

/**
 * DragDropFiles component allows users to drag and drop files or click to select files.
 *
 * @template T - A boolean type that determines if multiple files can be uploaded.
 * @param {Object} props - The properties for the component.
 * @param {function} props.setFile - A function to set the uploaded file(s).
 * @param {boolean} [props.multiple] - Indicates if multiple files can be uploaded. Defaults to false.
 * @param {string} [props.subtitleText] - Custom subtitle text to display in the dropzone.
 * @param {Accept} [props.acceptedFileTypes] - Specifies the accepted file types for the dropzone.
 * @returns {React.ReactElement} The rendered component.
 */
const DragDropFiles = <T extends boolean = false>({
  setFile,
  multiple,
  subtitleText,
  acceptedFileTypes,
}: DragDropFilesProps<T>): React.ReactElement => {
  const subtitle = subtitleText ?? `Drag and drop ${multiple ? 'your files' : 'a file'} here `;

  return (
    <Dropzone
      accept={acceptedFileTypes}
      multiple={multiple}
      onDrop={(acceptedFiles) => setFile(multiple ? acceptedFiles : (acceptedFiles[0] as any))}
    >
      {({ getRootProps, getInputProps }) => (
        <mui.core.Box
          sx={{
            border: '1px dashed #ddd',
            borderRadius: 1,
            display: 'block',
            textAlign: 'center',
            cursor: 'pointer',
            '&:hover': {
              background: '#f5f5f5',
            },
          }}
          p={5}
        >
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            <mui.core.Box mb={1}>
              <mui.icons.CloudUpload color="primary" />
            </mui.core.Box>
            <mui.core.Box>
              <mui.core.Typography>
                {subtitle}
                <mui.core.Typography color="primary" sx={{ display: 'inline-block' }}>
                  or click to select.
                </mui.core.Typography>
              </mui.core.Typography>
            </mui.core.Box>
          </div>
        </mui.core.Box>
      )}
    </Dropzone>
  );
};

DragDropFiles.defaultProps = {
  acceptedFileTypes: { 'text/csv': ['.csv'] },
  multiple: false,
};

export default DragDropFiles;
