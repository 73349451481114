/* eslint-disable no-unused-vars */
import { ts } from '_core';

import { PartialDeep } from 'type-fest';

export enum INTERACTION_PARAMS {
  IS_GROSS = 'is_gross',
  IS_BASKET = 'is_basket', // This is a ui field to handle the difference between basket and signal exposure
}

export type InteractionParams = {
  [INTERACTION_PARAMS.IS_GROSS]: boolean;
  [INTERACTION_PARAMS.IS_BASKET]?: true;
};

export type JobParams = ts.types.pipeline.Neutralize & { detailed_output?: boolean };

export type Params = JobParams & InteractionParams;
export type DraftParams = PartialDeep<Params>;
