import { _ } from '@local/finsera-core/src/libs';
import * as types from '@local/finsera-core/src/types';

export const argsToStringifiable = (args: Record<string, any>): types.analysis.StringifiableArgs => {
  return {
    args: Object.keys(args)
      .filter((key) => typeof args[key] !== 'function')
      .filter((key) => !_.isNil(args[key]))
      .map((key) => {
        const value = args[key];
        const type = typeof value;
        const transformedValue = value;
        return {
          key: key,
          value: transformedValue,
          type: type,
        };
      }),
  };
};

export const stringifiableToArgs = (argswrapper: types.analysis.StringifiableArgs) => {
  const obj = {} as Record<string, any>;
  argswrapper.args.forEach((item) => {
    const { key, value } = item;
    obj[key] = value;
  });

  return obj;
};

export const storeChartData = ({
  chart_type,
  args,
}: {
  chart_type: types.analysis.EmbedChartType;
  args: Record<string, any>;
}) => {
  const args_ready = argsToStringifiable(args);
  return JSON.stringify({ chart_type, args: args_ready }, null, 2);
};

export const getChartData = (stringData: string) => {
  const { id, args, chart_type, widgetTitle, widgetSubTitle } = JSON.parse(stringData) as {
    id: string;
    widgetTitle: string;
    widgetSubTitle: string;
    chart_type: types.analysis.EmbedChartType;
    args: types.analysis.StringifiableArgs;
  };

  return {
    id,
    widgetTitle,
    widgetSubTitle,
    args: stringifiableToArgs(args),
    chart_type,
  } as types.analysis.EmbedChartData;
};
