import { _, am4charts, am4core, helpers, ts } from '_core';

import { createImageHeader } from './create-image-header';
import { createSelectAllButton } from './create-select-all-button';
import { lineChartFormat } from './formatters/line-chart';
import * as utils from './utils';

const MAX_NUMBER_OF_DATAPOINTS = 250;

type createMultiScaleLineChartTypes = {
  id: string;
  data: ts.types.widgets.TableData;
  fullScreen: boolean;
  exportTitle: string;

  exportSubTitle?: string;
  setShowLegend: (_b: boolean) => void;
  percentLeft?: boolean;
  percentRight?: boolean;
  yAxis?: string;
  xAxis?: string;
  xIsDate?: boolean;
  title?: string;
  aggregation?: 'open' | 'close' | 'outlier' | 'min' | 'max';
  inSameScale?: string[];

  prepareData?: (_d: ts.types.widgets.LinearData) => ts.types.widgets.LinearData;
  seriesOrder?: (_c: string[]) => string[];
  customDownloadFormatting?: (_d: Record<string, string | number>[]) => Record<string, string | number>[];
  setExternalEmbed?: (_json: ts.types.analysis.EmbedChartData) => void;

  // If data is already sent processed
  widgetIsEmbedded?: {
    download: boolean;
  };
};

const EMPTY_ARRAY: any[] = [];

const createMultiScaleLineChart = (args: createMultiScaleLineChartTypes) => {
  const {
    id,
    data,
    fullScreen,
    setShowLegend,
    exportTitle,

    exportSubTitle = null,
    percentLeft = false,
    percentRight = false,
    xAxis = 'date',
    yAxis = 'value',
    xIsDate = true,
    title = null,
    aggregation = 'close', // open, close, outlier, min, max
    // This will only be applied on left scaling
    inSameScale = EMPTY_ARRAY,

    seriesOrder = (c) => c,
    customDownloadFormatting = undefined,
    prepareData = undefined,
    setExternalEmbed = null,

    widgetIsEmbedded = undefined,
  }: createMultiScaleLineChartTypes = args;
  let linearData = data as any as ts.types.widgets.LinearData;

  if (!widgetIsEmbedded) {
    // Format table as line chart
    linearData = lineChartFormat(data as ts.types.widgets.TableData);

    // Prepare data
    if (prepareData) linearData = prepareData(linearData);

    // Add Real value
    linearData = utils.addReal(linearData, yAxis as keyof ts.types.widgets.LinearData[string][0]);

    // Apply sort by
    const entries = Object.entries(linearData ?? {});
    const sortedKeys = seriesOrder(entries.map(([key, _v]) => key));
    linearData = _(sortedKeys)
      .map((key) => entries.find(([k, _v]) => key == k))
      .fromPairs()
      .value();
  }

  const rawData = linearData;

  if (!fullScreen)
    linearData = utils.groupData(
      linearData,
      aggregation,
      yAxis as keyof ts.types.widgets.LinearData[string][0],
      MAX_NUMBER_OF_DATAPOINTS
    );

  const chart = am4core.create(`chart-${id}-${fullScreen ? 'fs' : 'sw'}`, am4charts.XYChart);
  chart.paddingRight = 20;
  if (setExternalEmbed)
    setExternalEmbed({
      id,
      widgetTitle: exportTitle,
      widgetSubTitle: exportSubTitle,
      chart_type: 'multiscale-line-chart',
      args: helpers.embed.argsToStringifiable({
        ...args,
        data: linearData,
      }),
    });

  const diff = utils.getLinearChartLimitsDiff(linearData);

  if (title) {
    const chartTitle = chart.titles.create();
    chartTitle.text = title;
    chartTitle.fontSize = 15;
    chartTitle.marginBottom = 20;
  }

  let valueAxisX = null;
  if (xIsDate) {
    valueAxisX = chart.xAxes.push(new am4charts.DateAxis());
    valueAxisX.baseInterval = { timeUnit: 'day', count: 1 };
    valueAxisX.tooltipDateFormat = 'MMM dd yyyy';
    valueAxisX.renderer.labels.template.adapter.add('text', function (text) {
      return text && text.replace(' ', '\n');
    });
    valueAxisX.renderer.labels.template.textAlign = 'middle';
  } else valueAxisX = chart.xAxes.push(new am4charts.ValueAxis());
  valueAxisX.renderer.grid.template.location = 0;
  valueAxisX.tooltip.fontSize = 11;
  valueAxisX.tooltip.background.fill = am4core.color('#3C5688');
  valueAxisX.tooltip.background.cornerRadius = 3;
  valueAxisX.tooltip.background.strokeWidth = 0;
  if (!fullScreen) valueAxisX.renderer.minGridDistance = 40;

  const createAxisAndSeries = (keys: string[], index: number, opposite: boolean) => {
    const valueAxisY = chart.yAxes.push(new am4charts.ValueAxis());
    if (index != 0) {
      valueAxisY.syncWithAxis = chart.yAxes.getIndex(0) as any;
    }
    valueAxisY.tooltip.disabled = true;
    valueAxisY.renderer.minWidth = 35;

    // Create series
    keys.forEach((key, localIdx) => {
      const seriesName = key;
      const series = chart.series.push(new am4charts.LineSeries());
      series.calculatePercent = true;
      series.name = seriesName;

      if (xIsDate) series.dataFields.dateX = xAxis;
      else series.dataFields.valueX = xAxis;

      series.dataFields.valueY = yAxis;
      series.yAxis = valueAxisY;

      series.tooltipText = '{name}: {real}'; // This is aggregated
      series.tooltip.fontSize = 11;
      series.tooltip.background.fill = am4core.color('#3C5688');
      series.tooltip.background.cornerRadius = 3;
      series.tooltip.background.strokeWidth = 0;
      series.fill = am4core.color(helpers.getColor(index + localIdx));
      series.stroke = am4core.color(helpers.getColor(index + localIdx));
      series.data = linearData[key];

      // Set the number formatter based on the opposite parameter
      const isPercent = opposite ? percentRight : percentLeft;
      const numberFormatter = new am4core.NumberFormatter();
      if (isPercent) {
        numberFormatter.numberFormat = '#.##%';
      } else {
        numberFormatter.numberFormat = '#.##';
        if (diff <= 1e-5 && !(diff == 0)) {
          numberFormatter.numberFormat = '#.##e';
        }
      }
      valueAxisY.numberFormatter = numberFormatter;

      if (!(index == 0 && keys.length > 1)) {
        valueAxisY.renderer.line.strokeOpacity = 1;
        valueAxisY.renderer.line.strokeWidth = 1;
        valueAxisY.renderer.line.stroke = series.stroke;
        valueAxisY.renderer.labels.template.fill = series.stroke;
        valueAxisY.renderer.opposite = opposite;
        valueAxisY.renderer.grid.template.strokeWidth = 0.2;
      }
    });
  };

  const ordered = seriesOrder(Object.keys(linearData));
  const onLeft = _.isEmpty(inSameScale) ? [ordered[0]] : inSameScale.filter((key) => ordered.includes(key));
  const onRight = ordered.filter((key) => !onLeft.includes(key));

  // Add series on right
  onRight.forEach((key, index) => {
    createAxisAndSeries([key], index + onLeft.length, true);
  });

  // Add series on left (same scale)
  createAxisAndSeries(onLeft, 0, false);

  chart.logo.disabled = true;

  chart.cursor = new am4charts.XYCursor();
  chart.cursor.maxTooltipDistance = 0;

  chart.exporting.menu = new am4core.ExportMenu();
  chart.exporting.menu.align = 'left';
  chart.fontSize = 11;

  chart.exporting.menu.items = widgetIsEmbedded
    ? widgetIsEmbedded.download
      ? [
          {
            label: '...',
            menu: [{ type: 'csv', label: 'Download as CSV' }],
          },
        ]
      : []
    : [
        {
          label: '...',
          menu: [
            { type: 'jpg', label: 'JPG' },
            { type: 'png', label: 'PNG' },
            { type: 'csv', label: 'CSV' },
          ],
        },
      ];
  chart.exporting.filePrefix = 'chart-data';

  let showLegendLocal = false;

  if (chart.series.values.length <= 7 || fullScreen) {
    showLegendLocal = true;
    setShowLegend(true);
  } else {
    setShowLegend(false);
  }

  function resizeLegend() {
    if (document.getElementById(`legend-${id}-${fullScreen ? 'fs' : 'sw'}`))
      document.getElementById(`legend-${id}-${fullScreen ? 'fs' : 'sw'}`).style.height =
        chart.legend.contentHeight + 10 + 'px';
  }

  if (showLegendLocal) {
    const legendContainer = am4core.create(`legend-${id}-${fullScreen ? 'fs' : 'sw'}`, am4core.Container);
    legendContainer.width = am4core.percent(100);
    legendContainer.height = am4core.percent(100);
    legendContainer.logo.disabled = true;

    chart.legend = new am4charts.Legend();
    chart.legend.parent = legendContainer;

    chart.legend.maxHeight = fullScreen ? 100 : 44;
    chart.legend.scrollable = true;
    chart.legend.itemContainers.template.paddingTop = 1;
    chart.legend.itemContainers.template.paddingBottom = 1;
    chart.legend.markers.template.width = 10;
    chart.legend.markers.template.height = 10;
    chart.legend.markers.template.strokeWidth = 10;
    chart.legend.fontSize = 11;

    if (chart.series.values.length > 2) {
      createSelectAllButton(chart);
    }

    chart.events.on('datavalidated', resizeLegend);
    chart.events.on('maxsizechanged', resizeLegend);
    chart.legend.events.on('datavalidated', resizeLegend);
    chart.legend.events.on('maxsizechanged', resizeLegend);
    document.getElementById(`legend-${id}-${fullScreen ? 'fs' : 'sw'}`).style.height =
      chart?.legend?.contentHeight?.toString();

    // Add legend to the chart.
    chart.exporting.extraSprites.push(chart.legend);
  } else {
    document.getElementById(`legend-${id}-${fullScreen ? 'fs' : 'sw'}`).innerHTML = '';
  }

  // Assemble data from series
  chart.exporting.adapter.add('data', () => {
    const exportData = [] as { date: string; [key: string]: any }[];
    chart.series.each((series) => {
      for (let i = 0; i < rawData[series.name].length; i++) {
        const row = { ...rawData[series.name][i], name: series.name } as {
          date: string;
          [key: string]: any;
        };
        exportData.push(row);
      }
    });

    // Date - serie1 - serie2 ... - serieX as csv headers
    const grouped = _.groupBy(exportData, 'date');
    const localData = Object.keys(grouped).map((date) => {
      const el = grouped[date];
      const localObj = { date } as { date: string } & Record<string, number>;
      el.forEach((serieEl) => (localObj[serieEl.name] = serieEl.real));
      return localObj;
    });

    if (customDownloadFormatting) return { data: customDownloadFormatting(localData) };

    return { data: localData };
  });

  createImageHeader({ chart, title: exportTitle, fullScreen, id });
  return chart;
};

export default createMultiScaleLineChart;
