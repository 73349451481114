import { endpoint } from './config';
import { $ } from '../libs';

export const create = (formData: FormData) =>
  $.ajax({
    method: 'POST',
    url: `${endpoint}feedback`,
    data: formData,
    processData: false,
    contentType: false,
  } as JQueryAjaxSettings);
