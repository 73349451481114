import { _, ts } from '_core';

const daysBetweenRebalances = {
  [ts.enums.FREQUENCY_ENUM.DAILY]: 1,
  [ts.enums.FREQUENCY_ENUM.WEEKLY]: 5,
  [ts.enums.FREQUENCY_ENUM.MONTHLY]: 21,
  [ts.enums.FREQUENCY_ENUM.QUARTERLY]: 3 * 21,
  [ts.enums.FREQUENCY_ENUM.SEMIANNUAL]: 6 * 21,
  [ts.enums.FREQUENCY_ENUM.ANNUAL]: 12 * 21,
};

export const getRbicsName = (code: string, rbicsData: ts.types.industries.RBicsData) => {
  const levelOneCode = code.substring(0, 2);
  const levelTwoCode = code.substring(0, 4);
  const levelThreeCode = code.substring(0, 6);
  const levelFourCode = code;

  const rbics_l1 = rbicsData.levelOne.find((g) => g.value == levelOneCode)?.name;
  const rbics_l2 = rbicsData.levelTwo.find((g) => g.value == levelTwoCode)?.name;
  const rbics_l3 = rbicsData.levelThree.find((g) => g.value == levelThreeCode)?.name;
  const rbics_l4 = rbicsData.levelFour.find((g) => g.value == levelFourCode)?.name;

  return {
    levelOne: rbics_l1,
    levelTwo: rbics_l2,
    levelThree: rbics_l3,
    levelFour: rbics_l4,
  };
};

export const getFrequencyDays = (frequency: ts.enums.FREQUENCY_ENUM) => daysBetweenRebalances[frequency];

export const getDaysFrequency = (frequency: number) => {
  switch (frequency) {
    case 1:
      return ts.enums.FREQUENCY_ENUM.DAILY;
    case 5:
      return ts.enums.FREQUENCY_ENUM.WEEKLY;
    case 21:
      return ts.enums.FREQUENCY_ENUM.MONTHLY;
    case 3 * 21:
      return ts.enums.FREQUENCY_ENUM.QUARTERLY;
    case 6 * 21:
      return ts.enums.FREQUENCY_ENUM.SEMIANNUAL;
    case 12 * 21:
    default:
      return ts.enums.FREQUENCY_ENUM.ANNUAL;
  }
};

export const renameIndustryInclusions = (
  inclusions: ts.types.riskModel.CategoryFactor['inclusions'],
  factorHandle: string,
  rbicsData: ts.types.industries.RBicsData
) => {
  return (inclusions ?? []).map((el: ts.types.riskModel.CategoryFactorInclusions) => {
    const stringIndustry = el['value'].toString();
    const rbicsNames = getRbicsName(stringIndustry, rbicsData);
    return {
      ...rbicsNames,
      industry_handle: factorHandle,
      value: el['value'],
      item: 'rbics_l4_id',
      table_handle: 'industry',
    } as ts.types.riskModel.ExtendedCategoryFactorInclusions;
  });
};

const renameIndustryFactorDef = (
  factors: ts.types.riskModel.CategoryFactor[],
  rbicsData: ts.types.industries.RBicsData
) => {
  let renamedInclusions = [] as ts.types.riskModel.ExtendedCategoryFactorInclusions[];
  factors?.forEach((factor) => {
    renamedInclusions = renamedInclusions.concat(renameIndustryInclusions(factor.inclusions, factor.handle, rbicsData));
  });
  return renamedInclusions;
};

export const renameIndustryFactors = (
  factors: ts.types.riskModel.CategoryFactor[],
  rbicsData: ts.types.industries.RBicsData
) => {
  const emptyFactors = factors.filter((f) => _.isEmpty(f.inclusions));

  const renamedInclusions = renameIndustryFactorDef(factors, rbicsData);
  const groupedFactors = _.groupBy(renamedInclusions, 'industry_handle');

  const orderedFactors = Object.values(groupedFactors) as ts.types.riskModel.ExtendedCategoryFactorInclusions[][];

  const res = orderedFactors.map((el: ts.types.riskModel.ExtendedCategoryFactorInclusions[]) => {
    const currentFactor = factors.find((factor) => factor.handle == el[0].industry_handle);

    return {
      ...currentFactor,
      inclusions: [...el],
    };
  });

  return [...emptyFactors, ...res] as ts.types.riskModel.ExtendedCategoryFactor[];
};
