import { ts } from '_core';

import { DraftParams } from './types';

export default [
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.SIGNAL_TURNOVER,
    title: 'Turnover',
    helpLabel: 't1v3panf',
    form: true,
    defaultParams: {
      is_total: true,
      format_for_ui: true,
    } as DraftParams,
    neededFiles: ['signal_turnover'],
    tags: [
      ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.SIGNAL,
      ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.ALPHA_MODEL,
      ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.COMMON_ALPHA_MODEL,
    ],
  },
] satisfies ts.types.analysis.WidgetConfig[];
