import { ts } from '_core';

import { validate } from './helpers';
import { DraftParams, FRACTIONAL_RISK_ENUM, RISK_METRIC_ENUM, VARIABLES_FILTER_ENUM } from './types';

export default [
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.BACKTEST_ATTRIBUTION_HOLDINGS,
    title: 'Holdings Decomposition',
    helpLabel: '1aou8y1q',
    form: true,
    defaultParams: {
      attribution_type: 'holdings',
      name: 'holdings',
      histories: '$context_histories',
      buckets: { type: ts.enums.BUCKET_TYPES_ENUM.QUANTILES, value: 5 },
      variables: { type: ts.enums.EXPLANATORY_VARIABLE_TYPES_ENUM.RISK_FACTORS },
      variable_filter: VARIABLES_FILTER_ENUM.NONE,
    } as DraftParams,
    neededFiles: ['backtest_portan_chart'],
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.BACKTEST_PORTAN],

    validate,
    subtitle: 'Heat Map',
  },
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.BACKTEST_ATTRIBUTION_RETURNS,
    title: 'Returns Decomposition',
    helpLabel: 'qylc6940',
    form: true,
    defaultParams: {
      attribution_type: 'returns',
      histories: '$context_histories',
      buckets: { type: ts.enums.BUCKET_TYPES_ENUM.QUANTILES, value: 5 },
      variables: { type: ts.enums.EXPLANATORY_VARIABLE_TYPES_ENUM.RISK_FACTORS },
      variable_filter: VARIABLES_FILTER_ENUM.NONE,
    } as DraftParams,
    neededFiles: ['backtest_portan_chart'],
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.BACKTEST_PORTAN],

    validate,
    subtitle: 'Heat Map',
  },
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.BACKTEST_ATTRIBUTION_RISK_DECOMPOSITION,
    title: 'Risk Decomposition',
    helpLabel: 'mc46b2o8',
    form: true,
    defaultParams: {
      attribution_type: 'risk',
      histories: '$context_histories',
      buckets: { type: ts.enums.BUCKET_TYPES_ENUM.QUANTILES, value: 5 },
      variables: { type: ts.enums.EXPLANATORY_VARIABLE_TYPES_ENUM.RISK_FACTORS },
      variable_filter: VARIABLES_FILTER_ENUM.NONE,
      risk_metric: RISK_METRIC_ENUM.TOTAL,
      fractional_risk: FRACTIONAL_RISK_ENUM.FRACTION,
    } as DraftParams,
    neededFiles: ['backtest_portan_chart'],
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.BACKTEST_PORTAN],

    validate,
    subtitle: 'Heat Map',
  },
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.PORTFOLIO_ATTRIBUTION_HOLDINGS,
    title: 'Holdings Decomposition',
    helpLabel: 'x39827yb',
    form: true,
    defaultParams: {
      attribution_type: 'holdings',
      histories: '$context_histories',
      variables: { type: ts.enums.EXPLANATORY_VARIABLE_TYPES_ENUM.RISK_FACTORS },
      variable_filter: VARIABLES_FILTER_ENUM.NONE,
    } as DraftParams,
    neededFiles: ['portfolio_portan_chart'],
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.PORTFOLIO_ATTRIBUTION],

    validate,
    subtitle: 'Heat Map',
  },
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.PORTFOLIO_ATTRIBUTION_RETURNS,
    title: 'Returns Decomposition',
    form: true,
    defaultParams: {
      attribution_type: 'returns',
      histories: '$context_histories',
      variables: { type: ts.enums.EXPLANATORY_VARIABLE_TYPES_ENUM.RISK_FACTORS },
      variable_filter: VARIABLES_FILTER_ENUM.NONE,
    } as DraftParams,
    neededFiles: ['portfolio_portan_chart'],
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.PORTFOLIO_ATTRIBUTION],

    validate,
    subtitle: 'Heat Map',
    helpLabel: 'wa4ptt6v',
  },
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.PORTFOLIO_ATTRIBUTION_RISK_DECOMPOSITION,
    title: 'Risk Decomposition',
    form: true,
    defaultParams: {
      attribution_type: 'risk',
      histories: '$context_histories',
      variables: { type: ts.enums.EXPLANATORY_VARIABLE_TYPES_ENUM.RISK_FACTORS },
      variable_filter: VARIABLES_FILTER_ENUM.NONE,
      risk_metric: RISK_METRIC_ENUM.TOTAL,
      fractional_risk: FRACTIONAL_RISK_ENUM.FRACTION,
    } as DraftParams,
    neededFiles: ['portfolio_portan_chart'],
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.PORTFOLIO_ATTRIBUTION],

    validate,
    subtitle: 'Heat Map',
    helpLabel: 'kd0kq851',
  },
] satisfies ts.types.analysis.WidgetConfig[];
