import React from 'react';

import { getInputFilters } from './config';
import ResourceContextMenu from './resource-context-menu';
import * as enums from '../../enums';
import { resourcesSearch } from '../../helpers';
import { _, mui, useSelector } from '../../libs';
import * as types from '../../types';
import ConditionalTooltip from '../conditional-tooltip';
import Filters from '../guided-search/filters';
import { Filter } from '../guided-search/search-types';

type ValueType<Multiple, ChooseHandle> = ChooseHandle extends false | undefined
  ? Multiple extends false | undefined
    ? number
    : number[]
  : Multiple extends false | undefined
    ? string
    : string[];

interface IResourceAutocompleteProps<T, Multiple extends boolean, ChooseHandle> {
  value: ValueType<Multiple, ChooseHandle>;
  setValue: (_v: ValueType<Multiple, ChooseHandle>) => void;
  type: enums.RESOURCES_TYPES_ENUM | 'sp_pipeline' | 'signal_based' | 'alpha_model';
  showValid?: boolean;
  autocompleteProps?: Partial<React.ComponentProps<typeof mui.core.Autocomplete>>;
  inputProps?: React.ComponentProps<typeof mui.core.TextField>;
  disabled?: boolean;
  enableNone?: boolean;
  chooseHandle?: boolean;
  showFilters?: boolean;
  sortBy?: any[];
  groupBy?: (_v: T) => string;
  filter?: (_v: T) => boolean;
  getOptionDisabled?: (_v: T) => boolean;
  skipWorkspaceCheck?: boolean;
  hideDescription?: boolean;
  customInputFilters?: Filter[];
  customWidth?: number;
}

/**
 * Component that provides autocomplete functionality for selecting resources
 * 
 * @requires Redux Store:
 * - state.ui.currentWorkspaceId: Current workspace ID
 * - state.resources: Available resources
 * - state.ui.currentTab: Current UI tab
 * - state.resources.published_organizations: Published organizations
 * - state.auth.organization: Current organization
 *
 * @param value - Selected resource ID(s)
 * @param setValue - Callback to update selected value
 * @param type - Type of resource to search for
 * @param showValid - Whether to show validation state
 * @param autocompleteProps - Props to pass to MUI Autocomplete
 * @param inputProps - Props to pass to input TextField
 * @param disabled - Whether the component is disabled
 * @param enableNone - Allow selecting no value
 * @param chooseHandle - Whether to choose by handle instead of ID
 * @param showFilters - Whether to show search filters
 * @param sortBy - Sort options
 * @param groupBy - Group options function
 * @param filter - Filter options function
 * @param getOptionDisabled - Function to determine if option is disabled
 * @param skipWorkspaceCheck - Skip workspace validation
 * @param hideDescription - Hide description field
 * @param customInputFilters - Custom search filters
 * @param customWidth - Custom width override
 */
const ResourceAutocomplete = <
  T extends resourcesSearch.ReturnItem = types.common.ResourceExpanded,
  Multiple extends boolean | undefined = undefined,
  ChooseHandle extends boolean | undefined = undefined,
>(
  props: IResourceAutocompleteProps<T, Multiple, ChooseHandle>
) => {
  const {
    value,
    setValue,
    type,
    customInputFilters,
    autocompleteProps = {},
    showValid = true,
    getOptionDisabled = null,
    inputProps = {},
    enableNone = false,
    disabled = false,
    chooseHandle = false,
    sortBy = ['ws', (el: types.common.Resource & { ws?: string }) => el.name.toLowerCase(), 'handle'],
    groupBy = (el: types.common.Resource & { ws?: string }) => el.ws,
    filter = null,
    skipWorkspaceCheck = false,

    hideDescription = false,
    customWidth = 350,
  } = props;
  const [opened, setOpened] = React.useState(false);
  const currentWorkspaceId = useSelector((state: types.BaseStore) => state.ui.currentWorkspaceId);
  const resources = useSelector((state: types.BaseStore) => state.resources);
  const currTab = useSelector((state: types.BaseStore) => state.ui.currentTab);
  const organizations = useSelector((store:  types.BaseStore) => store.resources.published_organizations);
  const [hoveredOption, setHoveredOption] = React.useState<T | null>(null);
  const currentOrg = useSelector((state: types.BaseStore) => state.auth.organization);

  const wsContext: types.workspace.Workspace['context'] = React.useMemo(() => {
    if (currTab == 'production') return 'production';
    return 'research';
  }, [currTab]);

  const [showInputFilters, setShowInputFilters] = React.useState(false);
  const [uiFilters, setUiFilters] = React.useState('');
  const [currFilter, setCurrFilter] = React.useState<Filter>();

  const [contextMenu, setContextMenu] = React.useState<{
    mouseX: number;
    mouseY: number;
    option: T | null;
  } | null>(null);

  const handleContextMenu = (event: React.MouseEvent, option: T) => {
    event.preventDefault();
    event.stopPropagation();
    setContextMenu({
      mouseX: event.clientX,
      mouseY: event.clientY,
      option,
    });
  };

  const handleClose = () => {
    setContextMenu(null);
  };

  const removeUiFilter = (uiFilter: string) => {
    const newUiFilters = uiFilters.replaceAll(uiFilter, '');
    setUiFilters(newUiFilters.trim());
  };

  const inputFilters = React.useMemo(() => {
    return customInputFilters
      ? customInputFilters
      : getInputFilters(resources.users, resources.labels, type, resources.published_organizations);
  }, [resources]);

  const getOptions = () => {
    const opt = resourcesSearch.search(
      `type:${type}`,
      resources,
      wsContext,
      null,
      disabled, // Only include shared resources if disabled
      skipWorkspaceCheck,
      true
    ) as T[];
    if (enableNone) return [{ id: 'none', name: 'None', ws: 'None' }, ...opt] as T[];
    return opt;
  };

  const selectedFilters = React.useMemo(() => {
    if (!uiFilters) return [];
    const filtersEquality = uiFilters.trim().match(/(?:[^\s"]+|"[^"]*")+/g);
    return filtersEquality.map((fe) => fe.split(':')[0].trim());
  }, [uiFilters]);

  const ACPopper = React.useCallback(
    (props: any) => {
      return (
        <>
          <mui.core.Popper
            {...props}
            onMouseDown={(event) => event.preventDefault()}
            placement="bottom-start"
            sx={{ minWidth: customWidth }}
          >
            {showInputFilters ? (
              <mui.core.Box
                sx={{
                  maxHeight: '380px',
                  overflowY: 'auto',
                  overflowX: 'hidden',
                  backgroundColor: 'white',
                  borderRadius: '4px',
                  border: '1px solid #D6DCDF',
                  boxShadow: '0 10px 30px rgba(0,0,0,0.30)',
                }}
              >
                <Filters
                  currFilter={currFilter}
                  setCurrFilter={setCurrFilter}
                  filters={inputFilters}
                  addFilter={(filt) => {
                    setUiFilters(uiFilters ? uiFilters + ' ' + filt : filt);
                    setShowInputFilters(false);
                  }}
                  selectedFilters={selectedFilters}
                />
              </mui.core.Box>
            ) : (
              props.children
            )}
          </mui.core.Popper>
        </>
      );
    },
    [showInputFilters, uiFilters, selectedFilters, currFilter, inputFilters]
  );

  const options = React.useMemo(getOptions, [resources]);
  const [workspaceSet, setWorkspaceSet] = React.useState(new Set());

  const getWorkspaceSet = (currentWorkspaceId: number) => {
    const workspace = resources.workspaces.find((ws) => ws.id == currentWorkspaceId);

    let typeIds = `${type}_ids`;

    if (type == 'alpha_model' || type == 'signal_based') typeIds = 'signal_ids';
    if (type == 'sp_pipeline') typeIds = 'pipeline_ids';

    return new Set(workspace[typeIds as keyof types.workspace.Workspace] as number[]);
  };

  React.useEffect(() => {
    if (currentWorkspaceId) {
      setWorkspaceSet(getWorkspaceSet(currentWorkspaceId));
    }
  }, [currentWorkspaceId]);

  const filterOptions = (localOptions: T[], s: { inputValue: string }) => {
    let term = `type:${type} ${s.inputValue}`;
    // Add user selected filters to the search
    if (uiFilters) term = uiFilters.trim() + ' ' + term;
    const willShowArchived = uiFilters.includes('is_archived:"true"');

    const opt = (resourcesSearch.search(
      term,
      resources,
      wsContext,
      null,
      disabled, // Only include shared resources if disable
      skipWorkspaceCheck,
      true
    ) || []) as T[];
    const withWs = opt.map((o) => ({
      ...o,
      ws: workspaceSet.has(o.id) ? 'Current workspace' : 'Other',
    }));

    let filteredOpts = withWs;

    if (showValid) filteredOpts = filteredOpts.filter((r) => r.is_valid == undefined || r.is_valid);

    if (filter) filteredOpts = filteredOpts.filter(filter);

    let response = filteredOpts as T[];

    response = localOptions
      .map((lo) => response.find((r) => r.id == lo.id))
      .filter((f) => f)
      // Filter by deprecated items (if resource can be archived)
      // adding the current value (in case it is archived too)
      .filter(
        (f) =>
          willShowArchived ||
          f.is_deprecated == false ||
          _.isNil(f.is_deprecated) ||
          (chooseHandle ? f.handle == value : f.id == value)
      );

    response = _.sortBy(response, sortBy) as T[];

    if (enableNone && _.isEmpty(s.inputValue))
      response = [{ id: 'none', name: 'None', ws: 'None' }, ...response] as T[];

    return response;
  };

  const handleChange = (val: T | T[]) => {
    if (_.isArray(val)) {
      const mappedVal = val.map((r) => r?.id);
      setValue(mappedVal as ValueType<Multiple, ChooseHandle>);
    } else {
      // We have a special case in which we add none to id and then set as null
      const idVal = !val?.id || (val as any)?.id == 'none' ? null : val?.id;
      setValue(idVal as ValueType<Multiple, ChooseHandle>);
    }
  };

  const handleChangeAsHandle = (val: T | T[]) => {
    if (_.isArray(val)) {
      const mappedVal = val.map((r) => r?.handle);
      setValue(mappedVal as ValueType<Multiple, ChooseHandle>);
    } else {
      const handleVal = !val?.handle || val?.handle == 'none' ? null : val?.handle;
      setValue(handleVal as ValueType<Multiple, ChooseHandle>);
    }
  };

  const getValue = () => {
    if (_.isArray(value)) {
      return value.map((id) => options.find((r) => r.id == id));
    }
    const selected = options.find((r) => r.id == value);
    if (enableNone && !selected) return { id: 'none', name: 'None' };
    return selected || {};
  };

  const getValueAsHandle = () => {
    if (_.isArray(value)) {
      return value.map((handle) => options.find((r) => r.handle == handle));
    }
    const selected = options.find((r) => r.handle == value);
    if (enableNone && !selected) return { id: 'none', name: 'None' };
    return selected || '';
  };

  const resourceValue = React.useMemo(() => {
    if (!chooseHandle) return getValue() as resourcesSearch.ReturnItem;

    return getValueAsHandle() as resourcesSearch.ReturnItem;
  }, [chooseHandle, options, enableNone, value]);

  const getOptionSelected = (option: T, val: T) => option?.id == val?.id;

  const getOptionSelectedAsHandle = (option: T, val: T) => option?.handle == val?.handle;

  const getOptionLabel = (option: T) => {
    if (!option) return '';
    let label = '';
    // Option label needs to be unique
    if (option.name && option.handle) label = `${option.name} (${option.handle})`;
    // If we don't have handle, then the name is unique
    else if (option.name) label = option.name;

    return label;
  };

  return (
    <div>
      <ConditionalTooltip
        condition={!_.isEmpty(resourceValue) && resourceValue.name !== 'None' && !autocompleteProps.multiple}
        title={resourceValue.name + ` (${resourceValue.handle})`}
        placement="right"
      >
        <mui.core.Autocomplete<any, boolean, boolean, boolean>
          {...autocompleteProps}
          open={opened}
          openOnFocus
          disabled={disabled}
          disableClearable={true}
          PopperComponent={ACPopper}
          filterOptions={filterOptions}
          groupBy={groupBy}
          options={options}
          value={resourceValue as T | T[]}
          onChange={(_, val) => {
            setHoveredOption(null);
            !chooseHandle ? handleChange(val as T | T[]) : handleChangeAsHandle(val as T | T[]);
          }}
          isOptionEqualToValue={!chooseHandle ? getOptionSelected : getOptionSelectedAsHandle}
          getOptionLabel={getOptionLabel}
          getOptionDisabled={getOptionDisabled ? (v) => getOptionDisabled(v) || v.revoked : (v) => v.revoked}
          onOpen={() => setOpened(true)}
          onClose={(_e, reason) => {
            if (reason === 'toggleInput' && uiFilters) return;
            if (!_.isEmpty(contextMenu)) return;
            setOpened(false);
            setUiFilters('');
            setShowInputFilters(false);
            setHoveredOption(null);
          }}
          renderOption={(props, option) => {
            const provider = organizations.find((o) => o.id == (option?.source_org_id || option.organization_id))?.name;

            return (
              <li
                {...props}
                onMouseEnter={() => {
                  setHoveredOption(option);
                }}
                onMouseLeave={() => {
                  setHoveredOption(null);
                }}
                onContextMenu={(e) => {
                  if (option.id != 'none') handleContextMenu(e, option);
                }}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  textAlign: 'left',
                  alignItems: 'middle',
                  position: 'relative',
                  maxWidth: '100%',
                  padding: '0.5rem',
                }}
              >
                <mui.core.Box
                  flex="1"
                  sx={{
                    cursor: option != 'None' ? 'pointer' : 'default',
                    maxWidth: '450px',
                    overflow: 'hidden',
                    display: 'flex',
                    flexWrap: 'wrap',
                    alignItems: 'center',
                  }}
                >
                  <mui.core.Typography variant="body2" sx={{ fontWeight: '500', mr: 2 }}>
                    {option.name} {option.revoked && '(Revoked)'}
                  </mui.core.Typography>
                  {(option.name != 'None' || !hideDescription) && (
                    <>
                      <mui.core.Stack flexDirection="row" gap={2} sx={{ flexWrap: 'wrap' }}>
                        {option.handle && (
                          <mui.core.Chip
                            size="small"
                            color="secondary"
                            label={`${option.handle} ${option.is_deprecated ? ' · Archived' : ''}`}
                            sx={{ pointerEvents: 'none' }}
                          />
                        )}
                        {option.is_published && (
                          <mui.core.Chip
                            label="Published"
                            color="success"
                            size="small"
                            sx={{ pointerEvents: 'none' }}
                          />
                        )}
                        {option.id_deprecated && (
                          <mui.core.Chip label="Archived" color="warning" size="small" sx={{ pointerEvents: 'none' }} />
                        )}
                        {provider && provider != currentOrg.name && (
                          <mui.core.Chip
                            size="small"
                            variant="outlined"
                            color="secondary"
                            label={provider}
                            sx={{ pointerEvents: 'none' }}
                          />
                        )}
                      </mui.core.Stack>
                      {option.has_description && !_.isEmpty(option.short_description) && (
                        <mui.core.Typography color="textSecondary" sx={{ py: 1, fontSize: '0.6rem' }}>
                          {option.short_description}
                        </mui.core.Typography>
                      )}
                    </>
                  )}
                </mui.core.Box>
                <mui.core.Box sx={{ width: '80px', pl: 1, height: '1.5rem' }}>
                  {hoveredOption?.id == option.id && option.name != 'None' && (
                    <ResourceContextMenu option={option} onClose={handleClose} />
                  )}
                </mui.core.Box>
              </li>
            );
          }}
          renderInput={(p) => (
            <mui.core.TextField
              sx={{
                '.MuiOutlinedInput-root': {
                  paddingRight: '60px',
                },
                input: {
                  width: '100%!important',
                },
              }}
              {...p}
              {...inputProps}
              onKeyDown={(ev) => {
                const currValue = (ev.target as any).value || '';
                if (ev.key === 'Backspace' && uiFilters && !currValue)
                  removeUiFilter(uiFilters.match(/(?:[^\s"]+|"[^"]*")+/g).at(-1) as string);
              }}
              InputProps={{
                ...p.InputProps,
                startAdornment:
                  uiFilters || p.InputProps.startAdornment ? (
                    <>
                      {uiFilters &&
                        uiFilters.match(/(?:[^\s"]+|"[^"]*")+/g).map((uiFilter) => (
                          <mui.core.Tooltip key={uiFilter} title={uiFilter}>
                            <mui.core.Chip
                              label={uiFilter}
                              size="small"
                              sx={{
                                marginRight: '5px',
                                minWidth: '120px',
                                maxWidth: '120px',
                                backgroundColor: 'grey',
                              }}
                              onClick={() => removeUiFilter(uiFilter)}
                              onDelete={() => removeUiFilter(uiFilter)}
                              variant="outlined"
                              color="primary"
                            />
                          </mui.core.Tooltip>
                        ))}
                      {p.InputProps.startAdornment}
                    </>
                  ) : undefined,
                endAdornment: (
                  <>
                    {(resourceValue as any).revoked && (
                      <mui.core.InputAdornment position="end">
                        <mui.core.Chip
                          label="Needs Subscription"
                          size="small"
                          variant="filled"
                          sx={{ height: '18px!important', fontSize: '10px' }}
                        />
                      </mui.core.InputAdornment>
                    )}

                    <mui.core.InputAdornment position="end">
                      <mui.core.Tooltip title={showInputFilters ? 'Close Filters' : 'Filter search'}>
                        <mui.core.IconButton
                          aria-label="open filters"
                          size="small"
                          onClick={() => {
                            setShowInputFilters(!showInputFilters);
                            setOpened(true);
                          }}
                          edge="end"
                          disabled={disabled}
                        >
                          {showInputFilters ? (
                            <mui.icons.Close style={{ fontSize: '1rem', opacity: 0.8 }} />
                          ) : (
                            <mui.icons.FilterList style={{ fontSize: '1rem', opacity: 0.8 }} />
                          )}
                        </mui.core.IconButton>
                      </mui.core.Tooltip>
                    </mui.core.InputAdornment>
                    <mui.core.Box ml="28px">{p.InputProps.endAdornment}</mui.core.Box>
                  </>
                ),
              }}
            />
          )}
        />
      </ConditionalTooltip>
    </div>
  );
};

export default ResourceAutocomplete;
