import { useEffect, useRef, useState } from 'react';

/**
 * Hook that creates a typing animation effect for text
 * 
 * @param text - The text to animate
 * @param skip - Whether to skip the animation and show full text immediately (default: false)
 * @param completed - Whether the animation has completed (default: false)
 * @param onfinish - Callback function to run when animation completes (default: noop)
 * @param speed - Speed of typing animation in milliseconds (default: 15ms)
 * @returns The current text being displayed during animation
 * 
 * @example
 * ```tsx
 * const displayText = useTypingText("Hello world!", false, false, () => console.log("Done"), 20);
 * return <div>{displayText}</div>
 * ```
 */
const useTypingText = (
  text: string,
  skip = false,
  completed = false,
  onfinish = (() => undefined) as () => void,
  speed = 15
): string => {
  const [displayedText, setDisplayedText] = useState(skip ? text : '');
  const previousTextRef = useRef('');

  useEffect(() => {
    if (skip) {
      onfinish();
      return;
    }

    let currentIndex = previousTextRef.current.length;
    const interval = setInterval(() => {
      if (currentIndex < text.length) {
        setDisplayedText(text.slice(0, currentIndex + 1));
        currentIndex++;
      } else {
        onfinish();
        clearInterval(interval);
      }
    }, speed);

    previousTextRef.current = text;

    return () => {
      if (!skip) clearInterval(interval);
    };
  }, [text, speed, completed]);

  return displayedText;
};

export default useTypingText;
