import React from 'react';

import { mui } from '../libs';

type ConditionalTooltipProps = React.ComponentProps<typeof mui.core.Tooltip> & {
  title?: any;
  condition: boolean;
  children?: React.ReactNode;
};

/**
 * Conditional tooltip component that displays a tooltip only when a condition is met
 * 
 * @param title - The tooltip title 
 * @param condition - The condition that determines if the tooltip should be displayed
 * @param children - The content to display when the condition is met
 * 
 * @returns A tooltip component that displays a tooltip only when a condition is met
 */

const ConditionalTooltip: React.FC<ConditionalTooltipProps> = (props) => {
  if (props.condition) {
    return (
      <mui.core.Tooltip title={props.title} arrow {...props}>
        <span>{props.children}</span>
      </mui.core.Tooltip>
    );
  }
  return <>{props.children}</>;
};

export default ConditionalTooltip;
