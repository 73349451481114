import { _, hooks, mui, React } from '_core';

import { DraftParams } from './types';
import BasketsSelector from '../../common/forms/baskets-selector';

type FormProps = {
  params: DraftParams;
  setParams: (_p: DraftParams) => void;
};

const Form: React.FC<FormProps> = ({ params, setParams }) => {
  const options = ['yearly'];
  const [basketIds, setBasketIds] = React.useState(params.basket_ids || []);
  const uiStyles = hooks.useUiStyles();

  React.useEffect(() => {
    const data = { ...params, basket_ids: basketIds };
    setParams(data);
  }, [basketIds]);

  return (
    <mui.core.Box sx={uiStyles.widgetEditMode}>
      <mui.core.Box mt={4}>
        <mui.core.TextField
          select
          label="Rebalance Frequency"
          variant="outlined"
          size="small"
          value={'yearly'}
          onChange={() => undefined}
          disabled
          fullWidth
          required
        >
          {options.map((opt) => (
            <mui.core.MenuItem key={opt} value={opt}>
              {_.startCase(opt)}
            </mui.core.MenuItem>
          ))}
        </mui.core.TextField>
      </mui.core.Box>

      <mui.core.Box mt={2}>
        <BasketsSelector basketIds={basketIds} setBasketIds={setBasketIds} />
      </mui.core.Box>
    </mui.core.Box>
  );
};

export default Form;
