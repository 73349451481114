import { _, React, ts } from '_core';

import Chart from './chart';
import Form from './form';
import { DraftParams, Params } from './types';

type BacktestMaxDrawdownProps = {
  id: string;
  readOnly: boolean;
  widgetKey: string;
  mode: ts.enums.CHART_MODE_ENUM;
  params: Params;
  setParams: (_p: DraftParams) => void;
  title: string;

  fullScreen?: boolean;
  context?: ts.types.backtest.BacktestAnaylsisContext | ts.types.basket.BasketAnalysisContext;
  loadWidgetData: (_payload: ts.types.widgets.WidgetGetDataParams) => Promise<ts.types.widgets.WidgetGetDataResponse>;
  widgetPaths?: Record<'backtest_max_drawdown', string>;
};

const BacktestMaxDrawdown: React.FC<BacktestMaxDrawdownProps> = ({
  id,
  readOnly,
  widgetKey,
  params,
  setParams,
  mode,
  loadWidgetData,
  widgetPaths,
  fullScreen,
  title,
  context,
}): React.ReactElement => {
  const hasBenchmark = React.useMemo(
    () => (params.isBasket ? context?.definition?.optimizer_rebalance : !_.isEmpty(context?.definition?.benchmark)),
    []
  );

  const renderJobComponent = () => (
    <>
      <Chart
        id={id}
        readOnly={readOnly}
        widgetKey={widgetKey}
        params={params}
        fullScreen={fullScreen}
        loadWidgetData={loadWidgetData}
        portfolios={widgetPaths['backtest_max_drawdown']}
        title={title}
        hasBenchmark={hasBenchmark}
        isBasket={params.isBasket}
      />
    </>
  );

  return <>{mode == 'edit' ? <Form params={params} setParams={setParams} /> : renderJobComponent()}</>;
};

export default BacktestMaxDrawdown;
