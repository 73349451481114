import { hooks, mui, React, ui } from '_core';

import { DraftParams } from './types';
import SignalFactorExposures from '../../common/forms/signal-factor-exposure-selector';

type FormProps = {
  params: DraftParams;
  setParams: (_p: DraftParams) => void;
};

const Form: React.FC<FormProps> = ({ params, setParams }) => {
  const uiStyles = hooks.useUiStyles();

  return (
    <mui.core.Box sx={uiStyles.widgetEditMode}>
      <ui.Card>
        <mui.core.Typography variant="overline" color="textSecondary" sx={{ marginTop: '-5px', display: 'block' }}>
          SHEET 1
        </mui.core.Typography>
        <mui.core.Box display="flex" alignItems="center">
          <mui.core.Typography sx={{ fontWeight: 500, flex: '1' }}>Alpha Model Factor Exposures</mui.core.Typography>
          <mui.core.Chip size="small" label="No settings for this sheet" />
        </mui.core.Box>
      </ui.Card>
      <ui.Card>
        <mui.core.Typography variant="overline" color="textSecondary" sx={{ marginTop: '-5px', display: 'block' }}>
          SHEET 2
        </mui.core.Typography>
        <mui.core.Box display="flex" alignItems="center">
          <mui.core.Typography sx={{ fontWeight: 500, flex: '1' }}>Risk Factor Exposures</mui.core.Typography>
          <mui.core.Chip size="small" label="No settings for this sheet" />
        </mui.core.Box>
      </ui.Card>
      <ui.Card>
        <mui.core.Typography variant="overline" color="textSecondary" sx={{ marginTop: '-5px', display: 'block' }}>
          SHEET 3
          <mui.core.Chip
            sx={{ marginLeft: '10px' }}
            size="small"
            label="Only displayed if at least one signal is added"
          />
        </mui.core.Typography>

        <SignalFactorExposures
          value={params.analysis_pipelined_signals}
          setValue={(v) => setParams({ ...params, analysis_pipelined_signals: v })}
        />
      </ui.Card>
    </mui.core.Box>
  );
};

export default Form;
