import { React, ts } from '_core';

import Form from './form';
import { DraftParams, Params } from './types';

type BacktestDetailedViewSnapshotProps = {
  mode: ts.enums.CHART_MODE_ENUM;
  params: Params;
  setParams: (_p: DraftParams) => void;
  context: { start_date: string; end_date: string; frequency: ts.enums.FREQUENCY_ENUM };
};

const BacktestDetailedViewSnapshot: React.FC<BacktestDetailedViewSnapshotProps> = ({
  mode,
  context,
  params,
  setParams,
}) => {
  return (
    <>
      {mode == 'edit' ? (
        <Form
          params={params}
          setParams={setParams}
          startDate={context.start_date}
          endDate={context.end_date}
          frequency={context.frequency as ts.enums.FREQUENCY_ENUM}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default BacktestDetailedViewSnapshot;
