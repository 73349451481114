import { _, React, ts, useSelector } from '_core';

import Form from './form';
import Table from './table';
import { DraftParams, Params } from './types';

type SignalAttributionProps = {
  id: string;
  mode: ts.enums.CHART_MODE_ENUM;
  params: Params | DraftParams;
  setParams: (_p: DraftParams) => void;

  queryParams?: { initial?: string };
  goFullScreen?: (_v: string) => void;
  fullScreen?: boolean;
  context?: ts.types.signal.SignalAnalysisContext | ts.types.basket.BasketAnalysisContext;
  widgetPaths?: Record<
    'signal_returns_driven_attribution_details' | 'signal_returns_driven_attribution_summary',
    string
  >;

  loadWidgetData: (_payload: ts.types.widgets.WidgetGetDataParams) => Promise<ts.types.widgets.WidgetGetDataResponse>;
};

const SignalAttribution: React.FC<SignalAttributionProps> = ({
  id,
  loadWidgetData,
  params,
  setParams,
  mode,
  context,
  widgetPaths,
}): React.ReactElement => {
  const riskModels = useSelector((state) => state.resources.risk_models);

  const currentRiskModel = React.useMemo(
    () =>
      _.find(riskModels, (el) => {
        // As basket
        if ((context as any)?.definition?.risk_model_id) return el.id == (context as any)?.definition?.risk_model_id;
        // As signal
        if ((context as any)?.risk_model_id) return el.id == (context as any)?.risk_model_id;
        return false;
      }),
    [(context as ts.types.signal.SignalAnalysisContext)?.risk_model_id]
  );

  const frequency = React.useMemo(
    () =>
      (context as ts.types.signal.SignalAnalysisContext)?.frequency ||
      (context as ts.types.basket.BasketAnalysisContext)?.definition?.frequency,
    [context]
  );

  return mode == 'edit' ? (
    <Form params={params} setParams={setParams} contextRiskModel={currentRiskModel?.id} />
  ) : (
    <Table
      id={id}
      loadWidgetData={loadWidgetData}
      detailsFile={widgetPaths['signal_returns_driven_attribution_details']}
      summaryFile={widgetPaths['signal_returns_driven_attribution_summary']}
      frequency={frequency as ts.enums.FREQUENCY_ENUM}
      startDate={context?.start_date}
      endDate={context?.end_date}
      params={params}
      histories={context.context_histories}
    />
  );
};

export default SignalAttribution;
