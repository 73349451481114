import { _, config, mui, ts, ui } from '_core';

const baseResources = [
  {
    label: 'Universe',
    key: ts.enums.UI_RESOURCE_TYPE_ENUM.UNIVERSE,
    icon: () => <ui.ResourceIcon resource={ts.enums.UI_RESOURCE_TYPE_ENUM.UNIVERSE} />,
  },

  {
    label: 'Signal',
    key: ts.enums.UI_RESOURCE_TYPE_ENUM.SIGNAL,
    icon: () => <ui.ResourceIcon resource={ts.enums.UI_RESOURCE_TYPE_ENUM.SIGNAL} />,
  },
  {
    label: 'Alpha Model',
    key: ts.enums.UI_RESOURCE_TYPE_ENUM.ALPHA_MODEL,
    icon: () => <ui.ResourceIcon resource={ts.enums.UI_RESOURCE_TYPE_ENUM.ALPHA_MODEL} />,
  },
  {
    label: 'Backtest',
    key: ts.enums.UI_RESOURCE_TYPE_ENUM.BACKTEST,
    icon: () => <ui.ResourceIcon resource={ts.enums.UI_RESOURCE_TYPE_ENUM.BACKTEST} />,
  },
  {
    label: 'Time Series',
    key: ts.enums.UI_RESOURCE_TYPE_ENUM.TIME_SERIES,
    icon: () => <ui.ResourceIcon resource={ts.enums.UI_RESOURCE_TYPE_ENUM.TIME_SERIES} />,
  },
  {
    label: 'Signal Set',
    key: ts.enums.UI_RESOURCE_TYPE_ENUM.SIGNAL_SET,
    icon: () => <ui.ResourceIcon resource={ts.enums.UI_RESOURCE_TYPE_ENUM.SIGNAL_SET} />,
  },
  {
    label: 'Backtest Set',
    key: ts.enums.UI_RESOURCE_TYPE_ENUM.BACKTEST_SET,
    icon: () => <ui.ResourceIcon resource={ts.enums.UI_RESOURCE_TYPE_ENUM.BACKTEST_SET} />,
  },
  {
    label: 'Basket',
    key: ts.enums.UI_RESOURCE_TYPE_ENUM.BASKET,
    icon: () => <ui.ResourceIcon resource={ts.enums.UI_RESOURCE_TYPE_ENUM.BASKET} />,
  },
  {
    label: 'Strategy',
    key: ts.enums.UI_RESOURCE_TYPE_ENUM.STRATEGY,
    icon: () => <ui.ResourceIcon resource={ts.enums.UI_RESOURCE_TYPE_ENUM.STRATEGY} />,
  },
  {
    label: 'Portfolio',
    key: ts.enums.UI_RESOURCE_TYPE_ENUM.PORTFOLIO,
    icon: () => <ui.ResourceIcon resource={ts.enums.UI_RESOURCE_TYPE_ENUM.PORTFOLIO} />,
  },
  {
    label: 'Data Source',
    key: ts.enums.UI_RESOURCE_TYPE_ENUM.DATA_SOURCE,
    icon: () => <ui.ResourceIcon resource={ts.enums.UI_RESOURCE_TYPE_ENUM.DATA_SOURCE} />,
  },
  {
    label: 'Risk Model',
    key: ts.enums.UI_RESOURCE_TYPE_ENUM.RISK_MODEL,
    icon: () => <ui.ResourceIcon resource={ts.enums.UI_RESOURCE_TYPE_ENUM.RISK_MODEL} />,
  },
  {
    label: 'Pipeline',
    key: ts.enums.UI_RESOURCE_TYPE_ENUM.PIPELINE,
    icon: () => <ui.ResourceIcon resource={ts.enums.UI_RESOURCE_TYPE_ENUM.PIPELINE} />,
  },
  {
    label: 'Report',
    key: ts.enums.UI_RESOURCE_TYPE_ENUM.GENERAL_ANALYSIS,
    icon: () => <ui.ResourceIcon resource={ts.enums.UI_RESOURCE_TYPE_ENUM.GENERAL_ANALYSIS} />,
  },
];

const getResources = (context: ts.types.workspace.Workspace['context']) => {
  let localAddMenu = [...baseResources];

  if (!config.features.signal_set)
    localAddMenu = localAddMenu.filter((val) => val.key != ts.enums.UI_RESOURCE_TYPE_ENUM.SIGNAL_SET);

  if (!config.features.custom_data_source)
    localAddMenu = localAddMenu.filter((val) => val.key != ts.enums.UI_RESOURCE_TYPE_ENUM.DATA_SOURCE);

  if (!config.features.custom_risk_model)
    localAddMenu = localAddMenu.filter((val) => val.key != ts.enums.UI_RESOURCE_TYPE_ENUM.RISK_MODEL);

  if (!config.features.alpha_model)
    localAddMenu = localAddMenu.filter((val) => val.key != ts.enums.UI_RESOURCE_TYPE_ENUM.ALPHA_MODEL);

  if (!config.features.basket)
    localAddMenu = localAddMenu.filter((val) => val.key != ts.enums.UI_RESOURCE_TYPE_ENUM.BASKET);

  if (!config.features.backtest || !config.features.alpha_model)
    localAddMenu = localAddMenu.filter((val) => val.key != ts.enums.UI_RESOURCE_TYPE_ENUM.BACKTEST);

  if (!config.features.backtest || !config.features.alpha_model || !config.features.backtest_set)
    localAddMenu = localAddMenu.filter((val) => val.key != ts.enums.UI_RESOURCE_TYPE_ENUM.BACKTEST_SET);

  if (!config.features.time_series)
    localAddMenu = localAddMenu.filter((val) => val.key != ts.enums.UI_RESOURCE_TYPE_ENUM.TIME_SERIES);

  if (context == 'production') return localAddMenu;

  return localAddMenu.filter((val) => config.researchResources.includes(val.key));
};

export const getFilters = (
  users: ts.types.user.User[],
  labels: ts.StoreState['resources']['labels'],
  organizations: ts.types.organization.PublishedOrg[],
  context: ts.types.workspace.Workspace['context']
) => {
  return [
    {
      key: 'type',
      label: 'Type',
      type: 'select' as 'text' | 'select',
      options: getResources(context),
      icon: () => <mui.icons.Category />,
    },
    {
      key: 'definition',
      label: 'Definition',
      type: 'text' as 'text' | 'select',
      icon: () => <mui.icons.Abc />,
    },
    {
      key: 'author',
      label: 'Author',
      type: 'select' as 'text' | 'select',
      options: [
        ...users
          .map((u) => ({
            key: u.name,
            label: u.name,
          }))
          .filter((u) => u.label !== 'superadmin'),
        { key: 'finsera', label: 'Finsera' },
      ],
      icon: () => <mui.icons.Person />,
    },
    {
      key: 'label',
      label: 'Label',
      type: 'select' as 'text' | 'select',
      options: labels.map((l) => ({
        key: l.title,
        label: l.title,
      })),
      sort: (l: typeof Option[]) => _.sortBy(l, 'label'),
      icon: () => <mui.icons.Label />,
    },
    {
      key: 'organization',
      label: 'Provider',
      type: 'select' as 'text' | 'select',
      options: organizations.map((o) => ({
        key: o.subdomain,
        label: o.subdomain == 'internal' ? 'Finsera' : `${o.name} (${o.subdomain})`,
      })),
      icon: () => <mui.icons.Apartment />,
    },
    {
      key: 'theme',
      label: 'Theme',
      type: 'select' as 'text' | 'select',
      options: [...Object.keys(config.themes), ...Object.keys(config.styleFactorThemes)].map((key) => ({
        key,
        label:
          config.themes?.[key as keyof typeof config.themes] ||
          config.styleFactorThemes?.[key as keyof typeof config.styleFactorThemes],
      })),
      icon: () => <mui.icons.Palette />,
    },
    {
      key: 'is_published',
      label: 'Published',
      type: 'select' as 'text' | 'select',
      options: [
        { key: 'true', label: 'True' },
        { key: 'false', label: 'False' },
      ],
      icon: () => <mui.icons.Share />,
    },
    {
      key: 'is_archived',
      label: 'Archived',
      type: 'select' as 'text' | 'select',
      options: [
        { key: 'true', label: 'True' },
        { key: 'false', label: 'False' },
      ],
      icon: () => <mui.icons.Archive />,
    },
  ];
};
