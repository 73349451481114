import { React, ts, ui } from '_core';

type FrequencyFieldProps = {
  dataset: ts.types.signal.DatasetDraft;
  setDataset: (_d: ts.types.signal.DatasetDraft) => void;
  allOptional?: boolean;
  isPreference?: boolean;
};

const FrequencyField: React.FC<FrequencyFieldProps> = ({ dataset, setDataset, allOptional, isPreference }) => {
  return (
    <ui.Frequency
      value={dataset.frequency as ts.enums.FREQUENCY_ENUM}
      setValue={(frequency: ts.enums.FREQUENCY_ENUM) =>
        setDataset({
          ...dataset,
          frequency,
          id: isPreference ? dataset?.id : null,
        })
      }
      hideFrequencies={[ts.enums.FREQUENCY_ENUM.SEMIANNUAL]}
      required={!allOptional}
    />
  );
};

export default FrequencyField;
