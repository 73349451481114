import React from 'react';

import Modal from './modal';
import { datesFmt } from '../../data';
import { parseApiError } from '../../helpers';
import { _, mui } from '../../libs';
import * as types from '../../types';

type UploadCsvProps = {
  postToApi: (_p: any, _b: string) => Promise<void>;

  requiredCols?: string[];
  buttonProps?: React.ComponentProps<typeof mui.core.Button>;
  buttonLabel?: string;
  example?: string;
  instructions?: React.ReactElement;
  createBatch?: (_n: string, _t: number) => Promise<string>;
  batchName?: string;
  enableDateFormat?: boolean;
  successPath?: string;
};

/**
 * Component that handles CSV file uploads with validation and batch processing
 * 
 * @param postToApi - Function to post CSV data to API endpoint
 * @param requiredCols - Array of required column names to validate in CSV header
 * @param buttonProps - Props to pass to the upload button component
 * @param buttonLabel - Label text for the upload button
 * @param example - Example CSV format to show in modal
 * @param instructions - React element containing upload instructions
 * @param createBatch - Function to create a new batch after upload
 * @param batchName - Name for the batch being created
 * @param enableDateFormat - Whether to show date format selection
 * @param successPath - Path to redirect after successful upload
 * 
 * @returns Upload button and modal component for CSV file handling
 */
const UploadCsv: React.FC<UploadCsvProps> = ({
  requiredCols,
  postToApi,
  buttonProps,
  buttonLabel,
  example,
  instructions,
  createBatch,
  enableDateFormat,
  batchName,
  successPath,
}): React.ReactElement => {
  const [openModal, setOpenModal] = React.useState(false);
  const [loadingState, setLoadingState] = React.useState('init');
  const [error, setError] = React.useState('');
  const header = React.useRef('');

  const validHeader = () => {
    if (_.isEmpty(requiredCols)) return true;
    for (const column of requiredCols) if (!header.current.includes(column)) return false;
    return true;
  };

  const processChunk = (chunk: string) => {
    chunk = chunk.replace('\r', '');
    header.current = chunk.split('\n')[0];
    if (!validHeader())
      return {
        success: false,
        error: 'First row must have all the column names.',
      };
    return { success: true, data: chunk };
  };

  const processUpload = async (file: File, fileFormat: string) => {
    setLoadingState('loading');
    header.current = '';

    const fr = new FileReader();
    fr.onload = () => {
      const buffer = new Uint8Array(fr.result as ArrayBuffer);
      const snippet = new TextDecoder('utf-8').decode(buffer, { stream: true });
      const processed = processChunk(snippet);

      if (processed.success) {
        const post = async () => {
          try {
            await postToApi({ csv: processed.data, dates_fmt: fileFormat }, batchName);

            if (createBatch && batchName) {
              try {
                await createBatch(batchName, 1);
              } catch {
                console.log('Unable to create the batch');
              }
            }
          } catch (err) {
            setError(
              parseApiError(err as types.common.ApiError, (s: string) => {
                Object.keys(datesFmt).forEach((key) => {
                  s = s.replaceAll(key, datesFmt[key as keyof typeof datesFmt]);
                });
                return s;
              })
            );
          }
          setLoadingState('finished');
        };

        post();
      } else {
        setError(processed.error);
      }
    };
    fr.readAsArrayBuffer(file);
  };

  return (
    <>
      <mui.core.Button
        {...buttonProps}
        onClick={() => {
          setOpenModal(true);
        }}
      >
        {buttonLabel}
      </mui.core.Button>
      <Modal
        open={openModal}
        close={() => setOpenModal(false)}
        error={error}
        processUpload={processUpload}
        enableDateFormat={enableDateFormat}
        clearLoadingState={() => {
          setLoadingState('init');
          setError('');
        }}
        example={example}
        instructions={instructions}
        loadingState={loadingState}
        successPath={successPath}
      />
    </>
  );
};

UploadCsv.defaultProps = {
  buttonProps: {},
  buttonLabel: 'Upload csv',
  requiredCols: [],
  example: '',
  createBatch: null,
};

export default UploadCsv;
