import { hooks, mui, React, ts, ui } from '_core';

import { DraftParams, Params } from './types';

type FormProps = {
  params: DraftParams;
  setParams: (_p: Params) => void;
};

const Form: React.FC<FormProps> = ({ params, setParams }): React.ReactElement => {
  const [weights, setWeights] = React.useState(params.weights);
  const [unlevered, setUnlevered] = React.useState(false);
  const [grossLeverage, setGrossLeverage] = React.useState<number>(params.gross_leverage);
  const uiStyles = hooks.useUiStyles();

  React.useEffect(() => {
    const data = {
      ...params,
      weights,
      unlevered,
      gross_leverage: grossLeverage,
    };
    setParams(data);
  }, [weights, unlevered, grossLeverage]);

  const handleGrossLeverageChange = (value: number) => {
    if (!isNaN(value)) {
      setGrossLeverage(value);
      if (value !== 1.0) {
        setUnlevered(false);
      }
    }
  };

  const handleUnleveredChange = () => {
    const newUnleveredValue = !unlevered;
    setUnlevered(newUnleveredValue);
    if (newUnleveredValue) {
      setGrossLeverage(1.0);
    }
  };

  return (
    <mui.core.Box sx={uiStyles.widgetEditMode}>
      <mui.core.Grid container spacing={4}>
        <mui.core.Grid item xs={12} md={12}>
          <mui.core.TextField
            select
            variant="outlined"
            size="small"
            value={weights}
            onChange={(e) => setWeights(e.target.value as ts.enums.REPORT_ENUMS.WEIGHTS_ENUM)}
            label="Portfolio Weights"
            fullWidth
            InputProps={{ style: { backgroundColor: 'white' } }}
            style={{ marginRight: '10px', marginTop: '2px', minWidth: '150px' }}
          >
            <mui.core.MenuItem value={ts.enums.REPORT_ENUMS.WEIGHTS_ENUM.MINIMUM_VOLATILITY}>
              Minimum Volatility
            </mui.core.MenuItem>
            <mui.core.MenuItem value={ts.enums.REPORT_ENUMS.WEIGHTS_ENUM.MARKET_CAP}>
              Market Capitalization
            </mui.core.MenuItem>
            <mui.core.MenuItem value={ts.enums.REPORT_ENUMS.WEIGHTS_ENUM.EQUAL}>Equal</mui.core.MenuItem>
            <mui.core.MenuItem value={ts.enums.REPORT_ENUMS.WEIGHTS_ENUM.MARKET_NEUTRAL}>
              Market Neutral
            </mui.core.MenuItem>
          </mui.core.TextField>
        </mui.core.Grid>
        <mui.core.Grid item xs={12} md={12} container spacing={2}>
          {weights == ts.enums.REPORT_ENUMS.WEIGHTS_ENUM.MARKET_NEUTRAL && (
            <>
              <mui.core.Grid item xs={4}>
                <mui.core.FormControlLabel
                  control={<mui.core.Switch size="small" checked={unlevered} onChange={handleUnleveredChange} />}
                  label="Unlevered Portfolio"
                />
              </mui.core.Grid>
              <mui.core.Grid item xs={4}>
                <ui.NumericField
                  textFieldProps={{
                    label: 'Gross Leverage',
                    fullWidth: true,
                    variant: 'outlined',
                    size: 'small',
                    required: true,
                  }}
                  value={grossLeverage}
                  setValue={(v) => handleGrossLeverageChange(v)}
                />
              </mui.core.Grid>
            </>
          )}
        </mui.core.Grid>
      </mui.core.Grid>
    </mui.core.Box>
  );
};

export default Form;
