import { User } from '../../types/user';
import { _ } from '../lodash';
let userId = null as string;

/**
 * Initializes the local storage with a user ID
 * @param user - The user object containing an ID
 */
const init = (user: User) => {
  userId = user.id.toString();
};

type SetProps = {
  data: unknown;
  path?: string;
  key?: string;
  expiry?: number;
};

/**
 * Sets data in local storage, optionally at a specific path under a key
 * @param data - The data to store
 * @param path - Optional dot notation path to store data at
 * @param key - Optional storage key, defaults to current user ID
 * @param expiry - Optional expiry time in milliseconds, defaults to null
 * @returns The stored object
 */
const set = ({ data, path = null, key = userId, expiry = null }: SetProps) => {
  const localData = expiry ? { data, expiry: Date.now() + expiry } : data;
  const obj = path ? _.set(get({ key }) || {}, path, localData) : localData;
  localStorage.setItem(key, JSON.stringify(obj));
  return obj;
};

type GetProps = {
  path?: string;
  key?: string;
};

/**
 * Retrieves data from local storage, optionally from a specific path
 * @param path - Optional dot notation path to get data from
 * @param key - Optional storage key, defaults to current user ID
 * @returns The stored data, or false if not found or on error
 */
const get = ({ path = null, key = userId }: GetProps) => {
  if (!key) return false;
  try {
    const obj = JSON.parse(localStorage.getItem(key));
    if (!obj) return false;

    if (obj?.expiry && obj?.expiry < Date.now()) {
      localStorage.removeItem(key);
      return false;
    }

    if (obj?.expiry) {
      return path ? _.get(obj, path)?.data : obj.data;
    }

    return path ? _.get(obj, path) : obj;
  } catch {
    return false;
  }
};

type PushAndDropLastProps = {
  data: unknown;
  path?: string;
  stackSize?: number;
  key?: string;
};

/**
 * Checks if localStorage is available in the current environment
 * @returns boolean indicating if localStorage is available
 */
const checkStorageAvailability = () => {
  try {
    const testKey = '__storage_test__';
    localStorage.setItem(testKey, testKey);
    localStorage.removeItem(testKey);
    return true;
  } catch (e) {
    return false;
  }
};

checkStorageAvailability();

/**
 * Pushes data to an array in storage while maintaining a maximum size
 * @param data - The data to push to the array
 * @param path - Optional dot notation path to the array
 * @param stackSize - Maximum size of the array, defaults to 10
 * @param key - Optional storage key, defaults to current user ID
 * @returns The item that was dropped if array exceeded stackSize, otherwise null
 */
const pushAndDropLast = ({ data, path = null, stackSize = 10, key = userId }: PushAndDropLastProps) => {
  if (!key) return false;
  let obj = get({ path, key });
  if (!_.isArray(obj)) obj = [];

  let droppedItem = null;
  if (obj.length >= stackSize) droppedItem = obj[obj.length - 1];

  // add data, slice to stack size and get uniq
  obj = _.uniqWith([data, ...obj].slice(0, stackSize), _.isEqual);
  set({ data: obj, path, key });
  return droppedItem;
};

type ClearProps = {
  key?: string;
  path?: string;
};

/**
 * Clears data from local storage
 * @param key - Optional storage key to clear, defaults to current user ID
 * @param path - Optional dot notation path to clear
 * @returns void
 */
const clear = ({ key = userId, path = null }: ClearProps) => {
  if (key && path) {
    const obj = get({ key });

    if (obj) {
      const newObj = path ? _.set(obj, path, undefined) : obj;
      return localStorage.setItem(key, JSON.stringify(newObj));
    }
    return;
  }
  if (key) {
    return localStorage.removeItem(key);
  }
  return localStorage.clear();
};

const clearExpired = () => {
  const keys = Object.keys(localStorage);
  keys.forEach((key) => {
    const obj = get({ key });
    if (obj?.expiry && obj?.expiry < Date.now()) localStorage.removeItem(key);
  });
};

export { set };
export { pushAndDropLast };
export { get };
export { clear };
export { init };
export { clearExpired };
