/* eslint-disable no-unused-vars */
import { ts } from '_core';

export enum INTERACTION_PARAMS {
  BY = 'by',
  CHART_TYPE = 'chartType',
  TAB_VALUE = 'tabValue',
  SEL_RBICS = 'selectedRbics',
  PARENT = 'parent',
  children = 'children',
}

export type InteractionParams = {
  [INTERACTION_PARAMS.CHART_TYPE]: 'top' | 'rbics';
  [INTERACTION_PARAMS.SEL_RBICS]?: ts.types.industries.IndustryData[];
  [INTERACTION_PARAMS.TAB_VALUE]?: number;
  [INTERACTION_PARAMS.PARENT]?: keyof ts.StoreState['resources']['rbics_data'];
  [INTERACTION_PARAMS.children]?: keyof ts.StoreState['resources']['rbics_data'];
};

export type JobParams = {
  category: 'rbics_l1_id' | 'rbics_l2_id' | 'rbics_l3_id' | 'rbics_l4_id';
  drop_values_under?: number;
};

export type Params = JobParams & InteractionParams;
export type DraftParams = Partial<Params>;
