/* eslint-disable no-unused-vars */

export enum INTERACTION_PARAMS {
  SELECTED_TYPE = 'selectedType',
  AGGREGATION = 'aggregation',
}

export type InteractionParams = {
  [INTERACTION_PARAMS.AGGREGATION]: 'ANNUAL';
  [INTERACTION_PARAMS.SELECTED_TYPE]: 'show_total';
};

export type JobParams = {
  basket_ids: number[];
};

export type Params = JobParams & InteractionParams;
export type DraftParams = Partial<Params>;
