import { _, mui, React, ts } from '_core';

import ChartWrapper from '../../common/chart-wrapper';
import createNestedBarChart from '../../common/charts/create-nested-bar-chart';

type ChartProps = {
  id: string;
  readOnly: boolean;
  widgetKey: string;
  file: string;
  fullScreen: boolean;
  title: string;
  loadWidgetData: (_payload: ts.types.widgets.WidgetGetDataParams) => Promise<ts.types.widgets.WidgetGetDataResponse>;
};

type TabTypes = 'Rank Correlation' | 'Pearson Correlation';

const Chart: React.FC<ChartProps> = ({
  id,
  readOnly,
  widgetKey,
  loadWidgetData,
  file,
  fullScreen,
  title,
}): React.ReactElement => {
  const [data, setData] = React.useState<ts.types.widgets.TableData>(null);
  const [error, setError] = React.useState<ts.types.common.ApiError>(null);
  const [showLegend, setShowLegend] = React.useState(false);

  const [tab, setTab] = React.useState<TabTypes>('Pearson Correlation');

  const loadData = async () => {
    try {
      const response = await loadWidgetData({
        data: {
          file,
          category: 'build_date',
          dropna: true,
          columns: ['build_date', tab],
          rename: {
            rank_correlation: 'Rank Correlation',
            pearson_correlation: 'Pearson Correlation',
          },
        },
      });
      if (response) {
        setData(response.data);
      }
    } catch (err) {
      setError(err as ts.types.common.ApiError);
    }
  };

  React.useEffect(() => {
    if (file) loadData();
    return () => setData(null);
  }, [file, tab]);

  React.useEffect(() => {
    // Create line chart on data and scale changes
    const format = '#.##';
    if (!_.isNil(data))
      createNestedBarChart({
        id,
        data,
        fullScreen,
        setShowLegend,
        category: 'build_date',
        rotatedLabels: true,
        xAxisLabel: '',
        yAxisLabel: '',
        format,
        exportTitle: title,
      });
  }, [data]);

  return (
    <ChartWrapper
      id={id}
      readOnly={readOnly}
      widgetKey={widgetKey}
      data={data}
      showLegend={showLegend}
      warning={data?.length === 0 ? ['Empty File'] : []}
      error={error}
      fullScreen={fullScreen}
      tabs={
        <>
          <mui.core.Tabs
            value={tab}
            indicatorColor="primary"
            textColor="primary"
            onChange={(e, newTab) => setTab(newTab)}
            aria-label="Cross-Sectional Correlation Tabs"
          >
            <mui.core.Tab label={'Correlation'} value={'Pearson Correlation'} />
            <mui.core.Tab label={'Rank Correlation'} value={'Rank Correlation'} />
          </mui.core.Tabs>
        </>
      }
    />
  );
};

export default Chart;
