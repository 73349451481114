// Auth
export const SET_USER = 'SET_USER';
export const LOGOUT = 'LOGOUT';
export const REQUEST_PASSWORD_RESET = 'REQUEST_PASSWORD_RESET';
export const PERFORM_PASSWORD_RESET = 'PERFORM_PASSWORD_RESET';
export const INVALID_PASSCODE = 'INVALID_PASSCODE';
export const SET_VERIFIED = 'VERIFIED';
export const SET_AUTH_TOKEN = 'SET_AUTH_TOKEN';
// RESOURCES
export const LOAD_RESOURCES = 'LOAD_RESOURCES';
export const UPDATE_RESOURCE_TYPE_BY_ID = 'UPDATE_RESOURCE_TYPE_BY_ID';
export const UPDATE_CURRENT_WORKSPACE = 'UPDATE_CURRENT_WORKSPACE';
export const DELETE_RESOURCE_TYPE_BY_ID = 'DELETE_RESOURCE_TYPE_BY_ID';
// UI
export const SET_CURRENT_TAB = 'SET_CURRENT_TAB';
export const SET_WORKSPACES_METADATA = 'SET_WORKSPACES_METADATA';
export const SET_USER_PREFERENCES = 'SET_USER_PREFERENCES';
export const SET_PREFERENCES_OPEN = 'SET_PREFERENCES_OPEN';
export const SET_PREFERENCES_SELECTED_TAB = 'SET_PREFERENCES_SELECTED_TAB';
export const SET_PREFERENCES_LAST_UPDATED = 'SET_PREFERENCES_LAST_UPDATED';
export const SET_API_OPEN = 'SET_API_OPEN';
export const SET_SCHEDULE_DEMO_OPEN = 'SET_SCHEDULE_DEMO_OPEN';
export const SET_API_SELECTED_TAB = 'SET_API_SELECTED_TAB';
export const SET_DOCUMENTS_SIDEBAR_OPEN = 'SET_DOCUMENTS_SIDEBAR_OPEN';
export const SET_DOCUMENTS_SIDEBAR_URL = 'SET_DOCUMENTS_SIDEBAR_URL';
export const UPDATE_UI_VERSION = 'UPDATE_UI_VERSION';
export const UPDATE_FAUTH_IS_SET = 'UPDATE_FAUTH_IS_SET';
export const SET_CHATBOT_OPEN = 'SET_CHATBOT_OPEN';
export const SET_CHATBOT_SHOW = 'SET_CHATBOT_SHOW';
// API ERRORS
export const ADD_ERROR = 'ADD_ERROR';
export const POP_ERROR = 'POP_ERROR';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
// ROOT
export const RESET_STORE = 'RESET_STORE';
// WEBSOCKETS
export const JOB_UPDATE = 'JOB_UPDATE';
