import { _, React, ts } from '_core';

import ChartWrapper from '../../common/chart-wrapper';
import createHeatMapChart from '../../common/charts/create-heat-map';

type ChartProps = {
  id: string;
  readOnly: boolean;
  widgetKey: string;
  loadWidgetData: (_payload: ts.types.widgets.WidgetGetDataParams) => Promise<ts.types.widgets.WidgetGetDataResponse>;
  file: string;
  fullScreen: boolean;
  title: string;
};

const Chart: React.FC<ChartProps> = ({
  id,
  readOnly,
  widgetKey,
  loadWidgetData,
  file,
  fullScreen,
  title,
}): React.ReactElement => {
  const [data, setData] = React.useState<ts.types.widgets.HeatMapData>(null);
  const [error, setError] = React.useState<ts.types.common.ApiError>(null);

  const loadData = async () => {
    try {
      const response = await loadWidgetData({
        data: {
          file: file,
        },
      });
      if (response) {
        setData(response.data as ts.types.widgets.HeatMapData);
      }
    } catch (err) {
      setError(err as ts.types.common.ApiError);
    }
  };
  React.useEffect(() => {
    if (file) loadData();
    return () => setData(null);
  }, [file]);

  React.useEffect(() => {
    if (!_.isNil(data)) {
      createHeatMapChart({
        id,
        data: data,
        fullScreen,
        xAxis: 'horizon',
        yAxis: 'portfolio',
        xAxisLabel: 'Forecast horizon (days)',
        yAxisLabel: 'Portfolio Size (% of universe)',
        exportTitle: title,
        minColor: '#00FF00',
        maxColor: '#FF0000',
        showHeatLegend: false,
        labelValues: true,
      });
    }
  }, [data]);

  return (
    <ChartWrapper
      id={id}
      readOnly={readOnly}
      widgetKey={widgetKey}
      data={data}
      // warning={data?.warnings}
      error={error}
      fullScreen={fullScreen}
    />
  );
};

export default Chart;
