import { ts } from '_core';

import { INTERACTION_PARAMS } from './types';

export default [
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.RISK_MODEL_INDUSTRY_COVERAGE,
    title: 'Industry Coverage',
    form: false,
    defaultParams: {},
    interactionParams: Object.values(INTERACTION_PARAMS),
    neededFiles: ['risk_model_industry_coverage_summary', 'risk_model_industry_coverage_time_series'],
    tags: [
      ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.RISK_MODEL,
      ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.FINSERA_RISK_MODEL
    ],

    helpLabel: 'i2ayjl3x',
  },
] satisfies ts.types.analysis.WidgetConfig[];
