import { ts } from '_core';


export default [
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.GENERAL_MY_WORKSPACES,
    form: false,
    title: 'My Workspaces',
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.GENERAL],
    helpLabel: 'xwerkt0t',
  },
] satisfies ts.types.analysis.WidgetConfig[];
