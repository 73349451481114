import { ts } from '_core';

import { getName } from './helpers';

export default [
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.BACKTEST_BIAS_STATISTICS,
    title: 'Bias Statistic',
    helpLabel: 'ywryle32',
    form: true,
    defaultParams: {
      format_for_ui: true,
      unlevered: false,
    },
    neededFiles: ['backtest_bias_statistics'],
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.BACKTEST_PORTFOLIO_RISK],

    getName,
  },
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.PORTFOLIO_BIAS_STATISTICS,
    title: 'Bias Statistic',
    helpLabel: '088rb45y',
    form: true,
    defaultParams: {
      format_for_ui: true,
      unlevered: false,
    },
    neededFiles: ['portfolio_bias_statistics'],
    tags: [
      ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.PORTFOLIO_RISK,
      ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.PORTFOLIO_STRAT_RISK,
    ],

    getName,
  },
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.SIGNAL_BIAS_STATISTICS,
    title: 'Bias Statistic',
    helpLabel: '6mwipdlz',
    form: false,
    defaultParams: {
      format_for_ui: true,
    },
    neededFiles: ['signal_bias_statistics'],
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.SIGNAL, ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.ALPHA_MODEL],
  },
] satisfies ts.types.analysis.WidgetConfig[];
