import React from 'react';
import { argsToStringifiable } from '@local/finsera-core/src/helpers/embed/utils';
import { _, moment, mui } from '@local/finsera-core/src/libs';
import * as types from '@local/finsera-core/src/types';

import Chart from './chart';
import { useUiStyles, useWindowDimensions } from '../../../hooks';
import CenteredLoader from '../../centered-loader';
import ChartError from '../../chart-error';

type StackedChartProps = {
  id: string;
  data: types.widgets.TableData;
  fullScreen: boolean;
  readOnly: boolean;
  alert?: {
    type: 'error' | 'warning';
    message: string;
  };
  xAxisField?: string;
  exportTitle?: string;
  exportSubTitle?: string;
  download?: boolean;
  setExternalEmbed?: (_json: types.analysis.EmbedChartData) => void;
  yAxisLabel?: string;
  xAxisLabel?: string;
};

/**
 * Stacked area chart component that displays data in a stacked format
 *
 * @remarks
 * If xAxisField is set to 'date' (default), the values will be treated as dates
 * and formatted as 'MMM YYYY' (e.g. 'Jan 2023')
 *
 * @param id - Unique identifier for the chart
 * @param data - Data to be displayed in the chart
 * @param fullScreen - Whether the chart is in fullscreen mode
 * @param readOnly - Whether the chart is in read-only mode
 * @param alert - Alert message to display above the chart
 * @param xAxisField - Field to use for x-axis values. If set to 'date', values will be formatted as 'MMM YYYY'
 * @param exportTitle - Title used when exporting the chart
 * @param exportSubTitle - Subtitle used when exporting the chart
 * @param download - Whether to show download button
 * @param setExternalEmbed - Callback to set external embed data
 * @param yAxisLabel - Label for y-axis
 * @param xAxisLabel - Label for x-axis
 * @returns React component displaying a stacked area chart
 */
const Stacked: React.FC<StackedChartProps> = (args: StackedChartProps) => {
  const {
    id,
    data,
    fullScreen,
    readOnly,

    alert,
    xAxisField = 'date',
    exportTitle,
    exportSubTitle,
    download = true,
    setExternalEmbed,
    yAxisLabel,
    xAxisLabel,
  } = args;

  const uiStyles = useUiStyles();
  const [localData, setLocalData] = React.useState<types.widgets.TableData>(null);
  const { width } = useWindowDimensions(1_000);

  React.useEffect(() => {
    const handleData = async () => {
      setLocalData(null);
      if (_.isNil(data)) return;
      await new Promise((resolve) => setTimeout(resolve, 200));

      if (setExternalEmbed)
        setExternalEmbed({
          id,
          widgetTitle: exportTitle,
          widgetSubTitle: exportSubTitle,
          chart_type: 'stacked-chart-v5',
          args: argsToStringifiable({
            ...args,
            data,
          }),
        });

      if (xAxisField === 'date') {
        setLocalData(
          data.map((row) => ({
            ...row,
            date: moment(row.date).format('MMM YYYY') as string,
          }))
        );
      } else {
        setLocalData(data);
      }
    };
    handleData();
  }, [data, width]);

  return (
    <mui.core.Box
      sx={{
        height: '100%',
        position: 'relative',
        overflow: 'hidden',
        display: 'grid',
        gridTemplateRows: `${alert ? 'auto ' : ''} 1fr`,
      }}
    >
      {alert && <ChartError readOnly={readOnly} error={alert.message} severity={alert.type} />}
      <mui.core.Box sx={uiStyles.widgetContent}>
        <mui.core.Box sx={{ height: fullScreen ? 'calc(100% - 40px)' : '100%' }}>
          {_.isNil(localData) ? (
            <CenteredLoader label="Loading widget data..." top="-40px" />
          ) : (
            <Chart
              id={id}
              data={localData}
              fullScreen={fullScreen}
              xAxisField={xAxisField}
              download={download}
              yAxisLabel={yAxisLabel}
              xAxisLabel={xAxisLabel}
            />
          )}
        </mui.core.Box>
      </mui.core.Box>
    </mui.core.Box>
  );
};

export default Stacked;
