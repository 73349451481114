import { ts } from '_core';

import { DraftParams, INTERACTION_PARAMS } from './types';

export default [
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.ALPHA_MODEL_LINEAR_TABULAR_SIGNIFICANCE,
    interactionParams: Object.values(INTERACTION_PARAMS),
    clearInteractionOnRegenerate: ['table_dtypes'],
    defaultParams: { overall_stats: true } as DraftParams,
    title: 'Significance Statistics Report',
    form: false,
    tags: [
      ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.ALPHA_MODEL,
      ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.LINEAR_ALPHA_MODEL,
    ],
    neededFiles: ['linear_alpha_model_stats'],

    helpLabel: 'ih9edwpd',
  },
] satisfies ts.types.analysis.WidgetConfig[];
