import preferencesMenu from '_core/config/preferences-menu';
import {
  SetApiOpenType,
  SetApiSelectedTabType,
  SetChatBotOpenType,
  SetChatBotShowType,
  SetCurrentTabType,
  SetDocumentSidebarOpenType,
  SetDocumentSidebarUrlType,
  SetPreferencesLastUpdated,
  SetPreferencesOpenType,
  SetPreferencesSelectedTabType,
  SetScheduleDemoOpenType,
  SetUserPreferencesType,
  SetWorkspaceMetadataType,
  UpdateCurrentWorkspaceType,
  UpdateUiVersionType,
} from '_core/reducers/ui';
import { StoreState } from '_core/typescript-definitions/store';

import { Dispatch } from 'react';
import { PREFERENCES_INNER_TABS_ENUM, PREFERENCES_TABS_ENUM } from '@local/finsera-core/src/enums';
import { CurrentTab } from '@local/finsera-core/src/types/common';
import { LAST_UPDATED_PREFERENCES, OPEN_PREFERENCES, PREFERENCES } from '@local/finsera-core/src/types/preferences';
import { WorkspacesMetadata } from '@local/finsera-core/src/types/workspace';

import {
  SET_API_OPEN,
  SET_API_SELECTED_TAB,
  SET_CHATBOT_OPEN,
  SET_CHATBOT_SHOW,
  SET_CURRENT_TAB,
  SET_DOCUMENTS_SIDEBAR_OPEN,
  SET_DOCUMENTS_SIDEBAR_URL,
  SET_PREFERENCES_LAST_UPDATED,
  SET_PREFERENCES_OPEN,
  SET_PREFERENCES_SELECTED_TAB,
  SET_SCHEDULE_DEMO_OPEN,
  SET_USER_PREFERENCES,
  SET_WORKSPACES_METADATA,
  UPDATE_CURRENT_WORKSPACE,
  UPDATE_UI_VERSION,
} from './types';

// This method updates the state of the current workpace
export const setCurrentTab = (currentTab: CurrentTab) => (dispatch: Dispatch<SetCurrentTabType>) =>
  dispatch({ type: SET_CURRENT_TAB, payload: { currentTab } });

export const updateCurrentWorkspaceId = (id: number) => (dispatch: Dispatch<UpdateCurrentWorkspaceType>) =>
  dispatch({ type: UPDATE_CURRENT_WORKSPACE, payload: { id } });

export const updateWorkspacesMetadata =
  (workspacesMetadata: WorkspacesMetadata) => (dispatch: Dispatch<SetWorkspaceMetadataType>) =>
    dispatch({ type: SET_WORKSPACES_METADATA, payload: { workspacesMetadata } });

export const updatePreferences = (preferences: PREFERENCES) => (dispatch: Dispatch<SetUserPreferencesType>) =>
  dispatch({ type: SET_USER_PREFERENCES, payload: { preferences } });

export const setPreferencesOpen = (preferencesOpen: OPEN_PREFERENCES) => (dispatch: Dispatch<SetPreferencesOpenType>) =>
  dispatch({ type: SET_PREFERENCES_OPEN, payload: { preferencesOpen } });

export const setPreferencesLastUpdated =
  (lastUpdated: LAST_UPDATED_PREFERENCES) => async (dispatch: Dispatch<SetPreferencesLastUpdated>) => {
    dispatch({
      type: SET_PREFERENCES_LAST_UPDATED,
      payload: { preferencesLastUpdated: lastUpdated },
    });
    await new Promise((r) => setTimeout(r, 500));
    // Reset after some time to reenable the trigger
    dispatch({ type: SET_PREFERENCES_LAST_UPDATED, payload: { preferencesLastUpdated: null } });
  };

export const setPreferencesSelectedTab =
  (preferencesSelectedTab: PREFERENCES_TABS_ENUM) =>
  (dispatch: Dispatch<SetPreferencesSelectedTabType | SetPreferencesOpenType>) => {
    const preferencesSelectedInnerTab = preferencesMenu[preferencesSelectedTab]?.[0];

    dispatch({ type: SET_PREFERENCES_OPEN, payload: { preferencesOpen: { open: true } } });
    return dispatch({
      type: SET_PREFERENCES_SELECTED_TAB,
      payload: {
        preferencesSelectedTab,
        preferencesSelectedInnerTab,
      },
    });
  };

export const setPreferencesSelectedInnerTab =
  (preferencesSelectedInnerTab: PREFERENCES_INNER_TABS_ENUM, preferenceId?: number, sourceWidgetId?: string) =>
  (dispatch: Dispatch<SetPreferencesSelectedTabType | SetPreferencesOpenType>) => {
    const preferencesSelectedTab = Object.keys(preferencesMenu).find((key) =>
      preferencesMenu[key as PREFERENCES_TABS_ENUM].includes(preferencesSelectedInnerTab)
    ) as PREFERENCES_TABS_ENUM;

    if (parent) {
      dispatch({
        type: SET_PREFERENCES_OPEN,
        payload: { preferencesOpen: { open: true, source_widget_id: sourceWidgetId } },
      });

      return dispatch({
        type: SET_PREFERENCES_SELECTED_TAB,
        payload: {
          preferencesSelectedTab,
          preferencesSelectedInnerTab,
          preferencesSelectedId: preferenceId,
          source_widget_id: sourceWidgetId,
        },
      });
    }
  };

export const setDocumentSidebarOpen = (documentOpen: boolean) => (dispatch: Dispatch<SetDocumentSidebarOpenType>) =>
  dispatch({ type: SET_DOCUMENTS_SIDEBAR_OPEN, payload: { documentOpen } });

export const setDocumentSidebarUrl = (documentUrl: string) => (dispatch: Dispatch<SetDocumentSidebarUrlType>) =>
  dispatch({ type: SET_DOCUMENTS_SIDEBAR_URL, payload: { documentUrl } });

export const setApiOpen = (apiOpen: boolean) => (dispatch: Dispatch<SetApiOpenType>) =>
  dispatch({ type: SET_API_OPEN, payload: { apiOpen } });

export const setScheduleDemoOpen = (scheduleDemoOpen: boolean) => (dispatch: Dispatch<SetScheduleDemoOpenType>) =>
  dispatch({ type: SET_SCHEDULE_DEMO_OPEN, payload: { scheduleDemoOpen } });

export const setApiSelectedTab = (apiSelectedTab: number) => (dispatch: Dispatch<SetApiSelectedTabType>) =>
  dispatch({
    type: SET_API_SELECTED_TAB,
    payload: { apiSelectedTab },
  });

export const updateUiVersion =
  (version: string) => (dispatch: Dispatch<UpdateUiVersionType>, getState: () => StoreState) => {
    const currentVersion = getState().ui.currentUiVersion;
    if (currentVersion !== version) {
      dispatch({ type: UPDATE_UI_VERSION, payload: { version } });
    }
  };

export const setChatbotOpen = (chatbotOpen: boolean) => (dispatch: Dispatch<SetChatBotOpenType>) =>
  dispatch({ type: SET_CHATBOT_OPEN, payload: { chatbotOpen } });

export const setChatbotShow = (chatbotShow: boolean) => (dispatch: Dispatch<SetChatBotShowType>) =>
  dispatch({ type: SET_CHATBOT_SHOW, payload: { chatbotShow } });
