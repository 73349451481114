import { _, hooks, mui, React, ts, ui, useSelector } from '_core';

import { getRbicsChildren, getTopBucketsAndMergeRest, prepareNames } from './helpers';
import InteractionForm from './interaction-form';
import { Params } from './types';

type ChartProps = {
  id: string;
  readOnly: boolean;
  loadWidgetData: (_payload: ts.types.widgets.WidgetGetDataParams) => Promise<ts.types.widgets.WidgetGetDataResponse>;
  file: string;
  fullScreen: boolean;
  visualization: 'count' | 'percent';
  params: Params;
  setParams: (_p: Params) => void;
  title: string;
  subtitle?: string;
  setExternalEmbed: (_json: ts.types.analysis.EmbedChartData) => void;
};

const Chart: React.FC<ChartProps> = ({
  id,
  readOnly,
  loadWidgetData,
  file,
  fullScreen,
  visualization,
  params,
  setParams,
  title,
  subtitle,
  setExternalEmbed,
}) => {
  const resources = useSelector((state) => state.resources);

  const [data, setData] = React.useState<ts.types.widgets.TableData>(null);
  const [localParams, setLocalParams] = React.useState(params);
  const [alert, setAlert] = React.useState<{ type: 'error' | 'warning'; message: string } | null>(null);

  hooks.useEffectWithoutFirst(() => {
    setParams(localParams);
  }, [localParams]);

  const getSelected = () => {
    if (_.isEmpty(localParams.selectedRbics)) return [];
    return localParams.selectedRbics
      .map((sel) => getRbicsChildren(localParams.parent, sel.value, resources.rbics_data))
      .flat();
  };

  const selectedCols = React.useMemo(() => getSelected(), [localParams.selectedRbics]);

  const loadData = async () => {
    try {
      const response = await loadWidgetData({
        data: {
          file,
          values_filter: [
            {
              column: 'monthly_frequency',
              value: visualization == 'percent',
              operator: 'is',
              column_type: ts.types.components.dataGrid.COLUMNS_TYPE_ENUM.BOOLEAN,
            },
          ],
        },
      });
      if (response) {
        const dataWithoutFrequency = _.map(response.data, (obj) => _.omit(obj, 'monthly_frequency'));

        setData(prepareNames(dataWithoutFrequency, localParams.by, resources));
      }
    } catch (err) {
      setAlert({ type: 'error', message: 'Error loading data' });
    }
  };

  const filteredData = React.useMemo(() => {
    if (!data) return null;
    const localData =
      localParams.chartType == 'top'
        ? getTopBucketsAndMergeRest(data, 19)
        : _.map(data, (obj) => _.pick(obj, ['date', ...selectedCols.map((g) => g.value)]));

    return localData;
  }, [data, selectedCols, localParams.chartType]);

  React.useEffect(() => {
    setData(null);
    setExternalEmbed(null);

    if (file && localParams.chartType == 'top') loadData();
    if (file && localParams.chartType == 'rbics' && !_.isNull(selectedCols)) loadData();
    return () => setData(null);
  }, [file, visualization]);

  if (_.isNil(filteredData)) {
    return <ui.CenteredLoader label="Loading data..." />;
  }

  return (
    <mui.core.Box display="flex" flexDirection="column" height="100%">
      <InteractionForm<typeof localParams> params={localParams} setParams={setLocalParams} fullScreen={fullScreen} />
      <mui.core.Box flexGrow={1}>
        {visualization == 'percent' ? (
          <ui.StackedChart
            id={id}
            data={filteredData}
            fullScreen={fullScreen}
            readOnly={readOnly}
            alert={alert}
            exportTitle={title}
            exportSubTitle={subtitle}
            setExternalEmbed={setExternalEmbed}
            yAxisLabel="Percentage Coverage"
            xAxisLabel="Time"
          />
        ) : (
          <ui.LineChart
            id={id}
            data={filteredData}
            fullScreen={fullScreen}
            readOnly={readOnly}
            alert={alert}
            exportTitle={title}
            exportSubTitle={subtitle}
            setExternalEmbed={setExternalEmbed}
            yAxisLabel="Asset Count"
            numberFormat="#"
            xAxisLabel="Time"
          />
        )}
      </mui.core.Box>
    </mui.core.Box>
  );
};

export default Chart;
