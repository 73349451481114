import React from 'react';

import { Group } from './element';
import { useUiStyles } from '../../../hooks';
import { mui } from '../../../libs';
import * as types from '../../../types';

type QueryBuilderComponentsProps = {
  definition: any[];
  setDefinition: (_d: any[]) => void;
  config: types.components.queryBuilder.QbConfig;
};

const QueryBuilderComponents: React.FC<QueryBuilderComponentsProps> = ({
  definition = ['$and'],
  setDefinition = () => undefined,
  config,
}): React.ReactElement => {
  const uiStyles = useUiStyles();
  const theme = mui.styles.useTheme();

  return (
    <>
      {config.readOnly && (
        <mui.core.Box
          sx={{
            background: (theme.palette as any).background.default,
            color: (theme.palette as any).text.disabled,
            fontSize: '0.6rem',
            fontWeight: 500,
            padding: '4px',
          }}
        >
          read only mode
        </mui.core.Box>
      )}
      <mui.core.Box sx={config.readOnly ? uiStyles.readOnlyMode : {}}>
        <Group
          definition={definition?.length == 0 || !definition ? ['$and'] : definition}
          setDefinition={setDefinition}
          config={config}
        />
      </mui.core.Box>
    </>
  );
};

export default QueryBuilderComponents;
