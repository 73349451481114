import { _, helpers, ts } from '_core';

import { DraftParams } from './types';
import { getBucketsTitle } from '../../common/utils/get-name';

export const validate = (params: DraftParams) => {
  const rhErrors = helpers.validations.validSelectedReturnHorizon(params?.return_horizon);
  if (!_.isEmpty(rhErrors)) return rhErrors;
  if (!helpers.validations.validBuckets(params.buckets)) return ['Buckets are invalid.'];
  return [];
};

export const getName = (
  params: DraftParams,
  _context: ts.types.signal.SignalAnalysisContext,
  resources: ts.StoreState['resources']
) => {
  const init = getBucketsTitle(params.buckets, 'Portfolio-weighted Cumulative Returns by Bucket', resources);
  return `${init} for ${helpers.returnHorizon.getLabel(params?.return_horizon)}`;
};
