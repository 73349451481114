import { ts } from '_core';

import { DraftParams, INTERACTION_PARAMS } from './types';

export default [
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.BASKET_RETURN_TIME,
    title: 'Returns vs. Time',
    helpLabel: 'o5up9l7l',
    form: false,
    defaultParams: {
      isBasket: true,
      type: 'return',
      aggregation: 'ANNUAL',
    } as DraftParams,
    // Adding bucket and joint for legacy issues
    interactionParams: Object.values(INTERACTION_PARAMS),
    neededFiles: ['backtest_statistics_vs_time'],
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.BASKET],
  },
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.BASKET_RISK_TIME,
    title: 'Risk vs. Time',
    helpLabel: 'ghvmrp3v',
    form: false,
    defaultParams: {
      isBasket: true,
      type: 'risk',
      aggregation: 'ANNUAL',
    } as DraftParams,
    // Adding bucket and joint for legacy issues
    interactionParams: Object.values(INTERACTION_PARAMS),
    neededFiles: ['backtest_statistics_vs_time'],
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.BASKET],
  },
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.BASKET_ADJUSTED_TIME,
    title: 'Risk Adjusted Returns vs. Time',
    helpLabel: 'ahuhehxt',
    form: false,
    defaultParams: {
      isBasket: true,
      type: 'adjusted',
      aggregation: 'ANNUAL',
    } as DraftParams,
    // Adding bucket and joint for legacy issues
    interactionParams: Object.values(INTERACTION_PARAMS),
    neededFiles: ['backtest_statistics_vs_time'],
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.BASKET],
  },
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.BACKTEST_RETURN_TIME,
    title: 'Returns vs. Time',
    helpLabel: 'o7005rtq',
    form: false,
    defaultParams: {
      isBasket: false,
      type: 'return',
      aggregation: 'ANNUAL',
    } as DraftParams,
    // Adding bucket and joint for legacy issues
    interactionParams: Object.values(INTERACTION_PARAMS),
    neededFiles: ['backtest_statistics_vs_time'],
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.BACKTEST],
  },
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.BACKTEST_RISK_TIME,
    title: 'Risk vs. Time',
    helpLabel: 'npka5kqh',
    form: false,
    defaultParams: {
      isBasket: false,
      type: 'risk',
      aggregation: 'ANNUAL',
    } as DraftParams,
    // Adding bucket and joint for legacy issues
    interactionParams: Object.values(INTERACTION_PARAMS),
    neededFiles: ['backtest_statistics_vs_time'],
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.BACKTEST],
  },
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.BACKTEST_ADJUSTED_TIME,
    title: 'Risk Adjusted Returns vs. Time',
    helpLabel: 'oymffclw',
    form: false,
    defaultParams: {
      isBasket: false,
      type: 'adjusted',
      aggregation: 'ANNUAL',
    } as DraftParams,
    // Adding bucket and joint for legacy issues
    interactionParams: Object.values(INTERACTION_PARAMS),
    neededFiles: ['backtest_statistics_vs_time'],
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.BACKTEST],
  },
] satisfies ts.types.analysis.WidgetConfig[];
