// ********************** //
// BASED ON https://github.com/cwise89/react-detect-offline/blob/master/src/index.js //
// ********************** //

import { Children, Component, createElement, isValidElement } from 'react';

import ContextSingleton from '../../__singletons__/context-singleton';

const inBrowser = typeof navigator !== 'undefined';

// these browsers don't fully support navigator.onLine, so we need to use a polling backup
const unsupportedUserAgentsPattern = /Windows.*Chrome|Windows.*Firefox|Linux.*Chrome/;

const fetchWithTimeOut = (url: string, options: RequestInit, timeout = 10_000) => {
  return Promise.race([
    fetch(url, options),
    new Promise((_, reject) => setTimeout(() => reject(new Error('timeout')), timeout)),
  ]);
};

const ping = ({ url, timeout }: { url: string; timeout: number }) => {
  const store = ContextSingleton.getInstance().store;
  const actions = ContextSingleton.getInstance().actions;
  return new Promise((resolve) => {
    const isOnline = () => resolve(true);
    const isOffline = () => resolve(false);
    fetchWithTimeOut(
      url,
      {
        headers: {
          'sec-fetch-mode': 'navigate',
        },
      },
      timeout
    )
      .then((resp) => {
        if ((resp as Response).status) {
          // Check for UI version header and trigger update
          const uiVersion = (resp as Response).headers.get('x-ui');
          if (uiVersion) {
            // Assuming store is available globally
            store.dispatch(actions.ui.updateUiVersion(uiVersion) as any);
          }
          isOnline();
        } else {
          isOffline();
        }
      })
      .catch(() => {
        isOffline();
      });
  });
};

const defaultProps = {
  polling: true,
  wrapperType: 'span',
};

const defaultPollingConfig = {
  enabled: inBrowser && unsupportedUserAgentsPattern.test(navigator.userAgent),
  url: 'https://httpbin.org/get',
  timeout: 5000,
  interval: 5000,
};

interface PollingConfig {
  url: string;
  enabled: boolean;
  interval: number;
  timeout: number;
}

interface BaseProps {
  children?: React.ReactNode;
  onChange?: (_online: boolean) => void | undefined;
  wrapperType?: string;
  polling?: boolean | PollingConfig;
}

interface BaseState {
  online: boolean;
}

// base class that detects offline/online changes
class Base extends Component<BaseProps, BaseState> {
  constructor(props: any) {
    super(props);
    this.state = {
      online: inBrowser && typeof navigator.onLine === 'boolean' ? navigator.onLine : true,
    };
    // bind event handlers
    this.goOnline = this.goOnline.bind(this);
    this.goOffline = this.goOffline.bind(this);
  }

  componentDidMount() {
    window.addEventListener('online', this.goOnline);
    window.addEventListener('offline', this.goOffline);

    if (this.getPollingConfig().enabled) {
      this.startPolling();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('online', this.goOnline);
    window.removeEventListener('offline', this.goOffline);

    if ((this as any).pollingId) {
      this.stopPolling();
    }
  }

  renderChildren() {
    const { children, wrapperType } = this.props;

    // usual case: one child that is a react Element
    if (isValidElement(children)) {
      return children;
    }

    // no children
    if (!children) {
      return null;
    }

    // string children, multiple children, or something else
    return createElement(wrapperType, {}, ...Children.toArray(children));
  }

  getPollingConfig() {
    switch (this.props.polling) {
      case true:
        return defaultPollingConfig;
      case false:
        return { enabled: false };
      default:
        return Object.assign({}, defaultPollingConfig, this.props.polling);
    }
  }

  goOnline() {
    if (!this.state.online) {
      this.callOnChangeHandler(true);
      this.setState({ online: true });
    }
  }

  goOffline() {
    if (this.state.online) {
      this.callOnChangeHandler(false);
      this.setState({ online: false });
    }
  }

  callOnChangeHandler(online: boolean) {
    if (this.props.onChange) {
      this.props.onChange(online);
    }
  }

  startPolling() {
    const { interval } = this.getPollingConfig() as PollingConfig;
    (this as any).pollingId = setInterval(() => {
      const { url, timeout } = this.getPollingConfig() as PollingConfig;
      ping({ url, timeout }).then((online) => {
        online ? this.goOnline() : this.goOffline();
      });
    }, interval);
  }

  stopPolling() {
    clearInterval((this as any).pollingId);
  }
}
(Base as any).defaultProps = defaultProps;

/**
 * Component that renders its children only when online.
 * Extends Base class which handles polling and online state management.
 * @extends {Base}
 */
class Online extends Base {
  render() {
    return this.state.online ? this.renderChildren() : null;
  }
}
(Online as any).defaultProps = defaultProps;

/**
 * Component that renders its children only when offline.
 * Extends Base class which handles polling and online state management.
 * @extends {Base}
 */
class Offline extends Base {
  render() {
    return !this.state.online ? this.renderChildren() : null;
  }
}
(Offline as any).defaultProps = defaultProps;

class Detector extends Base {
  render() {
    return (this.props as any).render({ online: this.state.online });
  }
}
(Detector as any).defaultProps = defaultProps;

export { Online };
export { Offline };
