import { am5 } from '@local/finsera-core/src/libs';

export const addLabels = ({
  root,
  yAxis,
  xAxis,
  yAxisLabel,
  xAxisLabel,
}: {
  root: am5.Root;
  yAxis: any;
  xAxis: any;
  yAxisLabel: string | null;
  xAxisLabel: string | null;
  disableAxisLabel?: boolean;
}) => {
  if (yAxisLabel) {
    yAxis.children.push(
      am5.Label.new(root, {
        text: yAxisLabel,
        textAlign: 'center',
        rotation: -90,
        y: am5.p50,
        x: -30,
        fontWeight: 'semibold',
        fontSize: 11,
      })
    );
  }

  if (xAxisLabel) {
    xAxis.children.push(
      am5.Label.new(root, {
        text: xAxisLabel,
        textAlign: 'center',
        x: am5.p50,
        y: 20,
        fontWeight: 'semibold',
        fontSize: 11,
      })
    );
  }
};
