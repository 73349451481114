import { _, hooks, React, ts, useSelector } from '_core';

import * as utils from './fixed-costs-utils';

type ProviderProps = {
  children: React.ReactNode;
};

type LocalRows = { rows: ts.types.fixedCosts.FixedCostDraft[]; noValidate?: boolean };

type FixedCostsContextTypes = {
  alert: ts.types.common.Alert;
  setAlert: (_a: ts.types.common.Alert) => void;

  validationDef: ts.types.common.ValidationErrors;
  rowsChanged: boolean;
  setRowsChanged: (_b: boolean) => void;
  validating: boolean;

  rows: LocalRows;
  setRows: (_r: LocalRows) => void;

  saving: boolean;
  loadingNewCountry: boolean;

  addNewRow: () => void;
  saveRows: () => Promise<void>;

  setCssChange: (_i: number, _c?: string) => void;
  updatePropertyById: (_id: number, _p: string, _v: string | number) => void;
};

const FixedCostContext = React.createContext<FixedCostsContextTypes>(null);

// This context provider is passed to any component requiring the context
const Provider: React.FC<ProviderProps> = ({ children }): React.ReactElement => {
  // example: {message: 'something', severity: ts.enums.ALERT_SEVERITY_ENUM.ERROR}
  const [alert, setAlert] = React.useState<ts.types.common.Alert>();

  const [saving, setSaving] = React.useState(false);

  const [changedRows, setChangedRows] = React.useState([] as ts.types.fixedCosts.FixedCostDraft[]);

  const [rows, setRows] = React.useState({
    rows: [],
  } as LocalRows);

  const [validationDef, setValidationDef] = React.useState<ts.types.common.ValidationErrors>({
    valid: false,
  });
  const [rowsChanged, setRowsChanged] = React.useState(false);
  const [validating, setValidating] = React.useState(false);

  const [loadingNewCountry, setLoadingNewCountry] = React.useState(false);
  const fixedCosts = useSelector((store) => store.resources.fixed_costs);

  React.useEffect(() => {
    const newRows = [...fixedCosts];
    newRows[0].country_code = 'DEFAULT';
    setRows({ rows: newRows });
  }, []);

  hooks.useDefinitionValidate<LocalRows>({
    id: 0,
    validate: () => {
      setValidationDef(utils.validateRows(rows.rows));
      setRowsChanged(true);
    },
    definition: rows,
    setValidating,
  });

  const setCssChange = (index: number, color = 'rgb(255, 226, 145)') => {
    const all = document.getElementById('fixed-costs-table').querySelectorAll('[role="row"]');
    const cells: NodeListOf<HTMLElement> = all[index + 1]?.querySelectorAll('[role="cell"]');

    for (let i = 0; i < cells.length; i++) {
      cells[i].style.backgroundColor = color;
    }
  };

  const updateChangedRows = (row: ts.types.fixedCosts.FixedCostDraft) => {
    if (!changedRows.some((el) => el.id == row.id)) {
      const newChangedRows = [...changedRows];
      newChangedRows.push(row);
      setChangedRows(newChangedRows);
    }
  };

  const updatePropertyById = (id: number, property: string, value: string | number) => {
    const updatedRows = [...rows.rows];
    let index: number;

    _.each(rows.rows, (r, idx) => {
      if (r.id == id) {
        index = idx;
        return;
      }
    });

    (updatedRows[index][property as keyof ts.types.fixedCosts.FixedCost] as string | number) = value;
    updateChangedRows(updatedRows[index]);

    setCssChange(index);
    setRows({ rows: updatedRows });
  };

  const addNewRow = () =>
    utils.apiProcedures.createFixedCostRow(
      () => setLoadingNewCountry(true),
      (createdFixedCost) => {
        setRows({ rows: rows.rows.concat(createdFixedCost) });
        setLoadingNewCountry(false);
      },
      (err) => {
        setAlert({
          severity: ts.enums.ALERT_SEVERITY_ENUM.ERROR,
          message: err,
        });
        setLoadingNewCountry(false);
      }
    );

  const saveRows = async () => {
    setSaving(true);

    if (changedRows.length == 0) {
      setAlert({
        severity: ts.enums.ALERT_SEVERITY_ENUM.INFO,
        message: 'No changed rows',
      });
      return;
    }

    for (const row of changedRows) {
      const currentRow = { ...row };
      if (currentRow?.country_code == 'DEFAULT') {
        delete currentRow.country_code;
      }

      await utils.apiProcedures.saveChangedRows(
        currentRow?.id,
        currentRow,
        (updatedFixedCost) => {
          setRows((r) => ({
            rows: r.rows.map((r) => {
              if (r.id == updatedFixedCost.id)
                return {
                  ...updatedFixedCost,
                  country_code: r.country_code,
                };
              return r;
            }),
            noValidate: true,
          }));

          const index = rows.rows.findIndex((el) => el.id == updatedFixedCost.id);

          if (index >= 0) setCssChange(index, '#fff');
          setRowsChanged(false);
        },
        (err) =>
          setAlert({
            severity: ts.enums.ALERT_SEVERITY_ENUM.ERROR,
            message: err,
          })
      );
    }

    setSaving(false);
  };

  return (
    <FixedCostContext.Provider
      value={{
        alert,
        setAlert,

        validationDef,
        rowsChanged,
        setRowsChanged,
        validating,

        rows,
        setRows,

        saving,
        loadingNewCountry,

        addNewRow,
        saveRows,

        setCssChange,
        updatePropertyById,
      }}
    >
      {children}
    </FixedCostContext.Provider>
  );
};

const FixedCostContextProvider = Provider;

export { FixedCostContext };
export { FixedCostContextProvider };
