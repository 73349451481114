import { ts } from '_core';

import { getName, validate } from './helpers';
import { DraftParams } from './types';

export default [
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.BACKTEST_PREDICTED_AND_REALIZED_RISK_VS_TIME,
    title: 'Predicted vs. Realized Risk over Time',
    helpLabel: 'e1xs7d2x',
    form: true,
    defaultParams: {
      window: 21,
      unlevered: false,
    } as DraftParams,
    neededFiles: ['backtest_realized_predicted_forecast'],
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.BACKTEST_PORTFOLIO_RISK],

    validate,
    getName,
  },
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.PORTFOLIO_PREDICTED_AND_REALIZED_RISK_VS_TIME,
    title: 'Predicted vs. Realized Risk over Time',
    helpLabel: 'j0k2fts5',
    form: true,
    defaultParams: {
      window: 21,
      unlevered: false,
    } as DraftParams,
    neededFiles: ['portfolio_realized_predicted_forecast'],
    tags: [
      ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.PORTFOLIO_RISK,
      ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.PORTFOLIO_STRAT_RISK,
    ],

    validate,
    getName,
  },
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.SIGNAL_PREDICTED_AND_REALIZED_RISK_VS_TIME,
    title: 'Predicted vs. Realized Risk over Time',
    helpLabel: 'gxaisq4k',
    form: true,
    defaultParams: {
      window: 21,
      is_signal: true,
    } as DraftParams,
    neededFiles: ['signal_realized_predicted_forecast'],
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.SIGNAL, ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.ALPHA_MODEL],

    validate,
  },
] satisfies ts.types.analysis.WidgetConfig[];
