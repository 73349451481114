import { _, config, dnd, hooks, mui, React, ts, ui, uitheme } from '_core';

type MoveColumnsProps = {
  configColumns: ts.types.components.dataGrid.ColumnsData;
  columns: ts.types.components.dataGrid.ColumnsData;
  setColumns: (_columns: ts.types.components.dataGrid.ColumnsData) => void;
  names: Record<string, string>;
  setNames: (_p: Record<string, string>) => void;
  frozenColumns?: ts.types.components.dataGrid.ColumnsData;
  setFrozenColumns?: (_columns: ts.types.components.dataGrid.ColumnsData) => void;
  gridHeight?: number;
  topAction?: React.ReactElement;
};

const MoveColumns: React.FC<MoveColumnsProps> = ({
  configColumns,
  columns,
  setColumns,
  names,
  setNames,
  frozenColumns,
  setFrozenColumns,
  topAction,
}) => {
  const DRAG_DROP_HEIGHT = uitheme.layoutSize.DRAG_DROP_HEIGHT;
  const localColumns = React.useMemo(
    () => ({
      columns,
      frozenColumns,
    }),
    [columns, frozenColumns]
  );

  const setLocalColumns = (lc: {
    columns: ts.types.components.dataGrid.ColumnsData;
    frozenColumns: ts.types.components.dataGrid.ColumnsData;
  }) => {
    setColumns(lc.columns);
    setFrozenColumns(lc.frozenColumns);
  };

  hooks.useEffectWithoutFirst(() => {
    setColumns(localColumns.columns);
    setFrozenColumns(localColumns.frozenColumns);
  }, [localColumns]);

  const optionColumns = React.useMemo(() => {
    return configColumns.filter(
      (c) =>
        !_.some(localColumns.frozenColumns, (fc) => fc.key == c.key) &&
        !c.isFixed &&
        (!c.condition || c.condition(config.features))
    );
  }, [configColumns, localColumns.frozenColumns]);

  const onChange = (_: string, sourceIndex: number, targetIndex: number, targetKey: keyof typeof localColumns) => {
    const nextState = dnd.swap(localColumns[targetKey], sourceIndex, targetIndex);
    setLocalColumns({ ...localColumns, [targetKey]: nextState });
  };

  const removeColumn = (index: number) => {
    const newColumns = [...localColumns.columns];
    newColumns.splice(index, 1);
    setLocalColumns({ ...localColumns, columns: newColumns });
  };

  const emptyColumns = (
    <mui.core.Grid container sx={{ justifyContent: 'center' }}>
      <mui.core.Grid item xs={12} sm={6} lg={5}>
        <ui.EmptyMessage>You need to select at least one column.</ui.EmptyMessage>
      </mui.core.Grid>
    </mui.core.Grid>
  );

  const toggleFreezeColumns = (columnKey: string, freeze: boolean) => {
    const filterColumns = (colArray: ts.types.components.dataGrid.ColumnsData) => {
      const columnToBeAdded = colArray.find((c) => c.key == columnKey);
      const filteredColumns = colArray.filter((c) => c.key != columnKey);

      return { columnToBeAdded, filteredColumns };
    };

    if (freeze) {
      const { columnToBeAdded, filteredColumns } = filterColumns(columns);
      const newFrozenColumns = [...frozenColumns, columnToBeAdded];

      setLocalColumns({
        columns: filteredColumns,
        frozenColumns: newFrozenColumns,
      });
    } else {
      const { columnToBeAdded, filteredColumns } = filterColumns(frozenColumns);
      const newColumns = [...columns, columnToBeAdded];

      setLocalColumns({ columns: newColumns, frozenColumns: filteredColumns });
    }
  };

  const renderFreezeButton = (column: ts.types.components.dataGrid.ColumnsData[0], isFrozen: boolean) => {
    if (!isFrozen)
      return (
        <mui.core.Tooltip title="Freeze Column" arrow sx={isFrozen ? {} : { visibility: 'hidden' }}>
          <mui.core.IconButton aria-label="freeze" size="small" onClick={() => toggleFreezeColumns(column.key, true)}>
            <mui.icons.Lock style={{ fontSize: '0.7rem' }} />
          </mui.core.IconButton>
        </mui.core.Tooltip>
      );

    return (
      <mui.core.Tooltip title="Unfreeze Column" arrow>
        <mui.core.IconButton aria-label="freeze" size="small" onClick={() => toggleFreezeColumns(column.key, false)}>
          <mui.icons.LockOpen style={{ fontSize: '0.7rem' }} />
        </mui.core.IconButton>
      </mui.core.Tooltip>
    );
  };

  const renderActionButtons = (column: ts.types.components.dataGrid.ColumnsData[0], isFrozen: boolean) => (
    <>
      {!column.members && (
        <mui.core.Tooltip title="Edit Name" arrow>
          <mui.core.IconButton
            aria-label="freeze"
            size="small"
            onClick={() => setNameCol(column)}
            sx={{ visibility: 'hidden' }}
          >
            <mui.icons.Edit style={{ fontSize: '0.7rem' }} />
          </mui.core.IconButton>
        </mui.core.Tooltip>
      )}

      {renderFreezeButton(column, isFrozen)}
    </>
  );

  // Name handling
  const [nameCol, setNameCol] = React.useState<ts.types.components.dataGrid.ColumnsData[0]>(null);
  const [newName, setNewName] = React.useState<string>();

  React.useEffect(() => {
    if (nameCol) setNewName(names[nameCol.key] ?? (nameCol.name as string));
    else setNewName(null);
  }, [nameCol]);

  const [frozenHeight, setFrozenHeight] = React.useState<number>(0);
  const [columnsHeight, setColumnsHeight] = React.useState<number>(0);

  React.useEffect(() => {
    const height = Math.ceil(localColumns.frozenColumns.length / 3) * DRAG_DROP_HEIGHT;
    setFrozenHeight(height);
  }, [localColumns.frozenColumns]);

  React.useEffect(() => {
    const height = Math.ceil(localColumns.columns.length / 3) * DRAG_DROP_HEIGHT;
    setColumnsHeight(height);
  }, [localColumns.columns]);

  return (
    <>
      {nameCol && (
        <mui.core.Dialog open onClose={() => setNameCol(null)} fullWidth maxWidth="xs">
          <ui.DialogTitle closeAction={() => setNameCol(null)}>Update {nameCol.name}</ui.DialogTitle>

          <mui.core.DialogContent>
            <mui.core.Box display="flex" justifyContent="right">
              <mui.core.Button onClick={() => setNewName(nameCol.name as string)}>Reset to default</mui.core.Button>
            </mui.core.Box>

            <mui.core.Box pt={3}>
              <mui.core.TextField
                variant="outlined"
                size="small"
                label="Name"
                fullWidth
                value={newName}
                onChange={(e) => setNewName(e.target.value)}
              />
            </mui.core.Box>
          </mui.core.DialogContent>

          <mui.core.DialogActions>
            <mui.core.Button
              onClick={() => {
                setNameCol(null);
                setNames({ ...names, [nameCol.key]: newName });
              }}
              disabled={_.isEmpty(newName)}
              color="primary"
              variant="contained"
            >
              Update
            </mui.core.Button>
          </mui.core.DialogActions>
        </mui.core.Dialog>
      )}

      <mui.core.Grid container spacing={3}>
        <mui.core.Grid item md={4}>
          <mui.core.Autocomplete<ts.types.components.dataGrid.ColumnsData[0], true>
            fullWidth
            renderTags={() => undefined}
            multiple
            size="small"
            options={optionColumns}
            value={localColumns.columns || []}
            onChange={(_e, val) =>
              setLocalColumns({
                ...localColumns,
                columns: val as ts.types.components.dataGrid.ColumnsData,
              })
            }
            getOptionLabel={(option) => (option as ts.types.components.dataGrid.ColumnsData[0]).name as string}
            ListboxProps={{ style: { maxHeight: '400px' } }}
            isOptionEqualToValue={(option, value) => option.key === value.key}
            renderInput={(p) => (
              <mui.core.TextField {...p} label="Add/Remove Columns..." variant="outlined" size="small" />
            )}
            disableCloseOnSelect
            renderGroup={(params) => {
              if (!params.group) return <mui.core.Box key={params.key}>{params.children}</mui.core.Box>;

              return (
                <mui.core.Box key={params.key}>
                  <mui.core.Box py={1} mx={2}>
                    <mui.core.Typography color="primary">{params.group}</mui.core.Typography>
                  </mui.core.Box>
                  {params.children}
                </mui.core.Box>
              );
            }}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <ui.CheckboxItem selected={selected} name={option.name} />
              </li>
            )}
            groupBy={(option) => option.category ?? undefined}
          />
        </mui.core.Grid>
        {topAction && (
          <mui.core.Grid item md={8} sx={{ textAlign: 'right' }}>
            {topAction}
          </mui.core.Grid>
        )}
      </mui.core.Grid>

      {!_.isEmpty(localColumns.frozenColumns) && (
        <mui.core.Box mt={4} mb={8}>
          <mui.core.Typography variant="body2" fontWeight="500" sx={{ mb: 2 }}>
            Frozen Columns
          </mui.core.Typography>
          <dnd.GridContextProvider
            onChange={(sourceId: string, sourceIndex: number, targetIndex: number) =>
              onChange(sourceId, sourceIndex, targetIndex, 'frozenColumns')
            }
          >
            <dnd.GridDropZone id="items" boxesPerRow={3} rowHeight={DRAG_DROP_HEIGHT} style={{ height: frozenHeight }}>
              {localColumns.frozenColumns.map((column, index) => (
                <mui.core.Box
                  sx={{
                    '&:hover .MuiButtonBase-root': {
                      visibility: 'visible',
                    },
                  }}
                  key={index}
                >
                  <ui.DragItem
                    title={names?.[column.key] ?? (column.name as string)}
                    index={index}
                    key={column.key}
                    customAction={renderActionButtons(column, true)}
                    disableRemove
                  />
                </mui.core.Box>
              ))}
            </dnd.GridDropZone>
          </dnd.GridContextProvider>
        </mui.core.Box>
      )}

      <mui.core.Box mt={4} mb={8}>
        {!_.isEmpty(localColumns.frozenColumns) && (
          <mui.core.Typography variant="body2" fontWeight="500">
            Regular Columns
          </mui.core.Typography>
        )}
        {_.isEmpty(localColumns.columns) ? (
          emptyColumns
        ) : (
          <mui.core.Box mt={3}>
            <dnd.GridContextProvider
              onChange={(sourceId: string, sourceIndex: number, targetIndex: number) =>
                onChange(sourceId, sourceIndex, targetIndex, 'columns')
              }
            >
              <dnd.GridDropZone
                id="items"
                boxesPerRow={3}
                rowHeight={DRAG_DROP_HEIGHT}
                style={{ height: columnsHeight }}
              >
                {localColumns.columns.map((column, index) => (
                  <mui.core.Box
                    sx={{
                      '&:hover .MuiButtonBase-root': {
                        visibility: 'visible',
                      },
                    }}
                    key={index}
                  >
                    <ui.DragItem
                      title={names?.[column.key] ?? (column.name as string)}
                      index={index}
                      key={column.key}
                      removeColumn={() => removeColumn(index)}
                      customAction={renderActionButtons(column, false)}
                    />
                  </mui.core.Box>
                ))}
              </dnd.GridDropZone>
            </dnd.GridContextProvider>
          </mui.core.Box>
        )}
      </mui.core.Box>
    </>
  );
};

MoveColumns.defaultProps = {
  names: {},
};

export default MoveColumns;
