import { _, am4charts, am4core, ts } from '_core';

export const addExportAdapter = (
  chart: am4charts.XYChart,
  rawData: ts.types.widgets.LinearData,
  xAxis: string,
  customDownloadFormatting?: (_d: Record<string, string | number>[]) => Record<string, string | number>[],
  widgetIsEmbedded?: {
    download: boolean;
  }
) => {
  chart.exporting.menu = new am4core.ExportMenu();
  chart.exporting.menu.align = 'left';
  chart.fontSize = 11;

  chart.exporting.menu.items = widgetIsEmbedded
    ? widgetIsEmbedded.download
      ? [
          {
            label: '...',
            menu: [{ type: 'csv', label: 'Download as CSV' }],
          },
        ]
      : []
    : [
        {
          label: '...',
          menu: [
            { type: 'jpg', label: 'JPG' },
            { type: 'png', label: 'PNG' },
            { type: 'csv', label: 'CSV' },
          ],
        },
      ];
  chart.exporting.filePrefix = 'chart-data';

  chart.exporting.adapter.add('data', () => {
    const exportData = [] as { date: string; [key: string]: any }[];
    chart.series.each((series) => {
      for (let i = 0; i < rawData[series.name].length; i++) {
        const row = { ...rawData[series.name][i], name: series.name } as {
          date: string;
          [key: string]: any;
        };
        exportData.push(row);
      }
    });

    // Date - serie1 - serie2 ... - serieX as csv headers
    const grouped = _.groupBy(exportData, xAxis);
    const localData = Object.keys(grouped).map((date) => {
      const el = grouped[date];
      const localObj = { date } as { date: string } & Record<string, number>;
      el.forEach((serieEl) => (localObj[serieEl.name] = serieEl.real));
      return localObj;
    });

    if (customDownloadFormatting) return { data: customDownloadFormatting(localData) };

    return { data: localData };
  });
};
