import { hooks, mui, React, ts, ui } from '_core';

import { DraftParams } from './types';

type FormProps = {
  params: DraftParams;
  setParams: (_p: DraftParams) => void;
  context: ts.types.timeSeries.TimeSeriesAnalysisContext;
};

const Form: React.FC<FormProps> = ({ params, setParams, context }) => {
  const [comparets, setcomparets] = React.useState(params.analysis_time_series_ids || []);

  const uiStyles = hooks.useUiStyles();

  React.useEffect(() => {
    const data = {
      ...params,
      time_series_id: context?.id ? undefined : comparets?.[0],
      analysis_time_series_ids: comparets,
    };
    setParams(data);
  }, [comparets]);

  return (
    <mui.core.Box sx={uiStyles.widgetEditMode}>
      <ui.ResourceAutocomplete<ts.types.timeSeries.TimeSeriesExpanded, true>
        value={comparets}
        setValue={(ids) => setcomparets(ids)}
        type={ts.enums.RESOURCES_TYPES_ENUM.TIME_SERIES}
        autocompleteProps={{
          size: 'small',
          multiple: true,
          disableCloseOnSelect: true,
        }}
        inputProps={{
          label: 'Select Time Series',
          variant: 'outlined',
          size: 'small',
          required: true,
        }}
        filter={context ? (ts) => ts.id !== context.time_series_id : undefined}
      />
    </mui.core.Box>
  );
};

export default Form;
