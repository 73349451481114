import { _, config, mui, React, ts, ui, useSelector } from '_core';

const MyWorkspaces = () => {
  const resources = useSelector((state) => state.resources);
  const { workspaces } = resources;

  const workspacesProd = React.useMemo(() => workspaces.filter((ws) => ws.context == 'production'), [workspaces]);
  const workspacesRes = React.useMemo(() => workspaces.filter((ws) => ws.context == 'research'), [workspaces]);

  const list = () => (
    <>
      <mui.core.Box my={2}>
        <mui.core.Typography variant="body2">
          <strong style={{ fontWeight: 500, padding: '10px' }}>Research</strong>
        </mui.core.Typography>
      </mui.core.Box>
      {workspacesRes.map((w) => (
        <ui.InlineItem
          key={w.id}
          resource={w}
          resourceType={ts.enums.RESOURCES_TYPES_ENUM.WORKSPACE}
          to={`/research?wsid=${w.id}&wscontext=${w.context}`}
        />
      ))}

      {config.features.production_mode && (
        <>
          <mui.core.Box my={2}>
            <mui.core.Typography variant="body2">
              <strong style={{ fontWeight: 500, padding: '10px' }}>Production</strong>
            </mui.core.Typography>
          </mui.core.Box>
          {workspacesProd.map((w) => (
            <ui.InlineItem
              key={w.id}
              resource={w}
              resourceType={ts.enums.RESOURCES_TYPES_ENUM.WORKSPACE}
              to={`/research?wsid=${w.id}&wscontext=${w.context}`}
            />
          ))}
        </>
      )}
    </>
  );

  return (
    <>
      {_.isEmpty(workspaces) ? (
        <ui.EmptyMessage hideBorder>You {"haven't"} created any workspaces yet.</ui.EmptyMessage>
      ) : (
        list()
      )}
    </>
  );
};

export default MyWorkspaces;
