import { _, actions, hooks, mui, React, ts, useDispatch } from '_core';

import { GridRowParams } from '@mui/x-data-grid-premium';

import useGetPreferences from '_components/use-get-preferences';

import {
  convertFiltersToMuiX,
  convertMuiXFilters,
  convertMuiXSortBy,
  convertSortByToMuiX,
} from './utils/muix-converter';

export type GenerateColumns = (
  _colKeys: string[],
  _userPreferences: ts.types.components.dataGrid.ColumnPreferences
) => ts.types.components.dataGrid.ColumnsData;

export type GenerateHeatMappedColumns = (
  _colKeys: string[],
  _userPreferences: ts.types.components.dataGrid.ColumnPreferences
) => string[];

type BaseProviderProps = {
  rowClass?: (_row: Record<string, any>) => string;
  onRowClick?: (_p: GridRowParams<any>) => void;
  rowWidth?: number;
  rowHeight?: number;
  headerHeight?: number;
  customToolbar?: React.FC<{ columns: string[] }>;
  loading?: boolean;
  customHeight?: number;
  hideFilters?: boolean;
  hideDensity?: boolean;
  hideToolbar?: boolean;
  hideDownload?: boolean;
  hideDataCount?: boolean;
  hideColumnWidth?: boolean;
  alert?: ts.types.common.Alert;
  overrideHeight?: number;

  groupBy?: string[];
  expandedGroupIds?: ReadonlySet<unknown>;
  setExpandedGroupIds?: (_v: ReadonlySet<unknown>) => void;
  preferencesSourceWidgetId?: string;
  preferenceKey?: ts.enums.PREFERENCES_KEY_ENUM;
  preferenceTab?: ts.enums.PREFERENCES_INNER_TABS_ENUM;
  openPreferences?: () => void;
  initialTableParams?: ts.types.components.dataGrid.TableParams;
  updateTableParams?: (_v: ts.types.components.dataGrid.TableParams) => void;
  getHeatMappedCols?: GenerateHeatMappedColumns;
  heatMapColors?: ts.types.components.dataGrid.HeatMapColors;
  customStyles?: mui.core.SxProps<mui.core.Theme>;
  disableDefaultStyling?: boolean;
  setExternalEmbed?: (_json: ts.types.analysis.SyncTableEmbedMetadata) => void;

  overrideUserPreferences?: ts.types.components.dataGrid.ColumnPreferences;
};

type RegularProviderProps = BaseProviderProps & {
  tableType: 'regular';
  data: Record<string, any>[];
  triggerWidthChange: boolean;
  getColumns?: GenerateColumns;
  customRow?: () => React.ReactElement;
};

type AsyncProviderProps = BaseProviderProps & {
  tableType: 'async';
  loadData: ts.types.components.dataGrid.LoadData;
  downloadFullData?: ts.types.components.dataGrid.DownloadFullData;
  getColumns?: ts.types.components.dataGrid.GenerateColumns;
  loadOnX?: boolean;
  loadColumnsOnChange?: boolean;
  triggerLoadOnChange?: boolean;
  prepareData?: (_data: ts.types.components.dataGrid.Row[]) => ts.types.components.dataGrid.Row[];
  onDataChange?: (_data: ts.types.components.dataGrid.Row[]) => void;
  sheetFilters?: ts.types.components.dataGrid.ValueFilters;
  initialTableDtypes?: ts.types.components.dataGrid.TableDtypes;
  updateTableDtypes?: (_v: ts.types.components.dataGrid.TableDtypes) => void;
  overrideUserPreferences?: ts.types.components.dataGrid.ColumnPreferences;
};

export type ProviderProps = RegularProviderProps | AsyncProviderProps;

type TabularContextTypes = {
  groupBy: string[];
  setGroupBy: (_v: string[]) => void;
  preferenceKey: ts.enums.PREFERENCES_KEY_ENUM;
  preferenceTab: ts.enums.PREFERENCES_INNER_TABS_ENUM;
  firstPrefLoad: boolean;
  preferenceOptions: ts.types.userPreference.UserPreferenceDraft[];
  selectedPreference: ts.types.userPreference.UserPreferenceDraft;
  currentView: number;
  setCurrentView: (_v: number) => void;
  openPreferences: () => void;
  sortColumns: mui.dataGrid.GridSortModel;
  setSortColumns: (_v: mui.dataGrid.GridSortModel) => void;
  loadingPreferences: boolean;
  handleColumnsResize: (_key: string, _width: number) => void;
  filters: ts.types.components.dataGrid.GridFilterModel;
  setFilters: (_v: ts.types.components.dataGrid.GridFilterModel) => void;
  columnsWidth: ts.types.components.dataGrid.ColumnsWidth;
  setColumnsWidth: (_v: ts.types.components.dataGrid.ColumnsWidth) => void;
  fixedWidth: ts.types.components.dataGrid.TableParams['fixed_width'];
  setFixedWidth: (_v: ts.types.components.dataGrid.TableParams['fixed_width']) => void;
  expandedGroupIds: ReadonlySet<unknown>;
  setExpandedGroupIds: (_v: ReadonlySet<unknown>) => void;

  tableWidth: number;
  setTableWidth: (_v: number) => void;

  userColumns: ts.types.components.dataGrid.ColumnPreferences | ts.types.userPreference.DatasetPreferences;
};

const TabularContext = React.createContext<TabularContextTypes>(null);

const Provider: React.FC<ProviderProps & { children: React.ReactElement }> = (props): React.ReactElement => {
  const dispatch = useDispatch();

  const {
    children,
    initialTableParams,
    preferenceKey,
    preferencesSourceWidgetId,
    overrideUserPreferences,
    groupBy,
    updateTableParams,
    preferenceTab,
    expandedGroupIds,
    setExpandedGroupIds,
  } = props;

  const [sortColumns, setSortColumns] = React.useState(convertSortByToMuiX(initialTableParams?.sort_by));
  const [columnsWidth, setColumnsWidth] = React.useState<ts.types.components.dataGrid.ColumnsWidth>(
    initialTableParams?.cols_width || []
  );

  const [tableWidth, setTableWidth] = React.useState<number>(null);

  const [localExpandedGroupIds, setLocalExpandedGroupIds] = React.useState(expandedGroupIds || new Set());

  const [filters, setFilters] = React.useState(
    convertFiltersToMuiX(initialTableParams?.filters, initialTableParams?.filter_conjuction)
  );
  const [localGroupBy, setLocalGroupBy] = React.useState<string[]>(initialTableParams?.group_by || groupBy || []);

  // Preferences
  const [currentView, setCurrentView] = React.useState<number>(initialTableParams?.current_view);
  const [firstPrefLoad, setFirstPrefLoad] = React.useState(!!preferenceKey);
  const [loadingPreferences, userColumns, preferenceOptions, selectedPreference] = useGetPreferences(
    preferenceKey,
    currentView,
    preferencesSourceWidgetId,
    overrideUserPreferences
  );
  const [fixedWidth, setFixedWidth] = React.useState(initialTableParams?.fixed_width);

  const debouncedUpdateTableParams = React.useCallback(
    _.debounce((tableParams: ts.types.components.dataGrid.TableParams) => {
      if (updateTableParams) {
        updateTableParams(tableParams);
      }
    }, 750),
    [updateTableParams]
  );

  hooks.useEffectWithoutFirst(() => {
    debouncedUpdateTableParams({
      sort_by: convertMuiXSortBy(sortColumns),
      filters: convertMuiXFilters(filters),
      cols_width: columnsWidth,
      current_view: currentView,
      fixed_width: fixedWidth,
      group_by: localGroupBy,
    });
  }, [sortColumns, columnsWidth, currentView, filters, fixedWidth, localGroupBy]);

  hooks.useEffectWithoutFirst(() => {
    if (setExpandedGroupIds) {
      setExpandedGroupIds(localExpandedGroupIds);
    }
  }, [localExpandedGroupIds]);

  React.useEffect(() => {
    if (!loadingPreferences && preferenceKey) setFirstPrefLoad(false);
  }, [loadingPreferences]);

  const openPreferences = () => {
    dispatch(
      actions.ui.setPreferencesSelectedInnerTab(preferenceTab, selectedPreference?.id, preferencesSourceWidgetId)
    );
  };

  const handleColumnsResize = (colKey: string, newWidth: number) => {
    setColumnsWidth((c) => _.uniqBy([{ columnKey: colKey, columnWidth: newWidth }, ...c], 'columnKey'));
    // We can safely clear the autowidth
    setFixedWidth(null);
  };

  return (
    <TabularContext.Provider
      value={{
        preferenceKey,
        preferenceTab,
        firstPrefLoad,
        loadingPreferences,
        userColumns,
        preferenceOptions,
        selectedPreference,
        openPreferences,
        handleColumnsResize,
        currentView,
        setCurrentView,
        fixedWidth,
        setFixedWidth,
        columnsWidth,
        setColumnsWidth,
        sortColumns,
        setSortColumns,
        filters,
        setFilters,
        groupBy: localGroupBy,
        setGroupBy: setLocalGroupBy,
        expandedGroupIds: localExpandedGroupIds,
        setExpandedGroupIds: setLocalExpandedGroupIds,
        tableWidth,
        setTableWidth,
      }}
    >
      {children}
    </TabularContext.Provider>
  );
};

const TabularContextProvider = Provider;

Provider.defaultProps = {
  data: [],
  customRow: null,
  rowClass: null,
  rowHeight: 20,
  rowWidth: 150,
  headerHeight: 35,
  customToolbar: null,
  loading: false,
  customHeight: null,
  hideFilters: false,
  hideDensity: false,
  hideToolbar: false,
  hideColumnWidth: false,
  alert: null,
  overrideHeight: null,
  groupBy: undefined,
  getColumns: (colKeys: string[]) => colKeys.map((key) => ({ key, name: key })),
  expandedGroupIds: undefined,
  setExpandedGroupIds: undefined,
  customStyles: undefined,
  disableDefaultStyling: false,
};

export { TabularContext };
export { TabularContextProvider };
