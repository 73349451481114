import { config, hooks, mui, React, ts } from '_core';

import { getConstraintType } from '../../optimizer-utils/constraint-type';
import Bounds from '../bounds';
import SharedConstraintFields from '../shared-fields';
import Target from '../target';

type AssetAllProps = {
  id: string;
  params: ts.types.optimizer.CountryDraft;
  setParams: (_params: ts.types.optimizer.CountryDraft) => void;
  universe: ts.types.universe.Universe;
  readOnly: boolean;
  validationDef: ts.types.common.ValidationErrors;
  optimizerType: 'basket' | 'backtest';
};

const CountryContraint: React.FC<AssetAllProps> = ({
  id,
  params,
  setParams,
  readOnly,
  universe,
  optimizerType,
  validationDef,
}): React.ReactElement => {
  const [showMore, setShowMore] = React.useState(false);
  const [constraint, setConstraint] = React.useState(params);

  const universeCountries = universe.definition?.quotation_countries;

  const filteredCountries = config.countries.filter((c) => universeCountries.includes(c.code));

  React.useEffect(() => {
    if (optimizerType == 'basket' && params.benchmark_relative == null) {
      setConstraint({ ...constraint, benchmark_relative: true });
    }
  }, []);

  hooks.useEffectWithoutFirst(() => {
    setParams(constraint);
  }, [constraint]);

  const form = () => {
    if (getConstraintType(constraint) == 'BOUNDS') {
      if (showMore) {
        return (
          <Bounds
            id={id}
            params={constraint}
            setParams={setConstraint}
            isPercent
            readOnly={readOnly}
            validationDef={validationDef}
          >
            <SharedConstraintFields
              id={id}
              params={constraint}
              setParams={setConstraint}
              readOnly={readOnly}
              validationDef={validationDef}
            />
          </Bounds>
        );
      }

      return (
        <Bounds
          id={id}
          params={constraint}
          setParams={setConstraint}
          isPercent
          readOnly={readOnly}
          validationDef={validationDef}
        />
      );
    }

    if (showMore) {
      return (
        <Target params={constraint} setParams={setConstraint} readOnly={readOnly}>
          <SharedConstraintFields
            id={id}
            params={constraint}
            setParams={setConstraint}
            readOnly={readOnly}
            validationDef={validationDef}
          />
        </Target>
      );
    }

    return <Target params={constraint} setParams={setConstraint} readOnly={readOnly} />;
  };

  const countriesValue = React.useMemo(() => {
    return ((params.value as string[]) || []).map((c) => config.countries.find((cf) => cf.code == c));
  }, [params.value, config.countries]);

  return (
    <>
      {/* {!readOnly && (
        <ui.Button
          sx={uiStyles.fullCardButton}
          color="primary"
          size="small"
          fullWidth
          onClick={() => setConstraint({ 
            type: constraint.type == 'BOUNDS' ? 'TARGET' : 'BOUNDS',
            target_penalty: constraint.type == 'BOUNDS' ? 1.0 : undefined, 
          })}
        >
          { constraint.type == 'BOUNDS' ? 'Change to target mode' : 'Change to bounds mode' }
        </ui.Button>
      )} */}

      <mui.core.Grid container spacing={4}>
        <mui.core.Grid item xs={12} md={3}>
          <mui.core.Box px={3}>
            <mui.core.FormControlLabel
              control={
                <mui.core.Switch
                  size="small"
                  checked={constraint.benchmark_relative}
                  onChange={() =>
                    setConstraint({
                      ...constraint,
                      benchmark_relative: !constraint.benchmark_relative,
                    })
                  }
                  disabled={readOnly}
                />
              }
              label={optimizerType == 'backtest' ? 'Benchmark Relative' : 'Relative to Target Weights'}
            />
          </mui.core.Box>
        </mui.core.Grid>
        <mui.core.Grid item xs={12} md={9} xl={10}>
          <mui.core.Autocomplete
            multiple
            disabled={readOnly}
            options={filteredCountries}
            groupBy={(c) => c.continent}
            size="small"
            value={countriesValue}
            onChange={(e, val) => {
              setConstraint({
                ...constraint,
                value: val.map((c) => (c as ts.types.country.Country).code),
              });
            }}
            getOptionLabel={(option) =>
              `${(option as ts.types.country.Country).name} (${(option as ts.types.country.Country).code})`
            }
            renderInput={(params) => (
              <mui.core.TextField
                {...params}
                fullWidth
                label="Country"
                variant="outlined"
                required
                size="small"
                error={validationDef?.fields?.includes(`country_${id}`)}
              />
            )}
            renderTags={(tagValue, getTagProps) =>
              tagValue.map((option, index) => (
                <mui.core.Chip key={option.code} label={option.code} {...getTagProps({ index })} />
              ))
            }
          />
        </mui.core.Grid>
      </mui.core.Grid>

      <mui.core.Box p={2} px={0}>
        {form()}
        <mui.core.Button
          onClick={() => {
            setShowMore(!showMore);
          }}
          color="primary"
          style={{ marginTop: '0.5rem' }}
        >
          {showMore ? (
            <>
              <mui.icons.Remove style={{ fontSize: '14px', marginRight: '0.5rem' }} />
              Show Less
            </>
          ) : (
            <>
              <mui.icons.Add style={{ fontSize: '14px', marginRight: '0.5rem' }} />
              Show More
            </>
          )}
        </mui.core.Button>
      </mui.core.Box>
    </>
  );
};

export default CountryContraint;
