import React from 'react';

import { mui } from '../../../libs';
import * as types from '../../../types';
import CenteredLoader from '../../centered-loader';
import EmptyMessage from '../../empty-message';

type SearchResultsProps = {
  options: types.assetSelector.AssetObj[] | null;
  loadingAssets: boolean;
  infiniteScrollLoading: boolean;
  multiple: boolean;
  error: string;
  selectedAssets: types.assetSelector.AssetSelectorMetaData[] | undefined;
  setSelectedAssets: (_value: types.assetSelector.AssetSelectorMetaData[]) => void;
  loadOnScroll: () => void;
};

const EMPTY_ARRAY: any[] = [];

/**
 * Component that displays asset search results with selection handling and infinite scroll
 *
 * @param options - List of asset objects to display as search results
 * @param loadingAssets - Loading state for initial asset load
 * @param infiniteScrollLoading - Loading state for infinite scroll pagination
 * @param multiple - Whether multiple selection is enabled
 * @param error - Error message to display if search fails
 * @param selectedAssets - Currently selected asset metadata objects
 * @param setSelectedAssets - Callback to update selected assets
 * @param loadOnScroll - Function to trigger when scroll reaches bottom
 *
 * @returns Scrollable results container with asset checkboxes and loading states
 */

const AssetSelectorSearchResults = ({
  options,
  loadingAssets,
  multiple,
  setSelectedAssets,
  infiniteScrollLoading,
  loadOnScroll,
  selectedAssets = EMPTY_ARRAY,
  error = null,
}: SearchResultsProps) => {
  if (error)
    return (
      <EmptyMessage hideBorder>
        <mui.lab.Alert severity="error">{error}</mui.lab.Alert>
      </EmptyMessage>
    );

  if (loadingAssets) return <CenteredLoader top="-100px" label="Loading Assets" />;

  const handleInfiniteScroll = (e: any) => {
    const boxNode = e.currentTarget;
    if (boxNode.scrollTop + boxNode.clientHeight + 30 >= boxNode.scrollHeight) {
      loadOnScroll();
    }
  };

  if (options && options.length > 0) {
    return (
      <>
        <mui.core.Box
          pt={0}
          p={3}
          sx={{
            maxHeight: 400,
            overflow: 'auto',
          }}
          onScroll={handleInfiniteScroll}
        >
          <mui.core.FormGroup>
            {options.map((option) => (
              <mui.core.FormControlLabel
                key={option.fid}
                onChange={(_e) => {
                  let currentSelection = Array.isArray(selectedAssets) ? [...selectedAssets] : [];

                  if (multiple) {
                    const selected = currentSelection.find((a) => a.fid === option.fid);

                    if (selected) {
                      currentSelection = currentSelection.filter((metaData) => metaData.fid !== option.fid);
                    } else {
                      currentSelection = [...currentSelection, option];
                    }
                  } else {
                    currentSelection = [option]; // Wrap the option in an array
                  }

                  setSelectedAssets(currentSelection);
                }}
                control={
                  <mui.core.Checkbox
                    size="small"
                    color="primary"
                    checked={
                      multiple
                        ? selectedAssets.some((asset) => asset.fid === option.fid)
                        : selectedAssets[0]?.fid === option.fid
                    }
                  />
                }
                label={
                  <mui.core.Stack sx={{ width: '100%' }} gap={4} flexDirection="row">
                    <mui.core.Typography variant="body1" fontWeight="400">
                      {option.name}
                    </mui.core.Typography>
                    {option.ticker && <mui.core.Chip size="small" color="primary" label={option.ticker} />}
                    <mui.core.Chip color="primary" size="small" label={option.quotation_country} />
                  </mui.core.Stack>
                }
              />
            ))}
          </mui.core.FormGroup>

          {infiniteScrollLoading && (
            <mui.core.Box>
              <CenteredLoader />
            </mui.core.Box>
          )}
        </mui.core.Box>
      </>
    );
  }

  if (options == null) {
    return (
      <mui.core.Box
        sx={{
          height: '400px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <mui.core.Box sx={{ width: '50%' }}>
          <EmptyMessage hideBorder>
            Start your search by entering a name, ticker, CUSIP, or other identifier. Be sure to select a quotation
            country for more accurate results.
          </EmptyMessage>
        </mui.core.Box>
      </mui.core.Box>
    );
  }

  return <EmptyMessage hideBorder>No results found for your query, try adjusting the search params...</EmptyMessage>;
};

export default AssetSelectorSearchResults;
