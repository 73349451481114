import { _, hooks, mui, React, ui } from '_core';

import WidgetForm from './widget-form';
import { ManagerContext } from '../manager-context';

const ExpandedManager = (): React.ReactElement => {
  const uiStyles = hooks.useUiStyles();
  const managerContext = React.useContext(ManagerContext);
  const { cards } = managerContext;

  const emptyCards = (
    <mui.core.Grid container sx={{ justifyContent: 'center' }}>
      <mui.core.Grid item xs={12} sm={6} lg={5}>
        <ui.EmptyMessage>Search for an item to include it</ui.EmptyMessage>
      </mui.core.Grid>
    </mui.core.Grid>
  );

  const withFormCards = cards.filter((w) => w.form);
  const withoutFormCards = cards.filter((w) => !w.form);

  return (
    <mui.core.Box>
      {_.isEmpty(cards) ? (
        emptyCards
      ) : (
        <>
          <mui.core.Grid container spacing={3}>
            {withFormCards.map((widget) => (
              <WidgetForm key={widget.id} widget={widget} />
            ))}
          </mui.core.Grid>
          {withoutFormCards.length > 0 && (
            <>
              <mui.core.Typography component="p" variant="caption" sx={uiStyles.titleDivider}>
                <span>The following widgets don&apos;t need params</span>
              </mui.core.Typography>
              <mui.core.Grid container spacing={2}>
                {withoutFormCards.map((widget) => (
                  <WidgetForm key={widget.id} widget={widget} />
                ))}
              </mui.core.Grid>
            </>
          )}
        </>
      )}
    </mui.core.Box>
  );
};

export default ExpandedManager;
