import React from 'react';

import { mui } from '../../../libs';

type BoolRuleProps = {
  operator: string;
  value: boolean;
  setRule: (_o: string, _v: boolean) => void;
  readOnly?: boolean;
  displayToggle?: boolean;
};

const BoolRule: React.FC<BoolRuleProps> = ({
  operator,
  value,
  setRule,
  readOnly,
  displayToggle,
}): React.ReactElement => {
  if (!displayToggle) {
    return (
      <>
        <mui.core.Box display="flex" gap={2} height="100%" alignItems="center">
          <mui.core.Chip size="small" label="=" color="primary" />
          <mui.core.Chip size="small" label="True" color="primary" />
        </mui.core.Box>
      </>
    );
  }

  return (
    <>
      <mui.core.Switch
        size="small"
        disabled={readOnly}
        checked={value}
        onChange={(e) => setRule(operator, e.target.checked)}
      />
    </>
  );
};

BoolRule.defaultProps = {
  readOnly: false,
};

export default BoolRule;
