import React from 'react';

import ContextSingleton, { FinseraProviderValues } from './__singletons__/context-singleton';
import { refreshApis } from './api';

/**
 * Provider component that must wrap the entire application to enable importing and using
 * functionality from finsera-core. Initializes the singleton context with store, actions
 * and features that will be available throughout the package methods.
 *
 * Store needs to match the BaseStore type structure.
 * Actions needs to match the Actions type structure.
 *
 * We need the following actions always:
 * - actions.ui.updateUiVersion
 * - actions.apiErrors.addError
 * - actions.auth.storeLogout
 *
 * We need the following store always:
 * - store.auth.authenticated
 * - store.apiErrors.stack
 * - store.ui.runningUiVersion
 * - store.ui.currentUiVersion
 */
export const FinseraCoreProvider: React.FC<FinseraProviderValues & { children: React.ReactNode }> = ({
  store,
  children,
  features,
  actions,
}) => {
  // Initialize the API context when the provider mounts
  React.useEffect(() => {
    ContextSingleton.initialize({
      store,
      features,
      actions,
    });
    refreshApis();
  }, [store, features, actions]);

  return children;
};
