import {
  actions,
  api,
  config,
  fullStory,
  hooks,
  ls,
  mui,
  React,
  Sentry,
  ts,
  ui,
  useDispatch,
  useSelector,
} from '_core';

import { UserWithOrganization } from '@local/finsera-core/src/types/user';
import useWorkspaceMetadataBackgroundSave from 'use-workspace-metadata-background-save';
import LoggedInRouter from 'views/logged-in-router';
import LoggedOutRouter from 'views/logged-out-router';

import 'swagger-ui-react/swagger-ui.css';

const InApp = (): React.ReactElement => {
  const dispatch = useDispatch();
  useWorkspaceMetadataBackgroundSave();

  const auth = useSelector((state) => state.auth);

  const setUserFromSession = async (user: UserWithOrganization) =>
    await dispatch(actions.auth.setUserFromSession(user));
  const setInvalidPasscode = async () => await dispatch(actions.auth.setInvalidPasscode(true));

  const [booted, setBooted] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const sessionRef = React.useRef(null);

  const authenticated = React.useMemo(() => auth.authenticated, [auth.authenticated]);

  const verified = React.useMemo(() => auth.verified, [auth.verified]);

  React.useEffect(() => {
    ls.clearExpired();
  }, []);

  const getUserSession = async () => {
    try {
      const user = await api.auth.getSession();
      return user;
    } catch (e) {
      // If we get a 401 and we're already logged out, just ignore it silently
      if ((e as { status: number }).status === 401 && !auth.authenticated) {
        return;
      }
      if ((e as { status: number }).status === 409) {
        await setInvalidPasscode();
      } else {
        throw new Error('Failed to set user from session');
      }
    }
  };

  React.useEffect(() => {
    const renderLoading = async () => {
      // Load organization features
      try {
        const user = await getUserSession();
        if (!user) return;
        setLoading(true);
        await setUserFromSession(user);
      } finally {
        setBooted(true);
        setLoading(false);
      }
    };

    // to render loading
    if (!authenticated) renderLoading();
    else setBooted(true);
  }, [authenticated]);

  React.useEffect(() => {
    if (booted) {
      if (authenticated) ls.init(auth.currentUser);
      if (authenticated && !config.environment.match(/local/)) {
        Sentry.configureScope((scope) => {
          scope.setUser({ email: auth.currentUser.email });
          scope.setTag('organization', auth.organization.subdomain);
        });
      }
    }
  }, [booted, authenticated]);

  // Handle FullStory
  React.useEffect(() => {
    const features = auth?.organization?.features || {};
    if (!features.fullstory) return;
    if (authenticated) fullStory.init({ orgId: 'W543D' }, ({ sessionUrl }) => (sessionRef.current = sessionUrl));
    else if (sessionRef.current) fullStory.shutdown();
  }, [authenticated]);

  const profilerRefs = hooks.useProfilerRefs([() => verified]);

  if (loading) return <ui.FullPageLoader />;

  return (
    <>
      <div className="App" id="content">
        <ui.ErrorBoundaryFeedback relative={false}>
          <>
            <ui.HandleAuthenticationChanges />

            {booted && (
              <ui.UiWrapper>
                <ui.ApiErrors />
                <ui.AppOutdated />
                {authenticated ? <LoggedInRouter /> : <LoggedOutRouter />}
              </ui.UiWrapper>
            )}

            {!booted && (
              <ui.CustomProfiler
                profilerKey={ts.types.performanceLogs.KEYS_ENUM.INITIAL_LOADING}
                parentStates={{ names: ['verified'], states: profilerRefs }}
                persist
              >
                <ui.CenteredLoader />
              </ui.CustomProfiler>
            )}
          </>
        </ui.ErrorBoundaryFeedback>
      </div>

      <mui.core.Stack
        flexDirection="row"
        gap={1}
        sx={{
          display: 'none',
          position: 'fixed',
          right: 0,
          bottom: 0,
          background: '#ECF4FF',
          zIndex: '1000',
          padding: '4px',
          alignItems: 'center',
          borderTop: '1px solid #eee',
          borderRight: '1px solid #eee',
          borderTopLeftRadius: '2px',
          '@media print': {
            display: 'flex',
          },
        }}
      >
        <mui.core.Typography sx={{ fontSize: '10px', opacity: '0.5', letterSpacing: '-.5px' }}>
          Generated with
        </mui.core.Typography>
        <img src={config.customUrl + '/logo-print.png'} style={{ width: '44px', height: '12px' }} />
      </mui.core.Stack>
    </>
  );
};

export default InApp;
