import { React, ts } from '_core';

import Chart from './chart';
import Form from './form';
import { DraftParams, Params } from './types';

type BacktestBucketedCumulativeReturnsProps = {
  id: string;
  readOnly: boolean;
  widgetKey: string;
  mode: ts.enums.CHART_MODE_ENUM;
  params: Params;
  setParams: (_p: DraftParams) => void;
  title: string;

  fullScreen?: boolean;
  loadWidgetData: (_payload: ts.types.widgets.WidgetGetDataParams) => Promise<ts.types.widgets.WidgetGetDataResponse>;
  widgetPaths?: Record<'bucketed_returns_backtest', string>;
};

const BacktestBucketedCumulativeReturns: React.FC<BacktestBucketedCumulativeReturnsProps> = ({
  id,
  readOnly,
  widgetKey,
  loadWidgetData,
  widgetPaths,
  mode,
  params,
  setParams,
  fullScreen,
  title,
}): React.ReactElement => {
  const chart = () => (
    <Chart
      id={id}
      readOnly={readOnly}
      widgetKey={widgetKey}
      loadWidgetData={loadWidgetData}
      returns={widgetPaths['bucketed_returns_backtest']}
      fullScreen={fullScreen}
      params={params}
      title={title}
    />
  );

  const renderJobComponent = () => <>{chart()}</>;

  return <>{mode == 'edit' ? <Form params={params} setParams={setParams} /> : renderJobComponent()}</>;
};

export default BacktestBucketedCumulativeReturns;
