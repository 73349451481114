import { ts } from '_core';

import { validate } from './helpers';
import { INTERACTION_PARAMS } from './types';

export default [
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.RISK_MODEL_PREVIEW_ASSET_PERTINENCE,
    form: true,
    title: 'Asset Pertinence',
    neededFiles: ['risk_model_pertinence_preview'],
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.RISK_MODEL_PREVIEW],
    interactionParams: Object.values(INTERACTION_PARAMS),
    clearInteractionOnRegenerate: ['table_dtypes'],

    validate,
    helpLabel: 'wgggcjrd',
  },
] satisfies ts.types.analysis.WidgetConfig[];
