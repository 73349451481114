import { _, config, mui, React, ts } from '_core';

import FixedCosts from 'views/fixed-costs';
import { PreferencesContext } from 'views/preferences/preferences-context';

import innerTabNames from './common/inner-tab-names';
import SelectColumns from './common/select-columns';
import OtherForm from './other';
import ReportTemplates from './report-templates';
import SignalsPreferences from './signals';

const PreferencesMainView = () => {
  const preferencesContext = React.useContext(PreferencesContext);
  const {
    closePreferences,
    preferencesSelectedTab,
    preferencesSelectedInnerTab,
    saveMultiplePreferences,
    preferences,
    handleInnerTabChange,
  } = preferencesContext;
  return (
    <>
      <mui.core.Box>
        <mui.core.Box p={5} display="flex" justifyContent="space-between">
          <mui.core.Typography variant="h5">{_.startCase(_.toLower(preferencesSelectedTab))}</mui.core.Typography>
          <mui.core.IconButton
            size="small"
            onClick={closePreferences}
            sx={{
              position: 'absolute',
              right: '1rem',
              top: '0.75rem',
            }}
          >
            <mui.icons.Close />
          </mui.core.IconButton>
        </mui.core.Box>
        <mui.core.Box
          pl={5}
          sx={{
            borderBottom: '1px solid #f2f5f7',
          }}
        >
          {
            // Inner sub menu
            preferencesSelectedTab !== ts.enums.PREFERENCES_TABS_ENUM.REPORT_LAYOUTS &&
              !_.isEmpty(config.preferencesMenu?.[preferencesSelectedTab]) && (
                <mui.core.Tabs
                  value={preferencesSelectedInnerTab}
                  onChange={handleInnerTabChange}
                  variant="scrollable"
                  sx={{ marginTop: '-0.9rem' }}
                >
                  {config.preferencesMenu[preferencesSelectedTab].map((key) => (
                    <mui.core.Tab label={<mui.core.Box>{innerTabNames[key]}</mui.core.Box>} value={key} key={key} />
                  ))}
                </mui.core.Tabs>
              )
          }
        </mui.core.Box>
      </mui.core.Box>
      <mui.core.Box
        sx={{
          height: 'calc(100% - 85px)',
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {preferencesSelectedTab == ts.enums.PREFERENCES_TABS_ENUM.BACKTEST_SETS && <SelectColumns />}

        {preferencesSelectedTab == ts.enums.PREFERENCES_TABS_ENUM.BACKTESTS && <SelectColumns />}

        {preferencesSelectedTab == ts.enums.PREFERENCES_TABS_ENUM.BASKETS && <SelectColumns />}

        {preferencesSelectedTab == ts.enums.PREFERENCES_TABS_ENUM.TIME_SERIES && <SelectColumns />}

        {preferencesSelectedTab == ts.enums.PREFERENCES_TABS_ENUM.SIGNALS && <SignalsPreferences />}

        {preferencesSelectedTab == ts.enums.PREFERENCES_TABS_ENUM.RISK_MODELS && <SelectColumns />}

        {preferencesSelectedTab == ts.enums.PREFERENCES_TABS_ENUM.PORTFOLIOS && <SelectColumns />}

        {preferencesSelectedTab == ts.enums.PREFERENCES_TABS_ENUM.UNIVERSES && <SelectColumns />}

        {preferencesSelectedTab == ts.enums.PREFERENCES_TABS_ENUM.SIGNAL_SETS && <SelectColumns />}

        {preferencesSelectedTab == ts.enums.PREFERENCES_TABS_ENUM.FIXED_COSTS && <FixedCosts />}

        {preferencesSelectedTab == ts.enums.PREFERENCES_TABS_ENUM.REPORT_LAYOUTS && <ReportTemplates />}

        {preferencesSelectedTab == ts.enums.PREFERENCES_TABS_ENUM.OTHER && (
          <OtherForm
            preferences={preferences}
            save={async (p) => {
              await saveMultiplePreferences(p);
            }}
            saveAndClose={async (p) => {
              await saveMultiplePreferences(p);
              closePreferences();
            }}
          />
        )}
      </mui.core.Box>
    </>
  );
};

export default PreferencesMainView;
