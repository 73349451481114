import * as pluralize from './pluralize';
import { RESOURCES_TYPES_ENUM } from '../enums';
import { _ } from '../libs';
import { ArchivedDeps, ResourceDeps } from '../types/common';

/**
 * List of resource reference fields to check for dependencies
 */
const included = [
  'signal_ids',
  'universe_ids',
  'risk_model_ids',
  'pipeline_ids',
  'data_source_ids',
  'backtest_ids',
  'basket_ids',
  'tcost_model_ids',
  'general_analysis_ids',
  'signal_set_ids',
  'backtest_set_ids',
  'time_series_ids',
  'strategy_ids',
  'portfolio_ids',
];

/**
 * Gets first level dependencies for a resource
 * @param parent - Parent resource to get dependencies for
 * @param resources - Object containing all resources
 * @param localIncluded - List of reference fields to check (defaults to included)
 * @returns Array of first level resource dependencies
 */
const getFirstLvlDeps = (
  parent: ResourceDeps,
  resources: any,
  localIncluded = included
): ResourceDeps[] => {
  const children = [] as ResourceDeps[];

  localIncluded.forEach((ref) => {
    const resourceType = ref.replace('_ids', '') as RESOURCES_TYPES_ENUM;
    const reduxResource = pluralize.plural(resourceType);

    (parent?.[ref] ?? []).forEach((refID: number) => {
      const child = ((resources?.[reduxResource] as ResourceDeps[]) ?? []).find((v) => v.id == refID);
      if (child) children.push({ ...child, resourceType });
    });
  });

  return children;
};

/**
 * Builds a dependency tree for a resource
 * @param obj - Resource to build tree for
 * @param resources - Object containing all resources
 * @param visited - Set of already visited resource IDs
 * @returns Resource object with nested children dependencies
 */
const build = (obj: ResourceDeps, resources: any, visited = new Set<string>([])): ResourceDeps => {
  const children = getFirstLvlDeps(obj, resources);

  visited.add(`${obj.resourceType}_${obj.id}`);

  const newObj = {
    ...obj,
    children: children
      .filter((child) => !visited.has(`${child.resourceType}_${child.id}`))
      .map((child) => build(child, resources)),
  };
  visited.delete(`${obj.resourceType}_${obj.id}`);

  return newObj;
};

/**
 * Gets all dependencies for a resource
 * @param obj - Resource to get dependencies for
 * @param resources - Object containing all resources
 * @param forPublish - Whether to exclude external published resources
 * @returns Array of all resource dependencies
 */
const getDeps = (obj: ResourceDeps, resources: any, forPublish = false): ResourceDeps[] => {
  const allDeps = [] as ResourceDeps[];
  const queue = [obj];
  const visited = new Set<string>([]);

  while (!_.isEmpty(queue)) {
    const curr = queue.pop();
    visited.add(`${curr.resourceType}_${curr.id}`);

    if (forPublish && curr.definition?.publish_as_external) continue;
    const children = getFirstLvlDeps(curr, resources);
    children
      .filter((child) => !visited.has(`${child.resourceType}_${child.id}`))
      .forEach((child) => {
        queue.push(child);
        allDeps.push(child);
      });
  }

  return allDeps;
};

/**
 * Gets archived dependencies organized by level
 * @param deps - Array of resource dependencies
 * @param mainLabel - Label for first level dependencies
 * @returns Object containing archived dependencies grouped by level
 */
const getArchiveDeps = (deps: ResourceDeps[], mainLabel = 'On Definition:'): ArchivedDeps => {
  const archivedDeps = [] as ArchivedDeps;

  const firstLvl = deps.filter((d) => d.is_deprecated).map((d) => `${d.name} (${d.handle})`);
  if (!_.isEmpty(firstLvl))
    archivedDeps.push({
      label: mainLabel,
      elements: firstLvl,
    });

  const getInnerArchived = (localDeps = deps) => {
    let parsedDeps = [] as string[];
    localDeps.forEach((d) => {
      if (d.is_deprecated) parsedDeps.push(`${d.name} (${d.handle})`);
      parsedDeps = parsedDeps.concat(getInnerArchived(d.deps || []));
    });
    return parsedDeps;
  };

  const parsedInnerDeps = _.uniq(_.without(getInnerArchived(deps), ...firstLvl));
  if (!_.isEmpty(parsedInnerDeps))
    archivedDeps.push({
      label: 'Inner dependencies:',
      // In case we get cyclic repeated values and to remove first lvl
      elements: parsedInnerDeps,
    });

  return archivedDeps;
};

export { build };
export { getDeps };
export { getFirstLvlDeps };
export { getArchiveDeps };
