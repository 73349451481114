import { hooks, mui, React, ts, ui } from '_core';

import { DraftParams } from './types';

type FormProps = {
  params: DraftParams;
  setParams: (_p: DraftParams) => void;
  context?: ts.types.signal.SignalAnalysisContext;
};

const Form: React.FC<FormProps> = ({ params, setParams, context }) => {
  const [selectedSignal, setSelectedSignal] = React.useState(params.analysis_signal_id);
  const [selectedPipeline, setSelectedPipeline] = React.useState(params.pipeline_id);
  const [returnHorizon, setReturnHorizon] = React.useState<ts.types.returnHorizon.ReturnHorizon>(params.return_horizon);
  const [buckets, setBuckets] = React.useState(
    params.buckets || ({ type: 'LONG_SHORT' } as ts.types.widgets.common.BucketValue)
  );

  const [selectedHistories, setSelectedHistories] = React.useState(
    params.histories || ([] as ts.enums.HISTORY_ENUM_EXTENDED[])
  );
  const [selectedType, setSelectedType] = React.useState(params.selected_type || 'return_horizon');

  const uiStyles = hooks.useUiStyles();

  React.useEffect(() => {
    const localReturnHorizon = selectedType == 'return_horizon' ? returnHorizon : undefined;
    const localSignalID = selectedType == 'signal' ? selectedSignal : undefined;
    const localPipelineId = selectedType == 'signal' && selectedPipeline ? selectedPipeline : undefined;

    const data = {
      selected_type: selectedType,
      return_horizon: localReturnHorizon,
      analysis_signal_id: localSignalID,
      pipeline_id: localPipelineId,
      histories: selectedHistories,
      buckets,
    };

    setParams({ ...params, weights: params.weights, ...data });
  }, [returnHorizon, buckets, selectedHistories, selectedPipeline, selectedSignal, selectedType]);

  return (
    <mui.core.Box sx={uiStyles.widgetEditMode}>
      <mui.core.Box>
        <mui.core.FormControl component="fieldset">
          <mui.core.RadioGroup
            row
            aria-label="position"
            name="position"
            value={selectedType}
            onChange={(e) => setSelectedType(e.target.value)}
          >
            <mui.core.FormControlLabel
              value="return_horizon"
              control={<mui.core.Radio color="primary" />}
              label="Return Horizon"
            />
            <mui.core.FormControlLabel value="signal" control={<mui.core.Radio color="primary" />} label="Signal" />
          </mui.core.RadioGroup>
        </mui.core.FormControl>
      </mui.core.Box>

      <mui.core.Box mb={4}>
        {selectedType == 'signal' ? (
          <mui.core.Grid container spacing={2}>
            <mui.core.Grid item xs={6}>
              <ui.ResourceAutocomplete<ts.types.signal.SignalExpanded>
                value={selectedSignal}
                setValue={(id) => setSelectedSignal(id)}
                type={ts.enums.RESOURCES_TYPES_ENUM.SIGNAL}
                inputProps={{
                  label: 'Signal',
                  variant: 'outlined',
                  size: 'small',
                  required: true,
                }}
              />
            </mui.core.Grid>
            <mui.core.Grid item xs={6}>
              <ui.ResourceAutocomplete<ts.types.pipeline.PipelineExpanded>
                value={selectedPipeline}
                setValue={(id) => setSelectedPipeline(id)}
                type={ts.enums.RESOURCES_TYPES_ENUM.PIPELINE}
                inputProps={{
                  label: 'Pipeline',
                  variant: 'outlined',
                  size: 'small',
                }}
              />
            </mui.core.Grid>
          </mui.core.Grid>
        ) : (
          <ui.WidgetReturnHorizon<false>
            value={returnHorizon}
            setValue={(val) => setReturnHorizon(val)}
            context={context}
          />
        )}
      </mui.core.Box>
      <mui.core.Box mt={2}>
        <ui.WidgetHistories<true>
          multiple
          value={selectedHistories}
          setValue={setSelectedHistories}
          context={context}
        />
      </mui.core.Box>
      <mui.core.Box mt={4}>
        <ui.Buckets value={buckets} setValue={setBuckets} />
      </mui.core.Box>
    </mui.core.Box>
  );
};

export default Form;
