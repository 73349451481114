/**
 * Author: Yusuf Bhabhrawala
 * Abstraction for polling on table row
 * Features:
 *  - Multiple jobs
 *  - Multiple listener
 *  - Deduped polling
 *  - Cache of completed polls
 */

/* eslint-disable */

import { _ } from '../libs';
import apis from './scrud';
import { jobStatus } from '../helpers/jobs';
import { JOB_STATUS_ENUM } from '../enums';
import { Job } from '../types/job';

const JOB_POLLING_DURATION = 2; // seconds

const polls = {} as Record<
  string,
  {
    timer?: NodeJS.Timeout | boolean | number;
    promise?: Promise<{
      data: any;
      success: any;
      error: boolean;
    }>;
  }
>;

function wait(poll_id: string, ms = 1000) {
  return new Promise((resolve) => {
    polls[poll_id].timer = setTimeout(resolve, ms);
  });
}

const performWaitTillDone = async (
  id: number | string,
  type: string,
  check: (_j?: Job) => boolean,
  setValue: (_v: any) => void,
  apiCall: () => Promise<any> = null,
  returnData = true,
  duration = JOB_POLLING_DURATION
) => {
  let value;
  let isFirst = true;
  let state = null;
  const poll_id = `${id}-${type}`;

  const getValue = async () => {
    if (!apiCall) {
      return await apis[type as keyof typeof apis].get(id as number);
    }
    return await apiCall();
  };

  // not complete
  while (!state && (polls[poll_id].timer || isFirst)) {
    isFirst = false;

    value = await getValue();
    state = returnData ? check(value.data) : check(value);
    setValue(returnData ? value.data : value);

    if ([true, false, null].indexOf(state) < 0) throw 'Invalid poll check. Must return true, false or null';

    if ((polls[poll_id].timer as number) > 0) clearTimeout(polls[poll_id].timer as NodeJS.Timeout);

    if (!state) await wait(poll_id, duration * 1000);
  }

  setValue(returnData ? value.data : value);
  if (state !== null) return { data: returnData ? value.data : value, success: state, error: !state };
  // will return null if the polling was cancelled
  return null;
};

// Usage: let job = await poll.tillDone(123);
// Returns { data: job.data, succss: bool, error: bool}
export const tillDone = async (
  id: number | string,
  type = 'jobs',
  check: (_j?: any) => boolean = null,
  setResultValue: (_v: any) => void = () => undefined,
  apiCall: () => Promise<any> = null,
  returnData = true,
  duration = JOB_POLLING_DURATION
) => {
  if (!check && type === 'jobs') {
    // convenience for type jobs
    check = function (d) {
      const status = jobStatus(d);
      return status !== JOB_STATUS_ENUM.RUNNING;
    };
  }

  const poll_id = `${id}-${type}`;
  polls[poll_id] = polls[poll_id] || { timer: false, promise: null };

  if (polls[poll_id].promise === null || !polls[poll_id].timer) {
    polls[poll_id].promise = performWaitTillDone(id, type, check, setResultValue, apiCall, returnData, duration);
  }

  return await polls[poll_id].promise;
};

// Usage: poll.stop(123);
export const stop = (id: number | string, type = 'jobs') => {
  const poll_id = `${id}-${type}`;
  if (polls[poll_id]) {
    clearTimeout(polls[poll_id].timer as NodeJS.Timeout);
    polls[poll_id] = null;
  }
};
