import { ts } from '_core';

import { validate } from './helpers';
import { DraftParams, INTERACTION_PARAMS } from './types';

export default [
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.BASKET_EXPOSURE,
    title: 'Factor Exposures Over Time',
    getName: (p: DraftParams) => (p.is_gross ? 'Gross Factor Exposures Over Time' : 'Net Factor Exposures Over Time'),
    helpLabel: '0jymxzvw',
    form: true,
    defaultParams: {
      factors: [{ type: 'INDUSTRY', value: 'rbics_l1_id' }],
      detailed_output: false,
      is_basket: true,
      is_gross: false,
    } as DraftParams,
    interactionParams: Object.values(INTERACTION_PARAMS),
    neededFiles: ['basket_signal_exposure'],
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.BASKET, ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.BACKTEST],

    validate,
  },
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.SIGNAL_EXPOSURE,
    title: 'Factor Exposures Over Time',
    getName: (p: DraftParams) => (p.is_gross ? 'Gross Factor Exposures Over Time' : 'Net Factor Exposures Over Time'),
    helpLabel: 'jlpux5h5',
    form: true,
    defaultParams: {
      factors: [{ type: 'INDUSTRY', value: 'rbics_l1_id' }],
      detailed_output: false,
      is_gross: false,
    } as DraftParams,
    interactionParams: Object.values(INTERACTION_PARAMS),
    neededFiles: ['signal_exposure'],
    tags: [
      ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.SIGNAL,
      ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.ALPHA_MODEL,
      ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.COMMON_ALPHA_MODEL,
    ],

    validate,
  },
] satisfies ts.types.analysis.WidgetConfig[];
