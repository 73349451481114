/* eslint-disable no-unused-vars */
import { ts } from '_core';
export enum INTERACTION_PARAMS {
  TYPE = 'type',
  IS_BASKET = 'isBasket',
  AGGREGATION = 'aggregation',
  BUCKETS = 'buckets',
}

export type InteractionParams = {
  [INTERACTION_PARAMS.TYPE]: string;
  [INTERACTION_PARAMS.AGGREGATION]: string;
  [INTERACTION_PARAMS.IS_BASKET]: boolean;
  [INTERACTION_PARAMS.BUCKETS]: ts.types.widgets.common.BucketValue[];
};

export type Params = InteractionParams;
export type DraftParams = Partial<Params>;
