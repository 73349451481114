import { mui, React, ts } from '_core';

import Chart from './chart';
import Form from './form';
import { DraftParams, Params } from './types';

type PerformanceAttributionContributorsProps = {
  id: string;
  readOnly: boolean;
  widgetKey: string;
  mode: ts.enums.CHART_MODE_ENUM;
  params: Params & DraftParams & { fullWidth: boolean };
  setParams: (_p: DraftParams) => void;
  fullScreen?: boolean;
  widgetPaths?: Record<'performance_attribution_contributors' | 'portfolio_top_contributors', string>;

  goFullScreen?: (_q: string) => void;
  context?: ts.types.backtest.BacktestAnaylsisContext | ts.types.basket.BasketAnalysisContext;

  loadWidgetData: (_payload: ts.types.widgets.WidgetGetDataParams) => Promise<ts.types.widgets.WidgetGetDataResponse>;
};

const PerformanceAttributionContributors: React.FC<PerformanceAttributionContributorsProps> = ({
  id,
  readOnly,
  widgetKey,
  loadWidgetData,
  params,
  setParams,
  context,
  widgetPaths,
  fullScreen,
  goFullScreen,
  mode,
}): React.ReactElement => {
  const fileKey = React.useMemo(
    () => Object.keys(widgetPaths || {}).find((k) => k.endsWith('_contributors')) as keyof typeof widgetPaths,
    [widgetPaths]
  );

  const renderJobComponent = () => (
    <mui.core.Box sx={{ height: '100%' }}>
      <Chart
        id={id}
        readOnly={readOnly}
        widgetKey={widgetKey}
        loadWidgetData={loadWidgetData}
        file={widgetPaths[fileKey]}
        fullScreen={fullScreen}
        goFullScreen={goFullScreen}
        params={params}
        setParams={setParams}
        isBasket={!!(context as ts.types.basket.BasketAnalysisContext)?.basket_id}
      />
    </mui.core.Box>
  );

  return (
    <>{mode == 'edit' ? <Form params={params} setParams={setParams} context={context} /> : renderJobComponent()}</>
  );
};

export default PerformanceAttributionContributors;
