import React from 'react';

import { moment, mui } from '../../libs';
import DateDayRender from '../date-day-render';
import FinDatePicker from '../date-picker';


type StartDateOnlyProps = {
  value: mui.date.DateRange<Date>;
  onChange: (_v: mui.date.DateRange<Date>) => void;
  minDate: string;
  loading: boolean;
  disabled: boolean;
  validDates?: string[];
  allValidDailyDates?: string[];
  verticalPositioning?: boolean;
  setDateErrors: (_v: [boolean, boolean]) => void;
};

const StartDateOnly: React.FC<StartDateOnlyProps> = ({ ...props }): React.ReactElement => {
  const { value, onChange, minDate, loading, disabled, validDates, allValidDailyDates, verticalPositioning } = props;

  const loadingAdornment = loading ? (
    <mui.core.Box ml={1} mt={1} mr={2}>
      <mui.core.CircularProgress size={13} color="primary" sx={{ svg: { display: 'block!important' } }} />
    </mui.core.Box>
  ) : undefined;

  return (
    <mui.core.Grid container spacing={2}>
      <mui.core.Grid item xs={12} sm={verticalPositioning ? 12 : 6}>
        <FinDatePicker
          label="Start Date"
          value={value[0] ? moment(value[0]).format('YYYY-MM-DD') : null}
          onChange={(date) => {
            onChange([moment(date).toDate(), value[1]]);
          }}
          allValidDailyDates={allValidDailyDates}
          minDate={minDate ? minDate : undefined}
          maxDate={value[1] ? moment(value[1]).format('YYYY-MM-DD') : undefined}
          disabled={disabled}
          inputProps={{
            disableUnderline: true,
            size: 'small',
            startAdornment: <mui.core.InputAdornment position="start">{loadingAdornment}</mui.core.InputAdornment>,
          }}
          shouldDisableDate={
            validDates
              ? (date: string) => {
                  return !validDates.includes(moment(date).format('YYYY-MM-DD'));
                }
              : undefined
          }
          renderDay={(date, _dates, dayProps) => (
            <DateDayRender
              date={date}
              dayProps={dayProps}
              validDates={validDates}
              allValidDailyDates={allValidDailyDates}
            />
          )}
        />
      </mui.core.Grid>

      <mui.core.Grid item xs={12} sm={verticalPositioning ? 12 : 6}>
        <FinDatePicker
          label="End Date"
          value={value[1] ? moment(value[1]).format('YYYY-MM-DD') : null}
          inputProps={{
            disableUnderline: true,
            size: 'small',
            startAdornment: <mui.core.InputAdornment position="start">{loadingAdornment}</mui.core.InputAdornment>,
          }}
          disabled
        />
      </mui.core.Grid>
    </mui.core.Grid>
  );
};

export default StartDateOnly;
