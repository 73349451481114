import { ts } from '_core';

import { validate } from './helpers';
import { INTERACTION_PARAMS } from './types';

export default [
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.BASKET_COMPARE_RELATIVE_FACTOR_EXPOSURE,
    title: 'Relative Factor Exposure',
    form: true,
    helpLabel: 'mp22pn9q',
    generalAnalysisReportHelp: {
      document: ts.enums.UI_RESOURCE_TYPE_ENUM.BASKET,
      contentPath: `/${ts.enums.BASKET_ANALYSIS_TYPE_ENUM.COMPARE}`,
    },
    neededFiles: ['basket_relative_factor_exposures'],
    interactionParams: Object.values(INTERACTION_PARAMS),
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.BASKET_COMPARE, ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.GENERAL],
    clearInteractionOnRegenerate: ['table_dtypes'],

    validate,
    generalAnalysisPrefix: 'Baskets ',
  },
] satisfies ts.types.analysis.WidgetConfig[];
