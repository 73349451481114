/* eslint-disable no-unused-vars */
import { ts } from '_core';

export enum INTERACTION_PARAMS {
  TABLE_PARAMS = 'table_params',
  TABLE_DTYPES = 'table_dtypes',
  COMPONENT_ERRORS = 'component_errors',
}

export type InteractionParams = {
  [INTERACTION_PARAMS.TABLE_PARAMS]: ts.types.components.dataGrid.TableParamsSheet;
  [INTERACTION_PARAMS.TABLE_DTYPES]: ts.types.components.dataGrid.TableDtypesSheet;
  [INTERACTION_PARAMS.COMPONENT_ERRORS]: string[];
};

export type JobParams = {
  preview_date: string;
  analysis_universe_id: number;
};

export type Params = JobParams & InteractionParams;
export type DraftParams = Partial<Params>;
