import React from 'react';

import GroupActions from './group-actions';
import Rule from './rule';
import { mui } from '../../../libs';
import * as types from '../../../types';

type GroupProps = {
  definition: any[];
  setDefinition: (_d: any[]) => void;
  config: types.components.queryBuilder.QbConfig;
  remove?: () => void;
};

const Group: React.FC<GroupProps> = ({ definition, setDefinition, config, remove }) => {
  const theme = mui.styles.useTheme() as mui.core.Theme;

  // first element of the group is the conjunction operator
  const conjunction = definition[0];
  // the rest of the array is the inner groups/rules
  const inner = definition.slice(1);

  // this will update the array with the new definition of rule, group or conjunction
  const updateInnerElement = (arrayIndex: number, val: any) => {
    const newDefinition = [...definition];
    newDefinition[arrayIndex] = val;
    setDefinition(newDefinition);
  };

  // when adding rule add array with three null definitions. (attr, operator, definition)
  const addRule = () => {
    const newDefinition = [...definition];
    newDefinition.push([null, null, null]);
    setDefinition(newDefinition);
  };

  const removeInnerElement = (index: number) => {
    setDefinition(definition.filter((_el, i) => i !== index));
  };

  // when adding group, default to and and empty rule
  const addGroup = () => {
    const newDefinition = [...definition];
    newDefinition.push(['$and']);
    setDefinition(newDefinition);
  };

  return (
    <mui.core.Box
      p={2}
      sx={{
        border: `1px solid ${(theme.palette as any).gray.main}`,
        borderRight: 0,
        borderTopLeftRadius: 4,
        borderBottomLeftRadius: 4,
        paddingRight: 0,
      }}
    >
      <mui.core.Box style={{ maxWidth: 1150 }}>
        <GroupActions
          conjunction={conjunction}
          updateInnerElement={updateInnerElement}
          addGroup={addGroup}
          remove={remove}
          config={config}
        />
        <mui.core.Box>
          {/* Inner groups or rules */}
          {inner.map((elem, index) => (
            <Element
              groupDef={definition}
              key={`${index} - ${inner.length}`}
              elem={elem}
              update={(val) => updateInnerElement(index + 1, val)}
              remove={() => removeInnerElement(index + 1)}
              config={config}
            />
          ))}
        </mui.core.Box>
        <mui.core.Box display="flex" alignItems="center">
          <mui.core.Button
            variant="outlined"
            color="primary"
            startIcon={<mui.icons.Add />}
            onClick={addRule}
            style={{ marginTop: '6px' }}
            size="small"
            disabled={config.readOnly}
          >
            Rule
          </mui.core.Button>
        </mui.core.Box>
      </mui.core.Box>
    </mui.core.Box>
  );
};

Group.defaultProps = {
  remove: null,
};

type ElementProps = {
  groupDef: any[];
  elem: any;
  update: (_v: any[]) => void;
  config: types.components.queryBuilder.QbConfig;
  remove?: () => void;
};

const Element: React.FC<ElementProps> = ({ groupDef, elem, update, remove, config }): React.ReactElement => {
  let component;

  // If the first element is a conjunction operator, then is a group
  if (elem[0] === '$and' || elem[0] === '$or') {
    component = <Group definition={elem} setDefinition={update} remove={remove} config={config} />;
  } else {
    component = <Rule groupDef={groupDef} definition={elem} setDefinition={update} remove={remove} config={config} />;
  }

  return (
    <mui.core.Box ml={0} mt={2} mb={2}>
      {component}
    </mui.core.Box>
  );
};

Element.defaultProps = {
  remove: null,
  groupDef: [],
};

export { Element };
export { Group };
