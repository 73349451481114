import { _, hooks, mui, React, ts, ui, uitheme } from '_core';

import { ManagerContext } from '../manager-context';

type WidgetFormProps = {
  widget: ts.types.analysis.WidgetExtended;
};

const WidgetForm: React.FC<WidgetFormProps> = ({ widget }): React.ReactElement => {
  const managerContext = React.useContext(ManagerContext);
  const {
    updateCardParameters,
    removeCard,
    noLayoutChanges,
    context,
    formErrors,
    validating,
    cards,
    triggerOnWidgetAction,
    isGeneralAnalysis,
    disableWidgetTitleEdit,
    reportHelp,
  } = managerContext;
  const theme = mui.styles.useTheme() as mui.core.Theme;
  const [openEdit, setOpenEdit] = React.useState(false);
  const INPUT_SAFE_CONTAINER = uitheme.layoutSize.INPUT_SAFE_CONTAINER;

  const params = widget.params || {};

  const setParams = async (newParams: Record<string, any>) => {
    updateCardParameters(widget.id, newParams);
  };

  const widgetErrors = React.useMemo(() => formErrors?.[widget.id] || [], [formErrors?.[widget.id]]);
  const validWidget = React.useMemo(() => !widgetErrors.length, [widgetErrors, validating]);
  const uiStyles = hooks.useUiStyles();

  const resetTitleInfo = async (params: Record<string, any>) => {
    await setParams({
      ...params,
      title: null,
      subtitle: null,
    });
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event: React.UIEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <mui.core.Grid item sm={widget.fullWidth || widget.params?.fullWidth ? 12 : true} style={{ minWidth: '50%' }}>
      <div style={{ height: '100%', width: '100%' }}>
        <mui.core.Card
          sx={{
            fontWeight: '500',
            position: 'relative',
          }}
          style={validWidget ? {} : { border: '1px solid #EEB8BE' }}
        >
          <mui.core.Box p={3} alignItems="center" onClick={() => triggerOnWidgetAction(widget.key)}>
            <mui.core.Box display="flex" alignItems="center">
              <mui.core.Box
                sx={{
                  paddingRight: '1.25rem',
                  fontSize: '0.8rem',
                  flex: 1,
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <mui.core.Box
                  sx={validWidget ? uiStyles.successIndicator : uiStyles.dangerIndicator}
                  style={{ marginRight: '0.33rem', minWidth: '8px' }}
                />
                {widget.helpLabel && reportHelp ? (
                  <ui.HelpContent
                    helpContent={{
                      helpLabel: widget.helpLabel,
                      contentTitle: widget.title,
                      ...reportHelp,
                    }}
                  >
                    <>
                      {widget.title}
                      <span
                        style={{
                          marginLeft: 5,
                          fontSize: '0.6rem',
                          opacity: 0.8,
                          color: theme.palette.text.secondary,
                        }}
                      >
                        {widget.subtitle}
                      </span>
                    </>
                  </ui.HelpContent>
                ) : (
                  <>
                    {widget.title}
                    <span
                      style={{
                        marginLeft: 5,
                        fontSize: '0.6rem',
                        opacity: 0.8,
                        color: theme.palette.text.secondary,
                      }}
                    >
                      {widget.subtitle}
                    </span>
                  </>
                )}

                {!widget.disableTitleEdit && !disableWidgetTitleEdit && (
                  <mui.core.Tooltip title="Edit Widget Name" arrow>
                    <mui.core.IconButton style={{ marginLeft: 5 }} size="small" onClick={() => setOpenEdit(true)}>
                      <mui.icons.Edit style={{ fontSize: 14 }} />
                    </mui.core.IconButton>
                  </mui.core.Tooltip>
                )}

                <ui.EditWidgetTitle
                  open={openEdit}
                  setOpen={setOpenEdit}
                  params={widget.params || {}}
                  setParams={setParams}
                  resetTitle={() => resetTitleInfo(widget.params)}
                />
              </mui.core.Box>

              {!_.isEmpty(widgetErrors) && (
                <mui.core.Box mr={2}>
                  {widgetErrors.length === 1 ? (
                    <mui.core.Chip color="error" variant="outlined" label={widgetErrors[0]} size="small" />
                  ) : (
                    <>
                      <mui.core.Chip
                        color="error"
                        label={`${widgetErrors.length} errors found`}
                        size="small"
                        variant="outlined"
                        onClick={handlePopoverOpen}
                      />
                      <mui.core.Popover
                        id="mouse-over-popover"
                        open={open}
                        anchorEl={anchorEl}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'left',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'left',
                        }}
                        disableRestoreFocus
                        onClose={handlePopoverClose}
                      >
                        <mui.core.Box p={2}>
                          <mui.core.Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                            <mui.core.Chip label="Errors" color="error" size="small" />
                            <mui.core.IconButton onClick={handlePopoverClose} size="small">
                              <mui.icons.Close style={{ fontSize: 17 }} />
                            </mui.core.IconButton>
                          </mui.core.Box>
                          {widgetErrors.map((error: string, index: number) => (
                            <mui.core.Typography key={index} variant="body2" color="textSecondary">
                              {error}
                            </mui.core.Typography>
                          ))}
                        </mui.core.Box>
                      </mui.core.Popover>
                    </>
                  )}
                </mui.core.Box>
              )}

              {!widget.fullWidth && cards.length != 1 && !noLayoutChanges && (
                <ui.WidgetSetFullWidth params={params} setParams={setParams} />
              )}
              {!noLayoutChanges && (
                <mui.core.Tooltip arrow title="Remove Widget">
                  <mui.core.IconButton aria-label="delete" onClick={() => removeCard(widget.id)} size="small">
                    <mui.icons.Close style={{ fontSize: '0.9rem' }} />
                  </mui.core.IconButton>
                </mui.core.Tooltip>
              )}
            </mui.core.Box>

            {params.title && (
              <div style={{ marginLeft: 12 }}>
                <mui.core.Typography variant="caption" fontWeight="fontWeightNormal" color="textSecondary">
                  Custom title: <strong style={{ fontWeight: 500 }}>{params.title}</strong>
                </mui.core.Typography>
              </div>
            )}

            {params.subtitle && (
              <div style={{ marginLeft: 12 }}>
                <mui.core.Typography variant="caption" fontWeight="fontWeightNormal" color="textSecondary">
                  Custom subtitle: <strong style={{ fontWeight: 500 }}>{params.subtitle}</strong>
                </mui.core.Typography>
              </div>
            )}

            <mui.core.Box maxWidth={INPUT_SAFE_CONTAINER}>
              {widget.form && (
                <React.Suspense fallback={<></>}>
                  <widget.componentModule
                    {...{
                      id: widget.id,
                      params,
                      mode: 'edit',
                      context,
                      setParams,
                      isGeneralAnalysis,
                    }}
                  />
                </React.Suspense>
              )}
            </mui.core.Box>
          </mui.core.Box>
        </mui.core.Card>
      </div>
    </mui.core.Grid>
  );
};

export default WidgetForm;
