import { _, moment } from '../libs';
import { GicsData, IndustryData, RBicsData } from '../types/industries';

/**
 * Interface for RBICS API response item
 */
type RbicsApiItem = {
  end_date: string;
  l_id: string;
  l_name: string;
  start_date: string;
};

/**
 * Parses RBICS data from API response into structured format
 * @param items - Array of RBICS API items
 * @returns Object containing parsed RBICS data organized by levels
 */
const parseRbics = (items: RbicsApiItem[]): RBicsData => {
  const levelOneTemp = items.filter((item) => item.l_id.length == 2);

  const levelOne = _.uniqBy(
    levelOneTemp.map((el) => ({
      value: el.l_id,
      name: el.l_name,
      eff_start: el.start_date,
      eff_end: el.end_date,
    })),
    'value'
  );

  const levelTwoTemp = items.filter((item) => item.l_id.length == 4);

  const levelTwo = _.uniqBy(
    levelTwoTemp.map((el) => ({
      value: el.l_id,
      name: el.l_name,
      eff_start: el.start_date,
      eff_end: el.end_date,
    })),
    'value'
  );

  const levelThreeTemp = items.filter((item) => item.l_id.length == 6);

  const levelThree = _.uniqBy(
    levelThreeTemp.map((el) => ({
      value: el.l_id,
      name: el.l_name,
      eff_start: el.start_date,
      eff_end: el.end_date,
    })),
    'value'
  );

  const levelFourTemp = items.filter((item) => item.l_id.length == 8);

  const levelFour = _.uniqBy(
    levelFourTemp.map((el) => ({
      value: el.l_id,
      name: el.l_name,
      eff_start: el.start_date,
      eff_end: el.end_date,
    })),
    'value'
  );

  return { levelOne, levelTwo, levelThree, levelFour };
};

/**
 * Interface for GICS API response item
 */
type GicsApiItem = {
  code: string;
  description: string;
  eff_end: string;
  eff_start: string;
  type: string;
};

/**
 * Parses GICS data from API response into structured format
 * @param items - Array of GICS API items
 * @returns Object containing parsed GICS data organized by category
 */
const parseGics = (items: GicsApiItem[]): GicsData => {
  const sectorsTemp = items.filter((item) => item.type === 'sector');

  const sectors = sectorsTemp.map((el) => ({
    value: el.code,
    name: el.description,
    eff_start: el.eff_start,
    eff_end: el.eff_end,
  }));

  const groupsTemp = items.filter((item) => item.type === 'group');

  const groups = groupsTemp.map((el) => ({
    value: el.code,
    name: el.description,
    eff_start: el.eff_start,
    eff_end: el.eff_end,
  }));

  const industriesTemp = items.filter((item) => item.type === 'ind');

  const industries = industriesTemp.map((el) => ({
    value: el.code,
    name: el.description,
    eff_start: el.eff_start,
    eff_end: el.eff_end,
  }));

  const subindTemp = items.filter((item) => item.type === 'subind');

  const subIndustries = subindTemp.map((el) => ({
    value: el.code,
    name: el.description,
    eff_start: el.eff_start,
    eff_end: el.eff_end,
  }));

  return { sectors, industries, subIndustries, groups };
};

/**
 * Type definition for industry generator response
 */
type IndustryGeneratorResponse = {
  value: string;
  name: string;
  shortName: string;
}[];

/**
 * Generates formatted industry data array sorted by value
 * @param arr - Array of industry data objects
 * @returns Array of formatted industry objects with value, name and shortName
 */
const industryGenerator = (arr: IndustryData[]): IndustryGeneratorResponse =>
  _.sortBy(
    arr.map((a) => ({
      value: a.value,
      name: generateIndustryName(a),
      shortName: `${a.name} (${a.value})`,
    })),
    'value'
  );

/**
 * Generates a formatted industry name string with effective dates
 * @param s - Industry data object
 * @returns Formatted industry name string including dates if applicable
 */
const generateIndustryName = (s: IndustryData): string => {
  let name = `${s.name} (${s.value})`;

  if (new Date(s.eff_start) > new Date('1986-06-15T00:00:00'))
    name = name.concat(` [starts ${moment(s.eff_start).format('YYYY-MM-DD')}]`);
  if (new Date(s.eff_end) < new Date('2099-12-31T00:00:00'))
    name = name.concat(` [starts ${moment(s.eff_end).format('YYYY-MM-DD')}]`);

  return name;
};

export { parseRbics };
export { parseGics };
export { industryGenerator };
export { generateIndustryName };
