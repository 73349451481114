import { _, mui, React, ts, ui, useSelector } from '_core';

type ValueType<Multiple> = Multiple extends false | undefined ? number : number[];

type BasketSelectorProps<Multiple> = {
  basketIds: ValueType<Multiple>;
  setBasketIds: (_params: ValueType<Multiple>) => void;
  multiple?: boolean;
};

const BasketSelector = <Multiple extends boolean | undefined = true>(props: BasketSelectorProps<Multiple>) => {
  const { basketIds, setBasketIds, multiple } = props;
  const baskets = useSelector((state) => state.resources.baskets);
  const [snackbarMessage, setSnackbarMessage] = React.useState(null);

  const copyToClipboard = () => {
    if (multiple) {
      navigator.clipboard.writeText(_.isEmpty(basketIds) ? '' : (basketIds as number[]).join(','));
    } else {
      navigator.clipboard.writeText(!basketIds ? '' : basketIds.toString());
    }
    setSnackbarMessage('Copied to clipboard!');
  };

  const pasteToField = async () => {
    try {
      const clipboard = await navigator.clipboard.readText();
      const copiedBasketIds = clipboard.split(',').map((el) => Number(el));
      const filteredBasketIds = copiedBasketIds.filter((bid) => baskets.find((b) => b.id === bid));
      setBasketIds((multiple ? filteredBasketIds : filteredBasketIds[0]) as ValueType<Multiple>);
      setSnackbarMessage('Pasted succesfully!');
    } catch (err) {
      console.log('unable to paste to baskets field');
    }
  };

  return (
    <mui.core.Stack gap={2} display="flex" flexDirection="row" alignItems="flex-start">
      <mui.core.Box flex="1">
        <ui.ResourceAutocomplete<ts.types.basket.BasketExpanded, true>
          value={(basketIds || []) as number[]}
          setValue={(ids) => setBasketIds(ids as ValueType<Multiple>)}
          autocompleteProps={{
            multiple: multiple,
            size: 'small',
            disableCloseOnSelect: true,
          }}
          type={ts.enums.RESOURCES_TYPES_ENUM.BASKET}
          inputProps={{
            fullWidth: true,
            label: 'Baskets',
            variant: 'outlined',
            required: true,
            size: 'small',
          }}
        />
      </mui.core.Box>

      <mui.core.Tooltip title="Copy baskets">
        <mui.core.Button variant="outlined" onClick={copyToClipboard} disabled={_.isEmpty(basketIds)}>
          <mui.icons.FileCopyOutlined style={{ fontSize: '14px' }} />
        </mui.core.Button>
      </mui.core.Tooltip>

      <mui.core.Tooltip title="Paste baskets">
        <mui.core.Button variant="outlined" onClick={pasteToField}>
          <mui.icons.ContentPaste style={{ fontSize: '14px' }} />
        </mui.core.Button>
      </mui.core.Tooltip>

      <mui.core.Snackbar
        open={snackbarMessage}
        onClose={() => setSnackbarMessage(null)}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <mui.lab.Alert severity="success" variant="filled">
          {snackbarMessage}
        </mui.lab.Alert>
      </mui.core.Snackbar>
    </mui.core.Stack>
  );
};

export default BasketSelector;

BasketSelector.defaultProps = {
  multiple: true,
};
