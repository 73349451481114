import { _, hooks, mui, React, ts } from '_core';

import Tabular from 'views/report/tables/tabular';

import { summaryColumns, summaryColumnsWithBenchmark } from './columns-config';
import { Params } from './types';
import { getColumns } from '../../common/charts/utils';

type ChartProps = {
  id: string;
  readOnly: boolean;
  widgetKey: string;
  loadWidgetData: (_payload: ts.types.widgets.WidgetGetDataParams) => Promise<ts.types.widgets.WidgetGetDataResponse>;
  params: Params;
  setParams: (_p: Params) => void;
  fullScreen: boolean;
  goFullScreen: (_v: string) => void;
  summary: string;
  queryParams?: { initial?: string };
  context?: ts.types.portfolio.PortfolioAnalysisContext;
};

const Chart: React.FC<ChartProps> = ({
  id,
  readOnly,
  widgetKey,
  loadWidgetData,
  summary,
  goFullScreen,
  params,
  setParams,
  fullScreen,
}) => {
  const [error, setError] = React.useState<ts.types.common.ApiError>(null);
  const [data, setData] = React.useState<Record<string, ts.types.widgets.TableData>>();

  const [containerHeight, setContainerHeight] = React.useState(undefined);
  const { height } = hooks.useWindowDimensions();

  const container = React.createRef<HTMLDivElement>();

  React.useEffect(() => {
    if (container.current) {
      setContainerHeight(container.current.offsetHeight - 40);
    }
  }, [height]);

  const [tableParams, setTableParams] = React.useState<ts.types.components.dataGrid.TableParamsSheet>(
    params.table_params
  );

  const [expandedGroupIds, setExpandedGroupIds] = React.useState<Record<string, ReadonlySet<unknown>>>(
    params.expanded ? Object.fromEntries(Object.entries(params.expanded).map(([key, val]) => [key, new Set(val)])) : {}
  );

  const debouncedParamsSave = React.useCallback(
    _.debounce(async (exp: typeof expandedGroupIds, tbP: ts.types.components.dataGrid.TableParamsSheet) => {
      setParams({
        expanded: Object.fromEntries(
          Object.entries(exp).map(([key, val]) => [key, [...val] as string[]])
        ) as Params['expanded'],
        table_params: { ...tbP },
        fullWidth: true,
      });
    }, 1_000),
    []
  );

  hooks.useEffectWithoutFirst(() => {
    debouncedParamsSave(expandedGroupIds, tableParams);
  }, [expandedGroupIds, tableParams]);

  const loadData = async () => {
    try {
      const resp = await loadWidgetData({
        data: { file: summary },
      });

      if (resp) {
        const data = resp.data;

        const dataBySheet = data.reduce((acc: Record<string, ts.types.widgets.TableData>, item) => {
          const category = (item['sheet'] as string) ?? 'Summary';
          acc[category] = (acc[category] || []).concat(item);
          return acc;
        }, {});

        setData(dataBySheet);

        if (_.isEmpty(expandedGroupIds)) {
          setExpandedGroupIds(
            Object.entries(dataBySheet).reduce((acc: Record<string, Set<string | number | boolean>>, [key, values]) => {
              acc[key] = new Set(Object.values(values).map((x) => x.label));
              return acc;
            }, {})
          );
        }
      }
    } catch (err) {
      setError(err as ts.types.common.ApiError);
    }
  };

  React.useEffect(() => {
    if (summary) loadData();
    return () => setData(null);
  }, [summary]);

  return (
    <mui.core.Box
      id={id}
      key={widgetKey}
      sx={{
        height: 'calc(100% - 1px)',
        '& .rdg-light': { paddingBottom: '60px' },
      }}
      ref={container}
    >
      <Tabular
        data={data}
        apiError={error}
        readOnly={readOnly}
        getColumns={Object.keys(data ?? {}).reduce(
          (acc: Record<string, ts.types.components.dataGrid.GenerateColumns>, key) => {
            acc[key] = () => {
              const tableColumns =
                'Summary' in data && 'benchmark' in data['Summary'][0] ? summaryColumnsWithBenchmark : summaryColumns;
              return getColumns(
                tableColumns.map((c) => c.key),
                null,
                tableColumns
              );
            };
            return acc;
          },
          {}
        )}
        sheetTitles={Object.keys(data ?? {}).reduce((acc: Record<string, string>, key) => {
          acc[key] = key;
          return acc;
        }, {})}
        fullScreen={fullScreen}
        goFullScreen={goFullScreen}
        hideFilters
        alwaysShowBottom
        groupBy={Object.keys(data ?? {}).reduce((acc: Record<string, string[]>, key) => {
          acc[key] = ['label'];
          return acc;
        }, {})}
        expandedGroupIds={expandedGroupIds}
        setExpandedGroupIds={Object.keys(data ?? {}).reduce(
          (acc: Record<string, (_v: ReadonlySet<unknown>) => void>, key) => {
            acc[key] = (v) => setExpandedGroupIds((exp) => ({ ...exp, key: v }));
            return acc;
          },
          {}
        )}
        tableParams={tableParams}
        setTableParams={Object.keys(data ?? {}).reduce(
          (acc: Record<string, (_v: ts.types.components.dataGrid.TableParams) => void>, key) => {
            acc[key] = (v) => setTableParams((exp) => ({ ...exp, key: v }));
            return acc;
          },
          {}
        )}
        overrideHeight={containerHeight}
        triggerWidthChange={params.fullWidth}
      />
    </mui.core.Box>
  );
};

export default Chart;
