import { _, api, hooks, React, ts, useSelector } from '_core';

import * as columns from 'views/preferences/columns-config';

const useGetPreferences = (
  preferenceKey?: ts.enums.PREFERENCES_KEY_ENUM,
  currentView?: number,
  preferencesSourceWidgetId?: string,
  override?: ts.types.userPreference.UserPreference['definition'],
  defaultPreferences = [{ id: null as number, title: 'Default' }]
  // eslint-disable-next-line max-len
): [
  boolean,
  ts.types.userPreference.UserPreference['definition'],
  ts.types.userPreference.UserPreferenceDraft[],
  ts.types.userPreference.UserPreferenceDraft,
] => {
  const [loadingPref, setLoadingPref] = React.useState(!!preferenceKey);
  const [selected, setSelected] = React.useState<ts.types.userPreference.UserPreferenceDraft>();
  const [options, setOptions] = React.useState<ts.types.userPreference.UserPreferenceDraft[]>();
  const [pref, setPref] = React.useState<ts.types.userPreference.UserPreference['definition']>({});

  const prefLastUpdated = useSelector((state) => (state as ts.StoreState).ui.preferencesLastUpdated);

  const loadPreference = async (localPreferenceId?: number) => {
    setLoadingPref(true);

    const loadOptions = await api.user_preferences.search({
      query: ['key', '=', preferenceKey],
    });
    const optionsData = loadOptions.data;

    try {
      const prefView =
        optionsData?.find((el) => el.id == localPreferenceId) ??
        optionsData?.find((el) => el.is_latest) ??
        optionsData?.[0];

      setPref(prefView.definition);
      setSelected({
        id: prefView.id,
        title: prefView.title,
      });

      setOptions(loadOptions.data ?? defaultPreferences);
      setLoadingPref(false);
    } catch {
      setLoadingPref(false);
    }
  };

  // First load or when preference id changes
  React.useEffect(() => {
    if (preferenceKey) loadPreference(currentView);
  }, [currentView]);

  // Loads when there is a change on preferences
  hooks.useEffectWithoutFirst(() => {
    if (prefLastUpdated?.preferenceKey != preferenceKey) return;
    if (selected?.id == prefLastUpdated.preferenceView) {
      loadPreference(prefLastUpdated.preferenceView);
      return;
    }

    if (preferencesSourceWidgetId) {
      if (prefLastUpdated.source_widget_id == preferencesSourceWidgetId) loadPreference(prefLastUpdated.preferenceView);
    } else {
      loadPreference(prefLastUpdated.preferenceView);
    }
  }, [prefLastUpdated]);

  const prefValue = React.useMemo(() => {
    if (_.isEmpty(pref)) {
      if (override) return override;
      if (columns.columnDefaults[preferenceKey]) return columns.columnDefaults[preferenceKey];
      return {};
    }

    return pref;
  }, [pref, override]);

  return [loadingPref, prefValue, _.isEmpty(options) ? defaultPreferences : options, selected] as any;
};

export default useGetPreferences;
