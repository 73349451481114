import { hooks, mui, React, ui } from '_core';

import { DraftParams } from './types';
import BasketsSelector from '../../common/forms/baskets-selector';
import PipelineSignalsSelector from '../../common/forms/pipelined-signals-selector';

type FormProps = {
  params: DraftParams;
  setParams: (_p: DraftParams) => void;
  maxDate: string;
  minDate: string;
};

const Form: React.FC<FormProps> = ({ params, setParams, maxDate, minDate }) => {
  const uiStyles = hooks.useUiStyles();

  const [basketIds, setBasketIds] = React.useState(params.basket_ids || []);
  const [signals, setSignals] = React.useState(params.analysis_pipelined_signals || [{} as any]);
  const [componentErrors, setComponentErrors] = React.useState([]);
  const [basketDate, setBasketDate] = React.useState(params.basket_date);
  const [runOnDate, setRunOnDate] = React.useState(params.run_on_date ?? false);
  const [dailyReporting, setDailyReporting] = React.useState(params.daily_reporting || false);

  React.useEffect(() => {
    const data = {
      ...params,
      basket_ids: basketIds,
      analysis_pipelined_signals: signals,
      component_errors: componentErrors,
      basket_date: runOnDate ? basketDate : null,
      run_on_date: runOnDate,
      daily_reporting: dailyReporting,
    };
    setParams(data);
  }, [basketIds, signals, basketDate, runOnDate, componentErrors, dailyReporting]);

  return (
    <mui.core.Box sx={uiStyles.widgetEditMode}>
      <mui.core.Box my={2}>
        <mui.core.Box marginBottom={4}>
          <mui.core.Typography variant="body2" paragraph sx={{ margin: 'auto 0' }}>
            <strong>Baskets *</strong>
          </mui.core.Typography>
        </mui.core.Box>
        <BasketsSelector basketIds={basketIds} setBasketIds={setBasketIds} />
      </mui.core.Box>

      <mui.core.Box my={2}>
        <PipelineSignalsSelector signals={signals} setSignals={setSignals} />
      </mui.core.Box>

      <mui.core.Box my={2}>
        <mui.core.Box display="flex" alignItems="center" gap={2} p={2}>
          <mui.core.FormControlLabel
            control={<mui.core.Switch size="small" checked={runOnDate} onChange={() => setRunOnDate(!runOnDate)} />}
            label="Run on specific date"
          />
          <mui.core.FormControlLabel
            control={
              <mui.core.Switch
                size="small"
                checked={dailyReporting}
                onChange={() => setDailyReporting(!dailyReporting)}
              />
            }
            label="Daily Reporting"
          />
        </mui.core.Box>

        {runOnDate && (
          <ui.SmartDateSelector
            label="Date"
            value={basketDate}
            setValue={(date) => setBasketDate(date)}
            minDate={minDate}
            maxDate={maxDate}
            setExternalError={(e) => setComponentErrors(e ? [e] : [])}
          />
        )}
      </mui.core.Box>
    </mui.core.Box>
  );
};

export default Form;
