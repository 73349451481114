import { _, actions, config, helpers, mui, React, ts, ui,useDispatch, useHistory, useSelector } from '_core';

import * as utils from '../signal-utils';

type SignalFormProps = {
  close: () => void;
  setErrorMessage: (_message: string) => void;
};

const SignalForm: React.FC<SignalFormProps> = ({ setErrorMessage, close }) => {
  const signals = useSelector((state) => state.resources.signals as ts.types.signal.Signal[]);
  const workspaces = useSelector((state) => state.resources.workspaces as ts.types.workspace.Workspace[]);
  const currentWorkspaceId = useSelector((state) => state.ui.currentWorkspaceId);

  const dispatch = useDispatch();
  const updateSignalDatasets = (ds: ts.types.signal.Dataset) => dispatch(actions.resources.updateSignalDataset(ds));

  const history = useHistory();

  const [name, setName] = React.useState('');
  const [userProvided, setUserProvided] = React.useState(false);
  const [userProvidedFrequency, setUserProvidedFrequency] = React.useState(ts.enums.FREQUENCY_ENUM.DAILY);
  const [handle, setHandle] = React.useState('');
  const [template, setTemplate] = React.useState<ts.types.signal.Signal>();
  const [loadingCreate, setLoadingCreate] = React.useState(false);
  const [workspace, setWorkspace] = React.useState(
    () => workspaces.find((w) => w.id == currentWorkspaceId) || workspaces[0]
  );

  // To copy signal datasets
  const [loadingDatasets, setLoadingDatasets] = React.useState(false);
  const [signalDatasets, setSignalDatasets] = React.useState([] as ts.types.signal.Dataset[]);
  const [createDatasets, setCreateDatasets] = React.useState(true);

  // Load datasets
  React.useEffect(() => {
    if (template?.id) {
      setLoadingDatasets(true);
      utils.apiProcedures.loadArtifacts(
        template,
        setLoadingDatasets,
        (dsts) => setSignalDatasets(dsts),
        () => setErrorMessage('Error loading datasets'),
        false
      );
    } else {
      setSignalDatasets([] as ts.types.signal.Dataset[]);
    }
  }, [template]);

  // Create handle while typing name
  React.useEffect(() => {
    setHandle(helpers.createHandle(name, 'si'));
  }, [name]);

  const handleSubmit = async () => {
    setErrorMessage('');

    // Validation
    if (!name || !handle) {
      setErrorMessage('All attributes are required.');
      setLoadingCreate(false);
      return;
    }

    setLoadingCreate(true);

    const newSignal = utils.definition.getNewSignal(name, handle, userProvided, userProvidedFrequency, template);

    utils.apiProcedures.createSignal(
      newSignal,
      async (data, datasets) => {
        if (createDatasets && !_.isEmpty(datasets)) {
          datasets.forEach((ds) => updateSignalDatasets(ds.data));
        }

        if (workspace)
          await helpers.resourcesUtils.addToWorkspace(data.id, ts.enums.UI_RESOURCE_TYPE_ENUM.SIGNAL, workspace);
        close();
        history.push(`/signals/${data.id}`);
      },
      async (err) => {
        setErrorMessage(err);
        setLoadingCreate(false);
      },
      createDatasets && !_.isEmpty(signalDatasets) ? signalDatasets : undefined
    );
  };

  const formContent = () => (
    <mui.core.Box mt={3}>
      <mui.core.Box mb={4}>
        <mui.core.Grid container spacing={3}>
          <mui.core.Grid item xs={12} sm={6}>
            <mui.core.TextField
              autoFocus
              id="name"
              label={'Signal name'}
              value={name}
              onChange={(e) => setName(e.target.value)}
              fullWidth
              variant="outlined"
              required
              size="small"
              inputProps={{
                autocomplete: 'off',
              }}
            />
          </mui.core.Grid>
          <mui.core.Grid item xs={12} sm={6}>
            <mui.core.TextField
              id="handle"
              label={'Signal handle'}
              value={handle}
              onChange={(e) => setHandle(e.target.value)}
              fullWidth
              variant="outlined"
              required
              size="small"
            />
          </mui.core.Grid>
        </mui.core.Grid>
      </mui.core.Box>

      {config.features.api_only_signal && (
        <mui.core.Box pb={4}>
          <mui.core.FormControlLabel
            control={
              <mui.core.Switch
                size="small"
                checked={userProvided}
                color="primary"
                onChange={() => {
                  const newValue = !userProvided;
                  if (newValue) setTemplate(null);
                  setUserProvided(newValue);
                }}
              />
            }
            label="API Only"
          />
        </mui.core.Box>
      )}

      {userProvided && (
        <mui.core.Box pb={4}>
          <ui.Frequency value={userProvidedFrequency} setValue={setUserProvidedFrequency} />
        </mui.core.Box>
      )}

      {!userProvided && (
        <mui.core.Box py={4} display="flex">
          <mui.core.Box flexGrow={1}>
            <ui.ResourceAutocomplete<ts.types.signal.SignalExpanded>
              enableNone
              value={template?.id}
              setValue={(id) => setTemplate(signals.find((u) => u.id == id))}
              type={ts.enums.RESOURCES_TYPES_ENUM.SIGNAL}
              filter={(s) => !s.user_provided && !s.source_resource_id}
              inputProps={{
                fullWidth: true,
                label: 'Template',
                variant: 'outlined',
                required: true,
                size: 'small',
              }}
            />
          </mui.core.Box>

          {template?.id && (
            <mui.core.Box ml={2}>
              <mui.core.FormControlLabel
                control={
                  <mui.core.Switch
                    size="small"
                    checked={createDatasets}
                    onChange={() => setCreateDatasets((cd) => !cd)}
                    disabled={loadingDatasets || _.isEmpty(signalDatasets)}
                  />
                }
                label={
                  loadingDatasets
                    ? 'Loading datasets'
                    : _.isEmpty(signalDatasets)
                      ? 'No datasets found for template'
                      : // eslint-disable-next-line max-len
                        `Create ${signalDatasets.length} found signal parameters.`
                }
              />
            </mui.core.Box>
          )}
        </mui.core.Box>
      )}

      <ui.WorkspaceSelector value={workspace} setValue={setWorkspace} />
    </mui.core.Box>
  );

  return (
    <>
      <ui.DialogTitle closeAction={close}>Create Signal</ui.DialogTitle>

      <mui.core.DialogContent>{formContent()}</mui.core.DialogContent>

      <mui.core.DialogActions>
        <ui.Button
          variant="contained"
          color="primary"
          loading={loadingCreate}
          onClick={handleSubmit}
          style={{ margin: '0rem 1rem 1rem' }}
          fullWidth
        >
          {'Create Signal'}
        </ui.Button>
      </mui.core.DialogActions>
    </>
  );
};

export default SignalForm;
