import { ts } from '_core';

const defaultAlpha: ts.types.signal.OptimizedModelDraft = {
  training_sample: {
    frequency: ts.enums.FREQUENCY_ENUM.WEEKLY,
    number: 1,
  },
  return_horizon: {
    periods: { frequency: 'FREQUENCY', amount: 1 },
  },
  signals: [],
  risk_aversion: 0.0075,
  min_periods: 1,
};

export default defaultAlpha;
