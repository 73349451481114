import { _, am5, am5xy } from '@local/finsera-core/src/libs';
import * as types from '@local/finsera-core/src/types';

import { getColor } from '../../../../helpers';

export const createSeries = ({
  root,
  chart,
  data,
  xAxisField,
  xAxisRenderer,
  yAxisRenderer,
}: {
  root: am5.Root;
  chart: am5xy.XYChart;
  data: types.widgets.TableData;
  xAxisField: string;
  xAxisRenderer: am5xy.CategoryAxis<am5xy.AxisRenderer>;
  yAxisRenderer: am5xy.ValueAxis<am5xy.AxisRenderer>;
}) => {
  const createSingleSeries = (field: string, color: am5.Color) => {
    const series = chart.series.push(
      am5xy.LineSeries.new(root, {
        name: field,
        xAxis: xAxisRenderer,
        yAxis: yAxisRenderer,
        valueYField: field,
        categoryXField: xAxisField,
        stroke: color,
        fill: color,
        stacked: true,
        valueYShow: 'valueYTotalPercent',
        tooltip: am5.Tooltip.new(root, {
          pointerOrientation: 'horizontal',
          labelText: '[bold]{name}[/]\n{categoryX}: {valueYTotalPercent.formatNumber("#.0")}%',
        }),
      })
    );

    series.get('tooltip')?.label.setAll({
      fontSize: 11,
    });

    series.fills.template.setAll({
      visible: true,
      fillOpacity: 0.5,
    });

    return series;
  };

  const keys = _.without(Object.keys(data?.[0] ?? {}), xAxisField);
  keys.forEach((key, index) => {
    const seriesObj = createSingleSeries(key, am5.color(getColor(index)));
    seriesObj.data.setAll(data);
  });
};
