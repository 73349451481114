import { _, am4charts, am4core, helpers, ts } from '_core';

import { createImageHeader } from './create-image-header';
import { nestedBarChartFormat } from './formatters/nested-bar-chart';
import { getNestedBarLimits } from './utils';

type createNestedBarChartProps = {
  id: string;
  data: ts.types.widgets.TableData;
  fullScreen: boolean;
  setShowLegend: (_b: boolean) => void;
  exportTitle: string;

  exportSubTitle?: string;
  disableAxisLabel?: boolean;
  rotatedLabels?: boolean;
  format?: string;
  hideLegend?: boolean;
  yAxisLabel?: string;
  xAxisLabel?: string;
  yAxis?: string;
  xAxis?: string;
  hideSeries?: string[];
  tooltipText?: string;
  category?: string;
  percentPadding?: number;
  hideCategoryTooltip?: boolean;

  seriesOrder?: (_c: string[]) => string[];
  prepareData?: (_d: ts.types.widgets.NestedBarWidgetData) => ts.types.widgets.NestedBarWidgetData;
  setExternalEmbed?: (_data: ts.types.analysis.EmbedChartData) => void;

  // If data is already sent processed
  widgetIsEmbedded?: {
    download: boolean;
  };
  skipFormatter?: boolean;
};

const EMPTY_ARRAY: any[] = [];

const createNestedBarChart = (args: createNestedBarChartProps) => {
  const {
    id,
    data,
    fullScreen,
    setShowLegend,
    exportTitle,

    exportSubTitle = null,
    format = '#.##',
    disableAxisLabel = false,
    rotatedLabels = false,
    hideLegend = false,
    yAxisLabel = '',
    xAxisLabel = '',
    hideSeries = EMPTY_ARRAY,
    tooltipText = '[bold]{name}:[/] {valueY}',
    category = 'history',
    percentPadding = 0.15,
    hideCategoryTooltip = false,

    seriesOrder = (c) => c,
    prepareData = undefined,
    setExternalEmbed = null,

    widgetIsEmbedded = undefined,
    skipFormatter = false,
  }: createNestedBarChartProps = args;
  // Casting as any in case this comes from an embbeded widget which already has the
  // data prepared.
  let nestedBarData = data as any as ts.types.widgets.NestedBarWidgetData;

  if (!widgetIsEmbedded) {
    // Format table as line chart
    if (!skipFormatter) nestedBarData = nestedBarChartFormat(data as ts.types.widgets.TableData, category);

    // Prepare data
    if (prepareData) nestedBarData = prepareData(nestedBarData);

    // Apply sort by
    const sortedKeys = seriesOrder(Object.keys(nestedBarData.data?.[0] ?? {}));
    nestedBarData.data = nestedBarData.data.map((obj) => {
      const entries = Object.entries(obj);
      const sortedEntries = sortedKeys.map((key) => entries.find(([k, _v]) => k == key));
      return Object.fromEntries(sortedEntries);
    });
  }

  const chart = am4core.create(`chart-${id}-${fullScreen ? 'fs' : 'sw'}`, am4charts.XYChart);
  chart.data = nestedBarData.data;
  if (setExternalEmbed)
    setExternalEmbed({
      id,
      widgetTitle: exportTitle,
      widgetSubTitle: exportSubTitle,
      chart_type: 'nested-bar-chart',
      args: helpers.embed.argsToStringifiable({
        ...args,
        data: nestedBarData,
      }),
    });

  const count = nestedBarData.count;

  const { maxLimit, minLimit } = getNestedBarLimits(nestedBarData.data, hideSeries, percentPadding);

  if (format) chart.numberFormatter.numberFormat = format;
  chart.cursor = new am4charts.XYCursor();
  chart.cursor.behavior = 'zoomX';
  chart.cursor.maxTooltipDistance = 0;
  chart.fontSize = 11;

  const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
  categoryAxis.dataFields.category = 'category';
  categoryAxis.renderer.grid.template.location = 0;
  categoryAxis.renderer.minGridDistance = 30;

  if (hideCategoryTooltip) categoryAxis.cursorTooltipEnabled = false;
  else {
    categoryAxis.tooltip.fontSize = 11;
    categoryAxis.tooltip.background.fill = am4core.color('#3C5688');
    categoryAxis.tooltip.background.cornerRadius = 3;
    categoryAxis.tooltip.background.strokeWidth = 0;
  }

  if (xAxisLabel) {
    categoryAxis.title.marginTop = 10;
    categoryAxis.title.text = xAxisLabel;
  }

  if (rotatedLabels) {
    chart.fontSize = 9;
    categoryAxis.renderer.labels.template.rotation = 270;
    categoryAxis.renderer.labels.template.verticalCenter = 'middle';
    categoryAxis.renderer.labels.template.horizontalCenter = 'left';
  }

  if (disableAxisLabel) categoryAxis.renderer.labels.template.disabled = true;

  const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());

  valueAxis.min = minLimit;
  valueAxis.max = maxLimit;
  valueAxis.strictMinMax = true;
  valueAxis.tooltip.fontSize = 11;
  valueAxis.tooltip.background.fill = am4core.color('#3C5688');
  valueAxis.tooltip.background.cornerRadius = 3;
  valueAxis.tooltip.background.strokeWidth = 0;
  valueAxis.cursorTooltipEnabled = false;

  if (yAxisLabel) valueAxis.title.text = yAxisLabel;

  const createSeries = (value: string, name: string, color: number) => {
    const seriesName = name;

    const series = chart.series.push(new am4charts.ColumnSeries());
    series.dataFields.valueY = value;
    series.dataFields.categoryX = 'category';
    series.name = seriesName;
    series.tooltipText = tooltipText;

    if (!_.isEmpty(count)) {
      series.columns.template.events.on('over', function (ev) {
        const bucket = name;
        const history = ev.target.dataItem.categories.categoryX;
        series.tooltipText = `${tooltipText}\n[bold]Count:[/] ${count[history][bucket] || 0}`;
      });
    }

    series.tooltip.fontSize = 11;

    series.columns.template.fillOpacity = 1;
    series.fill = am4core.color(helpers.getColor(color));
    series.stroke = am4core.color(helpers.getColor(color));

    return series;
  };

  const firstObj = chart?.data?.[0] || {};
  const series = Object.keys(_.omit(firstObj, ['category'])).filter((el) => el);

  let hidden = 0;
  seriesOrder(series).forEach((serieKey, index) => {
    if (!hideSeries.includes(serieKey)) {
      createSeries(serieKey, serieKey, index - hidden);
    } else hidden += 1;
  });

  let showLegendLocal = false;

  if ((chart.series.values.length <= 7 || fullScreen) && !hideLegend) {
    showLegendLocal = true;
    setShowLegend(true);
  } else {
    setShowLegend(false);
  }

  function resizeLegend() {
    if (document.getElementById(`legend-${id}-${fullScreen ? 'fs' : 'sw'}`))
      document.getElementById(`legend-${id}-${fullScreen ? 'fs' : 'sw'}`).style.height =
        chart.legend.contentHeight + 10 + 'px';
  }

  chart.exporting.menu = new am4core.ExportMenu();
  chart.exporting.menu.align = 'left';
  chart.exporting.filePrefix = 'chart-data';

  chart.exporting.menu.items = widgetIsEmbedded
    ? widgetIsEmbedded.download
      ? [
          {
            label: '...',
            menu: [{ type: 'csv', label: 'Download as CSV' }],
          },
        ]
      : []
    : [
        {
          label: '...',
          menu: [
            { type: 'jpg', label: 'JPG' },
            { type: 'png', label: 'PNG' },
            { type: 'csv', label: 'CSV' },
          ],
        },
      ];

  if (showLegendLocal) {
    const legendContainer = am4core.create(`legend-${id}-${fullScreen ? 'fs' : 'sw'}`, am4core.Container);
    legendContainer.width = am4core.percent(100);
    legendContainer.height = am4core.percent(100);
    legendContainer.logo.disabled = true;

    chart.legend = new am4charts.Legend();
    chart.legend.parent = legendContainer;
    chart.legend.labels.template.maxWidth = 95;
    chart.legend.maxHeight = fullScreen ? undefined : 50;
    chart.legend.scrollable = true;
    chart.legend.itemContainers.template.paddingTop = 1;
    chart.legend.itemContainers.template.paddingBottom = 1;
    chart.legend.markers.template.width = 10;
    chart.legend.markers.template.height = 10;
    chart.legend.fontSize = 11;

    chart.events.on('datavalidated', resizeLegend);
    chart.events.on('maxsizechanged', resizeLegend);
    chart.legend.events.on('datavalidated', resizeLegend);
    chart.legend.events.on('maxsizechanged', resizeLegend);
    document.getElementById(`legend-${id}-${fullScreen ? 'fs' : 'sw'}`).style.height =
      chart.legend.contentHeight?.toString();

    // Add legend to the chart.
    chart.exporting.extraSprites.push(chart.legend);
  } else {
    document.getElementById(`legend-${id}-${fullScreen ? 'fs' : 'sw'}`).innerHTML = '';
  }

  chart.logo.disabled = true;

  createImageHeader({ chart, title: exportTitle, fullScreen, id });

  return chart;
};

export default createNestedBarChart;
