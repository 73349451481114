import { hooks, mui, React, ts, ui } from '_core';

import { DraftParams } from './types';

type FormProps = {
  params: DraftParams;
  setParams: (_p: DraftParams) => void;
  context?: ts.types.backtest.BacktestAnaylsisContext;
};

const Form: React.FC<FormProps> = ({ params, setParams, context }): React.ReactElement => {
  const [selectedHistories, setSelectedHistories] = React.useState(
    params.histories || ([] as ts.enums.HISTORY_ENUM_EXTENDED[])
  );
  const uiStyles = hooks.useUiStyles();

  React.useEffect(() => {
    const data = { histories: selectedHistories };

    setParams({ ...params, ...data });
  }, [selectedHistories]);

  return (
    <mui.core.Box sx={uiStyles.widgetEditMode}>
      <mui.core.Box mb={2}>
        <ui.WidgetHistories<true>
          multiple
          context={context}
          value={selectedHistories}
          setValue={setSelectedHistories}
        />
      </mui.core.Box>
    </mui.core.Box>
  );
};

export default Form;
