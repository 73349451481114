import { _, helpers, ts } from '_core';

import { DraftParams, Params } from './types';

export const formatSeriesName = (key: string, baskets: ts.types.basket.Basket[]) => {
  const currentBasket = baskets.find((b) => b.handle == key);
  if (currentBasket) return currentBasket.name;

  return key;
};

export const setUserDefinedOrderLinear = (
  data: ts.types.widgets.LinearData,
  basketId: number,
  basketIds: number[],
  baskets: ts.types.basket.Basket[]
) => {
  if (basketIds.includes(basketId)) {
    const newData = {} as ts.types.widgets.LinearData;
    basketIds.map((bid) => {
      const currentBasket = baskets.find((basket) => basket.id === bid)?.name;
      if (currentBasket) newData[currentBasket] = data[currentBasket];
    });
    return newData;
  }
  return data;
};

export const setUserDefinedOrderBar = (
  data: ts.types.widgets.NestedBarData,
  basketId: number,
  basketIds: number[],
  baskets: ts.types.basket.Basket[]
) => {
  if (basketIds.includes(basketId)) {
    return data.map((group) => {
      const groupData = {} as ts.types.widgets.NestedBarData[0];
      groupData['category'] = group['category'];
      basketIds.map((bid) => {
        const currentBasket = baskets.find((basket) => basket.id === bid)?.name;
        if (currentBasket) groupData[currentBasket] = group[currentBasket];
      });
      return groupData;
    });
  }
  return data;
};

export const prepareDataTurnOverVsTime = (
  fullData: ts.types.widgets.NestedBarWidgetData,
  params: Params,
  context: ts.types.basket.BasketAnalysisContext,
  baskets: ts.types.basket.Basket[]
) => {
  const localData = fullData.data;
  let newData = localData.map((group) => {
    const groupData = {} as ts.types.widgets.NestedBarData[0];
    Object.keys(group).forEach((innerKey) => {
      if (innerKey !== 'turnover') {
        const formattedInnerKey = formatSeriesName(innerKey.split('-')[0], baskets);
        groupData[formattedInnerKey] = group[innerKey];
      }
    });
    return groupData;
  });

  newData = setUserDefinedOrderBar(newData, context.basket_id, params.basket_ids, baskets);
  return {
    ...fullData,
    data: newData,
  } as ts.types.widgets.NestedBarWidgetData;
};

export const validate = (params: DraftParams) => helpers.validations.validBasketCompare(params);

export const getName = (params: DraftParams) => {
  const freq = params.aggregation == 'ANNUAL' ? 'Yearly' : params.aggregation;
  return `${_.startCase(freq)} Turnover`;
};

export const formatData = (data: ts.types.widgets.TableData): ts.types.widgets.TableData => {
  const groupedData = _.groupBy(data, 'date');

  const formattedData = _.map(groupedData, (items, date) => {
    const formattedEntry: ts.types.widgets.TableData[number] = {
      date: Number(date),
      type: 'value',
    };

    items.forEach((item) => {
      const { backtest_handle, turnover } = item;

      formattedEntry[`${backtest_handle}-turnover`] = turnover;
    });

    return formattedEntry;
  });

  return formattedData;
};
