import { React, ts } from '_core';

import Chart from './chart';
import { DraftParams, Params } from './types';

type BasketReturnsDrivenBySectorProps = {
  id: string;
  readOnly: boolean;
  widgetKey: string;
  mode: ts.enums.CHART_MODE_ENUM;
  params: Params | DraftParams;
  setParams: (_p: DraftParams) => void;

  queryParams?: { initial?: string };
  goFullScreen?: (_v: string) => void;
  fullScreen?: boolean;
  context?: ts.types.basket.BasketAnalysisContext;
  widgetPaths?: Record<'signal_returns_driven_attribution_chart', string>;

  loadWidgetData: (_payload: ts.types.widgets.WidgetGetDataParams) => Promise<ts.types.widgets.WidgetGetDataResponse>;
};

const BasketReturnsDrivenBySector: React.FC<BasketReturnsDrivenBySectorProps> = ({
  id,
  readOnly,
  widgetKey,
  loadWidgetData,
  fullScreen,
  widgetPaths,
  params,
  setParams,
}): React.ReactElement => {
  return (
    <Chart
      id={id}
      readOnly={readOnly}
      widgetKey={widgetKey}
      loadWidgetData={loadWidgetData}
      file={widgetPaths['signal_returns_driven_attribution_chart']}
      params={params}
      setParams={setParams}
      fullScreen={fullScreen}
    />
  );
};

export default BasketReturnsDrivenBySector;
