/* eslint-disable no-unused-vars */
import { ts } from '_core';

export enum INTERACTION_PARAMS {
  TABLE_PARAMS = 'table_params',
  EXPANDED = 'expanded',
  CURRENT_SHEET = 'current_sheet',
}

export type SHEETS =
  | ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.REBALANCE
  | ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.TRADE;

export type InteractionParams = {
  [INTERACTION_PARAMS.EXPANDED]: Record<SHEETS, string[]>;
  [INTERACTION_PARAMS.CURRENT_SHEET]: SHEETS;
  [INTERACTION_PARAMS.TABLE_PARAMS]: ts.types.components.dataGrid.TableParamsSheet;
};

export type Params = InteractionParams & { fullWidth: boolean };
