import { ts } from '_core';

import { getName, validate } from './helpers';
import { DraftParams } from './types';

export default [
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.BASKET_COMPARE_SUMMARY_STATISTICS,
    title: 'Summary Statistics',
    form: true,
    helpLabel: 'k7utdh8y',
    defaultParams: {
      histories: ['Full'],
    } as DraftParams,
    neededFiles: ['backtest_summarized_statistics_analysis'],
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.BASKET_COMPARE],

    validate,
    getName,
  },
] satisfies ts.types.analysis.WidgetConfig[];
