import { _, ts } from '_core';

import { getCountryName, getIndustryName } from '../../common/utils/get-series-name';

// prepareConstrainedPortfolioData
const transform = (
  data: ts.types.widgets.LinearData,
  tabs: any[],
  resources: ts.StoreState['resources']
) => {
  const newData = {} as Record<string, typeof data>;
  tabs.forEach((tab) => (newData[tab] = {})); // Set as empty arrays

  Object.keys(data).forEach((key) => {
    let newkey = key.replace('benchmark|', '');

    const isBenchmark = key.includes('benchmark|');
    const keyType = newkey.split('|')[0];
    const tab = isBenchmark ? `active_${keyType}` : keyType;

    if (newkey.startsWith('industry|l1|'))
      newkey = getIndustryName(newkey.replace('industry|l1|', ''), 'rbics_l1_id', resources);
    if (newkey.startsWith('industry|l2|'))
      newkey = getIndustryName(newkey.replace('industry|l2|', ''), 'rbics_l2_id', resources);
    if (newkey.startsWith('industry|l3|'))
      newkey = getIndustryName(newkey.replace('industry|l3|', ''), 'rbics_l3_id', resources);
    if (newkey.startsWith('industry|l4|'))
      newkey = getIndustryName(newkey.replace('industry|l4|', ''), 'rbics_l4_id', resources);
    if (newkey.startsWith('industry|l4|'))
      newkey = getIndustryName(newkey.replace('industry|l4|', ''), 'rbics_l4_id', resources);
    if (newkey.startsWith('country|')) newkey = getCountryName(newkey.replace('country|', ''));

    if (tabs.includes(tab)) newData[tab][newkey] = data[key];
    else newData['other'][key] = data[key];
  });

  return newData;
};

const getDataTabs = (sampleData: ts.types.widgets.TableData[0], tabs: string[]) => {
  const newTabs = _(sampleData)
    .keys()
    .filter((key) => key !== 'date')
    .map((key) => {
      const newkey = key.replace('benchmark|', '');
      const keyType = newkey.split('|')[0];
      const newKey = key.includes('benchmark|') ? `active_${keyType}` : keyType;
      return tabs.includes(newKey) ? newKey : 'other';
    })
    .uniq()
    .value();

  return newTabs;
};

export { transform };
export { getDataTabs };
