import { React, ts } from '_core';

import Chart from './chart';
import { DraftParams, Params } from './types';

type RiskModelIndustryCoverageProps = {
  params: Params;
  setParams: (_p: DraftParams) => void;
  loadWidgetData: (_payload: ts.types.widgets.WidgetGetDataParams) => Promise<ts.types.widgets.WidgetGetDataResponse>;
  widgetPaths?: Record<'risk_model_industry_coverage_summary' | 'risk_model_industry_coverage_time_series', string>;
  goFullScreen: (_q: string) => void;
  fullScreen: boolean;
  queryParams?: { initial?: string };
};

const RiskModelIndustryCoverage: React.FC<RiskModelIndustryCoverageProps> = ({
  params,
  setParams,
  loadWidgetData,
  widgetPaths,
  fullScreen,
  goFullScreen,
  queryParams,
}) => {
  return (
    <Chart
      params={params}
      setParams={setParams}
      summaryFile={widgetPaths['risk_model_industry_coverage_summary']}
      detailFile={widgetPaths['risk_model_industry_coverage_time_series']}
      loadWidgetData={loadWidgetData}
      goFullScreen={goFullScreen}
      fullScreen={fullScreen}
      queryParams={queryParams}
    />
  );
};

export default RiskModelIndustryCoverage;
