import { History } from 'history';

import * as pluralize from './pluralize';
import { FREQUENCY_ENUM, JOB_STATUS_ENUM } from '../enums';
import { _, moment } from '../libs';
import { ResourceAnalysis } from '../types/analysis';
import { Resource } from '../types/common';
import { Job, JobStatusResponse } from '../types/job';

/**
 * Extended Job type that includes analysis details
 */
type FullJob = Job & {
  analysis: ResourceAnalysis & {
    resource: Resource;
    signal_dataset: {
      id: number;
      universe_id: number;
      frequency: FREQUENCY_ENUM;
    };
  };
};

/**
 * Converts a JSON object into a YAML-like string representation
 * @param json - The JSON object to convert
 * @param d - Indentation depth
 * @returns YAML-like string representation of the JSON
 */
const yamliJson = (json: string | number | any[] | Record<string, any>, d = 0): string => {
  try {
    const str = [];
    if (_.isArray(json)) str.push(`\n${json.map((j) => yamliJson(j, d + 1)).join('\n')}`);
    else if (_.isObject(json)) {
      for (const k in json) {
        str.push(`${k}: ${yamliJson(json[k], d + 1)}`);
      }
    } else {
      str.push(`${json}`);
    }
    return str.map((s) => s.padStart(d * 2 + s.length, ' ')).join('\n');
  } catch (error) {
    return 'Unable to Yamlijson';
  }
};

/**
 * Formats a progress value ensuring it doesn't exceed 99%
 * @param value - Progress value as number or string
 * @returns Formatted progress number between 0-99
 */
export const formatProgress = (value: number | string): number => {
  return Math.min(Math.floor(+value), 99);
};

/**
 * Gets the status and optional message for a job
 * @param job - Job object to check status for
 * @param withMessage - Whether to include status message
 * @param reportTitle - Optional title to include in status messages
 * @returns Job status enum or status response object with message
 */
export const jobStatus = (
  job: Job,
  withMessage = false,
  reportTitle = ''
): JOB_STATUS_ENUM | JobStatusResponse | null => {
  if (!job) return null;
  let status = null;
  let message = 'None';
  // TODO: If the job is too old, say 1 day, it should be considered FAILED as well
  if (!_.isEmpty(job.completed_at)) {
    if (!_.isEmpty(job.error)) {
      status = JOB_STATUS_ENUM.ERROR;
      message = yamliJson(job.error || {});
    } else {
      status = JOB_STATUS_ENUM.SUCCESS;
      message = reportTitle ? `${reportTitle} succeeded` : 'Job succeeded';
    }
  } else {
    const age = moment.duration(moment().diff(moment(job.created_at))).asHours();
    if (age > 12) {
      status = JOB_STATUS_ENUM.ERROR;
      message = reportTitle ? `${reportTitle} timed out` : 'Job timed out';
    } else {
      status = JOB_STATUS_ENUM.RUNNING;
      if (_.isEmpty(job.started_at)) {
        message = 'Waiting in the queue...';
      } else if (job.progress) {
        message = `${formatProgress(job.progress)}% done`;
      } else message = reportTitle ? `Waiting for ${reportTitle}...` : 'Waiting...';
    }
  }
  if (!withMessage) return status;
  return { status, message };
};

/**
 * Checks if a job matches the expected status
 * @param job - Job object to check
 * @param status - Expected status to check against
 * @returns Boolean indicating if job status matches expected status
 * @throws Error if status is invalid
 */
export const jobCheck = (job: Job, status: JOB_STATUS_ENUM): boolean => {
  // eslint-disable-next-line no-throw-literal
  if ([JOB_STATUS_ENUM.ERROR, JOB_STATUS_ENUM.SUCCESS, JOB_STATUS_ENUM.RUNNING].indexOf(status) < 0)
    throw `Invalid Job Status - ${status}`;
  return jobStatus(job) === status;
};

/**
 * For an analysis job, return the URL to that job report and a flag indicating whether we are currently at that URL
 * @param job - Full job object containing analysis details
 * @param history - History object to check current location
 * @returns Tuple containing [reportUrl, isCurrentlyAtUrl]
 */
export const jobReportUrl = (job: FullJob, history: History | null): [string, boolean] => {
  if (!job?.analysis) return ['', false];

  const { resource } = job.analysis;
  const resourceType = job.item_type.replace('_analysis', '');
  const resourceUrl = `/${pluralize.plural(resourceType)}/${resource.id}`;

  let reportUrl;
  if (resourceType == 'signal') {
    reportUrl = `${resourceUrl}/dataset/${job.analysis.signal_dataset.id}/g/${job.analysis.name}`;
  } else {
    reportUrl = `${resourceUrl}/g/${job.analysis.name}`;
  }

  const inResource =
    history !== null &&
    (history.location.pathname.includes(`${reportUrl}`) || history.location.pathname == resourceUrl);

  return [reportUrl, inResource];
};
