import { Portfolio } from '../../../types/portfolio';
import { PortfolioExpanded } from '../../../types/portfolio';

const expandPortfolio = (portfolio: Portfolio & { resourceType: string }): PortfolioExpanded => {
  return {
    id: portfolio.id,
    name: portfolio.name,
    handle: portfolio.handle,
    resourceType: portfolio.resourceType,
    created_by_id: portfolio.created_by_id,
    label_ids: portfolio.label_ids,
    revoked: false,
    definition: [],
    is_deprecated: portfolio.is_deprecated,
    is_valid: portfolio.is_valid,
    created_at: portfolio.created_at,
    organization_id: portfolio.organization_id,
  } as PortfolioExpanded;
};

export default expandPortfolio;
