/* eslint-disable max-len */
import { _, mui, ts } from '_core';

/**
 * Converts the application's sort model to MuiX DataGrid sort model format
 * @param {ts.types.components.dataGrid.GridSortBy} initialSortBy - The initial sort configuration
 * @returns {mui.dataGrid.GridSortModel} The converted MuiX sort model
 *
 * @example
 * const sortBy = [{ columnKey: 'name', direction: 'ASC' }];
 * const muiXSortModel = convertSortByToMuiX(sortBy);
 * console.log(muiXSortModel); // [{ field: 'name', sort: 'asc' }]
 */
export const convertSortByToMuiX = (
  initialSortBy: ts.types.components.dataGrid.GridSortBy
): mui.dataGrid.GridSortModel => {
  return initialSortBy?.map((el) => ({
    field: el.columnKey,
    sort: _.lowerCase(el.direction),
  })) as mui.dataGrid.GridSortModel;
};

/**
 * Converts MuiX DataGrid sort model to the application's sort model format
 * @param {mui.dataGrid.GridSortModel} sortBy - The MuiX sort model
 * @returns {ts.types.components.dataGrid.GridSortBy} The converted application sort model
 *
 * @example
 * const muiXSortModel = [{ field: 'name', sort: 'asc' }];
 * const sortBy = convertMuiXSortBy(muiXSortModel);
 * console.log(sortBy); // [{ columnKey: 'name', direction: 'ASC' }]
 */
export const convertMuiXSortBy = (sortBy: mui.dataGrid.GridSortModel): ts.types.components.dataGrid.GridSortBy => {
  return sortBy?.map((el) => ({
    columnKey: el.field,
    direction: _.upperCase(el.sort) as 'ASC' | 'DESC',
  }));
};

/**
 * Converts the application's filter model to MuiX DataGrid filter model format
 * @param {ValueFilters} initialFilters - The initial filter configuration
 * @param {mui.dataGrid.GridLogicOperator} [filterConjuction] - Optional logic operator for combining filters
 * @returns {ts.types.components.dataGrid.GridFilterModel} The converted MuiX filter model
 *
 * @example
 * const filters = [{ column: 'name', operator: 'eq', value: 'John', column_type: 'string' }];
 * const muiXFilterModel = convertFiltersToMuiX(filters, 'AND');
 * console.log(muiXFilterModel); // { items: [{ field: 'name', operator: 'eq', value: 'John', column_type: 'string' }], logicOperator: 'AND' }
 */
export const convertFiltersToMuiX = (
  initialFilters: ts.types.components.dataGrid.ValueFilters,
  filterConjuction?: mui.dataGrid.GridLogicOperator
): ts.types.components.dataGrid.GridFilterModel => {
  return {
    items:
      initialFilters?.map((filter) => ({
        field: filter.column,
        operator: filter.operator,
        value: filter.value,
        column_type: filter.column_type,
      })) || [],
    logicOperator: filterConjuction,
  };
};

/**
 * Converts MuiX DataGrid filter model to the application's filter model format
 * @param {ts.types.components.dataGrid.GridFilterModel} filters - The MuiX filter model
 * @returns {ValueFilters} The converted application filter model
 *
 * @example
 * const muiXFilterModel = { items: [{ field: 'name', operator: 'eq', value: 'John', column_type: 'string' }], logicOperator: 'AND' };
 * const filters = convertMuiXFilters(muiXFilterModel);
 * console.log(filters); // [{ column: 'name', operator: 'eq', value: 'John', column_type: 'string' }]
 */
export const convertMuiXFilters = (
  filters: ts.types.components.dataGrid.GridFilterModel
): ts.types.components.dataGrid.ValueFilters => {
  return filters?.items?.map((filterItem) => ({
    column: filterItem.field,
    operator: filterItem.operator,
    value: filterItem.value,
    column_type: filterItem.column_type,
  }));
};
