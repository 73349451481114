import { ts } from '_core';

import { validate } from './helpers';

export default [
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.OPTIMIZED_RETURNS_WEIGHTS_COV,
    title: 'Returns, Weights and Covariances',
    helpLabel: 'sf8tjxws',
    form: true,
    neededFiles: ['optimized_alpha_model_data', 'optimized_alpha_model_covariance'],
    validate,
    // for preview
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.OPTIMIZED_ALPHA_MODEL_PREVIEW],
  },
] satisfies ts.types.analysis.WidgetConfig[];
