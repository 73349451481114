import { _, hooks, mui, React, ui, uitheme } from '_core';

import { AsyncDataGridContext } from './async-context';
import Toolbar from '../../toolbar';

const MIN_TABLE_HEIGHT = uitheme.layoutSize.MIN_TABLE_HEIGHT;

const PreviewContainer = React.forwardRef<HTMLDivElement, mui.core.BoxProps>((props, ref) => {
  const theme = mui.styles.useTheme() as mui.core.Theme;
  return (
    <mui.core.Box
      {...props}
      ref={ref}
      sx={{
        background: '#fff',
        height: 'calc(100% - 12px)',
        position: 'relative',
        margin: '6px 4px',
        borderRadius: '4px',
        boxShadow: theme.shadows[1],
        '@media print': {
          overflowX: 'auto',
          maxWidth: '100vw',
        },
        '& .react-grid-Toolbar .tools': {
          width: '100%',
          display: 'flex',
          justifyContent: 'flex-end',
        },
        '& .MuiDataGrid-virtualScroller': {
          scrollbarWidth: 'thin',
        },
        ...props.sx,
      }}
    />
  );
});

const LoadingContainer = (props: mui.core.BoxProps) => {
  return (
    <mui.core.Box
      component="section"
      {...props}
      sx={{
        width: '100% !important',
        height: '100%',
        minHeight: MIN_TABLE_HEIGHT,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        ...props.sx,
      }}
    />
  );
};

const NON_VALUE_FILTER_OPERATORS = ['isEmpty', 'isNotEmpty'];

const AsyncDataGrid = () => {
  const asyncDataGridContext = React.useContext(AsyncDataGridContext);
  const {
    customHeight,
    overrideHeight,
    rowHeight,
    headerHeight,
    alert,
    formattedRows,
    firstPrefLoad,
    mappedColumns,
    handleColumnsResize,
    loading,
    loadingPreferences,
    sortColumns,
    setSortColumns,
    filters,
    setFilters,
    pinnedColumns,
    handleScrollY,
    rowClass,
    onRowClick,
    loadOnX,
    customStyles,
    disableDefaultStyling,
    fixedWidth,
    tableWidth,
    setTableWidth,
    hideFilters,
    hideDensity,
    hideDataCount,
    hideColumnWidth,
    hideToolbar,
    hideDownload,
    customToolbar,
    handleDownload,
    downloading,
    responseColumns,
    handleScrollX,
    dataCount,
    handleCopyToClipboard,
  } = asyncDataGridContext;

  const containerRef = React.useRef(null);
  const [containerHeight, setContainerHeight] = React.useState<number>(undefined);

  const { width, height } = hooks.useWindowDimensions(0);

  const debouncedSetTableWidth = React.useCallback(
    _.debounce(() => {
      const newWidth = containerRef.current.offsetWidth;
      setTableWidth(newWidth);
    }, 300),
    [setTableWidth]
  );

  React.useEffect(() => {
    if (containerRef.current) {
      const newHeight = containerRef.current.offsetHeight;
      const newWidth = containerRef.current.offsetWidth;

      if (containerHeight === undefined || containerHeight !== newHeight) {
        setContainerHeight(newHeight);
      }

      if (tableWidth === undefined || tableWidth !== newWidth) {
        debouncedSetTableWidth();
      }
    }
  }, [width, height, containerHeight]);

  const dataGridHeight = customHeight ? customHeight : containerHeight ? containerHeight : 300;
  const tableHeight = dataGridHeight - 10;

  const filterPanel = React.useCallback(
    () => (
      <mui.core.Box
        sx={{
          '.MuiDataGrid-panelContent': {
            paddingTop: '4px',
          },
          '.MuiFormControl-root': {
            marginRight: '0.5rem',
          },
        }}
      >
        <mui.dataGrid.GridFilterPanel />
      </mui.core.Box>
    ),
    []
  );

  const [localFilters, setLocalFilters] = React.useState(filters);
  const prevLocalFilters = hooks.usePrevious(localFilters);

  hooks.useEffectWithoutFirst(() => {
    setFilters({
      ...localFilters,
      items: localFilters.items.filter(
        (item) => NON_VALUE_FILTER_OPERATORS.includes(item.operator) || !_.isEmpty(item.value)
      ),
    });
  }, [localFilters]);

  const onFilterModelChange = (newFilterModel: mui.dataGrid.GridFilterModel) => {
    const newItems = newFilterModel.items.map((f) => ({
      ...f,
      column_type: (mappedColumns || []).find((mc) => mc.key == f.field)?.type || 'string',
    }));
    const newFilters = { ...newFilterModel, items: newItems };

    _.zip(prevLocalFilters.items, newFilters.items).forEach(([pitem, nitem], idx) => {
      if (
        nitem &&
        pitem &&
        pitem?.column_type !== nitem?.column_type &&
        pitem?.value === nitem?.value &&
        !NON_VALUE_FILTER_OPERATORS.includes(nitem.operator)
      )
        newFilters.items[idx].value = '';
    });

    setLocalFilters(newFilters);
  };

  const customToolBar = React.useCallback(
    () => (
      <mui.dataGrid.GridToolbarContainer>
        <Toolbar
          alert={alert}
          hideFilters={hideFilters}
          hideDensity={hideDensity}
          hideDataCount={hideDataCount}
          handleCopyToClipboard={handleCopyToClipboard}
          hideColumnWidth={hideColumnWidth}
          hideToolbar={hideToolbar}
          hideDownload={hideDownload}
          customToolbar={customToolbar}
          handleDownload={handleDownload}
          downloading={downloading}
          responseColumns={responseColumns}
          handleScrollX={handleScrollX}
          loadOnX={loadOnX}
          dataCount={dataCount}
          loading={loading}
        />
      </mui.dataGrid.GridToolbarContainer>
    ),
    [dataCount, responseColumns, loading, downloading]
  );

  if ((_.isEmpty(formattedRows) && _.isEmpty(alert)) || firstPrefLoad)
    return (
      <PreviewContainer ref={containerRef}>
        <LoadingContainer>
          <ui.CenteredLoader label="Loading table..." />
        </LoadingContainer>
      </PreviewContainer>
    );

  return (
    <PreviewContainer>
      <mui.core.Box ref={containerRef} sx={{ height: 'calc(100%)' }}>
        <ui.FinDataGrid
          loading={loading || loadingPreferences}
          rows={formattedRows || []}
          columns={(mappedColumns || []) as mui.dataGrid.GridColDef[]}
          pinnedColumns={pinnedColumns}
          rowHeight={rowHeight}
          columnHeaderHeight={headerHeight}
          getRowClassName={(params) => (rowClass ? rowClass(params.row) : undefined)}
          filterMode="server"
          filterModel={localFilters}
          onFilterModelChange={onFilterModelChange}
          onRowsScrollEnd={loadOnX ? undefined : (params) => handleScrollY(params.visibleRowsCount)}
          sortingMode="server"
          sortModel={sortColumns}
          onSortModelChange={(newSortColumns) => setSortColumns(newSortColumns)}
          disableColumnResize={fixedWidth?.fill}
          onColumnWidthChange={(params) => handleColumnsResize(params.colDef.field, params.width)}
          onRowClick={onRowClick && onRowClick}
          hideFooter
          slots={{
            toolbar: customToolBar,
            columnsManagement: () => <></>,
            filterPanel: filterPanel,
          }}
          sx={{
            height: overrideHeight ? (overrideHeight == -1 ? 'auto' : overrideHeight) : tableHeight,
            ...customStyles,
          }}
          className={disableDefaultStyling ? '' : 'data-grid-default'}
          initialState={{ density: 'standard' }}
          showCellVerticalBorder
          showColumnVerticalBorder
          hideFooterPagination
          hideFooterRowCount
          defaultBackgroundColor
          disableColumnReorder
          disableRowSelectionOnClick
          disableColumnMenu
          cellSelection
          ignoreValueFormatterDuringExport
        />
      </mui.core.Box>
      {(loading || loadingPreferences) && (
        <mui.core.Box
          sx={{
            inlineSize: '180px',
            paddingBlock: '8px',
            paddingInline: '0.9rem',
            position: 'absolute',
            insetBlockEnd: '8px',
            insetInlineEnd: '8px',
            color: 'white',
            lineHeight: '35px',
            background: 'rgb(0 0 0 / 0.6)',
            marginBottom: '50px',
          }}
        >
          Loading ...
        </mui.core.Box>
      )}
    </PreviewContainer>
  );
};

export default AsyncDataGrid;
