import React from 'react';
import * as hooks from '@local/finsera-core/src/hooks';
import { mui } from '@local/finsera-core/src/libs';

import { DialogTitle } from '..';

type CopyToClipboardProps = {
  handleCopyToClipboard: () => void;
  disabled: boolean;
};

const CopyToClipboard: React.FC<CopyToClipboardProps> = ({ handleCopyToClipboard, disabled }): React.ReactElement => {
  const uiStyles = hooks.useUiStyles();
  const theme = mui.styles.useTheme() as mui.core.Theme;

  const [isOpen, setIsOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [error] = React.useState<string>(null);

  const loadToClipboard = async () => {
    setLoading(true);
    await handleCopyToClipboard();
    setLoading(false);
  };

  React.useEffect(() => {
    if (isOpen) loadToClipboard();
  }, [isOpen]);

  const responseRender = () => (
    <mui.core.Box sx={uiStyles.uploadBox} textAlign="center">
      <mui.core.Box mb={3}>
        {error ? (
          <mui.icons.Error style={{ color: theme.palette.danger.main, fontSize: '40px' }} />
        ) : (
          <mui.icons.CheckCircle style={{ color: theme.palette.success.main, fontSize: '40px' }} />
        )}
        <mui.core.Typography sx={{ marginTop: '15px' }} variant="body1" fontWeight="fontWeightBold">
          {error ? (
            <>
              <mui.core.Box>{error}</mui.core.Box>
            </>
          ) : (
            'Data has been exported to your clipboard.'
          )}
        </mui.core.Typography>
      </mui.core.Box>
    </mui.core.Box>
  );

  const loadingRender = () => (
    <mui.core.Box sx={uiStyles.uploadBox} textAlign="center">
      <mui.core.Box mb={3}>
        <mui.core.CircularProgress size={30} sx={{ color: theme.palette.primary.main }} />

        <mui.core.Typography sx={{ marginTop: '15px' }} variant="body1" fontWeight="fontWeightBold">
          Exporting data.
        </mui.core.Typography>
      </mui.core.Box>
    </mui.core.Box>
  );

  return (
    <>
      <mui.core.MenuItem disabled={disabled} onClick={() => setIsOpen(true)}>
        Save to Clipboard
      </mui.core.MenuItem>

      <mui.core.Dialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle closeAction={() => setIsOpen(false)}>Save to Clipboard</DialogTitle>

        <mui.core.DialogContent>
          <mui.core.Box pt={3}>{loading ? loadingRender() : responseRender()}</mui.core.Box>
        </mui.core.DialogContent>
      </mui.core.Dialog>
    </>
  );
};

export default CopyToClipboard;
