import { _, ts } from '_core';

import { getBucketName } from 'views/report/widgets/common/utils/get-series-name';

import { getColumns } from './data-helpers';
import { ParamsArgs } from '../types';

export const _getSheetType = (sheet: string) => {
  if (sheet.includes('_AND_')) return 'both';
  if (sheet.includes('variables')) return 'variables';
  return 'buckets';
};

/**
 * Formats each string in the input JSON array according to the supplied bucket.
 *
 * Example:
 * const inputArray = ['1', '2', '3'];
 * const paramBuckets = { type: 'QUANTILES', value: '3' } as BucketValue;
 * _getBuckets(JSON.stringify(inputArray), paramBuckets) === ['Q1 (lowest)', 'Q2', 'Q3 (highest)'];
 *
 * @param a a JSON string that can be parsed as an array of strings
 * @param paramBuckets a bucket definition
 */
export const _getBuckets = (a: string, paramBuckets?: ts.types.widgets.common.BucketValue) => {
  const vs = JSON.parse(a) as string[];
  if (!paramBuckets) return vs;
  return vs.map((k, _idx) => {
    return getBucketName(k, paramBuckets.type, paramBuckets.value, {} as any);
  });
};

/**
 * Turns each record in `data` to a single row of a flattened table.
 *
 * Example:
 * const inputData = {
 *   key1: [
 *     { v: '["1"]', value: 10, col1: 'col1' },
 *     { v: '["2"]', value: 20, col1: 'col1' },
 *   ],
 *   key2: [
 *     { v: '["1"]', value: 30, col1: 'col1' },
 *     { v: '["2"]', value: 40, col1: 'col1' },
 *   ],
 * };
 * const paramBuckets = { type: 'QUANTILES', value: '2' } as BucketValue;
 * const groupAttr = 'group';
 * const extraCols = ['col1'];
 *
 * _flattenGroup(inputData, paramBuckets, groupAttr, extraCols) === [
 *   { group: 'key1', 'Q1 (lowest)': 10, 'Q2 (highest)': 20, col1: 'col1' },
 *   { group: 'key2', 'Q1 (lowest)': 30, 'Q2 (highest)': 40, col1: 'col1' },
 * ];
 *
 * @param data a dictionary of table, where each table will be flattened into a single row of the result.
 * @param paramBuckets used to format the 'v' and 'value' columns in the data. The formatted 'v' become the columns
 * in the result with the 'value' in the rows.
 * @param groupAttr the column that will contain the keys of thr `data`
 * @param extraCols these column values will be transferred from the first row to the final table.
 */
export const _flattenGroup = (
  data: Record<string, ts.types.widgets.TableData>,
  paramBuckets: ts.types.widgets.common.BucketValue,
  groupAttr: string,
  extraCols: string[] = []
): ts.types.widgets.TableData => {
  return Object.entries(data).map(([key, data]) => {
    const row = {
      [groupAttr]: key,
      ...Object.fromEntries(data.map((r) => [_getBuckets(r.v as string, paramBuckets).join(' - '), r.value])),
    };

    extraCols.forEach((c) => (row[c] = data[0][c]));
    return row;
  });
};

/**
 * "Unstack" a table where the `v` column contains JSON arrays of two elements so that values in the second element
 * become columns of the result table. Both elements are formatted according to the 'paramBuckets'.
 *
 * Example:
 * const inputData = [
 *   { v: '["1","fin1"]', value: 1 },
 *   { v: '["1","fin2"]', value: 2 },
 *   { v: '["1","fin3"]', value: 3 },
 *   { v: '["2","fin1"]', value: 11 },
 *   { v: '["2","fin2"]', value: 22 },
 *   { v: '["2","fin3"]', value: 33 },
 *   { v: '["3","fin1"]', value: 111 },
 *   { v: '["3","fin2"]', value: 222 },
 *   { v: '["3","fin3"]', value: 333 },
 * ];
 *
 * const paramBuckets = { type: 'QUANTILES', value: '3' } as BucketValue;
 *
 * _createMatrix(inputData, paramBuckets) === [
 *   { matrix_variable: 'Q1 (lowest)', fin1: 1, fin2: 2, fin3: 3 },
 *   { matrix_variable: 'Q2', fin1: 11, fin2: 22, fin3: 33 },
 *   { matrix_variable: 'Q3 (highest)', fin1: 111, fin2: 222, fin3: 333 },
 * ];
 *
 * @param data table of 'v' and 'value' columns, where 'v' is a JSON string array of two elements, each of which are
 * bucket that can be formatted according to the param buckets
 * @param paramBuckets used to format the elements of the 'v' arrays.
 */
export const _createMatrix = (data: ts.types.widgets.TableData, paramBuckets: ts.types.widgets.common.BucketValue) => {
  const matrixData = data.map((row) => {
    const buckets = _getBuckets(row['v'] as string, paramBuckets);
    return {
      b1: buckets[0],
      b2: buckets[1],
      value: row['value'],
    };
  });

  return Object.entries(_.groupBy(matrixData, 'b1')).map(([bucket, data]) => {
    return {
      matrix_variable: bucket,
      ..._.reduce(
        data,
        (prev, r) => {
          return { ...prev, [r.b2]: r.value };
        },
        {}
      ),
    };
  });
};

export const _sortYearlyData = (data: ts.types.widgets.TableData): ts.types.widgets.TableData => {
  const numericYears = _.sortBy(
    data.filter((item) => /^\d+$/.test(item.year as string)),
    ['year']
  );
  // Get periods
  let enumKeys = data.filter((item) =>
    Object.values(ts.enums.HISTORY_ENUM_EXTENDED).find((h) => (item.year as string).includes(h))
  );

  // Sort periods
  enumKeys = _.sortBy(enumKeys, (item) =>
    Object.values(ts.enums.HISTORY_ENUM_EXTENDED).findIndex((h) => (item.year as string).includes(h))
  );

  return [...numericYears, ...enumKeys].filter(Boolean);
};

export const loadData = async (
  apiCall: (_p: ts.types.widgets.WidgetGetDataParams['data']) => Promise<ts.types.widgets.WidgetGetDataResponse>,
  sheet: string,
  dates: { start: string; end: string },
  paramBuckets: ts.types.widgets.common.BucketValue,
  detailsFile: string,
  summaryFile: string,
  callTypeArgs: ParamsArgs = {} as ParamsArgs
) => {
  const isSummary = sheet.includes('summary');
  const sheetType = _getSheetType(sheet);
  const buckets = callTypeArgs.comb.filter((x) => x != 'variables')[0];

  // Get the comb filter by sheet type
  let comb = callTypeArgs.comb;
  if (sheetType == 'variables') comb = ['variables'];
  if (sheetType == 'buckets') comb = [buckets];

  // Get the group by sheet type
  let groupBy = null as string;
  let extraColsGroupBy = [] as string[];

  if (sheetType != 'both' && isSummary) {
    groupBy = 'year';
    extraColsGroupBy = ['start_date', 'end_date'];
  }

  if (!isSummary) {
    extraColsGroupBy = ['total', 'start_date', 'end_date'];
    groupBy = 'date';
  }

  const detailsQuery = [
    '$and',
    ['comb', 'json=', comb],
    ['without_residuals', callTypeArgs.without_residuals ? 'isTrue' : 'isFalse'],
  ];
  if (sheetType == 'variables') detailsQuery.push(['view_type', 'equals', callTypeArgs.view_type]);

  const summaryQuery = [
    '$and',
    ['comb', 'json=', comb],
    ['without_residuals', callTypeArgs.without_residuals ? 'isTrue' : 'isFalse'],
    ['statistics_type', 'equals', callTypeArgs.statistics_type],
  ];

  if (sheetType == 'both') summaryQuery.push(['history', 'equals', callTypeArgs.history]);

  if (callTypeArgs.risk_metric) {
    detailsQuery.push(['risk_metric', 'equals', callTypeArgs.risk_metric]);
    summaryQuery.push(['risk_metric', 'equals', callTypeArgs.risk_metric]);
  } else {
    detailsQuery.push(['risk_metric', 'isEmpty']);
    summaryQuery.push(['risk_metric', 'isEmpty']);
  }

  const columns = ['end_date', 'start_date', 'total', 'v', 'value'];

  if (!isSummary) columns.push('date');
  else columns.push('year');

  const [responseDetailsData, responseSummaryData] = await Promise.all([
    apiCall({
      file: detailsFile,
      query: detailsQuery,
      columns,
      rename: { build_date: 'date' },
    }),
    apiCall({
      file: summaryFile,
      query: summaryQuery,
      columns,
      rename: { build_date: 'date' },
    }),
  ]);

  // Process each response separately
  const processResponse = (responseData: ts.types.widgets.TableData) => {
    if (groupBy) {
      const filteredData = _.groupBy(responseData, groupBy) as unknown as Record<string, ts.types.widgets.TableData>;
      let response = _flattenGroup(
        filteredData,
        sheetType == 'variables' ? null : paramBuckets,
        groupBy,
        extraColsGroupBy
      );

      if (groupBy == 'year') response = _sortYearlyData(response);
      return response;
    }

    return _createMatrix(responseData as ts.types.widgets.TableData, paramBuckets);
  };

  const detailsProcessed = processResponse(responseDetailsData.data);
  const summaryProcessed = processResponse(responseSummaryData.data);

  return {
    details: {
      data: detailsProcessed,
      columns: getColumns(detailsProcessed, sheet, dates.start, dates.end, callTypeArgs.statistics_type),
    },
    summary: {
      data: summaryProcessed,
      columns: getColumns(summaryProcessed, sheet, dates.start, dates.end, callTypeArgs.statistics_type),
    },
  };
};
