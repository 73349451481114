import React from 'react';
import { FileCopyOutlined } from '@mui/icons-material';
import { Alert, IconButton, Snackbar } from '@mui/material';

import { useUiStyles } from '../hooks';
import { mui } from '../libs';

/**
 * Component that renders a code block with copy-to-clipboard functionality
 *
 * @param content - The code content to display
 * @param lines - Optional JSX elements to render instead of raw content
 * @param small - Optional flag to enable compact styling
 *
 * @returns Code block component with copy button and snackbar notifications
 */

const CodeBlock = ({ content, lines, small }: { content: string; lines?: JSX.Element[]; small?: boolean }) => {
  const uiStyles = useUiStyles();
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);

  const handleSnackbarClose = (_event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const copyCodeToClipboard = () => {
    navigator.clipboard.writeText(content);
    setSnackbarOpen(true);
  };

  return (
    <mui.core.Box sx={uiStyles.codeBlock}>
      <IconButton sx={uiStyles.codeBlockCopy} onClick={copyCodeToClipboard} size="small">
        <FileCopyOutlined sx={{ fontSize: '14px' }} />
      </IconButton>

      <mui.core.Typography
        component="pre"
        sx={uiStyles.codeBlockContent}
        style={small ? { padding: '0.5rem 1rem 0.5rem 0.5rem', whiteSpace: 'normal', minHeight: '32px' } : {}}
      >
        {lines ? lines : content}
      </mui.core.Typography>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert severity="success" variant="filled">
          Copied to clipboard!
        </Alert>
      </Snackbar>
    </mui.core.Box>
  );
};

export default CodeBlock;
