import { helpers, mui, ts } from '_core';

const decimalLabels = ['Factor Exposures'];

const conditionalFormatting = (field: string) => {
  if (decimalLabels.includes(field)) {
    return helpers.tableFormatters.formatTo('float', {
      roundDigits: 2,
      commasOnThousands: true,
    });
  }

  return helpers.tableFormatters.formatTo('float', {
    roundDigits: 2,
    commasOnThousands: true,
    suffix: '%',
    multiplier: 100,
  });
};

const customValueGetter: mui.dataGrid.GridColDef['valueGetter'] = (value, row) => {
  return conditionalFormatting(row?.label);
};

export const summaryColumns = [
  {
    key: 'label',
    name: '',
    formatter: helpers.tableFormatters.formatTo('string', { align: 'left' }),
    filter: 'string',
  },
  {
    key: 'metric',
    name: 'Metric',
    formatter: helpers.tableFormatters.formatTo('string', { align: 'left' }),
    filter: 'string',
  },
  {
    key: 'initial',
    name: '',
    formatter: helpers.tableFormatters.formatTo('string', { align: 'right' }),
    filter: 'number',
    valueGetter: customValueGetter,
    renderCell: customValueGetter,
  },
] as ts.types.components.dataGrid.ColumnsData;

export const summaryColumnsWithBenchmark = [
  {
    key: 'label',
    name: '',
    formatter: helpers.tableFormatters.formatTo('string', { align: 'left' }),
    filter: 'string',
  },
  {
    key: 'metric',
    name: 'Metric',
    formatter: helpers.tableFormatters.formatTo('string', { align: 'left' }),
    filter: 'string',
  },
  {
    key: 'initial',
    name: 'Total',
    formatter: helpers.tableFormatters.formatTo('string', { align: 'right' }),
    filter: 'number',
    valueGetter: customValueGetter,
    renderCell: customValueGetter,
  },
  {
    key: 'benchmark',
    name: 'Benchmark',
    formatter: helpers.tableFormatters.formatTo('string', { align: 'right' }),
    filter: 'number',
    valueGetter: customValueGetter,
    renderCell: customValueGetter,
  },
  {
    key: 'active',
    name: 'Active',
    formatter: helpers.tableFormatters.formatTo('string', { align: 'right' }),
    filter: 'number',
    valueGetter: customValueGetter,
    renderCell: customValueGetter,
  },
] as ts.types.components.dataGrid.ColumnsData;
