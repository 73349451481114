import { helpers, ts } from '_core';

export default {
  [ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.MAIN]: [
    {
      key: 'entity_name',
      name: 'Entity Name',
      formatter: helpers.tableFormatters.formatTo('string', { align: 'left' }),
      filter: 'string',
    },
    {
      key: 'name',
      name: 'Security Name',
      formatter: helpers.tableFormatters.formatTo('string', { align: 'left' }),
      filter: 'string',
    },
    {
      key: 'ticker',
      name: 'Ticker',
      formatter: helpers.tableFormatters.formatTo('string', { align: 'left' }),
      filter: 'string',
    },
    {
      key: 'quotation_country',
      name: 'Quotation Country',
      formatter: helpers.tableFormatters.formatTo('string', { align: 'center' }),
      filter: 'string',
    },
    {
      key: 'eff_start',
      name: 'Period Start',
      filter: 'date',
      formatter: helpers.tableFormatters.formatTo('date', { align: 'right', format: 'YYYY-MM-DD' }),
    },
    {
      key: 'eff_end',
      name: 'Period End',
      filter: 'date',
      formatter: helpers.tableFormatters.formatTo('date', { align: 'right', format: 'YYYY-MM-DD' }),
    },
  ] as ts.types.components.dataGrid.ColumnsData,
};
