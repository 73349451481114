import { helpers, hooks, mui, React, ts } from '_core';

import Tabular from 'views/report/tables/tabular';

type LocateConstraintProps = {
  params: ts.types.optimizer.Locate[];
};

const LocateConstraint: React.FC<LocateConstraintProps> = ({ params }): React.ReactElement => {
  const [containerWidth, setContainerWidth] = React.useState(0);
  const locateArr = React.useMemo(() => {
    return params
      .map((v) => {
        return {
          ...v,
          id: v.asset_fids?.[0],
          fid: v.asset_fids?.[0],
          asset_fids: undefined as any,
        };
      })
      .filter((v) => v.status != 'UNLOCATED');
  }, []);

  const columns = [
    {
      name: 'Asset',
      key: 'fid',
    },
    {
      name: 'Name',
      key: 'name',
    },
    {
      name: 'Ticker',
      key: 'ticker',
      formatter: helpers.tableFormatters.formatTo('string', { align: 'left' }),
    },
    {
      name: 'Requested Shares',
      key: 'requested_shares',
      formatter: helpers.tableFormatters.formatTo('integer', { align: 'right' }),
    },
    {
      name: 'Approved Shares',
      key: 'approved_shares',
      formatter: helpers.tableFormatters.formatTo('integer', { align: 'right' }),
    },
    {
      name: 'Status',
      key: 'status',
    },
    {
      name: 'Note',
      key: 'note',
    },
  ];

  const container = React.createRef<HTMLDivElement>();

  const { height, width } = hooks.useWindowDimensions(0);
  React.useEffect(() => {
    if (container.current) {
      setContainerWidth(container.current.offsetWidth);
    }
  }, [width, height]);

  return (
    <mui.core.Box ref={container}>
      <mui.core.Box sx={{ width: containerWidth, height: '400px' }}>
        <Tabular<'locates'>
          data={{
            locates: locateArr,
          }}
          getColumns={{ locates: () => columns }}
          sheetTitles={{ locates: 'Locates' }}
          fullScreen
          hideFilters
          hideDensity
          tableParams={{
            locates: {
              cols_width: [
                { columnKey: 'fid', columnWidth: 100 },
                { columnKey: 'ticker', columnWidth: 100 },
                { columnKey: 'status', columnWidth: 100 },
              ],
            } as any,
          }}
          triggerWidthChange
        />
      </mui.core.Box>
    </mui.core.Box>
  );
};

export default LocateConstraint;
