import { _, hooks, mui, React, ts, ui } from '_core';

import { DraftParams } from './types';

type FormProps = {
  params: DraftParams;
  setParams: (_p: DraftParams) => void;
};

const Form: React.FC<FormProps> = ({ params, setParams }): React.ReactElement => {
  const BUCKET_TYPES_ENUM = ts.enums.BUCKET_TYPES_ENUM;

  const [scale, setScale] = React.useState(params.scale || ts.enums.SCALE_ENUM.LINEAR);
  const [buckets, setBuckets] = React.useState(
    params.buckets || ({ type: 'LONG_SHORT' } as ts.types.widgets.common.BucketValue)
  );
  const [joint, setJoint] = React.useState(_.isNil(params.joint) ? true : params.joint);

  const uiStyles = hooks.useUiStyles();

  React.useEffect(() => {
    const data = { ...params, buckets, scale, joint };
    setParams(data);
  }, [buckets, scale, joint]);

  const bucketOptions = [
    {
      value: BUCKET_TYPES_ENUM.LONG_SHORT,
      title: 'Long Short Classifier',
    },
    {
      value: BUCKET_TYPES_ENUM.QUANTILES,
      title: !params.isBasket ? 'Alpha Quantiles' : 'Ranking Signal Quantiles',
    },
    {
      value: BUCKET_TYPES_ENUM.INDUSTRY,
      title: 'Industry',
    },
    {
      value: BUCKET_TYPES_ENUM.RISK_MODEL,
      title: 'Risk Model',
    },
    {
      value: BUCKET_TYPES_ENUM.ASSET_MASTER,
      title: 'Asset Master',
    },
  ];

  return (
    <mui.core.Box sx={uiStyles.widgetEditMode}>
      <mui.core.Box mt={2}>
        <mui.core.TextField
          select
          label="Type"
          variant="outlined"
          size="small"
          value={scale}
          onChange={(e) => setScale(e.target.value as ts.enums.SCALE_ENUM)}
          fullWidth
        >
          <mui.core.MenuItem value={ts.enums.SCALE_ENUM.LINEAR}>Linear scale</mui.core.MenuItem>
          {/* <mui.core.MenuItem value="log">Log scale</mui.core.MenuItem> */}
        </mui.core.TextField>
      </mui.core.Box>
      <mui.core.Box mt={2}>
        <mui.core.FormControlLabel
          control={<mui.core.Switch size="small" checked={joint} onChange={() => setJoint(!joint)} />}
          label="Compound jointly"
        />
      </mui.core.Box>
      <mui.core.Box mt={4}>
        <ui.Buckets value={buckets} setValue={setBuckets} options={bucketOptions} />
      </mui.core.Box>
    </mui.core.Box>
  );
};

export default Form;
