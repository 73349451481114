import React from 'react';

import { mui } from '../libs';
import * as types from '../types';

type InputWrapperProps = {
  alert: types.common.Alert;
  children: React.ReactNode;
};

/**
 * InputWrapper component that displays an alert message above the input field.
 * 
 * @param alert - The alert object containing severity and message
 * @param children - The child components to be rendered within the wrapper
 * 
 * @returns A React component that displays an alert message above the input field.
 */

const InputWrapper: React.FC<InputWrapperProps> = ({ alert, children }): React.ReactElement => {
  const theme = mui.styles.useTheme() as mui.core.Theme;

  const getColor = (severity: 'error' | 'warning' | 'info' | 'success') => {
    if (severity == 'warning') return (theme.palette as any).warning.dark;
    if (severity == 'info') return (theme.palette as any).primary.main;
    return 'error';
  };

  return (
    <>
      {children}
      {alert && (
        <mui.core.Box display="flex" mt={1} alignItems="center">
          {alert.severity == 'error' && (
            <mui.icons.ErrorOutline style={{ fontSize: 14, marginRight: 4 }} color="error" />
          )}
          {alert.severity == 'warning' && (
            <mui.icons.ErrorOutline
              style={{
                fontSize: 14,
                marginRight: 4,
                color: getColor(alert.severity),
              }}
            />
          )}
          {alert.severity == 'info' && (
            <mui.icons.InfoOutlined style={{ fontSize: 14, marginRight: 4 }} color="primary" />
          )}
          <mui.core.Typography style={{ color: getColor(alert.severity) }} variant="caption">
            {alert.message}
          </mui.core.Typography>
        </mui.core.Box>
      )}
    </>
  );
};

export default InputWrapper;
