import { config, helpers, mui, React, ts, ui, useSelector } from '_core';

import * as utils from './strategy-utils';

type StrategyFormProps = {
  open: boolean;
  setOpen: (_open: boolean) => void;
};

const StrategyForm: React.FC<StrategyFormProps> = ({ open, setOpen }) => {
  const strategies = useSelector((state) => state.resources.strategies as ts.types.strategy.Strategy[]);

  const [errorMessage, setErrorMessage] = React.useState('');

  const [name, setName] = React.useState('');
  const [handle, setHandle] = React.useState('');
  const [template, setTemplate] = React.useState<ts.types.strategy.Strategy>();
  const [type, setType] = React.useState<ts.types.strategy.Strategy['type']>('PORTFOLIO_ANALYSIS');
  const [optimizerType, setOptimizerType] =
    React.useState<ts.types.optimizer.OptimizerConfig['optimizer_type']>('CVXPY');

  const [loadingCreate, setLoadingCreate] = React.useState(false);

  // Create handle while typing name
  React.useEffect(() => {
    setHandle(helpers.createHandle(name, 'st'));
  }, [name]);

  const handleSubmit = async () => {
    setErrorMessage('');

    // Validation
    if (!name || !handle) {
      setErrorMessage('All attributes are required.');
      setLoadingCreate(false);
      return;
    }

    setLoadingCreate(true);

    const newStrategy = utils.definition.getNewStrategy(name, handle, type, optimizerType, template);

    utils.apiProcedures.createStrategy(
      newStrategy,
      async () => {
        setOpen(false);
      },
      async (err) => {
        setErrorMessage(err);
        setLoadingCreate(false);
      }
    );
  };

  const formContent = () => (
    <mui.core.Box mt={3}>
      <mui.core.Box mb={4}>
        <mui.core.Grid container spacing={3}>
          <mui.core.Grid item xs={12} sm={6}>
            <mui.core.TextField
              autoFocus
              id="name"
              label={'Strategy name'}
              value={name}
              onChange={(e) => setName(e.target.value)}
              fullWidth
              variant="outlined"
              required
              size="small"
              inputProps={{
                autocomplete: 'off',
              }}
            />
          </mui.core.Grid>

          <mui.core.Grid item xs={12} sm={6}>
            <mui.core.TextField
              id="handle"
              label={'Strategy handle'}
              value={handle}
              onChange={(e) => setHandle(e.target.value)}
              fullWidth
              variant="outlined"
              required
              size="small"
            />
          </mui.core.Grid>
        </mui.core.Grid>
      </mui.core.Box>

      <mui.core.Box mb={4}>
        <mui.core.TextField
          id="stat_type"
          select
          label="Strategy based on"
          variant="outlined"
          value={type}
          onChange={(e) => {
            setType(e.target.value as ts.types.strategy.Strategy['type']);
            setTemplate(null);
          }}
          fullWidth
          required
          size="small"
        >
          <mui.core.MenuItem value="PORTFOLIO_ANALYSIS">Portfolio Analysis</mui.core.MenuItem>

          {config.features.alpha_model && <mui.core.MenuItem value="ALPHA_MODEL">Alpha Model</mui.core.MenuItem>}

          {config.features.basket && <mui.core.MenuItem value="BASKET">Basket</mui.core.MenuItem>}
        </mui.core.TextField>
      </mui.core.Box>

      {type !== 'PORTFOLIO_ANALYSIS' && (
        <mui.core.Box mb={4}>
          <mui.core.TextField
            id="optimizer"
            select
            label="Select an Optimizer"
            variant="outlined"
            value={optimizerType}
            onChange={(e) => setOptimizerType(e.target.value as 'CVXPY' | 'BARRA')}
            fullWidth
            required
            size="small"
          >
            <mui.core.MenuItem value={'CVXPY'}>Finsera Optimizer</mui.core.MenuItem>
            {config.features.has_barra_optimizer && (
              <mui.core.MenuItem value={'BARRA'}>Barra Optimizer</mui.core.MenuItem>
            )}
          </mui.core.TextField>
        </mui.core.Box>
      )}

      <mui.core.Box mb={4}>
        <ui.ResourceAutocomplete<ts.types.strategy.StrategyExpanded>
          enableNone
          value={template?.id}
          setValue={(id) => setTemplate(strategies.find((s) => s.id == id))}
          type={ts.enums.RESOURCES_TYPES_ENUM.STRATEGY}
          filter={(b) => {
            return b.type == type;
          }}
          inputProps={{
            fullWidth: true,
            label: 'Template',
            variant: 'outlined',
            required: true,
            size: 'small',
          }}
        />
      </mui.core.Box>
    </mui.core.Box>
  );

  return (
    <mui.core.Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="sm"
      fullWidth
    >
      {errorMessage && (
        <mui.lab.Alert onClose={() => setErrorMessage('')} severity="error">
          {errorMessage}
        </mui.lab.Alert>
      )}
      <ui.DialogTitle closeAction={() => setOpen(false)}>Create Strategy</ui.DialogTitle>

      <mui.core.DialogContent>{formContent()}</mui.core.DialogContent>

      <mui.core.DialogActions>
        <ui.Button
          variant="contained"
          color="primary"
          loading={loadingCreate}
          onClick={handleSubmit}
          style={{ margin: '0rem 1rem 1rem' }}
          fullWidth
        >
          {'Create Strategy'}
        </ui.Button>
      </mui.core.DialogActions>
    </mui.core.Dialog>
  );
};

export default StrategyForm;
