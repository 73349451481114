import { GeneralAnalysis } from '../../../types/analysis';
import { GeneralAnalysisExpanded } from '../../../types/analysis';

const expandGeneralAnalysis = (
  generalAnalysis: GeneralAnalysis & { resourceType: string }
): GeneralAnalysisExpanded => {
  return {
    id: generalAnalysis.id,
    name: generalAnalysis.name,
    handle: undefined,
    label_ids: undefined,
    created_by_id: generalAnalysis.created_by_id,
    resourceType: generalAnalysis.resourceType,
    revoked: false,
    is_published: false,
    is_deprecated: false,
    is_valid: true,
    created_at: generalAnalysis.created_at,
    organization_id: generalAnalysis.organization_id,
  } as GeneralAnalysisExpanded;
};

export default expandGeneralAnalysis;
