import { ts } from '_core';

import { DraftParams } from './types';

export default [
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.SIGNAL_NAME_COUNT,
    title: 'Name Count',
    helpLabel: 'e0ax100e',
    form: true,
    defaultParams: {
      drop_values_under: 1e-10,
    } as DraftParams,
    neededFiles: ['signal_name_count'],
    tags: [
      ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.SIGNAL,
      ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.ALPHA_MODEL,
      ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.COMMON_ALPHA_MODEL,
    ],
  },
] satisfies ts.types.analysis.WidgetConfig[];
