import { _, ts } from '_core';

// prepareNameCountData
export const transform = (
  data: ts.types.widgets.LinearData,
  isBasket: boolean,
  isEtf: boolean,
  hasBenchmark: boolean,
  isLongOnly: boolean
) => {
  if (_.isEmpty(data)) {
    return {};
  }

  // Update universe count name
  if (data['universe_count']) {
    data['Universe Count'] = data['universe_count'];
  }

  if (data['universe_count']) data['Universe Count'] = data['universe_count'];

  if (!isLongOnly && data['short_count']) data['Short Count'] = data['short_count'];

  if (data['long_count']) data['Long Count'] = data['long_count'];

  if (data['portfolio_count']) data['Portfolio Count'] = data['portfolio_count'];

  if (isEtf) {
    return data;
  }

  if (hasBenchmark && !isEtf) {
    if (data['benchmark_count']) data[isBasket ? 'Target Count' : 'Benchmark Count'] = data['benchmark_count'];
  }

  delete data['short_count'];
  delete data['long_count'];
  delete data['universe_count'];
  delete data['benchmark_count'];
  delete data['portfolio_count'];

  return data;
};
