import { ts } from '_core';

const helpItems: ts.types.dashboard.HelpItem[] = [
  {
    key: 0,
    label: 'Platform Overview',
    description: 'Learn the basics of the Finsera Interface',
    url: 'https://help.finsera.com/en/home',
    icon: 'overview',
  },
  {
    key: 1,
    label: 'Video Tutorials',
    description: 'Discover the platform through thoughtfully explained videos',
    to: '/help/video-docs',
    icon: 'video',
  },
  {
    key: 2,
    resourceType: ts.enums.UI_RESOURCE_TYPE_ENUM.UNIVERSE,
    label: 'Universes',
    description: 'Learn how to create your first universe and run reports on it',
    url: 'http://help.finsera.com/en/home/universes',
  },
  {
    key: 3,
    resourceType: ts.enums.UI_RESOURCE_TYPE_ENUM.SIGNAL,
    label: 'Signals',
    description: 'Learn signal definition and reports',
    url: 'http://help.finsera.com/en/home/signals',
  },
  {
    key: 4,
    resourceType: ts.enums.UI_RESOURCE_TYPE_ENUM.ALPHA_MODEL,
    label: 'Alpha Models',
    description: 'Learn alpha models definition and reports',
    url: 'http://help.finsera.com/en/home/alpha-models',
  },
  {
    key: 5,
    resourceType: ts.enums.UI_RESOURCE_TYPE_ENUM.BACKTEST,
    label: 'Backtests',
    description: 'Learn to define backtests and run different kind of reports',
    url: 'http://help.finsera.com/en/home/backtests',
  },
  {
    key: 6,
    resourceType: ts.enums.UI_RESOURCE_TYPE_ENUM.SIGNAL_SET,
    label: 'Signal Sets',
    description: 'Build sets of signals to run multiple analysis easily',
    url: 'http://help.finsera.com/en/home/signal-sets',
  },
  {
    key: 7,
    resourceType: ts.enums.UI_RESOURCE_TYPE_ENUM.BACKTEST_SET,
    label: 'Backtest sets',
    description: 'Run analysis on multiple backtests to speed up your flow',
    url: 'http://help.finsera.com/en/home/backtest-sets',
  },
  {
    key: 8,
    resourceType: ts.enums.UI_RESOURCE_TYPE_ENUM.BASKET,
    label: 'Baskets',
    description: 'Combine your investment portfolio in a Basket',
    url: 'http://help.finsera.com/en/home/baskets',
  },
  {
    key: 9,
    resourceType: ts.enums.UI_RESOURCE_TYPE_ENUM.DATA_SOURCE,
    label: 'Data Sources',
    description: 'Import or use our existing data sources for your reports',
    url: 'http://help.finsera.com/en/home/data-sources',
  },
  {
    key: 10,
    resourceType: ts.enums.UI_RESOURCE_TYPE_ENUM.RISK_MODEL,
    label: 'Risk Models',
    description: 'Learn to define your own set of risk models to use on reports',
    url: 'http://help.finsera.com/en/home/risk-models',
  },
  {
    key: 11,
    resourceType: ts.enums.UI_RESOURCE_TYPE_ENUM.PIPELINE,
    label: 'Pipelines',
    description: 'Setup pipelines to filter or transform your signals.',
    url: 'http://help.finsera.com/en/home/pipelines',
  },
];

export default [
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.GENERAL_ONBOARDING,
    form: false,
    title: 'Welcome to Finsera',

    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.GENERAL],
    helpLabel: 'm3sps400',
  },
] satisfies ts.types.analysis.WidgetConfig[];

export { helpItems };
