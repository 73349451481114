import { React, ts } from '_core';

import Chart from './chart';
import Form from './form';
import { DraftParams, Params } from './types';

type portfolioPreviewHoldingsProps = {
  id: string;
  mode: ts.enums.CHART_MODE_ENUM;
  context?: ts.types.portfolioHoldings.PortfolioHoldingsAnalysisContext;
  loadWidgetData: (_payload: ts.types.widgets.WidgetGetDataParams) => Promise<ts.types.widgets.WidgetGetDataResponse>;
  params: DraftParams;
  setParams: (_p: DraftParams) => void;
  widgetPaths?: Record<'rebalance_initial_holdings' | 'portfolio_initial_holdings', string>;
  goFullScreen?: (_b: string) => void;
  fullScreen?: boolean;

  queryParams?: { initial?: string };
};

const portfolioPreviewHoldings: React.FC<portfolioPreviewHoldingsProps> = ({
  id,
  mode,
  loadWidgetData,
  params,
  setParams,
  widgetPaths,
  fullScreen,
  goFullScreen,
  queryParams,
  context,
}) => {
  const renderJobComponent = () => (
    <Chart
      id={id}
      loadWidgetData={loadWidgetData}
      holdings={widgetPaths['rebalance_initial_holdings'] ?? widgetPaths['portfolio_initial_holdings']}
      fullScreen={fullScreen}
      queryParams={queryParams}
      goFullScreen={goFullScreen}
      context={context}
      params={params as Params}
      setParams={setParams}
    />
  );

  return <>{mode == 'edit' ? <Form params={params} setParams={setParams} /> : renderJobComponent()}</>;
};

export default portfolioPreviewHoldings;
