import { ts, uitheme } from '_core';

type MenuOptionsType = {
  name: string;
  type: ts.enums.UI_RESOURCE_TYPE_ENUM;
  sectionEnd?: boolean;
  disabled?: boolean;
  onClick: (_t: (_s: string) => void) => void;
};

export const addMenuOptions: MenuOptionsType[] = [
  {
    name: 'Workspace',
    type: ts.enums.UI_RESOURCE_TYPE_ENUM.WORKSPACE,
    sectionEnd: true,
    onClick: (triggerFunc: (_s: string) => void) => triggerFunc(ts.enums.UI_RESOURCE_TYPE_ENUM.WORKSPACE),
  },
  {
    name: 'Universe',
    type: ts.enums.UI_RESOURCE_TYPE_ENUM.UNIVERSE,
    onClick: (triggerFunc: (_s: string) => void) => triggerFunc(ts.enums.UI_RESOURCE_TYPE_ENUM.UNIVERSE),
  },
  {
    name: 'Signal',
    type: ts.enums.UI_RESOURCE_TYPE_ENUM.SIGNAL,
    onClick: (triggerFunc: (_s: string) => void) => triggerFunc(ts.enums.UI_RESOURCE_TYPE_ENUM.SIGNAL),
  },
  {
    name: 'Alpha Model',
    type: ts.enums.UI_RESOURCE_TYPE_ENUM.ALPHA_MODEL,
    onClick: (triggerFunc: (_s: string) => void) => triggerFunc(ts.enums.UI_RESOURCE_TYPE_ENUM.ALPHA_MODEL),
  },
  {
    name: 'Backtest',
    type: ts.enums.UI_RESOURCE_TYPE_ENUM.BACKTEST,
    onClick: (triggerFunc: (_s: string) => void) => triggerFunc(ts.enums.UI_RESOURCE_TYPE_ENUM.BACKTEST),
  },
  {
    name: 'Time Series',
    type: ts.enums.UI_RESOURCE_TYPE_ENUM.TIME_SERIES,
    sectionEnd: true,
    onClick: (triggerFunc: (_s: string) => void) => triggerFunc(ts.enums.UI_RESOURCE_TYPE_ENUM.TIME_SERIES),
  },
  {
    name: 'Signal Set',
    type: ts.enums.UI_RESOURCE_TYPE_ENUM.SIGNAL_SET,
    onClick: (triggerFunc: (_s: string) => void) => triggerFunc(ts.enums.UI_RESOURCE_TYPE_ENUM.SIGNAL_SET),
  },
  {
    name: 'Backtest Set',
    type: ts.enums.UI_RESOURCE_TYPE_ENUM.BACKTEST_SET,
    onClick: (triggerFunc: (_s: string) => void) => triggerFunc(ts.enums.UI_RESOURCE_TYPE_ENUM.BACKTEST_SET),
  },
  {
    name: 'Strategy',
    type: ts.enums.UI_RESOURCE_TYPE_ENUM.STRATEGY,
    onClick: (triggerFunc: (_s: string) => void) => triggerFunc(ts.enums.UI_RESOURCE_TYPE_ENUM.STRATEGY),
  },
  {
    name: 'Portfolio',
    type: ts.enums.UI_RESOURCE_TYPE_ENUM.PORTFOLIO,
    onClick: (triggerFunc: (_s: string) => void) => triggerFunc(ts.enums.UI_RESOURCE_TYPE_ENUM.PORTFOLIO),
  },
  {
    name: 'Basket',
    type: ts.enums.UI_RESOURCE_TYPE_ENUM.BASKET,
    sectionEnd: true,
    onClick: (triggerFunc: (_s: string) => void) => triggerFunc(ts.enums.UI_RESOURCE_TYPE_ENUM.BASKET),
  },
  {
    name: 'Data Source',
    type: ts.enums.UI_RESOURCE_TYPE_ENUM.DATA_SOURCE,
    onClick: (triggerFunc: (_s: string) => void) => triggerFunc(ts.enums.UI_RESOURCE_TYPE_ENUM.DATA_SOURCE),
  },
  {
    name: 'Risk Model',
    type: ts.enums.UI_RESOURCE_TYPE_ENUM.RISK_MODEL,
    onClick: (triggerFunc: (_s: string) => void) => triggerFunc(ts.enums.UI_RESOURCE_TYPE_ENUM.RISK_MODEL),
  },
  {
    name: 'Pipeline',
    type: ts.enums.UI_RESOURCE_TYPE_ENUM.PIPELINE,
    onClick: (triggerFunc: (_s: string) => void) => triggerFunc(ts.enums.UI_RESOURCE_TYPE_ENUM.PIPELINE),
  },
  {
    name: 'Report',
    type: ts.enums.UI_RESOURCE_TYPE_ENUM.GENERAL_ANALYSIS,
    onClick: (triggerFunc: (_s: string) => void) => triggerFunc(ts.enums.UI_RESOURCE_TYPE_ENUM.GENERAL_ANALYSIS),
  },
];

export const getLeftSidebarWidth = (preferences: ts.types.preferences.PREFERENCES, currTab: string) => {
  const { LEFT_DRAWER_WIDTH } = uitheme.layoutSize;
  const layoutPreferences = preferences.layout || {};
  if (currTab == 'production') return layoutPreferences.production_sidebar_size ?? LEFT_DRAWER_WIDTH;
  if (currTab == 'jobber') return layoutPreferences.jobber_sidebar_size ?? LEFT_DRAWER_WIDTH;
  return layoutPreferences.research_sidebar_size ?? LEFT_DRAWER_WIDTH;
};
