import { _, am4charts, am4core, helpers, ts } from '_core';

import { AxisRenderer } from '@amcharts/amcharts4/charts';

import { createLegend } from './create-legend';
import { createRanges } from './create-ranges';
import * as utils from '../utils';
const MAX_NUMBER_OF_DATAPOINTS = 250;

const allPeriods: { label: string; series_prefix: ts.enums.PERIOD_ENUM }[] = [
  { label: '5D', series_prefix: ts.enums.PERIOD_ENUM.FIVE_TRADING_DAYS },
  { label: '1M', series_prefix: ts.enums.PERIOD_ENUM.ONE_MONTH },
  { label: '3M', series_prefix: ts.enums.PERIOD_ENUM.THREE_MONTHS },
  { label: '6M', series_prefix: ts.enums.PERIOD_ENUM.SIX_MONTHS },
  { label: 'YTD', series_prefix: ts.enums.PERIOD_ENUM.YTD },
  { label: '1Y', series_prefix: ts.enums.PERIOD_ENUM.ONE },
  { label: '3Y', series_prefix: ts.enums.PERIOD_ENUM.THREE },
  { label: '5Y', series_prefix: ts.enums.PERIOD_ENUM.FIVE },
  { label: 'Full', series_prefix: ts.enums.PERIOD_ENUM.FULL },
];

export const createSeries = (
  id: string,
  chart: am4charts.XYChart,
  linearData: ts.types.widgets.LinearData,
  aggregation: 'open' | 'close' | 'outlier' | 'min' | 'max',
  valueAxisY: am4charts.ValueAxis<AxisRenderer>,
  endValueAxisY: am4charts.ValueAxis<AxisRenderer>,
  xIsDate: boolean,
  xAxis: string,
  yAxis: string,
  dotted: string[],
  showPeriodSelector: boolean,
  diff: number,
  percent: boolean,
  integerOnly: boolean,
  showEndValueLabel: boolean,
  fullScreen: boolean,
  setShowLegend: (_show: boolean) => void
) => {
  const periods = allPeriods.filter((period) => {
    if (period.series_prefix === 'Full') return true;
    const keys = Object.keys(linearData ?? {});
    return keys.some((key) => key.includes(period.series_prefix));
  });

  let selectorContainer: am4core.Container | null = null;

  if (showPeriodSelector) {
    selectorContainer = am4core.create(`periods-selector-${id}-${fullScreen ? 'fs' : 'sw'}`, am4core.Container);
    selectorContainer.logo.disabled = true;
    selectorContainer.width = am4core.percent(100);
    selectorContainer.height = 30;
    selectorContainer.marginBottom = 15;
    selectorContainer.layout = 'grid';
    selectorContainer.fixedWidthGrid = true;
    selectorContainer.align = 'right';
    selectorContainer.contentAlign = 'right';
  }

  let selectedPeriod = 'Full';

  const removePeriodPrefix = (key: string) => {
    for (const period of allPeriods) {
      if (key.startsWith(period.series_prefix) && period.series_prefix !== 'Full') {
        return key.slice(period.series_prefix.length + 1);
      }
    }
    return key;
  };

  const createSingleSerie = (key: string, index: number, axis: any, endLabel = false, data: any) => {
    const seriesName = showPeriodSelector ? removePeriodPrefix(key) : key;
    const series = chart.series.push(new am4charts.LineSeries());

    series.calculatePercent = true;
    series.name = seriesName;
    series.yAxis = axis;

    if (endLabel) {
      series.hiddenInLegend = true;
      series.hide();
    }

    if (xIsDate) series.dataFields.dateX = xAxis;
    else series.dataFields.valueX = xAxis;
    series.dataFields.valueY = yAxis;

    series.tooltipText = '{name}: {real}';
    series.tooltip.fontSize = 11;
    series.tooltip.zIndex = 1000000;
    series.fill = am4core.color(helpers.getColor(index));
    series.stroke = am4core.color(helpers.getColor(index));
    series.data = data;

    if (dotted.includes(key)) series.strokeDasharray = '3,3';
  };

  const filterSeriesByPeriod = () => {
    if (!xIsDate) {
      throw new Error('X axis is not a date');
    }

    chart.series.clear();
    chart.legend?.dispose();

    const seriesToBuild = [] as { key: string }[];

    Object.keys(linearData).forEach((key) => {
      let willHide = false;
      if (selectedPeriod === 'Full') {
        willHide = allPeriods.some((period) => key.includes(period.series_prefix) && period.series_prefix !== 'Full');
      } else {
        willHide = !key.includes(selectedPeriod);
      }

      if (!willHide || !showPeriodSelector) seriesToBuild.push({ key });
    });

    // Find first non-null data point across all series
    let firstValidIndex = 0;
    const seriesData = seriesToBuild.map(({ key }) => _.sortBy(linearData[key], xAxis));

    for (let i = 0; i < (seriesData[0] ?? []).length; i++) {
      const oneHaveValue = seriesData.some((data) => !_.isNil(data[i]?.value));
      if (oneHaveValue) {
        firstValidIndex = i;
        break;
      }
    }

    // Create series with trimmed data
    seriesToBuild.forEach(({ key }, index) => {
      let trimmedData = _.sortBy(linearData[key], xAxis).slice(firstValidIndex);

      if (!fullScreen)
        trimmedData = utils.groupData(
          {
            [key]: trimmedData,
          } as ts.types.widgets.LinearData,
          aggregation,
          yAxis as keyof ts.types.widgets.LinearData[string][0],
          MAX_NUMBER_OF_DATAPOINTS
        )[key];

      createSingleSerie(key, index, valueAxisY, false, trimmedData);
      createSingleSerie(key, index, endValueAxisY, true, trimmedData);
    });

    createLegend(chart, id, fullScreen, setShowLegend);
    if (showEndValueLabel) {
      createRanges(chart, endValueAxisY, diff, percent, integerOnly);
      chart.series.each((series) => {
        series.events.on('hidden', function () {
          createRanges(chart, endValueAxisY, diff, percent, integerOnly);
        });
        series.events.on('shown', function () {
          createRanges(chart, endValueAxisY, diff, percent, integerOnly);
        });
      });
    }
  };

  if (selectorContainer) {
    const buttonContainer = selectorContainer.createChild(am4core.Container);
    buttonContainer.layout = 'grid';
    buttonContainer.fixedWidthGrid = false;
    buttonContainer.contentAlign = 'right';
    buttonContainer.width = am4core.percent(100);

    const loadingLabel = buttonContainer.createChild(am4core.Label);
    loadingLabel.text = 'Loading...';
    loadingLabel.fontSize = 12;
    loadingLabel.align = 'right';
    loadingLabel.valign = 'bottom';
    loadingLabel.marginRight = 10;
    loadingLabel.fill = am4core.color('#606060');
    loadingLabel.hide();

    periods.forEach((period) => {
      const button = buttonContainer.createChild(am4core.Button);
      button.label.text = period.label;
      button.fontSize = 10;
      button.padding(6, 10, 6, 10);
      button.margin(0, 6, 0, 6);
      button.cursorOverStyle = am4core.MouseCursorStyle.pointer;

      // Set default state
      button.background.fill = am4core.color('#FFFFFF');
      button.label.fill = am4core.color('#000000');
      button.background.stroke = am4core.color('#E0E0E0');
      button.background.strokeWidth = 0.5;

      // Set hover state
      const hoverState = button.background.states.create('hover');
      hoverState.properties.fill = am4core.color('#DFE7F3');
      hoverState.properties.stroke = am4core.color('#B0C4E8');

      // Set down state
      const downState = button.background.states.create('down');
      downState.properties.fill = am4core.color('#3C5688');
      downState.properties.stroke = am4core.color('#2A3E60');

      button.events.on('hit', () => {
        selectedPeriod = period.series_prefix;
        loadingLabel.show();

        // Set active state immediately
        buttonContainer.children.each((child: any) => {
          if (child instanceof am4core.Button) {
            if (child === button) {
              child.isActive = true;
              child.background.setState('down');
              child.label.fill = am4core.color('#FFFFFF');
            } else {
              child.isActive = false;
              child.background.setState('default');
              child.label.fill = am4core.color('#000000');
            }
          }
        });

        setTimeout(() => {
          filterSeriesByPeriod();
          loadingLabel.hide();
        }, 100);
      });

      // Handle out event to maintain active state
      button.events.on('out', (ev) => {
        if (ev.target.isActive) {
          setTimeout(() => {
            button.background.setState('down');
          });
        } else {
          setTimeout(() => {
            button.background.setState('default');
            button.label.fill = am4core.color('#000000');
          });
        }
      });

      // Handle hover event to maintain active state
      button.events.on('over', (ev) => {
        if (ev.target.isActive) {
          setTimeout(() => {
            button.background.setState('down');
          });
        }
      });

      if (period.series_prefix === 'Full') {
        button.isActive = true;
        button.background.setState('down');
        button.label.fill = am4core.color('#FFFFFF');
      }
    });
  }

  // Initial filtering
  filterSeriesByPeriod();

  return true;
};
