import { _, helpers, ts } from '_core';

const dollar = helpers.tableFormatters.formatTo('float', {
  align: 'right',
  roundDigits: 2,
  commasOnThousands: true,
  numericPrefix: '$',
});

export const _getDynamicName = (key: string) => {
  if (key.includes('basket_')) {
    key = key.replace('basket_', '').replace('=>', ' ');
    return key;
  }

  if (key.includes('handle_')) {
    key = key.replace('handle_', '').replace('=>', ' ');
    return key;
  }

  return _.startCase(key);
};

export const _getStyleName = (key: string, signals: ts.StoreState['resources']['signals']) => {
  const newKey = key.replace('_style_', '');
  const signal = signals.find((s) => s.handle == newKey);
  if (signal) return signal.name;
  return newKey;
};

export const _getCustomInputName = (key: string) => {
  if (key.includes('market_cap')) {
    key = key.replace('market_cap', 'Market Cap $million');
    return key;
  }

  if (key.includes('adv_traded')) {
    return 'ADV Traded %';
  }

  return key;
};

export default {
  [ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.MAIN]: [
    {
      key: 'period_start',
      name: 'Period Start',
      formatter: helpers.tableFormatters.formatTo('date', { align: 'right', format: 'YYYY-MM-DD' }),
      filter: 'date',
    },
    {
      key: 'period_end',
      name: 'Period End',
      formatter: helpers.tableFormatters.formatTo('date', { align: 'right', format: 'YYYY-MM-DD' }),
      filter: 'date',
    },
    {
      key: 'name',
      name: 'Name',
      formatter: helpers.tableFormatters.formatTo('string', { align: 'left' }),
      filter: 'string',
    },
    {
      key: 'asset',
      name: 'Asset',
      formatter: helpers.tableFormatters.formatTo('string', { align: 'left' }),
      filter: 'string',
    },
    {
      key: 'asset_type',
      name: 'Type',
      formatter: helpers.tableFormatters.formatTo('string', { align: 'left' }),
      filter: 'string',
    },
    {
      key: 'ticker',
      name: 'Ticker',
      formatter: helpers.tableFormatters.formatTo('string', { align: 'left' }),
      filter: 'string',
    },
    {
      key: 'rbics_l1_id',
      name: 'Sector Code',
      formatter: helpers.tableFormatters.formatTo('integer', { align: 'right' }),
      filter: 'string',
    },
    {
      key: 'rbics_l2_id',
      name: 'Sub-Sector Code',
      formatter: helpers.tableFormatters.formatTo('integer', { align: 'right' }),
      filter: 'string',
    },
    {
      key: 'rbics_l3_id',
      name: 'Industry Code',
      formatter: helpers.tableFormatters.formatTo('integer', { align: 'right' }),
      filter: 'string',
    },
    {
      key: 'rbics_l4_id',
      name: 'Sub-Industry Code',
      formatter: helpers.tableFormatters.formatTo('integer', { align: 'right' }),
      filter: 'string',
    },
    {
      key: 'rbics_l1_name',
      name: 'Sector Name',
      formatter: helpers.tableFormatters.formatTo('string', { align: 'left' }),
      filter: 'string',
    },
    {
      key: 'rbics_l2_name',
      name: 'Sub-Sector Name',
      formatter: helpers.tableFormatters.formatTo('string', { align: 'left' }),
      filter: 'string',
    },
    {
      key: 'rbics_l3_name',
      name: 'Industry Name',
      formatter: helpers.tableFormatters.formatTo('string', { align: 'left' }),
      filter: 'string',
    },
    {
      key: 'rbics_l4_name',
      name: 'Sub-Industry Name',
      formatter: helpers.tableFormatters.formatTo('string', { align: 'left' }),
      filter: 'string',
    },
    {
      key: 'cusip',
      name: 'Cusip',
      formatter: helpers.tableFormatters.formatTo('string', { align: 'left' }),
      filter: 'string',
      condition: (features: ts.types.organization.OrganizationFeatures) => !features.hide_cusip_col,
    },
    {
      key: 'isin',
      name: 'ISIN',
      formatter: helpers.tableFormatters.formatTo('string', { align: 'left' }),
      filter: 'string',
      condition: (features: ts.types.organization.OrganizationFeatures) => !features.hide_isin_col,
    },
    {
      key: 'currency',
      name: 'Currency',
      formatter: helpers.tableFormatters.formatTo('string', { align: 'center' }),
      filter: 'string',
    },
    {
      key: 'issuer_country',
      name: 'Issuer Country',
      formatter: helpers.tableFormatters.formatTo('string', { align: 'center' }),
      filter: 'string',
    },
    {
      key: 'quotation_country',
      name: 'Quotation Country',
      formatter: helpers.tableFormatters.formatTo('string', { align: 'center' }),
      filter: 'string',
    },
    {
      key: 'price_in_usd',
      name: 'Price in USD',
      formatter: dollar,
      filter: 'number',
    },
    {
      key: 'main_initial',
      name: 'Initial Portfolio',
      formatter: helpers.tableFormatters.formatTo('float', {
        align: 'right',
        roundDigits: 3,
        multiplier: 100,
        suffix: '%',
      }),
      filter: 'number',
    },
    {
      key: 'main_optimal',
      name: 'Optimal Portfolio',
      formatter: helpers.tableFormatters.formatTo('float', {
        align: 'right',
        roundDigits: 3,
        multiplier: 100,
        suffix: '%',
      }),
      filter: 'number',
    },
    {
      key: 'main_after_returns',
      name: 'Portfolio After Returns',
      formatter: helpers.tableFormatters.formatTo('float', {
        align: 'right',
        roundDigits: 3,
        multiplier: 100,
        suffix: '%',
      }),
      filter: 'number',
    },
    {
      key: 'main_after_costs',
      name: 'Portfolio After Returns and Costs',
      formatter: helpers.tableFormatters.formatTo('float', {
        align: 'right',
        roundDigits: 3,
        multiplier: 100,
        suffix: '%',
      }),
      filter: 'number',
    },
    {
      key: 'period_returns',
      name: 'Period Returns',
      formatter: helpers.tableFormatters.formatTo('float', {
        align: 'right',
        roundDigits: 2,
        multiplier: 100,
        suffix: '%',
      }),
      filter: 'number',
    },
    {
      key: 'optimizer_warnings',
      name: 'Optimizer Warnings',
      formatter: helpers.tableFormatters.formatTo('string', { align: 'left' }),
      filter: 'string',
    },
    {
      key: '_input_', // Dynamic
      name: 'Backtest Inputs',
      formatter: helpers.tableFormatters.formatDynamicTo({
        _input_median_daily_volume_60: {
          colType: 'integer',
          args: { align: 'right', commasOnThousands: true, numericPrefix: '$' },
        },
        _input_market_cap: {
          colType: 'integer',
          args: { align: 'right', commasOnThousands: true, numericPrefix: '$' },
        },
        _input_market: {
          colType: 'float',
          args: { align: 'right', roundDigits: 4 },
        },
        _input_specific_risk: {
          colType: 'float',
          args: { align: 'right', roundDigits: 4 },
        },
        _input_adv_traded_pct: {
          colType: 'float',
          args: { align: 'right', roundDigits: 2, multiplier: 100, suffix: '%' },
        },
        other: {
          colType: 'inferred',
        },
      }),
      members: (key) => key.startsWith('_input_'),
      nameFormatter: (key) => _getCustomInputName(key.replace('_input_', '')),
      filter: 'inferred',
    },
    {
      key: 'alpha',
      name: 'Alpha',
      formatter: helpers.tableFormatters.formatTo('float', { align: 'right', roundDigits: 4 }),
      filter: 'number',
    },
    {
      key: 'traded_value',
      name: 'Traded Value',
      formatter: helpers.tableFormatters.formatTo('float', {
        align: 'right',
        roundDigits: 2,
        numericPrefix: '$ ',
        commasOnThousands: true,
      }),
      filter: 'number',
    },
    {
      key: 'benchmark_initial',
      name: 'Benchmark Initial',
      formatter: helpers.tableFormatters.formatTo('float', {
        align: 'right',
        roundDigits: 3,
        multiplier: 100,
        suffix: '%',
      }),
      filter: 'number',
    },
    {
      key: 'benchmark_after_returns',
      name: 'Benchmark After Returns',
      formatter: helpers.tableFormatters.formatTo('float', {
        align: 'right',
        roundDigits: 3,
        multiplier: 100,
        suffix: '%',
      }),
      filter: 'number',
    },
    {
      key: 'active_optimal',
      name: 'Active Optimal',
      formatter: helpers.tableFormatters.formatTo('float', {
        align: 'right',
        roundDigits: 3,
        multiplier: 100,
        suffix: '%',
      }),
      filter: 'number',
    },
    {
      key: 'active_after_returns',
      name: 'Active After Returns',
      formatter: helpers.tableFormatters.formatTo('float', {
        align: 'right',
        roundDigits: 3,
        multiplier: 100,
        suffix: '%',
      }),
      filter: 'number',
    },
    {
      key: 'active_after_costs',
      name: 'Active After Costs',
      formatter: helpers.tableFormatters.formatTo('float', {
        align: 'right',
        roundDigits: 3,
        multiplier: 100,
        suffix: '%',
      }),
      filter: 'number',
    },
    {
      key: 'tcost_weight',
      name: 'T-cost',
      formatter: helpers.tableFormatters.formatTo('float', {
        align: 'right',
        roundDigits: 4,
        multiplier: 100,
        suffix: '%',
      }),
      filter: 'number',
    },
    {
      key: 'asset_master_linked_assets',
      name: 'Linked Assets',
      formatter: helpers.tableFormatters.formatTo('string', { align: 'left' }),
      filter: 'string',
    },
    {
      key: '_style_', // Dynamic
      name: 'Risk Model Style Factors',
      formatter: helpers.tableFormatters.formatTo('inferred'),
      members: (key) => key.startsWith('_style_'),
      nameFormatter: (key, _c, resources: ts.StoreState['resources']) => _getStyleName(key, resources.signals),
      filter: 'inferred',
    },
    {
      key: '_category_', // Dynamic
      name: 'Risk Model Category Factors',
      formatter: helpers.tableFormatters.formatTo('inferred'),
      members: (key) => key.startsWith('_category_'),
      nameFormatter: (key) => _getDynamicName(key.replace('_category_', '')),
      filter: 'inferred',
    },
  ] as ts.types.components.dataGrid.ColumnsData,
};
