import { hooks, mui, React, ts, ui } from '_core';

import { DraftParams } from './types';

type FormProps = {
  params: DraftParams;
  setParams: (_p: DraftParams) => void;
  context?: ts.types.signal.SignalAnalysisContext;
};

const Form: React.FC<FormProps> = ({ params, setParams, context }) => {
  const [withBenchmark, setWithBenchmark] = React.useState(params.with_benchmark || false);
  const [selectedReturnHorizon, setSelectedReturnHorizon] = React.useState(params.return_horizons);

  const [benchmark, setBenchmark] = React.useState(params.benchmark || null);

  const uiStyles = hooks.useUiStyles();

  React.useEffect(() => {
    const data = { ...params, return_horizons: selectedReturnHorizon, with_benchmark: withBenchmark };
    if (withBenchmark) data.benchmark = benchmark;
    else if (data.benchmark) delete data.benchmark;

    setParams(data);
  }, [selectedReturnHorizon, withBenchmark, benchmark]);

  return (
    <mui.core.Box sx={uiStyles.widgetEditMode}>
      <mui.core.Box mt={4}>
        <ui.WidgetReturnHorizon<false>
          value={selectedReturnHorizon?.[0]}
          setValue={(val) => {
            setSelectedReturnHorizon([val]);
          }}
          context={context}
        />
      </mui.core.Box>
      <mui.core.Box mt={3}>
        <mui.core.FormControlLabel
          control={
            <mui.core.Switch size="small" checked={withBenchmark} onChange={() => setWithBenchmark(!withBenchmark)} />
          }
          label="Has benchmark"
        />
      </mui.core.Box>
      {withBenchmark && (
        <>
          <mui.core.Typography variant="body2" fontWeight="fontWeightMedium" style={{ marginTop: '1rem' }}>
            Benchmark
          </mui.core.Typography>
          <mui.core.Box mt={3}>
            <ui.Benchmark
              value={benchmark || undefined}
              setValue={setBenchmark}
              universeContextPath={'$universe.handle'}
              enableAnnualizedSignals
              universeIdPath={'definition.universe_id'}
            />
          </mui.core.Box>
        </>
      )}
    </mui.core.Box>
  );
};

export default Form;
