/* eslint-disable no-unused-vars */
import { ts } from '_core';

export enum RISK_METRIC_ENUM {
  TOTAL = 'PCR',
  FACTOR = 'FPCR',
  SPECIFIC = 'SPCR',
}

export enum RISK_METRIC_API {
  TOTAL = 'PCR risk',
  FACTOR = 'FPCR risk',
  SPECIFIC = 'SPCR risk',
  TOTAL_FR = 'PCR fractional risk',
  FACTOR_FR = 'FPCR fractional risk',
  SPECIFIC_FR = 'SPCR fractional risk',
}

export enum FRACTIONAL_RISK_ENUM {
  FRACTION = 'fractional risk',
  RISK = 'risk',
}

export enum VARIABLES_FILTER_ENUM {
  NONE = 'NONE',
  ALPHAS = 'ALPHAS',
  HOLDINGS = 'HOLDINGS',
}

export type ParamsArgs = {
  comb: string[];
  without_residuals: boolean;
  view_type: 'factor_returns' | 'factor_exposures' | 'attributed_returns';
  detailed?: boolean;
  group_by?: string[];
  risk_metric?: RISK_METRIC_API;
  history?: ts.enums.HISTORY_ENUM_EXTENDED;
  statistics_type?: ts.enums.REPORT_ENUMS.ANNU_STATISTICS_ENUM;
};

export type JobParams = {
  histories: '$context_histories' | ts.enums.HISTORY_ENUM_EXTENDED[];
  attribution_type: 'holdings' | 'returns' | 'risk';
  statistics_type: ts.enums.REPORT_ENUMS.ANNU_STATISTICS_ENUM;
  buckets: ts.types.widgets.common.BucketValue;
  variables: ts.types.widgets.common.ExplanatoryVariablesValue;
  variable_filter: VARIABLES_FILTER_ENUM;
  intercept: boolean;
  without_residuals: boolean;
  include_benchmark: boolean;
  benchmark_neutral: boolean;
};

export type Params = JobParams;
export type DraftParams = Partial<Params>;
