import { ts } from '_core';

import { validate } from './helpers';
import { DraftParams } from './types';

export default [
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.SIGNAL_BUCKETED_RETURN_PROFILE,
    title: 'Bucketed Return Profile',
    helpLabel: 'zok2e2k4',
    form: true,
    defaultParams: {
      quantiles: 5,
      days_before_event: 10,
      days_after_event: 21,
    } as DraftParams,
    neededFiles: ['signal_return_profile'],
    tags: [
      ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.SIGNAL,
      ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.ALPHA_MODEL,
      ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.COMMON_ALPHA_MODEL,
    ],

    validate: validate,
  },
] satisfies ts.types.analysis.WidgetConfig[];
