import { helpers, ts } from '_core';

import { Params } from './type';

export default [
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.METRIC_BASKET_RETURNS_BY_ASSET,
    title: 'Attribution by Asset',
    form: false,
    helpLabel: 'jti0bae0',
    neededFiles: ['signal_returns_driven_attribution_chart'],
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.BASKET_ADHOC],

    defaultParams: {
      statistics_type: ts.enums.REPORT_ENUMS.ANNU_STATISTICS_ENUM.RETURNS,
      buckets: { type: 'ASSET_MASTER', value: 'name' },
    } as Params,
    getAdhocData: (pathFile, _params, context) => {
      const histories = helpers.periods.getWindowPeriods(context.start_date, context.end_date);

      return {
        name: 'Attribution by Asset',
        file_name: pathFile,
        description: 'This file contains the attributed returns of the basket, attributed to individual assets.',
        columns: [
          { key: 'ASSET_MASTER:name', name: 'asset', description: 'Asset name.', type: 'string' },
          // eslint-disable-next-line max-len
          {
            key: 'history',
            name: 'period',
            description: `One of: ${Object.values(histories).join(', ')}.`,
            type: 'string',
          },
          {
            key: 'returns',
            name: 'returns',
            description: 'Attributed returns for that asset.',
            type: 'float',
          },
        ],
      };
    },
  },
] satisfies ts.types.analysis.WidgetConfig[];
