import React from 'react';

import EditWidgetTitle from './edit-widget-title-dialog';
import { Link, mui } from '../libs';

type EditableTabProps = {
  id: string;
  params: Record<string, any>;
  path: string;
  setParams: (_p: Record<string, any>) => Promise<void>;

  resetTitle?: () => Promise<void>;
  getName?: () => string;
  getSubtitle?: () => string;
  enableSubtitle?: boolean;
  removeTab?: () => void;
  disabledActions?: boolean;
  activeTab?: boolean;
};

/**
 * EditableTab component that displays a tab with an edit button.
 * 
 * @param id - The id of the tab.
 * @param params - The parameters of the tab.
 * @param path - The path of the tab.
 * @param setParams - The function to set the parameters of the tab.  
 * @param resetTitle - The function to reset the title of the tab.
 * @param getName - The function to get the name of the tab.
 * @param getSubtitle - The function to get the subtitle of the tab.
 * @param enableSubtitle - Whether to enable the subtitle of the tab.
 * @param removeTab - The function to remove the tab.
 * @param disabledActions - Whether to disable the actions of the tab.
 * @param activeTab - Whether the tab is active.
 * 
 * @returns A React component that displays a tab with an edit button.
 */

const EditableTab: React.FC<EditableTabProps> = ({
  id,
  params,
  setParams,
  getName,
  getSubtitle,
  path,
  resetTitle,
  enableSubtitle,
  removeTab,
  disabledActions,
  activeTab,
}): React.ReactElement => {
  const [openEdit, setOpenEdit] = React.useState(false);
  const theme = mui.styles.useTheme() as mui.core.Theme;

  const tabName = React.useMemo(() => getName() ?? params.title ?? '', [params.title]);
  const tabSubtitle = React.useMemo(() => getSubtitle(), [params.subtitle]);

  const isLongTitle = tabName.length > 40;

  const renderSubtitle = () => (
    <span style={{ textAlign: 'left', fontSize: '0.6rem', opacity: 0.8 }}>{tabSubtitle}</span>
  );

  return (
    <>
      <mui.core.Tab
        component={Link}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          minWidth: '0',
          '& span': {
            display: 'block',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            minWidth: 0,
            textTransform: 'initial',
            letterSpacing: '-.5px',
            fontWeight: 600,
          },
        }}
        to={`${path}/${id}`}
        label={
          <mui.core.Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {isLongTitle ? (
              <mui.core.Tooltip arrow title={tabName}>
                <mui.core.Box
                  sx={{
                    display: 'block',
                    maxWidth: '180px',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textAlign: 'left',
                    color: activeTab ? (theme.palette as any).primary.main : (theme.palette as any).text.secondary,
                  }}
                >
                  {tabName}
                  {tabSubtitle && renderSubtitle()}
                </mui.core.Box>
              </mui.core.Tooltip>
            ) : (
              <mui.core.Box
                sx={{
                  textAlign: 'left',
                  color: activeTab ? (theme.palette as any).primary.main : (theme.palette as any).text.secondary,
                }}
              >
                {tabName}
                {tabSubtitle && renderSubtitle()}
              </mui.core.Box>
            )}
            <mui.core.IconButton
              disabled={disabledActions}
              style={{ marginLeft: '4px', padding: '2px' }}
              size="small"
              onClick={(e) => {
                e.preventDefault();
                setOpenEdit(true);
              }}
            >
              <mui.icons.Edit style={{ fontSize: 14 }} />
            </mui.core.IconButton>
            {removeTab && (
              <mui.core.IconButton
                disabled={disabledActions}
                style={{ marginLeft: '2px' }}
                size="small"
                onClick={(e) => {
                  e.preventDefault();
                  removeTab();
                }}
              >
                <mui.icons.Delete style={{ fontSize: 14 }} />
              </mui.core.IconButton>
            )}
          </mui.core.Box>
        }
      />

      <EditWidgetTitle
        open={openEdit}
        setOpen={setOpenEdit}
        params={{ title: tabName, subtitle: tabSubtitle, ...(params || {}) }}
        setParams={setParams}
        resetTitle={resetTitle}
        enableSubtitle={enableSubtitle}
      />
    </>
  );
};

EditableTab.defaultProps = {
  getName: () => undefined,
  getSubtitle: () => undefined,
  enableSubtitle: true,
  disabledActions: false,
};

export default EditableTab;
