import { mui, React, ts, ui } from '_core';

import SharedConstraintFields from './shared-fields';
import constraintInputNames from '../optimizer-utils/input-names';

type AssetVolumeProps = {
  id: string;
  params: ts.types.optimizer.AssetVolumeDraft;
  setParams: (_params: ts.types.optimizer.AssetVolumeDraft) => void;
  readOnly: boolean;
  validationDef: ts.types.common.ValidationErrors;
};

const AssetVolume: React.FC<AssetVolumeProps> = ({
  id,
  params,
  setParams,
  readOnly,
  validationDef,
}): React.ReactElement => {
  const [showMore, setShowMore] = React.useState(false);
  return (
    <mui.core.Box p={2}>
      <mui.core.Grid container spacing={4}>
        <mui.core.Grid item xs={12} md={4}>
          <ui.NumericField
            textFieldProps={{
              label: constraintInputNames.max_position_days,
              fullWidth: true,
              variant: 'outlined',
              size: 'small',
              error: validationDef?.fields?.includes(`max_position_days_${id}`),
            }}
            disabled={readOnly}
            value={params.max_position_days}
            setValue={(v) => setParams({ ...params, max_position_days: v })}
          />
        </mui.core.Grid>

        <mui.core.Grid item xs={12} md={4}>
          <ui.NumericField
            textFieldProps={{
              label: constraintInputNames.max_trade_days,
              fullWidth: true,
              variant: 'outlined',
              size: 'small',
              error: validationDef?.fields?.includes(`trade_days_${id}`),
            }}
            disabled={readOnly}
            value={params.max_trade_days}
            setValue={(v) => setParams({ ...params, max_trade_days: v })}
          />
        </mui.core.Grid>

        <mui.core.Grid item xs={12} md={4}>
          <ui.NumericField
            textFieldProps={{
              label: 'Trade Days Decrease Multiplier',
              fullWidth: true,
              required: true,
              variant: 'outlined',
              size: 'small',
              error: validationDef?.fields?.includes(`trade_days_decrease_${id}`),
            }}
            disabled={readOnly}
            value={params.trade_days_decrease_multiplier}
            setValue={(v) => setParams({ ...params, trade_days_decrease_multiplier: v })}
          />
        </mui.core.Grid>

        <mui.core.Grid item xs={12} md={12}>
          <mui.core.Box ml={3}>
            <mui.core.FormControlLabel
              control={
                <mui.core.Switch
                  size="small"
                  checked={params.exempt_funding_trade}
                  onChange={() =>
                    setParams({
                      ...params,
                      exempt_funding_trade: !params.exempt_funding_trade,
                    })
                  }
                  disabled={readOnly}
                />
              }
              label="Exempt Funding Trade"
            />
          </mui.core.Box>
        </mui.core.Grid>
        {showMore && (
          <mui.core.Box px={5}>
            <SharedConstraintFields
              params={params}
              setParams={setParams}
              showRelaxation={false}
              readOnly={readOnly}
              validationDef={validationDef}
            />
          </mui.core.Box>
        )}
        <mui.core.Box p={5}>
          <mui.core.Button
            onClick={() => {
              setShowMore(!showMore);
            }}
            color="primary"
            style={{ marginTop: '0.5rem' }}
          >
            {showMore ? (
              <>
                <mui.icons.Remove style={{ fontSize: '14px', marginRight: '0.5rem' }} />
                Show Less
              </>
            ) : (
              <>
                <mui.icons.Add style={{ fontSize: '14px', marginRight: '0.5rem' }} />
                Show More
              </>
            )}
          </mui.core.Button>
        </mui.core.Box>
      </mui.core.Grid>
    </mui.core.Box>
  );
};

export default AssetVolume;
