import { config, hooks, mui, React, ts, ui } from '_core';

import { DraftParams } from './types';

type FormProps = {
  params: DraftParams;
  setParams: (_p: DraftParams) => void;

  startDate?: string;
  endDate?: string;
  universeId?: number;
};

const Form: React.FC<FormProps> = ({
  params,
  setParams,
  startDate,
  endDate,

  universeId,
}): React.ReactElement => {
  const uiStyles = hooks.useUiStyles();

  const [snapshotDate, setSnapshotDate] = React.useState(params.preview_date);

  const [componentErrors, setComponentErrors] = React.useState([]);

  const getInitialState = () => {
    if (params.analysis_universe_id) return params.analysis_universe_id;
    if (universeId) return universeId;

    return null;
  };

  const [selectedUniverse, setSelectedUniverse] = React.useState(getInitialState);

  React.useEffect(() => {
    const data = {
      preview_date: snapshotDate,
      analysis_universe_id: selectedUniverse,
      component_errors: componentErrors,
    };
    setParams({ ...params, ...data });
  }, [snapshotDate, selectedUniverse, componentErrors]);

  return (
    <mui.core.Box sx={uiStyles.widgetEditMode}>
      <mui.core.Grid container spacing={4}>
        <mui.core.Grid item xs={12} md={6}>
          <ui.ResourceAutocomplete<ts.types.universe.UniverseExpanded>
            value={selectedUniverse || null}
            setValue={(id) => setSelectedUniverse(id)}
            autocompleteProps={{
              disableClearable: true,
            }}
            type={ts.enums.RESOURCES_TYPES_ENUM.UNIVERSE}
            inputProps={{
              fullWidth: true,
              label: 'Universe',
              variant: 'outlined',
              size: 'small',
            }}
          />
        </mui.core.Grid>
        <mui.core.Grid item xs={12} md={6}>
          <ui.SmartDateSelector
            label="Snapshot Date"
            value={snapshotDate}
            setValue={(date) => setSnapshotDate(date)}
            minDate={startDate || config.features.start_date}
            maxDate={endDate || config.features.end_date}
            universeId={universeId}
            setExternalError={(e) => setComponentErrors(e ? [e] : [])}
          />
        </mui.core.Grid>
      </mui.core.Grid>
    </mui.core.Box>
  );
};

export default Form;
