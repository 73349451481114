import { hooks, mui, React, ts, ui } from '_core';

import { DraftParams } from './types';

type FormProps = {
  params: DraftParams;
  setParams: (_p: DraftParams) => void;
  context?: ts.types.signal.SignalAnalysisContext;
};

const Form: React.FC<FormProps> = ({ params, setParams, context }) => {
  const [returnHorizons, setReturnHorizons] = React.useState<ts.types.returnHorizon.ReturnHorizon[]>(
    params.return_horizons || []
  );
  const [dailyReporting, setDailyReporting] = React.useState(params?.daily_reporting || false);

  const [scale, setScale] = React.useState(params.scale || 'linear');
  const [benchmark, setBenchmark] = React.useState(params.benchmark || null);
  const [withBenchmark, setWithBenchmark] = React.useState(params.with_benchmark || false);

  const uiStyles = hooks.useUiStyles();

  React.useEffect(() => {
    const data = {
      ...params,
      return_horizons: returnHorizons,
      scale,
      with_benchmark: withBenchmark,
      daily_reporting: dailyReporting,
      agg_frequency: dailyReporting ? 'DAILY' : undefined,
    } as DraftParams;

    if (withBenchmark) data.benchmark = benchmark;
    else if (data.benchmark) delete data.benchmark;

    setParams(data);
  }, [returnHorizons, scale, dailyReporting, withBenchmark, benchmark]);

  return (
    <mui.core.Box sx={uiStyles.widgetEditMode}>
      <mui.core.Box mt={2}>
        <mui.core.TextField
          select
          label="Type"
          variant="outlined"
          size="small"
          value={scale}
          onChange={(e) => setScale(e.target.value as 'linear' | 'log')}
          fullWidth
        >
          <mui.core.MenuItem value="linear">Linear scale</mui.core.MenuItem>
          {/* <mui.core.MenuItem value="log">Log scale</mui.core.MenuItem> */}
        </mui.core.TextField>
      </mui.core.Box>
      <mui.core.Box mt={4}>
        <ui.WidgetReturnHorizon<true>
          value={returnHorizons}
          setValue={(val) => setReturnHorizons(val)}
          dailyReporting={dailyReporting}
          setDailyReporting={(val) => setDailyReporting(val)}
          multiple
          enableDailyReporting
          context={context}
        />
      </mui.core.Box>

      <mui.core.Box mt={2}>
        <mui.core.FormControlLabel
          control={
            <mui.core.Switch size="small" checked={withBenchmark} onChange={() => setWithBenchmark(!withBenchmark)} />
          }
          label="Has benchmark"
        />
      </mui.core.Box>

      {withBenchmark && (
        <mui.core.Box mt={2}>
          <ui.Benchmark
            value={benchmark || undefined}
            setValue={setBenchmark}
            universeContextPath={'$universe.handle'}
            enableAnnualizedSignals
            universeIdPath="universe_id"
          />
        </mui.core.Box>
      )}
    </mui.core.Box>
  );
};

export default Form;
