import { helpers, hooks, mui, React, ts } from '_core';

import Tabular from 'views/report/tables/tabular';

import { DraftParams, Params } from './types';

type ChartProps = {
  summaryFile: string;
  detailFile: string;
  loadWidgetData: (_payload: ts.types.widgets.WidgetGetDataParams) => Promise<ts.types.widgets.WidgetGetDataResponse>;
  fullScreen: boolean;
  goFullScreen: (_v: string) => void;
  queryParams?: { initial?: string };
  params: Params;
  setParams: (_p: DraftParams) => void;
};

const Chart: React.FC<ChartProps> = ({
  params,
  setParams,
  summaryFile,
  detailFile,
  loadWidgetData,
  fullScreen,
  goFullScreen,
  queryParams,
}) => {
  const [data, setData] = React.useState<{
    summary: ts.types.widgets.TableData;
    details: ts.types.widgets.TableData;
  }>(null);
  const [error, setError] = React.useState(null);

  const [tableParams, setTableParams] = React.useState<ts.types.components.dataGrid.TableParamsSheet>(
    params.table_params
  );

  hooks.useEffectWithoutFirst(() => {
    setParams({
      ...params,
      table_params: { ...tableParams },
    });
  }, [tableParams]);

  const loadFile = async (file: string) => {
    const response = await loadWidgetData({
      data: {
        file,
      },
    });
    if (response) {
      return response.data;
    }
    return null;
  };

  const loadData = async () => {
    try {
      const summaryDataResp = await loadFile(summaryFile);
      const detailDataResp = await loadFile(detailFile);
      if (summaryDataResp && detailDataResp) {
        setData({ summary: summaryDataResp, details: detailDataResp });
      }
    } catch (err) {
      setError(JSON.stringify(err));
    }
  };

  React.useEffect(() => {
    if (detailFile && summaryFile) loadData();
    return () => {
      setData(null);
    };
  }, [detailFile, summaryFile]);

  const getDetailsColumns = (colKeys: string[]) => {
    const detailColumns: any[] = [{ name: 'Industry', key: 'industry', width: 150 }];

    colKeys.forEach((key) => {
      if (key != 'industry') {
        detailColumns.push({
          key,
          name: key,
          width: 75,
          filter: 'number',
        });
      }
    });

    return detailColumns;
  };

  const summaryColumns = React.useMemo(() => {
    return [
      { name: 'Industry', key: 'industry' },
      { name: 'Min', key: 'min', filter: 'number' },
      { name: 'Q1', key: 'q1', filter: 'number' },
      { name: 'Median', key: 'median', filter: 'number' },
      { name: 'Mean', key: 'mean', filter: 'number' },
      { name: 'Q3', key: 'q3', filter: 'number' },
      { name: 'Max', key: 'max', filter: 'number' },
      {
        name: 'Non-Zero',
        key: 'count',
        filter: 'numeric',
        formatter: helpers.tableFormatters.formatTo('float', {
          align: 'right',
          roundDigits: 1,
          multiplier: 100,
          suffix: '%',
        }),
      },
    ];
  }, []);

  return (
    <mui.core.Box display="flex" flexDirection="column" style={{ height: '100%' }}>
      <Tabular<ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.SUMMARY | ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.DETAILS>
        data={data}
        getColumns={{
          [ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.SUMMARY]: () => summaryColumns,
          [ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.DETAILS]: getDetailsColumns,
        }}
        sheetTitles={{
          [ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.SUMMARY]: 'Summary',
          [ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.DETAILS]: 'By Year',
        }}
        fullScreen={fullScreen}
        goFullScreen={goFullScreen}
        queryParams={queryParams}
        customError={error}
        tableParams={tableParams}
        setTableParams={{
          [ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.SUMMARY]: (v) =>
            setTableParams((t) => ({
              ...t,
              [ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.SUMMARY]: v,
            })),
          [ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.DETAILS]: (v) =>
            setTableParams((t) => ({
              ...t,
              [ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.DETAILS]: v,
            })),
        }}
        triggerWidthChange={params.fullWidth}
      />
    </mui.core.Box>
  );
};

export default Chart;
