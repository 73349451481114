import { _, hooks, mui, React, ts, ui } from '_core';

import { DraftParams, VOLATILITY_SCALE_BY_ENUM } from './types';

type FormProps = {
  params: DraftParams;
  setParams: (_p: DraftParams) => void;
  context?: ts.types.signal.SignalAnalysisContext;
};

const Form: React.FC<FormProps> = ({ params, setParams, context }) => {
  const [analysisSignalId, setAnalysisSignalId] = React.useState(params.analysis_signal_id);
  const [upperBound, setUpperBound] = React.useState(params.upper_bound);
  const [lowerBound, setLowerBound] = React.useState(params.lower_bound);
  const [daysBeforeEvent, setDaysBeforeEvent] = React.useState(params.days_before_event);
  const [daysAfterEvent, setDaysAfterEvent] = React.useState(params.days_after_event);

  const [withVolatility, setWithVolatility] = React.useState(params.with_volatility ?? false);
  // eslint-disable-next-line max-len
  const [scaleByVolatility, setScaleByVolatility] = React.useState<VOLATILITY_SCALE_BY_ENUM>(
    params.scale_by ?? VOLATILITY_SCALE_BY_ENUM.TRAILING_20_DAY_RETURNS_VOLATILITY
  );

  const [getHistories, setGetHistories] = React.useState(params.get_histories ?? false);
  const [histories, setHistories] = React.useState(params.histories || Object.values(ts.enums.HISTORY_ENUM_EXTENDED));

  const uiStyles = hooks.useUiStyles();

  React.useEffect(() => {
    const data = {
      ...params,
      upper_bound: upperBound,
      lower_bound: lowerBound,
      days_before_event: daysBeforeEvent,
      days_after_event: daysAfterEvent,
      histories: getHistories ? histories : undefined,
      scale_by: withVolatility ? scaleByVolatility : undefined,
      with_volatility: withVolatility,
      get_histories: getHistories,
      analysis_signal_id: analysisSignalId,
    };
    setParams(data);
  }, [
    upperBound,
    lowerBound,
    daysBeforeEvent,
    daysAfterEvent,
    getHistories,
    histories,
    withVolatility,
    scaleByVolatility,
    analysisSignalId,
  ]);

  return (
    <mui.core.Box sx={uiStyles.widgetEditMode}>
      <ui.ResourceAutocomplete<ts.types.common.ResourceExpanded>
        value={analysisSignalId}
        setValue={(id) => setAnalysisSignalId(id)}
        type={ts.enums.RESOURCES_TYPES_ENUM.SIGNAL}
        inputProps={{
          fullWidth: true,
          label: 'Exposure Signal',
          variant: 'outlined',
          required: true,
          size: 'small',
        }}
      />
      <mui.core.Typography
        variant="body2"
        fontWeight="fontWeightMedium"
        sx={{
          marginBottom: '10px',
          marginTop: '5px',
        }}
      >
        Event Selection Bounds (at least one is needed)
      </mui.core.Typography>
      <mui.core.Grid container spacing={2}>
        <mui.core.Grid item xs={6}>
          <ui.NumericField
            textFieldProps={{
              label: 'Lower Bound',
              fullWidth: true,
              variant: 'outlined',
              size: 'small',
              required: true,
            }}
            value={lowerBound}
            setValue={(v) => setLowerBound(v)}
          />
        </mui.core.Grid>

        <mui.core.Grid item xs={6}>
          <ui.NumericField
            textFieldProps={{
              label: 'Upper Bound',
              fullWidth: true,
              variant: 'outlined',
              size: 'small',
              required: true,
            }}
            value={upperBound}
            setValue={(v) => setUpperBound(v)}
          />
        </mui.core.Grid>
      </mui.core.Grid>

      <mui.core.Typography
        variant="body2"
        fontWeight="fontWeightMedium"
        sx={{
          marginBottom: '10px',
          marginTop: '5px',
        }}
      >
        Event Window
      </mui.core.Typography>
      <mui.core.Grid container spacing={2}>
        <mui.core.Grid item xs={6}>
          <ui.NumericField
            textFieldProps={{
              label: 'Days Before Event (1 - 21)',
              fullWidth: true,
              variant: 'outlined',
              size: 'small',
              required: 'true',
            }}
            value={daysBeforeEvent}
            setValue={(v) => setDaysBeforeEvent(v)}
          />
        </mui.core.Grid>

        <mui.core.Grid item xs={6}>
          <ui.NumericField
            textFieldProps={{
              label: 'Days After Event (0 - 63)',
              fullWidth: true,
              variant: 'outlined',
              size: 'small',
            }}
            value={daysAfterEvent}
            setValue={(v) => setDaysAfterEvent(v)}
          />
        </mui.core.Grid>
      </mui.core.Grid>

      <mui.core.Box mt={4}>
        <mui.core.FormControlLabel
          control={
            <mui.core.Switch size="small" checked={getHistories} onChange={() => setGetHistories(!getHistories)} />
          }
          label="With Histories"
        />

        {getHistories && (
          <mui.core.Box sx={{ mt: 2 }}>
            <ui.WidgetHistories<true>
              multiple
              context={context}
              value={histories}
              setValue={setHistories}
              label="Histories (Only applied to Cumulative Returns and T-Stat"
            />
          </mui.core.Box>
        )}
      </mui.core.Box>

      <mui.core.Box mt={4}>
        <mui.core.FormControlLabel
          control={
            <mui.core.Switch
              size="small"
              checked={withVolatility}
              onChange={() => setWithVolatility(!withVolatility)}
            />
          }
          label="Scale By Volatility"
        />

        {withVolatility && (
          <mui.core.TextField
            select
            variant="outlined"
            size="small"
            value={scaleByVolatility}
            onChange={(e) => setScaleByVolatility(e.target.value as VOLATILITY_SCALE_BY_ENUM)}
            fullWidth
            required
          >
            {Object.values(VOLATILITY_SCALE_BY_ENUM).map((volatility) => (
              <mui.core.MenuItem key={volatility} value={volatility}>
                {_.startCase(_.lowerCase(volatility))}
              </mui.core.MenuItem>
            ))}
          </mui.core.TextField>
        )}
      </mui.core.Box>
    </mui.core.Box>
  );
};

export default Form;
