import { React, ts } from '_core';

import { loadData } from './api-helpers';
import TableComponent from './table';
import { getCustomToolbars, getSheets } from './table-helpers';
import { getBucketOrder } from '../../../common/utils/attribution-utils';
import { DraftParams, Params, ParamsArgs } from '../types';

type TableProps = {
  id: string;
  params: Params | DraftParams;
  detailsFile: string;
  summaryFile: string;
  frequency: ts.enums.FREQUENCY_ENUM;
  startDate: string;
  endDate: string;
  loadWidgetData: (_payload: ts.types.widgets.WidgetGetDataParams) => Promise<ts.types.widgets.WidgetGetDataResponse>;
  histories: ts.enums.HISTORY_ENUM_EXTENDED[];
};

const Table: React.FC<TableProps> = ({
  loadWidgetData,
  detailsFile,
  summaryFile,
  params,
  startDate,
  endDate,
  histories,
}): React.ReactElement => {
  const removeResiduals = React.useMemo(() => params?.without_residuals || false, [params?.without_residuals]);

  const [detailsData, setDetailsData] = React.useState<ts.types.widgets.TableData>(null);
  const [summaryData, setSummaryData] = React.useState<ts.types.widgets.TableData>(null);
  const [detailsColumns, setDetailsColumns] = React.useState<ts.types.components.dataGrid.ColumnsData>([]);
  const [summaryColumns, setSummaryColumns] = React.useState<ts.types.components.dataGrid.ColumnsData>([]);
  const [error, setError] = React.useState<ts.types.common.Alert>(null);
  const [loading, setLoading] = React.useState(false);
  const [selectedHistory, setSelectedHistory] = React.useState(histories[0]);
  const [selectedViewType, setSelectedViewType] = React.useState('attributed_returns');

  const [selectedStatistics, setSelectedStatistics] = React.useState<ts.enums.REPORT_ENUMS.ANNU_STATISTICS_ENUM>(
    ts.enums.REPORT_ENUMS.ANNU_STATISTICS_ENUM.RETURNS
  );

  const dates = { start: startDate, end: endDate };

  const bucketOrder = React.useMemo(
    () => getBucketOrder({ buckets: params.buckets, variables: params.variables }, 'selected_buckets'),
    []
  );

  const { sheets, sheetsStyles } = React.useMemo(() => getSheets(params, bucketOrder), []);

  const [currentSheet, setCurrentSheet] = React.useState(Object.keys(sheets)[0]);

  const customToolBars = getCustomToolbars({
    params,
    bucketOrder,
    selectedViewType,
    selectedStatistics,
    selectedHistory,
    setSelectedViewType,
    setSelectedStatistics,
    setSelectedHistory,
    histories,
    startDate,
    endDate,
  });

  const callLoadData = async (sheet: string) => {
    setLoading(true);
    setError(null);

    try {
      const { details, summary } = await loadData(
        (p) => loadWidgetData({ data: p }),
        sheet,
        dates,
        params.buckets,
        detailsFile,
        summaryFile,
        {
          comb: bucketOrder,
          history: selectedHistory,
          statistics_type: selectedStatistics,
          without_residuals: removeResiduals,
          view_type: selectedViewType as ParamsArgs['view_type'],
        }
      );

      setDetailsData(details.data);
      setSummaryData(summary.data);
      setDetailsColumns(details.columns);
      setSummaryColumns(summary.columns);
    } catch (err: any) {
      let errorMessage = JSON.stringify(err);
      if (err.responseJSON?.detail) errorMessage = err.responseJSON?.detail;
      setError({
        severity: ts.enums.ALERT_SEVERITY_ENUM.ERROR,
        message: errorMessage,
      });
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    if (detailsFile && summaryFile && currentSheet) callLoadData(currentSheet);
  }, [detailsFile, summaryFile, currentSheet, selectedViewType, selectedHistory, selectedStatistics]);

  // Pass the appropriate data and columns based on whether it's a summary sheet
  const currentData = currentSheet.includes('summary') ? summaryData : detailsData;
  const currentColumns = currentSheet.includes('summary') ? summaryColumns : detailsColumns;

  return (
    <TableComponent
      data={currentData}
      columns={currentColumns}
      sheets={sheets}
      customHeaderHeight={sheetsStyles.customHeaderHeight}
      customWidth={sheetsStyles.customWidth}
      customRowHeight={sheetsStyles.customRowHeight}
      customToolBars={customToolBars}
      loading={loading}
      error={error}
      currentSheet={currentSheet}
      setCurrentSheet={setCurrentSheet}
    />
  );
};

export default Table;
