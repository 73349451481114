import { hooks, mui, React, ts, ui } from '_core';

import { DraftParams } from './types';
import BasketsSelector from '../../common/forms/baskets-selector';

type FormProps = {
  params: DraftParams;
  setParams: (_p: DraftParams) => void;
  context?: ts.types.basket.BasketAnalysisContext;
};

const Form: React.FC<FormProps> = ({ params, setParams, context }) => {
  const [basketIds, setBasketIds] = React.useState(params.basket_ids || []);
  const [selectedHistories, setSelectedHistories] = React.useState(params.histories || []);

  const [benchmark, setBenchmark] = React.useState(params.benchmark || null);
  const [withBenchmark, setWithBenchmark] = React.useState(params.with_benchmark || false);

  const uiStyles = hooks.useUiStyles();

  React.useEffect(() => {
    const data = {
      ...params,
      statistics_type: params.statistics_type,
      basket_ids: basketIds,
      histories: selectedHistories,
      with_benchmark: withBenchmark,
    };
    if (withBenchmark) data.benchmark = benchmark;
    else if (data.benchmark) delete data.benchmark;

    setParams(data);
  }, [selectedHistories, basketIds, withBenchmark, benchmark]);

  return (
    <mui.core.Box sx={uiStyles.widgetEditMode}>
      <mui.core.Box mt={2}>
        <ui.WidgetHistories<true>
          multiple
          context={context}
          value={selectedHistories}
          setValue={setSelectedHistories}
        />
      </mui.core.Box>

      <mui.core.Box mt={2}>
        <mui.core.Box mt={2}>
          <BasketsSelector basketIds={basketIds} setBasketIds={setBasketIds} />
        </mui.core.Box>
      </mui.core.Box>

      <mui.core.Box mt={3}>
        <mui.core.FormControlLabel
          control={
            <mui.core.Switch size="small" checked={withBenchmark} onChange={() => setWithBenchmark(!withBenchmark)} />
          }
          label="Has benchmark"
        />
      </mui.core.Box>

      {withBenchmark && (
        <mui.core.Box mt={2}>
          <ui.Benchmark
            value={benchmark || undefined}
            setValue={setBenchmark}
            universeContextPath={'$universe.handle'}
            enableAnnualizedSignals
            universeIdPath={'definition.universe_id'}
          />
        </mui.core.Box>
      )}
    </mui.core.Box>
  );
};

export default Form;
