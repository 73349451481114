import { DataSource } from '../../../types/data-source';
import { DataSourceExpanded } from '../../../types/data-source';

const expandDataSource = (dataSource: DataSource & { resourceType: string }): DataSourceExpanded => {
  return {
    id: dataSource.id,
    name: dataSource.name,
    handle: dataSource.handle,
    resourceType: dataSource.resourceType,
    created_by_id: dataSource.created_by_id,
    is_published: dataSource.is_published,
    is_deprecated: dataSource.is_deprecated,
    revoked: false,
    is_valid: true,
    is_index: dataSource.is_index,
    has_assets: dataSource.has_assets,
    created_at: dataSource.created_at,
    organization_id: dataSource.organization_id,
    has_description: dataSource.has_description,
    short_description: dataSource.short_description,
    shared: dataSource.shared,
  } as DataSourceExpanded;
};

export default expandDataSource;
