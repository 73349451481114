import { _, api, helpers, ts } from '_core';

type DataSourceApiResponse = {
  data: ts.types.dataSource.DataSource;
};

export const createDataSource = async (
  newData: ts.types.dataSource.DataSourceDraft,
  callback: (_data: ts.types.dataSource.DataSource) => void,
  errorCallback: (_error: string) => void
) => {
  try {
    const resp: DataSourceApiResponse = await api.data_sources.create(newData);
    callback(resp.data);
  } catch (error) {
    errorCallback(`Error creating data source - ${helpers.parseApiError(error as ts.types.common.ApiError)}`);
  }
};

export const updateDataSource = async (
  id: number,
  newData: ts.types.dataSource.DataSourceDraft,
  callback: (_data: ts.types.dataSource.DataSource) => void,
  errorCallback: (_error: string) => void,
  increment = false
) => {
  try {
    const resp: DataSourceApiResponse = increment
      ? await api.data_sources.increment(id, { ...newData })
      : await api.data_sources.update(id, { ...newData });

    callback(resp.data);
  } catch (error) {
    errorCallback(`Error updating data source - ${helpers.parseApiError(error as ts.types.common.ApiError)}`);
  }
};

export const updateDefinition = async (
  id: number,
  definition: ts.types.dataSource.DataSourceDraft,
  callback: (_data: ts.types.dataSource.DataSource) => void,
  errorCallback: (_error: string) => void
) => {
  try {
    const resp = await api.data_sources.update(id, definition, true);
    callback(resp.data);
  } catch (error) {
    errorCallback(`Error updating the definition - ${helpers.parseApiError(error as ts.types.common.ApiError)}`);
  }
};

export const updateUiMetadata = async (
  dataSource: ts.types.dataSource.DataSource,
  uiMetaData: ts.types.dataSource.UiMetaData,
  callback: (_data: ts.types.dataSource.DataSource) => void,
  errorCallback: (_error: string) => void
) => {
  try {
    if (dataSource.shared) return;
    const updateData = {
      ui_metadata: uiMetaData,
      skip_signature_check: true,
    } as ts.types.dataSource.DataSourceDraft;
    const resp = await api.data_sources.update(dataSource.id, updateData);
    callback(resp.data);
  } catch (error) {
    errorCallback(`Error updating the resource metadata - ${helpers.parseApiError(error as ts.types.common.ApiError)}`);
  }
};

export const getDataSource = async (
  id: number,
  userId: number,
  callback: (_data: ts.types.dataSource.DataSource, _prevHtml?: string) => void,
  errorCallback: () => void
) => {
  try {
    const resp = await api.data_sources.get(id);
    const respExtra = await api.uiApi.getDataSourceData({
      data: {
        handle: resp.data.handle,
        ignore_cache: true,
      },
    });
    const ds = {
      ...resp.data,
      ui_metadata: _.isEmpty(resp.data.ui_metadata) ? {} : resp.data.ui_metadata,
    };

    callback(
      {
        ...ds,
        shared: !ds.is_published && ds.shared_with.includes(userId) && ds.created_by_id !== userId,
      },
      respExtra.preview_html
    );
  } catch (error) {
    errorCallback();
  }
};

const createAnalyses = async (id: number, names: ts.enums.DATA_SOURCE_ANALYSIS_TYPE_ENUM[]) => {
  const analyses = [];
  for (const name of names) {
    let analysisDefinition = { widgets: [] } as ts.types.analysis.AnalysisDefinition;

    if (name == ts.enums.DATA_SOURCE_ANALYSIS_TYPE_ENUM.BROWSER) {
      analysisDefinition = {
        mode: ts.enums.ANALYSIS_MODE_ENUM.FULLSCREEN,
        widgets: [
          {
            id: helpers.gibberishGenerator.stringGenerator(11),
            key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.DATA_SOURCE_BROWSER,
            params: {},
          },
        ],
      };
    }

    // create analysis
    const response = await api.data_source_analyses.create({
      report_definition: analysisDefinition,
      data_source_id: id,
      name,
      type: 'ANALYSIS',
    });
    analyses.push(response.data.name);
  }
  return analyses;
};

// Load analyses, and if we don't have them, create them
export const loadAnalyses = async (
  dataSource: ts.types.dataSource.DataSource,
  setAnalysesNames: (_data: ts.enums.DATA_SOURCE_ANALYSIS_TYPE_ENUM[]) => void,
  errorCallback: (_error: string) => void
) => {
  const analyses = _.without(
    Object.values(ts.enums.DATA_SOURCE_ANALYSIS_TYPE_ENUM),
    ts.enums.DATA_SOURCE_ANALYSIS_TYPE_ENUM.QUERIES
  );

  try {
    const resp = await api.data_source_analyses.search({
      query: ['$and', ['data_source_id', '=', dataSource.id], ['type', '=', 'ANALYSIS']],
      include: 'id,name',
    });

    let localNames = (resp.data || []).map(
      (a: ts.types.analysis.ResourceAnalysis) => a.name
    ) as ts.enums.DATA_SOURCE_ANALYSIS_TYPE_ENUM[];

    const toCreate = _.without(analyses, ...localNames);
    if (!_.isEmpty(toCreate)) {
      const newNames = (await createAnalyses(dataSource.id, toCreate)) as ts.enums.DATA_SOURCE_ANALYSIS_TYPE_ENUM[];
      localNames = localNames.concat(newNames);
    }

    setAnalysesNames(localNames);
  } catch (error) {
    errorCallback(`Error loading data source analyses - ${helpers.parseApiError(error as ts.types.common.ApiError)}`);
  }
};
