import api from '../../../api';
import * as enums from '../../../enums';
import * as helpers from '../../../helpers';
import { _ } from '../../../libs';
import * as types from '../../../types';

export const formatUploadColumns = (columns: Record<string, string>) => {
  const finseraCols = ['finsera_cusip', 'finsera_country', 'finsera_isin'];

  const localColumns = {
    finsera_fid: 'finsera_fid',
    finsera_ticker: 'finsera_ticker',
  } as Record<string, string>;

  localColumns['finsera_name'] = 'finsera_name';

  Object.keys(columns).forEach((key) => {
    if (columns[key] !== 'None') {
      localColumns[key] = columns[key];
      const finseraColFound = finseraCols.find((fc) => fc.includes(key));
      if (finseraColFound) localColumns[finseraColFound] = finseraColFound;
    }
  });

  return localColumns;
};

export const loadData = async (
  files: { mapped_file: string; unmapped_file: string },
  resourceId: number,
  unmappedColumns: Record<string, string>
) => {
  const mappedFileSplit = files.mapped_file?.split('/');
  const mappedFile = mappedFileSplit?.slice(2, mappedFileSplit?.length).join('/');

  const unmappedFileSplit = files.unmapped_file?.split('/');
  const unmappedFile = unmappedFileSplit?.slice(2, unmappedFileSplit?.length).join('/');

  let holdingsColumns = [];

  // If we don't have the saved columns for the file with errors
  // We will just use the default ones
  if (unmappedColumns) {
    const filteredColumns = formatUploadColumns(unmappedColumns);
    holdingsColumns = Object.keys(filteredColumns);
  } else {
    const rebColumns = Object.values(enums.REBALANCE_MAP_COLS);
    const finseraColumns = ['finsera_cusip', 'finsera_country', 'finsera_isin', 'finsera_fid', 'finsera_ticker'];

    holdingsColumns = [...rebColumns, ...finseraColumns];
  }

  const resp = await api.uiApi.getRebalanceData({
    data: {
      mapped_file: mappedFile,
      unmapped_file: unmappedFile,
      columns: holdingsColumns,
      resource_id: resourceId,
      ignore_cache: _.cookie('debug') && _.cookie('debug') === '1234', // TODO temp replace,
      resource: 'backtests',
    },
  });

  return {
    data: _.isEmpty(resp?.data) ? [] : resp?.data,
    columns: resp?.columns,
  } as { data: types.widgets.TableData; columns: string[] };
};

export const uploadData = async (
  holdingsId: number,
  columns: Record<string, string>,
  fileData: string,
  token: string,
  callback: (_data: types.rebalance.HoldingsData, _cols: string[]) => void,
  errorCallback: (_error: string) => void
) => {
  try {
    const filteredColumns = formatUploadColumns(columns);
    const holdingsColumns = Object.keys(filteredColumns);

    const holdingsParams = { column_map: filteredColumns, csv: fileData } as Record<string, any>;

    const files = await api.user_api.backtest_portfolio_post_holdings(token, holdingsId, holdingsParams);

    const mappedFileSplit = files.mapped_file?.split('/');
    const mappedFile = mappedFileSplit?.slice(2, mappedFileSplit?.length).join('/');

    const unmappedFileSplit = files.unmapped_file?.split('/');
    const unmappedFile = unmappedFileSplit?.slice(2, unmappedFileSplit?.length).join('/');

    const resp = await api.uiApi.getRebalanceData({
      data: {
        mapped_file: mappedFile,
        unmapped_file: unmappedFile,
        columns: holdingsColumns,
        resource_id: holdingsId,
        ignore_cache: _.cookie('debug') && _.cookie('debug') === '1234', // TODO temp replace,
        resource: 'backtests',
      },
    });

    callback(_.isEmpty(resp?.data) ? [] : resp?.data, resp?.columns);
  } catch (error) {
    errorCallback(`Error mapping file - ${helpers.parseApiError(error as types.common.ApiError)}`);
  }
};

export const submitHoldings = async (
  holdingsId: number,
  holdingsColumns: { title: string; field: string }[],
  // Data here already has the columns changed
  holdingsData: types.rebalance.HoldingsData,
  callback: () => void,
  errorCallback: (_error: string) => void,
  token: string
) => {
  try {
    const csv = helpers.csv
      .toCsv(
        holdingsData,
        holdingsColumns.map((c) => ({ key: c.field })),
        enums.SEPARATORS_ENUM.COMMA,
        false
      )
      .join('');

    const column_map = {} as Record<string, string>;
    holdingsColumns.forEach((c) => (column_map[c.field] = c.field));
    const holdingsParamsData = { column_map, csv } as Record<string, any>;
    await api.user_api.backtest_portfolio_post_holdings(token, holdingsId, holdingsParamsData);
    callback();
  } catch (error) {
    errorCallback(`Error mapping holdings - ${helpers.parseApiError(error as types.common.ApiError)}`);
  }
};
