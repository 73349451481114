/* eslint-disable no-unused-vars */

import { ts } from '_core';

export enum INTERACTION_PARAMS {
  HISTORIES = 'histories',
  SHOW_UNKNOWN = 'show_unknown',
}

export type InteractionParams = {
  [INTERACTION_PARAMS.HISTORIES]?: ts.enums.HISTORY_ENUM_EXTENDED[];
  [INTERACTION_PARAMS.SHOW_UNKNOWN]?: boolean;
};

export type JobParams = {
  time_series_id: number;
  quantiles: number;
};

export type Params = JobParams & InteractionParams;
export type DraftParams = Partial<Params>;
