import { mui, React, ts } from '_core';

import Collapsed from './collapsed';
import Expanded from './expanded';
import { ManagerContext } from './manager-context';
import ManagerHeader from './manager-header';

const Manager = (): React.ReactElement => {
  const managerContext = React.useContext(ManagerContext);
  const { managerState, setReadyToValidate } = managerContext;

  return (
    <mui.core.Box sx={{ px: 1 }} onClick={() => setReadyToValidate()}>
      <ManagerHeader />
      <mui.core.Box>
        {managerState == ts.enums.REPORT_ENUMS.MANAGER_STATE_ENUM.COLLAPSED ? <Collapsed /> : <Expanded />}
      </mui.core.Box>
    </mui.core.Box>
  );
};

export default Manager;
