import { ts } from '_core';

const LAYOUT_TYPE = ts.enums.LAYOUT_TYPE;
const WIDGET_KEY_ENUM = ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM;

export type WidgetsOrderType = Record<ts.enums.LAYOUT_TYPE, (ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM | string)[]>;

const widgetsOrder: WidgetsOrderType = {
  // GENERAL
  [LAYOUT_TYPE.GENERAL]: [
    WIDGET_KEY_ENUM.GENERAL_ONBOARDING,
    // BASKET COMPARE CUMULATIVE + CHARTS
    WIDGET_KEY_ENUM.BASKET_COMPARE_CUMULATIVE_RETURNS,
    WIDGET_KEY_ENUM.BASKET_COMPARE_PERFORMANCE,
    WIDGET_KEY_ENUM.BASKET_COMPARE_RELATIVE_FACTOR_EXPOSURE,
    // SIGNAL SET CUMULATIVE + CHARTS
    WIDGET_KEY_ENUM.SIGNAL_SET_CUMULATIVE_RETURNS,
    WIDGET_KEY_ENUM.SIGNAL_SET_PERFORMANCE,
    // TIME SERIES + CHARTS
    WIDGET_KEY_ENUM.TIME_SERIES_LINE_COMPARE,
    WIDGET_KEY_ENUM.TIME_SERIES_LINE_COMPARE_DIFF_SCALE,
    WIDGET_KEY_ENUM.TIME_SERIES_COMPARE_PERFORMANCE,
    WIDGET_KEY_ENUM.TIME_SERIES_SCATTER_COMPARE,
    // Static
    WIDGET_KEY_ENUM.GENERAL_LAST_CREATED_UNIVERSES,
    WIDGET_KEY_ENUM.GENERAL_LAST_CREATED_SIGNALS,
    WIDGET_KEY_ENUM.GENERAL_LAST_CREATED_ALPHA_MODELS,
    WIDGET_KEY_ENUM.GENERAL_LAST_CREATED_BACKTESTS,
    WIDGET_KEY_ENUM.GENERAL_LAST_CREATED_PIPELINES,
    WIDGET_KEY_ENUM.GENERAL_LAST_CREATED_DATASOURCES,
    WIDGET_KEY_ENUM.GENERAL_LAST_CREATED_SIGNAL_SETS,
    WIDGET_KEY_ENUM.GENERAL_LAST_CREATED_RISK_MODELS,
    WIDGET_KEY_ENUM.GENERAL_LAST_CREATED_BACKTEST_SETS,
    WIDGET_KEY_ENUM.GENERAL_LAST_CREATED_BASKETS,
    WIDGET_KEY_ENUM.GENERAL_LAST_CREATED_TIME_SERIES,
    WIDGET_KEY_ENUM.GENERAL_LAST_CREATED_STRATEGIES,
    WIDGET_KEY_ENUM.GENERAL_MY_WORKSPACES,
    WIDGET_KEY_ENUM.GENERAL_LAST_OPENED,
    WIDGET_KEY_ENUM.REPORT_SECTION,
    WIDGET_KEY_ENUM.DOWNLOAD_FILES,
  ],

  // UNIVERSE
  [LAYOUT_TYPE.UNIVERSE]: [
    WIDGET_KEY_ENUM.UNIVERSE_TOTAL_COVERAGE,
    WIDGET_KEY_ENUM.UNIVERSE_COVERAGE_BY_COUNTRY,
    WIDGET_KEY_ENUM.UNIVERSE_COVERAGE_BY_CURRENCY,
    WIDGET_KEY_ENUM.UNIVERSE_COVERAGE_BY_ASSET_TYPE,
    WIDGET_KEY_ENUM.UNIVERSE_COVERAGE_BY_ISSUER_COUNTRY,
    WIDGET_KEY_ENUM.COVERAGE_BY_RBICS_LVL_ONE,
    WIDGET_KEY_ENUM.COVERAGE_BY_RBICS_LVL_TWO,
    WIDGET_KEY_ENUM.COVERAGE_BY_RBICS_LVL_THREE,
    WIDGET_KEY_ENUM.COVERAGE_BY_RBICS_LVL_FOUR,
    WIDGET_KEY_ENUM.REPORT_SECTION,
    WIDGET_KEY_ENUM.DOWNLOAD_FILES,
  ],

  // SIGNAL
  [LAYOUT_TYPE.SIGNAL]: [
    WIDGET_KEY_ENUM.SIGNAL_CUMULATIVE_RETURNS,
    WIDGET_KEY_ENUM.SIGNAL_CUMULATIVE_RETURNS_WITH_TIME_SERIES,
    WIDGET_KEY_ENUM.SIGNAL_ANNUALIZED_RETURN,
    WIDGET_KEY_ENUM.SIGNAL_ANNUALIZED_RISK,
    WIDGET_KEY_ENUM.SIGNAL_ANNUALIZED_ADJUSTED,
    WIDGET_KEY_ENUM.SIGNAL_ANNUALIZED_RISK_ADJUSTED_LAGS,
    WIDGET_KEY_ENUM.SIGNAL_CUMULATIVE_RETURNS_LAGS,
    WIDGET_KEY_ENUM.SIGNAL_BUCKETED_CUMULATIVE_RETURNS,
    WIDGET_KEY_ENUM.SIGNAL_ANNUALIZED_BUCKETED_RETURN,
    WIDGET_KEY_ENUM.SIGNAL_ANNUALIZED_BUCKETED_RISK,
    WIDGET_KEY_ENUM.SIGNAL_ANNUALIZED_BUCKETED_ADJUSTED,
    WIDGET_KEY_ENUM.SIGNAL_ANNUALIZED_AVERAGE_BUCKETED_RETURN,
    WIDGET_KEY_ENUM.SIGNAL_ANNUALIZED_AVERAGE_BUCKETED_RISK,
    WIDGET_KEY_ENUM.SIGNAL_ANNUALIZED_AVERAGE_BUCKETED_ADJUSTED,
    WIDGET_KEY_ENUM.SIGNAL_SUMMARY_STATISTICS,
    WIDGET_KEY_ENUM.SIGNAL_NAME_COUNT,
    WIDGET_KEY_ENUM.SIGNAL_COVERAGE_BY_RBICS,
    WIDGET_KEY_ENUM.SIGNAL_DRAWDOWN,
    WIDGET_KEY_ENUM.SIGNAL_DRAWDOWN_BENCHMARK,
    WIDGET_KEY_ENUM.SIGNAL_MAX_DRAWDOWN,
    WIDGET_KEY_ENUM.SIGNAL_TURNOVER,
    WIDGET_KEY_ENUM.SIGNAL_EVENT_STUDY,
    WIDGET_KEY_ENUM.SIGNAL_HISTOGRAM,
    WIDGET_KEY_ENUM.SIGNAL_EXPOSURE,
    WIDGET_KEY_ENUM.SIGNAL_AVERAGE_BUCKETED_EXPOSURE,
    WIDGET_KEY_ENUM.SIGNAL_AVERAGE_BUCKETED_CORRELATION,
    WIDGET_KEY_ENUM.SIGNAL_AVERAGE_BUCKETED_CUMULATIVE_RETURNS,
    WIDGET_KEY_ENUM.SIGNAL_PERFORMANCE_EVENT_STUDY,
    WIDGET_KEY_ENUM.SIGNAL_REGIME,
    WIDGET_KEY_ENUM.SIGNAL_BUCKETED_REGIME,
    WIDGET_KEY_ENUM.SIGNAL_BUCKETED_REGIME_BY_QUANTILES,
    WIDGET_KEY_ENUM.SIGNAL_BUCKETED_REGIME_BY_QUANTILES_CONDITIONAL,
    WIDGET_KEY_ENUM.SIGNAL_DISTRIBUTION_OVER_TIME,
    WIDGET_KEY_ENUM.SIGNAL_BIAS_STATISTICS,
    WIDGET_KEY_ENUM.SIGNAL_PREDICTED_AND_REALIZED_RISK_VS_TIME,
    WIDGET_KEY_ENUM.SIGNAL_RELATIVE_RETURNS,
    WIDGET_KEY_ENUM.SIGNAL_BUCKETED_EVENT_STUDY,
    WIDGET_KEY_ENUM.SIGNAL_EXPOSURE_EVENT_STUDY,
    WIDGET_KEY_ENUM.SIGNAL_EXPOSURE_BUCKETED_EVENT_STUDY,
    WIDGET_KEY_ENUM.REPORT_SECTION,
    WIDGET_KEY_ENUM.DOWNLOAD_FILES,
  ],

  // SIGNAL ATTRIBUTION
  [LAYOUT_TYPE.SIGNAL_PORTAN]: [
    WIDGET_KEY_ENUM.SIGNAL_ATTRIBUTION_RETURNS_DRIVEN,
    WIDGET_KEY_ENUM.SIGNAL_ATTRIBUTION_RETURNS_DRIVEN_TABLE,
    WIDGET_KEY_ENUM.DOWNLOAD_FILES,
  ],

  // ALPHA MODEL
  [LAYOUT_TYPE.ALPHA_MODEL]: [
    WIDGET_KEY_ENUM.SIGNAL_CUMULATIVE_RETURNS,
    WIDGET_KEY_ENUM.SIGNAL_CUMULATIVE_RETURNS_WITH_TIME_SERIES,
    WIDGET_KEY_ENUM.SIGNAL_ANNUALIZED_RETURN,
    WIDGET_KEY_ENUM.SIGNAL_ANNUALIZED_RISK,
    WIDGET_KEY_ENUM.SIGNAL_ANNUALIZED_ADJUSTED,
    WIDGET_KEY_ENUM.SIGNAL_ANNUALIZED_RISK_ADJUSTED_LAGS,
    WIDGET_KEY_ENUM.SIGNAL_CUMULATIVE_RETURNS_LAGS,
    WIDGET_KEY_ENUM.SIGNAL_BUCKETED_CUMULATIVE_RETURNS,
    WIDGET_KEY_ENUM.SIGNAL_ANNUALIZED_BUCKETED_RETURN,
    WIDGET_KEY_ENUM.SIGNAL_ANNUALIZED_BUCKETED_RISK,
    WIDGET_KEY_ENUM.SIGNAL_ANNUALIZED_BUCKETED_ADJUSTED,
    WIDGET_KEY_ENUM.SIGNAL_ANNUALIZED_AVERAGE_BUCKETED_RETURN,
    WIDGET_KEY_ENUM.SIGNAL_ANNUALIZED_AVERAGE_BUCKETED_RISK,
    WIDGET_KEY_ENUM.SIGNAL_ANNUALIZED_AVERAGE_BUCKETED_ADJUSTED,
    WIDGET_KEY_ENUM.SIGNAL_BUCKETED_CUMULATIVE_RETURNS,
    WIDGET_KEY_ENUM.SIGNAL_SUMMARY_STATISTICS,
    WIDGET_KEY_ENUM.SIGNAL_NAME_COUNT,
    WIDGET_KEY_ENUM.SIGNAL_DRAWDOWN,
    WIDGET_KEY_ENUM.SIGNAL_DRAWDOWN_BENCHMARK,
    WIDGET_KEY_ENUM.SIGNAL_MAX_DRAWDOWN,
    WIDGET_KEY_ENUM.SIGNAL_TURNOVER,
    WIDGET_KEY_ENUM.ALPHA_MODEL_LINEAR_STATISTICS_TIME,
    WIDGET_KEY_ENUM.ALPHA_MODEL_OVERALL_STATISTICS_TIME,
    WIDGET_KEY_ENUM.ALPHA_MODEL_LINEAR_PERCENT_SIGNIFICANCE,
    WIDGET_KEY_ENUM.ALPHA_MODEL_LINEAR_TABULAR_SIGNIFICANCE,
    WIDGET_KEY_ENUM.ALPHA_MODEL_LINEAR_SIGNAL_WEIGHTS_TIME,
    WIDGET_KEY_ENUM.ALPHA_MODEL_OPTIMIZED_TABULAR_DATA,
    WIDGET_KEY_ENUM.ALPHA_MODEL_OPTIMIZED_SIGNAL_WEIGHTS_TIME,
    WIDGET_KEY_ENUM.ALPHA_MODEL_OPTIMIZED_TRUE_PREDICTED,
    WIDGET_KEY_ENUM.ALPHA_MODEL_OPTIMIZED_RISK_MODEL_SEARCH,
    WIDGET_KEY_ENUM.SIGNAL_HISTOGRAM,
    WIDGET_KEY_ENUM.SIGNAL_EXPOSURE,
    WIDGET_KEY_ENUM.SIGNAL_REGIME,
    WIDGET_KEY_ENUM.SIGNAL_EVENT_STUDY,
    WIDGET_KEY_ENUM.SIGNAL_BUCKETED_EVENT_STUDY,
    WIDGET_KEY_ENUM.SIGNAL_EXPOSURE_EVENT_STUDY,
    WIDGET_KEY_ENUM.SIGNAL_EXPOSURE_BUCKETED_EVENT_STUDY,
    WIDGET_KEY_ENUM.SIGNAL_PERFORMANCE_EVENT_STUDY,
    WIDGET_KEY_ENUM.SIGNAL_BUCKETED_REGIME,
    WIDGET_KEY_ENUM.SIGNAL_BUCKETED_REGIME_BY_QUANTILES,
    WIDGET_KEY_ENUM.SIGNAL_AVERAGE_BUCKETED_CUMULATIVE_RETURNS,
    WIDGET_KEY_ENUM.SIGNAL_DISTRIBUTION_OVER_TIME,
    WIDGET_KEY_ENUM.REPORT_SECTION,
    WIDGET_KEY_ENUM.DOWNLOAD_FILES,
  ],

  // BASKET
  [LAYOUT_TYPE.BASKET]: [
    WIDGET_KEY_ENUM.BASKET_CUMULATIVE_RETURNS,
    WIDGET_KEY_ENUM.BASKET_CUMULATIVE_RETURNS_WITH_TIME_SERIES,
    WIDGET_KEY_ENUM.BASKET_ANNUALIZED_STATISTICS_RETURN,
    WIDGET_KEY_ENUM.BASKET_ANNUALIZED_STATISTICS_RISK,
    WIDGET_KEY_ENUM.BASKET_ANNUALIZED_STATISTICS_ADJ,
    WIDGET_KEY_ENUM.BASKET_RETURN_TIME,
    WIDGET_KEY_ENUM.BASKET_RISK_TIME,
    WIDGET_KEY_ENUM.BASKET_ADJUSTED_TIME,
    WIDGET_KEY_ENUM.BASKET_BUCKETED_CUMULATIVE_RETURNS,
    WIDGET_KEY_ENUM.BASKET_TABULAR_OUTPUT,
    WIDGET_KEY_ENUM.BASKET_NAME_COUNT,
    WIDGET_KEY_ENUM.BASKET_TURNOVER_TIME,
    WIDGET_KEY_ENUM.BASKET_DRAWDOWN,
    WIDGET_KEY_ENUM.BASKET_MAX_DRAWDOWN,
    WIDGET_KEY_ENUM.BASKET_SUMMARY_STATISTICS,
    WIDGET_KEY_ENUM.BASKET_REGIME,
    WIDGET_KEY_ENUM.BASKET_PERFORMANCE_EVENT_STUDY,
    WIDGET_KEY_ENUM.BASKET_EXPOSURE,
    WIDGET_KEY_ENUM.BASKET_RELATIVE_RETURNS,
    WIDGET_KEY_ENUM.BASKET_TEST,
    WIDGET_KEY_ENUM.REPORT_SECTION,
    WIDGET_KEY_ENUM.DOWNLOAD_FILES,
  ],

  // BASKET ATTRIBUTION
  [LAYOUT_TYPE.BASKET_ATTRIBUTION]: [
    WIDGET_KEY_ENUM.SIGNAL_ATTRIBUTION_RETURNS_DRIVEN,
    WIDGET_KEY_ENUM.SIGNAL_ATTRIBUTION_RETURNS_DRIVEN_TABLE,
    WIDGET_KEY_ENUM.BASKET_RETURNS_DRIVEN_BY_SECTOR,
    WIDGET_KEY_ENUM.PERFORMANCE_ATTRIBUTION_CONTRIBUTORS,
    WIDGET_KEY_ENUM.DOWNLOAD_FILES,
  ],

  // BASKET DETAILED VIEW
  [LAYOUT_TYPE.BASKET_DETAILED_VIEW]: [
    WIDGET_KEY_ENUM.BACKTEST_DETAILED_VIEW_SNAPSHOT,
    WIDGET_KEY_ENUM.BACKTEST_DETAILED_ASSETS_PERTINENCE,
    WIDGET_KEY_ENUM.BACKTEST_PREVIEW_DATE_COMPARISON,
    WIDGET_KEY_ENUM.BACKTEST_DETAILED_VIEW_CONSTRAINTS,
    WIDGET_KEY_ENUM.REPORT_SECTION,
    WIDGET_KEY_ENUM.DOWNLOAD_FILES,
  ],

  // BASKET PORTFOLIO RISK
  [LAYOUT_TYPE.BASKET_PORTFOLIO_RISK]: [
    WIDGET_KEY_ENUM.BASKET_RISK_REPORT,
    WIDGET_KEY_ENUM.BASKET_SUMMARY_RISK_STATS,
    WIDGET_KEY_ENUM.BASKET_COMPARE_PERCENT_CONTRIBUTION_TO_RISK,
    WIDGET_KEY_ENUM.BASKET_RISK_FACTOR_EXPOSURE,
    WIDGET_KEY_ENUM.REPORT_SECTION,
    WIDGET_KEY_ENUM.DOWNLOAD_FILES,
  ],

  // BASKET COMPARE
  [LAYOUT_TYPE.BASKET_COMPARE]: [
    WIDGET_KEY_ENUM.BASKET_COMPARE_CUMULATIVE_RETURNS,
    WIDGET_KEY_ENUM.BASKET_COMPARE_ANNUALIZED_RETURN,
    WIDGET_KEY_ENUM.BASKET_COMPARE_ANNUALIZED_RISK,
    WIDGET_KEY_ENUM.BASKET_COMPARE_ANNUALIZED_ADJUSTED,
    WIDGET_KEY_ENUM.BASKET_COMPARE_PERFORMANCE,
    WIDGET_KEY_ENUM.BASKET_COMPARE_RELATIVE_FACTOR_EXPOSURE,
    WIDGET_KEY_ENUM.BASKET_COMPARE_NAME_COUNT,
    WIDGET_KEY_ENUM.BASKET_COMPARE_DRAWDOWN,
    WIDGET_KEY_ENUM.BASKET_COMPARE_TURNOVER_TIME,
    WIDGET_KEY_ENUM.BASKET_COMPARE_SUMMARY_STATISTICS,
    WIDGET_KEY_ENUM.BASKET_COMPARE_SIGNAL_EXPOSURE,
    WIDGET_KEY_ENUM.BASKET_COMPARE_ANNU_TRACKING_ERROR,
    WIDGET_KEY_ENUM.DOWNLOAD_FILES,
  ],

  // BACKTEST
  [LAYOUT_TYPE.BACKTEST]: [
    WIDGET_KEY_ENUM.BACKTEST_CUMULATIVE_RETURNS,
    WIDGET_KEY_ENUM.BACKTEST_CUMULATIVE_RETURNS_WITH_TIME_SERIES,
    WIDGET_KEY_ENUM.BACKTEST_ANNUALIZED_STATISTICS_RETURN,
    WIDGET_KEY_ENUM.BACKTEST_ANNUALIZED_STATISTICS_RISK,
    WIDGET_KEY_ENUM.BACKTEST_ANNUALIZED_STATISTICS_ADJ,
    WIDGET_KEY_ENUM.BACKTEST_RETURN_TIME,
    WIDGET_KEY_ENUM.BACKTEST_RISK_TIME,
    WIDGET_KEY_ENUM.BACKTEST_ADJUSTED_TIME,
    WIDGET_KEY_ENUM.BACKTEST_BUCKETED_CUMULATIVE_RETURN,
    WIDGET_KEY_ENUM.BACKTEST_TABULAR_OUTPUT,
    WIDGET_KEY_ENUM.BACKTEST_CONSTRAINED_PORTFOLIO_EXPOSURES_OVER_TIME,
    WIDGET_KEY_ENUM.BACKTEST_NAME_COUNT,
    WIDGET_KEY_ENUM.BACKTEST_TURNOVER_TIME,
    WIDGET_KEY_ENUM.BACKTEST_DRAWDOWN,
    WIDGET_KEY_ENUM.BACKTEST_MAX_DRAWDOWN,
    WIDGET_KEY_ENUM.BACKTEST_SUMMARY_STATISTICS,
    WIDGET_KEY_ENUM.BASKET_EXPOSURE,
    WIDGET_KEY_ENUM.BACKTEST_PORTFOLIO_EXPOSURES_OVER_TIME,
    WIDGET_KEY_ENUM.BACKTEST_SIGNAL_EXPOSURES_OVER_TIME,
    WIDGET_KEY_ENUM.BACKTEST_TEST,
    WIDGET_KEY_ENUM.REPORT_SECTION,
    WIDGET_KEY_ENUM.DOWNLOAD_FILES,
  ],

  // BACKTEST DETAILED VIEW
  [LAYOUT_TYPE.BACKTEST_DETAILED_VIEW]: [
    WIDGET_KEY_ENUM.BACKTEST_DETAILED_VIEW_SNAPSHOT,
    WIDGET_KEY_ENUM.BACKTEST_DETAILED_ASSETS_PERTINENCE,
    WIDGET_KEY_ENUM.BACKTEST_PREVIEW_DATE_COMPARISON,
    WIDGET_KEY_ENUM.BACKTEST_DETAILED_VIEW_CONSTRAINTS,
    WIDGET_KEY_ENUM.BACKTEST_DETAILED_VIEW_ALPHA_EXPOSURES,
    WIDGET_KEY_ENUM.REPORT_SECTION,
    WIDGET_KEY_ENUM.DOWNLOAD_FILES,
  ],

  // BACKTEST PORTFOLIO RISK
  [LAYOUT_TYPE.BACKTEST_PORTFOLIO_RISK]: [
    WIDGET_KEY_ENUM.BACKTEST_RISK_REPORT,
    WIDGET_KEY_ENUM.BACKTEST_SUMMARY_RISK_STATS,
    WIDGET_KEY_ENUM.BACKTEST_COMPARE_PERCENT_CONTRIBUTION_TO_RISK,
    WIDGET_KEY_ENUM.BACKTEST_RISK_FACTOR_EXPOSURE,
    WIDGET_KEY_ENUM.BACKTEST_BIAS_STATISTIC,
    WIDGET_KEY_ENUM.BACKTEST_PREDICTED_AND_REALIZED_RISK_VS_TIME,
    WIDGET_KEY_ENUM.REPORT_SECTION,
    WIDGET_KEY_ENUM.DOWNLOAD_FILES,
  ],

  // BACKTEST ATTRIBUTION
  [LAYOUT_TYPE.BACKTEST_PORTAN]: [
    WIDGET_KEY_ENUM.BACKTEST_ATTRIBUTION_HOLDINGS,
    WIDGET_KEY_ENUM.BACKTEST_ATTRIBUTION_RETURNS,
    WIDGET_KEY_ENUM.BACKTEST_ATTRIBUTION_RISK_DECOMPOSITION,
    WIDGET_KEY_ENUM.BACKTEST_ATTRIBUTION_HOLDINGS_TABLE,
    WIDGET_KEY_ENUM.BACKTEST_ATTRIBUTION_RETURNS_TABLE,
    WIDGET_KEY_ENUM.BACKTEST_ATTRIBUTION_RISK_DECOMPOSITION_TABLE,
    WIDGET_KEY_ENUM.PERFORMANCE_ATTRIBUTION_CONTRIBUTORS,
    WIDGET_KEY_ENUM.BACKTEST_BUCKET_ANALYSIS,
    WIDGET_KEY_ENUM.DOWNLOAD_FILES,
  ],

  // RISK MODEL
  [LAYOUT_TYPE.RISK_MODEL]: [
    WIDGET_KEY_ENUM.RISK_MODEL_COVERAGE,
    WIDGET_KEY_ENUM.RISK_MODEL_VOLATILITY_VS_TIME,
    WIDGET_KEY_ENUM.RISK_MODEL_MEDIAN_ANNU_VOLATILITY_VS_TIME,
    WIDGET_KEY_ENUM.RISK_MODEL_PERCENT_SIGNIFICANCE,
    WIDGET_KEY_ENUM.RISK_MODEL_PREDICTED_REALIZED_RISK_TIME,
    WIDGET_KEY_ENUM.RISK_MODEL_BIAS_STATISTIC,
    WIDGET_KEY_ENUM.RISK_MODEL_CROSS_SECTIONAL_CORRELATION,
    WIDGET_KEY_ENUM.RISK_MODEL_INDUSTRY_COVERAGE,
    WIDGET_KEY_ENUM.REPORT_SECTION,
    WIDGET_KEY_ENUM.DOWNLOAD_FILES,
  ],

  // RISK MODEL PREVIEW
  [LAYOUT_TYPE.RISK_MODEL_PREVIEW]: [
    WIDGET_KEY_ENUM.RISK_MODEL_PREVIEW_SNAPSHOT,
    WIDGET_KEY_ENUM.RISK_MODEL_PREVIEW_COV_MATRIX,
    WIDGET_KEY_ENUM.RISK_MODEL_PREVIEW_UNIVERSE_COVERAGE,
    WIDGET_KEY_ENUM.RISK_MODEL_PREVIEW_ASSET_PERTINENCE,
    WIDGET_KEY_ENUM.REPORT_SECTION,
    WIDGET_KEY_ENUM.DOWNLOAD_FILES,
  ],

  // SIGNAL SET
  [LAYOUT_TYPE.SIGNAL_SET]: [
    WIDGET_KEY_ENUM.SIGNAL_SET_CUMULATIVE_RETURNS,
    WIDGET_KEY_ENUM.SIGNAL_SET_CUMULATIVE_RETURNS_WITH_BENCHMARK,
    WIDGET_KEY_ENUM.SIGNAL_SET_CUMULATIVE_RETURNS_WITH_TIME_SERIES,
    WIDGET_KEY_ENUM.SIGNAL_SET_RELATIVE_RETURNS,
    WIDGET_KEY_ENUM.REPORT_SECTION,
    WIDGET_KEY_ENUM.DOWNLOAD_FILES,
  ],

  // SIGNAL SET REPORT
  [LAYOUT_TYPE.SIGNAL_SET_REPORT]: [
    WIDGET_KEY_ENUM.SIGNAL_SET_RETURNS_REPORT,
    WIDGET_KEY_ENUM.SIGNAL_SET_PERFORMANCE,
    WIDGET_KEY_ENUM.REPORT_SECTION,
    WIDGET_KEY_ENUM.DOWNLOAD_FILES,
  ],

  [LAYOUT_TYPE.SIGNAL_PREVIEW]: [
    WIDGET_KEY_ENUM.SIGNAL_PREVIEW_ENTIRE_UNIVERSE,
    WIDGET_KEY_ENUM.SIGNAL_PREVIEW_SELECTED_ASSETS,
    WIDGET_KEY_ENUM.SIGNAL_PREVIEW_DATE_COMPARISON,
    WIDGET_KEY_ENUM.OPTIMIZED_RETURNS_WEIGHTS_COV,
    WIDGET_KEY_ENUM.SIGNAL_PREVIEW_DOWNLOAD,
  ],

  // BACKTEST SET
  [LAYOUT_TYPE.BACKTEST_SET]: [],

  // DATA SOURCE
  [LAYOUT_TYPE.DATA_SOURCE]: [],

  // TIME SERIES
  [LAYOUT_TYPE.TIME_SERIES]: [
    WIDGET_KEY_ENUM.TIME_SERIES_PLOT,
    WIDGET_KEY_ENUM.TIME_SERIES_HISTOGRAM,
    WIDGET_KEY_ENUM.TIME_SERIES_SCATTER_COMPARE,
    WIDGET_KEY_ENUM.TIME_SERIES_LINE_COMPARE,
    WIDGET_KEY_ENUM.TIME_SERIES_LINE_COMPARE_DIFF_SCALE,
    WIDGET_KEY_ENUM.TIME_SERIES_COMPARE_PERFORMANCE,
    WIDGET_KEY_ENUM.TIME_SERIES_REGIME,
    WIDGET_KEY_ENUM.REPORT_SECTION,
    WIDGET_KEY_ENUM.DOWNLOAD_FILES,
  ],

  // STRATEGIES
  [LAYOUT_TYPE.STRATEGY]: [],

  // PORTFOLIOS ATTRIBUTION
  [LAYOUT_TYPE.PORTFOLIO_ATTRIBUTION]: [
    WIDGET_KEY_ENUM.PORTFOLIO_ATTRIBUTION_HOLDINGS,
    WIDGET_KEY_ENUM.PORTFOLIO_ATTRIBUTION_RETURNS,
    WIDGET_KEY_ENUM.PORTFOLIO_ATTRIBUTION_RISK_DECOMPOSITION,
    WIDGET_KEY_ENUM.PORTFOLIO_ATTRIBUTION_HOLDINGS_TABLE,
    WIDGET_KEY_ENUM.PORTFOLIO_ATTRIBUTION_RETURNS_TABLE,
    WIDGET_KEY_ENUM.PORTFOLIO_ATTRIBUTION_RISK_DECOMPOSITION_TABLE,
    WIDGET_KEY_ENUM.PORTFOLIO_PERFORMANCE_ATTRIBUTION_CONTRIBUTORS,
    WIDGET_KEY_ENUM.BACKTEST_BUCKET_ANALYSIS,
    WIDGET_KEY_ENUM.DOWNLOAD_FILES,
  ],

  // PORTFOLIOS PERFORMANCE
  [LAYOUT_TYPE.PORTFOLIO_PERFORMANCE]: [WIDGET_KEY_ENUM.PORTFOLIO_PERFORMANCE_TABLE, WIDGET_KEY_ENUM.DOWNLOAD_FILES],

  // PORTFOLIOS RISK
  [LAYOUT_TYPE.REBALANCE_PORTFOLIO_RISK]: [WIDGET_KEY_ENUM.PORTFOLIO_RISK_SUMMARY, WIDGET_KEY_ENUM.DOWNLOAD_FILES],

  // PORTFOLIOS TRADE LIST
  [LAYOUT_TYPE.REBALANCE_PORTFOLIO_TRADELIST]: [
    WIDGET_KEY_ENUM.PORTFOLIO_TRADE_LIST_SUMMARY,
    WIDGET_KEY_ENUM.PORTFOLIO_TRADE_LIST_FACTOR_EXPOSURE_SUMMARY,
    WIDGET_KEY_ENUM.PORTFOLIO_TRADE_LIST_BUCKET_EXPOSURE_SUMMARY,
    WIDGET_KEY_ENUM.PORTFOLIO_TRADE_LIST,
    WIDGET_KEY_ENUM.PORTFOLIO_TRADE_LIST_HOLDINGS,
    WIDGET_KEY_ENUM.REPORT_SECTION,
    WIDGET_KEY_ENUM.DOWNLOAD_FILES,
  ],

  // PORTFOLIOS HOLDINGS
  [LAYOUT_TYPE.REBALANCE_PORTFOLIO_HOLDINGS]: [
    WIDGET_KEY_ENUM.PORTFOLIO_SUMMARY,
    WIDGET_KEY_ENUM.PORTFOLIO_FACTOR_EXPOSURE_SUMMARY,
    WIDGET_KEY_ENUM.PORTFOLIO_SUMMARY_BUCKET_EXPOSURE,
    WIDGET_KEY_ENUM.PORTFOLIO_HOLDINGS,
    WIDGET_KEY_ENUM.REPORT_SECTION,
    WIDGET_KEY_ENUM.DOWNLOAD_FILES,
  ],

  // PORTFOLIOS HOLDINGS
  [LAYOUT_TYPE.PORTFOLIO_HOLDINGS_ANALYSIS]: [
    WIDGET_KEY_ENUM.PORTFOLIO_SUMMARY,
    WIDGET_KEY_ENUM.PORTFOLIO_FACTOR_EXPOSURE_SUMMARY,
    WIDGET_KEY_ENUM.PORTFOLIO_SUMMARY_BUCKET_EXPOSURE,
    WIDGET_KEY_ENUM.PORTFOLIO_HOLDINGS,
    WIDGET_KEY_ENUM.REPORT_SECTION,
    WIDGET_KEY_ENUM.DOWNLOAD_FILES,
  ],

  [LAYOUT_TYPE.PORTFOLIO_HOLDINGS_RISK]: [
    WIDGET_KEY_ENUM.PORTFOLIO_HOLDINGS_RISK_SUMMARY,
    WIDGET_KEY_ENUM.REPORT_SECTION,
    WIDGET_KEY_ENUM.DOWNLOAD_FILES,
  ],

  // PORTFOLIO Risk
  [LAYOUT_TYPE.PORTFOLIO_RISK]: [
    WIDGET_KEY_ENUM.PORTFOLIO_SUMMARY_RISK_STATS,
    WIDGET_KEY_ENUM.PORTFOLIO_RISK_FACTOR_EXPOSURE,
    WIDGET_KEY_ENUM.PORTFOLIO_PERCENT_CONTRIBUTION_TO_RISK,
    WIDGET_KEY_ENUM.PORTFOLIO_PREDICTED_AND_REALIZED_RISK_VS_TIME,
    WIDGET_KEY_ENUM.PORTFOLIO_BIAS_STATISTICS,
    WIDGET_KEY_ENUM.REPORT_SECTION,
    WIDGET_KEY_ENUM.DOWNLOAD_FILES,
  ],
};

export default widgetsOrder;
