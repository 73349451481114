import React from 'react';

import { useUiStyles } from '../hooks';
import { mui } from '../libs';

type InfoRowProps = {
  title: string;
  children: string | React.ReactElement | number | string[];
  loading?: boolean;
};

/**
 * InfoRow component that displays a title and a child component.
 *
 * @param title - The title to be displayed above the child component.
 * @param children - The child components to be rendered within the InfoRow.
 * @param loading - Optional flag to indicate if the component is loading.
 *
 * @returns A React component that displays a title and a child component.
 */

const InfoRow: React.FC<InfoRowProps> = ({ title, children, loading }): React.ReactElement => {
  const uiStyles = useUiStyles();

  return (
    <mui.core.Stack gap={0.5}>
      <mui.core.Typography
        variant="overline"
        color="textSecondary"
        fontWeight="fontWeightMedium"
        sx={{
          fontSize: '9px',
          lineHeight: '130%',
        }}
      >
        {title}
      </mui.core.Typography>
      {loading ? (
        <mui.core.Chip
          size="small"
          label="Loading..."
          sx={{ fontSize: '9px', padding: '0', height: '14px!important' }}
          icon={<mui.core.CircularProgress size={8} />}
          color="primary"
        />
      ) : children === 'undefined' ? (
        <mui.core.Chip
          size="small"
          label="Not available"
          sx={{ fontSize: '9px', padding: '0', height: '14px!important' }}
        />
      ) : (
        <mui.core.Typography variant="caption" fontWeight="fontWeightMedium" sx={uiStyles.ellipsis}>
          {children}
        </mui.core.Typography>
      )}
    </mui.core.Stack>
  );
};

export default InfoRow;
