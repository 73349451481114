import { _, ts } from '_core';

import { getFieldKeys, getFieldKeysFromDef } from 'views/backtest-sets/backtest-set-utils/definition';

import { DraftParams } from './types';

const percentColumns = [
  'cumulative_after_costs_portfolio_return',
  'annualized_before_costs_portfolio_return',
  'cumulative_before_costs_portfolio_return',
];

export const getFormOptions = (
  context: ts.types.backtestSet.BacktestSetAnalysisContext,
  resources: ts.StoreState['resources']
) => {
  const backtest = resources.backtests.find((b) => b.id == context?.definition?.backtest_id);

  if (backtest && context?.definition?.parameters) {
    return getFieldKeysFromDef(context.definition, backtest.definition);
  }
  return [];
};

const transform = (data: ts.types.widgets.ScatterData, metric: string) =>
  data.map((el) => {
    if (percentColumns.includes(metric)) {
      return {
        ...el,
        [`${metric}_percent`]: el[metric] * 100,
      };
    }
    return { ...el };
  });

const getName = (
  params: DraftParams,
  context: ts.types.backtestSet.BacktestSetAnalysisContext,
  resources: ts.StoreState['resources']
) => {
  const opts = getFormOptions(context, resources);
  const fieldName = opts.find((o) => o.key == params?.selectedParam)?.label ?? _.startCase(params?.selectedParam);
  return `${_.startCase(params?.metric)} vs. ${fieldName}`;
};

const validate = (
  params: DraftParams,
  context: ts.types.backtestSet.BacktestSetAnalysisContext,
  _g: any,
  resources: ts.StoreState['resources']
) => {
  let fieldKeys = getFieldKeys().map((f) => f.key);

  // If we already have a context we need to validate on that context
  // available fileds
  if (context) fieldKeys = getFormOptions(context, resources).map((f) => f.key);

  const errors = [];
  if (_.isEmpty(params.selectedParam) || !fieldKeys.includes(params.selectedParam ?? ''))
    errors.push('You need to select a valid Parameter');
  if (_.isEmpty(params.metric)) errors.push('You need to select a Metric');
  return errors;
};

export { transform };
export { percentColumns };
export { getName };
export { validate };
