import { _, api, helpers, mui, React, ts, ui, useSelector } from '_core';

import { Alert } from '@mui/material';

type PinSearchFormProps = {
  open: boolean;
  setOpen: (_b: boolean) => void;
  workspace: ts.types.workspace.Workspace;
  searchQuery: string;
  groupBy?: string[];
  setSearchOpen?: (_b: boolean) => void;
};

const PinSearchForm: React.FC<PinSearchFormProps> = ({
  open,
  setOpen,
  workspace = null,
  searchQuery,
  groupBy = ['type'],
  setSearchOpen,
}): React.ReactElement => {
  const [errorMessage, setErrorMessage] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [name, setName] = React.useState('');
  const [selOption, setSelOption] = React.useState<'expand' | 'pinned'>('expand');
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);

  const handleSnackbarClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const resources = useSelector((state) => state.resources);

  const handleSubmit = async () => {
    setErrorMessage('');
    setLoading(true);

    let workspace_update = {} as ts.types.workspace.Workspace;

    if (selOption === 'expand') {
      const found = helpers.resourcesSearch.search(searchQuery, resources, workspace.context, undefined, true);

      found.forEach((r) => {
        const resourceType = r.resourceType as ts.enums.RESOURCES_TYPES_ENUM;
        const idx = `${_.snakeCase(resourceType)}_ids` as keyof ts.types.workspace.Workspace;

        (workspace_update[idx] as number[]) = _.uniq(
          (workspace[idx] as number[]).concat((workspace_update[idx] as number[]) ?? []).concat([r.id])
        );
      });
    } else {
      // Validation
      if (!name) {
        setErrorMessage('All attributes are required.');
        setLoading(false);
        return;
      }

      const workspace_metadata = {
        ...workspace.workspace_metadata,
        pinned_searches: [
          ...(workspace.workspace_metadata?.pinned_searches || []),
          {
            id: helpers.gibberishGenerator.stringGenerator(11),
            name,
            query: searchQuery,
            groupBy,
          },
        ],
      };

      workspace_update = {
        ...workspace_update,
        workspace_metadata,
      };
    }

    try {
      await api.workspaces.update(workspace.id, workspace_update);
      setOpen(false);
      setLoading(false);
      setName('');
      setSearchOpen && setSearchOpen(false);
      setSnackbarOpen(true);
    } catch (e) {
      setErrorMessage(helpers.parseApiError(e as ts.types.common.ApiError));
      setLoading(false);
      setName(name);
    }
  };

  return (
    <>
      <mui.core.Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
        fullWidth
        sx={{ zIndex: '10000' }}
      >
        {errorMessage && (
          <mui.lab.Alert onClose={() => setErrorMessage('')} severity="error">
            {errorMessage}
          </mui.lab.Alert>
        )}
        <ui.DialogTitle closeAction={() => setOpen(false)}>How are we saving to the workspace?</ui.DialogTitle>
        <mui.core.DialogContent>
          <mui.core.Box>
            <mui.core.FormControl component="fieldset">
              <mui.core.RadioGroup
                row
                value={selOption}
                onChange={(e) => setSelOption(e.target.value as typeof selOption)}
              >
                <mui.core.FormControlLabel value="expand" control={<mui.core.Radio />} label="Add all resources" />
                <mui.core.FormControlLabel value="pinned" control={<mui.core.Radio />} label="Pin search" />
              </mui.core.RadioGroup>
            </mui.core.FormControl>
          </mui.core.Box>
          {selOption === 'pinned' && (
            <mui.core.Box mt={5}>
              <mui.core.TextField
                autoFocus
                id="name"
                label="Search name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                variant="outlined"
                size="small"
                fullWidth
                required
                inputProps={{
                  autocomplete: 'off',
                }}
              />
            </mui.core.Box>
          )}
        </mui.core.DialogContent>
        <mui.core.DialogActions>
          <ui.Button
            variant="contained"
            color="primary"
            loading={!!loading}
            onClick={handleSubmit}
            style={{ margin: '0rem 1rem 1rem' }}
            fullWidth
          >
            Save this search
          </ui.Button>
        </mui.core.DialogActions>
      </mui.core.Dialog>
      <mui.core.Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert severity="success" variant="filled">
          Search results added to workspace
        </Alert>
      </mui.core.Snackbar>
    </>
  );
};

export default PinSearchForm;
