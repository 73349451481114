import { mui, React, ts, ui } from '_core';

import { useUiStyles } from '@local/finsera-core/src/hooks';

import { DraftParams } from './types';

const BUCKET_TYPES_ENUM = ts.enums.BUCKET_TYPES_ENUM;

type FormProps = {
  params: DraftParams;
  setParams: (_p: DraftParams) => void;
};

const Form: React.FC<FormProps> = ({ params, setParams }) => {
  const uiStyles = useUiStyles();
  const theme = mui.styles.useTheme();

  const [buckets, setBuckets] = React.useState(params.buckets || []);

  React.useEffect(() => {
    const data = {
      ...params,
      buckets,
      expanded: undefined,
      sortBy: undefined,
    } as DraftParams;
    setParams(data);
  }, [buckets]);

  const addRow = () => {
    setBuckets([...buckets, {}]);
  };

  const updateRow = (idx: number, updated: ts.types.widgets.common.BucketValue) => {
    setBuckets(
      buckets.map((b, bidx) => {
        if (bidx == idx) return updated;
        return b;
      })
    );
  };

  const removeRow = (idx: number) => {
    setBuckets(buckets.filter((_b, bidx) => bidx !== idx));
  };

  const bucketOptions = [
    {
      value: BUCKET_TYPES_ENUM.SIGNAL,
      title: 'Signal',
    },
    {
      value: BUCKET_TYPES_ENUM.INDUSTRY,
      title: 'Industry',
    },
    {
      value: BUCKET_TYPES_ENUM.RISK_MODEL,
      title: 'Risk Model Category',
    },
    {
      value: BUCKET_TYPES_ENUM.RISK_MODEL_FACTORS,
      title: 'Risk Model Style Factors',
    },
    {
      value: BUCKET_TYPES_ENUM.ASSET_MASTER,
      title: 'Asset Master',
    },
  ];

  const assetsOptions = ['quotation_country', 'issuer_country', 'currency'];

  return (
    <mui.core.Box sx={uiStyles.widgetEditMode}>
      {buckets.map((b, idx) => (
        <mui.core.Box key={idx} pt={3}>
          <mui.core.Grid
            container
            spacing={4}
            alignItems="center"
            sx={{
              [theme.breakpoints.up('md')]: {
                flexWrap: 'nowrap',
              },
              [theme.breakpoints.down('md')]: {
                borderBottom: `1px solid ${theme.palette.gray.main}`,
                marginBottom: '0.5rem',
                paddingBottom: '0.5rem',
              },
            }}
          >
            <mui.core.Grid item xs={11}>
              <ui.Buckets
                value={b}
                setValue={(updated) => updateRow(idx, updated)}
                options={bucketOptions}
                assetMasterOptions={assetsOptions}
                riskModelCategoryOnly
                skipWorkspaceCheck
              />
            </mui.core.Grid>

            <mui.core.Grid item xs={1}>
              <mui.core.IconButton onClick={() => removeRow(idx)} size="small">
                <mui.icons.Close />
              </mui.core.IconButton>
            </mui.core.Grid>
          </mui.core.Grid>
        </mui.core.Box>
      ))}

      <mui.core.Box pt={2}>
        <ui.Button onClick={addRow} variant="outlined" color="primary">
          <mui.icons.Add style={{ fontSize: '0.75rem', marginRight: '4px' }} />
          Add bucket
        </ui.Button>
      </mui.core.Box>
    </mui.core.Box>
  );
};

export default Form;
