import am4themesMaterial from '@amcharts/amcharts4/themes/material';

import * as am4charts from './am4charts';
import * as am4core from './am4core';

// Amcharts configuration
am4core.useTheme(am4themesMaterial);
am4core.options.onlyShowOnViewport = false;
am4core.options.queue = true;
am4core.options.autoDispose = true;
am4core.options.suppressWarnings = true;
am4core.options.animationsEnabled = false;

am4charts.ValueAxis.prototype.getSeriesDataItem = function (series, position) {
  const key = this.axisFieldName + this.axisLetter;
  const value = this.positionToValue(position);
  return series.dataItems.getIndex(
    series.dataItems.findClosestIndex(
      value,
      function (x: Record<string, any>) {
        return x[key];
      },
      'any'
    )
  );
};

export { am4charts };
export { am4core };
