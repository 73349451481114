/* eslint-disable no-unused-vars */
import { ts } from '_core';

export enum SHEETS_ENUM {
  CUM_RET = 'CUMULATIVE_RETURNS',
  RET = 'RETURN_TYPE',
  RISK = 'RISK_TYPE',
  ADJ = 'ADJUSTED_TYPE',
}

export const sheetTitles = {
  [SHEETS_ENUM.CUM_RET]: 'Cumulative Returns',
  [SHEETS_ENUM.RET]: 'Annualized Returns',
  [SHEETS_ENUM.RISK]: 'Annualized Risk',
  [SHEETS_ENUM.ADJ]: 'Annualized Risk Adjusted Returns',
};

export type SHEETS = 'CUMULATIVE_RETURNS' | 'RISK_TYPE' | 'ADJUSTED_TYPE' | 'RETURN_TYPE';

export type JobParams = {
  signal_set_id: number;
  histories: ts.enums.HISTORY_ENUM_EXTENDED[];
  return_horizons: ts.types.returnHorizon.ReturnHorizon[];
  daily_reporting: boolean;
  set_params?: {
    id: 'summary';
    file: 'sp_returns';
    file_params: {
      return_horizons: ts.types.returnHorizon.ReturnHorizon[];
      agg_frequency: ts.enums.FREQUENCY_ENUM.DAILY | undefined;
    };
  }[];
};

export enum INTERACTION_PARAMS {
  TABLE_PARAMS = 'table_params',
}

export type InteractionParams = {
  [INTERACTION_PARAMS.TABLE_PARAMS]: ts.types.components.dataGrid.TableParamsSheet;
};

export type Params = JobParams & InteractionParams & { fullWidth: boolean };
export type DraftParams = Partial<Params>;
