import { _, hooks, mui, React, ts } from '_core';

import { DraftParams, Params } from './types';
import ChartWrapper from '../../common/chart-wrapper';
import createTreeMapChart from '../../common/charts/create-tree-map-chart';
import { checkForErrors, formatPortanData, getOrderedHistories } from '../../common/utils/attribution-utils';

type ChartProps = {
  id: string;
  readOnly: boolean;
  widgetKey: string;
  params: Params | DraftParams;
  file: string;
  fullScreen: boolean;
  setParams: (_p: DraftParams) => void;
  loadWidgetData: (_payload: ts.types.widgets.WidgetGetDataParams) => Promise<ts.types.widgets.WidgetGetDataResponse>;
};

const Chart: React.FC<ChartProps> = ({
  id,
  readOnly,
  widgetKey,
  loadWidgetData,
  file,
  fullScreen,
  params,
  setParams,
}): React.ReactElement => {
  const HISTORY_ENUM_EXTENDED = ts.enums.HISTORY_ENUM_EXTENDED;

  const histories = React.useMemo(() => {
    return getOrderedHistories(
      params?.histories || [
        HISTORY_ENUM_EXTENDED.ONE,
        HISTORY_ENUM_EXTENDED.THREE,
        HISTORY_ENUM_EXTENDED.FIVE,
        HISTORY_ENUM_EXTENDED.FULL,
        HISTORY_ENUM_EXTENDED.YTD,
      ]
    );
  }, [params?.histories]);

  const [data, setData] = React.useState<{ data: ts.types.widgets.TreeMapData }>(null);
  const [error, setError] = React.useState<ts.types.common.ApiError>(null);

  const [selectedHistory, setSelectedHistory] = React.useState(params.selectedHistory || histories[0]);

  const loadData = async () => {
    const statisticsType = params.statistics_type || ts.enums.REPORT_ENUMS.ANNU_STATISTICS_ENUM.RETURNS;
    const buckets = ['INDUSTRY:rbics_l1_name', 'ASSET_MASTER:name'];

    try {
      const response = await loadWidgetData({
        data: {
          file,
          query: [
            '$and',
            ['order', 'equals', `['${buckets.join("', '")}']`],
            ['statistics_type', 'equals', statisticsType],
            ['$or', ['history', 'equals', selectedHistory], ['history', 'equals', ts.enums.HISTORY_ENUM_EXTENDED.FULL]],
          ],
          rename: { build_date: 'date' },
          columns: [...buckets, 'holdings', 'returns', 'history'],
        },
      });
      const error = checkForErrors(response.data, 'attributed_returns');
      if (!error) {
        const responseData = formatPortanData(response.data, buckets, selectedHistory, statisticsType);
        setData({ data: responseData });
      } else {
        setError({ responseJSON: { detail: error } });
      }
    } catch (err) {
      setError(err as ts.types.common.ApiError);
    }
  };

  React.useEffect(() => {
    if (file) loadData();
    return () => {
      setError(null);
      setData(null);
    };
  }, [file, selectedHistory]);

  const debouncedSaveSelectedHistory = React.useCallback(
    _.debounce((history) => {
      setParams({
        ...params,
        selectedHistory: history,
      });
    }, 2000),
    []
  );

  hooks.useEffectWithoutFirst(() => {
    debouncedSaveSelectedHistory(selectedHistory);
  }, [selectedHistory]);

  const homeText = 'Returns Decomposition By Sector';

  React.useEffect(() => {
    // create chart
    if (!_.isNil(data)) {
      const numberFormat = '#.##%';

      createTreeMapChart({
        id,
        data: data?.data,
        fullScreen,
        homeText: homeText,
        selectedBuckets: ['INDUSTRY:rbics_l1_name', 'ASSET_MASTER:name'],
        numberFormat: numberFormat,
      });
    }
  }, [data]);

  return (
    <mui.core.Box display="flex" flexDirection="column" style={{ height: '100%' }}>
      <mui.core.Box px={4}>
        <mui.core.Grid
          container
          spacing={2}
          style={{
            marginTop: '12px',
          }}
        >
          <mui.core.Grid item xs={12} sm={2}>
            <mui.core.TextField
              select
              label="Selected History"
              variant="outlined"
              size="small"
              value={selectedHistory}
              onChange={(e) => setSelectedHistory(e.target.value as ts.enums.HISTORY_ENUM_EXTENDED)}
              fullWidth
            >
              {histories.map((h) => (
                <mui.core.MenuItem key={h} value={h}>
                  {h}
                </mui.core.MenuItem>
              ))}
            </mui.core.TextField>
          </mui.core.Grid>
        </mui.core.Grid>
      </mui.core.Box>
      <mui.core.Box p={3} flex="1">
        <ChartWrapper
          id={id}
          readOnly={readOnly}
          widgetKey={widgetKey}
          data={data}
          error={error}
          fullScreen={fullScreen}
        />
      </mui.core.Box>
    </mui.core.Box>
  );
};

export default Chart;
