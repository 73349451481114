import { _, mui, React, ts } from '_core';

import { transform } from './helper';
import ChartWrapper from '../../common/chart-wrapper';
import createLineChart from '../../common/charts/create-line-chart';

type ChartProps = {
  id: string;
  readOnly: boolean;
  widgetKey: string;
  loadWidgetData: (_payload: ts.types.widgets.WidgetGetDataParams) => Promise<ts.types.widgets.WidgetGetDataResponse>;
  file: string;
  fullScreen: boolean;
  title: string;
};

const Chart: React.FC<ChartProps> = ({ id, readOnly, widgetKey, loadWidgetData, file, fullScreen, title }) => {
  const [data, setData] = React.useState<ts.types.widgets.TableData>(null);
  const [error, setError] = React.useState<ts.types.common.ApiError>(null);
  const [showLegend, setShowLegend] = React.useState(false);
  const [selectedTab, setSelectedTab] = React.useState<'INDUSTRY' | 'OTHERS'>('OTHERS');

  const loadData = async (tab: 'INDUSTRY' | 'OTHERS') => {
    try {
      const response = await loadWidgetData({
        data: {
          file,
          query: ['tab', 'equals', tab],
          rename: { build_date: 'date' },
        },
      });
      if (response) {
        setData(response.data);
      }
    } catch (err) {
      setError(err as ts.types.common.ApiError);
    }
  };

  React.useEffect(() => {
    if (file) loadData(selectedTab);
    return () => setData(null);
  }, [file, selectedTab]);

  React.useEffect(() => {
    if (!_.isNil(data))
      createLineChart({
        id,
        data,
        fullScreen,
        setShowLegend,
        exportTitle: title,
        prepareData: (linearData) => transform(linearData),
      });
  }, [data]);

  const getTabs = () => {
    return (
      <mui.core.Tabs
        indicatorColor="primary"
        textColor="primary"
        value={selectedTab}
        onChange={(_e, newTab) => setSelectedTab(newTab)}
      >
        <mui.core.Tab label="Style Factors" value="OTHERS" />
        <mui.core.Tab label="Industries" value="INDUSTRY" />
      </mui.core.Tabs>
    );
  };

  return (
    <ChartWrapper
      id={id}
      readOnly={readOnly}
      widgetKey={widgetKey}
      data={data}
      showLegend={showLegend}
      error={error}
      tabs={getTabs()}
      fullScreen={fullScreen}
    />
  );
};

export default Chart;
