import React from 'react';

import { mui } from '../libs';

// eslint-disable-next-line max-len
type PossibleVariant =
  | 'button'
  | 'caption'
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'inherit'
  | 'overline'
  | 'subtitle1'
  | 'subtitle2'
  | 'body1'
  | 'body2';

type SectionTitleProps = {
  children: React.ReactNode;
  sx?: Record<string, React.CSSProperties>;
  action?: React.ReactElement;
  hideBorder?: boolean;
  subtitle?: React.ReactElement | string;
  variant?: PossibleVariant;
  onClick?: () => void;
  error?: boolean;
};

/**
 * Component that renders a section title with optional subtitle and action
 * 
 * @param children - Content to display within the title
 * @param sx - Custom styles to apply to the component
 * @param action - Optional action element to display alongside the title
 * @param hideBorder - Flag to hide the bottom border
 * @param subtitle - Optional subtitle content
 * @param variant - Variant of the title (e.g., h1, h2, etc.)
 * @param onClick - Optional click handler for the title
 * @param error - Flag to indicate an error state
 */

const SectionTitle: React.FC<SectionTitleProps> = ({
  children,
  variant,
  action,
  subtitle,
  hideBorder,
  onClick,
  error,
}): React.ReactElement => {
  const theme = mui.styles.useTheme() as mui.core.Theme;

  return (
    <mui.core.Box
      sx={
        hideBorder
          ? {}
          : {
              borderBottom: `1px solid ${(theme.palette as any).gray.main}`,
            }
      }
      mb={hideBorder ? 0 : 6}
      px={0}
      pb={3}
      display={action ? 'flex' : 'block'}
      alignItems="center"
    >
      <mui.core.Box sx={action ? { flex: '1' } : {}} display="flex" alignItems="center">
        <mui.core.Typography fontWeight="fontWeightMedium" variant={variant} onClick={onClick}>
          {children}
        </mui.core.Typography>
        {subtitle && (
          <mui.core.Typography
            color={error ? (theme.palette as any).error.light : (theme.palette as any).text.secondary}
            sx={{ marginLeft: theme.spacing(1) }}
            variant="body2"
          >
            {subtitle}
          </mui.core.Typography>
        )}
      </mui.core.Box>
      {action}
    </mui.core.Box>
  );
};

SectionTitle.defaultProps = {
  variant: 'body1',
};

export default SectionTitle;
