/* eslint-disable no-unused-vars */

export enum INTERACTION_PARAMS {
  IS_BASKET = 'isBasket',
}

export type InteractionParams = {
  [INTERACTION_PARAMS.IS_BASKET]: boolean;
};

export type JobParams = {
  analysis_time_series_ids: number[];
  excluded_from_agg: string[];
  type: 'cumulative';
  add_zero: true;
};

export type Params = JobParams & InteractionParams;
export type DraftParams = Partial<Params>;
