import { _, ts } from '_core';

import { DraftParams } from './types';

export const getExposureFactorName = (f: ts.types.pipeline.NeutralizeDraft['factors'][0]) => {
  if (f.type == 'market') return 'Market';

  const getValueName = (val: string) => {
    if (val == 'rbics_l1_id') return 'Sector';
    if (val == 'rbics_l2_id') return 'Sub Sector';
    if (val == 'rbics_l3_id') return 'Industry';
    if (val == 'rbics_l4_id') return 'Sub Industry';
    return _.startCase(val);
  };

  return getValueName(f.value);
};

export const validate = (params: DraftParams) => {
  if (_.isEmpty(params.factors)) return ['At least one variable is required.'];
  return [];
};
