/* eslint-disable no-unused-vars */
export enum INTERACTION_PARAMS {
  IS_BASKET = 'is_basket', // This is a ui field to handle the difference between basket and signal exposure
}

export type InteractionParams = {
  [INTERACTION_PARAMS.IS_BASKET]: true;
};

export type JobParams = {
  time_series_id: number;
  upper_bound: number;
  lower_bound: number;
  days_before_event: number;
  days_after_event: number;

  format_for_ui: true;
};

export type Params = JobParams & InteractionParams;
export type DraftParams = Partial<Params>;
