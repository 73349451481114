import { ts } from '_core';

import { validate } from './helpers';
import { DraftParams } from './types';

export default [
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.TIME_SERIES_LINE_COMPARE,
    title: 'Time Series Values over Time (same scale)',
    helpLabel: 'bdqyr4cu',
    generalAnalysisReportHelp: {
      document: ts.enums.UI_RESOURCE_TYPE_ENUM.TIME_SERIES,
      contentPath: `/${ts.enums.TIME_SERIES_ANALYSIS_TYPE_ENUM.ANALYSIS}`,
    },
    form: true,
    neededFiles: ['time_series_values_comparison'],
    defaultParams: {
      daily_reporting: true,
      return_horizons: [{ periods: { frequency: 'DAILY', amount: 1 }, signature: '1 Day Return' }],
      histories: Object.values(ts.enums.PERIOD_ENUM),
    } as DraftParams,
    tags: [
      ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.GENERAL,
      ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.TIME_SERIES,
    ],

    validate,
  },
] satisfies ts.types.analysis.WidgetConfig[];
