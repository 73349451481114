import { _, hooks, mui, React, ts, ui, uitheme } from '_core';

import RegularDataGrid from './data-grid';
import { GenerateColumns, GenerateHeatMappedColumns, TabularContextProvider } from '../tabular-context';

type TabularProps = {
  data: Record<string, ts.types.widgets.TableData>;
  getColumns: Record<string, GenerateColumns>;
  sheetTitles: Record<string, string>;
  sheetInfo: Record<string, React.ReactElement>;
  customError: ts.types.common.Alert;
  currentSheet: string;
  setCurrentSheet: (_sheet: string) => void;

  loading?: boolean;
  rowClass?: (_row: Record<string, any>) => string;
  customToolbarBySheet?: Record<string, () => React.ReactElement>;
  hideFilters?: boolean;
  hideDensity?: boolean;
  hideToolbar?: boolean;
  alwaysShowBottom?: boolean;

  groupBy?: Record<string, string[]>;
  expandedGroupIds?: Record<string, ReadonlySet<unknown>>;
  setExpandedGroupIds?: Record<string, (_v: ReadonlySet<unknown>) => void>;

  overrideHeight?: number;

  preferenceTab?: ts.enums.PREFERENCES_INNER_TABS_ENUM;
  preferenceKey?: ts.enums.PREFERENCES_KEY_ENUM;
  preferencesSourceWidgetId?: string;
  openPreferencesBySheet?: Record<string, () => void>;

  tableParams: Record<string, ts.types.components.dataGrid.TableParams>;
  setTableParams: Record<string, (_v: ts.types.components.dataGrid.TableParams) => void>;
  getHeatMappedCols?: Record<string, GenerateHeatMappedColumns>;
  heatMapColors?: Record<string, ts.types.components.dataGrid.HeatMapColors>;
  setExternalEmbed?: (_json: Record<string, ts.types.analysis.SyncTableEmbedMetadata>) => void;
  triggerWidthChange: boolean;
};

const TabularFull: React.FC<TabularProps> = ({
  getColumns,
  currentSheet,
  customError,
  customToolbarBySheet,
  data,
  hideFilters,
  hideDensity,
  loading,
  rowClass,
  setCurrentSheet,
  sheetInfo,
  sheetTitles,
  alwaysShowBottom,

  groupBy,
  expandedGroupIds,
  setExpandedGroupIds,

  overrideHeight,

  preferenceKey,
  preferenceTab,
  preferencesSourceWidgetId,
  openPreferencesBySheet,

  tableParams,
  setTableParams,
  getHeatMappedCols,
  heatMapColors,
  hideToolbar,
  setExternalEmbed,
  triggerWidthChange,
}): React.ReactElement => {
  const [containerHeight, setContainerHeight] = React.useState(undefined);

  const container = React.createRef<HTMLDivElement>();

  const { height } = hooks.useWindowDimensions(0);
  React.useEffect(() => {
    if (container.current) {
      setContainerHeight(container.current.offsetHeight);
    }
  }, [height]);

  const hasTabs = Object.keys(getColumns).length > 1;

  const error = (sheetKey: string) => {
    if (customError) return customError;
    if (_.isEmpty(data[sheetKey]))
      return {
        severity: ts.enums.ALERT_SEVERITY_ENUM.WARNING,
        message: 'No data to display',
      } as ts.types.common.Alert;
    return null;
  };

  const localEmbedRef = React.useRef<Record<string, ts.types.analysis.SyncTableEmbedMetadata>>({});
  const handleEmbed = (sheet: string, v: ts.types.analysis.SyncTableEmbedMetadata) => {
    localEmbedRef.current = { ...localEmbedRef.current, [sheetTitles[sheet] || sheet]: v };
    if (setExternalEmbed) setExternalEmbed(localEmbedRef.current);
  };

  const REPORT_TAB_HEIGHT = uitheme.layoutSize.REPORT_TAB_HEIGHT;

  return (
    <>
      <div ref={container} style={{ height: '100%', position: 'relative' }}>
        {_.isEmpty(data) && (
          <mui.core.Box height="100%" display="flex" justifyContent="center" alignItems="center">
            <ui.EmptyMessage hideBorder>No data to display</ui.EmptyMessage>
          </mui.core.Box>
        )}
        {Object.keys(getColumns).map((key) => {
          // Create shared props object for both components
          const sharedProps = {
            key: key === currentSheet ? key : `${key}_current`,
            data: data[key] || [],
            getColumns: getColumns?.[key],
            loading,
            customToolbar: customToolbarBySheet?.[key] || customToolbarBySheet?.['any'],
            hideDensity,
            rowClass: rowClass || undefined,
            customHeight: hasTabs ? containerHeight - REPORT_TAB_HEIGHT : null,
            hideFilters,
            hideToolbar,
            alert: error(key),
            groupBy: groupBy?.[key],
            expandedGroupIds: expandedGroupIds?.[key],
            setExpandedGroupIds: setExpandedGroupIds?.[key],
            initialTableParams: tableParams?.[key] || tableParams?.['any'],
            updateTableParams: setTableParams?.[key] || setTableParams?.['any'],
            overrideHeight,
            getHeatMappedCols: getHeatMappedCols?.[key],
            heatMapColors: heatMapColors?.[key],
            setExternalEmbed: (emb: ts.types.analysis.SyncTableEmbedMetadata) => handleEmbed(key, emb),
            triggerWidthChange,
          };

          return (
            <div
              key={key}
              style={{
                display: key === currentSheet ? 'block' : 'none',
                height: '100%',
              }}
            >
              <TabularContextProvider
                {...sharedProps}
                preferenceKey={preferenceKey}
                preferenceTab={preferenceTab}
                preferencesSourceWidgetId={preferencesSourceWidgetId}
                openPreferences={openPreferencesBySheet?.[key] || undefined}
                tableType="regular"
              >
                <RegularDataGrid {...sharedProps} />
              </TabularContextProvider>
            </div>
          );
        })}
      </div>
      {Object.keys(getColumns).length > (alwaysShowBottom ? 0 : 1) && (
        <mui.core.Tabs value={currentSheet} aria-label="tabs" className="tabular-tabs">
          {Object.keys(getColumns).map((key) => (
            <mui.core.Tab
              key={key}
              label={
                <mui.core.Box display="flex">
                  {sheetTitles[key] || key}
                  {sheetInfo[key] && (
                    <mui.core.Tooltip title={sheetInfo[key]} arrow>
                      <mui.core.IconButton size="small">
                        <mui.icons.InfoOutlined style={{ fontSize: 14 }} />
                      </mui.core.IconButton>
                    </mui.core.Tooltip>
                  )}
                </mui.core.Box>
              }
              value={key}
              onClick={() => setCurrentSheet(key)}
              sx={{
                height: REPORT_TAB_HEIGHT,
              }}
            />
          ))}
        </mui.core.Tabs>
      )}
    </>
  );
};

export default TabularFull;
