import React from 'react';

import * as enums from '../../../enums';
import { mui } from '../../../libs';
import * as types from '../../../types';
import ResourceAutocomplete from '../../resource-autocomplete';


type SignalSelectorProps = {
  value: types.widgets.common.BucketValue;
  setValue: (_v: types.widgets.common.BucketValue) => void;
  skipWorkspaceCheck?: boolean;
  disabled?: boolean;
  error?: boolean;
};

/**
 * Component that provides signal selection fields for bucket configuration
 * 
 * @param value - Current bucket configuration value
 * @param setValue - Callback to update bucket configuration
 * @param skipWorkspaceCheck - Whether to skip workspace validation
 * @param disabled - Whether the component is disabled
 * @param error - Whether there is a validation error
 * 
 * @returns Rendered signal selection fields
 */
const SignalSelector: React.FC<SignalSelectorProps> = ({
  value,
  setValue,
  skipWorkspaceCheck,
  disabled,
  error,
}): React.ReactElement => {
  return (
    <>
      <mui.core.Grid item xs={12} md={6}>
        <mui.core.TextField
          label="Quantiles (1 - 20)"
          variant="outlined"
          size="small"
          value={value.value}
          InputProps={{ inputProps: { min: 1, max: 20 } }}
          onChange={(e) => setValue({ ...value, value: e.target.value })}
          fullWidth
          required
          disabled={disabled}
        />
      </mui.core.Grid>

      <mui.core.Grid item xs={4}>
        <ResourceAutocomplete<types.common.ResourceExpanded>
          value={value?.signal_id}
          setValue={(id) =>
            setValue({
              ...value,
              signal_id: id,
            })
          }
          skipWorkspaceCheck={skipWorkspaceCheck}
          type="signal_based"
          inputProps={{
            fullWidth: true,
            label: 'Signal',
            variant: 'outlined',
            size: 'small',
            required: true,
            error: error,
          }}
        />
      </mui.core.Grid>

      <mui.core.Grid item xs={4}>
        <ResourceAutocomplete<types.common.ResourceExpanded>
          value={value?.pipeline_id}
          setValue={(id) =>
            setValue({
              ...value,
              pipeline_id: id,
            })
          }
          enableNone
          skipWorkspaceCheck={skipWorkspaceCheck}
          type={enums.RESOURCES_TYPES_ENUM.PIPELINE}
          inputProps={{
            fullWidth: true,
            label: 'Pipeline',
            variant: 'outlined',
            size: 'small',
          }}
        />
      </mui.core.Grid>

      <mui.core.Grid item xs={4}>
        <ResourceAutocomplete<types.common.ResourceExpanded>
          value={value?.sp_pipeline_id}
          setValue={(id) =>
            setValue({
              ...value,
              sp_pipeline_id: id,
            })
          }
          enableNone
          skipWorkspaceCheck={skipWorkspaceCheck}
          type="sp_pipeline"
          inputProps={{
            fullWidth: true,
            label: 'Portfolio Pipeline',
            variant: 'outlined',
            size: 'small',
          }}
        />
      </mui.core.Grid>
    </>
  );
};

export default SignalSelector;

SignalSelector.defaultProps = {
  skipWorkspaceCheck: false,
};
