export default {
  '%Y-%m-%d': 'yyyy-mm-dd',
  '%Y-%d-%m': 'yyyy-dd-mm',
  '%Y/%m/%d': 'yyyy/mm/dd',
  '%Y/%d/%m': 'yyyy/dd/mm',
  '%m-%d-%Y': 'mm-dd-yyyy',
  '%m/%d/%Y': 'mm/dd/yyyy',
  '%d-%m-%Y': 'dd-mm-yyyy',
  '%d/%m/%Y': 'dd/mm/yyyy',
  ISO8601: 'ISO 8601',
};
