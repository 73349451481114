import React from 'react';

import { _, mui } from '../../../libs';
import * as types from '../../../types';

type DeprecatedProps = {
  value: string | string[];
  config: types.components.queryBuilder.QbAttributes;
};

const Deprecated: React.FC<DeprecatedProps> = ({ value, config }): React.ReactElement => {
  return (
    <mui.core.Grid container spacing={2}>
      <mui.core.Grid item xs>
        <mui.core.TextField
          disabled
          label={config.label}
          variant="outlined"
          value={_.isArray(value) ? value.join(', ') : JSON.stringify(value)}
          size="small"
          fullWidth
        />
      </mui.core.Grid>
    </mui.core.Grid>
  );
};

export default Deprecated;
