import { Pipeline } from '../../../types/pipeline';
import { PipelineExpanded } from '../../../types/pipeline';

const expandPipeline = (pipeline: Pipeline & { resourceType: string }): PipelineExpanded => {
  return {
    id: pipeline.id,
    name: pipeline.name,
    handle: pipeline.handle,
    resourceType: pipeline.resourceType,
    created_by_id: pipeline.created_by_id,
    label_ids: pipeline.label_ids,
    definition: (pipeline.definition?.filters || []).map((filter) => filter.type),
    is_published: pipeline.is_published,
    is_deprecated: pipeline.is_deprecated,
    revoked: pipeline.revoked || false,
    is_valid: pipeline.is_valid,
    created_at: pipeline.created_at,
    source_resource_id: pipeline.source_resource_id,
    organization_id: pipeline.organization_id,
    has_description: pipeline.has_description,
    short_description: pipeline.short_description,
    source_org_id: pipeline.source_org_id,
    shared: pipeline.shared,
  } as PipelineExpanded;
};

export default expandPipeline;
