import { moment, mui } from '../../libs';

export const valueToDates = (value: [string, string]) => {
  const lval = [null, null] as mui.date.DateRange<Date>;
  if (value?.[0]) lval[0] = moment(value[0]).toDate();
  if (value?.[1]) lval[1] = moment(value[1]).toDate();
  return lval;
};

export const datesToValue = (d: mui.date.DateRange<Date>) => {
  const lval = [null, null] as string[];
  const [startRaw, endRaw] = d;
  const start = moment(startRaw);
  const end = moment(endRaw);
  if (start.isValid()) lval[0] = start.format('YYYY-MM-DD');
  if (end.isValid()) lval[1] = end.format('YYYY-MM-DD');
  return lval;
};

export const getNearest = (d: string, validDates: string[], startIdx: number, endIdx: number): Date => {
  if (startIdx > endIdx) return moment(validDates[Math.max(0, endIdx)]).toDate();
  const mid = Math.floor((startIdx + endIdx) / 2);

  if (d == validDates[mid]) return moment(validDates[mid]).toDate();
  if (moment(d).isAfter(moment(validDates[mid]))) return getNearest(d, validDates, mid + 1, endIdx);
  if (moment(d).isBefore(moment(validDates[mid]))) return getNearest(d, validDates, startIdx, mid - 1);
};

export const getValidValue = (
  value: mui.date.DateRange<Date>,
  validDates: string[],
  allValidDates: string[]
): mui.date.DateRange<Date> => {
  if (!validDates || !allValidDates) return value;

  const startVal = value[0]
    ? getNearest(moment(value[0]).format('YYYY-MM-DD'), validDates, 0, validDates.length - 1)
    : null;
  const endVal = value[1]
    ? getNearest(moment(value[1]).format('YYYY-MM-DD'), allValidDates, 0, allValidDates.length - 1)
    : null;
  return [startVal, endVal];
};
