import { _, hooks, mui, React, ts, ui } from '_core';

import * as prefColumns from 'views/preferences/columns-config';
import Tabular from 'views/report/tables/tabular';

import { DraftParams, Params } from './types';
import { getColumns } from '../../common/charts/utils';
import { prepareContributorsData } from '../../common/utils/attribution-utils';

type ChartProps = {
  id: string;
  readOnly: boolean;
  widgetKey: string;
  file: string;
  fullScreen: boolean;
  loadWidgetData: (_payload: ts.types.widgets.WidgetGetDataParams) => Promise<ts.types.widgets.WidgetGetDataResponse>;
  params: Params & DraftParams;
  setParams: (_p: DraftParams) => void;

  goFullScreen?: (_q: string) => void;
  isBasket?: boolean;
};

const Chart: React.FC<ChartProps> = ({
  id,
  loadWidgetData,
  params,
  setParams,
  file,
  fullScreen,
  goFullScreen,
  isBasket,
}): React.ReactElement => {
  const uiStyles = hooks.useUiStyles();

  const [error, setError] = React.useState<ts.types.common.ApiError>(null);
  const [data, setData] = React.useState<Record<string, ts.types.widgets.TableData>>();

  const [containerHeight, setContainerHeight] = React.useState(undefined);
  const { height } = hooks.useWindowDimensions();

  const [pnlColumnValue, setPnlColumnValue] = React.useState<Record<string, string>>({});
  const [groupedSum, setGroupedSum] = React.useState<Record<string, { TOP: string; BOTTOM: string }>>({});

  const container = React.createRef<HTMLDivElement>();

  React.useEffect(() => {
    if (container.current) {
      setContainerHeight(container.current.offsetHeight - 40);
    }
  }, [height]);

  const [expandedGroupIds, setExpandedGroupIds] = React.useState<Record<string, ReadonlySet<unknown>>>(
    params.expanded
      ? Object.fromEntries(Object.entries(params.expanded).map(([key, val]) => [key, new Set(val)]))
      : null
  );

  const [tableParams, setTableParams] = React.useState<ts.types.components.dataGrid.TableParamsSheet>(
    params.table_params
  );

  const debouncedParamsSave = React.useCallback(
    _.debounce(async (exp: typeof expandedGroupIds, tbP: ts.types.components.dataGrid.TableParamsSheet) => {
      setParams({
        ...params,
        expanded: Object.fromEntries(Object.entries(exp).map(([key, val]) => [key, [...val] as string[]])),
        table_params: { ...tbP },
      });
    }, 1_000),
    []
  );

  hooks.useEffectWithoutFirst(() => {
    debouncedParamsSave(expandedGroupIds, tableParams);
  }, [expandedGroupIds, tableParams]);

  const customHeader = (sheetKey: string) => (
    <mui.core.Box display="flex" width="100%" sx={uiStyles.hidePrint}>
      <mui.core.Box flexGrow={1}>
        <mui.core.Box display="flex" alignItems={'center'}>
          {!_.isEmpty(pnlColumnValue) && (
            <mui.core.Box width="30%">
              <ui.InfoRow title="Total P&L">{pnlColumnValue[sheetKey]}%</ui.InfoRow>
            </mui.core.Box>
          )}
        </mui.core.Box>
      </mui.core.Box>
    </mui.core.Box>
  );

  const loadData = async () => {
    try {
      const resp = await loadWidgetData({
        data: { file },
      });
      if (resp) {
        const { groupedData, pnlColumnValue, groupedSum } = prepareContributorsData(resp.data, params.n_performers);
        setData(groupedData);
        setPnlColumnValue(pnlColumnValue);
        setGroupedSum(groupedSum);

        const performersLabels = new Set(['TOP', 'BOTTOM']);
        const updatedExpandedGroupIds = { ...expandedGroupIds };

        params.histories.forEach((key) => {
          if (!(key in updatedExpandedGroupIds)) {
            updatedExpandedGroupIds[key] = performersLabels;
          }
        });

        setExpandedGroupIds(updatedExpandedGroupIds);
      }
    } catch (err) {
      setError(err as ts.types.common.ApiError);
    }
  };

  React.useEffect(() => {
    if (file) loadData();
    return () => setData(null);
  }, [file]);

  const columns = prefColumns.performanceAttributionContributorColumns;

  return (
    <Tabular
      data={data}
      getColumns={Object.fromEntries(
        Object.keys(data || {}).map((key) => [
          key,
          (_k: string[], preferences: ts.types.components.dataGrid.ColumnPreferences) => {
            const localColumns = getColumns(
              columns['data'].map((el) => el.key),
              preferences?.['data'],
              columns['data'],
              null,
              null,
              []
            );

            const performersColumn = localColumns.find((el) => el.key === 'performers');
            const remainingColumns = localColumns.filter((el) => el.key !== 'performers');

            performersColumn.renderCell = (params: mui.dataGrid.GridRenderCellParams) => {
              return () => (
                <>
                  {params.value} ({groupedSum[key][params.value as 'TOP' | 'BOTTOM']}% P&L)
                </>
              );
            };

            return [performersColumn, ...remainingColumns];
          },
        ])
      )}
      fullScreen={fullScreen}
      goFullScreen={goFullScreen}
      customToolbarBySheet={Object.fromEntries(params.histories.map((key) => [key, () => customHeader(key)]))}
      hideFilters
      alwaysShowBottom
      apiError={error}
      groupBy={Object.fromEntries(params.histories.map((key) => [key, ['performers']]))}
      expandedGroupIds={expandedGroupIds}
      setExpandedGroupIds={Object.fromEntries(
        params.histories.map((key) => [
          key,
          (v: ReadonlySet<unknown>) =>
            setExpandedGroupIds((exp) => ({
              ...exp,
              [key]: v,
            })),
        ])
      )}
      preferences={{
        preferencesSourceWidgetId: id,
        preferenceKey: isBasket
          ? ts.enums.PREFERENCES_KEY_ENUM.BASKET_PERFORMANCE_ATTRIBUTION_CONTRIBUTOR_COLUMNS
          : ts.enums.PREFERENCES_KEY_ENUM.BACKTEST_PERFORMANCE_ATTRIBUTION_CONTRIBUTOR_COLUMNS,
        preferenceTab: isBasket
          ? ts.enums.PREFERENCES_INNER_TABS_ENUM.BASKET_PERFORMANCE_ATTRIBUTION_CONTRIBUTOR_COLS
          : ts.enums.PREFERENCES_INNER_TABS_ENUM.BACKTEST_PERFORMANCE_ATTRIBUTION_CONTRIBUTOR_COLS,
      }}
      overrideHeight={containerHeight}
      tableParams={tableParams}
      setTableParams={Object.fromEntries(
        params.histories.map((key) => [
          key,
          (v: ts.types.components.dataGrid.TableParams) => setTableParams((t) => ({ ...t, [key]: v })),
        ])
      )}
      triggerWidthChange={params.fullWidth}
    />
  );
};

export default Chart;
