/* eslint-disable no-unused-vars */
import { ts } from '_core';

export enum INTERACTION_PARAMS {
  HISTORIES = 'histories',
  SELECTED_HISTORY = 'selectedHistory',
}

export type InteractionParams = {
  [INTERACTION_PARAMS.HISTORIES]: ts.enums.HISTORY_ENUM_EXTENDED[];
  [INTERACTION_PARAMS.SELECTED_HISTORY]: ts.enums.HISTORY_ENUM_EXTENDED;
};

export type JobParams = {
  buckets: ({ type: 'INDUSTRY'; value: 'rbics_l1_name' } | { type: 'ASSET_MASTER'; value: 'name' })[];
  statistics_type: ts.enums.REPORT_ENUMS.ANNU_STATISTICS_ENUM;
};

export type Params = JobParams & InteractionParams;
export type DraftParams = Partial<Params>;
