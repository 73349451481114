import { PIPELINE_FILTERS_ENUM } from '../enums';
import { Pipeline } from '../types/pipeline';

/**
 * Checks if a pipeline contains any portfolio-related filters
 * @param pipeline - The pipeline object to check
 * @returns Boolean indicating whether the pipeline contains portfolio filters
 */
const isSpPipeline = (pipeline: Pipeline): boolean => {
  let isSp = false;
  (pipeline?.definition?.filters || []).forEach((filter) => {
    if (
      [
        PIPELINE_FILTERS_ENUM.CHARACTERISTIC_PORTFOLIO,
        PIPELINE_FILTERS_ENUM.SIMPLE_PORTFOLIO,
        PIPELINE_FILTERS_ENUM.QUANTILE_PORTFOLIO,
        PIPELINE_FILTERS_ENUM.QUANTILE_PORTFOLIO_BUCKETS,
      ].includes(filter?.type as any)
    )
      isSp = true;
  });
  return isSp;
};

export default isSpPipeline;
