import { _, config, helpers, ts } from '_core';

export const generateSignalsFromVisual = (definition: ts.types.signalSet.Definition) => {
  const groups = definition.groups || [];

  const { universe_id, risk_model_id, frequency } = definition;

  let signals = [] as ts.types.signalSet.SignalMetadata[];

  const getGroupSignals = (group: ts.types.signalSet.SignalVisual) => {
    const groupSignals = [] as ts.types.signalSet.SignalMetadata[];
    const sids = group.signal_ids;
    const pids = [...(group.pipeline_ids || [])];
    const spids = [...(group.sp_pipeline_ids || [])];

    // Let's add the none option for pipelines if needed
    if (_.isEmpty(pids) || group.include_none_pipeline) {
      pids.push(null);
    }
    if (_.isEmpty(spids) || group.include_none_sp_pipeline) {
      spids.push(null);
    }

    const cartProd = _.uniq(helpers.cartesian(sids, pids, spids));
    // Create the signals with the fixed dataset info (universe, risk_model, frequency)
    cartProd.forEach((prod: any) => {
      groupSignals.push({
        id: helpers.gibberishGenerator.stringGenerator(11),
        signal_id: prod[0] as number,
        universe_id: universe_id,
        frequency: frequency,
        risk_model_id: risk_model_id,
        pipeline_id: prod[1],
        sp_pipeline_id: prod[2],
      });
    });
    return groupSignals;
  };

  groups.forEach((group) => {
    signals = signals.concat(getGroupSignals(group));
  });

  return _.uniqBy(
    signals,
    // eslint-disable-next-line max-len
    (el) => `${el.signal_id}_${universe_id}_${frequency}_${risk_model_id}_${el.pipeline_id}_${el.sp_pipeline_id}`
  );
};

export const getNewSignalSet = (name: string, handle: string, template: ts.types.signalSet.SignalSet = null) => {
  const nullDefinition = {};

  const newSignalSet: ts.types.signalSet.SignalSetDraft = {
    name,
    handle,
    definition: nullDefinition,
    is_valid: false,
    start_date: config.features.start_date,
    end_date: config.features.end_date,
  };

  // Check if we will create the signalset with a premade definition
  if (template && template.id) {
    newSignalSet.definition = {
      ...template.definition,
    };
    newSignalSet.ui_metadata = { visual_def: template.ui_metadata.visual_def ?? {} };
    newSignalSet.start_date = template.start_date;
    newSignalSet.end_date = template.end_date;
    newSignalSet.end_date_latest = template.end_date_latest;
    newSignalSet.is_valid = template.is_valid;
    newSignalSet.label_ids = template.label_ids;
  }

  return newSignalSet;
};

export const getArchivedDeps = (
  signalSet: ts.types.signalSet.SignalSet,
  resources: ts.StoreState['resources']
): ts.types.common.ArchivedDeps => {
  const deps = helpers.resourceGraph.getDeps(
    { ...signalSet, resourceType: ts.enums.RESOURCES_TYPES_ENUM.SIGNAL_SET },
    resources
  );
  return helpers.resourceGraph.getArchiveDeps(deps);
};

export const getUnpublishedDeps = (
  signalSet: ts.types.signalSet.SignalSet,
  resources: ts.StoreState['resources']
): ts.types.common.ResourceDeps[] => {
  return helpers.resourceGraph
    .getDeps({ ...signalSet, resourceType: ts.enums.RESOURCES_TYPES_ENUM.SIGNAL_SET }, resources)
    .filter((d) => !d.is_published);
};

export const getUnsharedDeps = (
  signalSet: ts.types.signalSet.SignalSet,
  resources: ts.StoreState['resources'],
  sharedWith?: number[]
): ts.types.common.ResourceDeps[] => {
  if (!sharedWith) sharedWith = signalSet.shared_with || [];

  return helpers.resourceGraph
    .getDeps({ ...signalSet, resourceType: ts.enums.RESOURCES_TYPES_ENUM.SIGNAL_SET }, resources)
    .filter((d) => !helpers.resourcesUtils.isShared(d, sharedWith));
};
