import { _, React, ts } from '_core';

import Chart from './chart';
import Form from './form';
import { DraftParams, Params } from './types';

type BacktestCumulativeReturnsProps = {
  id: string;
  readOnly: boolean;
  widgetKey: string;
  mode: ts.enums.CHART_MODE_ENUM;
  params: Params;
  setParams: (_p: DraftParams) => void;
  title: string;

  fullScreen?: boolean;
  context?: ts.types.backtest.BacktestAnaylsisContext;
  loadWidgetData: (_payload: ts.types.widgets.WidgetGetDataParams) => Promise<ts.types.widgets.WidgetGetDataResponse>;
  widgetPaths?: Record<'backtest_returns', string>;
};

const BacktestCommulativeReturns: React.FC<BacktestCumulativeReturnsProps> = ({
  id,
  readOnly,
  widgetKey,
  loadWidgetData,
  widgetPaths,
  params,
  setParams,
  mode,
  fullScreen,
  context,
  title,
}): React.ReactElement => {
  const hasBenchmark = React.useMemo(
    () => (params.isBasket ? context?.definition?.optimizer_rebalance : !_.isEmpty(context?.definition?.benchmark)),
    []
  );

  const chart = () => (
    <Chart
      id={id}
      readOnly={readOnly}
      widgetKey={widgetKey}
      loadWidgetData={loadWidgetData}
      title={title}
      portfolios={widgetPaths['backtest_returns']}
      fullScreen={fullScreen}
      hasBenchmark={hasBenchmark}
      params={params}
    />
  );

  return <>{mode == 'edit' ? <Form params={params} setParams={setParams} /> : <>{chart()}</>}</>;
};

export default BacktestCommulativeReturns;
