import { config, hooks, mui, React, ui } from '_core';

import { AIAssistantContext } from '../../ai-assistant-context';

const EmptyChat = () => {
  const conversationContext = React.useContext(AIAssistantContext);
  const { starting } = conversationContext;
  const uiStyles = hooks.useUiStyles();

  if (starting) {
    return (
      <mui.core.Box
        display="flex"
        alignContent="center"
        justifyContent="center"
        flexDirection="column"
        height="100%"
        width="100%"
      >
        <ui.CenteredLoader />
      </mui.core.Box>
    );
  }

  return (
    <mui.core.Box sx={uiStyles.emptyChatContainer}>
      <img
        src={config.customUrl + '/custom/finsera-logo.png'}
        alt="Finsera logo"
        style={{ height: 'auto', marginBottom: '50px', width: 50 }}
      />
      <mui.core.Typography variant="h5" textAlign="center" gutterBottom>
        Hello! How may I assist you today?
      </mui.core.Typography>

      <mui.core.Typography variant="body2" textAlign="center" gutterBottom color="textSecondary" my={2}>
        Welcome to {"Finsera's"} AI Assistant, ask about methods, data sources and how to build factors using the DSL
      </mui.core.Typography>
    </mui.core.Box>
  );
};

export default EmptyChat;
