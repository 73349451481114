import { hooks, mui, React, ts, ui, useSelector } from '_core';

import { DraftParams } from './types';

type DraftWidgetParams = DraftParams;

type FormProps = {
  params: DraftWidgetParams;
  setParams: (_p: DraftWidgetParams) => void;
};

const Form: React.FC<FormProps> = ({ params, setParams }): React.ReactElement => {
  const buildPipelineObject = (pipelineType: 'pipeline_id' | 'sp_pipeline_id') => {
    const formattedObject = {} as Record<number, number>;
    params.analysis_pipelined_signals?.forEach((el) => {
      formattedObject[el.signal_id] = el[pipelineType];
    });

    return formattedObject;
  };

  const [signals, setSignals] = React.useState(params.analysis_pipelined_signals?.map((p) => p.signal_id) || []);

  const [pipelines, setPipelines] = React.useState<Record<number, number>>(buildPipelineObject('pipeline_id'));
  const [spPipelines, setSpPipelines] = React.useState<Record<number, number>>(buildPipelineObject('sp_pipeline_id'));

  const uiStyles = hooks.useUiStyles();

  const resourceSignals = useSelector((state) => state.resources.signals);

  React.useEffect(() => {
    const data = signals.map((sId) => {
      return {
        signal_id: sId,
        pipeline_id: pipelines[sId],
        sp_pipeline_id: spPipelines[sId],
      };
    });

    setParams({ analysis_pipelined_signals: data });
  }, [signals, pipelines, spPipelines]);

  return (
    <mui.core.Box sx={uiStyles.widgetEditMode}>
      <mui.core.Box mt={2}>
        <ui.ResourceAutocomplete<ts.types.signal.SignalExpanded, true>
          value={signals}
          setValue={(ids) => setSignals(ids)}
          type={ts.enums.RESOURCES_TYPES_ENUM.SIGNAL}
          autocompleteProps={{
            size: 'small',
            multiple: true,
          }}
          inputProps={{
            label: 'Signals',
            variant: 'outlined',
            size: 'small',
            required: true,
          }}
        />
      </mui.core.Box>
      <mui.core.Box mt={2}>
        <mui.core.Grid container spacing={3} alignItems="center">
          {signals.map((sId) => {
            const signalHandle = resourceSignals.find((s) => s.id == sId)?.handle;

            return (
              <>
                <mui.core.Grid item xs={2}>
                  <mui.core.Typography
                    variant="body2"
                    color="textPrimary"
                    fontWeight="fontWeightMedium"
                    sx={{ wordBreak: 'break-word', overflowWrap: 'break-word' }}
                  >
                    {signalHandle}
                  </mui.core.Typography>
                </mui.core.Grid>
                <mui.core.Grid item xs={5}>
                  <ui.ResourceAutocomplete<ts.types.pipeline.PipelineExpanded>
                    value={pipelines[sId]}
                    setValue={(id) => setPipelines({ ...pipelines, [sId]: id })}
                    type={ts.enums.RESOURCES_TYPES_ENUM.PIPELINE}
                    enableNone
                    autocompleteProps={{
                      size: 'small',
                    }}
                    inputProps={{
                      label: 'Pipeline',
                      variant: 'outlined',
                      size: 'small',
                    }}
                  />
                </mui.core.Grid>
                <mui.core.Grid item xs={5}>
                  <ui.ResourceAutocomplete<ts.types.pipeline.PipelineExpanded>
                    value={spPipelines[sId]}
                    setValue={(id) => setSpPipelines({ ...spPipelines, [sId]: id })}
                    type="sp_pipeline"
                    enableNone
                    autocompleteProps={{
                      size: 'small',
                    }}
                    inputProps={{
                      label: 'Portfolio Pipeline',
                      variant: 'outlined',
                      size: 'small',
                    }}
                  />
                </mui.core.Grid>
              </>
            );
          })}
        </mui.core.Grid>
      </mui.core.Box>
    </mui.core.Box>
  );
};

export default Form;
