import { ts } from '_core';

import { DraftParams } from './types';

export const prepareData = (resources: ts.StoreState['resources'], data: ts.types.widgets.LinearData) => {
  const newData = {} as ts.types.widgets.LinearData;

  Object.keys(data).forEach((key) => {
    let newKey = key;
    newKey = resources.time_series.find((ts) => ts.handle === key)?.name;
    if (newKey) newData[newKey] = data[key];
  });

  return newData;
};

export const validate = (params: DraftParams) => {
  if ((params.analysis_time_series_ids || []).length < 1) return ['You need to add at least one time series.'];
  if ((params.analysis_time_series_ids || []).length > 3) return ['You can only add a max of three time series.'];
  return [];
};
