import { _, config, ts } from '_core';

const COMMON_INTERACTION_PARAMS = ['sort_by', 'title', 'subtitle', 'fullWidth', 'component_errors', 'is_embedded'];

// eslint-disable-next-line max-len
const widgetConfigs = import.meta.glob('./packages/*/config.ts', { eager: true }) as Record<string, { default: any }>;
// eslint-disable-next-line max-len
const widgetComponents = import.meta.glob('./packages/*/index.tsx', { eager: true }) as Record<
  string,
  { default: any }
>;

// /**
//  * Console logs all widgets in a csv string
//  * @param widgetConfigs Array of widget configurations to log
//  */
// const _generateWidgetReport = () => {
//   // Get the widget data by dynamically importing the widget configs
//   const widgetData = Object.entries(widgetConfigs).flatMap(([_path, module]) => {
//     return module.default as ts.types.analysis.WidgetConfig[];
//   });

//   // A widget has a tag list, we will create one record per widget per tag
//   // returning a list of object with
//   // (analysis: tag, widget_name: widget.title,  widget_key: widget.key, help_label: widget.helpLabel)
//   let widgetsList = widgetData.flatMap((widget) => {
//     return widget.tags?.map((tag) => ({
//       analysis: tag,
//       widget_name: widget.title,
//       widget_key: widget.key,
//     })) || [];
//   });

//   widgetsList = _.sortBy(widgetsList, 'analysis')
//     .filter((widget) => !['DownloadFiles', 'ReportSection'].includes(widget.widget_key));

//   // Check for duplicate analysis + widget_key combinations
//   const combinedKeys = widgetsList.map(widget => `${widget.analysis}_${widget.widget_key}`);
//   const duplicates = _.pickBy(_.countBy(combinedKeys), count => count > 1);

//   if (!_.isEmpty(duplicates)) {
//     const duplicateItems = Object.keys(duplicates).map(key => {
//       const [analysis, widget_key] = key.split('_');
//       return `(analysis: ${analysis}, widget_key: ${widget_key})`;
//     });
//     throw new Error(`Duplicate analysis + widget_key combinations found: ${duplicateItems.join(', ')}`);
//   }

//   const csvData = helpers.csv.toCsv(
//     widgetsList, [
//     { key: 'analysis', name: 'Analysis' },
//     { key: 'widget_name', name: 'Widget Name' },
//     { key: 'widget_key', name: 'Widget Key' },
//   ], ts.enums.SEPARATORS_ENUM.COMMA, false, false);

//   console.log(csvData.join(''));
// };

// _generateWidgetReport();

const exportedWidgets = Object.entries(widgetConfigs).flatMap(([_path, module], index) => {
  const component = Object.values(widgetComponents)[index]['default'];
  const widgetConfigs = module.default as ts.types.analysis.WidgetConfig[];

  const widgets = widgetConfigs.map((wc) => ({
    ...wc,
    componentModule: component,
  }));

  const keys = widgets.map((w) => w.key);
  const duplicatedKeys = _.keys(_.pickBy(_.countBy(keys), (count) => count > 1));
  if (!_.isEmpty(duplicatedKeys)) throw new Error(`Repeated keys on widgets: ${duplicatedKeys.join(', ')}`);

  const labels = widgets.map((w) => w.helpLabel);
  const duplicatedLabels = _.keys(_.pickBy(_.countBy(labels), (count) => count > 1));
  if (!_.isEmpty(duplicatedLabels)) throw new Error(`Repeated labels on widgets: ${duplicatedLabels.join(', ')}`);

  const isDevEnv = config.environment == 'development' || config.environment == 'local';
  // Filter dev only widgets
  return (
    widgets
      .filter((w) => !w.development || config.features?.dev_widgets || isDevEnv)
      // Add validate as empty if widget does not have that on config
      .map((w) => (w.validate ? w : { ...w, validate: () => [] as string[] }))
      // Add getName as title if there is no getName defined
      // We will prioritize the title interaction parameter
      .map((w) => {
        const getName = (
          params: ts.types.analysis.Widget['params'],
          context: ts.types.common.Resource,
          resources: ts.StoreState['resources'],
          ignoreTitle = false
        ) => {
          if (params?.title && !ignoreTitle) return params?.title;
          if (w.getName) return w.getName(params, context, resources);
          return w.title || 'Name not in config';
        };
        const getSubtitle = (params: ts.types.analysis.Widget['params'], ignoreSubtitle = false) => {
          if (params?.subtitle && !ignoreSubtitle) return params?.subtitle;
          if (w.getSubtitle) return w.getSubtitle(params);

          return w.subtitle || '';
        };
        const getGeneralAnalysisPrefix = (
          params: ts.types.analysis.Widget['params'],
          resources: ts.StoreState['resources'],
          isOnChartView = false
        ) => {
          if (isOnChartView && !!w.getGeneralAnalysisPrefix) return w.getGeneralAnalysisPrefix(params, resources);
          return w?.generalAnalysisPrefix;
        };
        return { ...w, getName, getSubtitle, getGeneralAnalysisPrefix };
      })
      // All the widgets have title and subtitle as an interaction parameter
      .map((w) =>
        w.interactionParams
          ? { ...w, interactionParams: [...w.interactionParams, ...COMMON_INTERACTION_PARAMS] }
          : { ...w, interactionParams: COMMON_INTERACTION_PARAMS }
      )
  );
});

export default exportedWidgets;
