import React from 'react';

import { _, mui } from '../../../libs';
import * as types from '../../../types';
import CenteredLoader from '../../centered-loader';
import CheckboxesAutocomplete from '../../checkboxes-autocomplete';
import * as etfUtils from '../etf-selector-utils';


type SearchFormProps = {
  params: types.etf.ETFParams;
  setParams: (_def: types.etf.ETFParams) => void;
  filtersOptions: types.etf.ETFFiltersOptions;
  loadingFilterOptions: boolean;
  openModal: boolean;
};

const ETFSearchForm: React.FC<SearchFormProps> = ({
  params,
  setParams,
  filtersOptions,
  loadingFilterOptions,
  openModal,
}): React.ReactElement => {
  const theme = mui.styles.useTheme() as mui.core.Theme;

  const searchField = React.createRef<HTMLDivElement>();

  const filters = React.useMemo(() => {
    if (!_.isEmpty(filtersOptions)) {
      const presetFilters = etfUtils.getEtfPresetFilters(filtersOptions);
      const customFilters = etfUtils.getEtfCustomFilters(filtersOptions);

      return [...presetFilters, ...customFilters];
    }

    return [];
  }, [filtersOptions]);

  React.useEffect(() => {
    if (openModal) {
      setTimeout(() => {
        searchField.current.focus();
      }, 100);
    }
  }, [openModal]);

  if (loadingFilterOptions) return <CenteredLoader top="-100px" label="Loading Custom Filters" />;

  return (
    <>
      <mui.core.Box mb={3} mt={3}>
        <mui.core.TextField
          variant="outlined"
          size="small"
          label="Search by Ticker or Name"
          id="search_term"
          inputRef={searchField}
          fullWidth
          value={params.search_text}
          onChange={(e) => setParams({ ...params, search_text: e.target.value })}
          InputProps={{
            startAdornment: (
              <mui.core.InputAdornment position="start">
                <mui.icons.Search
                  style={{
                    fontSize: '1rem',
                    color: (theme.palette as any).text.secondary,
                  }}
                />
              </mui.core.InputAdornment>
            ),
          }}
        />
      </mui.core.Box>

      {filters.map((filter, idx) => (
        <mui.core.Box mb={3} key={idx}>
          <CheckboxesAutocomplete<string | number>
            value={params.uiFilters[filter.key]}
            setValue={(val) => {
              const currentUIFilters = params?.uiFilters;
              const currentFilters = params?.filters;

              if (_.isEmpty(val)) {
                delete currentFilters[filter.key];
                delete currentUIFilters[filter.key];
              } else {
                if (filter.hasCustomValues)
                  currentFilters[filter.key] = _.flatten(_.map(val, (el: string) => filter.customValues[el]) as any[]);
                else currentFilters[filter.key] = val as string[] | number[];

                currentUIFilters[filter.key] = val as string[] | number[];
              }

              setParams({
                ...params,
                filters: { ...currentFilters },
                uiFilters: { ...currentUIFilters },
              });
            }}
            options={filter.options}
            label={filter.label}
            objKey={'code'}
            chipKey={'label'}
            optionLabel={(o) => o?.label as string}
            chipColor={'#e9eaed'}
          />
        </mui.core.Box>
      ))}
    </>
  );
};

export default ETFSearchForm;
