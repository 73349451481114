import { helpers, ts } from '_core';

export default [
  {
    key: 'data_date',
    name: 'Date',
    filter: 'date',
    column_category: 'Data Source',
    formatter: helpers.tableFormatters.formatTo('date', { align: 'right', format: 'YYYY-MM-DD' }),
  },
  {
    key: 'asset',
    name: 'Asset',
    formatter: helpers.tableFormatters.formatTo('string', { align: 'left' }),
    filter: 'string',
    column_category: 'Asset Master',
  },
  {
    key: 'name',
    name: 'Security Name',
    formatter: helpers.tableFormatters.formatTo('string', { align: 'left' }),
    filter: 'string',
    column_category: 'Asset Master',
  },
  {
    name: 'Issuer Country',
    description: '',
    key: 'issuer_country',
    formatter: helpers.tableFormatters.formatTo('string', { align: 'left' }),
    column_category: 'Asset Master',
  },
  {
    name: 'Quotation Country',
    description: '',
    key: 'quotation_country',
    formatter: helpers.tableFormatters.formatTo('string', { align: 'left' }),
    column_category: 'Asset Master',
  },
  {
    name: 'Currency',
    description: '',
    key: 'currency',
    formatter: helpers.tableFormatters.formatTo('string', { align: 'left' }),
    column_category: 'Asset Master',
  },
  {
    name: 'Cusip',
    description: '',
    key: 'cusip',
    formatter: helpers.tableFormatters.formatTo('string', { align: 'left' }),
    column_category: 'Asset Master',
    condition: (features: ts.types.organization.OrganizationFeatures) => !features.hide_cusip_col,
  },
  {
    name: 'Isin',
    description: '',
    key: 'isin',
    formatter: helpers.tableFormatters.formatTo('string', { align: 'left' }),
    column_category: 'Asset Master',
    condition: (features: ts.types.organization.OrganizationFeatures) => !features.hide_isin_col,
  },
  {
    name: 'Ticker',
    description: '',
    key: 'ticker',
    formatter: helpers.tableFormatters.formatTo('string', { align: 'left' }),
    column_category: 'Asset Master',
  },
  {
    name: 'Asset Type',
    description: '',
    key: 'asset_type',
    formatter: helpers.tableFormatters.formatTo('string', { align: 'left' }),
    column_category: 'Asset Master',
  },
  {
    name: 'Name',
    description: '',
    key: 'name',
    formatter: helpers.tableFormatters.formatTo('string', { align: 'left' }),
    column_category: 'Asset Master',
  },
  {
    name: 'Sector ID',
    description: '',
    key: 'rbics_l1_id',
    formatter: helpers.tableFormatters.formatTo('string', { align: 'left' }),
    column_category: 'Industry',
  },
  {
    name: 'Sub Sector ID',
    description: '',
    key: 'rbics_l2_id',
    formatter: helpers.tableFormatters.formatTo('string', { align: 'left' }),
    column_category: 'Industry',
  },
  {
    name: 'Industry ID',
    description: '',
    key: 'rbics_l3_id',
    formatter: helpers.tableFormatters.formatTo('string', { align: 'left' }),
    column_category: 'Industry',
  },
  {
    name: 'Sub Industry ID',
    description: '',
    key: 'rbics_l4_id',
    formatter: helpers.tableFormatters.formatTo('string', { align: 'left' }),
    column_category: 'Industry',
  },
  {
    name: 'Sector Name',
    description: '',
    key: 'rbics_l1_name',
    formatter: helpers.tableFormatters.formatTo('string', { align: 'left' }),
    column_category: 'Industry',
  },
  {
    name: 'Sub Sector Name',
    description: '',
    key: 'rbics_l2_name',
    formatter: helpers.tableFormatters.formatTo('string', { align: 'left' }),
    column_category: 'Industry',
  },
  {
    name: 'Industry Name',
    description: '',
    key: 'rbics_l3_name',
    formatter: helpers.tableFormatters.formatTo('string', { align: 'left' }),
    column_category: 'Industry',
  },
  {
    name: 'Sub Industry Name',
    description: '',
    key: 'rbics_l4_name',
    formatter: helpers.tableFormatters.formatTo('string', { align: 'left' }),
    column_category: 'Industry',
  },
];
