import React from 'react';
import { mui } from '@local/finsera-core/src/libs';

/**
 * Props interface for the FINDataGrid component
 * @interface FINDataGridProps
 * @extends {muiDatagrid.DataGridPremiumProps}
 */

/**
 * A customized DataGrid component with consistent styling
 * @component
 * @param {FINDataGridProps} props - Component props
 * @returns {JSX.Element} Rendered DataGrid component
 */
const FinDataGrid: React.FC<mui.dataGrid.DataGridPremiumProps & { defaultBackgroundColor?: boolean }> = ({
  sx,
  defaultBackgroundColor,
  ...props
}) => {
  const theme = mui.styles.useTheme() as any;

  return (
    <>
      <mui.dataGrid.DataGridPremium
        sx={{
          ...(!defaultBackgroundColor && {
            '& .MuiDataGrid-columnHeader': {
              background: theme.palette.background.paper,
              borderBottom: `1px solid ${theme.palette.gray.main}`,
            },
          }),
          '& .MuiDataGrid-columnHeaderTitleContainer': {
            fontSize: '0.7rem',
            color: theme.palette.text.secondary,
          },
          '& .MuiDataGrid-row:nth-child(even)': {
            backgroundColor: '#F9FAFB', // Light gray for odd rows
          },
          ...(sx || {}),
        }}
        {...props}
      />
    </>
  );
};

export default FinDataGrid;
