import { React, ts, useSelector } from '_core';

import Chart from './chart';
import Form from './form';
import { getFormOptions } from './helpers';
import { DraftParams, Params } from './types';

type BacktestSetMetricsVsParamsProps = {
  id: string;
  readOnly: boolean;
  widgetKey: string;
  mode: ts.enums.CHART_MODE_ENUM;
  params: Params | DraftParams;
  setParams: (_p: DraftParams) => void;
  title: string;

  fullScreen?: boolean;
  context?: ts.types.backtestSet.BacktestSetAnalysisContext;
  loadWidgetData: (_payload: ts.types.widgets.WidgetGetDataParams) => Promise<ts.types.widgets.WidgetGetDataResponse>;
  widgetPaths?: Record<'backtest_metric_vs_parameter_analysis', string>;
};

const BacktestSetMetricsVsParams: React.FC<BacktestSetMetricsVsParamsProps> = ({
  id,
  readOnly,
  widgetKey,
  loadWidgetData,
  widgetPaths,
  mode,
  params,
  fullScreen,
  setParams,
  context,
  title,
}) => {
  const resources = useSelector((state) => state.resources);
  const renderJobComponent = () => (
    <>
      <Chart
        id={id}
        readOnly={readOnly}
        widgetKey={widgetKey}
        loadWidgetData={loadWidgetData}
        summary={widgetPaths['backtest_metric_vs_parameter_analysis']}
        params={params as Params}
        fullScreen={fullScreen}
        title={title}
        formOptions={formOptions}
      />
    </>
  );

  const formOptions = React.useMemo(() => getFormOptions(context, resources), []);

  return (
    <>
      {mode == 'edit' ? <Form params={params} setParams={setParams} formOptions={formOptions} /> : renderJobComponent()}
    </>
  );
};

export default BacktestSetMetricsVsParams;
