import { api, helpers, mui, React, ts, ui, useHistory, useLocation, useSelector } from '_core';

type WorkspaceFormProps = {
  open: boolean;
  setOpen: (_b: boolean) => void;
  workspace?: ts.types.workspace.Workspace;
  workspaces?: ts.types.workspace.Workspace[];
  deleteWorkspace?: (_id: number) => Promise<void>;
};

const WorkspaceForm: React.FC<WorkspaceFormProps> = ({
  open,
  setOpen,
  workspace = null,
  workspaces,
  deleteWorkspace,
}): React.ReactElement => {
  const history = useHistory();
  const { pathname } = useLocation();

  const currTab = useSelector((state) => state.ui.currentTab);
  const wsContext: ts.types.workspace.Workspace['context'] = React.useMemo(() => {
    if (currTab == 'production') return 'production';
    return 'research';
  }, [currTab]);

  const [errorMessage, setErrorMessage] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [name, setName] = React.useState('');
  const [confirm, setConfirm] = React.useState(false);

  React.useEffect(() => {
    if (workspace && workspace.name) {
      setName(workspace.name);
    } else setName('');
  }, [workspace]);

  const handleDelete = () => {
    setLoading(true);

    deleteWorkspace(workspace.id);
    setLoading(false);
    setConfirm(false);
    setOpen(false);
  };

  const confirmDialog = () => (
    <mui.core.Dialog
      open={confirm}
      onClose={() => setConfirm(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="sm"
      fullWidth
    >
      <ui.DialogTitle closeAction={() => setConfirm(false)}>Confirm Delete</ui.DialogTitle>
      <mui.core.DialogContent>
        <mui.core.Box pb={4}>
          <mui.core.Typography>
            Are you sure you want to delete this workspace?
            <br />
            You will be able to search your resources and add them to other workspaces.
          </mui.core.Typography>
        </mui.core.Box>
      </mui.core.DialogContent>
      <mui.core.DialogActions>
        <ui.Button
          variant="contained"
          color="primary"
          loading={!!loading}
          onClick={handleDelete}
          style={{ margin: '0rem 1rem 1rem' }}
          fullWidth
        >
          Confirm
        </ui.Button>
      </mui.core.DialogActions>
    </mui.core.Dialog>
  );

  const handleSubmit = async () => {
    setErrorMessage('');

    // Validation
    if (!name) {
      setErrorMessage('All attributes are required.');
      setLoading(false);
      return;
    }

    setLoading(true);
    const method = workspace
      ? () => api.workspaces.update(workspace.id, { name })
      : () =>
          api.workspaces.create({
            name,
            context: wsContext,
          });
    try {
      method();

      if (pathname === '/dashboard') {
        history.push('/research');
      }

      setOpen(false);
      setLoading(false);
    } catch (e) {
      setErrorMessage(helpers.parseApiError(e as ts.types.common.ApiError));
      setLoading(false);
      setName(name);
    }
  };

  return (
    <mui.core.Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="sm"
      fullWidth
    >
      {errorMessage && (
        <mui.lab.Alert onClose={() => setErrorMessage('')} severity="error">
          {errorMessage}
        </mui.lab.Alert>
      )}
      <ui.DialogTitle closeAction={() => setOpen(false)}>
        {`${workspace ? 'Update' : 'Create'} workspace`}
      </ui.DialogTitle>
      <mui.core.DialogContent style={{ textAlign: 'right' }}>
        <mui.core.Box my={4}>
          <mui.core.TextField
            autoFocus
            id="name"
            label="Workspace name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            variant="outlined"
            fullWidth
            required
            inputProps={{
              autocomplete: 'off',
            }}
          />
        </mui.core.Box>
        <mui.core.Box display="flex">
          {workspaces?.length > 1 && (
            <mui.core.Box flex="1" textAlign="left">
              <ui.Button
                color="error"
                loading={!!loading}
                onClick={() => {
                  setConfirm(true);
                }}
              >
                Delete Workspace
              </ui.Button>
            </mui.core.Box>
          )}
          <ui.Button variant="contained" color="primary" loading={!!loading} onClick={handleSubmit}>
            Save
          </ui.Button>
        </mui.core.Box>
      </mui.core.DialogContent>
      {confirmDialog()}
    </mui.core.Dialog>
  );
};

export default WorkspaceForm;
