import { React } from '_core';

import { PreferencesContextProvider } from 'views/preferences/preferences-context';

import Preferences from './preferences';

const PreferencesIndex: React.FC = (): React.ReactElement => (
  <PreferencesContextProvider>
    <Preferences />
  </PreferencesContextProvider>
);

export default PreferencesIndex;
