import { hooks, mui, React, ts, ui, useSelector } from '_core';

import { DraftParams } from './types';

type FormProps = {
  params: DraftParams;
  setParams: (_p: DraftParams) => void;
};

const Form: React.FC<FormProps> = ({ params, setParams }): React.ReactElement => {
  const timeSeries = useSelector((state) => state.resources.time_series);
  const uiStyles = hooks.useUiStyles();
  const [comparets, setcomparets] = React.useState(params.analysis_time_series_ids || []);

  React.useEffect(() => {
    const compareTsHandles = timeSeries.filter((ts) => comparets.includes(ts.id)).map((ts) => ts.handle);

    const data = {
      ...params,
      excluded_from_agg: compareTsHandles,
      analysis_time_series_ids: comparets,
    };
    setParams(data);
  }, [comparets]);

  return (
    <mui.core.Box sx={uiStyles.widgetEditMode}>
      <mui.core.Box mt={4}>
        <ui.ResourceAutocomplete<ts.types.timeSeries.TimeSeriesExpanded, true>
          value={comparets}
          setValue={(ids) => setcomparets(ids)}
          type={ts.enums.RESOURCES_TYPES_ENUM.TIME_SERIES}
          autocompleteProps={{
            size: 'small',
            multiple: true,
            disableCloseOnSelect: true,
          }}
          inputProps={{
            label: 'Select Time Series',
            variant: 'outlined',
            size: 'small',
            required: true,
          }}
        />
      </mui.core.Box>
    </mui.core.Box>
  );
};

export default Form;
