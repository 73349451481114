import { actions, config, helpers, hooks, mui, React, ts, ui,useDispatch, useHistory, useSelector } from '_core';

import NavLink from './primary-nav-link';
import ApiDocsModal from '../api/docs';

type PrimaryNavProps = {
  dark?: boolean;
};

const PrimaryNav: React.FC<PrimaryNavProps> = ({ dark }): React.ReactElement => {
  const dispatch = useDispatch();
  const history = useHistory();

  const organization = useSelector((state) => state.auth.organization);
  const currentTab = useSelector((state) => state.ui.currentTab);

  const setCurrentTab = (tab: ts.types.common.CurrentTab) => dispatch(actions.ui.setCurrentTab(tab));
  const setScheduleDemoOpen = (o: boolean) => dispatch(actions.ui.setScheduleDemoOpen(o));

  const isFinseraUser = organization?.id === 0;
  const [routeChangeTriggered, setRouteChangeTriggered] = React.useState<[string, string]>();

  const locRef = React.useRef(history.location.pathname);
  locRef.current = history.location.pathname;

  // Block set current tab if there is no a real change on the route
  // this could happen due to outside blocks (prompt.tsx)
  hooks.useEffectWithoutFirst(() => {
    if (routeChangeTriggered[1] !== locRef.current) {
      setCurrentTab(routeChangeTriggered[0] as ts.types.common.CurrentTab);
    }
  }, [routeChangeTriggered]);

  // On first load, we will check which is the current tab
  React.useEffect(() => {
    // If we already have a current tab on one of the workspaces modes
    // we can safely return
    if (currentTab == 'production' || currentTab == 'research' || currentTab == 'jobber') return;

    const currentTabLocal = helpers.routes.getCurrentTab(locRef.current);
    if (currentTabLocal !== currentTab) {
      setCurrentTab(currentTabLocal as ts.types.common.CurrentTab);
    }
  }, [history.location.pathname]);

  const HandleUserLink = (tabName: ts.types.common.CurrentTab) => {
    if (currentTab !== tabName) {
      setRouteChangeTriggered([tabName, locRef.current]);
    }
  };

  return (
    <>
      <NavLink
        id="dashboard-link"
        to="/"
        onClick={() => HandleUserLink('dashboard')}
        selected={currentTab == 'dashboard'}
        icon={<mui.icons.HomeOutlined />}
        tooltip={'Dashboard'}
        dark={dark}
      />

      <NavLink
        id="research-link"
        to="/research"
        onClick={() => HandleUserLink('research')}
        selected={currentTab == 'research'}
        icon={<mui.icons.DesktopMacOutlined style={{ fontSize: '0.9rem' }} />}
        tooltip={'Research'}
        disabledTooltip={
          <ui.TourItem
            name="Research"
            description="Develop and organize your strategies and render visual reports for your clients"
            demoAction={() => setScheduleDemoOpen(true)}
          />
        }
        dark={dark}
      />
      {config.features.data_browser && (
        <NavLink
          id="datasets-link"
          to="/datasets"
          onClick={() => HandleUserLink('datasets')}
          selected={currentTab == 'datasets'}
          icon={<mui.icons.StorageOutlined />}
          tooltip={'Data Sources'}
          disabledTooltip={
            <ui.TourItem
              name="Data Sources"
              description="You can go to this area and navigate to your subscribed and custom data
                    sets in order to preview the raw data."
              demoAction={() => setScheduleDemoOpen(true)}
            />
          }
          dark={dark}
        />
      )}
      {config.features.production_mode && (
        <NavLink
          id="production-link"
          to="/production"
          onClick={() => HandleUserLink('production')}
          selected={currentTab == 'production'}
          icon={<mui.icons.RocketLaunchOutlined />}
          tooltip={'Production'}
          disabledTooltip={
            <ui.TourItem
              name="Data Sources"
              description="Once your strategy is ready you can go to this area
                    to start working with real investments and trading."
              demoAction={() => setScheduleDemoOpen(true)}
            />
          }
          dark={dark}
        />
      )}
      {config.features.publish_source && (
        <NavLink
          id="publsh-link"
          to="/publish"
          onClick={() => HandleUserLink('publish')}
          selected={currentTab == 'publish'}
          icon={<mui.icons.PublishedWithChangesOutlined />}
          tooltip={'Publish'}
          dark={dark}
        />
      )}
      {isFinseraUser && (
        <NavLink
          id="admin-link"
          to="/finsera-admin"
          onClick={() => HandleUserLink('finsera-admin')}
          selected={currentTab == 'finsera-admin'}
          icon={<mui.icons.SettingsOutlined />}
          tooltip={'Admin'}
          dark={dark}
        />
      )}
      {config.features.recent_jobs && (
        <NavLink
          id="recent-link"
          to="/reports"
          onClick={() => HandleUserLink('jobber')}
          selected={currentTab == 'jobber'}
          icon={<mui.icons.BarChartOutlined />}
          tooltip={'Recent Reports'}
          disabledTooltip={
            <ui.TourItem
              name="Reports"
              description="This area shows jobs (reports or performance charts) you've recently
                    run and their completion status.
                  This is a convenient place to see which of your jobs are still running and which have completed
                  and to navigate quickly to the results."
              demoAction={() => setScheduleDemoOpen(true)}
            />
          }
          dark={dark}
        />
      )}
      {dark && <mui.core.Divider />}
      <ui.ScheduleDemoModal />
      <ApiDocsModal />
    </>
  );
};

export default PrimaryNav;
