import { ts } from '_core';

import { getName, validate } from './helpers';
import { DraftParams } from './types';

export default [
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.SIGNAL_AVERAGE_BUCKETED_CUMULATIVE_RETURNS,
    title: 'Equal-weighted Cumulative Return by Bucket',
    helpLabel: '51etbarz',
    form: true,
    defaultParams: { weights: 'equal', ui_transformation: 'CUMULATIVE' } as DraftParams,
    neededFiles: ['bucketed_returns'],
    tags: [
      ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.SIGNAL,
      ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.ALPHA_MODEL,
      ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.COMMON_ALPHA_MODEL,
    ],

    getName,
    validate,
  },
] satisfies ts.types.analysis.WidgetConfig[];
