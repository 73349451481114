import { hooks, mui, React } from '_core';

import { DraftParams } from './types';
import SignalFactorExposures from '../../common/forms/signal-factor-exposure-selector';

type FormProps = {
  params: DraftParams;
  setParams: (_p: DraftParams) => void;
};

const Form: React.FC<FormProps> = ({ params, setParams }) => {
  const uiStyles = hooks.useUiStyles();

  return (
    <mui.core.Box sx={uiStyles.widgetEditMode}>
      <SignalFactorExposures
        value={params.analysis_pipelined_signals}
        setValue={(v) => setParams({ ...params, analysis_pipelined_signals: v })}
      />
    </mui.core.Box>
  );
};

export default Form;
