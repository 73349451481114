import { ts } from '_core';

import { validate } from './helpers';

export default [
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.BASKET_COMPARE_DRAWDOWN,
    title: 'Drawdown',
    form: true,
    helpLabel: 'lb00jb7q',
    neededFiles: ['backtest_drawdown'],
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.BASKET_COMPARE],

    validate,
  },
] satisfies ts.types.analysis.WidgetConfig[];
