import { _, config, helpers, ts } from '_core';

import * as columns from 'views/preferences/columns-config';

import { getBrowseItems } from './browse-items';
import { DataSourceBrowseItem, DraftParams } from '../types';

export const getDefaultColumns = (colKeys: string[]) => {
  const defaultCols = columns.dataSourceColumns.map((c) => c.key).filter((k) => colKeys.includes(k));
  return _.uniq([...defaultCols, ...colKeys]);
};

export const getDataSourceColumns = (datasource: ts.types.dataSource.DataSourceBrowserContext, dataKeys: string[]) => {
  const rawColumns = datasource.definition.columns;

  const startingColumns = _.filter(
    rawColumns,
    (col) => ![...config.identifierTypes, ...config.dateTypes].includes(col.dtype)
  );

  const defaultColumns = [...columns.dataSourceColumns];
  const dataSourceColumns = startingColumns.map((c) => {
    return {
      name: c.name,
      description: c.label,
      key: c.column_name,
      column_category: 'Data Source',
      filter: 'inferred',
    };
  });

  // Using uniq in case the ds have one of the asset or industry cols
  let dsColumns = _.uniqBy([...defaultColumns, ...dataSourceColumns], 'key').map((d) => ({
    ...d,
    name: _.isEmpty(d.name) ? d.key : d.name,
  }));
  if (dataKeys) {
    dsColumns = dataKeys.map((key) => {
      const foundDs = dsColumns.find((d) => d.key == key);
      if (foundDs) return foundDs;
      return {
        // Add name and description for columns filtered from special datatypes
        name: rawColumns.find((k) => k.column_name == key)?.name ?? key,
        description: rawColumns.find((k) => k.column_name == key)?.label ?? key,
        key: key,
        column_category: 'Data Source',
        filter: 'inferred',
      };
    });
  }

  return _.sortBy(
    dsColumns.map((d) => ({ ...d, category: d.column_category })),
    'column_category'
  );
};

export const processingParentFields = (params: DraftParams, field: string) => {
  if (field != 'root') return (params[`${field}_ui` as keyof DraftParams] || {}) as Record<string, any>;
  return params;
};

export const getParentField = (uiProcessing: boolean, field: string) => {
  if (field == 'root') return '';
  if (uiProcessing) return `${field}_ui`;
  return field;
};

const validField = (params: DraftParams, fieldData: DataSourceBrowseItem) => {
  let paramsObject = {} as Record<string, any>;
  if (!fieldData.ui_processing) paramsObject = params?.[fieldData.parent as keyof DraftParams] as Record<string, any>;
  else paramsObject = processingParentFields(params, fieldData.parent);

  let isSet = !!paramsObject?.[fieldData.name];

  // Validation by type
  if (fieldData.type == ts.enums.BROWSE_ITEM_TYPE_ENUM.ASSET) {
    isSet = !_.isEmpty(paramsObject?.[fieldData.name]?.list);
  }

  if (fieldData.type == ts.enums.BROWSE_ITEM_TYPE_ENUM.ETF) {
    if (!paramsObject?.[fieldData.name]) isSet = false;
  }

  if (fieldData.type == ts.enums.BROWSE_ITEM_TYPE_ENUM.DATE) {
    if (!params?.[fieldData.name as keyof DraftParams]) isSet = false;
  }

  if (fieldData.required && !isSet) return false;
  return true;
};

export const _validateParams = (browseItems: DataSourceBrowseItem[], params: DraftParams) => {
  // Validate browse items
  for (const fieldData of browseItems) {
    if (!validField(params, fieldData)) return [`${fieldData.label} is a required field.`];
  }
  if (!params?.eff_ts) return ['As of is a required field'];
  return [];
};

export const validateParams = (params: DraftParams, dataSource: ts.types.dataSource.DataSourceBrowserContext) => {
  const browseItems = getBrowseItems(dataSource);
  return _validateParams(browseItems, params);
};

export const getColumns = (
  dataKeys: string[],
  userColumns: ts.types.components.dataGrid.ColumnPreferences,
  ds: ts.types.dataSource.DataSourceBrowserContext
) => {
  const dsColumns = getDataSourceColumns(ds, dataKeys);
  const cols = userColumns?.[ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.MAIN]?.columns || getDefaultColumns(dataKeys);
  const frozenCols = userColumns?.[ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.MAIN]?.frozenColumns || [];
  const names = userColumns?.[ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.MAIN]?.names || {};

  const colObj = [] as ts.types.components.dataGrid.ColumnsData;

  frozenCols.forEach((colKey) => {
    if (dataKeys.includes(colKey) && dsColumns.find((d) => d.key == colKey)) {
      const c = dsColumns.find((d) => d.key == colKey);

      const col = {
        ...c,
        name: c.name ?? c.key,
        frozen: true,
        key: c.key,
        formatter: helpers.tableFormatters.formatTo('inferred', {
          align: 'center',
          booleanAsNumber: false,
        }),
      };

      if (c.key == 'value') {
        col.formatter = helpers.tableFormatters.formatTo('inferred', {
          align: 'right',
          commasOnThousands: true,
          roundDigits: 2,
        });
      }

      colObj.push(col);
    }
  });

  cols.forEach((colKey) => {
    if (dataKeys.includes(colKey) && dsColumns.find((d) => d.key == colKey)) {
      const c = dsColumns.find((d) => d.key == colKey);

      const col = {
        ...c,
        name: _.isEmpty(c.name) ? c.key : c.name,
        key: c.key,
        formatter: helpers.tableFormatters.formatTo('inferred', {
          align: 'center',
          booleanAsNumber: false,
        }),
      };

      if (c.key == 'value') {
        col.formatter = helpers.tableFormatters.formatTo('inferred', {
          align: 'right',
          commasOnThousands: true,
          roundDigits: 2,
        });
      }

      colObj.push(col);
    }
  });

  return _.uniqBy(colObj, 'key').map((el) => ({
    ...el,
    name: names[el.key] ?? el.name,
  }));
};

export const validate = (params: DraftParams, context: ts.types.dataSource.DataSourceBrowserContext) => {
  try {
    return validateParams(params, context);
  } catch {
    // Old browser runs could have invalid job context
    return ['Invalid last analysis.'];
  }
};
