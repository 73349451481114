import { ts } from '_core';

import { StyleFactor } from '@finsera/finsera/dist/resource/risk_model';

export const prepareData = (data: ts.types.widgets.LinearData, styleFactors?: Partial<StyleFactor>[]) => {
  const newData = {} as ts.types.widgets.LinearData;
  Object.keys(data).forEach((key) => {
    if (key.includes('_industry_')) {
      newData[key.replace('_industry_', '')] = data[key];
    } else if (key.includes('_style_')) {
      let newKey = key.replace('_style_', '');
      newKey = styleFactors?.find((el) => el.handle == newKey)?.name || newKey;

      newData[newKey] = data[key];
    } else {
      newData[key] = data[key];
    }
  });

  return newData;
};
