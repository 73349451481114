import { _, helpers, hooks, mui, React, ts } from '_core';

import AsyncTabular from 'views/report/tables/async-tabular';

import { Params, SHEETS } from './types';
import { getColumns } from '../../common/charts/utils';

type ChartProps = {
  id: string;
  loadWidgetData: (_payload: ts.types.widgets.WidgetGetDataParams) => Promise<ts.types.widgets.WidgetGetDataResponse>;
  params: Params;
  setParams: (_p: Params) => void;
  definition: ts.types.signal.OptimizedModel;
  file: string;
  fullScreen: boolean;
  goFullScreen: (_q: string) => void;

  queryParams?: { initial?: string };
};

const Chart: React.FC<ChartProps> = ({
  loadWidgetData,
  file,
  params,
  setParams,
  definition,
  fullScreen,
  goFullScreen,
  queryParams,
}) => {
  const container = React.createRef();

  const variableNames = React.useMemo(() => helpers.buildName.signalVarNameUniq(definition.signals), []);

  const [tableParams, setTableParams] = React.useState<ts.types.components.dataGrid.TableParamsSheet>(
    params.table_params
  );

  const [tableDtypes, setTableDtypes] = React.useState<ts.types.components.dataGrid.TableDtypesSheet>(
    params.table_dtypes
  );

  hooks.useEffectWithoutFirst(() => {
    setParams({
      ...params,
      table_params: { ...tableParams },
      table_dtypes: { ...tableDtypes },
    });
  }, [tableParams, tableDtypes]);

  const defaultColumns = [
    {
      key: 'index',
      name: 'Variable',
      filter: 'inferred',
      isIndex: true,
      frozen: true,
      formatter: ({ row }) => {
        const idx = Number(row?.index?.replace('var', ''));
        return variableNames[idx];
      },
    },
    {
      key: '_dynamic_',
      name: 'Dynamic',
      members: () => true,
      nameFormatter: (key: string) => key,
      filter: 'inferred',
    },
  ] as ts.types.components.dataGrid.ColumnsData;

  const loadData = async (
    offset: number,
    orderBy: ts.types.components.dataGrid.OrderBy,
    filters: ts.types.components.dataGrid.ValueFilters,
    logicOperator: mui.dataGrid.GridLogicOperator,
    sheet: 'weights' | 'returns' | 'true_returns'
  ) => {
    const response = await loadWidgetData({
      data: {
        file,
        pagination_x: { offset, limit: 20 },
        order_by: orderBy,
        values_filter: filters,
        filter_conjunction: logicOperator,
        rename: { variable: 'index' },
        query: ['tab', 'equals', sheet],
      },
    });

    return response;
  };

  const downloadData = async (
    rename: Record<string, string>,
    columnOrder: string[],
    sheet: 'weights' | 'returns' | 'true_returns'
  ) => {
    const response = await loadWidgetData({
      data: {
        file,
        rename,
        column_order: columnOrder,
        query: ['tab', 'equals', sheet],
      },
      download: true,
    });
    return response;
  };

  const localGetColumns = (colKeys: string[]) => {
    const columns = getColumns(colKeys, {}, defaultColumns);
    return columns.map((col) => {
      let colName = col.name;

      if (col.key !== 'index') {
        colName = String(colName).split(' ')[0];
      }

      return {
        ...col,
        name: colName,
      };
    });
  };

  const renderChart = () => (
    <AsyncTabular<SHEETS>
      key={file}
      loadData={{
        true_returns: (offset, orderBy, filters, logicOperator) =>
          loadData(offset, orderBy, filters, logicOperator, 'true_returns'),
        returns: (offset, orderBy, filters, logicOperator) =>
          loadData(offset, orderBy, filters, logicOperator, 'returns'),
        weights: (offset, orderBy, filters, logicOperator) =>
          loadData(offset, orderBy, filters, logicOperator, 'weights'),
      }}
      getColumns={{
        true_returns: (colKeys) => localGetColumns(colKeys),
        returns: (colKeys) => localGetColumns(colKeys),
        weights: (colKeys) => localGetColumns(colKeys),
      }}
      sheetTitles={{
        true_returns: 'Realized Returns',
        returns: 'Forecast Returns',
        weights: 'Weights',
      }}
      fullScreen={fullScreen}
      goFullScreen={goFullScreen}
      queryParams={queryParams}
      currentSheet={params.current_sheet}
      setCurrentSheet={(sheet) => setParams({ ...params, current_sheet: sheet })}
      downloadFullData={{
        true_returns: (rename, colOrder) => downloadData(rename, colOrder, 'true_returns'),
        returns: (rename, colOrder) => downloadData(rename, colOrder, 'returns'),
        weights: (rename, colOrder) => downloadData(rename, colOrder, 'weights'),
      }}
      tableParams={tableParams}
      setTableParams={{
        true_returns: (v) =>
          setTableParams((t) => ({
            ...t,
            true_returns: v,
          })),
        returns: (v) =>
          setTableParams((t) => ({
            ...t,
            returns: v,
          })),
        weights: (v) =>
          setTableParams((t) => ({
            ...t,
            weights: v,
          })),
      }}
      tableDtypes={tableDtypes}
      setTableDtypes={{
        true_returns: (v) =>
          setTableDtypes((t) => ({
            ...t,
            true_returns: v,
          })),
        returns: (v) =>
          setTableDtypes((t) => ({
            ...t,
            returns: v,
          })),
        weights: (v) =>
          setTableDtypes((t) => ({
            ...t,
            weights: v,
          })),
      }}
      hideFilters
      loadOnX
    />
  );

  return (
    <mui.core.Box display="flex" flexDirection="column" style={{ height: '100%' }} ref={container}>
      {!_.isNil(file) && renderChart()}
    </mui.core.Box>
  );
};

export default Chart;
