import React from 'react';

import EmptyMessage from './empty-message';

type NoResourceSelectedProps = {
  currentTab?: string;
  label?: React.ReactNode;
};

/**
 * Component that displays a message when no resource is selected.
 * 
 * @param currentTab - The current tab being viewed, defaults to 'dashboard'.
 * @param label - Optional label to display instead of the default message.
 * 
 * @returns A React element displaying the no resource selected message.
 */

const NoResourceSelected: React.FC<NoResourceSelectedProps> = ({ currentTab, label }): React.ReactElement => {
  return (
    <div style={{ width: '100%' }}>
      <EmptyMessage>
        <>
          {label ? (
            label
          ) : (
            <>
              {currentTab == 'jobber'
                ? 'Please select an Analyses or Preview report from the list to view'
                : 'Please select or create a resource to continue...'}
            </>
          )}
        </>
      </EmptyMessage>
    </div>
  );
};

NoResourceSelected.defaultProps = {
  currentTab: 'dashboard',
  label: null,
};

export default NoResourceSelected;
