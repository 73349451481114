import _ from 'lodash';

import './cookie';

import {
  checkIsNumber,
  compareArr,
  formatNumberWithSuffix,
  getObjectDiff,
  getObjectPaths,
  incBy,
  inferArrayType,
  inferType,
  isValidDate,
  numericField,
  numericValue,
  pickByNestedObj,
  testDomains,
} from './mixins';

interface ILoDashMixins extends _.LoDashStatic {
  numericField(_v: number | string, _m: number, _d: number): number;
  numericValue(_v: number | string, _m: number, _d: number, _precision: number): string;
  formatNumberWithSuffix(_v: number, _args: number): string;
  pickByNestedObj<T>(_o: T, _p: any): T;
  testDomains(_d: string): boolean;
  checkIsNumber(_v: number | string): boolean;
  getObjectDiff<T>(_updated_object: T, _base_object: T, _add_missing?: boolean): T;
  /**
   * Returns an array of paths to all properties of an object.
   *
   * @param obj - The object to traverse.
   * @param parentKey - The path to the current property (default: '').
   * @returns An array of strings representing the paths to the properties of the object.
   *
   * @example
   * const obj = { a: { b: 'hello', c: [{ idx: 1 }, { idx: 2 }] }, d: ['world'] };
   * const paths = _.getObjectPaths(obj);
   * console.log(paths); // ['a', 'a.b', 'a.c[0].idx', 'a.c[1].idx', 'd']
   */
  getObjectPaths<T>(_o: T, _pK?: string): string[];
  incBy<TArr>(_arr: TArr[], _k: string, _inc?: number): TArr[];
  compareArr<TArr>(_arr1: TArr[], _arr2: TArr[], _o: (_v: TArr) => string): [boolean, 'asc' | 'desc'];
  isValidDate(_d: string): boolean;
  inferType(_v: string | number | boolean): 'string' | 'boolean' | 'integer' | 'number' | 'date';
  inferArrayType(_v: any[]): 'string' | 'boolean' | 'integer' | 'number' | 'date';
  cookie: (_v: string, _s?: string) => string;
  removeCookie: (_v: string) => void;
}

_.mixin({ numericField }, { chain: false });

_.mixin({ numericValue }, { chain: false });

_.mixin({ pickByNestedObj }, { chain: false });

_.mixin({ testDomains }, { chain: false });

_.mixin({ checkIsNumber }, { chain: false });

_.mixin({ getObjectDiff }, { chain: false });

_.mixin({ getObjectPaths }, { chain: false });

_.mixin({ incBy }, { chain: false });

_.mixin({ compareArr }, { chain: false });

_.mixin({ isValidDate }, { chain: false });

_.mixin({ inferType }, { chain: false });

_.mixin({ inferArrayType }, { chain: false });

_.mixin({ formatNumberWithSuffix }, { chain: false });

// for amcharts and old use of lodash
_.mixin({
  pluck: _.map,
});

export default (_ as unknown) as ILoDashMixins;
