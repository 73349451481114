import { hooks, mui, React, ts, ui, useSelector } from '_core';

import SignalDatasetConfig from 'views/views-config/signal-dateset-config';

type SignalDatasetFormProps = {
  selectedDatasetPreference: ts.types.preferences.SIGNAL_DATASET_FORM;
  updateSelectedDataset: (_val: ts.types.preferences.SIGNAL_DATASET_FORM) => void;
  setIsValid: (_valid: boolean) => void;
};

const SignalDatasetForm: React.FC<SignalDatasetFormProps> = ({
  updateSelectedDataset,
  setIsValid,
  selectedDatasetPreference,
}) => {
  const resources = useSelector((state) => state.resources);

  const [isDateRangeValid, setIsDateRangeValid] = React.useState(false);
  const [datesLoading, setDatesLoading] = React.useState(false);

  // If we have an universe and frequency selected, dates must be valid
  hooks.useEffectWithoutFirst(() => {
    setIsValid(
      selectedDatasetPreference.universe_id && selectedDatasetPreference.frequency
        ? isDateRangeValid && !datesLoading
        : !datesLoading
    );
  }, [selectedDatasetPreference, isDateRangeValid, datesLoading]);

  return (
    <>
      <mui.core.Box mb={3}>
        <ui.Alert rounded severity="info" size="small" icon={<mui.icons.InfoOutlined />}>
          Partial dataset parameters are supported
        </ui.Alert>
      </mui.core.Box>
      <mui.core.TextField
        label="Dataset Default Name"
        variant="outlined"
        size="small"
        value={selectedDatasetPreference.name}
        onChange={(e) =>
          updateSelectedDataset({
            ...selectedDatasetPreference,
            name: e.target.value,
          })
        }
        fullWidth
      />
      <SignalDatasetConfig
        dataset={selectedDatasetPreference}
        setDataset={updateSelectedDataset}
        resources={resources}
        setIsDateRangeValid={setIsDateRangeValid}
        setDatesLoading={setDatesLoading}
        allOptional
        isPreference
      />
    </>
  );
};

export default SignalDatasetForm;
