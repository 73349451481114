import { ts } from '_core';

import { validate } from './data-source-browser-utils/helpers';
import { INTERACTION_PARAMS } from './types';

export default [
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.DATA_SOURCE_BROWSER,
    helpLabel: 'biz852k2',
    form: true,
    title: 'Browser',
    interactionParams: Object.values(INTERACTION_PARAMS),
    clearInteractionOnRegenerate: ['table_dtypes'],
    neededFiles: ['datasource_generate'],

    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.DATA_SOURCE_BROWSER],
    disableAdd: true,
    validate,
  },
] satisfies ts.types.analysis.WidgetConfig[];
