import { _, React, ts } from '_core';

import { Params } from './types';
import ChartWrapper from '../../common/chart-wrapper';
import createLineChart from '../../common/charts/create-line-chart';
import { prepareRhDataLinear } from '../../common/utils/signal-utils';

type ChartProps = {
  id: string;
  readOnly: boolean;
  widgetKey: string;
  loadWidgetData: (_payload: ts.types.widgets.WidgetGetDataParams) => Promise<ts.types.widgets.WidgetGetDataResponse>;
  returns: string;
  fullScreen: boolean;
  frequency: ts.enums.FREQUENCY_ENUM;
  params: Params;
  title: string;
  setExternalEmbed: (_json: ts.types.analysis.EmbedChartData) => void;
  subtitle?: string;
};

const Chart: React.FC<ChartProps> = ({
  id,
  readOnly,
  widgetKey,
  loadWidgetData,
  returns,
  fullScreen,
  frequency,
  params,
  title,
  subtitle,
  setExternalEmbed,
}) => {
  const [data, setData] = React.useState<ts.types.widgets.TableData>(null);
  const [error, setError] = React.useState<ts.types.common.ApiError>(null);
  const [showLegend, setShowLegend] = React.useState(false);
  const loadData = async () => {
    try {
      const response = await loadWidgetData({
        data: {
          file: returns,
        },
      });

      if (response) setData(response.data);
    } catch (err) {
      setError(err as ts.types.common.ApiError);
    }
  };

  React.useEffect(() => {
    if (returns && params.scale) loadData();
    return () => setData(null);
  }, [returns, params.scale]);

  React.useEffect(() => {
    // Create line chart on data and scale changes
    if (!_.isNil(data))
      createLineChart({
        id,
        data,
        scale: params.scale,
        fullScreen,
        setShowLegend,
        showEndValueLabel: true,
        exportTitle: title,
        exportSubTitle: subtitle,
        prepareData: (linearData) => prepareRhDataLinear(linearData, frequency),
        setExternalEmbed,
      });
  }, [data, params.scale, title, subtitle]);

  return (
    <ChartWrapper
      id={id}
      readOnly={readOnly}
      widgetKey={widgetKey}
      data={data}
      showLegend={showLegend}
      error={error}
      fullScreen={fullScreen}
    />
  );
};

export default Chart;
