import { React, ts } from '_core';

import Chart from './chart';
import { DraftParams, Params } from './types';

type BacktestSetSummaryTableProps = {
  id: string;
  mode: ts.enums.CHART_MODE_ENUM;
  params: Params;
  setParams: (_p: DraftParams) => void;
  goFullScreen: (_q: string) => void;
  fullScreen: boolean;
  loadWidgetData: (_payload: ts.types.widgets.WidgetGetDataParams) => Promise<ts.types.widgets.WidgetGetDataResponse>;
  widgetPaths?: Record<'backtest_set_summary', string>;
  queryParams?: { initial?: string };
};

const BacktestSetSummaryTable: React.FC<BacktestSetSummaryTableProps> = ({
  id,
  loadWidgetData,
  widgetPaths,
  fullScreen,
  goFullScreen,
  setParams,
  params,
  queryParams,
}) => {
  return (
    <Chart
      id={id}
      params={params}
      setParams={setParams}
      loadWidgetData={loadWidgetData}
      summary={widgetPaths['backtest_set_summary']}
      fullScreen={fullScreen}
      goFullScreen={goFullScreen}
      queryParams={queryParams}
    />
  );
};

export default BacktestSetSummaryTable;
