import { mui, queryString, React } from '_core';

type TabularSmallProps = {
  // Sheet name -> DataRow
  sheetKeys: string[];
  sheetTitles: Record<string, string>;
  sheetInfo: Record<string, React.ReactElement>;
  goFullScreen: (_queryParams: string) => void;
};

const TabularSmall: React.FC<TabularSmallProps> = ({
  sheetKeys,
  sheetTitles,
  sheetInfo,
  goFullScreen,
}): React.ReactElement => {
  const theme = mui.styles.useTheme() as mui.core.Theme;

  const openSheet = (key: string) => {
    goFullScreen(`?${queryString.stringify({ initial: key })}`);
  };

  return (
    <>
      {sheetKeys.map((key) => {
        return (
          <mui.core.Button
            key={key}
            onClick={() => openSheet(key)}
            sx={{
              display: 'flex',
              width: '100%',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              background: '#fff',
              padding: '0.65rem 0.5rem',
              borderBottom: `1px solid ${theme.palette.gray.main}`,
              transition: 'all ease-in-out 0.15s',
              color: theme.palette.secondary.light,
              fontSize: '0.7rem',
              '&:hover': {
                background: theme.palette.background.default,
                textDecoration: 'none',
              },
            }}
          >
            {`${sheetTitles[key] || key}`}
            {sheetInfo[key] && (
              <mui.core.Tooltip title={sheetInfo[key]} arrow>
                <mui.core.IconButton size="small">
                  <mui.icons.InfoOutlined style={{ fontSize: 14 }} />
                </mui.core.IconButton>
              </mui.core.Tooltip>
            )}
            <mui.icons.ArrowForward
              style={{
                fontSize: 12,
                marginRight: '6px',
                color: theme.palette.primary.main,
              }}
            />
          </mui.core.Button>
        );
      })}
    </>
  );
};

export default TabularSmall;
