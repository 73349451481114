/* eslint-disable no-unused-vars */
import { ts } from '_core';

export enum INTERACTION_PARAMS {
  TABLE_PARAMS = 'table_params',
}

export type InteractionParams = {
  [INTERACTION_PARAMS.TABLE_PARAMS]: ts.types.components.dataGrid.TableParamsSheet;
};

export type Params = InteractionParams & { fullWidth: boolean };
export type DraftParams = Partial<Params>;
