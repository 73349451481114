import { React, ts, ui } from '_core';

import { DraftParams } from './types';

type FormProps = {
  params: DraftParams;
  setParams: (_p: DraftParams) => void;
  context: ts.types.riskModel.RiskModelAnalysisContext;
};

const Form: React.FC<FormProps> = ({ params, setParams, context }) => {
  const [snapshotDate, setSnapshotDate] = React.useState(params.start_date);

  const [componentErrors, setComponentErrors] = React.useState([]);

  React.useEffect(() => {
    const data = {
      ...params,
      start_date: snapshotDate,
      end_date: snapshotDate,
      component_errors: componentErrors,
    };
    setParams(data);
  }, [snapshotDate, componentErrors]);

  return (
    <ui.SmartDateSelector
      label="Snapshot Date"
      value={snapshotDate}
      setValue={(date) => setSnapshotDate(date)}
      minDate={context.start_date}
      maxDate={context.end_date}
      universeId={context?.universe_id}
      setExternalError={(e) => setComponentErrors(e ? [e] : [])}
    />
  );
};

export default Form;
