import { hooks, mui, React, ts, ui } from '_core';

import { DraftParams, JobParams } from './types';

type FormProps = {
  params: DraftParams;
  setParams: (_p: DraftParams) => void;
  context?: ts.types.signal.SignalAnalysisContext;
};

const Form: React.FC<FormProps> = ({ params, setParams, context }) => {
  const [timeSeriesId, setTimeSeriesId] = React.useState(params.time_series_id);
  const [quantiles, setQuantiles] = React.useState(params.quantiles || 5);
  const [breakpoints, setBreakpoints] = React.useState(params.break_points || [null]);
  const [returnHorizon, setReturnHorizon] = React.useState<ts.types.returnHorizon.ReturnHorizon>(
    params.return_horizons?.[0]
  );
  const [selectedHistories, setSelectedHistories] = React.useState(params.histories || []);
  const [showUnknown, setShowUnknown] = React.useState(params.show_unknown || false);
  const [discrete, setDiscrete] = React.useState(params.discrete || false);

  const [dataType, setDataType] = React.useState<JobParams['data_type']>(params.data_type || 'quantiles');

  const uiStyles = hooks.useUiStyles();

  React.useEffect(() => {
    const data = {
      ...params,
      time_series_id: timeSeriesId,
      histories: selectedHistories,
      return_horizons: [returnHorizon] as [ts.types.returnHorizon.ReturnHorizon],
      quantiles: discrete || dataType === 'breakpoints' ? null : quantiles,
      break_points: discrete || dataType === 'quantiles' ? null : breakpoints,
      data_type: dataType,
      discrete,
      show_unknown: showUnknown,
    } satisfies DraftParams;
    setParams(data);
  }, [timeSeriesId, returnHorizon, quantiles, breakpoints, dataType, selectedHistories, showUnknown, discrete]);

  return (
    <mui.core.Box sx={uiStyles.widgetEditMode}>
      <mui.core.Grid container spacing={2} mt={2}>
        <mui.core.Grid item xs={12} md={6}>
          <ui.ResourceAutocomplete<ts.types.common.ResourceExpanded>
            value={timeSeriesId}
            setValue={(id) => setTimeSeriesId(id)}
            type={ts.enums.RESOURCES_TYPES_ENUM.TIME_SERIES}
            inputProps={{
              fullWidth: true,
              label: 'Time Series',
              variant: 'outlined',
              required: true,
              size: 'small',
            }}
          />
        </mui.core.Grid>

        <mui.core.Grid item xs={12} md={3} ml={2}>
          <mui.core.FormControlLabel
            control={<mui.core.Switch size="small" checked={discrete} onChange={() => setDiscrete(!discrete)} />}
            label="Is a discrete Time Series?"
          />
        </mui.core.Grid>
      </mui.core.Grid>

      <mui.core.Box mt={2} ml={1}>
        <mui.core.FormControl component="fieldset">
          <mui.core.RadioGroup
            row
            value={dataType}
            onChange={(e) => setDataType(e.target.value as JobParams['data_type'])}
          >
            <mui.core.FormControlLabel value="quantiles" control={<mui.core.Radio />} label="Quantiles" />
            <mui.core.FormControlLabel value="breakpoints" control={<mui.core.Radio />} label="Breakpoints" />
          </mui.core.RadioGroup>
        </mui.core.FormControl>
      </mui.core.Box>

      {!discrete && (
        <mui.core.Box mt={4}>
          {dataType === 'quantiles' ? (
            <ui.NumericField
              textFieldProps={{
                label: 'Quantiles (1 - 20)',
                fullWidth: true,
                variant: 'outlined',
                size: 'small',
                required: 'true',
              }}
              value={quantiles}
              setValue={(v) => setQuantiles(v)}
            />
          ) : (
            <ui.Breakpoints value={breakpoints} setValue={setBreakpoints} />
          )}
        </mui.core.Box>
      )}

      <mui.core.Box mt={5}>
        <ui.WidgetHistories<true>
          multiple
          value={selectedHistories}
          setValue={setSelectedHistories}
          context={context}
        />
      </mui.core.Box>

      <mui.core.Box mt={4}>
        <ui.WidgetReturnHorizon<false>
          value={returnHorizon}
          setValue={(val) => setReturnHorizon(val)}
          context={context}
        />
      </mui.core.Box>

      <mui.core.Box mt={2}>
        <mui.core.FormControlLabel
          control={<mui.core.Switch size="small" checked={showUnknown} onChange={() => setShowUnknown(!showUnknown)} />}
          label="Show statistics for missing Time Series values"
        />
      </mui.core.Box>
    </mui.core.Box>
  );
};

export default Form;
