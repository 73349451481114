import { helpers, ts } from '_core';

export default {
  [ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.PORTFOLIO_RISK_FACTOR_CONTRIBUTION]: [
    {
      key: 'factor',
      name: 'Factor',
      formatter: (row: { row: { factor: string } }) =>
        row['row']['factor'].replace('_industry_', '').replace('_handle_', ''),
    },
    {
      key: 'initial_mctr',
      name: 'Initial Marginal Contribution to Total Risk',
    },
    {
      key: 'optimal_mctr',
      name: 'Optimal Marginal Contribution to Total Risk',
    },
    {
      key: 'difference_mctr',
      name: 'Difference Marginal Contribution to Total Risk',
    },
    {
      key: 'initial_pctr',
      name: 'Initial Percent Contribution to Total Risk',
      formatter: helpers.tableFormatters.formatTo('float', {
        align: 'right',
        roundDigits: 2,
        multiplier: 100,
        suffix: '%',
      }),
    },
    {
      key: 'optimal_pctr',
      name: 'Optimal Percent Contribution to Total Risk',
      formatter: helpers.tableFormatters.formatTo('float', {
        align: 'right',
        roundDigits: 2,
        multiplier: 100,
        suffix: '%',
      }),
    },
    {
      key: 'difference_pctr',
      name: 'Difference Percent Contribution to Total Risk',
      formatter: helpers.tableFormatters.formatTo('float', {
        align: 'right',
        roundDigits: 2,
        multiplier: 100,
        suffix: '%',
      }),
    },
  ] as ts.types.components.dataGrid.ColumnsData,
  [ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.PORTFOLIO_RISK_SECURITY_CONTRIBUTION]: [
    {
      key: 'name',
      name: 'Name',
    },
    {
      key: 'ticker',
      name: 'Ticker',
    },
    {
      key: 'asset',
      name: 'Asset',
    },
    {
      key: 'initial_mctr',
      name: 'Initial Marginal Contribution to Total Risk',
    },
    {
      key: 'optimal_mctr',
      name: 'Optimal Marginal Contribution to Total Risk',
    },
    {
      key: 'difference_mctr',
      name: 'Difference Marginal Contribution to Total Risk',
    },
    {
      key: 'initial_pctr',
      name: 'Initial Percent Contribution to Total Risk',
      formatter: helpers.tableFormatters.formatTo('float', {
        align: 'right',
        roundDigits: 2,
        multiplier: 100,
        suffix: '%',
      }),
    },
    {
      key: 'optimal_pctr',
      name: 'Optimal Percent Contribution to Total Risk',
      formatter: helpers.tableFormatters.formatTo('float', {
        align: 'right',
        roundDigits: 2,
        multiplier: 100,
        suffix: '%',
      }),
    },
    {
      key: 'difference_pctr',
      name: 'Difference Percent Contribution to Total Risk',
      formatter: helpers.tableFormatters.formatTo('float', {
        align: 'right',
        roundDigits: 2,
        multiplier: 100,
        suffix: '%',
      }),
    },
  ] as ts.types.components.dataGrid.ColumnsData,
};
