import React from 'react';

import AssetSelectorModal from './asset-selector-modal';
import { queryAsset } from './asset-selector-utils';
import AssetTable from './asset-table';
import { useEffectWithoutFirst } from '../../hooks';
import { _, mui } from '../../libs';
import * as types from '../../types';

type AssetSelectorProps = {
  value: types.assetSelector.AssetSelectorValueObject;
  setValue: (
    _value: types.assetSelector.AssetSelectorValueObject,
    _metaData: types.assetSelector.AssetSelectorMetaData | types.assetSelector.AssetSelectorMetaData[]
  ) => void;
  createId?: (_asset: types.assetSelector.AssetSelectorMetaData) => string;
  createTitle?: (_asset: types.assetSelector.AssetSelectorMetaData) => string;
  multiple?: boolean;
  disabled?: boolean;
  tableView?: boolean; // Just with multiple
  dates?: types.assetSelector.AssetSelectorDates;
  customCountries?: string[];
  fields?: types.assetSelector.AssetSelectorTableFields[];
  selectorRef?: React.MutableRefObject<() => void>;

  externalOpen?: boolean;
  externalSetOpen?: (_v: boolean) => void;
  initialSearch?: {
    searchTerm: string;
    countries?: string[];
    identifier?: types.assetSelector.AssetParams['identifier'];
  };
  onlyModal?: boolean;
  errorState?: boolean;
  required?: boolean;
  fieldLabel?: string;
  withDateRanges?: boolean;
  dateRanges?: types.optimizer.TradeListConstraintDraft['asset_date_range'];
  onDateRangesChange?: (_ranges: types.optimizer.TradeListConstraintDraft['asset_date_range']) => void;
};

const EMPTY_ARRAY: any[] = [];

/**
 * Component that allows users to search and select assets, with optional date ranges and table view
 *
 * @param value - The selected asset FID(s)
 * @param setValue - Callback when asset selection changes
 * @param createId - Function to generate unique ID for asset
 * @param createTitle - Function to generate display title for asset
 * @param multiple - Allow selecting multiple assets
 * @param disabled - Disable the selector
 * @param tableView - Show selected assets in table view
 * @param dates - Date range constraints for asset search
 * @param customCountries - List of allowed country codes
 * @param fields - Fields to display in table view
 * @param selectorRef - Ref to trigger selector open
 * @param externalOpen - Control open state externally
 * @param externalSetOpen - Callback for external open state changes
 * @param initialSearch - Initial search parameters
 * @param onlyModal - Only show modal without input field
 * @param errorState - Show error state
 * @param required - Mark field as required
 * @param fieldLabel - Label for the input field
 * @param withDateRanges - Enable date range selection
 * @param dateRanges - Selected date ranges
 * @param onDateRangesChange - Callback when date ranges change
 *
 * @returns Asset selector component with optional table view
 */
const AssetSelector = ({
  value,
  setValue,
  createId,
  tableView,
  dates,
  fields,
  selectorRef,
  externalOpen,

  externalSetOpen,
  initialSearch,
  onlyModal,
  errorState,
  withDateRanges,
  dateRanges,
  onDateRangesChange,

  multiple = false,
  disabled = false,
  required = true,
  fieldLabel = 'Search Assets',
  customCountries = EMPTY_ARRAY,
  createTitle = (meta) =>
    meta?.name
      ? `${meta?.name} ${meta.quotation_country ? `[${meta.quotation_country}]` : ''}`
      : `${meta.ticker || meta.fid} ${meta.quotation_country ? `[${meta.quotation_country}]` : ''}`,
}: AssetSelectorProps): React.ReactElement => {
  const [initialLoad, setIniitalLoad] = React.useState(true);
  const [localOpen, localSetOpen] = React.useState(false);

  // Handle external state
  const setOpenModal = React.useMemo(() => {
    if (externalSetOpen) return externalSetOpen;
    return localSetOpen;
  }, []);

  const openModal = React.useMemo(() => {
    if (externalOpen !== undefined) return externalOpen;
    return localOpen;
  }, [externalOpen, localOpen]);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const divRef = React.useRef<HTMLDivElement>();

  const handleClick = () => {
    setOpenModal(true);
    setAnchorEl(divRef.current);
  };

  React.useEffect(() => {
    if (selectorRef) selectorRef.current = handleClick;
  }, []);

  const [localValue, setLocalValue] = React.useState<types.assetSelector.AssetSelectorMetaData[]>([]);

  // On boot, we need to load the metadata
  React.useEffect(() => {
    const initialLoad = async () => {
      const lfids = multiple ? (value as string[]) : [value as string];
      const queryResult = await queryAsset(
        {
          search_text: '',
          fids: lfids,
        },
        lfids.length,
        0
      );

      if (!queryResult.error) {
        setLocalValue(multiple ? queryResult?.response?.assets : [queryResult?.response?.assets?.[0]]);
      }
      setIniitalLoad(false);
    };
    if (!_.isEmpty(value)) initialLoad();
    else setIniitalLoad(false);
  }, []);

  useEffectWithoutFirst(() => {
    const newVal = multiple
      ? _.map((localValue as types.assetSelector.AssetSelectorMetaData[]) || [], 'fid')
      : (localValue as types.assetSelector.AssetSelectorMetaData[])?.[0]?.fid;

    const newMetadata = multiple ? localValue : localValue?.[0];

    if (
      !_.isEqual(
        multiple ? [...((value || []) as [])].sort() : value,
        multiple ? [...((newVal || []) as [])].sort() : newVal
      )
    )
      setValue(newVal, newMetadata);
  }, [localValue]);

  if (initialLoad && !onlyModal)
    return <mui.core.TextField disabled fullWidth label="Loading" variant="outlined" size="small" />;

  return (
    <mui.core.Box {...({ ref: divRef } as any)}>
      {!onlyModal && (
        <mui.core.Autocomplete
          multiple={multiple}
          fullWidth
          options={localValue || []}
          size="small"
          value={localValue}
          getOptionLabel={(option) => createTitle(option as types.assetSelector.AssetSelectorMetaData)}
          onChange={(_e, val) => {
            setLocalValue(val as types.assetSelector.AssetSelectorMetaData[]);
          }}
          renderInput={(params) => (
            <mui.core.TextField
              {...params}
              fullWidth
              label={fieldLabel}
              variant="outlined"
              required={required}
              size="small"
              error={errorState}
            />
          )}
          renderTags={(tagValue, getTagProps) => {
            return tagValue.map((option, index) => {
              const currentLabel = createTitle(option as types.assetSelector.AssetSelectorMetaData);
              return <mui.core.Chip key={index} label={currentLabel} {...getTagProps({ index })} />;
            });
          }}
          disabled={disabled}
          onOpen={!disabled ? handleClick : undefined}
        />
      )}

      {tableView && multiple && (
        <AssetTable
          value={localValue as types.assetSelector.AssetSelectorMetaData[]}
          setValue={setLocalValue}
          withDateRanges={withDateRanges}
          dateRanges={dateRanges}
          onDateRangesChange={onDateRangesChange}
          createTitle={createTitle}
          createId={createId}
          disabled={disabled}
          fields={fields}
        />
      )}
      <AssetSelectorModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        customCountries={customCountries}
        assets={localValue}
        setAssets={setLocalValue}
        dates={dates}
        anchorEl={externalSetOpen ? undefined : anchorEl}
        multiple={multiple}
        initialSearch={initialSearch}
      />
    </mui.core.Box>
  );
};

export default AssetSelector;
