import { helpers, React, ts, ui, useSelector } from '_core';

import { getSignalsObj, prepareLinearData } from '../../common/utils/signal-set-utils';

type ChartProps = {
  id: string;
  readOnly: boolean;
  widgetKey: string;
  loadWidgetData?: (_payload: ts.types.widgets.WidgetGetDataParams) => Promise<ts.types.widgets.WidgetGetDataResponse>;
  file: string;
  fullScreen: boolean;
  context: ts.types.signalSet.SignalSetAnalysisContext;
  title: string;
  subtitle?: string;
  setExternalEmbed: (_json: ts.types.analysis.EmbedChartData) => void;
};

const Chart: React.FC<ChartProps> = ({
  id,
  readOnly,
  loadWidgetData,
  file,
  fullScreen,
  context,
  title,
  subtitle,
  setExternalEmbed,
}) => {
  const [data, setData] = React.useState<ts.types.widgets.TableData>(null);
  const [alert, setAlert] = React.useState<{ type: 'error' | 'warning'; message: string } | null>(null);

  const resources = useSelector((state) => state.resources);
  const signalsObj = React.useMemo(() => getSignalsObj(context), [context]);

  const loadData = async () => {
    try {
      const response = await loadWidgetData({
        data: {
          file,
        },
      });
      if (response) {
        setData(prepareLinearData(response.data, resources, signalsObj));
      }
    } catch (err) {
      setAlert({
        type: 'error',
        message: helpers.parseApiError(err as ts.types.common.ApiError),
      });
    }
  };

  React.useEffect(() => {
    if (file) loadData();
    return () => setData(null);
  }, [file]);

  React.useEffect(() => {
    if (file) loadData();
    return () => setData(null);
  }, [file]);

  return (
    <ui.LineChart
      id={id}
      data={data}
      fullScreen={fullScreen}
      readOnly={readOnly}
      alert={alert}
      showEndValueLabel
      showPeriodSelector
      exportTitle={title}
      exportSubTitle={subtitle}
      numberFormat="#.##%"
      setExternalEmbed={setExternalEmbed}
    />
  );
};

export default Chart;
