import { mui, React } from '_core';

const TrialEndedModal = (): React.ReactElement => {
  const theme = mui.styles.useTheme();

  return (
    <mui.core.Dialog open>
      <mui.core.DialogContent
        sx={{
          textAlign: 'center',
          padding: '5rem 4rem 2rem',
        }}
      >
        <mui.core.Box
          sx={{
            background: theme.palette.primaryLight.light,
            width: '3rem',
            height: '3rem',
            borderRadius: '3rem',
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginBottom: '1rem',
          }}
        >
          <mui.icons.InfoOutlined color="primary" />
        </mui.core.Box>
        <mui.core.Typography
          variant="h4"
          color="primary"
          sx={{
            marginBottom: '0.5rem',
          }}
        >
          Your Finsera trial has ended
        </mui.core.Typography>
        <mui.core.Typography variant="body1" color="textSecondary">
          Please contact the Finsera team to enable subscription or to extend your trial.
        </mui.core.Typography>
      </mui.core.DialogContent>
    </mui.core.Dialog>
  );
};

export default TrialEndedModal;
