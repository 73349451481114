import { _, custom, helpers, hooks, mui, React, ts } from '_core';

import AsyncTabular from 'views/report/tables/async-tabular';

import { Params } from './types';
import { getColumns } from '../../common/charts/utils';

type ChartProps = {
  loadWidgetData: (_payload: ts.types.widgets.WidgetGetDataParams) => Promise<ts.types.widgets.WidgetGetDataResponse>;
  params: Params;
  setParams: (_p: Params) => void;
  file: string;
  fullScreen: boolean;
  goFullScreen: (_v: string) => void;

  queryParams?: { initial?: string };
};

const Chart: React.FC<ChartProps> = ({
  loadWidgetData,
  params,
  setParams,
  file,
  fullScreen,
  goFullScreen,
  queryParams,
}) => {
  const container = React.createRef();

  const defaultColumns = [
    {
      key: 'build_date',
      name: 'Build Date',
      filter: 'date',
      formatter: helpers.tableFormatters.formatTo('date', { align: 'right', format: 'YYYY-MM-DD' }),
    },
    {
      key: 'rsquared',
      name: 'Overall R²',
    },
    {
      key: 'fstat',
      name: <custom.MultilineTableHeader name={['Overall', 'F-Statistic']} />,
      cleanName: 'Overall F-Statistic',
    },
  ];

  const [tableParams, setTableParams] = React.useState<ts.types.components.dataGrid.TableParamsSheet>(
    params.table_params
  );

  const [tableDtypes, setTableDtypes] = React.useState<ts.types.components.dataGrid.TableDtypesSheet>(
    params.table_dtypes
  );

  hooks.useEffectWithoutFirst(() => {
    setParams({
      ...params,
      table_params: { ...tableParams },
      table_dtypes: { ...tableDtypes },
    });
  }, [tableParams, tableDtypes]);

  const loadData = async (
    offset: number,
    orderBy: ts.types.components.dataGrid.OrderBy,
    filters: ts.types.components.dataGrid.ValueFilters,
    logicOperator: mui.dataGrid.GridLogicOperator
  ) => {
    const response = await loadWidgetData({
      data: {
        file,
        pagination: { offset, limit: 100 },
        order_by: orderBy,
        values_filter: filters,
        filter_conjunction: logicOperator,
      },
    });

    return response;
  };

  const downloadData = async (rename: Record<string, string>, columnOrder: string[]) => {
    const response = await loadWidgetData({
      data: {
        file,
        file_name: 'linear_alpha_tabular_significance.csv',
        rename,
        column_order: columnOrder,
      },
      download: true,
    });
    return response;
  };

  const renderChart = () => (
    <AsyncTabular<ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.MAIN>
      key={file}
      loadData={{ [ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.MAIN]: loadData }}
      getColumns={{
        [ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.MAIN]: (colKeys) => getColumns(colKeys, {}, defaultColumns),
      }}
      sheetTitles={{
        [ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.MAIN]: 'Report',
      }}
      fullScreen={fullScreen}
      goFullScreen={goFullScreen}
      queryParams={queryParams}
      rowWidth={{ [ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.MAIN]: 90 }}
      rowHeight={{ [ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.MAIN]: 20 }}
      downloadFullData={{
        [ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.MAIN]: downloadData,
      }}
      tableParams={tableParams}
      setTableParams={{
        [ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.MAIN]: (v) =>
          setTableParams((t) => ({
            ...t,
            [ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.MAIN]: v,
          })),
      }}
      tableDtypes={tableDtypes}
      setTableDtypes={{
        [ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.MAIN]: (v) =>
          setTableDtypes((t) => ({
            ...t,
            [ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.MAIN]: v,
          })),
      }}
    />
  );

  return (
    <mui.core.Box display="flex" flexDirection="column" style={{ height: '100%' }} ref={container}>
      {!_.isNil(file) && renderChart()}
    </mui.core.Box>
  );
};

export default Chart;
