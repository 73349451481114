import { helpers } from '_core';
export const exposureColumns = [
  {
    key: 'metric',
    name: 'Metric',
    formatter: helpers.tableFormatters.formatTo('string', { align: 'left' }),
    filter: 'string',
  },
  {
    key: 'long_value',
    name: 'Long Value',
    formatter: helpers.tableFormatters.formatTo('float', {
      align: 'right',
      roundDigits: 2,
      numericPrefix: '$ ',
      commasOnThousands: true,
    }),
    filter: 'number',
  },
  {
    key: 'short_value',
    name: 'Short Value',
    formatter: helpers.tableFormatters.formatTo('float', {
      align: 'right',
      roundDigits: 2,
      numericPrefix: '$ ',
      commasOnThousands: true,
    }),
    filter: 'number',
  },
  {
    key: 'net_exposure',
    name: 'Net Exposure',
    formatter: helpers.tableFormatters.formatTo('float', {
      align: 'right',
      roundDigits: 3,
      multiplier: 100,
      suffix: '%',
      commasOnThousands: true,
    }),
    filter: 'number',
  },
];
