import { _, am4charts, am4core, helpers, ts } from '_core';

import { createImageHeader } from './create-image-header';
import { nestedBarChartFormat } from './formatters/nested-bar-chart';

type createStackedBarChartProps = {
  id: string;
  data: ts.types.widgets.TableData;
  fullScreen: boolean;
  setShowLegend: (_b: boolean) => void;
  exportTitle: string;
  format?: string;
  stacked?: boolean;
  rotatedLabels?: boolean;
  seriesOrder?: (_c: string[]) => string[];
  hideLegend?: boolean;
  yAxisLabel?: string;
  xAxisLabel?: string;
  yAxis?: string;
  xAxis?: string;
  hideSeries?: string[];
  disableAxisLabel?: boolean;
  category?: string;
  prepareData?: (_d: ts.types.widgets.NestedBarWidgetData) => ts.types.widgets.NestedBarWidgetData;
};

const EMPTY_ARRAY: any[] = [];

const createStackedBarChart = ({
  id,
  data,
  fullScreen,
  setShowLegend,
  exportTitle,
  format = '#.##',
  rotatedLabels = false,
  hideLegend = false,
  yAxisLabel = '',
  xAxisLabel = '',
  hideSeries = EMPTY_ARRAY,
  seriesOrder = (c) => c,
  disableAxisLabel = false,
  category = 'history',
  prepareData = undefined,
}: createStackedBarChartProps) => {
  const chart = am4core.create(`chart-${id}-${fullScreen ? 'fs' : 'sw'}`, am4charts.XYChart);

  let nestedBarData = nestedBarChartFormat(data, category);
  if (prepareData) nestedBarData = prepareData(nestedBarData);

  chart.data = nestedBarData.data;

  if (format) chart.numberFormatter.numberFormat = format;

  const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
  categoryAxis.dataFields.category = 'category';
  categoryAxis.renderer.grid.template.location = 0;
  categoryAxis.renderer.minGridDistance = 30;
  categoryAxis.tooltip.fontSize = 11;

  if (xAxisLabel) categoryAxis.title.text = xAxisLabel;

  chart.fontSize = 9;
  if (rotatedLabels) {
    categoryAxis.renderer.labels.template.rotation = 270;
    categoryAxis.renderer.labels.template.verticalCenter = 'middle';
    categoryAxis.renderer.labels.template.horizontalCenter = 'left';
  }

  const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
  valueAxis.strictMinMax = true;
  valueAxis.tooltip.fontSize = 11;
  valueAxis.tooltip.background.fill = am4core.color('#3C5688');
  valueAxis.tooltip.background.cornerRadius = 3;
  valueAxis.tooltip.background.strokeWidth = 0;

  valueAxis.cursorTooltipEnabled = true;

  if (disableAxisLabel) valueAxis.renderer.labels.template.disabled = true;

  if (yAxisLabel) {
    valueAxis.paddingRight = 10;
    valueAxis.title.text = yAxisLabel;
  }

  const createSeries = (value: string, name: string, color: number) => {
    const seriesName = name;

    const series = chart.series.push(new am4charts.ColumnSeries());
    series.dataFields.valueY = value;
    series.dataFields.categoryX = 'category';
    series.name = seriesName;

    series.sequencedInterpolation = true;
    series.stacked = true;

    series.columns.template.width = am4core.percent(60);
    series.columns.template.tooltipText = '[bold]{name}[/]\n{categoryX}: {valueY}';
    series.tooltip.fontSize = 11;

    series.fill = am4core.color(helpers.getColor(color));
    series.stroke = am4core.color(helpers.getColor(color));

    return series;
  };

  const firstObj = chart?.data?.[0] || {};
  const series = Object.keys(_.omit(firstObj, ['category'])).filter((el) => el);

  let hidden = 0;
  seriesOrder(series).forEach((serieKey, index) => {
    if (!hideSeries.includes(serieKey)) {
      createSeries(serieKey, serieKey, index - hidden);
    } else hidden += 1;
  });

  let showLegendLocal = false;

  if ((chart.series.values.length <= 7 || fullScreen) && !hideLegend) {
    showLegendLocal = true;
    setShowLegend(true);
  } else {
    setShowLegend(false);
  }

  function resizeLegend() {
    if (document.getElementById(`legend-${id}-${fullScreen ? 'fs' : 'sw'}`))
      document.getElementById(`legend-${id}-${fullScreen ? 'fs' : 'sw'}`).style.height =
        chart.legend.contentHeight + 10 + 'px';
  }

  chart.exporting.menu = new am4core.ExportMenu();
  chart.exporting.menu.align = 'left';
  chart.exporting.filePrefix = 'chart-data';

  chart.exporting.menu.items = [
    {
      label: '...',
      menu: [
        { type: 'jpg', label: 'JPG' },
        { type: 'png', label: 'PNG' },
        { type: 'csv', label: 'CSV' },
      ],
    },
  ];

  if (showLegendLocal) {
    const legendContainer = am4core.create(`legend-${id}-${fullScreen ? 'fs' : 'sw'}`, am4core.Container);
    legendContainer.width = am4core.percent(100);
    legendContainer.height = am4core.percent(100);
    legendContainer.logo.disabled = true;

    chart.legend = new am4charts.Legend();
    chart.legend.parent = legendContainer;
    chart.legend.labels.template.maxWidth = 95;
    chart.legend.maxHeight = fullScreen ? undefined : 50;
    chart.legend.scrollable = true;
    chart.legend.itemContainers.template.paddingTop = 1;
    chart.legend.itemContainers.template.paddingBottom = 1;
    chart.legend.markers.template.width = 10;
    chart.legend.markers.template.height = 10;
    chart.legend.fontSize = 11;

    chart.events.on('datavalidated', resizeLegend);
    chart.events.on('maxsizechanged', resizeLegend);
    chart.legend.events.on('datavalidated', resizeLegend);
    chart.legend.events.on('maxsizechanged', resizeLegend);
    document.getElementById(`legend-${id}-${fullScreen ? 'fs' : 'sw'}`).style.height =
      chart.legend.contentHeight?.toString();

    // Add legend to the chart.
    chart.exporting.extraSprites.push(chart.legend);
  } else {
    document.getElementById(`legend-${id}-${fullScreen ? 'fs' : 'sw'}`).innerHTML = '';
  }

  chart.logo.disabled = true;

  createImageHeader({ chart, title: exportTitle, fullScreen, id });

  return chart;
};

export default createStackedBarChart;
