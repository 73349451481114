import { _, hooks, mui, React, ts } from '_core';

import AsyncTabular from 'views/report/tables/async-tabular';

import EditColumns from './edit-columns';
import * as utils from '../data-source-browser-utils';
import { Params } from '../types';

type ChartProps = {
  loadWidgetData: (_payload: ts.types.widgets.WidgetGetDataParams) => Promise<ts.types.widgets.WidgetGetDataResponse>;
  params: Params;
  setParams: (_p: Params) => void;
  fullScreen: boolean;
  goFullScreen: (_v: string) => void;
  context: ts.types.dataSource.DataSourceBrowserContext;
  data: string;
  queryParams?: { initial?: string };
};

const Chart: React.FC<ChartProps> = ({
  loadWidgetData,
  data,
  fullScreen,
  goFullScreen,
  queryParams,
  context,
  params,
  setParams,
}) => {
  const container = React.createRef();

  const [tableParams, setTableParams] = React.useState<ts.types.components.dataGrid.TableParamsSheet>(
    params.table_params
  );

  const [tableDtypes, setTableDtypes] = React.useState<ts.types.components.dataGrid.TableDtypesSheet>(
    params.table_dtypes
  );

  const [paramColumns, setParamColumns] = React.useState(params.columns);

  hooks.useEffectWithoutFirst(() => {
    setParams({
      ...params,
      columns: paramColumns,
      table_params: { ...tableParams },
      table_dtypes: { ...tableDtypes },
    });
  }, [tableParams, tableDtypes, paramColumns]);

  const loadData = async (
    offset: number,
    orderBy: ts.types.components.dataGrid.OrderBy,
    filters: ts.types.components.dataGrid.ValueFilters,
    logicOperator: mui.dataGrid.GridLogicOperator
  ) => {
    const response = await loadWidgetData({
      data: {
        file: data,
        pagination: { offset, limit: 100 },
        order_by: orderBy,
        values_filter: filters,
        filter_conjunction: logicOperator,
      },
    });

    return response;
  };

  const downloadData = async (rename: Record<string, string>, columnOrder: string[]) => {
    const response = await loadWidgetData({
      data: {
        file: data,
        file_name: 'universe_preview_data.csv',
        rename,
        column_order: columnOrder,
      },
      download: true,
    });
    return response;
  };

  const customHeader = React.useMemo(
    () =>
      ({ columns }: { columns: string[] }) => {
        return (
          <EditColumns
            columns={
              paramColumns?.[ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.MAIN] || {
                columns: utils.helpers.getDefaultColumns(columns),
              }
            }
            setColumns={(cols) =>
              setParamColumns({
                [ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.MAIN]: cols,
              })
            }
            context={context}
            dataKeys={columns}
          />
        );
      },
    [params]
  );

  const renderChart = () => (
    <>
      <AsyncTabular<ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.MAIN>
        key={data}
        loadData={{
          [ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.MAIN]: loadData,
        }}
        getColumns={{
          [ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.MAIN]: (colKeys, userColumns) => {
            return utils.helpers.getColumns(colKeys, userColumns, context);
          },
        }}
        sheetTitles={{
          [ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.MAIN]: 'Browser',
        }}
        fullScreen={fullScreen}
        goFullScreen={goFullScreen}
        queryParams={queryParams}
        overrideUserPreferences={paramColumns}
        downloadFullData={{
          [ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.MAIN]: downloadData,
        }}
        customToolBar={{
          [ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.MAIN]: customHeader,
        }}
        tableParams={tableParams}
        setTableParams={{
          [ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.MAIN]: (v) =>
            setTableParams((t) => ({
              ...t,
              [ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.MAIN]: v,
            })),
        }}
        tableDtypes={tableDtypes}
        setTableDtypes={{
          [ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.MAIN]: (v) =>
            setTableDtypes((t) => ({
              ...t,
              [ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.MAIN]: v,
            })),
        }}
      />
    </>
  );

  return (
    <mui.core.Box display="flex" flexDirection="column" style={{ height: '100%' }} ref={container}>
      {!_.isNil(data) && renderChart()}
    </mui.core.Box>
  );
};

export default Chart;
