import { _, helpers, hooks, mui, React, ts, ui, useSelector } from '_core';

import * as columns from 'views/preferences/columns-config';
import Tabular from 'views/report/tables/tabular';

import { Params, SHEETS_ENUM, sheetTitles } from './types';
import { getColumns } from '../../common/charts/utils';

type ChartProps = {
  id: string;
  readOnly: boolean;
  widgetKey: string;
  title: string;
  subtitle?: string;
  file: string;
  loadWidgetData: (_payload: ts.types.widgets.WidgetGetDataParams) => Promise<ts.types.widgets.WidgetGetDataResponse>;
  params: Params;
  setExternalEmbed: (_json: ts.types.analysis.EmbedChartData) => void;
  setParams: (_p: Params) => void;
};

const Chart: React.FC<ChartProps> = ({
  id,
  readOnly,
  widgetKey,
  title,
  subtitle,
  params,
  setParams,
  file,
  loadWidgetData,
  setExternalEmbed,
}): React.ReactElement => {
  const timeSeries = useSelector((state) => state.resources.time_series);

  const [data, setData] = React.useState(null);
  const [asOfDate, setAsOfDate] = React.useState('');
  const [error, setError] = React.useState<ts.types.common.ApiError>(null);

  const [tableParams, setTableParams] = React.useState<ts.types.components.dataGrid.TableParamsSheet>(
    params.table_params
  );

  const prepareData = (lData: ts.types.widgets.TableData) => {
    const dataWithName = lData.map((el) => ({
      ...el,
      tsname: timeSeries.find((b) => b.handle == el.handle)?.name ?? el.handle,
    }));

    return _.sortBy(
      dataWithName.filter((f) => f.tsname),
      'tsname'
    );
  };

  hooks.useEffectWithoutFirst(() => {
    setParams({
      ...params,
      table_params: { ...tableParams },
    });
  }, [tableParams]);

  const loadData = async () => {
    try {
      const response = await loadWidgetData({
        data: {
          file,
          query: ['type', 'equals', 'value'],
        },
      });
      if (response) {
        setAsOfDate(response?.data?.[0]?.as_of as string);
        const localData = {
          [SHEETS_ENUM.CUM_RET]: prepareData(response.data.filter((l) => l.stat_type == SHEETS_ENUM.CUM_RET)),
          [SHEETS_ENUM.RET]: prepareData(response.data.filter((l) => l.stat_type == SHEETS_ENUM.RET)),
          [SHEETS_ENUM.RISK]: prepareData(response.data.filter((l) => l.stat_type == SHEETS_ENUM.RISK)),
          [SHEETS_ENUM.ADJ]: prepareData(response.data.filter((l) => l.stat_type == SHEETS_ENUM.ADJ)),
        };
        setData(localData);
      }
    } catch (err) {
      setError(err as ts.types.common.ApiError);
    }
  };

  React.useEffect(() => {
    if (file) loadData();
    return () => {
      setData(null);
    };
  }, [file]);

  const tableHeader = () => (
    <mui.core.Box display="flex">
      <mui.core.Box display="flex" flexGrow={1}>
        <ui.InfoRow title="As of">{asOfDate}</ui.InfoRow>
      </mui.core.Box>
    </mui.core.Box>
  );

  const getCustomColumns = React.useCallback(
    (sheet: SHEETS_ENUM) => (colkeys: string[], preferencesColumns: ts.types.components.dataGrid.ColumnPreferences) => {
      let cols = getColumns(
        colkeys.map((v) => v.split('|')[0]),
        preferencesColumns?.[ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.SUMMARY],
        columns.timeSeriesSummaryStatsReportColumns[ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.SUMMARY]
      );

      cols = cols.map((c) => ({
        ...c,
        key: colkeys.find((ck) => ck.includes(c.key)),
        formatter:
          sheet == SHEETS_ENUM.ADJ && c.filter == 'number'
            ? helpers.tableFormatters.formatTo('float', {
                align: 'right',
                roundDigits: 2,
              })
            : c.formatter,
      }));
      return cols;
    },
    []
  );

  const getHeatMappedCols = React.useCallback(
    (colkeys: string[], preferencesColumns: ts.types.components.dataGrid.ColumnPreferences) => {
      const cols = getColumns(
        colkeys.map((v) => v.split('|')[0]),
        preferencesColumns?.[ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.SUMMARY],
        columns.timeSeriesSummaryStatsReportColumns[ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.SUMMARY]
      );

      return cols
        .filter((c) => c.filter == 'number')
        .map((c) => c.key)
        .map((c) => colkeys.find((ck) => ck.startsWith(c)));
    },
    [params]
  );

  const setTableEmbed = (data: Record<string, ts.types.analysis.SyncTableEmbedMetadata>) => {
    const args = Object.fromEntries(
      Object.entries(data ?? {}).map(([sheet, sheetData]) => {
        return [
          sheet,
          {
            headerEls: [{ key: 'As of', value: asOfDate }],
            formatter: {
              number: {
                multiplier: sheet == sheetTitles[SHEETS_ENUM.ADJ] ? undefined : 100,
                to: 'float',
                align: 'right',
                roundDigits: 2,
                suffix: sheet == sheetTitles[SHEETS_ENUM.ADJ] ? undefined : '%',
              },
              string: {
                to: 'string',
                align: 'left',
              },
              date: {
                to: 'date',
                align: 'left',
                format: 'YYYY-MM-DD',
              },
            },
            ...sheetData,
          },
        ];
      })
    ) as ts.types.analysis.SyncTableEmbedData;

    setExternalEmbed({
      id,
      widgetTitle: title,
      widgetSubTitle: subtitle,
      chart_type: 'sync-table',
      args: helpers.embed.argsToStringifiable(args),
    });
  };

  const statToSheet = {
    risk: SHEETS_ENUM.RISK,
    return: SHEETS_ENUM.RET,
    adjusted: SHEETS_ENUM.ADJ,
    cumulative_returns: SHEETS_ENUM.CUM_RET,
  } as Record<(typeof params.statistics_types)[0], string>;
  const selectedSheets = params.statistics_types.map((v) => statToSheet[v]);

  const renderChart = () => {
    return (
      <mui.core.Box
        id={id}
        key={widgetKey}
        sx={{
          height: 'calc(100% - 1px)',
          '& .rdg-light': { paddingBottom: '60px' },
        }}
      >
        <Tabular<SHEETS_ENUM>
          // Pick the tabs that were selected by the user
          data={
            data
              ? (_.pick(
                  {
                    [SHEETS_ENUM.CUM_RET]: data?.[SHEETS_ENUM.CUM_RET],
                    [SHEETS_ENUM.RET]: data?.[SHEETS_ENUM.RET],
                    [SHEETS_ENUM.RISK]: data?.[SHEETS_ENUM.RISK],
                    [SHEETS_ENUM.ADJ]: data?.[SHEETS_ENUM.ADJ],
                  },
                  selectedSheets
                ) as Record<SHEETS_ENUM, ts.types.widgets.TableData>)
              : null
          }
          getColumns={{
            ..._.pick(
              {
                [SHEETS_ENUM.CUM_RET]: getCustomColumns(SHEETS_ENUM.CUM_RET),
                [SHEETS_ENUM.RET]: getCustomColumns(SHEETS_ENUM.RET),
                [SHEETS_ENUM.RISK]: getCustomColumns(SHEETS_ENUM.RISK),
                [SHEETS_ENUM.ADJ]: getCustomColumns(SHEETS_ENUM.ADJ),
              },
              selectedSheets
            ),
          }}
          customToolbarBySheet={{
            [SHEETS_ENUM.CUM_RET]: tableHeader,
            [SHEETS_ENUM.RET]: tableHeader,
            [SHEETS_ENUM.RISK]: tableHeader,
            [SHEETS_ENUM.ADJ]: tableHeader,
          }}
          sheetTitles={{
            [SHEETS_ENUM.CUM_RET]: sheetTitles[SHEETS_ENUM.CUM_RET],
            [SHEETS_ENUM.RET]: sheetTitles[SHEETS_ENUM.RET],
            [SHEETS_ENUM.RISK]: sheetTitles[SHEETS_ENUM.RISK],
            [SHEETS_ENUM.ADJ]: sheetTitles[SHEETS_ENUM.ADJ],
          }}
          getHeatMappedCols={{
            [SHEETS_ENUM.CUM_RET]: getHeatMappedCols,
            [SHEETS_ENUM.RET]: getHeatMappedCols,
            [SHEETS_ENUM.RISK]: getHeatMappedCols,
            [SHEETS_ENUM.ADJ]: getHeatMappedCols,
          }}
          preferences={{
            preferencesSourceWidgetId: id,
            preferenceKey: ts.enums.PREFERENCES_KEY_ENUM.TIME_SERIES_COMPARE_SUMMARY_STATS_COLS,
            preferenceTab: ts.enums.PREFERENCES_INNER_TABS_ENUM.TIME_SERIES_COMPARE_SUMMARY_STATS_COLS,
          }}
          fullScreen
          tableParams={tableParams}
          triggerWidthChange={params.fullWidth}
          setTableParams={{
            [SHEETS_ENUM.CUM_RET]: (v) => setTableParams((t) => ({ ...t, [SHEETS_ENUM.CUM_RET]: v })),
            [SHEETS_ENUM.RET]: (v) => setTableParams((t) => ({ ...t, [SHEETS_ENUM.RET]: v })),
            [SHEETS_ENUM.RISK]: (v) => setTableParams((t) => ({ ...t, [SHEETS_ENUM.RISK]: v })),
            [SHEETS_ENUM.ADJ]: (v) => setTableParams((t) => ({ ...t, [SHEETS_ENUM.ADJ]: v })),
          }}
          heatMapColors={{
            [SHEETS_ENUM.CUM_RET]: undefined,
            [SHEETS_ENUM.RET]: undefined,
            [SHEETS_ENUM.RISK]: {
              startColor: [120, 214, 145],
              endColor: [219, 110, 110],
            },
            [SHEETS_ENUM.ADJ]: undefined,
          }}
          setExternalEmbed={setTableEmbed}
          apiError={error}
          readOnly={readOnly}
        />
      </mui.core.Box>
    );
  };

  return (
    <mui.core.Box display="flex" flexDirection="column" style={{ height: '100%' }}>
      {!_.isNil(file) && renderChart()}
    </mui.core.Box>
  );
};

export default Chart;
