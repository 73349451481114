import React from 'react';

import * as enums from '../../../enums';
import { mui, useSelector } from '../../../libs';
import * as types from '../../../types';
import ResourceAutocomplete from '../../resource-autocomplete';

type RiskModelProps = {
  value: types.widgets.common.BucketValue;
  setValue: (_v: types.widgets.common.BucketValue) => void;
};

const RiskSelector: React.FC<RiskModelProps> = ({ value, setValue }): React.ReactElement => {
  const [riskCategories, setRiskCategories] = React.useState([]);

  const resources = useSelector((state: types.BaseStore) => state.resources);

  const getRiskCategories = () => {
    if (!value?.risk_model_id) return;
    const categories = resources.risk_models
      .find((rm) => rm.id == value.risk_model_id)
      ?.definition?.categories?.filter((r) => r.name != 'market');

    setRiskCategories(categories);
  };

  React.useEffect(() => {
    getRiskCategories();
  }, [value]);

  return (
    <>
      <mui.core.Grid item xs={12} md={6}>
        <ResourceAutocomplete<types.common.ResourceExpanded>
          value={value?.risk_model_id}
          setValue={(id) =>
            setValue({
              type: enums.BUCKET_TYPES_ENUM.RISK_MODEL,
              risk_model_id: id,
            })
          }
          type={enums.RESOURCES_TYPES_ENUM.RISK_MODEL}
          inputProps={{
            fullWidth: true,
            label: 'Risk Model',
            variant: 'outlined',
            size: 'small',
            required: true,
          }}
        />
      </mui.core.Grid>

      <mui.core.Grid item xs={12} md={6}>
        <mui.core.Autocomplete
          options={riskCategories}
          getOptionLabel={(option) => option?.name || option || ''}
          isOptionEqualToValue={(option, val) => option.handle == val}
          value={riskCategories.find((r) => r.handle == value?.value)?.name || ''}
          onChange={(e, val) => setValue({ ...value, value: val?.handle })}
          disableClearable
          renderInput={(p) => <mui.core.TextField {...p} label="Value" size="small" variant="outlined" fullWidth />}
        />
      </mui.core.Grid>
    </>
  );
};

export default RiskSelector;
