import { _, hooks, mui, React, ts, ui, useSelector } from '_core';

import * as columns from 'views/preferences/columns-config';
import AsyncTabular from 'views/report/tables/async-tabular';

import { Params, SHOW_OPTIONS } from './types';
import { getColumns } from '../../common/charts/utils';

type ChartProps = {
  id: string;
  loadWidgetData: (_payload: ts.types.widgets.WidgetGetDataParams) => Promise<ts.types.widgets.WidgetGetDataResponse>;
  params: Params;
  setParams: (_p: Params) => void;
  fullScreen: boolean;
  goFullScreen: (_v: string) => void;
  tradeList: string;
  queryParams?: { initial?: string };
  context?: ts.types.rebalance.RebalanceAnalysisContext;
};

const Chart: React.FC<ChartProps> = ({
  id,
  loadWidgetData,
  params,
  setParams,
  tradeList,
  fullScreen,
  goFullScreen,
  queryParams,
  context,
}) => {
  const container = React.createRef();
  const resources = useSelector((state) => state.resources);

  const [show, setShow] = React.useState(SHOW_OPTIONS.ALL);
  const [filter, setFilter] = React.useState<ts.types.components.dataGrid.ValueFilters>([]);

  const [tableParams, setTableParams] = React.useState<ts.types.components.dataGrid.TableParamsSheet>(
    params.table_params
  );

  const [tableDtypes, setTableDtypes] = React.useState<ts.types.components.dataGrid.TableDtypesSheet>(
    params.table_dtypes
  );

  hooks.useEffectWithoutFirst(() => {
    setParams({
      ...params,
      table_params: { ...tableParams },
      table_dtypes: { ...tableDtypes },
    });
  }, [tableParams, tableDtypes]);

  const loadData = async (
    offset: number,
    orderBy: ts.types.components.dataGrid.OrderBy,
    filters: ts.types.components.dataGrid.ValueFilters,
    logicOperator: mui.dataGrid.GridLogicOperator
  ) => {
    const response = await loadWidgetData({
      data: {
        file: tradeList,
        pagination: { offset, limit: 100 },
        order_by: _.isEmpty(orderBy) ? { column: 'traded_value_usd', sort: 'desc' } : orderBy,
        values_filter: filters,
        filter_conjunction: logicOperator,
      },
    });
    return response;
  };

  hooks.useEffectWithoutFirst(() => {
    if (show == SHOW_OPTIONS.BUY) setFilter([{ column: 'side', value: SHOW_OPTIONS.BUY }]);
    else if (show == SHOW_OPTIONS.SELL) setFilter([{ column: 'side', value: SHOW_OPTIONS.SELL }]);
    else if (show == SHOW_OPTIONS.COVER) setFilter([{ column: 'side', value: SHOW_OPTIONS.COVER }]);
    else if (show == SHOW_OPTIONS.SHORT) setFilter([{ column: 'side', value: SHOW_OPTIONS.SHORT }]);
    else if (show == SHOW_OPTIONS.CROSSOVER) setFilter([{ column: 'is_crossover', value: true }]);
    else setFilter([]);
  }, [show]);

  const downloadData = async (
    rename: Record<string, string>,
    columnOrder: string[],
    filters: ts.types.components.dataGrid.ValueFilters
  ) => {
    const response = await loadWidgetData({
      data: {
        file: tradeList,
        file_name: 'tradeList',
        rename,
        column_order: columnOrder,
        values_filter: filters,
      },
      download: true,
    });
    return response;
  };

  const preferencesButton = () => (
    <mui.core.Box display="flex" width="100%">
      <mui.core.Stack flexDirection="row" flexGrow={1} alignItems="center" gap={4}>
        <ui.InfoRow title="Trade Date">{context.trade_date}</ui.InfoRow>
        <mui.core.RadioGroup
          row
          value={show}
          onChange={(e) => {
            setShow(e.target.value as typeof show);
          }}
          style={{ marginTop: '-5px', display: 'flex' }}
        >
          <mui.core.FormControlLabel
            value={SHOW_OPTIONS.ALL}
            control={<mui.core.Radio color="primary" />}
            label="Show All"
            labelPlacement="end"
          />
          <mui.core.FormControlLabel
            value={SHOW_OPTIONS.BUY}
            control={<mui.core.Radio color="primary" />}
            label="Only Buy"
            labelPlacement="end"
          />
          <mui.core.FormControlLabel
            value={SHOW_OPTIONS.COVER}
            control={<mui.core.Radio color="primary" />}
            label="Only Cover"
            labelPlacement="end"
          />
          <mui.core.FormControlLabel
            value={SHOW_OPTIONS.SELL}
            control={<mui.core.Radio color="primary" />}
            label="Only Sell"
            labelPlacement="end"
          />
          <mui.core.FormControlLabel
            value={SHOW_OPTIONS.SHORT}
            control={<mui.core.Radio color="primary" />}
            label="Only Sell Short"
            labelPlacement="end"
          />
          <mui.core.FormControlLabel
            value={SHOW_OPTIONS.CROSSOVER}
            control={<mui.core.Radio color="primary" />}
            label="Crossover trade"
            labelPlacement="end"
          />
        </mui.core.RadioGroup>
      </mui.core.Stack>
    </mui.core.Box>
  );

  const getClass = (row: ts.types.widgets.TableData[0]) => {
    if (row.side == 'BUY') return 'data-grid-row-green';
    if (row.side == 'SELL') return 'data-grid-row-yellow';
    if (row.side == 'COVER') return 'data-grid-row-blue';
    if (row.side == 'SHORT') return 'data-grid-row-red';
    return undefined;
  };

  const renderChart = () => (
    <>
      <AsyncTabular<ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.MAIN>
        key={tradeList}
        loadData={{
          [ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.MAIN]: loadData,
        }}
        getColumns={{
          [ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.MAIN]: (colKeys, userColumns) =>
            getColumns(
              colKeys,
              userColumns[ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.MAIN],
              columns.portfolioTradeListColumns[ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.MAIN],
              context,
              resources,
              ['period_start', 'period_end']
            ),
        }}
        sheetTitles={{
          [ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.MAIN]: 'Trade List',
        }}
        sheetFilters={{
          [ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.MAIN]: filter,
        }}
        fullScreen={fullScreen}
        goFullScreen={goFullScreen}
        queryParams={queryParams}
        customToolBar={{
          [ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.MAIN]: preferencesButton,
        }}
        downloadFullData={{
          [ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.MAIN]: downloadData,
        }}
        rowClass={{
          [ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.MAIN]: getClass,
        }}
        preferences={{
          preferencesSourceWidgetId: id,
          preferenceKey: ts.enums.PREFERENCES_KEY_ENUM.PORTFOLIO_TRADE_LIST_COLS,
          preferenceTab: ts.enums.PREFERENCES_INNER_TABS_ENUM.PORTFOLIO_TRADE_LIST_COLS,
        }}
        tableParams={tableParams}
        setTableParams={{
          [ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.MAIN]: (v) =>
            setTableParams((t) => ({
              ...t,
              [ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.MAIN]: v,
            })),
        }}
        tableDtypes={tableDtypes}
        setTableDtypes={{
          [ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.MAIN]: (v) =>
            setTableDtypes((t) => ({
              ...t,
              [ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.MAIN]: v,
            })),
        }}
      />
    </>
  );

  return (
    <mui.core.Box display="flex" flexDirection="column" style={{ height: '100%' }} ref={container}>
      {!_.isNil(tradeList) && renderChart()}
    </mui.core.Box>
  );
};

export default Chart;
