import { api, helpers, ts } from '_core';

type ReportDefaultApiResponse = {
  data: ts.types.analysis.ReportDefault;
};

export const overrideReportDefault = async (
  defaultId: number,
  newDefaultData: { definition: ts.types.analysis.AnalysisDefinition },
  preload: () => void,
  callback: (_data: ts.types.analysis.ReportDefault) => void,
  errorCallback: (_error: string) => void
) => {
  try {
    preload();

    const resp: ReportDefaultApiResponse = await api.report_defaults.update(defaultId, newDefaultData);
    callback(resp.data);
  } catch (error) {
    errorCallback(helpers.parseApiError(error as ts.types.common.ApiError));
  }
};

export const createNewReportDefault = async (
  newDefaultData: ts.types.analysis.ReportDefaultDraft,
  preload: () => void,
  callback: (_data: ts.types.analysis.ReportDefault) => void,
  errorCallback: (_error: string) => void
) => {
  try {
    preload();

    const resp = await api.report_defaults.create(newDefaultData);
    callback(resp.data);
  } catch (error) {
    errorCallback(helpers.parseApiError(error as ts.types.common.ApiError));
  }
};
