import * as mui from '@local/finsera-core/src/libs/mui';

const defaultStyle = {
  textAlign: 'left',
  lineHeight: '11px',
  fontSize: '11px',
} as React.CSSProperties;

const smallStyle = {
  textAlign: 'left',
  lineHeight: '0.5rem',
  fontSize: '9.5px',
} as React.CSSProperties;

type MultilineTableHeaderProps = {
  name: string[];
  style?: React.CSSProperties;
  suffix?: string;
};

const MultilineTableHeader: React.FC<MultilineTableHeaderProps> = ({ name, style, suffix }): React.ReactElement => {
  const html = [];

  for (let i = 0; i < name.length; i++) {
    html.push(
      <span key={i} style={name.length > 3 ? smallStyle : style}>
        {name[i]}
      </span>
    );
  }

  return (
    <mui.core.Box display="flex" flexDirection="column" alignItems="flex-start" id="multiline">
      <>
        {html}
        {suffix && (
          <span
            style={{
              textAlign: 'left',
              fontSize: '0.6rem',
              opacity: 0.7,
              marginLeft: '5px',
            }}
          >
            {suffix}
          </span>
        )}
      </>
    </mui.core.Box>
  );
};

MultilineTableHeader.defaultProps = {
  style: defaultStyle,
  suffix: '',
};

export default MultilineTableHeader;
