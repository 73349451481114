import { ts } from '_core';

import { Params } from './types';
import { validate } from './utils';

export default [
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.METRIC_BASKET_BENCHMARK_RETURNS,
    form: true,
    helpLabel: 'c5fu9sxw',
    title: 'Benchmark Returns',
    neededFiles: ['portfolios'],
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.BASKET_ADHOC],

    validate,
    getAdhocData: (pathFile, params: Params, _context, resources: ts.StoreState['resources']) => {
      const benchmarkName = resources.baskets.find((b) => b.id == params.basket_ids?.[0])?.name ?? 'Benchmark';
      return {
        name: `${benchmarkName} Returns`,
        file_name: pathFile,
        description: `This file contains the returns of the ${benchmarkName} benchmark.`,
        columns: [
          {
            key: 'period_start',
            name: 'start',
            description: 'Start date of the period.',
            type: 'date',
          },
          { key: 'period_end', name: 'end', description: 'End date of the period.', type: 'date' },
          {
            key: 'return',
            name: 'returns',
            description: 'Returns of the portfolio.',
            type: 'float',
          },
        ],
        web_api_args: {
          values_filter: [
            {
              column: 'stage',
              value: 'after_costs',
              operator: 'equals',
            },
          ],
        },
      };
    },
  },
] satisfies ts.types.analysis.WidgetConfig[];
