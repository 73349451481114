import { ts } from '_core';

import { validate } from './helpers';

export default [
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.TIME_SERIES_LINE_COMPARE_DIFF_SCALE,
    title: 'Time Series Values over Time (different scale)',
    helpLabel: '7876yike',
    generalAnalysisReportHelp: {
      document: ts.enums.UI_RESOURCE_TYPE_ENUM.TIME_SERIES,
      contentPath: `/${ts.enums.TIME_SERIES_ANALYSIS_TYPE_ENUM.ANALYSIS}`,
    },
    form: true,
    neededFiles: ['time_series_values_comparison'],
    tags: [
      ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.GENERAL,
      ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.TIME_SERIES,
    ],

    validate,
  },
] satisfies ts.types.analysis.WidgetConfig[];
