import { mui, React, ts, uitheme } from '_core';

import SyncTable from './table';
import EmbedHeader from '../embed-header';

type SyncTableRendererProps = { widgetData: ts.types.analysis.EmbedChartData; download: boolean };
const SyncTableRenderer = ({ widgetData, download }: SyncTableRendererProps) => {
  const args = widgetData.args as ts.types.analysis.SyncTableEmbedData;
  const sheets = Object.keys(args);
  const [currentSheet, setCurrentSheet] = React.useState(sheets[0]);

  const hasTabs = sheets.length > 1;

  const { REPORT_TAB_HEIGHT } = uitheme.layoutSize;

  return (
    <mui.core.Box height="100%" display="flex" flexDirection="column" bgcolor="white" sx={{ overflowY: 'hidden' }}>
      <EmbedHeader title={widgetData.widgetTitle} subtitle={widgetData.widgetSubTitle} fullScreen={false} />

      <div
        style={{
          width: '100%',
          height: `calc(100% - ${REPORT_TAB_HEIGHT * (sheets.length > 1 ? 2 : 1)}px)`,
        }}
      >
        <div style={{ height: '100%' }}>
          <SyncTable key={currentSheet} tableData={args[currentSheet]} download={download} />
        </div>
      </div>

      {hasTabs && (
        <mui.core.Tabs value={currentSheet} aria-label="tabs" className="tabular-tabs">
          {sheets.map((key) => (
            <mui.core.Tab
              key={key}
              label={<mui.core.Box display="flex">{key}</mui.core.Box>}
              value={key}
              onClick={() => setCurrentSheet(key)}
            />
          ))}
        </mui.core.Tabs>
      )}
    </mui.core.Box>
  );
};

export default SyncTableRenderer;
