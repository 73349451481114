import { dnd, mui } from '../libs';
import { DRAG_DROP_HEIGHT, LARGE_DRAG_DROP_HEIGHT } from '../theme/layout-size';

type DragItemProps = {
  title: string;
  index: number;
  removeColumn?: () => void;
  disableRemove?: boolean;
  customAction?: JSX.Element;
  size?: 'default' | 'large';
};

/**
 * DragItem component that displays a draggable item.
 *
 * @param title - The title of the drag item
 * @param index - The index of the drag item in the list
 * @param removeColumn - Optional function to remove the column
 * @param disableRemove - Optional flag to disable the remove action
 * @param customAction - Optional custom action element to render
 * @param size - Optional size of the drag item, can be 'default' or 'large'
 *
 * @returns A React component that displays a draggable item.
 */

const DragItem: React.FC<DragItemProps> = ({
  title,
  removeColumn,
  index,
  disableRemove,
  customAction,
  size = 'default',
}): React.ReactElement => {
  const theme = mui.styles.useTheme() as mui.core.Theme;

  return (
    <dnd.GridItem key={index}>
      <div style={{ width: '100%', height: '100%' }}>
        <mui.core.Card
          variant="outlined"
          sx={{
            cursor: 'move',
            position: 'relative',
            padding: '0 8px',
            height: size == 'default' ? DRAG_DROP_HEIGHT - 8 : LARGE_DRAG_DROP_HEIGHT - 8,
            mb: 0,
          }}
        >
          <mui.core.Box display="flex" alignItems="center" justifyContent="center" height="100%" gap={2}>
            <mui.icons.DragHandle sx={{ color: theme.palette.text.secondary, fontSize: '14px' }} />
            <mui.core.Box
              flexGrow={1}
              sx={{
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                minWidth: '0',
                overflow: 'hidden',
                fontSize: '0.7rem',
              }}
            >
              <mui.core.Typography variant="body2" fontWeight="500">
                {title}
              </mui.core.Typography>
            </mui.core.Box>
            {customAction && customAction}
            {!disableRemove && (
              <mui.core.IconButton aria-label="delete" onClick={removeColumn} size="small">
                <mui.icons.Close style={{ fontSize: '0.8rem' }} />
              </mui.core.IconButton>
            )}
          </mui.core.Box>
        </mui.core.Card>
      </div>
    </dnd.GridItem>
  );
};

export default DragItem;
