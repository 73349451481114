import React from 'react';

type ErrorBoundaryProps = {
  children: React.ReactElement;
  setOnError: (_e: boolean) => void;
};

/**
 * ControlledError component that catches errors in its children and sets an error state.
 * This does not report errors to sentry as it is expected to be a controlled or expected error.
 *
 * @param children - The child components to be rendered within the ControlledError.
 * @param setOnError - The function to set the error state.
 *
 * @returns A React component that catches errors in its children and sets an error state.
 */
class ControlledError extends React.Component<ErrorBoundaryProps> {
  constructor(props: ErrorBoundaryProps) {
    super(props);

    props.setOnError(false);
  }

  componentDidCatch(): void {
    this.props.setOnError(true);
  }

  render() {
    return this.props.children;
  }
}

export default ControlledError;
