import { mui, React } from '_core';

const SelectViewField = ({ value, setValue }: { value: string; setValue: (_v: string) => void }) => (
  <mui.core.Box display="inline-flex" width={200} mr={2}>
    <mui.core.TextField
      select
      label="Selected View"
      variant="outlined"
      size="small"
      value={value}
      onChange={(e) => {
        setValue(e.target.value);
      }}
      fullWidth
    >
      <mui.core.MenuItem value="attributed_returns">Attributed Returns</mui.core.MenuItem>
      <mui.core.MenuItem value="factor_exposures">Factor Exposures</mui.core.MenuItem>
      <mui.core.MenuItem value="factor_returns">Factor Returns</mui.core.MenuItem>
    </mui.core.TextField>
  </mui.core.Box>
);

export default SelectViewField;
