import { _, helpers, mui, React, ts, ui } from '_core';

import { useUiStyles } from '@local/finsera-core/src/hooks';
import { GridEventListener, useGridApiContext } from '@mui/x-data-grid-premium';
import { useGridApiEventHandler } from '@mui/x-data-grid-premium';

import { TabularContext } from '../tabular-context';

type ToolbarProps = {
  alert: ts.types.common.Alert;
  hideFilters: boolean;
  hideDensity: boolean;
  hideDataCount: boolean;
  hideColumnWidth: boolean;
  hideToolbar: boolean;
  hideDownload: boolean;
  customToolbar: React.FC<{ columns: string[] }>;

  dataCount?: number;
  responseColumns?: React.MutableRefObject<string[]>;
  downloading?: boolean;
  loading?: boolean;
  loadOnX?: boolean;
  filteredRows?: Record<string, any>[];
  mappedColumns?: any[];
  handleScrollX?: (_e: React.UIEvent<HTMLDivElement>) => void;
  handleDownload?: () => void;
  handleCopyToClipboard?: () => Promise<void>;
};

const Toolbar = ({
  alert,
  dataCount,
  responseColumns,
  loading,
  downloading,
  filteredRows,
  mappedColumns,
  handleScrollX,
  handleDownload,
  handleCopyToClipboard,

  customToolbar,
  hideFilters,
  hideToolbar,
  hideDensity,
  hideColumnWidth,
  hideDownload,
  hideDataCount,
  loadOnX,
}: ToolbarProps) => {
  const tabularContext = React.useContext(TabularContext);
  const {
    preferenceKey,
    setCurrentView,
    preferenceOptions,
    selectedPreference,
    openPreferences,
    fixedWidth,
    setFixedWidth,
  } = tabularContext;

  const uiStyles = useUiStyles();
  const theme = mui.styles.useTheme() as mui.core.Theme;

  const apiRef = useGridApiContext();

  const handleEvent: GridEventListener<'scrollPositionChange'> = (_, event) => {
    if (loadOnX) handleScrollX(event as React.UIEvent<HTMLDivElement>);
  };

  useGridApiEventHandler(apiRef, 'scrollPositionChange', handleEvent);

  const errorMessage = React.useMemo(() => {
    const defaultMsg = 'Something went wrong. Please try again later.';
    if (typeof alert?.message === 'string') return alert.message || defaultMsg;
    return defaultMsg;
  }, [alert]);

  if (hideToolbar) return;

  const handleLocalDownload = () => {
    try {
      const csvData = helpers.csv
        .toCsv(filteredRows, mappedColumns, ts.enums.SEPARATORS_ENUM.COMMA, false, true)
        .join('');
      // Create a Blob from the CSV data
      const blob = new Blob([csvData], { type: 'text/csv' });

      // Create a link element
      const link = document.createElement('a');

      // Set the link's properties
      link.href = URL.createObjectURL(blob);
      link.download = 'data.csv';

      // Append the link to the document
      document.body.appendChild(link);

      // Trigger a click event on the link
      link.click();

      // Remove the link from the document
      document.body.removeChild(link);
    } catch {
      console.log('Unable to dowlonad!');
    }
  };

  return (
    <mui.core.Box sx={{ width: '100%' }}>
      <mui.core.Box display="flex" flex={1} mb={2} p={2} alignItems="center">
        {!_.isEmpty(alert) && (
          <mui.core.Box mr={2} display="flex" alignItems="center" sx={uiStyles.hidePrint}>
            <mui.core.Tooltip title={errorMessage} arrow>
              <mui.core.Chip
                color={alert.severity == 'warning' ? 'warning' : 'error'}
                label={alert.severity == 'warning' ? errorMessage : 'Failed'}
                icon={<mui.icons.ErrorOutline sx={{ fontSize: '0.9rem' }} />}
              />
            </mui.core.Tooltip>
          </mui.core.Box>
        )}

        <mui.core.Box flexGrow={1}>
          <mui.core.Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {customToolbar &&
              React.createElement(customToolbar, {
                columns: responseColumns?.current ?? [],
              })}
          </mui.core.Box>
        </mui.core.Box>

        <mui.core.Box
          pl={2}
          display="flex"
          sx={{
            ...uiStyles.hidePrint,
            button: {
              border: `1px solid ${theme.palette.primaryLight.light}`,
              ml: 1,
            },
            svg: { fontSize: '14px!important' },
          }}
        >
          {!hideDataCount && dataCount && (
            <ui.InfoRow title={loadOnX ? 'Columns' : 'Rows'} loading={loading}>
              {dataCount}
            </ui.InfoRow>
          )}

          {preferenceKey && preferenceOptions.length > 1 && (
            <mui.core.Box width="150px" ml={3}>
              <mui.core.Autocomplete<ts.types.userPreference.UserPreferenceDraft, false, true>
                options={preferenceOptions}
                value={selectedPreference ?? { id: null, title: 'Default' }}
                getOptionLabel={(v) => (v as ts.types.userPreference.UserPreferenceDraft).title}
                onChange={(_e, val) => {
                  setCurrentView((val as ts.types.userPreference.UserPreferenceDraft).id);
                }}
                isOptionEqualToValue={(opt, val) => {
                  return opt.id == val.id;
                }}
                disableClearable
                renderInput={(params) => (
                  <mui.core.TextField {...params} variant="outlined" required size="small" fullWidth />
                )}
              />
            </mui.core.Box>
          )}
          <mui.core.Stack flexDirection="row" ml={3}>
            {!hideDownload && (
              <mui.core.Box>
                <mui.dataGrid.GridToolbarExportContainer>
                  {handleCopyToClipboard && (
                    <ui.CopyToClipboard handleCopyToClipboard={handleCopyToClipboard} disabled={downloading} />
                  )}
                  <mui.core.MenuItem
                    disabled={downloading}
                    onClick={() => (handleDownload ? handleDownload() : handleLocalDownload())}
                  >
                    Download as CSV
                  </mui.core.MenuItem>
                </mui.dataGrid.GridToolbarExportContainer>
              </mui.core.Box>
            )}
            {preferenceKey && (
              <mui.core.Box
                onClick={() => {
                  openPreferences();
                }}
              >
                <mui.dataGrid.GridToolbarColumnsButton />
              </mui.core.Box>
            )}
            {!hideDensity && <mui.dataGrid.GridToolbarDensitySelector />}
            {!hideFilters && (
              <mui.core.MenuItem
                sx={{
                  display: 'inline-flex',
                  padding: 0,
                  '&:hover': {
                    backgroundColor: 'transparent',
                  },
                }}
              >
                <mui.dataGrid.GridToolbarFilterButton />
              </mui.core.MenuItem>
            )}
            {!hideColumnWidth && <ui.ColumnWidth fixedWidth={fixedWidth} setFixedWidth={setFixedWidth} />}
          </mui.core.Stack>
        </mui.core.Box>
      </mui.core.Box>
    </mui.core.Box>
  );
};

export default Toolbar;
