import { am5, am5xy } from '@local/finsera-core/src/libs';
import * as types from '@local/finsera-core/src/types';

export const createAxes = ({
  root,
  chart,
  data,
  xAxisField,
}: {
  root: am5.Root;
  chart: am5xy.XYChart;
  data: types.widgets.TableData;
  xAxisField: string;
}) => {
  const xAxis = chart.xAxes.push(
    am5xy.CategoryAxis.new(root, {
      categoryField: xAxisField,
      startLocation: 0.5,
      endLocation: 0.5,
      renderer: am5xy.AxisRendererX.new(root, {
        minorGridEnabled: true,
        minGridDistance: 70,
      }),
      tooltip: am5.Tooltip.new(root, {}),
    })
  );

  xAxis.get('renderer').labels.template.setAll({
    fontSize: 11,
    textAlign: 'center',
  });

  xAxis.data.setAll(data);

  const yAxis = chart.yAxes.push(
    am5xy.ValueAxis.new(root, {
      min: 0,
      max: 100,
      calculateTotals: true,
      strictMinMax: true,
      renderer: am5xy.AxisRendererY.new(root, {
        minGridDistance: 30,
      }),
    })
  );

  yAxis.get('renderer').labels.template.setAll({
    fontSize: 11,
  });

  return { xAxis, yAxis };
};
