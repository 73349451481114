import { _, api, config, helpers, ts } from '_core';

type UniverseApiResponse = {
  data: ts.types.universe.Universe;
};

export const createUniverse = async (
  newData: ts.types.universe.UniverseDraft,
  callback: (_data: ts.types.universe.Universe) => void,
  errorCallback: (_error: string) => void
) => {
  try {
    const resp: UniverseApiResponse = await api.universes.create(newData);
    callback(resp.data);
  } catch (error) {
    errorCallback(`Error creating universe - ${helpers.parseApiError(error as ts.types.common.ApiError)}`);
  }
};

export const updateUniverse = async (
  id: number,
  newData: ts.types.universe.UniverseDraft,
  callback: (_data: ts.types.universe.Universe) => void,
  errorCallback: (_error: string) => void
) => {
  try {
    const resp: UniverseApiResponse = await api.universes.update(id, { ...newData });
    callback(resp.data);
  } catch (error) {
    errorCallback(`Error updating universe - ${helpers.parseApiError(error as ts.types.common.ApiError)}`);
  }
};

export const keepCurrent = async (
  universe: ts.types.universe.UniverseDraft,
  callback: (_data: ts.types.universe.Universe) => void,
  errorCallback: (_error: string) => void
) => {
  try {
    if (universe.shared) return;
    const resp = await api.dates.keep_current({
      resource_id: universe.id,
      resource_type: 'universes',
      countries: universe.definition.quotation_countries,
      max_date: config.features.end_date,
    });
    callback(resp.resource);
  } catch (error) {
    errorCallback(`Error updating universe dates - ${helpers.parseApiError(error as ts.types.common.ApiError)}`);
  }
};

export const updateDefinition = async (
  id: number,
  definition: ts.types.universe.DefinitionDraft,
  isValid: boolean,
  callback: (_data: ts.types.universe.Universe) => void,
  errorCallback: (_error: string) => void
) => {
  try {
    const def = { ...definition };
    if (def?.assets?.length <= 1) def.assets = [];

    if (def.source?.type == 'universes') delete def.quotation_countries;

    const updateData = {
      definition,
      is_valid: isValid,
      skip_signature_check: !isValid,
    } as ts.types.universe.UniverseDraft;

    if (def.source?.type == 'etf' || def.source?.type == 'index' || def.source?.type == 'assets')
      updateData.frequency = ts.enums.FREQUENCY_ENUM.DAILY;

    const resp = await api.universes.update(id, updateData, true);
    callback(resp.data);
  } catch (error) {
    errorCallback(`Error updating the definition - ${helpers.parseApiError(error as ts.types.common.ApiError)}`);
  }
};

export const updateUiMetadata = async (
  universe: ts.types.universe.Universe,
  uiMetaData: ts.types.universe.UiMetaData,
  callback: (_data: ts.types.universe.Universe) => void,
  errorCallback: (_error: string) => void
) => {
  if (universe.shared) return;
  try {
    const updateData = {
      ui_metadata: uiMetaData,
      skip_signature_check: true,
    } as ts.types.universe.UniverseDraft;
    const resp = await api.universes.update(universe.id, updateData);
    callback(resp.data);
  } catch (error) {
    errorCallback(`Error updating the resource metadata - ${helpers.parseApiError(error as ts.types.common.ApiError)}`);
  }
};

const createAnalyses = async (id: number, names: ts.enums.UNIVERSE_ANALYSIS_TYPE_ENUM[]) => {
  const analyses = [];
  for (const name of names) {
    let analysisDefinition = { widgets: [] } as ts.types.analysis.AnalysisDefinition;

    if (name == ts.enums.UNIVERSE_ANALYSIS_TYPE_ENUM.SNAPSHOT) {
      // default preview value
      analysisDefinition = {
        mode: ts.enums.ANALYSIS_MODE_ENUM.FULLSCREEN,
        widgets: [
          {
            id: helpers.gibberishGenerator.stringGenerator(11),
            key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.UNIVERSE_PREVIEW_SNAPSHOT,
            params: {},
          },
        ],
      };
    }

    if (name == ts.enums.UNIVERSE_ANALYSIS_TYPE_ENUM.DATES_COMPARISON) {
      // default preview value
      analysisDefinition = {
        mode: ts.enums.ANALYSIS_MODE_ENUM.FULLSCREEN,
        widgets: [
          {
            id: helpers.gibberishGenerator.stringGenerator(11),
            key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.UNIVERSE_PREVIEW_DATE_COMPARISON,
            params: {},
          },
        ],
      };
    }

    if (name == ts.enums.UNIVERSE_ANALYSIS_TYPE_ENUM.ASSET_PERTINENCE) {
      // default preview value
      analysisDefinition = {
        mode: ts.enums.ANALYSIS_MODE_ENUM.FULLSCREEN,
        widgets: [
          {
            id: helpers.gibberishGenerator.stringGenerator(11),
            key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.UNIVERSE_PREVIEW_ASSET_PERTINENCE,
            params: {},
          },
        ],
      };
    }

    if (name == ts.enums.UNIVERSE_ANALYSIS_TYPE_ENUM.LINKED_ASSETS) {
      // default preview value
      analysisDefinition = {
        mode: ts.enums.ANALYSIS_MODE_ENUM.FULLSCREEN,
        widgets: [
          {
            id: helpers.gibberishGenerator.stringGenerator(11),
            key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.UNIVERSE_PREVIEW_LINKED_ASSETS,
            params: {},
          },
        ],
      };
    }

    // create analysis
    const response = await api.universe_analyses.create({
      report_definition: analysisDefinition,
      universe_id: id,
      name,
    });
    analyses.push(response.data.name);
  }
  return analyses;
};

// Load analyses, and if we don't have them, create them
export const loadAnalyses = async (
  universe: ts.types.universe.Universe,
  setAnalysesNames: (_data: ts.enums.UNIVERSE_ANALYSIS_TYPE_ENUM[]) => void,
  errorCallback: (_error: string) => void
) => {
  const analyses = Object.values(ts.enums.UNIVERSE_ANALYSIS_TYPE_ENUM);
  try {
    const resp = await api.universe_analyses.search({
      ignore_user_scope: universe.shared,
      query: ['$and', ['universe_id', '=', universe.id]],
      include: 'id,name',
    });

    let localNames = (resp.data || []).map(
      (a: ts.types.analysis.ResourceAnalysis) => a.name
    ) as ts.enums.UNIVERSE_ANALYSIS_TYPE_ENUM[];

    const toCreate = _.without(analyses, ...localNames);
    if (!_.isEmpty(toCreate)) {
      const newNames = (await createAnalyses(universe.id, toCreate)) as ts.enums.UNIVERSE_ANALYSIS_TYPE_ENUM[];
      localNames = localNames.concat(newNames);
    }

    setAnalysesNames(localNames);
  } catch (error) {
    errorCallback(`Error updating universe - ${helpers.parseApiError(error as ts.types.common.ApiError)}`);
  }
};
