import React from 'react';

import QB from './components/query-builder';
import * as types from '../../types';

type QueryBuilderProps = {
  definition: any[];
  setDefinition: (_d: any[]) => void;
  config: types.components.queryBuilder.QbConfig;
};

/**
 * Component that provides a query builder interface for constructing complex queries
 * 
 * @param definition - The current query definition array
 * @param setDefinition - Callback to update the query definition
 * @param config - Configuration object for the query builder
 * 
 * @returns Rendered query builder component
 */
const QueryBuilder: React.FC<QueryBuilderProps> = ({ 
  definition = ['$and'], 
  setDefinition = null, 
  config 
}): React.ReactElement => (
  <QB definition={definition} setDefinition={setDefinition} config={config} />
);

QueryBuilder.defaultProps = {
  definition: ['$and'],
  setDefinition: null,
};

export default QueryBuilder;
