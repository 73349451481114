import { ts } from '_core';


export default [
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.ALPHA_MODEL_OPTIMIZED_TRUE_PREDICTED,
    title: 'True Vs. Predicted',
    helpLabel: 'skuzbvi2',
    form: false,
    neededFiles: ['optimized_true_vs_predicted'],
    tags: [
      ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.ALPHA_MODEL,
      ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.OPTIMIZED_ALPHA_MODEL
    ],
  },
] satisfies ts.types.analysis.WidgetConfig[];
