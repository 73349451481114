import { ts } from '_core';

import { validate } from './helpers';
import { INTERACTION_PARAMS } from './types';

export default [
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.REBALANCE_PORTFOLIO_HOLDINGS,
    form: true,
    title: 'Holdings',
    neededFiles: ['rebalance_initial_holdings'],
    defaultParams: { exclude_zeroes: true },
    interactionParams: Object.values(INTERACTION_PARAMS),
    clearInteractionOnRegenerate: ['table_dtypes'],
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.REBALANCE_PORTFOLIO_HOLDINGS],

    validate,
    helpLabel: 'avoo8ojn',
  },
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.PORTFOLIO_HOLDINGS,
    form: true,
    title: 'Details',
    neededFiles: ['portfolio_initial_holdings'],
    defaultParams: { exclude_zeroes: true },
    interactionParams: Object.values(INTERACTION_PARAMS),
    clearInteractionOnRegenerate: ['table_dtypes'],
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.PORTFOLIO_HOLDINGS_ANALYSIS],

    validate,
    helpLabel: 'h1cyplud',
  },
] satisfies ts.types.analysis.WidgetConfig[];
