import { _, hooks, mui, React, ts } from '_core';

import * as columns from 'views/preferences/columns-config';
import Tabular from 'views/report/tables/tabular';

import { transform } from './helpers';
import { Params, SHEETS } from './types';
import { getColumns } from '../../common/charts/utils';

type ChartProps = {
  id: string;
  readOnly: boolean;
  widgetKey: string;
  loadWidgetData: (_payload: ts.types.widgets.WidgetGetDataParams) => Promise<ts.types.widgets.WidgetGetDataResponse>;
  params: Params;
  setParams: (_p: Params) => void;
  fullScreen: boolean;
  goFullScreen: (_v: string) => void;
  rebalance: string;
  trade: string;
  queryParams?: { initial?: string };
  context?: ts.types.portfolio.PortfolioAnalysisContext;
};

const Chart: React.FC<ChartProps> = ({
  id,
  readOnly,
  widgetKey,
  loadWidgetData,
  rebalance,
  trade,
  goFullScreen,
  params,
  setParams,
  fullScreen,
}) => {
  const [error, setError] = React.useState<ts.types.common.ApiError>(null);
  const [data, setData] = React.useState<Record<string, ts.types.widgets.TableData>>();

  const [containerHeight, setContainerHeight] = React.useState(undefined);
  const { height } = hooks.useWindowDimensions();

  const container = React.createRef<HTMLDivElement>();

  React.useEffect(() => {
    if (container.current) {
      setContainerHeight(container.current.offsetHeight - 40);
    }
  }, [height]);

  const [expandedGroupIds, setExpandedGroupIds] = React.useState<Record<string, ReadonlySet<unknown>>>(
    params.expanded
      ? Object.fromEntries(Object.entries(params.expanded).map(([key, val]) => [key, new Set(val)]))
      : null
  );

  const [tableParams, setTableParams] = React.useState<ts.types.components.dataGrid.TableParamsSheet>(
    params.table_params
  );

  const [currentSheet, setCurrentSheet] = React.useState<SHEETS>(params.current_sheet || ('rebalance' as SHEETS));

  const debouncedParamsSave = React.useCallback(
    _.debounce(async (exp: typeof expandedGroupIds, ct: SHEETS, tbP: ts.types.components.dataGrid.TableParamsSheet) => {
      setParams({
        expanded: Object.fromEntries(
          Object.entries(exp).map(([key, val]) => [key, [...val] as string[]])
        ) as Params['expanded'],
        current_sheet: ct,
        table_params: { ...tbP },
        fullWidth: true,
      });
    }, 1_000),
    []
  );

  hooks.useEffectWithoutFirst(() => {
    debouncedParamsSave(expandedGroupIds, currentSheet, tableParams);
  }, [expandedGroupIds, currentSheet, tableParams]);

  const loadData = async () => {
    try {
      const resp = await Promise.all([
        loadWidgetData({ data: { file: rebalance } }),
        loadWidgetData({ data: { file: trade } }),
      ]);

      if (resp) {
        setData({
          rebalance: transform(resp[0].data),
          trade: resp[1].data,
        });

        if (_.isEmpty(expandedGroupIds)) {
          const rebalanceLabels = new Set(resp[0].data.map((x) => x.label));
          const tradeLabels = new Set(resp[1].data.map((x) => x.label));
          setExpandedGroupIds({
            rebalance: rebalanceLabels,
            trade: tradeLabels,
          });
        }
      }
    } catch (err) {
      setError(err as ts.types.common.ApiError);
    }
  };

  React.useEffect(() => {
    if (rebalance && trade) loadData();
    return () => setData(null);
  }, [rebalance, trade]);

  return (
    <mui.core.Box
      id={id}
      key={widgetKey}
      sx={{
        height: 'calc(100% - 1px)',
        '& .rdg-light': { paddingBottom: '60px' },
      }}
      ref={container}
    >
      <Tabular<SHEETS>
        data={data}
        apiError={error}
        readOnly={readOnly}
        getColumns={{
          rebalance: (rebKeys, preferences) =>
            getColumns(rebKeys, preferences?.rebalance, columns.portfolioTradeListSummaryColumns['rebalance']),

          trade: (tradeKeys, preferences) =>
            getColumns(tradeKeys, preferences?.trade, columns.portfolioTradeListSummaryColumns['trade']),
        }}
        sheetTitles={{
          rebalance: 'Rebalance Summary',
          trade: 'Trade Summary',
        }}
        fullScreen={fullScreen}
        goFullScreen={goFullScreen}
        hideFilters
        alwaysShowBottom
        groupBy={{ rebalance: ['label'], trade: ['label'] }}
        expandedGroupIds={expandedGroupIds}
        setExpandedGroupIds={{
          rebalance: (v) => setExpandedGroupIds((exp) => ({ ...exp, rebalance: v })),
          trade: (v) => setExpandedGroupIds((exp) => ({ ...exp, trade: v })),
        }}
        preferences={{
          preferencesSourceWidgetId: id,
          preferenceKey: ts.enums.PREFERENCES_KEY_ENUM.PORTFOLIO_TRADE_LIST_SUMMARY_COLS,
          preferenceTab: ts.enums.PREFERENCES_INNER_TABS_ENUM.PORTFOLIO_TRADE_LIST_SUMMARY_COLS,
        }}
        tableParams={tableParams}
        setTableParams={{
          rebalance: (v) => setTableParams((t) => ({ ...t, rebalance: v })),
          trade: (v) => setTableParams((t) => ({ ...t, trade: v })),
        }}
        currentSheet={currentSheet}
        setCurrentSheet={setCurrentSheet}
        overrideHeight={containerHeight}
        triggerWidthChange={params.fullWidth}
      />
    </mui.core.Box>
  );
};

export default Chart;
