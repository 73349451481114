import { mui } from '../libs';

/**
 * Secondary tabs component with custom styling
 *
 * @returns Styled tabs component with custom styling
 */
const SecondaryTabs = mui.styles.styled(mui.core.Tabs)(() => ({
  backgroundColor: 'white',
  margin: '0 4px 4px',
  borderRadius: '4px',
  overflow: 'hidden',
  boxShadow: '0 1px 2px rgba(0,0,0,0.03)',
  '& .MuiTabs-indicator': {
    backgroundColor: '#A2B9DC',
  },
}));

export default SecondaryTabs;
