import { _, mui, React, ts } from '_core';

import { useUiStyles } from '@local/finsera-core/src/hooks';

type FactorTableProps = {
  factor: ts.types.riskModel.ExtendedCategoryFactor;
  readOnly: boolean;
  validationDef?: ts.types.common.ValidationErrors;
  deleteInclusions?: (_v: ts.types.riskModel.ExtendedCategoryFactorInclusions[]) => void;
  addToFactor?: (_vs: ts.types.riskModel.ExtendedCategoryFactorInclusions[]) => void;
};

const FactorTable: React.FC<FactorTableProps> = ({
  factor,
  readOnly,
  validationDef,
  deleteInclusions,
  addToFactor,
}): React.ReactElement => {
  const theme = mui.styles.useTheme() as mui.core.Theme;
  const uiStyles = useUiStyles();

  const errorFields = validationDef ? (validationDef.fields ?? []) : [];
  const [allSelected, setAllSelected] = React.useState<boolean>(false);
  const [selected, setSelected] = React.useState<ts.types.riskModel.ExtendedCategoryFactorInclusions[]>([]);

  React.useEffect(() => {
    // Clear selected as soon as we have a change on the definition
    setSelected([]);
  }, [factor.inclusions]);

  const hasActions = !readOnly && (addToFactor || deleteInclusions) && !_.isEmpty(factor.inclusions);
  return (
    <>
      <mui.core.Box display="flex" alignItems="center">
        {addToFactor && !_.isEmpty(selected) && (
          <mui.core.Button
            size="small"
            variant="outlined"
            sx={{ ...uiStyles.collapseHeaderButton, my: 2 }}
            onClick={() => addToFactor(selected)}
          >
            Add to Factor
          </mui.core.Button>
        )}

        {deleteInclusions && !_.isEmpty(selected) && (
          <mui.core.Button
            size="small"
            variant="outlined"
            sx={{ ...uiStyles.collapseHeaderButton, my: 2 }}
            onClick={() => deleteInclusions(selected)}
          >
            Delete
          </mui.core.Button>
        )}

        {!_.isEmpty(selected) && (
          <mui.core.Typography variant="caption" sx={{ ml: 3, my: 2 }}>
            {selected.length} subindustries selected
          </mui.core.Typography>
        )}
      </mui.core.Box>

      {hasActions && factor.inclusions.length > 1 && (
        <mui.core.FormControlLabel
          control={
            <mui.core.Checkbox
              size="small"
              checked={allSelected}
              onChange={() => {
                {
                  !allSelected ? setSelected(factor.inclusions) : setSelected([]);
                }
                setAllSelected(!allSelected);
              }}
            />
          }
          label={
            <mui.core.Typography color="primary" variant="caption" sx={{ fontWeight: 500 }}>
              Select all
            </mui.core.Typography>
          }
          sx={{ ml: 2, marginBottom: '-5px' }}
        />
      )}
      <mui.core.TableContainer>
        <mui.core.Table
          size="small"
          aria-label="simple table"
          stickyHeader
          sx={{
            '& .MuiTableRow-root': {
              '&:nth-of-type(even)': {
                backgroundColor: theme.palette.grayLight.light,
              },
              // hide last border
              '&:last-child td, &:last-child th': {
                border: 0,
              },
            },
            '& .MuiTableCell-root': {
              width: '25%',
              fontSize: '12px',
              padding: '2px 8px!important',
            },
          }}
        >
          <mui.core.TableBody>
            {(factor.inclusions || []).map((inclusion: ts.types.riskModel.ExtendedCategoryFactorInclusions, idx) => (
              <mui.core.TableRow
                key={idx}
                sx={
                  inclusion.isNew
                    ? {
                        '& .MuiTableCell-root': {
                          backgroundColor: theme.palette.successLight.light,
                          color: theme.palette.success.dark,
                          width: '25%',
                        },
                      }
                    : errorFields.includes(`subind_${inclusion.value}`)
                      ? {
                          '& .MuiTableCell-root': {
                            backgroundColor: theme.palette.dangerLight.light,
                            color: theme.palette.danger.dark,
                            width: '25%',
                          },
                        }
                      : undefined
                }
              >
                <mui.core.TableCell
                  sx={{
                    width: '1rem!important',
                    padding: '0px 8px!important',
                  }}
                >
                  {hasActions && (
                    <mui.core.Checkbox
                      size="small"
                      checked={!!selected.find((s) => s.value == inclusion.value)}
                      onChange={() => {
                        if (!!selected.find((s) => s.value == inclusion.value))
                          setSelected(selected.filter((s) => s.value !== inclusion.value));
                        else setSelected([...selected, inclusion]);
                      }}
                    />
                  )}
                </mui.core.TableCell>
                <mui.core.TableCell>{inclusion[ts.enums.MAPPED_RBICS.rbics_l1_name]}</mui.core.TableCell>
                <mui.core.TableCell>{inclusion[ts.enums.MAPPED_RBICS.rbics_l2_name]}</mui.core.TableCell>
                <mui.core.TableCell>{inclusion[ts.enums.MAPPED_RBICS.rbics_l3_name]}</mui.core.TableCell>
                <mui.core.TableCell>{inclusion[ts.enums.MAPPED_RBICS.rbics_l4_name]}</mui.core.TableCell>
              </mui.core.TableRow>
            ))}
          </mui.core.TableBody>
        </mui.core.Table>
      </mui.core.TableContainer>
    </>
  );
};

export default FactorTable;
