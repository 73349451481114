// CAUTION - THIS WIDGET IS ALSO RENDERED ON GENERAL ANALYSIS */

import { config, React, ts } from '_core';

import Chart from './chart';
import Form from './form';
import { DraftParams, Params } from './types';

type BasketRelativeFactorExposureProps = {
  id: string;
  mode: ts.enums.CHART_MODE_ENUM;
  params: Params | DraftParams;
  setParams: (_p: DraftParams) => void;
  title: string;
  setExternalEmbed: (_json: ts.types.analysis.EmbedChartData) => void;

  context: ts.types.basket.BasketAnalysisContext | ts.types.analysis.GeneralAnalysisContext;
  loadWidgetData: (_payload: ts.types.widgets.WidgetGetDataParams) => Promise<ts.types.widgets.WidgetGetDataResponse>;
  widgetPaths?: Record<'basket_relative_factor_exposures', string>;
  subtitle?: string;
};

const BasketRelativeFactorExposure: React.FC<BasketRelativeFactorExposureProps> = ({
  id,
  loadWidgetData,
  widgetPaths,
  mode,
  params,
  setParams,
  context,
  title,
  subtitle,
  setExternalEmbed,
}) => {
  const asOfDate = params.run_on_date ? params.basket_date : context?.end_date;

  const renderJobComponent = () => (
    <Chart
      id={id}
      loadWidgetData={loadWidgetData}
      file={widgetPaths['basket_relative_factor_exposures']}
      asOfDate={asOfDate}
      params={params as Params}
      setParams={setParams}
      title={title}
      subtitle={subtitle}
      setExternalEmbed={setExternalEmbed}
    />
  );

  return (
    <>
      {mode == 'edit' ? (
        <Form
          params={params}
          setParams={setParams}
          minDate={context.start_date ?? config.features.start_date}
          maxDate={context.end_date ?? config.features.end_date}
        />
      ) : (
        <>{renderJobComponent()}</>
      )}
    </>
  );
};

export default BasketRelativeFactorExposure;
