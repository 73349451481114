import { BENCHMARK_TYPES_ENUM, BUCKET_TYPES_ENUM } from '../enums';
import { _ } from '../libs';
import { ReturnHorizon } from '../types/return-horizon';

/**
 * Validates if a benchmark configuration is valid
 * @param benchmark - The benchmark configuration object
 * @returns True if benchmark is valid, false otherwise
 */
export const validBenchmark = (benchmark: Record<string, BENCHMARK_TYPES_ENUM | string | number>) => {
  if (_.isEmpty(benchmark)) return false;
  if (!benchmark.type) return false;
  if (benchmark.type == BENCHMARK_TYPES_ENUM.INDEX && !benchmark.code) return false;
  if (benchmark.type == BENCHMARK_TYPES_ENUM.UNIVERSE && (!benchmark.handle || !benchmark.weighting)) return false;
  return true;
};

/**
 * Validates if a buckets configuration is valid
 * @param buckets - The buckets configuration object
 * @returns True if buckets configuration is valid, false otherwise
 */
export const validBuckets = (buckets: Record<string, BUCKET_TYPES_ENUM | string | number>) => {
  if (_.isEmpty(buckets)) return false;

  if (
    buckets.type == BUCKET_TYPES_ENUM.RISK_MODEL ||
    buckets.type == BUCKET_TYPES_ENUM.RISK_MODEL_FACTORS ||
    buckets.type == BUCKET_TYPES_ENUM.ASSET_MASTER
  )
    return !!buckets.value;

  if (buckets.type === BUCKET_TYPES_ENUM.QUANTILES)
    return buckets.value && (buckets.value as number) >= 1 && (buckets.value as number) <= 20;

  if (buckets.type === BUCKET_TYPES_ENUM.SIGNAL)
    return buckets.value && (buckets.value as number) >= 1 && (buckets.value as number) <= 20 && buckets.signal_id;

  return true;
};

/**
 * Validates if a URL string is valid (http or https)
 * @param url - The URL string to validate
 * @returns True if URL is valid, false otherwise
 */
export const validUrl = (url: string) => {
  try {
    const newUrl = new URL(url);
    return newUrl.protocol === 'http:' || newUrl.protocol === 'https:';
  } catch (err) {
    return false;
  }
};

/**
 * Validates a single return horizon configuration
 * @param returnHorizon - The return horizon configuration object
 * @returns Array of error messages, empty if valid
 */
export const validSelectedReturnHorizon = (returnHorizon = {} as ReturnHorizon) => {
  if (_.isEmpty(returnHorizon?.periods)) return ['Return Horizon is a required field.'];
  if (returnHorizon.is_signal && !returnHorizon.signal_id) {
    return ['No signal selected for return horizon.'];
  }
  if (!returnHorizon?.periods?.amount || !returnHorizon?.periods?.frequency) {
    return ['Return horizon must be defined correctly.'];
  }
  return [];
};

/**
 * Validates an array of return horizon configurations
 * @param returnHorizons - Array of return horizon configurations
 * @returns Array of error messages, empty if valid
 */
export const validSelectedReturnHorizons = (returnHorizons: ReturnHorizon[]) => {
  if (_.isEmpty(returnHorizons)) return ['At least one return horizon is required.'];
  for (const returnHorizon of returnHorizons) {
    const rhErrors = validSelectedReturnHorizon(returnHorizon);
    if (rhErrors.length > 0) return ['One of the return horizons is invalid.'];
  }
  return [];
};

/**
 * Validates basket comparison parameters
 * @param params - Object containing basket comparison parameters
 * @returns Array of error messages, empty if valid
 */
export const validBasketCompare = (params: { basket_ids?: number[] }) => {
  const errors = [];
  if (_.isEmpty(params.basket_ids)) errors.push('You need to add at least one basket.');
  return errors;
};
