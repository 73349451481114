import { config, helpers, mui, React, ts, ui,useHistory, useSelector } from '_core';

import * as utils from './basket-utils';

type BasketFormProps = {
  open: boolean;
  setOpen: (_open: boolean) => void;
};

const BasketForm: React.FC<BasketFormProps> = ({ open, setOpen }): React.ReactElement => {
  const baskets = useSelector((state) => state.resources.baskets as ts.types.basket.Basket[]);
  const workspaces = useSelector((state) => state.resources.workspaces as ts.types.workspace.Workspace[]);
  const currentWorkspaceId = useSelector((state) => state.ui.currentWorkspaceId);
  const history = useHistory();

  const [optimizerType, setOptimizerType] =
    React.useState<ts.types.optimizer.OptimizerConfig['optimizer_type']>('CVXPY');
  const [errorMessage, setErrorMessage] = React.useState('');
  const [name, setName] = React.useState('');
  const [handle, setHandle] = React.useState('');
  const [type, setType] = React.useState<ts.enums.BASKET_TYPE_ENUM>(ts.enums.BASKET_TYPE_ENUM.COMMON);
  const [template, setTemplate] = React.useState<ts.types.basket.Basket>();
  const [loadingCreate, setLoadingCreate] = React.useState(false);
  const [workspace, setWorkspace] = React.useState(workspaces.find((w) => w.id == currentWorkspaceId) || workspaces[0]);

  // Create handle while typing name
  React.useEffect(() => {
    setHandle(helpers.createHandle(name, 'bsk'));
  }, [name]);

  const handleSubmit = async () => {
    setErrorMessage('');

    // Validation
    if (!name || !handle) {
      setErrorMessage('All attributes are required.');
      setLoadingCreate(false);
      return;
    }

    setLoadingCreate(true);
    const newBasket = utils.definition.getNewBasket(name, handle, optimizerType, type, template);
    utils.apiProcedures.createBasket(
      newBasket,
      async (data) => {
        await helpers.resourcesUtils.addToWorkspace(data.id, ts.enums.UI_RESOURCE_TYPE_ENUM.BASKET, workspace);
        setOpen(false);
        history.push(`/baskets/${data.id}/definition`);
      },
      async (err) => {
        setErrorMessage(err);
        setLoadingCreate(false);
      }
    );
  };

  const formContent = () => (
    <mui.core.Box mt={3}>
      <mui.core.Box mb={4}>
        <mui.core.Grid container spacing={3}>
          <mui.core.Grid item xs={12} sm={6}>
            <mui.core.TextField
              autoFocus
              id="name"
              label="Basket name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              fullWidth
              variant="outlined"
              required
              size="small"
              inputProps={{
                autocomplete: 'off',
              }}
            />
          </mui.core.Grid>
          <mui.core.Grid item xs={12} sm={6}>
            <mui.core.TextField
              id="handle"
              label="Basket handle"
              value={handle}
              onChange={(e) => setHandle(e.target.value)}
              fullWidth
              variant="outlined"
              required
              size="small"
            />
          </mui.core.Grid>
        </mui.core.Grid>
      </mui.core.Box>

      <mui.core.Box pt={4}>
        <mui.core.TextField
          id="Type"
          select
          label="What is the basket type?"
          variant="outlined"
          value={type}
          onChange={(e) => {
            setType(e.target.value as ts.enums.BASKET_TYPE_ENUM);
            setTemplate(null);
          }}
          fullWidth
          required
          size="small"
        >
          <mui.core.MenuItem value={ts.enums.BASKET_TYPE_ENUM.COMMON}>Simple</mui.core.MenuItem>
          <mui.core.MenuItem value={ts.enums.BASKET_TYPE_ENUM.COMBINED}>Dynamic</mui.core.MenuItem>
        </mui.core.TextField>
      </mui.core.Box>

      <mui.core.Box py={4}>
        <mui.core.TextField
          id="optimizer"
          select
          label="Select an Optimizer"
          variant="outlined"
          value={optimizerType}
          onChange={(e) => setOptimizerType(e.target.value as ts.types.optimizer.OptimizerConfig['optimizer_type'])}
          fullWidth
          required
          size="small"
        >
          <mui.core.MenuItem value="CVXPY">Finsera Optimizer</mui.core.MenuItem>
          {config.features.has_barra_optimizer && <mui.core.MenuItem value="BARRA">Barra Optimizer</mui.core.MenuItem>}
        </mui.core.TextField>
      </mui.core.Box>

      <mui.core.Box pb={4}>
        <ui.ResourceAutocomplete<ts.types.basket.BasketExpanded>
          enableNone
          value={template?.id}
          setValue={(id) => setTemplate(baskets.find((u) => u.id == id))}
          type={ts.enums.RESOURCES_TYPES_ENUM.BASKET}
          filter={(b) => b.type == type && !b.source_resource_id}
          inputProps={{
            fullWidth: true,
            label: 'Template',
            variant: 'outlined',
            required: true,
            size: 'small',
          }}
        />
      </mui.core.Box>
      <ui.WorkspaceSelector value={workspace} setValue={setWorkspace} />
    </mui.core.Box>
  );

  return (
    <mui.core.Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="sm"
      fullWidth
    >
      {errorMessage && (
        <mui.lab.Alert onClose={() => setErrorMessage('')} severity="error">
          {errorMessage}
        </mui.lab.Alert>
      )}
      <ui.DialogTitle closeAction={() => setOpen(false)}>Create Basket</ui.DialogTitle>
      <mui.core.DialogContent>{formContent()}</mui.core.DialogContent>
      <mui.core.DialogActions>
        <ui.Button
          variant="contained"
          color="primary"
          loading={loadingCreate}
          onClick={() => handleSubmit()}
          fullWidth
          style={{ margin: '0rem 1rem 1rem' }}
        >
          Create Basket
        </ui.Button>
      </mui.core.DialogActions>
    </mui.core.Dialog>
  );
};

export default BasketForm;
