import { endpoint } from './config';
import { $ } from '../libs';
import { Organization, OrganizationFeatures } from '../types/organization';
import { User, UserDraft } from '../types/user';

export const login = (data: {
  email: string;
  password: string;
  organization_subdomain?: string;
}): JQuery.jqXHR<{ auth: boolean; detail?: string }> =>
  $.ajax({
    method: 'POST',
    url: `${endpoint}login`,
    json: data,
  } as JQueryAjaxSettings);

export const getSession = (): JQuery.jqXHR<User & { organization: Organization }> =>
  $.ajax({
    method: 'POST',
    url: `${endpoint}auth/verify`,
  });

export const logout = () =>
  $.ajax({
    method: 'GET',
    url: `${endpoint}logout`,
  });

export const register = (data: {
  admin_name: string;
  admin_email: string;
  registration_secret: string;
  organization_subdomain: string;
  organization_name: string;
}): JQuery.jqXHR<UserDraft> =>
  $.ajax({
    method: 'POST',
    url: `${endpoint}register`,
    json: data,
  } as JQueryAjaxSettings);

export const passwordReset = (
  data: { new_password?: string; email?: string; organization_subdomain?: string },
  resetToken: string = null
): JQuery.jqXHR<{ success: boolean }> =>
  $.ajax({
    method: 'POST',
    url: resetToken ? `${endpoint}auth/password-reset?reset_token=${resetToken}` : `${endpoint}auth/password-reset`,
    json: data,
  } as JQueryAjaxSettings);

export const serviceLogin = (
  service: string,
  external = false,
  organization_subdomain?: string
): JQuery.jqXHR<{ uri: string }> => {
  let url = `${endpoint}${external ? 'external-' : ''}${service}-login`;
  if (organization_subdomain) url += `?query_organization_subdomain=${organization_subdomain}`;
  return $.ajax({
    method: 'GET',
    url,
  });
};

export const publicFeatures = (organization_subdomain?: string): JQuery.jqXHR<OrganizationFeatures> => {
  let url = `${endpoint}public_features`;
  if (organization_subdomain) url += `?query_organization_subdomain=${organization_subdomain}`;
  return $.ajax({
    method: 'GET',
    url,
  });
};
