import { hooks, mui, React, ts, ui } from '_core';

import { DraftParams } from './types';

type FormProps = {
  params: DraftParams;
  setParams: (_p: DraftParams) => void;
  context?: ts.types.signal.SignalAnalysisContext;
};

const Form: React.FC<FormProps> = ({ params, setParams, context }) => {
  const [variant, setVariant] = React.useState(params.variant || 'horizons');
  const [selectedHistories, setSelectedHistories] = React.useState(params.histories || []);
  const [selectedReturnHorizon, setSelectedReturnHorizon] = React.useState(params.return_horizons?.[0]);
  const [selectedReturnHorizons, setSelectedReturnHorizons] = React.useState(params?.return_horizons);
  const uiStyles = hooks.useUiStyles();

  React.useEffect(() => {
    let data;
    if (variant == 'horizons') data = { variant, return_horizons: selectedReturnHorizons };
    else {
      data = {
        ...params,
        variant,
        return_horizons: selectedReturnHorizon ? [selectedReturnHorizon] : [],
        histories: selectedHistories,
      };
    }

    setParams(data);
  }, [selectedReturnHorizon, selectedReturnHorizons, variant, selectedHistories]);

  const horizonsForm = (
    <ui.WidgetReturnHorizon<true>
      value={selectedReturnHorizons}
      setValue={(val) => setSelectedReturnHorizons(val)}
      multiple
      context={context}
    />
  );

  const historiesForm = (
    <>
      <mui.core.Box mb={3}>
        <ui.WidgetHistories<true>
          multiple
          value={selectedHistories}
          setValue={setSelectedHistories}
          context={context}
        />
      </mui.core.Box>
      <ui.WidgetReturnHorizon<false>
        value={selectedReturnHorizon}
        setValue={(val) => setSelectedReturnHorizon(val)}
        context={context}
      />
    </>
  );

  return (
    <mui.core.Box sx={uiStyles.widgetEditMode}>
      <mui.core.Box mb={2}>
        <mui.core.FormControl component="fieldset">
          <mui.core.RadioGroup
            aria-label="Variant"
            value={variant}
            onChange={(e) => setVariant(e.target.value as 'histories' | 'horizons')}
            row
          >
            <mui.core.FormControlLabel value="horizons" control={<mui.core.Radio />} label="Horizons" />
            <mui.core.FormControlLabel value="histories" control={<mui.core.Radio />} label="Histories" />
          </mui.core.RadioGroup>
        </mui.core.FormControl>
      </mui.core.Box>
      {variant == 'horizons' ? horizonsForm : historiesForm}
    </mui.core.Box>
  );
};

export default Form;
