import React from 'react';

import ETFModal from './etf-selector-modal';
import { queryETF } from './etf-selector-utils';
import api from '../../api';
import { useEffectWithoutFirst } from '../../hooks';
import { _, mui } from '../../libs';
import * as types from '../../types';


type EtfFieldProps = {
  value: types.etf.ETFValueObject;
  setValue: (
    _value: types.etf.ETFValueObject,
    _metaData: types.etf.ETFAssetMetaData | types.etf.ETFAssetMetaData[]
  ) => void;
  createTitle?: (_etf: types.etf.ETFAssetMetaData) => string;
  multiple?: boolean;
  disabled?: boolean;
  errorState?: boolean;
};

/**
 * Component for selecting ETFs with search and filtering capabilities
 * 
 * @requires Redux Store:
 * - None
 * 
 * @requires Redux Store Actions:
 * - None
 * 
 * @requires Features:
 * - None
 * 
 * @param value - The selected ETF value(s)
 * @param setValue - Callback to update selected ETF value(s)
 * @param createTitle - Optional function to customize ETF display title
 * @param multiple - Optional flag to allow multiple ETF selection
 * @param disabled - Optional flag to disable the field
 * @param errorState - Optional flag to show error state
 * 
 * @returns Rendered ETF selector field component
 */
const EtfField: React.FC<EtfFieldProps> = ({
  value,
  setValue,
  errorState,
  multiple = false,
  disabled  = false,
  createTitle = (meta) =>
    meta?.name
      ? `${meta?.name} ${meta.quotation_country ? `[${meta.quotation_country}]` : ''}`
      : `${meta.ticker} ${meta.quotation_country ? `[${meta.quotation_country}]` : ''}`,
}): React.ReactElement => {
  const [initialLoad, setInitialLoad] = React.useState(true);
  const [filtersOptions, setFiltersOptions] = React.useState([]);
  const [loadingFilterOptions, setLoadingFilterOptions] = React.useState(true);
  const [openModal, setOpenModal] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const divRef = React.useRef<HTMLDivElement>();

  const [localValue, setLocalValue] = React.useState<types.etf.ETFAssetMetaData | types.etf.ETFAssetMetaData[]>();

  const loadFilterOptions = async () => {
    const filtersResponse = await api.assetMaster.getEtfFilters();
    const geoExpoResponse = await api.assetMaster.getEtfGeoExposure();

    setLoadingFilterOptions(false);
    setFiltersOptions([...filtersResponse['filters'], geoExpoResponse['filters']]);
  };

  const triggerInitialLoad = async () => {
    const lfids = multiple ? (value as string[]) : [value as string];
    const queryResult = await queryETF(
      {
        search_text: '',
        filters: {},
        uiFilters: {},
        fids: lfids,
      },
      lfids.length,
      0
    );
    if (!queryResult.error) {
      setLocalValue(multiple ? queryResult?.response?.assets : queryResult?.response?.assets?.[0]);
    }
    setInitialLoad(false);
  };

  React.useEffect(() => {
    loadFilterOptions();
    if (!_.isEmpty(value)) triggerInitialLoad();
    else setInitialLoad(false);
  }, []);

  useEffectWithoutFirst(() => {
    const newVal = multiple
      ? _.map((localValue as types.etf.ETFAssetMetaData[]) || [], 'fid')
      : (localValue as types.etf.ETFAssetMetaData)?.fid;

    if (
      !_.isEqual(
        multiple ? [...((value || []) as [])].sort() : value,
        multiple ? [...((newVal || []) as [])].sort() : newVal
      )
    )
      setValue(newVal, localValue);
  }, [localValue]);

  if (initialLoad) return <mui.core.TextField disabled fullWidth label="Loading" variant="outlined" size="small" />;

  const handleClick = () => {
    setOpenModal(true);
    setAnchorEl(divRef.current);
  };

  return (
    <mui.core.Box {...({ ref: divRef } as any)}>
      <mui.core.Autocomplete
        multiple={multiple}
        fullWidth
        options={[]}
        size="small"
        value={multiple ? localValue || [] : localValue || null}
        getOptionLabel={(option) => createTitle(option as types.etf.ETFAssetMetaData)}
        onChange={(_e, val) => {
          setLocalValue(val as types.etf.ETFAssetMetaData | types.etf.ETFAssetMetaData[]);
        }}
        renderInput={(params) => (
          <mui.core.TextField
            {...params}
            fullWidth
            label="Search ETF"
            variant="outlined"
            required
            size="small"
            error={errorState}
          />
        )}
        renderTags={(tagValue, getTagProps) =>
          tagValue.map((option, index) => {
            const currentLabel = createTitle(option as types.etf.ETFAssetMetaData);
            return <mui.core.Chip key={index} label={currentLabel} {...getTagProps({ index })} />;
          })
        }
        disabled={disabled}
        onOpen={!disabled ? handleClick : undefined}
      />
      <ETFModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        etfs={localValue}
        setEtfs={setLocalValue}
        anchorEl={anchorEl}
        multiple={multiple}
        filtersOptions={filtersOptions}
        loadingFilterOptions={loadingFilterOptions}
      />
    </mui.core.Box>
  );
};


export default EtfField;
