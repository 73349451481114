import { am5, am5xy, mui } from '@local/finsera-core/src/libs';

export const createBaseTooltips = ({
  root,
  chart,
  xAxis,
  yAxis,
  theme,
}: {
  root: am5.Root;
  chart: am5xy.XYChart;
  xAxis: am5xy.ValueAxis<am5xy.AxisRenderer>;
  yAxis: am5xy.ValueAxis<am5xy.AxisRenderer>;
  theme: mui.core.Theme;
}) => {
  // Add cursor
  chart.set(
    'cursor',
    am5xy.XYCursor.new(root, {
      behavior: 'zoomX',
      xAxis: xAxis,
    })
  );

  const xAxisTooltip = xAxis.set(
    'tooltip',
    am5.Tooltip.new(root, {
      themeTags: ['axis'],
    })
  );
  xAxisTooltip.get('background').setAll({
    fill: am5.color(theme.palette.primary.dark),
  });
  xAxisTooltip.label.setAll({
    fontSize: 11,
  });

  const yAxisTooltip = yAxis.set(
    'tooltip',
    am5.Tooltip.new(root, {
      themeTags: ['axis'],
    })
  );
  yAxisTooltip.get('background').setAll({
    fill: am5.color(theme.palette.primary.dark),
  });
  yAxisTooltip.label.setAll({
    fontSize: 11,
  });

  return { xAxisTooltip, yAxisTooltip };
};
