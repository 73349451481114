import { ls } from '../libs';
import { DatasetDraft } from '../types/signal';

const setLastItem = async (datasetRecord: DatasetDraft) => {
  const response = await ls.set({ data: datasetRecord, path: 'last_dataset' });
  return response;
};

const getLastItem = async () => {
  const response = await ls.get({ path: 'last_dataset' });
  return response;
};

export { setLastItem };
export { getLastItem };
