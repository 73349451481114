import React from 'react';

import { styleFactorThemes, themes } from '../../data';
import * as enums from '../../enums';
import { _, mui } from '../../libs';
import * as types from '../../types';
import { Filter, Option } from '../guided-search/search-types';
import ResourceIcon from '../resource-icon';

const getSignalBasedResources = () => {
  return [
    {
      label: 'Signal',
      key: enums.UI_RESOURCE_TYPE_ENUM.SIGNAL,
      icon: () => <ResourceIcon resource={enums.UI_RESOURCE_TYPE_ENUM.SIGNAL} />,
    },
    {
      label: 'Alpha Model',
      key: enums.UI_RESOURCE_TYPE_ENUM.ALPHA_MODEL,
      icon: () => <ResourceIcon resource={enums.UI_RESOURCE_TYPE_ENUM.ALPHA_MODEL} />,
    },
  ] as Option[];
};

export const getInputFilters = (
  users: types.user.User[],
  labels: types.BaseStore['resources']['labels'],
  type: string,
  _organizations: types.organization.PublishedOrg[]
) => {
  let filters = [
    {
      key: 'label',
      label: 'Label',
      type: 'select' as 'select' | 'text',
      options: labels.map((l) => ({
        key: l.title,
        label: l.title,
      })),
      sort: (l: Option[]) => _.sortBy(l, 'label'),
      icon: () => <mui.icons.Label />,
    },
  ] as Filter[];

  if (type == 'signal' || type == 'alpha_model' || type == 'signal_based') {
    filters.push({
      key: 'theme',
      label: 'Theme',
      type: 'select' as 'select' | 'text',
      options: [...Object.keys(themes), ...Object.keys(styleFactorThemes)].map((key) => ({
        key,
        label:
          themes?.[key as keyof typeof themes] ||
          styleFactorThemes?.[key as keyof typeof styleFactorThemes],
      })),
      icon: () => <mui.icons.Palette />,
    } as Filter);
  }

  if (type == 'signal_based') {
    filters = [
      {
        key: 'type',
        label: 'Type',
        type: 'select' as 'select' | 'text',
        options: getSignalBasedResources(),
        icon: () => <mui.icons.Category />,
      } as Filter,
    ].concat(filters as Filter[]);
  }

  // filters.push({
  //   key: 'is_published',
  //   label: 'Published',
  //   type: 'select' as 'select' | 'text',
  //   options: [{ key: 'true', label: 'True' }, { key: 'false', label: 'False' }],
  //   icon: () => <mui.icons.Share />,
  // });

  // filters.push({
  //   key: 'is_archived',
  //   label: 'Archived',
  //   type: 'select' as 'select' | 'text',
  //   options: [{ key: 'true', label: 'True' }],
  //   icon: () => <mui.icons.Archive />,
  // });

  return filters;
};
