import { hooks, mui, React, ui } from '_core';

import { DraftParams } from './types';

type FormProps = {
  params: DraftParams;
  setParams: (_p: DraftParams) => void;
};

const Form: React.FC<FormProps> = ({ params, setParams }) => {
  const [daysBeforeEvent, setDaysBeforeEvent] = React.useState(params.days_before_event);
  const [daysAfterEvent, setDaysAfterEvent] = React.useState(params.days_after_event);
  const [quantiles, setQuantiles] = React.useState(params.quantiles);
  const uiStyles = hooks.useUiStyles();

  React.useEffect(() => {
    const data = {
      ...params,
      days_before_event: daysBeforeEvent,
      days_after_event: daysAfterEvent,
      quantiles,
    };
    setParams(data);
  }, [daysBeforeEvent, daysAfterEvent, quantiles]);

  return (
    <mui.core.Box sx={uiStyles.widgetEditMode}>
      <mui.core.Grid container spacing={2}>
        <mui.core.Grid item xs={6}>
          <ui.NumericField
            textFieldProps={{
              label: 'Quantiles (1 - 20)',
              fullWidth: true,
              variant: 'outlined',
              size: 'small',
              InputProps: { inputProps: { min: 1, max: 20 } },
            }}
            onlyPositive
            onlyInteger
            value={quantiles}
            setValue={(v) => setQuantiles(v)}
          />
        </mui.core.Grid>
      </mui.core.Grid>

      <mui.core.Typography
        variant="body2"
        fontWeight="fontWeightMedium"
        sx={{
          marginBottom: '10px',
          marginTop: '5px',
        }}
      ></mui.core.Typography>

      <mui.core.Typography
        variant="body2"
        fontWeight="fontWeightMedium"
        sx={{
          marginBottom: '10px',
          marginTop: '5px',
        }}
      >
        Returns Window
      </mui.core.Typography>
      <mui.core.Grid container spacing={2}>
        <mui.core.Grid item xs={6}>
          <ui.NumericField
            textFieldProps={{
              label: 'Days Before Build Date (1 - 21)',
              fullWidth: true,
              variant: 'outlined',
              size: 'small',
              required: 'true',
            }}
            value={daysBeforeEvent}
            setValue={(v) => setDaysBeforeEvent(v)}
          />
        </mui.core.Grid>

        <mui.core.Grid item xs={6}>
          <ui.NumericField
            textFieldProps={{
              label: 'Days After Build Date (0 - 63)',
              fullWidth: true,
              variant: 'outlined',
              size: 'small',
            }}
            value={daysAfterEvent}
            setValue={(v) => setDaysAfterEvent(v)}
          />
        </mui.core.Grid>
      </mui.core.Grid>
    </mui.core.Box>
  );
};

export default Form;
