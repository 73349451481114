import { config, helpers, ts } from '_core';

export const getNewBasket = (
  name: string,
  handle: string,
  optimizerType = 'CVXPY' as ts.types.optimizer.OptimizerConfig['optimizer_type'],
  type: ts.enums.BASKET_TYPE_ENUM,
  template: ts.types.basket.Basket = null
) => {
  const optimizer_config = {
    optimizer_type: optimizerType,
    objective_function: {
      id: helpers.gibberishGenerator.stringGenerator(11),
      mean_variance_type: 'min_risk_only',
      tcost_multiplier: 0.00000001,
      benchmark_relative: true,
      lambda_: optimizerType == 'CVXPY' ? 2.0 : 1 / 100,
    },
    constraints: [
      {
        constraint_type: 'gross_leverage_constraint',
        id: helpers.gibberishGenerator.stringGenerator(11),
        lower_bound: 1,
        upper_bound: 1,
        relax_order: 'NEVER',
        target_penalty: 1,
      },
      {
        constraint_type: 'all_assets_constraint',
        relax_order: 'NEVER',
        lower_bound: 0,
        upper_bound: 1,
        benchmark_relative: false,
        type: 'AllAssets',
      },
    ],
    keep_all_linked_assets: true,
  } as ts.types.optimizer.OptimizerConfig;

  let nullDefinition: ts.types.basket.DefinitionDraft = {
    source_type: 'universe',
    conditions: null,
    ranking_measure: null,
    initial_value: 100000000,
    must_complete: true,
    optimizer_rebalance: false,
    fixed_value: false,
    minimum_weight: 1e-4,
    return_lag_days: 0,
    benchmark: { type: 'BASKET' },
    frequency: ts.enums.FREQUENCY_ENUM.WEEKLY,
    optimizer_config,
    weights: 'MARKET_CAP',
  };

  if (type == ts.enums.BASKET_TYPE_ENUM.COMBINED) {
    nullDefinition = {
      time_series_percentile: true,
      initial_value: 100000000,
      optimizer_rebalance: false,
      frequency: ts.enums.FREQUENCY_ENUM.WEEKLY,
      sources: [],
      conditions: [],
      optimizer_config: { ...optimizer_config },
      benchmark: { type: 'BASKET' },
    };
  }

  const newBasket: ts.types.basket.BasketDraft = {
    name,
    handle,
    is_valid: false,
    definition: nullDefinition,
    start_date: config.features.start_date,
    end_date: config.features.end_date,
    end_date_latest: false,
    type,
  };

  // Check if we will create the basket with a premade definition
  if (template && template.id) {
    newBasket.definition = {
      ...template.definition,
    };
    newBasket.start_date = template.start_date;
    newBasket.end_date = template.end_date;
    newBasket.end_date_latest = template.end_date_latest;
    newBasket.signature = template.signature;
    newBasket.is_valid = true;
    newBasket.label_ids = template.label_ids;
    newBasket.definition.optimizer_config.optimizer_type = optimizerType;
  }

  return newBasket;
};

export const getArchivedDeps = (
  basket: ts.types.basket.Basket,
  resources: ts.StoreState['resources']
): ts.types.common.ArchivedDeps => {
  const deps = helpers.resourceGraph.getDeps(
    { ...basket, resourceType: ts.enums.RESOURCES_TYPES_ENUM.BASKET },
    resources
  );
  return helpers.resourceGraph.getArchiveDeps(deps);
};

export const getUnpublishedDeps = (
  basket: ts.types.basket.Basket,
  resources: ts.StoreState['resources']
): ts.types.common.ResourceDeps[] => {
  return helpers.resourceGraph
    .getDeps({ ...basket, resourceType: ts.enums.RESOURCES_TYPES_ENUM.BASKET }, resources)
    .filter((d) => !d.is_published);
};

export const getUnsharedDeps = (
  basket: ts.types.basket.Basket,
  resources: ts.StoreState['resources'],
  sharedWith?: number[]
): ts.types.common.ResourceDeps[] => {
  if (!sharedWith) sharedWith = basket.shared_with || [];

  return helpers.resourceGraph
    .getDeps({ ...basket, resourceType: ts.enums.RESOURCES_TYPES_ENUM.BASKET }, resources)
    .filter((d) => !helpers.resourcesUtils.isShared(d, sharedWith));
};
