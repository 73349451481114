const customUrl = typeof window !== 'undefined' ? (window as any)?.CUSTOM_URL || '' : '';

export const providerLogo = {
  'Finsera Core': customUrl + '/datasets/finsera.png',
  'Finsera Time Series': customUrl + '/datasets/fred.png',
  FactSet: customUrl + '/datasets/factset.png',
  'S&P Global': customUrl + '/datasets/sp-global.png',
  Refinitiv: customUrl + '/datasets/refinitiv.png',
  'IHS Markit': customUrl + '/datasets/ihs-markit.png',
  OptionMetrics: customUrl + '/datasets/option-metrics.png',
  Sensefolio: customUrl + '/datasets/sensefolio.png',
  'Impact Cubed': customUrl + '/datasets/impact-cubed.png',
  Thinknum: customUrl + '/datasets/thinknum.png',
  GIST: customUrl + '/datasets/gist.png',
  Pronto: customUrl + '/datasets/pronto.png',
  'Cirrus Research': customUrl + '/datasets/citrus.png',
  ITG: customUrl + '/datasets/itg.png',
  'Financial Modeling Prep': customUrl + '/datasets/fmp.png',
  Intrinio: customUrl + '/datasets/intrinio.png',
  'OWL ESG': customUrl + '/datasets/owl.png',
  'Forensic Alpha': customUrl + '/datasets/forensic-alpha.png',
  Wilshire: customUrl + '/datasets/wilshire-indexes.png',
  'Visible Alpha': customUrl + '/datasets/visible-alpha.png',
  'Maiden Century': customUrl + '/datasets/maiden-century.png',
  'Just Capital': customUrl + '/datasets/just-capital.png',
  Drop: customUrl + '/datasets/drop.png',
  'Menta Capital': customUrl + '/datasets/menta.png',
} as Record<string, string>;

export const defaultDsOrder = [
  'fs_asset_master',
  'fs_quotation_country',
  'fgp_global_prices',
  'fp_total_returns_daily',
  'fgp_ca_events',
  'fx_rates_usd',
  'rbics_focus',
  'federal_funds_effective_rate',
  'yield_spread_1yr',
  'market_yield_us_treasury_sec_2yr',
  'yield_spread_5yr',
  'market_yield_us_treasury_sec_10yr',
  'yield_spread_10_2yr',
  'credit_spread',
  'ice_bofa_us_high_yield_option_spread',
  'real_interest_rate_10_year',
  'breakeven_inflation_rate_10yr',
  'consumer_sentiment',
  'capacity_utilization_manufacturing_computers_comms_semiconductors',
  'brent_crude',
  'crude_oil_west_texas_intermediate',
  'natural_gas_spot_price',
  'copper_global_price',
  'copper_daily',
  'gold_daily',
  'silver_daily',
  'oil_daily',
  'broad_us_dollar_index',
  'dollar_euro_spot_ex_rate',
  'yen_dollar_spot_ex_rate',
  'sp500',
  'nasdaq_100_index',
  'wilshire_smallcap',
  'vix_index',
  'ff_ann',
  'ff_semi',
  'ff_qtr',
  'etf_holdings_by_etf',
  'etf_holdings',
  'etf_time_series',
  'etf_coverage',
  'fe_conh_saf',
  'fe_conh_af',
  'fe_conh_qf',
  'fe_conh_lt',
  'fe_conh_rec',
  'fe_act_af',
  'fe_act_qf',
  'fe_act_saf',
  'co_afnd2',
  'co_ifndq',
  'co_afnd1',
  'reuters_std',
  'ibes_actuals',
  'ibes_estimates',
  'ipreo_fund',
  'ipreo_fund_holding',
  'ipreo_company',
  'ipreo_company_holding',
  'ipreo_company_fund',
  'ivy_historical_volatility',
  'ivy_option_price',
  'ivy_option_volume',
  'ivy_security_price',
  'ivy_std_option_price',
  'ivy_volatility_surface',
  'impact_metrics',
  'consensus_scores',
  'principle_based_screens',
  'iq_esg',
  'ic_qtr_esg',
  'tn_glassdoor',
  'tn_linkedin',
  'tn_jobs_listings',
  'tn_webtraffic',
  'gist_sustainability',
  'gist_impact_imputed_disclosed',
  'gist_impact_on_un_sustainable_development_goals',
  'fmp_cmdty',
  'fmp_marketcap',
  'fmp_etf_holdings',
  'fmp_etf_holdings_by_etf',
  'fmp_financial_growth',
  'fmp_income_statement_growth',
  'fmp_financial_ratios',
  'fmp_key_metrics',
  'cirrus_ddi',
  'alphaESG_companyremarks',
  'alphaESG_analystQs',
  'sensefolio_company_scores',
  'sensefolio_mean_peer_group_scores',
  'sensefolio_companies',
  'us_interest_rates',
  'itg_tcosts',
  'financial_growth_monthly',
  'enterprise_values_monthly',
  'financial_ratios_monthly',
  'stock_index',
];
