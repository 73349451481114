import { actions, mui, React, useDispatch, useSelector } from '_core';

import ApiDetails from './details';

const ApiDocs = () => {
  const [alertError, setAlertError] = React.useState();

  const dispatch = useDispatch();
  const theme = mui.styles.useTheme() as mui.core.Theme;

  const apiSelectedTab = useSelector((state) => state.ui.apiSelectedTab);

  const handleTabChange = (event: React.ChangeEvent<any>, newValue: number) => {
    dispatch(actions.ui.setApiSelectedTab(newValue));
  };

  const menuItem = {
    marginBottom: '5px',
    padding: '10px',
    maxWidth: 'none',
    borderRadius: '4px!important',
    textAlign: 'left',
    alignItems: 'flex-start',
    fontSize: '14px',
    fontWeight: 500,
    color: '#202541',
    '&.Mui-selected': {
      background: '#E8ECF3',
      borderRight: '0!important',
    },
    '& svg': {
      fontSize: '15px',
    },
  };

  return (
    <mui.core.Grid
      container
      sx={{
        flex: 1,
        height: '100%',
        [theme.breakpoints.only('xs')]: {
          display: 'block',
        },
      }}
    >
      <mui.core.Grid item xs={12} sm={2}>
        <mui.core.Tabs
          value={apiSelectedTab}
          aria-label="Preferences tabs"
          orientation="vertical"
          onChange={handleTabChange}
          sx={{
            background: theme.palette.background.default,
            height: '100%',
            width: '100%',
            padding: '10px',
            '& .MuiTabs-indicator': {
              display: 'none',
            },
          }}
        >
          <mui.core.Tab
            label={
              <mui.core.Box display="flex" alignItems="center">
                <mui.icons.Upload sx={{ color: theme.palette.primary.main }} />
                <mui.core.Box ml={2}>Import</mui.core.Box>
              </mui.core.Box>
            }
            sx={menuItem}
          />
          <mui.core.Tab
            label={
              <mui.core.Box display="flex" alignItems="center">
                <mui.icons.FileDownload sx={{ color: theme.palette.primary.main }} />
                <mui.core.Box ml={2}>Export</mui.core.Box>
              </mui.core.Box>
            }
            sx={menuItem}
          />
        </mui.core.Tabs>
      </mui.core.Grid>
      <mui.core.Grid item xs={12} sm={10}>
        <mui.core.Box
          sx={{
            overflow: 'auto',
            height: '85vh',
          }}
        >
          {alertError && (
            <mui.lab.Alert onClose={() => setAlertError(null)} severity="error">
              {alertError}
            </mui.lab.Alert>
          )}
          {apiSelectedTab == 0 && (
            <ApiDetails
              url="/api/user-openapi.json?tags=import&excluded_tags=import&excluded_tags=global"
              label="Import"
            />
          )}
          {apiSelectedTab == 1 && (
            <ApiDetails
              url="/api/user-openapi.json?tags=export&excluded_tags=export&excluded_tags=global"
              label="Export"
            />
          )}
        </mui.core.Box>
      </mui.core.Grid>
    </mui.core.Grid>
  );
};

export default ApiDocs;
