import React from 'react';

import { mui, useSelector } from '../libs';
import * as types from '../types';
import { BaseStore } from '../types';

type WorkspaceSelectorProps = {
  /** Currently selected workspace */
  value: types.workspace.Workspace;
  /** Callback when workspace selection changes */
  setValue: (_ws: types.workspace.Workspace) => void;
  /** Label text for the selector */
  label?: string;
};

/**
 * Workspace selector component that allows selecting between research and production workspaces
 * 
 * @requires Redux Store:
 * - state.resources.workspaces: Array of available workspaces
 * - state.ui.currentTab: Current tab indicating context ('production' or 'research')
 * 
 * @param value Currently selected workspace
 * @param setValue Callback when workspace selection changes
 * @param label Label text for the selector
 * @returns Rendered component
 */
const WorkspaceSelector: React.FC<WorkspaceSelectorProps> = ({
  value,
  setValue,
  label = 'Add to workspace',
}): React.ReactElement => {
  // Get workspaces from store
  const workspaces = useSelector((state: types.BaseStore) => state.resources.workspaces);

  // Get current tab to determine context
  const currTab = useSelector((state: BaseStore) => state.ui.currentTab);
  const wsContext: types.workspace.Workspace['context'] = React.useMemo(() => {
    if (currTab == 'production') return 'production';
    return 'research';
  }, [currTab]);

  // Filter workspaces based on context
  const workspaceOptions = React.useMemo(
    () => workspaces.filter((ws) => ws.context == wsContext),
    [wsContext, workspaces]
  );

  // Reset to first workspace if current value is not available in context
  React.useEffect(() => {
    if (!workspaceOptions.find((ws) => ws.id == value.id)) setValue(workspaces[0]);
  }, [value, workspaceOptions]);

  return (
    <mui.core.Autocomplete
      options={workspaceOptions}
      value={value}
      onChange={(_e, val) => setValue(val as types.workspace.Workspace)}
      getOptionLabel={(option) => (option as types.workspace.Workspace).name}
      renderInput={(params) => (
        <mui.core.TextField {...params} fullWidth label={label} variant="outlined" required size="small" />
      )}
      disableClearable
    />
  );
};

export default WorkspaceSelector;
