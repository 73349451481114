import React from 'react';

import Button from './button';
import { mui } from '../libs';

type ConfirmationDialogProps = {
  open: boolean;
  title: string;
  content?: React.ReactNode;
  onSuccess: () => void;
  onCancel: () => void;
  successLabel?: string;
  cancelLabel?: string;
  hideCancel?: boolean;
  error?: string;
  disabled?: boolean;
  loading?: boolean;
};

/**
 * Confirmation dialog component for user confirmation actions
 * 
 * @param open - Whether the dialog is open
 * @param title - Dialog title text
 * @param content - Dialog content body
 * @param onSuccess - Callback for confirmation action
 * @param onCancel - Callback for cancel action
 * @param successLabel - Text for success button (default: 'OK')
 * @param cancelLabel - Text for cancel button (default: 'Cancel')
 * @param hideCancel - Whether to hide cancel button
 * @param error - Error message to display
 * @param disabled - Whether confirmation button is disabled
 * @param loading - Whether confirmation action is in progress
 * 
 * @returns A modal dialog for user confirmations
 */

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
  open,
  title,
  content,
  onSuccess,
  onCancel,
  successLabel,
  cancelLabel,
  hideCancel,
  error,
  disabled,
  loading,
}): React.ReactElement => (
  <mui.core.Dialog open={open} onClose={onCancel} maxWidth="xs" fullWidth>
    {error && <mui.lab.Alert severity="error">{error}</mui.lab.Alert>}
    <mui.core.DialogTitle id="alert-dialog-title">{title}</mui.core.DialogTitle>
    <mui.core.DialogContent>
      <mui.core.DialogContentText id="alert-dialog-description">{content}</mui.core.DialogContentText>
    </mui.core.DialogContent>
    <mui.core.DialogActions>
      {!hideCancel && (
        <mui.core.Button onClick={onCancel} color="primary" disabled={loading}>
          {cancelLabel}
        </mui.core.Button>
      )}
      <Button
        onClick={onSuccess}
        disabled={disabled || loading}
        color="primary"
        variant="contained"
        loading={loading}
        autoFocus
      >
        {successLabel}
      </Button>
    </mui.core.DialogActions>
  </mui.core.Dialog>
);

ConfirmationDialog.defaultProps = {
  successLabel: 'OK',
  cancelLabel: 'Cancel',
  hideCancel: false,
  disabled: false,
  content: '',
  error: '',
  loading: false,
};

export default ConfirmationDialog;
