import { mui, React, ui } from '_core';

import { AIAssistantContext } from './ai-assistant-context';
import ConversationChatbox from './conversation-chatbox';

const AiAssistant = () => {
  const conversationContext = React.useContext(AIAssistantContext);
  const { reload, extraActions } = conversationContext;

  return (
    <ui.PanelWithHeader
      title="Finsera Assistant"
      rightAction={
        <>
          <mui.core.Tooltip title="Reload" placement="top">
            <mui.core.IconButton aria-label="reload" onClick={reload} size="small">
              <mui.icons.Refresh />
            </mui.core.IconButton>
          </mui.core.Tooltip>
          {extraActions && extraActions()}
        </>
      }
      icon={<mui.icons.AutoAwesome color="primary" />}
    >
      <mui.core.Box sx={{ overflow: 'auto', height: '100%' }}>
        <ConversationChatbox />
      </mui.core.Box>
    </ui.PanelWithHeader>
  );
};

export default AiAssistant;
