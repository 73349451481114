/* eslint-disable no-unused-vars */
import { ts } from '_core';

import { PartialDeep } from 'type-fest';

export enum INTERACTION_PARAMS {
  EXPANDED = 'expanded',
  TABLE_PARAMS = 'table_params',
}

export type InteractionParams = {
  [INTERACTION_PARAMS.EXPANDED]: Record<string, string[]>;
  [INTERACTION_PARAMS.TABLE_PARAMS]: ts.types.components.dataGrid.TableParamsSheet;
};

export type Params = InteractionParams & { fullWidth: boolean };
export type DraftParams = PartialDeep<Params>;
