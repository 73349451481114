import { ts } from '_core';

import { validate } from './helpers';

export default [
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.SIGNAL_PREVIEW_DOWNLOAD,
    form: true,
    title: 'Download All Data',
    neededFiles: ['main_data_zip'],
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.SIGNAL_PREVIEW],

    validate,
    helpLabel: 'lw4fs45v',
  },
] satisfies ts.types.analysis.WidgetConfig[];
