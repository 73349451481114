/* eslint-disable no-unused-vars */
import { ts } from '_core';

export enum INTERACTION_PARAMS {
  TABLE_PARAMS = 'table_params',
  EXPANDED = 'expanded',
}

export type InteractionParams = {
  [INTERACTION_PARAMS.TABLE_PARAMS]: ts.types.components.dataGrid.TableParamsSheet;
  [INTERACTION_PARAMS.EXPANDED]: Record<string, string[]>;
};

export type JobParams = {
  histories: ts.enums.HISTORY_ENUM_EXTENDED[];
  n_performers: number;
};

export type Params = JobParams & InteractionParams & { fullWidth: boolean };
export type DraftParams = Partial<Params>;
