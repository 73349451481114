import { hooks, mui, React } from '_core';

import { DraftParams, optionLabels } from './types';
import BasketsSelector from '../../common/forms/baskets-selector';

type FormProps = {
  params: DraftParams;
  setParams: (_p: DraftParams) => void;
};

const Form: React.FC<FormProps> = ({ params, setParams }) => {
  const [dailyReporting, setDailyReporting] = React.useState(params?.daily_reporting || false);
  const [basketIds, setBasketIds] = React.useState(params.basket_ids || []);
  const [statisticsTypes, setStatisticsType] = React.useState(params.statistics_types || []);
  const uiStyles = hooks.useUiStyles();

  const statisticsOptions = ['cumulative_returns', 'return', 'risk', 'adjusted'];

  React.useEffect(() => {
    const data = {
      ...params,
      daily_reporting: dailyReporting,
      basket_ids: basketIds,
      statistics_types: statisticsTypes,
    };
    setParams(data);
  }, [basketIds, statisticsTypes, dailyReporting]);

  return (
    <mui.core.Box sx={uiStyles.widgetEditMode}>
      <mui.core.Box mt={2}>
        <mui.core.FormControl fullWidth>
          <mui.core.Autocomplete
            size="small"
            multiple
            options={statisticsOptions}
            value={statisticsTypes}
            onChange={(_event, newValue) => {
              setStatisticsType(newValue as typeof statisticsTypes);
            }}
            disableCloseOnSelect
            renderInput={(params) => <mui.core.TextField {...params} variant="outlined" label="Statistics" />}
            getOptionLabel={(opt) => optionLabels[opt as (typeof statisticsTypes)[0]]}
          />
        </mui.core.FormControl>
      </mui.core.Box>

      <mui.core.Box mt={2}>
        <BasketsSelector basketIds={basketIds} setBasketIds={setBasketIds} />
      </mui.core.Box>

      <mui.core.Box mt={2}>
        <mui.core.FormControlLabel
          control={
            <mui.core.Switch
              size="small"
              checked={dailyReporting}
              onChange={() => setDailyReporting(!dailyReporting)}
            />
          }
          label="Daily Reporting"
        />
      </mui.core.Box>
    </mui.core.Box>
  );
};

export default Form;
