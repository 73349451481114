import { ts } from '_core';

import { INTERACTION_PARAMS } from './types';

export default [
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.TIME_SERIES_PREVIEW,
    form: false,
    title: 'Time Series Preview',
    interactionParams: Object.values(INTERACTION_PARAMS),
    clearInteractionOnRegenerate: ['table_dtypes'],
    neededFiles: ['ts_main_data'],
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.TIME_SERIES_PREVIEW],

    helpLabel: 'm5qif8h5',
  },
] satisfies ts.types.analysis.WidgetConfig[];
