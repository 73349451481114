import { config, helpers, mui, React, ts, ui,useHistory, useSelector } from '_core';

import * as utils from './backtest-utils';

type BacktestFormProps = {
  open: boolean;
  setOpen: (_open: boolean) => void;
};

const BacktestForm: React.FC<BacktestFormProps> = ({ open, setOpen }): React.ReactElement => {
  const backtests = useSelector((state) => state.resources.backtests as ts.types.backtest.Backtest[]);
  const workspaces = useSelector((state) => state.resources.workspaces as ts.types.workspace.Workspace[]);
  const currentWorkspaceId = useSelector((state) => state.ui.currentWorkspaceId);

  const history = useHistory();

  const [errorMessage, setErrorMessage] = React.useState('');
  const [name, setName] = React.useState('');
  const [handle, setHandle] = React.useState('');
  const [optimizerType, setOptimizerType] =
    React.useState<ts.types.optimizer.OptimizerConfig['optimizer_type']>('CVXPY');
  const [longOption, setLongOption] = React.useState<ts.enums.LONG_OPTION_ENUM>(ts.enums.LONG_OPTION_ENUM.LONG_SHORT);
  const [template, setTemplate] = React.useState<ts.types.backtest.Backtest>();
  const [loadingCreate, setLoadingCreate] = React.useState(false);
  const [workspace, setWorkspace] = React.useState(workspaces.find((w) => w.id == currentWorkspaceId) || workspaces[0]);

  React.useEffect(() => {
    setHandle(helpers.createHandle(name, 'bt'));
  }, [name]);

  const handleSubmit = async () => {
    setErrorMessage('');

    // Validation
    if (!name || !handle) {
      setErrorMessage('All attributes are required.');
      setLoadingCreate(false);
      return;
    }

    setLoadingCreate(true);

    const newBacktest = utils.definition.getNewBacktest(name, handle, longOption, optimizerType, template);
    utils.apiProcedures.createBacktest(
      newBacktest,
      async (data) => {
        if (workspace)
          await helpers.resourcesUtils.addToWorkspace(data.id, ts.enums.UI_RESOURCE_TYPE_ENUM.BACKTEST, workspace);
        setOpen(false);
        history.push(`/backtests/${data.id}/definition`);
      },
      async (err) => {
        setErrorMessage(err);
        setLoadingCreate(false);
      }
    );
  };

  const validateOptimizer = template && optimizerType != template?.definition.optimizer_config.optimizer_type;

  const optimizerWarning = {
    message: 'The template optimizer differs from the one selected, user selected optimizer will be kept',
    severity: ts.enums.ALERT_SEVERITY_ENUM.WARNING,
  };

  const formContent = () => (
    <mui.core.Box mt={3}>
      <mui.core.Grid container spacing={3}>
        <mui.core.Grid item md={6}>
          <mui.core.TextField
            autoFocus
            id="name"
            label="Backtest name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            fullWidth
            variant="outlined"
            required
            size="small"
            inputProps={{
              autocomplete: 'off',
            }}
          />
        </mui.core.Grid>
        <mui.core.Grid item md={6}>
          <mui.core.TextField
            id="handle"
            label="Backtest handle"
            value={handle}
            onChange={(e) => setHandle(e.target.value)}
            fullWidth
            variant="outlined"
            required
            size="small"
          />
        </mui.core.Grid>
      </mui.core.Grid>

      <mui.core.Box pt={4}>
        <mui.core.TextField
          id="optimizer"
          select
          label="Is this a long/short or a long-only backtest?"
          variant="outlined"
          value={longOption}
          onChange={(e) => {
            setLongOption(e.target.value as ts.enums.LONG_OPTION_ENUM);
            setTemplate(null);
          }}
          fullWidth
          required
          size="small"
        >
          <mui.core.MenuItem value="long-short">Long/Short</mui.core.MenuItem>
          <mui.core.MenuItem value="long-only">Long-Only</mui.core.MenuItem>
        </mui.core.TextField>
      </mui.core.Box>

      <mui.core.Box py={4}>
        <mui.core.TextField
          id="optimizer"
          select
          label="Select an Optimizer"
          variant="outlined"
          value={optimizerType}
          onChange={(e) => setOptimizerType(e.target.value as ts.types.optimizer.OptimizerConfig['optimizer_type'])}
          fullWidth
          required
          size="small"
        >
          <mui.core.MenuItem value="CVXPY">Finsera Optimizer</mui.core.MenuItem>
          {config.features.has_barra_optimizer && <mui.core.MenuItem value="BARRA">Barra Optimizer</mui.core.MenuItem>}
        </mui.core.TextField>
      </mui.core.Box>

      <mui.core.Box pb={4}>
        <ui.InputWrapper alert={validateOptimizer ? optimizerWarning : null}>
          <ui.ResourceAutocomplete<ts.types.backtest.BacktestExpanded>
            enableNone
            value={template?.id}
            setValue={(id) => setTemplate(backtests.find((u) => u.id == id))}
            type={ts.enums.RESOURCES_TYPES_ENUM.BACKTEST}
            filter={(b) => {
              return b.longOption == longOption && !b.source_resource_id;
            }}
            inputProps={{
              fullWidth: true,
              label: 'Template',
              variant: 'outlined',
              required: true,
              size: 'small',
            }}
          />
        </ui.InputWrapper>
      </mui.core.Box>
      <ui.WorkspaceSelector value={workspace} setValue={setWorkspace} />
    </mui.core.Box>
  );

  return (
    <mui.core.Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="sm"
      fullWidth
    >
      {errorMessage && (
        <mui.lab.Alert onClose={() => setErrorMessage('')} severity="error">
          {errorMessage}
        </mui.lab.Alert>
      )}
      <ui.DialogTitle closeAction={() => setOpen(false)}>Create Backtest</ui.DialogTitle>
      <mui.core.DialogContent>{formContent()}</mui.core.DialogContent>
      <mui.core.DialogActions>
        <ui.Button
          variant="contained"
          color="primary"
          loading={loadingCreate}
          onClick={handleSubmit}
          fullWidth
          style={{ margin: '0rem 1rem 1rem' }}
        >
          Save Backtest
        </ui.Button>
      </mui.core.DialogActions>
    </mui.core.Dialog>
  );
};

export default BacktestForm;
