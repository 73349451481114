import { api, helpers, ts } from '_core';

type PipelineApiResponse = {
  data: ts.types.pipeline.Pipeline;
};

export const createPipeline = async (
  newData: ts.types.pipeline.PipelineDraft,
  callback: (_data: ts.types.pipeline.Pipeline) => void,
  errorCallback: (_error: string) => void
) => {
  try {
    const resp: PipelineApiResponse = await api.pipelines.create(newData);
    callback(resp.data);
  } catch (error) {
    errorCallback(`Error creating pipeline - ${helpers.parseApiError(error as ts.types.common.ApiError)}`);
  }
};

export const updatePipeline = async (
  id: number,
  newData: ts.types.pipeline.PipelineDraft,
  callback: (_data: ts.types.pipeline.Pipeline) => void,
  errorCallback: (_error: string) => void
) => {
  try {
    const resp: PipelineApiResponse = await api.pipelines.update(id, { ...newData });
    callback(resp.data);
  } catch (error) {
    errorCallback(`Error updating pipeline - ${helpers.parseApiError(error as ts.types.common.ApiError)}`);
  }
};

export const updateDefinition = async (
  id: number,
  definition: ts.types.pipeline.DefinitionDraft,
  isValid: boolean,
  callback: (_data: ts.types.pipeline.Pipeline) => void,
  errorCallback: (_error: string) => void
) => {
  try {
    const resp = await api.pipelines.update(
      id,
      {
        definition,
        is_valid: isValid,
        skip_signature_check: !isValid,
      },
      true
    );

    if (resp.data?.error) errorCallback(`Validation issue - ${resp.data?.error}`);
    else callback(resp.data);
  } catch (error) {
    errorCallback(`Error updating the definition - ${helpers.parseApiError(error as ts.types.common.ApiError)}`);
  }
};

export const updateUiMetadata = async (
  pipeline: ts.types.pipeline.Pipeline,
  uiMetaData: ts.types.pipeline.UiMetaData,
  callback: (_data: ts.types.pipeline.Pipeline) => void,
  errorCallback: (_error: string) => void
) => {
  try {
    if (pipeline.shared) return;

    const updateData = {
      ui_metadata: uiMetaData,
      skip_signature_check: true,
    } as ts.types.pipeline.PipelineDraft;
    const resp = await api.pipelines.update(pipeline.id, updateData);
    callback(resp.data);
  } catch (error) {
    errorCallback(`Error updating the resource metadata - ${helpers.parseApiError(error as ts.types.common.ApiError)}`);
  }
};
