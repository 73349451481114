import { ts } from '_core';


export default [
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.BACKTEST_PORTFOLIO_EXPOSURES_OVER_TIME,
    title: 'Portfolio Exposures Over Time',
    helpLabel: '10bzregl',
    form: false,
    neededFiles: ['backtest_portfolio_exposures'],
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.BACKTEST],
  },
] satisfies ts.types.analysis.WidgetConfig[];
