import { ts } from '_core';


export default [
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.ALPHA_MODEL_LINEAR_SIGNAL_WEIGHTS_TIME,
    title: 'Signal Weights Vs. Time',
    helpLabel: 'lijbfzeo',
    form: false,
    neededFiles: ['linear_alpha_model_data'],
    tags: [
      ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.ALPHA_MODEL,
      ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.LINEAR_ALPHA_MODEL,
    ],
  },
] satisfies ts.types.analysis.WidgetConfig[];
