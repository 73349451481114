import { ts } from '_core';

import { DraftParams, INTERACTION_PARAMS } from './types';

export default [
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.BASKET_RETURNS_DRIVEN_BY_SECTOR,
    title: 'Returns Decomposition by Sector',
    form: false,
    helpLabel: 'a4blzlby',
    defaultParams: {
      histories: [
        ts.enums.HISTORY_ENUM_EXTENDED.ONE,
        ts.enums.HISTORY_ENUM_EXTENDED.THREE,
        ts.enums.HISTORY_ENUM_EXTENDED.FIVE,
        ts.enums.HISTORY_ENUM_EXTENDED.FULL,
        ts.enums.HISTORY_ENUM_EXTENDED.YTD,
      ],
      selectedHistory: ts.enums.HISTORY_ENUM_EXTENDED.ONE,
      statistics_type: ts.enums.REPORT_ENUMS.ANNU_STATISTICS_ENUM.RETURNS,
      buckets: [
        { type: 'INDUSTRY', value: 'rbics_l1_name' },
        { type: 'ASSET_MASTER', value: 'name' },
      ],
    } as DraftParams,
    interactionParams: Object.values(INTERACTION_PARAMS),
    neededFiles: ['signal_returns_driven_attribution_chart'],
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.BASKET_ATTRIBUTION],
  },
] satisfies ts.types.analysis.WidgetConfig[];
