import { _, React, ts } from '_core';

import Chart from './chart';
import Form from './form';
import { DraftParams, Params } from './types';

type RiskModelCoverageProps = {
  id: string;
  readOnly: boolean;
  widgetKey: string;
  mode: ts.enums.CHART_MODE_ENUM;
  params: Params | DraftParams;
  setParams: (_p: Params) => void;
  title: string;

  fullScreen?: boolean;
  context?: ts.types.riskModel.RiskModelAnalysisContext;
  loadWidgetData: (_payload: ts.types.widgets.WidgetGetDataParams) => Promise<ts.types.widgets.WidgetGetDataResponse>;
  widgetPaths?: Record<'risk_model_coverage', string>;
};

const RiskModelCoverage: React.FC<RiskModelCoverageProps> = ({
  id,
  readOnly,
  widgetKey,
  mode,
  params,
  setParams,
  loadWidgetData,
  widgetPaths,
  context,
  fullScreen,
  title,
}): React.ReactElement => {
  const renderJobComponent = () => (
    <>
      <Chart
        id={id}
        readOnly={readOnly}
        widgetKey={widgetKey}
        loadWidgetData={loadWidgetData}
        coverageFile={widgetPaths['risk_model_coverage']}
        fullScreen={fullScreen}
        title={title}
        context={context}
        params={params as Params}
      />
    </>
  );

  return (
    <>
      {mode == 'edit' ? (
        <Form params={params} setParams={setParams} context={context} noContext={_.isEmpty(context)} />
      ) : (
        renderJobComponent()
      )}
    </>
  );
};

export default RiskModelCoverage;
