import CenteredLoader from './centered-loader';
import EmptyMessage from './empty-message';
import { mui } from '../libs';

type ReportNoDataProps = {
  status?: 'invalid' | 'ready' | 'generating' | 'none' | 'loading';
};

/**
 * Component that displays loading states and empty message for reports
 *
 * @param status - The current status of the report, which can be 'invalid', 'ready', 'generating', 'none', or 'loading'
 * @returns Rendered component showing appropriate loading or empty state
 */
const ReportNoData: React.FC<ReportNoDataProps> = ({ status }): React.ReactElement => {
  return (
    <mui.core.Card
      sx={{
        height: 'calc(100% - 8px)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'white',
        m: 1,
      }}
    >
      {status == 'generating' ? (
        <CenteredLoader label="Generating Report..." />
      ) : status == 'loading' ? (
        <CenteredLoader />
      ) : (
        <EmptyMessage hideBorder>Generate the report to display data</EmptyMessage>
      )}
    </mui.core.Card>
  );
};

ReportNoData.defaultProps = {
  status: 'none',
};

export default ReportNoData;
