import { helpers, ls, React, ts, ui } from '_core';

type ItemProps = {
  item: helpers.resourcesSearch.ReturnItem;
  clickCallback?: () => void;
  source: 'recently-opened' | 'search-results';
};

const Item: React.FC<ItemProps> = ({ item, clickCallback, source }): React.ReactElement => {
  const plural = helpers.pluralize.plural(item.resourceType);

  const clickHandler = () => {
    clickCallback();
    if (source === 'search-results') {
      ls.pushAndDropLast({ data: item, path: 'recently-opened', stackSize: 20 });
    }
  };

  return (
    <div style={{ marginBottom: '-5px' }}>
      <ui.InlineItem
        resource={item}
        resourceType={item.resourceType as ts.enums.RESOURCES_TYPES_ENUM}
        to={`/${plural}/${item.id}/definition`}
        clickCallback={clickHandler}
      />
    </div>
  );
};

export default Item;
