import { ts } from '_core';

import { getName, validate } from './helpers';
import { DraftParams, INTERACTION_PARAMS } from './types';

export default [
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.BACKTEST_SET_METRIC_VS_PARAM,
    title: 'Metrics vs. Parameters',
    helpLabel: 'aeo94e9o',
    defaultParams: {
      file: 'portfolios',
      aggregation_params: {
        force_all_row: true,
      },
    } as DraftParams,
    interactionParams: Object.values(INTERACTION_PARAMS),
    neededFiles: ['backtest_metric_vs_parameter_analysis'],
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.BACKTEST_SET],
    form: true,

    getName,
    validate,
  },
] satisfies ts.types.analysis.WidgetConfig[];
