import { mui, React, ts, ui } from '_core';

import EmbedHeader from './embed-header';
import ErrorComponent from './error-component';
import ChartWrapper from '../widgets/common/chart-wrapper';
import createLineChart from '../widgets/common/charts/create-line-chart';
import createMultiScaleLineChart from '../widgets/common/charts/create-multi-scale-line-chart';
import createNestedBarChart from '../widgets/common/charts/create-nested-bar-chart';
import createScatterChart from '../widgets/common/charts/create-scatter-chart';

type ChartRendererProps = { widgetData: ts.types.analysis.EmbedChartData; fullScreen: boolean; download: boolean };
const ChartRenderer = ({ widgetData, fullScreen, download }: ChartRendererProps) => {
  const [unableToLoad, setUnableToLoad] = React.useState(false);
  const [showLegend, setShowLegend] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (widgetData.chart_type === 'line-chart-v5') return;
    if (widgetData.chart_type === 'stacked-chart-v5') return;
    if (widgetData.chart_type === 'nested-bar-chart-v5') return;

    // Only for old line charts
    if (widgetData.chart_type === 'line-chart') {
      createLineChart({
        ...widgetData.args,
        widgetIsEmbedded: { download },
        setShowLegend,
        fullScreen,
      } as any);
    } else if (widgetData.chart_type === 'nested-bar-chart') {
      createNestedBarChart({
        ...widgetData.args,
        widgetIsEmbedded: { download },
        setShowLegend,
        fullScreen,
      } as any);
    } else if (widgetData.chart_type === 'multiscale-line-chart') {
      createMultiScaleLineChart({
        ...widgetData.args,
        widgetIsEmbedded: { download },
        setShowLegend,
        fullScreen,
      } as any);
    } else if (widgetData.chart_type === 'scatter-chart') {
      createScatterChart({
        ...widgetData.args,
        widgetIsEmbedded: { download },
        setShowLegend,
        fullScreen,
      } as any);
    } else {
      setUnableToLoad(true);
    }
  }, []);
  if (unableToLoad) return <ErrorComponent />;

  const renderChart = () => {
    if (widgetData.chart_type === 'line-chart-v5') {
      return (
        <ui.LineChart
          {...widgetData.args}
          id={widgetData.id}
          data={widgetData.args.data}
          readOnly={false}
          fullScreen={fullScreen}
          download={download}
        />
      );
    }

    if (widgetData.chart_type === 'stacked-chart-v5') {
      return (
        <ui.StackedChart
          {...widgetData.args}
          id={widgetData.id}
          data={widgetData.args.data}
          readOnly={false}
          fullScreen={fullScreen}
          download={download}
        />
      );
    }

    if (widgetData.chart_type === 'nested-bar-chart-v5') {
      return (
        <ui.NestedBarChart
          {...widgetData.args}
          id={widgetData.id}
          data={widgetData.args.data}
          readOnly={false}
          fullScreen={fullScreen}
          download={download}
        />
      );
    }

    return (
      <ChartWrapper
        data
        id={widgetData.id}
        fullScreen={fullScreen}
        showLegend={showLegend}
        readOnly={false}
        widgetKey="embeddedChart"
        disableProfiling
        showPeriodSelector={widgetData.args?.showPeriodSelector}
      />
    );
  };

  return (
    <mui.core.Box height="100%" display="flex" flexDirection="column">
      <EmbedHeader title={widgetData.widgetTitle} subtitle={widgetData.widgetSubTitle} fullScreen={fullScreen} />
      {renderChart()}
    </mui.core.Box>
  );
};

export default ChartRenderer;
