import React from 'react';

import { _, mui } from '../../../libs';
import * as types from '../../../types';
import CheckboxItem from '../../checkbox-item';

type MultiSelectRuleProps = {
  operator: string;
  value: (string | number)[];
  setRule: (_o: string, _v: (string | number)[]) => void;
  readOnly?: boolean;
  config: types.components.queryBuilder.QbAttributes;
};

const MultiSelectRule: React.FC<MultiSelectRuleProps> = ({
  operator,
  value,
  setRule,
  config,
  readOnly,
}): React.ReactElement => {
  const getValue = () => {
    return options.length !== 0 ? options.filter((o) => value.find((v) => v === o.value || v === o.compatibility)) : [];
  };

  const [options, setOptions] = React.useState<types.components.queryBuilder.QbOption[]>(config.options);

  const [selected, setSelected] = React.useState<types.components.queryBuilder.QbOption[]>(getValue);

  // only load the selected values on value changes
  React.useEffect(() => {
    setSelected(getValue);
  }, [value, options]);

  React.useEffect(() => {
    setOptions(config.options);
  }, [config]);

  return (
    <mui.core.Grid container spacing={2}>
      <mui.core.Grid item xs={2}>
        <mui.core.TextField
          select
          value={operator || ''}
          onChange={(e) => setRule(e.target.value, value)}
          fullWidth
          variant="outlined"
          disabled={readOnly}
          size="small"
        >
          <mui.core.MenuItem value="" disabled>
            Operator
          </mui.core.MenuItem>
          <mui.core.MenuItem value="IN">In</mui.core.MenuItem>
          <mui.core.MenuItem value="NOT IN">Not in</mui.core.MenuItem>
        </mui.core.TextField>
      </mui.core.Grid>
      <mui.core.Grid item xs>
        <mui.core.Autocomplete<types.components.queryBuilder.QbOption, true>
          multiple
          options={options}
          getOptionLabel={(option) => (option as types.components.queryBuilder.QbOption).name}
          disableCloseOnSelect
          groupBy={(option) => option.group ?? config.name}
          disabled={readOnly}
          value={selected}
          onChange={(e, v) =>
            setRule(
              operator,
              _.uniqBy(v, 'value').map((o) => (o as types.components.queryBuilder.QbOption).value)
            )
          }
          size="small"
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <CheckboxItem selected={selected} name={option.name} help={option.help} />
            </li>
          )}
          renderInput={(params) => <mui.core.TextField {...params} fullWidth variant="outlined" label={config.label} />}
          renderTags={(tagValue, getTagProps) =>
            tagValue.map((option, index) => (
              <mui.core.Chip
                key={index}
                label={option ? option.short_name || option.name : 'value not in config'}
                {...getTagProps({ index })}
              />
            ))
          }
        />
      </mui.core.Grid>
    </mui.core.Grid>
  );
};

MultiSelectRule.defaultProps = {
  readOnly: false,
};

export default MultiSelectRule;
