import { hooks, mui, React, ui } from '_core';

import { DraftParams, Params } from './types';

type FormProps = {
  params: DraftParams;
  setParams: (_p: Params) => void;
};

const Form: React.FC<FormProps> = ({ params, setParams }): React.ReactElement => {
  const [window, setWindow] = React.useState(params.window);

  const uiStyles = hooks.useUiStyles();

  hooks.useEffectWithoutFirst(() => {
    const data = { ...params, window };
    setParams(data);
  }, [window]);

  return (
    <mui.core.Box sx={uiStyles.widgetEditMode}>
      <mui.core.Box mt={4}>
        <ui.NumericField
          textFieldProps={{
            label: 'Forward Window (days)',
            fullWidth: true,
            variant: 'outlined',
            size: 'small',
            required: true,
          }}
          value={window}
          setValue={(v) => setWindow(v)}
        />
      </mui.core.Box>
    </mui.core.Box>
  );
};

export default Form;
