import { hooks, mui, React, ts, ui } from '_core';

import { DraftParams } from './types';

type FormProps = {
  params: DraftParams;
  setParams: (_p: DraftParams) => void;
  isGeneralAnalysis: boolean;
  context: ts.types.timeSeries.TimeSeriesAnalysisContext;
};

const Form: React.FC<FormProps> = ({ params, setParams, isGeneralAnalysis, context }) => {
  const [mainTs, setMainTs] = React.useState(params.time_series_id);
  const [comparets, setcomparets] = React.useState(params.compare_time_series_id);

  const uiStyles = hooks.useUiStyles();

  React.useEffect(() => {
    const data = {
      ...params,
      compare_time_series_id: comparets,
      time_series_id: isGeneralAnalysis ? mainTs : undefined,
    };
    setParams(data);
  }, [comparets, mainTs]);

  return (
    <mui.core.Box sx={uiStyles.widgetEditMode}>
      {isGeneralAnalysis && (
        <ui.ResourceAutocomplete<ts.types.timeSeries.TimeSeriesExpanded>
          value={mainTs}
          setValue={(id) => setMainTs(id)}
          type={ts.enums.RESOURCES_TYPES_ENUM.TIME_SERIES}
          autocompleteProps={{
            size: 'small',
          }}
          inputProps={{
            label: isGeneralAnalysis ? 'Select Time Series (Y Axis)' : 'Select Time Series',
            variant: 'outlined',
            size: 'small',
            required: true,
          }}
          filter={(ts) => ts.id !== context.time_series_id}
        />
      )}
      <mui.core.Box mt={4}>
        <ui.ResourceAutocomplete<ts.types.timeSeries.TimeSeriesExpanded>
          value={comparets}
          setValue={(id) => setcomparets(id)}
          type={ts.enums.RESOURCES_TYPES_ENUM.TIME_SERIES}
          autocompleteProps={{
            size: 'small',
          }}
          inputProps={{
            label: isGeneralAnalysis ? 'Select Time Series (X Axis)' : 'Select Time Series',
            variant: 'outlined',
            size: 'small',
            required: true,
          }}
          filter={(ts) => ts.id !== context.time_series_id}
        />
      </mui.core.Box>
    </mui.core.Box>
  );
};

export default Form;
