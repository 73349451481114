import { _, React, ts, useSelector } from '_core';

import { formatData, prepareDataTurnOverVsTime } from './helpers';
import { Params } from './types';
import ChartWrapper from '../../common/chart-wrapper';
import createNestedBarChart from '../../common/charts/create-nested-bar-chart';

type ChartProps = {
  id: string;
  readOnly: boolean;
  widgetKey: string;
  loadWidgetData: (_payload: ts.types.widgets.WidgetGetDataParams) => Promise<ts.types.widgets.WidgetGetDataResponse>;
  portfolios: string;
  fullScreen: boolean;
  params: Params;
  context: ts.types.basket.BasketAnalysisContext;
  title: string;
};

const Chart: React.FC<ChartProps> = ({
  id,
  readOnly,
  widgetKey,
  fullScreen,
  loadWidgetData,
  portfolios,
  params,
  context,
  title,
}) => {
  const baskets = useSelector((state) => state.resources.baskets);

  const [data, setData] = React.useState<ts.types.widgets.TableData>(null);
  const [error, setError] = React.useState<ts.types.common.ApiError>(null);
  const [showLegend, setShowLegend] = React.useState(false);

  const loadData = async () => {
    try {
      const response = await loadWidgetData({
        data: {
          file: portfolios,
        },
      });
      if (response) setData(formatData(response.data));
    } catch (err) {
      setError(err as ts.types.common.ApiError);
    }
  };

  React.useEffect(() => {
    if (portfolios) loadData();
    return () => setData(null);
  }, [portfolios]);

  React.useEffect(() => {
    const format = '#.####';

    if (!_.isNil(data))
      createNestedBarChart({
        id,
        data,
        prepareData: (nestedBarData) => prepareDataTurnOverVsTime(nestedBarData, params, context, baskets),
        fullScreen,
        setShowLegend,
        category: 'date',
        format,
        exportTitle: title,
      });
  }, [data]);

  return (
    <ChartWrapper
      id={id}
      readOnly={readOnly}
      widgetKey={widgetKey}
      data={data}
      showLegend={showLegend}
      error={error}
      fullScreen={fullScreen}
    />
  );
};

export default Chart;
