/* eslint-disable no-unused-vars */

import { ts } from '_core';

export enum INTERACTION_PARAMS {
  RISK_FACTORS = 'risk_factors',
}

export type InteractionParams = {
  [INTERACTION_PARAMS.RISK_FACTORS]?: ts.types.widgets.common.RiskFactorsType[];
};

export type JobParams = {
  critical_pvalue?: number;
};

export type Params = JobParams & InteractionParams;
export type DraftParams = Partial<Params>;
