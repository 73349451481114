/* eslint-disable no-unused-vars */
export enum INTERACTION_PARAMS {
  IS_BASKET = 'isBasket',
}

export type InteractionParams = {
  [INTERACTION_PARAMS.IS_BASKET]: boolean;
};

export type Params = InteractionParams;
export type DraftParams = Partial<Params>;
