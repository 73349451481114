/* eslint-disable no-unused-vars */

export enum MANAGER_STATE_ENUM {
  COLLAPSED = 'collapsed',
  EXPANDED = 'expanded',
}

export enum REPORT_TAG_ENUM {
  GENERAL = 'general',

  SIGNAL = 'signal',
  SIGNAL_PORTAN = 'signal-portan',
  SIGNAL_PREVIEW = 'signal-preview',
  SIGNAL_ADHOC = 'signal-adhoc',

  TIME_SERIES = 'time-series',
  TIME_SERIES_PREVIEW = 'time-series-preview',

  ALPHA_MODEL = 'alpha_model',
  COMMON_ALPHA_MODEL = 'common_alpha',
  LINEAR_ALPHA_MODEL = 'linear_alpha',
  OPTIMIZED_ALPHA_MODEL = 'optimized_alpha',
  OPTIMIZED_ALPHA_MODEL_PREVIEW = 'optimized_alpha_preview',

  STRATEGY = 'strategy',
  PORTFOLIO = 'portfolio',

  BACKTEST_SET = 'backtest_set',

  SIGNAL_SET = 'signal_set',
  SIGNAL_SET_REPORT = 'signal_set_report',
  SIGNAL_SET_BROWSER = 'signal_set_browser',

  UNIVERSE = 'universe',
  UNIVERSE_PREVIEW = 'universe-preview',

  BACKTEST = 'backtest',
  BACKTEST_PORTAN = 'backtest-portan',
  BACKTEST_DETAILS = 'backtest-details',
  BACKTEST_PORTFOLIO_RISK = 'backtest-portfolio-risk',

  BASKET = 'basket',
  BASKET_COMPARE = 'basket-compare',
  BASKET_ATTRIBUTION = 'basket-attribution',
  BASKET_DETAILS = 'basket-details',
  BASKET_PORTFOLIO_RISK = 'basket-portfolio-risk',
  BASKET_ADHOC = 'basket-adhoc',

  RISK_MODEL = 'risk_model',
  COMMON_RISK_MODEL = 'common_risk_model',
  RISK_MODEL_CATEGORY = 'risk_model_category',
  RISK_MODEL_PREVIEW = 'risk_model_preview',
  RISK_MODEL_REPORT = 'risk_model_report',
  FINSERA_RISK_MODEL = 'finsera_risk_model',
  STATISTICAL_RISK_MODEL = 'statistical_risk_model',

  DATA_SOURCE = 'data_source',
  DATA_SOURCE_BROWSER = 'data_source_browser',

  PORTFOLIO_ATTRIBUTION = 'portfolio_attribution',
  PORTFOLIO_PERFORMANCE = 'portfolio_performance',
  PORTFOLIO_RISK = 'portfolio_risk',
  PORTFOLIO_STRAT_RISK = 'portfolio_strat_risk',

  REBALANCE_PREVIEW = 'portfolio-preview',
  REBALANCE_PORTFOLIO_RISK = 'portfolio-risk',
  REBALANCE_PORTFOLIO_TRADE_LIST = 'portfolio-tradelist',
  REBALANCE_PORTFOLIO_HOLDINGS = 'portfolio-summary',

  PORTFOLIO_HOLDINGS_ANALYSIS = 'portfolio-holdings-analysis',
  PORTFOLIO_ANALYISIS_STRAT_HOLDINGS_RISK = 'portfolio_analysis_strat_holdings_risk',
}

export enum WIDGET_KEY_ENUM {
  // COMMON
  REPORT_SECTION = 'ReportSection',
  DOWNLOAD_FILES = 'DownloadFiles',

  // GENERAL
  GENERAL_ONBOARDING = 'Onboarding',
  GENERAL_LAST_OPENED = 'LastOpened',
  GENERAL_LAST_CREATED_UNIVERSES = 'LastCreatedUniverses',
  GENERAL_LAST_CREATED_SIGNALS = 'LastCreatedSignals',
  GENERAL_LAST_CREATED_ALPHA_MODELS = 'LastCreatedAlphaModels',
  GENERAL_LAST_CREATED_BACKTESTS = 'LastCreatedBackTests',
  GENERAL_LAST_CREATED_BACKTEST_SETS = 'LastCreatedBacktestSets',
  GENERAL_LAST_CREATED_PIPELINES = 'LastCreatedPipelines',
  GENERAL_LAST_CREATED_DATASOURCES = 'LastCreatedDataSources',
  GENERAL_LAST_CREATED_SIGNAL_SETS = 'LastCreatedSignalSets',
  GENERAL_LAST_CREATED_BASKETS = 'LastCreatedBaskets',
  GENERAL_LAST_CREATED_RISK_MODELS = 'LastCreatedRiskModels',
  GENERAL_LAST_CREATED_TIME_SERIES = 'LastCreatedTimeSeries',
  GENERAL_LAST_CREATED_STRATEGIES = 'LastCreatedStrategies',
  GENERAL_MY_WORKSPACES = 'MyWorkspaces',

  // UNIVERSE
  COVERAGE_BY_RBICS_LVL_ONE = 'CoverageByRBICSLevelOne',
  COVERAGE_BY_RBICS_LVL_TWO = 'CoverageByRBICSLevelTwo',
  COVERAGE_BY_RBICS_LVL_THREE = 'CoverageByRBICSLevelThree',
  COVERAGE_BY_RBICS_LVL_FOUR = 'CoverageByRBICSLevelFour',
  UNIVERSE_TOTAL_COVERAGE = 'TotalCoverage',
  UNIVERSE_COVERAGE_BY_COUNTRY = 'CoverageByQuotationCountry',
  UNIVERSE_COVERAGE_BY_CURRENCY = 'CoverageByCurrency',
  UNIVERSE_COVERAGE_BY_ASSET_TYPE = 'CoverageByAssetType',
  UNIVERSE_COVERAGE_BY_ISSUER_COUNTRY = 'CoverageByIssuerCountry',

  // UNIVERSE PREVIEW
  UNIVERSE_PREVIEW_SNAPSHOT = 'UniversePreviewSnapshot',
  UNIVERSE_PREVIEW_ASSET_PERTINENCE = 'UniversePreviewAssetPertinence',
  UNIVERSE_PREVIEW_DATE_COMPARISON = 'UniversePreviewDateComparison',
  UNIVERSE_PREVIEW_LINKED_ASSETS = 'UniversePreviewLinkedAssets',

  // Metrics
  METRIC_SIGNAL_RETURNS = 'MetricSignalReturns',
  METRIC_SIGNAL_TIME_SERIES = 'MetricSignalTimeSeries',
  METRIC_SIGNAL_BENCHMARK = 'MetricSignalBenchmark',
  METRIC_BASKET_RETURNS = 'MetricBasketReturns',
  METRIC_BASKET_ALLOCATION = 'MetricBasketAllocation',
  METRIC_BASKET_BENCHMARK_ALLOCATION = 'MetricBasketBenchmarkAllocation',
  METRIC_BASKET_BENCHMARK_RETURNS = 'MetricBasketBenchmarkReturns',
  METRIC_BASKET_RETURNS_BY_SECTOR = 'MetricBasketReturnsBySector',
  METRIC_BASKET_RETURNS_BY_INDUSTRY = 'MetricBasketReturnsByIndustry',
  METRIC_BASKET_RETURNS_BY_ASSET = 'MetricBasketReturnsByAsset',

  // SIGNAL (and Alphas)
  SIGNAL_CUMULATIVE_RETURNS = 'CumulativeReturns',
  SIGNAL_CUMULATIVE_RETURNS_WITH_TIME_SERIES = 'CumulativeReturnsWithTimeSeries',
  SIGNAL_CUMULATIVE_RETURNS_LAGS = 'CumulativeReturnsWithLags',
  SIGNAL_BUCKETED_CUMULATIVE_RETURNS = 'BucketedCumulativeReturns',
  SIGNAL_ANNUALIZED_RETURN = 'AnnualizedStatisticsReturn',
  SIGNAL_ANNUALIZED_RISK = 'AnnualizedStatisticsRisk',
  SIGNAL_ANNUALIZED_ADJUSTED = 'AnnualizedStatisticsAdjusted',
  SIGNAL_ANNUALIZED_RISK_ADJUSTED_LAGS = 'AnnualizedRiskAdjustedReturnsWithLags',
  SIGNAL_ANNUALIZED_BUCKETED_RETURN = 'AnnualizedBucketedStatisticsReturn',
  SIGNAL_ANNUALIZED_BUCKETED_RISK = 'AnnualizedBucketedStatisticsRisk',
  SIGNAL_ANNUALIZED_BUCKETED_ADJUSTED = 'AnnualizedBucketedStatisticsAdjusted',
  SIGNAL_ANNUALIZED_AVERAGE_BUCKETED_RETURN = 'AnnualizedAverageBucketedStatisticsReturn',
  SIGNAL_ANNUALIZED_AVERAGE_BUCKETED_RISK = 'AnnualizedAverageBucketedStatisticsRisk',
  SIGNAL_ANNUALIZED_AVERAGE_BUCKETED_ADJUSTED = 'AnnualizedAverageBucketedStatisticsAdjusted',
  SIGNAL_AVERAGE_BUCKETED_CUMULATIVE_RETURNS = 'AverageBucketedCumulativeReturns',
  SIGNAL_SUMMARY_STATISTICS = 'RadarSpReturns',
  SIGNAL_NAME_COUNT = 'NameCount',
  SIGNAL_DRAWDOWN = 'SignalDrawdown',
  SIGNAL_DRAWDOWN_BENCHMARK = 'SignalDrawdownWithBenchmark',
  SIGNAL_MAX_DRAWDOWN = 'SignalMaxDrawdown',
  SIGNAL_TURNOVER = 'SignalTurnover',
  SIGNAL_EVENT_STUDY = 'SignalEventStudy',
  SIGNAL_EXPOSURE_EVENT_STUDY = 'SignalExposureEventStudy',
  SIGNAL_BUCKETED_EVENT_STUDY = 'SignalBucketedEventStudy',
  SIGNAL_EXPOSURE_BUCKETED_EVENT_STUDY = 'SignalExposureBucketedEventStudy',
  SIGNAL_HISTOGRAM = 'SignalValueHistogram',
  SIGNAL_EXPOSURE = 'SignalExposure',
  SIGNAL_AVERAGE_BUCKETED_EXPOSURE = 'AverageBucketedExposure',
  SIGNAL_AVERAGE_BUCKETED_CORRELATION = 'AverageBucketedCorrelation',
  SIGNAL_AVERAGE_BUCKETED_EXPR_CORR = 'AverageBucketedExpCorr',
  SIGNAL_PERFORMANCE_EVENT_STUDY = 'SignalPerformanceEventStudy',
  SIGNAL_REGIME = 'SignalRegime',
  SIGNAL_RETURN_PROFILE = 'SignalReturnProfile',
  SIGNAL_BUCKETED_RETURN_PROFILE = 'SignalBucketedReturnProfile',
  SIGNAL_BUCKETED_REGIME = 'SignalBucketedRegime',
  SIGNAL_BUCKETED_REGIME_BY_QUANTILES = 'SignalBucketedRegimeByQuantiles',
  SIGNAL_BUCKETED_REGIME_BY_QUANTILES_CONDITIONAL = 'SignalBucketedRegimeByQuantilesConditional',
  SIGNAL_DISTRIBUTION_OVER_TIME = 'SignalDistributionOverTime',
  SIGNAL_BIAS_STATISTICS = 'SignalBiasStatistics',
  SIGNAL_PREDICTED_AND_REALIZED_RISK_VS_TIME = 'SignalPredictedAndRealizedRiskVsTime',
  SIGNAL_RELATIVE_RETURNS = 'SignalRelativeReturns',
  SIGNAL_COVERAGE_BY_RBICS = 'SignalCoverageByRBICS',

  // SIGNAL PREVIEW
  SIGNAL_PREVIEW_ENTIRE_UNIVERSE = 'SignalPreviewEntireUniverse',
  SIGNAL_PREVIEW_SELECTED_ASSETS = 'SignalPreviewSelectedAssets',
  SIGNAL_PREVIEW_DOWNLOAD = 'SignalPreviewDownload',
  SIGNAL_PREVIEW_DATE_COMPARISON = 'SignalPreviewDateComparison',

  // SIGNAL ATTRIBUTION
  SIGNAL_ATTRIBUTION_RETURNS_DRIVEN = 'SignalReturnsDrivenAttribution',
  SIGNAL_ATTRIBUTION_RETURNS_DRIVEN_TABLE = 'SignalReturnsDrivenAttributionTable',

  // ALPHA MODEL
  ALPHA_MODEL_OVERALL_STATISTICS_TIME = 'OverallStatisticsVsTime',

  // LINEAR ALPHA MODEL
  ALPHA_MODEL_LINEAR_STATISTICS_TIME = 'LinearStatisticsVsTime',
  ALPHA_MODEL_LINEAR_PERCENT_SIGNIFICANCE = 'LinearPercentSignificance',
  ALPHA_MODEL_LINEAR_TABULAR_SIGNIFICANCE = 'LinearTabularSignificance',
  ALPHA_MODEL_LINEAR_SIGNAL_WEIGHTS_TIME = 'LinearSignalWeightsVsTime',

  // OPTIMIZED ALPHA MODEL
  OPTIMIZED_RETURNS_WEIGHTS_COV = 'OptimizedReturnsWeightsCovariances',
  ALPHA_MODEL_OPTIMIZED_TABULAR_DATA = 'OptimizedTabularData',
  ALPHA_MODEL_OPTIMIZED_SIGNAL_WEIGHTS_TIME = 'OptimizedSignalWeightsVsTime',
  ALPHA_MODEL_OPTIMIZED_TRUE_PREDICTED = 'OptimizedTrueVsPredicted',
  ALPHA_MODEL_OPTIMIZED_RISK_MODEL_SEARCH = 'OptimizedRiskAversionSearch',

  // BASKET
  BASKET_CUMULATIVE_RETURNS = 'BasketCumulativeReturns',
  BASKET_CUMULATIVE_RETURNS_WITH_TIME_SERIES = 'BasketCumulativeReturnsWithTimeSeries',
  BASKET_BUCKETED_CUMULATIVE_RETURNS = 'BasketBucketedCumulativeReturns',
  BASKET_RETURN_TIME = 'BasketReturnVsTime',
  BASKET_RISK_TIME = 'BasketRiskVsTime',
  BASKET_ADJUSTED_TIME = 'BasketRiskAdjustedVsTime',
  BASKET_TABULAR_OUTPUT = 'BasketTabularOutput',
  BASKET_NAME_COUNT = 'BasketNameCount',
  BASKET_TURNOVER_TIME = 'BasketTurnoverVsTime',
  BASKET_DRAWDOWN = 'BasketDrawdown',
  BASKET_MAX_DRAWDOWN = 'BasketMaxDrawdown',
  BASKET_SUMMARY_STATISTICS = 'BasketSummaryStatistics',
  BASKET_TEST = 'BasketTest',
  BASKET_EXPOSURE = 'BasketExposure',
  BASKET_PERFORMANCE_EVENT_STUDY = 'BasketPerformanceEventStudy',
  BASKET_SUMMARY_RISK_STATS = 'BasketSummaryRiskStats',
  BASKET_RISK_FACTOR_EXPOSURE = 'BasketRiskFactorExposure',
  BASKET_ANNUALIZED_STATISTICS_RETURN = 'BasketAnnualizedStatisticsReturn',
  BASKET_ANNUALIZED_STATISTICS_RISK = 'BasketAnnualizedStatisticsRisk',
  BASKET_ANNUALIZED_STATISTICS_ADJ = 'BasketAnnualizedStatisticsAdjusted',
  BASKET_RISK_REPORT = 'BasketRiskReport',
  BASKET_REGIME = 'BasketRegime',
  BASKET_RELATIVE_RETURNS = 'BasketRelativeReturns',
  BASKET_BUCKET_ANALYSIS = 'BasketBucketAnalysis',

  // BASKET ATTRIBUTION
  BASKET_RETURNS_DRIVEN_BY_SECTOR = 'BasketReturnsDrivenBySector',
  BASKET_RETURNS_DRIVEN_BY_SECTOR_TABLE = 'BasketReturnsDrivenBySectorTable',

  // BASKET COMPARE
  BASKET_COMPARE_CUMULATIVE_RETURNS = 'BasketCompareCumulativeReturns',
  BASKET_COMPARE_RELATIVE_FACTOR_EXPOSURE = 'BasketCompareRelativeFactorExposure',
  BASKET_COMPARE_ANNUALIZED_RETURN = 'BasketCompareAnnualizedStatisticsReturn',
  BASKET_COMPARE_ANNUALIZED_RISK = 'BasketCompareAnnualizedStatisticsRisk',
  BASKET_COMPARE_ANNUALIZED_ADJUSTED = 'BasketCompareAnnualizedStatisticsAdjusted',
  BASKET_COMPARE_NAME_COUNT = 'BasketCompareNameCount',
  BASKET_COMPARE_DRAWDOWN = 'BasketCompareDrawdown',
  BASKET_COMPARE_PERFORMANCE = 'BasketCompareTabularOutput',
  BASKET_COMPARE_TURNOVER_TIME = 'BasketCompareTurnoverVsTime',
  BASKET_COMPARE_SUMMARY_STATISTICS = 'BasketCompareSummaryStatistics',
  BASKET_COMPARE_SIGNAL_EXPOSURE = 'BasketCompareSignalExposure',
  BASKET_COMPARE_ANNU_TRACKING_ERROR = 'BasketCompareAnnualizedTrackingError',
  BASKET_COMPARE_PERCENT_CONTRIBUTION_TO_RISK = 'BasketPercentContributionToRisk',

  // BACKTEST
  BACKTEST_CUMULATIVE_RETURNS = 'BacktestCumulativeReturns',
  BACKTEST_CUMULATIVE_RETURNS_WITH_TIME_SERIES = 'BacktestCumulativeReturnsWithTimeSeries',
  BACKTEST_BUCKETED_CUMULATIVE_RETURN = 'BacktestBucketedCumulativeReturns',
  BACKTEST_RETURN_TIME = 'BacktestReturnVsTime',
  BACKTEST_RISK_TIME = 'BacktestRiskVsTime',
  BACKTEST_ADJUSTED_TIME = 'BacktestRiskAdjustedVsTime',
  BACKTEST_TABULAR_OUTPUT = 'BacktestTabularOutput',
  BACKTEST_NAME_COUNT = 'BacktestNameCount',
  BACKTEST_TURNOVER_TIME = 'BacktestTurnoverVsTime',
  BACKTEST_DRAWDOWN = 'BacktestDrawdown',
  BACKTEST_MAX_DRAWDOWN = 'BacktestMaxDrawdown',
  BACKTEST_SUMMARY_STATISTICS = 'BacktestSummaryStatistics',
  BACKTEST_TEST = 'BacktestTest',
  BACKTEST_PORTFOLIO_EXPOSURES_OVER_TIME = 'BacktestExposuresOverTime',
  BACKTEST_SIGNAL_EXPOSURES_OVER_TIME = 'BacktestSignalExposuresOverTime',
  BACKTEST_BIAS_STATISTIC = 'BacktestBiasStatistic',
  BACKTEST_PREDICTED_REALIZED_RISK_TIME = 'BacktestlPredictedAndRealizedRiskVsTime',
  BACKTEST_DETAILED_VIEW_ALPHA_EXPOSURES = 'BacktestDetailedViewAlphaExposures',
  BACKTEST_ANNUALIZED_STATISTICS_RETURN = 'BacktestAnnualizedStatisticsReturn',
  BACKTEST_ANNUALIZED_STATISTICS_RISK = 'BacktestAnnualizedStatisticsRisk',
  BACKTEST_ANNUALIZED_STATISTICS_ADJ = 'BacktestAnnualizedStatisticsAdjusted',
  BACKTEST_DETAILED_ASSETS_PERTINENCE = 'BacktestDetailedAssetsPertinence',
  BACKTEST_BIAS_STATISTICS = 'BacktestBiasStatistic',
  BACKTEST_CONSTRAINED_PORTFOLIO_EXPOSURES_OVER_TIME = 'BacktestConstrainedPortfolioExposuresOverTime',
  BACKTEST_DETAILED_VIEW_CONSTRAINTS = 'BacktestDetailedViewConstraints',
  BACKTEST_PREVIEW_DATE_COMPARISON = 'BacktestPreviewDateComparison',
  BACKTEST_PREDICTED_AND_REALIZED_RISK_VS_TIME = 'BacktestPredictedAndRealizedRiskVsTime',
  BACKTEST_RISK_REPORT = 'BacktestRiskReport',
  BACKTEST_DETAILED_VIEW_SNAPSHOT = 'BacktestDetailedViewSnapshot',
  BACKTEST_SUMMARY_RISK_STATS = 'BacktestSummaryRiskStats',
  BACKTEST_RISK_FACTOR_EXPOSURE = 'BacktestRiskFactorExposure',
  BACKTEST_COMPARE_PERCENT_CONTRIBUTION_TO_RISK = 'BacktestPercentContributionToRisk',

  // BACKTEST ATTRIBUTION
  BACKTEST_ATTRIBUTION_HOLDINGS = 'BacktestPortan',
  BACKTEST_ATTRIBUTION_RETURNS = 'BacktestAttribution',
  BACKTEST_ATTRIBUTION_RISK_DECOMPOSITION = 'BacktestRiskDecomposition',
  BACKTEST_ATTRIBUTION_HOLDINGS_TABLE = 'BacktestPortanTable',
  BACKTEST_ATTRIBUTION_RETURNS_TABLE = 'BacktestAttributionTable',
  BACKTEST_ATTRIBUTION_RISK_DECOMPOSITION_TABLE = 'BacktestRiskDecompositionTable',
  BACKTEST_BUCKET_ANALYSIS = 'BacktestBucketAnalysis',

  // BACKTEST SET
  BACKTEST_SET_ALL_SUMMARY = 'BacktestSetAllSummary',
  BACKTEST_SET_PORTFOLIOS = 'BacktestSetPortfolios',
  BACKTEST_SET_METRIC_VS_PARAM = 'BacktestSetMetricVsParam',

  // RISK MODEL
  RISK_MODEL_PERCENT_SIGNIFICANCE = 'RiskModelPercentSignificance',
  RISK_MODEL_TABULAR_SIGNIFICANCE = 'RiskModelTabularSignificance',
  RISK_MODEL_PREDICTED_REALIZED_RISK_TIME = 'RiskModelPredictedAndRealizedRiskVsTime',
  RISK_MODEL_BIAS_STATISTIC = 'RiskModelBiasStatistic',
  RISK_MODEL_CROSS_SECTIONAL_CORRELATION = 'RiskModelCrossSectionalCorrelation',
  RISK_MODEL_INDUSTRY_SELECTION_DETAILS = 'RiskModelIndustrySelectionDetails',
  RISK_MODEL_VOLATILITY_VS_TIME = 'RiskModelVolatilityVsTime',
  RISK_MODEL_PREVIEW_UNIVERSE_COVERAGE = 'RiskModelPreviewUniverseCoverage',
  RISK_MODEL_PREVIEW_SNAPSHOT = 'RiskModelPreviewSnapshot',
  RISK_MODEL_PREVIEW_COV_MATRIX = 'RiskModelPreviewCovarianceMatrix',
  RISK_MODEL_PREVIEW_ASSET_PERTINENCE = 'RiskModelPreviewAssetPertinence',
  RISK_MODEL_MEDIAN_ANNU_VOLATILITY_VS_TIME = 'RiskModelMedianAnnualizedVolatilityVsTime',
  RISK_MODEL_INDUSTRY_COVERAGE = 'RiskModelIndustryCoverage',
  RISK_MODEL_COVERAGE = 'RiskModelCoverage',

  // RISK MODEL CATEGORY
  RISK_MODEL_CATEGORY_SELECTOR = 'RiskModelCategorySelector',

  // SIGNAL SET
  SIGNAL_SET_CUMULATIVE_RETURNS = 'SignalSetCumulativeReturns',
  SIGNAL_SET_CUMULATIVE_RETURNS_WITH_BENCHMARK = 'SignalSetCumulativeReturnsWithBenchmark',
  SIGNAL_SET_CUMULATIVE_RETURNS_WITH_TIME_SERIES = 'SignalSetCumulativeReturnsWithTimeSeries',
  SIGNAL_SET_BROWSER = 'SignalSetBrowser',

  // SIGNAL SET REPORT
  SIGNAL_SET_RETURNS_REPORT = 'SignalSetReturnsReport',
  SIGNAL_SET_RELATIVE_RETURNS = 'SignalSetRelativeReturns',
  SIGNAL_SET_PERFORMANCE = 'SignalSetPerformance',

  // TIME SERIES
  TIME_SERIES_PLOT = 'TimeSeriesPlot',
  TIME_SERIES_HISTOGRAM = 'TimeSeriesHistogram',
  TIME_SERIES_SCATTER_COMPARE = 'TimeSeriesScatterCompare',
  TIME_SERIES_REGIME = 'TimeSeriesRegime',
  TIME_SERIES_LINE_COMPARE = 'TimeSeriesLineCompare',
  TIME_SERIES_LINE_COMPARE_DIFF_SCALE = 'TimeSeriesLineCompareDiffScale',
  TIME_SERIES_PREVIEW = 'TimeSeriesPreview',
  TIME_SERIES_COMPARE_PERFORMANCE = 'TimeSeriesCompareTabularOutput',

  // REBALANCE PORTFOLIO HOLDINGS
  REBALANCE_PORTFOLIO_HOLDINGS = 'RebalanceInitialHoldings',
  REBALANCE_PORTFOLIO_SUMMARY = 'RebalancePortfolioSummary',
  REBALANCE_PORTFOLIO_FACTOR_EXPOSURE_SUMMARY = 'RebalancePortfolioFactorExposureSummary',
  REBALANCE_PORTFOLIO_SUMMARY_BUCKET_EXPOSURE = 'RebalancePortfolioSummaryBucketExposure',

  // PORTFOLIO HOLDINGS
  PORTFOLIO_HOLDINGS = 'InitialHoldings',
  PORTFOLIO_SUMMARY = 'PortfolioSummary',
  PORTFOLIO_FACTOR_EXPOSURE_SUMMARY = 'PortfolioFactorExposureSummary',
  PORTFOLIO_SUMMARY_BUCKET_EXPOSURE = 'PortfolioSummaryBucketExposure',
  PORTFOLIO_HOLDINGS_RISK_SUMMARY = 'PortfolioHoldingsRiskSummary',

  // PORTFOLIO
  PORTFOLIO_TRADE_LIST = 'TradeList',
  PORTFOLIO_TRADE_LIST_HOLDINGS = 'TradeListHoldings',
  PORTFOLIO_TRADE_LIST_SUMMARY = 'PortfolioTradeListSummary',
  PORTFOLIO_TRADE_LIST_FACTOR_EXPOSURE_SUMMARY = 'PortfolioTradeListFactorExposureSummary',
  PORTFOLIO_TRADE_LIST_BUCKET_EXPOSURE_SUMMARY = 'PortfolioTradeListBucketExposureSummary',
  PORTFOLIO_RISK_SUMMARY = 'PortfolioRiskSummary',
  PORTFOLIO_CONSTRAINTS = 'PortfolioConstraints',
  PORTFOLIO_RISK_REPORT = 'PortfolioRiskReport',
  PORTFOLIO_SUMMARY_RISK_STATS = 'PorftolioSummaryRiskStats',
  PORTFOLIO_PERCENT_CONTRIBUTION_TO_RISK = 'PortfolioPercentContributionToRisk',
  PORTFOLIO_RISK_FACTOR_EXPOSURE = 'PortfolioRiskFactorExposure',
  PORTFOLIO_BIAS_STATISTICS = 'PortfolioBiasStatistic',
  PORTFOLIO_PREDICTED_AND_REALIZED_RISK_VS_TIME = 'PortfolioPredictedAndRealizedRiskVsTime',
  PORTFOLIO_TABULAR_OUTPUT = 'PortfolioTabularOutput',

  // PORTFOLIO ATTRIBUTION
  PORTFOLIO_ATTRIBUTION_HOLDINGS = 'PortfolioAttributionHoldings',
  PORTFOLIO_ATTRIBUTION_RETURNS = 'PortfolioAttributionReturns',
  PORTFOLIO_ATTRIBUTION_RISK_DECOMPOSITION = 'PortfolioAttributionRiskDecomposition',
  PORTFOLIO_ATTRIBUTION_HOLDINGS_TABLE = 'PortfolioAttributionHoldingsTable',
  PORTFOLIO_ATTRIBUTION_RETURNS_TABLE = 'PortfolioAttributionReturnsTable',
  PORTFOLIO_ATTRIBUTION_RISK_DECOMPOSITION_TABLE = 'PortfolioAttributionRiskDecompositionTable',
  PORTFOLIO_PERFORMANCE_ATTRIBUTION_CONTRIBUTORS = 'PortfolioPerformanceAttributionContributors',
  PORTFOLIO_BUCKET_ANALYSIS = 'PortfolioBucketAnalysis',

  // PORTFOLIO PERFORMANCE
  PORTFOLIO_PERFORMANCE_TABLE = 'PortfolioPerformanceTable',

  // DATASOURCES
  DATA_SOURCE_BROWSER = 'DataSourceBrowser',

  // ATTRIBUTION
  PERFORMANCE_ATTRIBUTION_CONTRIBUTORS = 'performance_attribution_contributors',
}

// WIDGET ENUMS
export enum WEIGHTS_ENUM {
  MINIMUM_VOLATILITY = 'minimum_volatility',
  MARKET_CAP = 'market_cap',
  EQUAL = 'equal',
  MARKET_NEUTRAL = 'market_neutral',
}

export enum ANNU_STATISTICS_ENUM {
  RETURNS = 'return',
  RISK = 'risk',
  ADJUSTED = 'adjusted',
}

// UNIVERSE

export enum UNIVERSE_COVERAGE_BY_ENUM {
  ALL = 'all',
  QUOTATION_COUNTRY = 'quotation_country',
  CURRENCY = 'currency',
  ASSET_TYPE = 'asset_type',
  ISSUER_COUNTRY = 'issuer_country',
  RBICS_L1 = 'rbics_l1_id_code',
  RBICS_L2 = 'rbics_l2_id_code',
}

export enum UNIVERSE_COVERAGE_BY_GICS_ENUM {
  GICS_IND = 'gics_industry_code',
  GICS_SUBIND = 'gics_subindustry_code',
}

export enum UNIVERSE_COVERAGE_BY_RBICS_ENUM {
  RBICS_L3 = 'rbics_l3_id_code',
  RBICS_L4 = 'rbics_l4_id_code',
}

export enum REPORT_ANALYSIS_TYPE_ENUM {
  BACKTEST_ANALYSIS = 'backtest_analysis',
  BACKTEST_SET_ANALYSIS = 'backtest_set_analysis',
  BASKET_ANALYSIS = 'basket_analysis',
  DATA_SOURCE_ANALYSIS = 'data_source_analysis',
  PORTFOLIO_HOLDINGS_ANALYSIS = 'portfolio_holdings_analysis',
  GENERAL_ANALYSIS = 'general_analysis',
  REBALANCE_ANALYSIS = 'rebalance_analysis',
  PORTFOLIO_ANALYSIS = 'portfolio_analysis',
  RISK_MODEL_ANALYSIS = 'risk_model_analysis',
  RISK_MODEL_DEFINITION_ANALYSIS = 'risk_model',
  SIGNAL_ANALYSIS = 'signal_analysis',
  SIGNAL_PREVIEW_ANALYSIS = 'signal_preview_analysis',
  SIGNAL_SET_ANALYSIS = 'signal_set_analysis',
  TIME_SERIES_ANALYSIS = 'time_series_analysis',
  UNIVERSE_ANALYSIS = 'universe_analysis',
}

export enum TABLE_SHEET_KEY_ENUM {
  // BACKTESTS
  BACKTEST_TABULAR_YEARLY_SUMMARY = 'summary',
  BACKTEST_TABULAR_MONTHLY_SUMMARY = 'monthly_summary',
  BACKTEST_TABULAR_DETAILS = 'details',
  BACKTEST_TABULAR_ALPHA_DETAILS = 'alpha_details',

  BACKTEST_PORTFOLIO_RISK_SUMMARY = 'risk_summary',
  BACKTEST_PORTFOLIO_RISK_FACTOR_CONTRIBUTION = 'factor_contribution',
  BACKTEST_PORTFOLIO_RISK_FACTOR_EXPOSURE = 'factor_exposure',
  BACKTEST_PORTFOLIO_RISK_SECURITY_CONTRIBUTION = 'security_contribution',

  // BACKTEST BUCKET ANALYSIS
  BACKTEST_BUCKET_ANALYSIS_RETURN_DETAILS = 'return_details',
  BACKTEST_BUCKET_ANALYSIS_BUCKET_ALLOCATION_DETAILS = 'bucket_allocation_details',
  BACKTEST_BUCKET_ANALYSIS_BUCKET_STOCK_SELECTION_DETAILS = 'bucket_stock_selection_details',
  BACKTEST_BUCKET_ANALYSIS_RETURN_SUMMARY = 'return_summary',
  BACKTEST_BUCKET_ANALYSIS_BUCKET_ALLOCATION_SUMMARY = 'bucket_allocation_summary',
  BACKTEST_BUCKET_ANALYSIS_BUCKET_STOCK_SELECTION_SUMMARY = 'bucket_stock_selection_summary',

  // BASKET
  BASKET_TABULAR_YEARLY_SUMMARY = 'summary',
  BASKET_TABULAR_MONTHLY_SUMMARY = 'monthly_summary',
  BASKET_TABULAR_DETAILS = 'details',

  BASKET_PORTFOLIO_RISK_SUMMARY = 'risk_summary',
  BASKET_PORTFOLIO_RISK_FACTOR_CONTRIBUTION = 'factor_contribution',
  BASKET_PORTFOLIO_RISK_FACTOR_EXPOSURE = 'factor_exposure',
  BASKET_PORTFOLIO_RISK_SECURITY_CONTRIBUTION = 'security_contribution',

  PORTFOLIO_RISK_SUMMARY = 'risk_summary',
  PORTFOLIO_RISK_FACTOR_CONTRIBUTION = 'factor_contribution',
  PORTFOLIO_RISK_FACTOR_EXPOSURE = 'factor_exposure',
  PORTFOLIO_RISK_SECURITY_CONTRIBUTION = 'security_contribution',

  PORTFOLIO_TABULAR_YEARLY_SUMMARY = 'yearly_summary',
  PORTFOLIO_TABULAR_MONTHLY_SUMMARY = 'monthly_summary',
  PORTFOLIO_TABULAR_DETAILS = 'details',
  PORTFOLIO_TABULAR_ALPHA_DETAILS = 'alpha_details',

  // SIGNALS
  TRUE_RETURNS = 'true_returns',
  RETURNS = 'returns',
  WEIGHTS = 'weights',

  // PORTFOLIO
  REBALANCE = 'rebalance',
  TRADE = 'trade',
  MODEL = 'model',
  FACTOR = 'factor',
  RISK = 'risk',

  // BACKTEST SET
  SUMMARY = 'summary',

  // COMMON
  EXPOSURE = 'exposure',
  PREVIEW = 'preview',
  MAIN = 'data',
  DETAILS = 'details',
}

export enum TABLE_SHEET_PREF_NAME_ENUM {
  // BACKTESTS
  BACKTEST_TABULAR_YEARLY_SUMMARY = 'Yearly Summary',
  BACKTEST_TABULAR_MONTHLY_SUMMARY = 'Monthly Summary',
  BACKTEST_TABULAR_DETAILS = 'Details',
  BACKTEST_TABULAR_ALPHA_DETAILS = 'Alpha Details',

  BACKTEST_PORTFOLIO_RISK_SUMMARY = 'Risk Summary',
  BACKTEST_PORTFOLIO_RISK_FACTOR_CONTRIBUTION = 'Factor Contribution',
  BACKTEST_PORTFOLIO_RISK_FACTOR_EXPOSURE = 'Factor Exposure',
  BACKTEST_PORTFOLIO_RISK_SECURITY_CONTRIBUTION = 'Security Contribution',

  // BACKTEST SET
  BACKTEST_SET_SUMMARY = 'Summary',

  // BASKET
  BASKET_TABULAR_YEARLY_SUMMARY = 'Summary',
  BASKET_TABULAR_MONTHLY_SUMMARY = 'Monthly Summary',
  BASKET_TABULAR_DETAILS = 'Details',

  BASKET_PORTFOLIO_RISK_SUMMARY = 'Risk Summary',
  BASKET_PORTFOLIO_RISK_FACTOR_CONTRIBUTION = 'Factor Contribution',
  BASKET_PORTFOLIO_RISK_FACTOR_EXPOSURE = 'Factor Exposure',
  BASKET_PORTFOLIO_RISK_SECURITY_CONTRIBUTION = 'Security Contribution',

  // PORTFOLIO
  PORTFOLIO_TABULAR_YEARLY_SUMMARY = 'Yearly Summary',
  PORTFOLIO_TABULAR_MONTHLY_SUMMARY = 'Monthly Summary',
  PORTFOLIO_TABULAR_DETAILS = 'Details',
  PORTFOLIO_TABULAR_ALPHA_DETAILS = 'Alpha Details',

  PORTFOLIO_RISK_SUMMARY = 'Risk Summary',
  PORTFOLIO_RISK_FACTOR_CONTRIBUTION = 'Factor Contribution',
  PORTFOLIO_RISK_FACTOR_EXPOSURE = 'Factor Exposure',
  PORTFOLIO_RISK_SECURITY_CONTRIBUTION = 'Security Contribution',

  PORTFOLIO_TRADE_LIST_SUMMARY_REBALANCE = 'Rebalance Summary',
  PORTFOLIO_TRADE_LIST_SUMMARY_TRADE = 'Trade Summary',

  PORTFOLIO_TRADE_LIST_FACTOR_EXPOSURE_MODEL = 'Model Summary',
  PORTFOLIO_TRADE_LIST_FACTOR_EXPOSURE_RISK = 'Risk Factor Exposure Summary',
  PORTFOLIO_TRADE_LIST_FACTOR_EXPOSURE_USER = 'User Factor Exposure Summary',

  EXPOSURE = 'Exposure',

  // OTHER
  MAIN = 'Data',
}
