import { actions, api, mui, React, Resizable, uitheme, useDispatch, useSelector } from '_core';

import AIAssistant from '_components/ai-assistant';

const { ASISTANT_Z_INDEX, ASSISTANT_WIDTH } = uitheme.layoutSize;

const Assistant = () => {
  const [isChatbotOpen, setIsChatbotOpen] = React.useState(false);
  const preferences = useSelector((state) => state.ui.preferences);
  const dispatch = useDispatch();

  const [width, setWidth] = React.useState(preferences.layout?.assistant_popup_size ?? ASSISTANT_WIDTH);

  const height = '70vh';

  const updateSidebarSize = async (localSidebar: string) => {
    const layoutPreferences = preferences.layout || {};
    layoutPreferences.assistant_popup_size = localSidebar;
    preferences.layout = layoutPreferences;

    try {
      await api.users.updateMe({ preferences });
    } catch (e) {
      console.log('Unable to store sidebar size state.', e);
    }

    dispatch(actions.ui.updatePreferences(preferences));
  };

  const handleResize = (event: any, direction: any, ref: any) => {
    const widthString = ref.style.width;

    setWidth(widthString);
    updateSidebarSize(widthString);
  };

  return (
    <mui.core.Box>
      <mui.core.Box sx={{ position: 'fixed', right: 15, bottom: 15 }} zIndex={ASISTANT_Z_INDEX}>
        <Resizable
          defaultSize={{ height: '100%', width: ASSISTANT_WIDTH }}
          size={{ width: width, height: '100%' }}
          minWidth={isChatbotOpen ? 350 : 0}
          maxWidth={1000}
          onResizeStop={(_e, _direction, _ref) => handleResize(_e, _direction, _ref)}
          enable={{
            top: false,
            bottom: false,
            left: true,
            topRight: false,
            bottomRight: false,
            bottomLeft: false,
            topLeft: false,
          }}
        >
          <mui.core.Collapse in={isChatbotOpen}>
            <mui.core.Box>
              <mui.core.Paper
                elevation={3}
                sx={{
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <mui.core.Box height={height}>
                  <AIAssistant
                    service={{ type: 'main', service_data: {} }}
                    visible={isChatbotOpen}
                    autofocus
                    extraActions={() => (
                      <mui.core.Tooltip title="Close" placement="top">
                        <mui.core.IconButton aria-label="close" onClick={() => setIsChatbotOpen(false)} size="small">
                          <mui.icons.Close />
                        </mui.core.IconButton>
                      </mui.core.Tooltip>
                    )}
                  />
                </mui.core.Box>
              </mui.core.Paper>
            </mui.core.Box>
          </mui.core.Collapse>
        </Resizable>
      </mui.core.Box>
      <mui.core.Box
        display="flex"
        justifyContent="flex-end"
        sx={{
          '@media print': {
            display: 'none',
          },
        }}
      >
        <mui.core.Button
          onClick={() => setIsChatbotOpen(!isChatbotOpen)}
          sx={{
            background: 'linear-gradient(to bottom right, #D19718, #DF8079)',
            color: '#fff',
            fontSize: '13px',
            whiteSpace: 'nowrap',
          }}
          startIcon={isChatbotOpen ? <mui.icons.Close /> : <mui.icons.AutoAwesome />}
        >
          {isChatbotOpen ? 'Close' : 'Ask AI'}
        </mui.core.Button>
      </mui.core.Box>
    </mui.core.Box>
  );
};

export default Assistant;
