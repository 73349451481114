import React from 'react';

import DialogTitle from './dialog-title';
import { _, mui } from '../libs';
import * as types from '../types';
type ArchivedDepsProps = {
  deps: types.common.ArchivedDeps;
};

/**
 * ArchivedDepsCmp component for displaying archived dependencies
 * 
 * @param deps - The dependencies to display
 * 
 * @returns A component for displaying archived dependencies
 */
const ArchivedDepsCmp: React.FC<ArchivedDepsProps> = ({ deps }) => {
  const [openDialog, setOpenDialog] = React.useState(false);
  if (_.isEmpty(deps)) return undefined;
  return (
    <>
      <mui.core.Tooltip title="This resource is using archived resources." arrow>
        <mui.core.IconButton color="warning" onClick={() => setOpenDialog(true)}>
          <mui.icons.ErrorOutline />
        </mui.core.IconButton>
      </mui.core.Tooltip>

      <mui.core.Dialog open={openDialog} maxWidth="sm" fullWidth onClose={() => setOpenDialog(false)}>
        <DialogTitle closeAction={() => setOpenDialog(false)}>Archived Dependencies</DialogTitle>
        <mui.core.DialogContent>
          {deps.map((dep) => (
            <>
              <strong>{dep.label}</strong>
              <ol>
                {dep.elements.map((el) => (
                  <li key={el}>{el}</li>
                ))}
              </ol>
            </>
          ))}
        </mui.core.DialogContent>
      </mui.core.Dialog>
    </>
  );
};

export default ArchivedDepsCmp;
