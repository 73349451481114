import { helpers, hooks, mui, React, ts, ui, useSelector } from '_core';

import Tabular from 'views/report/tables/tabular';

import { Params } from './types';
import { getLabel, getSignalsObj, mapName } from '../../common/utils/signal-set-utils';

type ChartProps = {
  loadWidgetData?: (_payload: ts.types.widgets.WidgetGetDataParams) => Promise<ts.types.widgets.WidgetGetDataResponse>;
  file: string;
  params: Params;
  setParams: (_p: Params) => void;
  context: ts.types.signalSet.SignalSetAnalysisContext;
};

const Chart: React.FC<ChartProps> = ({ loadWidgetData, file, params, context, setParams }) => {
  const [data, setData] = React.useState<ts.types.widgets.TableData>(null);
  const [error, setError] = React.useState<ts.types.common.Alert>(null);
  const [columns, setColumns] = React.useState<ts.types.components.dataGrid.ColumnsData>([]);

  const [tableParams, setTableParams] = React.useState<ts.types.components.dataGrid.TableParamsSheet>(
    params.table_params
  );

  hooks.useEffectWithoutFirst(() => {
    setParams({
      ...params,
      table_params: { ...tableParams },
    });
  }, [tableParams]);

  const container = React.createRef();

  const resources = useSelector((state) => state.resources);
  const signalsObj = React.useMemo(() => getSignalsObj(context), [context]);

  const getColumns = (sheet: ts.types.widgets.TableData) => {
    return Object.keys(sheet?.[0] || {}).map((key) => ({
      name: mapName(key, resources, signalsObj),
      key,
      formatter: key.includes('date')
        ? helpers.tableFormatters.formatTo('date', { align: 'right', format: 'YYYY-MM-DD' })
        : helpers.tableFormatters.formatTo('float', {
            align: 'right',
            roundDigits: 2,
            suffix: '%',
            multiplier: 100,
          }),
    }));
  };

  const loadData = async () => {
    try {
      const response = await loadWidgetData({
        data: { file },
      });
      if (response.data) {
        const cols = getColumns(response.data || []);
        setColumns(cols);
        setData(response.data);
      } else {
        setError({
          severity: ts.enums.ALERT_SEVERITY_ENUM.WARNING,
          message: 'Result set too large to display.',
        });
      }
    } catch (err) {
      setError({
        severity: ts.enums.ALERT_SEVERITY_ENUM.ERROR,
        message: JSON.stringify(err),
      });
    }
  };

  React.useEffect(() => {
    if (file) loadData();
    return () => setData(null);
  }, [file]);

  const tableHeader = () => (
    <mui.core.Box display="flex">
      <ui.InfoRow title="Return Horizon">{getLabel(params.return_horizons[0].signature, context.frequency)}</ui.InfoRow>
    </mui.core.Box>
  );

  return (
    <mui.core.Box display="flex" flexDirection="column" style={{ height: '100%' }} ref={container}>
      <Tabular
        data={
          data
            ? {
                [ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.MAIN]: data,
              }
            : null
        }
        getColumns={{
          [ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.MAIN]: () => columns,
        }}
        fullScreen
        customToolbarBySheet={{ [ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.MAIN]: tableHeader }}
        customError={error}
        tableParams={tableParams}
        setTableParams={{
          [ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.MAIN]: (v) =>
            setTableParams((t) => ({
              ...t,
              [ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.MAIN]: v,
            })),
        }}
        triggerWidthChange={params.fullWidth}
      />
    </mui.core.Box>
  );
};

export default Chart;
