import { _, moment, ts } from '_core';

import { DraftParams } from './types';

export const validate = (params: DraftParams, context: ts.types.universe.UniversePreviewContext) => {
  const date = params.preview_start_date || params.preview_end_date;
  if (_.isEmpty(date)) return ['Date is a required field.'];
  if (context) {
    if (moment(date).isBefore(moment(context.start_date))) return ['Date must be inside dataset dates.'];
    if (moment(date).isAfter(moment(context.end_date))) return ['Date must be inside dataset dates.'];
  }

  if (!_.isEmpty(params.component_errors)) return params.component_errors;

  return [];
};
