import { ts } from '_core';

import { validate } from './helpers';
import { INTERACTION_PARAMS } from './types';

export default [
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.BACKTEST_DETAILED_VIEW_ALPHA_EXPOSURES,
    form: true,
    helpLabel: 'ejhsu3ua',
    title: 'Alpha Exposures',
    neededFiles: ['backtest_alpha_exposures_details'],
    interactionParams: Object.values(INTERACTION_PARAMS),
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.BACKTEST_DETAILS],
    clearInteractionOnRegenerate: ['table_dtypes'],

    validate,
  },
] satisfies ts.types.analysis.WidgetConfig[];
