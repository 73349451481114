// Adapted from https://github.com/wsmd/use-clipboard-copy/blob/master/src/useTimedToggle.ts

import { useEffect, useRef, useState } from 'react';

/**
 * Hook that provides timed toggle functionality with automatic reset
 * 
 * @param initialValue - Initial boolean value for the toggle state
 * 
 * @returns Tuple containing:
 * - Current toggle value
 * - Toggle function that accepts timeout duration in milliseconds
 * 
 * @note Automatically cleans up pending timeout on unmount
 */

export function useTimedToggle(
  initialValue: boolean,
): [boolean, (_timeout: number) => void] {
  const [value, setValue] = useState(false);
  const timeoutRef = useRef<number>();
  const initialValueRef = useRef(initialValue);

  const toggleValue = (timeout: number) => {
    clearTimeout(timeoutRef.current);
    setValue(!initialValueRef.current);
    timeoutRef.current = window.setTimeout(
      () => setValue(initialValueRef.current),
      timeout,
    );
  };

  useEffect(() => () => clearTimeout(timeoutRef.current), []);

  return [value, toggleValue];
}