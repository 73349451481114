import { hooks, mui, React, ts } from '_core';

import AsyncDataGrid from './data-grid';
import { TabularContextProvider } from '../tabular-context';

export type TabularProps = {
  loadData: Record<string, ts.types.components.dataGrid.LoadData>;
  hideFilters: boolean;
  hideDownload: boolean;
  hideToolbar: boolean;
  hideDataCount: boolean;
  alwaysShowBottom: boolean;
  overrideHeight: number;
  getColumns: Record<string, ts.types.components.dataGrid.GenerateColumns>;
  sheetTitles: Record<string, string>;
  sheetInfo: Record<string, React.ReactElement>;
  rowClass: Record<string, (_row: ts.types.components.dataGrid.Row) => string>;
  prepareData: Record<string, (_data: ts.types.components.dataGrid.Row[]) => ts.types.components.dataGrid.Row[]>;
  onDataChange: (_data: ts.types.components.dataGrid.Row[]) => void;
  downloadFullData: Record<string, ts.types.components.dataGrid.DownloadFullData>;
  customToolBar: Record<string, React.FC<{ columns: string[] }>>;
  currentSheet: string;
  setCurrentSheet: (_val: string) => void;
  sheetFilters: Record<string, ts.types.components.dataGrid.ValueFilters>;
  loadOnX: boolean;
  loadColumnsOnChange: boolean;
  triggerLoadOnChange: boolean;
  tableParams: Record<string, ts.types.components.dataGrid.TableParams>;
  setTableParams: Record<string, (_v: ts.types.components.dataGrid.TableParams) => void>;
  tableDtypes?: ts.types.components.dataGrid.TableDtypesSheet;
  setTableDtypes?: Record<string, (_v: ts.types.components.dataGrid.TableDtypes) => void>;
  preferencesSourceWidgetId?: string;
  preferenceTab?: ts.enums.PREFERENCES_INNER_TABS_ENUM;
  preferenceKey?: ts.enums.PREFERENCES_KEY_ENUM;

  overrideUserPreferences?: ts.types.components.dataGrid.ColumnPreferences;
};

const TabularFull: React.FC<TabularProps> = ({
  loadData,
  getColumns,
  sheetTitles,
  sheetInfo,
  currentSheet,
  prepareData,
  downloadFullData,
  rowClass,
  setCurrentSheet,
  loadOnX,
  loadColumnsOnChange,
  triggerLoadOnChange,
  customToolBar,
  hideFilters,
  alwaysShowBottom,
  overrideHeight,
  onDataChange,
  sheetFilters,
  hideDownload,
  hideToolbar,
  hideDataCount,
  tableParams,
  setTableParams,
  tableDtypes,
  setTableDtypes,
  preferenceKey,
  preferenceTab,
  overrideUserPreferences,
  preferencesSourceWidgetId,
}): React.ReactElement => {
  const [containerHeight, setContainerHeight] = React.useState(undefined);

  const container = React.createRef<HTMLDivElement>();

  const { height, width } = hooks.useWindowDimensions();

  React.useEffect(() => {
    if (container.current) {
      setContainerHeight(container.current.offsetHeight);
    }
  }, [width, height]);

  const hasTabs = Object.keys(loadData).length > 1;

  const currentTableParams = React.useMemo(() => {
    return tableParams?.[currentSheet] || tableParams?.['any'] || ({} as ts.types.components.dataGrid.TableParams);
  }, [currentSheet]);

  const currentDtypes = React.useMemo(() => {
    return tableDtypes?.[currentSheet] || tableDtypes?.['any'] || ({} as ts.types.components.dataGrid.TableDtypes);
  }, [currentSheet]);

  // Create shared props object as a simple constant
  const sharedProps = {
    key: currentSheet,
    loadData: loadData[currentSheet],
    getColumns: getColumns[currentSheet],
    downloadFullData: downloadFullData[currentSheet],
    loadOnX,
    loadColumnsOnChange,
    triggerLoadOnChange,
    customToolbar: customToolBar[currentSheet] || customToolBar['any'],
    rowClass: rowClass[currentSheet] || rowClass['any'],
    prepareData: prepareData[currentSheet] || prepareData['any'],
    customHeight: hasTabs ? containerHeight : null,
    hideFilters,
    hideDownload,
    hideToolbar,
    hideDataCount,
    overrideHeight,
    sheetFilters: sheetFilters[currentSheet],
    onDataChange,
    initialTableDtypes: currentDtypes,
    updateTableDtypes: setTableDtypes?.[currentSheet] || setTableDtypes?.['any'],
  };

  return (
    <>
      <div ref={container} style={{ height: '100%' }}>
        <TabularContextProvider
          {...sharedProps}
          initialTableParams={currentTableParams}
          updateTableParams={setTableParams?.[currentSheet] || setTableParams?.['any']}
          overrideUserPreferences={overrideUserPreferences}
          preferenceKey={preferenceKey}
          preferenceTab={preferenceTab}
          preferencesSourceWidgetId={preferencesSourceWidgetId}
          tableType="async"
        >
          <AsyncDataGrid {...sharedProps} />
        </TabularContextProvider>
      </div>
      {Object.keys(loadData).length > (alwaysShowBottom ? 0 : 1) && (
        <mui.core.Tabs value={currentSheet} aria-label="tabs">
          {Object.keys(loadData).map((key) => (
            <mui.core.Tab
              key={key}
              label={
                <mui.core.Box display="flex">
                  {sheetTitles[key] || key}
                  {sheetInfo[key] && (
                    <mui.core.Tooltip title={sheetInfo[key]} arrow>
                      <mui.core.IconButton size="small">
                        <mui.icons.InfoOutlined style={{ fontSize: 14 }} />
                      </mui.core.IconButton>
                    </mui.core.Tooltip>
                  )}
                </mui.core.Box>
              }
              value={key}
              onClick={() => setCurrentSheet(key)}
              sx={{
                height: '40px',
              }}
            />
          ))}
        </mui.core.Tabs>
      )}
    </>
  );
};

export default TabularFull;
