/* eslint-disable no-unused-vars */
import { ts } from '_core';

export type JobParams = {
  return_horizons: ts.types.returnHorizon.ReturnHorizon[];
  daily_reporting: boolean;
  histories: ts.enums.PERIOD_ENUM[];
  set_params: {
    id: 'report'; // Fixed id to get the file from payload on index
    file: 'sp_returns';
    file_params: {
      return_horizons: ts.types.returnHorizon.ReturnHorizon[];
      agg_frequency: 'DAILY' | undefined;
    };
    aggregation_params: Record<string, string>;
    aggregation: 'fin-gen-sp-returns';
  }[];

  // Only for general report
  signal_set_id?: number;
};

export enum INTERACTION_PARAMS {
  PIPELINES = 'pipelines_display',
}
export type InteractionParams = {
  [INTERACTION_PARAMS.PIPELINES]?: {
    pipeline: boolean;
    sp_pipeline: boolean;
  };
};

export type Params = JobParams & InteractionParams;
export type DraftParams = Partial<Params>;
