import { ts } from '_core';

const defaultAlpha: ts.types.signal.LinearRegressionDraft = {
  training_sample: {
    frequency: ts.enums.FREQUENCY_ENUM.WEEKLY,
    number: 1,
    min_count: 1,
  },
  return_horizon: {
    periods: { frequency: 'FREQUENCY', amount: 1 },
  },
  signals: [],
  intercept: true,
  mode: 'pooled',
  timeWeighting: 'equal',
  predictionValueType: 'return_horizon',
};

export default defaultAlpha;
