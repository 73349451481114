import { _, helpers, ts } from '_core';

import { DraftParams } from './types';
import { getBucketsTitle } from '../../common/utils/get-name';

export const validate = (params: DraftParams) => {
  if (_.isNil(params.time_series_id)) return ['Time Series is a required parameter.'];
  if (!params.discrete) {
    if (params.data_type === 'breakpoints') {
      if (_.isEmpty(params.break_points) || _.every(params.break_points, _.isNil))
        return ['Break Points is a required field.'];

      if (_.some(params.break_points, _.isNil)) return ['Invalid Break Points.'];
    } else {
      if (_.isNil(params.quantiles)) return ['Quantiles is a required parameter.'];
      if (!_.isNil(params.quantiles) && (params.quantiles <= 0 || params.quantiles > 20))
        return ['Quantiles must be between 0 and 21.'];
    }
  }
  const rhErrors = helpers.validations.validSelectedReturnHorizon(
    params?.return_horizon || ({} as ts.types.returnHorizon.ReturnHorizon)
  );
  if (!helpers.validations.validBuckets(params.buckets)) return ['Buckets are invalid.'];
  if (!_.isEmpty(rhErrors)) return rhErrors;
  return [];
};

export const getName = (
  params: DraftParams,
  _context: ts.types.signal.SignalAnalysisContext,
  resources: ts.StoreState['resources']
) => {
  const ts = resources.time_series.find((lt) => lt.id == params.time_series_id);
  // eslint-disable-next-line max-len
  return `${getBucketsTitle(
    params.buckets,
    'Time Series Bucketed Regime Analysis',
    resources
  )} for ${ts.name}: Average Signal Performance`;
};
