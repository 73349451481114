import React from 'react';
import * as enums from '@local/finsera-core/src/enums';
import { mui } from '@local/finsera-core/src/libs';

const allPeriods: { label: string; series_prefix: enums.PERIOD_ENUM }[] = [
  { label: '5D', series_prefix: enums.PERIOD_ENUM.FIVE_TRADING_DAYS },
  { label: '1M', series_prefix: enums.PERIOD_ENUM.ONE_MONTH },
  { label: '3M', series_prefix: enums.PERIOD_ENUM.THREE_MONTHS },
  { label: '6M', series_prefix: enums.PERIOD_ENUM.SIX_MONTHS },
  { label: 'YTD', series_prefix: enums.PERIOD_ENUM.YTD },
  { label: '1Y', series_prefix: enums.PERIOD_ENUM.ONE },
  { label: '3Y', series_prefix: enums.PERIOD_ENUM.THREE },
  { label: '5Y', series_prefix: enums.PERIOD_ENUM.FIVE },
  { label: 'Full', series_prefix: enums.PERIOD_ENUM.FULL },
];

type PeriodSelectorProps = {
  periods: enums.PERIOD_ENUM[];
  selectedPeriod: enums.PERIOD_ENUM;
  setSelectedPeriod: (_period: enums.PERIOD_ENUM) => void;
};

const PeriodSelector: React.FC<PeriodSelectorProps> = ({ periods, selectedPeriod, setSelectedPeriod }) => {
  return (
    <mui.core.Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
      <mui.core.ButtonGroup size="small" sx={{ mt: 4, mr: 5 }}>
        {allPeriods
          .filter((period) => periods.includes(period.series_prefix))
          .map((period) => (
            <mui.core.Button
              key={period.label}
              onClick={() => setSelectedPeriod(period.series_prefix)}
              variant={period.series_prefix === selectedPeriod ? 'contained' : 'outlined'}
            >
              {period.label}
            </mui.core.Button>
          ))}
      </mui.core.ButtonGroup>
    </mui.core.Box>
  );
};

export default PeriodSelector;
