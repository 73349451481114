import { _, helpers } from '_core';

import { DraftParams } from './types';

export const validate = (params: DraftParams) => {
  if (_.isEmpty(params?.bucket)) return ['Bucket is required.'];
  if (!helpers.validations.validBuckets(params?.bucket)) return ['Bucket is invalid.'];
  if (!!params.benchmark && !params.strategy_benchmark && !helpers.validations.validBenchmark(params.benchmark))
    return ['Benchmark is invalid.'];

  return [];
};
