import { _, ts } from '_core';

import { DraftParams, Params } from './types';
import { prepareBarChartBucketedNames, prepareBarChartCountNames } from '../../common/utils/get-series-name';

const getName = (
  params: Params,
  _context: ts.types.basket.BasketAnalysisContext,
  resources: ts.StoreState['resources']
) => {
  const ts = resources.time_series.find((lt) => lt.id == params.time_series_id);
  return `Time Series Regime Analysis for ${ts.name}: Average Performance`;
};

const validate = (params: DraftParams) => {
  if (_.isNil(params.time_series_id)) return ['Time Series is a required parameter.'];
  if (_.isNil(params.quantiles)) return ['Quantiles is a required parameter.'];
  if (!_.isNil(params.quantiles) && (params.quantiles <= 0 || params.quantiles > 20))
    return ['Quantiles must be between 0 and 21.'];
  return [];
};

const transform = (widgetData: ts.types.widgets.NestedBarWidgetData, params: DraftParams) => {
  const filteredData = params.show_unknown
    ? widgetData.data
    : widgetData.data.filter((item) => item.category !== 'UNKNOWN');

  const transformedData = params.histories.map((key) => {
    const newObj: { [key: string]: number | string } = { category: key };
    filteredData.forEach((item) => {
      newObj[item.category] = item[key];
    });
    return newObj;
  });

  const newData = {
    ...widgetData,
    data: prepareBarChartBucketedNames(transformedData, ts.enums.BUCKET_TYPES_ENUM.QUANTILES, params.quantiles),
    count: prepareBarChartCountNames(widgetData.count, ts.enums.BUCKET_TYPES_ENUM.QUANTILES, params.quantiles),
  };

  return newData;
};

export { getName };
export { validate };
export { transform };
