import { ts } from '_core';

import { validate } from './helpers';
import { INTERACTION_PARAMS, optionLabels, Params } from './types';
import { DraftParams } from '../linear-alpha-tabular-significance/types';

export default [
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.TIME_SERIES_COMPARE_PERFORMANCE,
    title: 'Time Series Performance',
    form: true,
    defaultParams: {
      tabular_view: true,
      histories: Object.values(ts.enums.HISTORY_ENUM_EXTENDED),
      statistics_types: ['cumulative_returns', 'return', 'risk', 'adjusted'],
    } as DraftParams,
    interactionParams: Object.values(INTERACTION_PARAMS),
    tags: [
      ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.GENERAL,
      ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.TIME_SERIES,
    ],
    neededFiles: ['time_series_annualized_statistics'],

    getSubtitle: (params: Params) => {
      if ((params.statistics_types ?? []).length == 1) return optionLabels[params.statistics_types[0]];
      return '';
    },
    validate,
    helpLabel: 'vx2ll93l',
    generalAnalysisReportHelp: {
      document: ts.enums.UI_RESOURCE_TYPE_ENUM.TIME_SERIES,
      contentPath: `/${ts.enums.TIME_SERIES_ANALYSIS_TYPE_ENUM.ANALYSIS}`,
    },
  },
] satisfies ts.types.analysis.WidgetConfig[];
