import * as enums from '@local/finsera-core/src/enums';
import { _ } from '@local/finsera-core/src/libs';
import * as types from '@local/finsera-core/src/types';

export const getPeriodData = ({
  data,
  selectedPeriod = enums.PERIOD_ENUM.FULL,
  xAxisField,
}: {
  data: types.widgets.TableData;
  selectedPeriod: enums.PERIOD_ENUM;
  xAxisField: string;
}) => {
  const allPeriods = Object.values(enums.PERIOD_ENUM) as string[];
  const availablePeriods = _.filter(
    allPeriods,
    (period) => Object.keys(data?.[0] ?? {}).some((key) => key.startsWith(period)) || period === enums.PERIOD_ENUM.FULL
  ) as enums.PERIOD_ENUM[];

  const newData = data.map((row: types.widgets.TableData[0]) => {
    const periods = Object.values(enums.PERIOD_ENUM) as string[];
    const newRow = {} as types.widgets.TableData[0];

    Object.keys(row).forEach((key) => {
      if (selectedPeriod === enums.PERIOD_ENUM.FULL) {
        if (!periods.some((period) => key.startsWith(period))) {
          newRow[key] = row[key];
        }
      } else {
        if (key.includes(selectedPeriod)) {
          newRow[key.slice(selectedPeriod.length + 1)] = row[key];
        }
      }
      newRow[xAxisField] = row[xAxisField];
    });
    return newRow;
  });

  // Find first non-null data point across all datapoints
  let firstValidIndex = 0;
  const seriesData = _.sortBy(newData, xAxisField);
  const dataKeys = _.without(Object.keys(seriesData?.[0] ?? {}), xAxisField);
  for (let i = 0; i < seriesData.length; i++) {
    const oneHaveValue = _.some(dataKeys, (key) => !_.isNil(seriesData[i]?.[key]));
    if (oneHaveValue) {
      firstValidIndex = i;
      break;
    }
  }
  return {
    seriesData: seriesData.slice(firstValidIndex),
    availablePeriods,
  };
};
