import { mui, React, ts, ui } from '_core';

type TargetProps = {
  params: ts.types.optimizer.TargetDraft;
  children?: React.ReactNode;
  setParams: (_params: ts.types.optimizer.TargetDraft) => void;
  readOnly: boolean;
};

const Target: React.FC<TargetProps> = ({ params, setParams, children = null, readOnly }): React.ReactElement => {
  return (
    <>
      <mui.core.Grid container spacing={4}>
        <mui.core.Grid item xs={12} md={6}>
          <ui.NumericField
            textFieldProps={{
              label: 'Target Weight',
              fullWidth: true,
              variant: 'outlined',
              size: 'small',
              required: true,
            }}
            disabled={readOnly}
            value={params.target_weight}
            setValue={(v) => setParams({ ...params, target_weight: v })}
          />
        </mui.core.Grid>
        <mui.core.Grid item xs={12} md={6}>
          <ui.NumericField
            textFieldProps={{
              label: 'Target Penalty',
              fullWidth: true,
              variant: 'outlined',
              size: 'small',
            }}
            disabled={readOnly}
            value={params.target_penalty}
            setValue={(v) => setParams({ ...params, target_penalty: v })}
          />
        </mui.core.Grid>
      </mui.core.Grid>
      <mui.core.Grid container spacing={4}>
        <mui.core.Grid item xs={12} md={6}>
          <ui.NumericField
            textFieldProps={{
              label: 'Lower Target',
              fullWidth: true,
              variant: 'outlined',
              size: 'small',
              required: true,
            }}
            disabled={readOnly}
            value={params.target_lower}
            setValue={(v) => setParams({ ...params, target_lower: v })}
          />
        </mui.core.Grid>
        <mui.core.Grid item xs={12} md={6}>
          <ui.NumericField
            textFieldProps={{
              label: 'Upper Target',
              fullWidth: true,
              variant: 'outlined',
              size: 'small',
              required: true,
            }}
            disabled={readOnly}
            value={params.target_upper}
            setValue={(v) => setParams({ ...params, target_upper: v })}
          />
        </mui.core.Grid>
      </mui.core.Grid>
      {children}
    </>
  );
};

export default Target;
