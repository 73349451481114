import { helpers, ts } from '_core';

// prepareWeightsVsTimeData
export const transform = (localData: ts.types.widgets.LinearData, definition: ts.types.signal.Lasso) => {
  const newData = helpers.buildName.signalVarName(localData, definition);

  Object.keys(newData).forEach((key) => {
    const jointKey = key.split('|').slice(1, key.length).join(' + ');
    newData[jointKey] = newData[key];
    delete newData[key];
  });

  return newData;
};
