import { _, mui, React, ts, ui,useSelector } from '_core';

import FactorTable from './factor-table';
import { renameIndustryInclusions } from '../../definition-utils';

type UnusedSubIndProps = {
  definition: ts.types.riskModel.DefinitionCategoryDraft[0];
  setDefinition: (_d: ts.types.riskModel.DefinitionCategoryDraft[0]) => void;
  readOnly: boolean;
};

const UnusedSubInd: React.FC<UnusedSubIndProps> = ({ definition, setDefinition, readOnly }): React.ReactElement => {
  const [showUnused, setShowUnused] = React.useState(false);
  const [addToFactor, setAddToFactor] = React.useState<ts.types.riskModel.ExtendedCategoryFactorInclusions[]>();
  const [selectedFactor, setSelectedFactor] = React.useState<ts.types.riskModel.ExtendedCategoryFactor>();

  const rbicsData = useSelector((state) => state.resources.rbics_data);
  const subindustries = rbicsData.levelFour;

  const closeAddToFactor = () => {
    setAddToFactor(undefined);
    setSelectedFactor(undefined);
  };

  const addInclusionsToFactor = () => {
    setDefinition({
      ...definition,
      factors: (definition.factors ?? []).map((f) => {
        if (f.handle == selectedFactor.handle)
          return {
            ...f,
            inclusions: [...(f.inclusions ?? []), ...addToFactor],
          };
        return f;
      }),
    });

    closeAddToFactor();
  };

  const options = React.useMemo(
    () => (definition.factors ?? []).filter((f) => !_.isEmpty(f.inclusions)),
    [definition.factors]
  );

  const unusedSubind = React.useMemo(() => {
    let used = [] as ts.types.riskModel.DefinitionCategoryDraft[0]['factors'][0]['inclusions'];
    (definition.factors ?? []).forEach((f) => {
      used = used.concat(f.inclusions ?? []);
    });

    const rawUnused = subindustries
      .filter((subInd) => {
        return !used.find((us) => us.value == subInd.value);
      })
      .map((raw) => ({
        table_handle: 'industry',
        item: 'industry',
        value: raw.value,
      }));

    return _.sortBy(
      renameIndustryInclusions(rawUnused, 'unused-subindustries', rbicsData),
      (el) => `${el.levelOne}_${el.levelTwo}_${el.levelThree}_${el.levelFour}`
    );
  }, [definition]);

  return (
    <>
      {addToFactor && (
        <mui.core.Dialog open={showUnused} maxWidth="md" fullWidth onClose={closeAddToFactor}>
          <ui.DialogTitle closeAction={closeAddToFactor}>Add to Factor</ui.DialogTitle>
          <mui.core.DialogContent>
            <mui.core.Box p={2}>
              <mui.core.Autocomplete
                options={options}
                value={selectedFactor}
                onChange={(_, val) => setSelectedFactor(val as typeof selectedFactor)}
                getOptionLabel={(el) => (el as typeof selectedFactor).name}
                isOptionEqualToValue={(option, val) => option?.handle == val?.handle}
                renderInput={(params) => <mui.core.TextField {...params} label="Add to factor..." />}
              />
            </mui.core.Box>

            <mui.core.Box display="flex" justifyContent="right" p={2}>
              <mui.core.Button
                onClick={addInclusionsToFactor}
                size="small"
                variant="outlined"
                disabled={_.isEmpty(selectedFactor)}
              >
                Add
              </mui.core.Button>
            </mui.core.Box>
          </mui.core.DialogContent>
        </mui.core.Dialog>
      )}

      <mui.core.Button
        variant="outlined"
        onClick={() => setShowUnused(true)}
        size="small"
        sx={{ fontSize: '12px', marginRight: '5px' }}
      >
        Unassigned Sub-industries
      </mui.core.Button>

      <mui.core.Dialog open={showUnused} maxWidth="lg" fullWidth onClose={() => setShowUnused(false)}>
        <ui.DialogTitle closeAction={() => setShowUnused(false)}>Unassigned Sub-industries</ui.DialogTitle>
        <mui.core.DialogContent>
          <mui.core.Box mb={4} sx={{ position: 'relative' }}>
            <mui.core.Typography variant="body2" color="textSecondary"></mui.core.Typography>
            <FactorTable
              factor={{
                name: 'Unused Subindustries',
                handle: 'unused-subindustries',
                inclusions: unusedSubind,
              }}
              addToFactor={_.isEmpty(options) ? undefined : setAddToFactor}
              readOnly={readOnly}
            />
          </mui.core.Box>
        </mui.core.DialogContent>
      </mui.core.Dialog>
    </>
  );
};

export default UnusedSubInd;
