import React from 'react';

import { mui } from '../libs';

type TourItemProps = {
  /** Name/title to display for the tour item */
  name: string;
  /** Description text or JSX content */
  description: string | JSX.Element;
  /** Optional callback function to handle demo scheduling */
  demoAction?: () => void;
};

/**
 * Component that renders a tour item with a title, description and optional demo button
 * 
 * @param name - Name/title to display for the tour item
 * @param description - Description text or JSX content
 * @param demoAction - Optional callback function to handle demo scheduling
 * @returns Rendered tour item component
 */
const TourItem: React.FC<TourItemProps> = ({ name, description, demoAction }): React.ReactElement => {
  return (
    <mui.core.Box
      sx={{
        textAlign: 'left',
        '@media print': {
          display: 'none',
        },
      }}
    >
      <mui.core.Typography
        sx={{
          fontSize: '1rem',
          display: 'block',
          marginBottom: '4px',
          marginTop: '-0.9rem',
          fontWeight: '600',
        }}
      >
        {name}
      </mui.core.Typography>
      <mui.core.Typography
        sx={{
          fontSize: '13px',
          '& strong': {
            fontWeight: 500,
          },
        }}
      >
        {description}
      </mui.core.Typography>
      {demoAction && (
        <mui.core.Button onClick={demoAction} variant="contained" color="primary" sx={{ marginTop: '1rem' }}>
          Schedule a demo
        </mui.core.Button>
      )}
    </mui.core.Box>
  );
};

export default TourItem;
