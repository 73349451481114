import { mui, React, ts, ui, useSelector } from '_core';

import CreateDatasetFormModal from './create-dataset-form-modal';
import EditDatasetFormModal from './edit-dataset-form';

type PreferencesTableProps = {
  localPreferences: ts.types.preferences.SIGNAL_DATASET_FORM_WITH_DEFINITION[];
  setLocalPreferences: (_v: ts.types.preferences.SIGNAL_DATASET_FORM_WITH_DEFINITION[]) => void;
  deletedPreferencesIds: number[];
  setDeletedPreferencesIds: (_v: number[]) => void;
};

const PreferencesTable: React.FC<PreferencesTableProps> = ({
  localPreferences,
  setLocalPreferences,
  deletedPreferencesIds,
  setDeletedPreferencesIds,
}) => {
  const theme = mui.styles.useTheme() as mui.core.Theme;
  const [openCreateDatasetModal, setOpenCreateDatasetModal] = React.useState(false);
  const [openEditDatasetModal, setOpenEditDatasetModal] = React.useState(false);

  const [editDatasetDefault, setEditDatasetDefault] = React.useState<ts.types.preferences.SIGNAL_DATASET_FORM>({});

  const universes = useSelector((state) => state.resources.universes);
  const pipelines = useSelector((state) => state.resources.pipelines);
  const riskModels = useSelector((state) => state.resources.risk_models);

  const formattedPreferences = React.useMemo(
    () =>
      localPreferences.map((l) => {
        const localDefinition = l.definition;
        return {
          ...localDefinition,
          id: l.id,
          name: l.title,
          universe_handle: universes.find((u) => u.id == localDefinition.universe_id)?.handle,
          pipeline_handle: pipelines.find((p) => p.id == localDefinition.pipeline_id)?.handle,
          sp_pipeline_handle: pipelines.find((p) => p.id == localDefinition.sp_pipeline_id)?.handle,
          risk_model_handle: riskModels.find((r) => r.id == localDefinition.risk_model_id)?.handle,
          is_preferred: l.is_preferred,
        };
      }),
    [localPreferences]
  );

  const handleUpdateLocalPreference = (updatedPreference: ts.types.preferences.SIGNAL_DATASET_FORM_DEFINITON) => {
    const updatedPreferenceIndex = localPreferences.findIndex((p) => p.id == updatedPreference.id);
    const newPreferences = [...localPreferences];
    newPreferences[updatedPreferenceIndex] = {
      ...newPreferences[updatedPreferenceIndex],
      title: updatedPreference.name,
      definition: { ...updatedPreference },
    };

    setLocalPreferences(newPreferences);
  };

  const handleSetPreferred = (rowData: ts.types.preferences.SIGNAL_DATASET_FORM_DEFINITON) => {
    const newPreferences = localPreferences.map((l) => {
      if (l.id == rowData.id) {
        return { ...l, is_preferred: true };
      }
      if (l.is_preferred) {
        return { ...l, is_preferred: false };
      }

      return { ...l };
    });

    setLocalPreferences(newPreferences);
  };

  const handleDeleteDefault = (rowData: ts.types.preferences.SIGNAL_DATASET_FORM_DEFINITON) => {
    const filteredDefaults = localPreferences.filter((l) => l.id != rowData.id);

    const deletedDefault = localPreferences.find((lP) => lP.id == rowData.id);

    setLocalPreferences(filteredDefaults);
    setDeletedPreferencesIds([...deletedPreferencesIds, deletedDefault.id]);
  };

  const handleCreateDatasetDefault = (newDefaultData: ts.types.preferences.SIGNAL_DATASET_FORM_WITH_DEFINITION) => {
    setOpenCreateDatasetModal(false);
    setLocalPreferences([...localPreferences, newDefaultData]);
  };

  const columns: mui.dataGrid.GridColDef[] = [
    { field: 'name', headerName: 'Name', flex: 1 },
    { field: 'universe_handle', headerName: 'Universe', flex: 1 },
    { field: 'frequency', headerName: 'Frequency', flex: 1 },
    { field: 'risk_model_handle', headerName: 'Risk Model', flex: 1 },
    { field: 'pipeline_handle', headerName: 'Pipeline', flex: 1 },
    { field: 'sp_pipeline_handle', headerName: 'Portfolio Pipeline', flex: 1 },
    { field: 'start_date', headerName: 'Start Date', flex: 1 },
    { field: 'end_date', headerName: 'End Date', flex: 1 },
    {
      field: 'actions',
      headerName: '',
      width: 100,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <mui.core.Box sx={{ display: 'flex', gap: 3 }}>
          <div
            onClick={(e) => {
              e.stopPropagation();
              handleSetPreferred(params.row);
            }}
          >
            {params.row.is_preferred ? (
              <mui.icons.Star style={{ fontSize: 17, color: '#FCA239' }} />
            ) : (
              <mui.icons.StarBorder style={{ fontSize: 17, color: theme.palette.text.secondary }} />
            )}
          </div>
          <div
            onClick={(e) => {
              e.stopPropagation();
              setEditDatasetDefault(params.row);
              setOpenEditDatasetModal(true);
            }}
          >
            <mui.icons.Edit style={{ fontSize: 17, color: theme.palette.text.secondary }} />
          </div>
          <div
            onClick={(e) => {
              e.stopPropagation();
              if ((localPreferences || []).length > 1) {
                handleDeleteDefault(params.row);
              }
            }}
          >
            <mui.icons.Delete
              style={{
                fontSize: 17,
                color:
                  (localPreferences || []).length == 1 ? theme.palette.grayLight.dark : theme.palette.text.secondary,
                cursor: (localPreferences || []).length > 1 ? 'pointer' : 'not-allowed',
              }}
            />
          </div>
        </mui.core.Box>
      ),
    },
  ];

  const CustomToolbar = () => (
    <mui.dataGrid.GridToolbarContainer>
      <mui.core.Box flex="1" display="flex" sx={{ mb: 2 }}>
        <ui.Alert severity="info" rounded size="small">
          Select the default signal dataset parameters. The preferred default will be automatically set when creating a
          new dataset.
        </ui.Alert>
      </mui.core.Box>
      <mui.core.Button
        size="small"
        variant="outlined"
        onClick={() => setOpenCreateDatasetModal(true)}
        style={{ marginBottom: '4px' }}
        startIcon={<mui.icons.Add style={{ fontSize: 14 }} />}
      >
        Add Dataset Default
      </mui.core.Button>
    </mui.dataGrid.GridToolbarContainer>
  );

  return (
    <mui.core.Box>
      <EditDatasetFormModal
        open={openEditDatasetModal}
        setOpen={setOpenEditDatasetModal}
        selectedDatasetDefault={editDatasetDefault}
        updateDatasetDefault={handleUpdateLocalPreference}
      />
      <CreateDatasetFormModal
        open={openCreateDatasetModal}
        setOpen={setOpenCreateDatasetModal}
        createDatasetDefault={handleCreateDatasetDefault}
        preferencesDefaults={localPreferences}
      />
      <ui.FinDataGrid
        rows={formattedPreferences}
        columns={columns}
        autoHeight
        hideFooter
        disableColumnMenu
        slots={{
          toolbar: CustomToolbar,
        }}
        sx={{
          '& .MuiDataGrid-columnHeaders': { background: theme.palette.grayLight?.light || '#f5f5f5' },
        }}
      />
    </mui.core.Box>
  );
};

export default PreferencesTable;
