import { _ } from '_core/libs/';
import {
  InvalidPasscodeType,
  LogoutType,
  PerformPasswordResetType,
  RequestPasswordResetType,
  SetAuthTokenType,
  SetUserType,
} from '_core/reducers/auth';
import { UpdateFauthIsSetType } from '_core/reducers/ui';
import { StoreState } from '_core/typescript-definitions/store';

import { api } from '@local/finsera-core';
import { OrganizationFeatures } from '@local/finsera-core/src/types/organization';
import { User, UserWithOrganization } from '@local/finsera-core/src/types/user';
import { WorkspacesMetadata } from '@local/finsera-core/src/types/workspace';

import * as resources from './resources';
import {
  INVALID_PASSCODE,
  LOGOUT,
  PERFORM_PASSWORD_RESET,
  REQUEST_PASSWORD_RESET,
  RESET_STORE,
  SET_AUTH_TOKEN,
  SET_USER,
  SET_VERIFIED,
  UPDATE_FAUTH_IS_SET,
} from './types';
import { updatePreferences, updateWorkspacesMetadata } from './ui';
import { override as configOverride } from '../config/index';

type Dispatch<A> = (_value: A) => Promise<any>;

const setUserUiMetaData = (dispatch: Dispatch<any>, user: User) => {
  dispatch(updateWorkspacesMetadata(user.workspaces_metadata || ({} as WorkspacesMetadata)));
  dispatch(updatePreferences({ ...user.preferences }));
};

export const loadInitialData = (userId: number, featureData: OrganizationFeatures) => (dispatch: Dispatch<any>) =>
  dispatch(resources.load(featureData, userId));

export const updateUser = (user: User) => (dispatch: Dispatch<SetUserType>) => {
  dispatch({ type: SET_USER, payload: { user } });
  setUserUiMetaData(dispatch, user);
};

export const setAuthToken = () => async (dispatch: Dispatch<SetAuthTokenType>) => {
  const response = await api.users.getAuthToken();
  dispatch({ type: SET_AUTH_TOKEN, payload: { authToken: response?.['X-AuthToken'] } });
};

export const resetAuthToken = () => async (dispatch: Dispatch<SetAuthTokenType>) => {
  const response = await api.users.resetAuthToken();
  dispatch({ type: SET_AUTH_TOKEN, payload: { authToken: response?.['X-AuthToken'] } });
  return response?.['X-AuthToken'];
};

export const setInvalidPasscode = (invalidPasscode: boolean) => (dispatch: Dispatch<InvalidPasscodeType>) =>
  dispatch({ type: INVALID_PASSCODE, payload: { invalidPasscode } });

export const setUserFromSession =
  (user: UserWithOrganization) => async (dispatch: Dispatch<SetUserType | any>, getState: () => StoreState) => {
    try {
      const serverStatus = await fetch('/api/v1/healthz');
      const serverDate = serverStatus.headers.get('Date');
      const serverTimeOffset = new Date(serverDate).getTime() - Date.now();

      dispatch({
        type: SET_VERIFIED,
        payload: { verified: true },
      });

      const featureData = (await api.organization_features.find()).data[0];

      await Promise.all([
        configOverride({
          features: featureData,
          userData: user,
        }),
        dispatch(loadInitialData(user.id, featureData)),
        dispatch(setAuthToken()),
      ]);

      // Set user metadata
      setUserUiMetaData(dispatch, user);
      // Set user in redux
      dispatch({
        type: SET_USER,
        payload: {
          organization: { ...user.organization, features: featureData },
          user: { ...user, organization: undefined, server_time_offset: serverTimeOffset },
        },
      });

      return {
        organization: { ...user.organization, features: featureData },
        user: { ...user, server_time_offset: serverTimeOffset },
      };
    } catch (e) {
      // If we get a 401 and we're already logged out, just ignore it silently
      if ((e as { status: number }).status === 401 && !getState().auth.authenticated) {
        return;
      }
      if ((e as { status: number }).status === 409) {
        dispatch(setInvalidPasscode(true));
      } else {
        throw new Error('Failed to set user from session');
      }
    }
  };

export const authLogin = async (email: string, password: string) => {
  const response = await api.auth.login({ email, password });
  return response.auth;
};

export const dispatchLogin = (auth: boolean) => async (dispatch: Dispatch<UpdateFauthIsSetType | any>) => {
  if (auth) {
    try {
      _.cookie('fauthIsSet', 'set');
      dispatch({ type: UPDATE_FAUTH_IS_SET, payload: true });
      const user = await api.auth.getSession();
      await dispatch(setUserFromSession(user));
    } catch (e) {
      if ((e as { status: number }).status !== 409) throw new Error('Inactive user');
      else {
        dispatch(setInvalidPasscode(true));
        throw new Error('Invalid passcode');
      }
    }
  } else {
    throw new Error('Invalid mail or password');
  }
};

export const logout = () => (dispatch: Dispatch<UpdateFauthIsSetType | LogoutType | { type: 'RESET_STORE' }>) => {
  return api.auth.logout().then(() => {
    _.removeCookie('fauthIsSet');
    dispatch({ type: RESET_STORE });
    dispatch({ type: UPDATE_FAUTH_IS_SET, payload: false });
    dispatch({ type: LOGOUT });
  });
};

export const storeLogout = () => (dispatch: Dispatch<UpdateFauthIsSetType | LogoutType | { type: 'RESET_STORE' }>) => {
  _.removeCookie('fauthIsSet');
  dispatch({ type: RESET_STORE });
  dispatch({ type: UPDATE_FAUTH_IS_SET, payload: false });
  dispatch({ type: LOGOUT });
};

export const register =
  (data: { name: string; email: string; registrationSecret: string; subdomain: string; organizationName: string }) =>
  () =>
    api.auth.register({
      admin_name: data.name,
      admin_email: data.email,
      registration_secret: data.registrationSecret,
      organization_subdomain: data.subdomain,
      organization_name: data.organizationName,
    });

export const requestPasswordReset = (email: string) => (dispatch: Dispatch<RequestPasswordResetType>) =>
  api.auth.passwordReset({ email }).then(() => {
    dispatch({ type: REQUEST_PASSWORD_RESET });
  });

export const performPasswordReset =
  (resetToken: string, newPassword: string) => (dispatch: Dispatch<PerformPasswordResetType>) =>
    api.auth.passwordReset({ new_password: newPassword }, resetToken).then(() => {
      dispatch({ type: PERFORM_PASSWORD_RESET });
    });

export const updateSession = (user: User) => (dispatch: Dispatch<SetUserType>, getState: () => StoreState) => {
  const { organization, currentUser } = getState().auth;
  if (user.password) delete user.password;
  // update user in redux
  dispatch({
    type: SET_USER,
    payload: {
      organization,
      user: { ...currentUser, ...user },
    },
  });
  setUserUiMetaData(dispatch, user);
};
