import { _, hooks, mui, React, ts, ui } from '_core';

import * as columns from 'views/preferences/columns-config';
import AsyncTabular from 'views/report/tables/async-tabular';

import { Params } from './types';
import { getColumns } from '../../common/charts/utils';

type ChartProps = {
  id: string;
  loadWidgetData: (_payload: ts.types.widgets.WidgetGetDataParams) => Promise<ts.types.widgets.WidgetGetDataResponse>;
  params: Params;
  setParams: (_p: Params) => void;
  fullScreen: boolean;
  goFullScreen: (_v: string) => void;
  holdings: string;
  queryParams?: { initial?: string };
  context?: ts.types.portfolioHoldings.PortfolioHoldingsAnalysisContext;
};

const Chart: React.FC<ChartProps> = ({
  id,
  loadWidgetData,
  params,
  setParams,
  holdings,
  fullScreen,
  goFullScreen,
  queryParams,
  context,
}) => {
  const container = React.createRef();

  const [excludeZeroes, setExcludeZeroes] = React.useState(params.exclude_zeroes ?? false);

  const [holdingsDate, setHoldingsDate] = React.useState('Loading...');

  const [filter, setFilter] = React.useState<ts.types.components.dataGrid.ValueFilters>(
    excludeZeroes
      ? [
          {
            column: 'share_count',
            operator: '!=',
            value: 0,
            column_type: 'number',
            replace_nans_to: 0,
          },
        ]
      : []
  );

  const [nav, setNav] = React.useState('');

  const getNav = (localData: ts.types.widgets.TableData) => {
    const navData = localData.find((el) => el.nav);

    if (navData) {
      const localeNav = parseInt(navData.nav as string).toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
      setNav(localeNav);
    }
  };

  const [tableParams, setTableParams] = React.useState<ts.types.components.dataGrid.TableParamsSheet>(
    params.table_params
  );

  const [tableDtypes, setTableDtypes] = React.useState<ts.types.components.dataGrid.TableDtypesSheet>(
    params.table_dtypes
  );

  hooks.useEffectWithoutFirst(() => {
    setParams({
      ...params,
      table_params: { ...tableParams },
      table_dtypes: { ...tableDtypes },
    });
  }, [tableParams, tableDtypes]);

  hooks.useEffectWithoutFirst(() => {
    if (excludeZeroes)
      setFilter([
        {
          column: 'share_count',
          operator: '!=',
          value: 0,
          column_type: 'number',
          replace_nans_to: 0,
        },
      ]);
    else setFilter([]);
  }, [excludeZeroes]);

  const loadData = async (
    offset: number,
    orderBy: ts.types.components.dataGrid.OrderBy,
    filters: ts.types.components.dataGrid.ValueFilters,
    logicOperator: mui.dataGrid.GridLogicOperator
  ) => {
    const response = await loadWidgetData({
      data: {
        file: holdings,
        pagination: { offset, limit: 100 },
        order_by: orderBy,
        values_filter: filters,
        filter_conjunction: logicOperator,
      },
    });

    if (response) {
      getNav(response?.data);
      setHoldingsDate(response?.data?.[0]?.build_date as string);
    }

    return response;
  };

  const downloadData = async (
    rename: Record<string, string>,
    columnOrder: string[],
    filters: ts.types.components.dataGrid.ValueFilters
  ) => {
    const response = await loadWidgetData({
      data: {
        file: holdings,
        file_name: 'holdings.csv',
        rename,
        column_order: columnOrder,
        values_filter: filters,
      },
      download: true,
    });

    return response;
  };

  const debouncedSaveExcludeZeroes = React.useCallback(
    _.debounce((localExcludeZeroes) => {
      setParams({
        ...params,
        exclude_zeroes: localExcludeZeroes,
      });
    }, 500),
    []
  );

  hooks.useEffectWithoutFirst(() => {
    debouncedSaveExcludeZeroes(excludeZeroes);
  }, [excludeZeroes]);

  const preferencesButton = () => (
    <mui.core.Box display="flex" width="100%">
      <mui.core.Stack flexDirection="row" gap={4} flexGrow={1}>
        <ui.InfoRow title="Holdings Date">
          {holdingsDate ?? context.holding_trade_date ?? 'Without holdings'}
        </ui.InfoRow>

        {nav && <ui.InfoRow title="NAV">${nav}</ui.InfoRow>}

        <mui.core.Box ml={4}>
          <mui.core.FormControlLabel
            control={
              <mui.core.Switch
                size="small"
                checked={excludeZeroes}
                onChange={() => {
                  setExcludeZeroes(!excludeZeroes);
                }}
              />
            }
            label="Exclude Rows with Zero Holdings"
          />
        </mui.core.Box>
      </mui.core.Stack>
    </mui.core.Box>
  );

  const getClass = (row: ts.types.widgets.TableData[0]) => {
    if (row.side == 'long') return 'data-grid-row-green';
    if (row.side == 'short') return 'data-grid-row-red';
    return undefined;
  };

  const renderChart = () => (
    <>
      <AsyncTabular<ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.MAIN>
        key={holdings}
        loadData={{ data: loadData }}
        getColumns={{
          [ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.MAIN]: (colKeys, userColumnsPreferences) =>
            getColumns(
              colKeys,
              userColumnsPreferences[ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.MAIN],
              columns.portfoliosInitialHoldingsColumns[ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.MAIN],
              undefined,
              undefined,
              ['period_start', 'period_end']
            ),
        }}
        sheetTitles={{
          [ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.MAIN]: 'Holdings',
        }}
        sheetFilters={{
          [ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.MAIN]: filter,
        }}
        fullScreen={fullScreen}
        goFullScreen={goFullScreen}
        queryParams={queryParams}
        customToolBar={{
          [ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.MAIN]: preferencesButton,
        }}
        downloadFullData={{
          [ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.MAIN]: downloadData,
        }}
        preferences={{
          preferencesSourceWidgetId: id,
          preferenceKey: ts.enums.PREFERENCES_KEY_ENUM.PORTFOLIOS_HOLDINGS_COLS,
          preferenceTab: ts.enums.PREFERENCES_INNER_TABS_ENUM.PORTFOLIOS_HOLDINGS_COLS,
        }}
        tableParams={tableParams}
        setTableParams={{
          [ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.MAIN]: (v) =>
            setTableParams((t) => ({
              ...t,
              [ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.MAIN]: v,
            })),
        }}
        tableDtypes={tableDtypes}
        setTableDtypes={{
          [ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.MAIN]: (v) =>
            setTableDtypes((t) => ({
              ...t,
              [ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.MAIN]: v,
            })),
        }}
        rowClass={{
          [ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.MAIN]: getClass,
        }}
      />
    </>
  );

  return (
    <mui.core.Box display="flex" flexDirection="column" style={{ height: '100%' }} ref={container}>
      {!_.isNil(holdings) && renderChart()}
    </mui.core.Box>
  );
};

export default Chart;
