import { _, mui, React, ts } from '_core';

import { prepareDistributionData } from './helpers';
import ChartWrapper from '../../common/chart-wrapper';
import createLineChart from '../../common/charts/create-line-chart';

type ChartProps = {
  id: string;
  readOnly: boolean;
  widgetKey: string;
  loadWidgetData: (_payload: ts.types.widgets.WidgetGetDataParams) => Promise<ts.types.widgets.WidgetGetDataResponse>;
  file: string;
  hasSPPipeline: boolean;
  hasPipeline: boolean;
  fullScreen: boolean;
  title: string;
};

type TabTypes = 'signal_' | 'pipeline_' | 'sp_pipeline_';

const Chart: React.FC<ChartProps> = ({
  id,
  readOnly,
  widgetKey,
  loadWidgetData,
  file,
  hasSPPipeline,
  hasPipeline,
  fullScreen,
  title,
}) => {
  let tabTypes = ['signal_'] as TabTypes[];
  if (hasPipeline) tabTypes = ['pipeline_'].concat(tabTypes) as TabTypes[];
  if (hasSPPipeline) tabTypes = ['sp_pipeline_'].concat(tabTypes) as TabTypes[];

  const [data, setData] = React.useState<ts.types.widgets.TableData>(null);
  const [formattedData, setFormattedData] = React.useState<ts.types.widgets.TableData>(null);
  const [error, setError] = React.useState<ts.types.common.ApiError>(null);
  const [showLegend, setShowLegend] = React.useState(false);
  const [tab, setTab] = React.useState<TabTypes>(tabTypes.at(0));

  const loadData = async () => {
    try {
      const response = await loadWidgetData({
        data: {
          file,
          rename: { build_date: 'date' },
        },
      });
      if (response) setData(response.data);
    } catch (err) {
      setError(err as ts.types.common.ApiError);
    }
  };

  React.useEffect(() => {
    if (file) loadData();
    return () => setData(null);
  }, [file]);

  React.useEffect(() => {
    if (!_.isNil(data)) {
      const filteredData = data.map((item) => {
        const filteredColumns = ['date', ..._.filter(Object.keys(item), (key) => key.startsWith(tab))];
        return _.pick(item, filteredColumns);
      });

      setFormattedData(filteredData);
    }
  }, [data, tab]);

  React.useEffect(() => {
    if (!_.isNil(formattedData))
      createLineChart({
        id,
        scale: 'linear',
        data: formattedData,
        fullScreen,
        setShowLegend,
        percent: false,
        yAxisLabel: 'Exposures',
        exportTitle: title,
        prepareData: (linearData) => prepareDistributionData(linearData, tab),
      });
  }, [formattedData]);

  const getMainTabTitle = (tab: TabTypes) => {
    if (tab == 'signal_') return 'Raw';
    if (tab == 'pipeline_') return 'After Pipeline';
    return 'After Portfolio Pipeline';
  };

  const getTabs = () => (
    <>
      <mui.core.Tabs
        indicatorColor="primary"
        textColor="primary"
        value={tab}
        onChange={(_e, newTab) => setTab(newTab as TabTypes)}
      >
        {tabTypes.map((f) => (
          <mui.core.Tab key={f} label={getMainTabTitle(f)} value={f} />
        ))}
      </mui.core.Tabs>
    </>
  );

  return (
    <ChartWrapper
      id={id}
      readOnly={readOnly}
      widgetKey={widgetKey}
      data={data}
      showLegend={showLegend}
      error={error}
      tabs={getTabs()}
      fullScreen={fullScreen}
    />
  );
};

export default Chart;
