import { hooks, mui, React, ts, ui } from '_core';

import { DraftParams } from './types';

type FormProps = {
  params: DraftParams;
  setParams: (_p: DraftParams) => void;
  context: ts.types.signalSet.SignalSetAnalysisContext;
};

const Form: React.FC<FormProps> = ({ params, setParams, context }) => {
  const [dailyReporting, setDailyReporting] = React.useState(params?.daily_reporting || false);
  const [benchmark, setBenchmark] = React.useState(params.benchmark || null);
  const [returnHorizons, setReturnHorizons] = React.useState(params?.return_horizons || []);

  React.useEffect(() => {
    setParams({
      ...params,
      daily_reporting: dailyReporting,
      return_horizons: returnHorizons,
      frequency: dailyReporting ? ts.enums.FREQUENCY_ENUM.DAILY : undefined,
      benchmark,
      set_params: [
        {
          id: 'report', // Fixed id to get the file from payload on index
          file: 'sp_returns',
          file_params: {
            return_horizons: returnHorizons,
            agg_frequency: dailyReporting ? ts.enums.FREQUENCY_ENUM.DAILY : undefined,
          },
          aggregation_params: {},
          aggregation: 'fin-gen-sp-returns',
        },
      ],
    });
  }, [returnHorizons, dailyReporting, benchmark]);

  const uiStyles = hooks.useUiStyles();
  return (
    <mui.core.Box sx={uiStyles.widgetEditMode}>
      <ui.WidgetReturnHorizon
        value={returnHorizons?.[0]}
        setValue={(val) => setReturnHorizons([val])}
        dailyReporting={dailyReporting}
        setDailyReporting={(val) => setDailyReporting(val)}
        multiple={false}
        enableDailyReporting
        context={context}
      />

      <mui.core.Box mt={4}>
        <mui.core.Typography
          variant="body2"
          fontWeight="fontWeightMedium"
          style={{ marginTop: '0.5rem', marginBottom: '1rem' }}
        >
          Benchmark
        </mui.core.Typography>
        <ui.Benchmark
          value={benchmark || undefined}
          setValue={setBenchmark}
          universeContextPath={'$universe.handle'}
          context={context}
          universeIdPath="universe_id"
        />
      </mui.core.Box>
    </mui.core.Box>
  );
};

export default Form;
