import { mui, React, ts } from '_core';

import DateForm from './signal-dataset-fields/dateform';
import FrequencyField from './signal-dataset-fields/frequency-field';
import PipelineField from './signal-dataset-fields/pipeline-field';
import RiskModelField from './signal-dataset-fields/risk-model-field';
import SpPipelineField from './signal-dataset-fields/sp-pipeline-field';
import UniverseField from './signal-dataset-fields/universe-field';

const FiltersItemContainer = ({ children }: { children: React.ReactNode }) => {
  return (
    <mui.core.Box
      sx={{
        textOverflow: 'ellipsis',
        fontSize: '0.8rem',
        minWidth: '4.5rem',
        marginBottom: '0.66rem',
        position: 'relative',
        '& .MuiInputBase-input': {
          paddingRight: '22px!important',
        },
      }}
    >
      {children}
    </mui.core.Box>
  );
};

type SignalDatasetConfigProps = {
  dataset: ts.types.signal.DatasetDraft;
  setDataset: (_d: ts.types.signal.DatasetDraft) => void;
  setIsDateRangeValid: (_v: boolean) => void;
  setDatesLoading: (_v: boolean) => void;
  resources: ts.StoreState['resources'];
  signal?: ts.types.signal.SignalDraft;
  allOptional?: boolean;
  isPreference?: boolean;
};

const SignalDatasetConfig: React.FC<SignalDatasetConfigProps> = (props) => {
  const { dataset } = props;

  return (
    <mui.core.Box mt={6}>
      <>
        <FiltersItemContainer>
          <UniverseField {...props} />
        </FiltersItemContainer>
        <FiltersItemContainer>
          <FrequencyField {...props} />
        </FiltersItemContainer>
        <FiltersItemContainer>
          <RiskModelField {...props} />
        </FiltersItemContainer>
        <FiltersItemContainer>
          <PipelineField {...props} />
        </FiltersItemContainer>
        <FiltersItemContainer>
          <SpPipelineField {...props} />
        </FiltersItemContainer>
      </>
      {dataset.frequency && <DateForm {...props} />}
    </mui.core.Box>
  );
};

export default SignalDatasetConfig;
