import { _, mui, React, ui } from '_core';

import SignalRow from './signal-row';

type SignalFactorExposuresEl = Partial<{
  signal_id: number;
  pipeline_id?: number;
  sp_pipeline_id?: number;
}>[];

type SignalFactorExposuresProps = {
  value: SignalFactorExposuresEl;
  setValue: (_p: SignalFactorExposuresEl) => void;
};

const SignalFactorExposures: React.FC<SignalFactorExposuresProps> = ({ value, setValue }) => {
  const [signals, setSignals] = React.useState(value || []);
  const [snackbarMessage, setSnackbarMessage] = React.useState(null);

  React.useEffect(() => {
    setValue(signals);
  }, [signals]);

  const addRow = () => {
    setSignals([...signals, { signal_id: null, pipeline_id: null, sp_pipeline_id: null }]);
  };

  const updateRow = (
    idx: number,
    updated: {
      signal_id?: number;
      pipeline_id?: number;
      sp_pipeline_id?: number;
    }
  ) => {
    setSignals(
      signals.map((s, sidx) => {
        if (sidx == idx) return updated;
        return s;
      }) as SignalFactorExposuresEl
    );
  };

  const removeRow = (idx: number) => {
    setSignals(signals.filter((_s, sidx) => sidx !== idx));
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(_.isEmpty(signals) ? '' : JSON.stringify(signals));
    setSnackbarMessage('Copied to clipboard!');
  };

  const pasteToField = async () => {
    try {
      const clipboard = await navigator.clipboard.readText();
      const localSignals = JSON.parse(clipboard);
      setSignals(localSignals);
      setSnackbarMessage('Pasted Succesfully!');
    } catch (err) {
      console.log('unable to paste to signals field');
    }
  };

  return (
    <>
      <mui.core.Box display="flex" alignItems="center">
        <mui.core.Typography sx={{ fontWeight: 500, flex: '1' }}>User Factor Exposures</mui.core.Typography>
      </mui.core.Box>

      {signals.map((s, idx) => (
        <SignalRow key={idx} row={s} setRow={(v) => updateRow(idx, v)} removeRow={() => removeRow(idx)} />
      ))}

      <mui.core.Stack pt={3} display="flex" flexDirection="row" gap={2}>
        <ui.Button onClick={addRow} variant="outlined" color="primary">
          <mui.icons.Add style={{ fontSize: '0.75rem', marginRight: '4px' }} />
          Add signal
        </ui.Button>
        {signals.length > 0 && (
          <mui.core.Button onClick={copyToClipboard} startIcon={<mui.icons.FileCopyOutlined />}>
            Copy Signals
          </mui.core.Button>
        )}
        <mui.core.Button onClick={pasteToField} startIcon={<mui.icons.ContentPaste />}>
          Paste Signals
        </mui.core.Button>

        <mui.core.Snackbar
          open={snackbarMessage}
          onClose={() => setSnackbarMessage(null)}
          autoHideDuration={3000}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        >
          <mui.lab.Alert severity="success" variant="filled">
            {snackbarMessage}
          </mui.lab.Alert>
        </mui.core.Snackbar>
      </mui.core.Stack>
    </>
  );
};

export default SignalFactorExposures;
