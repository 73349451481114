/**
 * Author: Yusuf Bhabhrawala
 * Abstraction for polling on job completion
 * Features:
 *  - Multiple jobs
 *  - Multiple listener
 *  - Deduped polling
 *  - Cache of completed polls
 */


import apis from './scrud';
import { REPORT_ANALYSIS_TYPE_ENUM } from '../enums/report';
import { _ } from '../libs';
import { Job, JobPrintData, JobRequests } from '../types/job';

type ReturnedJob = { last: Job };

type GetCurrentJobsProps = {
  job_type: string;
  item_type: REPORT_ANALYSIS_TYPE_ENUM;
  item_id_ref: string | number;

  depend_type?: string;
};

// Current Job
export const getCurrentJobs = async ({ job_type, item_type, item_id_ref, depend_type = null }: GetCurrentJobsProps) => {
  let depends: ReturnedJob = null;
  const jobs = { last: null } as ReturnedJob;
  if (depend_type && depend_type !== job_type) {
    depends = await getCurrentJobs({ job_type: depend_type, item_type, item_id_ref });
  }
  const query = [
    '$and',
    ['item_type', '=', item_type],
    ['item_id_ref', '=', `${item_id_ref}`],
    ['type', '=', job_type],
  ] as (string | [string, string, string | number])[];
  if (depends) return jobs;
  const resp = await apis.jobs.search({ query });
  if (!resp.data || resp.data.length === 0) return jobs;
  const sorted = _.sortBy(resp.data, 'id').reverse();
  jobs.last = sorted[0] as Job;
  return jobs;
};

type TriggerMasterJobProps = {
  context: Record<string, any>;
  job_type?: string;
  itemType: REPORT_ANALYSIS_TYPE_ENUM;
  itemId: string | number;

  requests?: JobRequests;
  printData?: JobPrintData;
};

// Master Job
export const triggerMasterJob = async ({ context, itemType, itemId, requests, printData }: TriggerMasterJobProps) => {
  try {
    const resp = await apis.jobs.create({
      type: 'master_job',
      signature: new Date().getTime().toString(),
      data: {
        context,
        requests,
        print_data: printData,
      },
      item_id_ref: itemId as string,
      item_type: itemType,
      started_at: new Date().toISOString(),
    });
    return resp.data;
  } catch (error) {
    return null;
  }
};

// Cancel job
export const cancelJob = async (jobId: number) => {
  try {
    const resp = await apis.jobs.update(jobId, { canceled: true });
    return resp.data;
  } catch (error) {
    return null;
  }
};
