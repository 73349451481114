import { config, React, Redirect, Route, Switch, ts, ui, useSelector } from '_core';

import Layout from './layout';

const UsersLazy = React.lazy(() => import('./users'));
const PublishResourcesLazy = React.lazy(() => import('./publish-resources'));
const DashboardLazy = React.lazy(() => import('./dashboard'));
const DatasetsLazy = React.lazy(() => import('./datasets'));
const FinseraAdminLazy = React.lazy(() => import('./finsera-admin'));
const HelpLazy = React.lazy(() => import('./help'));
const ResourcesRouterLazy = React.lazy(() => import('./resources-router'));

const UsersDeferred = () => {
  return (
    <React.Suspense fallback={<ui.CenteredLoader top="-100px" />}>
      <UsersLazy />
    </React.Suspense>
  );
};

const DashboardDeferred = () => {
  return (
    <React.Suspense fallback={<ui.CenteredLoader top="-100px" />}>
      <DashboardLazy />
    </React.Suspense>
  );
};

const DatasetsDeferred = () => {
  return (
    <React.Suspense fallback={<ui.CenteredLoader top="-100px" />}>
      <DatasetsLazy />
    </React.Suspense>
  );
};

const FinseraAdminDeferred = () => {
  return (
    <React.Suspense fallback={<ui.CenteredLoader top="-100px" />}>
      <FinseraAdminLazy />
    </React.Suspense>
  );
};

const PublishResourcesDeferred = () => {
  return (
    <React.Suspense fallback={<ui.CenteredLoader top="-100px" />}>
      <PublishResourcesLazy />
    </React.Suspense>
  );
};

const HelpDeferred = () => {
  return (
    <React.Suspense fallback={<ui.CenteredLoader top="-100px" />}>
      <HelpLazy />
    </React.Suspense>
  );
};

const ResourcesRouterDeferred = () => {
  return (
    <React.Suspense fallback={<ui.CenteredLoader top="-100px" />}>
      <ResourcesRouterLazy />
    </React.Suspense>
  );
};

const LoggedInRouter = () => {
  const org_id = useSelector((state: ts.StoreState) => state.auth.organization.id);

  return (
    <Layout>
      <ui.Websocket>
        <Switch>
          <Route
            path="/user-management"
            render={() => {
              return <UsersDeferred />;
            }}
          />

          <Route
            path="/dashboard"
            render={() => {
              return <DashboardDeferred />;
            }}
          />

          <Route
            path="/datasets"
            render={() => {
              return <DatasetsDeferred />;
            }}
          />

          <Route
            path="/help"
            render={() => {
              return <HelpDeferred />;
            }}
          />

          <Route
            path="/blank-loading"
            render={() => {
              return <ui.CenteredLoader />;
            }}
          />

          {config.features.publish_source && (
            <Route
              path="/publish"
              render={() => {
                return <PublishResourcesDeferred />;
              }}
            />
          )}

          {org_id == 0 && (
            <Route
              path="/finsera-admin"
              render={() => {
                return <FinseraAdminDeferred />;
              }}
            />
          )}

          {/* If we are on root, redirect to dashboard */}
          <Route
            exact
            path="/"
            render={() => {
              return <Redirect to="/dashboard" />;
            }}
          />

          {/* If no route match, then go to workspace or jobber */}
          <Route
            path="*"
            render={() => {
              return <ResourcesRouterDeferred />;
            }}
          />
        </Switch>
      </ui.Websocket>
    </Layout>
  );
};

export default LoggedInRouter;
