import { _, ts } from '_core';

import { replaceNans } from './helpers';

const getRow = (
  data: ts.types.widgets.TableData,
  col: string,
  realData: ts.types.widgets.TableData,
  index: string,
  scale: 'linear' | 'log'
): { [key: string]: any }[] => {
  return data.map((row, i) => ({
    [index]: row[index],
    value: row[col],
    real: scale === 'log' ? (realData[i][col] as number) + 1 : realData[i][col],
  }));
};

export const lineChartFormat = (
  data: ts.types.widgets.TableData,
  scale: 'linear' | 'log' = 'linear',
  without: string[] | null = null,
  index = 'date',
  addZeroOn = ''
): ts.types.widgets.LinearData => {
  let realData = _.cloneDeep(data);

  if (without) {
    data = data.map((row) => _.omit(row, without) as ts.types.widgets.TableData[number]);
  }

  const keys = Object.keys(data?.[0] ?? {}).filter((key) => key !== index);

  if (addZeroOn) {
    const firstDate = data[0][addZeroOn];
    const zeroData = {
      ..._.fromPairs(
        keys.map((key) => {
          if (key != addZeroOn) return [key, 0];
          return [key, firstDate];
        })
      ),
      [index]: firstDate,
    };

    data = [zeroData, ...data];
    realData = [zeroData, ...realData];
  }

  if (scale === 'log') {
    data = data.map((row) => {
      const newRow: ts.types.widgets.TableData[number] = { ...row };
      keys.forEach((col) => {
        newRow[col] = Math.log10(1 + (row[col] as number));
      });
      return newRow;
    });
  }

  data = replaceNans(data);
  realData = replaceNans(realData);

  const responseDict: { [key: string]: { [key: string]: any }[] } = {};
  keys.forEach((col) => {
    responseDict[col] = getRow(data, col, realData, index, scale);
  });

  return responseDict;
};
