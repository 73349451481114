import { ts } from '_core';

import { validate } from './helpers';
import { INTERACTION_PARAMS } from './types';

export default [
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.SIGNAL_PREVIEW_ENTIRE_UNIVERSE,
    form: true,
    title: 'Entire Universe',
    interactionParams: Object.values(INTERACTION_PARAMS),
    clearInteractionOnRegenerate: ['table_dtypes'],
    neededFiles: ['main_data'],
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.SIGNAL_PREVIEW],

    validate,
    helpLabel: 'ffttqkeg',
  },
] satisfies ts.types.analysis.WidgetConfig[];
