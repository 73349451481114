import { am5, am5plugins_exporting, moment } from '@local/finsera-core/src/libs';

export const createDownloadButton = (root: am5.Root, xAxisField: string, data: any) => {
  // Format date fields in the data
  const formattedData = data.map((item: any) => {
    if (item[xAxisField]) {
      return { ...item, [xAxisField]: moment(item[xAxisField]).format('YYYY-MM-DD') };
    }
    return item;
  });

  const exporting = am5plugins_exporting.Exporting.new(root, {
    dataSource: formattedData, // Use formatted data
    jsonOptions: { disabled: true },
    htmlOptions: { disabled: true },
    pdfOptions: { disabled: true },
    pdfdataOptions: { disabled: true },
    xlsxOptions: { disabled: true },
    printOptions: { disabled: true },
    menu: am5plugins_exporting.ExportingMenu.new(root, {
      align: 'left',
    }),
  });

  return exporting.get('menu').set('items', [
    {
      type: 'format',
      format: 'jpg',
      label: 'JPG',
    },
    {
      type: 'format',
      format: 'png',
      label: 'PNG',
    },
    {
      type: 'format',
      format: 'csv',
      label: 'CSV',
    },
  ]);
};
