import { _, mui, React, ts } from '_core';

import { prepareEventStudyData } from './helpers';
import { Params } from './types';
import ChartWrapper from '../../common/chart-wrapper';
import createErrorChart from '../../common/charts/create-error-chart';
import createHeatMapChart from '../../common/charts/create-heat-map';
import createNestedBarChart from '../../common/charts/create-nested-bar-chart';

type ChartProps = {
  id: string;
  readOnly: boolean;
  widgetKey: string;
  loadWidgetData: (_payload: ts.types.widgets.WidgetGetDataParams) => Promise<ts.types.widgets.WidgetGetDataResponse>;
  fileEvent: string;
  fileCount: string;
  fullScreen: boolean;
  params: Params;
  title: string;
};

const Chart: React.FC<ChartProps> = ({
  id,
  readOnly,
  widgetKey,
  loadWidgetData,
  fileEvent,
  fileCount,
  fullScreen,
  params,
  title,
}) => {
  const [data, setData] = React.useState<ts.types.widgets.TableData>(null);
  const [error, setError] = React.useState<ts.types.common.ApiError>(null);
  const [showErrorLine, setShowErrorLine] = React.useState(!params.get_histories);

  const [showLegend, setShowLegend] = React.useState(false);
  const [selectedTab, setSelectedTab] = React.useState<'error' | 'tstat' | 'count'>('error');

  const loadData = async (selectedTab: 'error' | 'tstat' | 'count') => {
    try {
      const response = await loadWidgetData({
        data: {
          file: selectedTab == 'count' ? fileCount : fileEvent,
          rename: selectedTab == 'error' ? { avg_cum: 'average_cumulative_return', std_cum: 'error' } : {},
          columns: selectedTab == 'tstat' ? ['days_from_event', 'tstat'] : undefined,
        },
      });
      if (response) setData(response.data);
    } catch (err) {
      setError(err as ts.types.common.ApiError);
    }
  };

  React.useEffect(() => {
    if (fileEvent || fileCount) loadData(selectedTab);
    return () => setData(null);
  }, [fileEvent, fileCount, selectedTab]);

  React.useEffect(() => {
    if (!_.isNil(data)) {
      if (selectedTab == 'tstat')
        if (params.get_histories)
          createHeatMapChart({
            id,
            data: data as ts.types.widgets.HeatMapData,
            fullScreen,
            xAxis: 'days_from_event',
            yAxis: 'category',
            exportTitle: title,
            prepareData: (heatMapData) => prepareEventStudyData(heatMapData, selectedTab, params.histories) as any,
          });
        else
          createNestedBarChart({
            id,
            data,
            fullScreen,
            setShowLegend,
            hideLegend: true,
            xAxisLabel: 'Days',
            yAxisLabel: 'T-stat',
            category: 'days_from_event',
            exportTitle: title,
            prepareData: (nestedBarData) => ({
              ...nestedBarData,
              data: prepareEventStudyData(nestedBarData.data, selectedTab, params.histories) as any,
            }),
          });

      if (selectedTab == 'error')
        createErrorChart({
          id,
          data,
          fullScreen,
          xAxis: 'days_from_event',
          yAxis: 'average_cumulative_return',
          yAxisLabel: 'Days',
          xAxisLabel: `Mean Cumulative Returns${!params.with_volatility ? ' (%)' : ''}`,
          setShowLegend,
          exportTitle: title,
          showError: showErrorLine,
          percent: !params.with_volatility,
          bucket: params.get_histories ? 'category' : undefined,
          prepareData: (errorData) => prepareEventStudyData(errorData, selectedTab, params.histories) as any,
        });
      if (selectedTab == 'count')
        createNestedBarChart({
          id,
          data: data,
          fullScreen,
          setShowLegend,
          hideLegend: true,
          yAxisLabel: 'Event Count',
          category: 'build_date',
          rotatedLabels: true,
          exportTitle: title,
          prepareData: (nestedBarData) => ({
            ...nestedBarData,
            data: prepareEventStudyData(nestedBarData.data, selectedTab, params.histories) as any,
          }),
        });
    }
  }, [data, showErrorLine]);

  const getTabs = () => (
    <mui.core.Box display="flex">
      <mui.core.Box flexGrow={1} display="flex" alignItems="flex-end">
        <mui.core.Tabs
          indicatorColor="primary"
          textColor="primary"
          value={selectedTab}
          onChange={(_e, newTab) => setSelectedTab(newTab)}
        >
          <mui.core.Tab label="Cumulative Returns" value="error" />
          <mui.core.Tab label="T-stat" value="tstat" />
          <mui.core.Tab label="Event Count" value="count" />
        </mui.core.Tabs>
      </mui.core.Box>

      {selectedTab == 'error' && (
        <mui.core.Box display="flex" justifyContent="flex-end">
          <mui.core.FormControlLabel
            control={
              <mui.core.Switch size="small" checked={showErrorLine} onChange={() => setShowErrorLine((v) => !v)} />
            }
            label={showErrorLine ? 'Hide error bars' : 'Show error bars'}
          />
        </mui.core.Box>
      )}
    </mui.core.Box>
  );

  return (
    <ChartWrapper
      id={id}
      readOnly={readOnly}
      widgetKey={widgetKey}
      data={data}
      showLegend={showLegend}
      warning={data?.length === 0 ? ['Empty File'] : []}
      error={error}
      tabs={getTabs()}
      fullScreen={fullScreen}
    />
  );
};

export default Chart;
