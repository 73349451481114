import { embeddedWidgets, endpoint } from './config';
import { $, gzip } from '../libs';
import { EmbedChartData } from '../types/analysis';

export const embed = (data: { widget_key: string; data: EmbedChartData }): JQuery.jqXHR<boolean> => {
  const compressed = gzip(JSON.stringify(data));
  return $.ajax({
    method: 'POST',
    url: `${endpoint}embed_widget`,
    data: compressed,
    processData: false,
    headers: {
      'Content-Encoding': 'identity',
      'Content-Type': 'application/octet-stream+json',
      'Gzip-Encoded': true,
      'Content-Length': compressed.length,
    },
  } as any);
};

export const loadData = (widget_key: string): JQuery.jqXHR<string> =>
  $.ajax({
    method: 'GET',
    url: `${embeddedWidgets}/${widget_key}`,
    contentType: 'application/json',
    traditional: true,
  } as JQueryAjaxSettings);
