import { _, config, helpers, ts } from '_core';

export const getNewRiskModel = (
  name: string,
  handle: string,
  type: ts.types.riskModel.Definition['parameters']['type'],
  resources: ts.StoreState['resources'],
  template: ts.types.riskModel.RiskModel = null
) => {
  const returnHorizonPipeline = resources.pipelines.find((p) => p.handle == 'fin_clip_at_25_percent');

  const defaultReturnHorizon: ts.types.returnHorizon.ReturnHorizon = {
    periods: {
      frequency: ts.enums.FREQUENCY_ENUM.DAILY,
      amount: 1,
    },
    pipeline_ids: returnHorizonPipeline ? [returnHorizonPipeline.id] : [],
    signature: '1_day(s)-fin_clip_at_25_percent',
    skip: null,
  };

  let parameters: ts.types.riskModel.Definition['parameters'];
  if (type == 'STAT') {
    parameters = {
      type: 'STAT',
      max_factors: null,
      num_factors: null,
      selection: 'erank',
      asset_variance_half_life: 63,
      window: 12 * 21,
      asset_returns_min_periods: 63,
    } as ts.types.riskModel.StatDefinitionParameters;
  } else {
    parameters = {
      type: 'FACTOR',
      correct_variance_bias: true,
      weight_root: 1,
      style_min_coverage_fraction: 0.05,
      style_min_count: 3.0,
      category_min_count: 1,
      window: 12 * 21,
      max_absolute_correlation: 0.9,
      factor_returns_min_periods: 63,
      factor_returns_min_recent_periods: 21,
      factor_variance_half_life: 63,
      factor_correlation_eigenvalue_shrinkage: true,
      correct_factor_variance_bias_half_life: 21,
      correct_asset_variance_bias_half_life: 21,
      factor_variance_scaling: true,
      asset_variance_half_life: 63,
      asset_returns_min_periods: 63,
      asset_returns_min_recent_periods: 21,
      min_specific_risk: 0.1,
      min_specific_risk_percentile: 0.05,
      specific_risk_offset: 0.0,
      industry_min_prob: 0.3,
      industry_estimation_periods: 1,
      return_horizon: defaultReturnHorizon,
    } as ts.types.riskModel.FactorDefinitionParameters;
  }

  const defaultDefinition: ts.types.riskModel.DefinitionDraft = {
    style: [],
    categories: [],
    parameters,
    market: type != 'STAT',
    type: 'FINSERA',
  };

  // Check if we will create the risk model with a premade definition
  const newRiskModel: ts.types.riskModel.RiskModelDraft = {
    name,
    handle,
    definition: defaultDefinition,
    is_valid: false,
    start_date: config.features.start_date,
    end_date: config.features.end_date,
  };

  if (template && template.id) {
    newRiskModel.signature = template.signature;
    newRiskModel.definition = template.definition;
    newRiskModel.is_valid = template.is_valid;
    newRiskModel.start_date = template.start_date;
    newRiskModel.end_date = template.end_date;
    newRiskModel.end_date_latest = template.end_date_latest;
    newRiskModel.label_ids = template.label_ids;
    newRiskModel.definition.industry_analysis_job_id = null;
  }

  return newRiskModel;
};

export const getArchivedDeps = (
  riskModel: ts.types.riskModel.RiskModel,
  resources: ts.StoreState['resources']
): ts.types.common.ArchivedDeps => {
  const deps = helpers.resourceGraph.getDeps(
    { ...riskModel, resourceType: ts.enums.RESOURCES_TYPES_ENUM.RISK_MODEL },
    resources
  );
  return helpers.resourceGraph.getArchiveDeps(deps);
};

export const getUnpublishedDeps = (
  riskModel: ts.types.riskModel.RiskModel,
  resources: ts.StoreState['resources']
): ts.types.common.ResourceDeps[] => {
  return helpers.resourceGraph
    .getDeps({ ...riskModel, resourceType: ts.enums.RESOURCES_TYPES_ENUM.RISK_MODEL }, resources)
    .filter((d) => !d.is_published);
};

export const getUnsharedDeps = (
  riskModel: ts.types.riskModel.RiskModel,
  resources: ts.StoreState['resources'],
  sharedWith?: number[]
): ts.types.common.ResourceDeps[] => {
  if (!sharedWith) sharedWith = riskModel.shared_with || [];

  return helpers.resourceGraph
    .getDeps({ ...riskModel, resourceType: ts.enums.RESOURCES_TYPES_ENUM.RISK_MODEL }, resources)
    .filter((d) => !helpers.resourcesUtils.isShared(d, sharedWith));
};

export const prepareDefinitionIndustries = (
  definition: ts.types.riskModel.DefinitionDraft,
  metadata = null as ts.types.riskModel.UiMetaData
): [ts.types.riskModel.DefinitionDraft, ts.types.riskModel.UiMetaData] => {
  const industryCategories = (definition.categories ?? []).find((el) => el.handle == 'industry');

  if (_.isEmpty(industryCategories)) return [definition, metadata];

  const newMetadata = {
    comments_rich_text: metadata?.comments_rich_text ?? {},
    sections: {},
  } as ts.types.riskModel.UiMetaData;
  const categories = definition.categories.map((c) => {
    if (c.handle != 'industry') return c;
    return {
      ...c,
      factors: c.factors.map((f) => {
        const newHandle = helpers.createHandle(f.name);
        if (metadata?.sections?.[`${f.handle}_collapsed`]) newMetadata.sections[`${newHandle}_collapsed`] = true;

        return { ...f, handle: newHandle };
      }),
    };
  });

  return [
    {
      ...definition,
      categories,
    },
    newMetadata,
  ];
};
