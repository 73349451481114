/*
    Custom DangerouslySetHtmlContent component which can run 
    javascript scrips based on the dangerously-set-html-content lib
    https://github.com/christo-pr/dangerously-set-html-content
*/

/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useRef } from 'react';

import { useUiStyles } from '../hooks';
import { mui } from '../libs';

type DangerouslySetHtmlContentProps = {
  html: string;
} & React.HTMLAttributes<HTMLDivElement>;

/**
 * DangerouslySetHtmlContent component for setting HTML content
 *
 * @param html - The HTML content to set
 * @param dangerouslySetInnerHTML - The dangerouslySetInnerHTML prop
 * @param rest - Additional props for the div element
 *
 * @returns The DangerouslySetHtmlContent component.
 */

const DangerouslySetHtmlContent: React.FC<DangerouslySetHtmlContentProps> = ({
  html,
  dangerouslySetInnerHTML,
  ...rest
}) => {
  // We remove 'dangerouslySetInnerHTML' from props passed to the div
  const divRef = useRef(null);
  const isFirstRender = useRef(true);
  const uiStyles = useUiStyles();

  useEffect(() => {
    if (!html || !divRef.current) throw new Error("Html prop cant't be null");
    if (!isFirstRender.current) return;
    isFirstRender.current = false;

    // Create a 'tiny' document and parse the html string
    const slotHtml = document.createRange().createContextualFragment(html);
    divRef.current.innerHTML = ''; // Clear the container
    divRef.current.appendChild(slotHtml); // Append the new content
  }, [html, divRef]);

  // eslint-disable-next-line react/react-in-jsx-scope
  return <mui.core.Box {...rest} ref={divRef} sx={uiStyles.dangerouslyHTML} />;
};

export default DangerouslySetHtmlContent;
