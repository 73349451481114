import React from 'react';

import { getValidValue } from './date-range-selector/utils';
import { moment, mui } from '../libs';

type DateRangeActionBarProps = {
  minDate: string;
  maxDate: string;
  startInputRef: React.RefObject<HTMLInputElement>;
  endInputRef: React.RefObject<HTMLInputElement>;
  value: mui.date.DateRange<Date>;
  setValue: (_v: mui.date.DateRange<Date>) => void;
  updateDates: () => void;
  validDates?: string[];
  allValidDates?: string[];
};

/**
 * Date range action bar component for selecting date ranges
 * 
 * @param minDate - Minimum date in string format
 * @param maxDate - Maximum date in string format
 * @param startInputRef - Ref for the start date input
 * @param endInputRef - Ref for the end date input
 * @param value - Current date range value
 * @param setValue - Function to update the date range value
 * @param updateDates - Function to update the date range
 * @param validDates - Array of valid date strings
 * @param allValidDates - Array of all valid date strings
 * 
 * @returns The DateRangeActionBar component.
 */


const DateRangeActionBar: React.FC<DateRangeActionBarProps> = ({ ...props }) => {
  const { minDate, maxDate, value, setValue, startInputRef, endInputRef, updateDates, validDates, allValidDates } =
    props;

  const minDateMoment = React.useMemo(() => moment(minDate), [minDate]);
  const maxDateMoment = React.useMemo(() => moment(maxDate), [maxDate]);
  const minMaxMonthDiff = React.useMemo(
    () => maxDateMoment.diff(minDateMoment, 'month'),
    [minDateMoment, maxDateMoment]
  );

  const momentValue = React.useMemo(
    () => [value[0] ? moment(value[0]) : null, value[1] ? moment(value[1]) : null],
    [value]
  );

  const years = React.useMemo(() => {
    const lyears: number[] = [];
    for (let i = minDateMoment.year(); i <= maxDateMoment.year(); i++) lyears.push(i);
    return lyears;
  }, []);

  const jumpToDate = (year: number, idx: number) => {
    const indexedVal = momentValue[idx] || moment();
    const newValue = [...value] as mui.date.DateRange<Date>;
    newValue[idx] = indexedVal.clone().set('year', year).toDate();

    setValue(getValidValue(newValue, validDates, allValidDates));

    if (idx == 0) startInputRef.current?.focus();
    if (idx == 1) endInputRef.current?.focus();
  };

  const setLastRange = (months: number = null) => {
    const newEndDate = maxDateMoment;
    const newStartDate = months ? maxDateMoment.clone().subtract(months, 'month') : minDateMoment;

    setValue(getValidValue([newStartDate.toDate(), newEndDate.toDate()], validDates, allValidDates));
    updateDates();
  };

  return (
    <mui.core.Box p={2}>
      <mui.core.Grid container>
        <mui.core.Grid item xs={6}>
          {/* {momentValue[0] && (
            <mui.core.Box mb={2} p={2}>
              {momentValue[0].format('dddd, MMMM Do YYYY')}
            </mui.core.Box>
          )} */}
          <mui.core.Box display="flex" justifyContent="center">
            <mui.core.Box display="flex" justifyContent="center" flexWrap="wrap" style={{ width: '300px' }}>
              {years.map((year) => (
                <mui.core.Button
                  key={year}
                  onClick={() => jumpToDate(year, 0)}
                  variant={year == momentValue[0]?.year() ? 'contained' : 'outlined'}
                  style={{ margin: '5px 5px 5px 5px' }}
                  size="small"
                  disabled={
                    momentValue[0] && momentValue[1]
                      ? momentValue[0].clone().set('year', year).isAfter(momentValue[1])
                      : undefined
                  }
                >
                  {year}
                </mui.core.Button>
              ))}
            </mui.core.Box>
          </mui.core.Box>
        </mui.core.Grid>
        <mui.core.Grid item xs={6}>
          <mui.core.Box display="flex" justifyContent="center">
            <mui.core.Box display="flex" justifyContent="center" flexWrap="wrap" style={{ width: '300px' }}>
              {years.map((year) => (
                <mui.core.Button
                  key={year}
                  onClick={() => jumpToDate(year, 1)}
                  variant={year == momentValue[1]?.year() ? 'contained' : 'outlined'}
                  style={{ margin: '5px 5px 5px 5px' }}
                  size="small"
                  disabled={
                    momentValue[0] && momentValue[1]
                      ? momentValue[1].clone().set('year', year).isBefore(momentValue[0])
                      : undefined
                  }
                >
                  {year}
                </mui.core.Button>
              ))}
            </mui.core.Box>
          </mui.core.Box>
        </mui.core.Grid>
      </mui.core.Grid>
      <mui.core.Box mt={3}>
        <mui.core.Divider />
      </mui.core.Box>
      <mui.core.Box p={2} mt={1} display="flex" justifyContent="center">
        <mui.core.Stack spacing={2} p={1} direction="row">
          {minMaxMonthDiff >= 1 && (
            <mui.core.Button onClick={() => setLastRange(1)} variant="outlined">
              Last month
            </mui.core.Button>
          )}
          {minMaxMonthDiff >= 12 && (
            <mui.core.Button onClick={() => setLastRange(12)} variant="outlined">
              Last year
            </mui.core.Button>
          )}
          {minMaxMonthDiff >= 12 * 3 && (
            <mui.core.Button onClick={() => setLastRange(12 * 3)} variant="outlined">
              Last 3 Years
            </mui.core.Button>
          )}
          {minMaxMonthDiff >= 12 * 5 && (
            <mui.core.Button onClick={() => setLastRange(12 * 5)} variant="outlined">
              Last 5 Years
            </mui.core.Button>
          )}
          <mui.core.Button onClick={() => setLastRange()} variant="outlined">
            Full Range
          </mui.core.Button>
        </mui.core.Stack>
      </mui.core.Box>
    </mui.core.Box>
  );
};

export default DateRangeActionBar;
