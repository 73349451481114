import { ts } from '_core';

import { getName, validate } from './helpers';
import { DraftParams, INTERACTION_PARAMS } from './types';

export default [
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.BASKET_REGIME,
    title: 'Time Series Regime Analysis',
    helpLabel: '9rdewmuz',
    form: true,
    defaultParams: {
      quantiles: 5,
      histories: ['1 Year', '3 Years', '5 Years', 'Full'],
      show_unknown: false,
    } as DraftParams,
    interactionParams: Object.values(INTERACTION_PARAMS),
    neededFiles: ['backtest_regime_analysis_ts'],
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.BASKET],

    getName,
    validate,
  },
] satisfies ts.types.analysis.WidgetConfig[];
