import { mui, React } from '_core';

type CheckboxFieldProps = {
  value: number;
  setValue: (_d: number) => void;
  label: string;
};

const CheckboxField: React.FC<CheckboxFieldProps> = ({ value, setValue, label }): React.ReactElement => {
  const handleChange = (e: React.ChangeEvent<any>) => {
    const checked = e.target.checked;
    setValue(checked ? 1 : undefined);
  };

  return (
    <mui.core.FormControlLabel
      control={<mui.core.Checkbox onChange={handleChange} name={label} value={value} />}
      label={label}
    />
  );
};

CheckboxField.defaultProps = {
  value: undefined,
};

export default CheckboxField;
