import { ts } from '_core';

import { validate } from './helpers';
import { DraftParams, INTERACTION_PARAMS } from './types';

export default [
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.SIGNAL_DRAWDOWN,
    title: 'Drawdown',
    helpLabel: 'p9sfpxza',
    form: true,
    defaultParams: { widgetType: 'regular', with_benchmark: false } as DraftParams,
    interactionParams: Object.values(INTERACTION_PARAMS),
    neededFiles: ['signal_drawdown'],
    tags: [
      ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.SIGNAL,
      ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.ALPHA_MODEL,
      ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.COMMON_ALPHA_MODEL,
    ],

    validate,
  },
] satisfies ts.types.analysis.WidgetConfig[];
