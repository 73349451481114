import React from 'react';

import ContextSingleton from '../__singletons__/context-singleton';
import * as enums from '../enums';
import { _, mui } from '../libs';

type FrequencyFieldProps = {
  value?: enums.FREQUENCY_ENUM;
  setValue: (_value: enums.FREQUENCY_ENUM) => void;
  hideFrequencies?: enums.FREQUENCY_ENUM[];
  forceDailyEnable?: boolean;
  label?: string;
  disabled?: boolean;
  required?: boolean;
  error?: boolean;
};

/**
 * FrequencyField component that displays a dropdown for selecting a frequency.
 * 
 * @requires Context Features:
 * - enable_daily_freq: ability to enable daily frequency selection (enable_daily_freq).
 * 
 * @param value - The value of the selected frequency.
 * @param setValue - The function to set the value of the selected frequency.
 * @param hideFrequencies - Optional array of frequencies to hide from the dropdown.
 * @param forceDailyEnable - Optional flag to force the daily frequency to be enabled.
 * @param label - Optional label to display above the dropdown.
 * @param disabled - Optional flag to disable the dropdown.
 * @param required - Optional flag to indicate if the dropdown is required.
 * @param error - Optional flag to indicate if the dropdown is in an error state.
 * 
 * @returns A React component that displays a dropdown for selecting a frequency.
 */

const FrequencyField: React.FC<FrequencyFieldProps> = ({
  value,
  setValue,
  hideFrequencies,
  disabled,
  label,
  forceDailyEnable,
  required,
  error,
}): React.ReactElement => {
  const features = ContextSingleton.getInstance().features;
  const displayedFrequencies = React.useMemo(() => {
    return Object.values(enums.FREQUENCY_ENUM).filter((f) => !hideFrequencies.includes(f));
  }, [hideFrequencies]);

  const enableDaily = forceDailyEnable ? true : features.enable_daily_freq;

  return (
    <mui.core.TextField
      select
      label={label}
      variant="outlined"
      size="small"
      value={value || ''}
      onChange={(e) => setValue(e.target.value as enums.FREQUENCY_ENUM)}
      fullWidth
      disabled={disabled}
      required={required}
      error={error}
    >
      {displayedFrequencies.map((frequency) => (
        <mui.core.MenuItem
          key={frequency}
          value={frequency}
          disabled={frequency === enums.FREQUENCY_ENUM.DAILY && !enableDaily}
        >
          {_.startCase(_.toLower(frequency))}
        </mui.core.MenuItem>
      ))}
    </mui.core.TextField>
  );
};

FrequencyField.defaultProps = {
  disabled: false,
  hideFrequencies: [],
  label: 'Frequency',
  forceDailyEnable: false,
  required: true,
};

export default FrequencyField;
