import { userapiendpoint } from './config';
import { $ } from '../libs';

type UiApiPostProps = {
  data?: Record<string, any>;
  download?: boolean;
};

type PostPayloadProps = {
  method: string;
  url: string;
  json: Record<string, any>;
  xhrFields?: { responseType: string };
};

export const getData = ({ data, download }: UiApiPostProps) => {
  let url = `${userapiendpoint}ui-api/get-data`;

  if (download) url = `${userapiendpoint}ui-api/get-data-download`;

  const payload = {
    method: 'POST',
    url,
    json: data,
  } as PostPayloadProps;

  return $.ajax(payload as JQueryAjaxSettings);
};

export const getRebalanceData = ({ data }: { data: Record<string, any> }) => {
  const url = `${userapiendpoint}ui-api/get-rebalance-data`;

  const payload = {
    method: 'POST',
    url,
    json: data,
  } as PostPayloadProps;

  return $.ajax(payload as JQueryAjaxSettings);
};
export const getDataSourceData = ({ data }: { data: Record<string, any> }) => {
  const url = `${userapiendpoint}ui-api/preview`;

  const payload = {
    method: 'POST',
    url,
    json: data,
  } as PostPayloadProps;

  return $.ajax(payload as JQueryAjaxSettings);
};
