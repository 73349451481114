import { hooks, ts } from '_core';

import React from 'react';

import Full from './full';
import Small from '../small-table';

export type TabularProps<SHEETS extends string> = {
  // Sheet name -> DataRow
  loadData: Record<SHEETS, ts.types.components.dataGrid.LoadData>;

  // OPTIONAL:

  hideFilters?: boolean;
  hideDownload?: boolean;
  hideToolbar?: boolean;
  alwaysShowBottom?: boolean;
  overrideHeight?: number;
  hideDataCount?: boolean;

  // To handle go fullscreen we keep track of tab to open via query params
  fullScreen?: boolean;
  goFullScreen?: (_queryParams: string) => void;
  queryParams?: { initial?: string };
  loadOnX?: boolean;
  loadColumnsOnChange?: boolean;
  currentSheet?: SHEETS;
  setCurrentSheet?: (_sheet: SHEETS) => void;
  triggerLoadOnChange?: boolean;

  // By sheet data
  getColumns?: Record<SHEETS, ts.types.components.dataGrid.GenerateColumns>;
  // Sheet name -> DataRow
  sheetTitles?: Record<SHEETS, string>;
  // Sheet name -> DataRow
  sheetInfo?: Record<SHEETS, React.ReactElement>;
  // Sheet name -> DataRow
  rowClass?: Record<SHEETS, (_row: ts.types.components.dataGrid.Row) => string>;
  // Sheet name -> Prepare Data
  prepareData?: Record<SHEETS, (_row: ts.types.components.dataGrid.Row[]) => ts.types.components.dataGrid.Row[]>;
  // Sheet name -> DataRow
  downloadFullData?: Record<SHEETS, ts.types.components.dataGrid.DownloadFullData>;
  // Sheet name -> DataRow
  customToolBar?: Record<SHEETS, React.FC<{ columns: string[] }>>;
  // Sheet name -> Custom filter
  sheetFilters?: Record<SHEETS, ts.types.components.dataGrid.ValueFilters>;

  onDataChange?: (_row: ts.types.components.dataGrid.Row[]) => void;

  preferences?: {
    preferencesSourceWidgetId: string;
    preferenceKey: ts.enums.PREFERENCES_KEY_ENUM;
    preferenceTab: ts.enums.PREFERENCES_INNER_TABS_ENUM;
  };

  tableParams?: ts.types.components.dataGrid.TableParamsSheet;
  setTableParams?: Record<string, (_v: ts.types.components.dataGrid.TableParams) => void>;
  tableDtypes?: ts.types.components.dataGrid.TableDtypesSheet;
  setTableDtypes?: Record<string, (_v: ts.types.components.dataGrid.TableDtypes) => void>;

  overrideUserPreferences?: ts.types.components.dataGrid.ColumnPreferences;
};

const Tabular = <T extends string>({
  loadData,
  getColumns,
  sheetTitles,
  sheetInfo,
  fullScreen,
  prepareData,
  sheetFilters,
  goFullScreen,
  downloadFullData,
  loadOnX,
  loadColumnsOnChange,
  queryParams,
  rowClass,
  customToolBar,
  hideFilters,
  alwaysShowBottom,
  overrideHeight,
  onDataChange,
  hideDownload,
  hideToolbar,
  currentSheet,
  setCurrentSheet,
  preferences,
  triggerLoadOnChange,
  tableParams,
  setTableParams,
  tableDtypes,
  setTableDtypes,
  hideDataCount,
  overrideUserPreferences,
}: TabularProps<T>): React.ReactElement => {
  const initialTab = (queryParams?.initial || Object.keys(loadData)[0]) as T;
  const [cmpCurrentSheet, setCmpCurrentSheet] = React.useState(
    currentSheet && loadData[currentSheet] ? currentSheet : initialTab
  );

  hooks.useEffectWithoutFirst(() => {
    if (setCurrentSheet) setCurrentSheet(cmpCurrentSheet);
  }, [cmpCurrentSheet]);

  return (
    <>
      {fullScreen ? (
        <Full
          loadData={loadData}
          getColumns={getColumns}
          sheetTitles={sheetTitles}
          sheetInfo={sheetInfo}
          currentSheet={cmpCurrentSheet}
          setCurrentSheet={setCmpCurrentSheet as any}
          downloadFullData={downloadFullData}
          customToolBar={customToolBar}
          loadOnX={loadOnX}
          loadColumnsOnChange={loadColumnsOnChange}
          triggerLoadOnChange={triggerLoadOnChange}
          rowClass={rowClass}
          hideFilters={hideFilters}
          hideDownload={hideDownload}
          hideToolbar={hideToolbar}
          hideDataCount={hideDataCount}
          alwaysShowBottom={alwaysShowBottom}
          overrideHeight={overrideHeight}
          prepareData={prepareData}
          onDataChange={onDataChange}
          sheetFilters={sheetFilters}
          tableParams={tableParams}
          setTableParams={setTableParams}
          tableDtypes={tableDtypes}
          setTableDtypes={setTableDtypes}
          overrideUserPreferences={overrideUserPreferences}
          preferenceKey={preferences?.preferenceKey}
          preferenceTab={preferences?.preferenceTab}
          preferencesSourceWidgetId={preferences?.preferencesSourceWidgetId}
        />
      ) : (
        <Small
          sheetKeys={Object.keys(loadData)}
          sheetTitles={sheetTitles}
          sheetInfo={sheetInfo}
          goFullScreen={goFullScreen}
        />
      )}
    </>
  );
};

Tabular.defaultProps = {
  hideFilters: false,
  hideDownload: false,
  hideToolbar: false,
  hideDataCount: false,
  alwaysShowBottom: false,
  overrideHeight: undefined,
  fullScreen: true,
  goFullScreen: () => undefined as any,
  loadOnX: false,
  loadColumnsOnChange: true,
  queryParams: {},
  sheetTitles: {},
  sheetInfo: {},
  rowClass: {},
  prepareData: {},
  downloadFullData: {},
  headerRowHeight: {},
  rowWidth: {},
  rowHeight: {},
  customToolBar: {},
  sheetFilters: {},
  onDataChange: null,
  tableParams: {},
  setTableParams: () => undefined as any,
  geOverrideUserColumns: () => undefined as any,
};

export default Tabular;
