import { _, actions, config, Link, moment, mui, React, ts, ui, uitheme, useDispatch, useSelector } from '_core';

import Assistant from 'views/main-assistant/assistant';

import AddMenu from './add-menu';
import GlobalSearch from './global-search';
import PrimaryNav from './primary-nav';
import ProfileNav from './profile-nav';
import TrialEndedModal from './trial-ended-modal';

const { APP_BAR_HEIGHT, LEFT_DRAWER_WIDTH } = uitheme.layoutSize;

const NavbarContainer = (props: mui.core.BoxProps) => {
  const theme = mui.styles.useTheme() as mui.core.Theme;

  return (
    <mui.core.Box
      {...props}
      sx={{
        zIndex: theme.zIndex.drawer + 1,
        ...props.sx,
      }}
    >
      {props.children}
    </mui.core.Box>
  );
};

const AppBarContent = (props: mui.core.StackProps) => {
  const theme = mui.styles.useTheme() as mui.core.Theme;

  return (
    <mui.core.Stack
      {...props}
      sx={{
        background: theme.palette.primary.dark,
        alignItems: 'center',
        height: APP_BAR_HEIGHT,
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        ...props.sx,
      }}
    >
      {props.children}
    </mui.core.Stack>
  );
};

const Navbar: React.FC = (): React.ReactElement => {
  const [open, setOpen] = React.useState(false);
  const uiState = useSelector((store) => store.ui);

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };
  const organizationName = useSelector((state) => state.auth.organization?.name);
  const currentUser = useSelector((state) => state.auth.currentUser);
  const dispatch = useDispatch();
  const setPreferencesOpen = (o: ts.types.preferences.OPEN_PREFERENCES) => dispatch(actions.ui.setPreferencesOpen(o));

  const [openProfileMenu, setOpenProfileMenu] = React.useState(null);
  const today = moment(moment().format('YYYY-MM-DD'));
  const trial = config.features.trial_end_date ? moment(config.features.trial_end_date) : null;
  const trialDiff = trial ? trial.diff(today, 'days') : null;

  const handleProfileClick = (event: React.UIEvent<HTMLElement>) => {
    setOpenProfileMenu(event.currentTarget);
  };

  const handleProfileClose = () => {
    setOpenProfileMenu(null);
  };

  return (
    <>
      {!_.isNil(trialDiff) && trialDiff <= 0 && <TrialEndedModal />}
      <NavbarContainer>
        <mui.core.AppBar
          sx={{
            boxShadow: '0',
            '@media print': {
              display: 'none',
            },
          }}
        >
          <AppBarContent gap={2} flexDirection="row">
            <Link to="/" id="logo-container">
              <ui.Logo />
            </Link>
            <mui.core.Hidden smDown>
              <mui.core.Stack
                flexDirection="row"
                flex="1"
                sx={{
                  gap: {
                    xs: 0,
                    lg: 2,
                  },
                }}
              >
                <PrimaryNav />
              </mui.core.Stack>
            </mui.core.Hidden>

            <AddMenu />
            <GlobalSearch />
            {config.features.ai_assistant && (
              <span style={{ display: uiState.chatbotShow ? 'block' : 'none' }}>
                <Assistant />
              </span>
            )}

            {!_.isNil(trialDiff) && trialDiff > 0 && (
              <mui.core.Hidden mdDown>
                <mui.core.Chip
                  color="primary"
                  label={`Trial ends in ${trialDiff} days`}
                  size="small"
                  sx={{ position: 'fixed', bottom: 4, right: 4 }}
                  icon={<mui.icons.InfoOutlined style={{ fontSize: '0.8rem' }} />}
                />
              </mui.core.Hidden>
            )}

            <mui.core.Box sx={{ display: { xs: 'block', sm: 'none' }, flex: '1', textAlign: 'right' }}>
              <ui.Button onClick={toggleDrawer(true)}>
                <mui.icons.Menu sx={{ color: '#fff' }} />
              </ui.Button>

              <mui.core.Drawer open={open} onClose={toggleDrawer(false)} sx={{ zIndex: '10000', position: 'relative' }}>
                <mui.core.Box sx={{ width: '250px' }}>
                  <PrimaryNav dark />
                  <ProfileNav />
                </mui.core.Box>
              </mui.core.Drawer>
            </mui.core.Box>

            <mui.core.Hidden smDown>
              <ui.Button
                onClick={handleProfileClick}
                size="small"
                sx={{
                  height: '30.5px',
                  background: 'rgba(255,255,255,.1)',
                  color: '#fff',
                  '&:hover': {
                    background: 'rgba(255,255,255,.15)',
                    padding: '0',
                  },
                  '.MuiButton-endIcon': { marginLeft: '2px' },
                }}
                endIcon={<mui.icons.KeyboardArrowDown />}
              >
                <mui.core.Box pr={2}>
                  <ui.UserAvatar user={currentUser.name} size="small" />
                </mui.core.Box>
                {organizationName}
              </ui.Button>
            </mui.core.Hidden>

            <mui.core.Menu
              anchorEl={openProfileMenu}
              keepMounted
              open={Boolean(openProfileMenu)}
              onClose={handleProfileClose}
            >
              <mui.core.Stack gap={2} sx={{ minWidth: LEFT_DRAWER_WIDTH }} p={1}>
                <ProfileNav closeAction={handleProfileClose} />
              </mui.core.Stack>
            </mui.core.Menu>

            <mui.core.IconButton
              id="preferences-link"
              onClick={() => {
                setPreferencesOpen({ open: true });
              }}
              sx={{ color: '#fff', px: 0 }}
            >
              <mui.icons.Settings />
            </mui.core.IconButton>
          </AppBarContent>
        </mui.core.AppBar>
      </NavbarContainer>
    </>
  );
};

export default Navbar;
