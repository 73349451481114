import { ts } from '_core';

const filtersNamesMap = {
  [ts.enums.PIPELINE_FILTERS_ENUM.CATEGORIZE_SCALE]: 'Categorize Scale',
  [ts.enums.PIPELINE_FILTERS_ENUM.CHARACTERISTIC_PORTFOLIO]: 'Characteristic Portfolio',
  [ts.enums.PIPELINE_FILTERS_ENUM.CLIP]: 'Clip',
  [ts.enums.PIPELINE_FILTERS_ENUM.DEMEAN]: 'Demean',
  [ts.enums.PIPELINE_FILTERS_ENUM.FILLNA]: 'Replace Nulls',
  [ts.enums.PIPELINE_FILTERS_ENUM.IMPLIED_ALPHA]: 'Implied Alpha',
  [ts.enums.PIPELINE_FILTERS_ENUM.MINMAX]: 'Min - Max',
  [ts.enums.PIPELINE_FILTERS_ENUM.NEUTRALIZE]: 'Neutralize',
  [ts.enums.PIPELINE_FILTERS_ENUM.ROBUST_NEUTRALIZE]: 'Robust Neutralize',
  [ts.enums.PIPELINE_FILTERS_ENUM.NORMALIZE_KEYS]: 'Normalize keys',
  [ts.enums.PIPELINE_FILTERS_ENUM.QUANTILE_PORTFOLIO]: 'Quantile Portfolio',
  [ts.enums.PIPELINE_FILTERS_ENUM.QUANTIZE]: 'Quantize',
  [ts.enums.PIPELINE_FILTERS_ENUM.RANK]: 'Rank',
  [ts.enums.PIPELINE_FILTERS_ENUM.ROBUST_STANDARDIZE]: 'Robust Standardize',
  [ts.enums.PIPELINE_FILTERS_ENUM.SCALE]: 'Scale',
  [ts.enums.PIPELINE_FILTERS_ENUM.SIGMA]: 'Sigma',
  [ts.enums.PIPELINE_FILTERS_ENUM.SIMPLE_PORTFOLIO]: 'Simple Portfolio',
  [ts.enums.PIPELINE_FILTERS_ENUM.STANDARDIZE]: 'Standardize',
  [ts.enums.PIPELINE_FILTERS_ENUM.TAIL]: 'Tail',
  [ts.enums.PIPELINE_FILTERS_ENUM.TANH]: 'Tanh',
  [ts.enums.PIPELINE_FILTERS_ENUM.TRUNCATE]: 'Truncate',
  [ts.enums.PIPELINE_FILTERS_ENUM.RISK_MODEL_MATRIX_MULTIPLICATION]: 'Risk Model Matrix Multiplication',
  [ts.enums.PIPELINE_FILTERS_ENUM.QUANTILE_PORTFOLIO_BUCKETS]: 'Quantile Portfolio By Buckets',
  [ts.enums.PIPELINE_FILTERS_ENUM.BUCKET_UPDATE]: 'Bucket Override',
  [ts.enums.PIPELINE_FILTERS_ENUM.BUCKET_AVERAGE]: 'Bucket Average',
};

export default filtersNamesMap;
