import React from 'react';

import { mui } from '../../../libs';
import * as types from '../../../types';


type RiskStyleFactorsProps = {
  value: types.widgets.common.BucketValue;
  setValue: (_v: types.widgets.common.BucketValue) => void;
};

const RiskStyleFactors: React.FC<RiskStyleFactorsProps> = ({ value, setValue }): React.ReactElement => {
  return (
    <>
      <mui.core.Grid item xs={12} md={6}>
        <mui.core.TextField
          label="Quantiles (1 - 20)"
          variant="outlined"
          size="small"
          value={value.value}
          InputProps={{ inputProps: { min: 1, max: 20 } }}
          onChange={(e) => setValue({ ...value, value: e.target.value })}
          fullWidth
          required
        />
      </mui.core.Grid>
    </>
  );
};

export default RiskStyleFactors;
