import { hooks, mui, React, ts, ui } from '_core';

import { DraftParams } from './types';
import BasketsSelector from '../../common/forms/baskets-selector';

type FormProps = {
  params: DraftParams;
  setParams: (_p: DraftParams) => void;
};

const Form: React.FC<FormProps> = ({ params, setParams }) => {
  const [basketIds, setBasketIds] = React.useState(params.basket_ids || []);
  const uiStyles = hooks.useUiStyles();

  const [signals, setSignals] = React.useState<ts.types.pipeline.NeutralizeDraft['factors'][0]>(
    params.factors?.[0] ?? {}
  );

  React.useEffect(() => {
    const data = { ...params, basket_ids: basketIds, factors: [signals] };
    setParams(data);
  }, [basketIds, signals]);

  return (
    <mui.core.Box sx={uiStyles.widgetEditMode}>
      <mui.core.Grid container spacing={4}>
        <mui.core.Grid item xs={4}>
          <ui.ResourceAutocomplete<ts.types.signal.SignalExpanded, false, true>
            value={signals.value}
            chooseHandle
            setValue={(handle) => setSignals({ ...signals, value: handle })}
            type="signal_based"
            inputProps={{
              label: 'Signal / Alpha Model',
              variant: 'outlined',
              size: 'small',
              required: true,
            }}
          />
        </mui.core.Grid>

        <mui.core.Grid item xs={4}>
          <ui.ResourceAutocomplete<ts.types.pipeline.PipelineExpanded>
            value={signals.pipeline_id}
            setValue={(id) => {
              setSignals({ ...signals, pipeline_id: id });
            }}
            type={ts.enums.RESOURCES_TYPES_ENUM.PIPELINE}
            enableNone
            inputProps={{
              label: 'Pipeline',
              variant: 'outlined',
              size: 'small',
            }}
          />
        </mui.core.Grid>

        <mui.core.Grid item xs={4}>
          <ui.ResourceAutocomplete<ts.types.pipeline.PipelineExpanded>
            value={signals.sp_pipeline_id}
            setValue={(id) => setSignals({ ...signals, sp_pipeline_id: id })}
            type="sp_pipeline"
            enableNone
            inputProps={{
              label: 'Portfolio Pipeline',
              variant: 'outlined',
              size: 'small',
            }}
          />
        </mui.core.Grid>

        <mui.core.Grid item xs={12}>
          <BasketsSelector basketIds={basketIds} setBasketIds={setBasketIds} />
        </mui.core.Grid>
      </mui.core.Grid>
    </mui.core.Box>
  );
};

export default Form;
