import { _, actions, api, helpers, React, ts, useLocation, useSelector } from '_core';

const useWorkspaceMetadataBackgroundSave = () => {
  const { pathname } = useLocation();

  const currentWorkspaceId = useSelector((state) => state.ui.currentWorkspaceId);
  const currentTab = useSelector((state) => state.ui.currentTab);

  const updateWorkspacesMetadata = (w: ts.types.workspace.WorkspacesMetadata) => actions.ui.updateWorkspacesMetadata(w);

  const [itemType, setItemType] = React.useState<ts.enums.RESOURCES_TYPES_ENUM>();
  const [itemId, setItemId] = React.useState<number>();

  React.useEffect(() => {
    const split = pathname.split('/');
    setItemType((split?.[1] ? helpers.pluralize.singular(split[1]) : null) as ts.enums.RESOURCES_TYPES_ENUM);
    setItemId(split?.[2] ? parseInt(split?.[2]) * 1 : null);
  }, [pathname]);

  const getRecent10 = (
    lastItem: ts.types.workspace.WorkspaceMetadataItem,
    wsrecent: ts.types.workspace.WorkspaceMetadataItem[]
  ): ts.types.workspace.WorkspaceMetadataItem[] => {
    let recent = wsrecent || [];
    // add data, slice to stack size and get uniq
    recent = _.uniqWith([lastItem, ...recent].slice(0, 10), _.isEqual) as ts.types.workspace.WorkspaceMetadataItem[];
    return recent;
  };

  const debouncedSave = React.useCallback(
    _.debounce(
      async (
        itemId: number,
        itemType: ts.enums.RESOURCES_TYPES_ENUM,
        currentWorkspaceId: number,
        wsContext: ts.types.workspace.Workspace['context']
      ) => {
        const buildNewData = (currentWorkspaceId: number, workspaceMetadata: ts.types.workspace.WorkspacesMetadata) => {
          if (wsContext == 'production') {
            const newData = { prodcurrentWorkspaceId: currentWorkspaceId } as ts.types.workspace.WorkspacesMetadata;
            if (itemType && itemId) {
              newData.prodlast = { itemId, itemType };
              newData.prodrecent = getRecent10({ itemId, itemType }, workspaceMetadata.prodrecent);
            }
            return newData;
          }

          const newData = { currentWorkspaceId } as ts.types.workspace.WorkspacesMetadata;
          if (itemType && itemId) {
            newData.last = { itemId, itemType };
            newData.recent = getRecent10({ itemId, itemType }, workspaceMetadata.recent);
          }
          return newData;
        };

        try {
          const workspaceMetadata = (await api.users.getMe()).workspaces_metadata;
          const newData = buildNewData(currentWorkspaceId, workspaceMetadata);
          const updatedMetadata = { ...workspaceMetadata, ...newData };
          // store in redux
          updateWorkspacesMetadata({ ...updatedMetadata });
          // call to api
          await api.users.updateMe({ workspaces_metadata: updatedMetadata });
        } catch (e) {
          console.log('unable to store last state of workspaces', e);
        }
      },
      5000
    ),
    []
  );

  // Update the current resource on workspace
  React.useEffect(() => {
    if (currentTab == 'production' || currentTab == 'research')
      debouncedSave(itemId, itemType, currentWorkspaceId, currentTab);
  }, [itemType, itemId, currentWorkspaceId, currentTab]);
};

export default useWorkspaceMetadataBackgroundSave;
