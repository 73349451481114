import { mui, React, ts, ui } from '_core';

type SignalRowProps = {
  row: { signal_id?: number; pipeline_id?: number; sp_pipeline_id?: number };
  setRow: (_v: { signal_id?: number; pipeline_id?: number; sp_pipeline_id?: number }) => void;
  removeRow: () => void;
};

const SignalRow: React.FC<SignalRowProps> = ({ row, setRow, removeRow }) => {
  const theme = mui.styles.useTheme();

  return (
    <mui.core.Box pt={3}>
      <mui.core.Grid
        container
        spacing={4}
        alignItems="center"
        sx={{
          [theme.breakpoints.up('md')]: {
            flexWrap: 'nowrap',
          },
          [theme.breakpoints.down('md')]: {
            borderBottom: `1px solid ${theme.palette.gray.main}`,
            marginBottom: '0.5rem',
            paddingBottom: '0.5rem',
          },
        }}
      >
        <mui.core.Grid item xs={6} md={5}>
          <ui.ResourceAutocomplete<ts.types.signal.SignalExpanded>
            value={row.signal_id}
            setValue={(id) => setRow({ ...row, signal_id: id })}
            type={ts.enums.RESOURCES_TYPES_ENUM.SIGNAL}
            skipWorkspaceCheck
            inputProps={{
              label: 'Signal',
              variant: 'outlined',
              size: 'small',
              required: true,
            }}
          />
        </mui.core.Grid>
        <mui.core.Grid item xs={6} md={3}>
          <ui.ResourceAutocomplete<ts.types.pipeline.PipelineExpanded>
            value={row.pipeline_id}
            setValue={(id) => setRow({ ...row, pipeline_id: id })}
            type={ts.enums.RESOURCES_TYPES_ENUM.PIPELINE}
            enableNone
            skipWorkspaceCheck
            inputProps={{
              label: 'Pipeline',
              variant: 'outlined',
              size: 'small',
            }}
          />
        </mui.core.Grid>
        <mui.core.Grid item xs={6} md={3}>
          <ui.ResourceAutocomplete<ts.types.pipeline.PipelineExpanded>
            value={row.sp_pipeline_id}
            setValue={(id) => setRow({ ...row, sp_pipeline_id: id })}
            type="sp_pipeline"
            enableNone
            skipWorkspaceCheck
            inputProps={{
              label: 'Portfolio Pipeline',
              variant: 'outlined',
              size: 'small',
            }}
          />
        </mui.core.Grid>

        <mui.core.Grid item xs={1}>
          <mui.core.IconButton onClick={() => removeRow()} size="small">
            <mui.icons.Close />
          </mui.core.IconButton>
        </mui.core.Grid>
      </mui.core.Grid>
    </mui.core.Box>
  );
};

export default SignalRow;
