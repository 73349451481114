/* eslint-disable no-unused-vars */
import { ts } from '_core';

export enum INTERACTION_PARAMS {
  TABLE_PARAMS = 'table_params',
  TABLE_DTYPES = 'table_dtypes',
  COLUMNS = 'columns',
}

export type DataSourceBrowseItem = {
  label?: string;
  name?: string;
  parent?: string;
  required?: boolean;
  type?: ts.enums.BROWSE_ITEM_TYPE_ENUM;
  remote_values_table?: string;
  values?: Record<string, string>;
  multiple?: boolean;
  remote_values_query?: any[];
  ui_processing?: boolean;
};

export type DataSourceBrowseColumns = {
  name: string;
  description?: string;
  key: string;
  formatter?: (_props: any) => React.ReactElement;
};

export type DataSourceBrowseColumnsDraft = Partial<DataSourceBrowseColumns>;

export type InteractionParams = {
  [INTERACTION_PARAMS.COLUMNS]: Record<
    ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.MAIN,
    {
      columns?: string[];
      frozenColumns?: string[];
      names?: Record<string, string>;
    }
  >;
  [INTERACTION_PARAMS.TABLE_PARAMS]: ts.types.components.dataGrid.TableParamsSheet;
  [INTERACTION_PARAMS.TABLE_DTYPES]: ts.types.components.dataGrid.TableDtypesSheet;
};

export type FileParams = {
  filters?: Record<string, any>;
  partitions_ui?: Record<string, any>;
  partitions?: string[];
  eff_ts?: string;
  start_date?: string;
  end_date?: string;
};

export type Params = FileParams & InteractionParams;
export type DraftParams = Partial<Params>;
