import { ts } from '_core';

import { getName, validate } from './helpers';
import { DraftParams } from './types';

export default [
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.RISK_MODEL_PREDICTED_REALIZED_RISK_TIME,
    title: 'Predicted and Realized Portfolio Risk vs. Time',
    helpLabel: 'a8tumyfx',
    form: true,
    defaultParams: {
      weights: 'minimum_volatility',
      window: 21,
      gross_leverage: 1.0,
    } as DraftParams,
    neededFiles: ['realized_predicted_risk'],
    tags: [
      ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.RISK_MODEL,
      ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.COMMON_RISK_MODEL,
      ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.STATISTICAL_RISK_MODEL,
    ],

    validate,
    getName,
  },
] satisfies ts.types.analysis.WidgetConfig[];
