import { ts } from '_core';

import { validate } from './helpers';
import { DraftParams, FRACTIONAL_RISK_ENUM, RISK_METRIC_ENUM, VARIABLES_FILTER_ENUM } from './types';

export default [
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.BACKTEST_ATTRIBUTION_HOLDINGS_TABLE,
    title: 'Holdings Decomposition',
    form: true,
    defaultParams: {
      attribution_type: 'holdings',
      histories: '$context_histories',
      variables: { type: ts.enums.EXPLANATORY_VARIABLE_TYPES_ENUM.RISK_FACTORS },
      variable_filter: VARIABLES_FILTER_ENUM.NONE,
    } as DraftParams,
    clearInteractionOnRegenerate: ['table_dtypes'],
    neededFiles: ['backtest_portan_details', 'backtest_portan_summary'],
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.BACKTEST_PORTAN],

    validate,
    subtitle: 'Workbook',
    helpLabel: 'jmj6o8cl',
  },
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.BACKTEST_ATTRIBUTION_RETURNS_TABLE,
    title: 'Returns Decomposition',
    form: true,
    defaultParams: {
      attribution_type: 'returns',
      histories: '$context_histories',
      buckets: { type: ts.enums.BUCKET_TYPES_ENUM.QUANTILES, value: 5 },
      variables: { type: ts.enums.EXPLANATORY_VARIABLE_TYPES_ENUM.RISK_FACTORS },
      variable_filter: VARIABLES_FILTER_ENUM.NONE,
    } as DraftParams,
    clearInteractionOnRegenerate: ['table_dtypes'],
    neededFiles: ['backtest_portan_details', 'backtest_portan_summary'],
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.BACKTEST_PORTAN],

    validate,
    subtitle: 'Workbook',
    helpLabel: 'kv6vprwh',
  },
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.BACKTEST_ATTRIBUTION_RISK_DECOMPOSITION_TABLE,
    title: 'Risk Decomposition',
    form: true,
    defaultParams: {
      attribution_type: 'risk',
      histories: '$context_histories',
      selectedType: 'table',
      buckets: { type: ts.enums.BUCKET_TYPES_ENUM.QUANTILES, value: 5 },
      variables: { type: ts.enums.EXPLANATORY_VARIABLE_TYPES_ENUM.RISK_FACTORS },
      variable_filter: VARIABLES_FILTER_ENUM.NONE,
      risk_metric: RISK_METRIC_ENUM.TOTAL,
      fractional_risk: FRACTIONAL_RISK_ENUM.FRACTION,
    } as DraftParams,
    clearInteractionOnRegenerate: ['table_dtypes'],
    neededFiles: ['backtest_portan_details', 'backtest_portan_summary'],
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.BACKTEST_PORTAN],

    validate,
    subtitle: 'Workbook',
    helpLabel: '7y8e52wj',
  },
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.PORTFOLIO_ATTRIBUTION_HOLDINGS_TABLE,
    title: 'Holdings Decomposition',
    form: true,
    defaultParams: {
      attribution_type: 'holdings',
      variables: { type: ts.enums.EXPLANATORY_VARIABLE_TYPES_ENUM.RISK_FACTORS },
      variable_filter: VARIABLES_FILTER_ENUM.NONE,
      histories: '$context_histories',
    } as DraftParams,
    neededFiles: ['portfolio_portan_details', 'portfolio_portan_summary'],
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.PORTFOLIO_ATTRIBUTION],

    validate,
    subtitle: 'Workbook',
    helpLabel: '8jam91ag',
  },
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.PORTFOLIO_ATTRIBUTION_RETURNS_TABLE,
    title: 'Returns Decomposition',
    form: true,
    defaultParams: {
      attribution_type: 'returns',
      histories: '$context_histories',
      variables: { type: ts.enums.EXPLANATORY_VARIABLE_TYPES_ENUM.RISK_FACTORS },
      variable_filter: VARIABLES_FILTER_ENUM.NONE,
    } as DraftParams,
    neededFiles: ['portfolio_portan_details', 'portfolio_portan_summary'],
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.PORTFOLIO_ATTRIBUTION],

    validate,
    subtitle: 'Workbook',
    helpLabel: 'zrdm4ie9',
  },
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.PORTFOLIO_ATTRIBUTION_RISK_DECOMPOSITION_TABLE,
    title: 'Risk Decomposition',
    form: true,
    defaultParams: {
      attribution_type: 'risk',
      histories: '$context_histories',
      variables: { type: ts.enums.EXPLANATORY_VARIABLE_TYPES_ENUM.RISK_FACTORS },
      variable_filter: VARIABLES_FILTER_ENUM.NONE,
      risk_metric: RISK_METRIC_ENUM.TOTAL,
      fractional_risk: FRACTIONAL_RISK_ENUM.FRACTION,
    } as DraftParams,
    neededFiles: ['portfolio_portan_details', 'portfolio_portan_summary'],
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.PORTFOLIO_ATTRIBUTION],

    validate,
    subtitle: 'Workbook',
    helpLabel: 't70022wv',
  },
] satisfies ts.types.analysis.WidgetConfig[];
