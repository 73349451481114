import { ts } from '_core';

import { validate } from './helpers';
import { DraftParams, INTERACTION_PARAMS } from './types';

export default [
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.ALPHA_MODEL_LINEAR_PERCENT_SIGNIFICANCE,
    title: 'Variable Percent Significance',
    helpLabel: '9almm74g',
    defaultParams: { critical_pvalue: 0.05 } as DraftParams,
    interactionParams: Object.values(INTERACTION_PARAMS),
    form: true,
    tags: [
      ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.ALPHA_MODEL,
      ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.LINEAR_ALPHA_MODEL,
    ],
    neededFiles: ['linear_alpha_model_critical_pvalue'],

    validate,
  },
] satisfies ts.types.analysis.WidgetConfig[];
