import React from 'react';

import { UploadContextProvider as UploadContextProviderType } from './holdings-types';
import * as utils from './utils';
import * as enums from '../../enums';
import { _, useSelector } from '../../libs';
import * as types from '../../types';

type ProviderProps = UploadContextProviderType & {
  children: React.ReactNode;

  open: boolean;
  setOpenModal: (_v: boolean) => void;
};

type UploadContextTypes = {
  open: boolean;
  setOpen: (_open: boolean) => void;

  step: enums.HOLDINGS_UPLOAD_STEPS_ENUM;
  setStep: (_step: enums.HOLDINGS_UPLOAD_STEPS_ENUM) => void;

  errorMessage: string;
  setErrorMessage: (_error: string) => void;

  fileData: string;
  setFileData: (_error: string) => void;

  holdingsId: number;
  setHoldingsId: (_rId: number) => void;

  availableColumns: string[];

  mappedColumns: Record<string, string>;
  setMappedColumns: (_mapCol: Record<string, string>) => void;

  holdingsData: types.rebalance.HoldingsData;
  holdingsColumns: { title: string; field: string }[];

  processUpload: (_file: File) => void;
  handleUploadData: (_col: Record<string, string>) => Promise<void>;
  handleCreateHoldings: (_skip?: boolean) => Promise<void>;
  handleSubmitHoldings: (_holdingsData: types.rebalance.HoldingsData) => Promise<void>;

  columnsToMap: string[];
};

const UploadContext = React.createContext<UploadContextTypes>(null);

// This context provider is passed to any component requiring the context
const HoldingsFormProvider: React.FC<ProviderProps> = ({
  createHoldings,
  open,
  setOpenModal,
  defaultMappedColumns,
  confirmStepCallback,
  children,
}): React.ReactElement => {
  const { authToken } = useSelector((state: types.BaseStore) => state.auth);

  const HOLDINGS_UPLOAD_STEPS_ENUM = enums.HOLDINGS_UPLOAD_STEPS_ENUM;

  const [step, setStep] = React.useState(HOLDINGS_UPLOAD_STEPS_ENUM.HOLDINGS_CREATION);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [fileData, setFileData] = React.useState('');
  const [fileName, setFileName] = React.useState('');

  const [availableColumns, setAvailableColumns] = React.useState([]);
  const [mappedColumns, setMappedColumns] = React.useState<Record<string, string>>(defaultMappedColumns || {});

  const [holdingsId, setHoldingsId] = React.useState<number>();
  const [holdingsData, setHoldingsData] = React.useState<types.rebalance.HoldingsData>([]);
  const [holdingsColumns, setHoldingsColumns] = React.useState<{ title: string; field: string }[]>([]);

  const columnsToMap = Object.values(enums.BACKTEST_HOLDINGS_MAP_COLS);

  React.useEffect(() => {
    if (fileData) {
      const columnNames = utils.getAvailableColumns(fileData);

      const filledColumns = {} as Record<string, string>;

      // We sort so we can map the columns with the most characters fit
      const sortedColumnsToMap = columnsToMap.sort((a, b) => b.length - a.length);
      columnNames.forEach((col) => {
        const lowerCaseCol = _.lowerCase(col);

        let mappedCol = '';
        if (!_.isEmpty(mappedColumns)) {
          mappedCol = _.findKey(mappedColumns, (val) => val == col);
        }

        if (!mappedCol) {
          mappedCol = sortedColumnsToMap.find((c) => lowerCaseCol.includes(c.replace('_', ' ')));
        }

        if (mappedCol) {
          filledColumns[mappedCol] = col;
        }
      });

      setMappedColumns(filledColumns);
      setAvailableColumns(columnNames);
    }
  }, [fileData]);

  const clearStates = () => {
    setErrorMessage('');
    setFileData('');
    setAvailableColumns([]);
    setMappedColumns(defaultMappedColumns || {});
    setHoldingsId(null);
    setHoldingsData([]);
    setHoldingsColumns(null);
  };

  const setOpen = (openLocal: boolean) => {
    setOpenModal(openLocal);
    if (!openLocal) {
      clearStates();
      setStep(HOLDINGS_UPLOAD_STEPS_ENUM.HOLDINGS_CREATION);
    }
  };

  const handleUploadData = (columns: Record<string, string>) =>
    utils.apiProcedures.uploadData(
      holdingsId,
      columns,
      fileData,
      authToken,
      (data, dataColumns) => {
        dataColumns = _.uniq(['error_message', ...dataColumns]);
        setHoldingsData(data);
        setHoldingsColumns(
          dataColumns.map((col) => ({
            title: _.startCase(col.replace('finsera', 'mapped')),
            field: col,
          }))
        );

        setStep(HOLDINGS_UPLOAD_STEPS_ENUM.HOLDINGS_CONFIRM);
      },
      (error) => setErrorMessage(error)
    );

  const handleSubmitHoldings = async (holdingsData: types.rebalance.HoldingsData) => {
    await utils.apiProcedures.submitHoldings(
      holdingsId,
      holdingsColumns,
      holdingsData,
      () => {
        setOpen(false);

        setStep(HOLDINGS_UPLOAD_STEPS_ENUM.HOLDINGS_CREATION);
        confirmStepCallback(mappedColumns, fileName, holdingsId, false);
      },
      (err) => setErrorMessage(err),
      authToken
    );
  };

  const handleCreateHoldings = async (skipToLastStep = false) => {
    await createHoldings((rId) => {
      setHoldingsId(rId);
      if (skipToLastStep) setStep(HOLDINGS_UPLOAD_STEPS_ENUM.HOLDINGS_CONFIRM);
      else setStep(enums.HOLDINGS_UPLOAD_STEPS_ENUM.MAPPING_COLUMNS);
    });
  };

  const processUpload = (file: File) => {
    setFileName(file.name);

    const fr = new FileReader();

    fr.readAsArrayBuffer(file as Blob);

    fr.onload = () => {
      const buffer = new Uint8Array(fr.result as ArrayBufferLike);
      const fileData = new TextDecoder('utf-8').decode(buffer).replace('\r', '');
      setFileData(fileData);
    };
  };

  return (
    <UploadContext.Provider
      value={{
        open,
        setOpen,

        step,
        setStep,

        errorMessage,
        setErrorMessage,

        fileData,
        setFileData,

        holdingsId,
        setHoldingsId,

        availableColumns,

        mappedColumns,
        setMappedColumns,

        holdingsData,
        holdingsColumns,

        processUpload,
        handleUploadData,
        handleCreateHoldings,
        handleSubmitHoldings,

        columnsToMap,
      }}
    >
      {children}
    </UploadContext.Provider>
  );
};

const UploadContextProvider = HoldingsFormProvider;

export { UploadContext };
export { UploadContextProvider };
