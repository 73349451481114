import { actions, api, mui, React, ts, ui, useDispatch } from '_core';

type ConfirmDeleteModalProps = {
  open: boolean;
  onClose: () => void;
  defaultEl: ts.types.analysis.ReportDefault;
};

const ConfirmDeleteModal: React.FC<ConfirmDeleteModalProps> = ({ open, onClose, defaultEl }) => {
  const dispatch = useDispatch();
  const [alert, setAlert] = React.useState<string>();
  const [title, setTitle] = React.useState<string>();
  const [removing, setRemoving] = React.useState(false);

  React.useEffect(() => {
    if (defaultEl) {
      setTitle(defaultEl.title);
    }
  }, [open]);

  const removeEl = async (localDefaultEl: typeof defaultEl) => {
    setRemoving(true);
    try {
      await api.report_defaults.remove(localDefaultEl.id);
      dispatch(actions.resources.deleteReportDefault(localDefaultEl));
      onClose();
      setRemoving(false);
    } catch (e) {
      setAlert('Unable to delete the layout.');
      setRemoving(false);
    }
  };

  return (
    <mui.core.Dialog open={open} onClose={onClose} fullWidth>
      {alert && <mui.lab.Alert severity={'error'}>{alert}</mui.lab.Alert>}
      <mui.core.DialogContent>
        <mui.core.Box p={4} textAlign="center">
          <mui.core.Typography variant="h5">
            Are you sure you want to delete <b>{title}</b> layout?
          </mui.core.Typography>
        </mui.core.Box>
        <mui.core.Box py={3} display="flex" justifyContent="center">
          <mui.core.Box mr={3}>
            <ui.Button
              variant="contained"
              color="primary"
              onClick={() => removeEl(defaultEl)}
              loading={removing}
              disabled={removing}
            >
              Delete this layout
            </ui.Button>
          </mui.core.Box>
          <ui.Button variant="outlined" color="inherit" onClick={onClose} disabled={removing}>
            Cancel
          </ui.Button>
        </mui.core.Box>
      </mui.core.DialogContent>
    </mui.core.Dialog>
  );
};

export default ConfirmDeleteModal;
