import { _ } from '../libs';
import { TableData } from '../types/widgets';

const getSignalDef = (idx: string, def: Record<string, any>, sep = ' - '): string => {
  let catName = def.signals[idx].signal;

  if (def.signals[idx].pipeline) catName += `${sep}${def.signals[idx].pipeline}`;
  if (def.signals[idx].sp_pipeline) catName += `${sep}${def.signals[idx].sp_pipeline}`;

  return catName;
};

const signalVarCategoryName = (data: TableData, definition: Record<string, any>) => {
  const newData = data.map((el) => {
    const newEl = { ...el };
    if ((el.category as string).includes('var')) {
      const idx = (el.category as string).replace('var', '');
      newEl.category = getSignalDef(idx, definition, ' + ');
    } else newEl.category = 'Intercept';
    return newEl;
  });

  return newData;
};

const signalVarName = (data: Record<string, any>, definition: Record<string, any>) => {
  const newData = { ...data };

  Object.keys(data).forEach((key) => {
    const splittedKey = key.split('|');
    let signalKey = splittedKey[0];
    if (splittedKey.length > 1) signalKey = splittedKey[1];

    if (signalKey.includes('var')) {
      const idx = signalKey.replace('var', '');
      const newKey = getSignalDef(idx, definition, '|');
      let jointKey = '';

      if (splittedKey[0] != signalKey) {
        jointKey = `${splittedKey[0]}|${newKey}`;
      } else {
        jointKey = newKey;
      }
      newData[jointKey] = newData[key];
    } else {
      newData[`${splittedKey[0]}|${_.startCase(splittedKey[1])}`] = newData[key];
    }
    delete newData[key];
  });

  return newData;
};

const getEquationStr = (m: number, b: number) => {
  let equation = '';

  if (m) equation += `${_.round(m, 3)} x`;
  if (m && b) equation += b < 0 ? ' - ' : ' + ';
  if (b) equation += _.round(Math.abs(b), 3);

  return equation;
};

const signalVarNameUniq = (signals: Record<string, any>[]): string[] => {
  const nameCandidatesCount = {} as Record<string, number>;
  signals.forEach((ds: any) => {
    nameCandidatesCount?.[ds.signal] ? (nameCandidatesCount[ds.signal] += 1) : (nameCandidatesCount[ds.signal] = 1);

    nameCandidatesCount?.[ds.signal + ' : ' + ds.pipeline]
      ? (nameCandidatesCount[ds.signal + ' : ' + ds.pipeline] += 1)
      : (nameCandidatesCount[ds.signal + ' : ' + ds.pipeline] = 1);
  });

  return signals.map((ds: any) => {
    const nameCandidates = [ds.signal];

    if (ds.pipeline && nameCandidatesCount[ds.signal] > 1) nameCandidates.push(ds.pipeline);
    if (nameCandidatesCount[ds.signal + ' : ' + ds.pipeline] > 1) nameCandidates.push(ds.sp_pipeline);

    return nameCandidates.filter(Boolean).join(' : ');
  });
};

export { signalVarCategoryName };
export { signalVarName };
export { getSignalDef };
export { getEquationStr };
export { signalVarNameUniq };
