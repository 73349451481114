import { _, helpers, moment, ts } from '_core';

import { DraftParams } from './types';

export const prepareReturnsAndWeightsChart = (
  data: ts.types.widgets.TableData,
  signals: ts.types.signal.AlphaSignalEnhanced[]
) => {
  const varNames = helpers.buildName.signalVarNameUniq(signals);
  const newData = {} as Record<string, ts.types.widgets.TableData[0]>;
  Object.keys(data?.[0] || {}).forEach((key) => {
    if (key !== 'build_date') {
      const val = data[0][key];
      const splitted = key.split('|');
      const varName = varNames[Number(splitted[1].replace('var', ''))];
      newData[splitted[1]] = {
        ...(newData?.[splitted[1]] || {}),
        [splitted[0]]: val,
        variable: varName,
      };
    }
  });
  return Object.values(newData);
};

export const prepareCovariancesChart = (
  data: ts.types.widgets.TableData,
  signals: ts.types.signal.AlphaSignalEnhanced[]
) => {
  const varNames = helpers.buildName.signalVarNameUniq(signals);
  return data.map((row) => {
    const varName1 = varNames[Number((row.factor_x as string).replace('var', ''))];
    const varName2 = varNames[Number((row.factor_y as string).replace('var', ''))];
    return {
      ...row,
      factor_x: varName1,
      factor_y: varName2,
    };
  });
};

export const validate = (params: DraftParams, context: ts.types.signal.SignalAnalysisContext) => {
  const date = params.start_date || params.end_date;
  if (_.isEmpty(date)) return ['Date is a required field.'];
  if (context) {
    if (moment(date).isBefore(moment(context.start_date))) return ['Date must be inside dataset dates.'];
    if (moment(date).isAfter(moment(context.end_date))) return ['Date must be inside dataset dates.'];
  }
  return [];
};
