/* eslint-disable max-len */

import React from 'react';

import api from '../api';
import { gibberishGenerator } from '../helpers';
import { useSelector } from '../libs';
import * as types from '../types';
import { BaseStore } from '../types';

const defaultParentStates = { names: null, states: null } as unknown as {
  names: string[];
  states: React.MutableRefObject<any[]>;
};

/**
 * A custom profiler component to measure and analyze the performance of a component (between mount and unmount)
 *
 * @param profilerKey - A unique key for the profiler.
 * @param children - The content to be profiled.
 * @param parentStates - An object containing names and states of the parent component.
 * @param persist - Whether to persist on the db or not. This will only be persisted if we are authenticated and not on localhost.
 * @param logOnConsole - Whether to log the data on console or not.
 */

const CustomProfiler: React.FC<{
  profilerKey: types.performanceLogs.ProfilerKeyType;
  children?: React.ReactNode;
  parentStates?: { names: string[]; states: React.MutableRefObject<any[]> };
  persist?: boolean;
  logOnConsole?: boolean;
}> = ({ profilerKey, children, parentStates = defaultParentStates, persist = false, logOnConsole = false }) => {
  // If user has been authenticated ref
  const authenticated = useSelector((state: BaseStore) => state.auth?.verified);
  const authenticatedRef = React.useRef(authenticated);
  authenticatedRef.current = authenticated;

  // Save latest profilerkey value in case that the key is dynamic. We only keep the last one and override
  // previous logs
  const profilerKeyRef = React.useRef(profilerKey);
  profilerKeyRef.current = profilerKey;

  // Save reference of the parentState values to store on each log update
  const parentStatesRef = React.useRef(parentStates);
  parentStatesRef.current = parentStates;

  const profilerLogs = React.useRef([] as types.performanceLogs.Log[]);
  const startTimeRef = React.useRef(new Date());
  const groupKey = React.useRef(gibberishGenerator.stringGenerator(11));

  const getDuration = () => {
    const currentDate = new Date();
    const duration = (currentDate as any) - (startTimeRef.current as any);
    startTimeRef.current = currentDate;

    return duration / 1_000;
  };

  const pushTotal = () => {
    const total = profilerLogs.current.reduce(
      (prev, current) => ({
        ...prev,
        ...current,
        duration: prev.duration + current.duration,
        phase: 'total',
        profiler_interactions: null,
        parent_states: null,
      }),
      { duration: 0 } as types.performanceLogs.Log
    );
    profilerLogs.current.push(total);
  };

  const pushLog = (phase: 'mount' | 'unmount' | 'update' | 'total', interactions?: string) => {
    profilerLogs.current.push({
      key: profilerKeyRef.current,
      group_key: groupKey.current,
      duration: getDuration(),
      phase,
      profiler_interactions: interactions ?? null,
      parent_states: Object.fromEntries(
        (parentStatesRef.current.names || []).map((_x, idx) => [
          [parentStates.names[idx]],
          parentStates.states.current[idx],
        ])
      ),
    });
  };

  const persistCall = (logsToPersist: types.performanceLogs.Log[]) => {
    if (!authenticatedRef.current) return;
    if (location.href.includes('localhost')) return;

    try {
      api.combined.run({
        ui_performance_logs: { create: logsToPersist },
      });
    } catch {
      // Not logged in yet, so nothing will be persisted
    }
  };

  React.useEffect(() => {
    return () => {
      pushLog('unmount');
      pushTotal();

      // Update latest profiler key. This is required because we could have keys that are finally set at unmount
      const finalLogs = profilerLogs.current.map((l) => ({
        ...l,
        key: profilerKeyRef.current,
      }));

      if (persist) persistCall(finalLogs);
      if (logOnConsole) console.table(finalLogs);
    };
  }, []);

  const profilerLog = (
    _id: string,
    phase: 'mount' | 'update',
    _actualDuration: number,
    _baseDuration: number,
    _startTime: number,
    _commitTime: number,
    interactions: Set<any>
  ) => {
    pushLog(phase, JSON.stringify(interactions));
  };

  return (
    <React.Profiler id={profilerKey} onRender={profilerLog as any}>
      {children}
    </React.Profiler>
  );
};

export default CustomProfiler;
