import { ts } from '_core';

import { validate } from './helpers';
import { DraftParams } from './types';

export default [
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.ALPHA_MODEL_OPTIMIZED_RISK_MODEL_SEARCH,
    title: 'Risk Aversion Search',
    helpLabel: 'sgqbhg3x',
    defaultParams: {
      log: false,
      histories: ['1 Year', '3 Years', '5 Years', 'Full'],
      lower: 0,
      format_for_ui: true,
    } as DraftParams,
    form: true,
    neededFiles: ['risk_aversion_grid_search'],
    tags: [
      ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.ALPHA_MODEL,
      ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.OPTIMIZED_ALPHA_MODEL,
    ],
    validate,
  },
] satisfies ts.types.analysis.WidgetConfig[];
