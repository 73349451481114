import { _, config, hooks, mui, React, ts, useSelector } from '_core';

import { Avatar, Box } from '@mui/material';

import CodeBlock from './code-block';
import MessageAttachments from './message-attachments';
import TextBox from './text-box';
import { AIAssistantContext } from '../ai-assistant-context';

const Message: React.FC<{
  message: ts.types.aiAssistant.MessageDraft;
  loading?: boolean;
  error?: boolean;
  animate?: boolean;
  onfinish?: () => void;
}> = ({ message, loading, error, animate, onfinish }) => {
  const conversationContext = React.useContext(AIAssistantContext);
  const { scrollToTheBottom } = conversationContext;

  const theme = mui.styles.useTheme() as mui.core.Theme;
  const userChar = useSelector((state) => state.auth.currentUser.name.charAt(0));

  const displayText = hooks.useTypingText(
    message.content.text,
    !animate || message.role !== 'assistant',
    message.completed,
    onfinish,
    15
  );

  React.useEffect(() => {
    scrollToTheBottom();
  }, [displayText]);

  const botIcon = () => (
    <Avatar
      sx={{
        width: '24px',
        height: '24px',
        background: '#fff',
        border: `1px solid ${theme.palette.gray.light}`,
      }}
    >
      <img
        src={config.customUrl + '/custom/finsera-logo.png'}
        alt="Finsera logo"
        style={{
          width: '70%',
          height: '70%',
          objectFit: 'contain',
        }}
      />
    </Avatar>
  );

  const userIcon = () => (
    <Avatar
      sx={{
        width: '24px',
        height: '24px',
        fontSize: '12px',
        fontWeight: 'bold',
        backgroundColor: theme.palette.success.main,
      }}
    >
      {userChar}
    </Avatar>
  );

  const iconBox = (role: 'user' | 'assistant') => (
    <Box mr={role == 'assistant' ? 1 : undefined} sx={{ display: 'flex', pt: '0.33rem' }}>
      {isUserMessage ? userIcon() : botIcon()}
    </Box>
  );

  const isUserMessage = message.role === 'user';
  const isFunction = message.purpose === 'action';

  const getPartitions = React.useCallback((text: string) => {
    const indexes = Array.from(text.matchAll(new RegExp('```', 'g')), (match) => match.index);
    return _.zip([0, ...indexes], [...indexes, text.length])
      .map(([init, end]) => text.substring(init, end))
      .reduce(
        ([acc_arr, in_code]: [{ type: string; text: string }[], boolean], text: string) => {
          if (text.startsWith('```') && !in_code) {
            acc_arr.push({ type: 'code' as any, text: text.replace(/```.*$/gm, '') });
            return [acc_arr, !in_code] as [{ type: string; text: string }[], boolean];
          }

          if (text.startsWith('```') && in_code) {
            acc_arr.push({ type: 'text' as any, text: text.replace('```', '') });
            return [acc_arr, !in_code] as [{ type: string; text: string }[], boolean];
          }

          acc_arr.push({ type: 'text' as any, text });
          return [acc_arr, false] as [{ type: string; text: string }[], boolean];
        },
        [[], false]
      )[0]
      .map((el) => ({ ...el, text: _.trim(el.text) }));
  }, []);

  const boxMessages = React.useMemo(() => {
    return getPartitions(displayText).filter((el) => el.text);
  }, [displayText]);

  const renderMessageText = () => {
    if (isUserMessage)
      return (
        <>
          {iconBox('user')}
          <TextBox message={message} loading={false} />
        </>
      );

    if (isFunction) return <TextBox message={message} loading={false} />;

    if (loading)
      return (
        <>
          {iconBox('assistant')}
          <TextBox message={message} loading={true} />
        </>
      );

    return (
      <>
        {iconBox('assistant')}
        <mui.core.Box display="flex" sx={{ width: '85%' }} flexDirection={'column'}>
          {boxMessages.map((box, idx) => (
            <mui.core.Box key={idx}>
              {box.type == 'code' ? (
                <CodeBlock message={{ ...message, content: { ...message.content, text: box.text } }} maxWidth="100%" />
              ) : (
                <TextBox
                  message={{ ...message, content: { ...message.content, text: box.text } }}
                  loading={loading}
                  error={error}
                  maxWidth="100%"
                />
              )}
            </mui.core.Box>
          ))}
          <MessageAttachments message={message} />
        </mui.core.Box>
      </>
    );
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: isFunction ? 'center' : 'flex-start',
        alignItems: 'flex-start',
        margin: '8px',
        marginBottom: '10px',
        gap: '0.8rem',
      }}
    >
      {renderMessageText()}
    </Box>
  );
};

export default Message;
