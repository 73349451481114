import { ts } from '_core';

import { validate } from './helpers';
import { DraftParams, INTERACTION_PARAMS } from './types';

export default [
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.BASKET_CUMULATIVE_RETURNS_WITH_TIME_SERIES,
    title: 'Cumulative Returns With Time Series',
    form: true,
    helpLabel: 'nm1adf0z',
    defaultParams: {
      isBasket: true,
      type: 'cumulative',
      add_zero: true,
    } as DraftParams,
    interactionParams: Object.values(INTERACTION_PARAMS),
    neededFiles: ['backtest_returns'],
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.BASKET],

    validate,
  },
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.BACKTEST_CUMULATIVE_RETURNS_WITH_TIME_SERIES,
    title: 'Cumulative Returns With Time Series',
    helpLabel: 'p7i5xkgv',
    form: true,
    defaultParams: {
      isBasket: false,
      type: 'cumulative',
      add_zero: true,
    } as DraftParams,
    interactionParams: Object.values(INTERACTION_PARAMS),
    neededFiles: ['backtest_returns'],
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.BACKTEST],

    validate,
  },
] satisfies ts.types.analysis.WidgetConfig[];
