import { _, actions, api, config, helpers, mui, React, ts, ui,useDispatch } from '_core';

import getOptions from './template-options';
import ReportManager from '../../report-manager';

type EditModalProps = {
  defaultEl: ts.types.analysis.ReportDefault;
  onClose: () => void;
  layoutType: ts.enums.LAYOUT_TYPE;
};

const EditModal: React.FC<EditModalProps> = ({ defaultEl, onClose, layoutType }) => {
  const theme = mui.styles.useTheme() as mui.core.Theme;
  const dispatch = useDispatch();

  const [step, setStep] = React.useState(0);
  const [title, setTitle] = React.useState(defaultEl?.title || '');
  const [error, setError] = React.useState<string>();

  const save = async (def: ts.types.analysis.Widget[], mode: ts.enums.ANALYSIS_MODE_ENUM) => {
    try {
      const newDefault = {
        title,
        definition: { widgets: def, mode },
        type: layoutType,
      };

      let resp;
      if (defaultEl?.id) resp = await api.report_defaults.update(defaultEl.id, newDefault);
      else resp = await api.report_defaults.create(newDefault);
      // store in redux and update ui
      dispatch(actions.resources.updateReportDefault(resp.data));

      onClose();
    } catch (e) {
      setError(helpers.parseApiError(e as ts.types.common.ApiError));
    }
  };

  const templateOptions = React.useMemo(() => getOptions(config.features), []);

  const layoutInfo = React.useMemo(() => templateOptions.find((opt) => opt.layout === layoutType), [layoutType]);

  return (
    <mui.core.Dialog
      open
      onClose={onClose}
      maxWidth={step == 0 ? 'sm' : 'md'}
      sx={
        step == 1 && {
          '& .MuiDialog-paper': {
            height: '100%',
          },
        }
      }
      fullWidth
    >
      <ui.DialogTitle closeAction={onClose}>Create/Edit Report Layout</ui.DialogTitle>
      {error && (
        <mui.lab.Alert onClose={() => setError(null)} severity="error">
          {error}
        </mui.lab.Alert>
      )}
      {step == 0 && (
        <mui.core.DialogContent>
          <mui.core.Box py={3}>
            <mui.core.Box mb={3}>
              <mui.core.TextField
                autoFocus
                id="name"
                label="Default title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                fullWidth
                variant="outlined"
                required
                inputProps={{
                  autocomplete: 'off',
                }}
              />
            </mui.core.Box>
            <ui.Button
              variant="contained"
              color="primary"
              onClick={() => setStep(step + 1)}
              fullWidth
              disabled={_.isEmpty(title)}
            >
              Next
            </ui.Button>
          </mui.core.Box>
        </mui.core.DialogContent>
      )}
      {step == 1 && (
        <mui.core.Box flex="1" style={{ background: theme.palette.background.default }}>
          <ReportManager
            definition={(defaultEl?.definition?.widgets as ts.types.analysis.Widget[]) || []}
            definitionMode={defaultEl?.definition?.mode || ts.enums.ANALYSIS_MODE_ENUM.GRID}
            updateDefinition={save}
            layoutType={layoutType}
            tags={layoutInfo.tags}
            onlyFullscreen={layoutInfo.onlyFullscreen}
            noDefault
            maxWidgetsPerPage={config.maxWidgetsPerPage}
          />
        </mui.core.Box>
      )}
    </mui.core.Dialog>
  );
};

export default EditModal;
