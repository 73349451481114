import { helpers, ts } from '_core';

export const getNewStrategy = (
  name: string,
  handle: string,
  type: ts.types.strategy.Strategy['type'],
  optimizerType: ts.types.optimizer.OptimizerConfig['optimizer_type'] = 'CVXPY',
  template: ts.types.strategy.Strategy = null
) => {
  const nullBasketDefinition: ts.types.strategy.DefinitionDraft = {
    source_type: 'universe',
    conditions: null,
    ranking_measure: null,
    initial_value: 100000000,
    tcost_multiplier: 1,
    must_complete: true,
    optimizer_rebalance: true,
    minimum_weight: 1e-4,
    return_lag_days: 0,
    benchmark: { type: 'BASKET' },
    tcost_strategy: 'None',
    frequency: ts.enums.FREQUENCY_ENUM.MONTHLY,
    weights: 'MARKET_CAP',
    optimizer_config: {
      optimizer_type: optimizerType,
      objective_function: {
        id: helpers.gibberishGenerator.stringGenerator(11),
        mean_variance_type: 'min_risk_only',
        tcost_multiplier: 0.00000001,
        benchmark_relative: true,
        lambda_: optimizerType == 'CVXPY' ? 2.0 : 1 / 100,
      },
      constraints: [
        {
          constraint_type: ts.enums.OPTIMIZER_CONSTRAINT_TYPE_ENUM.GROSS_LEVERAGE_CONSTRAINT,
          id: helpers.gibberishGenerator.stringGenerator(11),
          lower_bound: 1,
          upper_bound: 1,
          relax_order: 'NEVER',
          target_penalty: 1,
        },
        {
          constraint_type: ts.enums.OPTIMIZER_CONSTRAINT_TYPE_ENUM.ALL_ASSETS_CONSTRAINT,
          id: helpers.gibberishGenerator.stringGenerator(11),
          type: 'AllAssets',
          relax_order: 'NEVER',
          lower_bound: 0,
          upper_bound: 1,
          benchmark_relative: false,
        } as ts.types.optimizer.AssetAll,
      ],
      keep_all_linked_assets: true,
    },
    enable_trade_lists: true,
    round_lot_definition: {
      fixed_round_lot: 1,
    },
  };

  const nullAlphaDefinition: ts.types.strategy.DefinitionDraft = {
    optimizer_rebalance: true,
    minimum_weight: 1e-4,
    tcost_strategy: 'None',
    optimizer_config: {
      optimizer_type: optimizerType,
      objective_function: {
        id: helpers.gibberishGenerator.stringGenerator(11),
        mean_variance_type: 'default',
        tcost_multiplier: null,
        benchmark_relative: false,
        lambda_: optimizerType == 'CVXPY' ? 2.0 : 1 / 100,
      },
      constraints: [
        {
          constraint_type: ts.enums.OPTIMIZER_CONSTRAINT_TYPE_ENUM.GROSS_LEVERAGE_CONSTRAINT,
          id: helpers.gibberishGenerator.stringGenerator(11),
          lower_bound: 1.98,
          upper_bound: 2.02,
          relax_order: 'NEVER',
          target_penalty: 1,
        },
        {
          constraint_type: ts.enums.OPTIMIZER_CONSTRAINT_TYPE_ENUM.NET_LEVERAGE_CONSTRAINT,
          id: helpers.gibberishGenerator.stringGenerator(11),
          lower_bound: -0.01,
          upper_bound: 0.01,
          relax_order: 'NEVER',
          target_penalty: 1,
        },
      ],
      keep_all_linked_assets: true,
    },
    enable_trade_lists: true,
    round_lot_definition: {
      fixed_round_lot: 1,
    },
  };

  const definition: ts.types.strategy.DefinitionDraft =
    type == 'ALPHA_MODEL' ? nullAlphaDefinition : type == 'BASKET' ? nullBasketDefinition : {};

  // Check if we will create the strategy with a premade definition
  const newStrategy = {
    name,
    handle,
    definition,
    is_valid: false,
    type,
  } as ts.types.strategy.StrategyDraft;

  if (template && template.id) {
    newStrategy.definition = template.definition;
    newStrategy.is_valid = template.is_valid;
    newStrategy.type = template.type;
    newStrategy.label_ids = template.label_ids;
  }

  return { ...newStrategy } as ts.types.strategy.Strategy;
};

export const getArchivedDeps = (
  strategy: ts.types.strategy.Strategy,
  resources: ts.StoreState['resources']
): ts.types.common.ArchivedDeps => {
  const deps = helpers.resourceGraph.getDeps(
    { ...strategy, resourceType: ts.enums.RESOURCES_TYPES_ENUM.STRATEGY },
    resources
  );
  return helpers.resourceGraph.getArchiveDeps(deps);
};
