import { ts } from '_core';


export default [
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.RISK_MODEL_MEDIAN_ANNU_VOLATILITY_VS_TIME,
    title: 'Median Annualized Asset Volatility Vs. Time',
    helpLabel: '1k91fa5c',
    form: false,
    neededFiles: ['median_annualized_volatility'],
    tags: [
      ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.RISK_MODEL,
      ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.COMMON_RISK_MODEL,
      ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.STATISTICAL_RISK_MODEL,
    ],
  },
] satisfies ts.types.analysis.WidgetConfig[];
