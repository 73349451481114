import { helpers, hooks, mui, React, ts } from '_core';

import RiskModelCategory from './factors/risk-model-category';
import RiskModelStyle from './factors/risk-model-style';

type FactorComponentProps = {
  factorType: ts.types.widgets.common.FACTOR_TYPE_ENUM;
  factor: ts.types.widgets.common.RiskFactorsType[];
  setFactor: (_value: ts.types.widgets.common.RiskFactorsType[]) => void;
  styleOptions: string[];
};

const FactorComponent: React.FC<FactorComponentProps> = ({
  factorType,
  factor,
  setFactor,
  styleOptions,
}): React.ReactElement => {
  const uiStyles = hooks.useUiStyles();

  /*
   * All objects have an ID and they need to match if they have the same type
   * We check for an object of the same type and get its ID
   */

  const factorObject = factor.filter((f) => f.type == factorType)[0];

  const id = factorObject != undefined ? factorObject.id : helpers.gibberishGenerator.stringGenerator(11);

  const getFactorValue = () => factor.filter((f) => f.type == factorType).map((fac) => fac.value);

  const setFactorValue = (value: ts.types.widgets.common.RiskFactorsType[]) => {
    const filteredOldValues = factor.filter((f) => f.type != factorType);
    setFactor([...filteredOldValues, ...value]);
  };

  if (factorType == ts.types.widgets.common.FACTOR_TYPE_ENUM.RISK_MODEL_CATEGORY) {
    return (
      <mui.core.Box sx={uiStyles.pipelineItem}>
        <RiskModelCategory value={getFactorValue()} setValue={setFactorValue} id={id} />
      </mui.core.Box>
    );
  }
  if (factorType == ts.types.widgets.common.FACTOR_TYPE_ENUM.RISK_MODEL_STYLE) {
    return (
      <mui.core.Box sx={uiStyles.pipelineItem}>
        <RiskModelStyle value={getFactorValue()} setValue={setFactorValue} id={id} options={styleOptions} />
      </mui.core.Box>
    );
  }
  return <></>;
};

export default FactorComponent;
