import { React, ts } from '_core';

import Form from './form';
import { Params } from './types';

type CumulativeReturnsProps = {
  mode: ts.enums.CHART_MODE_ENUM;
  params: Params;
  setParams: (_p: Params) => void;
};

const CumulativeReturns: React.FC<CumulativeReturnsProps> = ({ mode, params, setParams }) => {
  return <>{mode == 'edit' ? <Form params={params} setParams={setParams} /> : <>Render</>}</>;
};

export default CumulativeReturns;
