import { _, ts } from '_core';

import { replaceNans } from '../charts/formatters/helpers';

type BucketsObjectType = {
  buckets: ts.types.widgets.common.BucketValue;
  variables?: ts.types.widgets.common.ExplanatoryVariablesValue;
};

export const getRiskDecompositionLegendName = {
  PCR: '',
  FPCR: 'Factor ',
  SPCR: 'Specific ',
};

const gicsDefaultOrder = [
  'INDUSTRY:gics_sector_desc',
  'INDUSTRY:gics_group_desc',
  'INDUSTRY:gics_ind_desc',
  'INDUSTRY:gics_subind_desc',
];

const rbicsDefaultOrder = [
  'INDUSTRY:rbics_l1_name',
  'INDUSTRY:rbics_l2_name',
  'INDUSTRY:rbics_l3_name',
  'INDUSTRY:rbics_l4_name',
];

export const getIndustryName = (value: string) => {
  const industryString = value.split('_');
  return `${_.startCase(industryString[1])}s`;
};

export const getRbicsIndustryName = (value: string) => {
  if (value.includes('rbics_l1')) return 'Sector';
  if (value.includes('rbics_l2')) return 'Sub Sector';
  if (value.includes('rbics_l3')) return 'Industry';
  if (value.includes('rbics_l4')) return 'Sub Industry';

  const industryString = value.split('_');
  return `${_.upperCase(industryString[0])} ${_.startCase(industryString[1])}`;
};

export const getBucketsNames = (obj: BucketsObjectType) => {
  return { var: obj.variables.type, buckets: `${obj.buckets.type}:${obj.buckets.value}` };
};

export const getBucketOrder = (obj: BucketsObjectType, order: string) => {
  const names = getBucketsNames(obj);

  let responseOrder: any[];
  let buckets = names.buckets;

  if (buckets?.includes('rbics')) {
    buckets = rbicsDefaultOrder.find((gd) => buckets == gd);
  }

  if (buckets[0]?.includes('gics')) {
    buckets = gicsDefaultOrder.find((gd) => buckets == gd);
  }

  if (order == 'selected_buckets') responseOrder = [buckets, 'variables'];
  else responseOrder = ['variables', buckets];

  return responseOrder;
};

export const getTitleByStatistics = (statistics: ts.enums.REPORT_ENUMS.ANNU_STATISTICS_ENUM) => {
  if (statistics == ts.enums.REPORT_ENUMS.ANNU_STATISTICS_ENUM.RETURNS) return 'Annual Return';
  if (statistics == ts.enums.REPORT_ENUMS.ANNU_STATISTICS_ENUM.RISK) return 'Annual Risk';

  return 'Annual Risk Adjusted Returns';
};

export const getBucketDefaultValue = (value: ts.enums.BUCKET_TYPES_ENUM, defaultRiskModelId?: number) => {
  if (value == ts.enums.BUCKET_TYPES_ENUM.QUANTILES) return { value: 5 };
  if (value == ts.enums.BUCKET_TYPES_ENUM.INDUSTRY) return { value: 'rbics_l1_name' };
  if (value == ts.enums.BUCKET_TYPES_ENUM.RISK_MODEL) return { risk_model_id: defaultRiskModelId };
  return {};
};

export const handleIndustryNames = (
  currentData: ts.types.widgets.TreeMapData,
  riskModelIndustries = [] as ts.types.riskModel.CategoryFactor[]
) => {
  const newData = [...currentData];
  const hasIndustries = (element: ts.types.widgets.TreeMapData) =>
    _.some(element, (el) => el?.name?.includes('industry'));

  if (hasIndustries(newData)) {
    newData.forEach((el) => {
      if (el.name.includes('industry')) {
        const industryHandle = el.name;
        const industryName = riskModelIndustries.find((el) => el.handle == industryHandle)?.name;
        if (industryName) el.name = industryName;
      }
    });
  } else {
    newData.forEach((el) => {
      const dataChildren = el.children as ts.types.widgets.TreeMapData;
      if (hasIndustries(dataChildren)) el.children = handleIndustryNames(dataChildren, riskModelIndustries);
    });
  }

  return newData;
};

export const prepareContributorsData = (currData: ts.types.widgets.TableData, nPerformers: number) => {
  const groupedData = _.groupBy(currData, 'history');
  const groupedSum = {} as Record<string, { TOP: string; BOTTOM: string }>;

  for (const history in groupedData) {
    const historyArray = groupedData[history];

    // Split the ordered rows into two arrays
    const topHalf = historyArray.slice(0, nPerformers);
    const bottomHalf = historyArray.slice(-nPerformers); // Select from the end of the array
    const topSum = topHalf.reduce((a, b) => a + ((b['pnl'] as number) || 0), 0) * 100;
    const bottomSum = bottomHalf.reduce((a, b) => a + ((b['pnl'] as number) || 0), 0) * 100;

    topHalf.forEach((row) => (row.performers = 'TOP'));

    bottomHalf.forEach((row) => (row.performers = 'BOTTOM'));
    groupedData[history] = [...topHalf, ...bottomHalf];
    groupedSum[history] = {
      TOP: topSum.toFixed(2),
      BOTTOM: bottomSum.toFixed(2),
    };
  }

  const pnlColumnValue = {} as Record<string, string>;

  Object.keys(groupedData).forEach((key) => {
    // calculate percentage of all values in pnl column
    const pnlCol = groupedData[key].map((d) => d['pnl'] as number).reduce((a, b) => a + b, 0);

    pnlColumnValue[key] = (pnlCol * 100).toFixed(2);
  });

  return { groupedData, pnlColumnValue, groupedSum };
};

export const formatName = (currentData: ts.types.widgets.TreeMapData) => {
  let newData = [...currentData];

  newData = newData.map((el) => {
    const children = el.children as ts.types.widgets.TreeMapData;

    return {
      ...el,
      name: el?.name?.toString(),
      children: children.map((el) => ({ ...el, name: el?.name.toString() })),
    };
  });

  return newData;
};

export const portanPreformat = (
  data: ts.types.widgets.TableData,
  buckets: string[],
  history: ts.enums.HISTORY_ENUM_EXTENDED
) => {
  const newData = replaceNans(data);
  let filteredData = newData.filter((el) => el.history == history);

  if (_.isEmpty(filteredData)) {
    filteredData = newData.filter((el) => el.history == ts.enums.HISTORY_ENUM_EXTENDED.FULL);
  }

  filteredData = filteredData.map((el) => ({
    [buckets[0]]: el[buckets[0]],
    [buckets[1]]: el[buckets[1]],
    holdings: el.holdings,
    returns: el.returns,
  }));

  const orderedData = filteredData.map((item) => {
    const orderedPart = _.pick(item, buckets);

    const remainingKeys = _.difference(_.keys(item), buckets);
    const remainingPart = _.pick(item, remainingKeys);

    return { ...orderedPart, ...remainingPart };
  });

  return orderedData.map((item) => {
    const updatedItem = { ...item };

    if (_.get(item, buckets[0]) === 0) {
      _.set(updatedItem, buckets[0], '');
    }

    if (_.get(item, buckets[1]) === 0) {
      _.set(updatedItem, buckets[1], '');
    }

    return updatedItem;
  });
};

export const getMaxValues = (data: ts.types.widgets.TableData, buckets: string[]): Record<string, number> => {
  const maximumValues: Record<string, number> = {};

  const tempValues = data.filter((row) => row[buckets[0]] !== null && (!row[buckets[1]] || row[buckets[1]] === null));

  const maxForFirstBucket = _.maxBy(tempValues, (row) => Math.abs(row.returns as number))?.returns || 0;
  maximumValues[buckets[0]] = Math.abs(maxForFirstBucket as number);

  const grouped = _.groupBy(data, buckets[0]);

  _.forEach(grouped, (group, key) => {
    const tempValues = group.filter((row) => row[buckets[1]] !== null);
    const maxForInnerGroup = _.maxBy(tempValues, (row) => Math.abs(row.returns as number))?.returns || 0;
    maximumValues[`${buckets[0]}|${key}`] = Math.abs(maxForInnerGroup as number);
  });

  return maximumValues;
};

export const getRgbColor = (maximum: number, value: number, isInverted = false): string => {
  value = value || 0;

  const ratio = Math.abs(value) / maximum;
  const colorValue = Math.max(0, Math.min(255, Math.floor(255 * ratio)));

  let red = 0;
  let green = 0;

  if (!isInverted) {
    if (value >= 0) {
      green = colorValue;
    } else {
      red = colorValue;
    }
  } else {
    if (value >= 0) {
      red = colorValue;
    } else {
      green = colorValue;
    }
  }

  return `rgb(${red}, ${green}, 0)`;
};

export const portanFormat = (
  data: ts.types.widgets.TableData,
  maxValues: { [key: string]: number },
  buckets: string[],
  statisticsType: ts.enums.REPORT_ENUMS.ANNU_STATISTICS_ENUM
): ts.types.widgets.TreeMapData => {
  function recurDictify(rows: ts.types.widgets.TableData, keyIdentity = ''): any[] {
    if (Object.keys(rows[0]).length === 2) {
      return rows.map((row) => row[Object.keys(row)[1]]);
    }

    const grouped = _.groupBy(rows, Object.keys(rows[0])[0]);
    const result: any[] = [];

    for (const key in grouped) {
      const group = grouped[key];
      const returns = group[0]['returns'];
      const maxBoundaryValue = maxValues[keyIdentity];

      if (Object.keys(group[0]).length > 3 && key !== null && key !== 'null') {
        result.push({
          name: key,
          returns: group[0][Object.keys(group[0]).pop()],
          color: getRgbColor(
            maxBoundaryValue,
            group[0][Object.keys(group[0]).pop()] as number,
            statisticsType === ts.enums.REPORT_ENUMS.ANNU_STATISTICS_ENUM.RISK
          ),
          children: recurDictify(
            group.map((row) => {
              const newRow = { ...row };
              delete newRow[Object.keys(row)[0]];
              return newRow;
            }),
            `${Object.keys(group[0])[0]}|${key}`
          ),
        });
      } else {
        // Last level
        const parentValues = Object.values(group[0]);
        if (!parentValues.includes(null)) {
          result.push({
            name: key,
            value: group[0]['holdings'],
            returns: returns,
            color: getRgbColor(
              maxBoundaryValue,
              returns as number,
              statisticsType === ts.enums.REPORT_ENUMS.ANNU_STATISTICS_ENUM.RISK
            ),
          });
        }
      }
    }

    return result;
  }

  return recurDictify(data, buckets[0]);
};

export const checkForErrors = (data: ts.types.widgets.TableData, viewType: string) => {
  if (_.isEmpty(data)) {
    return 'Performance Attribution produced no results.';
  }
  if (viewType != 'attributed_returns' && !Object.keys(data[0]).includes(viewType)) {
    return 'File missing columns, a new file is required. Change the definition and regenerate to get a new one.';
  }

  return '';
};

export const formatPortanData = (
  data: ts.types.widgets.TableData,
  buckets: string[],
  history: ts.enums.HISTORY_ENUM_EXTENDED,
  statisticsType: ts.enums.REPORT_ENUMS.ANNU_STATISTICS_ENUM = ts.enums.REPORT_ENUMS.ANNU_STATISTICS_ENUM.RETURNS
) => {
  const newData = portanPreformat(data, buckets, history);
  const maxValues = getMaxValues(newData, buckets);
  const formattedData = portanFormat(newData, maxValues, buckets, statisticsType);

  return formatName(formattedData);
};

export const getOrderedHistories = (histories: ts.enums.HISTORY_ENUM_EXTENDED[]): ts.enums.HISTORY_ENUM_EXTENDED[] => {
  const orderMap = Object.values(ts.enums.HISTORY_ENUM_EXTENDED).reduce(
    (acc, val, idx) => {
      acc[val] = idx;
      return acc;
    },
    {} as Record<string, number>
  );

  return [...histories].sort((a, b) => orderMap[b] - orderMap[a]);
};
