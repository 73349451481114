import { _, helpers, ts } from '_core';

import fields, { groupLabel, GroupType } from './fields';

export const getNewBacktestSet = (name: string, handle: string, template: ts.types.backtestSet.BacktestSet = null) => {
  const nullDefinition = {};
  const newBacktestSet = {
    name,
    definition: nullDefinition,
    ui_metadata: {},
    handle,
    is_valid: false,
  };

  // Check if we will create the signalset with a premade definition
  if (template && template.id) {
    newBacktestSet.definition = {
      ...template.definition,
    };
    newBacktestSet.ui_metadata = {};
    newBacktestSet.is_valid = template.is_valid;
  }
  return newBacktestSet;
};

export const getArchivedDeps = (
  backtestSet: ts.types.backtestSet.BacktestSet,
  resources: ts.StoreState['resources']
): ts.types.common.ArchivedDeps => {
  const deps = helpers.resourceGraph.getDeps(
    { ...backtestSet, resourceType: ts.enums.RESOURCES_TYPES_ENUM.BACKTEST_SET },
    resources
  );
  return helpers.resourceGraph.getArchiveDeps(deps);
};

export const getUnpublishedDeps = (
  backtestSet: ts.types.backtestSet.BacktestSet,
  resources: ts.StoreState['resources']
): ts.types.common.ResourceDeps[] => {
  return helpers.resourceGraph
    .getDeps({ ...backtestSet, resourceType: ts.enums.RESOURCES_TYPES_ENUM.BACKTEST_SET }, resources)
    .filter((d) => !d.is_published);
};

export const getUnsharedDeps = (
  backtestSet: ts.types.backtestSet.BacktestSet,
  resources: ts.StoreState['resources'],
  sharedWith?: number[]
): ts.types.common.ResourceDeps[] => {
  if (!sharedWith) sharedWith = backtestSet.shared_with || [];

  return helpers.resourceGraph
    .getDeps({ ...backtestSet, resourceType: ts.enums.RESOURCES_TYPES_ENUM.BACKTEST_SET }, resources)
    .filter((d) => !helpers.resourcesUtils.isShared(d, sharedWith));
};

export const getBacktestIdsAndGroups = (backtestDefinition: ts.types.backtest.DefinitionDraft) => {
  let constraints = [] as { id: string; group: GroupType; constraint: ts.types.optimizer.ConstraintDraft }[];
  if (backtestDefinition?.optimizer_config?.objective_function?.id)
    constraints.push({
      id: backtestDefinition?.optimizer_config?.objective_function?.id,
      constraint: backtestDefinition?.optimizer_config?.objective_function,
      group: 'objective_function',
    });

  if (!_.isEmpty(backtestDefinition?.optimizer_config?.constraints))
    constraints = constraints.concat(
      (backtestDefinition?.optimizer_config?.constraints ?? []).map((c) => ({
        id: c.id,
        group: c.constraint_type as GroupType,
        constraint: c,
      }))
    );

  return constraints;
};

// Return a list of the backtest set parameters with it's field metadata
export const expandParameters = (
  backtestSetDefinition: ts.types.backtestSet.DefinitionDraft,
  backtestDefinition: ts.types.backtest.DefinitionDraft
) => {
  const idsAndGroups = getBacktestIdsAndGroups(backtestDefinition);
  const parameters = backtestSetDefinition.parameters ?? [];

  return parameters
    .map((p) => {
      const group = idsAndGroups.find((c) => c.id == p.target_id);
      if (!group) return null;
      const field = fields.filter((f) => f.group_types.includes(group.group)).find((f) => f.property == p.property);

      return {
        ...p,
        fieldMetadata: field,
        groupLabel: group.group,
        constraint: group.constraint,
      };
    })
    .filter((v) => v);
};

export const getDefinitionGroups = (backtestDefinition: ts.types.backtest.Definition) => {
  return getBacktestIdsAndGroups(backtestDefinition)
    .map((c) => ({
      ...c,
      overrideParameters: fields
        .filter((f) => f.group_types.includes(c.group))
        .filter((f) => f.condition(backtestDefinition)),
    }))
    .filter((g) => !_.isEmpty(g.overrideParameters)) as {
    group: string;
    constraint?: ts.types.optimizer.ConstraintDraft;
    id?: string;
    overrideParameters: (typeof fields)[0][];
  }[];
};

export const getFieldKeys = (fieldsLocal = fields, groupLabelLocal = groupLabel) => {
  const fieldKeys = [] as { key: string; label: string }[];
  fieldsLocal.forEach((fieldConf) => {
    fieldConf.group_types.forEach((group) => {
      fieldKeys.push({
        key: `${group}_${fieldConf.property}`,
        label: `${groupLabelLocal[group]} - ${fieldConf.label}`,
      });
    });
  });
  return fieldKeys;
};

export const getFieldKeysFromDef = (
  backtestSetDefinition: ts.types.backtestSet.DefinitionDraft,
  backtestDefinition: ts.types.backtest.DefinitionDraft,
  groupLabelLocal = groupLabel
) => {
  const expanded = expandParameters(backtestSetDefinition, backtestDefinition);
  return expanded.map((e) => ({
    key: `${e.groupLabel}_${e.fieldMetadata.property}`,
    label: `${groupLabelLocal[e.groupLabel]} - ${e.fieldMetadata.label}`,
  }));
};

export const isMember = (key: string) => {
  const confKeys = getFieldKeys();
  const foundKey = confKeys.find((c) => c.key == key);
  return !!foundKey;
};

export const getParamName = (key: string) => {
  const confKeys = getFieldKeys();
  const foundKey = confKeys.find((c) => c.key == key);
  if (foundKey) return foundKey.label;
  return _.startCase(key);
};
