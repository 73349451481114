import { ts } from '_core';

// prepareDataStatisticsVsTime
export const transform = (rawData: ts.types.widgets.NestedBarWidgetData, hasBenchmark: boolean, isBasket: boolean) => {
  const newData = { ...rawData };
  newData.data = rawData.data.map((item) => {
    if (isBasket) item['Basket Return'] = item['portfolio'];
    else item['Portfolio Return'] = item['portfolio'];
    delete item['portfolio'];

    if (hasBenchmark) item['Active Return'] = item['active'];
    delete item['active'];

    if (item['category']) item.category = item.category.toString();

    return item;
  });

  return newData;
};

const validFrequenciesRisk = {
  [ts.enums.FREQUENCY_ENUM.DAILY]: ['MONTHLY', 'ANNUAL'],
  [ts.enums.FREQUENCY_ENUM.WEEKLY]: ['ANNUAL'],
  [ts.enums.FREQUENCY_ENUM.MONTHLY]: ['ANNUAL'],
  [ts.enums.FREQUENCY_ENUM.QUARTERLY]: [''],
  [ts.enums.FREQUENCY_ENUM.SEMIANNUAL]: [''],
  [ts.enums.FREQUENCY_ENUM.ANNUAL]: [''],
};

const validFrequencies = {
  [ts.enums.FREQUENCY_ENUM.DAILY]: ['WEEKLY', 'MONTHLY', 'ANNUAL'],
  [ts.enums.FREQUENCY_ENUM.WEEKLY]: ['MONTHLY', 'ANNUAL'],
  [ts.enums.FREQUENCY_ENUM.MONTHLY]: ['ANNUAL'],
  [ts.enums.FREQUENCY_ENUM.QUARTERLY]: [''],
  [ts.enums.FREQUENCY_ENUM.SEMIANNUAL]: [''],
  [ts.enums.FREQUENCY_ENUM.ANNUAL]: [''],
};

export const rebalanceValidation = (aggregation: string, backtestFrequency: ts.enums.FREQUENCY_ENUM, type: string) => {
  if (type == 'return' && validFrequencies[backtestFrequency].includes(aggregation)) return true;
  if (type != 'return' && validFrequenciesRisk[backtestFrequency].includes(aggregation)) return true;
  return false;
};
