import { hooks, mui, React } from '_core';

import { DraftReportSectionParams } from './types';

type FormProps = {
  params: DraftReportSectionParams;
  setParams: (_p: DraftReportSectionParams) => void;
};

const Form: React.FC<FormProps> = ({ params, setParams }) => {
  const uiStyles = hooks.useUiStyles();
  const [title, setTitle] = React.useState(params.title || '');
  const [description, setDescription] = React.useState(params.description || '');

  React.useEffect(() => {
    const data = { ...params, title, description };
    setParams(data);
  }, [title, description]);

  return (
    <mui.core.Box sx={uiStyles.widgetEditMode}>
      <mui.core.Box mt={2}>
        <mui.core.TextField
          label="Title"
          variant="outlined"
          size="small"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          fullWidth
          required
        />
      </mui.core.Box>
      <mui.core.Box mt={2}>
        <mui.core.TextField
          label="Description"
          variant="outlined"
          size="small"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          fullWidth
        />
      </mui.core.Box>
    </mui.core.Box>
  );
};

export default Form;
