import { hooks, mui, React, ui } from '_core';

import { DraftParams } from './types';

type FormProps = {
  params: DraftParams;
  setParams: (_p: DraftParams) => void;
};

const Form: React.FC<FormProps> = ({ params, setParams }) => {
  const uiStyles = hooks.useUiStyles();

  const [estimationPeriods, setEstimationPeriods] = React.useState(params.industry_estimation_periods);
  const [indMinProb, setIndMinProb] = React.useState(params.industry_min_prob);

  React.useEffect(() => {
    const data = {
      ...params,
      industry_estimation_periods: estimationPeriods,
      industry_min_prob: indMinProb,
    };
    setParams(data);
  }, [estimationPeriods, indMinProb]);

  return (
    <mui.core.Box sx={uiStyles.widgetEditMode}>
      <mui.core.Box mt={4}>
        <ui.NumericField
          textFieldProps={{
            label: 'Minimum Clustering Probability',
            fullWidth: true,
            required: true,
            variant: 'outlined',
          }}
          value={indMinProb}
          setValue={(val) => setIndMinProb(val)}
        />
      </mui.core.Box>
      <mui.core.Box mt={4}>
        <ui.NumericField
          textFieldProps={{
            label: 'Industry estimation periods',
            fullWidth: true,
            variant: 'outlined',
            size: 'small',
          }}
          value={estimationPeriods}
          setValue={(val) => setEstimationPeriods(val)}
        />
      </mui.core.Box>
    </mui.core.Box>
  );
};

export default Form;
