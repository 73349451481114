import { config, moment, mui, React, ts, ui } from '_core';

import { DraftParams } from './types';

type FormProps = {
  params: DraftParams;
  setParams: (_p: DraftParams) => void;

  universeFrequency?: ts.enums.FREQUENCY_ENUM;
  universeStartDate?: string;
  universeEndDate?: string;
};

const Form: React.FC<FormProps> = ({ params, setParams, universeFrequency, universeStartDate, universeEndDate }) => {
  const getInitialStartDate = () => {
    if (params.comparison_date_1) return params.comparison_date_1;

    const subtractedStartDate = moment(universeEndDate).subtract(1, 'years').format('YYYY-MM-DD');
    if (subtractedStartDate < universeStartDate) return universeStartDate;

    return subtractedStartDate;
  };

  const [firstDate, setFirstDate] = React.useState(getInitialStartDate);
  const [secondDate, setSecondDate] = React.useState(params.comparison_date_2 || universeEndDate);

  const [componentErrors, setComponentErrors] = React.useState([]);

  React.useEffect(() => {
    const data = {
      comparison_date_1: firstDate,
      comparison_date_2: secondDate,
      component_errors: componentErrors,
    };

    setParams({ ...params, ...data });
  }, [firstDate, secondDate, componentErrors]);

  return (
    <mui.core.Box mt={4}>
      <mui.core.Grid container spacing={2}>
        <mui.core.Grid item xs={6}>
          <ui.SmartDateSelector
            label="First Date"
            value={firstDate}
            setValue={(date) => setFirstDate(date)}
            minDate={universeStartDate || config.features.start_date}
            maxDate={secondDate || universeEndDate || config.features.end_date}
            frequency={universeFrequency}
            setExternalError={(e) => setComponentErrors(e ? [e] : [])}
          />
        </mui.core.Grid>

        <mui.core.Grid item xs={6}>
          <ui.SmartDateSelector
            label="Second Date"
            value={secondDate}
            setValue={(date) => setSecondDate(date)}
            minDate={firstDate || universeStartDate || config.features.start_date}
            maxDate={universeEndDate || config.features.end_date}
            frequency={universeFrequency}
            setExternalError={(e) => setComponentErrors(e ? [e] : [])}
          />
        </mui.core.Grid>
      </mui.core.Grid>
    </mui.core.Box>
  );
};

Form.defaultProps = {
  universeStartDate: null,
  universeEndDate: null,
};

export default Form;
