import { hooks, mui, React } from '_core';

import { DraftParams, Params } from './types';

type FormProps = {
  params: DraftParams;
  setParams: (_p: Params) => void;
};

const Form: React.FC<FormProps> = ({ params, setParams }): React.ReactElement => {
  const [unlevered, setUnlevered] = React.useState(params.unlevered);

  const uiStyles = hooks.useUiStyles();

  React.useEffect(() => {
    const data = { ...params, unlevered };
    setParams(data);
  }, [unlevered]);

  return (
    <mui.core.Box sx={uiStyles.widgetEditMode}>
      <mui.core.Box mt={2}>
        <mui.core.FormControlLabel
          control={<mui.core.Switch size="small" checked={unlevered} onChange={() => setUnlevered(!unlevered)} />}
          label="Unlevered Portfolio"
        />
      </mui.core.Box>
    </mui.core.Box>
  );
};

export default Form;
