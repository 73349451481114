import { _, api, helpers, ts } from '_core';

type StrategyApiResponse = {
  data: ts.types.strategy.Strategy;
};

export const createStrategy = async (
  newData: ts.types.strategy.StrategyDraft,
  callback: (_data: ts.types.strategy.Strategy, _ws: ts.types.workspace.Workspace) => void,
  errorCallback: (_error: string) => void
) => {
  try {
    let respStrat = await api.strategies.create(newData);
    const respWorkspace = await api.workspaces.create({
      name: newData.name,
      context: 'production',
      strategy_ids: [respStrat.data.id],
    });

    respStrat = await api.strategies.update(respStrat.data.id, {
      workspace_id: respWorkspace.data.id,
    });

    callback(respStrat.data, respWorkspace.data);
  } catch (error) {
    errorCallback(`Error creating strategy - ${helpers.parseApiError(error as ts.types.common.ApiError)}`);
  }
};

export const updateStrategy = async (
  strategy: ts.types.strategy.Strategy,
  newData: ts.types.strategy.StrategyDraft,
  callback: (_data: ts.types.strategy.Strategy, _ws?: ts.types.workspace.Workspace) => void,
  errorCallback: (_error: string) => void
) => {
  try {
    const resp: StrategyApiResponse = await api.strategies.update(strategy.id, { ...newData });

    // Update workspace name if needed
    let ws = null as ts.types.workspace.Workspace;
    if (strategy.workspace_id && newData['name'])
      ws = (await api.workspaces.update(strategy.workspace_id, { name: newData['name'] })).data;

    callback(resp.data, ws);
  } catch (error) {
    errorCallback(`Error updating strategy - ${helpers.parseApiError(error as ts.types.common.ApiError)}`);
  }
};

export const updateDefinition = async (
  id: number,
  definition: ts.types.strategy.DefinitionDraft,
  isValid: boolean,
  callback: (_data: ts.types.strategy.Strategy) => void,
  errorCallback: (_error: string) => void
) => {
  try {
    const localDef = { ...definition } as ts.types.strategy.Definition;

    const resp = await api.strategies.update(
      id,
      {
        definition: localDef,
        is_valid: isValid,
        skip_signature_check: !isValid,
      },
      true
    );

    callback(resp.data);
  } catch (error) {
    errorCallback(`Error updating the definition - ${helpers.parseApiError(error as ts.types.common.ApiError)}`);
  }
};

export const updateUiMetadata = async (
  id: number,
  uiMetaData: ts.types.strategy.UiMetaData,
  callback: (_data: ts.types.strategy.Strategy) => void,
  errorCallback: (_error: string) => void
) => {
  try {
    const updateData = {
      ui_metadata: uiMetaData,
      skip_signature_check: true,
    } as ts.types.strategy.StrategyDraft;
    const resp = await api.strategies.update(id, updateData);
    callback(resp.data);
  } catch (error) {
    errorCallback(`Error updating the resource metadata - ${helpers.parseApiError(error as ts.types.common.ApiError)}`);
  }
};

const createAnalyses = async (strategyId: number, names: ts.enums.STRATEGY_ANALYSIS_TYPE_ENUM[]) => {
  const analyses = [];
  for (const name of names) {
    const analysisDefinition = { widgets: [] } as ts.types.analysis.AnalysisDefinition;

    // create analysis
    const response = await api.strategy_analyses.create({
      report_definition: analysisDefinition,
      strategy_id: strategyId,
      name,
    });
    analyses.push(response.data.name);
  }
  return analyses;
};

// Load analyses, and if we don't have them, create them
export const loadAnalyses = async (
  strategyId: number,
  setAnalysesNames: (_data: ts.enums.STRATEGY_ANALYSIS_TYPE_ENUM[]) => void,
  errorCallback: (_error: string) => void
) => {
  const analyses = Object.values(ts.enums.STRATEGY_ANALYSIS_TYPE_ENUM);
  try {
    const resp = await api.strategy_analyses.search({
      query: ['$and', ['strategy_id', '=', strategyId]],
      include: 'id,name',
    });

    let localNames = (resp.data || []).map(
      (a: ts.types.analysis.ResourceAnalysis) => a.name
    ) as ts.enums.STRATEGY_ANALYSIS_TYPE_ENUM[];

    const toCreate = _.without(analyses, ...localNames);
    if (!_.isEmpty(toCreate)) {
      const newNames = (await createAnalyses(strategyId, toCreate)) as ts.enums.STRATEGY_ANALYSIS_TYPE_ENUM[];
      localNames = localNames.concat(newNames);
    }

    setAnalysesNames(localNames);
  } catch (error) {
    errorCallback(`Error loading strategy analyses - ${helpers.parseApiError(error as ts.types.common.ApiError)}`);
  }
};
