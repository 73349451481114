import { React, ui } from '_core';

type DataSourceFormStepsButtonProps = {
  loading: boolean;
  step: number;
  setStep: (_s: number) => void;
  handleCreate: () => void;
};

const DataSourceFormStepsButton: React.FC<DataSourceFormStepsButtonProps> = ({
  loading,
  step,
  setStep,
  handleCreate,
}): React.ReactElement => {
  const renderNextButton = () => (
    <ui.Button
      variant="contained"
      color="primary"
      loading={loading}
      onClick={() => setStep(step + 1)}
      fullWidth
      style={{ margin: '0rem 1rem 1rem' }}
    >
      Next
    </ui.Button>
  );

  const renderEndButtons = () => (
    <>
      <ui.Button
        disabled={loading}
        variant="outlined"
        color="primary"
        onClick={() => setStep(step - 1)}
        fullWidth
        style={{ margin: '0rem 0.5rem 1rem' }}
      >
        Back
      </ui.Button>
      <ui.Button
        variant="contained"
        color="primary"
        loading={loading}
        onClick={handleCreate}
        fullWidth
        style={{ margin: '0rem 0.5rem 1rem' }}
      >
        Create Data Source
      </ui.Button>
    </>
  );

  const buttonRendersPerStep: Record<number, any> = {
    0: renderNextButton(),
    1: renderEndButtons(),
  };

  if (buttonRendersPerStep[step]) return buttonRendersPerStep[step];
};

export default DataSourceFormStepsButton;
