import { userapiendpoint } from './config';
import { $ } from '../libs';
import { AssetParams } from '../types/asset-selector';
import { AssetSelectorDates } from '../types/asset-selector';
import { ETFParams } from '../types/etf';

export const assetMaster = (data: {
  params: AssetParams;
  limit: number;
  offset: number;
  dates?: AssetSelectorDates;
}) => {
  return $.ajax({
    method: 'POST',
    url: `${userapiendpoint}fin-api/asset_master/search/?fmt=object&limit=${data.limit}&offset=${data.offset}`,
    json: {
      ...data.params,
      start_date: data.dates?.start,
      end_date: data.dates?.end,
    },
  } as JQueryAjaxSettings);
};

export const searchEtf = (data: { params: ETFParams; limit: number; offset: number }) => {
  return $.ajax({
    method: 'POST',
    url: `${userapiendpoint}fin-api/asset_master/search_etf/?fmt=object&limit=${data.limit}&offset=${data.offset}`,
    json: data.params,
  } as JQueryAjaxSettings);
};

export const getEtfFilters = () => {
  return $.ajax({
    method: 'POST',
    url: `${userapiendpoint}fin-api/asset_master/etf_filters/?fmt=object`,
    json: {
      filter_names: ['etf_codes_tree'],
    },
  } as JQueryAjaxSettings);
};

export const getEtfGeoExposure = () => {
  return $.ajax({
    method: 'POST',
    url: `${userapiendpoint}fin-api/asset_master/etf_geo_exposure/?fmt=object`,
  } as JQueryAjaxSettings);
};

export const getGics = () => {
  return $.ajax({
    method: 'GET',
    url: `${userapiendpoint}fin-api/gics/?fmt=object`,
  } as JQueryAjaxSettings);
};

export const getRbics = () => {
  return $.ajax({
    method: 'GET',
    url: `${userapiendpoint}fin-api/rbics/?fmt=object`,
  } as JQueryAjaxSettings);
};
