import { hooks, mui, React, ui } from '_core';

import { DraftParams } from './types';

type FormProps = {
  params: DraftParams;
  setParams: (_p: DraftParams) => void;
};

const Form: React.FC<FormProps> = ({ params, setParams }) => {
  const [criticalpvalue, setCriticalpvalue] = React.useState(params.critical_pvalue);

  const uiStyles = hooks.useUiStyles();

  React.useEffect(() => {
    const data = { ...params, critical_pvalue: criticalpvalue };
    setParams(data);
  }, [criticalpvalue]);

  return (
    <mui.core.Box sx={uiStyles.widgetEditMode}>
      <mui.core.Box mt={4}>
        <ui.NumericField
          textFieldProps={{
            label: 'Critical Pvalue (between 0 and 1)',
            fullWidth: true,
            variant: 'outlined',
            size: 'small',
            required: true,
          }}
          value={criticalpvalue}
          setValue={(v) => setCriticalpvalue(v)}
        />
      </mui.core.Box>
    </mui.core.Box>
  );
};

export default Form;
