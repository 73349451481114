import React from 'react';

import { mui } from '../../../libs';
import { Filter, Option } from '../search-types';

type SelectOptionProps = {
  selectedFilter: Filter;
  setSelectedOption: (_v: Option) => void;
  clearFilter: () => void;
};

const TextFieldOption: React.FC<SelectOptionProps> = ({
  selectedFilter,
  setSelectedOption,
  clearFilter,
}): React.ReactElement => {
  const [posibleOption, setPosibleOption] = React.useState<string>();

  return (
    <mui.core.Box>
      <mui.core.Box p={2} display="flex">
        <mui.core.Chip
          label={selectedFilter.label}
          size="small"
          color="primary"
          sx={{ marginRight: '5px' }}
          onClick={clearFilter}
          variant="outlined"
        />
        <mui.core.Chip
          label="="
          size="small"
          color="primary"
          sx={{ marginRight: '5px' }}
          onClick={clearFilter}
          variant="outlined"
        />

        {posibleOption && <mui.core.Chip label={posibleOption} size="small" color="primary" variant="outlined" />}
      </mui.core.Box>

      <mui.core.Box m={2} display="flex">
        <mui.core.Box flexGrow={1}>
          <mui.core.TextField
            autoFocus
            variant="outlined"
            fullWidth
            value={posibleOption}
            size="small"
            onChange={(e) => setPosibleOption(e.target.value)}
            sx={{ paddingX: '5px', zIndex: 1000 }}
            onKeyDown={(ev) => {
              if (ev.key === 'Enter' && posibleOption) {
                setSelectedOption({
                  key: posibleOption,
                  label: posibleOption,
                });
              }
            }}
          />
        </mui.core.Box>

        <mui.core.Button
          variant="contained"
          color="primary"
          onClick={() => {
            setSelectedOption({
              key: posibleOption,
              label: posibleOption,
            });
          }}
          disabled={!posibleOption}
        >
          Add
        </mui.core.Button>
      </mui.core.Box>
    </mui.core.Box>
  );
};

export default TextFieldOption;
