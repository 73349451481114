import { React, ts } from '_core';

import Chart from './chart';
import { Params } from './types';

type BacktestTabularOutputProps = {
  id: string;
  params: Params;
  setParams: (_v: Params) => void;

  queryParams?: { initial?: string };
  goFullScreen?: (_v: string) => void;
  fullScreen?: boolean;
  context?: ts.types.backtest.BacktestAnaylsisContext | ts.types.basket.BasketAnalysisContext;
  widgetPaths?: Record<'backtest_tabular_data_analysis' | 'backtest_alpha_exposures', string>;

  loadWidgetData: (_payload: ts.types.widgets.WidgetGetDataParams) => Promise<ts.types.widgets.WidgetGetDataResponse>;
};

const BacktestTabularOutput: React.FC<BacktestTabularOutputProps> = ({
  id,
  loadWidgetData,
  widgetPaths,
  fullScreen,
  goFullScreen,
  context,
  queryParams,
  params,
  setParams,
}): React.ReactElement => {
  return (
    <Chart
      id={id}
      setParams={setParams}
      params={params}
      loadWidgetData={loadWidgetData}
      portfolios={widgetPaths['backtest_tabular_data_analysis']}
      alphaExposures={widgetPaths['backtest_alpha_exposures']}
      context={context}
      fullScreen={fullScreen}
      queryParams={queryParams}
      goFullScreen={goFullScreen}
    />
  );
};

export default BacktestTabularOutput;
