// basic info of the resources accesible for the user for forms and displays
import { DeleteResourceTypeByIdType, LoadResourcesType, UpdateResourceTypeByIdType } from '_core/reducers/resources';

import { Dispatch } from 'react';
import { ReportDefault } from '@local/finsera-core/src/types/analysis';
import { Resource } from '@local/finsera-core/src/types/common';
import { OrganizationFeatures } from '@local/finsera-core/src/types/organization';

import * as loadResource from './loading-calls';
import { DELETE_RESOURCE_TYPE_BY_ID, LOAD_RESOURCES, UPDATE_RESOURCE_TYPE_BY_ID } from './types';

export const load =
  (features: OrganizationFeatures, userId: number) => async (dispatch: Dispatch<LoadResourcesType>) => {
    const loadingCalls = [
      loadResource.loadBaskets,
      loadResource.loadCurrencies,
      loadResource.loadDataSources,
      loadResource.loadGeneralAnalyses,
      loadResource.loadGics,
      loadResource.loadLabels,
      loadResource.loadPipelines,
      loadResource.loadRbics,
      loadResource.loadReportDefaults,
      loadResource.loadRiskModels,
      loadResource.loadArtifacts,
      loadResource.loadSignals,
      loadResource.loadUniverses,
      loadResource.loadFixedCosts,
      loadResource.loadUsers,
      loadResource.loadWorkspaces,
      loadResource.publishedOrgs,
    ] as ((_features: OrganizationFeatures, _userId: number) => Promise<any>)[];

    // Do not load if organization features
    if (features.backtest && features.alpha_model) loadingCalls.push(loadResource.loadBacktests);
    if (features.backtest_set && features.backtest && features.alpha_model)
      loadingCalls.push(loadResource.loadBacktestSets);
    if (features.signal_set) loadingCalls.push(loadResource.loadSignalSets);
    if (features.show_help) loadingCalls.push(loadResource.loadHelpItems);
    if (features.time_series) loadingCalls.push(loadResource.loadTimeSeries);
    if (features.production_mode) loadingCalls.push(loadResource.loadStrategies);
    if (features.production_mode) loadingCalls.push(loadResource.loadPortfolios);

    const values = await Promise.all(loadingCalls.map((c) => c(features, userId)));

    let payload = {};
    values.forEach((v) => (payload = { ...v, ...payload }));

    dispatch({
      type: LOAD_RESOURCES,
      payload,
    });
  };

export const updateReportDefault = (data: ReportDefault) => async (dispatch: Dispatch<UpdateResourceTypeByIdType>) => {
  dispatch({
    type: UPDATE_RESOURCE_TYPE_BY_ID,
    resourceType: 'report_defaults',
    data: data as Resource,
  });
};

export const deleteReportDefault =
  (data: { id: number } & ReportDefault) => async (dispatch: Dispatch<DeleteResourceTypeByIdType>) => {
    dispatch({ type: DELETE_RESOURCE_TYPE_BY_ID, resourceType: 'report_defaults', data });
  };

export const updateSignalDataset =
  (data: { id: number; [key: string]: any }) => async (dispatch: Dispatch<UpdateResourceTypeByIdType>) => {
    dispatch({
      type: UPDATE_RESOURCE_TYPE_BY_ID,
      resourceType: 'signal_datasets',
      data,
    });
  };

export const deleteSignalDataset = (data: { id: number }) => async (dispatch: Dispatch<DeleteResourceTypeByIdType>) => {
  dispatch({ type: DELETE_RESOURCE_TYPE_BY_ID, resourceType: 'signal_datasets', data });
};

export const deleteHelpItem = (data: { id: number }) => async (dispatch: Dispatch<DeleteResourceTypeByIdType>) => {
  dispatch({ type: DELETE_RESOURCE_TYPE_BY_ID, resourceType: 'help_items', data });
};
