import { hooks, mui, React, ts } from '_core';

import LayoutActions from './layout-actions';
import { SheetConfig } from './pref-types';
import SheetSetup from './sheet-setup';

type LayoutSetupProps = {
  preferences: ts.types.userPreference.UserPreference[];
  setPreferences: (_v: ts.types.userPreference.UserPreference[]) => void;
  configColumns: Record<ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM, ts.types.components.dataGrid.ColumnsData>;
  sheetsConfig: SheetConfig;
  setSelectedPreference: (_v: ts.types.userPreference.UserPreference) => void;
  defaultPref: ts.types.components.dataGrid.ColumnPreferences;
  selectedPreference?: ts.types.userPreference.UserPreference;
};

const LayoutSetup: React.FC<LayoutSetupProps> = ({
  preferences,
  setPreferences,
  configColumns,
  sheetsConfig,
  setSelectedPreference,
  selectedPreference,
  defaultPref,
}) => {
  const uiStyles = hooks.useUiStyles();

  const multipleReport = sheetsConfig.length > 1;

  return (
    <>
      <LayoutActions
        preferences={preferences}
        setPreferences={setPreferences}
        selectedPreference={selectedPreference}
        setSelectedPreference={setSelectedPreference}
      />

      <mui.core.Box sx={{ ...uiStyles.settingsContainer, position: 'relative' }}>
        {sheetsConfig.map((currSheet, idx) => (
          <>
            <mui.core.Box
              key={idx}
              display={multipleReport ? 'grid' : 'block'}
              sx={multipleReport && { gridTemplateColumns: '1fr 8fr' }}
            >
              {sheetsConfig.length > 1 && (
                <mui.core.Typography variant="body1" sx={{ fontWeight: '600' }}>
                  {currSheet.title}
                </mui.core.Typography>
              )}
              <mui.core.Box>
                <SheetSetup
                  key={selectedPreference.id}
                  sheetDefinition={
                    (selectedPreference?.definition as ts.types.components.dataGrid.ColumnPreferences)?.[
                      currSheet.key
                    ] || {
                      columns: [],
                      names: {},
                    }
                  }
                  setSheetDefinition={(val) =>
                    setSelectedPreference({
                      ...selectedPreference,
                      definition: {
                        ...selectedPreference.definition,
                        [currSheet.key]: val,
                      },
                    })
                  }
                  configColumns={configColumns[currSheet.key]}
                  defaultPref={defaultPref[currSheet.key] || {}}
                />
              </mui.core.Box>
            </mui.core.Box>
            {sheetsConfig.length > 1 && (
              <mui.core.Box mb={10}>
                <mui.core.Divider />
              </mui.core.Box>
            )}
          </>
        ))}
      </mui.core.Box>
    </>
  );
};

export default LayoutSetup;
