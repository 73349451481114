import { mui, React, ts, ui } from '_core';

import { DraftParams } from './types';

type FormProps = {
  params: DraftParams;
  setParams: (_p: DraftParams) => void;
  context?: ts.types.signal.SignalAnalysisContext;
};

const Form: React.FC<FormProps> = ({ params, setParams, context }) => {
  const [date, setDate] = React.useState(params.start_date || context.end_date);

  React.useEffect(() => {
    const data = {
      ...params,
      start_date: date,
      end_date: date,
    };
    setParams(data);
  }, [date]);

  return (
    <mui.core.Box my={4}>
      <ui.FinDatePicker
        label="Date"
        value={date}
        onChange={(date) => setDate(date)}
        minDate={context.start_date}
        maxDate={context.end_date}
        inputProps={{
          disableUnderline: true,
          size: 'small',
        }}
      />
    </mui.core.Box>
  );
};

export default Form;
