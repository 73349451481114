import { _, helpers, ts } from '_core';

///////////////////
// Filter layout //
///////////////////

export const setParams = (
  index: number,
  params: ts.types.pipeline.Filter['params'],
  filters: ts.types.pipeline.Filter[],
  setFilters: (_filters: ts.types.pipeline.Filter[]) => void
) => {
  setFilters(
    filters.map((pf, i) => {
      if (i === index) return { ...pf, params };
      return pf;
    })
  );
};

export const removeFilter = (
  index: number,
  filters: ts.types.pipeline.Filter[],
  setFilters: (_filters: ts.types.pipeline.Filter[]) => void
) => {
  setFilters(filters.filter((filter, i) => index !== i));
};

const insert = (arr: ts.types.pipeline.Filter[], index: number, newItem: ts.types.pipeline.Filter) => [
  ...arr.slice(0, index),
  newItem,
  ...arr.slice(index),
];

export const addFilter = (
  filter: ts.types.pipeline.FiltersData,
  position: number,
  filters: ts.types.pipeline.Filter[],
  setFilters: (_filters: ts.types.pipeline.Filter[]) => void
) => {
  setFilters(
    insert(filters, position, {
      id: helpers.gibberishGenerator.stringGenerator(11),
      type: filter.type,
      params: filter.defaultParams || {},
    })
  );
};

///////////////////
//   QUANTIZE    //
///////////////////

export const generateSubQuantile = (
  numberOfQuantiles: number,
  params: ts.types.pipeline.QuantizeDraft,
  setParams: (_params: ts.types.pipeline.QuantizeDraft) => void,
  setSubQuantile: (_subQuantile: number[]) => void
) => {
  const subArr = [];
  const quantileDiv = 1 / numberOfQuantiles;
  let oldSubArr = 0;
  let temp = 0;
  subArr.push(0);

  for (let i = 0; i <= numberOfQuantiles; i += 1) {
    if (i === 11 || i === numberOfQuantiles - 1) {
      subArr.push(1);
      break;
    }

    temp = Math.round((oldSubArr + quantileDiv + Number.EPSILON) * 100) / 100;
    subArr.push(temp);
    oldSubArr = temp;
  }

  setParams({ ...params, quantiles: subArr });
  setSubQuantile(subArr);
};

//////////////////////////////////////
//   CHARACTERISTIC PORTFOLIO      //
//////////////////////////////////////

export const getCharPortOptionDisabled = (
  option: ts.types.pipeline.CharacteristicPortfolioDraft['risk_factors'][0]['type'],
  selected: ts.types.pipeline.CharacteristicPortfolioDraft['risk_factors'][0]['type'][]
) => {
  // If all is selected, then there are no options
  if (selected.includes('risk_model')) return true;
  // If we are here and all is not selected, then all is disabled
  if (!_.isEmpty(selected) && option == 'risk_model') return true;
  // If all styles is included, then disable style options
  if (selected.includes('risk_model_style') && (option == 'risk_model_theme' || option == 'risk_model_style'))
    return true;
  // If risk_model_theme has been selected, then risk_model_style is disabled
  if (selected.includes('risk_model_theme') && option == 'risk_model_style') return true;

  return false;
};

/////////////////////////
//   NEUTRALIZE       //
////////////////////////

// TODO: add test for this procedure

export const getNeutralizeFactorsEnabled = (
  el: ts.types.pipeline.NeutralizeDraft['factors'][0],
  factors: ts.types.pipeline.NeutralizeDraft['factors']
): Record<ts.enums.N_FACTOR_TYPE_ENUM, boolean> => {
  const factorsTaken = factors.map((f) => f.type);
  const fcount = _.countBy(factorsTaken, _.identity);
  const factorKeys = Object.values(ts.enums.N_FACTOR_TYPE_ENUM);

  const factorsEnabled: Partial<Record<ts.enums.N_FACTOR_TYPE_ENUM, boolean>> = {};

  factorKeys.forEach((key) => {
    let enabled = false;
    if (key == ts.enums.N_FACTOR_TYPE_ENUM.INDUSTRY && fcount[ts.enums.N_FACTOR_TYPE_ENUM.INDUSTRY]) enabled = true;
    if (key == ts.enums.N_FACTOR_TYPE_ENUM.ASSET_MASTER && fcount[ts.enums.N_FACTOR_TYPE_ENUM.ASSET_MASTER])
      enabled = true;
    if (
      key == ts.enums.N_FACTOR_TYPE_ENUM.RISK_MODEL_CATEGORY &&
      fcount[ts.enums.N_FACTOR_TYPE_ENUM.RISK_MODEL_CATEGORY]
    )
      enabled = true;
    if (key == ts.enums.N_FACTOR_TYPE_ENUM.RISK_MODEL_STYLE && fcount[ts.enums.N_FACTOR_TYPE_ENUM.RISK_MODEL_STYLE])
      enabled = true;
    if (
      key == ts.enums.N_FACTOR_TYPE_ENUM.RISK_MODEL_THEME &&
      fcount[ts.enums.N_FACTOR_TYPE_ENUM.RISK_MODEL_STYLE] &&
      el.type !== ts.enums.N_FACTOR_TYPE_ENUM.RISK_MODEL_STYLE
    )
      enabled = true;
    if (
      key == ts.enums.N_FACTOR_TYPE_ENUM.RISK_MODEL_STYLE &&
      fcount[ts.enums.N_FACTOR_TYPE_ENUM.RISK_MODEL_THEME] > 1 &&
      el.type == ts.enums.N_FACTOR_TYPE_ENUM.RISK_MODEL_THEME
    )
      enabled = true;
    if (
      key == ts.enums.N_FACTOR_TYPE_ENUM.RISK_MODEL_STYLE &&
      fcount[ts.enums.N_FACTOR_TYPE_ENUM.RISK_MODEL_THEME] &&
      el.type !== ts.enums.N_FACTOR_TYPE_ENUM.RISK_MODEL_THEME
    )
      enabled = true;

    factorsEnabled[key] = enabled;
  });

  return factorsEnabled as Record<ts.enums.N_FACTOR_TYPE_ENUM, boolean>;
};

export const hasDuplicateStyleFactors = (styleFactors: ts.types.pipeline.NeutralizeDraft['factors']) => {
  const uniqStyleFactors = _.uniqBy(styleFactors, 'value');

  if (uniqStyleFactors.length != styleFactors.length) return true;

  return false;
};
