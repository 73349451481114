import { _, ts } from '_core';

import { DraftParams } from './types';

// prepareCovarianceMatrixData
export const transform = (data: Record<string, any>[]) => {
  const newData = [] as ts.types.widgets.HeatMapData;
  const allFactors = _.map(data, 'factor');

  data.forEach((el) => {
    const key = el.factor;

    allFactors.forEach((elKey) => {
      newData.push({
        factor_x: key,
        factor_y: elKey,
        value: el[elKey],
      });
    });
  });

  return newData;
};

export const validate = (params: DraftParams) => {
  if (!params.start_date) return ['Snapshot date is a required field.'];

  if (!_.isEmpty(params.component_errors)) return params.component_errors;
  return [];
};
