import * as enums from '../enums';
import { _, mui } from '../libs';

type ResourceColorMap = {
  [resourceType: string]: string | ((_theme: mui.core.Theme) => string);
};

const RESOURCE_COLORS: ResourceColorMap = {
  [enums.UI_RESOURCE_TYPE_ENUM.SIGNAL]: (_theme: mui.core.Theme) => _theme.palette.warning.main,
  [enums.UI_RESOURCE_TYPE_ENUM.UNIVERSE]: (_theme: mui.core.Theme) => _theme.palette.purple.main,
  [enums.UI_RESOURCE_TYPE_ENUM.PIPELINE]: (_theme: mui.core.Theme) => _theme.palette.danger.main,
  [enums.UI_RESOURCE_TYPE_ENUM.WORKSPACE]: (_theme: mui.core.Theme) => _theme.palette.success.main,
  [enums.UI_RESOURCE_TYPE_ENUM.BACKTEST]: (_theme: mui.core.Theme) => _theme.palette.primary.main,
  [enums.UI_RESOURCE_TYPE_ENUM.DATA_SOURCE]: (_theme: mui.core.Theme) => _theme.palette.orange.main,
  [enums.UI_RESOURCE_TYPE_ENUM.GENERAL_ANALYSIS]: (_theme: mui.core.Theme) => _theme.palette.lime.main,
  [enums.UI_RESOURCE_TYPE_ENUM.RISK_MODEL]: (_theme: mui.core.Theme) => _theme.palette.pink.main,
  [enums.UI_RESOURCE_TYPE_ENUM.SIGNAL_SET]: '#FF6526',
  [enums.UI_RESOURCE_TYPE_ENUM.BACKTEST_SET]: (_theme: mui.core.Theme) => _theme.palette.primary.main,
  [enums.UI_RESOURCE_TYPE_ENUM.BASKET]: '#EE8D51',
  [enums.UI_RESOURCE_TYPE_ENUM.TIME_SERIES]: '#e12a2a',
  [enums.UI_RESOURCE_TYPE_ENUM.STRATEGY]: '#bf95fa',
  [enums.UI_RESOURCE_TYPE_ENUM.PORTFOLIO]: '#4574BA',
  [enums.PREFERENCES_SECTION_ENUM.FIXED_COST]: (_theme: mui.core.Theme) => _theme.palette.success.main,
  [enums.PREFERENCES_SECTION_ENUM.TOUR]: (_theme: mui.core.Theme) => _theme.palette.warning.main,
  [enums.PREFERENCES_SECTION_ENUM.OTHER]: (_theme: mui.core.Theme) => _theme.palette.info.main,
  [enums.PREFERENCES_SECTION_ENUM.REPORT_LAYOUT]: (_theme: mui.core.Theme) => _theme.palette.orange.main,
  default: (_theme: mui.core.Theme) => _theme.palette.info.main,
};

const getResourceColor = (
  resource: enums.UI_RESOURCE_TYPE_ENUM | enums.PREFERENCES_SECTION_ENUM | undefined,
  theme: mui.core.Theme
): string => {
  const colorValue = RESOURCE_COLORS[resource] || RESOURCE_COLORS['default'];
  return typeof colorValue === 'function' ? colorValue(theme) : (colorValue as string);
};

type ResourceIconProps = {
  resource?: enums.UI_RESOURCE_TYPE_ENUM | enums.PREFERENCES_SECTION_ENUM;
  hasTooltip?: boolean;
};

/**
 * Component that provides icons for different resource types with optional tooltips
 *
 * @param resourceType - The type of resource to display the corresponding icon
 * @param showTooltip - Flag to indicate whether to show a tooltip
 * @returns Rendered icon component with optional tooltip
 */
const ResourceIcon: React.FC<ResourceIconProps> = ({ resource, hasTooltip = false }): React.ReactElement => {
  const theme = mui.styles.useTheme() as mui.core.Theme;

  // Create common sx prop for all icons
  const iconSx = { color: getResourceColor(resource, theme) };

  const icon = (
    <>
      {resource == enums.UI_RESOURCE_TYPE_ENUM.SIGNAL ? (
        <mui.icons.ShowChart fontSize="small" color="inherit" sx={iconSx} />
      ) : resource == enums.UI_RESOURCE_TYPE_ENUM.UNIVERSE ? (
        <mui.icons.Language fontSize="small" color="inherit" sx={iconSx} />
      ) : resource == enums.UI_RESOURCE_TYPE_ENUM.PIPELINE ? (
        <mui.icons.FilterAlt fontSize="small" color="inherit" sx={iconSx} />
      ) : resource == enums.UI_RESOURCE_TYPE_ENUM.WORKSPACE ? (
        <mui.icons.DesktopMacOutlined fontSize="small" color="inherit" sx={iconSx} />
      ) : resource == enums.UI_RESOURCE_TYPE_ENUM.BACKTEST ? (
        <mui.icons.SettingsBackupRestore fontSize="small" color="inherit" sx={iconSx} />
      ) : resource == enums.UI_RESOURCE_TYPE_ENUM.DATA_SOURCE ? (
        <mui.icons.CloudDownload fontSize="small" color="inherit" sx={iconSx} />
      ) : resource == enums.UI_RESOURCE_TYPE_ENUM.GENERAL_ANALYSIS ? (
        <mui.icons.BarChart fontSize="small" color="inherit" sx={iconSx} />
      ) : resource == enums.UI_RESOURCE_TYPE_ENUM.RISK_MODEL ? (
        <mui.icons.PermDataSetting fontSize="small" color="inherit" sx={iconSx} />
      ) : resource == enums.UI_RESOURCE_TYPE_ENUM.SIGNAL_SET ? (
        <mui.icons.GraphicEq fontSize="small" color="inherit" sx={iconSx} />
      ) : resource == enums.UI_RESOURCE_TYPE_ENUM.BACKTEST_SET ? (
        <mui.icons.Grain fontSize="small" color="inherit" sx={iconSx} />
      ) : resource == enums.UI_RESOURCE_TYPE_ENUM.BASKET ? (
        <mui.icons.ShoppingBasket fontSize="small" color="inherit" sx={iconSx} />
      ) : resource == enums.UI_RESOURCE_TYPE_ENUM.TIME_SERIES ? (
        <mui.icons.AccessTime fontSize="small" color="inherit" sx={iconSx} />
      ) : resource == enums.PREFERENCES_SECTION_ENUM.FIXED_COST ? (
        <mui.icons.AttachMoney fontSize="small" color="inherit" sx={iconSx} />
      ) : resource == enums.PREFERENCES_SECTION_ENUM.TOUR ? (
        <mui.icons.TourOutlined fontSize="small" color="inherit" sx={iconSx} />
      ) : resource == enums.PREFERENCES_SECTION_ENUM.OTHER ? (
        <mui.icons.Settings fontSize="small" color="inherit" sx={iconSx} />
      ) : resource == enums.PREFERENCES_SECTION_ENUM.REPORT_LAYOUT ? (
        <mui.icons.GridView fontSize="small" color="inherit" sx={iconSx} />
      ) : resource == enums.UI_RESOURCE_TYPE_ENUM.STRATEGY ? (
        <mui.icons.TrackChanges fontSize="small" color="inherit" sx={iconSx} />
      ) : resource == enums.UI_RESOURCE_TYPE_ENUM.PORTFOLIO ? (
        <mui.icons.WorkOutlined fontSize="small" color="inherit" sx={iconSx} />
      ) : (
        <mui.icons.Timeline fontSize="small" color="inherit" sx={iconSx} />
      )}
    </>
  );

  if (hasTooltip) {
    return (
      <mui.core.Tooltip arrow title={_.startCase(resource)}>
        <span>{icon}</span>
      </mui.core.Tooltip>
    );
  }

  return <>{icon}</>;
};

export default ResourceIcon;
