import { _, mui, React, ts } from '_core';

import { rebalanceValidation, transform } from './helpers';
import { Params } from './types';
import ChartWrapper from '../../common/chart-wrapper';
import createNestedBarChart from '../../common/charts/create-nested-bar-chart';

type ChartProps = {
  id: string;
  readOnly: boolean;
  widgetKey: string;
  portfolios: string;
  fullScreen: boolean;
  hasBenchmark: boolean;
  frequency: ts.enums.FREQUENCY_ENUM;
  params: Params;
  loadWidgetData: (_payload: ts.types.widgets.WidgetGetDataParams) => Promise<ts.types.widgets.WidgetGetDataResponse>;
  title: string;
  isBasket: boolean;
};

const Chart: React.FC<ChartProps> = ({
  id,
  readOnly,
  widgetKey,
  loadWidgetData,
  portfolios,
  hasBenchmark,
  params,
  fullScreen,
  frequency,
  title,
  isBasket,
}): React.ReactElement => {
  const [data, setData] = React.useState(null);
  const [error, setError] = React.useState(null);
  const [showLegend, setShowLegend] = React.useState(false);
  const [selectedTab, setSelectedTab] = React.useState<'ANNUAL' | 'MONTHLY'>('ANNUAL');

  const loadData = async () => {
    try {
      const response = await loadWidgetData({
        data: {
          file: portfolios,
          query: ['$and', ['statistic', 'equals', params.type], ['frequency', 'equals', selectedTab]],
          columns: ['date', 'portfolio', 'active'],
        },
      });

      if (response) {
        const resp = response;
        setData(resp.data);
      }
    } catch (err) {
      setError(JSON.stringify(err));
    }
  };

  React.useEffect(() => {
    if (portfolios) loadData();
    return () => setData(null);
  }, [portfolios, selectedTab]);

  React.useEffect(() => {
    const format = params.type !== 'adjusted' ? '#.##%' : '#.##';
    // Create line chart on data and scale changes
    if (!_.isNil(data))
      createNestedBarChart({
        id,
        data: data,
        fullScreen,
        setShowLegend,
        category: 'date',
        format,
        rotatedLabels: selectedTab != 'ANNUAL' ? true : false,
        exportTitle: title,
        prepareData: (nestedBarData) => transform(nestedBarData, hasBenchmark, isBasket),
      });
  }, [data]);

  return (
    <ChartWrapper
      id={id}
      readOnly={readOnly}
      widgetKey={widgetKey}
      data={data}
      showLegend={showLegend}
      warning={data?.warnings}
      error={error}
      fullScreen={fullScreen}
      tabs={
        <mui.core.Tabs
          indicatorColor="primary"
          textColor="primary"
          value={selectedTab}
          onChange={(e, newTab) => setSelectedTab(newTab)}
          aria-label="Return Horizon Tabs"
        >
          <mui.core.Tab label="Annual" value="ANNUAL" />
          {rebalanceValidation('MONTHLY', frequency, params.type) && <mui.core.Tab label="Monthly" value="MONTHLY" />}
          {rebalanceValidation('WEEKLY', frequency, params.type) && <mui.core.Tab label="Weekly" value="WEEKLY" />}
        </mui.core.Tabs>
      }
    />
  );
};

Chart.defaultProps = {
  hasBenchmark: false,
};

export default Chart;
