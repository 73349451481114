import { hooks, mui, React, ts, ui } from '_core';

import { DraftParams } from './types';

type FormProps = {
  params: DraftParams;
  setParams: (_p: DraftParams) => void;
  context?: ts.types.signal.SignalAnalysisContext;
};

const Form: React.FC<FormProps> = ({ params, setParams, context }) => {
  const [selectedReturnHorizon, setSelectedReturnHorizon] = React.useState(params.return_horizons);

  const uiStyles = hooks.useUiStyles();

  React.useEffect(() => {
    const data = { ...params, return_horizons: selectedReturnHorizon };

    setParams(data);
  }, [selectedReturnHorizon]);

  return (
    <mui.core.Box sx={uiStyles.widgetEditMode}>
      <mui.core.Box mt={4}>
        <ui.WidgetReturnHorizon<false>
          value={selectedReturnHorizon?.[0]}
          setValue={(val) => {
            setSelectedReturnHorizon([val]);
          }}
          context={context}
        />
      </mui.core.Box>
    </mui.core.Box>
  );
};

export default Form;
