// CAUTION - THIS WIDGET IS ALSO RENDERED ON GENERAL ANALYSIS */

import { React, ts } from '_core';

import Chart from './chart';
import Form from './form';
import { DraftParams, Params } from './types';

type TimeSeriesLineCompareProps = {
  id: string;
  readOnly: boolean;
  widgetKey: string;
  mode: ts.enums.CHART_MODE_ENUM;
  fullScreen?: boolean;
  params: Params | DraftParams;
  setParams: (_p: DraftParams) => void;
  context: ts.types.timeSeries.TimeSeriesAnalysisContext;
  title: string;
  subtitle?: string;

  isGeneralAnalysis: boolean;
  setExternalEmbed: (_json: ts.types.analysis.EmbedChartData) => void;

  loadWidgetData: (_payload: ts.types.widgets.WidgetGetDataParams) => Promise<ts.types.widgets.WidgetGetDataResponse>;
  widgetPaths: Record<'time_series_values_comparison', string>;
};

const TimeSeriesLineCompare: React.FC<TimeSeriesLineCompareProps> = ({
  id,
  readOnly,
  widgetKey,
  mode,
  params,
  setParams,
  context,
  loadWidgetData,
  widgetPaths,
  fullScreen,
  title,
  subtitle,
  isGeneralAnalysis,
  setExternalEmbed,
}) => {
  const renderJobComponent = () => (
    <Chart
      id={id}
      readOnly={readOnly}
      widgetKey={widgetKey}
      loadWidgetData={loadWidgetData}
      file={widgetPaths['time_series_values_comparison']}
      fullScreen={fullScreen}
      title={title}
      subtitle={subtitle}
      setExternalEmbed={setExternalEmbed}
    />
  );

  return (
    <>
      {mode == 'edit' ? (
        <Form params={params} setParams={setParams} context={isGeneralAnalysis ? null : context} />
      ) : (
        renderJobComponent()
      )}
    </>
  );
};

export default TimeSeriesLineCompare;
