import { ts } from '_core';

import { INTERACTION_PARAMS } from './types';

export default [
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.ALPHA_MODEL_OPTIMIZED_TABULAR_DATA,
    title: 'Optimized Model Report',
    helpLabel: '3w8nbtia',
    form: false,
    neededFiles: ['optimized_alpha_tabular_data'],
    interactionParams: Object.values(INTERACTION_PARAMS),
    clearInteractionOnRegenerate: ['table_dtypes'],
    tags: [
      ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.ALPHA_MODEL,
      ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.OPTIMIZED_ALPHA_MODEL,
    ],
  },
] satisfies ts.types.analysis.WidgetConfig[];
