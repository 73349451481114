import { helpers, ts } from '_core';

const nonSummaryColumns = [
  'tab',
  'benchmark',
  'market',
  'bucket',
  'stock_selection',
  'total',
  'statistics_type',
  'year',
  'interaction',
  'start_date',
  'end_date',
  'period_start',
  'period_end',
];

export default {
  [ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.BACKTEST_BUCKET_ANALYSIS_RETURN_DETAILS]: [
    {
      key: 'period_start',
      name: 'Start Date',
      formatter: helpers.tableFormatters.formatTo('date', { align: 'right', format: 'YYYY-MM-DD' }),
      filter: 'date',
    },
    {
      key: 'period_end',
      name: 'End Date',
      formatter: helpers.tableFormatters.formatTo('date', { align: 'right', format: 'YYYY-MM-DD' }),
      filter: 'date',
    },
    {
      key: 'benchmark',
      name: 'Benchmark',
      formatter: helpers.
        tableFormatters.formatTo('float', { align: 'right', roundDigits: 2, multiplier: 100, suffix: '%' }),
      filter: 'number',
    },
    {
      key: 'market',
      name: 'Market',
      formatter: helpers.
        tableFormatters.formatTo('float', { align: 'right', roundDigits: 2, multiplier: 100, suffix: '%' }),
      filter: 'number',
    },
    {
      key: 'bucket',
      name: 'Bucket',
      formatter: helpers.
        tableFormatters.formatTo('float', { align: 'right', roundDigits: 2, multiplier: 100, suffix: '%' }),
      filter: 'number',
    },
    {
      key: 'stock_selection',
      name: 'Stock Selection',
      formatter: helpers.
        tableFormatters.formatTo('float', { align: 'right', roundDigits: 2, multiplier: 100, suffix: '%' }),
      filter: 'number',
    },
    {
      key: 'total',
      name: 'Total',
      formatter: helpers.
        tableFormatters.formatTo('float', { align: 'right', roundDigits: 2, multiplier: 100, suffix: '%' }),
      filter: 'number',
    },
  ] as ts.types.components.dataGrid.ColumnsData,
  [ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.BACKTEST_BUCKET_ANALYSIS_RETURN_SUMMARY]: [
    {
      key: 'year',
      name: 'Year',
      formatter: helpers.tableFormatters.formatTo('string', { align: 'left' }),
      filter: 'string',
    },
    {
      key: 'start_date',
      name: 'Start Date',
      formatter: helpers.tableFormatters.formatTo('date', { align: 'right', format: 'YYYY-MM-DD' }),
      filter: 'date',
    },
    {
      key: 'end_date',
      name: 'End Date',
      formatter: helpers.tableFormatters.formatTo('date', { align: 'right', format: 'YYYY-MM-DD' }),
      filter: 'date',
    },
    {
      key: 'benchmark',
      name: 'Benchmark',
      formatter: helpers
        .tableFormatters.formatTo('float', { align: 'right', roundDigits: 2, multiplier: 100, suffix: '%' }),
      filter: 'number',
    },
    {
      key: 'market',
      name: 'Market',
      formatter: helpers
        .tableFormatters.formatTo('float', { align: 'right', roundDigits: 2, multiplier: 100, suffix: '%' }),
      filter: 'number',
    },
    {
      key: 'bucket',
      name: 'Bucket',
      formatter: helpers
        .tableFormatters.formatTo('float', { align: 'right', roundDigits: 2, multiplier: 100, suffix: '%' }),
      filter: 'number',
    },
    {
      key: 'stock_selection',
      name: 'Stock Selection',
      formatter: helpers
        .tableFormatters.formatTo('float', { align: 'right', roundDigits: 2, multiplier: 100, suffix: '%' }),
      filter: 'number',
    },
    {
      key: 'interaction',
      name: 'Interaction',
      formatter: helpers
        .tableFormatters.formatTo('float', { align: 'right', roundDigits: 2, multiplier: 100, suffix: '%' }),
      filter: 'number',
    },
    {
      key: 'total',
      name: 'Total',
      formatter: helpers
        .tableFormatters.formatTo('float', { align: 'right', roundDigits: 2, multiplier: 100, suffix: '%' }),
      filter: 'number',
    },
  ] as ts.types.components.dataGrid.ColumnsData,
  [ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.BACKTEST_BUCKET_ANALYSIS_BUCKET_ALLOCATION_DETAILS]: [
    {
      key: 'period_start',
      name: 'Start Date',
      formatter: helpers.tableFormatters.formatTo('date', { align: 'right', format: 'YYYY-MM-DD' }),
      filter: 'date',
    },
    {
      key: 'period_end',
      name: 'End Date',
      formatter: helpers.tableFormatters.formatTo('date', { align: 'right', format: 'YYYY-MM-DD' }),
      filter: 'date',
    },
    {
      key: '_dynamic_',
      name: 'Dynamic',
      members: (key) => !nonSummaryColumns.includes(key),
      nameFormatter: (key: string) => {
        if (Number.isInteger(parseFloat(key))) {
          return `Q${parseInt(key)}`;
        }
        return key;
      },
      formatter: helpers
        .tableFormatters.formatTo('float', { align: 'right', roundDigits: 2, multiplier: 100, suffix: '%' }),
      filter: 'inferred',
    },
  ] as ts.types.components.dataGrid.ColumnsData,
  [ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.BACKTEST_BUCKET_ANALYSIS_BUCKET_ALLOCATION_SUMMARY]: [
    {
      key: 'year',
      name: 'Year',
      formatter: helpers.tableFormatters.formatTo('string', { align: 'left' }),
      filter: 'string',
    },
    {
      key: 'start_date',
      name: 'Start Date',
      formatter: helpers.tableFormatters.formatTo('date', { align: 'right', format: 'YYYY-MM-DD' }),
      filter: 'date',
    },
    {
      key: 'end_date',
      name: 'End Date',
      formatter: helpers.tableFormatters.formatTo('date', { align: 'right', format: 'YYYY-MM-DD' }),
      filter: 'date',
    },
    {
      key: '_dynamic_',
      name: 'Dynamic',
      members: (key) => !nonSummaryColumns.includes(key),
      nameFormatter: (key: string) => {
        if (Number.isInteger(parseFloat(key))) {
          return `Q${parseInt(key)}`;
        }
        return key;
      },
      formatter: helpers
        .tableFormatters.formatTo('float', { align: 'right', roundDigits: 2, multiplier: 100, suffix: '%' }),
      filter: 'inferred',
    },
  ] as ts.types.components.dataGrid.ColumnsData,
  [ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.BACKTEST_BUCKET_ANALYSIS_BUCKET_STOCK_SELECTION_DETAILS]: [
    {
      key: 'period_start',
      name: 'Start Date',
      formatter: helpers.tableFormatters.formatTo('date', { align: 'right', format: 'YYYY-MM-DD' }),
      filter: 'date',
    },
    {
      key: 'period_end',
      name: 'End Date',
      formatter: helpers.tableFormatters.formatTo('date', { align: 'right', format: 'YYYY-MM-DD' }),
      filter: 'date',
    },
    {
      key: '_dynamic_',
      name: 'Dynamic',
      members: (key) => !nonSummaryColumns.includes(key),
      nameFormatter: (key: string) => {
        if (Number.isInteger(parseFloat(key))) {
          return `Q${parseInt(key)}`;
        }
        return key;
      },
      formatter: helpers
        .tableFormatters.formatTo('float', { align: 'right', roundDigits: 2, multiplier: 100, suffix: '%' }),
      filter: 'inferred',
    },
  ] as ts.types.components.dataGrid.ColumnsData,
  [ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.BACKTEST_BUCKET_ANALYSIS_BUCKET_STOCK_SELECTION_SUMMARY]: [
    {
      key: 'year',
      name: 'Year',
      formatter: helpers.tableFormatters.formatTo('string', { align: 'left' }),
      filter: 'string',
    },
    {
      key: 'start_date',
      name: 'Start Date',
      formatter: helpers.tableFormatters.formatTo('date', { align: 'right', format: 'YYYY-MM-DD' }),
      filter: 'date',
    },
    {
      key: 'end_date',
      name: 'End Date',
      formatter: helpers.tableFormatters.formatTo('date', { align: 'right', format: 'YYYY-MM-DD' }),
      filter: 'date',
    },
    {
      key: '_dynamic_',
      name: 'Dynamic',
      members: (key) => !nonSummaryColumns.includes(key),
      nameFormatter: (key: string) => {
        if (Number.isInteger(parseFloat(key))) {
          return `Q${parseInt(key)}`;
        }
        return key;
      },
      formatter: helpers
        .tableFormatters.formatTo('float', { align: 'right', roundDigits: 2, multiplier: 100, suffix: '%' }),
      filter: 'inferred',
    },
  ] as ts.types.components.dataGrid.ColumnsData,
};
