import { _, config, helpers, ts } from '_core';

export const removeQuotationCountry = (def: ts.types.universe.DefinitionDraft): ts.types.universe.DefinitionDraft => {
  const definition = { ...def };
  const assets = def.assets;

  const countries = [...definition.quotation_countries];
  let newCountries = [] as string[];

  _.forEach(assets, (asset) => {
    if (_.isArray(asset)) {
      if (asset[0] == 'quotation_country') {
        const index = assets.indexOf(asset);
        assets.splice(index, 1);

        newCountries = countries.concat(asset[2]);
      }
    }
  });

  if (!_.isEmpty(newCountries)) definition.quotation_countries = newCountries;
  definition.assets = assets;

  return definition;
};

export const getNewUniverse = (
  name: string,
  handle: string,
  template: ts.types.universe.Universe = null,
  userProvided = false
) => {
  const nullDefinition: ts.types.universe.DefinitionDraft = {
    assets: [],
    source: {
      type: userProvided ? 'user_provided' : 'asset_master',
    },
    limit: {
      limit: _.min([config.features.universe_limit, 2000]),
      order_by: 'marketcap_usd',
      sort: 'desc',
      deadband: 0,
    },
    quotation_countries: ['US'],
    include: { assets: [], universes: [] },
    exclude: { assets: [], universes: [] },
    keep_all_linked_assets: true,
  };

  const newUniverse: ts.types.universe.UniverseDraft = {
    name,
    handle,
    is_valid: true,
    definition: nullDefinition,
    start_date: config.features.start_date,
    end_date: config.features.end_date,
    end_date_latest: false,
    frequency: ts.enums.FREQUENCY_ENUM.DAILY,
    frequency_rebalance_month: 12,
  };

  // Check if we will create the universe with a premade definition
  if (template && template.id) {
    newUniverse.definition = {
      ...template.definition,
      limit: _.isEmpty(template.definition?.limit)
        ? {
            limit: config.features.universe_limit,
            order_by: 'marketcap_usd',
            sort: 'desc',
          }
        : template.definition.limit,
    };
    newUniverse.start_date = template.start_date;
    newUniverse.end_date = template.end_date;
    newUniverse.end_date_latest = template.end_date_latest;
    newUniverse.signature = template.signature;
    newUniverse.frequency = template.frequency;
    newUniverse.frequency_rebalance_month = template.frequency_rebalance_month;
    newUniverse.is_valid = template.is_valid;
    newUniverse.label_ids = template.label_ids;
  }
  return newUniverse;
};

export const getArchivedDeps = (
  universe: ts.types.universe.Universe,
  resources: ts.StoreState['resources']
): ts.types.common.ArchivedDeps => {
  const deps = helpers.resourceGraph.getDeps(
    { ...universe, resourceType: ts.enums.RESOURCES_TYPES_ENUM.UNIVERSE },
    resources
  );
  return helpers.resourceGraph.getArchiveDeps(deps);
};

export const getUnpublishedDeps = (
  universe: ts.types.universe.Universe,
  resources: ts.StoreState['resources']
): ts.types.common.ResourceDeps[] => {
  return helpers.resourceGraph
    .getDeps({ ...universe, resourceType: ts.enums.RESOURCES_TYPES_ENUM.UNIVERSE }, resources)
    .filter((d) => !d.is_published);
};

export const getUnsharedDeps = (
  universe: ts.types.universe.Universe,
  resources: ts.StoreState['resources'],
  sharedWith?: number[]
): ts.types.common.ResourceDeps[] => {
  if (!sharedWith) sharedWith = universe.shared_with || [];

  return helpers.resourceGraph
    .getDeps({ ...universe, resourceType: ts.enums.RESOURCES_TYPES_ENUM.UNIVERSE }, resources)
    .filter((d) => !helpers.resourcesUtils.isShared(d, sharedWith));
};
