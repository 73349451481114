import { _, mui, React, ts, ui } from '_core';

type SharesOutstandingProps = {
  id: string;
  params: ts.types.optimizer.SharesOutstadingDraft;
  setParams: (_params: ts.types.optimizer.SharesOutstadingDraft) => void;
  readOnly: boolean;
  validationDef: ts.types.common.ValidationErrors;
  dates: {
    start: string;
    end: string;
  };
};

const SharesOutstandingConstraint: React.FC<SharesOutstandingProps> = ({
  id,
  params,
  setParams,
  readOnly,
  dates,
  validationDef,
}): React.ReactElement => {
  const [allAssets, setAllAssets] = React.useState(_.isEmpty(params.asset_fids));

  return (
    <mui.core.Box p={3}>
      <mui.core.Grid container spacing={4} pb={3}>
        <mui.core.Grid item xs={12} md={6}>
          <ui.NumericField
            textFieldProps={{
              label: 'Shares Outstanding Lower Bound %',
              fullWidth: true,
              variant: 'outlined',
              size: 'small',
              error: validationDef?.fields?.includes(`lower_shares_outstanding_bound_${id}`),
            }}
            disabled={readOnly}
            suffix="%"
            dividerOption={ts.enums.NUMBER_FORMATTERS_ENUM.HUNDRED}
            value={params.lower_shares_outstanding_bound}
            setValue={(v) =>
              setParams({
                ...params,
                lower_shares_outstanding_bound: v,
              })
            }
          />
        </mui.core.Grid>

        <mui.core.Grid item xs={12} md={6}>
          <ui.NumericField
            textFieldProps={{
              label: 'Shares Outstanding Upper Bound %',
              fullWidth: true,
              variant: 'outlined',
              size: 'small',
              error: validationDef?.fields?.includes(`upper_shares_outstanding_bound_${id}`),
            }}
            disabled={readOnly}
            suffix="%"
            dividerOption={ts.enums.NUMBER_FORMATTERS_ENUM.HUNDRED}
            value={params.upper_shares_outstanding_bound}
            setValue={(v) =>
              setParams({
                ...params,
                upper_shares_outstanding_bound: v,
              })
            }
          />
        </mui.core.Grid>
        <mui.core.Grid item xs={12} md={3}>
          <mui.core.FormControlLabel
            style={{
              paddingLeft: '16px',
            }}
            control={
              <mui.core.Switch
                size="small"
                checked={allAssets}
                onChange={() => {
                  setAllAssets(!allAssets);
                  if (!allAssets) {
                    setParams({
                      ...params,
                      asset_fids: [],
                    });
                  }
                }}
                disabled={readOnly}
              />
            }
            label="All Assets"
          />
        </mui.core.Grid>
        {!allAssets && (
          <mui.core.Grid item xs={12} md={9}>
            <ui.AssetSelector
              value={params.asset_fids as string[]}
              setValue={(val: ts.types.assetSelector.AssetSelectorValueObject) => {
                setParams({
                  ...params,
                  asset_fids: val as string[],
                });
              }}
              createId={(a) => `${a.fid}`}
              disabled={readOnly || allAssets}
              dates={dates}
              fields={[
                { title: 'Ticker', key: 'ticker' },
                { title: 'Name', key: 'name' },
                { title: 'Country', key: 'quotation_country' },
              ]}
              tableView={true}
              required={false}
              fieldLabel="Select Specific Assets"
              multiple
              errorState={validationDef?.fields?.includes(`asset_${id}`)}
            />
          </mui.core.Grid>
        )}
      </mui.core.Grid>
    </mui.core.Box>
  );
};

export default SharesOutstandingConstraint;
