import { helpers, ts } from '_core';

import widgets from '../../';

const getSetParams = (signalWidgets: ts.types.job.JobRequests) => {
  return signalWidgets.map((sw) => ({
    file: Object.keys(sw)[0],
    id: sw[Object.keys(sw)[0]].id,
    signature: sw[Object.keys(sw)[0]].signature,
    file_params: sw[Object.keys(sw)[0]].params,
  }));
};

export const getBrowserWidgets = (defaultDef: ts.types.analysis.AnalysisDefinition) => {
  const widgetConf = (widget: ts.types.analysis.Widget) => widgets.find((w) => w.key == widget.key);

  const rawWidgets = (defaultDef?.widgets || []).filter((c) => widgetConf(c)).map((c) => ({ ...c, ...widgetConf(c) }));

  const setParamWidgets = helpers.reports.getJobRequests(rawWidgets) as ts.types.job.JobRequests;
  return getSetParams(setParamWidgets);
};
