import { REPORT_TAG_ENUM, WIDGET_KEY_ENUM } from '../enums/report';

/* eslint-disable no-unused-vars */
export enum FIXED_KEYS_ENUM {
  INITIAL_LOADING = 'initial_loading',
  INITIAL_LOADING_AFTER_LOGIN = 'initial_loading_after_login',
}

export enum FIXED_LABELS_ENUM {
  LOADING = 'loading',
}

export const KEYS_ENUM = { ...FIXED_KEYS_ENUM, ...WIDGET_KEY_ENUM };
export type ProfilerKeyType = (typeof KEYS_ENUM)[keyof typeof KEYS_ENUM];

export const LABELS_ENUM = { ...FIXED_LABELS_ENUM, ...REPORT_TAG_ENUM };
export type LabelKeyType = (typeof LABELS_ENUM)[keyof typeof LABELS_ENUM];

export type Log = {
  key: ProfilerKeyType;
  group_key: string;
  phase: 'mount' | 'unmount' | 'update' | 'total';
  duration: number;

  parent_states?: Record<string, any>;
  profiler_interactions?: string;
  created_at?: string;
};

export type TableLog = {
  key: ProfilerKeyType;
  group_key: string;
  phase: 'mount' | 'unmount' | 'update' | 'total';
  duration: number;

  parent_states?: Record<string, any>;
  profiler_interactions?: string;

  created_at?: string;
  organization?: string;
  created_at_date?: string;
  created_at_ts?: string;

  aggregation?: number;
  group_label?: string;
  agg_label?: string;
};
