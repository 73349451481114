import { React } from '_core';

import { AsyncDataGridContextProvider, ProviderProps } from './async-context';
import DataGrid from './data-grid';

const AsyncDataGrid: React.FC<ProviderProps> = (props): React.ReactElement => {
  return (
    <AsyncDataGridContextProvider {...props}>
      <DataGrid />
    </AsyncDataGridContextProvider>
  );
};

export default AsyncDataGrid;
