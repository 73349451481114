import { _, helpers, ts } from '_core';

import { DraftParams, Params } from './types';

export const formatSeriesName = (key: string, baskets: ts.types.basket.Basket[]) => {
  const currentBasket = baskets.find((b) => b.handle == key);
  if (currentBasket) return currentBasket.name;

  return key;
};

export const prepareDataSummaryStats = (
  localData: {
    chart: ts.types.widgets.RadarData;
    table: ts.types.widgets.TableData;
  },
  params: Params,
  context: ts.types.basket.BasketAnalysisContext,
  baskets: ts.types.basket.Basket[]
) => {
  const dimensions = new Set([]);
  const newData = {} as {
    chart: ts.types.widgets.RadarData;
    table: ts.types.widgets.TableData;
    dimensions: string[];
  };
  newData.chart = localData.chart.map((el) => {
    const newEl = {} as ts.types.widgets.RadarData[0];
    Object.keys(el).forEach((key) => {
      const newKey = formatSeriesName(key.split('-')[0], baskets);
      if (key !== 'spoke') dimensions.add(newKey);
      newEl[newKey] = el[key];
    });
    return newEl;
  });

  newData.table = localData.table.map((el) => {
    const newEl = {} as ts.types.widgets.TableData[0];
    Object.keys(el).forEach((key) => {
      const newKey = formatSeriesName(key.split('-')[0], baskets);
      newEl[newKey] = el[key];
    });
    return newEl;
  });
  newData.dimensions = Array.from(dimensions);

  // SORT BY USER SELETED ORDER
  if (params.basket_ids.includes(context.basket_id)) {
    const newDimensions = [] as string[];
    params.basket_ids.map((bid) => {
      const currentBasket = baskets.find((basket) => basket.id === bid)?.name;
      if (currentBasket) newDimensions.push(currentBasket);
    });
    newData.dimensions = newDimensions;
  }

  return newData;
};

export const validate = (params: DraftParams) => {
  const basketCompareErrors = helpers.validations.validBasketCompare(params);
  if (!_.isEmpty(basketCompareErrors)) return basketCompareErrors;
  if (_.isEmpty(params.histories)) return ['Histories is a required field.'];
  return [];
};

export const getName = (params: DraftParams) => {
  return `${_.startCase(params.histories?.[0] || '')} Summary Statistics`;
};

export const formatData = (data: ts.types.widgets.TableData): ts.types.widgets.TableData => {
  const groupedData = _.groupBy(data, 'spoke');

  const formattedData = _.map(groupedData, (items, spoke) => {
    const formattedEntry: ts.types.widgets.TableData[number] = { spoke };

    const dynamicKeys = Object.keys(items[0]).filter((key) => key !== 'backtest_handle' && key !== 'spoke');

    dynamicKeys.forEach((dynamicKey) => {
      items.forEach((item) => {
        const { backtest_handle } = item;
        const value = item[dynamicKey];

        formattedEntry[`${backtest_handle}-${dynamicKey}`] = value;
      });
    });

    return formattedEntry;
  });

  return formattedData;
};
