import { _, React, ts } from '_core';

import { Params } from './types';
import ChartWrapper from '../../common/chart-wrapper';
import createNestedBarChart from '../../common/charts/create-nested-bar-chart';

type ChartProps = {
  id: string;
  readOnly: boolean;
  widgetKey: string;
  portfolios: string;
  fullScreen: boolean;
  params: Params;
  title: string;
  loadWidgetData: (_payload: ts.types.widgets.WidgetGetDataParams) => Promise<ts.types.widgets.WidgetGetDataResponse>;
};

const Chart: React.FC<ChartProps> = ({
  id,
  readOnly,
  widgetKey,
  fullScreen,
  loadWidgetData,
  portfolios,
  title,
}): React.ReactElement => {
  const [data, setData] = React.useState<ts.types.widgets.TableData>(null);
  const [error, setError] = React.useState<ts.types.common.ApiError>(null);
  const [showLegend, setShowLegend] = React.useState(false);

  const loadData = async () => {
    try {
      const response = await loadWidgetData({
        data: {
          file: portfolios,
        },
      });
      if (response) setData(response.data);
    } catch (err) {
      setError(err as ts.types.common.ApiError);
    }
  };

  React.useEffect(() => {
    if (portfolios) loadData();
    return () => setData(null);
  }, [portfolios]);

  React.useEffect(() => {
    const format = '#.####';

    if (!_.isNil(data))
      createNestedBarChart({
        id,
        data,
        category: 'date',
        tooltipText: '[bold]Turnover:[/] {valueY}',
        fullScreen,
        setShowLegend,
        format,
        exportTitle: title,
        prepareData: (nestedData) => {
          return {
            ...nestedData,
            data: nestedData.data.map((row) => {
              const newRow = { ...row, Turnover: row['turnover'] } as Record<string, number>;
              delete newRow['turnover'];
              return newRow;
            }),
          };
        },
      });
  }, [data]);

  return (
    <ChartWrapper
      id={id}
      readOnly={readOnly}
      widgetKey={widgetKey}
      data={data}
      showLegend={showLegend}
      error={error}
      fullScreen={fullScreen}
    />
  );
};

export default Chart;
