import { ts } from '_core';

import { validate } from './helpers';
import { DraftParams, INTERACTION_PARAMS } from './types';

export default [
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.RISK_MODEL_PERCENT_SIGNIFICANCE,
    title: 'Risk Factor Percent Significance',
    helpLabel: 'tgbvnblb',
    form: true,
    interactionParams: Object.values(INTERACTION_PARAMS),
    neededFiles: ['risk_model_percent_significance'],
    tags: [
      ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.RISK_MODEL,
      ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.FINSERA_RISK_MODEL
    ],
    defaultParams: {
      critical_pvalue: 0.05,
      risk_factors: [{ type: 'risk_model', value: 'risk_model' }],
    } as DraftParams,

    validate,
  },
] satisfies ts.types.analysis.WidgetConfig[];
