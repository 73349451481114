
import * as apiProcedures from './api-procedures';
import validateColumns from './holdings-validation';
import * as enums from '../../../enums';
import { _ } from '../../../libs';
import * as types from '../../../types';

export { validateColumns };

export { apiProcedures };


export const getAvailableColumns = (fileData: string) => {
  const lines = fileData.split('\n');
  let sep = enums.SEPARATORS_ENUM.COMMA;

  if (fileData.includes(enums.SEPARATORS_ENUM.TAB)) sep = enums.SEPARATORS_ENUM.TAB;
  const columnNames = lines[0].split(sep);

  return columnNames;
};

export const manualMap = (
  row: types.rebalance.HoldingsData[0],
  metadata: types.assetSelector.AssetSelectorMetaData,
  setUpdatedRows: (_value: React.SetStateAction<types.rebalance.HoldingsData>) => void,
  clearMappingRow: () => void
) => {
  const newRow = { ...row };

  if (!_.isUndefined(row['ticker'])) newRow['ticker'] = metadata['ticker'];
  if (!_.isUndefined(row['finsera_ticker'])) newRow['finsera_ticker'] = metadata['ticker'];

  if (!_.isUndefined(row['cusip'])) newRow['cusip'] = metadata['cusip'];
  if (!_.isUndefined(row['finsera_cusip'])) newRow['finsera_cusip'] = metadata['cusip'];

  if (!_.isUndefined(row['country'])) newRow['country'] = metadata['quotation_country'];
  if (!_.isUndefined(row['finsera_country'])) newRow['finsera_country'] = metadata['quotation_country'];

  if (!_.isUndefined(row['fid'])) newRow['fid'] = metadata['fid'];
  if (!_.isUndefined(row['finsera_fid'])) newRow['finsera_fid'] = metadata['fid'];

  if (!_.isUndefined(row['name'])) newRow['name'] = metadata['name'];
  if (!_.isUndefined(row['finsera_name'])) newRow['finsera_name'] = metadata['name'];

  newRow['error_message'] = '';

  setUpdatedRows((rows) => {
    if (rows.find((r) => r._row_number == row._row_number)) {
      return rows.map((r) => {
        if (r._row_number == row._row_number) return newRow;
        return r;
      });
    }
    return [...rows, newRow];
  });

  clearMappingRow();
};
