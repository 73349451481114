/* eslint-disable no-unused-vars */
import { ts } from '_core';

export enum INTERACTION_PARAMS {
  IS_BASKET = 'isBasket',
  CURRENT_SHEET = 'current_sheet',
  TABLE_PARAMS = 'table_params',
  TABLE_DTYPES = 'table_dtypes',
}

export type SHEETS =
  | ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.BACKTEST_PORTFOLIO_RISK_SUMMARY
  | ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.BACKTEST_PORTFOLIO_RISK_FACTOR_CONTRIBUTION
  | ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.BACKTEST_PORTFOLIO_RISK_FACTOR_EXPOSURE
  | ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.BACKTEST_PORTFOLIO_RISK_SECURITY_CONTRIBUTION;

export type InteractionParams = {
  [INTERACTION_PARAMS.IS_BASKET]: boolean;
  [INTERACTION_PARAMS.CURRENT_SHEET]: SHEETS;
  [INTERACTION_PARAMS.TABLE_PARAMS]: ts.types.components.dataGrid.TableParamsSheet;
  [INTERACTION_PARAMS.TABLE_DTYPES]: ts.types.components.dataGrid.TableDtypesSheet;
};

export type JobParams = {
  start_date: string;
  end_date: string;
};

export type Params = JobParams & InteractionParams;
export type DraftParams = Partial<Params>;
