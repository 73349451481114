import { config, mui, React, ui } from '_core';

import { DraftParams } from './types';

type FormProps = {
  params: DraftParams;
  setParams: (_p: DraftParams) => void;

  startDate?: string;
  endDate?: string;
  universeId?: number;
};

const Form: React.FC<FormProps> = ({ params, setParams, startDate, endDate, universeId }) => {
  const [previewDate, setPreviewDate] = React.useState(params.preview_end_date || endDate);
  const [debug, setDebug] = React.useState(params.debug);

  const [componentErrors, setComponentErrors] = React.useState([]);

  React.useEffect(() => {
    const data = {
      preview_start_date: previewDate,
      preview_end_date: previewDate,
      component_errors: componentErrors,
      debug,
    };

    setParams({ ...params, ...data });
  }, [previewDate, debug, componentErrors]);

  return (
    <>
      <mui.core.Box mt={2}>
        <mui.core.FormControlLabel
          control={
            <mui.core.Switch
              size="small"
              checked={debug}
              onChange={() => {
                setDebug(!debug);
              }}
            />
          }
          label="Debug"
        />
      </mui.core.Box>

      <mui.core.Box my={4}>
        <ui.SmartDateSelector
          label="Date"
          value={previewDate}
          setValue={(date) => setPreviewDate(date)}
          minDate={startDate || config.features.start_date}
          maxDate={endDate || config.features.end_date}
          universeId={universeId}
          setExternalError={(e) => setComponentErrors(e ? [e] : [])}
        />
      </mui.core.Box>
    </>
  );
};

Form.defaultProps = {
  startDate: null,
  endDate: null,
};

export default Form;
