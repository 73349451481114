import { helpers, ts } from '_core';

import { getParamName, isMember } from 'views/backtest-sets/backtest-set-utils/definition';

export default {
  [ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.SUMMARY]: [
    {
      key: 'start_date',
      name: 'Start Date',
      filter: 'date',
      formatter: helpers.tableFormatters.formatTo('date', { align: 'right', format: 'YYYY-MM-DD' }),
    },
    {
      key: 'end_date',
      name: 'End Date',
      filter: 'date',
      formatter: helpers.tableFormatters.formatTo('date', { align: 'right', format: 'YYYY-MM-DD' }),
    },
    {
      key: '_parameters',
      name: 'Parameters',
      members: isMember,
      cleanNameFormatter: (key) => getParamName(key),
      nameFormatter: (key) => getParamName(key),
      formatter: helpers.tableFormatters.formatTo('float', { align: 'right', roundDigits: 2 }),
      filter: 'number',
    },
    {
      key: 'average_optimal_gross_leverage',
      name: 'Average Optimal Gross Leverage',
      formatter: helpers.tableFormatters.formatTo('float', { align: 'right', roundDigits: 2 }),
      filter: 'number',
    },
    {
      key: 'average_optimal_net_leverage',
      name: 'Average Optimal Net Leverage',
      formatter: helpers.tableFormatters.formatTo('float', { align: 'right', roundDigits: 2 }),
      filter: 'number',
    },
    {
      key: 'average_optimal_cash_weight',
      name: 'Average Optimal Cash Weight',
      formatter: helpers.tableFormatters.formatTo('float', { align: 'right', roundDigits: 2 }),
      filter: 'number',
    },
    {
      key: 'cumulative_after_costs_portfolio_return',
      name: 'Cumulative After Costs Portfolio Return',
      formatter: helpers.tableFormatters.formatTo('float', {
        align: 'right',
        roundDigits: 2,
        multiplier: 100,
        suffix: '%',
      }),
      filter: 'number',
    },
    {
      key: 'annualized_after_costs_portfolio_return',
      name: 'Annualized After Costs Portfolio Return',
      formatter: helpers.tableFormatters.formatTo('float', {
        align: 'right',
        roundDigits: 2,
        multiplier: 100,
        suffix: '%',
      }),
      filter: 'number',
    },
    {
      key: 'annualized_after_costs_portfolio_risk',
      name: 'Annualized After Costs Portfolio Risk',
      formatter: helpers.tableFormatters.formatTo('float', {
        align: 'right',
        roundDigits: 2,
        multiplier: 100,
        suffix: '%',
      }),
      filter: 'number',
    },
    {
      key: 'annualized_after_costs_portfolio_risk_adjusted_return',
      name: 'Annualized After Costs Portfolio Risk Adjusted Return',
      formatter: helpers.tableFormatters.formatTo('float', { align: 'right', roundDigits: 2 }),
      filter: 'number',
    },
    {
      key: 'cumulative_benchmark_return',
      name: 'Cumulative Benchmark Return',
      formatter: helpers.tableFormatters.formatTo('float', {
        align: 'right',
        roundDigits: 2,
        multiplier: 100,
        suffix: '%',
      }),
      filter: 'number',
    },
    {
      key: 'annualized_benchmark_risk',
      name: 'Annualized Benchmark Risk',
      formatter: helpers.tableFormatters.formatTo('float', {
        align: 'right',
        roundDigits: 3,
        multiplier: 100,
        suffix: '%',
      }),
      filter: 'number',
    },
    {
      key: 'annualized_benchmark_risk_adjusted_return',
      name: 'Annualized Benchmark Risk Adjusted Return',
      formatter: helpers.tableFormatters.formatTo('float', { align: 'right', roundDigits: 2 }),
      filter: 'number',
    },
    {
      key: 'average_turnover',
      name: 'Average Turnover',
      formatter: helpers.tableFormatters.formatTo('float', { align: 'right', roundDigits: 2 }),
      filter: 'number',
    },
    {
      key: 'average_transaction_cost_per_turnover',
      name: 'Average Transaction Cost Per Turnover (BPS)',
      formatter: helpers.tableFormatters.formatTo('float', { align: 'right', roundDigits: 2 }),
      filter: 'number',
    },
    {
      key: 'average_universe_name_count',
      name: 'Average Universe Name Count',
      formatter: helpers.tableFormatters.formatTo('integer', { align: 'right', commasOnThousands: true }),
      filter: 'number',
    },
    {
      key: 'average_name_count_for_long_names',
      name: 'Average Name Count For Long Names',
      formatter: helpers.tableFormatters.formatTo('integer', { align: 'right', commasOnThousands: true }),
      filter: 'number',
    },
    {
      key: 'average_name_count_for_short_names',
      name: 'Average Name Count For Short Names',
      formatter: helpers.tableFormatters.formatTo('integer', { align: 'right', commasOnThousands: true }),
      filter: 'number',
    },
  ] as ts.types.components.dataGrid.ColumnsData,
};
