import { _, ts } from '_core';


export default [
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.DOWNLOAD_FILES,
    title: 'Download Raw Files',
    helpLabel: 'rrbukw63',
    form: false,
    tags: _.without(
      Object.values(ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM),
      ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.SIGNAL_ADHOC,
      ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.BASKET_ADHOC
    ),

    development: true,
  },
] satisfies ts.types.analysis.WidgetConfig[];
