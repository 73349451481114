import { _, actions, config, helpers, hooks, React, ts, useDispatch, useHistory, useSelector } from '_core';

import * as utils from './risk-model-utils';

type ProviderProps = {
  id: number;
  children: React.ReactNode;
};
type RiskModelContextTypes = {
  alert: ts.types.common.Alert;
  analysesNames: ts.enums.RISK_MODEL_ANALYSIS_TYPE_ENUM[];
  archivedDeps: ts.types.common.ArchivedDeps;
  currentTab: ts.types.riskModel.RiskModelTab;
  definition: ts.types.riskModel.DefinitionDraft & { noValidate?: boolean };
  definitionChanged: boolean;
  depsTimestamp: {
    dependency: string;
    timestamp: any;
  }[];
  isExternalRiskModel: boolean;
  keepCurrent: () => Promise<void>;
  resources: ts.StoreState['resources'];
  uiMetadata: ts.types.riskModel.UiMetaData;
  setUiMetadata: (_m: ts.types.riskModel.UiMetaData) => void;
  riskModel: ts.types.riskModel.RiskModelDraft;
  setAlert: (_a: ts.types.common.Alert) => void;
  setDefinition: (_d: ts.types.riskModel.DefinitionDraft & { noValidate?: boolean }) => void;
  setDefinitionChanged: (_dc: boolean) => void;
  setRiskModel: (_r: ts.types.riskModel.RiskModel) => void;
  setRightDrawer: (_v: ts.types.riskModel.RightDrawer) => void;
  rightDrawer: ts.types.riskModel.RightDrawer;
  universes: ts.types.universe.Universe[];
  updateDefinition: (_d?: ts.types.riskModel.DefinitionDraft, _ts?: boolean, _valid?: boolean) => Promise<void>;
  updateRiskModel: (_p: ts.types.riskModel.RiskModelDraft) => Promise<void>;
  user: ts.types.user.User;
  validating: boolean;
  validationDef: ts.types.common.ValidationErrors;
  organization: ts.types.organization.Organization;
};

const RiskModelContext = React.createContext<RiskModelContextTypes>(null);

// This context provider is passed to any component requiring the context
const Provider: React.FC<ProviderProps> = ({ id, children }): React.ReactElement => {
  const resources = useSelector((state) => state.resources);
  const organization = useSelector((state) => state.auth.organization);
  const user = useSelector((state) => state.auth.currentUser);
  const documentationOpen = useSelector((state) => state.ui.documentSidebarOpen);

  const universes = resources.universes;

  const history = useHistory();
  const dispatch = useDispatch();
  const setDocumentSidebarOpen = (o: boolean) => dispatch(actions.ui.setDocumentSidebarOpen(o));

  const [riskModel, setRiskModel] = React.useState<ts.types.riskModel.RiskModelDraft>({
    definition: null,
  });
  // example: {message: 'something', severity: ts.enums.ALERT_SEVERITY_ENUM.ERROR}
  const [alert, setAlert] = React.useState<ts.types.common.Alert>();
  const [analysesNames, setAnalysesNames] = React.useState<ts.enums.RISK_MODEL_ANALYSIS_TYPE_ENUM[]>([]);
  // separate definition for reactiveness
  const [definition, setDefinition] = React.useState<ts.types.riskModel.DefinitionDraft & { noValidate?: boolean }>({});
  // handle the state of the validation
  const [validationDef, setValidationDef] = React.useState<ts.types.common.ValidationErrors>();
  const [definitionChanged, setDefinitionChanged] = React.useState(false);
  const [validating, setValidating] = React.useState(false);

  const [uiMetadata, setUiMetadata] = React.useState<ts.types.riskModel.UiMetaData>();

  const [rightDrawer, setRightDrawer] = React.useState<ts.types.riskModel.RightDrawer>(null);

  const path = history.location.pathname;
  const currentTab = React.useMemo(() => utils.getCurrentTab(path), [path]);

  // Archived deps
  const archivedDeps = React.useMemo(
    () => utils.definition.getArchivedDeps(riskModel as ts.types.riskModel.RiskModel, resources),
    [riskModel]
  );

  // Use hook to validate definition changes
  hooks.useDefinitionValidate<ts.types.riskModel.DefinitionDraft>({
    id,
    validate: () => {
      setValidationDef(utils.validateDefinition(definition));
      setDefinitionChanged(true);
    },
    definition,
    setValidating,
  });

  hooks.useEffectWithoutFirst(() => {
    if (documentationOpen && !!rightDrawer) setRightDrawer(null);
  }, [documentationOpen]);

  hooks.useEffectWithoutFirst(() => {
    if (!!rightDrawer && documentationOpen) setDocumentSidebarOpen(false);
  }, [rightDrawer]);

  const definitionFromRiskModel = (
    riskModel: ts.types.riskModel.RiskModelDraft
  ): ts.types.riskModel.DefinitionDraft => {
    const definition = riskModel.definition;
    // Convert an external model into a finsera model with no parameters.
    return {
      style: definition.style,
      categories: definition.categories,
      market: definition.market,
      version: definition.version,
      type: riskModel.definition.type || 'FINSERA',
      universe_id: definition.universe_id,
      industry_analysis_job_id: definition.industry_analysis_job_id,
      parameters: definition.parameters,
      publish_as_external: definition.publish_as_external,
    };
  };

  const updateRiskModel = (newData: ts.types.riskModel.RiskModelDraft) =>
    utils.apiProcedures.updateRiskModel(id, newData, setRiskModel, (err) =>
      setAlert({ severity: ts.enums.ALERT_SEVERITY_ENUM.ERROR, message: err })
    );

  const updateDefinition = (
    def: ts.types.riskModel.DefinitionDraft = definition,
    updateDefTimestamp = true,
    isValid = validationDef?.valid ?? false
  ) =>
    utils.apiProcedures.updateDefinition(
      id,
      def,
      uiMetadata,
      isValid,
      (updatedRiskModel) => {
        setRiskModel(updatedRiskModel);
        setUiMetadata(updatedRiskModel.ui_metadata ?? ({} as any));
        setDefinitionChanged(false);
        // Reupdate definition in case it was triggered from the report framework
        setDefinition({
          ...def,
          noValidate: true,
        });
      },
      (err) => setAlert({ severity: ts.enums.ALERT_SEVERITY_ENUM.ERROR, message: err }),
      updateDefTimestamp
    );

  const updateUiMetadata = (
    meta: ts.types.riskModel.UiMetaData = uiMetadata,
    riskModelL = riskModel as ts.types.riskModel.RiskModel
  ) =>
    utils.apiProcedures.updateUiMetadata(
      riskModelL,
      meta,
      (updatedRiskModel) => {
        setRiskModel(updatedRiskModel);
        setUiMetadata(updatedRiskModel.ui_metadata ?? ({} as any));
      },
      (err) => setAlert({ severity: ts.enums.ALERT_SEVERITY_ENUM.ERROR, message: err })
    );

  const debouncedSaveMetadata = React.useCallback(
    _.debounce((metadata, rm) => {
      updateUiMetadata(metadata, rm);
    }, 1000),
    []
  );

  hooks.useEffectWithoutFirst(() => {
    if (!_.isEmpty(uiMetadata) && !_.isEqual(uiMetadata, riskModel.ui_metadata))
      debouncedSaveMetadata(uiMetadata, riskModel);
  }, [uiMetadata]);

  const keepCurrent = () =>
    utils.apiProcedures.keepCurrent(
      riskModel,
      (resp) => {
        setRiskModel(resp.resource);
      },
      (err) => setAlert({ severity: ts.enums.ALERT_SEVERITY_ENUM.ERROR, message: err })
    );

  // if the riskModel id changes
  React.useEffect(() => {
    setRiskModel(null);
    // Load the riskModel data, if the data doesn't exist redirect to the workspace root
    const riskModelData = resources.risk_models.find((s) => s.id === id);
    if (!riskModelData || riskModelData?.revoked) history.push('/404');
    else {
      setRiskModel(riskModelData);
      setDefinition({
        ...definitionFromRiskModel(riskModelData),
      });
      setUiMetadata(riskModelData.ui_metadata ?? ({} as any));

      if (riskModelData.is_valid) setValidationDef({ valid: true });
      else if (!helpers.resourcesUtils.justCreated(riskModelData.created_at))
        setValidationDef(
          utils.validateDefinition({
            ...definitionFromRiskModel(riskModelData),
          })
        );
    }
  }, [id]);

  React.useEffect(() => {
    // Keep up to date
    if (riskModel.end_date_latest && config.features.keep_resources_current && riskModel.organization_id != 0)
      keepCurrent();
  }, [riskModel?.id, definition?.universe_id]);

  // Check if risk model is common
  const isExternalRiskModel = _.isEmpty(definition?.parameters);

  React.useEffect(() => {
    const analyses = [ts.enums.RISK_MODEL_ANALYSIS_TYPE_ENUM.ANALYSIS, ts.enums.RISK_MODEL_ANALYSIS_TYPE_ENUM.PREVIEW];
    if (!isExternalRiskModel) analyses.push(ts.enums.RISK_MODEL_ANALYSIS_TYPE_ENUM.CATEGORY);
    analyses.push(ts.enums.RISK_MODEL_ANALYSIS_TYPE_ENUM.REPORT);

    if (riskModel?.id)
      utils.apiProcedures.loadAnalyses(riskModel as ts.types.riskModel.RiskModel, analyses, setAnalysesNames, (err) =>
        setAlert({ severity: ts.enums.ALERT_SEVERITY_ENUM.ERROR, message: err })
      );
  }, [riskModel?.id]);

  React.useEffect(
    () =>
      function cleanUp() {
        setAnalysesNames([]);
        setAlert(null);
      },
    [id]
  );

  const depsTimestamp = React.useMemo(() => {
    if (!riskModel) return [];
    return [
      riskModel,
      ...helpers.resourceGraph.getDeps(
        {
          ...(riskModel as ts.types.riskModel.RiskModel),
          resourceType: ts.enums.RESOURCES_TYPES_ENUM.RISK_MODEL,
        },
        resources
      ),
    ]
      .map((r) => ({
        dependency: r.id == riskModel.id ? 'Current Risk Model' : `${r.name} (${r.handle})`,
        timestamp: r.definition_updated_at,
      }))
      .filter((el) => !!el.timestamp);
  }, [riskModel, resources]);

  return (
    <RiskModelContext.Provider
      value={{
        alert,
        analysesNames,
        archivedDeps,
        currentTab,
        definition,
        definitionChanged,
        depsTimestamp,
        isExternalRiskModel: isExternalRiskModel,
        keepCurrent,
        resources,
        uiMetadata,
        setUiMetadata,
        riskModel,
        setAlert,
        setDefinition,
        setDefinitionChanged,
        setRiskModel,
        rightDrawer,
        setRightDrawer,
        universes,
        updateDefinition,
        updateRiskModel,
        user,
        validating,
        validationDef,
        organization,
      }}
    >
      {children}
    </RiskModelContext.Provider>
  );
};

const RiskModelContextProvider = Provider;

export { RiskModelContext };
export { RiskModelContextProvider };
