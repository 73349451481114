export enum INTERACTION_PARAMS {
  // eslint-disable-next-line no-unused-vars
  IS_BASKET = 'isBasket',
}

export type JobParams = {
  analysis_pipelined_signals: {
    signal_id: number;
    pipeline_id: number;
    sp_pipeline_id: number;
  }[];
};
export type InteractionParams = {
  [INTERACTION_PARAMS.IS_BASKET]: boolean;
};
export type Params = JobParams & InteractionParams;
export type DraftParams = Partial<Params>;
