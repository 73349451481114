import { hooks, mui, React, ts, ui } from '_core';

import { DraftParams } from './types';

type FormProps = {
  params: DraftParams;
  setParams: (_p: DraftParams) => void;
  context: ts.types.signalSet.SignalSetAnalysisContext;
};

const Form: React.FC<FormProps> = ({ params, setParams, context }) => {
  const [dailyReporting, setDailyReporting] = React.useState(params?.daily_reporting || false);
  const [returnHorizon, setReturnHorizon] = React.useState(params?.return_horizons?.[0]);

  React.useEffect(() => {
    setParams({
      ...params,
      daily_reporting: dailyReporting,
      return_horizons: [returnHorizon],
      set_params: [
        {
          id: 'summary',
          file: 'sp_returns',
          file_params: {
            return_horizons: [returnHorizon],
            agg_frequency: dailyReporting ? ts.enums.FREQUENCY_ENUM.DAILY : undefined,
          },
        },
      ],
    });
  }, [returnHorizon, dailyReporting]);

  const uiStyles = hooks.useUiStyles();
  return (
    <mui.core.Box sx={uiStyles.widgetEditMode}>
      <mui.core.Box mt={4}>
        <ui.WidgetReturnHorizon<false>
          value={returnHorizon}
          setValue={(val) => setReturnHorizon(val)}
          dailyReporting={dailyReporting}
          setDailyReporting={(val) => setDailyReporting(val)}
          enableDailyReporting
          context={context}
        />
      </mui.core.Box>
    </mui.core.Box>
  );
};

export default Form;
