import { ts } from '_core';

import { INTERACTION_PARAMS } from './types';

export default [
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.PORTFOLIO_TRADE_LIST_SUMMARY,
    form: false,
    title: 'Summary',
    interactionParams: Object.values(INTERACTION_PARAMS),
    clearInteractionOnRegenerate: ['table_dtypes'],
    neededFiles: ['trade_list_rebalance_summary', 'trade_list_trade_summary', 'rebalance_analysis'],
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.REBALANCE_PORTFOLIO_TRADE_LIST],

    helpLabel: '12djj88f',
  },
] satisfies ts.types.analysis.WidgetConfig[];
