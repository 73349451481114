import { ts } from '_core';

import { INTERACTION_PARAMS } from './types';

export default [
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.BACKTEST_CONSTRAINED_PORTFOLIO_EXPOSURES_OVER_TIME,
    title: 'Constrained Portfolio Exposures Over Time',
    helpLabel: '5perl48b',
    defaultParams: { isBasket: false },
    interactionParams: Object.values(INTERACTION_PARAMS),
    form: false,
    neededFiles: ['backtest_constraints'],
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.BACKTEST],
  },
] satisfies ts.types.analysis.WidgetConfig[];
