import { _, helpers, mui, React, ts, ui,useHistory, useSelector } from '_core';

import Initial from './initial';
import Schema from './schema';
import DataSourceFormStepsButton from './steps-button';
import * as utils from '../data-source-utils';

type DataSourceFormProps = {
  open: boolean;
  setOpen: (_open: boolean) => void;
};

const DataSourceForm: React.FC<DataSourceFormProps> = ({ open, setOpen }): React.ReactElement => {
  const organization = useSelector((state: ts.StoreState) => state.auth.organization);
  const workspaces = useSelector((state: ts.StoreState) => state.resources.workspaces);
  const currentWorkspaceId = useSelector((state) => state.ui.currentWorkspaceId as number);

  const history = useHistory();

  const [errorMessage, setErrorMessage] = React.useState('');
  const [params, setParams] = React.useState<ts.types.dataSource.DataSourceFormParamsDraft>({
    name: '',
    handle: '',
    frequency: 'DAILY',
    lag: { unit: 'days', amount: 0 },
    look_back: 7,
    hasAssets: true,
    hasFID: false,
    dataType: 'csv',
    withHeader: true,
    rollback_days: null,
    rollback_country: null,
    workspace: workspaces.find((w) => w.id == currentWorkspaceId) || workspaces[0],
  });

  const [step, setStep] = React.useState(0);
  const [loadingCreate, setLoadingCreate] = React.useState(false);

  const handleSubmit = async () => {
    setErrorMessage('');

    // Validation
    if (!params.name || !params.handle) {
      setErrorMessage('All initial attributes are required.');
      setLoadingCreate(false);
      return;
    }

    if (_.isNil(params.lag.amount) || !params.lag.unit) {
      setErrorMessage("Lag is a required field. If received_date is provided on the uploads lag won't be used");
      setLoadingCreate(false);
      return;
    }

    setLoadingCreate(true);

    const newDataSource = utils.definition.getNewDataSource(organization.id, params);

    utils.apiProcedures.createDataSource(
      newDataSource,
      async (data) => {
        if (params.workspace)
          await helpers.resourcesUtils.addToWorkspace(
            data.id,
            ts.enums.UI_RESOURCE_TYPE_ENUM.DATA_SOURCE,
            params.workspace as ts.types.workspace.Workspace
          );
        setOpen(false);
        history.push(`/data_sources/${data.id}/definition`);
      },
      async (err) => {
        setErrorMessage(err);
        setLoadingCreate(false);
      }
    );
  };

  return (
    <mui.core.Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="sm"
      fullWidth
    >
      {errorMessage && (
        <mui.lab.Alert onClose={() => setErrorMessage('')} severity="error">
          {errorMessage}
        </mui.lab.Alert>
      )}
      <ui.DialogTitle closeAction={() => setOpen(false)}>Create Data Source</ui.DialogTitle>
      <mui.core.DialogContent>
        <mui.core.Box pt={4} pb={4}>
          <mui.core.Stepper activeStep={step}>
            <mui.core.Step key="Basic Info">
              <mui.core.StepLabel>Basic Info</mui.core.StepLabel>
            </mui.core.Step>
            <mui.core.Step key="Setup">
              <mui.core.StepLabel>Setup Info (optional)</mui.core.StepLabel>
            </mui.core.Step>
          </mui.core.Stepper>
        </mui.core.Box>
        {step == 0 ? (
          <Initial params={params} setParams={setParams} workspaces={workspaces} />
        ) : (
          <Schema params={params} setParams={setParams} />
        )}
      </mui.core.DialogContent>
      <mui.core.DialogActions>
        <DataSourceFormStepsButton
          loading={loadingCreate}
          step={step}
          setStep={setStep}
          handleCreate={() => handleSubmit()}
        />
      </mui.core.DialogActions>
    </mui.core.Dialog>
  );
};

export default DataSourceForm;
