import { mui, React, ts } from '_core';

import Chart from './chart';
import Form from './form';
import { DraftParams, Params } from './types';

type PortfolioHoldingsBucketExposureProps = {
  id: string;
  readOnly: boolean;
  widgetKey: string;
  mode: ts.enums.CHART_MODE_ENUM;
  context?: ts.types.portfolio.PortfolioAnalysisContext;
  loadWidgetData: (_payload: ts.types.widgets.WidgetGetDataParams) => Promise<ts.types.widgets.WidgetGetDataResponse>;
  params: Params;
  setParams: (_p: DraftParams) => void;
  widgetPaths?: Record<'portfolio_summary_bucket_exposures' | 'portfolio_summary_bucket_exposures_holdings', string>;
  goFullScreen?: (_b: string) => void;
  fullScreen?: boolean;

  queryParams?: { initial?: string };
};

const PortfolioHoldingsBucketExposure: React.FC<PortfolioHoldingsBucketExposureProps> = ({
  id,
  readOnly,
  widgetKey,
  mode,
  loadWidgetData,
  params,
  setParams,
  widgetPaths,
  fullScreen,
  goFullScreen,
  queryParams,
  context,
}) => {
  const renderJobComponent = () => (
    <mui.core.Box sx={{ height: '100%' }}>
      <Chart
        id={id}
        readOnly={readOnly}
        widgetKey={widgetKey}
        loadWidgetData={loadWidgetData}
        exposure={
          widgetPaths['portfolio_summary_bucket_exposures'] ??
          widgetPaths['portfolio_summary_bucket_exposures_holdings']
        }
        fullScreen={fullScreen}
        queryParams={queryParams}
        goFullScreen={goFullScreen}
        context={context}
        params={params}
        setParams={setParams}
      />
    </mui.core.Box>
  );

  return <>{mode == 'edit' ? <Form params={params} setParams={setParams} /> : renderJobComponent()}</>;
};

export default PortfolioHoldingsBucketExposure;
