import { _, mui, React, ts } from '_core';

import { filterTabBarDataByReturnHorizon, prepareData } from './helpers';
import { Params } from './types';
import ChartWrapper from '../../common/chart-wrapper';
import createNestedBarChart from '../../common/charts/create-nested-bar-chart';
import { getLabel, prepareRhDataBarChart } from '../../common/utils/signal-utils';

type ChartProps = {
  id: string;
  readOnly: boolean;
  widgetKey: string;
  loadWidgetData: (_payload: ts.types.widgets.WidgetGetDataParams) => Promise<ts.types.widgets.WidgetGetDataResponse>;
  returns: string;
  fullScreen: boolean;
  params: Params;
  frequency: ts.enums.FREQUENCY_ENUM;
  title: string;
  subtitle?: string;
  setExternalEmbed: (_json: ts.types.analysis.EmbedChartData) => void;
};

const Chart: React.FC<ChartProps> = ({
  id,
  readOnly,
  widgetKey,
  loadWidgetData,
  returns,
  fullScreen,
  params,
  frequency,
  title,
  subtitle,
  setExternalEmbed,
}) => {
  const hasBenchmark = !_.isEmpty(params.benchmark);

  const [tab, setTab] = React.useState(params.return_horizons[0].signature);
  const [data, setData] = React.useState<ts.types.widgets.TableData>(null);
  const [error, setError] = React.useState<ts.types.common.ApiError>(null);
  const [showLegend, setShowLegend] = React.useState(false);

  const loadData = async () => {
    try {
      const response = await loadWidgetData({
        data: {
          file: returns,
        },
      });

      if (response) {
        setData(prepareData(response.data, params));
      }
    } catch (err) {
      setError(err as ts.types.common.ApiError);
    }
  };

  React.useEffect(() => {
    if (returns) loadData();
    return () => setData(null);
  }, [returns]);

  React.useEffect(() => {
    const format = params.statistics_type !== 'adjusted' ? '#.##%' : '#.##';

    // Create line chart on data and scale changes
    if (!_.isNil(data)) {
      const localData = hasBenchmark ? filterTabBarDataByReturnHorizon(data, tab) : data;

      createNestedBarChart({
        id,
        data: localData,
        fullScreen,
        setShowLegend,
        format,
        exportTitle: title,
        exportSubTitle: subtitle,
        prepareData: (nestedBarData) => prepareRhDataBarChart(nestedBarData, frequency),
        setExternalEmbed,
      });
    }
  }, [data, tab, title, subtitle]);

  const getTabs = () => {
    if (data && hasBenchmark) {
      return (
        <mui.core.Tabs
          value={tab}
          indicatorColor="primary"
          textColor="primary"
          onChange={(e, newTab) => setTab(newTab)}
          aria-label="Return Horizon Tabs"
        >
          {params.return_horizons.map((rh) => (
            <mui.core.Tab key={rh.signature} label={getLabel(rh.signature, frequency)} value={rh.signature} />
          ))}
        </mui.core.Tabs>
      );
    }
    return null;
  };

  return (
    <>
      <ChartWrapper
        id={id}
        readOnly={readOnly}
        widgetKey={widgetKey}
        data={data}
        showLegend={showLegend}
        warning={data?.length === 0 ? ['Empty File'] : []}
        error={error}
        tabs={getTabs()}
        fullScreen={fullScreen}
      />
    </>
  );
};

export default Chart;
