import { _, ts } from '_core';

import { DraftParams } from './types';

export const prepareEventStudyData = (
  data: ts.types.widgets.HeatMapData | ts.types.widgets.NestedBarData | ts.types.widgets.ErrorLinearData,
  tab: 'error' | 'tstat' | 'count',
  histories: ts.enums.HISTORY_ENUM_EXTENDED[] = null
): ts.types.widgets.HeatMapData | ts.types.widgets.NestedBarData | ts.types.widgets.ErrorLinearData => {
  const histMap = {
    ONE_YEAR: ts.enums.HISTORY_ENUM_EXTENDED.ONE,
    THREE_YEARS: ts.enums.HISTORY_ENUM_EXTENDED.THREE,
    FIVE_YEARS: ts.enums.HISTORY_ENUM_EXTENDED.FIVE,
    FULL: ts.enums.HISTORY_ENUM_EXTENDED.FULL,
    YTD: ts.enums.HISTORY_ENUM_EXTENDED.YTD,
  };

  if (tab == 'count' || _.isEmpty(histories)) return data;
  if (tab == 'error') {
    return Object.fromEntries(
      Object.entries(data)
        .map(([key, val]) => [histMap[key as keyof typeof histMap], val])
        .filter(([key, _val]) => histories.includes(key))
    );
  }

  if (tab == 'tstat') {
    return (data as ts.types.widgets.HeatMapData)
      .map((d) => ({ ...d, category: histMap[d['category'] as keyof typeof histMap] }))
      .filter((d) => histories.includes(d['category']));
  }

  return data;
};

export const validate = (params: DraftParams) => {
  if (_.isNil(params.lower_bound) && _.isNil(params.upper_bound))
    return ['Lower Bound or Upper Bound is a required field.'];
  if (!_.isNil(params.lower_bound) && !_.isNil(params.upper_bound))
    if (params.lower_bound > params.upper_bound) return ['Lower Bound must be less than Upper Bound.'];
  if (params.days_before_event < 1 || params.days_before_event > 21)
    return ['Days Before Event must be between 1 and 21.'];
  if (params.days_after_event < 0 || params.days_after_event > 63)
    return ['Days After Event must be between 0 and 63.'];
  if (params.get_histories && _.isEmpty(params.histories)) return ['You need to select at least one history.'];
  return [];
};

export const getName = (params: DraftParams, _context: ts.types.signal.SignalAnalysisContext) => {
  if (params.with_volatility) return `Event Study Scaled By ${_.startCase(_.lowerCase(params.scale_by))}`;
  return 'Event Study';
};
