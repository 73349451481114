import { _, helpers, React, ts, ui,useSelector } from '_core';

import { Params } from './types';

type LastCreatedProps = {
  params: Params;
};

const LastCreated: React.FC<LastCreatedProps> = ({ params }) => {
  const resources = useSelector((state) => state.resources);

  let resourceType: Params['by'];
  let resourceName: ts.enums.RESOURCES_TYPES_ENUM;
  let lastCreated = [] as ts.types.common.Resource[];

  if (params.by == 'alpha_models') {
    resourceType = ts.enums.TABLES_ENUM.SIGNALS;
    lastCreated = resources.signals
      .filter((s) => s.type == 'ALPHA_MODEL')
      .slice(-10)
      .reverse();
  } else if (params.by == 'signals') {
    resourceType = ts.enums.TABLES_ENUM.SIGNALS;
    resourceName = ts.enums.RESOURCES_TYPES_ENUM.SIGNAL;
    lastCreated = resources.signals
      .filter((s) => s.type == 'SIGNAL')
      .slice(-10)
      .reverse();
  } else {
    resourceType = _.snakeCase(params.by) as Params['by'];
    resourceName = helpers.pluralize.singular(resourceType) as ts.enums.RESOURCES_TYPES_ENUM;
    lastCreated = resources[resourceType as ts.enums.TABLES_ENUM].slice(-10).reverse();
  }

  const list = () => (
    <>
      {lastCreated.map((r) => (
        <ui.InlineItem
          key={`${resourceType}-${r.id}`}
          resource={r}
          resourceType={resourceName}
          to={`/${resourceType}/${r.id}`}
        />
      ))}
    </>
  );

  return (
    <>
      {_.isEmpty(lastCreated) ? (
        <ui.EmptyMessage hideBorder>
          You {"haven't"} created any {resourceType} yet.
        </ui.EmptyMessage>
      ) : (
        list()
      )}
    </>
  );
};

export default LastCreated;
