import { _, api, helpers, ts } from '_core';

type PortfolioApiResponse = {
  data: ts.types.portfolio.Portfolio;
};

export const createPortfolio = async (
  newData: ts.types.portfolio.PortfolioDraft,
  callback: (_data: ts.types.portfolio.Portfolio) => void,
  errorCallback: (_error: string) => void
) => {
  try {
    const resp: PortfolioApiResponse = await api.portfolios.create(newData);
    callback(resp.data);
  } catch (error) {
    errorCallback(`Error creating portfolio - ${helpers.parseApiError(error as ts.types.common.ApiError)}`);
  }
};

export const updatePortfolio = async (
  id: number,
  newData: ts.types.portfolio.PortfolioDraft,
  callback: (_data: ts.types.portfolio.Portfolio) => void,
  errorCallback: (_error: string) => void
) => {
  try {
    const resp: PortfolioApiResponse = await api.portfolios.update(id, newData);
    callback(resp.data);
  } catch (error) {
    errorCallback(`Error updating portfolio - ${helpers.parseApiError(error as ts.types.common.ApiError)}`);
  }
};

export const updateUiMetadata = async (
  id: number,
  uiMetaData: ts.types.portfolio.UiMetaData,
  callback: (_data: ts.types.portfolio.Portfolio) => void,
  errorCallback: (_error: string) => void
) => {
  try {
    const updateData = {
      ui_metadata: uiMetaData,
      skip_signature_check: true,
    } as ts.types.portfolio.PortfolioDraft;
    const resp = await api.portfolios.update(id, updateData);
    callback(resp.data);
  } catch (error) {
    errorCallback(`Error updating the resource metadata - ${helpers.parseApiError(error as ts.types.common.ApiError)}`);
  }
};

const createAnalyses = async (
  portfolio: ts.types.portfolio.PortfolioDraft,
  strategy: ts.types.strategy.StrategyDraft,
  names: ts.enums.PORTFOLIO_ANALYSIS_TYPE_ENUM[]
) => {
  const analyses = [];
  for (const name of names) {
    let analysisDefinition = { widgets: [] } as ts.types.analysis.AnalysisDefinition;

    if (name == ts.enums.PORTFOLIO_ANALYSIS_TYPE_ENUM.PORTFOLIO_RISK) {
      // default preview value
      analysisDefinition = {
        mode: ts.enums.ANALYSIS_MODE_ENUM.GRID,
        widgets: [
          {
            id: helpers.gibberishGenerator.stringGenerator(11),
            key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.PORTFOLIO_PERCENT_CONTRIBUTION_TO_RISK,
            params: {},
          },
          {
            id: helpers.gibberishGenerator.stringGenerator(11),
            key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.RISK_MODEL_PREDICTED_REALIZED_RISK_TIME,
            params: {},
          },
          {
            id: helpers.gibberishGenerator.stringGenerator(11),
            key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.RISK_MODEL_BIAS_STATISTIC,
            params: {},
          },
          {
            id: helpers.gibberishGenerator.stringGenerator(11),
            key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.BASKET_SUMMARY_RISK_STATS,
            params: {},
          },
          {
            id: helpers.gibberishGenerator.stringGenerator(11),
            key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.PORTFOLIO_RISK_FACTOR_EXPOSURE,
            params: {},
          },
        ],
      };
    }

    if (strategy.type !== 'PORTFOLIO_ANALYSIS' && name == ts.enums.PORTFOLIO_ANALYSIS_TYPE_ENUM.PORTFOLIO_RISK) {
      analysisDefinition = {
        ...analysisDefinition,
        widgets: [
          ...analysisDefinition.widgets,
          {
            id: helpers.gibberishGenerator.stringGenerator(11),
            key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.PORTFOLIO_RISK_FACTOR_EXPOSURE,
            params: {},
          },
        ],
      };
    }

    if (name == ts.enums.PORTFOLIO_ANALYSIS_TYPE_ENUM.REPORT) {
      // default summary/report value
      analysisDefinition = {
        mode: ts.enums.ANALYSIS_MODE_ENUM.FULLSCREEN,
        widgets: [
          {
            id: helpers.gibberishGenerator.stringGenerator(11),
            key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.PORTFOLIO_TABULAR_OUTPUT,
            params: {},
          },
        ],
      };
    }

    // create analysis
    const response = await api.portfolio_analyses.create({
      report_definition: analysisDefinition,
      portfolio_id: portfolio.id,
      name,
    });

    analyses.push(response.data.name);
  }
  return analyses;
};

// Load analyses, and if we don't have them, create them
export const loadAnalyses = async (
  portfolio: ts.types.portfolio.PortfolioDraft,
  strategy: ts.types.strategy.StrategyDraft,
  setAnalysesNames: (_data: ts.enums.PORTFOLIO_ANALYSIS_TYPE_ENUM[]) => void,
  errorCallback: (_error: string) => void
) => {
  const analyses = Object.values(ts.enums.PORTFOLIO_ANALYSIS_TYPE_ENUM);
  try {
    const resp = await api.portfolio_analyses.search({
      query: ['$and', ['portfolio_id', '=', portfolio.id]],
      include: 'id,name',
    });

    let localNames = (resp.data || []).map(
      (a: ts.types.analysis.ResourceAnalysis) => a.name
    ) as ts.enums.PORTFOLIO_ANALYSIS_TYPE_ENUM[];

    const toCreate = _.without(analyses, ...localNames);
    if (!_.isEmpty(toCreate)) {
      const newNames = (await createAnalyses(portfolio, strategy, toCreate)) as ts.enums.PORTFOLIO_ANALYSIS_TYPE_ENUM[];
      localNames = localNames.concat(newNames);
    }

    setAnalysesNames(localNames);
  } catch (error) {
    console.log(error);
    errorCallback(`Error updating portfolio - ${helpers.parseApiError(error as ts.types.common.ApiError)}`);
  }
};
