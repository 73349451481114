import { ts } from '_core';

import { validate } from './helpers';
import { DraftParams, INTERACTION_PARAMS } from './types';

export default [
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.PORTFOLIO_TRADE_LIST_BUCKET_EXPOSURE_SUMMARY,
    title: 'Bucket Exposure Summary',
    defaultParams: {
      buckets: [{ type: ts.enums.BUCKET_TYPES_ENUM.RISK_MODEL_FACTORS, value: 5 }],
    } as DraftParams,
    form: true,
    interactionParams: Object.values(INTERACTION_PARAMS),
    neededFiles: ['trade_list_bucket_factor_exposure', 'rebalance_analysis'],
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.REBALANCE_PORTFOLIO_TRADE_LIST],

    validate,
    helpLabel: '7ezc3yzn',
  },
] satisfies ts.types.analysis.WidgetConfig[];
