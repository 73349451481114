import { mui, React, ts, ui } from '_core';

import SharedConstraintFields from '../shared-fields';

type TradeListConstraintProps = {
  id: string;
  params: ts.types.optimizer.TradeListConstraintDraft;
  setParams: (_params: ts.types.optimizer.TradeListConstraintDraft) => void;
  readOnly: boolean;
  validationDef: ts.types.common.ValidationErrors;
  dates: {
    start: string;
    end: string;
  };
};

const TradeListConstraint: React.FC<TradeListConstraintProps> = ({
  id,
  params,
  setParams,
  readOnly,
  dates,
  validationDef,
}): React.ReactElement => {
  const [showMore, setShowMore] = React.useState(false);

  return (
    <>
      <mui.core.Box p={2}>
        <mui.core.Box pb={3}>
          <mui.core.TextField
            select
            label="Constraint Type"
            variant="outlined"
            value={params.trade_list_constraint_type}
            onChange={(e) =>
              setParams({
                ...params,
                trade_list_constraint_type: e.target
                  .value as ts.types.optimizer.TradeListConstraint['trade_list_constraint_type'],
              })
            }
            fullWidth
            disabled={readOnly}
            size="small"
            error={validationDef?.fields?.includes(`constraint_type_${id}`)}
          >
            <mui.core.MenuItem value={'DONT_TRADE'}>{"Don't Trade"}</mui.core.MenuItem>
            <mui.core.MenuItem value={'NO_INCREASE'}>{'No Increase'}</mui.core.MenuItem>
            <mui.core.MenuItem value={'DONT_BUY'}>{"Don't Buy"}</mui.core.MenuItem>
            <mui.core.MenuItem value={'DONT_SELL'}>{"Don't Sell"}</mui.core.MenuItem>
            <mui.core.MenuItem value={'DONT_SHORT'}>{"Don't Short"}</mui.core.MenuItem>
            <mui.core.MenuItem value={'DONT_COVER'}>{"Don't Cover"}</mui.core.MenuItem>
            <mui.core.MenuItem value={'NO_HOLD'}>{'No Hold'}</mui.core.MenuItem>
          </mui.core.TextField>
        </mui.core.Box>

        <mui.core.Box>
          <ui.AssetSelector
            value={params.asset_fids as string[]}
            setValue={(val: ts.types.assetSelector.AssetSelectorValueObject) => {
              setParams({
                ...params,
                asset_fids: val as string[],
              });
            }}
            withDateRanges
            dateRanges={params.asset_date_range}
            onDateRangesChange={(ranges) => {
              setParams({
                ...params,
                asset_date_range: ranges,
              });
            }}
            createId={(a) => `${a.fid}`}
            disabled={readOnly}
            dates={dates}
            fields={[
              { title: 'Ticker', key: 'ticker' },
              { title: 'Name', key: 'name' },
              { title: 'Country', key: 'quotation_country' },
            ]}
            tableView={true}
            multiple
            errorState={validationDef?.fields?.includes(`asset_${id}`)}
          />
        </mui.core.Box>

        {showMore && (
          <SharedConstraintFields
            id={id}
            params={params}
            setParams={setParams}
            showRelaxation={false}
            readOnly={readOnly}
            validationDef={validationDef}
          />
        )}
      </mui.core.Box>
      <mui.core.Box p={3}>
        <mui.core.Button
          onClick={() => {
            setShowMore(!showMore);
          }}
          color="primary"
        >
          {showMore ? (
            <>
              <mui.icons.Remove style={{ fontSize: '14px', marginRight: '0.5rem' }} />
              Show Less
            </>
          ) : (
            <>
              <mui.icons.Add style={{ fontSize: '14px', marginRight: '0.5rem' }} />
              Show More
            </>
          )}
        </mui.core.Button>
      </mui.core.Box>
    </>
  );
};

export default TradeListConstraint;
