import { _, am5, am5xy } from '@local/finsera-core/src/libs';
import * as types from '@local/finsera-core/src/types';

import { getColor } from '../../../../helpers';

export const createSeries = ({
  root,
  chart,
  data,
  xAxisField,
  xAxisRenderer,
  yAxisRenderer,
  showEndValueLabel,
}: {
  root: am5.Root;
  chart: am5xy.XYChart;
  data: types.widgets.TableData;
  xAxisField: string;
  xAxisRenderer: am5xy.ValueAxis<am5xy.AxisRenderer>;
  yAxisRenderer: am5xy.ValueAxis<am5xy.AxisRenderer>;
  showEndValueLabel: boolean;
}) => {
  const createSingleSeries = (field: string, color: am5.Color) => {
    const series = chart.series.push(
      am5xy.LineSeries.new(root, {
        name: field,
        xAxis: xAxisRenderer,
        yAxis: yAxisRenderer,
        valueYField: field,
        valueXField: xAxisField,
        stroke: color,
        fill: color,
        tooltip: am5.Tooltip.new(root, {
          labelText: '[bold]{name}[/]: {valueY}',
        }),
      })
    );

    // Add bullet only for the last data point
    if (showEndValueLabel) {
      series.bullets.push((root, series, dataItem) => {
        const lastIndex = series.dataItems.length - 1;
        if (series.dataItems.indexOf(dataItem) === lastIndex) {
          const formattedValue = root.numberFormatter.format((dataItem.dataContext as any)[field]);

          const textLength = formattedValue.length;
          const dynamicCenterX = Math.min(50, Math.max(0, textLength * 2));

          return am5.Bullet.new(root, {
            sprite: am5.Label.new(root, {
              text: ` ${formattedValue}`,
              fontSize: 10,
              fill: am5.color('#000000'),
              background: am5.Rectangle.new(root, {
                fill: color,
                fillOpacity: 1,
              }),
              centerY: am5.p50,
              centerX: am5.percent(dynamicCenterX),
              paddingLeft: 2,
              paddingRight: 5,
              paddingTop: 2,
              paddingBottom: 2,
            }),
          });
        }
        return null;
      });
    }

    series.get('tooltip')?.label.setAll({
      fontSize: 11,
    });

    return series;
  };

  const keys = _.without(Object.keys(data?.[0] ?? {}), xAxisField);
  keys.forEach((key, index) => {
    const seriesObj = createSingleSeries(key, am5.color(getColor(index)));
    seriesObj.data.setAll(data);
  });
};
