import { React, ts } from '_core';

import Chart from './chart';
import { Params } from './types';

type PortfolioSummaryHoldingsProps = {
  id: string;
  readOnly: boolean;
  widgetKey: string;
  context?: ts.types.portfolio.PortfolioAnalysisContext;
  loadWidgetData: (_payload: ts.types.widgets.WidgetGetDataParams) => Promise<ts.types.widgets.WidgetGetDataResponse>;
  widgetPaths?: Record<'portfolio_summary_analysis' | 'portfolio_summary_analysis_holdings', string>;
  goFullScreen?: (_b: string) => void;
  fullScreen?: boolean;
  params: Params;
  queryParams?: { initial?: string };
};

const PortfolioSummaryHoldings: React.FC<PortfolioSummaryHoldingsProps> = ({
  id,
  readOnly,
  widgetKey,
  loadWidgetData,
  widgetPaths,
  fullScreen,
  goFullScreen,
  queryParams,
  context,
  params,
}) => {
  return (
    <Chart
      id={id}
      readOnly={readOnly}
      widgetKey={widgetKey}
      loadWidgetData={loadWidgetData}
      summary={widgetPaths['portfolio_summary_analysis'] ?? widgetPaths['portfolio_summary_analysis_holdings']}
      fullScreen={fullScreen}
      queryParams={queryParams}
      goFullScreen={goFullScreen}
      context={context}
      params={params}
    />
  );
};

export default PortfolioSummaryHoldings;
