import { mui, React } from '_core';

import DslEmptyChat from './empty-chats/dsl-empty-chat';
import ExploreEmptyChat from './empty-chats/explore-empty-chat';
import MainEmptyChat from './empty-chats/main-empty-chat';
import Messages from './messages';
import UserInput from './user-input';
import { AIAssistantContext } from '../ai-assistant-context';

const Chat = () => {
  const theme = mui.styles.useTheme() as mui.core.Theme;
  const aiAssistantContext = React.useContext(AIAssistantContext);
  const { conversation, parentContainerRef, hookedAtBottomRef, loadingResponse, service } = aiAssistantContext;

  const [inputValue, setInputValue] = React.useState('');

  const onScroll = () => {
    const parentScrollTop = parentContainerRef.current?.scrollTop;
    const parentClientHeight = parentContainerRef.current?.clientHeight;
    const parentScrollHeight = parentContainerRef.current?.scrollHeight;

    hookedAtBottomRef.current = parentScrollTop + parentClientHeight >= parentScrollHeight - 10;
  };

  const renderChat = () => {
    if ((conversation?.messages ?? []).length !== 0 || loadingResponse) {
      return (
        <mui.core.Box>
          <Messages />
        </mui.core.Box>
      );
    }
    if (service.type == 'dsl') return <DslEmptyChat />;
    if (service.type == 'explore') return <ExploreEmptyChat />;

    return <MainEmptyChat />;
  };

  return (
    <mui.core.Box width="100%" height="100%" display="flex" flexDirection="column" position="relative">
      <mui.core.Box overflow="auto" flexGrow={1} ref={parentContainerRef} onScroll={onScroll}>
        {renderChat()}
      </mui.core.Box>

      <mui.core.Box
        position="sticky"
        width="100%"
        alignSelf="center"
        sx={{ borderTop: `1px solid ${theme.palette.gray.main}`, pt: 3 }}
      >
        <UserInput inputValue={inputValue} setInputValue={setInputValue} />
      </mui.core.Box>
    </mui.core.Box>
  );
};

export default Chat;
