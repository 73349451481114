import { ts } from '_core';

import { DraftParams, INTERACTION_PARAMS } from './types';

export default [
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.BASKET_MAX_DRAWDOWN,
    title: 'Max Drawdown by Year',
    helpLabel: 'y0c81yfv',
    form: false,
    defaultParams: { isBasket: true, aggregation: 'ANNUAL' } as DraftParams,
    interactionParams: Object.values(INTERACTION_PARAMS),
    neededFiles: ['backtest_max_drawdown'],
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.BASKET],
  },
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.BACKTEST_MAX_DRAWDOWN,
    title: 'Max Drawdown by Year',
    helpLabel: 'yti93j2s',
    form: false,
    defaultParams: { isBasket: false, aggregation: 'ANNUAL' } as DraftParams,
    interactionParams: Object.values(INTERACTION_PARAMS),
    neededFiles: ['backtest_max_drawdown'],
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.BACKTEST],
  },
] satisfies ts.types.analysis.WidgetConfig[];
