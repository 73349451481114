import { mui } from '../libs';

type PanelWithHeaderProps = {
  title: string;
  children: React.ReactNode;

  rightAction?: React.ReactNode;
  icon?: React.ReactNode;
};

/**
 * Component that displays a panel with a header and a content area.
 *
 * @param title - The title to be displayed in the header
 * @param children - The content to be displayed in the panel
 * @param icon - The icon to be displayed in the header
 * @param rightAction - The action to be displayed in the right side of the header
 *
 * @returns Rendered component with a panel having a header and a content area
 */

const PanelWithHeader: React.FC<PanelWithHeaderProps> = ({
  children,
  title,
  icon,
  rightAction,
}): React.ReactElement => {
  const theme = mui.styles.useTheme() as mui.core.Theme;

  return (
    <>
      <mui.core.Box
        p={2}
        display="flex"
        alignItems="center"
        sx={{
          background: theme.palette.background.default,
          '& .MuiSvgIcon-root': {
            marginRight: '8px',
            marginLeft: '4px',
            fontSize: '14px',
          },
        }}
      >
        {icon && icon}
        {/* TODO: ADD VARIANT FOR UPPERCASE */}
        <mui.core.Typography
          variant="overline"
          fontWeight="fontWeightBold"
          color="textSecondary"
          sx={{
            textTransform: 'uppercase',
            letterSpacing: '1px',
            flex: '1',
          }}
        >
          {title}
        </mui.core.Typography>
        {rightAction && rightAction}
      </mui.core.Box>
      <mui.core.Box
        sx={{
          overflow: 'auto',
          height: 'calc(100% - 40px)',
        }}
      >
        {children}
      </mui.core.Box>
    </>
  );
};

PanelWithHeader.defaultProps = {
  icon: null,
  rightAction: null,
};

export default PanelWithHeader;
