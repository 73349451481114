import { helpers, ts } from '_core';

const getDefaultValue = (
  factorName: ts.types.widgets.common.FACTOR_TYPE_ENUM,
  defaultValue: { value: string | string[]; isArray: boolean }
): ts.types.widgets.common.RiskFactorsType[] => {
  if (defaultValue.isArray)
    return (defaultValue.value as string[]).map((v) => ({
      id: helpers.gibberishGenerator.stringGenerator(11),
      type: factorName,
      value: v,
    }));

  return [
    {
      id: helpers.gibberishGenerator.stringGenerator(11),
      type: factorName,
      value: defaultValue.value as string,
    },
  ];
};

export default getDefaultValue;
