import api from '../api';
import { UI_RESOURCE_TYPE_ENUM } from '../enums';
  import { _, moment } from '../libs';
import { Resource } from '../types/common';
import { StoreDataSource } from '../types/data-source';
import { Workspace } from '../types/workspace';

/**
 * Filters a list of resources based on data source subscriptions and user ID
 * @param resourceList - List of resources to filter
 * @param notSubscribed - List of data sources user is not subscribed to
 * @param userId - ID of current user
 * @returns Filtered list of resources
 */
export const filterResourceByDs = (resourceList: Resource[], notSubscribed: StoreDataSource[], userId: number) => {
  return resourceList.filter((el) => {
    const useInvalidDs = _.some(el.data_source_ids || [], (id) => notSubscribed.find((ds) => ds.id == id));
    return !useInvalidDs || el.created_by_id == userId;
  });
};

/**
 * Separates data sources into subscribed and unsubscribed based on whitelist and organization
 * @param dataSources - List of all data sources
 * @param dataSourceWhiteList - List of whitelisted data source handles
 * @param orgId - Organization ID
 * @returns Object containing subscribed and unsubscribed data sources
 */
export const handleDataSourcesSubscriptions = (
  dataSources: StoreDataSource[],
  dataSourceWhiteList: string[],
  orgId: number
) => {
  const subscribed = dataSources.filter((ds) => {
    return (
      ds.ds_type != 'COMMON' ||
      dataSourceWhiteList.includes(ds.handle) ||
      (ds.ds_type == 'COMMON' && orgId == 0 && ds.organization_id == 0)
    );
  });

  const unsubscribed = dataSources.filter((ds) => {
    return ds.ds_type == 'COMMON' && !dataSourceWhiteList.includes(ds.handle);
  });

  return { subscribed, unsubscribed };
};

/**
 * Adds a resource to a workspace
 * @param resourceId - ID of resource to add
 * @param resourceName - Type of resource
 * @param workspace - Workspace to add resource to
 * @param callback - Optional callback function called with updated workspace
 */
export const addToWorkspace = async (
  resourceId: number,
  resourceName: UI_RESOURCE_TYPE_ENUM,
  workspace: Workspace,
  callback?: (_w: Workspace) => void
) => {
  const resourceIdsTag = `${resourceName}_ids` as keyof Workspace;
  const updatedData = { [resourceIdsTag]: [] as number[] };
  updatedData[resourceIdsTag] = [resourceId, ...(workspace[resourceIdsTag] as number[])];

  const resp = await api.workspaces.update(workspace.id, updatedData);
  if (resp && callback) callback(resp.data);
};

/**
 * Gets a formatted label for a resource
 * @param resource - Resource to get label for
 * @returns Formatted string with resource name and handle
 */
export const getResourceLabel = (resource: Resource) => {
  return `${resource.name} (${resource.handle})`;
};

/**
 * Checks if a resource is shared with specified users
 * @param resource - Resource to check
 * @param checkWith - Array of user IDs to check against
 * @returns True if resource is published or shared with all specified users
 */
export const isShared = (resource: Resource, checkWith: number[]) => {
  if (resource.is_published) return true;
  if (_.intersection(resource.shared_with, checkWith).length == checkWith.length) return true;
  return false;
};

/**
 * Checks if a resource was created within the last 5 seconds
 * @param createdAt - Creation timestamp
 * @returns True if resource was created within last 5 seconds
 */
export const justCreated = (createdAt: string) => moment().diff(moment(createdAt), 'seconds') <= 5;
