import { ts } from '_core';

/*
 * Format on the data object naming:
 * Before: weights|earnings_yield
 * After: Earnings Yield
 */
export const prepareWeigtsVsTimeData = (localData: ts.types.widgets.LinearData) => {
  Object.keys(localData).forEach((key) => {
    const jointKey = key.split('|').slice(1, key.length).join(' + ');
    localData[jointKey] = localData[key];
    delete localData[key];
  });
  return localData;
};
