import { _, helpers } from '_core';

import { DraftParams } from './types';

export const validate = (params: DraftParams) => {
  if (_.isEmpty(params?.buckets)) return ['You need to provide at least one bucket.'];

  if (_.some(params.buckets, (b) => !helpers.validations.validBuckets(b)))
    return ['One of the buckets is not correctly defined.'];
  return [];
};
