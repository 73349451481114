import { hooks, mui, React, ts, ui } from '_core';

import { getOptions } from './helpers';
import { getConstraintType } from '../../optimizer-utils/constraint-type';
import Bounds from '../bounds';
import SharedConstraintFields from '../shared-fields';
import Target from '../target';

type AssetAllProps = {
  id: string;
  params: ts.types.optimizer.RiskFactorDraft;
  setParams: (_params: ts.types.optimizer.RiskFactorDraft) => void;
  readOnly: boolean;
  riskModel: ts.types.riskModel.RiskModel;
  optimizerType: 'basket' | 'backtest';
  validationDef: ts.types.common.ValidationErrors;
};

const riskFactorOptions = [
  {
    key: 'FACTOR',
    name: 'All Risk Model Factors',
  },
  {
    key: 'STYLE',
    name: 'Risk Model Style Factor',
  },
  {
    key: 'CATEGORY',
    name: 'Risk Model Category',
  },
];

const RiskFactorConstraint: React.FC<AssetAllProps> = ({
  id,
  params,
  setParams,
  readOnly,
  riskModel,
  optimizerType,
  validationDef,
}): React.ReactElement => {
  const [showMore, setShowMore] = React.useState(false);
  const [constraint, setConstraint] = React.useState(params);
  const [valueOptions, setValueOptions] = React.useState(getOptions(params.type, riskModel));

  React.useEffect(() => {
    if (optimizerType == 'basket' && params.benchmark_relative == null) {
      setConstraint({ ...constraint, benchmark_relative: true });
    }
  }, []);

  hooks.useEffectWithoutFirst(() => {
    const newOptions = getOptions(params.type, riskModel);
    setValueOptions(newOptions);
  }, [params.type, riskModel]);

  hooks.useEffectWithoutFirst(() => {
    setParams(constraint);
  }, [constraint]);

  const form = () => {
    if (getConstraintType(constraint) == 'BOUNDS') {
      if (showMore) {
        return (
          <Bounds
            id={id}
            params={constraint}
            setParams={setConstraint}
            readOnly={readOnly}
            validationDef={validationDef}
          >
            <SharedConstraintFields
              id={id}
              params={constraint}
              setParams={setConstraint}
              readOnly={readOnly}
              validationDef={validationDef}
            />
          </Bounds>
        );
      }

      return (
        <Bounds
          id={id}
          params={constraint}
          setParams={setConstraint}
          readOnly={readOnly}
          validationDef={validationDef}
        />
      );
    }

    if (showMore) {
      return (
        <Target params={constraint} setParams={setConstraint} readOnly={readOnly}>
          <SharedConstraintFields
            id={id}
            params={constraint}
            setParams={setConstraint}
            readOnly={readOnly}
            validationDef={validationDef}
          />
        </Target>
      );
    }

    return <Target params={constraint} setParams={setConstraint} readOnly={readOnly} />;
  };

  return (
    <>
      <mui.core.Box p={2}>
        <mui.core.Box pb={3}>
          <mui.core.Grid container spacing={4}>
            <mui.core.Grid item xs={12} md={3}>
              <mui.core.Box px={3}>
                <mui.core.FormControlLabel
                  control={
                    <mui.core.Switch
                      size="small"
                      checked={constraint.benchmark_relative}
                      onChange={() =>
                        setConstraint({
                          ...constraint,
                          benchmark_relative: !constraint.benchmark_relative,
                        })
                      }
                      disabled={readOnly}
                    />
                  }
                  label={optimizerType == 'backtest' ? 'Benchmark Relative' : 'Relative to Target Weights'}
                />
              </mui.core.Box>
            </mui.core.Grid>
            <mui.core.Grid item xs={12} md={5}>
              <mui.core.TextField
                select
                label="Factor"
                variant="outlined"
                size="small"
                value={constraint.type}
                onChange={(e) =>
                  setConstraint({
                    ...constraint,
                    type: e.target.value as ts.types.optimizer.RiskFactor['type'],
                    value: [],
                  })
                }
                fullWidth
                disabled={readOnly}
                required
                error={validationDef?.fields?.includes(`risk_factor_${id}`)}
              >
                {riskFactorOptions.map((rf) => (
                  <mui.core.MenuItem key={rf.key} value={rf.key}>
                    {rf.name}
                  </mui.core.MenuItem>
                ))}
              </mui.core.TextField>
            </mui.core.Grid>
            <mui.core.Grid item xs={12} md={4}>
              <ui.CheckboxesAutocomplete<string>
                value={constraint.value as string[]}
                setValue={(val) => setConstraint({ ...params, value: val })}
                options={valueOptions as Record<string, any>[]}
                label="Type"
                disabled={readOnly}
                optionLabel={(o) => o.name as string}
                objKey="handle"
                error={validationDef?.fields?.includes(`risk_factor_type_${id}`)}
              />
            </mui.core.Grid>
          </mui.core.Grid>
        </mui.core.Box>
        {form()}

        <mui.core.Button
          onClick={() => {
            setShowMore(!showMore);
          }}
          color="primary"
          style={{ marginTop: '1rem' }}
        >
          {showMore ? (
            <>
              <mui.icons.Remove style={{ fontSize: '14px', marginRight: '0.5rem' }} />
              Show Less
            </>
          ) : (
            <>
              <mui.icons.Add style={{ fontSize: '14px', marginRight: '0.5rem' }} />
              Show More
            </>
          )}
        </mui.core.Button>
      </mui.core.Box>
    </>
  );
};

export default RiskFactorConstraint;
