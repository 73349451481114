import React from 'react';

import HelpContent from './help-content';
import { _, mui } from '../libs';
import * as types from '../types';

type CardContainerProps = React.ComponentProps<typeof mui.core.Box> & {
  children: React.ReactNode;
  helpContent?: types.helpItem.HelpItemContent;
  title?: string | Element;
  titleSize?: 'small' | 'large';
  expanded?: boolean;
  onClick?: () => void;
  errorState?: boolean;
};

/**
 * Container component for definition cards with optional help content and styling
 * 
 * @param children - Main content to be displayed in the card body
 * @param helpContent - Optional help content to display with help icon
 * @param title - Card title text or element
 * @param titleSize - Controls title typography size ('small' or 'large')
 * @param expanded - Controls expanded/collapsed state
 * @param onClick - Click handler for the entire card container
 * @param errorState - Visual error state indicator
 * 
 * @returns Responsive card container with title section and content area
 */

const CardContainer: React.FC<CardContainerProps> = (props) => {
  const isMobile = mui.core.useMediaQuery('(max-width:960px)');
  const boxProps = _.omit(props, ['title', 'children']);

  return (
    <mui.core.Box p={3} {...boxProps} onClick={() => props.onClick}>
      <mui.core.Grid container spacing={4}>
        <mui.core.Grid item xs={12} md={3} mt={1.5} mb={isMobile ? 3 : 0} lg={2}>
          <mui.core.Stack
            gap={2}
            flexDirection="row"
            alignItems="center"
            justifyContent={isMobile ? 'flex-start' : 'flex-end'}
          >
            <mui.core.Box display="flex">
              {props.title && (
                <>
                  {props.helpContent ? (
                    <HelpContent helpContent={props.helpContent}>
                      <mui.core.Typography
                        variant={props.titleSize == 'large' ? 'h4' : 'body2'}
                        color={props.errorState ? 'error' : 'default'}
                        sx={{ fontWeight: 500, textAlign: 'right' }}
                      >
                        {props.title}
                      </mui.core.Typography>
                    </HelpContent>
                  ) : (
                    <mui.core.Typography
                      variant={props.titleSize == 'large' ? 'h4' : 'body2'}
                      color={props.errorState ? 'error' : 'default'}
                      sx={{ fontWeight: 500, textAlign: 'right' }}
                    >
                      {props.title}
                    </mui.core.Typography>
                  )}
                </>
              )}
            </mui.core.Box>
          </mui.core.Stack>
        </mui.core.Grid>
        <mui.core.Grid item xs={12} md={9} lg={10} sx={{ position: 'relative' }}>
          {props.children}
        </mui.core.Grid>
      </mui.core.Grid>
    </mui.core.Box>
  );
};

CardContainer.defaultProps = {
  mb: 3,
  expanded: true,
  titleSize: 'small',
};

export default CardContainer;
