import { _ } from '_core';

export const format = (
  data: Record<string, string | number | boolean>[],
  formatRow?: (_r: Record<string, string | number | boolean>) => Record<string, string | number | boolean>
) => {
  if (_.isEmpty(data)) return data;
  return data.map((row) => (formatRow ? formatRow(row) : row));
};

export const compare = (
  a: Record<string, string | number | boolean>,
  b: Record<string, string | number | boolean>,
  sortColumn: string
) => {
  const aVal = a[sortColumn] || '';
  const bVal = b[sortColumn] || '';
  return aVal < bVal ? -1 : aVal > bVal ? 1 : 0;
};
