import { _, helpers, React, ts, useSelector } from '_core';

import { Params } from './types';
import ChartWrapper from '../../common/chart-wrapper';
import createScatterChart from '../../common/charts/create-scatter-chart';

type ChartProps = {
  id: string;
  readOnly: boolean;
  widgetKey: string;
  loadWidgetData: (_payload: ts.types.widgets.WidgetGetDataParams) => Promise<ts.types.widgets.WidgetGetDataResponse>;
  file: string;
  fullScreen: boolean;
  params: Params;
  context: ts.types.timeSeries.TimeSeriesAnalysisContext;
  widgetTitle: string;
  setExternalEmbed: (_json: ts.types.analysis.EmbedChartData) => void;
};

const Chart: React.FC<ChartProps> = ({
  id,
  readOnly,
  widgetKey,
  loadWidgetData,
  file,
  params,
  context,
  fullScreen,
  widgetTitle,
  setExternalEmbed,
}) => {
  const [data, setData] = React.useState<ts.types.widgets.ScatterData>(null);
  const [error, setError] = React.useState<ts.types.common.ApiError>(null);

  const timeSeries = useSelector((state) => state.resources.time_series);

  const ts1 = timeSeries.find((t) => t.id == (context.time_series_id ?? params.time_series_id));
  const ts2 = timeSeries.find((t) => t.id == params.compare_time_series_id);

  const loadData = async () => {
    try {
      const response = await loadWidgetData({
        data: {
          file,
          rename: { build_date: 'date' },
        },
      });
      if (response) setData(response.data as ts.types.widgets.ScatterData);
    } catch (err) {
      setError(err as ts.types.common.ApiError);
    }
  };

  React.useEffect(() => {
    if (file) loadData();
    return () => setData(null);
  }, [file]);

  React.useEffect(() => {
    if (!_.isNil(data)) {
      const corr = data?.[0]?.['corr'] || null;
      const m = data?.[0]?.['m'] || null;
      const b = data?.[0]?.['b'] || null;
      const eq = helpers.buildName.getEquationStr(m, b);

      const corrLabel = `[bold]Correlation:[/] ${corr ? _.round(corr, 4) : 'Unable to process it.'}`;
      const equationLabel = `[bold]${!_.isEmpty(eq) ? 'y = ' + eq : 'Unable to process the equation.'}[/]`;

      const title = corrLabel + '\u00A0\u00A0\u00A0\u00A0' + equationLabel;

      createScatterChart({
        id,
        fullScreen,
        data: data,
        xAxis: ts2.handle,
        yAxis: ts1.handle,
        xAxisLabel: ts2.name,
        yAxisLabel: ts1.name,
        trendline: data?.[0]?.fit ? 'fit' : undefined,
        title,
        exportTitle: widgetTitle,
        setExternalEmbed,
      });
    }
  }, [data]);

  return (
    <ChartWrapper id={id} readOnly={readOnly} widgetKey={widgetKey} data={data} error={error} fullScreen={fullScreen} />
  );
};

export default Chart;
