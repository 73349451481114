import { ts } from '_core';

// Application of translation here will also affect the search

const constraintsNamesMap: Record<ts.enums.OPTIMIZER_CONSTRAINT_TYPE_ENUM, string> = {
  'all_assets_constraint': 'All Assets',
  'asset_volume_constraint': 'Asset Volume',
  'trade_list_signal_exposure_constraint': 'Trade List Signal Exposure',
  'country_constraint': 'Country',
  'gross_leverage_constraint': 'Gross Leverage',
  'industry_constraint': 'Industry',
  'maximum_risk_constraint': 'Maximum Risk',
  'net_leverage_constraint': 'Net Exposure',
  'risk_factor_constraint': 'Risk Factor',
  'factor_constraint': 'Signal Exposure',
  'specific_assets_constraint': 'Specific Assets',
  'shares_outstanding_constraint': 'Shares Outstanding',
  'stock_loan_availability_constraint': 'Stock Loan Availability',
  'tcost_constraint': 'Maximum TCost',
  'trade_list_constraint': 'Trade List',
  'turnover_constraint': 'Maximum Turnover',
  'short_shares_assets_constraint': 'Locate',
  'cash_constraint': 'Cash',
};

export default constraintsNamesMap;
