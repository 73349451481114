import { _, api, config, helpers, ts } from '_core';

import { prepareDefinitionIndustries } from './definition';

type RiskModelApiResponse = {
  data: ts.types.riskModel.RiskModel;
};

export const createRiskModel = async (
  newData: ts.types.riskModel.RiskModelDraft,
  callback: (_data: ts.types.riskModel.RiskModel) => void,
  errorCallback: (_error: string) => void
) => {
  try {
    const resp: RiskModelApiResponse = await api.risk_models.create(newData);
    callback(resp.data);
  } catch (error) {
    errorCallback(`Error creating risk model - ${helpers.parseApiError(error as ts.types.common.ApiError)}`);
  }
};

export const updateRiskModel = async (
  id: number,
  newData: ts.types.riskModel.RiskModelDraft,
  callback: (_data: ts.types.riskModel.RiskModel) => void,
  errorCallback: (_error: string) => void
) => {
  try {
    const resp: RiskModelApiResponse = await api.risk_models.update(id, { ...newData });
    callback(resp.data);
  } catch (error) {
    errorCallback(`Error updating risk model - ${helpers.parseApiError(error as ts.types.common.ApiError)}`);
  }
};

export const updateDefinition = async (
  id: number,
  def: ts.types.riskModel.DefinitionDraft,
  metadata: ts.types.riskModel.UiMetaData,
  isValid: boolean,
  callback: (_data: ts.types.riskModel.RiskModel) => void,
  errorCallback: (_error: string) => void,
  updateDefTimestamp = true,
  skipHandleUpdate = false
) => {
  try {
    // Create new handles by name
    const [newDef, newMetadata] = skipHandleUpdate ? [def, metadata] : prepareDefinitionIndustries(def, metadata);

    const resp = await api.risk_models.update(
      id,
      {
        definition: newDef,
        ui_metadata: {
          ...newMetadata,
          // Clear collapsed sections on external update
          sections: updateDefTimestamp ? newMetadata?.sections : null,
        },
        is_valid: isValid,
        skip_signature_check: !isValid,
      },
      updateDefTimestamp
    );

    if (resp.data?.validation_error && !resp.data?.is_valid) errorCallback(resp.data?.validation_error);
    else callback(resp.data);
  } catch (error) {
    errorCallback(`Error updating the definition - ${helpers.parseApiError(error as ts.types.common.ApiError)}`);
  }
};

export const updateUiMetadata = async (
  riskModel: ts.types.riskModel.RiskModel,
  uiMetaData: ts.types.riskModel.UiMetaData,
  callback: (_data: ts.types.riskModel.RiskModel) => void,
  errorCallback: (_error: string) => void
) => {
  if (riskModel.shared) return;
  if (riskModel.organization_id == 0) return;
  try {
    const updateData = {
      ui_metadata: uiMetaData,
      skip_signature_check: true,
    } as ts.types.riskModel.RiskModelDraft;
    const resp = await api.risk_models.update(riskModel.id, updateData);
    callback(resp.data);
  } catch (error) {
    errorCallback(`Error updating the resource metadata - ${helpers.parseApiError(error as ts.types.common.ApiError)}`);
  }
};

const createAnalyses = async (id: number, names: ts.enums.RISK_MODEL_ANALYSIS_TYPE_ENUM[]) => {
  const analyses = [];
  for (const name of names) {
    let analysisDefinition = {
      widgets: [],
    } as ts.types.analysis.AnalysisDefinition;

    if (name == ts.enums.RISK_MODEL_ANALYSIS_TYPE_ENUM.PREVIEW) {
      // default preview value
      analysisDefinition = {
        mode: ts.enums.ANALYSIS_MODE_ENUM.FULLSCREEN,
        widgets: [],
      };
    }

    if (name == ts.enums.RISK_MODEL_ANALYSIS_TYPE_ENUM.CATEGORY) {
      // default industry selector value
      analysisDefinition = {
        mode: ts.enums.ANALYSIS_MODE_ENUM.FULLSCREEN,
        widgets: [
          {
            id: helpers.gibberishGenerator.stringGenerator(11),
            key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.RISK_MODEL_CATEGORY_SELECTOR,
            params: {
              industry_min_prob: 0.3,
            },
          },
        ],
      };
    }

    if (name == ts.enums.RISK_MODEL_ANALYSIS_TYPE_ENUM.REPORT) {
      // default report value
      analysisDefinition = {
        mode: ts.enums.ANALYSIS_MODE_ENUM.FULLSCREEN,
        widgets: [
          {
            id: helpers.gibberishGenerator.stringGenerator(11),
            key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.RISK_MODEL_TABULAR_SIGNIFICANCE,
            params: {},
          },
        ],
      };
    }

    // create analysis
    const response = await api.risk_model_analyses.create({
      report_definition: analysisDefinition,
      risk_model_id: id,
      name,
    });
    analyses.push(response.data.name);
  }
  return analyses;
};

// Load analyses, and if we don't have them, create them
export const loadAnalyses = async (
  riskModel: ts.types.riskModel.RiskModel,
  analyses: ts.enums.RISK_MODEL_ANALYSIS_TYPE_ENUM[],
  setAnalysesNames: (_data: ts.enums.RISK_MODEL_ANALYSIS_TYPE_ENUM[]) => void,
  errorCallback: (_error: string) => void
) => {
  try {
    const resp = await api.risk_model_analyses.search({
      ignore_user_scope: riskModel.shared,
      query: ['$and', ['risk_model_id', '=', riskModel.id]],
      include: 'id,name',
    });

    let localNames = (resp.data || []).map(
      (a: ts.types.analysis.ResourceAnalysis) => a.name
    ) as ts.enums.RISK_MODEL_ANALYSIS_TYPE_ENUM[];

    const toCreate = _.without(analyses, ...localNames);
    if (!_.isEmpty(toCreate)) {
      const newNames = (await createAnalyses(riskModel.id, toCreate)) as ts.enums.RISK_MODEL_ANALYSIS_TYPE_ENUM[];
      localNames = localNames.concat(newNames);
    }

    setAnalysesNames(localNames);
  } catch (error) {
    errorCallback(`Error loading risk model analyses - ${helpers.parseApiError(error as ts.types.common.ApiError)}`);
  }
};

export const keepCurrent = async (
  riskModel: ts.types.riskModel.RiskModelDraft,
  callback: (_data: { resource: ts.types.riskModel.RiskModel; universe?: ts.types.universe.Universe }) => void,
  errorCallback: (_error: string) => void
) => {
  try {
    if (riskModel.shared) return;
    const resp = await api.dates.keep_current({
      resource_id: riskModel.id,
      resource_type: 'risk_models',
      // Until we don't have a universe, our keep current will be just the max date we can have
      universe_id: riskModel.definition?.universe_id,
      max_date: config.features.end_date,
    });
    callback(resp);
  } catch (error) {
    errorCallback(`Error updating risk model dates - ${helpers.parseApiError(error as ts.types.common.ApiError)}`);
  }
};
