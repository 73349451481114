import { ts } from '_core';


export default [
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.BASKET_RISK_FACTOR_EXPOSURE,
    title: 'Factor Exposure',
    helpLabel: 'idnwonc7',
    form: false,
    neededFiles: ['backtest_factor_exposure_analysis'],
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.BASKET_PORTFOLIO_RISK],
  },
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.BACKTEST_RISK_FACTOR_EXPOSURE,
    title: 'Factor Exposure',
    helpLabel: 'bwbczzcn',
    form: false,
    neededFiles: ['backtest_factor_exposure_analysis'],
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.BACKTEST_PORTFOLIO_RISK],
  },
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.PORTFOLIO_RISK_FACTOR_EXPOSURE,
    title: 'Factor Exposure',
    helpLabel: '2v4eb8r9',
    form: false,
    neededFiles: ['portfolio_factor_exposure_analysis'],
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.PORTFOLIO_RISK],
  },
] satisfies ts.types.analysis.WidgetConfig[];
