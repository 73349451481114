import { mui, React, ts } from '_core';

type IndustryProps = {
  value: ts.types.pipeline.NeutralizeDraft['factors'][0];
  setValue: (_value: ts.types.pipeline.NeutralizeDraft['factors'][0]) => void;
};

const Industry: React.FC<IndustryProps> = ({ value, setValue }): React.ReactElement => {
  let industries = {
    [ts.enums.NEUTRALIZE_RISK_MODEL_STYLE_ENUM.SECTOR]: 'Sector',
    [ts.enums.NEUTRALIZE_RISK_MODEL_STYLE_ENUM.SUB_SECTOR]: 'Sub Sector',
    [ts.enums.NEUTRALIZE_RISK_MODEL_STYLE_ENUM.INDUSTRY]: 'Industry',
    [ts.enums.NEUTRALIZE_RISK_MODEL_STYLE_ENUM.SUB_INDUSTRY]: 'Sub Industry',
  };

  if (value?.value?.includes('gics')) {
    industries = {
      ...industries,
      ...{
        [ts.enums.NEUTRALIZE_RISK_MODEL_STYLE_OUTDATED_ENUM.SECTOR]: 'Sector (Outdated)',
        [ts.enums.NEUTRALIZE_RISK_MODEL_STYLE_OUTDATED_ENUM.GROUP]: 'Sub Sector (Outdated)',
        [ts.enums.NEUTRALIZE_RISK_MODEL_STYLE_OUTDATED_ENUM.INDUSTRY]: 'Industry (Outdated)',
        [ts.enums.NEUTRALIZE_RISK_MODEL_STYLE_OUTDATED_ENUM.SUB_INDUSTRY]: 'Sub Industry (Outdated)',
      },
    };
  }

  return (
    <mui.core.TextField
      select
      label="Value"
      variant="outlined"
      size="small"
      value={value.value}
      onChange={(e) => setValue({ ...value, value: e.target.value })}
      fullWidth
      required
    >
      {Object.keys(industries).map((key) => (
        <mui.core.MenuItem key={key} value={key}>
          {industries[key as ts.enums.NEUTRALIZE_RISK_MODEL_STYLE_ENUM]}
        </mui.core.MenuItem>
      ))}
    </mui.core.TextField>
  );
};

export default Industry;
