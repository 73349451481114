/* eslint-disable max-len */
import { ts } from '_core';

import { validate } from './helpers';
import { DraftParams, INTERACTION_PARAMS } from './types';

export default [
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.METRIC_BASKET_BENCHMARK_ALLOCATION,
    form: true,
    helpLabel: 'JSd2jfs3',
    title: 'Benchmark Allocation',
    interactionParams: Object.values(INTERACTION_PARAMS),
    neededFiles: ['backtest_details'],
    defaultParams: { type: 'show_all' } as DraftParams,
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.BASKET_ADHOC],

    validate,
    getAdhocData: (pathFile, params, _context, resources: ts.StoreState['resources']) => {
      const benchmarkName = resources.baskets.find((b) => b.id == params.basket_id)?.name ?? 'Benchmark';
      return {
        name: `${benchmarkName} Allocation for ${params.details_start_date}`,
        file_name: pathFile,
        description: `This file contains the asset allocation of the ${benchmarkName} benchmark as of ${params.details_start_date}.`,
        columns: [
          { key: 'asset', name: 'name', description: 'Asset name', type: 'string' },
          {
            key: 'main_initial',
            name: 'initial',
            description: 'Allocation before optimization.',
            type: 'float',
          },
          {
            key: 'main_optimal',
            name: 'optimal',
            description: 'Optimal allocation after optimization.',
            type: 'float',
          },
          {
            key: 'main_after_costs',
            name: 'allocation',
            description: 'Allocation after returns and after costs.',
            type: 'float',
          },
          { key: 'rbics_l1_name', name: 'sector', description: 'Asset sector.', type: 'string' },
          {
            key: 'rbics_l3_name',
            name: 'industry',
            description: 'Asset industry.',
            type: 'string',
          },
        ],
      };
    },
  },
] satisfies ts.types.analysis.WidgetConfig[];
