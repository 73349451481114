import { config, hooks, moment, mui, React, ui } from '_core';

import { DraftParams, Params } from './types';

type FormProps = {
  params: DraftParams;
  setParams: (_p: DraftParams) => void;

  riskModelStartDate?: string;
  riskModelEndDate?: string;
};

const Form: React.FC<FormProps> = ({ params, setParams, riskModelStartDate, riskModelEndDate }) => {
  const uiStyles = hooks.useUiStyles();

  const getInitialStartDate = () => {
    if (params.start_date) return params.start_date;

    const subtractedStartDate = moment(riskModelEndDate).subtract(1, 'years').format('YYYY-MM-DD');
    if (subtractedStartDate < riskModelStartDate) return riskModelStartDate;

    return subtractedStartDate;
  };

  const [startDate, setStartDate] = React.useState(getInitialStartDate);
  const [endDate, setEndDate] = React.useState(params.end_date || riskModelEndDate);
  const [assets, setAssets] = React.useState<Params['assets']>(params.assets || ({} as Params['assets']));

  const [componentErrors, setComponentErrors] = React.useState([]);

  React.useEffect(() => {
    const data = {
      start_date: startDate,
      end_date: endDate,
      assets,
      asset_filter: assets.list || [],
      component_errors: componentErrors,
    };

    setParams({ ...params, ...data });
  }, [startDate, endDate, assets, componentErrors]);

  const rangeValue = React.useMemo(() => {
    return [startDate, endDate] as string[];
  }, [startDate, endDate]);

  return (
    <mui.core.Box sx={uiStyles.widgetEditMode}>
      <mui.core.Box mb={4}>
        <ui.AssetSelector
          value={assets.list}
          setValue={(val) => {
            setAssets({
              ...assets,
              list: val as string[],
            });
          }}
          multiple
        />
      </mui.core.Box>

      <ui.SmartDateRangeSelector
        value={rangeValue}
        onChange={(v) => {
          setStartDate(v[0]);
          setEndDate(v[1]);
        }}
        minDate={riskModelStartDate || config.features.start_date}
        maxDate={riskModelEndDate || config.features.end_date}
        setExternalError={(e) => setComponentErrors(e ? [e] : [])}
      />
    </mui.core.Box>
  );
};

Form.defaultProps = {
  riskModelStartDate: null,
  riskModelEndDate: null,
};

export default Form;
