import React from 'react';

import ContextSingleton from '../../__singletons__/context-singleton';
import { _, mui } from '../../libs';
import * as types from '../../types';
import FinDatePicker from '../date-picker';

type AssetTableProps = {
  value: types.assetSelector.AssetSelectorMetaData[];
  setValue: (_value: types.assetSelector.AssetSelectorMetaData[]) => void;
  createTitle: (_asset: types.assetSelector.AssetSelectorMetaData) => string;
  createId: (_asset: types.assetSelector.AssetSelectorMetaData) => string;
  disabled?: boolean;
  fields?: types.assetSelector.AssetSelectorTableFields[];
  withDateRanges?: boolean;
  dateRanges?: types.optimizer.TradeListConstraintDraft['asset_date_range'];
  onDateRangesChange?: (_ranges: types.optimizer.TradeListConstraintDraft['asset_date_range']) => void;
};

/**
 * Renders a table of assets with date ranges.
 *
 * @requires features:
 * - start_date: Default start date if not provided.
 * - end_date: Default end date if not provided.
 *
 * @param value - The assets to display in the table.
 * @param setValue - A function to update the assets in the table.
 * @param fields - The fields to display in the table.
 * @param withDateRanges - Whether to display date ranges in the table.
 * @param dateRanges - The date ranges to display in the table.
 * @param onDateRangesChange - A function to update the date ranges in the table.
 *
 * @returns A React element representing the asset table.
 */
const AssetTable: React.FC<AssetTableProps> = ({
  value,
  setValue,
  fields,
  createId,

  withDateRanges,
  dateRanges,
  onDateRangesChange,
  disabled = false,
}): React.ReactElement => {
  const features = ContextSingleton.getInstance().features;

  const [enabledExpirations, setEnabledExpirations] = React.useState<Set<string>>(
    new Set(dateRanges?.filter((r) => r.start_date || r.end_date).map((r) => r.asset_fid))
  );

  const [localDateRanges, setLocalDateRanges] = React.useState<
    types.optimizer.TradeListConstraintDraft['asset_date_range']
  >(dateRanges || []);

  React.useEffect(() => {
    if (!_.isEqual(dateRanges, localDateRanges)) {
      setLocalDateRanges(dateRanges || []);
    }
  }, [dateRanges]);

  const debouncedOnChange = React.useCallback(
    _.debounce((newRanges: types.optimizer.TradeListConstraintDraft['asset_date_range']) => {
      onDateRangesChange?.(newRanges);
    }, 300),
    [onDateRangesChange]
  );

  const headers = [
    ...fields.map((field, idx) => (
      <mui.core.TableCell key={idx}>
        <strong>{field.title}</strong>
      </mui.core.TableCell>
    )),
    withDateRanges && (
      <mui.core.TableCell>
        <strong>Date Range</strong>
      </mui.core.TableCell>
    ),
  ];

  const handleDateChange = (fid: string, type: 'start_date' | 'end_date', value: string) => {
    const newRanges = [...localDateRanges];
    const existingRange = newRanges.find((r) => r.asset_fid === fid);

    if (existingRange) {
      existingRange[type] = value;
    } else {
      newRanges.push({
        asset_fid: fid,
        [type]: value,
      });
    }

    setLocalDateRanges(newRanges);
    debouncedOnChange(newRanges);
  };

  const handleToggleDate = (fid: string) => {
    const isCurrentlyEnabled = enabledExpirations.has(fid);
    const newEnabledExpirations = new Set(enabledExpirations);

    if (isCurrentlyEnabled) {
      newEnabledExpirations.delete(fid);
      const hasRange = localDateRanges.find((r) => r.asset_fid === fid);
      if (hasRange) {
        const newRanges = localDateRanges.filter((r) => r.asset_fid !== fid);
        setLocalDateRanges(newRanges);
        debouncedOnChange(newRanges);
      }
    } else {
      newEnabledExpirations.add(fid);
    }

    setEnabledExpirations(newEnabledExpirations);
  };

  const items = ((value || []) as types.assetSelector.AssetSelectorMetaData[]).map((obj) => (
    <mui.core.TableRow key={createId(obj)}>
      {fields.map((field, idx) => (
        <mui.core.TableCell key={idx}>
          {obj?.[field.key as keyof types.assetSelector.AssetSelectorMetaData]}
        </mui.core.TableCell>
      ))}

      {withDateRanges && (
        <mui.core.TableCell>
          <mui.core.Box display="flex" gap={1} alignItems="center">
            <mui.core.FormControlLabel
              control={
                <mui.core.Switch
                  checked={enabledExpirations.has(obj.fid)}
                  onChange={() => handleToggleDate(obj.fid)}
                  disabled={disabled}
                  size="small"
                />
              }
              label="Expiration"
              labelPlacement="start"
              style={{ margin: 0, marginRight: 8 }}
            />
            {enabledExpirations.has(obj.fid) && (
              <>
                <FinDatePicker
                  clearable
                  fullWidth
                  label="Start"
                  size="small"
                  value={localDateRanges?.find((r) => r.asset_fid === obj.fid)?.start_date || ''}
                  onChange={(date: string) => handleDateChange(obj.fid, 'start_date', date)}
                  disabled={disabled}
                  inputVariant="outlined"
                  minDate={features.start_date}
                  maxDate={localDateRanges?.find((r) => r.asset_fid === obj.fid)?.end_date || features.end_date}
                />
                <FinDatePicker
                  clearable
                  fullWidth
                  label="End"
                  size="small"
                  value={localDateRanges?.find((r) => r.asset_fid === obj.fid)?.end_date || ''}
                  onChange={(date: string) => handleDateChange(obj.fid, 'end_date', date)}
                  disabled={disabled}
                  inputVariant="outlined"
                  minDate={localDateRanges?.find((r) => r.asset_fid === obj.fid)?.start_date || features.start_date}
                  maxDate={features.end_date}
                />
              </>
            )}
          </mui.core.Box>
        </mui.core.TableCell>
      )}

      {!disabled && (
        <mui.core.TableCell
          style={{
            textAlign: 'right',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
            justifyContent: 'center',
          }}
        >
          <mui.icons.Close
            sx={{
              color: 'lightgrey',
              cursor: 'pointer',
              '&:hover': {
                color: 'inherit',
              },
            }}
            onClick={() => {
              setValue(value.filter((el) => el.fid !== obj.fid));
            }}
          />
        </mui.core.TableCell>
      )}
    </mui.core.TableRow>
  ));

  const data = (
    <>
      {items.length > 0 && (
        <mui.core.TableContainer>
          <mui.core.Table size={!withDateRanges ? 'small' : 'medium'}>
            <mui.core.TableHead>
              <mui.core.TableRow>
                {headers}
                <mui.core.TableCell />
              </mui.core.TableRow>
            </mui.core.TableHead>
            <mui.core.TableBody>{items}</mui.core.TableBody>
          </mui.core.Table>
        </mui.core.TableContainer>
      )}
    </>
  );

  return (
    <mui.core.Box
      color={disabled ? 'text.disabled' : 'null'}
      style={{
        maxWidth: !withDateRanges ? '600px' : '100%',
        marginTop: 10,
      }}
    >
      {data}
    </mui.core.Box>
  );
};

export default AssetTable;
