/* eslint-disable no-unused-vars */
import { ts } from '_core';

import { PartialDeep } from 'type-fest';

export enum INTERACTION_PARAMS {
  TABLE_PARAMS = 'table_params',
  EXPANDED = 'expanded',
}

export type InteractionParams = {
  [INTERACTION_PARAMS.EXPANDED]: Record<string, string[]>;
  [INTERACTION_PARAMS.TABLE_PARAMS]: Record<string, ts.types.components.dataGrid.TableParams>;
};

export type JobParams = {
  buckets: ts.types.widgets.common.BucketValue[];
};

export type Params = JobParams & InteractionParams & { fullWidth: boolean };
export type DraftParams = PartialDeep<Params>;
