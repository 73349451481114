import { mui, React, ts, ui } from '_core';

type DebugFilesProps = {
  params: ts.types.widgets.common.DebugFilesParams;
};

const DebugFiles: React.FC<DebugFilesProps> = ({ params }) => {
  return (
    <mui.core.Box mt={5} p={2}>
      <ui.EmptyMessage>{params.text || 'Add the download raw files widget.'}</ui.EmptyMessage>
    </mui.core.Box>
  );
};

export default DebugFiles;
