import { _, ts } from '_core';

const getOptions = (type: ts.types.optimizer.RiskFactor['type'], riskModel: ts.types.riskModel.RiskModel) => {
  if (type && riskModel) {
    const style = riskModel.definition?.style || [];
    const industry = riskModel.definition?.categories.find((c) => c.handle == 'industry')?.factors || [];

    if (type == 'STYLE') return [{ name: 'select-all', handle: 'select-all', theme: 'select-all' }, ...style];
    if (type == 'CATEGORY') return [{ name: 'select-all', handle: 'select-all', theme: 'select-all' }, ...industry];
    if (type == 'FACTOR')
      return _.uniqBy(
        [{ name: 'select-all', handle: 'select-all', theme: 'select-all' }, ...style, ...industry],
        'handle'
      );
  }

  return [];
};

export { getOptions };
