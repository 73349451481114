import { config, mui, React, ts, ui } from '_core';

import { DraftParams } from './types';

type FormProps = {
  params: DraftParams;
  setParams: (_p: DraftParams) => void;

  frequency?: ts.enums.FREQUENCY_ENUM;
  universeStartDate?: string;
  universeEndDate?: string;
};

const Form: React.FC<FormProps> = ({ params, setParams, frequency, universeStartDate, universeEndDate }) => {
  const [snapshotDate, setSnapshotDate] = React.useState(params.end_date || universeEndDate);

  const [componentErrors, setComponentErrors] = React.useState([]);

  React.useEffect(() => {
    const data = {
      start_date: snapshotDate,
      end_date: snapshotDate,
      component_errors: componentErrors,
    };
    setParams({ ...params, ...data });
  }, [snapshotDate, componentErrors]);

  return (
    <mui.core.Box mt={4} mb={3}>
      <ui.SmartDateSelector
        label="Snapshot Date"
        value={snapshotDate}
        setValue={(date) => setSnapshotDate(date)}
        minDate={universeStartDate || config.features.start_date}
        maxDate={universeEndDate || config.features.end_date}
        frequency={frequency}
        setExternalError={(e) => setComponentErrors(e ? [e] : [])}
      />
    </mui.core.Box>
  );
};

Form.defaultProps = {
  universeStartDate: null,
  universeEndDate: null,
};

export default Form;
