import { React, ts } from '_core';

import Chart from './chart';
import Form from './form';
import { DraftParams, Params } from './types';

type AnnualizedAverageBucketedStatisticsProps = {
  id: string;
  readOnly: boolean;
  widgetKey: string;
  mode: ts.enums.CHART_MODE_ENUM;
  params: Params | DraftParams;
  setParams: (_p: DraftParams) => void;
  title: string;

  fullScreen?: boolean;
  context?: ts.types.signal.SignalAnalysisContext;
  loadWidgetData: (_payload: ts.types.widgets.WidgetGetDataParams) => Promise<ts.types.widgets.WidgetGetDataResponse>;
  widgetPaths?: Record<'bucketed_returns', string>;
};

const AnnualizedAverageBucketedStatistics: React.FC<AnnualizedAverageBucketedStatisticsProps> = ({
  id,
  readOnly,
  widgetKey,
  loadWidgetData,
  widgetPaths,
  mode,
  params,
  setParams,
  fullScreen,
  context,
  title,
}) => {
  const renderJobComponent = () => (
    <>
      <Chart
        id={id}
        readOnly={readOnly}
        widgetKey={widgetKey}
        loadWidgetData={loadWidgetData}
        returns={widgetPaths['bucketed_returns']}
        fullScreen={fullScreen}
        params={params as Params}
        title={title}
      />
    </>
  );

  return (
    <>{mode == 'edit' ? <Form params={params} setParams={setParams} context={context} /> : renderJobComponent()}</>
  );
};

export default AnnualizedAverageBucketedStatistics;
