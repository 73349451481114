import { helpers, ts } from '_core';

import { DraftParams } from './types';
import { getBucketsTitle } from '../../common/utils/get-name';

export const validate = (params: DraftParams) => {
  if (!helpers.validations.validBuckets(params.buckets)) return ['Buckets are invalid.'];
  return [];
};

export const getName = (
  params: DraftParams,
  _context: ts.types.backtest.BacktestAnaylsisContext | ts.types.basket.BasketAnalysisContext,
  resources: ts.StoreState['resources']
) => {
  return getBucketsTitle(params.buckets, 'Bucketed Cumulative Returns', resources);
};
