import { _, helpers, ts } from '_core';

import { comparators } from 'views/report/tables/tabular/data-grid/utils';

const fixedCols = ['residuals', 'total'];
export const getColumns = (
  columnData: ts.types.widgets.TableData,
  sheet: string,
  startDate: string,
  endDate: string,
  statisticsType: ts.enums.REPORT_ENUMS.ANNU_STATISTICS_ENUM
) => {
  let columns = [] as ts.types.components.dataGrid.ColumnsData;

  const defaultColumns = {
    summary: [
      {
        key: 'year',
        name: 'Year',
        formatter: helpers.tableFormatters.formatTo('string', { align: 'left' }),
        frozen: true,
        defaultSortComparator: comparators.compareHistoryPeriods,
      },
      {
        key: 'start_date',
        name: 'Start Date',
        filter: 'date',
        start_date: startDate,
        end_date: endDate,
        formatter: helpers.tableFormatters.formatTo('date', { align: 'right', format: 'YYYY-MM-DD' }),
        frozen: true,
      },
      {
        key: 'end_date',
        name: 'End Date',
        filter: 'date',
        start_date: startDate,
        end_date: endDate,
        formatter: helpers.tableFormatters.formatTo('date', { align: 'right', format: 'YYYY-MM-DD' }),
        frozen: true,
      },
    ],
    details: [
      {
        key: 'start_date',
        name: 'Start Date',
        filter: 'date',
        start_date: startDate,
        end_date: endDate,
        formatter: helpers.tableFormatters.formatTo('date', { align: 'right', format: 'YYYY-MM-DD' }),
        frozen: true,
      },
      {
        key: 'end_date',
        name: 'End Date',
        filter: 'date',
        start_date: startDate,
        end_date: endDate,
        formatter: helpers.tableFormatters.formatTo('date', { align: 'right', format: 'YYYY-MM-DD' }),
        frozen: true,
      },
    ],
    matrix: [{ key: 'matrix_variable', name: '', frozen: true }],
  };

  const currentDataKeys = [...new Set((columnData || []).flatMap((r) => Object.keys(r ?? {})))].sort();

  if (!sheet.includes('summary')) {
    columns = [...defaultColumns['details']];
  } else if (currentDataKeys.includes('matrix_variable')) {
    columns = [...defaultColumns['matrix']];
  } else if (sheet.includes('summary')) {
    columns = [...defaultColumns['summary']];
  }

  const numberFormatting =
    statisticsType == 'adjusted' && sheet.includes('summary') ? {} : { multiplier: 100, suffix: '%' };

  currentDataKeys.forEach((key) => {
    if (!_.some(columns, (c) => c.key == key)) {
      // This prevent duplicated columns

      const colName = key;
      if (!fixedCols.includes(key) && key != 'date') {
        columns.push({
          key,
          formatter: helpers.tableFormatters.formatTo('float', {
            align: 'right',
            roundDigits: 2,
            ...numberFormatting,
          }),
          name: colName,
        });
      }
    }
  });

  const residualsColumn = currentDataKeys.find((c) => c === 'residuals');
  if (residualsColumn) {
    columns.push({
      key: 'residuals',
      formatter: helpers.tableFormatters.formatTo('float', {
        align: 'right',
        roundDigits: 2,
        ...numberFormatting,
      }),
      name: 'Residuals',
    });
  }

  // Send to start
  const totalColumn = currentDataKeys.find((c) => c === 'total');
  if (totalColumn) {
    columns = [
      {
        key: 'total',
        formatter: helpers.tableFormatters.formatTo('float', {
          align: 'right',
          roundDigits: 2,
          ...numberFormatting,
        }),
        name: 'Total',
      } as ts.types.components.dataGrid.ColumnsData[0],
    ].concat(columns);
  }

  return columns;
};
