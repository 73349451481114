import { api, mui, React, ts } from '_core';

const MessageAttachments: React.FC<{
  message: ts.types.aiAssistant.MessageDraft;
}> = ({ message }) => {
  const [images, setImages] = React.useState<string[]>([]);

  const loadImages = async () => {
    const localImages = [];
    const attachments = (message?.attachments ?? []).filter((at) => at.file_type === 'png');
    for (const img of attachments) {
      const resp = await api.s3.getImage(img.file_path, img.job_id);
      localImages.push(resp);
    }
    setImages(localImages);
  };

  React.useEffect(() => {
    loadImages();
  }, []);

  return (
    <mui.core.Box>
      {images.map((img) => (
        <mui.core.Box key={img} sx={{ marginTop: '40px' }} alignSelf="center">
          <img src={img} width="100%" />
        </mui.core.Box>
      ))}
    </mui.core.Box>
  );
};

export default MessageAttachments;
