import { React, ts } from '_core';

import Chart from './chart';
import Form from './form';
import { DraftParams, Params } from './types';

type CumulativeReturnsProps = {
  id: string;
  readOnly: boolean;
  widgetKey: string;
  mode: ts.enums.CHART_MODE_ENUM;
  params: Params | DraftParams;
  setParams: (_p: DraftParams) => void;
  title: string;
  setExternalEmbed: (_json: ts.types.analysis.EmbedChartData) => void;

  subtitle?: string;
  fullScreen?: boolean;
  context?: ts.types.signal.SignalAnalysisContext;
  loadWidgetData: (_payload: ts.types.widgets.WidgetGetDataParams) => Promise<ts.types.widgets.WidgetGetDataResponse>;
  widgetPaths?: Record<'signal_cumulative_returns', string>;
};

const CumulativeReturns: React.FC<CumulativeReturnsProps> = ({
  id,
  readOnly,
  widgetKey,
  loadWidgetData,
  widgetPaths,
  mode,
  params,
  setParams,
  fullScreen,
  context,
  title,
  subtitle,
  setExternalEmbed,
}) => {
  const chart = () => (
    <Chart
      id={id}
      readOnly={readOnly}
      widgetKey={widgetKey}
      loadWidgetData={loadWidgetData}
      returns={widgetPaths['signal_cumulative_returns']}
      params={params as Params}
      fullScreen={fullScreen}
      frequency={context.frequency as ts.enums.FREQUENCY_ENUM}
      title={title}
      subtitle={subtitle}
      setExternalEmbed={setExternalEmbed}
    />
  );

  return <>{mode == 'edit' ? <Form params={params} setParams={setParams} context={context} /> : <>{chart()}</>}</>;
};

export default CumulativeReturns;
