import { mui, React, ts } from '_core';

type AssetMasterProps = {
  value: ts.types.pipeline.NeutralizeDraft['factors'][0];
  setValue: (_value: ts.types.pipeline.NeutralizeDraft['factors'][0]) => void;
};

const AssetMaster: React.FC<AssetMasterProps> = ({ value, setValue }): React.ReactElement => {
  const assetMaster = {
    [ts.enums.NEUTRALIZE_ASSET_MASTER_ENUM.CURRENCY]: 'Currency',
    [ts.enums.NEUTRALIZE_ASSET_MASTER_ENUM.QUOTATION_COUNTRY]: 'Quotation Country',
  };

  return (
    <mui.core.TextField
      select
      label="Value"
      variant="outlined"
      size="small"
      value={value.value}
      onChange={(e) => setValue({ ...value, value: e.target.value })}
      fullWidth
      required
    >
      {Object.keys(assetMaster).map((key) => (
        <mui.core.MenuItem value={key} key={key}>
          {assetMaster[key as ts.enums.NEUTRALIZE_ASSET_MASTER_ENUM]}
        </mui.core.MenuItem>
      ))}
    </mui.core.TextField>
  );
};

export default AssetMaster;
