import { React, ts } from '_core';

import Chart from './chart';
import { Params } from './types';

type portfolioPreviewHoldingsProps = {
  id: string;
  context?: ts.types.rebalance.RebalanceAnalysisContext;
  loadWidgetData: (_payload: ts.types.widgets.WidgetGetDataParams) => Promise<ts.types.widgets.WidgetGetDataResponse>;
  params: Params;
  setParams: (_p: Params) => void;
  widgetPaths?: Record<'rebalance_constraints_info', string>;
  goFullScreen?: (_b: string) => void;
  fullScreen?: boolean;

  queryParams?: { initial?: string };
};

const portfolioPreviewHoldings: React.FC<portfolioPreviewHoldingsProps> = ({
  id,
  loadWidgetData,
  params,
  setParams,
  widgetPaths,
  fullScreen,
  goFullScreen,
  queryParams,
  context,
}) => {
  return (
    <Chart
      id={id}
      loadWidgetData={loadWidgetData}
      constraints={widgetPaths['rebalance_constraints_info']}
      fullScreen={fullScreen}
      queryParams={queryParams}
      goFullScreen={goFullScreen}
      context={context}
      params={params}
      setParams={setParams}
    />
  );
};

export default portfolioPreviewHoldings;
