import { Helmet } from '../libs';

type PageTitleProps = {
  appName?: string;
  title: string;
};

/**
 * Component that displays a page title with a Helmet title tag.
 *
 * @param title - The title to be displayed in the Helmet title tag
 *
 * @returns Rendered component with a Helmet title tag
 *
 */

const PageTitle: React.FC<PageTitleProps> = ({ title, appName }): React.ReactElement => {
  return (
    <Helmet>
      <title>
        {title} – {appName || 'Finsera'}
      </title>
    </Helmet>
  );
};

export default PageTitle;
