import { config, moment, mui, React, ui } from '_core';

import { DraftParams } from './types';

type FormProps = {
  params: DraftParams;
  setParams: (_p: DraftParams) => void;

  contextStartDate?: string;
  contextEndDate?: string;
  universeId?: number;
};

const Form: React.FC<FormProps> = ({ params, setParams, contextStartDate, contextEndDate, universeId }) => {
  const getInitialStartDate = () => {
    if (params.preview_start_date) return params.preview_start_date;

    const subtractedStartDate = moment(contextEndDate).subtract(1, 'years').format('YYYY-MM-DD');
    if (subtractedStartDate < contextStartDate) return contextStartDate;

    return subtractedStartDate;
  };

  const [startDate, setStartDate] = React.useState(getInitialStartDate);
  const [endDate, setEndDate] = React.useState(params.preview_end_date || contextEndDate);
  const [assets, setassets] = React.useState(params.assets || ([] as DraftParams['assets']));
  const [debug, setDebug] = React.useState(params.debug);

  const [componentErrors, setComponentErrors] = React.useState([]);

  React.useEffect(() => {
    const data = {
      preview_start_date: startDate,
      preview_end_date: endDate,
      assets: assets || [],
      component_errors: componentErrors,
      debug,
    };

    setParams({ ...params, ...data });
  }, [startDate, endDate, assets, debug, componentErrors]);

  const rangeValue = React.useMemo(() => {
    return [startDate, endDate] as string[];
  }, [startDate, endDate]);

  return (
    <mui.core.Box mt={4}>
      <mui.core.Box mb={2}>
        <mui.core.FormControlLabel
          control={
            <mui.core.Switch
              size="small"
              checked={debug}
              onChange={() => {
                setDebug(!debug);
              }}
            />
          }
          label="Debug"
        />
      </mui.core.Box>

      <mui.core.Grid container spacing={2}>
        <mui.core.Grid item xs={12}>
          <ui.AssetSelector
            value={assets}
            setValue={(val) => {
              setassets(val as string[]);
            }}
            multiple
          />
        </mui.core.Grid>

        <mui.core.Grid item xs={12}>
          <ui.SmartDateRangeSelector
            startText="Start Date"
            endText="End Date"
            value={rangeValue}
            onChange={(v) => {
              setStartDate(v[0]);
              setEndDate(v[1]);
            }}
            minDate={contextStartDate || config.features.start_date}
            maxDate={contextEndDate || config.features.end_date}
            universeId={universeId}
            setExternalError={(e) => setComponentErrors(e ? [e] : [])}
          />
        </mui.core.Grid>
      </mui.core.Grid>
    </mui.core.Box>
  );
};

Form.defaultProps = {
  contextStartDate: null,
  contextEndDate: null,
};

export default Form;
