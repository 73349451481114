import { ts } from '_core';

import { validate } from './helpers';
import { INTERACTION_PARAMS } from './types';

export default [
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.BACKTEST_PREVIEW_DATE_COMPARISON,
    form: true,
    helpLabel: 'bs2kvugc',
    title: 'Date Comparison',
    neededFiles: ['backtest_date_comparison'],
    clearInteractionOnRegenerate: ['table_dtypes'],
    interactionParams: Object.values(INTERACTION_PARAMS),
    tags: [
      ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.BACKTEST_DETAILS,
      ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.BASKET_DETAILS,
    ],

    validate,
  },
] satisfies ts.types.analysis.WidgetConfig[];
