import { React, ts } from '_core';

import Chart from './chart';

type OptimizedAlphaTrueVsPredictedProps = {
  id: string;
  readOnly: boolean;
  widgetKey: string;
  title: string;
  fullScreen?: boolean;
  loadWidgetData: (_payload: ts.types.widgets.WidgetGetDataParams) => Promise<ts.types.widgets.WidgetGetDataResponse>;
  context?: ts.types.signal.SignalAnalysisContext;
  widgetPaths: Record<'optimized_true_vs_predicted', string>;
};

const OptimizedAlphaTrueVsPredicted: React.FC<OptimizedAlphaTrueVsPredictedProps> = ({
  id,
  readOnly,
  widgetKey,
  loadWidgetData,
  fullScreen,
  widgetPaths,
  context,
  title,
}) => {
  return (
    <Chart
      id={id}
      readOnly={readOnly}
      widgetKey={widgetKey}
      fullScreen={fullScreen}
      loadWidgetData={loadWidgetData}
      file={widgetPaths['optimized_true_vs_predicted']}
      definition={
        (context.definition as ts.types.signal.AlphaDefinition)?.json_definition as ts.types.signal.OptimizedModel
      }
      widgetTitle={title}
    />
  );
};

export default OptimizedAlphaTrueVsPredicted;
