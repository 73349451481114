import { _, am4charts, am4core, helpers, ts } from '_core';

import { createImageHeader } from './create-image-header';

type CreateScatterChartProps = {
  id: string;
  data: ts.types.widgets.TableData;
  fullScreen: boolean;
  yAxis: string;
  xAxis: string;
  exportTitle: string;

  exportSubTitle?: string;
  percent?: boolean;
  yAxisLabel?: string;
  xAxisLabel?: string;
  xIsDate?: boolean;
  tooltip?: string;
  title?: string;
  yAxisFormatter?: string;
  xAxisFormatter?: string;
  lineSeriesName?: string;
  trendline?: string;
  prepareData?: (_d: ts.types.widgets.ScatterData) => ts.types.widgets.ScatterData;
  setExternalEmbed?: (_json: ts.types.analysis.EmbedChartData) => void;
  // If data is already sent as linear data
  widgetIsEmbedded?: {
    download: boolean;
  };
};

const createScatterChart = (args: CreateScatterChartProps) => {
  const {
    id,
    data,
    fullScreen,
    exportTitle,

    exportSubTitle = null,
    percent = false,
    yAxisLabel = null,
    xAxisLabel = null,
    yAxis = 'value',
    xAxis = 'date',
    xIsDate = false,
    tooltip = '',
    title = null,
    xAxisFormatter = null,
    yAxisFormatter = null,
    lineSeriesName = null,
    trendline = null,
    prepareData = undefined,
    setExternalEmbed = null,
    widgetIsEmbedded = undefined,
  } = args;

  let scatterChartData = data as any as ts.types.widgets.ScatterData;

  if (!widgetIsEmbedded) {
    if (prepareData) scatterChartData = prepareData(scatterChartData);
  }

  const chart = am4core.create(`chart-${id}-${fullScreen ? 'fs' : 'sw'}`, am4charts.XYChart);
  chart.data = scatterChartData;

  chart.paddingRight = 20;
  if (setExternalEmbed)
    setExternalEmbed({
      id,
      widgetTitle: exportTitle,
      widgetSubTitle: exportSubTitle,
      chart_type: 'scatter-chart',
      args: helpers.embed.argsToStringifiable({
        ...args,
        data: scatterChartData,
      }),
    });

  if (percent) chart.numberFormatter.numberFormat = '#.##%';

  if (title) {
    const chartTitle = chart.titles.create();
    chartTitle.text = title;
    chartTitle.fontSize = 15;
    chartTitle.marginBottom = 20;
  }

  let valueAxisX = null;
  if (xIsDate) {
    valueAxisX = chart.xAxes.push(new am4charts.DateAxis());
    valueAxisX.renderer.labels.template.adapter.add('text', function (text) {
      return text && text.replace(' ', '\n');
    });
    valueAxisX.renderer.labels.template.textAlign = 'middle';
  } else valueAxisX = chart.xAxes.push(new am4charts.ValueAxis());

  valueAxisX.title.text = xAxisLabel ? xAxisLabel : _.startCase(xAxis);
  if (!fullScreen) valueAxisX.renderer.minGridDistance = 40;

  const valueAxisY = chart.yAxes.push(new am4charts.ValueAxis());
  valueAxisY.title.text = yAxisLabel ? yAxisLabel : _.startCase(yAxis);

  valueAxisY.extraMin = 0.05;
  valueAxisY.extraMax = 0.05;

  if (xAxisFormatter) {
    valueAxisX.numberFormatter = new am4core.NumberFormatter();
    valueAxisX.numberFormatter.numberFormat = xAxisFormatter;
  }

  if (yAxisFormatter) {
    valueAxisY.numberFormatter = new am4core.NumberFormatter();
    valueAxisY.numberFormatter.numberFormat = yAxisFormatter;
  }

  // Create series
  const lineSeries = chart.series.push(new am4charts.LineSeries());
  lineSeries.dataFields.valueY = yAxis;

  if (lineSeriesName) lineSeries.name = lineSeriesName;

  if (xIsDate) valueAxisX = lineSeries.dataFields.dateX = xAxis;
  else valueAxisX = lineSeries.dataFields.valueX = xAxis;

  lineSeries.strokeOpacity = 0;
  lineSeries.tooltip.label.wrap = true;
  lineSeries.tooltip.label.maxWidth = 500;

  const bullet = lineSeries.bullets.push(new am4charts.Bullet());

  const circle = bullet.createChild(am4core.Circle);
  circle.horizontalCenter = 'middle';
  circle.verticalCenter = 'middle';
  circle.strokeWidth = 0;
  circle.fill = am4core.color(helpers.getColor(1));
  circle.width = 7;
  circle.height = 7;
  circle.tooltipText = tooltip;

  chart.logo.disabled = true;

  chart.cursor = new am4charts.XYCursor();
  chart.cursor.lineY.disabled = true;
  chart.cursor.lineX.disabled = true;

  chart.exporting.menu = new am4core.ExportMenu();
  chart.exporting.menu.align = 'left';
  chart.fontSize = 11;

  chart.events.on('ready', () => {
    if (trendline) {
      const trend = chart.series.push(new am4charts.LineSeries());
      trend.data = _.cloneDeep(scatterChartData);

      trend.dataFields.valueY = trendline;
      trend.dataFields.valueX = xAxis;
      trend.strokeWidth = 2;
      trend.stroke = chart.colors.getIndex(0);
      trend.strokeOpacity = 0.7;
    }
  });

  chart.exporting.menu.items = [
    {
      label: '...',
      menu: [
        { type: 'jpg', label: 'JPG' },
        { type: 'png', label: 'PNG' },
        { type: 'csv', label: 'CSV' },
      ],
    },
  ];
  chart.exporting.filePrefix = 'chart-data';

  document.getElementById(`legend-${id}-${fullScreen ? 'fs' : 'sw'}`).innerHTML = '';

  createImageHeader({ chart, title: exportTitle, fullScreen, id });

  return chart;
};

export default createScatterChart;
