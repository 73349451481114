import { mui, React, ts, ui } from '_core';

type PipelineFieldProps = {
  dataset: ts.types.signal.DatasetDraft;
  setDataset: (_d: ts.types.signal.DatasetDraft) => void;
  isPreference?: boolean;
};

const PipelineField: React.FC<PipelineFieldProps> = ({ dataset, setDataset, isPreference }) => {
  return (
    <mui.core.Grid container spacing={2} style={{ flexWrap: 'nowrap' }} alignItems="center">
      <mui.core.Grid item xs>
        <ui.ResourceAutocomplete<ts.types.pipeline.PipelineExpanded>
          enableNone
          value={dataset.pipeline_id}
          setValue={(id) =>
            setDataset({
              ...dataset,
              pipeline_id: id,
              id: isPreference ? dataset?.id : null,
            })
          }
          type={ts.enums.RESOURCES_TYPES_ENUM.PIPELINE}
          inputProps={{
            fullWidth: true,
            label: 'Pipeline',
            variant: 'outlined',
            size: 'small',
          }}
        />
      </mui.core.Grid>
    </mui.core.Grid>
  );
};

export default PipelineField;
