import { _, helpers, mui, ts } from '_core';

import { compare } from '../../../utils/helpers';

/**
 * Sorts an array of data rows based on specified sort columns
 * @param rows - Array of data objects to be sorted
 * @param sortColumns - Sort configuration specifying fields and sort direction
 * @returns Sorted array of data objects
 */
export const sortData = (
  rows: Record<string, string | number | boolean>[],
  sortColumns: mui.dataGrid.GridSortModel
) => {
  if (sortColumns.length === 0) return rows;

  const sortedRows = [...rows];
  sortedRows.sort((a, b) => {
    for (const sort of sortColumns) {
      const compResult = compare(a, b, sort.field);
      if (compResult !== 0) {
        return sort.sort === 'asc' ? compResult : -compResult;
      }
    }
    return 0;
  });
  return sortedRows;
};

/**
 * Filters data rows based on filter criteria
 * @param data - Array of data objects to be filtered
 * @param filters - Object containing filter criteria where key is column name and value is filter string
 * @returns Filtered array of data objects matching all filter criteria
 */
export const filterData = (data: Record<string, string | number | boolean>[], filters: Record<string, string>) => {
  return data.filter((r) => {
    let partOfSet = true;
    for (const key in filters)
      if (filters[key]) {
        if (typeof r[key] == 'string')
          if (!_.lowerCase((r[key] as string) || '').includes(_.lowerCase(filters[key]))) partOfSet = false;
      }
    return partOfSet;
  });
};

/**
 * Determines column types for table columns, either using inferred types from data or specified filters
 * @param data - Array of data objects to analyze
 * @param tableColumns - Column configuration specifying how to determine column types
 * @returns Object mapping column keys to their determined types
 */
export const getColumnsType = (
  data: Record<string, string | number | boolean>[],
  tableColumns: ts.types.components.dataGrid.ColumnsData
) => {
  const columnsDTypes = {} as Record<string, string>;

  tableColumns.forEach((el) => {
    if (el.filter == 'inferred') {
      const columnType = helpers.columnUtils.convertColumnType(_.inferArrayType(_.map(data, el.key)));
      columnsDTypes[el.key] = columnType;
    } else {
      columnsDTypes[el.key] = el.filter;
    }
  });

  return columnsDTypes;
};
