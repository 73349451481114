import { React, ts } from '_core';

import Chart from './chart';
import Form from './form';
import { DraftParams, Params } from './types';

type SignalBucketedReturnProfilekProps = {
  id: string;
  readOnly: boolean;
  widgetKey: string;
  mode: ts.enums.CHART_MODE_ENUM;
  params: Params | DraftParams;
  setParams: (_p: DraftParams) => void;
  title: string;

  fullScreen?: boolean;
  context?: ts.types.signal.SignalAnalysisContext;
  loadWidgetData: (_payload: ts.types.widgets.WidgetGetDataParams) => Promise<ts.types.widgets.WidgetGetDataResponse>;
  widgetPaths?: Record<'signal_return_profile', string>;
};

const SignalBucketedReturnProfile: React.FC<SignalBucketedReturnProfilekProps> = ({
  id,
  readOnly,
  widgetKey,
  loadWidgetData,
  widgetPaths,
  mode,
  params,
  setParams,
  fullScreen,
  title,
}) => {
  const fileEvent = 'signal_return_profile';

  const renderJobComponent = () => (
    <>
      <Chart
        id={id}
        readOnly={readOnly}
        widgetKey={widgetKey}
        loadWidgetData={loadWidgetData}
        fileEvent={widgetPaths[fileEvent]}
        params={params as Params}
        fullScreen={fullScreen}
        title={title}
      />
    </>
  );

  return <>{mode == 'edit' ? <Form params={params} setParams={setParams} /> : renderJobComponent()}</>;
};

export default SignalBucketedReturnProfile;
