import { _, helpers, React, ts, ui } from '_core';

import { Params } from './types';

type ChartProps = {
  id: string;
  readOnly: boolean;
  loadWidgetData: (_payload: ts.types.widgets.WidgetGetDataParams) => Promise<ts.types.widgets.WidgetGetDataResponse>;
  file: string;
  fullScreen: boolean;
  params: Params;
  title: string;
  subtitle?: string;
  setExternalEmbed: (_json: ts.types.analysis.EmbedChartData) => void;
};

const Chart: React.FC<ChartProps> = ({
  id,
  readOnly,
  loadWidgetData,
  file,
  params,
  fullScreen,
  title,
  subtitle,
  setExternalEmbed,
}) => {
  const [data, setData] = React.useState<ts.types.widgets.TableData>(null);
  const [alert, setAlert] = React.useState<{ type: 'error' | 'warning'; message: string } | null>(null);

  const loadData = async () => {
    try {
      const response = await loadWidgetData({
        data: {
          file,
        },
      });
      if (response) {
        const renamedData = response.data.map((item) => {
          return {
            ..._.omit(item, ['histogram', 'history']),
            category: Number(item.histogram).toFixed(4),
          };
        });

        setData(renamedData);
      }
    } catch (err) {
      setAlert({
        type: 'error',
        message: helpers.parseApiError(err as ts.types.common.ApiError),
      });
    }
  };

  React.useEffect(() => {
    setData(null);
    setExternalEmbed(null);

    if (file) loadData();
    return () => setData(null);
  }, [file]);

  if (_.isNil(data)) {
    return <ui.CenteredLoader label="Loading data..." />;
  }

  return (
    <ui.NestedBarChart
      id={id}
      data={data}
      fullScreen={fullScreen}
      readOnly={readOnly}
      alert={alert}
      hideLegend
      category="category"
      yAxisLabel={params.percent ? 'Count (%)' : 'Count'}
      tooltipText={
        params.percent
          ? // eslint-disable-next-line max-len
            'Count: {value.formatNumber("##.##")}% \n Min: {min.formatNumber("#.####")} \n Max: {max.formatNumber("#.####")}'
          : 'Count: {value.formatNumber("#")} \n Min: {min.formatNumber("#.####")} \n Max: {max.formatNumber("#.####")}'
      }
      rotateXLabels
      hideSeries={['min', 'max', 'histogram_type']}
      exportTitle={title}
      exportSubTitle={subtitle}
      setExternalEmbed={setExternalEmbed}
    />
  );
};

export default Chart;
