import React from 'react';

import * as enums from '../../enums';
import { _, mui } from '../../libs';
import CheckboxItem from '../checkbox-item';


type HistoriesValueType<TMultiple> = TMultiple extends false | undefined
  ? enums.HISTORY_ENUM_EXTENDED
  : enums.HISTORY_ENUM_EXTENDED[];

interface IWidgetHistoriesProps<TMultiple> {
  value: HistoriesValueType<TMultiple>;
  setValue: (_value: HistoriesValueType<TMultiple>) => void;
  multiple?: boolean;
  context?: Record<string, any>;
  label?: string;
}

/**
 * Component that allows users to select one or multiple history options for data analysis
 * 
 * @param value - Current history value(s) 
 * @param setValue - Callback to update history value(s)
 * @param multiple - Optional flag to allow multiple history selections
 * @param context - Optional context object containing frequency and available histories
 * @param label - Optional custom label for the input field
 * 
 * @returns Rendered component for selecting histories
 */
const WidgetHistories = <TMultiple extends boolean | undefined = undefined>({
  value,
  setValue,
  context,
  label,
  multiple = false,
}: IWidgetHistoriesProps<TMultiple>): React.ReactElement => {
  const options = React.useMemo(() => {
    // Add a context default state to handle widgets created
    //outside a resource
    const localContext = _.isEmpty(context)
      ? {
          definition: {},
        }
      : context;

    const isDailyFrequency =
      localContext.frequency == enums.FREQUENCY_ENUM.DAILY ||
      localContext.definition.frequency == enums.FREQUENCY_ENUM.DAILY;

    let contextHistories = (localContext.context_histories as enums.HISTORY_ENUM_EXTENDED[]) ?? [];

    if (_.isEmpty(contextHistories)) {
      const histories = Object.values(enums.HISTORY_ENUM_EXTENDED);
      if (!isDailyFrequency) return histories.filter((h) => h != enums.HISTORY_ENUM_EXTENDED.FIVE_TRADING_DAYS);
      return histories;
    }

    if (!isDailyFrequency)
      contextHistories = contextHistories.filter((h) => h != enums.HISTORY_ENUM_EXTENDED.FIVE_TRADING_DAYS);

    if (contextHistories && !value) {
      return contextHistories;
    }

    // Now here comes the tricky part, if we already added a value that is not on the context_histories
    // we need to make it valid until it is changed
    return _.uniq([...contextHistories, ...(Array.isArray(value) ? value : [value])]);
  }, [value, context]);

  const localLabel = label || (multiple ? 'Histories' : 'History');

  if (!multiple)
    return (
      <mui.core.Autocomplete
        fullWidth
        options={options}
        disableCloseOnSelect
        value={value as any}
        size="small"
        onChange={(_e, val) => {
          setValue(val as any);
        }}
        renderInput={(p) => <mui.core.TextField {...p} variant="outlined" label={localLabel} size="small" required />}
      />
    );

  // multiple
  return (
    <mui.core.Autocomplete
      multiple
      fullWidth
      options={options}
      disableCloseOnSelect
      value={value as any}
      size="small"
      onChange={(_e, val) => {
        setValue(val as any);
      }}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <CheckboxItem selected={selected} name={option} />
        </li>
      )}
      renderInput={(p) => <mui.core.TextField {...p} variant="outlined" label={localLabel} size="small" required />}
    />
  );
};

export default WidgetHistories;
