import { custom, helpers, ts } from '_core';

const dollar = helpers.tableFormatters.formatTo('float', {
  align: 'right',
  roundDigits: 2,
  commasOnThousands: true,
  numericPrefix: '$',
});
const integer = helpers.tableFormatters.formatTo('integer', { align: 'right', commasOnThousands: true });
const percent = helpers
  .tableFormatters.formatTo('float', { align: 'right', roundDigits: 4, multiplier: 100, suffix: '%' });
const stringf = helpers.tableFormatters.formatTo('string', { align: 'left' });

export default {
  [ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.MAIN]: [
    {
      key: 'name',
      name: 'Name',
      formatter: stringf,
      filter: 'string',
    },
    {
      key: 'strategy_name',
      name: 'Strategy',
      formatter: stringf,
      filter: 'string',
    },
    {
      key: 'portfolio_name',
      name: 'Portfolio',
      formatter: stringf,
      filter: 'string',
    },
    {
      key: 'asset',
      name: 'Asset',
      formatter: stringf,
      filter: 'string',
    },
    {
      key: 'asset_type',
      name: 'Type',
      formatter: stringf,
      filter: 'string',
    },
    {
      key: 'ticker',
      name: 'Ticker',
      formatter: stringf,
      filter: 'string',
    },
    {
      key: 'cusip',
      name: 'Cusip',
      formatter: stringf,
      filter: 'string',
      condition: (features: ts.types.organization.OrganizationFeatures) => !features.hide_cusip_col,
    },
    {
      key: 'isin',
      name: 'ISIN',
      formatter: stringf,
      filter: 'string',
      condition: (features: ts.types.organization.OrganizationFeatures) => !features.hide_isin_col,
    },
    {
      key: 'currency',
      name: 'Currency',
      formatter: helpers.tableFormatters.formatTo('string', { align: 'center' }),
      filter: 'string',
    },
    {
      key: 'issuer_country',
      name: 'Issuer Country',
      formatter: helpers.tableFormatters.formatTo('string', { align: 'center' }),
      filter: 'string',
    },
    {
      key: 'quotation_country',
      name: 'Quotation Country',
      formatter: helpers.tableFormatters.formatTo('string', { align: 'center' }),
      filter: 'string',
    },
    {
      key: 't_quotation_country',
      name: 'Triton Quotation Country',
      formatter: helpers.tableFormatters.formatTo('string', { align: 'center' }),
      filter: 'string',
    },
    {
      key: 'price_in_usd',
      name: 'Price in USD',
      formatter: dollar,
      filter: 'number',
    },
    {
      key: 'price',
      name: 'Local Price',
      formatter: helpers.tableFormatters.formatTo('float', { align: 'right', roundDigits: 2, commasOnThousands: true }),
      filter: 'number',
    },
    {
      key: 'traded_value_usd',
      name: 'Trade Value',
      formatter: dollar,
      filter: 'number',
    },
    {
      key: 'traded_value_local',
      name: 'Trade Value (Local)',
      formatter: helpers.tableFormatters.formatTo('float', { align: 'right', roundDigits: 2, commasOnThousands: true }),
      filter: 'number',
    },
    {
      key: 'split_factor',
      name: 'Split Factor',
      formatter: helpers.tableFormatters.formatTo('float', { align: 'right', roundDigits: 4, commasOnThousands: true }),
      filter: 'number',
    },
    {
      key: 'forecast_cost',
      name: 'Forecast Transaction Cost',
      formatter: percent,
      filter: 'number',
    },
    {
      key: 'shares',
      name: 'Shares',
      formatter: integer,
      filter: 'number',
    },
    {
      key: 'side',
      name: 'Side',
      formatter: stringf,
      filter: 'string',
    },
    {
      key: 't_side',
      name: 'Triton Side',
      formatter: stringf,
      filter: 'string',
    },
    {
      key: 'optimizer_warnings',
      name: 'Optimizer Warnings',
      formatter: helpers.tableFormatters.formatTo('string', { align: 'left' }),
      filter: 'string',
    },
    {
      key: 'holiday',
      name: 'Holiday',
      formatter: helpers.tableFormatters.formatTo('integer', { align: 'right' }),
      filter: 'number',
    },
    {
      key: 'broker',
      name: 'Broker',
      formatter: stringf,
      filter: 'string',
    },
    {
      key: 'split_adj_price_in_usd',
      name: 'Split Adj Price',
      formatter: dollar,
      filter: 'number',
    },
    {
      key: 'days_adv',
      name: 'Days ADV',
      formatter: percent,
      filter: 'number',
    },
    {
      key: 'initial_portfolio_shares',
      name: 'Initial Shares',
      formatter: integer,
      filter: 'number',
    },
    {
      key: 'optimal_portfolio_shares',
      name: 'Optimal Shares',
      formatter: integer,
      filter: 'number',
    },
    {
      key: 'split_adj_shares',
      name: 'Split Adj Shares',
      formatter: integer,
      filter: 'number',
    },
    {
      key: 'previous_value_usd',
      name: 'Previous Value',
      formatter: dollar,
      filter: 'number',
    },
    {
      key: 'optimal_value_usd',
      name: 'Optimal Value',
      formatter: dollar,
      filter: 'number',
    },
    {
      key: 'previous_weight',
      name: 'Previous Weight',
      formatter: percent,
      filter: 'number',
    },
    {
      key: 'optimal_weight',
      name: 'Optimal Weight',
      formatter: percent,
      filter: 'number',
    },
    {
      key: 'trade_weight',
      name: 'Trade Weight',
      formatter: percent,
      filter: 'number',
    },
    {
      key: 'total_available_weight',
      name: 'Total Available Weight',
      formatter: percent,
      filter: 'number',
    },
    {
      key: 'constraint_lower_bound',
      name: 'Lower Bound',
      formatter: percent,
      filter: 'number',
    },
    {
      key: 'constraint_lower_bound_source',
      name: 'Lower Bound Source',
      formatter: stringf,
      filter: 'string',
    },
    {
      key: 'constraint_upper_bound',
      name: 'Upper Bound',
      formatter: percent,
      filter: 'number',
    },
    {
      key: 'constraint_upper_bound_source',
      name: 'Upper Bound Source',
      formatter: stringf,
      filter: 'string',
    },
    {
      key: 'prior_day_returns',
      name: 'Prior Day Return',
      formatter: percent,
      filter: 'number',
    },
    {
      key: 'shares_outstanding',
      name: 'Shares Outstanding',
      formatter: integer,
      filter: 'number',
    },
    {
      key: 'percent_held',
      name: 'Percent Held',
      formatter: percent,
      filter: 'number',
    },
    {
      key: '_rm_industry',
      name: 'Risk Model Industry',
      formatter: stringf,
      filter: 'string',
    },
    {
      key: '_rm_currency',
      name: 'Risk Model Currency',
      formatter: helpers.tableFormatters.formatTo('string', { align: 'center' }),
      filter: 'string',
    },
    {
      key: 'rbics_l1_name',
      name: 'Sector',
      formatter: helpers.tableFormatters.formatTo('string', { align: 'left' }),
      filter: 'string',
    },
    {
      key: 'rbics_l2_name',
      name: 'Sub-Sector',
      formatter: helpers.tableFormatters.formatTo('string', { align: 'left' }),
      filter: 'string',
    },
    {
      key: 'rbics_l3_name',
      name: 'Industry',
      formatter: helpers.tableFormatters.formatTo('string', { align: 'left' }),
      filter: 'string',
    },
    {
      key: 'rbics_l4_name',
      name: 'Sub-Industry',
      formatter: helpers.tableFormatters.formatTo('string', { align: 'left' }),
      filter: 'string',
    },
    {
      key: '_rm_deciles_', // Dynamic
      name: 'Risk Model Factor Deciles',
      formatter: integer,
      filter: 'inferred',
      members: (key) => key.startsWith('_rm_deciles_'),
      cleanNameFormatter: (key) => `Decile for: ${key.split('_rm_deciles_')[1]}`,
      nameFormatter: (key) => <custom.MultilineTableHeader name={['Decile for:', key.split('_rm_deciles_')[1]]} />,
    },
    {
      key: '_rm_exposures_', // Dynamic
      name: 'Risk Model Factor Exposures',
      formatter: helpers.tableFormatters.formatTo('float', { align: 'right', roundDigits: 4, commasOnThousands: true }),
      filter: 'inferred',
      members: (key) => key.startsWith('_rm_exposures_'),
      cleanNameFormatter: (key) => `Exposure to: ${key.split('_rm_exposures_')[1]}`,
      nameFormatter: (key) => <custom.MultilineTableHeader name={['Exposure to:', key.split('_rm_exposures_')[1]]} />,
    },
    {
      key: 'alpha_exposure',
      name: 'Alpha Exposure',
      formatter: helpers.tableFormatters.formatTo('float', { align: 'right', roundDigits: 4, commasOnThousands: true }),
      filter: 'number',
    },
    {
      key: '_alpha_exposures_', // Dynamic
      name: 'Alpha Factor Exposures',
      formatter: helpers.tableFormatters.formatTo('float', { align: 'right', roundDigits: 4, commasOnThousands: true }),
      filter: 'inferred',
      members: (key) => key.startsWith('_alpha_exposures_'),
      cleanNameFormatter: (key) => `Exposure to: ${key.split('_alpha_exposures_')[1]}`,
      nameFormatter: (key) => (
        <custom.MultilineTableHeader name={['Exposure to:', key.split('_alpha_exposures_')[1]]} />
      ),
    },
    {
      key: '_user_exposures_', // Dynamic
      name: 'User Factor Exposures',
      formatter: helpers.tableFormatters.formatTo('float', { align: 'right', roundDigits: 4, commasOnThousands: true }),
      filter: 'inferred',
      members: (key) => key.startsWith('_user_exposures_'),
      cleanNameFormatter: (key) => `Exposure to: ${key.split('_user_exposures_')[1]}`,
      nameFormatter: (key) => <custom.MultilineTableHeader name={['Exposure to:', key.split('_user_exposures_')[1]]} />,
    },
  ] as ts.types.components.dataGrid.ColumnsData,
};
