import React from 'react';

import { mui } from '../libs';

const DialogTitleComp = mui.styles.styled(mui.core.DialogTitle)(({ theme }) => ({
  padding: theme.spacing(4),
  paddingBottom: '0',
  marginBottom: theme.spacing(4),
  position: 'relative',
  fontSize: '1rem',
}));

type DialogTitleFinProps = {
  children: React.ReactNode;
  closeAction?: (_e: React.UIEvent<HTMLElement>) => void;
  description?: string;
  id?: string;
};

/**
 * DialogTitleFin component displays a customizable dialog title with optional description and close action.
 *
 * @param children - The content to be displayed in the dialog title.
 * @param closeAction - Optional function to handle close action when the close button is clicked.
 * @param description - Optional description text to display below the title.
 * @param id - Optional ID for the dialog title component.
 *
 * @returns The rendered component.
 */

const DialogTitleFin: React.FC<DialogTitleFinProps> = (props): React.ReactElement => {
  const { children, closeAction, description = null, id } = props;

  return (
    <DialogTitleComp id={id} sx={{ paddingRight: '3rem' }}>
      {children}
      {description && (
        <mui.core.Typography
          variant="caption"
          color="textSecondary"
          sx={{
            flex: 1,
            display: 'inline-block',
            marginLeft: '0.5rem',
          }}
        >
          {description}
        </mui.core.Typography>
      )}
      {closeAction && (
        <mui.core.IconButton
          size="small"
          onClick={closeAction}
          sx={{
            position: 'absolute',
            right: '1rem',
            top: '0.75rem',
          }}
        >
          <mui.icons.Close />
        </mui.core.IconButton>
      )}
    </DialogTitleComp>
  );
};

export default DialogTitleFin;
