import { Country } from '../types/country';

const countries: Country[] = [
  {
    code: 'US',
    name: 'United States of America',
    continent: 'North America',
    marketCap: 40297.98,
  },
  {
    code: 'CA',
    name: 'Canada',
    continent: 'North America',
    marketCap: 2744.72,
  },
  {
    code: 'MX',
    name: 'Mexico',
    continent: 'North America',
    marketCap: 454.37,
  },
  {
    code: 'GB',
    name: 'United Kingdom',
    continent: 'Europe',
    marketCap: 3095.98,
  },
  {
    code: 'FR',
    name: 'France',
    continent: 'Europe',
    marketCap: 2365.95,
  },
  {
    code: 'DE',
    name: 'Germany',
    continent: 'Europe',
    marketCap: 1889.66,
  },
  {
    code: 'CH',
    name: 'Switzerland',
    continent: 'Europe',
    marketCap: 1830.52,
  },
  {
    code: 'ES',
    name: 'Spain',
    continent: 'Europe',
    marketCap: 665.5,
  },
  {
    code: 'NL',
    name: 'Netherlands',
    continent: 'Europe',
    marketCap: 1100.11,
  },
  {
    code: 'IT',
    name: 'Italy',
    continent: 'Europe',
    marketCap: 587.31,
  },
  {
    code: 'BE',
    name: 'Belgium',
    continent: 'Europe',
    marketCap: 321.09,
  },
  {
    code: 'NO',
    name: 'Norway',
    continent: 'Europe',
    marketCap: 295.55,
  },
  {
    code: 'SE',
    name: 'Sweden',
    continent: 'Europe',
    marketCap: 289.88,
  },
  {
    code: 'DK',
    name: 'Denmark',
    continent: 'Europe',
    marketCap: 151.35,
  },
  {
    code: 'IE',
    name: 'Ireland',
    continent: 'Europe',
    marketCap: 110.15,
  },
  {
    code: 'PT',
    name: 'Portugal',
    continent: 'Europe',
    marketCap: 61.93,
  },
  {
    code: 'FI',
    name: 'Finland',
    continent: 'Europe',
    marketCap: 20.34,
  },
  {
    code: 'SK',
    name: 'Slovak Republic',
    continent: 'Europe',
    marketCap: 5.38,
  },
  {
    code: 'UA',
    name: 'Ukraine',
    continent: 'Europe',
    marketCap: 4.42,
  },
  {
    code: 'RS',
    name: 'Serbia',
    continent: 'Europe',
    marketCap: 4.06,
  },
  {
    code: 'RU',
    name: 'Russian Federation',
    continent: 'Europe',
    marketCap: 530.1,
  },
  {
    code: 'TR',
    name: 'Turkey',
    continent: 'Europe',
    marketCap: 330.01,
  },
  {
    code: 'PL',
    name: 'Poland',
    continent: 'Europe',
    marketCap: 147.66,
  },
  {
    code: 'AT',
    name: 'Austria',
    continent: 'Europe',
    marketCap: 123.05,
  },
  {
    code: 'GR',
    name: 'Greece',
    continent: 'Europe',
    marketCap: 59.04,
  },
  {
    code: 'LU',
    name: 'Luxembourg',
    continent: 'Europe',
    marketCap: 50.9,
  },
  {
    code: 'KZ',
    name: 'Kazakhstan',
    continent: 'Europe',
    marketCap: 45.77,
  },
  {
    code: 'RO',
    name: 'Romania',
    continent: 'Europe',
    marketCap: 28.98,
  },
  {
    code: 'CZ',
    name: 'Czech Republic',
    continent: 'Europe',
    marketCap: 28.81,
  },
  {
    code: 'HU',
    name: 'Hungary',
    continent: 'Europe',
    marketCap: 23.87,
  },
  {
    code: 'CN',
    name: 'China',
    continent: 'Asia',
    marketCap: 11469.35,
  },
  {
    code: 'JP',
    name: 'Japan',
    continent: 'Asia',
    marketCap: 5380.48,
  },
  {
    code: 'HK',
    name: 'Hong Kong, SAR China',
    continent: 'Asia',
    marketCap: 4566.81,
  },
  {
    code: 'TW',
    name: 'Taiwan',
    continent: 'Asia',
    marketCap: 2261.86,
  },
  {
    code: 'IN',
    name: 'India',
    continent: 'Asia',
    marketCap: 3603.48,
  },
  {
    code: 'KR',
    name: 'Korea (South)',
    continent: 'Asia',
    marketCap: 1644.51,
  },
  {
    code: 'IR',
    name: 'Iran, Islamic Republic of',
    continent: 'Asia',
    marketCap: 1613.04,
  },
  {
    code: 'SG',
    name: 'Singapore',
    continent: 'Asia',
    marketCap: 619.36,
  },
  {
    name: 'Indonesia',
    code: 'ID',
    continent: 'Asia',
    marketCap: 610.29,
  },
  {
    code: 'TH',
    name: 'Thailand',
    continent: 'Asia',
    marketCap: 604.36,
  },
  {
    code: 'MY',
    name: 'Malaysia',
    continent: 'Asia',
    marketCap: 381.21,
  },
  {
    code: 'PH',
    name: 'Philippines',
    continent: 'Asia',
    marketCap: 238.58,
  },
  {
    code: 'VN',
    name: 'Viet Nam',
    continent: 'Asia',
    marketCap: 170.15,
  },
  {
    code: 'BD',
    name: 'Bangladesh',
    continent: 'Asia',
    marketCap: 42.89,
  },
  {
    code: 'PK',
    name: 'Pakistan',
    continent: 'Asia',
    marketCap: 28.71,
  },
  {
    code: 'UZ',
    name: 'Uzbekistan',
    continent: 'Asia',
    marketCap: 5.19,
  },
  {
    code: 'BR',
    name: 'Brazil',
    continent: 'Central and South America',
    marketCap: 794.42,
  },
  {
    code: 'CL',
    name: 'Chile',
    continent: 'Central and South America',
    marketCap: 285.23,
  },
  {
    code: 'PE',
    name: 'Peru',
    continent: 'Central and South America',
    marketCap: 71.31,
  },
  {
    code: 'CO',
    name: 'Colombia',
    continent: 'Central and South America',
    marketCap: 68.41,
  },
  {
    code: 'AR',
    name: 'Argentina',
    continent: 'Central and South America',
    marketCap: 52.95,
  },
  {
    code: 'VE',
    name: 'Venezuela',
    continent: 'Central and South America',
    marketCap: 3.98,
  },
  {
    code: 'SA',
    name: 'Saudi Arabia',
    continent: 'Middle East and Africa',
    marketCap: 2638.59,
  },
  {
    code: 'ZA',
    name: 'South Africa',
    continent: 'Middle East and Africa',
    marketCap: 1171.75,
  },
  {
    code: 'AE',
    name: 'United Arab Emirates',
    continent: 'Middle East and Africa',
    marketCap: 873.56,
  },
  {
    code: 'QA',
    name: 'Qatar',
    continent: 'Middle East and Africa',
    marketCap: 166.78,
  },
  {
    code: 'KW',
    name: 'Kuwait',
    continent: 'Middle East and Africa',
    marketCap: 152.68,
  },
  {
    code: 'NG',
    name: 'Nigeria',
    continent: 'Middle East and Africa',
    marketCap: 91.44,
  },
  {
    code: 'MA',
    name: 'Morocco',
    continent: 'Middle East and Africa',
    marketCap: 53.71,
  },
  {
    code: 'EG',
    name: 'Egypt',
    continent: 'Middle East and Africa',
    marketCap: 38.85,
  },
  {
    code: 'BW',
    name: 'Botswana',
    continent: 'Middle East and Africa',
    marketCap: 31.02,
  },
  {
    code: 'BH',
    name: 'Bahrain',
    continent: 'Middle East and Africa',
    marketCap: 30.26,
  },
  {
    code: 'JO',
    name: 'Jordan',
    continent: 'Middle East and Africa',
    marketCap: 25.43,
  },
  {
    code: 'LB',
    name: 'Lebanon',
    continent: 'Middle East and Africa',
    marketCap: 10.58,
  },
  {
    code: 'CI',
    name: "Cote d'Ivoire",
    continent: 'Middle East and Africa',
    marketCap: 7.33,
  },
  {
    code: 'IL',
    name: 'Israel',
    continent: 'Middle East and Africa',
    marketCap: 269.64,
  },
  {
    code: 'AU',
    name: 'Australia',
    continent: 'Oceania',
    marketCap: 1679.17,
  },
  {
    code: 'NZ',
    name: 'New Zealand',
    continent: 'Oceania',
    marketCap: 97.55,
  },
];

export default countries;
