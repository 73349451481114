import { _, mui } from '@local/finsera-core/src/libs';
import * as types from '@local/finsera-core/src/types';
import {
  ColumnsData,
  ColumnsWidth,
  TableParams,
  ValueFilters,
} from '@local/finsera-core/src/types/components/data-grid';

import { columnUtils, datagrid } from '..';

/**
 * Converts filters to a query format
 * @param filters - Array of filter objects
 * @param logicOperator - Logic operator to use in the query
 * @returns Array of query elements
 */
const convertFiltersToQuery = (filters: ValueFilters, logicOperator: mui.dataGrid.GridLogicOperator) => {
  const operatorMap: { [key: string]: string } = {
    is: '=',
    not: '!=',
    after: '>',
    onOrAfter: '>=',
    before: '<',
    onOrBefore: '<=',
  };

  const query: any[] = [`$${logicOperator.toLowerCase()}`];

  filters.forEach((filter) => {
    const { column, operator, value } = filter;
    let convertedOperator = operatorMap[operator] || operator;
    let convertedValue = value;

    if (operator === 'isEmpty') {
      convertedOperator = '=';
      convertedValue = null;
    } else if (operator === 'isNotEmpty') {
      convertedOperator = '!=';
      convertedValue = null;
    }

    query.push([column, convertedOperator, convertedValue]);
  });

  return query;
};

const _getNotCalculatedColumn = (c: ColumnsData[0], columnsWidth: ColumnsWidth, baseRowWidth: number) => {
  const foundCustomWidth = columnsWidth?.find((el) => el.columnKey == c.key)?.columnWidth;
  return foundCustomWidth ?? c.width ?? baseRowWidth;
};

export const getColumnWidth = (
  c: ColumnsData[0],
  dataColumns: ColumnsData,
  columnsWidth: ColumnsWidth,
  baseRowWidth: number,
  fixedWidth: TableParams['fixed_width'],
  toolbarWidth: number,
  // Handle for fixed scrollbar
  exact = false
) => {
  const notCalculated = _getNotCalculatedColumn(c, columnsWidth, baseRowWidth);
  if (fixedWidth?.ignore_index && c.frozen) return notCalculated;

  const indexes = dataColumns.filter((d) => d.frozen);
  const indexesWidth = fixedWidth?.ignore_index
    ? _.sum(indexes.map((i) => _getNotCalculatedColumn(i, columnsWidth, baseRowWidth)))
    : 0;

  const numberOfCols = fixedWidth?.ignore_index ? dataColumns.length - indexes.length : dataColumns.length;

  if (fixedWidth?.fill) return (toolbarWidth - indexesWidth - (exact ? 3 : 30)) / numberOfCols;
  if (fixedWidth?.width) return fixedWidth.width;

  return notCalculated;
};

export { convertFiltersToQuery };

type BuildMappedColumnsOptions = {
  dataColumns: types.components.dataGrid.ColumnsData;
  data: Record<string, string | number | boolean>[];
  columnsWidth: types.components.dataGrid.ColumnsWidth;
  fixedWidth: types.components.dataGrid.TableParams['fixed_width'];
  tableWidth: number;
  isAsync?: boolean;
  indexClass?: string;
  tableDtypes?: Record<string, string>;
  baseRowWidth?: number;
};

/**
 * Transforms raw column definitions into MuiX Data Grid compatible column configurations.
 *
 * @param {ts.types.components.dataGrid.ColumnsData} dataColumns - Raw column definitions
 * @param {Record<string, string | number | boolean>[]} data - Dataset used for column width and type inference
 * @param {ts.types.components.dataGrid.ColumnsWidth} columnsWidth - Column width configurations
 * @param {ts.types.components.dataGrid.TableParams['fixed_width']} fixedWidth - Fixed width settings for columns
 * @param {number} tableWidth - Total width of the table
 * @param {boolean} [isAsync=false] - Flag indicating if the grid operates in async mode
 *
 * @returns {Array} Transformed column configurations compatible with MuiX Data Grid and with all needed properties
 *
 * @description
 * This function performs several transformations on column definitions:
 * - Calculates optimal column widths based on content and headers
 * - Infers or assigns column data types
 * - Configures appropriate filter operators based on data type
 * - Sets up value formatting and cell rendering
 * - Handles special cases for frozen columns and index columns
 * - Configures async-specific behaviors when isAsync is true
 */
const buildMappedColumns = ({
  dataColumns,
  data,
  columnsWidth,
  fixedWidth,
  tableWidth,
  isAsync = false,
  indexClass = '',
  baseRowWidth = 150,
}: BuildMappedColumnsOptions) => {
  const localColumns = dataColumns?.map((c) => {
    if (typeof c.name === 'string') {
      c.width = columnUtils.determineColumnWidth(c, data);
    }

    const columnType = columnUtils.determineColumnType(c, data);

    const filterOperators = columnUtils.getFilterOperatorsForColumnType(columnType, isAsync);

    const newColumn = {
      ...c,
      width: datagrid.getColumnWidth(c, dataColumns, columnsWidth, baseRowWidth, fixedWidth, tableWidth),
      valueGetter: columnUtils.createValueGetter(c),
      renderCell: columnUtils.createRenderCell(c),
      headerName: c.name,
      headerAlign: 'left',
      headerClassName: c.isIndex || c.frozen ? indexClass : undefined,
      field: c.key,
      type: columnType,
      filterOperators,
    };

    return {
      ...newColumn,
    };
  });

  return localColumns;
};

export { buildMappedColumns };
