import { mui, React, ts } from '_core';

import factors from './factors';

type FactorComponentProps = {
  value: ts.types.pipeline.NeutralizeDraft['factors'][0];
  setValue: (_value: ts.types.pipeline.NeutralizeDraft['factors'][0]) => void;
  setType: (_type: ts.enums.N_FACTOR_TYPE_ENUM) => void;
  getFactorsEnabled: () => Record<ts.enums.N_FACTOR_TYPE_ENUM, boolean>;
  remove: () => void;
  readOnly?: boolean;
};

const FactorComponent: React.FC<FactorComponentProps> = ({
  value,
  setValue,
  setType,
  remove,
  getFactorsEnabled,
}): React.ReactElement => {
  const factorsEnabled = React.useMemo(() => getFactorsEnabled(), [value]);

  const factorOptions = {
    [ts.enums.N_FACTOR_TYPE_ENUM.ASSET_MASTER as string]: 'Asset Master',
    [ts.enums.N_FACTOR_TYPE_ENUM.INDUSTRY as string]: 'Industry',
    [ts.enums.N_FACTOR_TYPE_ENUM.SIGNAL as string]: 'Signal',
    [ts.enums.N_FACTOR_TYPE_ENUM.MARKET as string]: 'Market',
    [ts.enums.N_FACTOR_TYPE_ENUM.RISK_MODEL_CATEGORY as string]: 'Risk Model Category',
    [ts.enums.N_FACTOR_TYPE_ENUM.RISK_MODEL_STYLE as string]: 'All Risk Model Style Factors',
  };

  return (
    <mui.core.Grid container spacing={4} style={{ marginBottom: '0.66rem' }} wrap="nowrap">
      <mui.core.Grid item xs={4}>
        <mui.core.TextField
          select
          label="Factor"
          variant="outlined"
          size="small"
          value={value.type || ''}
          onChange={(e) => setType(e.target.value as ts.enums.N_FACTOR_TYPE_ENUM)}
          fullWidth
        >
          {Object.keys(factorOptions).map((key) => (
            <mui.core.MenuItem key={key} value={key} disabled={factorsEnabled[key as ts.enums.N_FACTOR_TYPE_ENUM]}>
              {factorOptions[key]}
            </mui.core.MenuItem>
          ))}
        </mui.core.TextField>
      </mui.core.Grid>
      <mui.core.Grid item xs={8} md>
        {factors[value.type as keyof typeof factors] &&
          React.createElement(factors[value.type as keyof typeof factors], {
            value,
            setValue,
          })}
      </mui.core.Grid>
      <mui.core.Grid item>
        <mui.core.IconButton aria-label="remove" onClick={remove} size="small">
          <mui.icons.Close />
        </mui.core.IconButton>
      </mui.core.Grid>
    </mui.core.Grid>
  );
};

export default FactorComponent;
