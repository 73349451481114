import { React, ts } from '_core';

import Chart from './chart';
import Form from './form';
import { DraftParams, Params } from './types';

type BacktestDetailedViewConstraintsProps = {
  id: string;
  mode: ts.enums.CHART_MODE_ENUM;
  params: Params | DraftParams;
  setParams: (_p: DraftParams) => void;

  loadWidgetData: (_payload: ts.types.widgets.WidgetGetDataParams) => Promise<ts.types.widgets.WidgetGetDataResponse>;
  widgetPaths?: Record<'backtest_preview_constraints', string>;
  goFullScreen?: (_b: string) => void;
  fullScreen?: boolean;

  context: ts.types.backtest.BacktestAnaylsisContext | ts.types.basket.BasketAnalysisContext;
  queryParams?: { initial?: string };
};

const BacktestDetailedViewConstraints: React.FC<BacktestDetailedViewConstraintsProps> = ({
  id,
  loadWidgetData,
  widgetPaths,
  mode,
  context,
  params,
  setParams,
  fullScreen,
  goFullScreen,
  queryParams,
}) => {
  const renderJobComponent = () => (
    <>
      <Chart
        id={id}
        loadWidgetData={loadWidgetData}
        file={widgetPaths['backtest_preview_constraints']}
        fullScreen={fullScreen}
        context={context}
        queryParams={queryParams}
        goFullScreen={goFullScreen}
        params={params as Params}
        setParams={setParams}
      />
    </>
  );

  return (
    <>
      {mode == 'edit' ? (
        <Form
          params={params}
          setParams={setParams}
          startDate={context?.start_date}
          endDate={context?.end_date}
          universeId={context?.definition?.universe_id}
          frequency={context?.definition.frequency as ts.enums.FREQUENCY_ENUM}
        />
      ) : (
        renderJobComponent()
      )}
    </>
  );
};

export default BacktestDetailedViewConstraints;
