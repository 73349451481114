import { hooks, mui, React, ts, ui } from '_core';

import { DraftParams } from './types';

type FormProps = {
  params: DraftParams;
  setParams: (_p: DraftParams) => void;
  context?: ts.types.backtest.BacktestAnaylsisContext | ts.types.basket.BasketAnalysisContext;
};

const Form: React.FC<FormProps> = ({ params, setParams }): React.ReactElement => {
  const uiStyles = hooks.useUiStyles();

  const [nPerformers, setNPerformers] = React.useState(params?.n_performers || 20);

  React.useEffect(() => {
    const data = {
      n_performers: nPerformers,
    };

    setParams({ ...params, ...data });
  }, [nPerformers]);

  return (
    <mui.core.Box style={{ padding: '8px' }} sx={uiStyles.widgetEditMode}>
      <mui.core.Grid container spacing={4} mt={1}>
        <mui.core.Grid item xs={12} md={6}>
          <ui.NumericField
            textFieldProps={{
              label: 'Number of Top and Bottom Performers',
              fullWidth: true,
              variant: 'outlined',
              size: 'small',
            }}
            onlyPositive
            onlyInteger
            value={nPerformers}
            setValue={(v) => setNPerformers(v)}
          />
        </mui.core.Grid>
      </mui.core.Grid>
    </mui.core.Box>
  );
};

export default Form;
