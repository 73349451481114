import { _, ts } from '_core';

import { DraftParams, Params } from './types';

const prepareRegimeData = (data: ts.types.widgets.TableData) => {
  const groupedData = _.groupBy(data, (item) => `${item.type}-${item.history}`);

  // Step 2: Transform the grouped data
  const transformedData = Object.values(groupedData).map((group) => {
    const { type, history } = group[0];
    const buckets = _.reduce(
      group,
      (acc, item) => {
        acc[item.bucket as string] = item.value as number;
        return acc;
      },
      {} as Record<string, number>
    );

    return {
      type,
      history,
      ...buckets,
    };
  });

  return transformedData;
};

export const transform = (data: ts.types.widgets.TableData) => {
  const countData = data.filter((el) => el.type === 'count');
  const performanceData = data.filter((el) => el.type === 'value');

  return [...prepareRegimeData(countData), ...prepareRegimeData(performanceData)];
};

export const validate = (params: DraftParams) => {
  if (_.isNil(params.compare_time_series_id)) return ['Time Series is a required parameter.'];
  if (!params.discrete) {
    if (params.data_type === 'breakpoints') {
      if (_.isEmpty(params.break_points) || _.every(params.break_points, _.isNil))
        return ['Break Points is a required field.'];

      if (_.some(params.break_points, _.isNil)) return ['Invalid Break Points.'];
    } else {
      if (_.isNil(params.quantiles)) return ['Quantiles is a required parameter.'];
      if (!_.isNil(params.quantiles) && (params.quantiles <= 0 || params.quantiles > 20))
        return ['Quantiles must be between 0 and 21.'];
    }
  }
  return [];
};

export const getName = (
  params: Params,
  _context: ts.types.timeSeries.TimeSeriesAnalysisContext,
  resources: ts.StoreState['resources']
) => {
  const ts = resources.time_series.find((lt) => lt.id == params.compare_time_series_id);
  return `Time Series Regime Analysis for ${ts.name}: Average Performance`;
};
