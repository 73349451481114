/* eslint-disable no-unused-vars */
import { ts } from '_core';

export enum INTERACTION_PARAMS {
  SELECTED_TYPE = 'selected_type',
}

export type InteractionParams = {
  [INTERACTION_PARAMS.SELECTED_TYPE]: 'signal' | 'benchmark';
};

export type SignalType = {
  signal_id: number;
  pipeline_id?: number;
  sp_pipeline_id?: number;
}[];

export type JobParams = {
  return_horizon: ts.types.returnHorizon.ReturnHorizon;
  benchmark?: Record<string, ts.enums.BENCHMARK_TYPES_ENUM | string | number>;
  analysis_pipelined_signals?: SignalType;
  set_params: {
    id: 'report'; // Fixed id to get the file from payload on index
    file: 'signal_relative_returns';
    file_params: {
      return_horizon: ts.types.returnHorizon.ReturnHorizon;
      benchmark?: Record<string, ts.enums.BENCHMARK_TYPES_ENUM | string | number>;
      analysis_pipelined_signals?: SignalType;
    };
  }[];
};

export type Params = JobParams & InteractionParams;
export type DraftParams = Partial<Params>;
