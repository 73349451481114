import { ts } from '_core';

import { validate } from './helpers';

export default [
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.RISK_MODEL_COVERAGE,
    form: true,
    title: 'Universe Coverage',
    neededFiles: ['risk_model_coverage'],
    tags: [
      ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.RISK_MODEL,
      ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.COMMON_RISK_MODEL,
      ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.STATISTICAL_RISK_MODEL,
    ],

    helpLabel: 'kcxhs9oo',
    validate,
  },
] satisfies ts.types.analysis.WidgetConfig[];
