import React from 'react';

import HelpContent from './help-content';
import { useUiStyles } from '../hooks';
import { mui } from '../libs';
import * as types from '../types';

type CollapseWithHeaderProps = {
  title: string | React.ReactElement;
  customBG?: string;
  helpContent?: types.helpItem.HelpItemContent;
  rightText?: string;
  color?: 'primary' | 'error' | 'default' | 'transparent';

  padding?: number;

  expanded?: boolean;
  isSidebar?: boolean;

  onChange?: () => void;
  onClose?: () => void;

  children?: React.ReactNode;
  icon?: React.ReactNode;
  rightAction?: React.ReactNode;

  selected?: boolean;
};

/**
 * @param color - Background color variant for the header
 * - primary: Light blue background
 * - error: Red background with danger color text
 * - default: Grey background
 * - transparent: No background color
 */
const CollapseWithHeader: React.FC<CollapseWithHeaderProps> = ({
  expanded,
  onChange,
  title,
  onClose,
  rightText = false,
  color = 'default',
  padding = 2,
  children,
  icon,
  selected,
  isSidebar,
  customBG,
  rightAction,
  helpContent,
}): React.ReactElement => {
  const theme = mui.styles.useTheme() as mui.core.Theme;
  const uiStyles = useUiStyles();

  let summaryStyle = {};
  if (color == 'default') summaryStyle = { backgroundColor: '#fff' };
  if (color == 'primary') summaryStyle = { backgroundColor: '#fff' };
  if (color == 'transparent') summaryStyle = { backgroundColor: '#fff' };
  if (color == 'error')
    summaryStyle = {
      backgroundColor: '#F6DCDE',
      color: (theme.palette as any).danger.main,
    };
  if (selected) summaryStyle = { backgroundColor: '#fff' };

  const titleStyle = (
    isSidebar
      ? {
          color: '#7F8D93',
          fontSize: '0.7rem',
          fontWeight: '600',
        }
      : { fontSize: '12px', fontWeight: '500' }
  ) as React.CSSProperties;

  return (
    <mui.core.Accordion
      square
      expanded={expanded}
      onChange={onChange}
      sx={{
        boxShadow: 'none',
        background: 'transparent',
      }}
    >
      <mui.core.AccordionSummary
        expandIcon={<mui.icons.ExpandMore />}
        sx={{
          height: '30px',
          margin: '2px 4px',
          borderRadius: '4px',
          background: '#fff',
          minHeight: '30px!important',
          '&:before': {
            display: 'none',
          },
          '& .right-action': {
            display: 'none',
          },
          '&:hover .right-action': {
            display: 'block',
          },
        }}
        style={summaryStyle}
      >
        <mui.core.Box display="flex" width="100%" alignItems="center">
          {icon}
          <mui.core.Box flexGrow={1}>
            {helpContent ? (
              <HelpContent helpContent={helpContent}>
                <mui.core.Typography variant="body1" style={titleStyle} sx={uiStyles.editorTitle}>
                  {title}
                </mui.core.Typography>
              </HelpContent>
            ) : (
              <mui.core.Typography variant="body1" style={titleStyle} sx={uiStyles.editorTitle}>
                {title}
              </mui.core.Typography>
            )}
          </mui.core.Box>

          <mui.core.Box display="flex" alignItems="center">
            {/* rightText */}
            {rightText && (
              <mui.core.Box mr={1}>
                <mui.core.Typography style={{ color: mui.colors.grey[700] }}>{rightText}</mui.core.Typography>
              </mui.core.Box>
            )}

            <mui.core.Box className="right-action">{rightAction}</mui.core.Box>

            {/* On close Button */}
            {onClose && (
              <mui.icons.Close
                onClick={(event) => {
                  event.stopPropagation();
                  onClose();
                }}
                style={{ color: mui.colors.grey[700], fontSize: '0.9rem' }}
              />
            )}
          </mui.core.Box>
        </mui.core.Box>
      </mui.core.AccordionSummary>
      <mui.core.Box padding={padding} style={{ background: customBG }} className="accordion-content">
        {children}
      </mui.core.Box>
    </mui.core.Accordion>
  );
};

CollapseWithHeader.defaultProps = {
  rightText: '',
  color: 'default',
  padding: 2,
  icon: null,
  selected: false,
  isSidebar: false,
  customBG: '#fff',
  rightAction: null,
  onChange: () => undefined,
  expanded: false,
  children: null,
  helpContent: null,
};

export default CollapseWithHeader;
