/*
    Method to merge multiple refs together based on react-merge-refs
    https://github.com/gregberge/react-merge-refs
    https://github.com/gregberge/react-merge-refs/blob/main/src/index.tsx
*/

import React from 'react';

/**
 * Merges multiple React refs into a single ref callback
 * @template T The type of the ref value
 * @param refs Array of refs to merge - can be mutable ref objects or legacy ref callbacks
 * @returns A ref callback that updates all provided refs
 * @example
 * const Component = React.forwardRef((props, ref) => {
 *   const localRef = useRef(null);
 *   const mergedRef = mergeRefs([ref, localRef]);
 *   return <div ref={mergedRef} />;
 * });
 */
const mergeRefs = <T = any>(refs: Array<React.MutableRefObject<T> | React.LegacyRef<T>>): React.RefCallback<T> => {
  return (value) => {
    refs.forEach((ref) => {
      if (typeof ref === 'function') {
        ref(value);
      } else if (ref != null) {
        (ref as React.MutableRefObject<T | null>).current = value;
      }
    });
  };
};

export default mergeRefs;
