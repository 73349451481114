import { custom, helpers, ts } from '_core';

const dollar = helpers.tableFormatters.formatTo('float', {
  align: 'right',
  roundDigits: 2,
  commasOnThousands: true,
  numericPrefix: '$',
});
const integer = helpers.tableFormatters.formatTo('integer', { align: 'right', commasOnThousands: true });
const percent = helpers
  .tableFormatters.formatTo('float', { align: 'right', roundDigits: 2, multiplier: 100, suffix: '%' });
const percent_with_decimals = helpers.tableFormatters.formatTo('float', {
  align: 'right',
  roundDigits: 4,
  multiplier: 100,
  suffix: '%',
});
const stringf = helpers.tableFormatters.formatTo('string', { align: 'left' });

export default {
  [ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.MAIN]: [
    {
      key: 'period_start',
      name: 'Period Start',
      formatter: helpers.tableFormatters.formatTo('date', { align: 'right', format: 'YYYY-MM-DD' }),
      filter: 'date',
    },
    {
      key: 'period_end',
      name: 'Period End',
      formatter: helpers.tableFormatters.formatTo('date', { align: 'right', format: 'YYYY-MM-DD' }),
      filter: 'date',
    },
    {
      key: 'period_returns',
      name: 'Period Returns',
      formatter: percent_with_decimals,
      filter: 'number',
    },
    {
      key: 'name',
      name: 'Name',
      formatter: stringf,
      filter: 'string',
    },
    {
      key: 'asset',
      name: 'Asset',
      formatter: stringf,
      filter: 'string',
    },
    {
      key: 'asset_type',
      name: 'Type',
      formatter: stringf,
      filter: 'string',
    },
    {
      key: 'ticker',
      name: 'Ticker',
      formatter: stringf,
      filter: 'string',
    },
    {
      key: 'cusip',
      name: 'Cusip',
      formatter: stringf,
      filter: 'string',
      condition: (features: ts.types.organization.OrganizationFeatures) => !features.hide_cusip_col,
    },
    {
      key: 'isin',
      name: 'ISIN',
      formatter: stringf,
      filter: 'string',
      condition: (features: ts.types.organization.OrganizationFeatures) => !features.hide_isin_col,
    },
    {
      key: 'currency',
      name: 'Currency',
      formatter: helpers.tableFormatters.formatTo('string', { align: 'center' }),
      filter: 'string',
    },
    {
      key: 'issuer_country',
      name: 'Issuer Country',
      formatter: helpers.tableFormatters.formatTo('string', { align: 'center' }),
      filter: 'string',
    },
    {
      key: 'quotation_country',
      name: 'Quotation Country',
      formatter: helpers.tableFormatters.formatTo('string', { align: 'center' }),
      filter: 'string',
    },
    {
      key: 'price_in_usd',
      name: 'Price in USD',
      formatter: dollar,
      filter: 'number',
    },
    {
      key: 'price',
      name: 'Local Price',
      formatter: helpers.tableFormatters.formatTo('float', { align: 'right', roundDigits: 2, commasOnThousands: true }),
      filter: 'number',
    },
    {
      key: 'marketcap_usd',
      name: 'Market Cap  ($M USD)',
      formatter: helpers.tableFormatters.formatTo('float', { align: 'right', roundDigits: 3, commasOnThousands: true }),
      filter: 'number',
    },
    {
      key: 'last_price_date',
      name: 'Price Date',
      formatter: helpers.tableFormatters.formatTo('date', { align: 'right', format: 'YYYY-MM-DD' }),
      filter: 'date',
    },
    {
      key: 'exception',
      name: 'Exception',
      formatter: stringf,
      filter: 'string',
    },
    {
      key: 'exception_details',
      name: 'Exception Details',
      formatter: stringf,
      filter: 'string',
    },
    {
      key: 'total_available_shares',
      name: 'Available Short Shares',
      formatter: helpers.tableFormatters.formatTo('float', { align: 'right', roundDigits: 2, commasOnThousands: true }),
      filter: 'number',
    },
    {
      key: 'available_shares_1_week',
      name: 'Available Short Shares 1 Week',
      formatter: helpers.tableFormatters.formatTo('float', { align: 'right', roundDigits: 2, commasOnThousands: true }),
      filter: 'number',
    },
    {
      key: 'available_shares_diff_1_week',
      name: 'Availability Diff 1 Week',
      formatter: percent,
      filter: 'number',
    },
    {
      key: 'shares_outstanding',
      name: 'Shares Outstanding',
      formatter: integer,
      filter: 'number',
    },
    {
      key: 'percent_held',
      name: 'Percent Held',
      formatter: percent_with_decimals,
      filter: 'number',
    },
    {
      key: 'days_adv',
      name: 'Days ADV',
      formatter: percent,
      filter: 'number',
    },
    {
      key: 'value_usd',
      name: 'Value',
      formatter: dollar,
      filter: 'number',
    },
    {
      key: 'value',
      name: 'Local Value',
      formatter: helpers.tableFormatters.formatTo('float', { align: 'right', roundDigits: 2, commasOnThousands: true }),
      filter: 'number',
    },
    {
      key: 'side',
      name: 'Side',
      formatter: helpers.tableFormatters.formatTo('string', { align: 'left', capitalize: true }),
      filter: 'string',
    },
    {
      key: 'broker',
      name: 'Broker',
      formatter: stringf,
      filter: 'string',
    },
    {
      key: 'benchmark_weight',
      name: 'Benchmark Weight',
      formatter: percent,
      filter: 'number',
    },
    {
      key: 'active_weight',
      name: 'Active Weight',
      formatter: percent,
      filter: 'number',
    },
    {
      key: 'specific_risk',
      name: 'Specific Risk',
      formatter: percent,
      filter: 'string',
    },
    {
      key: 'next_split',
      name: 'Next Split',
      formatter: helpers.tableFormatters.formatTo('date', { align: 'right', format: 'YYYY-MM-DD' }),
      filter: 'date',
    },
    {
      key: 'prior_day_returns',
      name: 'Prior Day Return',
      formatter: percent,
      filter: 'number',
    },
    {
      key: 'prior_week_returns',
      name: 'Prior Week Return',
      formatter: percent,
      filter: 'number',
    },
    {
      key: 'prior_month_returns',
      name: 'Prior Month Return',
      formatter: percent,
      filter: 'number',
    },
    {
      key: '_rm_industry',
      name: 'Risk Model Industry',
      formatter: stringf,
      filter: 'string',
    },
    {
      key: 'rbics_l1_name',
      name: 'Sector',
      formatter: stringf,
      filter: 'string',
    },
    {
      key: 'rbics_l2_name',
      name: 'Sub-Sector',
      formatter: stringf,
      filter: 'string',
    },
    {
      key: 'rbics_l3_name',
      name: 'Industry',
      formatter: stringf,
      filter: 'string',
    },
    {
      key: 'rbics_l4_name',
      name: 'Sub-Industry',
      formatter: stringf,
      filter: 'string',
    },
    {
      key: 'marginal_contribution_to_risk',
      name: 'Marginal Contribution to Risk',
      formatter: percent,
      filter: 'number',
    },
    {
      key: 'percent_contribution_to_risk',
      name: 'Percent Contribution to Risk',
      formatter: percent,
      filter: 'number',
    },
    {
      key: 'benchmark_marginal_contribution_to_risk',
      name: 'Benchmark Marginal Contribution to Risk',
      formatter: percent,
      filter: 'number',
    },
    {
      key: 'benchmark_percent_contribution_to_risk',
      name: 'Benchmark Percent Contribution to Risk',
      formatter: percent,
      filter: 'number',
    },
    {
      key: 'active_marginal_contribution_to_risk',
      name: 'Active Marginal Contribution to Risk',
      formatter: percent,
      filter: 'number',
    },
    {
      key: 'active_percent_contribution_to_risk',
      name: 'Active Percent Contribution to Risk',
      formatter: percent,
      filter: 'number',
    },
    {
      key: 'median_daily_volume_60',
      name: 'Medial Daily Volume 60',
      formatter: integer,
    },
    {
      key: 'initial_shares',
      name: 'Initial Shares',
      formatter: integer,
    },
    {
      key: 'initial_holdings_prices',
      name: 'Initial Holdings Prices',
      formatter: dollar,
    },
    {
      key: 'split_factor',
      name: 'Split Factor',
      formatter: helpers.tableFormatters.formatTo('float', { align: 'right', roundDigits: 4, commasOnThousands: true }),
      filter: 'number',
    },
    {
      key: 'optimizer_warnings',
      name: 'Optimizer Warnings',
      formatter: helpers.tableFormatters.formatTo('string', { align: 'left' }),
      filter: 'string',
    },
    {
      key: 'tcost_weight',
      name: 'T-cost',
      formatter: percent_with_decimals,
      filter: 'number',
    },
    {
      key: 'traded_value',
      name: 'Traded Value',
      formatter: dollar,
      filter: 'number',
    },
    {
      key: 'main_initial',
      name: 'Initial',
      formatter: percent_with_decimals,
      filter: 'number',
    },
    {
      key: 'main_optimal',
      name: 'Optimal',
      formatter: percent_with_decimals,
      filter: 'number',
    },
    {
      key: 'main_after_returns',
      name: 'After Returns',
      formatter: percent_with_decimals,
      filter: 'number',
    },
    {
      key: 'main_after_costs',
      name: 'Portfolio After Returns and Costs',
      formatter: percent_with_decimals,
      filter: 'number',
    },
    {
      key: 'benchmark_initial',
      name: 'Target Weights',
      formatter: percent_with_decimals,
      filter: 'number',
    },
    {
      key: 'benchmark_after_returns',
      name: 'Target After Returns',
      formatter: percent_with_decimals,
      filter: 'number',
    },
    {
      key: 'alpha',
      name: 'Alpha',
      formatter: helpers.tableFormatters.formatTo('float', { align: 'right', roundDigits: 4 }),
      filter: 'number',
    },
    {
      key: 'alpha_exposure',
      name: 'Alpha Exposure',
      formatter: helpers.tableFormatters.formatTo('float', { align: 'right', roundDigits: 3, commasOnThousands: true }),
      filter: 'number',
    },
    {
      key: '_rm_deciles_', // Dynamic
      name: 'Risk Model Factor Deciles',
      formatter: integer,
      filter: 'inferred',
      members: (key) => key.startsWith('_rm_deciles_'),
      cleanNameFormatter: (key) => `Decile for: ${key.split('_rm_deciles_')[1]}`,
      nameFormatter: (key) => <custom.MultilineTableHeader name={['Decile for:', key.split('_rm_deciles_')[1]]} />,
    },
    {
      key: '_alpha_exposures_', // Dynamic
      name: 'Alpha Factor Exposures',
      formatter: helpers.tableFormatters.formatTo('float', { align: 'right', roundDigits: 3, commasOnThousands: true }),
      filter: 'inferred',
      members: (key) => key.startsWith('_alpha_exposures_'),
      cleanNameFormatter: (key) => `Exposure to: ${key.split('_alpha_exposures_')[1]}`,
      nameFormatter: (key) => (
        <custom.MultilineTableHeader name={['Exposure to:', key.split('_alpha_exposures_')[1]]} />
      ),
    },
    {
      key: '_user_exposures_', // Dynamic
      name: 'User Factor Exposures',
      formatter: helpers.tableFormatters.formatTo('float', { align: 'right', roundDigits: 3, commasOnThousands: true }),
      filter: 'inferred',
      members: (key) => key.startsWith('_user_exposures_'),
      cleanNameFormatter: (key) => `Exposure to: ${key.split('_user_exposures_')[1]}`,
      nameFormatter: (key) => (
        <custom.MultilineTableHeader name={['Exposure to:', key.split('_user_exposures_')[1]]} />
      ),
    },
  ] as ts.types.components.dataGrid.ColumnsData,
};
