import React from 'react';

import * as enums from '../../../enums';
import { _, mui } from '../../../libs';
import * as types from '../../../types';
import ResourceAutocomplete from '../../resource-autocomplete';

type DeadbandType = { deadband: number };


type SignalRuleProps = {
  operator: string;
  signal: string;
  value?: any;
  deadband?: DeadbandType;
  setRule: (_s: string, _o: string, _v: any, _d: DeadbandType) => void;
  config: types.components.queryBuilder.QbAttributes;
  readOnly?: boolean;
};

const SignalRule: React.FC<SignalRuleProps> = ({
  operator,
  value,
  deadband,
  setRule,
  config,
  readOnly,
  signal,
}): React.ReactElement => {
  let multiplier = 1;
  if (config?.multiplier) multiplier = config.multiplier;

  const [selSignal, setSelSignal] = React.useState<string>(signal);
  const [numberValue, setNumberValue] = React.useState(value !== '' ? value / multiplier : '');
  const [deadbandValue, setDeadbandValue] = React.useState(
    deadband?.deadband ? _.round(deadband.deadband * 100, 2) : null
  );

  // eslint-disable-next-line no-nested-ternary
  const operatorValue = operator == '>' ? '>=' : operator == '<' ? '<=' : operator;
  return (
    <mui.core.Grid container spacing={2}>
      <mui.core.Grid item xs={3}>
        <ResourceAutocomplete<types.signal.SignalExpanded, undefined, true>
          value={selSignal}
          chooseHandle
          setValue={(v) => {
            setSelSignal(v);
            setRule(v, operatorValue, value, deadband);
          }}
          type={enums.RESOURCES_TYPES_ENUM.SIGNAL}
          disabled={readOnly}
          inputProps={{
            fullWidth: true,
            label: 'Signal',
            variant: 'outlined',
            size: 'small',
            required: true,
          }}
        />
      </mui.core.Grid>

      <mui.core.Grid item xs={2}>
        <mui.core.TextField
          value={operatorValue}
          onChange={(e) => setRule(selSignal, e.target.value, value, deadband)}
          fullWidth
          variant="outlined"
          disabled={readOnly}
          select
          size="small"
          SelectProps={{
            displayEmpty: true,
          }}
        >
          <mui.core.MenuItem value="" disabled>
            Operator
          </mui.core.MenuItem>
          <mui.core.MenuItem value="<=">{'\u2264'}</mui.core.MenuItem>
          <mui.core.MenuItem value=">=">{'\u2265'}</mui.core.MenuItem>
        </mui.core.TextField>
      </mui.core.Grid>
      <mui.core.Grid item xs>
        <mui.core.TextField
          label={config.label}
          required
          type="number"
          variant="outlined"
          value={numberValue}
          disabled={readOnly}
          onChange={(e) => {
            setNumberValue(e.target.value);
            setRule(selSignal, operator, e.target.value !== '' ? Number(e.target.value) * multiplier : '', deadband);
          }}
          size="small"
          fullWidth
        />
      </mui.core.Grid>
      <mui.core.Grid item xs>
        <mui.core.TextField
          label={config.deadbandLabel}
          type="number"
          variant="outlined"
          value={deadbandValue || ''}
          disabled={readOnly}
          onChange={(e) => {
            setDeadbandValue(Number(e.target.value) || null);
            setRule(selSignal, operator, value, {
              deadband: e.target.value ? Number(e.target.value) / 100 : null,
            });
          }}
          size="small"
          fullWidth
        />
      </mui.core.Grid>
    </mui.core.Grid>
  );
};

SignalRule.defaultProps = {
  signal: null,
  value: null,
  deadband: { deadband: null },
  readOnly: false,
};

export default SignalRule;
