import { _ } from '_core';

import { DraftParams } from './types';

export const validate = (params: DraftParams) => {
  if (_.isNil(params.num_bins)) return ['Number of Bins is a required field.'];
  if (_.isNil(params.history)) return ['History is a required field.'];
  if (params.num_bins < 0 || params.num_bins > 100) return ['Number of Bins must be between 0 and 100.'];
  return [];
};

export const getName = (params: DraftParams) => {
  return `Histogram (${params.history})`;
};
