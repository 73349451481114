import { hooks, mui, React, ts, ui } from '_core';

import { DraftParams } from './types';

type FormProps = {
  params: DraftParams;
  setParams: (_p: DraftParams) => void;
  context?: ts.types.signal.SignalAnalysisContext;
};

const Form: React.FC<FormProps> = ({ params, setParams, context }) => {
  const [returnHorizon, setReturnHorizon] = React.useState<ts.types.returnHorizon.ReturnHorizon>(params.return_horizon);
  const [buckets, setBuckets] = React.useState(
    params.buckets ||
      ({
        type: ts.enums.BUCKET_TYPES_ENUM.LONG_SHORT,
      } as ts.types.widgets.common.BucketValue)
  );
  const [selectedHistories, setSelectedHistories] = React.useState(
    params.histories || ([] as ts.enums.HISTORY_ENUM_EXTENDED[])
  );
  const [joint, setJoint] = React.useState(params.joint || false);

  const uiStyles = hooks.useUiStyles();

  React.useEffect(() => {
    const data = {
      return_horizon: returnHorizon,
      histories: selectedHistories,
      statistics_type: params.statistics_type,
      buckets,
      joint,
    };

    setParams({ ...params, ...data });
  }, [returnHorizon, buckets, selectedHistories, joint]);

  return (
    <mui.core.Box sx={uiStyles.widgetEditMode}>
      <mui.core.Box mt={4}>
        <ui.WidgetReturnHorizon<false>
          value={returnHorizon}
          setValue={(val) => setReturnHorizon(val)}
          context={context}
        />
      </mui.core.Box>

      {params.statistics_type != 'risk' && (
        <mui.core.Box mt={2}>
          <mui.core.FormControlLabel
            control={<mui.core.Switch size="small" checked={joint} onChange={() => setJoint(!joint)} />}
            label="Compound jointly"
          />
        </mui.core.Box>
      )}

      <mui.core.Box mt={2}>
        <ui.WidgetHistories<true>
          multiple
          value={selectedHistories}
          setValue={setSelectedHistories}
          context={context}
        />
      </mui.core.Box>
      <mui.core.Box mt={4}>
        <ui.Buckets value={buckets} setValue={setBuckets} />
      </mui.core.Box>
    </mui.core.Box>
  );
};

export default Form;
