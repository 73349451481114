import { helpers, ts } from '_core';

export default {
  model: [
    {
      key: 'label',
      name: '',
      formatter: helpers.tableFormatters.formatTo('string', { align: 'left' }),
      filter: 'string',
      isFixed: true,
    },
    {
      key: 'metric',
      name: 'Metric',
      formatter: helpers.tableFormatters.formatTo('string', { align: 'left' }),
      filter: 'string',
    },
    {
      key: 'pipeline',
      name: 'Pipeline',
      formatter: helpers.tableFormatters.formatTo('string', { align: 'left' }),
      filter: 'string',
    },
    {
      key: 'sp_pipeline',
      name: 'Portfolio Pipeline',
      formatter: helpers.tableFormatters.formatTo('string', { align: 'left' }),
      filter: 'string',
    },
    {
      key: 'initial',
      name: 'Previous',
      formatter: helpers.tableFormatters.formatTo('float', { align: 'right', roundDigits: 3 }),
      filter: 'number',
    },
    {
      key: 'optimal',
      name: 'Optimal',
      formatter: helpers.tableFormatters.formatTo('float', { align: 'right', roundDigits: 3 }),
      filter: 'number',
    },
    {
      key: 'diff',
      name: 'Change',
      formatter: helpers.tableFormatters.formatTo('float', {
        align: 'right',
        roundDigits: 3,
        multiplier: 100,
        suffix: '%',
        commasOnThousands: true,
      }),
      filter: 'number',
    },
    {
      key: '1 day rank correlation',
      name: '1 Day Rank Correlation',
      formatter: helpers.tableFormatters.formatTo('float', {
        align: 'right',
        roundDigits: 3,
        multiplier: 100,
        suffix: '%',
        commasOnThousands: true,
      }),
      filter: 'number',
    },
    {
      key: '1 month rank correlation',
      name: '1 Month Rank Correlation',
      formatter: helpers.tableFormatters.formatTo('float', {
        align: 'right',
        roundDigits: 3,
        multiplier: 100,
        suffix: '%',
        commasOnThousands: true,
      }),
      filter: 'number',
    },
    {
      key: '1 week rank correlation',
      name: '1 Week Rank Correlation',
      formatter: helpers.tableFormatters.formatTo('float', {
        align: 'right',
        roundDigits: 3,
        multiplier: 100,
        suffix: '%',
        commasOnThousands: true,
      }),
      filter: 'number',
    },
    {
      key: '3 month rank correlation',
      name: '3 Month Rank Correlation',
      formatter: helpers.tableFormatters.formatTo('float', {
        align: 'right',
        roundDigits: 3,
        multiplier: 100,
        suffix: '%',
        commasOnThousands: true,
      }),
      filter: 'number',
    },
  ] as ts.types.components.dataGrid.ColumnsData,
  risk: [
    {
      key: 'label',
      name: '',
      formatter: helpers.tableFormatters.formatTo('string', { align: 'left' }),
      filter: 'string',
      isFixed: true,
    },
    {
      key: 'metric',
      name: 'Metric',
      formatter: helpers.tableFormatters.formatTo('string', { align: 'left' }),
      filter: 'string',
    },
    {
      key: 'initial',
      name: 'Previous',
      formatter: helpers.tableFormatters.formatTo('float', { align: 'right', roundDigits: 3 }),
      filter: 'number',
    },
    {
      key: 'optimal',
      name: 'Optimal',
      formatter: helpers.tableFormatters.formatTo('float', { align: 'right', roundDigits: 3 }),
      filter: 'number',
    },
    {
      key: 'diff',
      name: 'Change',
      formatter: helpers.tableFormatters.formatTo('float', {
        align: 'right',
        roundDigits: 3,
        multiplier: 100,
        suffix: '%',
        commasOnThousands: true,
      }),
      filter: 'number',
    },
  ] as ts.types.components.dataGrid.ColumnsData,
  factor: [
    {
      key: 'label',
      name: '',
      formatter: helpers.tableFormatters.formatTo('string', { align: 'left' }),
      filter: 'string',
      isFixed: true,
    },
    {
      key: 'metric',
      name: 'Signal',
      formatter: helpers.tableFormatters.formatTo('string', { align: 'left' }),
      filter: 'string',
    },
    {
      key: 'initial',
      name: 'Previous',
      formatter: helpers.tableFormatters.formatTo('float', { align: 'right', roundDigits: 3 }),
      filter: 'number',
    },
    {
      key: 'optimal',
      name: 'Optimal',
      formatter: helpers.tableFormatters.formatTo('float', { align: 'right', roundDigits: 3 }),
      filter: 'number',
    },
    {
      key: 'diff',
      name: 'Change',
      formatter: helpers.tableFormatters.formatTo('float', {
        align: 'right',
        roundDigits: 3,
        multiplier: 100,
        suffix: '%',
        commasOnThousands: true,
      }),
      filter: 'number',
    },
    {
      key: '1 day rank correlation',
      name: '1 Day Rank Correlation',
      formatter: helpers.tableFormatters.formatTo('float', {
        align: 'right',
        roundDigits: 3,
        multiplier: 100,
        suffix: '%',
        commasOnThousands: true,
      }),
      filter: 'number',
    },
    {
      key: '1 month rank correlation',
      name: '1 Month Rank Correlation',
      formatter: helpers.tableFormatters.formatTo('float', {
        align: 'right',
        roundDigits: 3,
        multiplier: 100,
        suffix: '%',
        commasOnThousands: true,
      }),
      filter: 'number',
    },
    {
      key: '1 week rank correlation',
      name: '1 Week Rank Correlation',
      formatter: helpers.tableFormatters.formatTo('float', {
        align: 'right',
        roundDigits: 3,
        multiplier: 100,
        suffix: '%',
        commasOnThousands: true,
      }),
      filter: 'number',
    },
    {
      key: '3 month rank correlation',
      name: '3 Month Rank Correlation',
      formatter: helpers.tableFormatters.formatTo('float', {
        align: 'right',
        roundDigits: 3,
        multiplier: 100,
        suffix: '%',
        commasOnThousands: true,
      }),
      filter: 'number',
    },
  ] as ts.types.components.dataGrid.ColumnsData,
};
