import { am4charts, am4core, config } from '_core';

type createImageHeaderProps = {
  chart: am4charts.XYChart;
  id: string;
  title: string;
  fullScreen: boolean;
};

export const createImageHeader = ({ chart, id, title, fullScreen }: createImageHeaderProps) => {
  const footerId = `widget-footer-${id}-${fullScreen ? 'fs' : 'sw'}`;

  const header = chart.createChild(am4core.Container);
  header.toBack();
  header.layout = 'absolute';
  header.width = am4core.percent(100);
  header.marginTop = fullScreen ? 10 : 5;
  header.opacity = 0.7;
  header.marginBottom = 10;

  const widgetTitle = header.createChild(am4core.Label);
  widgetTitle.text = title;
  (widgetTitle as any).fontSize = fullScreen ? 20 : 15;
  (widgetTitle as any).fontWeight = 600;
  (widgetTitle as any).layout = 'fixed';
  widgetTitle.align = 'left';

  const footer = am4core.create(footerId, am4core.Container);
  footer.layout = 'absolute';
  footer.logo.disabled = true;
  footer.height = 50;
  footer.width = am4core.percent(100);

  const finseraLogo = footer.createChild(am4core.Image);
  finseraLogo.href = config.customUrl + '/custom/finsera-logo-dark.png';
  finseraLogo.align = 'right';
  finseraLogo.width = fullScreen ? 120 : 60;
  finseraLogo.opacity = 0.5;
  finseraLogo.marginRight = fullScreen ? 20 : 10;

  chart.exporting.extraSprites.push(footer);

  header.disabled = true;
  document.getElementById(footerId).style.height = '0px';
  document.getElementById(footerId).style.marginTop = '-20px';
  document.getElementById(footerId).style.padding = '10px';

  chart.exporting.events.on('exportstarted', function () {
    document.getElementById(footerId).style.height = '70px';
    header.disabled = false;
  });

  chart.exporting.events.on('exportfinished', function () {
    document.getElementById(footerId).style.height = '0px';
    header.disabled = true;
  });
};
