import { helpers, hooks, mui, React, ts, ui,useSelector } from '_core';

import { getConstraintType } from '../../optimizer-utils/constraint-type';
import Bounds from '../bounds';
import SharedConstraintFields from '../shared-fields';
import Target from '../target';

type AssetAllProps = {
  id: string;
  params: ts.types.optimizer.IndustryDraft;
  setParams: (_params: ts.types.optimizer.IndustryDraft) => void;
  readOnly: boolean;
  optimizerType: 'basket' | 'backtest';
  validationDef: ts.types.common.ValidationErrors;
};

const IndustryConstraint: React.FC<AssetAllProps> = ({
  id,
  params,
  setParams,
  readOnly,
  optimizerType,
  validationDef,
}): React.ReactElement => {
  const [showMore, setShowMore] = React.useState(false);
  const [constraint, setConstraint] = React.useState(params);

  const resources = useSelector((state) => state.resources);
  const theme = mui.styles.useTheme() as mui.core.Theme;

  React.useEffect(() => {
    if (optimizerType == 'basket' && params.benchmark_relative == null) {
      setConstraint({ ...constraint, benchmark_relative: true });
    }
  }, []);

  hooks.useEffectWithoutFirst(() => {
    setParams(constraint);
  }, [constraint]);

  const form = () => {
    if (getConstraintType(constraint) == 'BOUNDS') {
      if (showMore) {
        return (
          <Bounds
            id={id}
            params={constraint}
            setParams={setConstraint}
            isPercent
            readOnly={readOnly}
            validationDef={validationDef}
          >
            <SharedConstraintFields
              id={id}
              params={constraint}
              setParams={setConstraint}
              readOnly={readOnly}
              validationDef={validationDef}
            />
          </Bounds>
        );
      }

      return (
        <Bounds
          id={id}
          params={constraint}
          setParams={setConstraint}
          isPercent
          readOnly={readOnly}
          validationDef={validationDef}
        />
      );
    }

    if (showMore) {
      return (
        <Target params={constraint} setParams={setConstraint} readOnly={readOnly}>
          <SharedConstraintFields
            id={id}
            params={constraint}
            setParams={setConstraint}
            readOnly={readOnly}
            validationDef={validationDef}
          />
        </Target>
      );
    }

    return <Target params={constraint} setParams={setConstraint} readOnly={readOnly} />;
  };

  let valueOptions: { value: string; name: string; shortName: string }[] = [];
  // if (params.type == 'GICS_SECTOR')
  //   valueOptions = helpers.parseIndustries.industryGenerator(resources.gics_data.sectors);
  // if (params.type == 'GICS_GROUP')
  //   valueOptions = helpers.parseIndustries.industryGenerator(resources.gics_data.groups);
  // if (params.type == 'GICS_IND')
  //   valueOptions = helpers.parseIndustries.industryGenerator(resources.gics_data.industries);
  // if (params.type == 'GICS_SUBIND')
  //   valueOptions = helpers.parseIndustries.industryGenerator(resources.gics_data.subIndustries);
  if (params.type == 'RBICS_L1_ID')
    valueOptions = helpers.parseIndustries.industryGenerator(resources.rbics_data.levelOne);
  if (params.type == 'RBICS_L2_ID')
    valueOptions = helpers.parseIndustries.industryGenerator(resources.rbics_data.levelTwo);
  if (params.type == 'RBICS_L3_ID')
    valueOptions = helpers.parseIndustries.industryGenerator(resources.rbics_data.levelThree);
  if (params.type == 'RBICS_L4_ID')
    valueOptions = helpers.parseIndustries.industryGenerator(resources.rbics_data.levelFour);

  valueOptions = [{ value: 'select-all', name: 'select-all', shortName: 'select-all' }, ...valueOptions];

  let industryOptions: {
    value: string;
    label: string;
    deprecated?: boolean;
  }[] = [
    {
      value: 'RBICS_L1_ID',
      label: 'Sector',
    },
    {
      value: 'RBICS_L2_ID',
      label: 'Sub Sector',
    },
    {
      value: 'RBICS_L3_ID',
      label: 'Industry',
    },
    {
      value: 'RBICS_L4_ID',
      label: 'Sub Industry',
    },
  ];

  if (params?.type?.includes('GICS')) {
    industryOptions = industryOptions.concat([
      {
        value: 'GICS_SECTOR',
        label: 'Sector (Deprecated)',
        deprecated: true,
      },
      {
        value: 'GICS_GROUP',
        label: 'Group (Deprecated)',
        deprecated: true,
      },
      {
        value: 'GICS_IND',
        label: 'Industry (Deprecated)',
        deprecated: true,
      },
      {
        value: 'GICS_SUBIND',
        label: 'Sub Industry (Deprecated)',
        deprecated: true,
      },
    ]);
  }

  return (
    <>
      <mui.core.Box p={2}>
        <mui.core.Grid container spacing={5} style={{ marginBottom: theme.spacing(3) }}>
          <mui.core.Grid item xs={12} md={3}>
            <mui.core.Box px={3}>
              <mui.core.FormControlLabel
                control={
                  <mui.core.Switch
                    size="small"
                    checked={constraint.benchmark_relative}
                    onChange={() =>
                      setConstraint({
                        ...constraint,
                        benchmark_relative: !constraint.benchmark_relative,
                      })
                    }
                    disabled={readOnly}
                  />
                }
                label={optimizerType == 'backtest' ? 'Benchmark Relative' : 'Relative to Target Weights'}
              />
            </mui.core.Box>
          </mui.core.Grid>
          <mui.core.Grid item xs={12} md={4}>
            <mui.core.TextField
              select
              label="Type"
              variant="outlined"
              size="small"
              value={constraint.type}
              onChange={(e) =>
                setConstraint({
                  ...constraint,
                  type: e.target.value as ts.types.optimizer.CategoryConstraint['type'],
                  value: undefined,
                })
              }
              fullWidth
              disabled={readOnly}
              required
              error={validationDef?.fields?.includes(`industry_type_${id}`)}
            >
              {industryOptions.map((indOp) => (
                <mui.core.MenuItem key={indOp.value} value={indOp.value} disabled={indOp.deprecated}>
                  {indOp.label}
                </mui.core.MenuItem>
              ))}
            </mui.core.TextField>
          </mui.core.Grid>
          <mui.core.Grid item xs={12} md={4}>
            <ui.CheckboxesAutocomplete<string>
              value={constraint.value as string[]}
              setValue={(val) => setConstraint({ ...constraint, value: val })}
              options={valueOptions}
              label="Value"
              disabled={readOnly || constraint?.type?.includes('GICS')}
              optionLabel={(o) => o.name as string}
              chipKey="shortName"
              error={validationDef?.fields?.includes(`industry_value_${id}`)}
            />
          </mui.core.Grid>
        </mui.core.Grid>
        {form()}
        <mui.core.Button
          onClick={() => {
            setShowMore(!showMore);
          }}
          color="primary"
          style={{ marginTop: '0.5rem' }}
        >
          {showMore ? (
            <>
              <mui.icons.Remove style={{ fontSize: '14px', marginRight: '0.5rem' }} />
              Show Less
            </>
          ) : (
            <>
              <mui.icons.Add style={{ fontSize: '14px', marginRight: '0.5rem' }} />
              Show More
            </>
          )}
        </mui.core.Button>
      </mui.core.Box>
    </>
  );
};

export default IndustryConstraint;
