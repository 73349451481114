import { endpoint } from './config';
import { $ } from '../libs';
import { Organization, OrganizationCosts, OrganizationFeaturesDraft } from '../types/organization';
import { OrganizationFeatures } from '../types/organization';
import { Pagination } from '../types/widgets/common';

export const getOrganizations = (): JQuery.jqXHR<{ organizations: Organization[] }> =>
  $.ajax({
    method: 'GET',
    url: `${endpoint}admin/organizations`,
  });

export const getOrganizationFeatures = (
  id: number
): JQuery.jqXHR<{ data: OrganizationFeatures; pagination: Pagination }> =>
  $.ajax({
    method: 'GET',
    url: `${endpoint}admin/organizations/${id}/features`,
  });

export const updateOrganizationFeatures = (
  id: number,
  data: OrganizationFeaturesDraft
): JQuery.jqXHR<{ data: OrganizationFeatures }> =>
  $.ajax({
    method: 'PUT',
    url: `${endpoint}admin/organizations/${id}/features`,
    json: data,
  } as JQueryAjaxSettings);

export const getCosts = (organization_id: number, year: string, month: string): JQuery.jqXHR<OrganizationCosts> =>
  $.ajax({
    method: 'GET',
    url: `${endpoint}job-report`,
    data: {
      organization_id,
      year,
      month,
    },
  } as JQueryAjaxSettings);

export const updateOrganizationStatus = (id: number, is_active: boolean): JQuery.jqXHR<Organization> =>
  $.ajax({
    method: 'PUT',
    url: `${endpoint}admin/organizations/${id}/active`,
    json: { is_active },
  } as JQueryAjaxSettings);
