import { ts } from '_core';

import AssetMaster from './asset-master';
import Industry from './industry';
// import RiskModel from './risk-model';
import RiskModelCategory from './risk-model-category';
import Signal from './signal';

export default {
  [ts.enums.N_FACTOR_TYPE_ENUM.ASSET_MASTER]: AssetMaster,
  [ts.enums.N_FACTOR_TYPE_ENUM.INDUSTRY]: Industry,
  [ts.enums.N_FACTOR_TYPE_ENUM.RISK_MODEL_CATEGORY]: RiskModelCategory,
  [ts.enums.N_FACTOR_TYPE_ENUM.RISK_MODEL_STYLE]: () => <></>,
  [ts.enums.N_FACTOR_TYPE_ENUM.MARKET]: () => <></>,
  [ts.enums.N_FACTOR_TYPE_ENUM.SIGNAL]: Signal,
  [ts.enums.N_FACTOR_TYPE_ENUM.RISK_MODEL_THEME]: null,
};
