import { econDevelopmentFilter } from './custom-filters';
import { searchEtf } from '../../api/asset-master';
import { _ } from '../../libs';
import * as types from '../../types';


type customGeoRenamesType = {
  [key: string]: string;
};

type customGeoOptionsValuesType = {
  [key: string]: (string | number)[];
};

type geoFilter = {
  etf_geo_exposure_code: string | number;
  etf_specific_geo_desc: string;
  etf_broad_geo_desc: string;
};

const filteringOptions = [
  {
    field_label: 'Size',
    field_type: 'focus',
    field_key: 'fund_focus_code',
    field_id: 26,
    filter_key: 'fund_category_code',
  },
  {
    field_label: 'Style',
    field_type: 'niche',
    field_key: 'fund_niche_code',
    field_id: 26,
    filter_key: 'fund_category_code',
  },
  {
    field_label: 'Theme',
    field_type: 'niche',
    field_key: 'fund_niche_code',
    field_id: 58,
    filter_key: 'fund_focus_code',
  },
];

const customGeoExposureRenames: customGeoRenamesType = {
  'Developed Markets': 'Global',
  'Emerging Markets': 'Global',
  'Frontier Markets': 'Global',
  'Global Ex-U.S.': 'Global',
};

export const getEtfPresetFilters = (filtersOptions: any[]) => {
  return filteringOptions.map((option) => {
    const currentOptions = _.uniqBy(
      _.map(
        _.filter(filtersOptions, (f) => f[option.filter_key] == option.field_id),
        (el) => {
          return { code: el[option.field_key], label: el[option.field_type] };
        }
      ),
      'label'
    );
    return { label: option.field_label, key: option.field_key, options: currentOptions };
  }) as types.etf.ETFFiltersOptions;
};

const getSectorIndustryFilters = (options: any[]) => {
  const sectorOptions = _.filter(options, (f) => f['fund_category_code'] == 27 && f['fund_focus_code'] != 58);

  const uniqMapOptions = (codeKey: string, labelKey: string) =>
    _.uniqBy(
      _.map(sectorOptions, (el) => {
        return { code: el[codeKey], label: el[labelKey] };
      }),
      'label'
    );

  const sectors = uniqMapOptions('fund_focus_code', 'focus');
  const industries = uniqMapOptions('fund_niche_code', 'niche');

  const sectorOption = { label: 'Sector', key: 'fund_focus_code', options: sectors };
  const industryOption = { label: 'Industry', key: 'fund_niche_code', options: industries };
  return [sectorOption, industryOption];
};

const formatGeoExposureOptions = (geoOptions: geoFilter[]) => {
  // Handling Custom Filter Cases
  const canadaGeo = _.find(geoOptions, (el) => el.etf_geo_exposure_code == 'CA');
  canadaGeo.etf_broad_geo_desc = 'Canada';

  const usGeo = _.find(geoOptions, (el) => el.etf_geo_exposure_code == 'US');
  usGeo.etf_broad_geo_desc = 'US';

  const finalOptions = [...geoOptions, canadaGeo, usGeo];

  const customOptionValues = {} as customGeoOptionsValuesType;

  const renamedOptions = finalOptions.map((el: geoFilter) => {
    let broadDesc = el.etf_broad_geo_desc;
    if (customGeoExposureRenames[broadDesc]) {
      broadDesc = customGeoExposureRenames[broadDesc];
    }

    return { ...el, etf_broad_geo_desc: broadDesc };
  });

  const uniqueBroadOptions = _.uniq(_.map(renamedOptions, 'etf_broad_geo_desc'));

  uniqueBroadOptions.forEach((uniqOption) => {
    const filteredOptions = _.map(
      _.filter(renamedOptions, (el) => el.etf_broad_geo_desc == uniqOption),
      'etf_geo_exposure_code'
    );

    customOptionValues[uniqOption] = filteredOptions;
  });

  const filteredGeoOptions = _.uniqBy(renamedOptions, 'etf_broad_geo_desc');

  return { options: filteredGeoOptions, customValues: customOptionValues };
};

export const getEtfCustomFilters = (filtersOptions: any[]) => {
  const sectorsOptions = getSectorIndustryFilters(filtersOptions);

  const newGeoOptions = formatGeoExposureOptions(filtersOptions[filtersOptions.length - 1]);

  const geoExpoOption = {
    label: 'Geo Exposure',
    key: 'etf_geo_exposure_code',
    hasCustomValues: true,
    customValues: newGeoOptions.customValues,
    options: newGeoOptions?.options.map((geo: geoFilter) => {
      return { code: geo['etf_broad_geo_desc'], label: geo['etf_broad_geo_desc'] };
    }),
  };

  const econDevelopmentOption = {
    label: 'Market Development',
    key: 'etf_econ_development_desc',
    options: econDevelopmentFilter,
  };

  return [...sectorsOptions, geoExpoOption, econDevelopmentOption] as types.etf.ETFFiltersOptions;
};

export const queryETF = async (params: types.etf.ETFParams, limit: number, offset: number) => {
  try {
    const resultETFs = await searchEtf({ params, limit, offset });
    return { error: false, response: resultETFs };
  } catch (e) {
    return { error: true, response: 'Unable to search for ETFs, please try again.' };
  }
};
