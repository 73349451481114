import { helpers, ts } from '_core';

export default {
  rebalance: [
    {
      key: 'label',
      name: '',
      formatter: helpers.tableFormatters.formatTo('string', { align: 'left' }),
      filter: 'string',
      isFixed: true,
    },
    {
      key: 'metric',
      name: 'Metric',
      formatter: helpers.tableFormatters.formatTo('string', { align: 'left' }),
      filter: 'string',
    },
    {
      key: 'initial',
      name: 'Previous',
      formatter: helpers.tableFormatters.formatTo('string', { align: 'right', showBlanks: true }),
      filter: 'number',
    },
    {
      key: 'optimal',
      name: 'Optimal',
      formatter: helpers.tableFormatters.formatTo('string', { align: 'right' }),
      filter: 'number',
    },
    {
      key: 'diff',
      name: 'Change',
      formatter: helpers.tableFormatters.formatTo('float', {
        align: 'right',
        roundDigits: 3,
        multiplier: 100,
        suffix: '%',
        commasOnThousands: true,
        showBlanks: true,
      }),
      filter: 'number',
    },
  ] as ts.types.components.dataGrid.ColumnsData,
  trade: [
    {
      key: 'label',
      name: '',
      formatter: helpers.tableFormatters.formatTo('string', { align: 'left' }),
      filter: 'string',
      isFixed: true,
    },
    {
      key: 'metric',
      name: 'Metric',
      formatter: helpers.tableFormatters.formatTo('string', { align: 'left' }),
      filter: 'string',
    },
    {
      key: 'value',
      name: 'Value (USD)',
      formatter: helpers.tableFormatters.formatTo('integer', {
        align: 'right',
        prefix: '$',
        commasOnThousands: true,
      }),
      filter: 'number',
    },
    {
      key: '%',
      name: '% of Gross Exposure',
      formatter: helpers.tableFormatters.formatTo('float', {
        align: 'right',
        roundDigits: 3,
        multiplier: 100,
        suffix: '%',
        commasOnThousands: true,
      }),
      filter: 'number',
    },
    {
      key: 'trade_count',
      name: 'Trade Count',
      formatter: helpers.tableFormatters.formatTo('integer', { align: 'right', commasOnThousands: true }),
      filter: 'number',
    },
    {
      key: 'share_count',
      name: 'Share Count',
      formatter: helpers.tableFormatters.formatTo('integer', { align: 'right', commasOnThousands: true }),
      filter: 'number',
    },
    {
      key: 'average_price_share',
      name: 'Average Price/Share',
      formatter: helpers.tableFormatters.formatTo('float', {
        align: 'right',
        roundDigits: 3,
        prefix: '$',
      }),
      filter: 'number',
    },
    {
      key: 'forecast_cost',
      name: 'Forecast Cost',
      formatter: helpers.tableFormatters.formatTo('float', {
        align: 'right',
        roundDigits: 3,
        multiplier: 100,
        suffix: '%',
        commasOnThousands: true,
      }),
      filter: 'number',
    },
  ] as ts.types.components.dataGrid.ColumnsData,
};
