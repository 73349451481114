import React from 'react';

import { mui } from '../../libs';
import CodeBlock from '../code-block';

const ErrorLog = ({
  isOpen,
  handleClose,
  errorMessage,
  errorLog,
}: {
  isOpen: boolean;
  handleClose: () => void;
  errorMessage: string;
  errorLog: string;
}) => {
  const theme = mui.styles.useTheme() as mui.core.Theme;

  return (
    <mui.core.Dialog
      open={isOpen}
      onClose={handleClose}
      maxWidth="sm"
      fullWidth
      sx={{ '& .MuiDialog-paper': { maxHeight: '60vh' } }}
    >
      <mui.core.DialogTitle>
        <mui.core.Typography sx={{ fontWeight: 600 }} color="error" variant="body1">
          {errorMessage}
        </mui.core.Typography>
      </mui.core.DialogTitle>
      <mui.core.DialogContent dividers>
        <CodeBlock content={errorLog} />
        <mui.core.Typography color={(theme.palette as any).text.secondary} variant="body2"></mui.core.Typography>
      </mui.core.DialogContent>
      <mui.core.DialogActions>
        <mui.core.Button onClick={handleClose} color="primary" variant="contained">
          Close
        </mui.core.Button>
      </mui.core.DialogActions>
    </mui.core.Dialog>
  );
};

export default ErrorLog;
