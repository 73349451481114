import { ts } from '_core';

// prepareRiskFactorExposureData
export const transform = (data: ts.types.widgets.LinearData) => {
  const newData: ts.types.widgets.LinearData = {};

  Object.keys(data).forEach((key) => {
    if (key != 'tab') {
      const filteredData = data[key].filter((el) => el.value !== null);
      if (filteredData.length > 0) newData[key] = filteredData;
    }
  });

  return newData;
};
