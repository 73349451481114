import { _, hooks, mui, React, ts, useSelector } from '_core';

import { getTabName, prepareNames } from './helpers';
import { Params } from './types';
import ChartWrapper from '../../common/chart-wrapper';
import createLineChart from '../../common/charts/create-line-chart';
import { getRbicsChildren, getTopBucketsAndMergeRest } from '../universe-coverage-by-rbics/helpers';
import InteractionForm from '../universe-coverage-by-rbics/interaction-form';

type ChartProps = {
  id: string;
  readOnly: boolean;
  widgetKey: string;
  loadWidgetData: (_payload: ts.types.widgets.WidgetGetDataParams) => Promise<ts.types.widgets.WidgetGetDataResponse>;
  file: string;
  fullScreen: boolean;
  params: Params;
  setParams: (_p: Params) => void;
  title: string;
  context: ts.types.signal.SignalAnalysisContext;
};

const Chart: React.FC<ChartProps> = ({
  id,
  readOnly,
  widgetKey,
  loadWidgetData,
  file,
  fullScreen,
  params,
  setParams,
  title,
  context,
}) => {
  const resources = useSelector((state) => state.resources);

  const [data, setData] = React.useState<ts.types.widgets.TableData>(null);
  const [error, setError] = React.useState<ts.types.common.ApiError>(null);
  const [selectedTab, setSelectedTab] = React.useState<'signal_count' | 'pipeline_count' | 'sp_pipeline_count'>(
    'signal_count'
  );
  const [showLegend, setShowLegend] = React.useState<boolean>(false);

  const availableTabs = React.useMemo(() => {
    const lat = ['signal_count'];
    if (context.pipeline_id) lat.push('pipeline_count');
    if (context.sp_pipeline_id) lat.push('sp_pipeline_count');
    return lat;
  }, [context]);

  // params state copy
  const [localParams, setLocalParams] = React.useState(params);

  hooks.useEffectWithoutFirst(() => {
    setParams(localParams);
  }, [localParams]);

  const getSelected = () => {
    if (_.isEmpty(localParams.selectedRbics)) return [];
    return localParams.selectedRbics
      .map((sel) => getRbicsChildren(localParams.parent, sel.value, resources.rbics_data))
      .flat();
  };

  const selectedCols = React.useMemo(() => getSelected(), [localParams.selectedRbics]);

  const loadData = async () => {
    try {
      const response = await loadWidgetData({
        data: {
          file,
          rename: { build_date: 'date' },
          values_filter: [
            {
              column: 'tab',
              operator: 'equals',
              value: selectedTab,
            },
          ],
        },
      });
      if (response) {
        setData((response.data ?? []).map((r) => _.omit(r, 'tab')));
      }
    } catch (err) {
      setError(err as ts.types.common.ApiError);
    }
  };

  const filteredData = React.useMemo(() => {
    if (!data) return null;
    let localData = data;

    if (['rbics_l3_id', 'rbics_l4_id'].includes(localParams.category)) {
      localData =
        localParams.chartType == 'top'
          ? getTopBucketsAndMergeRest(data, 19)
          : _.map(data, (obj) => _.pick(obj, ['date', ...selectedCols.map((g) => g.value)]));
    }
    return localData;
  }, [data, selectedCols, localParams.chartType, selectedTab]);

  React.useEffect(() => {
    if (file && localParams.chartType == 'top') loadData();
    if (file && localParams.chartType == 'rbics' && !_.isNull(selectedCols)) loadData();
    return () => setData(null);
  }, [file, selectedTab]);

  React.useEffect(() => {
    // Create line chart on data and scale changes
    if (!_.isNil(data)) {
      createLineChart({
        id,
        scale: 'linear',
        data: filteredData,
        fullScreen,
        setShowLegend,
        percent: false,
        yAxisLabel: 'Signal Coverage',
        xAxisLabel: 'Time',
        aggregation: 'outlier',
        integerOnly: true,
        exportTitle: title,
        prepareData: (linearData) => prepareNames(linearData, localParams.category, resources),
      });
    }
  }, [filteredData]);

  const getTabs = () => {
    return (
      <mui.core.Tabs
        indicatorColor="primary"
        textColor="primary"
        value={selectedTab}
        onChange={(e, newTab) => setSelectedTab(newTab)}
        aria-label="Tabs"
      >
        {availableTabs.map((tab) => (
          <mui.core.Tab key={tab} label={getTabName(tab as typeof selectedTab)} value={tab} />
        ))}
      </mui.core.Tabs>
    );
  };

  return (
    <>
      <ChartWrapper
        id={id}
        readOnly={readOnly}
        widgetKey={widgetKey}
        data={data}
        showLegend={showLegend}
        error={error}
        tabs={getTabs()}
        customHeader={
          ['rbics_l3_id', 'rbics_l4_id'].includes(localParams.category) ? (
            <>
              <InteractionForm<typeof localParams>
                params={localParams}
                setParams={setLocalParams}
                fullScreen={fullScreen}
              />
            </>
          ) : undefined
        }
        fullScreen={fullScreen}
      />
    </>
  );
};

export default Chart;
