import { _, helpers, hooks, mui, React, ui } from '_core';

import { RegularDataGridContext } from './regular-context';
import Toolbar from '../../toolbar';

const PreviewContainer = React.forwardRef<HTMLDivElement, mui.core.BoxProps>((props, ref) => {
  return (
    <mui.core.Box
      {...props}
      ref={ref}
      sx={{
        background: '#fff',
        overflow: 'hidden',
        height: '100%',
        position: 'relative',
        margin: '0 4px',
        '@media print': {
          maxWidth: '100vw',
        },
        '& .react-grid-Grid .react-grid-Cell': {
          height: '200px',
        },
        '& .react-grid-Toolbar .tools': {
          width: '100%',
          display: 'flex',
          justifyContent: 'flex-end',
        },
        '& .MuiDataGrid-virtualScroller': {
          scrollbarWidth: 'thin',
        },
        ...props.sx,
      }}
    />
  );
});

const LoaderWrapper = (props: mui.core.BoxProps) => {
  return (
    <mui.core.Box
      component="section"
      {...props}
      sx={{
        position: 'fixed',
        zIndex: 100,
        width: 'calc(100% - 1px) !important',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgba(255, 255, 255, 0.7)',
        ...props.sx,
      }}
    />
  );
};

const LoadingContainer = (props: mui.core.BoxProps) => {
  return (
    <mui.core.Box
      component="section"
      {...props}
      sx={{
        width: '100% !important',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        ...props.sx,
      }}
    />
  );
};

const CustomDataGrid = (): React.ReactElement => {
  const regularDataGridContext = React.useContext(RegularDataGridContext);
  const {
    loading,
    customHeight,
    firstPrefLoad,
    mappedColumns,
    loadingPreferences,
    tableWidth,
    setTableWidth,
    triggerWidthChange,
  } = regularDataGridContext;
  const containerRef = React.useRef(null);
  const [containerHeight, setContainerHeight] = React.useState<number>(undefined);

  const { width, height } = hooks.useWindowDimensions(0);

  const debouncedSetTableWidth = React.useCallback(
    _.debounce(() => {
      const newWidth = containerRef.current.offsetWidth;
      setTableWidth(newWidth);
    }, 300),
    [setTableWidth]
  );

  React.useEffect(() => {
    if (containerRef.current) {
      const newHeight = containerRef.current.offsetHeight;
      const newWidth = containerRef.current.offsetWidth;

      if (containerHeight === undefined || containerHeight !== newHeight) {
        setContainerHeight(newHeight);
      }

      if (tableWidth === undefined || tableWidth !== newWidth) {
        debouncedSetTableWidth();
      }
    }
  }, [width, triggerWidthChange, height, containerHeight]);

  const dataGridHeight = customHeight ? customHeight : containerHeight ? containerHeight : 300;
  const tableHeight = dataGridHeight - 10;

  const loadingView = () => (
    <LoaderWrapper>
      <ui.CenteredLoader label="Loading..." top="-150px" />
    </LoaderWrapper>
  );

  if (firstPrefLoad || !mappedColumns)
    return (
      <PreviewContainer ref={containerRef}>
        <LoadingContainer>
          <ui.CenteredLoader label="Loading table..." />
        </LoadingContainer>
      </PreviewContainer>
    );

  return (
    <PreviewContainer ref={containerRef}>
      {loading || (firstPrefLoad && loadingView())}
      <DataGridWrapper tableHeight={tableHeight} />
      {(loading || loadingPreferences) && (
        <mui.core.Box
          sx={{
            inlineSize: '180px',
            paddingBlock: '8px',
            paddingInline: '0.9rem',
            position: 'absolute',
            insetBlockEnd: '8px',
            insetInlineEnd: '8px',
            color: 'white',
            lineHeight: '35px',
            background: 'rgb(0 0 0 / 0.6)',
            marginBottom: '50px',
          }}
        >
          Loading ...
        </mui.core.Box>
      )}
    </PreviewContainer>
  );
};

const DataGridWrapper = ({ tableHeight }: { tableHeight: number }) => {
  const regularDataGridContext = React.useContext(RegularDataGridContext);
  const {
    alert,
    hideFilters,
    hideDensity,
    hideColumnWidth,
    hideToolbar,
    hideDownload,
    headerHeight,
    rowHeight,
    onRowClick,
    rowClass,
    overrideHeight,
    mappedColumns,
    pinnedColumns,
    filteredRows,
    sortColumns,
    setSortColumns,
    groupBy,
    expandedGroupIds,
    setExpandedGroupIds,
    handleColumnsResize,
    filterModel,
    setFilterModel,
    heatMappedCols,
    heatMapColors,
    customStyles,
    disableDefaultStyling,
    fixedWidth,
    setGroupBy,
    customToolbar,
  } = regularDataGridContext;

  const CustomToolbar = React.useMemo(
    () => () => (
      <mui.dataGrid.GridToolbarContainer>
        <Toolbar
          alert={alert}
          hideFilters={hideFilters}
          hideDensity={hideDensity}
          hideColumnWidth={hideColumnWidth}
          hideToolbar={hideToolbar}
          hideDownload={hideDownload}
          filteredRows={filteredRows}
          mappedColumns={mappedColumns}
          customToolbar={customToolbar}
          hideDataCount
        />
      </mui.dataGrid.GridToolbarContainer>
    ),
    [filteredRows, mappedColumns]
  );

  const apiRef = mui.dataGrid.useGridApiRef();

  const onLocalRowClick = React.useCallback<mui.dataGrid.GridEventListener<'rowClick'>>(
    (params) => {
      const rowNode = apiRef.current.getRowNode(params.id);

      if (rowNode && rowNode.type === 'group') {
        const newExpandedGroupIds = expandedGroupIds as Set<unknown>;

        if (!rowNode.childrenExpanded) newExpandedGroupIds.add(rowNode.groupingKey);
        else newExpandedGroupIds.delete(rowNode.groupingKey);
        setExpandedGroupIds(newExpandedGroupIds);

        apiRef.current.setRowChildrenExpansion(params.id, !rowNode.childrenExpanded);
      }

      if (onRowClick) onRowClick(params);
    },
    [apiRef, setExpandedGroupIds]
  );

  const initialState = mui.dataGrid.useKeepGroupedColumnsHidden({
    apiRef,
    initialState: {
      columns: {
        columnVisibilityModel: _.zipObject(groupBy, _.fill(Array(groupBy?.length), false)),
      },
      rowGrouping: {
        model: groupBy,
      },
      density: 'standard',
    },
  });

  const heatMapClasses = React.useMemo(() => {
    if (_.isEmpty(heatMappedCols)) return {};

    const localInterpolate = !heatMapColors
      ? () => helpers.interpolateColors({})
      : () => helpers.interpolateColors(heatMapColors);

    const newClasses = {} as Record<string, Record<string, string>>;
    localInterpolate().forEach((v, idx) => {
      newClasses[`& .heatmapclass-${idx}`] = {
        backgroundColor: `rgb(${v.join(',')})`,
      };
    });
    return newClasses;
  }, [heatMappedCols]);

  const assignToColorsBin = React.useMemo(() => {
    if (_.isEmpty(heatMappedCols)) return null;
    let maxVal = -Infinity;

    heatMappedCols.forEach((col) => {
      const rowVals = filteredRows.map((r: any) => r[col]).map((v: any) => Math.abs(v));
      maxVal = _.max([...rowVals, maxVal]);
    });

    return (value: number) =>
      helpers.assignValueToColorBin(value, {
        maxValue: maxVal,
        minValue: maxVal * -1,
        ...(heatMapColors ?? {}),
      });
  }, [filteredRows, heatMappedCols]);

  const filterPanel = React.useMemo(() => {
    return () => (
      <mui.core.Box
        sx={{
          '.MuiDataGrid-panelContent': {
            paddingTop: '4px',
          },
          '.MuiFormControl-root': {
            marginRight: '0.5rem',
          },
        }}
      >
        <mui.dataGrid.GridFilterPanel />
      </mui.core.Box>
    );
  }, []);

  const [openGroupSort, setOpenGroupSort] = React.useState(false);

  const CustomColumnMenu = React.useCallback(
    (props: any) => {
      const { hideMenu } = props;

      const CustomReorderItem = () => (
        <>
          {groupBy.length > 1 && (
            <mui.core.MenuItem
              onClick={() => {
                setOpenGroupSort(true);
                hideMenu();
              }}
            >
              <mui.core.ListItemIcon>
                <mui.icons.Reorder fontSize="small" />
              </mui.core.ListItemIcon>
              <mui.core.ListItemText>Reorder Groups</mui.core.ListItemText>
            </mui.core.MenuItem>
          )}
        </>
      );

      return (
        <mui.dataGrid.GridColumnMenu
          {...props}
          slots={{
            columnMenuColumnsItem: null,
            columnMenuFilterItem: mui.dataGrid.GridColumnMenuFilterItem,
            columnMenuSortItem: null,
            columnMenuGroupingItem: mui.dataGrid.GridColumnMenuGroupingItem,
            columnMenuReorderItem: CustomReorderItem,
            columnMenuPinningItem: null,
            columnMenuHideItem: null,
            columnMenuManageItem: null,
            columnMenuAggregationItem: null,
          }}
          slotProps={{
            columnMenuReorderItem: {
              displayOrder: 99,
            },
          }}
        />
      );
    },
    [groupBy]
  );

  const sortableGroups = React.useMemo(
    () =>
      groupBy.map((field) => {
        const column = mappedColumns.find((col) => col.field === field);
        return {
          name: column?.headerName || field,
          key: field,
        };
      }),
    [groupBy, mappedColumns]
  );

  const handleSaveGroupOrder = (orderedGroups: typeof sortableGroups) => {
    const newGroupBy = orderedGroups.map((group) => group.key);
    setGroupBy(newGroupBy);
  };

  return (
    <>
      <ui.FinDataGrid
        apiRef={apiRef}
        rows={filteredRows || []}
        columns={(mappedColumns || []) as mui.dataGrid.GridColDef[]}
        pinnedColumns={pinnedColumns}
        onRowClick={onLocalRowClick}
        initialState={initialState}
        rowGroupingModel={groupBy}
        onRowGroupingModelChange={(newModel) => {
          setGroupBy(newModel);
        }}
        groupingColDef={{
          hideDescendantCount: true,
          headerName: 'Group',
          width: mappedColumns.find((c) => c.field == groupBy[0])?.width,
          renderCell: (params) => {
            const rowNode = params.rowNode as any;
            const currCol = mappedColumns.find((col) => col.field === rowNode.groupingField);
            if (!currCol) {
              return null;
            }

            if (currCol.renderCell) {
              return currCol.renderCell(params);
            }

            const paddingLeft = rowNode.depth * 15;
            const ArrowIcon = rowNode.childrenExpanded ? mui.icons.KeyboardArrowDown : mui.icons.KeyboardArrowRight;

            return (
              <div style={{ display: 'flex', alignItems: 'center', paddingLeft }}>
                <ArrowIcon sx={{ fontSize: '1rem' }} />
                {currCol?.headerName || currCol?.name
                  ? `${currCol.headerName || currCol.name}: ${params.value}`
                  : params.value}
              </div>
            );
          },
        }}
        defaultGroupingExpansionDepth={-1}
        isGroupExpandedByDefault={(node) =>
          groupBy.includes(node.groupingField) && expandedGroupIds?.has(node.groupingKey)
        }
        rowHeight={rowHeight}
        columnHeaderHeight={headerHeight}
        filterModel={filterModel}
        onFilterModelChange={(newFilterModel) => setFilterModel(newFilterModel)}
        sortModel={sortColumns}
        onSortModelChange={(newSortColumns) => setSortColumns(newSortColumns)}
        disableColumnResize={fixedWidth?.fill}
        onColumnWidthChange={(params) => {
          let colKey = params.colDef.field;

          // On groups we need to update the width of the first group column
          if (params.colDef.headerName == 'Group') colKey = groupBy[0];
          handleColumnsResize(colKey, params.width);
        }}
        getRowClassName={rowClass}
        slots={{
          toolbar: CustomToolbar,
          columnsManagement: () => <></>,
          filterPanel: filterPanel,
          columnMenu: CustomColumnMenu,
        }}
        sx={{
          height: overrideHeight ? overrideHeight : tableHeight,
          ...heatMapClasses,
          ...customStyles,
        }}
        className={disableDefaultStyling ? '' : 'data-grid-default'}
        showCellVerticalBorder
        showColumnVerticalBorder
        hideFooter
        hideFooterPagination
        hideFooterRowCount
        disableColumnReorder
        disableRowSelectionOnClick
        cellSelection
        defaultBackgroundColor
        getCellClassName={(params: mui.dataGrid.GridCellParams) => {
          if (heatMappedCols.includes(params.field)) {
            return `heatmapclass-${assignToColorsBin(params.row[params.field] as number)}`;
          }
        }}
      />
      <ui.ItemsSortModal
        open={openGroupSort}
        setOpen={setOpenGroupSort}
        title="Groups"
        items={sortableGroups}
        saveItemsOrder={handleSaveGroupOrder}
        disableRemove
      />
    </>
  );
};

export default CustomDataGrid;
