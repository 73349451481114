import React from 'react';

import * as enums from '../../enums';
import { pluralize, routes } from '../../helpers';
import { useClipboard } from '../../hooks/use-clipboard-copy';
import { mui, useSelector } from '../../libs';
import * as types from '../../types';
import { BaseStore } from '../../types';

type ShareActionUrlProps = {
  resource: types.common.ResourceDraft;
  resourceType?: enums.RESOURCES_TYPES_ENUM;
};

const ShareActionUrl: React.FC<ShareActionUrlProps> = ({ resource, resourceType }): React.ReactElement => {
  const theme = mui.styles.useTheme() as mui.core.Theme;
  const subdomain = useSelector((s: BaseStore) => s.auth.organization.subdomain);

  const clipboard = useClipboard();

  return (
    <mui.core.DialogContent style={{ paddingTop: 0 }}>
      <mui.core.Box mb={4}>
        <mui.core.Typography color="textSecondary" variant="body2">
          Share this link with all the users you are sharing the resource with.
        </mui.core.Typography>
      </mui.core.Box>

      <mui.core.Stack
        flexDirection="row"
        gap={2}
        sx={{
          position: 'relative',
        }}
      >
        <mui.core.IconButton
          size="small"
          sx={{
            position: 'absolute',
            left: '12px',
            top: '14px',
            zIndex: '1',
          }}
          onClick={clipboard.copy}
        >
          <mui.icons.FileCopyOutlined
            style={{
              fontSize: '0.9rem',
              color: (theme.palette as any).primary.main,
            }}
          />
        </mui.core.IconButton>
        <input
          ref={clipboard.target}
          value={`${routes.getOrgPath(subdomain)}/${pluralize.plural(resourceType)}/${resource.id}`}
          style={{
            background: (theme.palette as any).background.default,
            border: `1px solid ${(theme.palette as any).gray.main}`,
            borderRadius: 4,
            height: 47,
            width: '100%',
            fontSize: '0.8rem',
            color: (theme.palette as any).secondary.main,
            paddingLeft: '2.5rem',
            marginTop: '2px',
          }}
        />
      </mui.core.Stack>
    </mui.core.DialogContent>
  );
};

export default ShareActionUrl;
