import { React, ts, ui } from '_core';

type RiskModelFieldProps = {
  dataset: ts.types.signal.DatasetDraft;
  setDataset: (_d: ts.types.signal.DatasetDraft) => void;
  isPreference?: boolean;
};

const RiskModelField: React.FC<RiskModelFieldProps> = ({ dataset, setDataset, isPreference }) => {
  return (
    <ui.ResourceAutocomplete<ts.types.common.ResourceExpanded>
      enableNone
      value={dataset.risk_model_id}
      setValue={(id) =>
        setDataset({
          ...dataset,
          risk_model_id: id,
          id: isPreference ? dataset?.id : null,
        })
      }
      type={ts.enums.RESOURCES_TYPES_ENUM.RISK_MODEL}
      inputProps={{
        fullWidth: true,
        label: 'Risk Model',
        variant: 'outlined',
        size: 'small',
      }}
    />
  );
};

export default RiskModelField;
