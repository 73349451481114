import { custom, helpers, ts } from '_core';

const float = helpers.tableFormatters.formatTo('float', { align: 'right', roundDigits: 3, commasOnThousands: true });

export default {
  [ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.MAIN]: [
    {
      key: 'build_date',
      name: 'Date',
      filter: 'date',
      formatter: helpers.tableFormatters.formatTo('date', { align: 'right', format: 'YYYY-MM-DD' }),
    },
    {
      key: 'asset',
      name: 'Asset',
      formatter: helpers.tableFormatters.formatTo('string', { align: 'left' }),
      filter: 'string',
    },
    {
      key: 'alpha_exposure',
      name: 'Alpha Exposure',
      formatter: float,
      filter: 'number',
    },
    {
      key: 'transfer_coefficient',
      name: 'Transfer Coefficient',
      formatter: float,
      filter: 'number',
    },
    {
      key: 'market',
      name: 'Market',
      formatter: float,
      filter: 'number',
    },
    {
      key: 'name',
      name: 'Name',
      formatter: helpers.tableFormatters.formatTo('string', { align: 'left' }),
      filter: 'string',
    },
    {
      key: 'asset_type',
      name: 'Type',
      formatter: helpers.tableFormatters.formatTo('string', { align: 'left' }),
      filter: 'string',
    },
    {
      key: 'ticker',
      name: 'Ticker',
      formatter: helpers.tableFormatters.formatTo('string', { align: 'left' }),
      filter: 'string',
    },
    {
      key: 'rbics_l1_id',
      name: 'Sector Code',
      formatter: helpers.tableFormatters.formatTo('integer', { align: 'right' }),
      filter: 'string',
    },
    {
      key: 'rbics_l2_id',
      name: 'Sub-Sector Code',
      formatter: helpers.tableFormatters.formatTo('integer', { align: 'right' }),
      filter: 'string',
    },
    {
      key: 'rbics_l3_id',
      name: 'Industry Code',
      formatter: helpers.tableFormatters.formatTo('integer', { align: 'right' }),
      filter: 'string',
    },
    {
      key: 'rbics_l4_id',
      name: 'Sub-Industry Code',
      formatter: helpers.tableFormatters.formatTo('integer', { align: 'right' }),
      filter: 'string',
    },
    {
      key: 'rbics_l1_name',
      name: 'Sector Name',
      formatter: helpers.tableFormatters.formatTo('string', { align: 'left' }),
      filter: 'string',
    },
    {
      key: 'rbics_l2_name',
      name: 'Sub-Sector Name',
      formatter: helpers.tableFormatters.formatTo('string', { align: 'left' }),
      filter: 'string',
    },
    {
      key: 'rbics_l3_name',
      name: 'Industry Name',
      formatter: helpers.tableFormatters.formatTo('string', { align: 'left' }),
      filter: 'string',
    },
    {
      key: 'rbics_l4_name',
      name: 'Sub-Industry Name',
      formatter: helpers.tableFormatters.formatTo('string', { align: 'left' }),
      filter: 'string',
    },
    {
      key: 'cusip',
      name: 'Cusip',
      formatter: helpers.tableFormatters.formatTo('string', { align: 'left' }),
      filter: 'string',
      condition: (features: ts.types.organization.OrganizationFeatures) => !features.hide_cusip_col,
    },
    {
      key: 'isin',
      name: 'ISIN',
      formatter: helpers.tableFormatters.formatTo('string', { align: 'left' }),
      filter: 'string',
      condition: (features: ts.types.organization.OrganizationFeatures) => !features.hide_isin_col,
    },
    {
      key: 'currency',
      name: 'Currency',
      formatter: helpers.tableFormatters.formatTo('string', { align: 'center' }),
      filter: 'string',
    },
    {
      key: 'issuer_country',
      name: 'Issuer Country',
      formatter: helpers.tableFormatters.formatTo('string', { align: 'center' }),
      filter: 'string',
    },
    {
      key: 'quotation_country',
      name: 'Quotation Country',
      formatter: helpers.tableFormatters.formatTo('string', { align: 'center' }),
      filter: 'string',
    },
    {
      key: '_handle_', // Dynamic
      name: 'Style Factors',
      formatter: float,
      filter: 'inferred',
      members: (key) => key.startsWith('_handle_'),
      cleanNameFormatter: (key, _c, resources: ts.StoreState['resources']) => {
        const handle = key.split('_handle_')[1];
        const signal = resources.signals.find((s) => s.handle === handle);
        return `Exposure to Risk Factors: ${signal?.name ?? handle}`;
      },
      nameFormatter: (key, _c, resources: ts.StoreState['resources']) => {
        const handle = key.split('_handle_')[1];
        const signal = resources.signals.find((s) => s.handle === handle);
        return <custom.MultilineTableHeader name={['Exposure to Risk Factors:', signal?.name ?? handle]} />;
      },
    },
    {
      key: '_industry_', // Dynamic
      name: 'Industry Factors',
      formatter: float,
      filter: 'inferred',
      members: (key) => key.startsWith('_industry_'),
      cleanNameFormatter: (key) => `Exposure to Industry Factors: ${key.split('_industry_')[1]}`,
      nameFormatter: (key) => (
        <custom.MultilineTableHeader name={['Exposure to Industry Factors:', key.split('_industry_')[1]]} />
      ),
    },
    {
      key: '_alpha_exposures_', // Dynamic
      name: 'Alpha Factor Exposures',
      formatter: float,
      filter: 'inferred',
      members: (key) => key.startsWith('_alpha_exposures_'),
      cleanNameFormatter: (key) => `Exposure to Alpha Signals: ${key.split('_alpha_exposures_')[1]}`,
      nameFormatter: (key) => (
        <custom.MultilineTableHeader name={['Exposure to Alpha Signals:', key.split('_alpha_exposures_')[1]]} />
      ),
    },
    {
      key: '_alpha_weight_', // Dynamic
      name: 'Alpha Weight',
      formatter: float,
      filter: 'inferred',
      members: (key) => key.startsWith('_alpha_weight_'),
      cleanNameFormatter: (key) => `Weight: ${key.split('_alpha_weight_')[1]}`,
      nameFormatter: (key) => <custom.MultilineTableHeader name={['Weight:', key.split('_alpha_weight_')[1]]} />,
    },
  ] as ts.types.components.dataGrid.ColumnsData,
};
