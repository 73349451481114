/* eslint-disable no-case-declarations */
import * as types from '@local/finsera-core/src/types';

import { DELETE_RESOURCE_TYPE_BY_ID, LOAD_RESOURCES, UPDATE_RESOURCE_TYPE_BY_ID } from '../actions/types';

export const INITIAL_STATE = {
  backtests: [] as types.backtest.Backtest[],
  backtest_sets: [] as types.backtestSet.BacktestSet[],
  baskets: [] as types.basket.Basket[],
  currencies: [] as types.currency.Currency[],
  data_sources: [] as types.dataSource.StoreDataSource[],
  general_analyses: [] as types.analysis.GeneralAnalysis[],
  gics_data: {} as types.industries.GicsData,
  help_items: [] as types.helpItem.HelpItem[],
  labels: [] as types.label.Label[],
  pipelines: [] as types.pipeline.Pipeline[],
  signal_datasets: [] as {
    id: number;
    signal_id: number;
    is_preferred: true;
    ws_is_latest: Record<number, boolean>;
  }[],
  rbics_data: {} as types.industries.RBicsData,
  report_defaults: [] as types.analysis.ReportDefault[],
  risk_models: [] as types.riskModel.RiskModel[],
  signals: [] as types.signal.Signal[],
  signal_sets: [] as types.signalSet.SignalSet[],
  strategies: [] as types.strategy.Strategy[],
  published_organizations: [] as types.organization.PublishedOrg[],
  portfolios: [] as types.portfolio.Portfolio[],
  time_series: [] as types.timeSeries.TimeSeries[],
  universes: [] as types.universe.Universe[],
  unsubscribed_data_sources: [] as types.dataSource.StoreDataSource[],
  users: [] as types.user.User[],
  fixed_costs: [] as types.fixedCosts.FixedCost[],
  workspaces: [] as types.workspace.Workspace[],
};

export type ResourcesType = typeof INITIAL_STATE;
export type LoadResourcesType = {
  type: typeof LOAD_RESOURCES;
  payload: Partial<typeof INITIAL_STATE>;
};

export type UpdateResourceTypeByIdType = {
  type: typeof UPDATE_RESOURCE_TYPE_BY_ID;
  resourceType:
    | 'signals'
    | 'signal_sets'
    | 'time_series'
    | 'universes'
    | 'baskets'
    | 'backtests'
    | 'strategies'
    | 'backtest_sets'
    | 'workspaces'
    | 'pipelines'
    | 'labels'
    | 'data_sources'
    | 'general_analyses'
    | 'fixed_costs'
    | 'risk_models'
    | 'report_defaults'
    | 'signal_datasets'
    | 'portfolios'
    | 'help_items';
  data: types.common.Resource;
};

export type DeleteResourceTypeByIdType = {
  type: typeof DELETE_RESOURCE_TYPE_BY_ID;
  resourceType: 'report_defaults' | 'workspaces' | 'signal_datasets' | 'general_analyses' | 'help_items';
  data: { id: number } & types.common.ResourceDraft;
};

type ActionType = LoadResourcesType | UpdateResourceTypeByIdType | DeleteResourceTypeByIdType;

export default (state = INITIAL_STATE, action: ActionType) => {
  switch (action.type) {
    case LOAD_RESOURCES:
      return { ...state, ...(action as LoadResourcesType).payload };
    case UPDATE_RESOURCE_TYPE_BY_ID:
      const type = (action as UpdateResourceTypeByIdType).resourceType;
      let resources = state[type] as types.common.Resource[];
      const { id } = (action as UpdateResourceTypeByIdType).data;
      let found = false;
      resources = resources.map((r) => {
        if (r.id != id) return r;
        found = true;
        return (action as UpdateResourceTypeByIdType).data;
      }) as types.common.Resource[];
      if (!found) (resources as types.common.Resource[]).push((action as UpdateResourceTypeByIdType).data);
      return { ...state, ...{ [type]: resources } };
    case DELETE_RESOURCE_TYPE_BY_ID:
      const typeDel = action.resourceType;
      let resourcesDel = state[typeDel] as types.common.Resource[];
      const idDel = (action as DeleteResourceTypeByIdType).data.id;
      resourcesDel = resourcesDel.filter((rs) => rs.id !== idDel);

      return {
        ...state,
        ...{ [typeDel]: resourcesDel },
      };

    default:
      return state;
  }
};
