import { ts } from '_core';

import { INDUSTRY_SELECTION_DETAILS_INTERACTION_PARAMS, IndustrySelectionDetailsDraftParams } from './types';

export default [
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.BASKET_TEST,
    title: 'Portfolios and Holdings (Debug)',
    form: false,
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.BASKET],
    neededFiles: ['holdings', 'portfolios'],
    interactionParams: Object.values(ts.types.widgets.common.DEBUG_FILES_INTERACTION_PARAMS),
    defaultParams: {
      // eslint-disable-next-line max-len
      text: 'This widget triggers holdings and portfolio files so you can download them with the download raw files widget.',
      isBasket: true,
    } as ts.types.widgets.common.DebugFilesParams,

    development: true,
    helpLabel: 'fuodpvl4',
  },
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.BACKTEST_TEST,
    title: 'Portfolios and Holdings (Debug)',
    form: false,
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.BACKTEST],
    neededFiles: ['holdings', 'portfolios'],
    interactionParams: Object.values(ts.types.widgets.common.DEBUG_FILES_INTERACTION_PARAMS),
    defaultParams: {
      // eslint-disable-next-line max-len
      text: 'This widget triggers holdings and portfolio files so you can download them with the download raw files widget.',
      isBasket: false,
    } as ts.types.widgets.common.DebugFilesParams,

    development: true,
    helpLabel: 't08dqt1p',
  },
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.RISK_MODEL_INDUSTRY_SELECTION_DETAILS,
    title: 'Industry Selection Details (Debug)',
    form: false,
    tags: [
      ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.RISK_MODEL,
      ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.COMMON_RISK_MODEL,
      ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.STATISTICAL_RISK_MODEL,
    ],
    neededFiles: ['industry_clusters_count_analysis'],
    interactionParams: Object.values(INDUSTRY_SELECTION_DETAILS_INTERACTION_PARAMS),
    defaultParams: {
      // eslint-disable-next-line max-len
      text: 'This widget triggers industry_clusters_count_analysis file so you can download it with the download raw files widget.',
    } as IndustrySelectionDetailsDraftParams,

    development: true,
    helpLabel: 'rmuav3e8',
  },
] satisfies ts.types.analysis.WidgetConfig[];
