import { ts } from '_core';
/* eslint-disable no-unused-vars */

export enum INTERACTION_PARAMS {
  TABLE_PARAMS = 'table_params',
  TABLE_DTYPES = 'table_dtypes',
  COMPONENT_ERRORS = 'component_errors',
}

export type InteractionParams = {
  [INTERACTION_PARAMS.TABLE_PARAMS]: ts.types.components.dataGrid.TableParamsSheet;
  [INTERACTION_PARAMS.TABLE_DTYPES]: ts.types.components.dataGrid.TableDtypesSheet;
  [INTERACTION_PARAMS.COMPONENT_ERRORS]: string[];
};

export type FileParams = {
  start_date: string;
  end_date: string;
};

export type Params = FileParams & InteractionParams;
export type DraftParams = Partial<Params>;
