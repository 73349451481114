import { ts } from '_core';

import { getName, validate } from './helpers';
import { DraftParams } from './types';

export default [
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.SIGNAL_SET_CUMULATIVE_RETURNS_WITH_BENCHMARK,
    title: 'Cumulative Returns With Benchmark',
    helpLabel: 'pwfgkib8',
    form: true,
    neededFiles: ['signal_set_cumulative_returns'],
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.SIGNAL_SET],

    defaultParams: {
      daily_reporting: true,
      return_horizons: [{ periods: { frequency: 'DAILY', amount: 1 }, signature: '1 Day Return' }],
      histories: Object.values(ts.enums.PERIOD_ENUM),
    } as DraftParams,
    getName,
    validate,
  },
] satisfies ts.types.analysis.WidgetConfig[];
