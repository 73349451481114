import { _, api, helpers, ts } from '_core';

export const createThread = async (
  service: ts.types.aiAssistant.ThreadService,
  initialThreadId: number,
  onSuccess: (_c: ts.types.aiAssistant.Conversation) => void,
  errorCallback: (_err: string) => void
) => {
  try {
    if (initialThreadId) {
      return loadConversation(initialThreadId, onSuccess, errorCallback);
    }

    const thread = await api.assistant_threads.create({
      service,
      stream: true,
    });

    onSuccess({
      ...thread['data'],
      messages: [],
    });
  } catch (err) {
    errorCallback(helpers.parseApiError(err as any));
  }
};

export const loadConversation = async (
  id: number,
  onSuccess: (_c: ts.types.aiAssistant.Conversation) => void,
  errorCallback: (_err: string) => void
) => {
  const promises = [api.assistant_threads.get(id), api.assistant_messages.search({ query: ['thread_id', '=', id] })];

  const [threadResp, messageResp] = await Promise.all(promises);

  const data = {
    ...(threadResp['data'] as ts.types.aiAssistant.Thread),
    messages: messageResp['data'] as ts.types.aiAssistant.Message[],
  };

  if (_.some(messageResp.data as ts.types.aiAssistant.Message[], (m) => m.purpose == 'error')) {
    errorCallback('Something happened. Try again later...');
  }

  onSuccess(data);
  return data;
};

export const sendMessage = async (
  text: string,
  conversation: ts.types.aiAssistant.Conversation,
  setConversation: (_c: ts.types.aiAssistant.Conversation) => void,
  onSuccess: () => void,
  errorCallback: (_err: string) => void
) => {
  try {
    const newMessage = {
      thread_id: conversation.id,
      role: 'user',
      purpose: 'default',
      content: { text },
    } as ts.types.aiAssistant.MessageDraft;

    setConversation({
      ...conversation,
      status: 'busy',
      messages: [
        ...conversation.messages,
        {
          id: -1,
          ...newMessage,
        } as ts.types.aiAssistant.Message,
      ],
    });

    await api.assistant_messages.create(newMessage);
    onSuccess();
  } catch (err) {
    errorCallback(helpers.parseApiError(err as any));
  }
};
