/* eslint-disable no-unused-vars */
export enum INTERACTION_PARAMS {
  STATISTIC_TYPE = 'statisticType',
}

export type InteractionParams = {
  [INTERACTION_PARAMS.STATISTIC_TYPE]: 'r2' | 'regression' | 'tStatistic' | 'pValue';
};

export type JobParams = {
  overall_stats?: boolean;
};

export type Params = JobParams & InteractionParams;
export type DraftParams = Partial<Params>;
