import { PREFERENCES_INNER_TABS_ENUM, PREFERENCES_TABS_ENUM } from '@local/finsera-core/src/enums';

export default {
  [PREFERENCES_TABS_ENUM.BACKTEST_SETS]: [PREFERENCES_INNER_TABS_ENUM.BACKTEST_SET_SUMMARY_COLS],
  [PREFERENCES_TABS_ENUM.BACKTESTS]: [
    PREFERENCES_INNER_TABS_ENUM.BACKTEST_PREVIEW_COLS,
    PREFERENCES_INNER_TABS_ENUM.BACKTEST_DATE_COMPARISON,
    PREFERENCES_INNER_TABS_ENUM.BACKTEST_PREVIEW_ALPHA_EXPOSURES_COLS,
    PREFERENCES_INNER_TABS_ENUM.BACKTEST_CONSTRAINTS_COLS,
    PREFERENCES_INNER_TABS_ENUM.BACKTEST_SUMMARY_AND_DETAILS_COLS,
    PREFERENCES_INNER_TABS_ENUM.BACKTEST_PORTFOLIO_RISK_COLS,
    PREFERENCES_INNER_TABS_ENUM.BACKTEST_PERFORMANCE_ATTRIBUTION_CONTRIBUTOR_COLS,
  ],
  [PREFERENCES_TABS_ENUM.BASKETS]: [
    PREFERENCES_INNER_TABS_ENUM.BASKET_PREVIEW_COLS,
    PREFERENCES_INNER_TABS_ENUM.BASKET_DATE_COMPARISON,
    PREFERENCES_INNER_TABS_ENUM.BASKET_CONSTRAINTS_COLS,
    PREFERENCES_INNER_TABS_ENUM.BASKET_SUMMARY_AND_DETAILS_COLS,
    PREFERENCES_INNER_TABS_ENUM.BASKET_PORTFOLIO_RISK_COLS,
    PREFERENCES_INNER_TABS_ENUM.BASKET_COMPARE_SUMMARY_STATS_COLS,
    PREFERENCES_INNER_TABS_ENUM.BASKET_PERFORMANCE_ATTRIBUTION_CONTRIBUTOR_COLS,
  ],
  [PREFERENCES_TABS_ENUM.PORTFOLIOS]: [
    PREFERENCES_INNER_TABS_ENUM.PORTFOLIOS_HOLDINGS_COLS,
    PREFERENCES_INNER_TABS_ENUM.PORTFOLIO_SUMMARY_AND_DETAILS_COLS,
    PREFERENCES_INNER_TABS_ENUM.PORTFOLIO_CONSTRAINTS_COLS,
    PREFERENCES_INNER_TABS_ENUM.PORTFOLIO_TRADE_LIST_COLS,
    PREFERENCES_INNER_TABS_ENUM.PORTFOLIO_TRADE_LIST_SUMMARY_COLS,
    PREFERENCES_INNER_TABS_ENUM.PORTFOLIO_TRADE_LIST_HOLDINGS_COLS,
    PREFERENCES_INNER_TABS_ENUM.PORTFOLIO_TRADE_LIST_BUCKET_EXPOSURE_COLS,
    PREFERENCES_INNER_TABS_ENUM.PORTFOLIO_TRADE_LIST_FACTOR_EXPOSURE_COLS,
  ],
  [PREFERENCES_TABS_ENUM.RISK_MODELS]: [
    PREFERENCES_INNER_TABS_ENUM.RISK_MODEL_PREVIEW_COLS,
    PREFERENCES_INNER_TABS_ENUM.RISK_MODEL_ASSET_PERTINENCE_PREVIEW_COLS,
  ],
  [PREFERENCES_TABS_ENUM.SIGNALS]: [
    PREFERENCES_INNER_TABS_ENUM.SIGNAL_PREVIEW_COLS,
    PREFERENCES_INNER_TABS_ENUM.SIGNAL_DATE_COMPARISON_COLS,
    PREFERENCES_INNER_TABS_ENUM.SIGNAL_DATASET_FORM,
  ],
  [PREFERENCES_TABS_ENUM.SIGNAL_SETS]: [PREFERENCES_INNER_TABS_ENUM.SIGNAL_SET_SUMMARY_STATS_COLS],
  [PREFERENCES_TABS_ENUM.TIME_SERIES]: [
    PREFERENCES_INNER_TABS_ENUM.TIME_SERIES_PREVIEW_COLS,
    PREFERENCES_INNER_TABS_ENUM.TIME_SERIES_COMPARE_SUMMARY_STATS_COLS,
  ],
  [PREFERENCES_TABS_ENUM.UNIVERSES]: [
    PREFERENCES_INNER_TABS_ENUM.UNI_ASSET_PERTINENCE_COLS,
    PREFERENCES_INNER_TABS_ENUM.UNI_DATE_COMPARISON_COLS,
    PREFERENCES_INNER_TABS_ENUM.UNI_SNAPSHOT_COLS,
    PREFERENCES_INNER_TABS_ENUM.UNI_LINKED_ASSETS_COLS,
  ],
  [PREFERENCES_TABS_ENUM.REPORT_LAYOUTS]: [
    PREFERENCES_INNER_TABS_ENUM.UNI_TEMPLATE,

    PREFERENCES_INNER_TABS_ENUM.SIGNAL_TEMPLATE,
    PREFERENCES_INNER_TABS_ENUM.SIGNAL_PREVIEW_TEMPLATE,
    PREFERENCES_INNER_TABS_ENUM.SIGNAL_ATTRIBUTION_TEMPLATE,

    PREFERENCES_INNER_TABS_ENUM.ALPHA_MODEL_TEMPLATE,

    PREFERENCES_INNER_TABS_ENUM.BACKTEST_TEMPLATE,
    PREFERENCES_INNER_TABS_ENUM.BACKTEST_PREVIEW_TEMPLATE,
    PREFERENCES_INNER_TABS_ENUM.BACKTEST_PORTFOLIO_RISK_TEMPLATE,
    PREFERENCES_INNER_TABS_ENUM.BACKTEST_ATTRIBUTION_TEMPLATE,

    PREFERENCES_INNER_TABS_ENUM.TIME_SERIES_TEMPLATE,

    PREFERENCES_INNER_TABS_ENUM.BASKET_TEMPLATE,
    PREFERENCES_INNER_TABS_ENUM.BASKET_PREVIEW_TEMPLATE,
    PREFERENCES_INNER_TABS_ENUM.BASKET_ATTRIBUTION_TEMPLATE,
    PREFERENCES_INNER_TABS_ENUM.BASKET_COMPARE_TEMPLATE,
    PREFERENCES_INNER_TABS_ENUM.BASKET_PORTFOLIO_RISK_TEMPLATE,

    PREFERENCES_INNER_TABS_ENUM.BACKTEST_SET_TEMPLATE,

    PREFERENCES_INNER_TABS_ENUM.SIGNAL_SET_TEMPLATE,
    PREFERENCES_INNER_TABS_ENUM.SIGNAL_SET_REPORT_TEMPLATE,

    PREFERENCES_INNER_TABS_ENUM.RISK_MODEL_TEMPLATE,

    PREFERENCES_INNER_TABS_ENUM.REBALANCE_PORTFOLIO_RISK_TEMPLATE,
    PREFERENCES_INNER_TABS_ENUM.REBALANCE_PORTFOLIO_TRADELIST_TEMPLATE,
  ],
} as Record<PREFERENCES_TABS_ENUM, PREFERENCES_INNER_TABS_ENUM[]>;
