import { ts } from '_core';

import { getName, validate } from './helpers';
import { DraftParams } from './types';

export default [
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.BASKET_COMPARE_ANNUALIZED_RETURN,
    title: 'Annualized Returns',
    form: true,
    helpLabel: '9wf75bgg',
    defaultParams: {
      statistics_type: 'return',
      histories: ['1 Year', '3 Years', '5 Years', 'Full', 'Year to Date'],
      compare: true,
    } as DraftParams,
    neededFiles: ['backtest_annualized_statistics'],
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.BASKET_COMPARE],

    validate,
    getName,
  },
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.BASKET_COMPARE_ANNUALIZED_RISK,
    title: 'Annualized Risk',
    form: true,
    helpLabel: 'dx31i08b',
    defaultParams: {
      statistics_type: 'risk',
      histories: ['1 Year', '3 Years', '5 Years', 'Full', 'Year to Date'],
      compare: true,
    } as DraftParams,
    neededFiles: ['backtest_annualized_statistics'],
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.BASKET_COMPARE],

    validate,
    getName,
  },
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.BASKET_COMPARE_ANNUALIZED_ADJUSTED,
    title: 'Annualized Risk Adjusted Returns',
    form: true,
    helpLabel: '93j0jj2e',
    defaultParams: {
      statistics_type: 'adjusted',
      histories: ['1 Year', '3 Years', '5 Years', 'Full', 'Year to Date'],
      compare: true,
    } as DraftParams,
    neededFiles: ['backtest_annualized_statistics'],
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.BASKET_COMPARE],

    validate,
    getName,
  },
] satisfies ts.types.analysis.WidgetConfig[];
