import { custom, helpers, ts } from '_core';

const dollar = helpers.tableFormatters.formatTo('float', {
  align: 'right',
  roundDigits: 2,
  commasOnThousands: true,
  numericPrefix: '$',
});
const floatf = helpers.tableFormatters.formatTo('float', { align: 'right', roundDigits: 3, commasOnThousands: true });
const percentf = helpers.tableFormatters.formatTo('float', {
  align: 'right',
  roundDigits: 2,
  commasOnThousands: true,
  suffix: '%',
  multiplier: 100,
});
const intf = helpers.tableFormatters.formatTo('integer', { align: 'right', commasOnThousands: true });
const stringf = helpers.tableFormatters.formatTo('string', { align: 'left' });

export default {
  [ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.PORTFOLIO_TABULAR_DETAILS]: [
    {
      key: 'start_date',
      name: 'Start Date',
      filter: 'date',
      formatter: helpers.tableFormatters.formatTo('date', { align: 'right', format: 'YYYY-MM-DD' }),
    },
    {
      key: 'end_date',
      name: 'End Date',
      filter: 'date',
      formatter: helpers.tableFormatters.formatTo('date', { align: 'right', format: 'YYYY-MM-DD' }),
    },
    {
      key: 'returns',
      name: 'Returns',
      formatter: percentf,
      filter: 'number',
    },
    {
      key: 'gross_leverage',
      name: 'Gross Leverage',
      formatter: floatf,
      filter: 'number',
    },
    {
      key: 'nav',
      name: 'NAV',
      formatter: dollar,
      filter: 'number',
    },
    {
      key: 'cash_value',
      name: 'Cash Value',
      formatter: dollar,
      filter: 'number',
    },
    {
      key: 'long_value',
      name: 'Long Value',
      formatter: dollar,
      filter: 'number',
    },
    {
      key: 'short_value',
      name: 'Short Value',
      formatter: dollar,
      filter: 'number',
    },
    {
      key: 'net_leverage',
      name: 'Net Leverage',
      formatter: floatf,
      filter: 'number',
    },
    {
      key: 'cash_weight',
      name: 'Cash Weight',
      formatter: floatf,
      filter: 'number',
    },
    {
      key: 'alpha_exposure',
      name: 'Alpha Exposure',
      formatter: floatf,
      filter: 'number',
    },
    {
      key: 'annualized_forecast_risk',
      name: 'Annualized Forecast Risk',
      formatter: floatf,
      filter: 'number',
    },
    {
      key: 'universe_size',
      name: 'Universe Size',
      formatter: intf,
      filter: 'number',
    },

    {
      key: 'long_position_count',
      name: 'Long Position Count',
      formatter: intf,
      filter: 'number',
    },
    {
      key: 'short_position_count',
      name: 'Short Position Count',
      formatter: intf,
      filter: 'number',
    },
    {
      key: 'benchmark_returns',
      name: 'Benchmark Returns',
      formatter: percentf,
      filter: 'number',
    },
    {
      key: 'active_returns',
      name: 'Active Returns',
      formatter: percentf,
      filter: 'number',
    },
    {
      key: 'annualized_active_risk_forecast',
      name: 'Annualized Active Risk Forecast',
      formatter: percentf,
      filter: 'number',
    },
    {
      key: 'alpha_correlation',
      name: 'Aplha Correlation',
      formatter: floatf,
      filter: 'number',
    },
    {
      key: 'cp_correlation',
      name: 'CP Correlation',
      formatter: floatf,
      filter: 'number',
    },
    {
      key: 'total_turnover',
      name: 'Total Turnover',
      formatter: floatf,
      filter: 'number',
    },
  ] as ts.types.components.dataGrid.ColumnsData,
  [ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.PORTFOLIO_TABULAR_YEARLY_SUMMARY]: [
    {
      key: 'start_date',
      name: 'Start Date',
      filter: 'date',
      formatter: helpers.tableFormatters.formatTo('date', { align: 'right', format: 'YYYY-MM-DD' }),
    },
    {
      key: 'end_date',
      name: 'End Date',
      filter: 'date',
      formatter: helpers.tableFormatters.formatTo('date', { align: 'right', format: 'YYYY-MM-DD' }),
    },
    {
      key: 'year',
      name: 'Year',
      filter: 'string',
      formatter: stringf,
    },
    {
      key: 'cumulative_returns',
      name: 'Cumulative Returns',
      formatter: percentf,
      filter: 'number',
    },
    {
      key: 'annualized_returns',
      name: 'Annualized Returns',
      formatter: percentf,
      filter: 'number',
    },
    {
      key: 'annualized_benchmark_returns',
      name: 'Annualized Benchmark Returns',
      formatter: percentf,
      filter: 'number',
    },
    {
      key: 'average_gross_leverage',
      name: 'Average Gross Leverage',
      formatter: floatf,
      filter: 'number',
    },
    {
      key: 'average_net_leverage',
      name: 'Average Net Leverage',
      formatter: floatf,
      filter: 'number',
    },
    {
      key: 'average_cash_weight',
      name: 'Average Cash Weight',
      formatter: floatf,
      filter: 'number',
    },
    {
      key: 'average_alpha_correlation',
      name: 'Average Alpha Correlation',
      formatter: floatf,
      filter: 'number',
    },
    {
      key: 'average_cp_correlation',
      name: 'Average CP Correlation',
      formatter: floatf,
      filter: 'number',
    },
    {
      key: 'average_annualized_forecast_risk',
      name: 'Average Annualized Forecast Risk',
      formatter: floatf,
      filter: 'number',
    },
    {
      key: 'cumulative_benchmark_returns',
      name: 'Cumulative Benchmark Returns',
      formatter: floatf,
      filter: 'number',
    },
    {
      key: 'average_turnover',
      name: 'Average Turnover',
      formatter: floatf,
      filter: 'number',
    },
    {
      key: 'average_universe_size',
      name: 'Average Universe Size',
      formatter: floatf,
      filter: 'number',
    },
    {
      key: 'average_long_position_count',
      name: 'Average Long Position Count',
      formatter: floatf,
      filter: 'number',
    },
    {
      key: 'average_short_position_count',
      name: 'Average Short Position Count',
      formatter: floatf,
      filter: 'number',
    },
  ] as ts.types.components.dataGrid.ColumnsData,
  [ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.PORTFOLIO_TABULAR_MONTHLY_SUMMARY]: [
    {
      key: 'start_date',
      name: 'Start Date',
      filter: 'date',
      formatter: helpers.tableFormatters.formatTo('date', { align: 'right', format: 'YYYY-MM-DD' }),
    },
    {
      key: 'end_date',
      name: 'End Date',
      filter: 'date',
      formatter: helpers.tableFormatters.formatTo('date', { align: 'right', format: 'YYYY-MM-DD' }),
    },
    {
      key: 'month',
      name: 'Month',
      filter: 'number',
      formatter: stringf,
    },
    {
      key: 'cumulative_returns',
      name: 'Cumulative Returns',
      formatter: percentf,
      filter: 'number',
    },
    {
      key: 'average_gross_leverage',
      name: 'Average Gross Leverage',
      formatter: floatf,
      filter: 'number',
    },
    {
      key: 'average_net_leverage',
      name: 'Average Net Leverage',
      formatter: floatf,
      filter: 'number',
    },
    {
      key: 'average_cash_weight',
      name: 'Average Cash Weight',
      formatter: floatf,
      filter: 'number',
    },
    {
      key: 'average_alpha_correlation',
      name: 'Average Alpha Correlation',
      formatter: floatf,
      filter: 'number',
    },
    {
      key: 'average_cp_correlation',
      name: 'Average CP Correlation',
      formatter: floatf,
      filter: 'number',
    },
    {
      key: 'average_annualized_forecast_risk',
      name: 'Average Annualized Forecast Risk',
      formatter: floatf,
      filter: 'number',
    },
    {
      key: 'cumulative_benchmark_returns',
      name: 'Cumulative Benchmark Returns',
      formatter: floatf,
      filter: 'number',
    },
    {
      key: 'average_turnover',
      name: 'Average Turnover',
      formatter: floatf,
      filter: 'number',
    },
    {
      key: 'average_universe_size',
      name: 'Average Universe Size',
      formatter: floatf,
      filter: 'number',
    },
    {
      key: 'average_long_position_count',
      name: 'Average Long Position Count',
      formatter: floatf,
      filter: 'number',
    },
    {
      key: 'average_short_position_count',
      name: 'Average Short Position Count',
      formatter: floatf,
      filter: 'number',
    },
  ] as ts.types.components.dataGrid.ColumnsData,
  [ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.PORTFOLIO_TABULAR_ALPHA_DETAILS]: [
    {
      key: 'build_date',
      name: 'Date',
      filter: 'date',
      formatter: helpers.tableFormatters.formatTo('date', { align: 'right', format: 'YYYY-MM-DD' }),
    },
    {
      key: 'alpha_exposure',
      name: 'Alpha Exposure',
      formatter: floatf,
      filter: 'number',
    },
    {
      key: 'transfer_coefficient',
      name: 'Transfer Coefficient',
      formatter: floatf,
      filter: 'number',
    },
    {
      key: 'market',
      name: 'Market',
      formatter: floatf,
      filter: 'number',
    },
    {
      key: '_handle_', // Dynamic
      name: 'Risk Style Factors',
      formatter: floatf,
      filter: 'inferred',
      members: (key) => key.startsWith('_handle_'),
      cleanNameFormatter: (key, _c, resources: ts.StoreState['resources']) => {
        const handle = key.split('_handle_')[1];
        const signal = resources.signals.find((s) => s.handle === handle);
        return `Exposure to Risk Factors: ${signal?.name ?? handle}`;
      },
      nameFormatter: (key, _c, resources: ts.StoreState['resources']) => {
        const handle = key.split('_handle_')[1];
        const signal = resources.signals.find((s) => s.handle === handle);
        return <custom.MultilineTableHeader name={['Exposure to Risk Factors:', signal?.name ?? handle]} />;
      },
    },
    {
      key: '_industry_', // Dynamic
      name: 'Industry Factors',
      formatter: floatf,
      filter: 'inferred',
      members: (key) => key.startsWith('_industry_'),
      cleanNameFormatter: (key) => `Exposure to Industry Factors: ${key.split('_industry_')[1]}`,
      nameFormatter: (key) => (
        <custom.MultilineTableHeader name={['Exposure to Industry Factors:', key.split('_industry_')[1]]} />
      ),
    },
    {
      key: '_alpha_exposures_', // Dynamic
      name: 'Alpha Factor Exposures',
      formatter: floatf,
      filter: 'inferred',
      members: (key) => key.startsWith('_alpha_exposures_'),
      cleanNameFormatter: (key) => `Exposure to Alpha Signals: ${key.split('_alpha_exposures_')[1]}`,
      nameFormatter: (key) => (
        <custom.MultilineTableHeader name={['Exposure to Alpha Signals:', key.split('_alpha_exposures_')[1]]} />
      ),
    },
    {
      key: '_alpha_weights_', // Dynamic
      name: 'Alpha Weight',
      formatter: floatf,
      filter: 'inferred',
      members: (key) => key.startsWith('_alpha_weights_'),
      cleanNameFormatter: (key) => `Weight: ${key.split('_alpha_weights_')[1]}`,
      nameFormatter: (key) => <custom.MultilineTableHeader name={['Weight:', key.split('_alpha_weights_')[1]]} />,
    },
  ] as ts.types.components.dataGrid.ColumnsData,
};
