import { mui, React, ts, ui } from '_core';

type SignalProps = {
  value: ts.types.pipeline.NeutralizeDraft['factors'][0];
  setValue: (_value: ts.types.pipeline.NeutralizeDraft['factors'][0]) => void;
};

const Signal: React.FC<SignalProps> = ({ value, setValue }): React.ReactElement => {
  return (
    <mui.core.Stack gap={3}>
      <ui.ResourceAutocomplete<ts.types.signal.SignalExpanded, false, true>
        value={value.value}
        chooseHandle
        setValue={(handle) => setValue({ ...value, value: handle })}
        type="signal_based"
        inputProps={{
          label: 'Signal / Alpha Model',
          variant: 'outlined',
          size: 'small',
          required: true,
        }}
      />
      <ui.ResourceAutocomplete<ts.types.pipeline.PipelineExpanded>
        value={value.pipeline_id}
        setValue={(id) => {
          setValue({ ...value, pipeline_id: id });
        }}
        type={ts.enums.RESOURCES_TYPES_ENUM.PIPELINE}
        inputProps={{
          label: 'Pipeline',
          variant: 'outlined',
          size: 'small',
        }}
        enableNone
      />
      <ui.ResourceAutocomplete<ts.types.pipeline.PipelineExpanded>
        value={value.sp_pipeline_id}
        enableNone
        setValue={(id) => setValue({ ...value, sp_pipeline_id: id })}
        type="sp_pipeline"
        inputProps={{
          label: 'Portfolio Pipeline',
          variant: 'outlined',
          size: 'small',
        }}
      />
    </mui.core.Stack>
  );
};

export default Signal;
