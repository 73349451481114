import { _, hooks, mui, React, ts } from '_core';

import * as columns from 'views/preferences/columns-config';
import Tabular from 'views/report/tables/tabular';

import { Params } from './types';
import { getColumns } from '../../common/charts/utils';
import * as utils from '../portfolio-trade-list-summary/helpers';

type ChartProps = {
  id: string;
  readOnly: boolean;
  widgetKey: string;
  loadWidgetData: (_payload: ts.types.widgets.WidgetGetDataParams) => Promise<ts.types.widgets.WidgetGetDataResponse>;
  params: Params;
  setParams: (_p: Params) => void;
  fullScreen: boolean;
  goFullScreen: (_v: string) => void;
  exposure: string;
  queryParams?: { initial?: string };
  context?: ts.types.portfolio.PortfolioAnalysisContext;
};

const Chart: React.FC<ChartProps> = ({
  id,
  readOnly,
  widgetKey,
  loadWidgetData,
  params,
  exposure,
  goFullScreen,
  setParams,
  fullScreen,
}) => {
  const [error, setError] = React.useState<ts.types.common.ApiError>(null);
  const [data, setData] = React.useState<Record<string, ts.types.widgets.TableData>>();

  const [containerHeight, setContainerHeight] = React.useState(undefined);
  const { height } = hooks.useWindowDimensions();

  const container = React.createRef<HTMLDivElement>();

  React.useEffect(() => {
    if (container.current) {
      setContainerHeight(container.current.offsetHeight - 100);
    }
  }, [height]);

  const [expandedGroupIds, setExpandedGroupIds] = React.useState<Record<string, ReadonlySet<unknown>>>(
    params.expanded
      ? Object.fromEntries(Object.entries(params.expanded).map(([key, val]) => [key, new Set(val)]))
      : null
  );

  const [tableParams, setTableParams] = React.useState<ts.types.components.dataGrid.TableParamsSheet>(
    params.table_params
  );

  const debouncedParamsSave = React.useCallback(
    _.debounce(async (exp: typeof expandedGroupIds, tbP: ts.types.components.dataGrid.TableParamsSheet) => {
      setParams({
        ...params,
        table_params: { ...tbP },
        expanded: Object.fromEntries(Object.entries(exp).map(([key, val]) => [key, [...val] as string[]])),
      });
    }, 1_000),
    []
  );

  hooks.useEffectWithoutFirst(() => {
    debouncedParamsSave(expandedGroupIds, tableParams);
  }, [expandedGroupIds, tableParams]);

  const loadData = async () => {
    try {
      const resp = await loadWidgetData({
        data: { file: exposure },
      });
      if (resp) {
        const data = utils.prepareExposureData(resp.data);
        setData({
          exposure: data,
        });

        if (_.isEmpty(expandedGroupIds)) {
          const exposureLabels = new Set(data.map((x) => x.label));
          setExpandedGroupIds({ exposure: exposureLabels });
        }
      }
    } catch (err) {
      setError(err as ts.types.common.ApiError);
    }
  };

  React.useEffect(() => {
    if (exposure) loadData();
    return () => setData(null);
  }, [exposure]);

  return (
    <mui.core.Box
      id={id}
      key={widgetKey}
      sx={{
        height: 'calc(100% - 1px)',
        '& .rdg-light': { paddingBottom: '60px' },
      }}
      ref={container}
    >
      <Tabular<ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.EXPOSURE>
        data={data}
        getColumns={{
          [ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.EXPOSURE]: (exposureKeys, preferences) =>
            getColumns(
              exposureKeys,
              preferences?.[ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.EXPOSURE],
              columns.portfolioTradeListBucketExposureColumns[ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.EXPOSURE]
            ),
        }}
        sheetTitles={{
          [ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.EXPOSURE]: 'Bucket Exposure Summary',
        }}
        fullScreen={fullScreen}
        goFullScreen={goFullScreen}
        hideFilters
        alwaysShowBottom
        groupBy={{
          [ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.EXPOSURE]: ['label'],
        }}
        expandedGroupIds={expandedGroupIds}
        setExpandedGroupIds={{
          [ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.EXPOSURE]: (v) =>
            setExpandedGroupIds((exp) => ({
              ...exp,
              [ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.EXPOSURE]: v,
            })),
        }}
        preferences={{
          preferencesSourceWidgetId: id,
          preferenceKey: ts.enums.PREFERENCES_KEY_ENUM.PORTFOLIO_TRADE_LIST_BUCKET_EXPOSURE_COLS,
          preferenceTab: ts.enums.PREFERENCES_INNER_TABS_ENUM.PORTFOLIO_TRADE_LIST_BUCKET_EXPOSURE_COLS,
        }}
        tableParams={tableParams}
        setTableParams={{
          [ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.EXPOSURE]: (v) =>
            setTableParams((t) => ({
              ...t,
              [ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.EXPOSURE]: v,
            })),
        }}
        overrideHeight={containerHeight}
        triggerWidthChange={params.fullWidth}
        apiError={error}
        readOnly={readOnly}
      />
    </mui.core.Box>
  );
};

export default Chart;
