import { _, api, mui, React, ts, ui } from '_core';

type JobPathsInner = { [key: string]: string };
type DownloadObj = {
  key: string;
  path: string;
  resource: string;
  resourceId: number;
};

type DownloadFilesProps = {
  jobPaths: ts.types.job.JobPaths;
  jobId: number;
};

const BacktestSetPortfolios: React.FC<DownloadFilesProps> = ({ jobPaths, jobId }) => {
  const [downloading, setDownloading] = React.useState(false);
  const theme = mui.styles.useTheme() as mui.core.Theme;

  const paths = jobPaths || {};

  const downloadFile = (obj: DownloadObj) => {
    setDownloading(true);
    const s3FileObject = {} as Record<string, string>;
    s3FileObject[`${obj.key}`] = obj.path;
    api.s3
      .signPaths(s3FileObject, obj.resourceId, obj.resource)
      .then((signed: any) => {
        let url;
        if (obj.resourceId) url = signed.data[obj.resource][obj.resourceId][obj.key];
        else url = signed.data[obj.resource][obj.key];

        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', url);
        document.body.appendChild(link);
        link.click();
        setDownloading(false);
      })
      .catch((e: React.EventHandler<any>) => console.log(e));
  };

  const getDownloadObjects = (obj: ts.types.job.JobPaths, resource = 'jobs') => {
    let paths = [] as DownloadObj[];
    Object.keys(obj).forEach((fileKey) => {
      if (!_.isObject(obj[fileKey])) {
        paths.push({
          key: fileKey,
          path: (obj as JobPathsInner)[fileKey],
          resource,
          resourceId: resource == 'jobs' ? jobId : null,
        });
      } else {
        paths = [...paths, ...getDownloadObjects(obj[fileKey] as ts.types.job.JobPaths, fileKey)];
      }
    });
    return paths;
  };

  return (
    <>
      {
        // eslint-disable-next-line no-nested-ternary
        !_.isEmpty(paths) ? (
          getDownloadObjects(paths).map((obj) => (
            <>
              <mui.core.Link
                component="button"
                onClick={() => downloadFile(obj)}
                disabled={downloading}
                sx={{
                  display: 'flex',
                  width: '100%',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  background: '#fff',
                  padding: '0.65rem 0.5rem',
                  borderBottom: `1px solid ${theme.palette.gray.main}`,
                  transition: 'all ease-in-out 0.15s',
                  color: theme.palette.secondary.light,
                  fontSize: '0.7rem',
                  '&:hover': {
                    background: theme.palette.background.default,
                    textDecoration: 'none',
                  },
                }}
              >
                {`${obj.key}`}
                <mui.icons.CloudDownload
                  sx={{
                    fileIcon: {
                      fontSize: 12,
                      marginRight: '6px',
                      color: theme.palette.primary.main,
                    },
                  }}
                />
              </mui.core.Link>
            </>
          ))
        ) : (
          <mui.core.Box mt={5}>
            <ui.EmptyMessage>This widget needs to be added with other widgets that need files.</ui.EmptyMessage>
          </mui.core.Box>
        )
      }
    </>
  );
};

export default BacktestSetPortfolios;
