import { config, helpers, mui, React, ts, ui, useHistory, useSelector } from '_core';

import * as utils from './universe-utils';

type UniverseFormProps = {
  open: boolean;
  setOpen: (_open: boolean) => void;
};

const UniverseForm: React.FC<UniverseFormProps> = ({ open, setOpen }): React.ReactElement => {
  const universes = useSelector((state) => state.resources.universes as ts.types.universe.Universe[]);
  const workspaces = useSelector((state) => state.resources.workspaces as ts.types.workspace.Workspace[]);
  const currentWorkspaceId = useSelector((state) => state.ui.currentWorkspaceId);

  const history = useHistory();

  const [errorMessage, setErrorMessage] = React.useState('');
  const [name, setName] = React.useState('');
  const [handle, setHandle] = React.useState('');
  const [userProvided, setUserProvided] = React.useState(false);
  const [template, setTemplate] = React.useState<ts.types.universe.Universe>();
  const [loadingCreate, setLoadingCreate] = React.useState(false);
  const [workspace, setWorkspace] = React.useState(workspaces.find((w) => w.id == currentWorkspaceId) || workspaces[0]);

  // Create handle while typing name
  React.useEffect(() => {
    setHandle(helpers.createHandle(name, 'u'));
  }, [name]);

  const handleSubmit = async () => {
    setErrorMessage('');

    // Validation
    if (!name || !handle) {
      setErrorMessage('All attributes are required.');
      setLoadingCreate(false);
      return;
    }

    setLoadingCreate(true);

    const newUniverse = utils.definition.getNewUniverse(name, handle, template, userProvided);
    utils.apiProcedures.createUniverse(
      {
        ...newUniverse,
        user_provided: userProvided ? true : false,
      },
      async (data) => {
        if (workspace)
          await helpers.resourcesUtils.addToWorkspace(data.id, ts.enums.UI_RESOURCE_TYPE_ENUM.UNIVERSE, workspace);
        setOpen(false);
        history.push(`/universes/${data.id}/definition`);
      },
      (err) => {
        setErrorMessage(err);
        setLoadingCreate(false);
      }
    );
  };

  const formContent = () => (
    <mui.core.Box mt={3}>
      <mui.core.Box mb={4}>
        <mui.core.Grid container spacing={3}>
          <mui.core.Grid item xs={12} sm={6}>
            <mui.core.TextField
              autoFocus
              id="name"
              label="Universe name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              fullWidth
              variant="outlined"
              required
              size="small"
              inputProps={{
                autocomplete: 'off',
              }}
            />
          </mui.core.Grid>
          <mui.core.Grid item xs={12} sm={6}>
            <mui.core.TextField
              id="handle"
              label="Universe handle"
              value={handle}
              onChange={(e) => setHandle(e.target.value)}
              fullWidth
              variant="outlined"
              required
              size="small"
            />
          </mui.core.Grid>
        </mui.core.Grid>
      </mui.core.Box>
      {config.features.api_only_universe && (
        <mui.core.Box pb={4}>
          <mui.core.FormControlLabel
            control={
              <mui.core.Switch
                size="small"
                checked={userProvided}
                color="primary"
                onChange={() => setUserProvided(!userProvided)}
              />
            }
            label="API Only"
          />
        </mui.core.Box>
      )}
      {!userProvided && (
        <mui.core.Box pb={4}>
          <ui.ResourceAutocomplete<ts.types.universe.UniverseExpanded>
            enableNone
            value={template?.id}
            setValue={(id) => setTemplate(universes.find((u) => u.id == id))}
            type={ts.enums.RESOURCES_TYPES_ENUM.UNIVERSE}
            inputProps={{
              fullWidth: true,
              label: 'Template',
              variant: 'outlined',
              required: true,
              size: 'small',
            }}
            filter={(u) => !u.user_provided && !u.source_resource_id}
          />
        </mui.core.Box>
      )}
      <ui.WorkspaceSelector value={workspace} setValue={setWorkspace} />
    </mui.core.Box>
  );

  return (
    <mui.core.Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="sm"
      fullWidth
    >
      {errorMessage && (
        <mui.lab.Alert onClose={() => setErrorMessage('')} severity="error">
          {errorMessage}
        </mui.lab.Alert>
      )}
      <ui.DialogTitle closeAction={() => setOpen(false)}>Create Universe</ui.DialogTitle>
      <mui.core.DialogContent>{formContent()}</mui.core.DialogContent>
      <mui.core.DialogActions>
        <ui.Button
          variant="contained"
          color="primary"
          loading={loadingCreate}
          onClick={() => handleSubmit()}
          fullWidth
          style={{ margin: '0rem 1rem 1rem' }}
        >
          Create Universe
        </ui.Button>
      </mui.core.DialogActions>
    </mui.core.Dialog>
  );
};

export default UniverseForm;
