import React from 'react';

import { gibberishGenerator } from '../helpers';
import { $ } from '../libs';

type OnMountUnmountProps = {
  virtualDom: boolean;
  performOnMount: () => void;
  performOnUnmount: () => void;
};

/**
 * Component that triggers actions when a component is mounted/unmounted
 * 
 * @param virtualDom - Whether to use virtual DOM
 * @param performOnMount - Action to perform when the component is mounted
 * @param performOnUnmount - Action to perform when the component is unmounted
 * 
 * @returns Rendered component with mounted/unmounted actions
 */
const OnMountUnmount: React.FC<OnMountUnmountProps> = ({ virtualDom, performOnMount, performOnUnmount }) => {
  const componentId = React.useMemo(() => gibberishGenerator.stringGenerator(11), []);

  React.useEffect(() => {
    performOnMount();

    if (virtualDom) {
      return performOnUnmount;
    }

    $(`#${componentId}`).on('DOMNodeRemoved', function () {
      performOnUnmount();
    });
  }, []);

  return <span id={componentId} />;
};

OnMountUnmount.defaultProps = {
  virtualDom: false,
};

export default OnMountUnmount;
