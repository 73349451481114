import React from 'react';

import { datesFmt } from '../../data';
import * as hooks from '../../hooks';
import { Link, mui } from '../../libs';
import DialogTitle from '../dialog-title';
import DragDropFiles from '../drag-drop-files';

type UploadCsvModalProps = {
  close: () => void;
  processUpload: (_f: File, _format: string) => Promise<void>;
  loadingState: string;
  clearLoadingState: () => void;
  error?: string;
  open?: boolean;
  example?: string;
  instructions?: React.ReactElement;
  enableDateFormat: boolean;
  successPath?: string;
};

const UploadCsvModal: React.FC<UploadCsvModalProps> = ({
  open,
  close,
  loadingState,
  processUpload,
  error,
  example,
  instructions,
  clearLoadingState,
  enableDateFormat,
  successPath,
}): React.ReactElement => {
  const uiStyles = hooks.useUiStyles();
  const theme = mui.styles.useTheme() as mui.core.Theme;

  const [file, setFile] = React.useState<File>();
  const [dateFormat, setDateFormat] = React.useState('%Y-%m-%d');

  const renderFileForm = () => (
    <>
      {example && (
        <mui.core.Box>
          <mui.core.Box mb={1}>
            <mui.core.Typography
              variant="caption"
              fontWeight="fontWeightBold"
              sx={uiStyles.uppercase}
              color="textSecondary"
            >
              Example
            </mui.core.Typography>
          </mui.core.Box>
          <mui.core.TextField
            placeholder="Expression"
            multiline
            rows={3}
            maxRows={6}
            fullWidth
            value={example}
            disabled
            variant="outlined"
          />
        </mui.core.Box>
      )}

      <mui.core.Box my={3}>
        {file ? (
          <mui.core.Box display="flex">
            <mui.core.Box sx={uiStyles.fileBox} mr={2}>
              <mui.core.Typography variant="body1" style={{ marginRight: '1rem' }}>
                {file.name}
              </mui.core.Typography>
              <mui.core.IconButton
                size="small"
                onClick={() => {
                  setFile(null);
                }}
              >
                <mui.icons.Close style={{ fontSize: '1rem' }} />
              </mui.core.IconButton>
            </mui.core.Box>

            <mui.core.Box flexGrow={1} mt={2}>
              <mui.core.Box display="flex">
                {enableDateFormat && (
                  <mui.core.TextField
                    select
                    label="Date format"
                    value={dateFormat}
                    onChange={(e) => setDateFormat(e.target.value)}
                    size="small"
                    variant="outlined"
                  >
                    {Object.keys(datesFmt).map((key) => (
                      <mui.core.MenuItem key={key} value={key}>
                        <mui.core.Box pr={2}>{datesFmt[key as keyof typeof datesFmt]}</mui.core.Box>
                      </mui.core.MenuItem>
                    ))}
                  </mui.core.TextField>
                )}
                <mui.core.Box flexGrow={1} ml={2}>
                  <mui.core.Button
                    variant="contained"
                    color="primary"
                    component="span"
                    disabled={!file}
                    onClick={() => processUpload(file, dateFormat)}
                  >
                    Upload file
                  </mui.core.Button>
                </mui.core.Box>
              </mui.core.Box>
            </mui.core.Box>
          </mui.core.Box>
        ) : (
          <DragDropFiles setFile={setFile} />
        )}
      </mui.core.Box>
    </>
  );

  const renderProgress = () => {
    if (loadingState == 'loading')
      return (
        <mui.core.Box mb={3} sx={uiStyles.uploadBox} textAlign="center">
          <mui.core.Typography variant="body2" style={{ marginBottom: '0.66rem' }}>
            Please do not close your browser until the upload finishes
          </mui.core.Typography>
          <mui.core.Box mb={2}>
            <mui.core.CircularProgress />
          </mui.core.Box>
        </mui.core.Box>
      );

    return (
      <mui.core.Box mb={3} sx={uiStyles.uploadBox} textAlign="center">
        <mui.core.Box mb={3}>
          {error ? (
            <mui.icons.Error style={{ color: (theme.palette as any).danger.main }} />
          ) : (
            <mui.icons.CheckCircle style={{ color: (theme.palette as any).success.main }} />
          )}
          <mui.core.Typography variant="body1" fontWeight="fontWeightBold">
            {error ? (
              <>
                <mui.core.Box>{error}</mui.core.Box>
              </>
            ) : (
              'Your data has been submitted.'
            )}
          </mui.core.Typography>
          {!error && (
            <mui.core.Typography variant="body2">
              Please check the logs to follow the state of the created submissions.
            </mui.core.Typography>
          )}
        </mui.core.Box>
        <mui.core.Box display="flex" justifyContent="center">
          <mui.core.Box mr={2}>
            {!error && successPath && (
              <Link to={successPath}>
                <mui.core.Button size="small" variant="contained" color="primary">
                  View Logs
                </mui.core.Button>
              </Link>
            )}
          </mui.core.Box>
          <mui.core.Button onClick={clearLoadingState} size="small" color="primary">
            Go back to upload
          </mui.core.Button>
        </mui.core.Box>
      </mui.core.Box>
    );
  };

  return (
    <mui.core.Dialog open={open} onClose={close} fullWidth maxWidth="md">
      <DialogTitle closeAction={close}>Upload CSV</DialogTitle>
      <mui.core.DialogContent>
        {instructions && instructions}
        {loadingState == 'init' ? renderFileForm() : renderProgress()}
      </mui.core.DialogContent>
    </mui.core.Dialog>
  );
};

UploadCsvModal.defaultProps = {
  error: '',
  open: false,
  example: '',
  instructions: null,
};

export default UploadCsvModal;
