import { hooks, mui, React, ts, ui } from '_core';

import Bounds from '../bounds';
import SharedConstraintFields from '../shared-fields';

type AssetAllProps = {
  id: string;
  params: ts.types.optimizer.AssetAllDraft;
  setParams: (_params: ts.types.optimizer.AssetAllDraft) => void;
  readOnly: boolean;
  optimizerType: 'basket' | 'backtest';
  constraints: ts.types.optimizer.Constraint[];
  isLongOnly: boolean;
  validationDef: ts.types.common.ValidationErrors;
};

const AssetAllConstraint: React.FC<AssetAllProps> = ({
  id,
  params,
  setParams,
  readOnly,
  optimizerType,
  constraints,
  isLongOnly,
  validationDef,
}): React.ReactElement => {
  const [showMore, setShowMore] = React.useState(false);
  const [constraint, setConstraint] = React.useState(params);

  const semidisabled = React.useMemo(() => {
    if (optimizerType !== 'backtest' || !isLongOnly) return false;
    const firstAssetAll = constraints.find(
      (c) => c.constraint_type == ts.enums.OPTIMIZER_CONSTRAINT_TYPE_ENUM.ALL_ASSETS_CONSTRAINT
    );
    return firstAssetAll && firstAssetAll.id == id;
  }, []);

  React.useEffect(() => {
    if (optimizerType == 'basket' && params.benchmark_relative == null) {
      setConstraint({ ...constraint, benchmark_relative: true });
    }
  }, []);

  hooks.useEffectWithoutFirst(() => {
    setParams(constraint);
  }, [constraint]);

  return (
    <>
      <mui.core.Box p={3}>
        <mui.core.FormControlLabel
          control={
            <mui.core.Switch
              size="small"
              checked={constraint.benchmark_relative}
              onChange={() =>
                setConstraint({
                  ...constraint,
                  benchmark_relative: !constraint.benchmark_relative,
                })
              }
              disabled={readOnly || semidisabled}
            />
          }
          label={optimizerType == 'backtest' ? 'Benchmark Relative' : 'Relative to Target Weights'}
        />
      </mui.core.Box>
      <mui.core.Box p={2}>
        <Bounds
          params={constraint}
          setParams={setConstraint}
          isPercent
          disableLower={semidisabled}
          id={id}
          readOnly={readOnly}
          validationDef={validationDef}
        >
          {showMore && (
            <mui.core.Box mt={3}>
              <mui.core.Grid container spacing={4}>
                <mui.core.Grid item xs={12} md={6}>
                  <ui.NumericField
                    textFieldProps={{
                      label: 'Grand Father Lower Bound %',
                      fullWidth: true,
                      variant: 'outlined',
                      size: 'small',
                      error: validationDef?.fields?.includes(`grand_father_lower_bound_${id}`),
                    }}
                    disabled={readOnly || semidisabled}
                    suffix="%"
                    dividerOption={ts.enums.NUMBER_FORMATTERS_ENUM.HUNDRED}
                    value={constraint.grandfathered_lower_bound}
                    setValue={(v) =>
                      setConstraint({
                        ...constraint,
                        grandfathered_lower_bound: v,
                      })
                    }
                  />
                </mui.core.Grid>
                <mui.core.Grid item xs={12} md={6}>
                  <ui.NumericField
                    textFieldProps={{
                      label: 'Grand Father Upper Bound %',
                      fullWidth: true,
                      variant: 'outlined',
                      size: 'small',
                      error: validationDef?.fields?.includes(`grand_father_upper_bound_${id}`),
                    }}
                    disabled={readOnly}
                    suffix="%"
                    dividerOption={ts.enums.NUMBER_FORMATTERS_ENUM.HUNDRED}
                    value={constraint.grandfathered_upper_bound}
                    setValue={(v) =>
                      setConstraint({
                        ...constraint,
                        grandfathered_upper_bound: v,
                      })
                    }
                  />
                </mui.core.Grid>
              </mui.core.Grid>
              <SharedConstraintFields
                id={id}
                params={constraint}
                setParams={setConstraint}
                showRelaxation={false}
                disableAll={semidisabled}
                readOnly={readOnly}
                validationDef={validationDef}
              />
            </mui.core.Box>
          )}
        </Bounds>
      </mui.core.Box>
      <mui.core.Button
        onClick={() => {
          setShowMore(!showMore);
        }}
        color="primary"
      >
        {showMore ? (
          <>
            <mui.icons.Remove style={{ fontSize: '14px', marginRight: '0.5rem' }} />
            Show Less
          </>
        ) : (
          <>
            <mui.icons.Add style={{ fontSize: '14px', marginRight: '0.5rem' }} />
            Show More
          </>
        )}
      </mui.core.Button>
    </>
  );
};

export default AssetAllConstraint;
