/* eslint-disable prefer-const */
import { _, am4charts, am4core, helpers, ts } from '_core';

import { createImageHeader } from './create-image-header';
import { errorChartFormat } from './formatters/error-chart';

type CreateErrorChartProps = {
  id: string;
  data: ts.types.widgets.TableData;
  fullScreen: boolean;
  setShowLegend: (_b: boolean) => void;
  exportTitle: string;

  yAxisLabel?: string;
  xAxisLabel?: string;
  xAxis?: string;
  yAxis?: string;
  title?: string;
  showError?: boolean;
  percent?: boolean;
  bucket?: string;
  showBucketOnTooltip?: boolean;
  prepareData?: (_d: ts.types.widgets.ErrorLinearData) => ts.types.widgets.ErrorLinearData;
};

const createErrorChart = ({
  id,
  data,
  fullScreen,
  setShowLegend,
  exportTitle,
  yAxisLabel = '',
  xAxisLabel = '',
  xAxis = 'date',
  yAxis = 'value',
  title = null,
  showError = true,
  percent = true,
  bucket = undefined,
  showBucketOnTooltip = false,
  prepareData = undefined,
}: CreateErrorChartProps) => {
  let [errorData, minLimit, maxLimit] = errorChartFormat(
    data as ts.types.widgets.TableData,
    xAxis,
    yAxis,
    showError ? 'error' : undefined,
    bucket,
    !percent
  );

  // Prepare data
  if (prepareData) errorData = prepareData(errorData);

  const chart = am4core.create(`chart-${id}-${fullScreen ? 'fs' : 'sw'}`, am4charts.XYChart);
  chart.paddingRight = 20;
  chart.numberFormatter.numberFormat = '#.##';

  if (title) {
    const chartTitle = chart.titles.create();
    chartTitle.text = title;
    chartTitle.fontSize = 15;
    chartTitle.marginBottom = 20;
  }

  const categoryAxis = chart.xAxes.push(new am4charts.ValueAxis());

  categoryAxis.strictMinMax = true;
  categoryAxis.max = _.maxBy(Object.values(errorData)?.[0] || [], xAxis)?.[xAxis] ?? 0;
  categoryAxis.max += categoryAxis.max * 0.02;
  categoryAxis.min = _.minBy(Object.values(errorData)?.[0] || [], xAxis)?.[xAxis] ?? 0;
  categoryAxis.min += categoryAxis.min * 0.04;

  categoryAxis.renderer.minGridDistance = 60;
  categoryAxis.tooltip.fontSize = 11;
  categoryAxis.tooltip.background.fill = am4core.color('#3C5688');
  categoryAxis.tooltip.background.cornerRadius = 3;
  categoryAxis.tooltip.background.strokeWidth = 0;
  if (yAxisLabel) categoryAxis.title.text = yAxisLabel;

  const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
  if (!_.isNil(maxLimit)) valueAxis.max = maxLimit;
  if (!_.isNil(minLimit)) valueAxis.min = minLimit;

  valueAxis.tooltip.disabled = true;
  valueAxis.tooltip.fontSize = 11;
  valueAxis.tooltip.background.fill = am4core.color('#3C5688');
  valueAxis.tooltip.background.cornerRadius = 3;
  valueAxis.tooltip.background.strokeWidth = 0;
  valueAxis.renderer.minGridDistance = 40;
  if (xAxisLabel) valueAxis.title.text = xAxisLabel;

  const createSeries = (key: string, index: number) => {
    const series = chart.series.push(new am4charts.LineSeries());
    series.name = key;

    series.tooltip.fontSize = 11;

    series.yAxis = valueAxis;
    series.xAxis = categoryAxis;

    series.dataFields.valueX = xAxis;
    series.dataFields.valueY = yAxis;

    series.tooltipText = showBucketOnTooltip
      ? `{name} \nvalue: {valueY.value}${percent ? '%' : ''} \nerror: {error}${percent ? '%' : ''}`
      : `value: {valueY.value}${percent ? '%' : ''} \nerror: {error}${percent ? '%' : ''}`;
    series.fill = am4core.color(helpers.getColor(index));
    series.stroke = am4core.color(helpers.getColor(index));
    series.calculatePercent = percent;
    series.data = errorData[key];

    if (showError) {
      const errorBullet = series.bullets.create(am4charts.ErrorBullet);
      (errorBullet as any).isDynamic = true;
      errorBullet.strokeWidth = 2;

      const circle = (errorBullet as any).createChild(am4core.Circle);
      circle.radius = 3;
      circle.fill = am4core.color('#ffffff');

      // adapter adjusts height of a bullet
      errorBullet.adapter.add('pixelHeight', function (pixelHeight, target) {
        const dataItem = target.dataItem;

        if (dataItem) {
          const value = (dataItem as any).valueY;
          const errorTopValue = value + (dataItem.dataContext as any).error / 2;
          const errorTopY = valueAxis.valueToPoint(errorTopValue).y;

          const errorBottomValue = value - (dataItem.dataContext as any).error / 2;
          const errorBottomY = valueAxis.valueToPoint(errorBottomValue).y;

          return Math.abs(errorTopY - errorBottomY);
        }
        return pixelHeight;
      });
    }
  };

  let showLegendLocal = false;

  if (((Object.keys(errorData) ?? []).length <= 7 || fullScreen) && (Object.keys(errorData) ?? []).length > 1) {
    showLegendLocal = true;
    setShowLegend(true);
  } else {
    setShowLegend(false);
  }

  function resizeLegend() {
    if (document.getElementById(`legend-${id}-${fullScreen ? 'fs' : 'sw'}`))
      document.getElementById(`legend-${id}-${fullScreen ? 'fs' : 'sw'}`).style.height =
        chart.legend.contentHeight + 10 + 'px';
  }

  if (showLegendLocal) {
    const legendContainer = am4core.create(`legend-${id}-${fullScreen ? 'fs' : 'sw'}`, am4core.Container);
    legendContainer.width = am4core.percent(100);
    legendContainer.height = am4core.percent(100);
    legendContainer.logo.disabled = true;

    chart.legend = new am4charts.Legend();
    chart.legend.parent = legendContainer;
    chart.legend.useDefaultMarker = true;

    chart.legend.maxHeight = fullScreen ? 100 : 44;
    chart.legend.scrollable = true;
    chart.legend.itemContainers.template.paddingTop = 1;
    chart.legend.itemContainers.template.paddingBottom = 1;
    chart.legend.markers.template.width = 10;
    chart.legend.markers.template.height = 10;
    chart.legend.markers.template.strokeWidth = 10;
    chart.legend.fontSize = 11;

    chart.events.on('datavalidated', resizeLegend);
    chart.events.on('maxsizechanged', resizeLegend);
    chart.legend.events.on('datavalidated', resizeLegend);
    chart.legend.events.on('maxsizechanged', resizeLegend);
    document.getElementById(`legend-${id}-${fullScreen ? 'fs' : 'sw'}`).style.height =
      chart?.legend?.contentHeight?.toString();

    // Add legend to the chart.
    chart.exporting.extraSprites.push(chart.legend);
  } else {
    document.getElementById(`legend-${id}-${fullScreen ? 'fs' : 'sw'}`).innerHTML = '';
  }

  Object.keys(errorData).forEach((key, idx) => {
    createSeries(key, idx);
  });

  chart.logo.disabled = true;

  chart.cursor = new am4charts.XYCursor();
  chart.cursor.maxTooltipDistance = 0;

  chart.exporting.menu = new am4core.ExportMenu();
  chart.exporting.menu.align = 'left';
  chart.fontSize = 11;

  chart.exporting.menu.items = [
    {
      label: '...',
      menu: [
        { type: 'jpg', label: 'JPG' },
        { type: 'png', label: 'PNG' },
        { type: 'csv', label: 'CSV' },
      ],
    },
  ];
  chart.exporting.filePrefix = 'chart-data';

  // Assemble data from series
  chart.exporting.adapter.add('data', () => {
    let exportData = [] as { [key: string]: any }[];

    if (Object.keys(errorData).length > 1)
      Object.entries(errorData).map(([key, value]) => {
        exportData = exportData.concat(value.map((v) => ({ category: key, ...v })));
      });
    else {
      exportData = exportData.concat(Object.values(errorData)[0].map((v) => ({ ...v })));
    }
    return { data: exportData };
  });

  createImageHeader({ chart, title: exportTitle, fullScreen, id });

  return chart;
};

export default createErrorChart;
