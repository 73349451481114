import { hooks, mui, React, ui } from '_core';

import { DraftParams } from './types';

type FormProps = {
  params: DraftParams;
  setParams: (_p: DraftParams) => void;
};

const Form: React.FC<FormProps> = ({ params, setParams }) => {
  const [benchmark, setBenchmark] = React.useState(params.benchmark || null);
  const [withBenchmark, setWithBenchmark] = React.useState(params.with_benchmark || false);

  const uiStyles = hooks.useUiStyles();

  React.useEffect(() => {
    const data = {
      ...params,
      with_benchmark: withBenchmark,
    } as DraftParams;

    if (withBenchmark) data.benchmark = benchmark;
    else if (data.benchmark) delete data.benchmark;

    setParams(data);
  }, [withBenchmark, benchmark]);

  return (
    <mui.core.Box sx={uiStyles.widgetEditMode}>
      <mui.core.Box mt={2}>
        <mui.core.FormControlLabel
          control={
            <mui.core.Switch size="small" checked={withBenchmark} onChange={() => setWithBenchmark(!withBenchmark)} />
          }
          label="Has benchmark"
        />
      </mui.core.Box>

      {withBenchmark && (
        <mui.core.Box mt={2}>
          <ui.Benchmark
            value={benchmark || undefined}
            setValue={setBenchmark}
            universeContextPath={'$universe.handle'}
            enableAnnualizedSignals
            universeIdPath="universe_id"
          />
        </mui.core.Box>
      )}
    </mui.core.Box>
  );
};

export default Form;
