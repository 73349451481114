import { _, ts } from '_core';

import { replaceNans } from './helpers';

export const nestedBarChartFormat = (
  data: ts.types.widgets.TableData,
  category: string
): ts.types.widgets.NestedBarWidgetData => {
  const countData: ts.types.widgets.CountData = {};
  const formattedData: ts.types.widgets.NestedBarData = [];

  data = replaceNans(data);

  data.forEach((item) => {
    if (item.type === 'count' || item.tab === 'count') {
      const categoryKey = item[category] as string;
      countData[categoryKey] = _.omit(item, [category, 'type', 'Unnamed: 0']);
    } else {
      formattedData.push({
        category: item[category] as string | number,
        ..._.omit(item, [category, 'type', 'Unnamed: 0']),
      });
    }
  });

  return { data: formattedData, count: countData };
};
