import { mui, React, ts } from '_core';

import Chart from './chart';
import { Params } from './types';

type PortfolioTradelistSummaryProps = {
  id: string;
  readOnly: boolean;
  widgetKey: string;
  context?: ts.types.portfolio.PortfolioAnalysisContext;
  loadWidgetData: (_payload: ts.types.widgets.WidgetGetDataParams) => Promise<ts.types.widgets.WidgetGetDataResponse>;
  params: Params;
  setParams: (_p: Params) => void;
  widgetPaths?: Record<'trade_list_rebalance_summary' | 'trade_list_trade_summary', string>;
  goFullScreen?: (_b: string) => void;
  fullScreen?: boolean;

  queryParams?: { initial?: string };
};

const PortfolioTradelistSummary: React.FC<PortfolioTradelistSummaryProps> = ({
  id,
  readOnly,
  widgetKey,
  loadWidgetData,
  params,
  setParams,
  widgetPaths,
  fullScreen,
  goFullScreen,
  queryParams,
  context,
}) => {
  return (
    <mui.core.Box sx={{ height: '100%' }}>
      <Chart
        id={id}
        readOnly={readOnly}
        widgetKey={widgetKey}
        loadWidgetData={loadWidgetData}
        rebalance={widgetPaths['trade_list_rebalance_summary']}
        trade={widgetPaths['trade_list_trade_summary']}
        fullScreen={fullScreen}
        queryParams={queryParams}
        goFullScreen={goFullScreen}
        context={context}
        params={params}
        setParams={setParams}
      />
    </mui.core.Box>
  );
};

export default PortfolioTradelistSummary;
