import { mui, React, ts, ui } from '_core';

import SignalDatasetForm from './signal-dataset-form';

type EditDatasetFormModalProps = {
  open: boolean;
  setOpen: (_val: boolean) => void;
  selectedDatasetDefault: ts.types.preferences.SIGNAL_DATASET_FORM;
  updateDatasetDefault: (_val: ts.types.preferences.SIGNAL_DATASET_FORM) => void;
};

const EditDatasetFormModal: React.FC<EditDatasetFormModalProps> = ({
  open,
  setOpen,
  selectedDatasetDefault,
  updateDatasetDefault,
}) => {
  const [localDataset, setLocalDataset] = React.useState<ts.types.preferences.SIGNAL_DATASET_FORM>();
  const [isValid, setIsValid] = React.useState(false);

  React.useEffect(() => {
    setLocalDataset(selectedDatasetDefault);
  }, [selectedDatasetDefault]);

  const handleClose = () => {
    setLocalDataset({});
    setOpen(false);
  };

  return (
    <mui.core.Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
      <mui.core.DialogTitle>Edit Dataset Default</mui.core.DialogTitle>
      <mui.core.DialogContent>
        <SignalDatasetForm
          selectedDatasetPreference={localDataset}
          updateSelectedDataset={setLocalDataset}
          setIsValid={setIsValid}
        />
      </mui.core.DialogContent>
      <mui.core.DialogActions>
        <ui.Button color="primary" variant="outlined" onClick={() => setOpen(false)}>
          Cancel
        </ui.Button>
        <ui.Button
          color="primary"
          variant="contained"
          disabled={!isValid}
          onClick={() => {
            updateDatasetDefault(localDataset);
            setOpen(false);
          }}
        >
          Confirm
        </ui.Button>
      </mui.core.DialogActions>
    </mui.core.Dialog>
  );
};

export default EditDatasetFormModal;
