import { React } from '_core';

import DataGrid from './data-grid';
import { ProviderProps, RegularDataGridContextProvider } from './regular-context';

const RegularDataGrid: React.FC<ProviderProps> = (props): React.ReactElement => {
  return (
    <RegularDataGridContextProvider {...props}>
      <DataGrid />
    </RegularDataGridContextProvider>
  );
};

export default RegularDataGrid;
