import { _, helpers, hooks, queryString, React, ts, useSelector } from '_core';

import { swap } from 'react-grid-dnd';

import widgets from '../report/widgets';
import { filterByTags, getOptions } from '../report/widgets/common/utils/report-widgets';

type ProviderProps = {
  onSave?: () => void;
  onClose?: () => void;

  children?: React.ReactNode;

  updateDefinition: (
    _d: ts.types.analysis.WidgetExtended[],
    _m: ts.enums.ANALYSIS_MODE_ENUM,
    _regenerate: boolean
  ) => Promise<void>;
  definition: ts.types.analysis.Widget[];
  definitionMode: ts.enums.ANALYSIS_MODE_ENUM;

  noDefault: boolean;
  onlyFullscreen?: boolean;

  noLayoutChanges?: boolean;

  layoutType: ts.enums.LAYOUT_TYPE;
  reportHelp?: {
    document: ts.enums.UI_RESOURCE_TYPE_ENUM;
    contentPath?: `/${string}`;
  };

  context?: ts.types.common.ResourceDraft;
  tags: ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM[];

  errorMessage?: string;

  triggerOnWidgetAction?: (_v: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM) => void;
  isGeneralAnalysis?: boolean;
  isAdhoc?: boolean;
  maxWidgetsPerPage: number;

  disableWidgetTitleEdit?: boolean;
};

type ManagerContextTypes = {
  managerState: ts.enums.REPORT_ENUMS.MANAGER_STATE_ENUM;
  setManagerState: (_m: ts.enums.REPORT_ENUMS.MANAGER_STATE_ENUM) => void;

  cards: ts.types.analysis.WidgetExtended[];
  setCards: (_c: ts.types.analysis.WidgetExtended[]) => void;
  moveCard: (_id: string, _si: number, _ti: number) => void;
  removeCard: (_id: string) => void;
  addCard: (_c: ts.types.analysis.WidgetConfig) => void;
  updateCardParameters: (_id: string, _p: Record<string, any>) => void;
  setReadyToValidate: () => void;

  mode: ts.enums.ANALYSIS_MODE_ENUM;
  setMode: (_m: ts.enums.ANALYSIS_MODE_ENUM) => void;

  handleSave: (_regenerate: boolean) => Promise<void>;
  saving: boolean;

  defaults: ts.types.analysis.ReportDefault[];
  noDefault: boolean;
  loadDefault: (_d: ts.types.analysis.ReportDefault) => Promise<void>;

  addOptions: ts.types.analysis.WidgetConfig[];

  formErrors: Record<string, any>;
  errorMessage: string;
  maxWidgetsPerPageError: boolean;

  noLayoutChanges: boolean;
  layoutType: ts.enums.LAYOUT_TYPE;
  onlyFullscreen: boolean;
  needsJob: boolean;

  onClose: () => void;
  context: ts.types.common.ResourceDraft;
  widgetConf: (_w: ts.types.analysis.Widget) => ts.types.analysis.WidgetConfig;
  tags: ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM[];
  validating: boolean;
  isGeneralAnalysis: boolean;
  reportHelp?: {
    document: ts.enums.UI_RESOURCE_TYPE_ENUM;
    contentPath?: `/${string}`;
  };

  triggerOnWidgetAction: (_v: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM) => void;
  isAdhoc?: boolean;
  maxWidgetsPerPage: number;

  disableWidgetTitleEdit: boolean;
};

const ManagerContext = React.createContext<ManagerContextTypes>(null);

// This context provider is passed to any component requiring the context
const Provider: React.FC<ProviderProps> = (props): React.ReactElement => {
  const {
    children,
    context,
    definition,
    definitionMode,
    errorMessage,
    isGeneralAnalysis,
    layoutType,
    noDefault,
    noLayoutChanges,
    onClose,
    onlyFullscreen,
    onSave,
    tags,
    triggerOnWidgetAction,
    updateDefinition,
    isAdhoc,
    maxWidgetsPerPage,
    disableWidgetTitleEdit,
    reportHelp,
  } = props;

  const queryParams = queryString.parse(location.search);

  const resources = useSelector((state) => state.resources);

  const layoutDefaults = useSelector(
    (state) => state.resources.report_defaults as unknown as ts.types.analysis.ReportDefault[]
  ).filter((x) => x.type == layoutType);

  const widgetConf = (widget: ts.types.analysis.Widget) => widgets.find((w) => w.key == widget.key);
  const widgetExist = (widget: ts.types.analysis.Widget) => !!widgetConf(widget);

  const defaults = React.useMemo(() => {
    const filteredDefaults = layoutDefaults.map((d) => ({
      ...d,
      definition: {
        ...d.definition,
        widgets: d.definition.widgets.filter((w) => widgetExist(w)),
      },
    }));

    return filteredDefaults;
  }, [layoutDefaults]);

  const getWidgetsFromDefinition = (): ts.types.analysis.WidgetExtended[] =>
    definition
      .filter((w) => widgetExist(w))
      .map((w) => ({ ...w, ...widgetConf(w) }))
      .map((w) => ({
        ...w,
        title: `${isGeneralAnalysis ? w.getGeneralAnalysisPrefix(w.params, resources) || '' : ''}${w.title}`,
      }));

  const [managerState, setManagerState] = React.useState<ts.enums.REPORT_ENUMS.MANAGER_STATE_ENUM>(
    ts.enums.REPORT_ENUMS.MANAGER_STATE_ENUM.EXPANDED
  );

  const [mode, setMode] = React.useState<ts.enums.ANALYSIS_MODE_ENUM>(
    onlyFullscreen
      ? ts.enums.ANALYSIS_MODE_ENUM.FULLSCREEN
      : isAdhoc
        ? ts.enums.ANALYSIS_MODE_ENUM.ADHOC
        : definitionMode
  );

  const [cards, setCards] = React.useState<ts.types.analysis.WidgetExtended[]>(getWidgetsFromDefinition);

  const [needsJob, setNeedsJob] = React.useState(false);

  const [saving, setSaving] = React.useState(false);
  const [formErrors, setFormErrors] = React.useState(queryParams.invalid ? { invalid: true } : {});
  const [maxWidgetsPerPageError, setMaxWidgetsPerPageError] = React.useState(cards.length > maxWidgetsPerPage);
  const [validating, setValidating] = React.useState(false);

  const addOptions = React.useMemo(() => {
    return getOptions(widgets, tags, mode, layoutType, isGeneralAnalysis);
  }, [mode]);

  const performReportValidation = (cards: ts.types.analysis.WidgetExtended[]) => {
    const validateEachWidget = () => {
      const widgetErrors = {} as Record<string, any>;

      cards.forEach((widgetDef: ts.types.analysis.WidgetExtended) => {
        const widgetVal = widgetDef.validate(widgetDef.params, context, isGeneralAnalysis, resources);
        if (!_.isEmpty(widgetVal)) {
          widgetErrors[widgetDef.id] = widgetVal;
        }
      });
      return widgetErrors;
    };

    setFormErrors(validateEachWidget());
    setMaxWidgetsPerPageError(cards.length > maxWidgetsPerPage);
    setValidating(false);
  };

  const debouncedValidate = React.useCallback(_.debounce(performReportValidation, 2000), [context]);

  // We are ready to validate as soon as the user interacts with the form
  // or when we get the invalid query parameter
  const readyToValidate = React.useRef(queryParams.invalid == 'true');
  hooks.useEffectWithoutN(
    () => {
      // everytime there is a change on the cards, we will set the form as valid and validate length
      // we will also check if we need jobs
      if (!readyToValidate.current) return;
      if (!_.isEmpty(cards)) {
        setValidating(true);
        debouncedValidate(cards);

        const newNeedsJob = cards.reduce((prev, curr) => prev || !!curr.neededFiles, false as boolean);
        setNeedsJob(newNeedsJob);
      } else {
        setNeedsJob(false);
      }
    },
    queryParams.invalid == 'true' && queryParams.firstRender != 'true' ? 2 : 1,
    [cards]
  );

  // Perform validation as soon as we enter into the form if we already know is invalid
  React.useEffect(() => {
    if (queryParams.invalid == 'true' && queryParams.firstRender != 'true') performReportValidation(cards);
  }, []);

  const updateCardParameters = (widgetId: string, params: Record<string, any>) => {
    setCards((cards) =>
      cards.map((w) => {
        if (widgetId == w.id) return { ...w, params };
        return w;
      })
    );
  };

  const moveCard = (sourceId: string, sourceIndex: number, targetIndex: number) => {
    const nextState = swap(cards, sourceIndex, targetIndex);
    setCards(nextState);
  };

  const removeCard = (widgetId: string) => {
    setCards((cards) => cards.filter((w) => w.id != widgetId));
  };

  const addCard = (card: ts.types.analysis.WidgetConfig) => {
    const newCard = {
      ...card,
      id: helpers.gibberishGenerator.stringGenerator(11),
    } as ts.types.analysis.WidgetExtended;
    if (newCard.defaultParams) newCard.params = { ...newCard.defaultParams };
    else newCard.params = {};

    setCards((cards) => cards.concat([newCard]));
    triggerOnWidgetAction(card.key);
  };

  const handleSave = async (regenerate: boolean) => {
    setSaving(true);
    try {
      await updateDefinition(cards, mode, regenerate);
      onSave();
      setSaving(false);
    } catch {
      console.log('Error when saving');
    }
  };

  const loadDefault = async (defaultOpt: ts.types.analysis.ReportDefault) => {
    const defaultDef = defaultOpt.definition;
    // First we set cards as empty to force rebuild of all child components on next setCards
    await setCards([]);
    const widgets = ((defaultDef?.widgets as ts.types.analysis.Widget[]) || [])
      .filter((c) => widgetConf(c))
      .map((c) => ({ ...c, ...widgetConf(c) }));

    // Filter by tag
    const filtered = filterByTags(tags, widgets) as ts.types.analysis.WidgetExtended[];

    setCards(filtered);
    setMode(defaultDef?.mode || ts.enums.ANALYSIS_MODE_ENUM.GRID);
  };

  return (
    <ManagerContext.Provider
      value={{
        addCard,
        addOptions,
        cards,
        context,
        defaults,
        errorMessage,
        formErrors,
        handleSave,
        isGeneralAnalysis,
        layoutType,
        loadDefault,
        managerState,
        maxWidgetsPerPageError,
        mode,
        moveCard,
        needsJob,
        noDefault,
        noLayoutChanges,
        onClose,
        onlyFullscreen,
        removeCard,
        saving,
        setCards,
        setManagerState,
        setMode,
        setReadyToValidate: () => (readyToValidate.current = true),
        tags,
        triggerOnWidgetAction,
        updateCardParameters,
        validating,
        widgetConf,
        isAdhoc,
        maxWidgetsPerPage,
        disableWidgetTitleEdit,
        reportHelp,
      }}
    >
      {children}
    </ManagerContext.Provider>
  );
};

const ManagerContextProvider = Provider;

Provider.defaultProps = {
  definition: [],
  definitionMode: ts.enums.ANALYSIS_MODE_ENUM.GRID,
  noDefault: false,
  onlyFullscreen: false,
  context: null,
  noLayoutChanges: false,
  onClose: null,
  layoutType: null,
  errorMessage: 'Fix widget errors to save report.',
  onSave: () => undefined,
  triggerOnWidgetAction: () => undefined,
  isGeneralAnalysis: false,
  disableWidgetTitleEdit: false,
};

export { ManagerContext };
export { ManagerContextProvider };
