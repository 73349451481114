import { _, hooks, mui, React, ts, ui, useSelector } from '_core';

import * as columns from 'views/preferences/columns-config';
import AsyncTabular from 'views/report/tables/async-tabular';

import { Params } from './types';
import { getColumns } from '../../common/charts/utils';

type ChartProps = {
  id: string;
  loadWidgetData: (_payload: ts.types.widgets.WidgetGetDataParams) => Promise<ts.types.widgets.WidgetGetDataResponse>;
  params: Params;
  setParams: (_p: Params) => void;
  fullScreen: boolean;
  goFullScreen: (_v: string) => void;
  context: ts.types.backtest.BacktestAnaylsisContext | ts.types.basket.BasketAnalysisContext;
  snapshot: string;
  queryParams?: { initial?: string };
};

const Chart: React.FC<ChartProps> = ({
  id,
  loadWidgetData,
  snapshot,
  fullScreen,
  goFullScreen,
  queryParams,
  params,
  setParams,
  context,
}) => {
  const isBasket = !!(context as ts.types.basket.BasketAnalysisContext).basket_id;
  const resources = useSelector((state) => state.resources);

  const uiStyles = hooks.useUiStyles();
  const container = React.createRef();

  const [show, setShow] = React.useState(ts.enums.BACKTEST_PREVIEW_MEMBERS_ENUM.SHOW_ALL);
  const [filter, setFilter] = React.useState<ts.types.components.dataGrid.ValueFilters>([]);

  const [tableParams, setTableParams] = React.useState<ts.types.components.dataGrid.TableParamsSheet>(
    params.table_params
  );

  const [tableDtypes, setTableDtypes] = React.useState<ts.types.components.dataGrid.TableDtypesSheet>(
    params.table_dtypes
  );

  const [periodPortfolioValue, setPeriodPortfolioValue] = React.useState('');

  const getPeriodPortfolioValue = (localData: ts.types.widgets.TableData) => {
    const value = localData.find((el) => el.period_portfolio_value)?.period_portfolio_value;

    if (value) {
      const localeValue = parseInt(value as string).toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
      setPeriodPortfolioValue(localeValue);
    }
  };

  hooks.useEffectWithoutFirst(() => {
    setParams({
      ...params,
      table_params: { ...tableParams },
      table_dtypes: { ...tableDtypes },
    });
  }, [tableParams, tableDtypes]);

  const loadData = async (
    offset: number,
    orderBy: ts.types.components.dataGrid.OrderBy,
    filters: ts.types.components.dataGrid.ValueFilters,
    logicOperator: mui.dataGrid.GridLogicOperator
  ) => {
    const response = await loadWidgetData({
      data: {
        file: snapshot,
        pagination: { offset, limit: 100 },
        order_by: orderBy,
        values_filter: filters,
        filter_conjunction: logicOperator,
      },
    });

    if (response) {
      getPeriodPortfolioValue(response?.data);
    }

    return response;
  };

  const downloadData = async (rename: Record<string, string>, columnOrder: string[]) => {
    const response = await loadWidgetData({
      data: {
        file: snapshot,
        file_name: 'snapshot.csv',
        rename,
        column_order: columnOrder,
      },
      download: true,
    });
    return response;
  };

  hooks.useEffectWithoutFirst(() => {
    if (show == ts.enums.BACKTEST_PREVIEW_MEMBERS_ENUM.EXCLUDE_ZEROES)
      setFilter([
        {
          column: 'main_initial',
          operator: '!=',
          value: 0,
          column_type: 'number',
          replace_nans_to: 0,
        },
        {
          column: 'main_optimal',
          operator: '!=',
          value: 0,
          column_type: 'number',
          replace_nans_to: 0,
        },
      ]);
    else setFilter([]);
  }, [show]);

  const preferencesButton = () => (
    <mui.core.Box display="flex" width="100%" sx={uiStyles.hidePrint}>
      <mui.core.Box flexGrow={1}>
        <mui.core.Box display="flex">
          <ui.InfoRow title="Snapshot For">{params.details_start_date}</ui.InfoRow>

          <mui.core.Box ml={2}>
            <mui.core.Divider orientation="vertical" />
          </mui.core.Box>
          {periodPortfolioValue && (
            <mui.core.Box ml={2}>
              <ui.InfoRow title="Period Portfolio Value">${periodPortfolioValue}</ui.InfoRow>
            </mui.core.Box>
          )}

          <mui.core.Box pl={6}>
            <mui.core.RadioGroup
              row
              name="members"
              value={show}
              onChange={(e) => {
                setShow(e.target.value as ts.enums.BACKTEST_PREVIEW_MEMBERS_ENUM);
              }}
              style={{ display: 'flex' }}
            >
              <mui.core.FormControlLabel
                value={ts.enums.BACKTEST_PREVIEW_MEMBERS_ENUM.SHOW_ALL}
                control={<mui.core.Radio color="primary" />}
                label="Show All"
                labelPlacement="end"
              />
              <mui.core.FormControlLabel
                value={ts.enums.BACKTEST_PREVIEW_MEMBERS_ENUM.EXCLUDE_ZEROES}
                control={<mui.core.Radio color="primary" />}
                label="Show Non Zero Holdings"
                labelPlacement="end"
              />
            </mui.core.RadioGroup>
          </mui.core.Box>
        </mui.core.Box>
      </mui.core.Box>
    </mui.core.Box>
  );

  const getClass = (row: ts.types.widgets.TableData[0]) => {
    if (parseFloat(row.main_optimal as string) > 0) return 'data-grid-row-green';
    if (parseFloat(row.main_optimal as string) < 0) return 'data-grid-row-red';
    return undefined;
  };

  const renderChart = () => (
    <>
      <AsyncTabular<ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.MAIN>
        key={snapshot}
        loadData={{
          [ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.MAIN]: loadData,
        }}
        getColumns={{
          [ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.MAIN]: (colKeys, userColumnsPreferences) =>
            getColumns(
              colKeys,
              userColumnsPreferences[ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.MAIN],
              isBasket
                ? columns.basketPreviewColumns[ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.MAIN]
                : columns.backtestPreviewColumns[ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.MAIN],
              context,
              resources
            ),
        }}
        sheetTitles={{
          [ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.MAIN]: 'Detailed View - Snapshot',
        }}
        sheetFilters={{
          [ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.MAIN]: filter,
        }}
        fullScreen={fullScreen}
        goFullScreen={goFullScreen}
        queryParams={queryParams}
        rowWidth={{ [ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.MAIN]: 120 }}
        rowHeight={{ [ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.MAIN]: 20 }}
        customToolBar={{
          [ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.MAIN]: preferencesButton,
        }}
        downloadFullData={{
          [ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.MAIN]: downloadData,
        }}
        preferences={{
          preferencesSourceWidgetId: id,
          preferenceKey: isBasket
            ? ts.enums.PREFERENCES_KEY_ENUM.BASKET_PREVIEW_COLS
            : ts.enums.PREFERENCES_KEY_ENUM.BACKTEST_PREVIEW_COLS,
          preferenceTab: isBasket
            ? ts.enums.PREFERENCES_INNER_TABS_ENUM.BASKET_PREVIEW_COLS
            : ts.enums.PREFERENCES_INNER_TABS_ENUM.BACKTEST_PREVIEW_COLS,
        }}
        tableParams={tableParams}
        setTableParams={{
          [ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.MAIN]: (v) =>
            setTableParams((t) => ({
              ...t,
              [ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.MAIN]: v,
            })),
        }}
        tableDtypes={tableDtypes}
        setTableDtypes={{
          [ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.MAIN]: (v) =>
            setTableDtypes((t) => ({
              ...t,
              [ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.MAIN]: v,
            })),
        }}
        rowClass={{
          [ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.MAIN]: getClass,
        }}
      />
    </>
  );

  return (
    <mui.core.Box display="flex" flexDirection="column" style={{ height: '100%' }} ref={container}>
      {!_.isNil(snapshot) && renderChart()}
    </mui.core.Box>
  );
};

export default Chart;
