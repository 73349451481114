/* eslint-disable no-restricted-syntax */
import { _, ts } from '_core';

import { hasDuplicateStyleFactors } from './filters-procedures';

function formatFilterId(filterId: string) {
  const parts = filterId.split(' ');
  const formattedId = _.toLower(parts[0]);
  return formattedId;
}

export const _boundsValidationsErrors = (bounds: number[], percentile: boolean, filterId: string, type?: string) => {
  const errors: string[] = [];
  const warnings: string[] = [];
  const fields: ts.types.pipeline.PipelineErrorFields[] = [];

  const formattedId = type ? formatFilterId(filterId) + type : formatFilterId(filterId);
  const formattedName = type ? `${filterId}/${type} Bound` : filterId;

  if (_.isNil(bounds?.[0]) && _.isNil(bounds?.[1])) {
    errors.push(`${formattedName} - You need at least one bound.`);
    fields.push(`lower_bound_${formattedId}`);
    fields.push(`upper_bound_${formattedId}`);
  }

  if (bounds?.[1] && bounds?.[0] && bounds[1] < bounds[0]) {
    errors.push(`${formattedName} - Upper Bound must be greater or equal than Lower Bound`);
    fields.push(`upper_bound_${formattedId}`);
  }

  if (percentile) {
    if (!_.isNil(bounds?.[1]) && (bounds[1] > 1 || bounds[1] < 0)) {
      errors.push(`${formattedName} - Upper Bound must be between 0 and 100`);
      fields.push(`upper_bound_${formattedId}`);
    }

    if (!_.isNil(bounds?.[0]) && (bounds[0] > 1 || bounds[0] < 0)) {
      errors.push(`${formattedName} - Lower Bound must be between 0 and 100`);
      fields.push(`lower_bound_${formattedId}`);
    }
  }

  return { errors, warnings, fields };
};

export const _validateSignalFactor = (factor: ts.types.pipeline.NeutralizeDraft['factors'][0], id: string) => {
  const errors: string[] = [];
  const warnings: string[] = [];
  const fields: ts.types.pipeline.PipelineErrorFields[] = [];

  if (!factor.signal_id) errors.push(`${id} - You need to specify a signal.`);
  if (!factor.beta_range) errors.push(`${id} - You need to specify the Coefficient Sign.`);
  return { errors, warnings, fields };
};

export const _clip = (def: ts.types.pipeline.ClipDraft, filterId: string) =>
  _boundsValidationsErrors(def?.bound, def?.percentile, filterId);

export const _tail = (def: ts.types.pipeline.TailDraft, filterId: string) => {
  const errors: string[] = [];
  const warnings: string[] = [];
  const fields: ts.types.pipeline.PipelineErrorFields[] = [];

  const formattedId = formatFilterId(filterId);

  if (_.isNil(def.bottom)) {
    errors.push(`${filterId} - Bottom is a required field.`);
    fields.push(`bottom_${formattedId}`);
  }
  if (_.isNil(def.top)) {
    errors.push(`${filterId} - Top is a required field.`);
    fields.push(`top_${formattedId}`);
  }
  if (!_.isNil(def.bottom) && (def.bottom < 0 || def.bottom > 1)) {
    errors.push(`${filterId} - Bottom must be between zero and one (inclusive).`);
    fields.push(`bottom_${formattedId}`);
  }
  if (!_.isNil(def.top) && (def.top < 0 || def.top > 1)) {
    errors.push(`${filterId} - Top must be between zero and one (inclusive).`);
    fields.push(`top_${formattedId}`);
  }
  if (!_.isNil(def.top) && !_.isNil(def.bottom) && def.top + def.bottom > 1) {
    errors.push(`${filterId} - Bottom + Top shouldn't be more than one.`);
    fields.push(`bottom_${formattedId}`);
    fields.push(`top_${formattedId}`);
  }

  return { errors, warnings, fields };
};

export const _fillna = (def: ts.types.pipeline.ReplaceNullsDraft, filterId: string) => {
  const errors: string[] = [];
  const warnings: string[] = [];
  const fields: ts.types.pipeline.PipelineErrorFields[] = [];
  const formattedId = formatFilterId(filterId);

  if (_.isNil(def.fill_value)) {
    if (_.isNil(def.mode) || def.mode == 'value') {
      errors.push(`${filterId} - You must select a value.`);
      fields.push(`fill_value_${formattedId}`);
    } else {
      errors.push(`${filterId} - You must select a signal.`);
      fields.push(`fill_signal_${formattedId}`);
    }
  }

  return { errors, warnings, fields };
};

export const _simple_portfolio = (def: ts.types.pipeline.SimplePortfolioDraft, filterId: string) => {
  const errors: string[] = [];
  const warnings: string[] = [];
  const fields: ts.types.pipeline.PipelineErrorFields[] = [];
  const formattedId = formatFilterId(filterId);

  if (_.isNil(def.scale)) {
    errors.push(`${filterId} - Gross Leverage is a required field.`);
    fields.push(`gross_leverage_${formattedId}`);
  }
  return { errors, warnings, fields };
};

export const _characteristic_portfolio = (def: ts.types.pipeline.CharacteristicPortfolioDraft, filterId: string) => {
  const errors: string[] = [];
  const warnings: string[] = [];
  const fields: ts.types.pipeline.PipelineErrorFields[] = [];
  const formattedId = formatFilterId(filterId);

  if (_.isEmpty(def.scale_method)) errors.push(`${filterId} - Scaling method is a required field.`);
  // scale is a number
  if (_.isEmpty(def.risk_factors)) {
    errors.push('Risk Factor is a required field');
  }
  if (_.isNil(def.scale)) {
    errors.push(`${filterId} - Scaling factor is a required field.`);
    fields.push(`scale_factor_${formattedId}`);
  }
  return { errors, warnings, fields };
};

export const _robust_standardize = (def: ts.types.pipeline.RobustStandardizeDraft, filterId: string) => {
  let errors: string[] = [];
  let warnings: string[] = [];
  let fields: ts.types.pipeline.PipelineErrorFields[] = [];
  const formattedId = formatFilterId(filterId);

  // Clip bounds
  const clipErrors = _boundsValidationsErrors(def.clip_bound, false, filterId, 'clip');
  errors = errors.concat(clipErrors.errors);
  warnings = warnings.concat(clipErrors.warnings);
  fields = fields.concat(clipErrors.fields);
  // trunc errors
  const truncErrors = _boundsValidationsErrors(def.trunc_bound, false, filterId, 'truncate');
  errors = errors.concat(truncErrors.errors);
  warnings = warnings.concat(truncErrors.warnings);
  fields = fields.concat(truncErrors.fields);

  if (def.stdev_lb && _.isNil(def.min_stdev)) {
    errors.push(`${filterId} - Min Stdev is a required field.`);
    fields.push(`min_stdev_${formattedId}`);
  }
  if (_.isNil(def.iqr_ratio)) {
    errors.push(`${filterId} - IQR Ratio is a required field.`);
    fields.push(`iqr_ratio_${formattedId}`);
  }

  return { errors, warnings, fields };
};

export const _standardize = (def: ts.types.pipeline.StandardizeDraft, filterId: string) => {
  const errors: string[] = [];
  const warnings: string[] = [];
  const fields: ts.types.pipeline.PipelineErrorFields[] = [];
  const formattedId = formatFilterId(filterId);

  if (_.isNil(def.min_stdev) && def.stdev_lb) {
    errors.push(`${filterId} - Min Stdev is a required field.`);
    fields.push(`min_stdev_${formattedId}`);
  }
  if (_.isNil(def.iqr_ratio) && def.stdev_lb) {
    errors.push(`${filterId} - IQR Ratio is a required field.`);
    fields.push(`iqr_ratio_${formattedId}`);
  }

  return { errors, warnings, fields };
};

export const _truncate = (def: ts.types.pipeline.TruncateDraft, filterId: string) =>
  _boundsValidationsErrors(def?.bound, def?.percentile, filterId);

export const _tanh = (def: ts.types.pipeline.TanhDraft, filterId: string) => {
  const errors: string[] = [];
  const warnings: string[] = [];
  const fields: ts.types.pipeline.PipelineErrorFields[] = [];
  const formattedId = formatFilterId(filterId);

  if (_.isNil(def.center)) {
    errors.push(`${filterId} - Center is a required field.`);
    fields.push(`center_${formattedId}`);
  }
  if (_.isNil(def.width)) {
    errors.push(`${filterId} - Width is a required field.`);
    fields.push(`width_${formattedId}`);
  }

  if (!_.isNil(def.width) && def.width < 0) {
    errors.push(`${filterId} - Width must be greater or equal than zero.`);
    fields.push(`width_${formattedId}`);
  }

  return { errors, warnings, fields };
};

export const _risk_model_matrix_multiplication = (def: ts.types.pipeline.RiskModelMatrixDraft, filterId: string) => {
  const errors: string[] = [];
  const warnings: string[] = [];
  const fields: ts.types.pipeline.PipelineErrorFields[] = [];
  const formattedId = formatFilterId(filterId);

  if (def.inverse_risk && _.isNil(def.gross_leverage)) {
    errors.push(`${filterId} - Gross Leverage is a required field.`);
    fields.push(`gross_leverage_${formattedId}`);
  }
  return { errors, warnings, fields };
};

export const _rank = (def: ts.types.pipeline.RankDraft, filterId: string) => {
  const errors: string[] = [];
  const warnings: string[] = [];
  const fields: ts.types.pipeline.PipelineErrorFields[] = [];
  const formattedId = formatFilterId(filterId);

  if (_.isNil(def.min)) {
    errors.push(`${filterId} - Min is a required field.`);
    fields.push(`min_rank_${formattedId}`);
  }
  if (_.isNil(def.max)) {
    errors.push(`${filterId} - Max is a required field.`);
    fields.push(`max_rank_${formattedId}`);
  }

  if (!_.isNil(def.min) && !_.isNil(def.max) && def.max < def.min) {
    errors.push(`${filterId} - Max must be greater or equal than Min.`);
    fields.push(`max_rank_${formattedId}`);
  }

  return { errors, warnings, fields };
};

export const _quantile_portfolio = (def: ts.types.pipeline.QuantilePortfolioDraft, filterId: string) => {
  const errors: string[] = [];
  const warnings: string[] = [];
  const fields: ts.types.pipeline.PipelineErrorFields[] = [];
  const formattedId = formatFilterId(filterId);

  if (_.isNil(def.short_q) && _.isNil(def.long_q)) {
    errors.push(`${filterId} - At least one of Short Quantile or Long Quantile must be non-empty.`);
    fields.push(`short_quantile_${formattedId}`);
    fields.push(`long_quantile_${formattedId}`);
  }
  if (!_.isNil(def.short_q) && !_.isNil(def.long_q) && def.short_q > def.long_q) {
    errors.push(`${filterId} - Short Quantile must be smaller than Long Quantile.`);
    fields.push(`short_quantile_${formattedId}`);
  }
  if (!_.isNil(def.short_q) && (def.short_q > 100 || def.short_q < 0)) {
    errors.push(`${filterId} - Short Quantile must be between 0 and 100.`);
    fields.push(`short_quantile_${formattedId}`);
  }
  if (!_.isNil(def.long_q) && (def.long_q > 100 || def.long_q < 0)) {
    errors.push(`${filterId} - Long Quantile must be between 0 and 100.`);
    fields.push(`long_quantile_${formattedId}`);
  }

  return { errors, warnings, fields };
};

export const _quantile_portfolio_by_buckets = (
  def: ts.types.pipeline.QuantilePortfolioByBucketsDraft,
  filterId: string
) => {
  const errors: string[] = [];
  const warnings: string[] = [];
  const fields: ts.types.pipeline.PipelineErrorFields[] = [];
  const formattedId = formatFilterId(filterId);

  if (_.isNil(def?.buckets)) errors.push(`${filterId} - Bucket is a required field.`);
  else if (!def?.buckets?.value) {
    errors.push(`${filterId} - Bucket ${_.startCase(_.lowerCase(def.buckets.type))} is a required field.`);
    fields.push(`bucket_${formatFilterId(_.lowerCase(def.buckets.type))}_${formattedId}`);
  }
  if (def?.buckets?.type == 'QUANTILES' && !def?.signal_handle) {
    errors.push(`${filterId} - Sort by Signal is required.`);
    fields.push(`sort_signal_${formattedId}`);
  }
  if (_.isNil(def.short_q) && _.isNil(def.long_q)) {
    errors.push(`${filterId} - At least one of Short Quantile or Long Quantile must be non-empty.`);
    fields.push(`short_quantile_${formattedId}`);
    fields.push(`long_quantile_${formattedId}`);
  }
  if (!_.isNil(def.short_q) && !_.isNil(def.long_q) && def.short_q > def.long_q) {
    errors.push(`${filterId} - Short Quantile must be smaller than Long Quantile.`);
    fields.push(`short_quantile_${formattedId}`);
  }
  if (!_.isNil(def.short_q) && (def.short_q > 100 || def.short_q < 0)) {
    errors.push(`${filterId} - Short Quantile must be between 0 and 100.`);
    fields.push(`short_quantile_${formattedId}`);
  }
  if (!_.isNil(def.long_q) && (def.long_q > 100 || def.long_q < 0)) {
    errors.push(`${filterId} - Long Quantile must be between 0 and 100.`);
    fields.push(`long_quantile_${formattedId}`);
  }

  if (!_.isNil(def.min_assets) && def.min_assets === 0) {
    errors.push(`${filterId} - Min Assets must be greater than 0.`);
    fields.push(`min_assets_${formattedId}`);
  }

  return { errors, warnings, fields };
};

export const _bucket_update = (def: ts.types.pipeline.BucketUpdateDraft, filterId: string) => {
  const errors: string[] = [];
  const warnings: string[] = [];
  const fields: ts.types.pipeline.PipelineErrorFields[] = [];
  const formattedId = formatFilterId(filterId);

  if (_.isNil(def?.buckets)) errors.push(`${filterId} - Bucket is a required field.`);

  if (def?.scale && _.isNil(def?.value)) {
    errors.push(`${filterId} - When Scale By is selected, Value is a required field.`);
    fields.push(`quantile_value_${formattedId}`);
  }

  if (!def?.scale && !def.replace) {
    errors.push(`${filterId} - Scale By or Replace By is required.`);
  }

  if (def?.buckets?.type == 'QUANTILES') {
    if (!def?.buckets.value) {
      errors.push(`${filterId} - A quantile value must be selected.`);
      fields.push(`bucket_quantiles_${formattedId}`);
    } else {
      if (_.isEmpty(def?.bucket_values)) {
        errors.push(`${filterId} - A quantile range must be selected.`);
        fields.push(`from_quantile_${formattedId}`);
        fields.push(`to_quantile_${formattedId}`);
      } else if (def?.bucket_values[0] > def?.bucket_values[def?.bucket_values.length - 1]) {
        errors.push(`${filterId} - From Quantile must be less or equal than To Quantile.`);
        fields.push(`from_quantile_${formattedId}`);
        fields.push(`to_quantile_${formattedId}`);
      } else if (def?.bucket_values[def?.bucket_values.length - 1] > def?.buckets.value) {
        errors.push(`${filterId} - Quantile range outside of quantile count.`);
        fields.push(`from_quantile_${formattedId}`);
        fields.push(`to_quantile_${formattedId}`);
      }
    }
  } else if (def?.buckets?.type == 'INDUSTRY') {
    if (!def?.buckets.value) errors.push(`${filterId} - An industry type must be selected.`);
    else {
      if (_.isEmpty(def?.bucket_values)) {
        errors.push(`${filterId} - Industries values are required.`);
        fields.push(`bucket_industry_${formattedId}`);
      }
    }
  }

  return { errors, warnings, fields };
};

export const _bucket_average = (def: ts.types.pipeline.BucketAverageDraft, filterId: string) => {
  const errors: string[] = [];
  const warnings: string[] = [];
  const fields: ts.types.pipeline.PipelineErrorFields[] = [];
  const formattedId = formatFilterId(filterId);

  if (_.isNil(def?.buckets)) errors.push(`${filterId} - Bucket is a required field.`);

  if (def?.buckets?.type == 'QUANTILES') {
    if (!def?.buckets.value) {
      errors.push(`${filterId} - A quantile value must be selected.`);
      fields.push(`bucket_quantiles_${formattedId}`);
    }
  } else if (def?.buckets?.type == 'INDUSTRY') {
    if (!def?.buckets.value) errors.push(`${filterId} - An industry type must be selected.`);
  }

  return { errors, warnings, fields };
};

export const _quantize = (def: ts.types.pipeline.QuantizeDraft, filterId: string) => {
  const errors: string[] = [];
  const warnings: string[] = [];
  const fields: ts.types.pipeline.PipelineErrorFields[] = [];
  const formattedId = formatFilterId(filterId);

  if (_.isNil(def.number_of_quantiles) || _.isNaN(def.number_of_quantiles)) {
    errors.push(`${filterId} - You must select at least one quantile.`);
    fields.push(`quantiles_${formattedId}`);
  }
  if (!def.border_rule) errors.push(`${filterId} - Border rule is a required field.`);
  if (!_.isNil(def.number_of_quantiles) && (def.number_of_quantiles <= 0 || def.number_of_quantiles > 10)) {
    errors.push(`${filterId} - Quantiles must be between 1 and 10.`);
    fields.push(`quantiles_${formattedId}`);
  }
  let prevQ = null;
  for (const q of (def.quantiles as number[]) || []) {
    if (_.isNil(q)) {
      errors.push(`${filterId} - All quantile fields are required.`);
      fields.push(`quantiles_field_${formattedId}`);
    }
    if (prevQ > q) {
      errors.push(`${filterId} - Ranges have to be on increasing order.`);
      fields.push(`quantiles_field_${formattedId}`);
      break;
    }
    if (q < 0 || q > 1) {
      errors.push(`${filterId} - Ranges have to be greater or equal than 0 and less or equal than 1.`);
      fields.push(`quantiles_field_${formattedId}`);
      break;
    }
    prevQ = q;
  }
  if (prevQ != 1 && !_.isNil(def.number_of_quantiles)) {
    errors.push(`${filterId} - Values have to sum up to 1.`);
    fields.push(`quantiles_field_${formattedId}`);
  }

  return { errors, warnings, fields };
};

export const _neutralize = (def: ts.types.pipeline.NeutralizeDraft, filterId: string) => {
  let errors: string[] = [];
  let warnings: string[] = [];
  const fields: ts.types.pipeline.PipelineErrorFields[] = [];
  const formattedId = formatFilterId(filterId);

  if (def.extra_filter_type == 'robust_neutralize') {
    const regression = def.regression || {};
    if (!regression.k) {
      errors.push(`${filterId} - Threshold is a required field.`);
      fields.push(`threshold_${formattedId}`);
    }
  }

  if (_.isEmpty(def.factors)) {
    errors.push(`${filterId} - At least one explanatory variable must be selected.`);
    fields.push(`explanatory_${formattedId}`);
    return { errors, warnings, fields };
  }

  // FACTORS
  // If intercept is on, then we shouldn't have asset_master, industry or risk model category
  if (def.intercept) {
    if (
      def.factors.find((f) => f.type == ts.enums.N_FACTOR_TYPE_ENUM.ASSET_MASTER) ||
      def.factors.find((f) => f.type == ts.enums.N_FACTOR_TYPE_ENUM.INDUSTRY) ||
      def.factors.find((f) => f.type == ts.enums.N_FACTOR_TYPE_ENUM.RISK_MODEL_CATEGORY)
    )
      warnings.push(`${filterId} - This regression has colinear explanatory variables.`);
  }

  // If factor is asset master, then we should't have industry or risk model category
  if (def.factors.find((f) => f.type == ts.enums.N_FACTOR_TYPE_ENUM.ASSET_MASTER)) {
    if (
      def.factors.find((f) => f.type == ts.enums.N_FACTOR_TYPE_ENUM.INDUSTRY) ||
      def.factors.find((f) => f.type == ts.enums.N_FACTOR_TYPE_ENUM.RISK_MODEL_CATEGORY)
    )
      warnings.push(`${filterId} - This regression has colinear explanatory variables.`);
  }

  // If factor is industry, then we should't have risk model category
  if (def.factors.find((f) => f.type == ts.enums.N_FACTOR_TYPE_ENUM.INDUSTRY)) {
    if (def.factors.find((f) => f.type == ts.enums.N_FACTOR_TYPE_ENUM.RISK_MODEL_CATEGORY))
      warnings.push(`${filterId} - This regression has colinear explanatory variables.`);
  }

  if (def.factors.find((f) => f.type == ts.enums.N_FACTOR_TYPE_ENUM.RISK_MODEL_THEME)) {
    const styleFactors = def.factors.filter((factor) => factor.type == ts.enums.N_FACTOR_TYPE_ENUM.RISK_MODEL_THEME);

    if (hasDuplicateStyleFactors(styleFactors)) errors.push(`${filterId} - Risk model style factors should be unique.`);
  }

  // To see if we added the same signal combination more than once
  const signalKeys: string[] = [];
  _.forEach(def.factors, (df, idx) => {
    if (_.isEmpty(df)) errors.push(`${filterId}(Factor ${idx + 1}) - Factor can't be empty.`);
    if (df.type == ts.enums.N_FACTOR_TYPE_ENUM.SIGNAL) {
      const signalFactorErrors = _validateSignalFactor(df, `${filterId} - Factor ${idx + 1}-`);
      errors = errors.concat(signalFactorErrors.errors);
      warnings = warnings.concat(signalFactorErrors.warnings);
      // If no error add key
      if (_.isEmpty(signalFactorErrors.errors)) {
        const factorKey = `${df.signal_id}-${df?.pipeline_id || 'np'}-${df.sp_pipeline_id || 'nsp'}`;
        if (signalKeys.includes(factorKey)) {
          errors.push(`${filterId} - signal/pipeline/sp_pipeline combination has been selected more than once.`);
        } else signalKeys.push(factorKey);
      }
    }
  });

  return { errors, warnings, fields };
};

type FilterValidations = Record<
  ts.enums.PIPELINE_FILTERS_ENUM,
  (_f: Record<string, any>, _fid: string) => 
    { errors: string[]; warnings: string[]; fields: ts.types.pipeline.PipelineErrorFields[] }
>;
const validations: FilterValidations = {
  [ts.enums.PIPELINE_FILTERS_ENUM.CLIP]: _clip,
  [ts.enums.PIPELINE_FILTERS_ENUM.TAIL]: _tail,
  [ts.enums.PIPELINE_FILTERS_ENUM.CHARACTERISTIC_PORTFOLIO]: _characteristic_portfolio,
  [ts.enums.PIPELINE_FILTERS_ENUM.NEUTRALIZE]: _neutralize,
  [ts.enums.PIPELINE_FILTERS_ENUM.FILLNA]: _fillna,
  [ts.enums.PIPELINE_FILTERS_ENUM.SIMPLE_PORTFOLIO]: _simple_portfolio,
  [ts.enums.PIPELINE_FILTERS_ENUM.ROBUST_STANDARDIZE]: _robust_standardize,
  [ts.enums.PIPELINE_FILTERS_ENUM.STANDARDIZE]: _standardize,
  [ts.enums.PIPELINE_FILTERS_ENUM.TRUNCATE]: _truncate,
  [ts.enums.PIPELINE_FILTERS_ENUM.TANH]: _tanh,
  [ts.enums.PIPELINE_FILTERS_ENUM.QUANTILE_PORTFOLIO]: _quantile_portfolio,
  [ts.enums.PIPELINE_FILTERS_ENUM.QUANTILE_PORTFOLIO_BUCKETS]: _quantile_portfolio_by_buckets,
  [ts.enums.PIPELINE_FILTERS_ENUM.RANK]: _rank,
  [ts.enums.PIPELINE_FILTERS_ENUM.QUANTIZE]: _quantize,
  [ts.enums.PIPELINE_FILTERS_ENUM.RISK_MODEL_MATRIX_MULTIPLICATION]: _risk_model_matrix_multiplication,
  [ts.enums.PIPELINE_FILTERS_ENUM.BUCKET_UPDATE]: _bucket_update,
  [ts.enums.PIPELINE_FILTERS_ENUM.BUCKET_AVERAGE]: _bucket_average,
  [ts.enums.PIPELINE_FILTERS_ENUM.CATEGORIZE_SCALE]: () => ({
    errors: [],
    warnings: [],
    fields: [],
  }),
  [ts.enums.PIPELINE_FILTERS_ENUM.CATEGORIZE]: () => ({
    errors: [],
    warnings: [],
    fields: [],
  }),
  [ts.enums.PIPELINE_FILTERS_ENUM.DEMEAN]: () => ({
    errors: [],
    warnings: [],
    fields: [],
  }),
  [ts.enums.PIPELINE_FILTERS_ENUM.IMPLIED_ALPHA]: () => ({
    errors: [],
    warnings: [],
    fields: [],
  }),
  [ts.enums.PIPELINE_FILTERS_ENUM.ROBUST_NEUTRALIZE]: () => ({
    errors: [],
    warnings: [],
    fields: [],
  }),
  [ts.enums.PIPELINE_FILTERS_ENUM.MINMAX]: () => ({
    errors: [],
    warnings: [],
    fields: [],
  }),
  [ts.enums.PIPELINE_FILTERS_ENUM.NORMALIZE_KEYS]: () => ({
    errors: [],
    warnings: [],
    fields: [],
  }),
  [ts.enums.PIPELINE_FILTERS_ENUM.SCALE]: () => ({
    errors: [],
    warnings: [],
    fields: [],
  }),
  [ts.enums.PIPELINE_FILTERS_ENUM.SIGMA]: () => ({
    errors: [],
    warnings: [],
    fields: [],
  }),
};

export default validations;
