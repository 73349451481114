import { useUiStyles } from '../hooks';
import { mui } from '../libs';

type UiAlertProps = React.ComponentProps<typeof mui.lab.Alert> & {
  rounded?: boolean;
  size?: 'small' | 'smallest' | 'default';
};

/**
 * UiAlert component for displaying an alert with optional rounded corners
 *
 * @param props - Component props
 * @param props.children - The content to display inside the alert
 * @param props.rounded - Whether to use rounded corners
 * @param props.size - The size of the alert
 *
 * @returns An alert component with optional rounded corners and size
 */

const UiAlert: React.FC<UiAlertProps> = (props) => {
  const { children } = props;
  const uiStyles = useUiStyles();

  return (
    <mui.lab.Alert
      {...props}
      sx={
        (props.size == 'small' && {
          display: 'flex',
          padding: '0 1rem',
          '.MuiAlert-icon': {
            display: 'flex',
            alignItems: 'center',
          },
          svg: { fontSize: '1rem' },
        },
        props.size == 'smallest' && {
          display: 'flex',
          padding: '0 0.5rem',
          alignItems: 'center',
          fontSize: '11px',
          '.MuiAlert-icon': {
            padding: 0,
          },
          svg: { fontSize: '12px' },
          '& .MuiAlert-message': {
            padding: 0,
          },
        },
        props.rounded && { ...uiStyles.alertRounded })
      }
    >
      {children}
    </mui.lab.Alert>
  );
};

export default UiAlert;
