import { actions, mui, React, ui,useDispatch, useSelector } from '_core';

import ApiDocs from './api-docs';

const ApiDocsModal = () => {
  const dispatch = useDispatch();
  const apiOpen = useSelector((state) => state.ui.apiOpen);

  return (
    <mui.core.Dialog
      open={apiOpen}
      fullWidth
      maxWidth="xl"
      onClose={() => dispatch(actions.ui.setApiOpen(false))}
      sx={{
        '& .MuiDialog-paper': {
          height: '80vh',
        },
      }}
    >
      <ui.DialogTitle closeAction={() => dispatch(actions.ui.setApiOpen(false))}>API Documentation</ui.DialogTitle>
      <ApiDocs />
    </mui.core.Dialog>
  );
};

export default ApiDocsModal;
