import { _, helpers, ts } from '_core';

export const getNewDataSource = (organizationId: number, params: ts.types.dataSource.DataSourceFormParamsDraft) => {
  const createDefinition: ts.types.dataSource.DataSourceDefinitionDraft = {
    columns: [] as ts.types.dataSource.DataColumnDraft[],
    partition_index: [],
    loader_index: [] as string[],
    extra_index: [] as string[],
    date_partition_frequency: 'MONTHLY',
    partition_by_date: true,
    partition_by_country: params.hasAssets ? true : false,
  };

  let dateColumnName = 'date';
  let fileColumns: ts.types.dataSource.DataColumnDraft[] = [];

  if (params.tabData) {
    fileColumns = helpers.inferType.inferTabular(
      params.tabData,
      params.dataType == 'csv' ? ts.enums.SEPARATORS_ENUM.COMMA : ts.enums.SEPARATORS_ENUM.TAB,
      params.withHeader
    );
    if (_.isEmpty(fileColumns)) throw Error('Invalid tabular data.');
    const dateColumns = fileColumns.filter((col) => col.dtype == 'DATE');

    if (dateColumns.length == 1) dateColumnName = dateColumns[0].column_name;
  }

  const nonFixedIdentifierColumns = ['cusip', 'isin', 'ticker', 'country', 'bloomberg_ticker'];

  const identifierColumns: ts.types.dataSource.DataColumnDraft[] = [];

  const dateColumns: ts.types.dataSource.DataColumnDraft[] = [
    {
      dtype: 'DATA_DATE',
      column_name: dateColumnName,
      name: 'Data Date',
    },
    {
      dtype: 'RECEIVED_DATE',
      column_name: 'received_date',
      name: 'Received Date',
    },
  ];

  const checkIfColumnExist = (columnKey: string) =>
    _.some(fileColumns, (col) => _.toLower(col.column_name) == columnKey);

  nonFixedIdentifierColumns.forEach((col) => {
    if (checkIfColumnExist(col)) {
      identifierColumns.unshift({
        dtype: _.toUpper(col) as ts.types.dataSource.DataColumn['dtype'],
        column_name: col,
        name: _.startCase(col),
      });
    }
  });

  if (params.hasFID)
    identifierColumns.unshift({
      dtype: 'FID',
      column_name: 'fid',
      name: 'FID',
    });

  let columnsBeforeMap = [
    ...identifierColumns,
    ...dateColumns,
    ...fileColumns,
  ] as ts.types.dataSource.DataColumnDraft[];

  if (!params.hasAssets) {
    columnsBeforeMap = [...dateColumns, ...fileColumns] as ts.types.dataSource.DataColumnDraft[];
    createDefinition['partition_by_date'] = false;
  }

  const mapCol = (el: ts.types.dataSource.DataColumnDraft) => helpers.createHandle(el.column_name, 'col');
  // If schema was updated from tsv then we need to store on the def a flag and the unmapped keys
  if (!_.isEmpty(fileColumns)) {
    createDefinition['inferred'] = true;
    createDefinition['inferred_unmapped'] = params.hasAssets
      ? _.without<string>(
          _.map(identifierColumns as ts.types.dataSource.DataColumnDraft[], mapCol),
          ..._.map(fileColumns, mapCol)
        )
      : [];
  }

  // get uniq columns
  createDefinition['columns'] = _.uniqBy(columnsBeforeMap, mapCol);

  createDefinition['lag'] = params.lag;
  createDefinition['rollback_days'] = params.rollback_days;
  createDefinition['rollback_country'] = params.rollback_country;

  const newDataSource: ts.types.dataSource.DataSourceDraft = {
    name: params.name,
    handle: params.handle,
    data_provider_label: params.dataProvider || null,
    data_set_label: params.dataSet || null,
    ds_type: organizationId == 0 ? 'COMMON' : 'CUSTOM',
    frequency: params.frequency,
    look_back: params.look_back,
    has_assets: params.hasAssets,
    is_index: params.is_index,
    definition: createDefinition,
    ui_metadata: {},
  };

  return newDataSource;
};

const _getColumnsLayers = (definition: ts.types.dataSource.DataSourceDefinition) => {
  const columns = (definition.columns ?? []).map((c) => c.column_name);
  const indexesValues = (definition.partition_index ?? []).map((v) => Object.values(v.values ?? {}));

  return [...indexesValues, columns];
};

const _getColumnsCombinations = (
  arrays: string[][],
  currentIndex = 0,
  currentCombination = [] as string[]
): string[] => {
  if (currentIndex === arrays.length) {
    return [currentCombination.join('.')];
  }

  return _.flatMap(arrays[currentIndex], (element) => {
    const newCombination = [...currentCombination, element];
    return _getColumnsCombinations(arrays, currentIndex + 1, newCombination);
  });
};

export const getColumns = (datasource: ts.types.dataSource.DataSource) => {
  const layers = _getColumnsLayers(datasource.definition);
  const columns = datasource.definition.columns ?? [];

  return _getColumnsCombinations([[datasource.handle], ...layers]).map((c) => {
    const fullCol = columns.find((fullCol) => fullCol.column_name == c.split('.').at(-1));

    return {
      column: c,
      description: fullCol?.label || fullCol?.name || fullCol?.column_name,
    };
  });
};
