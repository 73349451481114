import { moment, React, ts, ui } from '_core';

type UniverseFieldProps = {
  dataset: ts.types.signal.DatasetDraft;
  setDataset: (_d: ts.types.signal.DatasetDraft) => void;
  resources: ts.StoreState['resources'];
  allOptional?: boolean;
  isPreference?: boolean;
};

const UniverseField: React.FC<UniverseFieldProps> = ({ dataset, setDataset, resources, allOptional, isPreference }) => {
  const onChange = (id: number) => {
    if (!id) {
      setDataset({ ...dataset, universe_id: null });
      return;
    }
    const universeId = id;
    const universe = resources.universes.find((u) => u.id == universeId);

    const uniStart = moment(universe.start_date);
    const uniEnd = moment(universe.end_date);

    const newStartDate = dataset.start_date
      ? moment(dataset.start_date).isBetween(uniStart, uniEnd)
        ? dataset.start_date
        : universe.start_date
      : universe.start_date;

    const newEndDate = dataset.start_date
      ? moment(dataset.end_date).isBetween(uniStart, uniEnd)
        ? dataset.end_date
        : universe.end_date
      : universe.end_date;

    if (universe) {
      const newDataset = {
        ...dataset,
        universe_id: universeId,
        id: isPreference ? dataset?.id : null,
      } as ts.types.signal.DatasetDraft;

      if (!dataset.start_date) {
        newDataset.start_date = newStartDate;
      }

      if (!dataset.end_date) {
        newDataset.end_date = dataset.end_date_latest ? dataset.end_date : newEndDate;
      }

      setDataset(newDataset);
    }
  };

  return (
    <ui.ResourceAutocomplete<ts.types.universe.UniverseExpanded>
      value={dataset.universe_id || null}
      enableNone
      setValue={onChange}
      type={ts.enums.RESOURCES_TYPES_ENUM.UNIVERSE}
      inputProps={{
        fullWidth: true,
        label: 'Universe',
        variant: 'outlined',
        size: 'small',
        required: !allOptional,
      }}
    />
  );
};

export default UniverseField;
