import { mui, React } from '_core';

import { PreferencesContext } from 'views/preferences/preferences-context';

import PreferencesMainMenu from './main-menu';
import PreferencesMainView from './main-view';

const Preferences = () => {
  const preferencesContext = React.useContext(PreferencesContext);
  const { preferencesOpen, closePreferences, alertError } = preferencesContext;

  const theme = mui.styles.useTheme() as mui.core.Theme;

  return (
    <mui.core.Dialog
      open={preferencesOpen.open}
      fullWidth
      maxWidth="xl"
      onClose={closePreferences}
      sx={{
        '& .MuiDialog-paper': {
          height: '90vh',
        },
      }}
      scroll="paper"
    >
      <mui.core.Grid
        container
        sx={{
          flex: 1,
          height: 'calc(100% - 54px)',
          [theme.breakpoints.only('xs')]: {
            display: 'block',
          },
        }}
      >
        <mui.core.Grid item xs={12} sm={3} lg={2}>
          <PreferencesMainMenu />
        </mui.core.Grid>

        <mui.core.Grid item xs={12} sm={9} lg={10} style={{ height: '100%' }}>
          {alertError && <mui.lab.Alert severity="error">{alertError}</mui.lab.Alert>}
          <PreferencesMainView />
        </mui.core.Grid>
      </mui.core.Grid>
    </mui.core.Dialog>
  );
};

export default Preferences;
