import { mui, React, ts, ui } from '_core';

import { FixedCostContext } from '../fixed-costs-context';

type NumericFormatterProps = {
  row: ts.types.fixedCosts.FixedCostDraft;
  col_key: string;
};

const NumericFormatter: React.FC<NumericFormatterProps> = ({ row, col_key }): React.ReactElement => {
  const [value, setValue] = React.useState(row[col_key as keyof ts.types.fixedCosts.FixedCost]);

  const fixedcostsContext = React.useContext(FixedCostContext);
  const { updatePropertyById } = fixedcostsContext;

  return (
    <ui.NumericField
      textFieldProps={{ customInput: mui.core.TextField }}
      value={value as number}
      setValue={(v) => {
        setValue(v);
        updatePropertyById(row.id, col_key, v);
      }}
    />
  );
};

export default NumericFormatter;
