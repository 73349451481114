import { ts } from '_core';

import { validate } from './helpers';
import { INTERACTION_PARAMS } from './types';

export default [
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.PORTFOLIO_TRADE_LIST,
    form: true,
    title: 'Trade List',
    interactionParams: Object.values(INTERACTION_PARAMS),
    clearInteractionOnRegenerate: ['table_dtypes'],
    neededFiles: ['tradelist_rebalance_analysis', 'rebalance_analysis'],
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.REBALANCE_PORTFOLIO_TRADE_LIST],

    validate,
    helpLabel: 'ssspykv6',
  },
] satisfies ts.types.analysis.WidgetConfig[];
