import { _, React, ts } from '_core';

import { transform } from './helpers';
import { Params } from './types';
import ChartWrapper from '../../common/chart-wrapper';
import createNestedBarChart from '../../common/charts/create-nested-bar-chart';
import { prepareBarChartBucketedNames, prepareBarChartCountNames } from '../../common/utils/get-series-name';

type ChartProps = {
  id: string;
  readOnly: boolean;
  widgetKey: string;
  loadWidgetData: (_payload: ts.types.widgets.WidgetGetDataParams) => Promise<ts.types.widgets.WidgetGetDataResponse>;
  file: string;
  fullScreen: boolean;
  params: Params;
  title: string;
};

const Chart: React.FC<ChartProps> = ({ id, readOnly, widgetKey, loadWidgetData, file, fullScreen, params, title }) => {
  const [data, setData] = React.useState<ts.types.widgets.TableData>(null);
  const [error, setError] = React.useState<ts.types.common.ApiError>(null);
  const [showLegend, setShowLegend] = React.useState(false);

  const loadData = async () => {
    try {
      const response = await loadWidgetData({
        data: {
          file,
        },
      });

      if (response) {
        setData(response.data);
      }
    } catch (err) {
      setError(err as ts.types.common.ApiError);
    }
  };

  React.useEffect(() => {
    if (file) loadData();
    return () => setData(null);
  }, [file]);

  React.useEffect(() => {
    // Create line chart on data and scale changes
    if (!_.isNil(data)) {
      const localData = transform(data);

      createNestedBarChart({
        id,
        data: localData,
        fullScreen,
        setShowLegend,
        format: '#.##',
        exportTitle: title,
        prepareData: (nestedBarData) => ({
          data: prepareBarChartBucketedNames(
            nestedBarData.data,
            params.discrete ? ts.enums.BUCKET_TYPES_ENUM.FIXED_FLOAT : ts.enums.BUCKET_TYPES_ENUM.QUANTILES,
            params.quantiles
          ),
          count: prepareBarChartCountNames(
            nestedBarData.count,
            params.discrete ? ts.enums.BUCKET_TYPES_ENUM.FIXED_FLOAT : ts.enums.BUCKET_TYPES_ENUM.QUANTILES,
            params.quantiles
          ),
        }),
      });
    }
  }, [data]);

  return (
    <>
      <ChartWrapper
        id={id}
        readOnly={readOnly}
        widgetKey={widgetKey}
        data={data}
        showLegend={showLegend}
        warning={data?.length === 0 ? ['Empty File'] : []}
        error={error}
        fullScreen={fullScreen}
      />
    </>
  );
};

export default Chart;
