import { _, config, hooks, mui, React, ts, ui } from '_core';

type StartDateFieldProps = {
  dataset: ts.types.signal.DatasetDraft;
  setDataset: (_d: ts.types.signal.DatasetDraft) => void;
  setIsDateRangeValid: (_val: boolean) => void;
  setDatesLoading: (_val: boolean) => void;
  signal?: ts.types.signal.SignalDraft;
};

const StartDateField: React.FC<StartDateFieldProps> = ({
  dataset,
  setDataset,
  setIsDateRangeValid,
  setDatesLoading,
  signal,
}) => {
  const [validDates, setValidDates] = React.useState([] as string[]);

  const localValue: string[] = React.useMemo(() => {
    return [dataset.start_date, dataset.end_date];
  }, [dataset]);

  const { height } = hooks.useWindowDimensions();
  const tooltipPlacement = height >= 1000 ? 'top' : 'auto';

  const handleKeepCurrent = async () => {
    setDataset({
      ...dataset,
      end_date_latest: !dataset.end_date_latest,
      end_date: dataset.end_date_latest ? dataset.end_date : validDates.at(-1) || config.features.end_date,
    });
  };

  return (
    <mui.core.Box mb={3}>
      <ui.SmartDateRangeSelector
        value={localValue}
        onChange={(v) => setDataset({ ...dataset, start_date: v[0], end_date: v[1] })}
        minDate={config.features.start_date}
        maxDate={config.features.end_date}
        universeId={dataset.end_date_latest ? undefined : dataset.universe_id}
        frequency={dataset.frequency as ts.enums.FREQUENCY_ENUM}
        onlyStartDate={dataset.end_date_latest}
        setExternalError={(err) => {
          setIsDateRangeValid(_.isEmpty(err));
        }}
        setExternalValidDates={setValidDates}
        setExternalLoading={(loading) => {
          setDatesLoading(loading);
        }}
        verticalPositioning
        allowAllDates
        placement={tooltipPlacement as any}
        forceReload={dataset.end_date_latest}
      />

      {config.features.keep_resources_current && !_.isEmpty(validDates) && signal?.organization_id != 0 && (
        <mui.core.Box mt={2} px={2}>
          <mui.core.FormControlLabel
            control={
              <mui.core.Switch
                checked={dataset.end_date_latest}
                size="small"
                color="primary"
                onChange={() => {
                  handleKeepCurrent();
                }}
              />
            }
            label="Keep Current"
          />
        </mui.core.Box>
      )}
    </mui.core.Box>
  );
};

export default StartDateField;
