const constraintInputNames: any = {
  lambda_: 'Risk Aversion',
  relax_order: 'Relaxation Order',
  max_position_days: 'Max Position Days of ADV',
  max_trade_days: 'Max Trade Days of ADV',
  maximum_t_cost_upper_bound: 'Maximum TCost (BPS)',
  trade_list_constraint_type: 'Constraint Type',
  default_available_shares: 'Default Availability',
  signal_handle: 'Signal',
  mean_variance_type: 'Type',
};

export default constraintInputNames;
