import { mui, React } from '_core';

import { RISK_METRIC_ENUM } from '../../../types';

const SelectRiskMetricField = ({
  value,
  setValue,
}: {
  value: RISK_METRIC_ENUM;
  setValue: (_v: RISK_METRIC_ENUM) => void;
}) => (
  <mui.core.Box display="inline-flex" width={200}>
    <mui.core.TextField
      select
      label="Risk View"
      variant="outlined"
      size="small"
      value={value}
      onChange={(e) => setValue(e.target.value as RISK_METRIC_ENUM)}
      fullWidth
    >
      <mui.core.MenuItem value={RISK_METRIC_ENUM.TOTAL}>Total Risk</mui.core.MenuItem>
      <mui.core.MenuItem value={RISK_METRIC_ENUM.FACTOR}>Factor Risk</mui.core.MenuItem>
      <mui.core.MenuItem value={RISK_METRIC_ENUM.SPECIFIC}>Specific Risk</mui.core.MenuItem>
    </mui.core.TextField>
  </mui.core.Box>
);

export default SelectRiskMetricField;
