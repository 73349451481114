import React from 'react';

import UiButton from './button';
import { useUiStyles } from '../hooks';
import { _, mui } from '../libs';
import * as types from '../types';

type SaveBarProps = {
  validationDef: types.common.ValidationErrorsDraft;
  updateDefinition: () => Promise<void>;
  definitionChanged: boolean;
  validating: boolean;
  saveLabel?: string;
  savedLabel?: string;
  customAction?: JSX.Element;
  customSaveAction?: JSX.Element;
  customRightAction?: JSX.Element;
  validatingText?: string;
  noBackground?: boolean;
  saveAs?: () => void;
  forceSaving?: boolean;
  enableSaveOnValid?: boolean;
};

/**
 * Component that provides a save bar with validation status and save functionality
 *
 * @param validationDef - Validation errors draft
 * @param updateDefinition - Function to update the definition
 * @param definitionChanged - Indicates if the definition has changed
 * @param validating - Indicates if validation is in progress
 * @param saveLabel - Label for the save button
 * @param savedLabel - Label for the saved state
 * @param customAction - Custom action element
 * @param customSaveAction - Custom save action element
 * @param customRightAction - Custom right action element
 * @param validatingText - Text to display during validation
 * @param noBackground - Flag to disable background
 * @param saveAs - Function for save as action
 * @param forceSaving - Flag to force saving
 * @param enableSaveOnValid - Flag to enable save when valid
 */
const SaveBar: React.FC<SaveBarProps> = ({
  validationDef,
  updateDefinition,
  definitionChanged,
  validating,
  saveAs,
  saveLabel,
  savedLabel,
  customAction,
  customSaveAction,
  customRightAction,
  validatingText,
  forceSaving,
  enableSaveOnValid,
}): React.ReactElement => {
  const [saving, setSaving] = React.useState(false);
  const uiStyles = useUiStyles();
  const theme = mui.styles.useTheme() as mui.core.Theme;

  const saveDefinition = async () => {
    setSaving(true);
    await updateDefinition();
    setSaving(false);
  };

  const getButtonLabel = () => {
    if (validating) {
      return (
        <>
          <mui.core.CircularProgress
            size={14}
            style={{ marginRight: '8px', color: (theme.palette as any).text.secondary }}
          />
          {validatingText}
        </>
      );
    }
    if (!definitionChanged) return savedLabel;
    return saveLabel;
  };

  const buttonDisabled = saving || forceSaving || validating || (enableSaveOnValid && !validationDef.valid);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event: React.UIEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const renderErrors = (errors: string[], isWarning = false) => (
    <>
      <mui.core.Chip
        color={isWarning ? 'warning' : 'error'}
        label={
          <>
            {errors[0]}
            {errors.length > 1 && (
              <>
                <mui.core.Button
                  onClick={handleClick}
                  color={isWarning ? 'warning' : 'error'}
                  size="small"
                  sx={{ fontSize: '0.7rem!important' }}
                >
                  (view {errors.length - 1} more)
                </mui.core.Button>
                <mui.core.Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                >
                  {errors.slice(1).map((error) => (
                    <mui.core.ListItem dense key={error}>
                      {' '}
                      <mui.core.ListItemText primary={error} />{' '}
                    </mui.core.ListItem>
                  ))}
                </mui.core.Popover>
              </>
            )}
          </>
        }
      />
    </>
  );

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <mui.core.Box
      sx={uiStyles.definitionSaveBar}
      display="flex"
      style={{
        background: (theme.palette as any).background.default,
      }}
    >
      <mui.core.Box mr={2}>
        <UiButton
          loading={saving || forceSaving}
          variant="contained"
          color="primary"
          onClick={() => {
            saveDefinition();
          }}
          disabled={buttonDisabled || !definitionChanged}
        >
          {getButtonLabel()}
        </UiButton>
      </mui.core.Box>
      {customSaveAction && <mui.core.Box mr={2}> {customSaveAction} </mui.core.Box>}
      {saveAs && (
        <mui.core.Box mr={2}>
          <UiButton
            variant="outlined"
            onClick={() => saveAs()}
            color="primary"
            disabled={buttonDisabled || !validationDef.valid}
          >
            Save As
          </UiButton>
        </mui.core.Box>
      )}

      {customAction && <mui.core.Box mr={2}> {customAction} </mui.core.Box>}
      {/* Render errors */}
      {!validationDef.valid && !_.isEmpty(validationDef.errors) && !validating && renderErrors(validationDef.errors)}

      {/* Render warnings */}
      {validationDef.valid &&
        !_.isEmpty(validationDef.warnings) &&
        !validating &&
        renderErrors(validationDef.warnings, true)}

      {customRightAction && (
        <mui.core.Box flex={1} display="flex" justifyContent="flex-end">
          {customRightAction}
        </mui.core.Box>
      )}
    </mui.core.Box>
  );
};

SaveBar.defaultProps = {
  saveLabel: 'Save',
  savedLabel: 'Saved',
  customAction: null,
  customSaveAction: null,
  validatingText: 'Validating',
  forceSaving: false,
  validationDef: {},
};

export default SaveBar;
