import { _, React, ts, useSelector } from '_core';

import { formatData, prepareLinearData } from './helpers';
import { Params } from './types';
import ChartWrapper from '../../common/chart-wrapper';
import createLineChart from '../../common/charts/create-line-chart';

type ChartProps = {
  id: string;
  readOnly: boolean;
  widgetKey: string;
  loadWidgetData: (_payload: ts.types.widgets.WidgetGetDataParams) => Promise<ts.types.widgets.WidgetGetDataResponse>;
  portfolios: string;
  fullScreen: boolean;
  params: Params;
  title: string;
  setExternalEmbed: (_json: ts.types.analysis.EmbedChartData) => void;
  context?: ts.types.basket.BasketAnalysisContext;
};

const Chart: React.FC<ChartProps> = ({
  id,
  readOnly,
  widgetKey,
  fullScreen,
  loadWidgetData,
  portfolios,
  params,
  title,
  setExternalEmbed,
}) => {
  const baskets = useSelector((state) => state.resources.baskets);

  const [data, setData] = React.useState<ts.types.widgets.TableData>(null);
  const [error, setError] = React.useState<ts.types.common.ApiError>(null);
  const [showLegend, setShowLegend] = React.useState(false);

  const loadData = async () => {
    try {
      const response = await loadWidgetData({
        data: {
          file: portfolios,
        },
      });
      if (response) {
        setData(formatData(response.data, params.histories ?? []));
      }
    } catch (err) {
      setError(err as ts.types.common.ApiError);
    }
  };
  React.useEffect(() => {
    if (portfolios) loadData();
    return () => setData(null);
  }, [portfolios]);

  React.useEffect(() => {
    // Create line chart on data and scale changes
    if (!_.isNil(data))
      createLineChart({
        id,
        data,
        fullScreen,
        setShowLegend,
        showEndValueLabel: true,
        prepareData: (linearData) => prepareLinearData(linearData, true, baskets),
        exportTitle: title,
        setExternalEmbed,
        showPeriodSelector: true,
      });
  }, [data]);

  return (
    <ChartWrapper
      id={id}
      readOnly={readOnly}
      widgetKey={widgetKey}
      data={data}
      showLegend={showLegend}
      error={error}
      fullScreen={fullScreen}
      showPeriodSelector={true}
    />
  );
};

export default Chart;
