import { Store } from 'redux';

import { OrganizationFeatures } from '../types/organization';

// Create a singleton to hold the context values
export type FinseraProviderValues = {
  store: Store;
  features: OrganizationFeatures;
  actions: any;
};

class ContextSingleton {
  private static instance: FinseraProviderValues | null = null;

  static initialize(contextValues: FinseraProviderValues) {
    this.instance = contextValues;
  }


  static getInstance(): FinseraProviderValues {
    if (!this.instance) {
      this.instance = {
        store: {} as Store,
        features: {} as any,
        actions: {} as any,
      };
    }
    return this.instance;
  }
}

export default ContextSingleton;