import React from 'react';

import EmptyMessage from './empty-message';
import { parseApiError } from '../helpers';
import { _, mui } from '../libs';

export type ApiError = {
  responseJSON: any;
};

interface ChartErrorProps {
  error?: ApiError | string;
  readOnly: boolean;
  severity?: 'error' | 'warning';
}

/**
 * ChartError component for displaying error messages
 * 
 * @param error - Error message or object
 * @param readOnly - Whether the chart is read-only
 * @param severity - Severity of the error message
 * 
 * @returns A component for displaying error messages
 */

const ChartError: React.FC<ChartErrorProps> = ({ error, readOnly, severity = 'error' }) => {
  if (readOnly) {
    return (
      <mui.core.Box height="100%">
        <EmptyMessage>
          This widget is read only. If the widget works on the owner account, it might reference a resource that is not
          published/shared. Please ask them to share the dependencies with you or publish them in your organization.
        </EmptyMessage>
      </mui.core.Box>
    );
  }

  return (
    <mui.lab.Alert severity={severity} sx={{ maxHeight: '50px' }}>
      {_.isString(error) ? error : parseApiError(error)}
    </mui.lab.Alert>
  );
};

export default ChartError;
