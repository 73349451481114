import { _, helpers, mui, React, ts, ui, useHistory, useSelector } from '_core';

import { RiskModelContext } from 'views/risk-models/risk-model-context';
import DefinitionCategoryFactors from 'views/risk-models/risk-model-definition/definition/category-factors';

import { UIApiParamsIndustries, UIApiResponseIndustries } from './types';

type ChartCategoriesProps = {
  riskModelId: number;
  loadWidgetData: (_payload: UIApiParamsIndustries) => Promise<UIApiResponseIndustries>;
  file: string;
};

const ChartCategories: React.FC<ChartCategoriesProps> = ({ loadWidgetData, file, riskModelId }) => {
  const { updateDefinition, validationDef } = React.useContext(RiskModelContext);

  const [categories, setCategories] = React.useState<ts.types.riskModel.DefinitionCategory>();
  const [loadingUpdate, setLoadingUpdate] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [modalOpen, setModalOpen] = React.useState(false);

  const [categoriesToExport, setCategoriesToExport] = React.useState<('industry' | 'country' | 'currency')[]>([
    'industry',
    'country',
    'currency',
  ]);

  const history = useHistory();
  const currentRiskModel = useSelector((store) => store.resources.risk_models.find((r) => r.id == riskModelId));

  const buildNewCategories = () => {
    const newCategories = (currentRiskModel.definition?.categories ?? []).map((c) => {
      if (categoriesToExport.includes(c.handle as (typeof categoriesToExport)[0]))
        return categories.find((newC) => newC.handle == c.handle);
      return c;
    });

    categoriesToExport.forEach((toExport) => {
      const newCategoryValue = categories.find((c) => c.handle == toExport);
      if (!newCategories.find((c) => c.handle == toExport) && newCategoryValue) newCategories.push(newCategoryValue);
    });
    return newCategories;
  };

  const updateRiskModel = async () => {
    try {
      setLoadingUpdate(true);

      // export selected categories
      await updateDefinition(
        {
          ...(currentRiskModel.definition || {}),
          categories: buildNewCategories(),
          type: 'FINSERA',
        },
        false,
        true
      );

      history.push(`/risk_models/${currentRiskModel.id}/definition`);
      setLoadingUpdate(false);
    } catch (err) {
      console.log(err);
      setError(JSON.stringify(err));
      setLoadingUpdate(false);
    }
  };

  const loadData = async () => {
    try {
      const response = await loadWidgetData({
        data: { file },
      });

      if (response) {
        const { data } = response;
        setCategories((data || []).map((el) => JSON.parse(el?.[0] ?? '[]')));
      }
    } catch (err) {
      setError(JSON.stringify(err));
    }
  };

  React.useEffect(() => {
    if (file) loadData();
    return () => setCategories(undefined);
  }, [file]);

  const renderCategories = () => {
    if (_.isEmpty(categories)) return <ui.EmptyMessage> No data for the selected params. </ui.EmptyMessage>;

    return (
      <mui.core.Box display="flex" flexDirection="column" p={3}>
        {!currentRiskModel.is_published && (
          <mui.core.Box display="flex" justifyContent="end">
            <ui.Button
              loading={loadingUpdate}
              // onClick={() => updateRiskModel()}
              onClick={() => setModalOpen(!modalOpen)}
              variant="outlined"
            >
              Update Definition
            </ui.Button>
            <mui.core.Dialog open={modalOpen}>
              <ui.DialogTitle closeAction={() => setModalOpen(false)}>
                You are about to edit the definition
              </ui.DialogTitle>
              <mui.core.DialogContent>
                <ui.Alert severity="info" rounded>
                  Clicking {"'Confirm'"} will apply the selected categories to the Risk Model definition and allow you
                  to make further edits.
                </ui.Alert>
                <mui.core.Box mt={5}>
                  <mui.core.Typography sx={{ fontWeight: 500 }}>Select category types:</mui.core.Typography>
                </mui.core.Box>
                <mui.core.FormControlLabel
                  control={
                    <mui.core.Checkbox
                      checked={categoriesToExport.includes('industry')}
                      onChange={(event) => {
                        if (event.target.checked) setCategoriesToExport([...categoriesToExport, 'industry']);
                        else setCategoriesToExport(categoriesToExport.filter((c) => c !== 'industry'));
                      }}
                      color="primary"
                    />
                  }
                  label="Industry"
                />
                {categories.find((c) => c.handle == 'country') && (
                  <mui.core.FormControlLabel
                    control={
                      <mui.core.Checkbox
                        checked={categoriesToExport.includes('country')}
                        onChange={(event) => {
                          if (event.target.checked) setCategoriesToExport([...categoriesToExport, 'country']);
                          else setCategoriesToExport(categoriesToExport.filter((c) => c !== 'country'));
                        }}
                        color="primary"
                      />
                    }
                    label="Country"
                  />
                )}
                {categories.find((c) => c.handle == 'currency') && (
                  <mui.core.FormControlLabel
                    control={
                      <mui.core.Checkbox
                        checked={categoriesToExport.includes('currency')}
                        onChange={(event) => {
                          if (event.target.checked) setCategoriesToExport([...categoriesToExport, 'currency']);
                          else setCategoriesToExport(categoriesToExport.filter((c) => c !== 'currency'));
                        }}
                        color="primary"
                      />
                    }
                    label="Currency"
                  />
                )}
              </mui.core.DialogContent>
              <mui.core.DialogActions>
                <mui.core.Button onClick={() => setModalOpen(false)} color="primary" disabled={loadingUpdate}>
                  Cancel
                </mui.core.Button>
                <ui.Button
                  onClick={() => updateRiskModel()}
                  color="primary"
                  variant="contained"
                  loading={loadingUpdate}
                  autoFocus
                  disabled={loadingUpdate || _.isEmpty(categoriesToExport)}
                >
                  Update definition
                </ui.Button>
              </mui.core.DialogActions>
            </mui.core.Dialog>
          </mui.core.Box>
        )}

        <DefinitionCategoryFactors
          definition={categories || []}
          setDefinition={() => undefined}
          uiMetadata={{ sections: {} }}
          setUiMetadata={() => undefined}
          validationDef={validationDef}
          readOnly
          helpContentPath={`/${ts.enums.RISK_MODEL_ANALYSIS_TYPE_ENUM.CATEGORY}`}
          showEmptyCategories
        />
      </mui.core.Box>
    );
  };

  return (
    <mui.core.Box>
      <mui.core.Box display="flex">
        {error && (
          <mui.lab.Alert severity="error" style={{ width: '100%' }}>
            {helpers.parseApiError(error)}
          </mui.lab.Alert>
        )}
      </mui.core.Box>

      {_.isNil(categories) && !error && (
        <mui.core.Box style={{ padding: 30 }}>
          <ui.CenteredLoader label="Loading industries..." top="8%" />
        </mui.core.Box>
      )}

      {!_.isNil(categories) && !error && renderCategories()}
    </mui.core.Box>
  );
};

export default ChartCategories;
