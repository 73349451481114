import { mui, React, ui, uitheme } from '_core';

import JobNotifications from './job-notifications';
import Navbar from './navbar';

type AuthLayoutProps = {
  children: React.ReactNode;
};

const AuthLayout: React.FC<AuthLayoutProps> = ({ children }): React.ReactElement => {
  const theme = mui.styles.useTheme() as mui.core.Theme;

  const { APP_BAR_HEIGHT } = uitheme.layoutSize;
  return (
    <mui.core.Box
      display="flex"
      height="100%"
      sx={{
        '@media print': {
          height: 'auto',
        },
      }}
    >
      <JobNotifications />
      <mui.core.Box
        sx={{
          width: '100%',
          height: '100%',
          display: 'grid',
          gridTemplateRows: `${APP_BAR_HEIGHT} 1fr`,
          overflow: 'hidden',
          '@media print': {
            gridTemplateRows: '1fr',
          },
        }}
      >
        <Navbar />
        <mui.core.Box
          sx={{
            background: theme.palette.background.default,
            overflow: 'auto!important',
            '@media print': {
              overflow: 'initial!important',
            },
          }}
        >
          <ui.ErrorBoundaryFeedback>
            <>{children}</>
          </ui.ErrorBoundaryFeedback>
        </mui.core.Box>
      </mui.core.Box>
    </mui.core.Box>
  );
};

export default AuthLayout;
