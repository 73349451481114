import { _, config, ts } from '_core';

import AllAssets from './asset-all';
import AssetVolume from './asset-volume';
import constraintsNameMap from './constraints-name-map';
import CountryConstraint from './country-constraint';
import GrossLeverageConstraint from './gross-leverage-constraint';
import IndustryConstraint from './industry-constraint';
import LocateConstraint from './locate';
import MaximumRisk from './maximum-risk';
import MaximumTcost from './maximum-tcost';
import MaximumTurnover from './maximum-turnover';
import NetLeverageConstraint from './net-leverage-constraint';
import RiskFactorConstraint from './risk-factor-constraint';
import SharesOutstanding from './shares-outstanding';
import SignalExposure from './signal-exposure';
import SpecificAssets from './specific-assets';
import StockLoanAvailability from './stock-loan-availability';
import TradeListConstraint from './trade-list';
import TradeListSignalExposureConstraint from './trade-list-signal-exposure';
import constraintInputNames from '../optimizer-utils/input-names';

const formatToPercent = (value: unknown) => {
  return value ? `${(value as number) * 100}%` : null;
};

const getCommonLabels = (constraint: ts.types.optimizer.Constraint, percent = false) => {
  return [
    { key: 'Lower Bound', value: percent ? formatToPercent(constraint.lower_bound) : constraint.lower_bound },
    { key: 'Upper Bound', value: percent ? formatToPercent(constraint.upper_bound) : constraint.upper_bound },
    {
      key: 'GrandFathered Lower Bound',
      value: percent ? formatToPercent(constraint.grandfathered_lower_bound) : constraint.grandfathered_lower_bound,
    },
    {
      key: 'GrandFathered Upper Bound',
      value: percent ? formatToPercent(constraint.grandfathered_upper_bound) : constraint.grandfathered_upper_bound,
    },
    { key: constraintInputNames['relax_order'], value: constraint.relax_order },
    { key: 'Benchmark Relative', value: constraint.benchmark_relative },
    { key: 'Start Date', value: constraint.start },
    { key: 'End Date', value: constraint.end },
  ];
};

const sliceValues = (values: string[]) => {
  const slicedValues = values.slice(0, 2);
  return slicedValues.join(', ') + `${values.length > 2 ? '...' : ''}`;
};

const constraints: ts.types.optimizer.ConstraintMetadata[] = [
  {
    constraint_type: ts.enums.OPTIMIZER_CONSTRAINT_TYPE_ENUM.GROSS_LEVERAGE_CONSTRAINT,
    name: constraintsNameMap[ts.enums.OPTIMIZER_CONSTRAINT_TYPE_ENUM.GROSS_LEVERAGE_CONSTRAINT],
    multipleAdd: false,
    group: ts.enums.OPTIMIZER_UI_GROUPS_ENUM.LEVERAGE,
    component: GrossLeverageConstraint,
    helpLabel: 'rzfg61d1',
    defaultParams: {
      lower_bound: 1.98,
      upper_bound: 2.02,
      relax_order: 'NEVER',
      target_penalty: 1.0,
    },
    summaryLabels: (constraint: ts.types.optimizer.Constraint) => {
      return [...getCommonLabels(constraint)];
    },
  },
  {
    constraint_type: ts.enums.OPTIMIZER_CONSTRAINT_TYPE_ENUM.NET_LEVERAGE_CONSTRAINT,
    name: constraintsNameMap[ts.enums.OPTIMIZER_CONSTRAINT_TYPE_ENUM.NET_LEVERAGE_CONSTRAINT],
    multipleAdd: false,
    group: ts.enums.OPTIMIZER_UI_GROUPS_ENUM.LEVERAGE,
    component: NetLeverageConstraint,
    helpLabel: 'ha1eih9i',
    defaultParams: {
      lower_bound: 1.0,
      upper_bound: 1.0,
      relax_order: 'NEVER',
      target_penalty: 1.0,
    },
    summaryLabels: (constraint: ts.types.optimizer.Constraint) => {
      return [...getCommonLabels(constraint)];
    },
  },
  {
    constraint_type: ts.enums.OPTIMIZER_CONSTRAINT_TYPE_ENUM.MAXIMUM_RISK_CONSTRAINT,
    name: constraintsNameMap[ts.enums.OPTIMIZER_CONSTRAINT_TYPE_ENUM.MAXIMUM_RISK_CONSTRAINT],
    multipleAdd: false,
    group: ts.enums.OPTIMIZER_UI_GROUPS_ENUM.PORTFOLIO,
    component: MaximumRisk,
    helpLabel: 'mji0npxr',
    defaultParams: {
      upper_bound: null,
    },
    summaryLabels: (constraint: ts.types.optimizer.Constraint) => {
      return [
        { key: 'Maximum Annualized Risk', value: formatToPercent(constraint.upper_bound) },
        { key: constraintInputNames['relax_order'], value: constraint.relax_order },
        { key: 'Benchmark Relative', value: constraint.benchmark_relative },
      ];
    },
  },
  {
    constraint_type: ts.enums.OPTIMIZER_CONSTRAINT_TYPE_ENUM.TCOST_CONSTRAINT,
    name: constraintsNameMap[ts.enums.OPTIMIZER_CONSTRAINT_TYPE_ENUM.TCOST_CONSTRAINT],
    multipleAdd: false,
    group: ts.enums.OPTIMIZER_UI_GROUPS_ENUM.PORTFOLIO,
    component: MaximumTcost,
    helpLabel: 'h0xp9icj',
    defaultParams: {
      upper_bound: null,
      relax_order: 'FIRST',
    },
    summaryLabels: (constraint: ts.types.optimizer.Constraint) => {
      return [
        { key: constraintInputNames['maximum_t_cost_upper_bound'], value: constraint.upper_bound },
        { key: constraintInputNames['relax_order'], value: constraint.relax_order },
      ];
    },
  },
  {
    constraint_type: ts.enums.OPTIMIZER_CONSTRAINT_TYPE_ENUM.TURNOVER_CONSTRAINT,
    name: constraintsNameMap[ts.enums.OPTIMIZER_CONSTRAINT_TYPE_ENUM.TURNOVER_CONSTRAINT],
    multipleAdd: false,
    group: ts.enums.OPTIMIZER_UI_GROUPS_ENUM.PORTFOLIO,
    component: MaximumTurnover,
    helpLabel: 'gvjmy4ay',
    defaultParams: {
      upper_bound: null,
      relax_order: 'FIRST',
    },
    summaryLabels: (constraint: ts.types.optimizer.Constraint) => {
      return [
        { key: 'Maximum Turnover per Period', value: constraint.upper_bound },
        { key: constraintInputNames['relax_order'], value: constraint.relax_order },
      ];
    },
  },
  {
    constraint_type: ts.enums.OPTIMIZER_CONSTRAINT_TYPE_ENUM.STOCK_LOAN_AVAILABILITY_CONSTRAINT,
    name: constraintsNameMap[ts.enums.OPTIMIZER_CONSTRAINT_TYPE_ENUM.STOCK_LOAN_AVAILABILITY_CONSTRAINT],
    multipleAdd: false,
    group: ts.enums.OPTIMIZER_UI_GROUPS_ENUM.ASSETS,
    component: StockLoanAvailability,
    helpLabel: 'setyre4q',
    defaultParams: {
      default_available_shares: 0,
    } as ts.types.optimizer.StockLoanAvailabilityDraft,
    summaryLabels: (constraint: ts.types.optimizer.Constraint) => {
      return [
        { key: constraintInputNames['default_available_shares'], value: constraint.default_available_shares },
        ...getCommonLabels(constraint),
      ];
    },
  },

  {
    constraint_type: ts.enums.OPTIMIZER_CONSTRAINT_TYPE_ENUM.RISK_FACTOR_CONSTRAINT,
    name: constraintsNameMap[ts.enums.OPTIMIZER_CONSTRAINT_TYPE_ENUM.RISK_FACTOR_CONSTRAINT],
    multipleAdd: true,
    group: ts.enums.OPTIMIZER_UI_GROUPS_ENUM.PORTFOLIO,
    component: RiskFactorConstraint,
    helpLabel: 'd32ibu0p',
    defaultParams: {
      value: [],
      lower_bound: null,
      upper_bound: null,
      grandfathered_lower_bound: null,
      grandfathered_upper_bound: null,
      relax_order: 'FIRST',
      target_penalty: 1.0,
    } as ts.types.optimizer.RiskFactorDraft,
    summaryLabels: (constraint: ts.types.optimizer.Constraint, resources: ts.StoreState['resources']) => {
      const labels = [];

      if (!_.isEmpty(constraint.value)) {
        const values = (constraint.value as string[]).map((v) => resources.signals.find((r) => r.handle == v)?.name);

        labels.push({
          key: 'Risk Factors',
          value: sliceValues(values),
        });
      }
      return [...getCommonLabels(constraint), ...labels];
    },
  },
  {
    constraint_type: ts.enums.OPTIMIZER_CONSTRAINT_TYPE_ENUM.INDUSTRY_CONSTRAINT,
    name: constraintsNameMap[ts.enums.OPTIMIZER_CONSTRAINT_TYPE_ENUM.INDUSTRY_CONSTRAINT],
    multipleAdd: true,
    group: ts.enums.OPTIMIZER_UI_GROUPS_ENUM.PORTFOLIO,
    component: IndustryConstraint,
    helpLabel: 'czc08k0n',
    defaultParams: {
      type: 'RBICS_L1_ID',
      value: [],
      lower_bound: null,
      upper_bound: null,
      grandfathered_lower_bound: null,
      grandfathered_upper_bound: null,
      relax_order: 'FIRST',
      target_penalty: 1.0,
    } as ts.types.optimizer.CategoryConstraint,
    summaryLabels: (constraint: ts.types.optimizer.Constraint, resources: ts.StoreState['resources']) => {
      const rbicsKey =
        ts.enums.MAPPED_RBICS[String(constraint.type).toLowerCase() as keyof typeof ts.enums.MAPPED_RBICS];

      const labels = [];
      if (rbicsKey && !_.isEmpty(constraint.value)) {
        const values = ((constraint.value as string[]) || []).map(
          (v) =>
            resources.rbics_data[rbicsKey].find((r) => r.value == v)?.name
        );

        labels.push({
          key: 'Industries',
          value: sliceValues(values),
        });
      }

      return [...getCommonLabels(constraint, true), ...labels];
    },
  },
  {
    constraint_type: ts.enums.OPTIMIZER_CONSTRAINT_TYPE_ENUM.COUNTRY_CONSTRAINT,
    name: constraintsNameMap[ts.enums.OPTIMIZER_CONSTRAINT_TYPE_ENUM.COUNTRY_CONSTRAINT],
    multipleAdd: true,
    group: ts.enums.OPTIMIZER_UI_GROUPS_ENUM.PORTFOLIO,
    component: CountryConstraint,
    helpLabel: 'oamwlwya',
    defaultParams: {
      type: 'COUNTRY',
      value: [],
      lower_bound: null,
      upper_bound: null,
      grandfathered_lower_bound: null,
      grandfathered_upper_bound: null,
      relax_order: 'FIRST',
      target_penalty: 1.0,
    } as ts.types.optimizer.CategoryConstraint,
    summaryLabels: (constraint: ts.types.optimizer.Constraint) => {
      const labels = [];

      if (!_.isEmpty(constraint.value)) {
        // 'replaceAll(...)' is used to avoid confusion on commas as we have countries with name s
        // like 'Hong Kong, SAR China' which will be turned into 'Hong Kong - SAR China'
        const values = (constraint.value as string[]).map((v) =>
          config.countries.find((r) => r.code == v)?.name.replaceAll(', ', ' - ')
        );

        labels.push({
          key: 'Countries',
          value: sliceValues(values),
        });
      }

      return [...getCommonLabels(constraint, true), ...labels];
    },
  },
  {
    constraint_type: ts.enums.OPTIMIZER_CONSTRAINT_TYPE_ENUM.FACTOR_CONSTRAINT,
    name: constraintsNameMap[ts.enums.OPTIMIZER_CONSTRAINT_TYPE_ENUM.FACTOR_CONSTRAINT],

    // If we add more FACTOR_CONSTRAINTS, we use the method above
    // and migrate the old ones to have the correct type
    multipleAdd: true,
    group: ts.enums.OPTIMIZER_UI_GROUPS_ENUM.PORTFOLIO,
    component: SignalExposure,
    helpLabel: 'qko8yg',
    defaultParams: {
      type: 'SIGNAL_EXPOSURE',
      benchmark_relative: false,
      lower_bound: null,
      upper_bound: null,
      grandfathered_lower_bound: null,
      grandfathered_upper_bound: null,
      relax_order: 'FIRST',
      target_penalty: 1.0,
    } as ts.types.optimizer.SignalExposureDraft,
    summaryLabels: (constraint: ts.types.optimizer.Constraint, resources: ts.StoreState['resources']) => {
      const signal = resources.signals.find((r) => r.id == constraint.signal_id);
      const labels = [
        {
          key: 'Signal',
          value: signal?.name,
        },
      ];

      if (!_.isEmpty(constraint.pipeline_ids)) {
        const values = (constraint.pipeline_ids as number[]).map((v) =>
          resources.pipelines.find((r) => r.id == v)?.name.replaceAll(', ', ' - ')
        );

        labels.push({
          key: 'Pipelines',
          value: sliceValues(values),
        });
      }

      return [...getCommonLabels(constraint), ...labels];
    },
  },
  {
    constraint_type: ts.enums.OPTIMIZER_CONSTRAINT_TYPE_ENUM.TRADE_LIST_CONSTRAINT,
    name: constraintsNameMap[ts.enums.OPTIMIZER_CONSTRAINT_TYPE_ENUM.TRADE_LIST_CONSTRAINT],
    multipleAdd: true,
    group: ts.enums.OPTIMIZER_UI_GROUPS_ENUM.ASSETS,
    component: TradeListConstraint,
    helpLabel: 'hdjhok65',
    defaultParams: {
      asset_fids: [],
      trade_list_constraint_type: 'DONT_BUY',
    } as ts.types.optimizer.TradeListConstraintDraft,
    summaryLabels: (constraint: ts.types.optimizer.Constraint) => {
      return [
        ...getCommonLabels(constraint),
        { key: constraintInputNames['trade_list_constraint_type'], value: constraint.trade_list_constraint_type },
        { key: 'Assets', value: sliceValues(constraint.asset_fids as string[]) },
      ];
    },
  },
  {
    constraint_type: ts.enums.OPTIMIZER_CONSTRAINT_TYPE_ENUM.TRADE_LIST_SIGNAL_EXPOSURE_CONSTRAINT,
    name: constraintsNameMap[ts.enums.OPTIMIZER_CONSTRAINT_TYPE_ENUM.TRADE_LIST_SIGNAL_EXPOSURE_CONSTRAINT],
    multipleAdd: true,
    group: ts.enums.OPTIMIZER_UI_GROUPS_ENUM.ASSETS,
    component: TradeListSignalExposureConstraint,
    helpLabel: 'q2l5fs',
    defaultParams: {
      trade_list_constraint_type: 'DONT_BUY',
    } as ts.types.optimizer.TradeListSignalExposureConstraintDraft,
    summaryLabels: (constraint: ts.types.optimizer.Constraint, resources: ts.StoreState['resources']) => {
      const signal = resources.signals.find((r) => r.id == constraint.signal_id);
      const labels = [
        {
          key: 'Signal',
          value: signal?.name,
        },
      ];

      return [
        ...getCommonLabels(constraint),
        ...labels,
        { key: 'Constraint Type', value: constraint.trade_list_constraint_type },
      ];
    },
  },
  {
    constraint_type: ts.enums.OPTIMIZER_CONSTRAINT_TYPE_ENUM.ALL_ASSETS_CONSTRAINT,
    name: constraintsNameMap[ts.enums.OPTIMIZER_CONSTRAINT_TYPE_ENUM.ALL_ASSETS_CONSTRAINT],
    component: AllAssets,
    group: ts.enums.OPTIMIZER_UI_GROUPS_ENUM.ASSETS,
    multipleAdd: true,
    helpLabel: '2vht00et',
    defaultParams: {
      upper_bound: null,
      grandfathered_lower_bound: null,
      grandfathered_upper_bound: null,
      relax_order: 'NEVER',
    } as ts.types.optimizer.AssetAllDraft,
    summaryLabels: (constraint: ts.types.optimizer.Constraint) => {
      return [...getCommonLabels(constraint, true)];
    },
  },
  {
    constraint_type: ts.enums.OPTIMIZER_CONSTRAINT_TYPE_ENUM.ASSET_VOLUME_CONSTRAINT,
    name: constraintsNameMap[ts.enums.OPTIMIZER_CONSTRAINT_TYPE_ENUM.ASSET_VOLUME_CONSTRAINT],
    multipleAdd: false,
    group: ts.enums.OPTIMIZER_UI_GROUPS_ENUM.ASSETS,
    component: AssetVolume,
    helpLabel: '64cbosk2',
    defaultParams: {
      max_position_days: null,
      max_trade_days: null,
      trade_days_decrease_multiplier: 1,
      exempt_funding_trade: true,
      relax_order: 'NEVER',
    } as ts.types.optimizer.AssetVolumeDraft,
    summaryLabels: (constraint: ts.types.optimizer.Constraint) => {
      return [
        ...getCommonLabels(constraint),
        { key: constraintInputNames['max_position_days'], value: constraint.max_position_days },
        { key: [constraintInputNames['max_trade_days']], value: constraint.max_trade_days },
        { key: 'Trade Days Decrease Multiplier', value: constraint.trade_days_decrease_multiplier },
        { key: 'Exempt Funding Trade', value: constraint.exempt_funding_trade },
      ];
    },
  },
  {
    constraint_type: ts.enums.OPTIMIZER_CONSTRAINT_TYPE_ENUM.SPECIFIC_ASSETS_CONSTRAINT,
    name: constraintsNameMap[ts.enums.OPTIMIZER_CONSTRAINT_TYPE_ENUM.SPECIFIC_ASSETS_CONSTRAINT],
    multipleAdd: true,
    group: ts.enums.OPTIMIZER_UI_GROUPS_ENUM.ASSETS,
    component: SpecificAssets,
    helpLabel: 'beteic67',
    defaultParams: {
      lower_bound: null,
      upper_bound: null,
      grandfathered_lower_bound: null,
      grandfathered_upper_bound: null,
      relax_order: 'NEVER',
      target_penalty: 1.0,
      asset_fids: [],
    } as ts.types.optimizer.SpecificAssetsDraft,
    summaryLabels: (constraint: ts.types.optimizer.Constraint) => {
      return [...getCommonLabels(constraint), { key: 'Assets', value: sliceValues(constraint.asset_fids as string[]) }];
    },
  },
  {
    constraint_type: ts.enums.OPTIMIZER_CONSTRAINT_TYPE_ENUM.SHARES_OUTSTANDING_CONSTRAINT,
    name: constraintsNameMap[ts.enums.OPTIMIZER_CONSTRAINT_TYPE_ENUM.SHARES_OUTSTANDING_CONSTRAINT],
    multipleAdd: true,
    group: ts.enums.OPTIMIZER_UI_GROUPS_ENUM.ASSETS,
    component: SharesOutstanding,
    helpLabel: '6apup9rf',
    defaultParams: {
      lower_shares_outstanding_bound: null,
      upper_shares_outstanding_bound: null,
      asset_fids: [],
    } as ts.types.optimizer.SharesOutstadingDraft,
    summaryLabels: (constraint: ts.types.optimizer.Constraint) => {
      return [
        {
          key: 'Shares Outstanding Lower Bound',
          value: constraint.lower_shares_outstanding_bound ? `${constraint.lower_shares_outstanding_bound}%` : null,
        },
        {
          key: 'Shares Outstanding Upper Bound',
          value: constraint.upper_shares_outstanding_bound ? `${constraint.upper_shares_outstanding_bound}%` : null,
        },
        {
          key: 'Assets',
          value: _.isEmpty(constraint.asset_fids) ? 'All' : sliceValues(constraint.asset_fids as string[]),
        },
      ];
    },
  },
  {
    constraint_type: ts.enums.OPTIMIZER_CONSTRAINT_TYPE_ENUM.SHORT_SHARES_ASSETS_CONSTRAINT,
    name: constraintsNameMap[ts.enums.OPTIMIZER_CONSTRAINT_TYPE_ENUM.SHORT_SHARES_ASSETS_CONSTRAINT],
    multipleAdd: false,
    group: ts.enums.OPTIMIZER_UI_GROUPS_ENUM.ASSETS,
    component: LocateConstraint,
    helpLabel: 'kunpefcz',
    groupUnderComponent: true,
    disableAdd: true,
  },
];

export default constraints;
