import { _, React, ts } from '_core';

import { transform } from './helpers';
import { Params } from './types';
import ChartWrapper from '../../common/chart-wrapper';
import createNestedBarChart from '../../common/charts/create-nested-bar-chart';

type ChartProps = {
  id: string;
  readOnly: boolean;
  widgetKey: string;
  loadWidgetData: (_payload: ts.types.widgets.WidgetGetDataParams) => Promise<ts.types.widgets.WidgetGetDataResponse>;
  returns: string;
  fullScreen: boolean;
  params: Params;
  frequency: ts.enums.FREQUENCY_ENUM;
  title: string;
};

const Chart: React.FC<ChartProps> = ({
  id,
  readOnly,
  widgetKey,
  loadWidgetData,
  returns,
  fullScreen,
  params,
  title,
}) => {
  const [data, setData] = React.useState<ts.types.widgets.TableData>(null);
  const [error, setError] = React.useState<ts.types.common.ApiError>(null);
  const [showLegend, setShowLegend] = React.useState(false);

  const loadData = async () => {
    try {
      const response = await loadWidgetData({
        data: {
          file: returns,
          columns: ['quantiles', ...params.histories],
        },
      });

      if (response) {
        setData(response.data);
      }
    } catch (err) {
      setError(err as ts.types.common.ApiError);
    }
  };

  React.useEffect(() => {
    if (returns) loadData();
    return () => setData(null);
  }, [returns]);

  React.useEffect(() => {
    // Create line chart on data and scale changes
    if (!_.isNil(data)) {
      createNestedBarChart({
        id,
        data: data,
        fullScreen,
        setShowLegend,
        exportTitle: title,
        category: 'quantiles',
        prepareData: (nestedBarData) => transform(nestedBarData, params),
      });
    }
  }, [data]);

  return (
    <>
      <ChartWrapper
        id={id}
        readOnly={readOnly}
        widgetKey={widgetKey}
        data={data}
        showLegend={showLegend}
        warning={data?.length === 0 ? ['Empty File'] : []}
        error={error}
        fullScreen={fullScreen}
      />
    </>
  );
};

export default Chart;
