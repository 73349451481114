/* eslint-disable no-unused-vars */

export enum INTERACTION_PARAMS {
  BOOKMARKED = 'bookmarked',
}

export type InteractionParams = {
  [INTERACTION_PARAMS.BOOKMARKED]: string[];
};

export type JobParams = {
  set_params: Record<string, any>[];
  report_default_id: number;
};

export type Params = JobParams & InteractionParams;
export type DraftParams = Partial<Params>;
