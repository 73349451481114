import { React, Route, Switch, ui, useHistory, useLocation } from '_core';

import EmbeddedWidget from '../views/report/embedded-widget';

const Global = (): React.ReactElement => {
  const location = useLocation();
  const history = useHistory();

  // Function to update the URL with only the random query parameter to invalidate the cache
  const invalidateCache = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const randomQueryParam = `${Math.random().toString(36).substring(7)}`;

    urlParams.set('cache', randomQueryParam);

    // Remove existing query parameters, if any
    const pathname = location.pathname;
    const search = `?${urlParams.toString()}`;

    history.replace({
      pathname,
      search,
    });
  };

  React.useEffect(() => {
    invalidateCache();
  }, []);

  return (
    <Switch>
      <Route
        path={'/_global/embedded/:widget_key'}
        render={({ match }) => <EmbeddedWidget widgetKey={match.params.widget_key} />}
      />
      <Route path="*">
        <ui.NotFound />
      </Route>
    </Switch>
  );
};

export default Global;
