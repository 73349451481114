import { hooks, mui, React, ui } from '_core';

import { DraftParams, Params } from './types';

type FormProps = {
  params: DraftParams;
  setParams: (_p: Params) => void;
};

const Form: React.FC<FormProps> = ({ params, setParams }): React.ReactElement => {
  const [window, setWindow] = React.useState(params.window);
  const [unlevered, setUnlevered] = React.useState(params.unlevered);

  const uiStyles = hooks.useUiStyles();

  React.useEffect(() => {
    const data = { ...params, window, unlevered };
    setParams(data);
  }, [window, unlevered]);

  return (
    <mui.core.Box sx={uiStyles.widgetEditMode}>
      <mui.core.Grid container spacing={4}>
        <mui.core.Grid item xs={12} md={7}>
          <ui.NumericField
            textFieldProps={{
              label: 'Forward Window (days)',
              fullWidth: true,
              variant: 'outlined',
              size: 'small',
              required: true,
            }}
            value={window}
            setValue={(v) => setWindow(v)}
          />
        </mui.core.Grid>
        {!params.is_signal && (
          <mui.core.Grid item xs={12} md={5}>
            <mui.core.FormControlLabel
              control={<mui.core.Switch size="small" checked={unlevered} onChange={() => setUnlevered(!unlevered)} />}
              label="Unlevered Portfolio"
            />
          </mui.core.Grid>
        )}
      </mui.core.Grid>
    </mui.core.Box>
  );
};

export default Form;
