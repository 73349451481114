import { _, helpers, mui, React, ts, ui,useHistory, useSelector } from '_core';

import * as utils from './risk-model-utils';

type RiskModelFormProps = {
  open: boolean;
  setOpen: (_open: boolean) => void;
};

const RiskModelForm: React.FC<RiskModelFormProps> = ({ open, setOpen }): React.ReactElement => {
  const riskModels = useSelector((state) => state.resources.risk_models as ts.types.riskModel.RiskModel[]);
  const resources = useSelector((state) => state.resources);
  const workspaces = useSelector((state) => state.resources.workspaces as ts.types.workspace.Workspace[]);
  const currentWorkspaceId = useSelector((state) => state.ui.currentWorkspaceId as number);

  const history = useHistory();

  const [errorMessage, setErrorMessage] = React.useState('');
  const [name, setName] = React.useState('');
  const [handle, setHandle] = React.useState('');
  const [paramsType, setParamsType] = React.useState<'FACTOR' | 'STAT'>('FACTOR');
  const [template, setTemplate] = React.useState<ts.types.riskModel.RiskModel>();
  const [loadingCreate, setLoadingCreate] = React.useState(false);

  const [workspace, setWorkspace] = React.useState(workspaces.find((w) => w.id == currentWorkspaceId) || workspaces[0]);

  // Create handle while typing name
  React.useEffect(() => {
    setHandle(helpers.createHandle(name, 'rm'));
  }, [name]);

  const handleSubmit = async () => {
    setErrorMessage('');
    setLoadingCreate(true);

    // Validation
    if (!name || !handle) {
      setErrorMessage('All attributes are required.');
      setLoadingCreate(false);
      return;
    }

    const newRiskModel = utils.definition.getNewRiskModel(name, handle, paramsType, resources, template);
    utils.apiProcedures.createRiskModel(
      newRiskModel,
      async (data) => {
        if (workspace)
          await helpers.resourcesUtils.addToWorkspace(data.id, ts.enums.UI_RESOURCE_TYPE_ENUM.RISK_MODEL, workspace);
        setOpen(false);
        history.push(`/risk_models/${data.id}`);
      },
      async (err) => {
        setErrorMessage(err);
        setLoadingCreate(false);
      }
    );
  };

  const formContent = () => (
    <mui.core.Box mt={3}>
      <mui.core.Grid container spacing={3}>
        <mui.core.Grid item xs={12} sm={6}>
          <mui.core.TextField
            autoFocus
            id="name"
            label="Risk model name"
            value={name}
            variant="outlined"
            onChange={(e) => setName(e.target.value)}
            fullWidth
            required
            size="small"
            inputProps={{
              autocomplete: 'off',
            }}
          />
        </mui.core.Grid>
        <mui.core.Grid item xs={12} sm={6}>
          <mui.core.TextField
            id="handle"
            label="Risk model handle"
            value={handle}
            variant="outlined"
            onChange={(e) => setHandle(e.target.value)}
            fullWidth
            required
            size="small"
          />
        </mui.core.Grid>
      </mui.core.Grid>

      <mui.core.Box pt={4}>
        <mui.core.TextField
          id="type"
          select
          label="Parameters type"
          variant="outlined"
          value={paramsType}
          onChange={(e) => {
            setParamsType(e.target.value as typeof paramsType);
            setTemplate(null);
          }}
          fullWidth
          required
          size="small"
        >
          <mui.core.MenuItem value="FACTOR">Factor-based</mui.core.MenuItem>
          <mui.core.MenuItem value="STAT">Statistical</mui.core.MenuItem>
        </mui.core.TextField>
      </mui.core.Box>
      <mui.core.Box py={4}>
        <ui.ResourceAutocomplete<ts.types.common.ResourceExpanded>
          enableNone
          value={template?.id}
          setValue={(id) => setTemplate(riskModels.find((u) => u.id == id))}
          type={ts.enums.RESOURCES_TYPES_ENUM.RISK_MODEL}
          inputProps={{
            fullWidth: true,
            label: 'Template',
            variant: 'outlined',
            required: true,
            size: 'small',
          }}
          filter={(b) => {
            const definition = (b as ts.types.riskModel.RiskModel)?.definition;
            return !b.source_resource_id && !_.isEmpty(definition?.parameters);
          }}
        />
      </mui.core.Box>
      <ui.WorkspaceSelector value={workspace} setValue={setWorkspace} />
    </mui.core.Box>
  );

  return (
    <mui.core.Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="sm"
      fullWidth
    >
      {errorMessage && (
        <mui.lab.Alert onClose={() => setErrorMessage('')} severity="error">
          {errorMessage}
        </mui.lab.Alert>
      )}
      <ui.DialogTitle closeAction={() => setOpen(false)}>Create Risk Model</ui.DialogTitle>

      <mui.core.DialogContent>{formContent()}</mui.core.DialogContent>
      <mui.core.DialogActions>
        <ui.Button
          variant="contained"
          color="primary"
          loading={loadingCreate}
          onClick={handleSubmit}
          fullWidth
          style={{ margin: '0rem 1rem 1rem' }}
        >
          Create Risk Model
        </ui.Button>
      </mui.core.DialogActions>
    </mui.core.Dialog>
  );
};

export default RiskModelForm;
