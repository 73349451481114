import { hooks, mui, React, ts, ui } from '_core';

import { DraftParams } from './types';

type FormProps = {
  params: DraftParams;
  setParams: (_p: DraftParams) => void;
  context: ts.types.signalSet.SignalSetAnalysisContext;
};

const Form: React.FC<FormProps> = ({ params, setParams, context }) => {
  const [returnHorizon, setReturnHorizon] = React.useState<ts.types.returnHorizon.ReturnHorizon>(params.return_horizon);
  const [benchmark, setBenchmark] = React.useState(params.set_params?.[0].file_params?.benchmark || null);
  const [selectedSignal, setSelectedSignal] = React.useState(params.analysis_pipelined_signals?.[0]?.signal_id);
  const [selectedPipeline, setSelectedPipeline] = React.useState(params.analysis_pipelined_signals?.[0]?.pipeline_id);
  const [selectedSpPipeline, setSelectedSpPipeline] = React.useState(
    params.analysis_pipelined_signals?.[0]?.sp_pipeline_id
  );

  const [selectedType, setSelectedType] = React.useState(params.selected_type || 'signal');

  const uiStyles = hooks.useUiStyles();

  React.useEffect(() => {
    const localBenchmark = selectedType == 'benchmark' ? benchmark : undefined;
    const localSignalID = selectedType == 'signal' ? selectedSignal : undefined;
    const localPipelineId = selectedType == 'signal' && selectedPipeline ? selectedPipeline : undefined;
    const localSpPipelineId = selectedType == 'signal' && selectedSpPipeline ? selectedSpPipeline : undefined;

    const analysisPipelinedSignals =
      selectedType == 'signal'
        ? [
            {
              signal_id: localSignalID,
              pipeline_id: localPipelineId,
              sp_pipeline_id: localSpPipelineId,
            },
          ]
        : undefined;

    const data = {
      ...params,
      selected_type: selectedType,
      return_horizon: returnHorizon,
      analysis_pipelined_signals: analysisPipelinedSignals,
      set_params: [
        {
          id: 'report' as const, // Fixed id to get the file from payload on index
          file: 'signal_relative_returns' as const,
          file_params: {
            return_horizon: returnHorizon,
            benchmark: localBenchmark,
            analysis_pipelined_signals: analysisPipelinedSignals,
          },
          aggregation_params: {},
          aggregation: 'fin-gen-sp-returns',
        },
      ],
    };
    setParams(data);
  }, [returnHorizon, benchmark, selectedSignal, selectedPipeline, selectedSpPipeline, selectedType]);

  return (
    <mui.core.Box sx={uiStyles.widgetEditMode}>
      <mui.core.Box>
        <mui.core.FormControl component="fieldset">
          <mui.core.RadioGroup
            row
            aria-label="position"
            name="position"
            value={selectedType}
            onChange={(e) => setSelectedType(e.target.value as 'benchmark' | 'signal')}
          >
            <mui.core.FormControlLabel value="signal" control={<mui.core.Radio color="primary" />} label="Signal" />
            <mui.core.FormControlLabel
              value="benchmark"
              control={<mui.core.Radio color="primary" />}
              label="Benchmark"
            />
          </mui.core.RadioGroup>
        </mui.core.FormControl>
      </mui.core.Box>

      <mui.core.Box mt={2}>
        {selectedType == 'signal' ? (
          <mui.core.Grid container spacing={2}>
            <mui.core.Grid item xs={4}>
              <ui.ResourceAutocomplete<ts.types.signal.SignalExpanded>
                value={selectedSignal}
                setValue={(id) => setSelectedSignal(id)}
                type={ts.enums.RESOURCES_TYPES_ENUM.SIGNAL}
                filter={(el) => !context || !!context.signals.find((signal) => signal.signal_id == el.id)}
                inputProps={{
                  label: 'Signal',
                  variant: 'outlined',
                  size: 'small',
                  required: true,
                }}
              />
            </mui.core.Grid>
            <mui.core.Grid item xs={4}>
              <ui.ResourceAutocomplete<ts.types.pipeline.PipelineExpanded>
                value={selectedPipeline}
                setValue={(id) => setSelectedPipeline(id)}
                enableNone
                type={ts.enums.RESOURCES_TYPES_ENUM.PIPELINE}
                filter={(el) => !context || !!context.signals.find((signal) => signal.pipeline_id == el.id)}
                inputProps={{
                  label: 'Pipeline',
                  variant: 'outlined',
                  size: 'small',
                }}
              />
            </mui.core.Grid>
            <mui.core.Grid item xs={4}>
              <ui.ResourceAutocomplete<ts.types.pipeline.PipelineExpanded>
                value={selectedSpPipeline}
                setValue={(id) => setSelectedSpPipeline(id)}
                enableNone
                type="sp_pipeline"
                filter={(el) => !context || !!context.signals.find((signal) => signal.sp_pipeline_id == el.id)}
                inputProps={{
                  label: 'SP Pipeline',
                  variant: 'outlined',
                  size: 'small',
                }}
              />
            </mui.core.Grid>
          </mui.core.Grid>
        ) : (
          <ui.Benchmark
            value={benchmark || undefined}
            setValue={setBenchmark}
            universeContextPath={'$universe.handle'}
            context={context}
            universeIdPath="universe_id"
          />
        )}
      </mui.core.Box>

      <mui.core.Box mt={4}>
        <ui.WidgetReturnHorizon<false>
          value={returnHorizon}
          setValue={(val) => setReturnHorizon(val)}
          context={context}
        />
      </mui.core.Box>
    </mui.core.Box>
  );
};

export default Form;
