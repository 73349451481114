/* eslint-disable */

import { UniverseExpanded } from '../../../types/universe';
import { Universe } from '../../../types/universe';
import { BaseStore } from '../../../types/base-store';
import { Resource } from '../../../types/common';
import { assetTypes, countries } from '../../../data';

const expandClassifier = (
  jsonDef: string,
  classifiersRedux: Resource[] | Record<string, any>,
  regex: RegExp,
  nameAttr = 'name',
  codeAttr = 'code'
): string[][] => {
  const rxMatch = regex.exec(jsonDef);
  let universeClassifiers: Record<string, any>[] = [];
  if (rxMatch && rxMatch[1]) universeClassifiers = JSON.parse(rxMatch[1]);
  return classifiersRedux
    .filter((c: Record<string, any>) => universeClassifiers.includes(c[codeAttr]))
    .map((c: Record<string, any>) => [c[codeAttr], c[nameAttr]]);
};

const expandUniverse = (
  universe: Universe & { resourceType: string },
  resources: BaseStore['resources']
): UniverseExpanded => {
  let expanded: string[][] = [];
  const jsonDefinition = JSON.stringify(universe.definition || '{}');
  // Expand currencies
  expanded = expanded.concat(
    expandClassifier(
      jsonDefinition,
      resources.currencies,
      /\"currency\"\s?,\s?\"IN\"\s*,\s*(\[[\w\",\s]*\])\]+/,
      'name',
      'alpha_code'
    )
  );

  // Expand countries
  expanded = expanded.concat(
    expandClassifier(jsonDefinition, countries, /\"quotation_country\"\s?,\s?\"IN\"\s*,\s*(\[[\w\",\s]*\])\]+/)
  );

  expanded = expanded.concat(
    expandClassifier(jsonDefinition, countries, /\"issuer_country\"\s?,\s?\"IN\"\s*,\s*(\[[\w\",\s]*\])\]+/)
  );

  // Expand rbics data
  expanded = expanded.concat(
    expandClassifier(
      jsonDefinition,
      resources.rbics_data.levelTwo,
      /\"rbics_l2_id\"\s?,\s?\"IN\"\s*,\s*(\[[\w\",\s]*\])\]+/,
      'name',
      'value'
    )
  );

  expanded = expanded.concat(
    expandClassifier(
      jsonDefinition,
      resources.rbics_data.levelOne,
      /\"rbics_l1_id\"\s?,\s?\"IN\"\s*,\s*(\[[\w\",\s]*\])\]+/,
      'name',
      'value'
    )
  );

  expanded = expanded.concat(
    expandClassifier(
      jsonDefinition,
      resources.rbics_data.levelThree,
      /\"rbics_l3_id\"\s?,\s?\"IN\"\s*,\s*(\[[\w\",\s]*\])\]+/,
      'name',
      'value'
    )
  );

  expanded = expanded.concat(
    expandClassifier(
      jsonDefinition,
      resources.rbics_data.levelFour,
      /\"rbics_l4_id\"\s?,\s?\"IN\"\s*,\s*(\[[\w\",\s]*\])\]+/,
      'name',
      'value'
    )
  );

  expanded = expanded.concat(
    expandClassifier(
      jsonDefinition,
      assetTypes,
      /\"asset_type\"\s?,\s?\"IN\"\s*,\s*(\[[\w\",\s]*\])\]+/,
      'name',
      'value'
    )
  );

  return {
    id: universe.id,
    name: universe.name,
    handle: universe.handle,
    resourceType: universe.resourceType,
    created_by_id: universe.created_by_id,
    label_ids: universe.label_ids,
    definition: expanded.flat(),
    user_provided: universe.user_provided,
    is_published: universe.is_published,
    is_deprecated: universe.is_deprecated,
    revoked: universe.revoked || false,
    is_valid: universe.is_valid,
    created_at: universe.created_at,
    source_resource_id: universe.source_resource_id,
    organization_id: universe.organization_id,
    has_description: universe.has_description,
    short_description: universe.short_description,
    source_org_id: universe.source_org_id,
    shared: universe.shared,
  } as UniverseExpanded;
};

export default expandUniverse;
