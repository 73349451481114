import { Sentry } from '../libs';

/**
 * Interface for API error response containing JSON data
 */
type apiError = {
  responseJSON: any;
};

/**
 * Interface for parsed API response containing error details
 */
type apiResponse = {
  detail: string | any[];
};

/**
 * Parses API error responses and formats them into user-friendly error messages
 * @param e - API error response object
 * @param performTransform - Optional function to transform error message strings
 * @returns Formatted error message string
 */
const parseApiError = (e: apiError, performTransform: (_s: string) => string = (s) => s): string => {
  const response: apiResponse = e.responseJSON;
  // Handle errors coming from the api
  if (response) {
    // If there is an array with errors
    if (Array.isArray(response.detail))
      return performTransform(`${response.detail[0].loc.join(',')} ${response.detail[0].msg}`);
    // If there is only a message
    return performTransform(response.detail) ?? 'Internal server error';
  }

  // This are errors thrown by the ui
  if (e instanceof Error) {
    Sentry.withScope((scope) => {
      scope.setTag('manual', 'true');
      if (e.message) {
        Sentry.captureException(e);
      } else {
        Sentry.captureException(new Error((e as any)?.message || JSON.stringify(e)));
      }
    });
    return e.message ?? 'Internal server error';
  }

  return 'Internal server error';
};

export default parseApiError;
