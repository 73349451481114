import { _, moment, ts } from '_core';

import { DraftParams } from './types';

export const validate = (
  params: DraftParams,
  context: ts.types.backtest.BacktestAnaylsisContext | ts.types.basket.BasketAnalysisContext
) => {
  let errors = [];
  if (_.isEmpty(params.comparison_date_1)) errors.push('First date is a required field.');
  if (_.isEmpty(params.comparison_date_2)) errors.push('Second date is a required field.');

  if (!_.isEmpty(params.comparison_date_1) && !_.isEmpty(params.comparison_date_2)) {
    if (moment(params.comparison_date_1).isAfter(moment(params.comparison_date_2)))
      errors.push('First date must be before Second date.');
  }

  if (context) {
    if (
      !_.isEmpty(params.comparison_date_1) &&
      (moment(params.comparison_date_1).isBefore(moment(context.start_date)) ||
        moment(params.comparison_date_1).isAfter(moment(context.end_date)))
    )
      errors.push('First date must be inside the universe dates.');
    if (
      !_.isEmpty(params.comparison_date_2) &&
      (moment(params.comparison_date_2).isAfter(moment(context.end_date)) ||
        moment(params.comparison_date_2).isBefore(moment(context.start_date)))
    )
      errors.push('Second date must be inside the universe dates.');
  }

  if (!_.isEmpty(params.component_errors)) errors = errors.concat(params.component_errors);
  return errors;
};
