import { _, hooks, mui, React, ts, ui, useSelector } from '_core';

import * as columns from 'views/preferences/columns-config';
import AsyncTabular from 'views/report/tables/async-tabular';

import { Params } from './types';
import { getColumns } from '../../common/charts/utils';

type ChartProps = {
  id: string;
  loadWidgetData: (_payload: ts.types.widgets.WidgetGetDataParams) => Promise<ts.types.widgets.WidgetGetDataResponse>;
  params: Params;
  setParams: (_p: Params) => void;
  fullScreen: boolean;
  goFullScreen: (_v: string) => void;
  context: ts.types.universe.UniversePreviewContext;
  dateComparison: string;

  queryParams?: { initial?: string };
};

const Chart: React.FC<ChartProps> = ({
  id,
  loadWidgetData,
  dateComparison,
  fullScreen,
  goFullScreen,
  queryParams,
  context,
  params,
  setParams,
}) => {
  const resources = useSelector((state) => state.resources);

  const [show, setShow] = React.useState(ts.enums.UNIVERSE_PREVIEW_MEMBERS_ENUM.SHOW_ALL);
  const [filter, setFilter] = React.useState<ts.types.components.dataGrid.ValueFilters>([]);
  const [dataCount, setDataCount] = React.useState('Loading...');

  const [displayData, setDisplayData] =
    React.useState<(ts.types.widgets.TableData[0] & { diff?: 'removed' | 'added' })[]>(null);

  const container = React.createRef();

  const [tableParams, setTableParams] = React.useState<ts.types.components.dataGrid.TableParamsSheet>(
    params.table_params
  );

  const [tableDtypes, setTableDtypes] = React.useState<ts.types.components.dataGrid.TableDtypesSheet>(
    params.table_dtypes
  );

  hooks.useEffectWithoutFirst(() => {
    setParams({
      ...params,
      table_params: { ...tableParams },
      table_dtypes: { ...tableDtypes },
    });
  }, [tableParams, tableDtypes]);

  const loadData = async (
    offset: number,
    orderBy: ts.types.components.dataGrid.OrderBy,
    filters: ts.types.components.dataGrid.ValueFilters,
    logicOperator: mui.dataGrid.GridLogicOperator
  ) => {
    const response = await loadWidgetData({
      data: {
        file: dateComparison,
        pagination: { offset, limit: 100 },
        order_by: orderBy,
        values_filter: filters,
        filter_conjunction: logicOperator,
      },
    });

    setDataCount(String(response?.pagination.count));

    return response;
  };

  hooks.useEffectWithoutFirst(() => {
    if (show == ts.enums.UNIVERSE_PREVIEW_MEMBERS_ENUM.ONLY_ADDED) setFilter([{ column: 'diff', value: 'added' }]);
    else if (show == ts.enums.UNIVERSE_PREVIEW_MEMBERS_ENUM.ONLY_REMOVED)
      setFilter([{ column: 'diff', value: 'removed' }]);
    else setFilter([]);
  }, [show]);

  const preferencesButton = () => (
    <mui.core.Box display="flex" width="100%">
      <mui.core.Box flexGrow={1}>
        <mui.core.Stack flexDirection="row" gap={4}>
          <ui.InfoRow title="First Date">{params.comparison_date_1}</ui.InfoRow>
          <ui.InfoRow title="Second Date">{params.comparison_date_2}</ui.InfoRow>
          <mui.core.RadioGroup
            row
            name="universe_members"
            value={show}
            onChange={(e) => {
              setShow(e.target.value as ts.enums.UNIVERSE_PREVIEW_MEMBERS_ENUM);
            }}
            style={{ marginTop: '-5px', display: 'flex' }}
          >
            <mui.core.FormControlLabel
              value={ts.enums.UNIVERSE_PREVIEW_MEMBERS_ENUM.SHOW_ALL}
              control={<mui.core.Radio color="primary" />}
              label="Show All"
              labelPlacement="end"
            />
            <mui.core.FormControlLabel
              value={ts.enums.UNIVERSE_PREVIEW_MEMBERS_ENUM.ONLY_ADDED}
              control={<mui.core.Radio color="primary" />}
              label="Only Added"
              labelPlacement="end"
            />
            <mui.core.FormControlLabel
              value={ts.enums.UNIVERSE_PREVIEW_MEMBERS_ENUM.ONLY_REMOVED}
              control={<mui.core.Radio color="primary" />}
              label="Only Removed"
              labelPlacement="end"
            />
          </mui.core.RadioGroup>

          {!_.isNil(displayData?.length) && <ui.InfoRow title={_.startCase(show)}>{dataCount}</ui.InfoRow>}
        </mui.core.Stack>
      </mui.core.Box>
    </mui.core.Box>
  );

  const downloadData = async (
    rename: Record<string, string>,
    columnOrder: string[],
    filters: ts.types.components.dataGrid.ValueFilters
  ) => {
    const response = await loadWidgetData({
      data: {
        file: dateComparison,
        file_name: 'universe_preview_date_comparison.csv',
        rename,
        column_order: columnOrder,
        values_filter: filters,
      },
      download: true,
    });
    return response;
  };

  const renderChart = () => (
    <>
      <AsyncTabular<ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.MAIN>
        key={dateComparison}
        loadData={{
          [ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.MAIN]: loadData,
        }}
        getColumns={{
          [ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.MAIN]: (colKeys, userColumnsPreferences) =>
            getColumns(
              colKeys,
              userColumnsPreferences[ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.MAIN],
              columns.universePreviewColumns[ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.MAIN],
              context,
              resources
            ),
        }}
        sheetTitles={{
          [ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.MAIN]: 'Date Comparison',
        }}
        fullScreen={fullScreen}
        goFullScreen={goFullScreen}
        queryParams={queryParams}
        customToolBar={{
          [ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.MAIN]: preferencesButton,
        }}
        downloadFullData={{
          [ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.MAIN]: downloadData,
        }}
        onDataChange={setDisplayData}
        sheetFilters={{
          [ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.MAIN]: filter,
        }}
        rowClass={{
          [ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.MAIN]: (row: (typeof displayData)[0]) =>
            row.diff == 'removed' ? 'data-grid-row-red' : row.diff == 'added' ? 'data-grid-row-green' : undefined,
        }}
        preferences={{
          preferencesSourceWidgetId: id,
          preferenceKey: ts.enums.PREFERENCES_KEY_ENUM.UNIVERSE_COMPARISON_COLS,
          preferenceTab: ts.enums.PREFERENCES_INNER_TABS_ENUM.UNI_DATE_COMPARISON_COLS,
        }}
        tableParams={tableParams}
        setTableParams={{
          [ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.MAIN]: (v) =>
            setTableParams((t) => ({
              ...t,
              [ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.MAIN]: v,
            })),
        }}
        tableDtypes={tableDtypes}
        setTableDtypes={{
          [ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.MAIN]: (v) =>
            setTableDtypes((t) => ({
              ...t,
              [ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.MAIN]: v,
            })),
        }}
        hideDataCount
      />
    </>
  );

  return (
    <mui.core.Box display="flex" flexDirection="column" style={{ height: '100%' }} ref={container}>
      {!_.isNil(dateComparison) && renderChart()}
    </mui.core.Box>
  );
};

export default Chart;
