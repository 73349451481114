import { mui, React, ts } from '_core';

import { Box } from '@mui/material';

import CodeBlock from './code-block';

const MessageLog: React.FC<{
  message: ts.types.aiAssistant.Message;
  onfinish?: () => void;
}> = ({ message, onfinish }) => {
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    onfinish();
  }, []);

  const renderMessageLogText = () => {
    return (
      <mui.core.Box width="100%" mt={2}>
        <mui.core.Button
          onClick={() => setOpen((o) => !o)}
          size="small"
          color="secondary"
          sx={{
            fontSize: '12px',
            fontWeight: '500',
          }}
          startIcon={<mui.icons.AccessTime sx={{ fontSize: '14px!important' }} />}
        >
          Logs
          {open ? (
            <mui.icons.ExpandLess sx={{ fontSize: '14px' }} />
          ) : (
            <mui.icons.ExpandMore sx={{ fontSize: '14px' }} />
          )}
        </mui.core.Button>

        <mui.core.Collapse in={open}>
          <CodeBlock message={message} />
        </mui.core.Collapse>
      </mui.core.Box>
    );
  };

  return (
    <Box
      sx={{
        display: 'flex',
        marginLeft: '52px',
        marginTop: '-1rem',
      }}
    >
      {renderMessageLogText()}
    </Box>
  );
};

export default MessageLog;
