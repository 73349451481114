// IMPORTANT!!!!!!
// THIS WIDGET CAN ONLY RUN FROM WITHIN THE RISK MODEL CONTEXT

import { hooks, mui, React, ts } from '_core';

import ChartIndustries from './chart-categories';
import ChartDendrogram from './chart-dendrogram';
import Form from './form';
import { DraftParams, Params, UIApiParamsIndustries, UIApiResponseIndustries } from './types';

type RiskModelCategorySelectorProps = {
  mode: ts.enums.CHART_MODE_ENUM;
  params: Params | DraftParams;
  setParams: (_p: DraftParams) => void;

  loadWidgetData: (_payload: UIApiParamsIndustries) => Promise<UIApiResponseIndustries>;
  widgetPaths?: Record<'risk_model_dendrogram' | 'risk_model_category_analysis', string>;
  jobId?: number;

  context: ts.types.riskModel.RiskModelAnalysisContext;
};

const RiskModelCategorySelector: React.FC<RiskModelCategorySelectorProps> = ({
  loadWidgetData,
  widgetPaths,
  mode,
  params,
  setParams,
  context,
  jobId,
}) => {
  const uiStyles = hooks.useUiStyles();
  const theme = mui.styles.useTheme() as mui.core.Theme;
  const [tab, setTab] = React.useState(0);

  const dendogramFile = widgetPaths?.['risk_model_dendrogram']?.replace('.zip', '')?.replace('.tsv', '.html');

  return (
    <>
      {mode == 'edit' ? (
        <Form params={params} setParams={setParams} />
      ) : (
        <>
          <mui.core.Box style={{ borderBottom: `1px solid ${theme.palette.gray.main}` }}>
            <mui.core.Tabs
              value={tab}
              indicatorColor="primary"
              textColor="primary"
              onChange={(e, v) => setTab(v)}
              scrollButtons={false}
              variant="standard"
              aria-label="Industry Category Tabs"
            >
              <mui.core.Tab label="Selected Category Factors" />
              <mui.core.Tab label="Dendrogram" />
            </mui.core.Tabs>
          </mui.core.Box>
          <mui.core.Box sx={uiStyles.widgetWithTabsContent}>
            {tab == 0 ? (
              <ChartIndustries
                loadWidgetData={loadWidgetData}
                file={widgetPaths['risk_model_category_analysis']}
                riskModelId={context.risk_model_id}
              />
            ) : (
              <ChartDendrogram file={dendogramFile} jobId={jobId} />
            )}
          </mui.core.Box>
        </>
      )}
    </>
  );
};

export default RiskModelCategorySelector;
