import { helpers, mui, React, ts, ui } from '_core';

type DataSourceFormInitProps = {
  params: ts.types.dataSource.DataSourceFormParamsDraft;
  setParams: (_p: ts.types.dataSource.DataSourceFormParamsDraft) => void;
  workspaces: ts.types.workspace.Workspace[];
};

const DataSourceFormInit: React.FC<DataSourceFormInitProps> = ({ params, setParams }): React.ReactElement => {
  // Create handle while typing name
  React.useEffect(() => {
    if (params.name) setParams({ ...params, handle: helpers.createHandle(params.name, 'd') });
  }, [params.name]);

  return (
    <>
      <mui.core.Grid container spacing={3}>
        <mui.core.Grid item xs={12} sm={6}>
          <mui.core.TextField
            autoFocus
            label="Name"
            value={params.name}
            onChange={(e) => setParams({ ...params, name: e.target.value })}
            fullWidth
            variant="outlined"
            required
            size="small"
            inputProps={{
              autocomplete: 'off',
            }}
          />
        </mui.core.Grid>
        <mui.core.Grid item xs={12} sm={6}>
          <mui.core.TextField
            label="Handle"
            value={params.handle}
            onChange={(e) => setParams({ ...params, handle: e.target.value })}
            fullWidth
            variant="outlined"
            required
            size="small"
          />
        </mui.core.Grid>
      </mui.core.Grid>
      <mui.core.Box pt={4}>
        <ui.WorkspaceSelector
          value={params.workspace as ts.types.workspace.Workspace}
          setValue={(val) => setParams({ ...params, workspace: val })}
        />
      </mui.core.Box>
    </>
  );
};

export default DataSourceFormInit;
