import React from 'react';

import ResourceIcon from './resource-icon';
import * as enums from '../enums';
import { Link, mui } from '../libs';
import * as types from '../types';

type InlineItemProps = {
  resource: types.common.Resource;
  resourceType: enums.RESOURCES_TYPES_ENUM;
  to: string;
  clickCallback?: () => void;
};

const StyledLink = mui.styles.styled(Link)(({ theme }) => ({
  display: 'flex',
  outline: 'none',
  alignItems: 'center',
  textDecoration: 'none',
  padding: theme.spacing(1),
  borderBottom: `1px solid ${(theme.palette as any).background.default}`,
  transition: 'all ease-in-out 0.15s',
  maxWidth: 'calc(100vw - 20px)',
  '&:hover': {
    backgroundColor: `${(theme.palette as any).background.default}`,
  },
}));

const InlineItem: React.FC<InlineItemProps> = ({ resource, resourceType, to, clickCallback }): React.ReactElement => {
  const theme = mui.styles.useTheme() as mui.core.Theme;

  const uiResourceType = React.useMemo(() => {
    if (resourceType === enums.RESOURCES_TYPES_ENUM.SIGNAL && resource.type == 'ALPHA_MODEL')
      return enums.UI_RESOURCE_TYPE_ENUM.ALPHA_MODEL;
    return resourceType as undefined as enums.UI_RESOURCE_TYPE_ENUM;
  }, [resource]);

  return (
    <StyledLink to={to} onClick={clickCallback ? clickCallback : undefined}>
      <mui.core.Box
        sx={{
          fontWeight: 'inherit',
          flexGrow: 1,
          minWidth: '0',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        <mui.core.Box display="flex">
          <mui.core.Box
            px={2}
            sx={{
              '& span': {
                padding: '0',
                display: 'flex',
              },
              '& svg': {
                fontSize: '12px',
              },
            }}
          >
            <ResourceIcon resource={uiResourceType} />
          </mui.core.Box>
          <mui.core.Box>
            <mui.core.Typography
              variant="body2"
              sx={{
                color: (theme.palette as any).secondary.main,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                minWidth: '0',
                marginBottom: '-8px',
              }}
            >
              {resource.name}
            </mui.core.Typography>
            {resource.handle && (
              <mui.core.Typography
                variant="caption"
                sx={{
                  color: '#7F8D93',
                  textDecoration: 'none',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  minWidth: '0',
                }}
              >
                {resource.handle}
              </mui.core.Typography>
            )}
          </mui.core.Box>
        </mui.core.Box>
      </mui.core.Box>

      {resource.shared && (
        <mui.core.Box mr={2} style={{ minWidth: '55px' }}>
          <mui.core.Chip size="small" label="Shared" />
        </mui.core.Box>
      )}

      {!resource.shared && resource.is_deprecated && (
        <mui.core.Box mr={2} style={{ minWidth: '55px' }}>
          <mui.core.Chip size="small" label="Archived" />
        </mui.core.Box>
      )}

      <mui.icons.ArrowForward
        style={{
          color: '#D6DCDF',
          fontSize: '0.9rem',
          marginRight: '0.5rem',
          marginLeft: '0.5rem',
        }}
      />
    </StyledLink>
  );
};

export default InlineItem;
