import { _, helpers, ts } from '_core';

import { DraftParams } from './types';
import { getBenchmarkTitle } from '../../common/utils/get-name';

export const validate = (params: DraftParams) => {
  const rhErrors = helpers.validations.validSelectedReturnHorizons(params?.return_horizons);
  if (!_.isEmpty(rhErrors)) return rhErrors;
  if (params.with_benchmark && !helpers.validations.validBenchmark(params.benchmark))
    return ['Benchmark is invalid.'];
  return [];
};

export const getName = (
  params: DraftParams,
  _context: ts.types.signal.SignalAnalysisContext,
  resources: ts.StoreState['resources']
) => {
  const widgetTitle = 'Cumulative Returns';

  if (params.benchmark) return getBenchmarkTitle(params.benchmark, widgetTitle, resources.universes);
  return widgetTitle;
};
