import { React, ts } from '_core';

import Chart from './chart';

type BacktestConstrainedPortfolioExposuresOverTimeProps = {
  id: string;
  readOnly: boolean;
  widgetKey: string;

  fullScreen?: boolean;
  loadWidgetData: (_payload: ts.types.widgets.WidgetGetDataParams) => Promise<ts.types.widgets.WidgetGetDataResponse>;
  widgetPaths?: Record<'backtest_constraints', string>;

  title: string;
};

const BacktestConstrainedPortfolioExposuresOverTime: React.FC<BacktestConstrainedPortfolioExposuresOverTimeProps> = ({
  id,
  readOnly,
  widgetKey,
  loadWidgetData,
  widgetPaths,
  fullScreen,
  title,
}): React.ReactElement => {
  return (
    <Chart
      id={id}
      readOnly={readOnly}
      widgetKey={widgetKey}
      fullScreen={fullScreen}
      loadWidgetData={loadWidgetData}
      file={widgetPaths['backtest_constraints']}
      scale={ts.enums.SCALE_ENUM.LINEAR}
      title={title}
    />
  );
};

export default BacktestConstrainedPortfolioExposuresOverTime;
