import { _, helpers, ts } from '_core';

import { DraftParams } from './types';
import { getBenchmarkTitle } from '../../common/utils/get-name';
import { lagsLabel } from '../../common/utils/get-series-name';

export const prepareBarChartLagsNames = (
  data: ts.types.widgets.NestedBarWidgetData,
  freq: ts.enums.FREQUENCY_ENUM,
  isBenchmark = false
) => {
  const localData = _.cloneDeep(data);
  if (!localData?.data) return localData;

  const cleanedData = !isBenchmark
    ? localData.data
    : _.map(localData.data, (el) => _.pickBy(el, (_, key) => key.includes('_active') || key === 'category'));

  const transformRow = (row: Record<string, string | number>) => {
    const newRow = {} as ts.types.widgets.NestedBarData[0];
    Object.keys(row).forEach((key) => {
      if (key == 'category') newRow[key] = row[key];
      else {
        const newKey = lagsLabel(freq, key.replace('_active', ''));
        newRow[newKey] = row[key];
      }
    });
    return newRow;
  };

  localData.data = cleanedData.map(transformRow) as ts.types.widgets.NestedBarData;

  // Handle 'count' field
  if (localData.count) {
    Object.keys(localData.count).forEach((key) => {
      const row = localData.count[key];
      const newEl = transformRow(row);
      localData.count[key] = newEl as Record<string, number>;
    });
  }

  return localData;
};

export const validate = (params: DraftParams) => {
  const rhErrors = helpers.validations.validSelectedReturnHorizon(params?.return_horizon);
  if (!_.isEmpty(rhErrors)) return rhErrors;
  if (!params.lags || params.lags < 1 || params.lags > 12) return ['Lags is invalid.'];
  if (params.with_benchmark && !helpers.validations.validBenchmark(params.benchmark)) return ['Benchmark is invalid.'];
  return [];
};

export const getName = (
  params: DraftParams,
  _context: ts.types.signal.SignalAnalysisContext,
  resources: ts.StoreState['resources']
) => {
  if (params.benchmark)
    return getBenchmarkTitle(params.benchmark, `${'Annualized Risk Adjusted Returns With Lags'}`, resources.universes);
  return 'Annualized Risk Adjusted Returns With Lags';
};
