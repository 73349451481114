import { ts } from '_core';

import { INTERACTION_PARAMS } from './types';

export default [
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.PORTFOLIO_TRADE_LIST_HOLDINGS,
    form: true,
    title: 'Holdings',
    interactionParams: Object.values(INTERACTION_PARAMS),
    clearInteractionOnRegenerate: ['table_dtypes'],
    neededFiles: ['trade_list_holdings'],
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.REBALANCE_PORTFOLIO_TRADE_LIST],

    helpLabel: 'v6opn8uu',
  },
] satisfies ts.types.analysis.WidgetConfig[];
