import { helpers, ts } from '_core';

import { DraftParams } from './types';
import { getBenchmarkTitle } from '../../common/utils/get-name';

// prepareLinearData
export const transform = (
  data: ts.types.widgets.LinearData,
  isBasket: boolean,
  hasBenchmark: boolean,
  isIndexBenchmark: boolean
) => {
  const newData = {} as ts.types.widgets.LinearData;
  // Rename portfolio returns
  newData[isBasket ? 'Basket Weights' : 'Portfolio Returns'] = data.portfolio_returns || data.return;

  if (hasBenchmark) {
    // Rename benchmark_returns
    newData[isBasket && !isIndexBenchmark ? 'Target Weights' : 'Benchmark Returns'] = data.benchmark_returns;
    // Rename active_returns
    newData['Active'] = data.active_returns;
  }

  return newData;
};

export const validate = (params: DraftParams) => {
  if (params.with_benchmark && !helpers.validations.validBenchmark(params.benchmark)) return ['Benchmark is invalid.'];
  return [];
};

export const getName = (
  params: DraftParams,
  context: ts.types.backtest.BacktestAnaylsisContext | ts.types.basket.BasketAnalysisContext,
  resources: ts.StoreState['resources']
) => {
  const widgetTitle = 'Cumulative Returns';

  if (params.benchmark) return getBenchmarkTitle(params.benchmark, widgetTitle, resources.universes);

  if (context?.definition?.benchmark)
    return getBenchmarkTitle(
      context.definition.benchmark as ts.types.benchmark.Benchmark,
      `${'Cumulative Returns'}`,
      resources.universes
    );

  return widgetTitle;
};
