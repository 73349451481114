import { ts } from '_core';

export const datasetExists = (localDataset: ts.types.signal.DatasetDraft, datasets: ts.types.signal.Dataset[]) => {
  const ds = datasets.find(
    (localds) =>
      localds.universe_id == localDataset.universe_id &&
      localds.risk_model_id == localDataset.risk_model_id &&
      localds.pipeline_id == localDataset.pipeline_id &&
      localds.sp_pipeline_id == localDataset.sp_pipeline_id &&
      localds.frequency == localDataset.frequency
  );

  return ds;
};

export const datasetsAreEqual = (left: ts.types.signal.DatasetDraft, right: ts.types.signal.DatasetDraft) => {
  return (
    left.universe_id == right.universe_id &&
    left.risk_model_id == right.risk_model_id &&
    left.pipeline_id == right.pipeline_id &&
    left.sp_pipeline_id == right.sp_pipeline_id &&
    left.frequency == right.frequency &&
    left.start_date == right.start_date &&
    left.end_date == right.end_date &&
    !!left.end_date_latest == !!right.end_date_latest
  );
};
