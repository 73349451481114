import { mui, React, ts, ui } from '_core';

type BoundsProps = {
  params: ts.types.optimizer.Bounds;
  children?: React.ReactNode;
  disableLower?: boolean;
  isPercent?: boolean;
  setParams: (_params: ts.types.optimizer.Bounds) => void;
  id?: string;
  readOnly: boolean;
  validationDef: ts.types.common.ValidationErrors;
};

const Bounds: React.FC<BoundsProps> = ({
  params,
  setParams,
  isPercent = false,
  disableLower,
  children = null,
  id,
  readOnly,
  validationDef,
}): React.ReactElement => {
  return (
    <>
      <mui.core.Grid container spacing={4}>
        <mui.core.Grid item xs={12} md={6}>
          <ui.NumericField
            textFieldProps={{
              label: 'Lower Bound',
              fullWidth: true,
              variant: 'outlined',
              size: 'small',
              required: true,
              error: validationDef?.fields?.includes(`lower_bound_${id}`),
            }}
            disabled={readOnly || disableLower}
            value={params.lower_bound}
            setValue={(v) => setParams({ ...params, lower_bound: v })}
            suffix={isPercent ? '%' : ''}
            dividerOption={isPercent ? ts.enums.NUMBER_FORMATTERS_ENUM.HUNDRED : undefined}
          />
        </mui.core.Grid>

        <mui.core.Grid item xs={12} md={6}>
          <ui.NumericField
            textFieldProps={{
              label: 'Upper Bound',
              fullWidth: true,
              variant: 'outlined',
              size: 'small',
              required: true,
              error: validationDef?.fields?.includes(`upper_bound_${id}`),
            }}
            disabled={readOnly}
            value={params.upper_bound}
            setValue={(v) => setParams({ ...params, upper_bound: v })}
            suffix={isPercent ? '%' : ''}
            dividerOption={isPercent ? ts.enums.NUMBER_FORMATTERS_ENUM.HUNDRED : undefined}
          />
        </mui.core.Grid>
      </mui.core.Grid>
      {children}
    </>
  );
};

export default Bounds;
