import { ts } from '_core';

import { validate } from './helpers';

export default [
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.BASKET_COMPARE_NAME_COUNT,
    title: 'Name Count',
    form: true,
    helpLabel: 'bg9xqzc2',
    neededFiles: ['backtest_name_count'],
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.BASKET_COMPARE],

    validate,
  },
] satisfies ts.types.analysis.WidgetConfig[];
