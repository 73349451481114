import { endpoint, userapiendpoint } from './config';
import { $ } from '../libs';
import { SpecType } from '../types/common';

type QueryType = {
  tags?: string[];
  without_tags?: boolean;
  exclude_tags?: string[];
};

export const userApi = (query: QueryType): JQuery.jqXHR<SpecType> =>
  $.ajax({
    method: 'GET',
    url: `${userapiendpoint}user-openapi.json`,
    data: query,
    contentType: 'application/json',
    traditional: true,
  } as JQueryAjaxSettings);

export const helpItemEntry = (json: {
  label: string;
  document: string;
  content_path?: string;
  content_title?: string;
  reset?: boolean;
}): JQuery.jqXHR<{ url: string; edit_url: string }> =>
  $.ajax({
    method: 'POST',
    url: `${endpoint}help_item_docs`,
    json: json,
  } as JQueryAjaxSettings);
