import { ts } from '_core';

import { validate } from './helpers';
import { DraftParams } from './types';

export default [
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.SIGNAL_SET_CUMULATIVE_RETURNS_WITH_TIME_SERIES,
    title: 'Cumulative Returns With Time Series',
    helpLabel: 'ove97obh',
    form: true,
    neededFiles: ['signal_set_cumulative_returns'],
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.SIGNAL_SET],

    defaultParams: {
      daily_reporting: true,
      return_horizons: [{ periods: { frequency: 'DAILY', amount: 1 }, signature: '1 Day Return' }],
    } as DraftParams,
    validate,
  },
] satisfies ts.types.analysis.WidgetConfig[];
