import { _, helpers } from '_core';

import { DraftParams } from './types';

export const validate = (params: DraftParams) => {
  if (_.isEmpty(params?.buckets) && _.isEmpty(params?.variables))
    return ['At least one bucket or variable is required.'];
  if (!_.isEmpty(params?.buckets) 
    && !helpers.validations.validBuckets(params?.buckets)) return ['Buckets are invalid.'];

  return [];
};
