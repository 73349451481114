import { hooks, mui, React, ts, ui } from '_core';

type DataSourceInferDataProps = {
  params: ts.types.dataSource.DataSourceFormParamsDraft;
  setParams: (_p: ts.types.dataSource.DataSourceFormParamsDraft) => void;
  isModal?: boolean;
};

const DataSourceInferData: React.FC<DataSourceInferDataProps> = ({
  params,
  setParams,
  isModal,
}): React.ReactElement => {
  const uiStyles = hooks.useUiStyles();

  const [file, setFile] = React.useState<Record<string, any>>();

  const processUpload = (file: Record<string, any>) => {
    const fr = new FileReader();
    // 100KB - good balance between safety margin and efficiency
    const CHUNK_SIZE = 100 * 1024; // 100KB
    const chunk = file.slice(0, CHUNK_SIZE);
    fr.readAsArrayBuffer(chunk);

    fr.onload = () => {
      const fileData = new TextDecoder('utf-8').decode(fr.result as AllowSharedBufferSource);
      // Find the position of the 10th newline or take all content if less than 10 lines
      let endPos = 0;
      let newlineCount = 0;

      for (let i = 0; i < fileData.length && newlineCount < 10; i++) {
        if (fileData[i] === '\n') {
          newlineCount++;
        }
        endPos = i;
      }

      const rows = fileData.slice(0, endPos + 1);
      setParams({ ...params, tabData: rows });
    };
  };

  React.useEffect(() => {
    if (file) {
      processUpload(file);
    } else {
      setParams({ ...params, tabData: null });
    }
  }, [file]);

  // Let's try to know if this is tsv or csv
  React.useEffect(() => {
    if (params.tabData) {
      if (params.tabData.includes('\t')) setParams({ ...params, dataType: 'tsv' });
      else setParams({ ...params, dataType: 'csv' });
    }
  }, [params.tabData]);

  const form = () => (
    <>
      <mui.core.Box display="flex" alignItems="center" mt={4}>
        <mui.core.FormControl component="fieldset">
          <mui.core.RadioGroup
            row
            value={params.dataType}
            onChange={(e) => setParams({ ...params, dataType: e.target.value })}
          >
            <mui.core.FormControlLabel value="csv" control={<mui.core.Radio />} label="CSV" />
            <mui.core.FormControlLabel value="tsv" control={<mui.core.Radio />} label="TSV" />
          </mui.core.RadioGroup>
        </mui.core.FormControl>
        <mui.core.FormControlLabel
          control={
            <mui.core.Switch
              size="small"
              checked={params.withHeader}
              onChange={() => setParams({ ...params, withHeader: !params.withHeader })}
            />
          }
          label="First row is header"
        />
      </mui.core.Box>
      <mui.core.Box py={4}>
        {file ? (
          <>
            <mui.core.Box mb={2}>
              <mui.core.Typography
                variant="caption"
                sx={uiStyles.uppercase}
                color="textSecondary"
                fontWeight="fontWeightMedium"
              >
                Data infered from
              </mui.core.Typography>
            </mui.core.Box>
            <mui.core.Box sx={uiStyles.fileBox}>
              <mui.core.Typography variant="body1" style={{ marginRight: '1rem' }}>
                {file.name}
              </mui.core.Typography>
              <mui.core.IconButton
                size="small"
                onClick={() => {
                  setFile(null);
                }}
              >
                <mui.icons.Close style={{ fontSize: '1rem' }} />
              </mui.core.IconButton>
            </mui.core.Box>
          </>
        ) : (
          <ui.DragDropFiles setFile={setFile} />
        )}
      </mui.core.Box>
    </>
  );

  return (
    <>
      {!isModal && (
        <mui.core.Box p={2} textAlign="center" mb={3} sx={uiStyles.helpBox}>
          <mui.core.Typography fontWeight="fontWeightMedium">
            *Skip this step if you want to manually define your schema
          </mui.core.Typography>
        </mui.core.Box>
      )}
      <mui.core.Box p={8} textAlign="center" sx={uiStyles.helpBox}>
        <mui.icons.CloudUpload sx={{ fontSize: '2rem', mb: 3 }} />
        <mui.core.Typography variant="h5">
          Upload a CSV or TSV file to infer the data source schema
          {!isModal && '(optional)'}
        </mui.core.Typography>
        <mui.core.Typography variant="body2">
          <ul style={{ marginBottom: 0 }}>
            {isModal && <li>The current data schema will get overwritten and lost if a new one is uploaded</li>}
            <li>
              Please note that the data will not be included in the data source, it would only be used to infer the data
              source schema
            </li>
          </ul>
        </mui.core.Typography>
      </mui.core.Box>
      {form()}
    </>
  );
};

export default DataSourceInferData;
