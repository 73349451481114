import { React, ts } from '_core';

import Chart from './chart';
import Form from './form';
import { DraftParams, Params } from './types';

type SignalExposureProps = {
  id: string;
  readOnly: boolean;
  widgetKey: string;
  mode: ts.enums.CHART_MODE_ENUM;
  params: Params | DraftParams;
  setParams: (_p: DraftParams) => void;
  title: string;

  fullScreen?: boolean;
  context?: ts.types.signal.SignalAnalysisContext | ts.types.basket.BasketAnalysisContext;
  loadWidgetData: (_payload: ts.types.widgets.WidgetGetDataParams) => Promise<ts.types.widgets.WidgetGetDataResponse>;
  widgetPaths?: Record<'signal_exposure' | 'basket_signal_exposure', string>;
};

const SignalExposure: React.FC<SignalExposureProps> = ({
  id,
  readOnly,
  widgetKey,
  loadWidgetData,
  widgetPaths,
  mode,
  params,
  setParams,
  fullScreen,
  context,
  title,
}) => {
  const isBasket = params.is_basket || false;

  const renderJobComponent = () => (
    <>
      <Chart
        id={id}
        readOnly={readOnly}
        widgetKey={widgetKey}
        loadWidgetData={loadWidgetData}
        file={isBasket ? widgetPaths['basket_signal_exposure'] : widgetPaths['signal_exposure']}
        fullScreen={fullScreen}
        params={params as Params}
        hasPipeline={isBasket ? false : !!(context as ts.types.signal.SignalAnalysisContext).pipeline_id}
        title={title}
        hasSPPipeline={isBasket ? false : !!(context as ts.types.signal.SignalAnalysisContext).sp_pipeline_id}
      />
    </>
  );

  return (
    <>{mode == 'edit' ? <Form params={params} setParams={setParams} isBasket={isBasket} /> : renderJobComponent()}</>
  );
};

export default SignalExposure;
