import React from 'react';

import * as enums from '../../enums';
import { _, mui } from '../../libs';
import * as types from '../../types';
import DialogTitle from '../dialog-title';


type ErrorDialogProps = {
  handleClose: () => void;
  data: types.job.JobError[];
  severity: enums.JOB_ERROR_SEVERITY_ENUM | '';
};

const ErrorDialog: React.FC<ErrorDialogProps> = ({ handleClose, data, severity }): React.ReactElement => {
  const theme = mui.styles.useTheme() as mui.core.Theme;

  function parseTitle(title: string): string {
    const cleanedTitle = title.replace(/Warning$/, '');
    return _.startCase(_.camelCase(cleanedTitle));
  }

  const localData = React.useMemo(() => {
    return _.groupBy(data, (el) => el.type);
  }, [data]);

  const renderMessage = (element: types.job.JobError) => {
    const message = element.message ?? '';

    let subtitle = '';
    let items = [] as string[];

    if (message.includes('because')) {
      subtitle = message.split('because')[0] + ' because:';
      items = message.split('because')[1].split('\n');
    } else {
      items = message.split('\n');
    }

    if (_.isEmpty(items[0])) items = ['No records for the Error/Warning'];

    return (
      <>
        <mui.core.Box>
          {subtitle && (
            <mui.core.Typography paragraph variant="body2">
              {subtitle}
            </mui.core.Typography>
          )}
          <ul style={{ margin: '8px 0' }}>
            {items.map((el, index) => (
              <li key={index}>
                <mui.core.Typography variant="body2">{el}</mui.core.Typography>
              </li>
            ))}
          </ul>
        </mui.core.Box>
      </>
    );
  };

  return (
    <>
      <mui.core.Dialog open onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle closeAction={handleClose}>
          Job {severity === enums.JOB_ERROR_SEVERITY_ENUM.ERROR ? 'Errors' : 'Warnings'}
        </DialogTitle>
        <mui.core.DialogContent style={{ paddingTop: 0, maxHeight: '350px' }}>
          {Object.entries(localData).map((group) => {
            return (
              <mui.core.Box
                key={group[0]}
                sx={
                  severity === enums.JOB_ERROR_SEVERITY_ENUM.ERROR
                    ? {
                        background: (theme.palette as any).dangerLight.light,
                        borderRadius: 2,
                        p: 3,
                        color: (theme.palette as any).error.light,
                      }
                    : {
                        background: (theme.palette as any).warningLight.light,
                        borderRadius: 2,
                        p: 3,
                        color: (theme.palette as any).warning.dark,
                      }
                }
              >
                <mui.core.Typography variant="h6">
                  {parseTitle(group[0]) ?? 'Unknown Error/Warning'}
                </mui.core.Typography>
                {localData[group[0]].map((element: types.job.JobError, idx: React.Key) => (
                  <React.Fragment key={idx}>{renderMessage(element)}</React.Fragment>
                ))}
              </mui.core.Box>
            );
          })}
        </mui.core.DialogContent>

        <mui.core.DialogActions>
          <mui.core.Button
            variant="contained"
            color="primary"
            onClick={handleClose}
            style={{ margin: '0rem 1rem 1rem' }}
            fullWidth
          >
            Close
          </mui.core.Button>
        </mui.core.DialogActions>
      </mui.core.Dialog>
    </>
  );
};

export default ErrorDialog;
