import { ts } from '_core';

import { DraftParams, INTERACTION_PARAMS } from './types';

export default [
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.RISK_MODEL_VOLATILITY_VS_TIME,
    title: 'Annualized Risk Factor Volatility Vs. Time',
    helpLabel: 'yiul0odq',
    form: false,
    interactionParams: Object.values(INTERACTION_PARAMS),
    defaultParams: {
      tab: 'style_factors',
      category_factors: [],
    } as DraftParams,
    neededFiles: ['risk_model_factor_volatility'],
    tags: [
      ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.RISK_MODEL,
      ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.COMMON_RISK_MODEL,
      ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.STATISTICAL_RISK_MODEL,
    ],
  },
] satisfies ts.types.analysis.WidgetConfig[];
