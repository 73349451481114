import React from 'react';

import api from '../api';
import { _, mui, useSelector } from '../libs';
import * as types from '../types';

type LabelsAutocompleteProps = {
  selectedLabelIds: number[];
  submitData: (_ids: number[]) => void;
  disabled?: boolean;
};

/**
 * Component for selecting labels from a dropdown menu
 * 
 * @requires Redux Store:
 * - state.resources.labels: The labels available for selection in the autocomplete
 * 
 * @param selectedLabelIds - Array of currently selected label IDs
 * @param submitData - Function to submit the selected label IDs
 * @param disabled - Optional flag to disable the autocomplete
 * 
 * @returns A React component that allows users to select labels from a dropdown menu
 */

const LabelsAutocomplete: React.FC<LabelsAutocompleteProps> = ({
  selectedLabelIds,
  submitData,
  disabled,
}): React.ReactElement => {
  const labels = useSelector((state: types.BaseStore) => state.resources.labels);

  const [value, setValue] = React.useState(selectedLabelIds);
  const [creatingLabel, setCreatingLabel] = React.useState(false);

  const createLabel = async (title: string, labelIds: number[]) => {
    setCreatingLabel(true);
    const resp = await api.labels.create({ title });
    if (resp) {
      setCreatingLabel(false);
      const r = _.uniq([...labelIds, resp.data.id]);
      submitData(r);
      setValue(r);
    }
  };

  const onChange = (e: React.ChangeEvent<any>, v: types.label.Label[] | string[]) => {
    const labelIds = v.map((l) => (l as types.label.Label)?.id).filter(Boolean);
    // Adding new
    if (v.length > value.length) {
      let title = '';
      if (typeof v[v.length - 1] === 'string' || v[v.length - 1] instanceof String) title = v[v.length - 1] as string;
      else title = (v[v.length - 1] as types.label.Label).title;
      // check if the label is new or only no label was selected manually
      const foundLabel = labels.find((l) => l.title == title);
      if (!foundLabel) createLabel(title, labelIds);
      else {
        const r = _.uniq([...labelIds, foundLabel.id]);
        // do not set state if equal
        if (!_.isEqual(r, value)) submitData(r);
        setValue(r);
      }
    } else {
      //  Removing label
      submitData(labelIds);
      setValue(labelIds);
    }
  };

  const selectedLabels = React.useMemo(() => {
    return value.map((selectedLabel) => ({
      id: selectedLabel,
      title: labels.find((l) => l.id === selectedLabel)?.title,
    }));
  }, [value, labels]);

  return (
    <mui.core.Autocomplete<types.label.Label, true, true, true>
      multiple
      filterSelectedOptions
      freeSolo
      limitTags={2}
      options={labels}
      getOptionLabel={(option) => (option as types.label.Label).title || 'not existing'}
      PopperComponent={(props: any) => (
        <mui.core.Box
          {...props}
          sx={{
            position: 'absolute',
            minWidth: '200px',
          }}
        >
          {props.children}
        </mui.core.Box>
      )}
      value={selectedLabels}
      size="small"
      disabled={creatingLabel || disabled}
      disableClearable
      style={{ minWidth: '100px' }}
      renderInput={(params) => <mui.core.TextField {...params} variant="outlined" label="Labels" size="small" />}
      onChange={(e, v) => onChange(e, v as types.label.Label[])}
    />
  );
};

export default LabelsAutocomplete;
