import { hooks, mui, React, ts, ui } from '_core';

import { DraftParams, Params } from './types';
import FactorAutoComplete from '../../common/forms/factor-autocomplete';

type FormProps = {
  params: DraftParams;
  setParams: (_p: Params) => void;
  context: ts.types.riskModel.RiskModelAnalysisContext;
  noContext: boolean;
};

const Form: React.FC<FormProps> = ({ params, setParams, context, noContext }): React.ReactElement => {
  const [criticalpvalue, setCriticalpvalue] = React.useState(params.critical_pvalue);

  const uiStyles = hooks.useUiStyles();

  let styleOptions = [] as string[];

  if (!noContext) {
    const styles = context.definition?.style?.map((s) => s.handle);
    styleOptions = styles;
  }

  React.useEffect(() => {
    const data = { ...params, critical_pvalue: criticalpvalue };
    setParams(data);
  }, [criticalpvalue]);

  return (
    <mui.core.Box sx={uiStyles.widgetEditMode}>
      <mui.core.Box mt={4}>
        <ui.NumericField
          textFieldProps={{
            label: 'Critical Pvalue (between 0 and 1)',
            fullWidth: true,
            variant: 'outlined',
            size: 'small',
            required: true,
          }}
          value={criticalpvalue}
          setValue={(v) => setCriticalpvalue(v)}
        />
      </mui.core.Box>
      <FactorAutoComplete
        params={params.risk_factors}
        setParams={(val) => setParams({ ...params, risk_factors: val })}
        styleOptions={styleOptions}
      />
    </mui.core.Box>
  );
};

export default Form;
