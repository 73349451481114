import { config, hooks, mui, React, ts, ui } from '_core';

import { DraftParams, Params } from './types';

type FormProps = {
  params: Params;
  setParams: (_p: DraftParams) => void;
  frequency: ts.enums.FREQUENCY_ENUM;
  startDate: string;
  endDate: string;
};

const Form: React.FC<FormProps> = ({ params, setParams, startDate, endDate, frequency }) => {
  const uiStyles = hooks.useUiStyles();
  const [snapshotDate, setSnapshotDate] = React.useState(params.details_end_date || endDate);
  const [basket, setBasket] = React.useState(params.basket_id);
  const [componentErrors, setComponentErrors] = React.useState([]);

  React.useEffect(() => {
    const data = {
      details_start_date: snapshotDate,
      details_end_date: snapshotDate,
      basket_id: basket,
      component_errors: componentErrors,
    };

    setParams({ ...params, ...data });
  }, [snapshotDate, basket, componentErrors]);

  return (
    <mui.core.Box sx={uiStyles.widgetEditMode}>
      <mui.core.Box mt={2}>
        <ui.ResourceAutocomplete<ts.types.universe.UniverseExpanded>
          value={basket || null}
          setValue={(id) => setBasket(id)}
          autocompleteProps={{
            disableClearable: true,
          }}
          type={ts.enums.RESOURCES_TYPES_ENUM.BASKET}
          inputProps={{
            fullWidth: true,
            label: 'Benchmark',
            variant: 'outlined',
            size: 'small',
          }}
        />
      </mui.core.Box>

      <mui.core.Box mt={2}>
        <ui.SmartDateSelector
          label="Allocation Date"
          value={snapshotDate}
          setValue={(date) => setSnapshotDate(date)}
          minDate={startDate || config.features.start_date}
          maxDate={endDate || config.features.end_date}
          frequency={frequency}
          setExternalError={(e) => setComponentErrors(e ? [e] : [])}
        />
      </mui.core.Box>
    </mui.core.Box>
  );
};

export default Form;
