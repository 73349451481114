import { ts } from '_core';

import { validate } from './helpers';
import { DraftParams } from './types';

export default [
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.SIGNAL_BUCKETED_REGIME_BY_QUANTILES,
    title: 'Signal Bucketed Regime Analysis',
    helpLabel: 'fs6hu0z5',
    form: true,
    defaultParams: {
      weights: 'equal',
      quantiles: 5,
      show_unknown: false,
      discrete: false,
      conditional: false,
      format_for_ui: true,
      market_relative_returns: false,
      version_change: 1,
    } as DraftParams,
    neededFiles: ['signal_bucketed_regime_analysis_by_quantiles'],
    tags: [
      ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.SIGNAL,
      ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.ALPHA_MODEL,
      ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.COMMON_ALPHA_MODEL,
    ],

    validate,
  },
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.SIGNAL_BUCKETED_REGIME_BY_QUANTILES_CONDITIONAL,
    title: 'Conditional Signal Bucketed Regime Analysis',
    helpLabel: 'lcx13qbf',
    form: true,
    defaultParams: {
      weights: 'equal',
      quantiles: 5,
      show_unknown: false,
      discrete: false,
      conditional: true,
      format_for_ui: true,
      market_relative_returns: false,
    } as DraftParams,
    neededFiles: ['signal_bucketed_regime_analysis_by_quantiles'],
    tags: [
      ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.SIGNAL,
      ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.ALPHA_MODEL,
      ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.COMMON_ALPHA_MODEL,
    ],

    validate,
  },
] satisfies ts.types.analysis.WidgetConfig[];
