/* eslint-disable max-len */
import { _, ts } from '_core';
// Do not change parent types. That is being used to get the alpha category for the analysis templates.
const P_TYPE = ts.enums.ALPHA_MODEL_PARENT_ENUM;

export const innerSubtypes = [
  { name: 'AdaBoost', type: 'adaboost', parentType: P_TYPE.ENSEMBLE_METHODS, disabled: true },
  {
    name: 'Affinity Propagation',
    type: 'affinityPropagation',
    parentType: P_TYPE.CLUSTERING,
    disabled: true,
  },
  { name: 'Bagging', type: 'bagging', parentType: P_TYPE.ENSEMBLE_METHODS, disabled: true },
  {
    name: 'Classification',
    type: 'classification',
    parentType: P_TYPE.DECISSION_TREES,
    disabled: true,
  },
  { name: 'Custom', type: 'custom', parentType: P_TYPE.CUSTOM, disabled: false },
  {
    name: 'Ordinary Least Squares',
    type: 'linearRegression',
    parentType: P_TYPE.LINEAR_REGRESSION,
    disabled: false,
  },
  // { name: 'Elastic Net', type: A_TYPE.ELASTICNET, parentType: P_TYPE.LINEAR_REGRESSION, disabled: true },
  {
    name: 'Factor Analysis',
    type: 'factorAnalysis',
    parentType: P_TYPE.FACTOR_DECOMPOSITION,
    disabled: true,
  },
  { name: 'Fixed Weights', type: 'fixed', parentType: P_TYPE.FIXED_WEIGHTS, disabled: false },
  {
    name: 'Forest of Randomized Trees',
    type: 'forestOfRandomizedTrees',
    parentType: P_TYPE.ENSEMBLE_METHODS,
    disabled: true,
  },
  {
    name: 'Gaussian Mixture Models',
    type: 'gaussianMixtureModels',
    parentType: P_TYPE.GAUSSIAN,
    disabled: true,
  },
  {
    name: 'Hierarchichal Clustering',
    type: 'hierarchicalClustering',
    parentType: P_TYPE.CLUSTERING,
    disabled: true,
  },
  {
    name: 'Independent Component Analysis',
    type: 'ica',
    parentType: P_TYPE.FACTOR_DECOMPOSITION,
    disabled: true,
  },
  { name: 'K-Means', type: 'kmeans', parentType: P_TYPE.CLUSTERING, disabled: true },
  { name: 'Lasso', type: 'lasso', parentType: P_TYPE.LINEAR_REGRESSION, disabled: false },
  {
    name: 'Latent Dirichlet Allocation (LDA)',
    type: 'lda',
    parentType: P_TYPE.FACTOR_DECOMPOSITION,
    disabled: true,
  },
  // { name: 'Logistic Regression', type: A_TYPE.LOGISTIC, parentType: P_TYPE.LINEAR_REGRESSION, disabled: true },
  { name: 'Mean Shift', type: 'meanShift', parentType: P_TYPE.CLUSTERING, disabled: true },
  {
    name: 'Optimized Model',
    type: 'optimizedModel',
    parentType: P_TYPE.OPTIMIZED_MODEL,
    disabled: false,
  },
  // { name: 'Orthogonal Matching Pursuit', type: A_TYPE.ORTHOGONAL, parentType: P_TYPE.LINEAR_REGRESSION, disabled: true },
  {
    name: 'Principal Component Analysis',
    type: 'pca',
    parentType: P_TYPE.FACTOR_DECOMPOSITION,
    disabled: true,
  },
  { name: 'Regression', type: 'regression', parentType: P_TYPE.DECISSION_TREES, disabled: true },
  // { name: 'Ridge Regression', type:  A_TYPE.RIDGE, parentType: P_TYPE.LINEAR_REGRESSION, disabled: true },
  {
    name: 'Robust Regression',
    type: 'robust',
    parentType: P_TYPE.LINEAR_REGRESSION,
    disabled: false,
  },
  {
    name: 'Spectral Clustering',
    type: 'spectralClustering',
    parentType: P_TYPE.CLUSTERING,
    disabled: true,
  },
  {
    name: 'Voting Classifier',
    type: 'votingClassifier',
    parentType: P_TYPE.ENSEMBLE_METHODS,
    disabled: true,
  },
  {
    name: 'Voting Regressor',
    type: 'votingRegressor',
    parentType: P_TYPE.ENSEMBLE_METHODS,
    disabled: true,
  },
];

export const alphaParentTypes = [
  // { name: 'Clustering', type: P_TYPE.CLUSTERING, disabled: true },
  { name: 'Custom', type: P_TYPE.CUSTOM, disabled: false },
  // { name: 'Decision Trees', type: P_TYPE.DECISSION_TREES, disabled: true },
  // { name: 'Ensemble Methods', type: P_TYPE.ENSEMBLE_METHODS, disabled: true },
  // { name: 'Factor Decomposition', type: P_TYPE.FACTOR_DECOMPOSITION, disabled: true },
  { name: 'Fixed Weights', type: P_TYPE.FIXED_WEIGHTS, disabled: false },
  // { name: 'Gaussian Mixture Models', type: P_TYPE.GAUSSIAN, disabled: true },
  { name: 'Linear Model', type: P_TYPE.LINEAR_REGRESSION, disabled: false },
  { name: 'Optimized Model', type: P_TYPE.OPTIMIZED_MODEL, disabled: false },
];

export const types = _.uniq([...innerSubtypes.map((el) => el.type)]);
