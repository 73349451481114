import { actions, hooks, mui, React, ts, useDispatch, useSelector } from '_core';

import { DraftParams } from './types';
import { getBrowserWidgets } from './utils';

type FormProps = {
  params: DraftParams;
  setParams: (_p: DraftParams) => void;
};

const Form: React.FC<FormProps> = ({ params, setParams }) => {
  const uiStyles = hooks.useUiStyles();
  const reportDefaults = useSelector((state) => state.resources.report_defaults).filter((rd) => rd.type == 'signal');
  const dispatch = useDispatch();
  const openPreferences = () => {
    dispatch(actions.ui.setPreferencesSelectedInnerTab(ts.enums.PREFERENCES_INNER_TABS_ENUM.SIGNAL_TEMPLATE));
  };

  const [selectedLayout, setSelectedLayout] = React.useState(params.report_default_id);

  const defaultRep = React.useMemo(() => {
    return reportDefaults.find((rd) => rd.id == selectedLayout);
  }, [selectedLayout]);

  React.useEffect(() => {
    if (defaultRep) {
      setParams({
        ...params,
        report_default_id: defaultRep?.id,
        set_params: defaultRep?.id ? getBrowserWidgets(defaultRep.definition) : [],
      });
    }
  }, [defaultRep]);

  return (
    <mui.core.Box sx={uiStyles.widgetEditMode}>
      <mui.core.TextField
        value={selectedLayout}
        onChange={(e) => setSelectedLayout(Number(e.target.value))}
        label="Signal Analysis Layout"
        size="small"
        variant="outlined"
        select
        required
        fullWidth
      >
        <mui.core.MenuItem
          onClick={openPreferences}
          onMouseDown={(event) => {
            event.preventDefault();
          }}
        >
          Create new...
        </mui.core.MenuItem>
        {reportDefaults.map((rd) => (
          <mui.core.MenuItem key={rd.id} value={rd.id}>
            {rd.title}
          </mui.core.MenuItem>
        ))}
      </mui.core.TextField>
    </mui.core.Box>
  );
};

export default Form;
