import { ts } from '_core';

import { validate } from './helpers';

export default [
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.SIGNAL_CUMULATIVE_RETURNS_WITH_TIME_SERIES,
    title: 'Cumulative Returns With Time Series',
    helpLabel: 'v4tqc45d',
    form: true,
    neededFiles: ['signal_cumulative_returns'],
    tags: [
      ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.SIGNAL,
      ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.ALPHA_MODEL,
      ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.COMMON_ALPHA_MODEL,
    ],

    validate,
  },
] satisfies ts.types.analysis.WidgetConfig[];
