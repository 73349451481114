import { _, helpers, hooks, mui, React, ts } from '_core';

import { DraftParams, Params } from './types';
import ChartWrapper from '../../common/chart-wrapper';
import createTreeMapChart from '../../common/charts/create-tree-map-chart';
import {
  checkForErrors,
  formatPortanData,
  getBucketOrder,
  getOrderedHistories,
  getTitleByStatistics,
  handleIndustryNames,
} from '../../common/utils/attribution-utils';

type ChartProps = {
  id: string;
  readOnly: boolean;
  widgetKey: string;
  params: Params | DraftParams;
  file: string;
  fullScreen: boolean;
  histories: ts.enums.HISTORY_ENUM_EXTENDED[];
  loadWidgetData: (_payload: ts.types.widgets.WidgetGetDataParams) => Promise<ts.types.widgets.WidgetGetDataResponse>;

  startDate: string;
  endDate: string;

  riskModelIndustries?: ts.types.riskModel.CategoryFactor[];
};

const Chart: React.FC<ChartProps> = ({
  id,
  readOnly,
  widgetKey,
  loadWidgetData,
  fullScreen,
  file,
  params,
  histories,
  startDate,
  endDate,
  riskModelIndustries,
}): React.ReactElement => {
  const uiStyles = hooks.useUiStyles();

  const [data, setData] = React.useState<{ data: ts.types.widgets.TreeMapData }>(null);
  const [error, setError] = React.useState<ts.types.common.ApiError>(null);
  const [bucketsOrder, setBucketsOrder] = React.useState([] as string[]);

  const [selectedStat, setSelectedStat] = React.useState(ts.enums.REPORT_ENUMS.ANNU_STATISTICS_ENUM.RETURNS);
  const [selectedType, setSelectedType] = React.useState('selected_buckets');

  const orderedHistories = React.useMemo(() => getOrderedHistories(histories), [histories]);

  const [selectedHistory, setSelectedHistory] = React.useState(
    orderedHistories[0] || ts.enums.HISTORY_ENUM_EXTENDED.FULL
  );

  const getHistLabel = (h: (typeof histories)[0]) => {
    return helpers.periods.isAnnualized(startDate, endDate, h) ? `${h} (Annualized)` : h;
  };

  const loadData = async (bucketOrder: string[]) => {
    const statisticsType = selectedStat || ts.enums.REPORT_ENUMS.ANNU_STATISTICS_ENUM.RETURNS;
    try {
      const response = await loadWidgetData({
        data: {
          file,
          query: [
            '$and',
            ['order', 'equals', `['${bucketOrder.join("', '")}']`],
            ['statistics_type', 'equals', statisticsType],
            ['$or', ['history', 'equals', selectedHistory], ['history', 'equals', ts.enums.HISTORY_ENUM_EXTENDED.FULL]],
          ],
          rename: { build_date: 'date' },
          columns: [...bucketOrder, 'holdings', 'returns', 'history'],
        },
      });
      const error = checkForErrors(response.data, 'attributed_returns');
      if (!error) {
        const responseData = formatPortanData(response.data, bucketOrder, selectedHistory, statisticsType);
        const renamedData = handleIndustryNames(responseData, riskModelIndustries);

        setData({ data: renamedData });
      } else {
        setError({ responseJSON: { detail: error } });
      }
    } catch (err) {
      setError(err as ts.types.common.ApiError);
    }
  };

  React.useEffect(() => {
    // In legacy jobs, buckets and variables may be arrays with exactly one element.
    const buckets = _.isArray(params.buckets) ? params.buckets[0] : params.buckets;
    const variables = _.isArray(params.variables) ? params.variables[0] : params.variables;
    const bucketOrder = getBucketOrder({ buckets: buckets, variables: variables }, selectedType);

    setBucketsOrder(bucketOrder);
    if (file) loadData(bucketOrder);
    return () => {
      setError(null);
      setData(null);
    };
  }, [file, selectedType, selectedHistory, selectedStat]);

  const statisticsName = getTitleByStatistics(selectedStat);

  let historyName;
  if (selectedHistory !== ts.enums.HISTORY_ENUM_EXTENDED.FULL) historyName = `Last ${selectedHistory}`;
  else historyName = 'Entire Period';

  const homeText = `Returns-Driven ${statisticsName} Decomposition by Average Allocation over the ${historyName}`;

  React.useEffect(() => {
    // create chart
    if (!_.isNil(data)) {
      const numberFormat = selectedStat != 'adjusted' ? '#.##%' : '#.##';

      createTreeMapChart({
        id,
        data: data?.data,
        fullScreen,
        homeText: homeText,
        selectedBuckets: bucketsOrder,
        numberFormat: numberFormat,
      });
    }
  }, [data]);

  return (
    <mui.core.Box display="flex" flexDirection="column" style={{ height: '100%', background: '#fff' }}>
      <mui.core.Box px={4} sx={uiStyles.hidePrint}>
        <mui.core.Grid
          container
          spacing={2}
          style={{
            marginTop: '12px',
          }}
        >
          <mui.core.Grid item xs={12} sm={2}>
            <mui.core.TextField
              select
              label="Summary Statistics"
              variant="outlined"
              required
              size="small"
              value={selectedStat}
              onChange={(e) => setSelectedStat(e.target.value as ts.enums.REPORT_ENUMS.ANNU_STATISTICS_ENUM)}
              fullWidth
            >
              <mui.core.MenuItem value={ts.enums.REPORT_ENUMS.ANNU_STATISTICS_ENUM.RETURNS}>
                Annualized Returns
              </mui.core.MenuItem>
              <mui.core.MenuItem value={ts.enums.REPORT_ENUMS.ANNU_STATISTICS_ENUM.RISK}>
                Annualized Risk
              </mui.core.MenuItem>
              <mui.core.MenuItem value={ts.enums.REPORT_ENUMS.ANNU_STATISTICS_ENUM.ADJUSTED}>
                Annualized Risk Adjusted Returns
              </mui.core.MenuItem>
            </mui.core.TextField>
          </mui.core.Grid>

          <mui.core.Grid item xs={12} sm={2}>
            <mui.core.TextField
              select
              label="Selected History"
              variant="outlined"
              size="small"
              value={selectedHistory}
              onChange={(e) => setSelectedHistory(e.target.value as ts.enums.HISTORY_ENUM_EXTENDED)}
              fullWidth
            >
              {orderedHistories.map((h) => (
                <mui.core.MenuItem key={h} value={h}>
                  {getHistLabel(h)}
                </mui.core.MenuItem>
              ))}
            </mui.core.TextField>
          </mui.core.Grid>

          <mui.core.Grid item xs={12} sm={4}>
            <mui.core.FormControl component="fieldset">
              <mui.core.RadioGroup
                row
                aria-label="position"
                name="position"
                value={selectedType}
                onChange={(e) => {
                  setSelectedType(e.target.value);
                }}
              >
                <mui.core.FormControlLabel
                  value="selected_variables"
                  control={<mui.core.Radio color="primary" />}
                  label="Show Risk Factors First"
                />
                <mui.core.FormControlLabel
                  value="selected_buckets"
                  control={<mui.core.Radio color="primary" />}
                  label="Show Buckets First"
                />
              </mui.core.RadioGroup>
            </mui.core.FormControl>
          </mui.core.Grid>
        </mui.core.Grid>
      </mui.core.Box>
      <mui.core.Box p={3} flex="1">
        <ChartWrapper
          id={id}
          readOnly={readOnly}
          widgetKey={widgetKey}
          data={data}
          error={error}
          fullScreen={fullScreen}
        />
      </mui.core.Box>
    </mui.core.Box>
  );
};

Chart.defaultProps = {
  riskModelIndustries: [],
};

export default Chart;
