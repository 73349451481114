import { _, helpers, React, ts } from '_core';

import ChartWrapper from '../../common/chart-wrapper';
import createNestedBarChart from '../../common/charts/create-nested-bar-chart';

type ChartProps = {
  id: string;
  readOnly: boolean;
  widgetKey: string;
  loadWidgetData: (_payload: ts.types.widgets.WidgetGetDataParams) => Promise<ts.types.widgets.WidgetGetDataResponse>;
  file: string;
  fullScreen: boolean;
  definition: ts.types.signal.Lasso;
  title: string;
};

const Chart: React.FC<ChartProps> = ({
  id,
  readOnly,
  widgetKey,
  loadWidgetData,
  file,
  fullScreen,
  definition,
  title,
}) => {
  const [data, setData] = React.useState<ts.types.widgets.TableData>(null);
  const [error, setError] = React.useState(null);
  const [showLegend, setShowLegend] = React.useState(false);

  const loadData = async () => {
    try {
      const response = await loadWidgetData({
        data: {
          file,
        },
      });
      if (response) {
        setData(response.data);
      }
    } catch (err) {
      setError(JSON.stringify(err));
    }
  };

  React.useEffect(() => {
    if (file) loadData();
    return () => setData(null);
  }, [file]);

  React.useEffect(() => {
    // Create line chart on data and scale changes
    if (!_.isNil(data))
      createNestedBarChart({
        id,
        data: data,
        fullScreen,
        setShowLegend,
        format: '#.##%',
        yAxisLabel: 'Percent Significance',
        exportTitle: title,
        prepareData: (nestedBarData) => ({
          data: helpers.buildName.signalVarCategoryName(
            nestedBarData.data,
            definition
          ) as ts.types.widgets.NestedBarData,
          ...nestedBarData,
        }),
      });
  }, [data]);

  return (
    <ChartWrapper
      id={id}
      readOnly={readOnly}
      widgetKey={widgetKey}
      data={data}
      showLegend={showLegend}
      warning={data?.length === 0 ? ['Empty File'] : []}
      error={error}
      fullScreen={fullScreen}
    />
  );
};

export default Chart;
