/* eslint-disable no-unused-vars */
import { _, mui, React, ts } from '_core';

import { prepareCovariancesChart, prepareReturnsAndWeightsChart } from './helpers';
import ChartWrapper from '../../common/chart-wrapper';
import createHeatMapChart from '../../common/charts/create-heat-map';
import createLayeredChart from '../../common/charts/create-layered-chart';

type ChartProps = {
  id: string;
  readOnly: boolean;
  widgetKey: string;
  fullScreen: boolean;
  loadWidgetData: (_payload: ts.types.widgets.WidgetGetDataParams) => Promise<ts.types.widgets.WidgetGetDataResponse>;
  definition: ts.types.signal.OptimizedModel;
  optimizedCovariance: string;
  optimizedAlphaData: string;
  title: string;
};

enum TabsEnum {
  FACTOR_RETURNS_AND_WEIGHTS = 'Factor Returns and Weights',
  FACTOR_COVARIANCE = 'Factor Covariance',
}

const Chart: React.FC<ChartProps> = ({
  id,
  readOnly,
  widgetKey,
  fullScreen,
  loadWidgetData,
  definition,
  optimizedAlphaData,
  optimizedCovariance,
  title,
}) => {
  const [data, setData] = React.useState<ts.types.widgets.TableData>(null);
  const [error, setError] = React.useState<ts.types.common.ApiError>(null);

  const [selectedTab, setSelectedTab] = React.useState<TabsEnum>(TabsEnum.FACTOR_RETURNS_AND_WEIGHTS);

  const loadData = async (tab: TabsEnum) => {
    try {
      const response = await loadWidgetData({
        data: {
          file: tab == TabsEnum.FACTOR_RETURNS_AND_WEIGHTS ? optimizedAlphaData : optimizedCovariance,
        },
      });

      if (response) {
        setData(response.data);
      }
    } catch (err) {
      setError(err as ts.types.common.ApiError);
    }
  };

  React.useEffect(() => {
    if (optimizedAlphaData && optimizedCovariance) loadData(selectedTab);
    return () => setData(null);
  }, [optimizedAlphaData, optimizedCovariance, selectedTab]);

  React.useEffect(() => {
    if (!_.isNil(data)) {
      if (selectedTab == TabsEnum.FACTOR_RETURNS_AND_WEIGHTS)
        createLayeredChart({
          id,
          data: data,
          fullScreen,
          xAxis: 'variable',
          leftTitle: 'Returns',
          rightTitle: 'Weights',
          outerBar: 'true_returns',
          outerBarTitle: 'Realized Returns',
          innerBar: 'returns',
          innerBarTitle: 'Forecast Returns',
          line: 'weights',
          lineTitle: 'Weights',
          exportTitle: title,
          prepareData: (layeredData) => prepareReturnsAndWeightsChart(layeredData, definition.signals),
        });
      else {
        createHeatMapChart({
          id,
          data: data as ts.types.widgets.HeatMapData,
          fullScreen,
          xAxis: 'factor_x',
          yAxis: 'factor_y',
          rotateLabels: true,
          exportTitle: title,
          prepareData: (heatMapData) => prepareCovariancesChart(heatMapData, definition.signals) as any,
        });
      }
    }
  }, [data]);

  const getTabs = () => {
    return (
      <mui.core.Tabs
        indicatorColor="primary"
        textColor="primary"
        value={selectedTab}
        onChange={(_e, newTab) => setSelectedTab(newTab)}
      >
        <mui.core.Tab label={TabsEnum.FACTOR_RETURNS_AND_WEIGHTS} value={TabsEnum.FACTOR_RETURNS_AND_WEIGHTS} />
        <mui.core.Tab label={TabsEnum.FACTOR_COVARIANCE} value={TabsEnum.FACTOR_COVARIANCE} />
      </mui.core.Tabs>
    );
  };

  return (
    <ChartWrapper
      id={id}
      readOnly={readOnly}
      widgetKey={widgetKey}
      data={data}
      error={error}
      fullScreen={fullScreen}
      tabs={getTabs()}
    />
  );
};

export default Chart;
