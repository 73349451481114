import { _, helpers, ts } from '_core';

import { DraftParams } from './types';

export const formatSeriesName = (key: string, baskets: ts.types.basket.Basket[]) => {
  const currentBasket = baskets.find((b) => b.handle == key);
  if (currentBasket) return currentBasket.name;

  return key;
};

export const prepareLinearData = (
  localData: ts.types.widgets.LinearData,
  isPortfolio = false,
  baskets: ts.types.basket.Basket[] = []
) => {
  const newData = {} as ts.types.widgets.LinearData;
  Object.keys(localData).forEach((key) => {
    const formattedKey = formatSeriesName(key.split('-')[0], baskets);
    if (isPortfolio) {
      if (key.includes('portfolio_returns')) newData[formattedKey] = localData[key];
    } else if (!key.includes('portfolio_returns')) newData[formattedKey] = localData[key];
  });

  return newData;
};

export const setUserDefinedOrderLinear = (
  data: ts.types.widgets.LinearData,
  basketId: number,
  basketIds: number[],
  baskets: ts.types.basket.Basket[]
) => {
  if (basketIds.includes(basketId)) {
    const newData = {} as ts.types.widgets.LinearData;
    basketIds.map((bid) => {
      const currentBasket = baskets.find((basket) => basket.id === bid)?.name;
      if (currentBasket) newData[currentBasket] = data[currentBasket];
    });
    return newData;
  }
  return data;
};

export const validate = (params: DraftParams) => helpers.validations.validBasketCompare(params);

export const formatData = (data: ts.types.widgets.TableData): ts.types.widgets.TableData => {
  const groupedData = _.groupBy(data, 'end_date');

  return _.map(groupedData, (items, date) => {
    const formattedEntry: ts.types.widgets.TableData[number] = { date };

    items.forEach((item) => {
      const { backtest_handle, benchmark_returns } = item;

      formattedEntry[`${backtest_handle}-benchmark_drawdown`] = benchmark_returns;
    });

    return formattedEntry;
  });
};
