import { ts } from '_core';


export default [
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.BASKET_SUMMARY_RISK_STATS,
    title: 'Summary Risk Statistics',
    helpLabel: 'dec8c877',
    form: false,
    defaultParams: {
      format_for_ui: true,
    },
    neededFiles: ['backtest_risk_forecast_summary_statistics'],
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.BASKET_PORTFOLIO_RISK],
  },
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.BACKTEST_SUMMARY_RISK_STATS,
    title: 'Summary Risk Statistics',
    helpLabel: 'i068owgg',
    form: false,
    defaultParams: {
      format_for_ui: true,
    },
    neededFiles: ['backtest_risk_forecast_summary_statistics'],
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.BACKTEST_PORTFOLIO_RISK],
  },
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.PORTFOLIO_SUMMARY_RISK_STATS,
    title: 'Summary Risk Statistics',
    helpLabel: 'jy2ms6bq',
    form: false,
    defaultParams: {
      format_for_ui: true,
    },
    neededFiles: ['portfolio_risk_forecast_summary_statistics'],
    tags: [
      ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.PORTFOLIO_RISK,
      ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.PORTFOLIO_STRAT_RISK,
    ],
  },
] satisfies ts.types.analysis.WidgetConfig[];
