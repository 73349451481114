/**
 * Collection of functions for generating random test data including numbers, strings and booleans.
 * The main function is 'gibberishGenerator', which determines which generator to call based on the type parameter.
 * Only numberGenerator and dateGenerator require additional parameters.
 */

/**
 * Generates a random number between min and max values
 * @param max - Maximum value (inclusive)
 * @param min - Minimum value (inclusive) 
 * @returns Random number between min and max
 */
const numberGenerator = (max: number, min: number): number => Math.random() * (max - min) + min;

/**
 * Generates a random alphanumeric string
 * @param length - Optional length of string to generate. If not provided, generates length between 4-5 characters
 * @returns Random alphanumeric string
 */
const stringGenerator = (length: number | null = null): string => {
  let resultString = '';
  const characters = 'abcdefghijklmnopqrstuvwxyz0123456789';

  if (!length) length = Math.floor(numberGenerator(5, 4));

  for (let i = 0; i < length; i++) {
    resultString += characters.charAt(Math.floor(Math.random() * characters.length));
  }

  return resultString;
};

/**
 * Generates a random boolean value
 * @returns Random true or false value
 */
const boolGenerator = (): boolean => (Math.round(Math.random()) == 1 ? true : false);

export { stringGenerator };
export { numberGenerator };
export { boolGenerator };
