import { ts } from '_core';

import { validate } from './helpers';
import { DraftParams, INTERACTION_PARAMS } from './types';

export default [
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.BACKTEST_DETAILED_ASSETS_PERTINENCE,
    helpLabel: '0hgk5tkx',
    form: true,
    title: 'Asset Pertinence',
    neededFiles: ['backtest_details'],
    interactionParams: Object.values(INTERACTION_PARAMS),
    defaultParams: { type: 'selected_assets' } as DraftParams,
    tags: [
      ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.BACKTEST_DETAILS,
      ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.BASKET_DETAILS,
    ],
    clearInteractionOnRegenerate: ['table_dtypes'],

    validate,
  },
] satisfies ts.types.analysis.WidgetConfig[];
