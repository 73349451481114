import React from 'react';

import * as enums from '../enums';
import { mui } from '../libs';
import * as types from '../types';

type ResourceEditableFieldProps = {
  children: React.ReactNode;
  resourceType: enums.RESOURCES_TYPES_ENUM;
  resource: types.common.ResourceDraft;
  selectedAttribute: string;

  disabled?: boolean;

  updateResource: (
    _d: types.common.ResourceDraft,
    _rt?: enums.RESOURCES_TYPES_ENUM,
    _id?: number
  ) => Promise<void>;
};

/**
 * Component that allows inline editing of resource fields by converting labels to editable inputs
 * 
 * Sample usage:
 *
 *   <ui.ResourceEditableField
 *     resource={signal}
 *     updateResource={updateResource}
 *     selectedAttribute="name"
 *   >
 *     <mui.core.Typography variant="h4">{signal.name}</mui.core.Typography>
 *   </ui.ResourceEditableField>
 *
 * @param children React nodes to display in non-edit mode
 * @param resource The resource object to edit
 * @param resourceType The type of resource being edited
 * @param selectedAttribute The attribute of the resource to edit
 * @param updateResource Callback to handle resource updates
 * @param disabled Whether editing is disabled
 * @returns Rendered component with editable field functionality
 */
const ResourceEditableField: React.FC<ResourceEditableFieldProps> = ({
  children,
  resource,
  resourceType,
  selectedAttribute,
  updateResource,
  disabled = false,
}): React.ReactElement => {
  const [editing, setEditing] = React.useState(false);
  const [tempValue, setTempValue] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    setTempValue(resource[selectedAttribute]);
  }, [resource[selectedAttribute]]);

  const onEdit = () => {
    setTempValue(resource[selectedAttribute]);
    setEditing(true);
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setTempValue(value);
  };

  const handleSave = async () => {
    setLoading(true);
    if (tempValue) {
      const data = {} as types.common.ResourceDraft;
      data.id = resource.id;
      data[selectedAttribute] = tempValue;

      await updateResource(data, resourceType, resource.id);
    }
    setEditing(false);
    setLoading(false);
  };

  const onKeyUp = (e: any) => {
    if (e.key == 'Escape') {
      setEditing(false);
    }

    if (
      e.key === 'Enter' ||
      e.which == 9 // Handle tabs
    ) {
      handleSave();
    }
  };

  const editableDiv = (
    <>
      {disabled ? (
        <mui.core.Box
          sx={{
            display: 'flex',
            padding: '0.25rem 0.5rem',
            alignItems: 'center',
            '& h5': {
              fontSize: '1rem',
            },
          }}
        >
          {children}
        </mui.core.Box>
      ) : (
        <mui.core.Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            padding: '0.25rem',
            minWidth: 0,
            border: '1px solid transparent',
            borderRadius: '4px',
            '& h5': {
              fontSize: '1rem',
            },
            '&:hover': {
              cursor: 'pointer',
              border: '1px dashed #ccc',
            },
          }}
          role="button"
          onClick={onEdit}
        >
          {children}
        </mui.core.Box>
      )}
    </>
  );

  return (
    <mui.core.Box mx={1}>
      {editing ? (
        <mui.core.ClickAwayListener onClickAway={() => setEditing(false)}>
          <div>
            <mui.core.TextField
              autoFocus
              onChange={onChange}
              onKeyDown={onKeyUp}
              value={tempValue}
              variant="outlined"
              sx={{
                minWidth: '200px',
                fontSize: '0.8rem',
                '& input': {
                  fontSize: '0.75rem !important',
                  fontWeight: '500',
                },
              }}
              size="small"
            />
            <mui.core.Button
              onClick={() => handleSave()}
              variant="contained"
              color="primary"
              style={{ marginLeft: '4px', marginRight: '10px' }}
              disabled={loading || !tempValue}
            >
              Update
            </mui.core.Button>
          </div>
        </mui.core.ClickAwayListener>
      ) : (
        editableDiv
      )}
    </mui.core.Box>
  );
};

export default ResourceEditableField;
