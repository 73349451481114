import { _, mui, React, ts } from '_core';

import { transform } from './helpers';
import ChartWrapper from '../../common/chart-wrapper';
import createLineChart from '../../common/charts/create-line-chart';

type ChartProps = {
  id: string;
  readOnly: boolean;
  widgetKey: string;
  portfolios: string;
  fullScreen: boolean;
  hasBenchmark: boolean;
  isBasket: boolean;
  isCombinedBasket: boolean;
  isLongOnly: boolean;
  scale: ts.enums.SCALE_ENUM;
  loadWidgetData: (_payload: ts.types.widgets.WidgetGetDataParams) => Promise<ts.types.widgets.WidgetGetDataResponse>;
  title: string;
};

const Chart: React.FC<ChartProps> = ({
  id,
  readOnly,
  widgetKey,
  fullScreen,
  scale,
  loadWidgetData,
  portfolios,
  hasBenchmark,
  isBasket,
  isCombinedBasket,
  isLongOnly,
  title,
}): React.ReactElement => {
  const [data, setData] = React.useState<ts.types.widgets.TableData>(null);
  const [filteredData, setFilteredData] = React.useState<ts.types.widgets.TableData>(null);
  const [error, setError] = React.useState<ts.types.common.ApiError>(null);
  const [showLegend, setShowLegend] = React.useState(false);

  const [selectedTab, setSelectedTab] = React.useState<'assets' | 'etf'>('assets');
  const availableTabs = isCombinedBasket ? ['assets', 'etf'] : ['assets'];

  const loadData = async () => {
    try {
      const response = await loadWidgetData({
        data: {
          file: portfolios,
        },
      });
      if (response.data) setData(response.data);
    } catch (err) {
      setError(err as ts.types.common.ApiError);
    }
  };

  React.useEffect(() => {
    if (portfolios) loadData();
    return () => setData(null);
  }, [portfolios]);

  React.useEffect(() => {
    if (!_.isNil(data)) {
      const localFilteredData = data.map((el) =>
        _.pickBy(
          el,
          (_, key) =>
            key == 'date' ||
            (isCombinedBasket && key.startsWith('etf_')) ||
            (!isCombinedBasket && !key.startsWith('etf_'))
        )
      );

      setFilteredData(localFilteredData);
    }
  }, [data, selectedTab]);

  React.useEffect(() => {
    // Create line chart on data and scale changes
    if (!_.isNil(filteredData))
      createLineChart({
        id,
        scale,
        data: filteredData,
        fullScreen,
        setShowLegend,
        percent: false,
        aggregation: 'outlier',
        exportTitle: title,
        prepareData: (linearData) => transform(linearData, isBasket, selectedTab == 'etf', hasBenchmark, isLongOnly),
      });
  }, [filteredData]);

  const getTabs = () => {
    if (availableTabs.length == 1) {
      return undefined;
    }
    return (
      <mui.core.Tabs
        indicatorColor="primary"
        textColor="primary"
        value={selectedTab}
        onChange={(_e, newTab) => setSelectedTab(newTab)}
      >
        <mui.core.Tab label="Assets" value="assets" />
        <mui.core.Tab label="Etf Components" value="etf" />
      </mui.core.Tabs>
    );
  };

  return (
    <ChartWrapper
      id={id}
      readOnly={readOnly}
      widgetKey={widgetKey}
      data={data}
      showLegend={showLegend}
      error={error}
      fullScreen={fullScreen}
      tabs={getTabs()}
    />
  );
};

Chart.defaultProps = {
  hasBenchmark: false,
};

export default Chart;
