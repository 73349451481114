/* eslint-disable no-unused-vars */

import { ts } from '_core';

export enum INTERACTION_PARAMS {
  IS_BASKET = 'isBasket',
}

export type InteractionParams = {
  [INTERACTION_PARAMS.IS_BASKET]: boolean;
};

export type JobParams = {
  histories: ts.enums.HISTORY_ENUM_EXTENDED[];
  statistics_type: 'return' | 'risk' | 'adjusted';
  benchmark?: Record<string, ts.enums.BENCHMARK_TYPES_ENUM | string | number>;
  with_benchmark: boolean;
};

export type Params = JobParams & InteractionParams;
export type DraftParams = Partial<Params>;
