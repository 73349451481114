import { hooks, mui, React } from '_core';

import { DraftParams } from './types';

type FormProps = {
  params: DraftParams;
  setParams: (_p: DraftParams) => void;
};

const Form: React.FC<FormProps> = ({ params, setParams }) => {
  const [selectedType, setSelectedType] = React.useState(params.is_total ? 'show_total' : 'long_short');

  const uiStyles = hooks.useUiStyles();

  React.useEffect(() => {
    setParams({ ...params, is_total: selectedType === 'show_total' });
  }, [selectedType]);

  return (
    <mui.core.Box sx={uiStyles.widgetEditMode}>
      <mui.core.Box mt={4}>
        <mui.core.FormControl component="fieldset">
          <mui.core.RadioGroup
            row
            aria-label="position"
            name="position"
            value={selectedType}
            onChange={(e) => {
              setSelectedType(e.target.value as 'show_total' | 'long_short');
            }}
          >
            <mui.core.FormControlLabel
              value="show_total"
              control={<mui.core.Radio color="primary" />}
              label="Show Total Turnover"
            />
            <mui.core.FormControlLabel
              value="long_short"
              control={<mui.core.Radio color="primary" />}
              label="Show Long and Short Turnover"
            />
          </mui.core.RadioGroup>
        </mui.core.FormControl>
      </mui.core.Box>
    </mui.core.Box>
  );
};

export default Form;
