import { ts } from '_core';

import { getName, validate } from './helpers';
import { DraftParams, INTERACTION_PARAMS } from './types';

export default [
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.BASKET_COMPARE_TURNOVER_TIME,
    title: 'Turnover',
    form: true,
    helpLabel: '9ax4c86d',
    defaultParams: {
      aggregation: 'ANNUAL',
      selectedType: 'show_total',
    } as DraftParams,
    interactionParams: Object.values(INTERACTION_PARAMS),
    neededFiles: ['backtest_turnover_analysis'],
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.BASKET_COMPARE],

    validate,
    getName,
  },
] satisfies ts.types.analysis.WidgetConfig[];
