import { React, ts } from '_core';

import Chart from './chart';
import { DraftParams, Params } from '../portfolio-percent-contribution-to-risk/types';

type SummaryRiskStatsProps = {
  id: string;
  readOnly: boolean;
  widgetKey: string;
  loadWidgetData: (_payload: ts.types.widgets.WidgetGetDataParams) => Promise<ts.types.widgets.WidgetGetDataResponse>;
  params: Params | DraftParams;
  setParams: (_p: DraftParams) => void;
  widgetPaths: Record<
    | 'rebalance_risk_forecast_summary_statistics'
    | 'backtest_risk_forecast_summary_statistics'
    | 'portfolio_risk_forecast_summary_statistics',
    string
  >;
  title: string;
  fullScreen: boolean;
};

const SummaryRiskStats: React.FC<SummaryRiskStatsProps> = ({
  id,
  readOnly,
  widgetKey,
  loadWidgetData,
  widgetPaths,
  fullScreen,
  title,
}) => {
  const fileKey = React.useMemo(
    () =>
      Object.keys(widgetPaths || {}).find((k) =>
        k.endsWith('_forecast_summary_statistics')
      ) as keyof typeof widgetPaths,
    [widgetPaths]
  );

  return (
    <>
      <Chart
        id={id}
        readOnly={readOnly}
        widgetKey={widgetKey}
        loadWidgetData={loadWidgetData}
        file={widgetPaths[fileKey]}
        fullScreen={fullScreen}
        title={title}
      />
    </>
  );
};

export default SummaryRiskStats;
