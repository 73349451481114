import { config, helpers, ts } from '_core';

export const getNewTimeSeries = (name: string, handle: string, template: ts.types.timeSeries.TimeSeries = null) => {
  const nullDefinition = { source: '' };

  // Check if we will create the time series with a premade definition
  const newSignal = {
    name,
    handle,
    definition: nullDefinition,
    start_date: config.features.start_date,
    end_date: config.features.end_date,
    frequency: ts.enums.FREQUENCY_ENUM.WEEKLY,
    is_valid: false,
  } as ts.types.timeSeries.TimeSeriesDraft;

  if (template && template.id) {
    newSignal.definition = template.definition;
    newSignal.signature = template.signature;
    newSignal.signal_ids = template.signal_ids;
    newSignal.universe_ids = template.universe_ids;
    newSignal.risk_model_ids = template.risk_model_ids;
    newSignal.pipeline_ids = template.pipeline_ids;
    newSignal.start_date = template.start_date;
    newSignal.end_date = template.end_date;
    newSignal.frequency = template.frequency;
    newSignal.errors = template.errors;
    newSignal.is_valid = template.is_valid;
    newSignal.label_ids = template.label_ids;
  }

  return { ...newSignal } as ts.types.timeSeries.TimeSeries;
};

export const getArchivedDeps = (
  timeSeries: ts.types.timeSeries.TimeSeries,
  resources: ts.StoreState['resources']
): ts.types.common.ArchivedDeps => {
  const deps = helpers.resourceGraph.getDeps(
    { ...timeSeries, resourceType: ts.enums.RESOURCES_TYPES_ENUM.TIME_SERIES },
    resources
  );
  return helpers.resourceGraph.getArchiveDeps(deps);
};

export const getUnpublishedDeps = (
  timeSeries: ts.types.timeSeries.TimeSeries,
  resources: ts.StoreState['resources']
): ts.types.common.ResourceDeps[] => {
  return helpers.resourceGraph
    .getDeps({ ...timeSeries, resourceType: ts.enums.RESOURCES_TYPES_ENUM.TIME_SERIES }, resources)
    .filter((d) => !d.is_published);
};

export const getUnsharedDeps = (
  timeSeries: ts.types.timeSeries.TimeSeries,
  resources: ts.StoreState['resources'],
  sharedWith?: number[]
): ts.types.common.ResourceDeps[] => {
  if (!sharedWith) sharedWith = timeSeries.shared_with || [];

  return helpers.resourceGraph
    .getDeps({ ...timeSeries, resourceType: ts.enums.RESOURCES_TYPES_ENUM.TIME_SERIES }, resources)
    .filter((d) => !helpers.resourcesUtils.isShared(d, sharedWith));
};
