/* eslint-disable no-unused-vars */
import { ts } from '_core';

import { PartialDeep } from 'type-fest';

export enum INTERACTION_PARAMS {
  TABLE_PARAMS = 'table_params',
  TABLE_DTYPES = 'table_dtypes',
}

export type InteractionParams = {
  [INTERACTION_PARAMS.TABLE_PARAMS]: ts.types.components.dataGrid.TableParamsSheet;
  [INTERACTION_PARAMS.TABLE_DTYPES]: ts.types.components.dataGrid.TableDtypesSheet;
};

export type JobParams = {
  analysis_pipelined_signals: {
    signal_id: number;
    pipeline_id?: number;
    sp_pipeline_id?: number;
  }[];
};

export type Params = JobParams & InteractionParams;
export type DraftParams = PartialDeep<Params>;
