import { helpers, ts } from '_core';

import _ from 'lodash';

import { Params } from './type';

export default [
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.METRIC_BASKET_RETURNS_BY_SECTOR,
    title: 'Attribution by Sector',
    form: false,
    helpLabel: 'wrg8xhza',
    neededFiles: ['signal_returns_driven_attribution_chart'],
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.BASKET_ADHOC],

    defaultParams: {
      statistics_type: ts.enums.REPORT_ENUMS.ANNU_STATISTICS_ENUM.RETURNS,
      buckets: { type: 'INDUSTRY', value: 'rbics_l1_name' },
    } as Params,
    getAdhocData: (pathFile, params, context) => {
      const bucket = `INDUSTRY:${params.buckets[0].value}`;
      const category = params.buckets[0].value == 'rbics_l1_name' ? 'sector' : 'industry';
      const histories = helpers.periods.getWindowPeriods(context.start_date, context.end_date);

      return {
        name: `Attribution by ${_.startCase(category)}`,
        file_name: pathFile,
        description: 'This file contains the attributed returns of the basket, attributed to individual assets.',
        columns: [
          { key: bucket, name: category, description: 'Asset name.', type: 'string' },
          {
            key: 'history',
            name: 'period',
            description: `One of: ${Object.values(histories).join(', ')}.`,
            type: 'string',
          },
          {
            key: 'returns',
            name: 'returns',
            description: `Attributed returns for that ${category}.`,
            type: 'float',
          },
        ],
      };
    },
  },
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.METRIC_BASKET_RETURNS_BY_INDUSTRY,
    title: 'Attribution by Industry',
    form: false,
    helpLabel: 'iu99dr7j',
    neededFiles: ['signal_returns_driven_attribution_chart'],
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.BASKET_ADHOC],

    defaultParams: {
      statistics_type: ts.enums.REPORT_ENUMS.ANNU_STATISTICS_ENUM.RETURNS,
      buckets: { type: 'INDUSTRY', value: 'rbics_l3_name' },
    } as Params,
    getAdhocData: (pathFile, params, context) => {
      const bucket = `INDUSTRY:${params.buckets[0].value}`;
      const category = params.buckets[0].value == 'rbics_l1_name' ? 'sector' : 'industry';
      const histories = helpers.periods.getWindowPeriods(context.start_date, context.end_date);

      return {
        name: `Attribution by ${_.startCase(category)}`,
        file_name: pathFile,
        description: 'This file contains the attributed returns of the basket, attributed to individual assets.',
        columns: [
          { key: bucket, name: category, description: 'Asset name.', type: 'string' },
          {
            key: 'history',
            name: 'period',
            description: `One of: ${Object.values(histories).join(', ')}.`,
            type: 'string',
          },
          {
            key: 'returns',
            name: 'returns',
            description: `Attributed returns for that ${category}.`,
            type: 'float',
          },
        ],
      };
    },
  },
] satisfies ts.types.analysis.WidgetConfig[];
