import React from 'react';

import EndDateOnly from './end-date-only';
import StartDateOnly from './start-date-only';
import { datesToValue, getValidValue, valueToDates } from './utils';
import { mergeRefs } from '../../helpers';
import { useEffectWithoutFirst } from '../../hooks';
import { _, moment, mui } from '../../libs';
import DateDayRender from '../date-day-render';
import DateRangeActionBar from '../date-range-action-bar';
import { DatePickerFooter } from '../smart-date-selector';

type FinDateRangePickerProps = {
  value: string[];
  onChange: (_v: string[]) => void;
  minDate: string;
  maxDate: string;
  validDates?: string[];
  allValidDates?: string[];
  allValidDailyDates?: string[];
  loading?: boolean;
  disabled?: boolean;
  setExternalError?: (_v: string) => void;
  verticalPositioning?: boolean;
  hideInputError?: boolean;
  dateRef?: React.MutableRefObject<HTMLDivElement>;
  placement?: 'bottom' | 'top' | 'left' | 'right';
  onlyUpdateOnClose?: boolean;
  onlyEndDate?: boolean;
  onlyStartDate?: boolean;
  defaultToFullRangeIfEmpty?: boolean;
  [key: string]: any;
};

/**
 * Component that provides a date range picker with validation and formatting capabilities
 * 
 * @param value - Array of start and end dates in string format ['YYYY-MM-DD', 'YYYY-MM-DD']
 * @param onChange - Callback when dates change, receives array of dates
 * @param minDate - Minimum selectable date in YYYY-MM-DD format
 * @param maxDate - Maximum selectable date in YYYY-MM-DD format
 * @param validDates - Array of valid dates for start date selection
 * @param allValidDates - Array of valid dates for end date selection
 * @param allValidDailyDates - Array of valid daily dates for calendar display
 * @param loading - Whether the component is in loading state
 * @param disabled - Whether the component is disabled
 * @param setExternalError - Callback to set external error state
 * @param verticalPositioning - Whether to stack inputs vertically
 * @param hideInputError - Whether to hide input validation errors
 * @param dateRef - Ref for the date input element
 * @param placement - Popper placement ('bottom', 'top', 'left', 'right')
 * @param onlyUpdateOnClose - Only trigger onChange when picker is closed
 * @param onlyEndDate - Show only end date picker
 * @param onlyStartDate - Show only start date picker
 * @param defaultToFullRangeIfEmpty - Default to full date range if no dates selected
 * 
 * @returns Date range picker component
 */
const FinDateRangePicker: React.FC<FinDateRangePickerProps> = ({ ...props }): React.ReactElement => {
  const {
    value,
    onChange,
    minDate,
    maxDate,
    validDates,
    allValidDates,
    allValidDailyDates,
    loading,
    disabled,
    setExternalError,
    verticalPositioning,
    hideInputError,
    placement,
    dateRef,
    onlyEndDate,
    onlyStartDate,
    onlyUpdateOnClose,
    defaultToFullRangeIfEmpty,
  } = props;

  const [open, setOpen] = React.useState(false);
  const [localValue, setLocalValueState] = React.useState<mui.date.DateRange<Date>>([null, null]);

  const [dateErrors, setDateErrors] = React.useState([false, false]);
  const updateDateError = (v: [boolean, boolean]) => {
    if (!v) setDateErrors([false, false]);
    else setDateErrors(v);
  };

  const [shortcuts, setShortcuts] = React.useState(false);

  const localValueRef = React.useRef<mui.date.DateRange<Date>>([null, null]);

  const startInputRef = React.useRef() as React.RefObject<HTMLInputElement>;
  const endInputRef = React.useRef() as React.RefObject<HTMLInputElement>;

  const setLocalValue = (v: mui.date.DateRange<Date>) => {
    localValueRef.current = v;
    setLocalValueState(v);
  };

  React.useEffect(() => {
    const effectValue = value ?? [null, null];
    if (defaultToFullRangeIfEmpty && !effectValue[0] && !effectValue[1])
      setLocalValue(valueToDates([minDate, maxDate]));
    else setLocalValue(valueToDates(effectValue as [string, string]));
  }, [value]);

  const updateDates = (shouldClose = true) => {
    const newValue = localValueRef.current;
    const localDateErrors = [newValue[0]?.toString() === 'Invalid Date', newValue[1]?.toString() === 'Invalid Date'];

    if (localDateErrors[0] || localDateErrors[1]) setDateErrors(localDateErrors);
    else {
      const newVal = datesToValue(localValueRef.current);
      if (value[0] !== newVal[0] || value[1] !== newVal[1]) onChange(newVal);
      if (shouldClose) setOpen(false);
    }
  };

  React.useEffect(() => {
    const errors = [];
    if (dateErrors[0]) errors.push('Start date is invalid');
    if (dateErrors[1]) errors.push('End date is invalid');
    setExternalError(errors.join('') || null);
  }, [dateErrors]);

  // First time is mount
  // Second time is the first load of the valid dates component
  // Next N changes is what we need, frequency changes, universe changes, etc
  // We default to 2 due to the above reasons. There are places (like datastets)
  // where we want to get valid dates since second render.
  useEffectWithoutFirst(() => {
    setLocalValue(getValidValue(localValue, validDates, allValidDates));
    updateDates();
  }, [validDates, allValidDates]);

  const loadingAdornment = loading ? (
    <mui.core.Box ml={1} mt={1} mr={2}>
      <mui.core.CircularProgress color="primary" sx={{ svg: { display: 'block!important' } }} size={13} />
    </mui.core.Box>
  ) : undefined;

  useEffectWithoutFirst(() => {
    if (!onlyUpdateOnClose) updateDates(false);
  }, [localValue]);

  const handleOnClose = () => {
    if (!onlyUpdateOnClose) setOpen(false);
    else updateDates();
  };

  if (onlyStartDate) {
    return (
      <StartDateOnly
        value={localValue}
        onChange={(v) => {
          setLocalValue(getValidValue(v, validDates, allValidDates));
          updateDates();
        }}
        minDate={minDate}
        loading={loading}
        validDates={validDates}
        allValidDailyDates={allValidDailyDates}
        disabled={loading || disabled}
        verticalPositioning={verticalPositioning}
        setDateErrors={setDateErrors}
      />
    );
  }

  if (onlyEndDate) {
    return (
      <EndDateOnly
        value={valueToDates(value as [string, string])}
        onChange={(v) => {
          setLocalValue(getValidValue(v, validDates, allValidDates));
          updateDates();
        }}
        maxDate={maxDate}
        loading={loading}
        validDates={validDates}
        allValidDailyDates={allValidDailyDates}
        disabled={loading || disabled}
        verticalPositioning={verticalPositioning}
        setDateErrors={setDateErrors}
      />
    );
  }

  return (
    <mui.date.LocalizationProvider
      dateAdapter={mui.date.AdapterDateFns}
      localeText={{
        start: 'Start Date',
        end: 'End Date',
      }}
    >
      <mui.date.DateRangePicker
        {...props}
        open={open}
        disabled={loading || disabled}
        onOpen={() => {
          setLocalValue(getValidValue(localValue, validDates, allValidDates));
          setOpen(true);
        }}
        defaultCalendarMonth={maxDate ? moment(maxDate).toDate() : undefined}
        inputFormat="yyyy-MM-dd"
        value={localValue}
        onChange={setLocalValue}
        maxDate={maxDate ? moment(maxDate).toDate() : undefined}
        minDate={minDate ? moment(minDate).toDate() : undefined}
        onClose={handleOnClose}
        onError={(reason) => {
          updateDateError([!!reason[0], !!reason[1]]);
        }}
        shouldDisableDate={
          allValidDates
            ? (date) => {
                return !allValidDates.includes(moment(date).format('YYYY-MM-DD'));
              }
            : undefined
        }
        renderDay={(date, dayProps) => (
          <DateDayRender
            date={date}
            dayProps={dayProps}
            validDates={validDates}
            allValidDailyDates={allValidDailyDates}
          />
        )}
        PopperProps={{
          placement: placement,
          modifiers: [
            {
              name: 'preventOverflow',
              options: {
                altAxis: true,
                tether: false,
              },
            },
          ],
        }}
        components={{
          ActionBar: () => (
            <mui.core.Box px={3} position="relative">
              <mui.core.Box display="flex" justifyContent="space-between" pt={2}>
                <mui.core.Box flex={1}>
                  <mui.core.Button onClick={() => setShortcuts(!shortcuts)}>
                    {shortcuts ? 'Hide Shortcuts' : 'Show Shortcuts'}
                  </mui.core.Button>
                </mui.core.Box>

                {!_.isEmpty(validDates) && !_.isEmpty(allValidDates) && (
                  <mui.core.Box flex={1} mt={-2}>
                    <DatePickerFooter />
                  </mui.core.Box>
                )}

                <mui.core.Box flex={1} display="flex" justifyContent="flex-end">
                  <mui.core.Button onClick={handleOnClose}>Close</mui.core.Button>
                </mui.core.Box>
              </mui.core.Box>

              <mui.core.Box>
                {shortcuts && (
                  <DateRangeActionBar
                    minDate={minDate}
                    maxDate={maxDate}
                    value={localValue}
                    setValue={setLocalValue}
                    startInputRef={startInputRef}
                    endInputRef={endInputRef}
                    updateDates={updateDates}
                    validDates={validDates}
                    allValidDates={allValidDates}
                  />
                )}
              </mui.core.Box>
            </mui.core.Box>
          ),
        }}
        renderInput={(startProps, endProps) => {
          return (
            <React.Fragment>
              <mui.core.Grid container spacing={2}>
                <mui.core.Grid item xs={verticalPositioning ? 12 : 6}>
                  <mui.core.TextField
                    {...startProps}
                    helperText={dateErrors[0] && !hideInputError ? 'Selected date is not valid' : undefined}
                    InputProps={{
                      endAdornment: (
                        <mui.core.InputAdornment position="end">
                          <mui.core.IconButton
                            disabled={disabled}
                            onClick={() => {
                              setOpen(!open);
                            }}
                          >
                            <mui.icons.Event />
                          </mui.core.IconButton>
                        </mui.core.InputAdornment>
                      ),
                      startAdornment: (
                        <mui.core.InputAdornment position="start">{loadingAdornment}</mui.core.InputAdornment>
                      ),
                    }}
                    fullWidth
                    size="small"
                    inputRef={mergeRefs([startInputRef, dateRef])}
                  />
                </mui.core.Grid>
                <mui.core.Grid item xs={verticalPositioning ? 12 : 6}>
                  <mui.core.TextField
                    {...endProps}
                    helperText={dateErrors[1] && !hideInputError ? 'Selected date is not valid' : undefined}
                    InputProps={{
                      endAdornment: (
                        <mui.core.InputAdornment position="end">
                          <mui.core.IconButton
                            disabled={disabled}
                            onClick={() => {
                              setOpen(!open);
                            }}
                          >
                            <mui.icons.Event />
                          </mui.core.IconButton>
                        </mui.core.InputAdornment>
                      ),
                      startAdornment: (
                        <mui.core.InputAdornment position="start">{loadingAdornment}</mui.core.InputAdornment>
                      ),
                    }}
                    fullWidth
                    size="small"
                    inputRef={endInputRef}
                  />
                </mui.core.Grid>
              </mui.core.Grid>
            </React.Fragment>
          );
        }}
      />
    </mui.date.LocalizationProvider>
  );
};

FinDateRangePicker.defaultProps = {
  value: [null, null],
  inputProps: {},
  setExternalError: () => undefined,
  verticalPositioning: false,
  hideInputError: false,
  onlyEndDate: false,
  onlyUpdateOnClose: false,
};

export default FinDateRangePicker;
