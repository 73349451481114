import React from 'react';

import { _, mui } from '../../../libs';
import * as types from '../../../types';
import CenteredLoader from '../../centered-loader';
import EmptyMessage from '../../empty-message';

type SearchResultsProps = {
  options: types.etf.ETFObj[];
  loadingEtf: boolean;
  infiniteScrollLoading: boolean;
  multiple: boolean;
  error: string;
  selectedETFs: types.etf.ETFAssetMetaData | types.etf.ETFAssetMetaData[];
  setSelectedETFs: (_value: types.etf.ETFAssetMetaData | types.etf.ETFAssetMetaData[]) => void;
  loadOnScroll: () => void;
};

const ETFSearchResults: React.FC<SearchResultsProps> = ({
  options,
  loadingEtf,
  error,
  multiple,
  selectedETFs,
  setSelectedETFs,
  infiniteScrollLoading,
  loadOnScroll,
}): React.ReactElement => {
  if (error)
    return (
      <EmptyMessage hideBorder>
        <mui.lab.Alert severity="error">{error}</mui.lab.Alert>
      </EmptyMessage>
    );
  if (loadingEtf) return <CenteredLoader top="-100px" label="Loading ETFs" />;

  const handleInfiniteScroll = (e: any) => {
    const boxNode = e.currentTarget;

    if (boxNode.scrollTop + boxNode.clientHeight + 30 >= boxNode.scrollHeight) {
      loadOnScroll();
    }
  };

  if (!_.isEmpty(options)) {
    return (
      <>
        <mui.core.Box px={3} pt={3} pb={2}>
          <mui.core.Typography variant="body2" fontWeight="fontWeightBold">
            ETFs found
          </mui.core.Typography>
        </mui.core.Box>
        <mui.core.Box
          pt={0}
          p={3}
          onScroll={handleInfiniteScroll}
          sx={{
            maxHeight: 400,
            overflow: 'auto',
          }}
        >
          <mui.core.FormGroup>
            {options.map((option) => (
              <mui.core.FormControlLabel
                key={option.fid}
                onChange={(_e) => {
                  let currentSelection = _.cloneDeep(selectedETFs) || null;

                  if (multiple) {
                    const selected = !!((currentSelection || []) as types.etf.ETFAssetMetaData[]).find(
                      (a) => a.fid == option.fid
                    );

                    if (selected) {
                      currentSelection = _.filter(
                        currentSelection as types.etf.ETFAssetMetaData[],
                        (metaData) => metaData.fid !== option.fid
                      );
                    } else {
                      currentSelection = [...((currentSelection || []) as types.etf.ETFAssetMetaData[]), option];
                    }
                  } else {
                    currentSelection = option;
                  }
                  setSelectedETFs(currentSelection);
                }}
                control={
                  <mui.core.Checkbox
                    size="small"
                    color="primary"
                    checked={
                      multiple
                        ? !!((selectedETFs || []) as types.etf.ETFAssetMetaData[]).find(
                            (etf) => etf.fid == option.fid
                          )
                        : (selectedETFs as types.etf.ETFAssetMetaData)?.fid == option.fid
                    }
                  />
                }
                label={
                  <mui.core.Box my={1}>
                    <mui.core.Typography variant="body2" fontWeight="fontWeightMedium">
                      {option.name}
                    </mui.core.Typography>
                    <mui.core.Typography variant="body2" color="textSecondary">
                      {option.ticker}
                      {option.aum && ` - $${_.formatNumberWithSuffix(_.toNumber(option.aum), 0)}`}
                    </mui.core.Typography>
                  </mui.core.Box>
                }
              />
            ))}
          </mui.core.FormGroup>

          {infiniteScrollLoading && (
            <mui.core.Box>
              <CenteredLoader />
            </mui.core.Box>
          )}
        </mui.core.Box>
      </>
    );
  }

  if (options == null) {
    return <EmptyMessage>Select your params and search for ETFs.</EmptyMessage>;
  }

  return <EmptyMessage>No results found for your query, try adjusting the search params...</EmptyMessage>;
};

ETFSearchResults.defaultProps = {
  error: null,
};

export default ETFSearchResults;
