import React from 'react';

import Filters from './filters';
import SearchPopper from './search-popper';
import { Filter } from './search-types';
import { resourcesSearch } from '../../helpers';
import { _, mui } from '../../libs';
import * as types from '../../types';
import CenteredLoader from '../centered-loader';

interface IGuidedSearchProps<T> {
  // options: T[];
  inputFilters: Filter[];
  filterOptions: (_options: T[], _val: string) => T[];
  emptyView: React.ReactNode;
  setOpened: (_opened: boolean) => void;
  onResultClick: (_option: T) => void;
  inputStyle?: mui.core.SxProps;
  renderOption?: React.FunctionComponent<T>;
  saveSearchLabel?: string;
  saveSearch?: (_val: string) => void;
  sortBy?: (_options: T[]) => T[];
  formatOptions?: (_options: T[]) => T[];
}

/**
 * Component that provides guided search functionality with filters and autocomplete
 *
 * @param inputFilters - Array of Filter objects defining available search filters
 * @param filterOptions - Function to filter options based on search term
 * @param emptyView - React node to show when search is empty
 * @param setOpened - Function to control search popup visibility
 * @param onResultClick - Callback when a search result is clicked
 * @param inputStyle - Optional styles for the search input
 * @param renderOption - Optional custom render function for search results
 * @param saveSearchLabel - Optional label for save search button
 * @param saveSearch - Optional callback to save a search
 * @param sortBy - Optional function to sort filtered results
 * @param formatOptions - Optional function to format search results
 *
 * @returns Rendered guided search component
 */
const GuidedSearch = <T extends resourcesSearch.ReturnItem = types.common.ResourceExpanded>(
  props: IGuidedSearchProps<T>
) => {
  const theme = mui.styles.useTheme() as mui.core.Theme;

  const {
    inputFilters,
    filterOptions,
    emptyView,
    onResultClick,

    inputStyle,
    renderOption,
    saveSearch,
    saveSearchLabel,
    sortBy,
    formatOptions = (options) => options,
    setOpened,
  } = props;

  const [showFilters, setShowFilters] = React.useState(false);
  const [uiFilters, setUiFilters] = React.useState('');
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState<T[]>([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [totalResults, setTotalResults] = React.useState(0);

  const [selectedFilter, setSelectedFilter] = React.useState<Filter>();
  const autocompleteRef = React.useRef();

  const removeUiFilter = (uiFilter: string) => {
    const newUiFilters = uiFilters.replaceAll(uiFilter, '');
    setUiFilters(newUiFilters.trim());
  };

  const selectedFilters = React.useMemo(() => {
    if (!uiFilters) return [];
    const filtersEquality = uiFilters.trim().match(/(?:[^\s"]+|"[^"]*")+/g);
    return filtersEquality.map((fe) => fe.split(':')[0].trim());
  }, [uiFilters]);

  const getOptionLabel = (option: string | T) => {
    // Option label needs to be unique
    if ((option as T).name && (option as T).handle) return `${(option as T).name} (${(option as T).handle})`;
    // If we don't have handle, then the name is unique
    if ((option as T).name) return (option as T).name;
    return '';
  };

  const localFilterOptions = (localOptions: T[], s: { inputValue: string }, localUiFilters: string) => {
    let term = s.inputValue;
    if (localUiFilters) term = localUiFilters.trim() + ' ' + s.inputValue;

    const filteredOptions = filterOptions(localOptions, term);
    setTotalResults(filteredOptions.length);
    const limitedOptions = filteredOptions.slice(0, 100); // Cap the results to 100
    setOptions(sortBy ? sortBy(formatOptions(limitedOptions)) : formatOptions(limitedOptions));
    setIsLoading(false);
  };

  const debouncedFilterOptions = React.useMemo(() => _.debounce(localFilterOptions, 500), []);

  React.useEffect(() => {
    if (inputValue || uiFilters) {
      setIsLoading(true);
      debouncedFilterOptions(options, { inputValue }, uiFilters);
    }
  }, [inputValue, uiFilters]);

  const emptyQuery = (inputValue?.length ?? 0) == 0 && !uiFilters;

  const CustomPaperComponent = React.useMemo(
    () => (props: any) => (
      <SearchPopper
        emptyQuery={emptyQuery}
        showFilters={showFilters}
        isLoading={isLoading}
        options={options}
        saveSearch={saveSearch}
        saveSearchLabel={saveSearchLabel}
        inputValue={inputValue}
        uiFilters={uiFilters}
        totalResults={totalResults}
      >
        {props.children}
      </SearchPopper>
    ),
    [emptyQuery, showFilters, isLoading, options, saveSearch, saveSearchLabel, inputValue, uiFilters, totalResults]
  );

  return (
    <>
      <mui.core.Autocomplete<any, boolean, boolean, boolean>
        freeSolo
        openOnFocus
        open
        inputValue={inputValue}
        onInputChange={(_, newInputValue, reason) => {
          if (reason === 'input') {
            setInputValue(newInputValue);
          }
        }}
        onChange={(e, value) => {
          onResultClick(value);
        }}
        disabled={showFilters}
        disableClearable={true}
        disablePortal
        groupBy={(option) => option.group_label}
        options={options}
        filterOptions={(options) => options}
        getOptionLabel={getOptionLabel}
        onClose={(_e, reason) => {
          if (reason == 'toggleInput' && uiFilters) return;
          if (reason == 'blur' && selectedFilter?.type == 'text') return;
          setSelectedFilter(undefined);
          setUiFilters('');
        }}
        renderOption={(props, option) => {
          return (
            <li
              tabIndex={props.tabIndex}
              role={props.role}
              id={props.id}
              data-option-index={(props as any)['data-option-index']}
              aria-disabled={props['aria-disabled']}
              aria-selected={props['aria-selected']}
            >
              {renderOption(option, () => setOpened(false))}
            </li>
          );
        }}
        PaperComponent={CustomPaperComponent}
        renderInput={(p) => (
          <mui.core.TextField
            {...p}
            autoFocus
            inputRef={autocompleteRef}
            sx={inputStyle}
            placeholder="Search resource"
            onKeyDown={(ev) => {
              const currValue = (ev.target as any).value || '';

              if (ev.key === 'Backspace' && uiFilters && !currValue) {
                removeUiFilter(uiFilters.match(/(?:[^\s"]+|"[^"]*")+/g).at(-1) as string);
              }

              if (ev.key === 'Escape') {
                ev.preventDefault();
                setOpened(false);
              }
            }}
            InputProps={{
              ...p.InputProps,
              startAdornment: (
                <>
                  <mui.core.InputAdornment position="start">
                    <mui.icons.Search
                      style={{
                        fontSize: '1.5rem',
                        opacity: 0.7,
                        color: (theme.palette as any).primary.main,
                      }}
                    />
                  </mui.core.InputAdornment>

                  {uiFilters &&
                    uiFilters.match(/(?:[^\s"]+|"[^"]*")+/g).map((uiFilter) => (
                      <mui.core.Tooltip key={uiFilter} title={uiFilter}>
                        <mui.core.Chip
                          label={uiFilter}
                          size="small"
                          sx={{
                            marginRight: '5px',
                            minWidth: '120px',
                            '& svg': {
                              color: `${(theme.palette as any).textLight.light}!important`,
                            },
                          }}
                          onClick={() => removeUiFilter(uiFilter)}
                          onDelete={() => removeUiFilter(uiFilter)}
                          variant="outlined"
                        />
                      </mui.core.Tooltip>
                    ))}
                </>
              ),
              endAdornment: (
                <>
                  <mui.core.InputAdornment position="end" sx={{ position: 'absolute', right: '1rem' }}>
                    <mui.core.Tooltip title={showFilters ? 'Close Filters' : 'Filter search'}>
                      <mui.core.IconButton
                        aria-label="open filters"
                        onClick={() => {
                          setShowFilters(!showFilters);
                          setOpened(true);
                        }}
                        edge="end"
                      >
                        {showFilters ? (
                          <mui.icons.Close
                            style={{
                              fontSize: '1.1rem',
                              opacity: 0.5,
                            }}
                          />
                        ) : (
                          <mui.icons.FilterList
                            style={{
                              fontSize: '1.1rem',
                            }}
                          />
                        )}
                      </mui.core.IconButton>
                    </mui.core.Tooltip>
                  </mui.core.InputAdornment>
                  <mui.core.Box ml="35px">{p.InputProps.endAdornment}</mui.core.Box>
                </>
              ),
            }}
          />
        )}
      />

      {isLoading && (inputValue || uiFilters) && <CenteredLoader top="-100px" label="Searching..." />}

      {emptyQuery && !showFilters && emptyView}

      {showFilters && (
        <mui.core.Box
          sx={{
            maxHeight: '380px',
            backgroundColor: 'white',
            borderRadius: '4px',
            overflowY: 'auto',
            overflowX: 'hidden',
            mt: 4,
          }}
        >
          <Filters
            autocompleteFocus={() => {
              (autocompleteRef.current as any)?.focus();
            }}
            currFilter={selectedFilter}
            setCurrFilter={setSelectedFilter}
            filters={inputFilters}
            addFilter={(filt) => {
              setUiFilters(uiFilters ? uiFilters + ' ' + filt : filt);
              setShowFilters(false);
            }}
            selectedFilters={selectedFilters}
          />
        </mui.core.Box>
      )}
    </>
  );
};

export default GuidedSearch;
