import { ts } from '_core';

import { validate } from './helpers';
import { DraftParams } from './types';

export default [
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.SIGNAL_ATTRIBUTION_RETURNS_DRIVEN,
    title: 'Returns Decomposition by Risk Factors',
    form: true,
    helpLabel: 'dvzukrhl',
    defaultParams: {
      histories: '$context_histories',
      buckets: { type: ts.enums.BUCKET_TYPES_ENUM.QUANTILES, value: 5 },
      variables: { type: ts.enums.EXPLANATORY_VARIABLE_TYPES_ENUM.RISK_FACTORS, values: ['style'] },
      variable_filter: 'NONE',
      include_benchmark: false,
      intercept: false,
      benchmark_neutral: false,
    } as DraftParams,
    neededFiles: ['signal_returns_driven_attribution_chart'],
    tags: [
      ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.SIGNAL_PORTAN,
      ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.BASKET_ATTRIBUTION,
    ],

    validate,
    subtitle: 'Heat Map',
  },
] satisfies ts.types.analysis.WidgetConfig[];
