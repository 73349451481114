import { ts } from '_core';

import { validate } from './helpers';
import { INTERACTION_PARAMS } from './types';

export default [
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.RISK_MODEL_PREVIEW_UNIVERSE_COVERAGE,
    form: true,
    title: 'Universe Coverage',
    neededFiles: ['risk_model_coverage_snapshot'],
    interactionParams: Object.values(INTERACTION_PARAMS),
    clearInteractionOnRegenerate: ['table_dtypes'],
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.RISK_MODEL_PREVIEW],

    validate,
    helpLabel: '35oyi2p4',
  },
] satisfies ts.types.analysis.WidgetConfig[];
