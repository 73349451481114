import React from 'react';

import SelectFilter from './select-filter';
import SelectOption from './select-option';
import TextFieldOption from './textfield-option';
import { mui } from '../../../libs';
import { Filter, Option } from '../search-types';


type FiltersProps = {
  filters: Filter[];
  addFilter: (_f: string) => void;
  selectedFilters: string[];

  currFilter: Filter;
  setCurrFilter: (_f: Filter) => void;
  autocompleteFocus?: () => void;
};

const Filters: React.FC<FiltersProps> = ({
  filters,
  addFilter,
  selectedFilters,

  currFilter,
  setCurrFilter,
  autocompleteFocus,
}): React.ReactElement => {
  const [selectedOption, setSelectedOption] = React.useState<Option>();

  React.useEffect(() => {
    if (selectedOption) {
      addFilter(`${currFilter?.key}:"${selectedOption.key}"`);
      setCurrFilter(undefined);
      autocompleteFocus();
    }
  }, [selectedOption]);

  const renderOption = React.useMemo(
    () => () => {
      if (currFilter?.type == 'text')
        return (
          <TextFieldOption
            selectedFilter={currFilter}
            setSelectedOption={setSelectedOption}
            clearFilter={() => setCurrFilter(undefined)}
          />
        );

      if (currFilter?.type == 'select')
        return (
          <SelectOption
            selectedFilter={currFilter}
            setSelectedOption={setSelectedOption}
            clearFilter={() => setCurrFilter(undefined)}
          />
        );
      return <></>;
    },
    [currFilter]
  );

  return (
    <mui.core.Box px={3} pb={3}>
      <mui.core.Typography color="textSecondary" mb={2} variant="body2">
        Filter results by
      </mui.core.Typography>
      {currFilter ? (
        renderOption()
      ) : (
        <SelectFilter filters={filters} setSelectedFilter={setCurrFilter} selectedFilters={selectedFilters} />
      )}
    </mui.core.Box>
  );
};

Filters.defaultProps = {
  autocompleteFocus: () => undefined,
};

export default Filters;
