/* eslint-disable max-len */

import expandWords from './expand-words';
import filterMethods from './filters';
import parseSearchQuery from './parse-search-query';
import ContextSingleton from '../../__singletons__/context-singleton';
import { RESOURCES_TYPES_ENUM, UI_RESOURCE_TYPE_ENUM } from '../../enums';
import isSpPipeline from '../../helpers/is-sp-pipeline';
import { _ } from '../../libs';
import { GeneralAnalysisExpanded } from '../../types/analysis';
import { BacktestExpanded } from '../../types/backtest';
import { BaseStore } from '../../types/base-store';
import { BasketExpanded } from '../../types/basket';
import { Resource, ResourceExpanded } from '../../types/common';
import { DataSourceExpanded } from '../../types/data-source';
import { PipelineExpanded } from '../../types/pipeline';
import { PortfolioExpanded } from '../../types/portfolio';
import { SignalExpanded } from '../../types/signal';
import { SignalSetExpanded } from '../../types/signal-set';
import { StrategyExpanded } from '../../types/strategy';
import { UniverseExpanded } from '../../types/universe';
import { Workspace } from '../../types/workspace';

/**
 * Union type representing all possible expanded resource types that can be returned from search
 */
export type ReturnItem =
  | ResourceExpanded
  | BacktestExpanded
  | SignalExpanded
  | StrategyExpanded
  | PortfolioExpanded
  | SignalSetExpanded
  | UniverseExpanded
  | PipelineExpanded
  | DataSourceExpanded
  | GeneralAnalysisExpanded
  | BasketExpanded;

/**
 * Configuration object defining valid search query keywords
 */
const queryOptions = {
  keywords: [
    'label',
    'type',
    'theme',
    'author',
    'organization',
    'definition',
    'is_archived',
    'is_published',
    'provider',
  ],
};

/**
 * Resources that are considered production resources
 */
const PRODUCTION_RESOURCES = [UI_RESOURCE_TYPE_ENUM.STRATEGY, UI_RESOURCE_TYPE_ENUM.PORTFOLIO];

/**
 * Resources that are considered research resources (all except production ones)
 */
const RESEARCH_RESOURCES = _.without(
  Object.values(UI_RESOURCE_TYPE_ENUM),
  UI_RESOURCE_TYPE_ENUM.STRATEGY,
  UI_RESOURCE_TYPE_ENUM.PORTFOLIO
);

/**
 * Search through resources based on query text and filters
 * @param searchQueryText - The search query string
 * @param resources - Store containing all resources
 * @param context - Current workspace context ('production' or 'research')
 * @param filter - Optional function to further filter results
 * @param includeShared - Whether to include shared resources
 * @param skipWorkspaceCheck - Whether to skip workspace context filtering
 * @param includeRevoked - Whether to include revoked resources
 * @param productionResources - Resources considered as production
 * @param researchResources - Resources considered as research
 * @returns Array of filtered and expanded resources matching search criteria
 */
const search = (
  searchQueryText: string,
  resources: BaseStore['resources'],
  context: Workspace['context'],
  filter: (_v: Resource[]) => Resource[] | null = null,
  includeShared = false,
  skipWorkspaceCheck = false,
  includeRevoked = false,
  productionResources = PRODUCTION_RESOURCES,
  researchResources = RESEARCH_RESOURCES
): ReturnItem[] => {
  const features = ContextSingleton.getInstance().features;
  const params = parseSearchQuery(searchQueryText, queryOptions);
  const { filters, searchTerm } = params;
  let resultsArray: Resource[] = [];

  // Many of the resources we have are signals on the background
  // if type=="signal_based" then we will have signals, alphas
  if (filters?.type == 'signal_based') {
    resultsArray.push(...resources.signals.map((r) => ({ ...r, resourceType: 'signal_based' })));
  }

  // Get everything
  resultsArray.push(
    ...resources.signals
      .filter((s) => s.type == 'SIGNAL')
      .map((r) => ({ ...r, resourceType: RESOURCES_TYPES_ENUM.SIGNAL }))
  );

  if (!features || features.signal_set)
    resultsArray.push(...resources.signal_sets.map((r) => ({ ...r, resourceType: RESOURCES_TYPES_ENUM.SIGNAL_SET })));
  if (!features || (features.backtest && features.alpha_model))
    resultsArray.push(...resources.backtests.map((r) => ({ ...r, resourceType: RESOURCES_TYPES_ENUM.BACKTEST })));
  if (!features || (features.backtest && features.alpha_model && features.backtest_set))
    resultsArray.push(
      ...resources.backtest_sets.map((r) => ({
        ...r,
        resourceType: RESOURCES_TYPES_ENUM.BACKTEST_SET,
      }))
    );
  if (!features || features.basket)
    resultsArray.push(...resources.baskets.map((r) => ({ ...r, resourceType: RESOURCES_TYPES_ENUM.BASKET })));
  if (!features || features.time_series)
    resultsArray.push(
      ...resources.time_series.map((r) => ({
        ...r,
        resourceType: RESOURCES_TYPES_ENUM.TIME_SERIES,
      }))
    );
  if (!features || features.production_mode)
    resultsArray.push(...resources.strategies.map((r) => ({ ...r, resourceType: RESOURCES_TYPES_ENUM.STRATEGY })));
  if (!features || features.production_mode)
    resultsArray.push(...resources.portfolios.map((r) => ({ ...r, resourceType: RESOURCES_TYPES_ENUM.PORTFOLIO })));

  resultsArray.push(...resources.universes.map((r) => ({ ...r, resourceType: RESOURCES_TYPES_ENUM.UNIVERSE })));
  resultsArray.push(...resources.pipelines.map((r) => ({ ...r, resourceType: RESOURCES_TYPES_ENUM.PIPELINE })));
  resultsArray.push(...resources.data_sources.map((r) => ({ ...r, resourceType: RESOURCES_TYPES_ENUM.DATA_SOURCE })));
  resultsArray.push(...resources.risk_models.map((r) => ({ ...r, resourceType: RESOURCES_TYPES_ENUM.RISK_MODEL })));
  resultsArray.push(
    ...resources.general_analyses.map((r) => ({
      ...r,
      resourceType: RESOURCES_TYPES_ENUM.GENERAL_ANALYSIS,
    }))
  );

  // Extra
  resultsArray.push(
    ...resources.pipelines.filter((r) => isSpPipeline(r)).map((r) => ({ ...r, resourceType: 'sp_pipeline' }))
  );
  if (!features || features.alpha_model)
    resultsArray.push(
      ...resources.signals.filter((s) => s.type == 'ALPHA_MODEL').map((r) => ({ ...r, resourceType: 'alpha_model' }))
    );
  // Filter by context

  if (!skipWorkspaceCheck) {
    if (context == 'production') {
      resultsArray = resultsArray.filter(
        (r) => [...productionResources].includes(r.resourceType) || r.is_published === true
      );
    } else {
      resultsArray = resultsArray.filter((r) =>
        [...researchResources, 'sp_pipeline', 'signal_based', 'alpha_model'].includes(r.resourceType)
      );
    }
  }

  if (!includeShared) resultsArray = resultsArray.filter((r) => !r.shared);

  if (!includeRevoked) resultsArray = resultsArray.filter((r) => !r.revoked);

  // Apply Dimension filters
  Object.keys(filters).forEach((filter) => {
    const filterMethod = filterMethods[filter];
    const val = filters[filter];

    // If filter is defined apply it
    if (filterMethods[filter]) {
      if (Array.isArray(val)) {
        resultsArray = filterMethod(resultsArray, val, resources);
      } else resultsArray = filterMethod(resultsArray, [val], resources);
    }
  });

  if (filter) resultsArray = filter(resultsArray);

  let expandedArray = resultsArray.map((resource) => expandWords(resource, resources));
  // if we have a search term
  if (searchTerm) expandedArray = filterMethods.nameHandleAndDef(expandedArray, searchTerm);

  // merge extra
  expandedArray = expandedArray.map((item) => ({
    ...item,
    resourceType: item.resourceType == ('sp_pipeline' as any) ? RESOURCES_TYPES_ENUM.PIPELINE : item.resourceType,
  })) as ReturnItem[];
  expandedArray = expandedArray.map((item) => ({
    ...item,
    resourceType: item.resourceType == ('alpha_model' as any) ? RESOURCES_TYPES_ENUM.SIGNAL : item.resourceType,
  })) as ReturnItem[];

  expandedArray = _.uniqBy(expandedArray, (item) => `${item.id}-${item.resourceType}`);
  expandedArray = _.reverse(_.sortBy(expandedArray, (item) => [item.created_at, item.is_deprecated]));

  return expandedArray;
};

export default search;
