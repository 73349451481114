import { _, moment, ts } from '_core';

import { DraftParams } from './types';

export const validate = (
  params: DraftParams,
  context: ts.types.backtest.BacktestAnaylsisContext | ts.types.basket.BasketAnalysisContext
) => {
  const date = params.details_start_date || params.details_end_date;

  if (_.isEmpty(date)) return ['Snapshot date is a required field.'];

  if (context) {
    if (moment(date).isBefore(moment(context.start_date))) return ['Snapshot date must be inside the backtest dates.'];
    if (moment(date).isAfter(moment(context.end_date))) return ['Snapshot date must be inside the backtest dates.'];
  }

  if (!_.isEmpty(params.component_errors)) return params.component_errors;
  return [];
};
