import { _, mui, React, ui } from '_core';

import { AIAssistantContext } from '../ai-assistant-context';

const UserInput: React.FC<{
  inputValue: string;
  setInputValue: (_v: string) => void;
}> = ({ inputValue, setInputValue }) => {
  const conversationContext = React.useContext(AIAssistantContext);
  const { starting, loadingResponse, hasError, autofocus, sendMessage, visible } = conversationContext;

  const disableSend = hasError || loadingResponse || starting || _.isEmpty(inputValue.trim());

  const handleSendMessage = () => {
    const trimmedInputValue = inputValue.trim();
    if (trimmedInputValue !== '') {
      sendMessage(trimmedInputValue);
      setInputValue('');
    }
  };

  return (
    <mui.core.Box display="flex" width="100%">
      <ui.ResizableTextArea
        key={visible ? 'visible' : 'hidden'}
        label="Type your message"
        variant="standard"
        autoFocus={autofocus}
        fullWidth
        value={inputValue}
        onChange={(v) => setInputValue(v)}
        onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
          if (e.key === 'Enter' && !e.shiftKey && !disableSend) {
            e.preventDefault(); // Prevents the default newline behavior
            handleSendMessage();
          }
        }}
        InputProps={{
          disableUnderline: true,
          endAdornment: (
            <mui.core.InputAdornment position="end">
              {
                <mui.core.Button
                  variant="contained"
                  color="primary"
                  onClick={handleSendMessage}
                  style={{ height: '30px' }}
                  size="small"
                  disabled={disableSend}
                >
                  <mui.icons.Send style={{ fontSize: '1rem' }} />
                </mui.core.Button>
              }
            </mui.core.InputAdornment>
          ),
        }}
      />
    </mui.core.Box>
  );
};

export default UserInput;
