import { ts } from '_core';

import fixedWeights from './fixed-weights';
import lasso from './lasso';
import linearRegression from './linear-regression';
import optimizedModel from './optimized-model';
import robust from './robust';

const typeMap: Record<ts.types.signal.AlphaDefinition['alpha_type'], any> = {
  adaboost: {},
  affinityPropagation: {},
  bagging: {},
  classification: {},
  clustering: {},
  custom: {},
  decisionTrees: {},
  elasticnet: {},
  ensembleMethods: {},
  factorAnalysis: {},
  factorDecomposition: {},
  fixed: fixedWeights,
  forestOfRandomizedTrees: {},
  gaussianMixtureModels: {},
  hierarchicalClustering: {},
  ica: {},
  kmeans: {},
  lasso: lasso,
  lda: {},
  linearRegression: linearRegression,
  logistic: {},
  meanShift: {},
  optimizedModel: optimizedModel,
  orthogonalMatchingPursuit: {},
  pca: {},
  regression: {},
  ridge: {},
  robust: robust,
  spectralClustering: {},
  votingClassifier: {},
  votingRegressor: {},
};

export default typeMap;
