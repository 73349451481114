import { mui, React } from '_core';

import Message from './message';
import MessageLog from './message-log';
import { AIAssistantContext } from '../ai-assistant-context';

const Messages = () => {
  const conversationContext = React.useContext(AIAssistantContext);
  const { conversation, loadingResponse, hasError, conversationStarted } = conversationContext;

  const [displayedUntil, setDisplayUntil] = React.useState(conversation.messages.length - 1);

  const handleMessageFinished = (index: number) => {
    const message = conversation.messages[index];
    if (message.completed || message.role == 'user') {
      setDisplayUntil(index + 1);
    }
  };
  const displayedMessages = conversation.messages.slice(0, displayedUntil + 1);

  return (
    <mui.core.Box
      display="flex"
      alignContent="flex-end"
      justifyContent="flex-start"
      flexDirection="column"
      height="100%"
      width="100%"
      className="hide-scrollbar"
    >
      {displayedMessages.map((message, index) => (
        <mui.core.Box key={index}>
          {message.purpose === 'log' ? (
            <MessageLog message={message} onfinish={() => handleMessageFinished(index)} />
          ) : (
            <Message message={message} animate={conversationStarted} onfinish={() => handleMessageFinished(index)} />
          )}
        </mui.core.Box>
      ))}

      {loadingResponse && !hasError && (
        <mui.core.Box>
          <Message
            loading
            message={{
              content: { text: '' },
              role: 'assistant',
            }}
          />
        </mui.core.Box>
      )}

      {hasError && (
        <mui.core.Box>
          <Message
            error
            message={{
              content: {
                text: 'Unable to connect with Assistant Engine. Please try again later.',
              },
              role: 'assistant',
            }}
          />
        </mui.core.Box>
      )}
    </mui.core.Box>
  );
};

export default Messages;
