import { hooks, mui, React, ts, ui } from '_core';

import { DraftParams } from './types';

type FormProps = {
  params: DraftParams;
  setParams: (_p: DraftParams) => void;

  contextStartDate?: string;
  contextEndDate?: string;
  universeId?: number;
  frequency?: ts.enums.FREQUENCY_ENUM;
};

const Form: React.FC<FormProps> = ({ params, setParams, contextStartDate, contextEndDate, universeId, frequency }) => {
  const uiStyles = hooks.useUiStyles();

  const [startDate, setStarttDate] = React.useState(params.details_start_date || contextStartDate);
  const [endDate, setEndDate] = React.useState(params.details_end_date || contextEndDate);
  const [assets, setAssets] = React.useState(params.assets || ([] as DraftParams['assets']));

  const [componentErrors, setComponentErrors] = React.useState([]);

  React.useEffect(() => {
    const data = {
      details_start_date: startDate,
      details_end_date: endDate,
      assets: assets,
      component_errors: componentErrors,
    };

    setParams({ ...params, ...data });
  }, [startDate, endDate, assets, componentErrors]);

  const rangeValue = React.useMemo(() => {
    return [startDate, endDate] as string[];
  }, [startDate, endDate]);

  return (
    <mui.core.Box sx={uiStyles.widgetEditMode}>
      <mui.core.Box mb={4}>
        <ui.AssetSelector
          value={assets}
          setValue={(val) => {
            setAssets(val as string[]);
          }}
          multiple
        />
      </mui.core.Box>

      <ui.SmartDateRangeSelector
        value={rangeValue}
        onChange={(v) => {
          setStarttDate(v[0]);
          setEndDate(v[1]);
        }}
        minDate={contextStartDate}
        maxDate={contextEndDate}
        universeId={universeId}
        frequency={frequency}
        setExternalError={(e) => setComponentErrors(e ? [e] : [])}
      />
    </mui.core.Box>
  );
};

Form.defaultProps = {
  contextStartDate: null,
  contextEndDate: null,
};

export default Form;
