import { createTheme } from '@mui/material/styles';

import baseConfigTheme from './base-config-theme';
// eslint-disable-next-line max-len
const outherTheme = createTheme({
  palette: {
    primary: {
      light: '#4FB087',
      main: '#429672',
      dark: '#34785B',
    },
    primaryLight: {
      light: '#F1FDF3',
      main: '#ECF8EE',
    },
    dangerLight: {
      dark: '#E9BDC1',
      main: '#F4D3D6',
      light: '#F5EDEE',
    },
    warningLight: {
      main: '#F8F2E1',
      dark: '#ECE1C2',
    },
    successLight: {
      dark: '#D9F1DD',
      main: '#ECF8EE',
      light: '#F5FBF7',
    },
    textLight: {
      main: '#627178',
    },
    secondary: {
      light: '#363A54',
      main: '#202541',
      dark: '#1B1F37',
    },
    danger: {
      light: '#D44E5C',
      main: '#DA6974',
      dark: '#B81F2A',
    },
    warning: {
      light: '#D4B04E',
      main: '#DABC69',
      dark: '#B49642',
    },
    info: {
      light: '#69C3DA',
      main: '#4EB9D4',
      dark: '#429DB4',
    },
    success: {
      light: '#61C471',
      main: '#45BA58',
      dark: '#3B9E4B',
    },
    text: {
      secondary: '#627178',
      disabled: '#7F8D93',
    },
    background: {
      default: '#F4FBF6',
      paper: '#FFF',
    },
    divider: '#E7EBEF',
    gray: {
      dark: '#E7E0DA',
      main: '#E1EFE4',
      light: '#EDF0F3',
    },
    grayLight: {
      main: '#f2f4fb',
    },
    purple: {
      main: '#864ED4',
    },
    orange: {
      main: '#D4894E',
    },
    lime: {
      main: '#95BD40',
    },
    pink: {
      main: '#BD40A1',
    },
  },
  ...(baseConfigTheme as any),
});

export default outherTheme;
