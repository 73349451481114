/* eslint-disable no-unused-vars */
import { ts } from '_core';

export enum INTERACTION_PARAMS {
  SELECTED_TYPE = 'selected_type',
}

export type InteractionParams = {
  [INTERACTION_PARAMS.SELECTED_TYPE]: 'signal' | 'benchmark';
};

export type JobParams = {
  return_horizon: ts.types.returnHorizon.ReturnHorizon;
  benchmark?: Record<string, ts.enums.BENCHMARK_TYPES_ENUM | string | number>;
  analysis_pipelined_signals: {
    signal_id: number;
    pipeline_id?: number;
    sp_pipeline_id?: number;
    signal_label?: string;
  }[];
  format_for_ui: true;
};

export type Params = JobParams & InteractionParams;
export type DraftParams = Partial<Params>;
