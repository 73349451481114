import React from 'react';
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers';

import { _ } from '../libs';
import { moment } from '../libs';

type DateDayRenderProps = {
  date: Date;
  dayProps: PickersDayProps<Date>;
  validDates: string[];
  allValidDailyDates: string[];
};

/**
 * Custom render component for date picker days
 * 
 * @param date - The date to render
 * @param dayProps - Props for the PickersDay component
 * @param validDates - Array of valid date strings
 * @param allValidDailyDates - Array of all valid date strings
 * 
 * @returns The DateDayRender component.
 */

const DateDayRender = ({ date, dayProps, validDates, allValidDailyDates }: DateDayRenderProps) => {
  if (_.isEmpty(validDates) && _.isEmpty(allValidDailyDates)) {
    return (
      <PickersDay
        {...dayProps}
        sx={{
          ...dayProps.sx,
        }}
      />
    );
  }

  const isWeekend = date.getDay() === 0 || date.getDay() === 6;
  const isFuture = date > new Date();

  const isAfterValidDates = date > moment(validDates[validDates.length - 1]).toDate();
  const isBeforeValidDates = date < moment(validDates[0]).toDate();

  const isValid =
    validDates?.includes(moment(date).format('YYYY-MM-DD')) ||
    allValidDailyDates?.includes(moment(date).format('YYYY-MM-DD'));

  let dayStyle = {};
  if (!isBeforeValidDates && !isAfterValidDates) {
    if (!isFuture && isWeekend) {
      dayStyle = { backgroundColor: '#F4D3D6' };
    } else if (!isValid && !isFuture) {
      dayStyle = { backgroundColor: '#fffcc2' };
    }
  }

  return (
    <PickersDay
      {...dayProps}
      sx={{
        ...dayProps.sx,
        ...dayStyle,
      }}
    />
  );
};

export default DateDayRender;
