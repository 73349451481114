import { ts } from '_core';

// prepareDrawdownData
export const transform = (data: ts.types.widgets.LinearData, isBasket: boolean, hasBenchmark: boolean) => {
  const localData = { ...data };
  localData[isBasket ? 'Basket Drawdown' : 'Portfolio Drawdown'] = data['return'];

  if (hasBenchmark) {
    localData[isBasket ? 'Target Drawdown' : 'Benchmark Drawdown'] = data['benchmark_returns'];
  }

  delete localData['return'];
  delete localData['benchmark_returns'];

  return localData;
};
