import { mui, React, ts, ui, useSelector } from '_core';

type ContextFormProps = {
  params: Record<string, any>;
  setParams: (_p: Record<string, any>) => void;
  setExternalContext: (_c: ts.types.signalSet.SignalSetAnalysisContext) => void;
  originalContext: ts.types.signalSet.SignalSetAnalysisContext | ts.types.analysis.GeneralAnalysisContext;
  mode: ts.enums.CHART_MODE_ENUM;
  isGeneralAnalysis: boolean;
};

const ContextForm: React.FC<ContextFormProps> = ({
  params,
  setParams,
  setExternalContext,
  mode,
  isGeneralAnalysis,
  originalContext,
}) => {
  const signalSets = useSelector((state) => state.resources.signal_sets);

  const [signalSetId, setSignalSetId] = React.useState(params.signal_set_id ?? null);

  const localContext = React.useMemo(() => {
    const signalSet = signalSets.find((s) => s.id == signalSetId);
    if (signalSet) {
      const context = {
        id: signalSet.id,
        signal_set_id: signalSet.id,
        signals: signalSet.definition.signals,
        universe_id: signalSet?.ui_metadata?.visual_def?.universe_id,
        risk_model_id: signalSet?.ui_metadata?.visual_def?.risk_model_id,
        frequency: signalSet?.ui_metadata?.visual_def?.frequency,
        ...originalContext,
      } as ts.types.signalSet.SignalSetAnalysisContext;

      setExternalContext(context);
      return context;
    }

    return null;
  }, [signalSetId]);

  React.useEffect(() => {
    if (mode == 'edit') {
      setParams({
        ...params,
        ...localContext,
      });
    }
  }, [localContext]);

  if (mode == 'chart' || !isGeneralAnalysis) return <></>;
  return (
    <mui.core.Box mt={4}>
      <ui.ResourceAutocomplete<ts.types.signalSet.SignalSetExpanded>
        value={signalSetId}
        setValue={(id) => setSignalSetId(id)}
        type={ts.enums.RESOURCES_TYPES_ENUM.SIGNAL_SET}
        autocompleteProps={{
          size: 'small',
        }}
        inputProps={{
          label: 'Select Signal Set',
          variant: 'outlined',
          size: 'small',
          required: true,
        }}
      />
    </mui.core.Box>
  );
};

export default ContextForm;
