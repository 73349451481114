import { hooks, mui, React, ts } from '_core';

import Chart from './chart';
import { Params } from './types';

type UniverseCoverageByRbicsProps = {
  id: string;
  readOnly: boolean;
  loadWidgetData: (_payload: ts.types.widgets.WidgetGetDataParams) => Promise<ts.types.widgets.WidgetGetDataResponse>;
  widgetPaths: Record<string, string>;
  fullScreen: boolean;
  params: Params;
  setParams: (_p: Params) => void;
  title: string;
  subtitle?: string;
  setExternalEmbed: (_json: ts.types.analysis.EmbedChartData) => void;
};

const UniverseCoverageByRbics: React.FC<UniverseCoverageByRbicsProps> = ({
  id,
  readOnly,
  loadWidgetData,
  widgetPaths,
  fullScreen,
  params,
  setParams,
  title,
  subtitle,
  setExternalEmbed,
}) => {
  const uiStyles = hooks.useUiStyles();
  const theme = mui.styles.useTheme() as mui.core.Theme;

  const [value, setValue] = React.useState(params.tabValue || 0);

  const handleChange = (newValue: number) => {
    setValue(newValue);
    setParams({ ...params, tabValue: newValue });
  };

  return (
    <>
      <mui.core.Box style={{ borderBottom: `1px solid ${theme.palette.gray.light}` }}>
        <mui.core.Tabs
          value={value}
          indicatorColor="primary"
          textColor="primary"
          onChange={(_e, val: number) => handleChange(val)}
          aria-label="Count Chart/Percent Chart Tabs"
        >
          <mui.core.Tab label="Count" />
          <mui.core.Tab label="Percent" />
        </mui.core.Tabs>
      </mui.core.Box>
      <mui.core.Box sx={uiStyles.widgetWithTabsContent}>
        <Chart
          id={id}
          readOnly={readOnly}
          loadWidgetData={loadWidgetData}
          file={widgetPaths[params.by]}
          fullScreen={fullScreen}
          visualization={value == 0 ? 'count' : 'percent'}
          params={params}
          setParams={setParams}
          title={title}
          subtitle={subtitle}
          setExternalEmbed={setExternalEmbed}
        />
      </mui.core.Box>
    </>
  );
};

export default UniverseCoverageByRbics;
