import { ts } from '_core';

import * as apiProcedures from './api-procedures';
import * as definition from './definition';
import validateDefinition from './risk-model-validation';

const getCurrentTab = (path: string) => {
  let currentTab = 'definition';
  if (path.includes('description')) currentTab = 'description';
  if (path.includes(ts.enums.RISK_MODEL_ANALYSIS_TYPE_ENUM.ANALYSIS))
    currentTab = ts.enums.RISK_MODEL_ANALYSIS_TYPE_ENUM.ANALYSIS;
  if (path.includes(ts.enums.RISK_MODEL_ANALYSIS_TYPE_ENUM.PREVIEW))
    currentTab = ts.enums.RISK_MODEL_ANALYSIS_TYPE_ENUM.PREVIEW;
  if (path.includes(ts.enums.RISK_MODEL_ANALYSIS_TYPE_ENUM.REPORT))
    currentTab = ts.enums.RISK_MODEL_ANALYSIS_TYPE_ENUM.REPORT;
  if (path.includes(ts.enums.RISK_MODEL_ANALYSIS_TYPE_ENUM.CATEGORY))
    currentTab = ts.enums.RISK_MODEL_ANALYSIS_TYPE_ENUM.CATEGORY;

  return currentTab as 'definition' | ts.enums.RISK_MODEL_ANALYSIS_TYPE_ENUM;
};

export { apiProcedures };
export { validateDefinition };
export { definition };
export { getCurrentTab };
