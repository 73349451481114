import { React, ts } from '_core';

import Chart from './chart';
import { DraftParams, Params } from './types';

type UniversePreviewLinkedAssetsProps = {
  id: string;
  mode: ts.enums.CHART_MODE_ENUM;
  loadWidgetData: (_payload: ts.types.widgets.WidgetGetDataParams) => Promise<ts.types.widgets.WidgetGetDataResponse>;
  widgetPaths: Record<'universe_linked_assets', string>;
  params: Params | DraftParams;
  setParams: (_p: DraftParams) => void;
  goFullScreen: (_b: string) => void;
  fullScreen: boolean;
  context: ts.types.universe.UniversePreviewContext;
  queryParams?: { initial?: string };
};

const UniversePreviewLinkedAssets: React.FC<UniversePreviewLinkedAssetsProps> = ({
  id,
  loadWidgetData,
  widgetPaths,
  context,
  fullScreen,
  goFullScreen,
  queryParams,
  params,
  setParams,
}) => {
  return (
    <Chart
      id={id}
      loadWidgetData={loadWidgetData}
      linked={widgetPaths['universe_linked_assets']}
      fullScreen={fullScreen}
      context={context}
      queryParams={queryParams}
      goFullScreen={goFullScreen}
      params={params as Params}
      setParams={setParams}
    />
  );
};

export default UniversePreviewLinkedAssets;
