/*
    SIMPLE PLURALIZE/SINGULARIZE METHOD BASED ON pluralize.js
    https://github.com/plurals/pluralize/blob/master/pluralize.js
*/

/* eslint-disable */

/**
 * Rules for converting singular words to plural form
 * Each rule is an array containing [regex pattern, replacement]
 */
const pluralizeRules: any[][] = [
  [/s?$/i, 's'],
  [/[^\u0000-\u007F]$/i, '$0'],
  [/([^aeiou]ese)$/i, '$1'],
  [/(ax|test)is$/i, '$1es'],
  [/(alias|[^aou]us|t[lm]as|gas|ris)$/i, '$1es'],
  [/(e[mn]u)s?$/i, '$1s'],
  [/([^l]ias|[aeiou]las|[ejzr]as|[iu]am)$/i, '$1'],
  [/(alumn|syllab|vir|radi|nucle|fung|cact|stimul|termin|bacill|foc|uter|loc|strat)(?:us|i)$/i, '$1i'],
  [/(alumn|alg|vertebr)(?:a|ae)$/i, '$1ae'],
  [/(seraph|cherub)(?:im)?$/i, '$1im'],
  [/(her|at|gr)o$/i, '$1oes'],
  [
    /(agend|addend|millenni|dat|extrem|bacteri|desiderat|strat|candelabr|errat|ov|symposi|curricul|automat|quor)(?:a|um)$/i,
    '$1a',
  ],
  [/(apheli|hyperbat|periheli|asyndet|noumen|phenomen|criteri|organ|prolegomen|hedr|automat)(?:a|on)$/i, '$1a'],
  [/sis$/i, 'ses'],
  [/(?:(kni|wi|li)fe|(ar|l|ea|eo|oa|hoo)f)$/i, '$1$2ves'],
  [/([^aeiouy]|qu)y$/i, '$1ies'],
  [/([^ch][ieo][ln])ey$/i, '$1ies'],
  [/(x|ch|ss|sh|zz)$/i, '$1es'],
  [/(matr|cod|mur|sil|vert|ind|append)(?:ix|ex)$/i, '$1ices'],
  [/\b((?:tit)?m|l)(?:ice|ouse)$/i, '$1ice'],
  [/(pe)(?:rson|ople)$/i, '$1ople'],
  [/(child)(?:ren)?$/i, '$1ren'],
  [/eaux$/i, '$0'],
  [/man$/i, 'men'],
];

/**
 * Rules for converting plural words to singular form
 * Each rule is an array containing [regex pattern, replacement]
 */
const singularizeRules: any[][] = [
  [/s$/i, ''],
  [/(ss)$/i, '$1'],
  [/(wi|kni|(?:after|half|high|low|mid|non|night|[^\w]|^)li)ves$/i, '$1fe'],
  [/(ar|(?:wo|[ae])l|[eo][ao])ves$/i, '$1f'],
  [/ies$/i, 'y'],
  [/(dg|ss|ois|lk|ok|wn|mb|th|ch|ec|oal|is|ck|ix|sser|ts|wb)ies$/i, '$1ie'],
  [
    /\b(l|(?:neck|cross|hog|aun)?t|coll|faer|food|gen|goon|group|hipp|junk|vegg|(?:pork)?p|charl|calor|cut)ies$/i,
    '$1ie',
  ],
  [/\b(mon|smil)ies$/i, '$1ey'],
  [/\b((?:tit)?m|l)ice$/i, '$1ouse'],
  [/(seraph|cherub)im$/i, '$1'],
  [/(x|ch|ss|sh|zz|tto|go|cho|alias|[^aou]us|t[lm]as|gas|(?:her|at|gr)o|[aeiou]ris)(?:es)?$/i, '$1'],
  [/(analy|diagno|parenthe|progno|synop|the|empha|cri|ne)(?:sis|ses)$/i, '$1sis'],
  [/(movie|twelve|abuse|e[mn]u)s$/i, '$1'],
  [/(test)(?:is|es)$/i, '$1is'],
  [/(alumn|syllab|vir|radi|nucle|fung|cact|stimul|termin|bacill|foc|uter|loc|strat)(?:us|i)$/i, '$1us'],
  [/(agend|addend|millenni|dat|extrem|bacteri|desiderat|strat|candelabr|errat|ov|symposi|curricul|quor)a$/i, '$1um'],
  [/(apheli|hyperbat|periheli|asyndet|noumen|phenomen|criteri|organ|prolegomen|hedr|automat)a$/i, '$1on'],
  [/(alumn|alg|vertebr)ae$/i, '$1a'],
  [/(cod|mur|sil|vert|ind)ices$/i, '$1ex'],
  [/(matr|append)ices$/i, '$1ix'],
  [/(pe)(rson|ople)$/i, '$1rson'],
  [/(child)ren$/i, '$1'],
  [/(eau)x?$/i, '$1'],
  [/men$/i, 'man'],
];

/**
 * List of resources that should not be transformed
 */
const alreadyFormattedResources: string[] = ['time_series'];

/**
 * Interpolates numbered placeholders in a string with provided arguments
 * @param str - String containing numbered placeholders ($1, $2, etc)
 * @param args - Arguments to replace the placeholders with
 * @returns String with placeholders replaced by argument values
 */
const interpolate = (str: string, args: IArguments) => str.replace(/\$(\d{1,2})/g, (match, index) => args[index] || '');

/**
 * Applies a pluralization/singularization rule to a word
 * @param word - Word to transform
 * @param rule - Rule to apply [regex pattern, replacement]
 * @returns Transformed word
 */
const replaceWord = (word: string, rule: string[]) =>
  word.replace(rule[0], function (match, index) {
    const result = interpolate(rule[1], arguments);
    return result;
  });

/**
 * Converts a singular word to its plural form
 * @param word - Word to pluralize
 * @returns Plural form of the word
 */
const plural = (word: string) => {
  let res = word;
  pluralizeRules.forEach((rule) => {
    if (rule[0].test(word.toLowerCase())) {
      res = replaceWord(word, rule);
    }
  });
  return res;
};

/**
 * Converts a plural word to its singular form
 * @param word - Word to singularize
 * @returns Singular form of the word
 */
const singular = (word: string) => {
  let res = word;

  if (alreadyFormattedResources.includes(res)) return res;

  singularizeRules.forEach((rule) => {
    if (rule[0].test(word.toLowerCase())) {
      res = replaceWord(word, rule);
    }
  });
  return res;
};

export { plural };
export { singular };
