import { am5, am5xy } from '@local/finsera-core/src/libs';
import * as types from '@local/finsera-core/src/types';

export const createAxes = ({
  root,
  chart,
  data,
  category,
  rotateXLabels,
}: {
  root: am5.Root;
  chart: am5xy.XYChart;
  data: types.widgets.TableData;
  category: string;
  rotateXLabels: boolean;
}) => {
  const xAxis = chart.xAxes.push(
    am5xy.CategoryAxis.new(root, {
      paddingTop: 10,
      categoryField: category,
      renderer: am5xy.AxisRendererX.new(root, {
        minGridDistance: 30,
      }),
    })
  );

  xAxis.data.setAll(data);

  xAxis.get('renderer').labels.template.setAll({
    fontSize: 10.5,
    textAlign: 'center',
    maxWidth: 80,
    oversizedBehavior: 'wrap',
  });

  if (rotateXLabels) {
    xAxis.get('renderer').labels.template.setAll({
      fontSize: 11,
      rotation: 270,
      centerY: am5.p50,
      centerX: 0,
    });
  }

  const yAxis = chart.yAxes.push(
    am5xy.ValueAxis.new(root, {
      renderer: am5xy.AxisRendererY.new(root, {
        minGridDistance: 30,
      }),
    })
  );

  yAxis.get('renderer').labels.template.setAll({
    fontSize: 11,
  });

  return { xAxis, yAxis };
};
