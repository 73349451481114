import { _, mui, React, ts } from '_core';

import { Params } from './types';
import ChartWrapper from '../../common/chart-wrapper';
import createErrorChart from '../../common/charts/create-error-chart';
import createNestedBarChart from '../../common/charts/create-nested-bar-chart';

type ChartProps = {
  id: string;
  readOnly: boolean;
  widgetKey: string;
  loadWidgetData: (_payload: ts.types.widgets.WidgetGetDataParams) => Promise<ts.types.widgets.WidgetGetDataResponse>;
  fileEvent: string;
  fullScreen: boolean;
  params: Params;
  title: string;
};

const Chart: React.FC<ChartProps> = ({ id, readOnly, widgetKey, loadWidgetData, fileEvent, fullScreen, title }) => {
  const [data, setData] = React.useState<ts.types.widgets.TableData>(null);
  const [error, setError] = React.useState<ts.types.common.ApiError>(null);
  const [showLegend, setShowLegend] = React.useState(false);
  const [selectedTab, setSelectedTab] = React.useState<'error' | 'tstat'>('error');

  const loadData = async (selectedTab: 'error' | 'tstat') => {
    try {
      const response = await loadWidgetData({
        data: {
          file: fileEvent,
          rename: selectedTab == 'error' ? { avg_cum: 'average_cumulative_return', std_cum: 'error' } : {},
          columns: selectedTab == 'tstat' ? ['days_from_event', 'tstat'] : undefined,
        },
      });
      if (response) setData(response.data);
    } catch (err) {
      setError(err as ts.types.common.ApiError);
    }
  };

  React.useEffect(() => {
    if (fileEvent) loadData(selectedTab);
    return () => setData(null);
  }, [fileEvent, selectedTab]);

  React.useEffect(() => {
    if (!_.isNil(data)) {
      if (selectedTab == 'tstat')
        createNestedBarChart({
          id,
          data,
          fullScreen,
          setShowLegend,
          hideLegend: true,
          xAxisLabel: 'Days',
          yAxisLabel: 'T-stat',
          category: 'days_from_event',
          exportTitle: title,
        });
      if (selectedTab == 'error')
        createErrorChart({
          id,
          data,
          fullScreen,
          xAxis: 'days_from_event',
          yAxis: 'average_cumulative_return',
          yAxisLabel: 'Days',
          xAxisLabel: 'Mean Cumulative Returns (%)',
          setShowLegend,
          exportTitle: title,
        });
    }
  }, [data]);

  const getTabs = () => (
    <mui.core.Tabs
      indicatorColor="primary"
      textColor="primary"
      value={selectedTab}
      onChange={(_e, newTab) => setSelectedTab(newTab)}
    >
      <mui.core.Tab label="Cumulative Returns with Error" value="error" />
      <mui.core.Tab label="T-stat" value="tstat" />
    </mui.core.Tabs>
  );

  return (
    <ChartWrapper
      id={id}
      readOnly={readOnly}
      widgetKey={widgetKey}
      data={data}
      showLegend={showLegend}
      warning={data?.length === 0 ? ['Empty File'] : []}
      error={error}
      tabs={getTabs()}
      fullScreen={fullScreen}
    />
  );
};

export default Chart;
