import { ts } from '_core';

import { getName, validate } from './helpers';
import { DraftParams } from './types';

export default [
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.SIGNAL_REGIME,
    title: 'Time Series Regime Analysis',
    helpLabel: 'ji364fql',
    form: true,
    defaultParams: {
      quantiles: 5,
      histories: ['1 Year', '3 Years', '5 Years', 'Full'],
      discrete: false,
      show_unknown: false,
      format_for_ui: true,
    } as DraftParams,
    neededFiles: ['signal_regime_analysis'],
    tags: [
      ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.SIGNAL,
      ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.ALPHA_MODEL,
      ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.COMMON_ALPHA_MODEL,
    ],

    getName,
    validate,
  },
] satisfies ts.types.analysis.WidgetConfig[];
