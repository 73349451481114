/* eslint-disable no-unused-vars */

import * as REPORT_ENUMS from './report';

export enum NUMBER_FORMATTERS_ENUM {
  HUNDRED = 'h',
  THOUSAND = 't',
  MILLION = 'm',
  BILLION = 'b',
}

export enum FINSERA_ADMIN_ENUM {
  FEATURES = 'features',
  PERFORMANCE = 'performance',
  DATASOURCES = 'datasources',
  USERS = 'users',
  APIS = 'apis',
  COSTS = 'costs',
  GLOBAL_DATASOURCES = 'datasources',
  LOGS = 'logs',
}

export enum FEATURE_INFO_TYPE_ENUM {
  BOOLEAN = 'boolean',
  DATE = 'date',
  MULTIPLE_SELECT = 'multiple-select',
  STRING = 'string',
  INTEGER = 'integer',
}

export enum BREAKPOINTS_ENUM {
  XS = 'xs',
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
  XL = 'xl',
}

export enum ALERT_SEVERITY_ENUM {
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info',
  SUCCESS = 'success',
}

export enum RESOURCES_TYPES_ENUM {
  BACKTEST = 'backtest',
  BACKTEST_SET = 'backtest_set',
  BASKET = 'basket',
  DATA_SOURCE = 'data_source',
  GENERAL_ANALYSIS = 'general_analysis',
  PIPELINE = 'pipeline',
  PORTFOLIO = 'portfolio',
  RISK_MODEL = 'risk_model',
  SIGNAL = 'signal',
  SIGNAL_SET = 'signal_set',
  STRATEGY = 'strategy',
  TIME_SERIES = 'time_series',
  UNIVERSE = 'universe',
  WORKSPACE = 'workspace',
}

// Just for the ui notion, not the actual one
export enum UI_RESOURCE_TYPE_ENUM {
  ALPHA_MODEL = 'alpha_model',
  BACKTEST = 'backtest',
  BACKTEST_SET = 'backtest_set',
  BASKET = 'basket',
  DATA_SOURCE = 'data_source',
  GENERAL_ANALYSIS = 'general_analysis',
  PIPELINE = 'pipeline',
  PORTFOLIO = 'portfolio',
  RISK_MODEL = 'risk_model',
  SIGNAL = 'signal',
  SIGNAL_SET = 'signal_set',
  STRATEGY = 'strategy',
  TIME_SERIES = 'time_series',
  UNIVERSE = 'universe',
  WORKSPACE = 'workspace',
}

export enum TABLES_ENUM {
  BACKTESTS = 'backtests',
  BACKTEST_SETS = 'backtest_sets',
  BASKETS = 'baskets',
  DATA_SOURCES = 'data_sources',
  GENERAL_ANALYSES = 'general_analyses',
  PIPELINES = 'pipelines',
  PORTFOLIOS = 'portfolios',
  RISK_MODELS = 'risk_models',
  SIGNALS = 'signals',
  SIGNAL_SETS = 'signal_sets',
  STRATEGIES = 'strategies',
  TIME_SERIES = 'time_series',
  UNIVERSES = 'universes',
  WORKSPACES = 'workspaces',
}

export const MAP_RESOURCE_WITH_TABLE = {
  [RESOURCES_TYPES_ENUM.BACKTEST]: TABLES_ENUM.BACKTESTS,
  [RESOURCES_TYPES_ENUM.BACKTEST_SET]: TABLES_ENUM.BACKTEST_SETS,
  [RESOURCES_TYPES_ENUM.BASKET]: TABLES_ENUM.BASKETS,
  [RESOURCES_TYPES_ENUM.DATA_SOURCE]: TABLES_ENUM.DATA_SOURCES,
  [RESOURCES_TYPES_ENUM.GENERAL_ANALYSIS]: TABLES_ENUM.GENERAL_ANALYSES,
  [RESOURCES_TYPES_ENUM.PIPELINE]: TABLES_ENUM.PIPELINES,
  [RESOURCES_TYPES_ENUM.PORTFOLIO]: TABLES_ENUM.PORTFOLIOS,
  [RESOURCES_TYPES_ENUM.RISK_MODEL]: TABLES_ENUM.RISK_MODELS,
  [RESOURCES_TYPES_ENUM.SIGNAL]: TABLES_ENUM.SIGNALS,
  [RESOURCES_TYPES_ENUM.SIGNAL_SET]: TABLES_ENUM.SIGNAL_SETS,
  [RESOURCES_TYPES_ENUM.STRATEGY]: TABLES_ENUM.STRATEGIES,
  [RESOURCES_TYPES_ENUM.TIME_SERIES]: TABLES_ENUM.TIME_SERIES,
  [RESOURCES_TYPES_ENUM.UNIVERSE]: TABLES_ENUM.UNIVERSES,
  [RESOURCES_TYPES_ENUM.WORKSPACE]: TABLES_ENUM.WORKSPACES,
};

export const MAP_TABLE_WITH_RESOURCE = Object.fromEntries(
  Object.entries(MAP_RESOURCE_WITH_TABLE).map(([key, value]) => [value as TABLES_ENUM, key as RESOURCES_TYPES_ENUM])
);

export enum PREFERENCES_SECTION_ENUM {
  FIXED_COST = 'fixed_cost',
  OTHER = 'other',
  REPORT_LAYOUT = 'report_layout',
  TOUR = 'tour',
}

export enum PREFERENCES_TABS_ENUM {
  BACKTEST_SETS = 'backtest_sets',
  BACKTESTS = 'backtests',
  BASKETS = 'basket',
  PORTFOLIOS = 'portfolios',
  RISK_MODELS = 'risk_models',
  SIGNALS = 'signals',
  SIGNAL_SETS = 'signal_sets',
  TIME_SERIES = 'time_series',
  UNIVERSES = 'universes',
  FIXED_COSTS = 'fixed_costs',
  REPORT_LAYOUTS = 'report_layouts',
  OTHER = 'other',
}

export enum PREFERENCES_INNER_TABS_ENUM {
  // BACKTEST_SETS
  BACKTEST_SET_SUMMARY_COLS = 'backtest_set_summary_columns',
  // BACKTEST
  BACKTEST_CONSTRAINTS_COLS = 'backtest_constraints_columns',
  BACKTEST_DATE_COMPARISON = 'backtest_date_comparison',
  BACKTEST_PORTFOLIO_RISK_COLS = 'backtest_portfolio_risk_columns',
  BACKTEST_PREVIEW_COLS = 'backtest_preview_columns',
  BACKTEST_PREVIEW_ALPHA_EXPOSURES_COLS = 'backtest_preview_alpha_exposures_columns',
  BACKTEST_SUMMARY_AND_DETAILS_COLS = 'backtest_summary_and_details_columns',
  BACKTEST_PERFORMANCE_ATTRIBUTION_CONTRIBUTOR_COLS = 'backtest_performance_attribution_contributor_columns',
  // BASKET
  BASKET_CONSTRAINTS_COLS = 'basket_constraints_columns',
  BASKET_DATE_COMPARISON = 'basket_date_comparison',
  BASKET_PORTFOLIO_RISK_COLS = 'basket_portfolio_risk_columns',
  BASKET_PREVIEW_COLS = 'basket_preview_columns',
  BASKET_SUMMARY_AND_DETAILS_COLS = 'basket_summary_and_details_columns',
  BASKET_PERFORMANCE_ATTRIBUTION_CONTRIBUTOR_COLS = 'basket_performance_attribution_contributor_columns',
  // BASKET COMPARE
  BASKET_COMPARE_SUMMARY_STATS_COLS = 'basket_compare_summary_stats_columns',
  TIME_SERIES_COMPARE_SUMMARY_STATS_COLS = 'time_series_compare_summary_stats_columns',
  // PORTFOLIOS
  PORTFOLIO_CONSTRAINTS_COLS = 'portfolio_constraints_columns',
  PORTFOLIOS_HOLDINGS_COLS = 'portfolios_initial_holdings_columns',
  PORTFOLIO_TRADE_LIST_COLS = 'portfolio_trade_list_columns',
  PORTFOLIO_TRADE_LIST_SUMMARY_COLS = 'portfolio_trade_list_summary_columns',
  PORTFOLIO_TRADE_LIST_BUCKET_EXPOSURE_COLS = 'portfolio_trade_list_bucket_exposure_columns',
  PORTFOLIO_TRADE_LIST_FACTOR_EXPOSURE_COLS = 'portfolio_trade_list_factor_exposure_columns',
  PORTFOLIO_TRADE_LIST_HOLDINGS_COLS = 'portfolio_trade_list_holdings_columns',
  PORTFOLIO_SUMMARY_AND_DETAILS_COLS = 'portfolio_summary_and_details_columns',
  // RISK MODELS
  RISK_MODEL_PREVIEW_COLS = 'risk_model_preview_columns',
  RISK_MODEL_ASSET_PERTINENCE_PREVIEW_COLS = 'risk_model_asset_pertinence_preview_columns',
  // SIGNALS
  SIGNAL_DATASET_FORM = 'signal_dataset_form',
  SIGNAL_PREVIEW_COLS = 'signal_preview_columns',
  SIGNAL_DATE_COMPARISON_COLS = 'signal_date_comparison',
  // SIGNAL SETS
  SIGNAL_SET_SUMMARY_STATS_COLS = 'signal_set_summary_stats_columns',
  // TIME SERIES
  TIME_SERIES_PREVIEW_COLS = 'time_series_preview_columns',
  // UNIVERSES
  UNI_ASSET_PERTINENCE_COLS = 'universe_asset_pertinence_columns',
  UNI_DATE_COMPARISON_COLS = 'universe_dates_comparison_columns',
  UNI_LINKED_ASSETS_COLS = 'universe_linked_assets_columns',
  UNI_SNAPSHOT_COLS = 'universe_snapshot_columns',

  // REPORT TEMPLATES
  UNI_TEMPLATE = 'universe',

  SIGNAL_TEMPLATE = 'signal',
  SIGNAL_PREVIEW_TEMPLATE = 'signal_preview',
  SIGNAL_ATTRIBUTION_TEMPLATE = 'signal-portan',

  ALPHA_MODEL_TEMPLATE = 'alpha_model',

  BACKTEST_TEMPLATE = 'backtest',
  BACKTEST_PREVIEW_TEMPLATE = 'backtest-detailed-view',
  BACKTEST_PORTFOLIO_RISK_TEMPLATE = 'backtest-portfolio-risk',
  BACKTEST_ATTRIBUTION_TEMPLATE = 'backtest-portan',

  TIME_SERIES_TEMPLATE = 'time_series',

  BASKET_TEMPLATE = 'basket',
  BASKET_PREVIEW_TEMPLATE = 'basket-detailed-view',
  BASKET_ATTRIBUTION_TEMPLATE = 'basket-attribution',
  BASKET_COMPARE_TEMPLATE = 'basket-compare',
  BASKET_PORTFOLIO_RISK_TEMPLATE = 'basket-portfolio-risk',

  BACKTEST_SET_TEMPLATE = 'backtest_set',

  SIGNAL_SET_TEMPLATE = 'signal_set',
  SIGNAL_SET_REPORT_TEMPLATE = 'signal_set_report',

  RISK_MODEL_TEMPLATE = 'risk_model',

  REBALANCE_PORTFOLIO_RISK_TEMPLATE = 'rebalance-portfolio-risk',
  REBALANCE_PORTFOLIO_TRADELIST_TEMPLATE = 'rebalance_portfolio_tradelist',
  REBALANCE_PORTFOLIO_HOLDINGS_TEMPLATE = 'rebalance_portfolio_holdings',
}

export enum PREFERENCES_IN_USER_OBJ {
  GENERATE_ON_OUTDATED = 'generateOnOutdated',
  HELP_ICON_EDIT_MODE = 'helpIconEditMode',
  SIGNAL_DATASET_FORM = 'signalDatasetsForm',
  HIDE_ARCHIVED_RESOURCES_WARNING = 'hideArchivedResourcesWarning',
  TOUR = 'tour',
  LAYOUT = 'layout',
}

export enum PREFERENCES_KEY_ENUM {
  BACKTEST_PORTFOLIO_RISK_COLS = 'backtestPortfolioRiskColumns',
  BACKTEST_CONSTRAINTS_COLS = 'backtestConstraintsColumns',
  BACKTEST_PREVIEW_COLS = 'backtestPreviewColumns',
  BACKTEST_PREVIEW_ALPHA_EXPOSURES_COLS = 'backtestPreviewAlphaExposuresColumns',
  BACKTEST_SET_SUMMARY_COLS = 'backtestSetSummaryColumns',
  BACKTEST_TABULAR_COLS = 'backtestTabularOutputColumns',
  BACKTEST_PERFORMANCE_ATTRIBUTION_CONTRIBUTOR_COLUMNS = 'backtestPerformanceAttributionContributorColumns',
  BASKET_DATE_COMPARISON_COLS = 'basketDateComparisonColumns',
  BACKTEST_DATE_COMPARISON_COLS = 'backtestDateComparisonColumns',
  BASKET_PORTFOLIO_RISK_COLS = 'basketPortfolioRiskColumns',
  BASKET_CONSTRAINTS_COLS = 'basketConstraintsColumns',
  BASKET_PREVIEW_COLS = 'basketPreviewColumns',
  BASKET_TABULAR_COLS = 'basketTabularOutputColumns',
  BASKET_PERFORMANCE_ATTRIBUTION_CONTRIBUTOR_COLUMNS = 'basketPerformanceAttributionContributorColumns',
  PORTFOLIO_TABULAR_COLS = 'portfolioTabularOutputColumns',
  PORTFOLIO_RISK_COLS = 'portfolioRiskColumns',
  PORTFOLIO_TRADE_LIST_COLS = 'portfolioTradeListColumns',
  PORTFOLIO_TRADE_LIST_SUMMARY_COLS = 'portfolioTradeListSummaryColumns',
  PORTFOLIO_TRADE_LIST_HOLDINGS_COLS = 'portfolioTradeListHoldingsColumns',
  PORTFOLIO_TRADE_LIST_BUCKET_EXPOSURE_COLS = 'portfolioTradeListBucketExposureColumns',
  PORTFOLIO_TRADE_LIST_FACTOR_EXPOSURE_COLS = 'portfolioTradeListFactorExposureColumns',
  PORTFOLIO_CONSTRAINTS_COLS = 'portfolioConstraintsColumns',
  PORTFOLIOS_HOLDINGS_COLS = 'portfoliosInitialHoldingsColumns',
  RISK_MODEL_SNAPSHOT_COLS = 'riskModelSnapshotColumns',
  RISK_MODEL_ASSET_PERTINENCE_COLS = 'riskModelAssetPertinenceColumns',
  SIGNAL_PREVIEW_COLS = 'signalPreviewColumns',
  SIGNAL_DATE_COMPARISON_COLS = 'signalDateComparison',
  SIGNAL_DATESET_FORM = 'signalDatasetForm',
  TIME_SERIES_PREVIEW_COLS = 'timeSeriesPreviewColumns',
  UNIVERSE_COMPARISON_COLS = 'universeComparisonColumns',
  UNIVERSE_LINKED_ASSETS_COLS = 'universeLinkedAssetsColumns',
  UNIVERSE_PERTINENC_COLS = 'universePertinenceColumns',
  UNIVERSE_SNAPSHOT_COLS = 'universeSnapshotColumns',
  SIGNAL_SET_SUMMARY_STATS_COLS = 'signalSetSummaryStatsColumns',
  BASKET_COMPARE_SUMMARY_STATS_COLS = 'basketCompareSummaryStatsColumns',
  TIME_SERIES_COMPARE_SUMMARY_STATS_COLS = 'timeSeriesCompareSummaryStatsColumns',
}

export enum SEPARATORS_ENUM {
  TAB = '\t',
  COMMA = ',',
  SEMICOLON = ';',
}

export enum BENCHMARK_TYPES_ENUM {
  INDEX = 'INDEX',
  UNIVERSE = 'UNIVERSE',
  ANNUALIZED_RETURNS_SIGNAL = 'ANNUALIZED_RETURNS_SIGNAL',
}

export enum BUCKET_TYPES_ENUM {
  RISK_MODEL = 'RISK_MODEL',
  RISK_MODEL_FACTORS = 'STYLE_FACTORS_QUANTILES',
  LONG_SHORT = 'LONG_SHORT',
  QUANTILES = 'QUANTILES',
  INDUSTRY = 'INDUSTRY',
  ASSET_MASTER = 'ASSET_MASTER',
  SIGNAL = 'SIGNAL',
  RISK_ADJUSTED_LAGS = 'riskAdjustedLags',
  FIXED_FLOAT = 'fixedFloat',
  ANY = 'any',
}

export enum BUCKET_INDUSTRY_VALUE {
  RBICS_L1 = 'rbics_l1_name',
  RBICS_L2 = 'rbics_l2_name',
  RBICS_L3 = 'rbics_l3_name',
  RBICS_L4 = 'rbics_l4_name',
}

export enum EXPLANATORY_VARIABLE_TYPES_ENUM {
  ALPHA_SIGNALS = 'ALPHA_SIGNALS',
  RISK_FACTORS = 'RISK_FACTORS',
}

export enum FREQUENCY_ENUM {
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
  QUARTERLY = 'QUARTERLY',
  SEMIANNUAL = 'SEMIANNUAL',
  ANNUAL = 'ANNUAL',
}

export enum HISTORY_ENUM {
  ONE = '1 Year',
  THREE = '3 Years',
  FIVE = '5 Years',
  YTD = 'Year to Date',
  FULL = 'Full',
}

export enum HISTORY_ENUM_EXTENDED {
  FIVE_TRADING_DAYS = '5 Trading Days',
  ONE_MONTH = '1 Month',
  THREE_MONTHS = '3 Months',
  SIX_MONTHS = '6 Months',
  ONE = '1 Year',
  THREE = '3 Years',
  FIVE = '5 Years',
  MTD = 'Month to Date',
  QTD = 'Quarter to Date',
  YTD = 'Year to Date',
  FULL = 'Full',
}

export enum PERIOD_ENUM {
  FIVE_TRADING_DAYS = '5 Trading Days',
  ONE_MONTH = '1 Month',
  THREE_MONTHS = '3 Months',
  SIX_MONTHS = '6 Months',
  ONE = '1 Year',
  THREE = '3 Years',
  FIVE = '5 Years',
  YTD = 'Year to Date',
  FULL = 'Full',
}

export enum MONTH_ENUM {
  JANUARY = 1,
  FEBRUARY = 2,
  MARCH = 3,
  APRIL = 4,
  MAY = 5,
  JUNE = 6,
  JULY = 7,
  AUGUST = 8,
  SEPTEMBER = 9,
  OCTOBER = 10,
  NOVEMBER = 11,
  DECEMBER = 12,
}

export enum SHEET_CONVERSION_TYPE_ENUM {
  OBJECT = 'object',
  ARRAY = 'array',
}

export enum BOOLEAN_ENUM {
  TRUE = 'TRUE',
  FALSE = 'FALSE',
}

export enum DIRECTION_ENUM {
  TOP = 'TOP',
  BOTTOM = 'BOTTOM',
}

// PIPELINES

export enum REGRESSION_METHOD_ENUM {
  HUBER = 'HUBER',
  BISQUARE = 'BISQUARE',
}

export enum NEUTRALIZE_ASSET_MASTER_ENUM {
  CURRENCY = 'currency',
  QUOTATION_COUNTRY = 'quotation_country',
}

export enum NEUTRALIZE_RISK_MODEL_STYLE_ENUM {
  SECTOR = 'rbics_l1_id',
  SUB_SECTOR = 'rbics_l2_id',
  INDUSTRY = 'rbics_l3_id',
  SUB_INDUSTRY = 'rbics_l4_id',
}

export enum NEUTRALIZE_RISK_MODEL_STYLE_OUTDATED_ENUM {
  SECTOR = 'gics_sector',
  GROUP = 'gics_group',
  INDUSTRY = 'gics_ind',
  SUB_INDUSTRY = 'gics_subind',
}

export enum N_FACTOR_TYPE_ENUM {
  ASSET_MASTER = 'ASSET_MASTER',
  INDUSTRY = 'INDUSTRY',
  MARKET = 'market',
  RISK_MODEL_STYLE = 'risk_model_style',
  RISK_MODEL_CATEGORY = 'risk_model_category',
  RISK_MODEL_THEME = 'risk_model_theme',
  SIGNAL = 'signal',
}

export enum RISK_MODEL_CATEGORIES_ENUM {
  INDUSTRY = 'industry',
  CURRENCY = 'currency',
  COUNTRY = 'country',
}

export enum RISK_MODEL_THEMES_ENUM {
  CREDIT = 'credit',
  GROWTH = 'growth',
  LIQUIDITY = 'liquidity',
  MOMENTUM = 'momentum',
  SIZE = 'size',
  VALUE = 'value',
  VOLATILITY = 'volatility',
}

export enum CP_INDUSTRY_ENUM {
  SECTOR = 'rbics_l1_id',
}

export enum MAPPED_RBICS {
  rbics_l1_name = 'levelOne',
  rbics_l2_name = 'levelTwo',
  rbics_l3_name = 'levelThree',
  rbics_l4_name = 'levelFour',
  rbics_l1_id = 'levelOne',
  rbics_l2_id = 'levelTwo',
  rbics_l3_id = 'levelThree',
  rbics_l4_id = 'levelFour',
}

export enum MAPPED_RBICS_NAME {
  rbics_l1_name = 'Sector',
  rbics_l2_name = 'Sub Sector',
  rbics_l3_name = 'Industries',
  rbics_l4_name = 'Sub Industries',
  rbics_l1_id = 'Sector',
  rbics_l2_id = 'Sub Sector',
  rbics_l3_id = 'Industries',
  rbics_l4_id = 'Sub Industries',
}

export enum PIPELINE_FILTERS_ENUM {
  CLIP = 'clip',
  TAIL = 'tail',
  CATEGORIZE_SCALE = 'categorize_scale',
  CATEGORIZE = 'categorize',
  CHARACTERISTIC_PORTFOLIO = 'characteristic_portfolio',
  DEMEAN = 'demean',
  IMPLIED_ALPHA = 'implied_alpha',
  NEUTRALIZE = 'neutralize',
  ROBUST_NEUTRALIZE = 'robust_neutralize',
  MINMAX = 'minmax',
  NORMALIZE_KEYS = 'normalize_keys',
  QUANTILE_PORTFOLIO = 'quantile_portfolio',
  FILLNA = 'fillna',
  SCALE = 'scale',
  SIGMA = 'sigma',
  SIMPLE_PORTFOLIO = 'simple_portfolio',
  ROBUST_STANDARDIZE = 'robust_standardize',
  STANDARDIZE = 'standardize',
  TRUNCATE = 'truncate',
  QUANTIZE = 'quantize',
  TANH = 'tanh',
  RANK = 'rank',
  RISK_MODEL_MATRIX_MULTIPLICATION = 'risk_model_matrix_multiplication',
  QUANTILE_PORTFOLIO_BUCKETS = 'bucket_quantile',
  BUCKET_UPDATE = 'bucket_update',
  BUCKET_AVERAGE = 'bucket_average',
}

export enum BROWSE_ITEM_TYPE_ENUM {
  ASSET = 'ASSET',
  DATE_RANGE = 'DATE_RANGE',
  DATE = 'DATE',
  NUMBER_BOOLEAN = 'NUMBER_BOOLEAN',
  INPUT = 'INPUT',
  HIDDEN = 'HIDDEN',
  SELECT = 'SELECT',
  ETF = 'ETF',
  REMOTE_SELECT = 'REMOTE_SELECT',
}

export enum DATA_GRID_TYPE_ENUM {
  NUMBER = 'number',
  STRING = 'string',
  DATE = 'date',
  BOOLEAN = 'boolean',
  INTEGER = 'integer',
}

export enum DATA_SOURCE_ANALYSIS_TYPE_ENUM {
  BROWSER = 'browser',
  QUERIES = 'queries',
}

// ANALYSES

export enum ANALYSIS_MODE_ENUM {
  FULLSCREEN = 'fullscreen',
  GRID = 'grid',
  ADHOC = 'adhoc',
}

export enum CHART_MODE_ENUM {
  EDIT = 'edit',
  CHART = 'chart',
}

export enum SCALE_ENUM {
  LINEAR = 'linear',
  LOG = 'log',
}

export enum LAYOUT_TYPE {
  GENERAL = 'general',
  UNIVERSE = 'universe',
  SIGNAL = 'signal',
  SIGNAL_PREVIEW = 'signal_preview',
  SIGNAL_PORTAN = 'signal-portan',
  ALPHA_MODEL = 'alpha_model',
  BACKTEST = 'backtest',
  BACKTEST_PORTAN = 'backtest-portan',
  BACKTEST_DETAILED_VIEW = 'backtest-detailed-view',
  BACKTEST_PORTFOLIO_RISK = 'backtest-portfolio-risk',
  BASKET = 'basket',
  BASKET_ATTRIBUTION = 'basket-attribution',
  BASKET_DETAILED_VIEW = 'basket-detailed-view',
  BASKET_PORTFOLIO_RISK = 'basket-portfolio-risk',
  BACKTEST_SET = 'backtest_set',
  SIGNAL_SET = 'signal_set',
  SIGNAL_SET_REPORT = 'signal_set_report',
  RISK_MODEL = 'risk_model',
  RISK_MODEL_PREVIEW = 'risk_model_preview',
  BASKET_COMPARE = 'basket-compare',
  DATA_SOURCE = 'data_source',
  TIME_SERIES = 'time_series',
  STRATEGY = 'strategy',
  PORTFOLIO_ATTRIBUTION = 'portfolio_attribution',
  PORTFOLIO_PERFORMANCE = 'portfolio_performance',
  PORTFOLIO_RISK = 'portfolio_risk',
  PORTFOLIO_HOLDINGS_ANALYSIS = 'portfolio_holdings_analysis',
  PORTFOLIO_HOLDINGS_RISK = 'portfolio_holdings_analysis_risk',
  REBALANCE_PORTFOLIO_RISK = 'rebalance-portfolio-risk',
  REBALANCE_PORTFOLIO_TRADELIST = 'rebalance_portfolio_tradelist',
  REBALANCE_PORTFOLIO_HOLDINGS = 'rebalance_portfolio_holdings',
}

// JOBS

export enum JOB_STATUS_ENUM {
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
  RUNNING = 'RUNNING',
  CANCELLED = 'CANCELLED',
}

export enum JOB_ERROR_SEVERITY_ENUM {
  ERROR = 'ERROR',
  WARNING = 'WARNING',
}

// BACKTEST

// export enum RELAX_ORDER_ENUM {
//   FIRST = 'FIRST',
//   SECOND = 'SECOND',
//   THIRD = 'THIRD',
//   LAST =  'LAST',
//   NEVER = 'NEVER',
// }

export enum LONG_OPTION_ENUM {
  LONG_ONLY = 'long-only',
  LONG_SHORT = 'long-short',
}

export enum BACKTEST_ANALYSIS_TYPE_ENUM {
  DETAILS = 'details',
  ANALYSIS = 'analysis',
  SUMMARY = 'summary',
  PORTAN = 'portan',
  PORTFOLIO_RISK = 'portfoliorisk',
}

export enum BACKTEST_PREVIEW_MEMBERS_ENUM {
  SHOW_ALL = 'show_all',
  EXCLUDE_ZEROES = 'exclude_zeroes',
  ONLY_ADDED = 'only_added',
  ONLY_REMOVED = 'only_removed',
}

// SIGNALS

export enum SIGNAL_TYPE_ENUM {
  SIGNAL = 'SIGNAL',
  ALPHA_MODEL = 'ALPHA_MODEL',
}

export enum SIGNAL_ANALYSIS_TYPE_ENUM {
  ANALYSIS = 'analysis',
  ADHOC = 'adhoc',
  PREVIEW = 'preview',
  PORTAN = 'portan',
}

export enum ALPHA_MODEL_PARENT_ENUM {
  CLUSTERING = 'clustering',
  CUSTOM = 'custom',
  DECISSION_TREES = 'decisionTrees',
  ENSEMBLE_METHODS = 'ensembleMethods',
  FACTOR_DECOMPOSITION = 'factorDecomposition',
  FIXED_WEIGHTS = 'fixed',
  GAUSSIAN = 'gaussianMixtureModels',
  LINEAR_REGRESSION = 'linearRegression',
  OPTIMIZED_MODEL = 'optimizedModel',
}

export enum EXP_BUILDER_TYPE_ENUM {
  INT = 'int',
  FLOAT = 'float',
  STR = 'str',
  BOOL = 'bool',
  DATE_AMOUNT = 'date_amount',
  EXPR = 'expr',
  SIGNAL_SAMPLE = 'signal_sample',
  ENUM = 'enum',
  REMOTE_SELECT = 'REMOTE_SELECT',
}

// TIME SERIES

export enum TIME_SERIES_ANALYSIS_TYPE_ENUM {
  ANALYSIS = 'analysis',
  PREVIEW = 'preview',
}

// RISK MODELS

export enum RISK_MODEL_ANALYSIS_TYPE_ENUM {
  ANALYSIS = 'analysis',
  PREVIEW = 'preview',
  REPORT = 'report',
  CATEGORY = 'category',
}

// STRATEGIES

export enum STRATEGY_ANALYSIS_TYPE_ENUM {
  ANALYSIS = 'analysis',
}

// PORTFOLIOS
export enum PORTFOLIO_ANALYSIS_TYPE_ENUM {
  ATTRIBUTION = 'attribution',
  REPORT = 'report',
  PORTFOLIO_RISK = 'portfoliorisk',
}

export enum REBALANCE_ANALYSIS_TYPE_ENUM {
  TRADE_LIST = 'tradelist',
  CONSTRAINTS = 'constraints',
  PORTFOLIO_RISK = 'portfoliorisk',
  PORTFOLIO_HOLDINGS = 'portfolioholdings',
}

export enum PORTFOLIO_HOLDINGS_ANALYSIS_TYPE_ENUM {
  ANALYSIS = 'analysis',
}

// UNIVERSES

export enum UNIVERSE_ANALYSIS_TYPE_ENUM {
  SNAPSHOT = 'snapshot',
  DATES_COMPARISON = 'comparison',
  ASSET_PERTINENCE = 'pertinence',
  LINKED_ASSETS = 'linked',
  ANALYSIS = 'analysis',
}

export enum UNIVERSE_PREVIEW_MEMBERS_ENUM {
  SHOW_ALL = 'show_all',
  ONLY_ADDED = 'only_added',
  ONLY_REMOVED = 'only_removed',
}

export enum UNIVERSE_PREVIEW_VISUALIZATION_ENUM {
  DATE_COMPARISON = 'date_comparison',
  ASSET_PERTINENCE = 'asset_pertinence',
  SNAPSHOT = 'snapshot',
}

export enum UNIVERSE_PREVIEW_FILES {
  MEMBERS = 'members',
  AM = 'preview_am',
  INDUSTRY = 'preview_industry',
}

// OPTIMIZER

export enum OPTIMIZER_CONSTRAINT_TYPE_ENUM {
  GROSS_LEVERAGE_CONSTRAINT = 'gross_leverage_constraint',
  NET_LEVERAGE_CONSTRAINT = 'net_leverage_constraint',
  ALL_ASSETS_CONSTRAINT = 'all_assets_constraint',
  ASSET_VOLUME_CONSTRAINT = 'asset_volume_constraint',
  SPECIFIC_ASSETS_CONSTRAINT = 'specific_assets_constraint',
  RISK_FACTOR_CONSTRAINT = 'risk_factor_constraint',
  INDUSTRY_CONSTRAINT = 'industry_constraint',
  COUNTRY_CONSTRAINT = 'country_constraint',
  FACTOR_CONSTRAINT = 'factor_constraint',
  MAXIMUM_RISK_CONSTRAINT = 'maximum_risk_constraint',
  TURNOVER_CONSTRAINT = 'turnover_constraint',
  TCOST_CONSTRAINT = 'tcost_constraint',
  CASH_CONSTRAINT = 'cash_constraint',
  STOCK_LOAN_AVAILABILITY_CONSTRAINT = 'stock_loan_availability_constraint',
  TRADE_LIST_CONSTRAINT = 'trade_list_constraint',
  TRADE_LIST_SIGNAL_EXPOSURE_CONSTRAINT = 'trade_list_signal_exposure_constraint',
  SHARES_OUTSTANDING_CONSTRAINT = 'shares_outstanding_constraint',
  SHORT_SHARES_ASSETS_CONSTRAINT = 'short_shares_assets_constraint',
}

export enum OPTIMIZER_UI_GROUPS_ENUM {
  LEVERAGE = 'Leverage',
  ASSETS = 'Assets',
  PORTFOLIO = 'Portfolio',
}

// BASKET

export enum BASKET_TYPE_ENUM {
  COMMON = 'COMMON',
  COMBINED = 'COMBINED',
}

export enum BASKET_ANALYSIS_TYPE_ENUM {
  ADHOC = 'adhoc',
  PORTFOLIO_ANALYSIS = 'portfolioanalysis',
  ATTRIBUTION_ANALYSIS = 'attributionanalysis',
  PORTFOLIO_REPORT = 'portfolioreport',
  PORTFOLIO_DETAILS = 'portfoliodetails',
  COMPARE = 'compare',
  PORTFOLIO_RISK = 'portfoliorisk',
}

export enum BASKET_WEIGHTS_SOURCE_ENUM {
  ETF = 'etf',
  BASKET = 'basket',
  CASH = 'cash',
  INDEX = 'index',
}

// Fixed Costs

export enum FIXED_COST_COMISSION_ENUM {
  BPS = 'bps',
  MILS = 'mils',
}

// Backtest Sets

export enum BACKTEST_SET_ANALYSIS_TYPE_ENUM {
  ANALYSIS = 'analysis',
  SUMMARY = 'summary',
}

export enum OBJECTIVE_METRIC_ENUM {
  MAXIMIZE_RISK_ADJUSTED_RETURNS = 'MAXIMIZE_RISK_ADJUSTED_RETURNS',
  MAXIMIZE_RETURNS = 'MAXIMIZE_RETURNS',
  MINIMIZE_RISK = 'MINIMIZE_RISK',
}

// Signal Sets

export enum SIGNAL_SET_ANALYSIS_TYPE_ENUM {
  ANALYSIS = 'analysis',
  BROWSER = 'browser',
  REPORT = 'report',
}

// Users

export enum USER_ROLES_ENUM {
  ADMIN = 'ADMIN',
  REGULAR_USER = 'REGULAR_USER',
}

// OUTSIDE EXPORTS

export { REPORT_ENUMS };

// Rebalance

export enum IDENTIFIERS_ENUM {
  CUSIP = 'cusip',
  SEDOL = 'sedol',
  ISIN = 'isin',
  TICKER = 'ticker',
  BLOOMBERG_TICKER = 'bloomberg_ticker',
}

export enum REBALANCE_MAP_COLS {
  DATE = 'date',

  BLOOMBERG_TICKER = 'bloomberg_ticker',
  TICKER = 'ticker',
  COUNTRY = 'country',
  CUSIP = 'cusip',
  ISIN = 'isin',
  SEDOL = 'sedol',

  NAME = 'name',

  PRICE = 'price',
  CURRENCY = 'currency',
  SHARE_COUNT = 'share_count',
  BROKER = 'broker',
}

export enum BACKTEST_HOLDINGS_MAP_COLS {
  BLOOMBERG_TICKER = 'bloomberg_ticker',
  COUNTRY = 'country',
  CUSIP = 'cusip',
  ISIN = 'isin',
  NAME = 'name',
  SEDOL = 'sedol',
  TICKER = 'ticker',
  WEIGHTS = 'weights',
}

export const COLUMN_ALIASES = {
  [REBALANCE_MAP_COLS.DATE]: ['date', 'as of date', 'trade date', 'position date'],
  [REBALANCE_MAP_COLS.BLOOMBERG_TICKER]: ['bloomberg ticker', 'bloomberg', 'bbg ticker', 'bbg'],
  [REBALANCE_MAP_COLS.TICKER]: ['ticker', 'symbol', 'stock symbol', 'equity ticker'],
  [REBALANCE_MAP_COLS.COUNTRY]: ['country', 'country code', 'nation', 'location'],
  [REBALANCE_MAP_COLS.CUSIP]: ['cusip', 'cusip number', 'cusip id'],
  [REBALANCE_MAP_COLS.ISIN]: ['isin', 'isin number', 'isin code', 'international securities identification number'],
  [REBALANCE_MAP_COLS.SEDOL]: ['sedol', 'sedol code', 'stock exchange daily official list'],
  [REBALANCE_MAP_COLS.NAME]: ['name', 'security name', 'asset name', 'description', 'security description'],
  [REBALANCE_MAP_COLS.PRICE]: ['price', 'market price', 'security price', 'last price'],
  [REBALANCE_MAP_COLS.CURRENCY]: ['currency', 'ccy', 'currency code', 'denomination'],
  [REBALANCE_MAP_COLS.SHARE_COUNT]: ['shares', 'share count', 'number of shares', 'quantity', 'position'],
  [REBALANCE_MAP_COLS.BROKER]: ['broker', 'broker name', 'custodian', 'dealer'],
};

// Rebalance

export enum HOLDINGS_UPLOAD_STEPS_ENUM {
  HOLDINGS_CREATION = 'holdings_creation',
  MAPPING_COLUMNS = 'mapping_columns',
  CREATING_HOLDINGS = 'creating_holdings',
  HOLDINGS_CONFIRM = 'holdings_confirm',
}
