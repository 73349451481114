import createSignature from './create-signature';
import { _ } from '../libs';
import { WidgetExtended } from '../types/analysis';
import { Resource } from '../types/common';
import { JobRequest, JobRequests } from '../types/job';

/**
 * Gets all paths in an object that contain wildcard values (starting with $)
 * @param value - The object to search for wildcards
 * @param currentPath - The current path being searched (used for recursion)
 * @returns Array of paths containing wildcard values
 */
export const getWildcardPaths = (value: any, currentPath = ''): string[] => {
  let result: string[] = [];

  if (value == null) result = [];
  else if (Array.isArray(value)) {
    value.forEach((v, idx) => {
      const newPath: string = (_.isEmpty(currentPath) ? '' : currentPath + '.') + idx.toString();
      result.push(...getWildcardPaths(v, newPath));
    });
  } else if (typeof value === 'object') {
    Object.entries(value).forEach(([key, v]) => {
      const newPath: string = (_.isEmpty(currentPath) ? '' : currentPath + '.') + key;
      result.push(...getWildcardPaths(v, newPath));
    });
  } else if (typeof value == 'string' && value.startsWith('$')) result.push(currentPath);

  return result;
};

/**
 * Replaces wildcard values in widget parameters with actual values from context
 * @param widgetParams - The widget parameters containing wildcards
 * @param context - The context object containing actual values
 * @returns New parameters object with wildcards replaced by actual values
 */
const overrideWidgetParamsWildcards = (
  widgetParams: Record<string, any>,
  context: Record<string, any>
): Record<string, any> => {
  if (_.isEmpty(widgetParams)) return {};

  const newParams = _.cloneDeep(widgetParams);
  const wildcards = getWildcardPaths(widgetParams);

  wildcards.forEach((wcPath) => {
    const contextPath = _.get(widgetParams, wcPath).replace('$', '');
    let attribute = _.get(context, contextPath);

    if (!attribute) {
      // Let's try to get the attribute from first level of params. Could be found there on
      // widget specific contexts (general analysis)
      attribute = _.get(widgetParams, contextPath);
    }

    _.set(newParams, wcPath, attribute ?? null);
  });

  return newParams;
};

/**
 * Restores wildcard values in updated widget parameters
 * @param widgetParams - Original widget parameters containing wildcards
 * @param updatedWidgetParams - Updated widget parameters to restore wildcards in
 * @returns New parameters object with wildcards restored
 */
export const bringBackWildcards = (
  widgetParams: Record<string, any>, // This one has the wild cards
  updatedWidgetParams: Record<string, any>
) => {
  const newParams = _.cloneDeep(updatedWidgetParams);
  const paths = getWildcardPaths(widgetParams);

  paths.forEach((p) => {
    _.set(newParams, p, _.get(widgetParams, p) ?? _.get(newParams, p));
  });

  return newParams;
};

/**
 * Generates job requests for a list of widgets
 * @param widgets - Array of widgets to generate job requests for
 * @returns Array of job requests with unique signatures
 */
const getJobRequests = (widgets: WidgetExtended[]): JobRequests => {
  const requests: JobRequests = [];
  const signatures: Record<string, boolean> = {};
  widgets.forEach((widget) => {
    (widget.neededFiles || []).forEach((file) => {
      const jobRelatedParams = _.omit(
        {
          ...widget.defaultParams,
          ...widget.params,
        },
        widget.interactionParams || []
      );
      const signature = createSignature({ file, params: jobRelatedParams });
      const request: Record<string, JobRequest> = {};
      request[file] = { id: signature, signature, params: jobRelatedParams };
      // add if we don't have the signature already added
      if (!signatures[signature]) requests.push(request);
      signatures[signature] = true;
    });
  });

  return requests;
};

/**
 * Gets the name of a resource with a given ID and type
 * @param resources - Object containing resource collections
 * @param resourceId - ID of the resource to find
 * @param resourceType - Type of resource to search in
 * @returns Resource name with a hyphen suffix if found, null otherwise
 */
export const getResourceName = (
  resources: any,
  resourceId: number,
  resourceType: string
) => {
  const currentResourceName = (resources?.[resourceType] as Resource[])?.find((el) => el.id === resourceId)?.name;

  if (currentResourceName) return `${currentResourceName} -`;
  return null;
};

export { overrideWidgetParamsWildcards };
export { getJobRequests };
