// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
// Using from https://github.com/JosephClay/underscore-cookie/blob/master/underscore.cookie.js

import _ from 'lodash';

// Check if we're in a browser environment
const isBrowser = typeof window !== 'undefined' && typeof document !== 'undefined';

// Create mock document/window for non-browser environments
const mockDocument = {
  cookie: ''
};

const doc = isBrowser ? document : mockDocument;
const win = isBrowser ? window : {};

(function (_, window, document) {
  const _cookie = function (key, value, options) {
    // Return null if not in browser
    if (!isBrowser) return null;

    // Write =====================================
    if (value !== undefined) {
      options = _.extend({}, _cookie.defaults, options);

      if (value === null) {
        options.expires = -1;
      }

      if (typeof options.expires === 'number') {
        const days = options.expires;
        const t = (options.expires = new Date());
        t.setDate(t.getDate() + days);
      }

      value = _cookie.json ? JSON.stringify(value) : String(value);

      return (document.cookie = [
        encodeURIComponent(key),
        '=',
        _cookie.raw ? value : encodeURIComponent(value),
        // use expires attribute, max-age is not supported by IE
        options.expires ? `; expires=${options.expires.toUTCString()}` : '',
        options.path ? `; path=${options.path}` : '',
        options.domain ? `; domain=${options.domain}` : '',
        options.secure ? '; secure' : '',
      ].join(''));
    }

    // Read =====================================
    const decode = _cookie.raw ? _raw : _decoded;
    const cookies = document.cookie.split('; ');
    let idx = 0;
    const { length } = cookies;
    for (; idx < length; idx++) {
      const parts = cookies[idx].split('=');

      if (decode(parts.shift()) === key) {
        const cookie = decode(parts.join('='));
        return _cookie.json ? JSON.parse(cookie) : cookie;
      }
    }

    return null;
  };

  _cookie.defaults = { path: '/' };

  const _removeCookie = function (key, options) {
    if (!isBrowser) return false;
    
    if (_cookie(key) !== null) {
      _cookie(key, null, options);
      return true;
    }
    return false;
  };

  /* Utility Methods ***************************************************** */
  const _raw = function (s) {
    return s;
  };

  const _pluses = /\+/g;
  const _decoded = function (s) {
    return decodeURIComponent(s.replace(_pluses, ' '));
  };

  _.mixin({
    cookie: _cookie,
    removeCookie: _removeCookie,
  });
})(_, win, doc);
