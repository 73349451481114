import { _, React, ts } from '_core';

import ChartWrapper from '../../common/chart-wrapper';
import createLineChart from '../../common/charts/create-line-chart';

type ChartProps = {
  id: string;
  readOnly: boolean;
  widgetKey: string;
  loadWidgetData: (_payload: ts.types.widgets.WidgetGetDataParams) => Promise<ts.types.widgets.WidgetGetDataResponse>;
  file: string;
  fullScreen: boolean;
  title: string;
};

const Chart: React.FC<ChartProps> = ({ id, readOnly, widgetKey, loadWidgetData, file, fullScreen, title }) => {
  const [data, setData] = React.useState<ts.types.widgets.TableData>(null);
  const [error, setError] = React.useState<ts.types.common.ApiError>(null);
  const [showLegend, setShowLegend] = React.useState(false);

  const loadData = async () => {
    try {
      const response = await loadWidgetData({
        data: {
          file,
          rename: {
            build_date: 'date',
          },
        },
      });
      if (response) {
        setData(response.data);
      }
    } catch (err) {
      setError(err as ts.types.common.ApiError);
    }
  };

  React.useEffect(() => {
    if (file) loadData();
    return () => setData(null);
  }, [file]);

  React.useEffect(() => {
    if (!_.isNil(data))
      createLineChart({
        id,
        data,
        fullScreen,
        setShowLegend,
        exportTitle: title,
      });
  }, [data]);

  return (
    <ChartWrapper
      id={id}
      readOnly={readOnly}
      widgetKey={widgetKey}
      data={data}
      showLegend={showLegend}
      error={error}
      fullScreen={fullScreen}
    />
  );
};

export default Chart;
