import { _, React, ts } from '_core';

import { transform } from './helpers';
import { Params } from './types';
import ChartWrapper from '../../common/chart-wrapper';
import createNestedBarChart from '../../common/charts/create-nested-bar-chart';

type ChartProps = {
  id: string;
  readOnly: boolean;
  widgetKey: string;
  portfolios: string;
  fullScreen: boolean;
  hasBenchmark: boolean;
  params: Params;
  loadWidgetData: (_payload: ts.types.widgets.WidgetGetDataParams) => Promise<ts.types.widgets.WidgetGetDataResponse>;
  title: string;
};

const Chart: React.FC<ChartProps> = ({
  id,
  readOnly,
  widgetKey,
  loadWidgetData,
  portfolios,
  params,
  fullScreen,
  hasBenchmark,
  title,
}): React.ReactElement => {
  const [data, setData] = React.useState(null);
  const [error, setError] = React.useState(null);
  const [showLegend, setShowLegend] = React.useState(false);

  const loadData = async () => {
    try {
      const response = await loadWidgetData({
        data: {
          file: portfolios,
        },
      });
      if (response) {
        response.data.forEach((row) => {
          delete row.as_of;
        });

        setData(response.data);
      }
    } catch (err) {
      setError(JSON.stringify(err));
    }
  };
  React.useEffect(() => {
    if (portfolios) loadData();
    return () => setData(null);
  }, [portfolios]);

  React.useEffect(() => {
    // Create line chart on data and scale changes
    const format = params.statistics_type !== 'adjusted' ? '#.##%' : '#.##';
    if (!_.isNil(data))
      createNestedBarChart({
        id,
        data,
        fullScreen,
        setShowLegend,
        format,
        exportTitle: title,
        prepareData: (nestedBarData) =>
          transform(
            nestedBarData,
            params.statistics_type,
            params.isBasket,
            hasBenchmark,
            params.benchmark?.type === 'INDEX'
          ),
      });
  }, [data]);

  return (
    <ChartWrapper
      id={id}
      readOnly={readOnly}
      widgetKey={widgetKey}
      data={data}
      showLegend={showLegend}
      warning={data?.warnings}
      error={error}
      fullScreen={fullScreen}
    />
  );
};

Chart.defaultProps = {
  hasBenchmark: false,
};

export default Chart;
