import React from 'react';

import ContextSingleton from '../../__singletons__/context-singleton';
import { get_valid_dates } from '../../api/dates';
import * as enums from '../../enums';
import * as hooks from '../../hooks';
import { _, moment } from '../../libs';
import FinDateRangePicker from '../date-range-selector';

type SmartDateRangePickerSelectorProps = {
  value: string[];
  onChange: (_v: string[]) => void;

  minDate: string;
  maxDate: string;

  externalLoading?: boolean;

  universeId?: number;
  strategyId?: number;
  countries?: string[];

  disabled?: boolean;
  frequency?: enums.FREQUENCY_ENUM;
  setExternalError?: (_e: string) => void;
  setExternalValidDates?: (_d: string[]) => void;
  setExternalLoading?: (_l: boolean) => void;
  forceReload?: boolean;
  hideMessage?: boolean;
  fullRange?: boolean;
  verticalPositioning?: boolean;
  hideInputError?: boolean;
  [key: string]: any;
  placement?: 'top' | 'bottom' | 'right' | 'left';
  onlyEndDate?: boolean;
  onlyStartDate?: boolean;
  onlyUpdateOnClose?: boolean;
  allowAllDates?: boolean;
  untilLatestTradeDate?: boolean;
};

/**
 * Component that provides a date range selector with validation against valid dates from an API
 * 
 * @requires Features:
 * - start_date: Default start date if not provided
 * - end_date: Default end date if not provided
 * 
 * @param props - Component props
 * @param props.value - Selected date range values
 * @param props.onChange - Callback when date range changes
 * @param props.minDate - Minimum allowed date
 * @param props.maxDate - Maximum allowed date
 * @param props.externalLoading - External loading state
 * @param props.universeId - ID of universe to get valid dates for
 * @param props.strategyId - ID of strategy to get valid dates for
 * @param props.countries - Array of country codes to get valid dates for
 * @param props.disabled - Whether the selector is disabled
 * @param props.frequency - Frequency enum for valid dates
 * @param props.setExternalError - Callback to set external error state
 * @param props.setExternalValidDates - Callback to set external valid dates
 * @param props.setExternalLoading - Callback to set external loading state
 * @param props.forceReload - Whether to force reload valid dates
 * @param props.hideMessage - Whether to hide messages
 * @param props.fullRange - Whether to show full date range
 * @param props.verticalPositioning - Whether to position vertically
 * @param props.hideInputError - Whether to hide input errors
 * @param props.placement - Placement of the popover
 * @param props.onlyEndDate - Whether to only show end date
 * @param props.onlyStartDate - Whether to only show start date
 * @param props.onlyUpdateOnClose - Whether to only update on close
 * @param props.allowAllDates - Whether to allow all dates
 * @param props.untilLatestTradeDate - Whether to get dates until latest trade date
 * 
 * @returns Rendered date range selector component
 */
const SmartDateRangePickerSelector: React.FC<SmartDateRangePickerSelectorProps> = (props): React.ReactElement => {
  const features = ContextSingleton.getInstance().features;

  const [loading, setLoading] = React.useState(true);
  const [validDates, setValidDates] = React.useState<string[]>(null);
  const [allValidDates, setAllValidDates] = React.useState<string[]>(null);
  const [allValidDailyDates, setAllValidDailyDates] = React.useState<string[]>(null);
  const [maxUniverseDate, setMaxUniverseDate] = React.useState<moment.Moment>();
  const [minUniverseDate, setMinUniverseDate] = React.useState<moment.Moment>();

  React.useEffect(() => {
    setLoading(props.externalLoading);
  }, [props.externalLoading]);

  const {
    universeId,
    strategyId,
    countries,
    value,
    onChange,
    disabled,
    maxDate,
    minDate,
    frequency,
    setExternalError,
    forceReload,
    setExternalValidDates,
    setExternalLoading,
    verticalPositioning,
    hideInputError,
    placement,
    onlyEndDate,
    onlyStartDate,
    allowAllDates,
    untilLatestTradeDate,
  } = props;

  // As countries is an array, it could change the memo space with updates, but without changing the inner data
  // We will manage an inner state that will only be updated if there is a change

  React.useEffect(() => {
    if (setExternalLoading) setExternalLoading(loading);
  }, [loading]);

  const [innerCountries, setInnerCountries] = React.useState<string[]>(null);

  React.useEffect(() => {
    if (!_.isEqual(countries, innerCountries)) setInnerCountries(countries);
  }, [countries]);

  const minSelectorDate = React.useMemo(() => {
    const momentMinUniDate = minUniverseDate ? moment(minUniverseDate) : null;
    const momentMinDate = moment(minDate);

    return momentMinUniDate
      ? momentMinUniDate.isAfter(momentMinDate)
        ? momentMinUniDate.format('YYYY-MM-DD')
        : momentMinDate.format('YYYY-MM-DD')
      : momentMinDate.format('YYYY-MM-DD');
  }, [minDate, minUniverseDate]);

  const maxSelectorDate = React.useMemo(() => {
    const momentMaxUniDate = maxUniverseDate ? moment(maxUniverseDate) : null;
    const momentMaxDate = moment(maxDate);

    return momentMaxUniDate
      ? momentMaxUniDate.isBefore(momentMaxDate)
        ? momentMaxUniDate.format('YYYY-MM-DD')
        : momentMaxDate.format('YYYY-MM-DD')
      : momentMaxDate.format('YYYY-MM-DD');
  }, [maxDate, maxUniverseDate]);

  const loadUniverseValidDates = async (localFrequency = frequency, skipUniverse = false) => {
    try {
      let getValidDatesPayload;
      if (universeId && !skipUniverse) {
        getValidDatesPayload = {
          universe_id: universeId,
        };
      } else if (strategyId && !skipUniverse) {
        getValidDatesPayload = {
          strategy_id: strategyId,
          start_date: minDate || features.start_date,
          end_date: maxDate || features.end_date,
        };
      } else {
        getValidDatesPayload = {
          countries: innerCountries,
          start_date: minDate || features.start_date,
          end_date: maxDate || features.end_date,
        };
      }

      const resp = await get_valid_dates({
        frequency: localFrequency,
        ...getValidDatesPayload,
        until_latest_trade_date: untilLatestTradeDate,
      });
      const dates = resp.data || [];
      return dates;
    } catch {
      throw 'Error while loading the universe valid dates.';
    }
  };

  // First effect will be triggered when inner countries is set
  hooks.useEffectWithoutFirst(() => {
    const loadValidDates = async () => {
      try {
        setLoading(true);

        const datesCalls = [loadUniverseValidDates()];

        if (frequency !== enums.FREQUENCY_ENUM.DAILY)
          datesCalls.push(loadUniverseValidDates(enums.FREQUENCY_ENUM.DAILY));

        const [dates, allDailyDates] = await Promise.all(datesCalls);

        const allDates = allowAllDates ? await loadUniverseValidDates(frequency, true) : dates;
        setMinUniverseDate(dates?.[0] ? moment(moment(dates[0])) : undefined);
        setMaxUniverseDate(allDates?.[allDates.length - 1] ? moment(moment(allDates[allDates.length - 1])) : undefined);

        setValidDates(dates);
        setAllValidDates(allDates);
        setAllValidDailyDates(allDailyDates ?? dates);
        setExternalValidDates(dates);
      } catch (err) {
        console.log(err);
      }
      setLoading(false);
    };

    loadValidDates();
  }, [universeId, strategyId, innerCountries, forceReload, frequency]);

  const localValidDates = React.useMemo(() => {
    return disabled || loading ? undefined : validDates;
  }, [validDates]);

  const localAllValidDates = React.useMemo(() => {
    return disabled || loading ? undefined : allValidDates;
  }, [allValidDates]);

  const localAllValidDailyDates = React.useMemo(() => {
    return disabled || loading ? undefined : allValidDailyDates;
  }, [allValidDailyDates]);

  return (
    <>
      <FinDateRangePicker
        {...props}
        defaultCalendarMonth={maxSelectorDate ? moment(maxSelectorDate).toDate() : undefined}
        disabled={loading || disabled}
        loading={loading}
        maxDate={maxSelectorDate}
        minDate={minSelectorDate}
        value={value}
        onChange={onChange}
        validDates={localValidDates}
        allValidDates={localAllValidDates}
        allValidDailyDates={localAllValidDailyDates}
        setExternalError={setExternalError}
        verticalPositioning={verticalPositioning}
        hideInputError={hideInputError || loading}
        placement={placement}
        onlyEndDate={onlyEndDate}
        onlyStartDate={onlyStartDate}
      />
    </>
  );
};

SmartDateRangePickerSelector.defaultProps = {
  frequency: undefined,
  setExternalError: () => undefined,
  forceReload: false,
  hideMessage: false,
  fullRange: false,
  disabled: false,
  setExternalValidDates: () => undefined,
  verticalPositioning: false,
  hideInputError: false,
  onlyEndDate: false,
  onlyStartDate: false,
  onlyUpdateOnClose: false,
  untilLatestTradeDate: false,
  countries: [],
};

export default SmartDateRangePickerSelector;
