import FinDateFilterInput from '@local/finsera-core/src/_components/fin-data-grid/date-filter-input';
import * as enums from '@local/finsera-core/src/enums';
import { _, mui } from '@local/finsera-core/src/libs';
import * as types from '@local/finsera-core/src/types';
import {
  getGridBooleanOperators,
  getGridDateOperators,
  getGridNumericOperators,
  getGridStringOperators,
  GridFilterOperator,
} from '@mui/x-data-grid-premium';

import { tableFormatters } from '..';

const COLUMN_TYPE_MAP = {
  string: types.components.dataGrid.COLUMNS_TYPE_ENUM.STRING,
  boolean: types.components.dataGrid.COLUMNS_TYPE_ENUM.BOOLEAN,
  integer: types.components.dataGrid.COLUMNS_TYPE_ENUM.NUMBER,
  number: types.components.dataGrid.COLUMNS_TYPE_ENUM.NUMBER,
  date: types.components.dataGrid.COLUMNS_TYPE_ENUM.DATE,
};

/**
 * Converts a string type to its corresponding COLUMNS_TYPE_ENUM value
 * @param {string} type - The type to convert (e.g., 'string', 'boolean', 'integer', 'number', 'date')
 * @returns {COLUMNS_TYPE_ENUM} The corresponding enum value from COLUMNS_TYPE_ENUM
 *
 * @example
 * const type = 'integer';
 * const columnType = convertColumnType(type);
 * console.log(columnType); // COLUMNS_TYPE_ENUM.NUMBER || 'number'
 */
export const convertColumnType = (type: string) => {
  return COLUMN_TYPE_MAP[type as keyof typeof COLUMN_TYPE_MAP];
};

/**
 * Calculates the width of a column based on text length
 *
 * @param textLength - Length of text to calculate width for
 * @returns Calculated width in pixels
 */
export const calculateColumnWidthFromText = (textLength: number): number => {
  const fontSize = 16;
  const paddingCompensation = 6;
  const baseWidth = textLength * fontSize * 0.6 + paddingCompensation;
  const minWidth = 49;
  const maxWidth = 600;
  return Math.round(Math.min(Math.max(baseWidth, minWidth), maxWidth));
};

/**
 * Determines the column width based on content
 *
 * @param column - Column definition
 * @param data - Dataset to analyze for width calculation
 * @returns Calculated width for the column
 */
export const determineColumnWidth = (
  column: types.components.dataGrid.ColumnsData[number],
  data: Record<string, string | number | boolean>[]
): number => {
  const nameWidth = column.name ? (column.name as string).length : 0;
  const values: any[] = [];

  data.forEach((row) => {
    values.push(row[column.key]);
  });

  const valueWidth: number = _.max(values.map((val) => (val ? (val.toString().length as number) : 0))) || 0;
  const localWidth = Math.max(nameWidth, valueWidth);

  return calculateColumnWidthFromText(localWidth);
};

/**
 * Creates filter operators based on column type
 *
 * @param columnType - Type of the column
 * @param isAsync - Whether the grid is in async mode
 * @param dateFilterInput - Optional custom date filter input component for async mode
 * @returns Array of filter operators for the column
 */
export const getFilterOperatorsForColumnType = (columnType: string, isAsync = false): GridFilterOperator[] => {
  const customStringOperator: GridFilterOperator = {
    label: 'not equal',
    value: 'notEquals',
    getApplyFilterFn: (filterItem) => {
      if (!filterItem.field || !filterItem.value || !filterItem.operator) {
        return null;
      }

      return (value) => {
        return value != filterItem.value;
      };
    },
    InputComponent: getGridStringOperators()[0].InputComponent,
    InputComponentProps: getGridStringOperators()[0].InputComponentProps,
  };

  let filterOperators: GridFilterOperator[] = getGridStringOperators();
  const equalsIndex = filterOperators.findIndex((operator) => operator.value === 'equals');

  filterOperators = [
    ...filterOperators.slice(0, equalsIndex + 1),
    customStringOperator,
    ...filterOperators.slice(equalsIndex + 1),
  ];

  if (columnType === enums.DATA_GRID_TYPE_ENUM.BOOLEAN) {
    filterOperators = getGridBooleanOperators();
  } else if (columnType === enums.DATA_GRID_TYPE_ENUM.DATE) {
    filterOperators = getGridDateOperators();
    if (isAsync) {
      filterOperators = filterOperators.map((operator) => ({
        ...operator,
        InputComponent: operator.InputComponent ? FinDateFilterInput : undefined,
      }));
    }
  } else if (columnType === enums.DATA_GRID_TYPE_ENUM.NUMBER || columnType === enums.DATA_GRID_TYPE_ENUM.INTEGER) {
    filterOperators = getGridNumericOperators();
  }

  // Filter out isAnyOf for async mode
  if (isAsync) {
    filterOperators = filterOperators.filter((fil) => fil.value !== 'isAnyOf');
  }

  return filterOperators;
};

/**
 * Determines the column type based on data and configuration
 *
 * @param column - Column definition
 * @param data - Dataset to analyze for type inference
 * @param tableDtypes - Optional predefined data types
 * @returns Determined column type
 */
export const determineColumnType = (
  column: types.components.dataGrid.ColumnsData[number],
  data: Record<string, string | number | boolean>[],
  tableDtypes?: Record<string, string>
): string => {
  if (column.filter && column.filter !== 'inferred') {
    return column.filter;
  }

  const filteredRows = _.filter(data, (el: any) => !_.isNil(el[column.key]));

  if (!_.isEmpty(filteredRows)) {
    return convertColumnType(_.inferArrayType(_.map(filteredRows, column.key)));
  }

  if (tableDtypes && tableDtypes[column.key]) {
    return tableDtypes[column.key];
  }

  return '';
};

/**
 * Creates value getter function for a column
 *
 * @param column - Column definition
 * @returns Value getter function
 */
export const createValueGetter = (column: types.components.dataGrid.ColumnsData[number]) => {
  const customValueGetter: mui.dataGrid.GridColDef['valueGetter'] = (value, row, colDef, apiRef) => {
    const field = colDef.field;

    if (column.valueGetter) {
      return column.valueGetter(value, row, colDef, apiRef);
    }

    if (column.ignoreValueGetter) {
      return value;
    }

    const formatter = column.formatter;
    const formattedValue = formatter
      ? formatter({ row, column: { key: field }, toValue: true })
      : tableFormatters.formatTo('inferred')({
          row,
          column: { key: field },
          toValue: true,
        });

    return formattedValue;
  };

  return customValueGetter;
};

/**
 * Creates render cell function for a column
 *
 * @param column - Column definition
 * @returns Render cell function
 */
export const createRenderCell = (column: types.components.dataGrid.ColumnsData[number]) => {
  return (params: mui.dataGrid.GridRenderCellParams) => {
    if (column.renderCell) {
      return column.renderCell(params)({
        row: params.row,
        column: { key: params.field },
      });
    }

    if (params.rowNode.type === 'group') {
      return params.value || '';
    }

    return column.formatter
      ? column.formatter({ row: params.row, column: { key: params.field } })
      : tableFormatters.formatTo('inferred')({
          row: params.row,
          column: { key: params.field },
        });
  };
};
