import { actions, Link, mui, React, ts, ui,useDispatch, useHistory, useSelector } from '_core';

import UserPreferencesModal from '../preferences';

type ProfileNavProps = {
  closeAction?: () => void;
};

const ProfileNav: React.FC<ProfileNavProps> = ({ closeAction }): React.ReactElement => {
  const history = useHistory();
  const dispatch = useDispatch();
  const currentTab = useSelector((state) => state.ui.currentTab);
  const currentUser = useSelector((state) => state.auth.currentUser);
  const setCurrentTab = (tab: ts.types.common.CurrentTab) => dispatch(actions.ui.setCurrentTab(tab));
  const [openFeedback, setOpenFeedback] = React.useState(false);
  const [showConfirmSignout, setShowConfirmSignout] = React.useState(false);
  const [loadingSignout, setLoadingSignout] = React.useState(false);
  const theme = mui.styles.useTheme() as mui.core.Theme;

  const HandleUserLink = (tabName: ts.types.common.CurrentTab) => {
    if (currentTab !== tabName) setCurrentTab(tabName);
    closeAction();
  };

  const handleSignout = () => {
    setShowConfirmSignout(true);
  };

  const logout = async () => {
    setLoadingSignout(true);
    await dispatch(actions.auth.logout());
    history.push('/');
    setLoadingSignout(false);
  };

  return (
    <>
      <mui.core.Box p={3}>
        <mui.core.Typography fontWeight={600}>{currentUser.name}</mui.core.Typography>
        <mui.core.Typography color="textSecondary" sx={{ mb: 1 }}>
          {currentUser.email}
        </mui.core.Typography>
        <Link
          to="/user-management/me"
          onClick={() => {
            HandleUserLink('users');
          }}
        >
          <ui.Button variant="light">Edit Profile</ui.Button>
        </Link>
      </mui.core.Box>

      <mui.core.Divider />

      <mui.core.Box>
        <mui.core.MenuItem
          onClick={() => {
            setOpenFeedback(true);
            closeAction();
          }}
          sx={{ borderRadius: theme.spacing(1) }}
        >
          <mui.core.ListItemIcon>
            <mui.icons.FeedbackOutlined />
          </mui.core.ListItemIcon>
          <mui.core.ListItemText>Give Feedback</mui.core.ListItemText>
        </mui.core.MenuItem>

        <mui.core.MenuItem
          component={Link}
          to={currentTab == 'help' ? history.location.pathname : '/help'}
          onClick={() => HandleUserLink('help')}
          sx={{ borderRadius: theme.spacing(1) }}
        >
          <mui.core.ListItemIcon>
            <mui.icons.HelpOutline />
          </mui.core.ListItemIcon>
          <mui.core.ListItemText>Help</mui.core.ListItemText>
        </mui.core.MenuItem>
      </mui.core.Box>

      <mui.core.Divider />

      <mui.core.Box>
        {currentUser.role == 'ADMIN' && (
          <mui.core.MenuItem
            component={Link}
            to="/user-management"
            id="users-link"
            onClick={() => HandleUserLink('users')}
            sx={{ borderRadius: theme.spacing(1) }}
          >
            <mui.core.ListItemIcon>
              <mui.icons.PersonOutlined />
            </mui.core.ListItemIcon>
            <mui.core.ListItemText>Users</mui.core.ListItemText>
          </mui.core.MenuItem>
        )}

        <mui.core.MenuItem onClick={handleSignout}>
          <mui.core.ListItemIcon>
            <mui.icons.LogoutOutlined />
          </mui.core.ListItemIcon>
          <mui.core.ListItemText>Sign out</mui.core.ListItemText>
        </mui.core.MenuItem>
      </mui.core.Box>
      <ui.ConfirmationDialog
        open={showConfirmSignout}
        title="Are you sure?"
        content="Are you sure you want to sign out?"
        onSuccess={() => logout()}
        onCancel={() => setShowConfirmSignout(false)}
        successLabel="Sign Out"
        loading={loadingSignout}
      />
      <UserPreferencesModal />
      <ui.FeedbackDialog isOpen={openFeedback} handleClose={() => setOpenFeedback(false)} currentUser={currentUser} />
    </>
  );
};

export default ProfileNav;
