import { _, hooks, mui, React, ts, ui } from '_core';

import FactorComponent from './factor-component';
import getDefaultValue from './get-factor-default-value';

type NeutralizeProps = {
  params: ts.types.pipeline.NeutralizeDraft & { detailed_output?: boolean };
  setParams: (_params: ts.types.pipeline.NeutralizeDraft & { detailed_output?: boolean }) => void;
  isBasket?: boolean;
};

const Neutralize: React.FC<NeutralizeProps> = ({ params, setParams, isBasket }): React.ReactElement => {
  const F_ENUM = ts.enums.N_FACTOR_TYPE_ENUM;

  // For legacy purposes
  const getFactorFromParams = () => {
    const factorKeys = Object.values(F_ENUM);
    const factorsReady = params?.factors || [];

    return factorsReady.filter((f) => factorKeys.includes(f?.type as ts.enums.N_FACTOR_TYPE_ENUM));
  };

  const [factors, setFactors] = React.useState<ts.types.pipeline.NeutralizeDraft['factors']>(getFactorFromParams);

  // Store on factor changes
  hooks.useEffectWithoutFirst(() => {
    const data = { factors };
    setParams({ ...params, ...data });
  }, [factors]);

  // If we change intercept, then we will filter the disabled factors
  hooks.useEffectWithoutFirst(() => {
    if (params.intercept) {
      const filteredFactors = factors.filter((f) => {
        return f.type != 'INDUSTRY' && f.type != 'ASSET_MASTER' && f.type != 'risk_model_category';
      });
      setFactors(filteredFactors);
    }
  }, [params.intercept]);

  const addFactor = () => {
    setFactors([
      ...factors,
      {
        type: ts.enums.N_FACTOR_TYPE_ENUM.ASSET_MASTER,
        value: 'quotation_country',
      },
    ]);
  };

  const removeFactor = (idx: number) => {
    setFactors(factors.filter((el, el_idx) => el_idx !== idx));
  };

  const setFactorValue = (value: ts.types.pipeline.NeutralizeDraft['factors'][0], idx: number) => {
    const newFactors = [...factors];
    newFactors[idx] = value;
    setFactors(newFactors);
  };

  const setFactorType = (type: ts.enums.N_FACTOR_TYPE_ENUM, idx: number) => {
    const newFactors = [...factors];
    newFactors[idx] = getDefaultValue(type);
    setFactors(newFactors);
  };

  const getNeutralizeFactorsEnabled = (
    el: ts.types.pipeline.NeutralizeDraft['factors'][0],
    factors: ts.types.pipeline.NeutralizeDraft['factors']
  ): Record<ts.enums.N_FACTOR_TYPE_ENUM, boolean> => {
    const factorsTaken = factors.map((f) => f.type);
    const fcount = _.countBy(factorsTaken, _.identity);
    const factorKeys = Object.values(ts.enums.N_FACTOR_TYPE_ENUM);

    const factorsEnabled: Partial<Record<ts.enums.N_FACTOR_TYPE_ENUM, boolean>> = {};

    factorKeys.forEach((key) => {
      let enabled = false;
      if (key == ts.enums.N_FACTOR_TYPE_ENUM.RISK_MODEL_STYLE && fcount[ts.enums.N_FACTOR_TYPE_ENUM.RISK_MODEL_STYLE])
        enabled = true;
      factorsEnabled[key] = enabled;
    });

    return factorsEnabled as Record<ts.enums.N_FACTOR_TYPE_ENUM, boolean>;
  };

  return (
    <mui.core.Box p={2}>
      {!isBasket && (
        <mui.core.FormControlLabel
          control={
            <mui.core.Switch
              size="small"
              checked={params.detailed_output}
              onChange={() =>
                setParams({
                  ...params,
                  detailed_output: !params.detailed_output,
                })
              }
            />
          }
          label="Detailed Output (only applicable if the parameters have Pipeline or Portfolio Pipeline)"
        />
      )}
      <mui.core.Box mt={4}>
        <ui.SectionTitle subtitle="(At least 1 required)">Explanatory Variables</ui.SectionTitle>
        {factors.map((el, idx) => (
          <FactorComponent
            key={`${el.signal_id}_${el.pipeline_id}_${el.sp_pipeline_id}`}
            value={el}
            setValue={(val) => setFactorValue(val, idx)}
            setType={(type) => setFactorType(type, idx)}
            remove={() => removeFactor(idx)}
            getFactorsEnabled={() => getNeutralizeFactorsEnabled(el, factors)}
          />
        ))}
      </mui.core.Box>
      <mui.core.Box display="inline-flex">
        <mui.core.Button onClick={addFactor} variant="outlined" color="primary" disabled={factors.length >= 4}>
          <mui.icons.Add style={{ fontSize: '0.75rem', marginRight: '4px' }} />
          Add Variable
        </mui.core.Button>
      </mui.core.Box>
    </mui.core.Box>
  );
};

Neutralize.defaultProps = {
  isBasket: false,
};

export default Neutralize;
