import { ts } from '_core';

import { validate } from './helpers';
import { INTERACTION_PARAMS } from './types';

export default [
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.RISK_MODEL_PREVIEW_COV_MATRIX,
    form: true,
    title: 'Covariance Matrix',
    neededFiles: ['risk_model_covariance'],
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.RISK_MODEL_PREVIEW],
    interactionParams: Object.values(INTERACTION_PARAMS),

    validate,
    helpLabel: 'ccseh02h',
  },
] satisfies ts.types.analysis.WidgetConfig[];
