import { _, mui, React, ts, useSelector } from '_core';

import Chart from './chart';
import Form from './form';
import { DraftParams, Params } from './types';

type PerformanceAttributionContributorsProps = {
  mode: ts.enums.CHART_MODE_ENUM;
  params: Params & DraftParams & { fullWidth: boolean };
  setParams: (_p: DraftParams) => void;
  fullScreen?: boolean;
  widgetPaths?: Record<'backtest_bucket_analysis', string>;

  goFullScreen?: (_q: string) => void;
  context?: ts.types.backtest.BacktestAnaylsisContext | ts.types.basket.BasketAnalysisContext;
  queryParams?: { initial?: string };

  loadWidgetData: (_payload: ts.types.widgets.WidgetGetDataParams) => Promise<ts.types.widgets.WidgetGetDataResponse>;
};

const PerformanceAttributionContributors: React.FC<PerformanceAttributionContributorsProps> = ({
  loadWidgetData,
  params,
  setParams,
  context,
  widgetPaths,
  fullScreen,
  goFullScreen,
  mode,
  queryParams,
}): React.ReactElement => {
  const riskModels = useSelector((state) => state.resources.risk_models);
  const strategies = useSelector((state) => state.resources.strategies);

  const fileKey = React.useMemo(
    () => Object.keys(widgetPaths || {}).find((k) => k.endsWith('_bucket_analysis')) as keyof typeof widgetPaths,
    [widgetPaths]
  );

  const currentStrategy = React.useMemo(() => {
    return _.find(
      strategies,
      (el) => el.id == (context?.definition as ts.types.portfolio.PortfolioAnalysisContext['definition'])?.strategy_id
    );
  }, [(context?.definition as ts.types.portfolio.PortfolioAnalysisContext['definition'])?.strategy_id]);

  const currentRiskModel = React.useMemo(() => {
    return _.find(
      riskModels,
      (el) => el.id == context?.definition?.risk_model_id || el.id == currentStrategy?.definition?.risk_model_id
    );
  }, [context?.definition?.risk_model_id]);

  const renderJobComponent = () => (
    <mui.core.Box sx={{ height: '100%' }}>
      <Chart
        loadWidgetData={loadWidgetData}
        file={widgetPaths[fileKey]}
        fullScreen={fullScreen}
        goFullScreen={goFullScreen}
        params={params}
        setParams={setParams}
        queryParams={queryParams}
      />
    </mui.core.Box>
  );

  return (
    <>
      {mode == 'edit' ? (
        <Form params={params} setParams={setParams} context={context} contextRiskModel={currentRiskModel?.id} />
      ) : (
        renderJobComponent()
      )}
    </>
  );
};

export default PerformanceAttributionContributors;
