import React from 'react';

/**
 * Hook that works like useEffect but skips the first N renders
 * 
 * @param func - Effect callback function to run
 * @param n - Number of renders to skip before running the effect
 * @param deps - Array of dependencies that trigger the effect
 * 
 * @example
 * ```tsx
 * useEffectWithoutN(() => {
 *   console.log('This will run after skipping 3 renders');
 * }, 3, [someValue]);
 * ```
 */
const useEffectWithoutN = (func: React.EffectCallback, n: number, deps: React.DependencyList) => {
  const didMount = React.useRef(0);

  React.useEffect(() => {
    if (didMount.current == n) func();
    else didMount.current += 1;
  }, deps);
};

export default useEffectWithoutN;
