import { _, React, ts, useSelector } from '_core';

import Chart from './chart';
import Form from './form';
import { DraftParams, Params } from './types';

type SignalAttributionProps = {
  id: string;
  readOnly: boolean;
  widgetKey: string;
  mode: ts.enums.CHART_MODE_ENUM;
  params: Params | DraftParams;
  setParams: (_p: DraftParams) => void;

  queryParams?: { initial?: string };
  goFullScreen?: (_v: string) => void;
  fullScreen?: boolean;
  context?: ts.types.signal.SignalAnalysisContext | ts.types.basket.BasketAnalysisContext;
  widgetPaths?: Record<'signal_returns_driven_attribution_chart', string>;

  loadWidgetData: (_payload: ts.types.widgets.WidgetGetDataParams) => Promise<ts.types.widgets.WidgetGetDataResponse>;
};

const SignalAttribution: React.FC<SignalAttributionProps> = ({
  id,
  readOnly,
  widgetKey,
  loadWidgetData,
  params,
  fullScreen,
  setParams,
  mode,
  context,
  widgetPaths,
}): React.ReactElement => {
  const riskModels = useSelector((state) => state.resources.risk_models);

  const currentRiskModel = React.useMemo(
    () =>
      _.find(riskModels, (el) => {
        // As basket
        if ((context as any)?.definition?.risk_model_id) return el.id == (context as any)?.definition?.risk_model_id;
        // As signal
        if ((context as any)?.risk_model_id) return el.id == (context as any)?.risk_model_id;
        return false;
      }),
    [(context as ts.types.signal.SignalAnalysisContext)?.risk_model_id]
  );

  const currentRiskModelIndustries = currentRiskModel?.definition?.categories?.[0]?.factors;

  const frequency =
    (context as ts.types.signal.SignalAnalysisContext).frequency ||
    (context as ts.types.basket.BasketAnalysisContext).definition.frequency;

  const filteredHistories =
    frequency == ts.enums.FREQUENCY_ENUM.DAILY
      ? context.context_histories.filter((h) => h !== ts.enums.HISTORY_ENUM_EXTENDED.FIVE_TRADING_DAYS)
      : context.context_histories;

  return mode == 'edit' ? (
    <Form params={params} setParams={setParams} contextRiskModel={currentRiskModel?.id} />
  ) : (
    <Chart
      id={id}
      readOnly={readOnly}
      widgetKey={widgetKey}
      loadWidgetData={loadWidgetData}
      file={widgetPaths['signal_returns_driven_attribution_chart']}
      riskModelIndustries={currentRiskModelIndustries}
      fullScreen={fullScreen}
      params={params}
      histories={filteredHistories}
      startDate={context.start_date}
      endDate={context.end_date}
    />
  );
};

export default SignalAttribution;
