import { _, mui, React, ts, useSelector } from '_core';

import { Params } from './types';
import ChartWrapper from '../../common/chart-wrapper';
import createHeatMapChart from '../../common/charts/create-heat-map';
import { prepareBucketedRegimeData } from '../../common/utils/signal-utils';

type ChartProps = {
  id: string;
  readOnly: boolean;
  widgetKey: string;
  fullScreen: boolean;
  loadWidgetData: (_payload: ts.types.widgets.WidgetGetDataParams) => Promise<ts.types.widgets.WidgetGetDataResponse>;
  file: string;
  params: Params;
  context?: ts.types.signal.SignalAnalysisContext;
  title: string;
};

const Chart: React.FC<ChartProps> = ({
  id,
  readOnly,
  widgetKey,
  fullScreen,
  loadWidgetData,
  file,
  params,
  context,
  title,
}) => {
  const [data, setData] = React.useState<ts.types.widgets.HeatMapData>(null);
  const [error, setError] = React.useState<ts.types.common.ApiError>(null);
  const [selectedTab, setSelectedTab] = React.useState<'performance' | 'count' | 'volatility' | 'information_ratio'>(
    'performance'
  );

  const resources = useSelector((state) => state.resources);

  const timeSeries = useSelector((state) => state.resources.time_series);
  const signals = useSelector((state) => state.resources.signals);

  const pts = React.useMemo(() => {
    return timeSeries.find((ts) => ts.id == params.time_series_id);
  }, [params.time_series_id]);

  const currSig = React.useMemo(() => {
    return signals.find((s) => s.id == context.signal_id);
  }, [context.signal_id]);

  const loadData = async (tab: 'performance' | 'count' | 'volatility' | 'information_ratio') => {
    try {
      const response = await loadWidgetData({
        data: {
          file,
          query: ['tab', 'equals', tab],
        },
      });

      if (response) {
        setData(response.data as ts.types.widgets.HeatMapData);
      }
    } catch (err) {
      setError(err as ts.types.common.ApiError);
    }
  };

  React.useEffect(() => {
    if (file) loadData(selectedTab);
    return () => setData(null);
  }, [file, selectedTab]);

  React.useEffect(() => {
    const format = selectedTab === 'performance' || selectedTab === 'volatility' ? '#.##%' : '#.##';
    // Create line chart on data and scale changes
    if (!_.isNil(data)) {
      createHeatMapChart({
        id,
        data: data,
        prepareData: (heatMapData) =>
          prepareBucketedRegimeData(heatMapData, params.discrete, params.quantiles, params.buckets, resources),
        fullScreen,
        xAxis: 'bucket1',
        yAxis: 'bucket2',
        xAxisLabel: pts.name,
        yAxisLabel: currSig.name,
        rotateLabels: false,
        format,
        exportTitle: title,
      });
    }
  }, [data]);

  const getTabName = (t = selectedTab) => {
    if (t == 'count') return 'Avg Count';
    return _.startCase(t);
  };

  const getTabs = () => {
    return (
      <mui.core.Tabs
        indicatorColor="primary"
        textColor="primary"
        value={selectedTab}
        onChange={(e, newTab) => setSelectedTab(newTab)}
        aria-label="Tabs"
      >
        {['performance', 'count', 'volatility', 'information_ratio'].map((tab) => (
          <mui.core.Tab key={tab} label={getTabName(tab as any)} value={tab} />
        ))}
      </mui.core.Tabs>
    );
  };

  return (
    <ChartWrapper
      id={id}
      readOnly={readOnly}
      widgetKey={widgetKey}
      data={data}
      warning={data?.length === 0 ? ['Empty File'] : []}
      error={error}
      fullScreen={fullScreen}
      tabs={getTabs()}
    />
  );
};

export default Chart;
