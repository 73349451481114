import React from 'react';

import { _ } from '../libs';

/**
 * Hook that watches a cookie value and returns its current value.
 * Re-renders component when cookie value changes.
 * 
 * @param cookieName - Name of the cookie to watch
 * @param pollingRate - How frequently to check for cookie changes in milliseconds (default: 500ms)
 * @returns Current value of the cookie as a string
 * 
 * @example
 * ```tsx
 * const cookieValue = useWatchCookie('myCookie');
 * ```
 */
const useWatchCookie = (cookieName: string, pollingRate = 500): string => {
  const cookieValueRef = React.useRef(_.cookie(cookieName)) as React.MutableRefObject<string>;
  const [, setCookieState] = React.useState(cookieValueRef.current);

  React.useEffect(() => {
    const interval = setInterval(() => {
      const cookie = _.cookie(cookieName);
      if (cookie !== cookieValueRef.current) {
        cookieValueRef.current = cookie;
        setCookieState(cookie); // trigger state update
      }
    }, pollingRate);
    return () => clearInterval(interval);
  }, []);

  return cookieValueRef.current;
};

export default useWatchCookie;
