import { mui, React, ts } from '_core';

import Chart from './chart';
import Form from './form';
import { DraftParams, Params } from './types';

type PortfolioTradelistProps = {
  id: string;
  mode: ts.enums.CHART_MODE_ENUM;
  context?: ts.types.rebalance.RebalanceAnalysisContext;
  loadWidgetData: (_payload: ts.types.widgets.WidgetGetDataParams) => Promise<ts.types.widgets.WidgetGetDataResponse>;
  params: Params;
  setParams: (_p: DraftParams) => void;
  widgetPaths?: Record<'tradelist_rebalance_analysis', string>;
  goFullScreen?: (_b: string) => void;
  fullScreen?: boolean;

  queryParams?: { initial?: string };
};

const PortfolioTradelist: React.FC<PortfolioTradelistProps> = ({
  id,
  loadWidgetData,
  mode,
  params,
  setParams,
  widgetPaths,
  fullScreen,
  goFullScreen,
  queryParams,
  context,
}) => {
  const renderJobComponent = () => (
    <mui.core.Box sx={{ height: '100%' }}>
      <Chart
        id={id}
        loadWidgetData={loadWidgetData}
        tradeList={widgetPaths['tradelist_rebalance_analysis']}
        fullScreen={fullScreen}
        queryParams={queryParams}
        goFullScreen={goFullScreen}
        context={context}
        params={params}
        setParams={setParams}
      />
    </mui.core.Box>
  );

  return <>{mode == 'edit' ? <Form params={params} setParams={setParams} /> : renderJobComponent()}</>;
};

export default PortfolioTradelist;
