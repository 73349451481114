import { helpers, ts } from '_core';

export const getNewPortfolio = (name: string, handle: string, strategy: ts.types.strategy.Strategy) => {
  // Check if we will create the portfolio with a premade definition
  const newPortfolio = {
    name,
    handle,
    definition: {
      strategy_id: strategy.id,
    },
    is_valid: true,
  } as ts.types.portfolio.PortfolioDraft;

  return { ...newPortfolio } as ts.types.portfolio.Portfolio;
};

export const getArchivedDeps = (
  portfolio: ts.types.portfolio.Portfolio,
  resources: ts.StoreState['resources']
): ts.types.common.ArchivedDeps => {
  const deps = helpers.resourceGraph.getDeps(
    { ...portfolio, resourceType: ts.enums.RESOURCES_TYPES_ENUM.PORTFOLIO },
    resources
  );
  return helpers.resourceGraph.getArchiveDeps(deps);
};
