import { ts } from '_core';

import { getName, validate } from './helpers';
import { DraftParams } from './types';

export default [
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.TIME_SERIES_HISTOGRAM,
    title: 'Histogram',
    helpLabel: '27tmo4vx',
    form: true,
    defaultParams: {
      history: 'Full',
      num_bins: 100,
      percent: true,
      format_for_ui: true,
    } as DraftParams,
    neededFiles: ['time_series_histogram'],
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.TIME_SERIES],

    getName,
    validate,
  },
] satisfies ts.types.analysis.WidgetConfig[];
