import searchQuery from 'search-query-parser';

/**
 * Response type for parsed search query containing filters and search term
 */
type searchQueryResponse = {
  /** The main search term extracted from the query */
  searchTerm?: string | string[];
  /** Object containing any filter key-value pairs from the query */
  filters: Record<string, string | string[]>;
};

/**
 * Parses a search query string into filters and search term
 * @param searchQueryText - The raw search query text to parse
 * @param queryOptions - Configuration options for the query parser
 * @returns Object containing extracted filters and search term
 * @example
 * // Returns {filters: {type: "signal"}, searchTerm: "momentum"}
 * parseSearchQuery("type:signal momentum", {keywords: ["type"]})
 */
const parseSearchQuery = (searchQueryText: string, queryOptions: Record<string, any>): searchQueryResponse => {
  const params = searchQuery.parse(searchQueryText, queryOptions);
  // check if we have extra filters or just the search term
  if (params && typeof params == 'object') return { filters: params, searchTerm: params.text };
  return { filters: {}, searchTerm: params as string };
};

export default parseSearchQuery;
