import { Store } from 'redux';

import * as admin from './admin';
import * as assetMaster from './asset-master';
import * as auth from './auth';
import * as combined from './combined';
import * as datasets_ls from './datasets_ls';
import * as dates from './dates';
import * as docs from './docs';
import * as dslSignal from './dsl_signal';
import * as embedWidgets from './embed-widgets';
import * as feedback from './feedback';
import * as jobLogs from './job-logs';
import * as job from './jobs';
import * as loadData from './load-data';
import * as localApi from './local-api';
import * as orgPublish from './org-publish';
import * as poll from './poll';
import * as s3 from './s3';
import apis from './scrud';
import * as uiApi from './ui-api';
import * as user_api from './user-api';
import * as user from './users';
import * as videos from './videos';
import ContextSingleton, { FinseraProviderValues } from '../__singletons__/context-singleton';
import { _, $, deepmerge, Sentry } from '../libs';
import { ApiTypes, BaseStore } from '../types';

const MOCK_APIS = ['local-api'];

const RETRY_TIMEOUT_LIMIT = 1;
const RETRY_500_ERRORS = 3;

const isBrowser = typeof window !== 'undefined' && typeof document !== 'undefined';
const mockWindow = {
  CUSTOM_URL: '',
};

const win = isBrowser ? window : mockWindow;
const CUSTOM_URL = (win as any).CUSTOM_URL || '';

const TIMEOUT_STATUS = 504;
const UNAUTHORIZED_STATUS = 401;
const CONFLICT_STATUS = 409;

const ajaxOverride = (settings: JQueryAjaxSettings) => {
  const url = settings.url;
  const publicFileKey = url.replace('/local-api/', '');
  Object.assign(settings, { url: `${CUSTOM_URL}/lookup/${publicFileKey}.json`, type: 'GET' });
};

const setupAjax = (actions: FinseraProviderValues['actions'], store: Store) => {
  if (actions?.ui?.updateUiVersion && process.env.NODE_ENV !== 'test') {
    $.ajaxSetup({
      beforeSend(xhr: any, settings: any) {
        xhr.url = settings.url;
        if (settings.type !== 'GET' && settings.json && _.isObject(settings.json)) {
          settings.contentType = 'application/json';
          settings.data = JSON.stringify(settings.json);

          xhr.setRequestHeader('content-type', 'application/json;charset=UTF-8');
        }
        if (MOCK_APIS.find((mock) => settings.url.match(`(/${mock}/)`))) ajaxOverride(settings);
      },
      complete(xhr) {
        const uiVersion = xhr.getResponseHeader('x-ui');
        if (uiVersion) {
          store?.dispatch(actions.ui.updateUiVersion(uiVersion));
        }
      },
    });
  }
};

const getApis = (oCall: (_func: any) => any) => {
  const localApis = deepmerge(apis, {
    admin,
    auth,
    combined,
    datasets_ls,
    dates,
    docs,
    feedback,
    assetMaster,
    jobLogs,
    jobs: { ...job, ...apis.jobs },
    loadData,
    localApi,
    poll,
    s3,
    uiApi,
    user_api,
    users: { ...user, ...apis.users },
    videos,
    orgPublish,
    embedWidgets,
    dslSignal,
  });
  // first level of the object are the actual api calls
  Object.keys(localApis).forEach((key) => {
    const currentApi = localApis[key as keyof typeof localApis] as ApiTypes<unknown>;
    Object.keys(currentApi).forEach((funcKey) => {
      currentApi[funcKey as keyof ApiTypes<unknown>] = oCall(currentApi[funcKey as keyof ApiTypes<unknown>]) as any;
    });
  });

  return localApis;
};

let currentApis: ReturnType<typeof getApis> | null = null;

const getContextAwareApis = () => {
  const store = ContextSingleton.getInstance().store;

  const authenticated = (store?.getState?.() as BaseStore)?.auth?.authenticated ?? false;
  const actions = ContextSingleton.getInstance()?.actions;

  setupAjax(actions, store);

  // We are using an extra method instead of using AjaxSetup because we need
  // to prevent outside catch on first 504 tries
  const overrideCall = (func: any) => {
    const sleep = (s: number) => new Promise((r) => setTimeout(r, s * 1000));
    if (_.isEmpty(actions)) return func;

    return async (...args: any) => {
      const makeCall = async (tries = 1): Promise<any> => {
        // Incremental retry
        if (tries > 1) {
          await sleep(Math.pow(2, tries - 1));
        }

        try {
          return await func(...args);
        } catch (e: any) {
          const status = e?.status;

          // if we had a connection error
          if (!status) {
            throw Error('Unable to connect to Finsera. Please check your connection.');
          }

          // If timeout and we have tries then call again
          if (status == TIMEOUT_STATUS && tries <= RETRY_TIMEOUT_LIMIT) {
            return await makeCall(tries + 1);
          }

          // If 500 error but TimeOut and we have tries then call again
          if (status !== TIMEOUT_STATUS && status >= 500 && tries <= RETRY_500_ERRORS) {
            return await makeCall(tries + 1);
          }

          // if unauthorized or conflict, we will send a top message and logout the user,
          // only if the user is authenticated and has a current user
          if (
            (status == UNAUTHORIZED_STATUS || status == CONFLICT_STATUS) &&
            authenticated &&
            store?.getState()?.auth?.currentUser
          ) {
            store?.dispatch(actions.auth.storeLogout());
            store?.dispatch(
              actions.apiErrors.addError(
                undefined,
                undefined,
                'Your session has expired. Please login again.',
                undefined
              )
            );
          }

          // Dispatch 500 errors on top of page
          if (status >= 500) {
            Sentry.withScope((scope) => {
              scope.setTag('manual', 'true');
              if (e instanceof Error) Sentry.captureException(e);
              else Sentry.captureException(new Error((e as any)?.message || JSON.stringify(e)));
            });

            store?.dispatch(actions.apiErrors.addError(e.url, status, e.statusText, e.responseJSON));
          }
          throw e;
        }
      };

      return await makeCall();
    };
  };

  currentApis = getApis(overrideCall);
  return currentApis;
};

export const refreshApis = () => {
  return getContextAwareApis();
};

// Create a proxy that always returns the current APIs
const apiProxy = new Proxy({} as ReturnType<typeof getApis>, {
  get(target, prop) {
    if (!currentApis) {
      currentApis = getContextAwareApis();
    }
    return currentApis[prop as keyof typeof currentApis];
  },
});

export default apiProxy;
