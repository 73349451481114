import { mui, React, ts, ui, useSelector } from '_core';

import { FullJob } from '../jobber-context';

type JobLabelProps = {
  job: FullJob;
  inResource: boolean;
  styles?: Record<string, mui.core.SxProps>;
  titleMessage?: string;
  showDataset?: boolean;
  hideIcon?: boolean;
};

const JobLabel: React.FC<JobLabelProps> = ({
  job,
  inResource,
  styles,
  titleMessage = '',
  showDataset = true,
  hideIcon = false,
}): React.ReactElement => {
  const theme = mui.styles.useTheme();

  const defaultStyles = {
    labelText: {
      fontWeight: 'inherit',
      flexGrow: 1,
      minWidth: '0',
      [theme.breakpoints.down('md')]: {
        maxWidth: '150px',
      },
    },
    icon: {
      color: '#7F8D93',
      '&:hover': {
        color: 'inherit',
      },
    },
    jobTitle: {
      color: '#627178',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      minWidth: '0',
      paddingTop: '3px',
      fontSize: '0.7rem',
    },
    resourceName: {
      color: '#7F8D93',
      textDecoration: 'none',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      minWidth: '0',
      fontSize: '0.6rem',
      paddingBottom: '8px',
      '& span': {
        paddingLeft: '4px',
      },
      '& span:first-child': {
        borderRight: `1px solid ${theme.palette.gray.main}`,
        padding: '0 4px 0 0',
      },
    },
    resourceIconContainer: {
      paddingTop: 2,
      '& svg': {
        fontSize: 12,
      },
    },
  };

  const resources = useSelector((state) => state.resources);
  const { resource } = job.analysis;
  const resourceType = job.item_type.replace('_analysis', '');

  const jobLabelStyles = styles ?? defaultStyles;

  let datasetUniverseName;
  let datasetFrequency;
  if (resourceType == 'signal') {
    datasetUniverseName = resources.universes.find((u) => u.id == job.analysis.signal_dataset.universe_id)?.name;
    datasetFrequency = job.analysis.signal_dataset.frequency;
  }

  const iconType = resource.type == 'ALPHA_MODEL' ? 'alpha_model' : resourceType;
  return (
    <mui.core.Box sx={jobLabelStyles.labelText}>
      <mui.core.Box display="flex">
        {!hideIcon && (
          <mui.core.Box pr={1} sx={jobLabelStyles.resourceIconContainer}>
            <ui.ResourceIcon resource={iconType as ts.enums.UI_RESOURCE_TYPE_ENUM} hasTooltip />
          </mui.core.Box>
        )}
        <mui.core.Typography
          variant="body1"
          style={inResource ? { color: theme.palette.secondary.main, fontWeight: '600' } : null}
          sx={jobLabelStyles.jobTitle}
        >
          {resource.name} {titleMessage}
        </mui.core.Typography>
      </mui.core.Box>
      {resourceType == 'signal' && showDataset && (
        <mui.core.Typography
          variant="body2"
          style={inResource ? { color: '#4574BA' } : null}
          sx={jobLabelStyles.resourceName}
        >
          <>
            <span>{datasetUniverseName}</span>
            <span>{datasetFrequency}</span>
          </>
        </mui.core.Typography>
      )}
    </mui.core.Box>
  );
};

export default JobLabel;
