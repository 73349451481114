import { _, ts } from '_core';

import { getBucketName } from 'views/report/widgets/common/utils/get-series-name';

import { getColumns } from './data-helpers';
import { ParamsArgs } from '../types';

export const _getSheetType = (sheet: string) => {
  if (sheet.includes('_AND_')) return 'both';
  if (sheet.includes('variables')) return 'variables';
  return 'buckets';
};

export const _getBuckets = (a: string, paramBuckets?: ts.types.widgets.common.BucketValue) => {
  const vs = JSON.parse(a) as string[];
  if (!paramBuckets) return vs;
  return vs.map((k, _idx) => {
    return getBucketName(k, paramBuckets.type, paramBuckets.value, {} as any);
  });
};

export const _flattenGroup = (
  data: Record<string, ts.types.widgets.TableData>,
  paramBuckets: ts.types.widgets.common.BucketValue,
  groupAttr: string,
  extraCols: string[] = []
): ts.types.widgets.TableData => {
  return Object.entries(data).map(([key, data]) => {
    const row = {
      [groupAttr]: key,
      ...Object.fromEntries(data.map((r) => [_getBuckets(r.v as string, paramBuckets).join(' - '), r.value])),
    };

    extraCols.forEach((c) => (row[c] = data[0][c]));
    return row;
  });
};

export const _createMatrix = (data: ts.types.widgets.TableData, paramBuckets: ts.types.widgets.common.BucketValue) => {
  const matrixData = data.map((row) => {
    const buckets = _getBuckets(row['v'] as string, paramBuckets);
    return {
      b1: buckets[0],
      b2: buckets[1],
      value: row['value'],
    };
  });

  return Object.entries(_.groupBy(matrixData, 'b1')).map(([bucket, data]) => {
    return {
      matrix_variable: bucket,
      ..._.reduce(
        data,
        (prev, r) => {
          return { ...prev, [r.b2]: r.value };
        },
        {}
      ),
    };
  });
};

export const _sortYearlyData = (data: ts.types.widgets.TableData): ts.types.widgets.TableData => {
  const numericYears = _.sortBy(
    data.filter((item) => /^\d+$/.test(item.year as string)),
    ['year']
  );
  // Get periods
  let enumKeys = data.filter((item) =>
    Object.values(ts.enums.HISTORY_ENUM_EXTENDED).find((h) => (item.year as string).includes(h))
  );

  // Sort periods
  enumKeys = _.sortBy(enumKeys, (item) =>
    Object.values(ts.enums.HISTORY_ENUM_EXTENDED).findIndex((h) => (item.year as string).includes(h))
  );

  return [...numericYears, ...enumKeys].filter(Boolean);
};

export const loadData = async (
  apiCall: (_p: ts.types.widgets.WidgetGetDataParams['data']) => Promise<ts.types.widgets.WidgetGetDataResponse>,
  sheet: string,
  dates: { start: string; end: string },
  paramBuckets: ts.types.widgets.common.BucketValue,
  detailsFile: string,
  summaryFile: string,
  callTypeArgs: ParamsArgs = {} as ParamsArgs
) => {
  const isSummary = sheet.includes('summary');
  const sheetType = _getSheetType(sheet);
  const buckets = callTypeArgs.comb.filter((x) => x != 'variables')[0];

  // Get the comb filter by sheet type
  let comb = callTypeArgs.comb;
  if (sheetType == 'variables') comb = ['variables'];
  if (sheetType == 'buckets') comb = [buckets];

  // Get the group by sheet type
  let groupBy = null as string;
  let extraColsGroupBy = [] as string[];

  if (sheetType != 'both' && isSummary) {
    groupBy = 'year';
    extraColsGroupBy = ['start_date', 'end_date'];
  }

  if (!isSummary) {
    extraColsGroupBy = ['total', 'start_date', 'end_date'];
    groupBy = 'date';
  }

  const detailsQuery = [
    '$and',
    ['comb', 'json=', comb],
    ['without_residuals', callTypeArgs.without_residuals ? 'isTrue' : 'isFalse'],
  ];
  if (sheetType == 'variables') detailsQuery.push(['view_type', 'equals', callTypeArgs.view_type]);

  const summaryQuery = [
    '$and',
    ['comb', 'json=', comb],
    ['without_residuals', callTypeArgs.without_residuals ? 'isTrue' : 'isFalse'],
    ['statistics_type', 'equals', callTypeArgs.statistics_type],
  ];

  if (sheetType == 'both') summaryQuery.push(['history', 'equals', callTypeArgs.history]);

  const columns = ['end_date', 'start_date', 'total', 'v', 'value'];

  if (!isSummary) columns.push('date');
  else columns.push('year');

  const [responseDetailsData, responseSummaryData] = await Promise.all([
    apiCall({
      file: detailsFile,
      query: detailsQuery,
      columns,
      rename: { build_date: 'date' },
    }),
    apiCall({
      file: summaryFile,
      query: summaryQuery,
      columns,
      rename: { build_date: 'date' },
    }),
  ]);

  // Process each response separately
  const processResponse = (responseData: ts.types.widgets.TableData) => {
    if (groupBy) {
      const groupedData = _.groupBy(responseData, groupBy) as unknown as Record<string, ts.types.widgets.TableData>;
      let response = _flattenGroup(
        groupedData,
        sheetType == 'variables' ? null : paramBuckets,
        groupBy,
        extraColsGroupBy
      );

      if (groupBy == 'year') response = _sortYearlyData(response);
      return response;
    }
    return _createMatrix(responseData as ts.types.widgets.TableData, paramBuckets);
  };

  const detailsProcessed = processResponse(responseDetailsData.data);
  const summaryProcessed = processResponse(responseSummaryData.data);

  return {
    details: {
      data: detailsProcessed,
      columns: getColumns(detailsProcessed, sheet, dates.start, dates.end, callTypeArgs.statistics_type),
    },
    summary: {
      data: summaryProcessed,
      columns: getColumns(summaryProcessed, sheet, dates.start, dates.end, callTypeArgs.statistics_type),
    },
  };
};
