import { createTheme } from '@mui/material/styles';

import baseConfigTheme from './base-config-theme';
// eslint-disable-next-line max-len
const outherTheme = createTheme({
  palette: {
    primary: {
      light: '#6189C4',
      main: '#4574BA',
      dark: '#3C5688',
    },
    primaryLight: {
      light: '#EFF3F9',
      main: '#DFE7F3',
      dark: '#D0E1FB',
    },
    dangerLight: {
      dark: '#E9BDC1',
      main: '#F4D3D6',
      light: '#F5EDEE',
    },
    warningLight: {
      light: '#FCF6E5',
      main: '#F8F2E1',
      dark: '#ECE1C2',
    },
    successLight: {
      dark: '#D9F1DD',
      main: '#ECF8EE',
      light: '#F5FBF7',
    },
    textLight: {
      main: '#627178',
      light: '#9DA2A4',
    },
    secondary: {
      light: '#363A54',
      main: '#202541',
      dark: '#1B1F37',
    },
    danger: {
      light: '#D44E5C',
      main: '#DA6974',
      dark: '#B0913B',
    },
    warning: {
      light: '#D4B04E',
      main: '#C9AD5E',
      dark: '#A48632',
    },
    info: {
      light: '#69C3DA',
      main: '#4EB9D4',
      dark: '#429DB4',
    },
    success: {
      light: '#61C471',
      main: '#45BA58',
      dark: '#3B9E4B',
    },
    text: {
      secondary: '#627178',
      disabled: '#7F8D93',
    },
    background: {
      default: '#F4F7FB',
      paper: '#FFF',
    },
    divider: '#E7EBEF',
    gray: {
      dark: '#D6DCDF',
      main: '#E7EBEF',
      light: '#EDF0F3',
    },
    grayLight: {
      main: '#f2f4fb',
      light: '#F9FAFB',
    },
    purple: {
      main: '#864ED4',
    },
    orange: {
      main: '#D4894E',
    },
    lime: {
      main: '#95BD40',
    },
    pink: {
      main: '#BD40A1',
    },
  },
  ...(baseConfigTheme as any),
});

export default outherTheme;
