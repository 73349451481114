/* eslint-disable no-unused-vars */
import { ts } from '_core';

export enum VOLATILITY_SCALE_BY_ENUM {
  TRAILING_20_DAY_RETURNS_VOLATILITY = 'TRAILING_20_DAY_RETURNS_VOLATILITY',
  TRAILING_60_DAY_RETURNS_VOLATILITY = 'TRAILING_60_DAY_RETURNS_VOLATILITY',
  TOTAL_RISK = 'TOTAL_RISK',
  SPECIFIC_RISK = 'SPECIFIC_RISK',
}

export type JobParams = {
  upper_bound: number;
  lower_bound: number;
  days_before_event: number;
  days_after_event: number;
  get_histories: boolean;
  histories?: ts.enums.HISTORY_ENUM_EXTENDED[];
  scale_by?: VOLATILITY_SCALE_BY_ENUM;
  with_volatility?: boolean;

  format_for_ui: true;
};

export type Params = JobParams;
export type DraftParams = Partial<Params>;
