const assetTypes = [
  { name: 'American Depositary Receipt', value: 'ADR' },
  { name: 'Global Depositary Receipt', value: 'GDR' },
  { name: 'Non-Voting Depositary Receipt', value: 'NVDR' },
  {
    name: 'Preferred',
    value: 'PREF',
    // eslint-disable-next-line max-len
    help: 'A type of stock that pays a fixed dividend regardless of corporate earnings, and which has priority over common stock in the payment of dividends. The main characteristics of preferred stocks is the fixed income stream and priority of payment at liquidation. ',
  },
  {
    name: 'Preferred Equity',
    value: 'PREFEQ',
    // eslint-disable-next-line max-len
    help: 'A security that has equity characteristics (i.e., ownership at liquidation, share in the profits in the company) rather than fixed redemption and trades as a common equity (i.e., is very liquid and its prices are quoted in a currency rather than percentage of par, which is a common characteristic of preferred stock). These securities are most commonly issued in Brazil, Canada, and many European markets (i.e., Russia, Germany, and Italy). Preferred equity has some priority rights over common stock. This category also includes Italian savings shares.',
  },
  { name: 'Share/Common/Ordinary', value: 'SHARE', compatibility: 'EQUITY' },
  { name: 'Exchange Traded Fund', value: 'ETF_ETF' },
  { name: 'Closed-ended Fund', value: 'MF_C' },
  { name: 'Open-ended Fund', value: 'MF_O' },
  { name: 'Unit', value: 'UNIT' },
];

export default assetTypes;
