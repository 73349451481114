import { hooks, mui, React, ts, ui } from '_core';

import { DraftParams } from './types';

type FormProps = {
  params: DraftParams;
  setParams: (_p: DraftParams) => void;
  context?: ts.types.signal.SignalAnalysisContext;
};

const Form: React.FC<FormProps> = ({ params, setParams, context }) => {
  const [returnHorizon, setReturnHorizon] = React.useState(params.return_horizon);
  const [selectedHistories, setSelectedHistories] = React.useState(params.histories || []);
  const [log, setLog] = React.useState(params.log || false);
  const [upper, setUpper] = React.useState(params.upper);
  const [lower, setLower] = React.useState(params.lower);
  const [extraN, setExtraN] = React.useState(params.step || params.n);

  const uiStyles = hooks.useUiStyles();

  React.useEffect(() => {
    let data;
    if (log) {
      data = {
        log: true,
        return_horizon: returnHorizon,
        histories: selectedHistories,
        lower,
        upper,
        n: extraN,
      };
    } else {
      data = {
        log: false,
        return_horizon: returnHorizon,
        histories: selectedHistories,
        lower,
        upper,
        step: extraN,
      };
    }

    setParams(data);
  }, [returnHorizon, selectedHistories, upper, lower, extraN, log]);

  return (
    <mui.core.Box sx={uiStyles.widgetEditMode}>
      <mui.core.Box ml={2} my={2}>
        <mui.core.FormControl>
          <mui.core.RadioGroup
            row
            value={log ? 'log' : 'linear'}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              const isLog = (event.target as HTMLInputElement).value == 'log';
              setLog(isLog);

              setLower(isLog ? 1 : 0);
              setUpper(null);
              setExtraN(null);
            }}
          >
            <mui.core.FormControlLabel value="linear" control={<mui.core.Radio />} label="Linear search" />
            <mui.core.FormControlLabel value="log" control={<mui.core.Radio />} label="Logarithmic search" />
          </mui.core.RadioGroup>
        </mui.core.FormControl>
      </mui.core.Box>

      <mui.core.Grid container spacing={2}>
        <mui.core.Grid item xs={4}>
          <ui.NumericField
            textFieldProps={{
              label: 'Lower bound (inclusive)',
              fullWidth: true,
              variant: 'outlined',
              size: 'small',
              required: true,
            }}
            value={params.lower}
            setValue={(v) => setLower(v)}
          />
        </mui.core.Grid>

        <mui.core.Grid item xs={4}>
          <ui.NumericField
            textFieldProps={{
              label: 'Upper bound (inclusive)',
              fullWidth: true,
              variant: 'outlined',
              size: 'small',
              required: true,
            }}
            value={params.upper}
            setValue={(v) => setUpper(v)}
          />
        </mui.core.Grid>

        <mui.core.Grid item xs={4}>
          <ui.NumericField
            textFieldProps={{
              label: log ? 'Number of values' : 'Step',
              fullWidth: true,
              variant: 'outlined',
              size: 'small',
              required: true,
            }}
            onlyPositive={log}
            onlyInteger={log}
            value={extraN}
            setValue={(v) => setExtraN(v)}
          />
        </mui.core.Grid>
      </mui.core.Grid>

      <mui.core.Box mt={4}>
        <ui.WidgetReturnHorizon<false>
          value={returnHorizon}
          setValue={(val) => setReturnHorizon(val)}
          context={context}
          noSignal
        />
      </mui.core.Box>
      <mui.core.Box mt={4}>
        <ui.WidgetHistories<true>
          multiple
          value={selectedHistories}
          setValue={setSelectedHistories}
          context={context}
        />
      </mui.core.Box>
    </mui.core.Box>
  );
};

export default Form;
