import { HISTORY_ENUM_EXTENDED } from '../enums';
import { moment } from '../libs';

/**
 * Guard function to ensure exhaustive handling of enum values
 * @param _value - Value that should be of type never
 * @throws Error if function is called with any value
 */
const exhaustiveGuard = (_value: never): never => {
  throw new Error(`ERROR! Reached forbidden guard function with unexpected value: ${JSON.stringify(_value)}`);
};

/**
 * Gets valid time window periods based on start and end dates
 * @param rawStartDate - Start date string
 * @param rawEndDate - End date string
 * @returns Array of valid HISTORY_ENUM_EXTENDED values for the date range
 */
export const getWindowPeriods = (rawStartDate: string, rawEndDate: string): HISTORY_ENUM_EXTENDED[] => {
  const marginOfError = 0.05;

  const startDate = moment(rawStartDate);
  const endDate = moment(rawEndDate);

  const isValid = (freq: string, val: number) => {
    return endDate.diff(startDate, freq as any, true) >= val - val * marginOfError;
  };

  return Object.values(HISTORY_ENUM_EXTENDED).filter((v) => {
    switch (v) {
      case HISTORY_ENUM_EXTENDED.FIVE:
        return isValid('years', 5);
      case HISTORY_ENUM_EXTENDED.FULL:
        return true;
      case HISTORY_ENUM_EXTENDED.FIVE_TRADING_DAYS:
        return isValid('days', 5);
      case HISTORY_ENUM_EXTENDED.ONE:
        return isValid('years', 1);
      case HISTORY_ENUM_EXTENDED.ONE_MONTH:
        return isValid('months', 1);
      case HISTORY_ENUM_EXTENDED.SIX_MONTHS:
        return isValid('months', 6);
      case HISTORY_ENUM_EXTENDED.THREE:
        return isValid('years', 3);
      case HISTORY_ENUM_EXTENDED.THREE_MONTHS:
        return isValid('months', 3);
      case HISTORY_ENUM_EXTENDED.YTD:
        return true;
      case HISTORY_ENUM_EXTENDED.MTD:
        return true;
      case HISTORY_ENUM_EXTENDED.QTD:
        return true;
      default:
        return exhaustiveGuard(v);
    }
  });
};

/**
 * Determines if a time period should be annualized based on start/end dates and history type
 * @param rawStartDate - Start date string
 * @param rawEndDate - End date string
 * @param hist - History enum value to check
 * @returns True if the period should be annualized, false otherwise
 */
export const isAnnualized = (rawStartDate: string, rawEndDate: string, hist: HISTORY_ENUM_EXTENDED): boolean => {
  const startDate = moment(rawStartDate);
  const endDate = moment(rawEndDate);

  const moreThanAYear = endDate.diff(startDate, 'years') >= 1;

  switch (hist) {
    case HISTORY_ENUM_EXTENDED.ONE:
      return moreThanAYear;
    case HISTORY_ENUM_EXTENDED.THREE:
      return moreThanAYear;
    case HISTORY_ENUM_EXTENDED.FIVE:
      return moreThanAYear;
    case HISTORY_ENUM_EXTENDED.FULL:
      return moreThanAYear;
    case HISTORY_ENUM_EXTENDED.ONE_MONTH:
      return false;
    case HISTORY_ENUM_EXTENDED.THREE_MONTHS:
      return false;
    case HISTORY_ENUM_EXTENDED.SIX_MONTHS:
      return false;
    case HISTORY_ENUM_EXTENDED.FIVE_TRADING_DAYS:
      return false;
    case HISTORY_ENUM_EXTENDED.YTD:
      return false;
    case HISTORY_ENUM_EXTENDED.MTD:
      return false;
    case HISTORY_ENUM_EXTENDED.QTD:
      return false;
    default:
      return exhaustiveGuard(hist);
  }
};
