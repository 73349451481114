import { _, hooks, mui, React, ui } from '_core';

import { DraftParams } from './types';

type FormProps = {
  params: DraftParams;
  setParams: (_p: DraftParams) => void;
};

const Form: React.FC<FormProps> = ({ params, setParams }) => {
  const uiStyles = hooks.useUiStyles();

  const [category, setCategory] = React.useState(params.category || 'rbics_l1_id');
  const [withDropValue, setWithDropValue] = React.useState(!_.isNil(params.drop_values_under));
  const [dropValuesUnder, setDropValuesUnder] = React.useState(params.drop_values_under);

  React.useEffect(() => {
    if (!withDropValue && _.isNil(dropValuesUnder)) setDropValuesUnder(1e-10);
  }, [withDropValue]);

  React.useEffect(() => {
    const localParams = {
      ...params,
      category,
      drop_values_under: withDropValue ? dropValuesUnder : undefined,
    };

    if (localParams.category === 'rbics_l4_id') {
      localParams.parent = 'levelThree';
      localParams.children = 'levelFour';
      localParams.chartType = 'top';
    } else if (localParams.category === 'rbics_l3_id') {
      localParams.parent = 'levelTwo';
      localParams.children = 'levelThree';
      localParams.chartType = 'top';
    } else if (localParams.category === 'rbics_l2_id') {
      localParams.parent = null;
      localParams.children = null;
      localParams.chartType = 'rbics';
    }

    setParams(localParams);
  }, [category, dropValuesUnder, withDropValue]);

  return (
    <mui.core.Box sx={uiStyles.widgetEditMode}>
      <mui.core.Box mt={4}>
        <mui.core.TextField
          select
          label="Industry"
          variant="outlined"
          size="small"
          value={category}
          onChange={(e) => setCategory(e.target.value as typeof category)}
          fullWidth
        >
          <mui.core.MenuItem key={'rbics_l1_id'} value={'rbics_l1_id'}>
            Sector
          </mui.core.MenuItem>
          <mui.core.MenuItem key={'rbics_l2_id'} value={'rbics_l2_id'}>
            Sub Sector
          </mui.core.MenuItem>
          <mui.core.MenuItem key={'rbics_l3_id'} value={'rbics_l3_id'}>
            Industry
          </mui.core.MenuItem>
          <mui.core.MenuItem key={'rbics_l4_id'} value={'rbics_l4_id'}>
            Sub Industry
          </mui.core.MenuItem>
        </mui.core.TextField>
      </mui.core.Box>

      <mui.core.Box mt={1} ml={1}>
        <mui.core.Box mb={2}>
          <mui.core.FormControlLabel
            control={
              <mui.core.Switch size="small" checked={withDropValue} onChange={() => setWithDropValue(!withDropValue)} />
            }
            label={'Drop values under threshold'}
          />
        </mui.core.Box>

        {withDropValue && (
          <ui.NumericField
            textFieldProps={{
              label: 'Drop Zeroes in Signal Portfolio',
              fullWidth: true,
              variant: 'outlined',
              size: 'small',
            }}
            showExponentialLabel
            value={Number(dropValuesUnder)}
            setValue={(v) => setDropValuesUnder(v)}
          />
        )}
      </mui.core.Box>
    </mui.core.Box>
  );
};

export default Form;
