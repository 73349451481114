import { React } from '_core';

import FixedCost from './fixed-costs';
import { FixedCostContextProvider } from './fixed-costs-context';

const FixedCosts: React.FC = (): React.ReactElement => {
  return (
    <FixedCostContextProvider>
      <FixedCost />
    </FixedCostContextProvider>
  );
};

export default FixedCosts;
