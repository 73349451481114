import { _, api, config, helpers, ts } from '_core';

type TimeSeriesApiResponse = {
  data: ts.types.timeSeries.TimeSeries;
};

export const createTimeSeries = async (
  newData: ts.types.timeSeries.TimeSeriesDraft,
  callback: (_data: ts.types.timeSeries.TimeSeries) => void,
  errorCallback: (_error: string) => void
) => {
  try {
    const resp: TimeSeriesApiResponse = await api.time_series.create(newData);
    callback(resp.data);
  } catch (error) {
    errorCallback(`Error creating time series - ${helpers.parseApiError(error as ts.types.common.ApiError)}`);
  }
};

export const updateTimeSeries = async (
  id: number,
  newData: ts.types.timeSeries.TimeSeriesDraft,
  callback: (_data: ts.types.timeSeries.TimeSeries) => void,
  errorCallback: (_error: string) => void
) => {
  try {
    const resp: TimeSeriesApiResponse = await api.time_series.update(id, { ...newData });
    callback(resp.data);
  } catch (error) {
    errorCallback(`Error updating time series - ${helpers.parseApiError(error as ts.types.common.ApiError)}`);
  }
};

export const updateDefinition = async (
  id: number,
  definition: ts.types.timeSeries.DefinitionDraft,
  callback: (_data: ts.types.timeSeries.TimeSeries) => void,
  errorCallback: (_error: string) => void
) => {
  try {
    const updateData = { definition, is_valid: true } as ts.types.timeSeries.TimeSeriesDraft;
    const resp = await api.time_series.update(id, updateData, true);
    callback(resp.data);
  } catch (error) {
    errorCallback(`Error updating the definition - ${helpers.parseApiError(error as ts.types.common.ApiError)}`);
  }
};

const createAnalyses = async (timeSeriesId: number, names: ts.enums.TIME_SERIES_ANALYSIS_TYPE_ENUM[]) => {
  const analyses = [];
  for (const name of names) {
    let analysisDefinition = { widgets: [] } as ts.types.analysis.AnalysisDefinition;

    if (name == ts.enums.TIME_SERIES_ANALYSIS_TYPE_ENUM.PREVIEW) {
      // default preview value
      analysisDefinition = {
        mode: ts.enums.ANALYSIS_MODE_ENUM.FULLSCREEN,
        widgets: [
          {
            id: helpers.gibberishGenerator.stringGenerator(11),
            key: 'TimeSeriesPreview',
            params: {},
          },
        ],
      };
    }

    // create analysis
    const response = await api.time_series_analyses.create({
      report_definition: analysisDefinition,
      time_series_id: timeSeriesId,
      name,
    });
    analyses.push(response.data.name);
  }
  return analyses;
};

// Load analyses, and if we don't have them, create them
export const loadAnalyses = async (
  timeSeries: ts.types.timeSeries.TimeSeries,
  setAnalysesNames: (_data: ts.enums.TIME_SERIES_ANALYSIS_TYPE_ENUM[]) => void,
  errorCallback: (_error: string) => void
) => {
  const analyses = Object.values(ts.enums.TIME_SERIES_ANALYSIS_TYPE_ENUM);
  try {
    const resp = await api.time_series_analyses.search({
      ignore_user_scope: timeSeries.shared,
      query: ['$and', ['time_series_id', '=', timeSeries.id]],
      include: 'id,name',
    });

    let localNames = (resp.data || []).map(
      (a: ts.types.analysis.ResourceAnalysis) => a.name
    ) as ts.enums.TIME_SERIES_ANALYSIS_TYPE_ENUM[];

    const toCreate = _.without(analyses, ...localNames);
    if (!_.isEmpty(toCreate)) {
      const newNames = (await createAnalyses(timeSeries.id, toCreate)) as ts.enums.TIME_SERIES_ANALYSIS_TYPE_ENUM[];
      localNames = localNames.concat(newNames);
    }

    setAnalysesNames(localNames);
  } catch (error) {
    errorCallback(`Error loading time series analyses - ${helpers.parseApiError(error as ts.types.common.ApiError)}`);
  }
};

export const keepCurrent = async (
  timeSeries: ts.types.timeSeries.TimeSeriesDraft,
  callback: (_data: ts.types.timeSeries.TimeSeries) => void,
  errorCallback: (_error: string) => void
) => {
  try {
    if (timeSeries.shared) return;
    const resp = await api.dates.keep_current({
      resource_id: timeSeries.id,
      resource_type: 'time_series',
      max_date: config.features.end_date,
    });
    callback(resp.resource);
  } catch (error) {
    errorCallback(`Error updating time series dates - ${helpers.parseApiError(error as ts.types.common.ApiError)}`);
  }
};

export const updateUiMetadata = async (
  timeSeries: ts.types.timeSeries.TimeSeries,
  uiMetaData: ts.types.timeSeries.UiMetaData,
  callback: (_data: ts.types.timeSeries.TimeSeries) => void,
  errorCallback: (_error: string) => void
) => {
  try {
    if (timeSeries.shared) return;
    const updateData = {
      ui_metadata: uiMetaData,
      skip_signature_check: true,
    } as ts.types.timeSeries.TimeSeriesDraft;
    const resp = await api.time_series.update(timeSeries.id, updateData);
    callback(resp.data);
  } catch (error) {
    errorCallback(`Error updating the resource metadata - ${helpers.parseApiError(error as ts.types.common.ApiError)}`);
  }
};
