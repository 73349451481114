/* eslint-disable no-unused-vars */
import { ts } from '_core';

export type JobParams = {
  return_horizons: ts.types.returnHorizon.ReturnHorizon[];
  daily_reporting: boolean;
  set_params: {
    id: 'report'; // Fixed id to get the file from payload on index
    file: 'sp_returns';
    file_params: {
      return_horizons: ts.types.returnHorizon.ReturnHorizon[];
      agg_frequency: 'DAILY' | undefined;
    };
    aggregation_params: Record<string, string>;
    aggregation: 'fin-gen-sp-returns';
  }[];
};

export enum INTERACTION_PARAMS {
  TABLE_PARAMS = 'table_params',
}

export type InteractionParams = {
  [INTERACTION_PARAMS.TABLE_PARAMS]: ts.types.components.dataGrid.TableParamsSheet;
};

export type Params = JobParams & InteractionParams & { fullWidth: boolean };
export type DraftParams = Partial<Params>;
