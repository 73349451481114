import { _, hooks, mui, React, ts, ui } from '_core';

import { getConstraintType } from '../../optimizer-utils/constraint-type';
import Bounds from '../bounds';
import SharedConstraintFields from '../shared-fields';
import Target from '../target';

type SignalExposureProps = {
  id: string;
  params: ts.types.optimizer.SignalExposureDraft;
  setParams: (_params: ts.types.optimizer.SignalExposureDraft) => void;
  readOnly: boolean;
  validationDef: ts.types.common.ValidationErrors;
  optimizerType: 'basket' | 'backtest';
};

const SignalExposureConstraint: React.FC<SignalExposureProps> = ({
  id,
  params,
  setParams,
  readOnly,
  optimizerType,
  validationDef,
}): React.ReactElement => {
  const [showMore, setShowMore] = React.useState(false);
  const [constraint, setConstraint] = React.useState(params);

  React.useEffect(() => {
    if (optimizerType == 'basket' && params.benchmark_relative == null) {
      setConstraint({ ...constraint, benchmark_relative: true });
    }
  }, []);

  hooks.useEffectWithoutFirst(() => {
    setParams(constraint);
  }, [constraint]);

  const form = () => {
    if (getConstraintType(constraint) == 'BOUNDS') {
      if (showMore) {
        return (
          <Bounds
            id={id}
            params={constraint}
            setParams={setConstraint}
            readOnly={readOnly}
            validationDef={validationDef}
          >
            <SharedConstraintFields
              id={id}
              params={constraint}
              setParams={setConstraint}
              readOnly={readOnly}
              validationDef={validationDef}
            />
          </Bounds>
        );
      }

      return (
        <Bounds
          id={id}
          params={constraint}
          setParams={setConstraint}
          readOnly={readOnly}
          validationDef={validationDef}
        />
      );
    }

    if (showMore) {
      return (
        <Target params={constraint} setParams={setConstraint} readOnly={readOnly}>
          <SharedConstraintFields
            id={id}
            params={constraint}
            setParams={setConstraint}
            readOnly={readOnly}
            validationDef={validationDef}
          />
        </Target>
      );
    }

    return <Target params={constraint} setParams={setConstraint} readOnly={readOnly} />;
  };

  const pipeline_ids = constraint.pipeline_ids || (constraint.pipeline_id != null ? [constraint.pipeline_id] : []);

  return (
    <>
      {/* {!readOnly && (
        <ui.Button
          sx={uiStyles.fullCardButton}
          color="primary"
          size="small"
          fullWidth
          onClick={() => setConstraint({ 
            type: constraint.type == 'BOUNDS' ? 'TARGET' : 'BOUNDS',
            target_penalty: constraint.type == 'BOUNDS' ? 1.0 : undefined, 
          })}
        >
          { constraint.type == 'BOUNDS' ? 'Change to target mode' : 'Change to bounds mode' }
        </ui.Button>
      )} */}

      <mui.core.Box p={3}>
        <mui.core.FormControlLabel
          control={
            <mui.core.Switch
              size="small"
              checked={constraint.benchmark_relative}
              onChange={() =>
                setConstraint({
                  ...constraint,
                  benchmark_relative: !constraint.benchmark_relative,
                })
              }
              disabled={readOnly}
            />
          }
          label={optimizerType == 'backtest' ? 'Benchmark Relative' : 'Relative to Target Weights'}
        />
      </mui.core.Box>
      <mui.core.Box px={3}>
        <mui.core.Grid container spacing={4}>
          <mui.core.Grid item xs={12} md={6}>
            <ui.ResourceAutocomplete<ts.types.signal.SignalExpanded>
              value={constraint.signal_id}
              setValue={(id) => setConstraint({ ...constraint, signal_id: id })}
              type="signal_based"
              disabled={readOnly}
              sortBy={['type', 'ws', (el: any[]) => el.length]}
              inputProps={{
                fullWidth: true,
                label: 'Signal / Alpha Model',
                variant: 'outlined',
                size: 'small',
                required: true,
                disabled: readOnly,
                error: validationDef?.fields?.includes(`signal_${id}`),
              }}
            />
          </mui.core.Grid>
          <mui.core.Grid item xs={12} md={6}>
            <ui.ResourceAutocomplete<ts.types.pipeline.PipelineExpanded, true, false>
              value={_.isArray(pipeline_ids) ? pipeline_ids : [pipeline_ids]}
              // Setting pipeline_id as null for legacy issues
              setValue={(ids) => setConstraint({ ...constraint, pipeline_id: null, pipeline_ids: ids })}
              type={ts.enums.RESOURCES_TYPES_ENUM.PIPELINE}
              disabled={readOnly}
              autocompleteProps={{
                size: 'small',
                multiple: true,
              }}
              inputProps={{
                fullWidth: true,
                label: 'Pipelines',
                variant: 'outlined',
                size: 'small',
              }}
            />
          </mui.core.Grid>
        </mui.core.Grid>
      </mui.core.Box>

      <mui.core.Box p={3}>
        {form()}
        <mui.core.Button
          onClick={() => {
            setShowMore(!showMore);
          }}
          color="primary"
          style={{ marginTop: '0.5rem' }}
        >
          {showMore ? (
            <>
              <mui.icons.Remove style={{ fontSize: '14px', marginRight: '0.5rem' }} />
              Show Less
            </>
          ) : (
            <>
              <mui.icons.Add style={{ fontSize: '14px', marginRight: '0.5rem' }} />
              Show More
            </>
          )}
        </mui.core.Button>
      </mui.core.Box>
    </>
  );
};

export default SignalExposureConstraint;
