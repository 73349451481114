/* eslint-disable no-unused-vars */

export enum INDUSTRY_SELECTION_DETAILS_INTERACTION_PARAMS {
  TEXT = 'text',
}

export type IndustrySelectionDetailsInteractionParams = {
  text: string;
};

export type IndustrySelectionDetailsParams = IndustrySelectionDetailsInteractionParams;
export type IndustrySelectionDetailsDraftParams = Partial<IndustrySelectionDetailsParams>;
