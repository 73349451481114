import { am4charts, am4core, ts } from '_core';

type createTreeMapChartProps = {
  id: string;
  data: ts.types.widgets.TreeMapData;
  fullScreen: boolean;

  numberFormat?: string;
  tooltipSuffix?: string;
  homeText?: string;
  selectedBuckets?: string[];
};

const EMPTY_ARRAY: any[] = [];

const createTreeMapChart = ({
  id,
  data,
  fullScreen,
  numberFormat = '#.##',
  tooltipSuffix = '',
  homeText = '',
  selectedBuckets = EMPTY_ARRAY,
}: createTreeMapChartProps) => {
  const chart = am4core.create(`chart-${id}-${fullScreen ? 'fs' : 'sw'}`, am4charts.TreeMap);

  chart.numberFormatter.numberFormat = numberFormat;

  chart.hiddenState.properties.opacity = 0;

  chart.padding(0, 0, 0, 0);
  chart.data = data;
  chart.colors.step = 0;
  // only one level visible initially
  chart.maxLevels = 1;
  // define data fields
  chart.dataFields.value = 'value';
  chart.dataFields.color = 'color';
  chart.dataFields.name = 'name';
  chart.dataFields.children = 'children';
  chart.homeText = homeText;
  chart.logo.disabled = true;

  // enable navigation
  chart.navigationBar = new am4charts.NavigationBar();
  chart.navigationBar.links.template.fill = am4core.color('#202541');
  chart.navigationBar.activeLink.fill = am4core.color('#202541');

  for (let i = 0; i < selectedBuckets.length + 2; i += 1) {
    const levelSeriesTemplate = chart.seriesTemplates.create(i.toString());
    levelSeriesTemplate.columns.template.tooltipText = `{name}: {returns}${tooltipSuffix}`;
    levelSeriesTemplate.strokeWidth = 2;
    levelSeriesTemplate.columns.template.column.cornerRadius(10, 10, 10, 10);
    levelSeriesTemplate.columns.template.tooltipX = am4core.percent(1);
    levelSeriesTemplate.columns.template.tooltipY = am4core.percent(1);

    const bullet = levelSeriesTemplate.bullets.push(new am4charts.LabelBullet());
    bullet.locationX = 0.5;
    bullet.locationY = 0.5;
    bullet.label.text = '{name}';
    if (selectedBuckets[i]?.includes('QUANTILES')) {
      bullet.label.text = 'Q{name.formatNumber("###")}';
      levelSeriesTemplate.columns.template.tooltipText = `Q{name.formatNumber("###")}: {returns}${tooltipSuffix}`;
    }
    bullet.label.fill = am4core.color('#fff');
    bullet.label.fontSize = '0.8rem';
    bullet.label.fontWeight = 'bold';
    levelSeriesTemplate.bulletsContainer.hiddenState.properties.opacity = 0;
    levelSeriesTemplate.bulletsContainer.hiddenState.properties.visible = false;
    const hoverState = levelSeriesTemplate.columns.template.states.create('hover');

    bullet.label.adapter.add('fillOpacity', function (_, target) {
      if (!target.dataItem) return 0;
      const dataItem = target.dataItem;

      if ((dataItem as any)?.itemHeight <= 15 || (dataItem as any)?.itemWidth <= 15) return 0;

      return 1;
    });

    hoverState.adapter.add('fill', (fill) => am4core.color(am4core.colors.brighten((fill as any).rgb, -0.2)));
  }

  return chart;
};

export default createTreeMapChart;
