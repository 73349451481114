import { hooks, mui, React, ts, ui } from '_core';

import { DraftParams } from './types';

type FormProps = {
  params: DraftParams;
  setParams: (_p: DraftParams) => void;
};

const Form: React.FC<FormProps> = ({ params, setParams }) => {
  const [upperBound, setUpperBound] = React.useState(params.upper_bound);
  const [lowerBound, setLowerBound] = React.useState(params.lower_bound);
  const [daysBeforeEvent, setDaysBeforeEvent] = React.useState(params.days_before_event);
  const [daysAfterEvent, setDaysAfterEvent] = React.useState(params.days_after_event);
  const [buckets, setBuckets] = React.useState(
    params.buckets || ({ type: 'LONG_SHORT' } as ts.types.widgets.common.BucketValue)
  );
  const uiStyles = hooks.useUiStyles();

  React.useEffect(() => {
    const data = {
      ...params,
      upper_bound: upperBound,
      lower_bound: lowerBound,
      days_before_event: daysBeforeEvent,
      days_after_event: daysAfterEvent,
      buckets,
    };
    setParams(data);
  }, [upperBound, lowerBound, daysBeforeEvent, daysAfterEvent, buckets]);

  return (
    <mui.core.Box sx={uiStyles.widgetEditMode}>
      <mui.core.Typography
        variant="body2"
        fontWeight="fontWeightMedium"
        sx={{
          marginBottom: '10px',
          marginTop: '5px',
        }}
      >
        Event Selection Bounds (at least one is needed)
      </mui.core.Typography>
      <mui.core.Grid container spacing={2}>
        <mui.core.Grid item xs={6}>
          <ui.NumericField
            textFieldProps={{
              label: 'Lower Bound',
              fullWidth: true,
              variant: 'outlined',
              size: 'small',
              required: true,
            }}
            value={lowerBound}
            setValue={(v) => setLowerBound(v)}
          />
        </mui.core.Grid>

        <mui.core.Grid item xs={6}>
          <ui.NumericField
            textFieldProps={{
              label: 'Upper Bound',
              fullWidth: true,
              variant: 'outlined',
              size: 'small',
              required: true,
            }}
            value={upperBound}
            setValue={(v) => setUpperBound(v)}
          />
        </mui.core.Grid>
      </mui.core.Grid>

      <mui.core.Typography
        variant="body2"
        fontWeight="fontWeightMedium"
        sx={{
          marginBottom: '10px',
          marginTop: '5px',
        }}
      >
        Event Window
      </mui.core.Typography>
      <mui.core.Grid container spacing={2}>
        <mui.core.Grid item xs={6}>
          <ui.NumericField
            textFieldProps={{
              label: 'Days Before Event (1 - 21)',
              fullWidth: true,
              variant: 'outlined',
              size: 'small',
              required: 'true',
            }}
            value={daysBeforeEvent}
            setValue={(v) => setDaysBeforeEvent(v)}
          />
        </mui.core.Grid>

        <mui.core.Grid item xs={6}>
          <ui.NumericField
            textFieldProps={{
              label: 'Days After Event (0 - 63)',
              fullWidth: true,
              variant: 'outlined',
              size: 'small',
            }}
            value={daysAfterEvent}
            setValue={(v) => setDaysAfterEvent(v)}
          />
        </mui.core.Grid>
      </mui.core.Grid>
      <mui.core.Typography
        variant="body2"
        fontWeight="fontWeightMedium"
        sx={{
          marginBottom: '10px',
          marginTop: '5px',
        }}
      >
        Buckets
      </mui.core.Typography>
      <mui.core.Box mt={4}>
        <ui.Buckets value={buckets} setValue={setBuckets} />
      </mui.core.Box>
    </mui.core.Box>
  );
};

export default Form;
