import { BaseStore } from '../../../types/base-store';
import { Resource } from '../../../types/common';
import { StrategyExpanded } from '../../../types/strategy';
import { Strategy } from '../../../types/strategy';

const getResourceWords = (id: number, resources: Resource[]) => {
  const resource = resources.find((r) => r.id == id);
  if (resources) return [resource?.name, resource?.handle];
  return [];
};

const expandStrategy = (
  strategy: Strategy & { resourceType: string },
  resources: BaseStore['resources']
): StrategyExpanded => {
  const expanded = [];

  // Using any for the strategy definition as we have three types of def
  // in which 2 has this attributes
  const uniId = (strategy.definition as any)?.universe_id;
  const alphaId = (strategy.definition as any)?.alpha_id;
  const pipelineIds = ((strategy.definition as any)?.pipeline_ids as number[]) || [];

  const riskModelId = strategy.definition?.risk_model_id;

  if (uniId) expanded.push(getResourceWords(uniId, resources.universes));
  if (alphaId) expanded.push(getResourceWords(alphaId, resources.signals));
  if (riskModelId) expanded.push(getResourceWords(riskModelId, resources.risk_models));
  pipelineIds.forEach((pid) => {
    expanded.push(getResourceWords(pid, resources.pipelines));
  });

  return {
    id: strategy.id,
    name: strategy.name,
    handle: strategy.handle,
    resourceType: strategy.resourceType,
    created_by_id: strategy.created_by_id,
    label_ids: strategy.label_ids,
    definition: expanded.flat(),
    is_deprecated: strategy.is_deprecated,
    revoked: false,
    is_valid: strategy.is_valid,
    type: strategy.type,
    created_at: strategy.created_at,
    organization_id: strategy.organization_id,
  } as StrategyExpanded;
};

export default expandStrategy;
