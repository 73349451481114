import { _,helpers, ts } from '_core';

export const getDynamicName = (key: string) => {
  if (key.startsWith('_handle_')) return key.replaceAll('_handle_', '');
  return _.startCase(key);
};

export default {
  [ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.MAIN]: [
    {
      key: 'index',
      name: 'Index',
      formatter: helpers.tableFormatters.formatTo('inferred'),
      filter: 'date',
      members: (key) => ['build_date', 'data_date'].includes(key),
      cleanNameFormatter: (key) => getDynamicName(key),
      nameFormatter: (key) => getDynamicName(key),
      isIndex: true,
    },
    {
      key: 'value',
      name: 'Value',
      filter: 'inferred',
      formatter: helpers.tableFormatters.formatTo('inferred'),
    },
    {
      key: 'dynamic',
      name: 'Time Series Specific',
      filter: 'inferred',
      formatter: helpers.tableFormatters.formatTo('inferred'),
      members: () => true,
      cleanNameFormatter: (key) => getDynamicName(key),
      nameFormatter: (key) => getDynamicName(key),
    },
  ] as ts.types.components.dataGrid.ColumnsData,
};
