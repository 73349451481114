import { _, helpers, hooks, mui, React, ts, ui, useSelector } from '_core';

import Tabular from 'views/report/tables/tabular';

import { prepareData } from './helpers';
import { Params } from './types';

const Sheet = ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.BACKTEST_PORTFOLIO_RISK_FACTOR_CONTRIBUTION;

type ChartProps = {
  id: string;
  loadWidgetData: (_payload: ts.types.widgets.WidgetGetDataParams) => Promise<ts.types.widgets.WidgetGetDataResponse>;
  file: string;
  params: Params;
  setParams: (_p: Params) => void;
  asOfDate: string;
  title: string;
  subtitle?: string;
  setExternalEmbed: (_json: ts.types.analysis.EmbedChartData) => void;
};

const Chart: React.FC<ChartProps> = ({
  id,
  loadWidgetData,
  file,
  params,
  setParams,
  asOfDate,
  title,
  subtitle,
  setExternalEmbed,
}) => {
  const resources = useSelector((state) => state.resources);

  const [data, setData] = React.useState<ts.types.widgets.TableData>(null);
  const [error, setError] = React.useState<ts.types.common.Alert>(null);

  const [tableParams, setTableParams] = React.useState<ts.types.components.dataGrid.TableParamsSheet>(
    params.table_params
  );

  hooks.useEffectWithoutFirst(() => {
    setParams({
      ...params,
      table_params: { ...tableParams },
    });
  }, [tableParams]);

  const loadData = async () => {
    try {
      const response = await loadWidgetData({
        data: { file },
      });
      if (response) {
        setData(prepareData(response.data, resources));
      }
    } catch (err) {
      setError({
        severity: ts.enums.ALERT_SEVERITY_ENUM.ERROR,
        message: JSON.stringify(err),
      });
    }
  };

  React.useEffect(() => {
    if (!_.isEmpty(file)) loadData();
    return () => setData(null);
  }, [file]);

  const tableHeader = () => (
    <mui.core.Box display="flex">
      <mui.core.Box display="flex" flexGrow={1}>
        <mui.core.Box mt={-1}>
          <ui.InfoRow title="As of">{asOfDate}</ui.InfoRow>
        </mui.core.Box>
      </mui.core.Box>
    </mui.core.Box>
  );

  const getCustomColumns = React.useCallback(
    (colkeys: string[]) =>
      colkeys.map((c) => {
        const signal = resources.signals.find((s) => s.handle == c);
        const signalLabel = params.analysis_pipelined_signals.find((el) => el.signal_id == signal?.id)?.signal_label;
        const col = !!signalLabel ? signalLabel : c;

        return {
          key: c,
          cleanName: _.startCase(col),
          name: col,
          isIndex: c == 'Basket',
          frozen: c == 'Basket',
          formatter:
            c != 'Basket'
              ? helpers.tableFormatters.formatTo('integer', {
                  align: 'right',
                })
              : helpers.tableFormatters.formatTo('string', { align: 'left' }),
        };
      }),
    []
  );

  const getHeatMappedCols = React.useCallback(
    (colkeys: string[]) => {
      return colkeys.filter((c) => c !== 'Basket');
    },
    [params]
  );

  const setTableEmbed = (data: Record<string, ts.types.analysis.SyncTableEmbedMetadata>) => {
    const args = Object.fromEntries(
      Object.entries(data ?? {}).map(([sheet, sheetData]) => {
        return [
          sheet,
          {
            headerEls: [{ key: 'As of', value: asOfDate }],
            formatter: {
              number: {
                to: 'integer',
                align: 'right',
              },
              string: {
                to: 'string',
                align: 'left',
              },
            },
            ...sheetData,
          },
        ];
      })
    ) as ts.types.analysis.SyncTableEmbedData;

    setExternalEmbed({
      id,
      widgetTitle: title,
      widgetSubTitle: subtitle,
      chart_type: 'sync-table',
      args: helpers.embed.argsToStringifiable(args),
    });
  };

  return (
    <mui.core.Box display="flex" flexDirection="column" style={{ height: '100%' }}>
      <Tabular<ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.BACKTEST_PORTFOLIO_RISK_FACTOR_CONTRIBUTION>
        data={
          data
            ? {
                [ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.BACKTEST_PORTFOLIO_RISK_FACTOR_CONTRIBUTION]: data,
              }
            : null
        }
        getColumns={{
          [ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.BACKTEST_PORTFOLIO_RISK_FACTOR_CONTRIBUTION]: getCustomColumns,
        }}
        getHeatMappedCols={{
          [ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.BACKTEST_PORTFOLIO_RISK_FACTOR_CONTRIBUTION]: getHeatMappedCols,
        }}
        fullScreen
        customToolbarBySheet={{ [Sheet]: tableHeader }}
        customError={error}
        tableParams={tableParams}
        setTableParams={{
          [ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.BACKTEST_PORTFOLIO_RISK_FACTOR_CONTRIBUTION]: (v) =>
            setTableParams((t) => ({
              ...t,
              [ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.BACKTEST_PORTFOLIO_RISK_FACTOR_CONTRIBUTION]: v,
            })),
        }}
        heatMapColors={{
          [ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.BACKTEST_PORTFOLIO_RISK_FACTOR_CONTRIBUTION]: {
            startColor: [120, 214, 145],
            endColor: [219, 110, 110],
            middleColor: [255, 255, 255],
            minValue: 0,
            maxValue: data?.length,
          },
        }}
        setExternalEmbed={setTableEmbed}
        triggerWidthChange={params.fullWidth}
      />
    </mui.core.Box>
  );
};

export default Chart;
