// eslint-disable-next-line max-len

import { BacktestExpanded } from '../../../types/backtest';
import { Backtest } from '../../../types/backtest';
import { BaseStore } from '../../../types/base-store';
import { Resource } from '../../../types/common';

const getResourceWords = (id: number, resources: Resource[]) => {
  const resource = resources.find((r) => r.id == id );
  if (resources) return [resource?.name, resource?.handle];
  return [];
};

const expandBacktest = (
  backtest: Backtest & { resourceType: string },
  resources: BaseStore['resources']
): BacktestExpanded => {
  const expanded = [];

  const uniId = backtest.definition?.universe_id;
  const alphaId = backtest.definition?.signal_id;
  const riskModelId = backtest.definition?.risk_model_id;
  const pipelineIds = backtest.definition?.pipeline_ids || [];

  if (uniId) expanded.push(getResourceWords(uniId, resources.universes));
  if (alphaId) expanded.push(getResourceWords(alphaId, resources.signals));
  if (riskModelId) expanded.push(getResourceWords(riskModelId, resources.risk_models));
  pipelineIds.forEach((pid) => {
    expanded.push(getResourceWords(pid, resources.pipelines));
  });

  return {
    id: backtest.id,
    name: backtest.name,
    handle: backtest.handle,
    resourceType: backtest.resourceType,
    created_by_id: backtest.created_by_id,
    label_ids: backtest.label_ids,
    definition: expanded.flat(),
    is_published: backtest.is_published,
    is_deprecated: backtest.is_deprecated,
    longOption: backtest.revoked ? null : backtest.ui_metadata.longOption || 'long-short',
    revoked: backtest.revoked || false,
    is_valid: backtest.is_valid,
    created_at: backtest.created_at,
    source_resource_id: backtest.source_resource_id,
    organization_id: backtest.organization_id,
    source_org_id: backtest.source_org_id,
    has_description: backtest.has_description,
    short_description: backtest.short_description,
    shared: backtest.shared,
  } as BacktestExpanded;
};

export default expandBacktest;
