import { React, ts } from '_core';

import Chart from './chart';

type SignalDistributionOverTimeProps = {
  id: string;
  readOnly: boolean;
  widgetKey: string;
  context?: ts.types.signal.SignalAnalysisContext;
  fullScreen: boolean;
  loadWidgetData: (_payload: ts.types.widgets.WidgetGetDataParams) => Promise<ts.types.widgets.WidgetGetDataResponse>;
  widgetPaths: Record<'signal_distribution_analysis', string>;
  title: string;
};

const SignalDistributionOverTime: React.FC<SignalDistributionOverTimeProps> = ({
  id,
  readOnly,
  widgetKey,
  loadWidgetData,
  widgetPaths,
  fullScreen,
  context,
  title,
}) => {
  return (
    <>
      <Chart
        id={id}
        readOnly={readOnly}
        widgetKey={widgetKey}
        loadWidgetData={loadWidgetData}
        file={widgetPaths['signal_distribution_analysis']}
        fullScreen={fullScreen}
        hasPipeline={!!context.pipeline_id}
        hasSPPipeline={!!context.sp_pipeline_id}
        title={title}
      />
    </>
  );
};

export default SignalDistributionOverTime;
