import { DeleteResourceTypeByIdType, UpdateResourceTypeByIdType } from '_core/reducers/resources';

import { Dispatch } from 'react';
import { Resource } from '@local/finsera-core/src/types/common';
import { Job } from '@local/finsera-core/src/types/job';

import { DELETE_RESOURCE_TYPE_BY_ID, JOB_UPDATE, UPDATE_RESOURCE_TYPE_BY_ID } from './types';
import { JobCompletedActionType } from '../reducers/jobs';

const supported_resources = [
  'backtests',
  'help_items',
  'pipelines',
  'risk_models',
  'signal_sets',
  'time_series',
  'universes',
  'backtest_sets',
  'baskets',
  'data_sources',
  'general_analyses',
  'signals',
  'strategies',
  'portfolios',
  'labels',
  'fixed_costs',
  'workspaces',
];

export const receiveMessage =
  (data: any) =>
  (dispatch: Dispatch<JobCompletedActionType | UpdateResourceTypeByIdType | DeleteResourceTypeByIdType>) => {
    if (data.resource_type == 'jobs')
      dispatch({
        type: JOB_UPDATE,
        job: data.resource as Job,
      });

    if (supported_resources.includes(data.resource_type) && (data.method == 'update' || data.method == 'create')) {
      dispatch({
        type: UPDATE_RESOURCE_TYPE_BY_ID,
        resourceType: data.resource_type,
        data: data.resource as Resource,
      });
    }

    if (supported_resources.includes(data.resource_type) && data.method == 'delete') {
      dispatch({
        type: DELETE_RESOURCE_TYPE_BY_ID,
        resourceType: data.resource_type,
        data: data.resource as Resource,
      });
    }
  };
