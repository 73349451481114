import { _, React, ts } from '_core';

import Chart from './chart';
import Form from './form';
import { DraftParams, Params } from './types';

/*
    This widget uses jobpaths instead of widget paths due to different
    response payload
*/
type ReturnsReportProps = {
  mode: ts.enums.CHART_MODE_ENUM;
  params: Params | DraftParams;
  setParams: (_p: DraftParams) => void;

  context?: ts.types.signalSet.SignalSetAnalysisContext;
  loadWidgetData?: (_payload: ts.types.widgets.WidgetGetDataParams) => Promise<ts.types.widgets.WidgetGetDataResponse>;
  getSignature?: (_v: string) => string;
  jobPaths?: Record<string, string>;
};

const ReturnsReport: React.FC<ReturnsReportProps> = ({
  loadWidgetData,
  jobPaths,
  mode,
  getSignature,
  params,
  setParams,
  context,
}) => {
  // Let's select the file that has the report data
  const file = React.useMemo(() => {
    if (!_.isEmpty(jobPaths)) {
      // Fix key (check form to see how we send report as id)
      return jobPaths[`signal_set_returns_${getSignature('signal_set_returns')}_report`];
    }
    return null;
  }, [jobPaths]);

  const renderJobComponent = () => (
    <>
      <Chart
        loadWidgetData={loadWidgetData}
        file={file}
        params={params as Params}
        context={context}
        setParams={setParams}
      />
    </>
  );

  return (
    <>{mode == 'edit' ? <Form params={params} setParams={setParams} context={context} /> : renderJobComponent()}</>
  );
};

export default ReturnsReport;
