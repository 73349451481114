import { _, api, config, helpers, ts } from '_core';

/**
 * Formats columns for upload by adding Finsera-specific columns and mapping values
 * @param columns - Object containing column name key-value pairs
 * @returns Object with original columns plus Finsera columns mapped appropriately
 */
export const _formatUploadColumns = (columns: Record<string, string>) => {
  const finseraCols = ['finsera_cusip', 'finsera_country', 'finsera_isin'];

  const localColumns = {
    finsera_fid: 'finsera_fid',
    finsera_ticker: 'finsera_ticker',
  } as Record<string, string>;

  localColumns['finsera_name'] = 'finsera_name';

  Object.keys(columns).forEach((key) => {
    if (columns[key] !== 'None') {
      localColumns[key] = columns[key];
      const finseraColFound = finseraCols.find((fc) => fc.includes(key));
      if (finseraColFound) localColumns[finseraColFound] = finseraColFound;
    }
  });

  return localColumns;
};
type BacktestApiResponse = {
  data: ts.types.backtest.Backtest;
};

export const createBacktest = async (
  newData: ts.types.backtest.BacktestDraft,
  callback: (_data: ts.types.backtest.Backtest) => void,
  errorCallback: (_error: string) => void
) => {
  try {
    const resp: BacktestApiResponse = await api.backtests.create(newData);
    callback(resp.data);
  } catch (error) {
    errorCallback(`Error creating backtest - ${helpers.parseApiError(error as ts.types.common.ApiError)}`);
  }
};

export const updateBacktest = async (
  id: number,
  newData: ts.types.backtest.BacktestDraft,
  callback: (_data: ts.types.backtest.Backtest) => void,
  errorCallback: (_error: string) => void
) => {
  try {
    const resp: BacktestApiResponse = await api.backtests.update(id, { ...newData });
    callback(resp.data);
  } catch (error) {
    errorCallback(`Error updating backtest - ${helpers.parseApiError(error as ts.types.common.ApiError)}`);
  }
};

export const updateDefinition = async (
  id: number,
  definition: ts.types.backtest.DefinitionDraft,
  isValid: boolean,
  callback: (_data: ts.types.backtest.Backtest) => void,
  errorCallback: (_error: string) => void
) => {
  try {
    const localDef = _.pickByNestedObj<ts.types.backtest.DefinitionDraft>(definition, (x: any) => !_.isNil(x));
    const resp = await api.backtests.update(
      id,
      {
        definition: localDef,
        is_valid: isValid,
        skip_signature_check: !isValid,
      },
      true
    );

    callback(resp.data);
  } catch (error) {
    errorCallback(`Error updating the definition - ${helpers.parseApiError(error as ts.types.common.ApiError)}`);
  }
};

export const updateUiMetadata = async (
  backtest: ts.types.backtest.Backtest,
  uiMetaData: ts.types.backtest.UiMetaData,
  callback: (_data: ts.types.backtest.Backtest) => void,
  errorCallback: (_error: string) => void
) => {
  try {
    if (backtest.shared) return;
    const updateData = {
      ui_metadata: uiMetaData,
      skip_signature_check: true,
    } as ts.types.backtest.BacktestDraft;
    const resp = await api.backtests.update(backtest.id, updateData);
    callback(resp.data);
  } catch (error) {
    errorCallback(`Error updating the resource metadata - ${helpers.parseApiError(error as ts.types.common.ApiError)}`);
  }
};

const createAnalyses = async (id: number, names: ts.enums.BACKTEST_ANALYSIS_TYPE_ENUM[]) => {
  const analyses = [];
  for (const name of names) {
    let analysisDefinition = { widgets: [] } as ts.types.analysis.AnalysisDefinition;

    if (name == ts.enums.BACKTEST_ANALYSIS_TYPE_ENUM.DETAILS) {
      // default portan value
      analysisDefinition = { mode: ts.enums.ANALYSIS_MODE_ENUM.FULLSCREEN, widgets: [] };
    }
    if (name == ts.enums.BACKTEST_ANALYSIS_TYPE_ENUM.SUMMARY) {
      // default summary/report value
      analysisDefinition = {
        mode: ts.enums.ANALYSIS_MODE_ENUM.FULLSCREEN,
        widgets: [
          {
            id: helpers.gibberishGenerator.stringGenerator(11),
            key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.BACKTEST_TABULAR_OUTPUT,
            params: {},
          },
        ],
      };
    }

    // create analysis
    const response = await api.backtest_analyses.create({
      report_definition: analysisDefinition,
      backtest_id: id,
      name,
    });
    analyses.push(response.data.name);
  }
  return analyses;
};

// Load analyses, and if we don't have them, create them
export const loadAnalyses = async (
  backtest: ts.types.backtest.Backtest,
  setAnalysesNames: (_data: ts.enums.BACKTEST_ANALYSIS_TYPE_ENUM[]) => void,
  errorCallback: (_error: string) => void
) => {
  const analyses = Object.values(ts.enums.BACKTEST_ANALYSIS_TYPE_ENUM);
  try {
    const resp = await api.backtest_analyses.search({
      ignore_user_scope: backtest.shared,
      query: ['$and', ['backtest_id', '=', backtest.id]],
      include: 'id,name',
    });

    let localNames = (resp.data || []).map(
      (a: ts.types.analysis.ResourceAnalysis) => a.name
    ) as ts.enums.BACKTEST_ANALYSIS_TYPE_ENUM[];

    const toCreate = _.without(analyses, ...localNames);
    if (!_.isEmpty(toCreate)) {
      const newNames = (await createAnalyses(backtest.id, toCreate)) as ts.enums.BACKTEST_ANALYSIS_TYPE_ENUM[];
      localNames = localNames.concat(newNames);
    }

    setAnalysesNames(localNames);
  } catch (error) {
    errorCallback(`Error loading backtest analyses - ${helpers.parseApiError(error as ts.types.common.ApiError)}`);
  }
};

export const keepCurrent = async (
  backtest: ts.types.backtest.BacktestDraft,
  callback: (_data: { resource: ts.types.backtest.Backtest; universe?: ts.types.universe.Universe }) => void,
  errorCallback: (_error: string) => void
) => {
  try {
    if (backtest.shared) return;
    const resp = await api.dates.keep_current({
      resource_id: backtest.id,
      resource_type: 'backtests',
      frequency: (backtest.definition?.frequency as ts.enums.FREQUENCY_ENUM) || undefined,
      // Until we don't have a universe, our keep current will be just the max date we can have
      universe_id: backtest.definition.universe_id || undefined,
      max_date: config.features.end_date,
    });
    callback(resp);
  } catch (error) {
    errorCallback(`Error updating backtest dates - ${helpers.parseApiError(error as ts.types.common.ApiError)}`);
  }
};

export const getMappedData = async (
  backtest: ts.types.backtest.Backtest,
  uiMetadata: ts.types.backtest.Backtest['ui_metadata'],
  callback: (_data: ts.types.rebalance.HoldingsData, _cols: { title: string; field: string }[]) => void,
  errorCallback: (_error: string) => void
) => {
  try {
    const resp = await api.uiApi.getRebalanceData({
      data: {
        mapped_file: (backtest.mapped_file || '').replace(`backtests/${backtest.id}/`, ''),
        unmapped_file: (backtest.unmapped_file || '').replace(`backtests/${backtest.id}/`, ''),
        columns: Object.keys(_formatUploadColumns(uiMetadata.mapped_columns)),
        resource_id: backtest.id,
        ignore_cache: config.debug,
        resource: 'backtests',
      },
    });

    const data = _.isEmpty(resp?.data) ? [] : resp?.data;
    const cols = (resp?.columns as string[])
      .filter((x) => x !== 'error_message')
      .map((col) => ({
        title: _.startCase(col.replace('finsera', 'mapped')),
        field: col,
      }));

    callback(data, cols);
  } catch (error) {
    errorCallback(`Error getting data - ${helpers.parseApiError(error as ts.types.common.ApiError)}`);
  }
};
