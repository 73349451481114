import { BaseStore } from '../../../types/base-store';
import { Resource } from '../../../types/common';
import { TimeSeriesExpanded } from '../../../types/time-series';
import { TimeSeries } from '../../../types/time-series';

const getResourceWords = (id: number, resources: Resource[]) => {
  const resource = resources.find((r) => r.id == id);
  if (resources) return [resource?.name, resource?.handle];
  return [];
};

const expandTimeSeries = (
  timeSeries: TimeSeries & { resourceType: string },
  resources: BaseStore['resources']
): TimeSeriesExpanded => {
  const expanded = [];
  expanded.push(timeSeries.definition?.source);

  if (timeSeries.signal_ids) {
    (timeSeries.signal_ids || []).forEach((sId) => {
      expanded.push(getResourceWords(sId, resources.signals));
    });
  }

  if (timeSeries.universe_ids) {
    (timeSeries.universe_ids || []).forEach((uId) => {
      expanded.push(getResourceWords(uId, resources.universes));
    });
  }

  if (timeSeries.pipeline_ids) {
    (timeSeries.pipeline_ids || []).forEach((pId) => {
      expanded.push(getResourceWords(pId, resources.pipelines));
    });
  }

  return {
    id: timeSeries.id,
    name: timeSeries.name,
    handle: timeSeries.handle,
    resourceType: timeSeries.resourceType,
    created_by_id: timeSeries.created_by_id,
    label_ids: timeSeries.label_ids,
    definition: expanded.flat(),
    is_published: timeSeries.is_published,
    is_deprecated: timeSeries.is_deprecated,
    revoked: timeSeries.revoked || false,
    is_valid: timeSeries.is_valid,
    created_at: timeSeries.created_at,
    source_resource_id: timeSeries.source_resource_id,
    organization_id: timeSeries.organization_id,
    has_description: timeSeries.has_description,
    short_description: timeSeries.short_description,
    source_org_id: timeSeries.source_org_id,
    shared: timeSeries.shared,
  } as TimeSeriesExpanded;
};

export default expandTimeSeries;
