import { ts } from '_core';

import innerTabNames from '../common/inner-tab-names';

type TemplateOption = {
  name: string;
  resourceType: ts.enums.UI_RESOURCE_TYPE_ENUM;
  layout: ts.enums.LAYOUT_TYPE;
  enabled: boolean;
  tags: ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM[];
  onlyFullscreen?: boolean;
};

const getOptions = (features: ts.types.organization.OrganizationFeatures): TemplateOption[] => [
  {
    name: innerTabNames[ts.enums.PREFERENCES_INNER_TABS_ENUM.UNI_TEMPLATE],
    resourceType: ts.enums.UI_RESOURCE_TYPE_ENUM.UNIVERSE,
    layout: ts.enums.LAYOUT_TYPE.UNIVERSE,
    enabled: true,
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.UNIVERSE],
  },
  {
    name: innerTabNames[ts.enums.PREFERENCES_INNER_TABS_ENUM.SIGNAL_TEMPLATE],
    resourceType: ts.enums.UI_RESOURCE_TYPE_ENUM.SIGNAL,
    layout: ts.enums.LAYOUT_TYPE.SIGNAL,
    enabled: true,
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.SIGNAL],
  },
  {
    name: innerTabNames[ts.enums.PREFERENCES_INNER_TABS_ENUM.SIGNAL_PREVIEW_TEMPLATE],
    resourceType: ts.enums.UI_RESOURCE_TYPE_ENUM.SIGNAL,
    layout: ts.enums.LAYOUT_TYPE.SIGNAL_PREVIEW,
    enabled: true,
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.SIGNAL_PREVIEW],
  },
  {
    name: innerTabNames[ts.enums.PREFERENCES_INNER_TABS_ENUM.SIGNAL_ATTRIBUTION_TEMPLATE],
    resourceType: ts.enums.UI_RESOURCE_TYPE_ENUM.SIGNAL,
    layout: ts.enums.LAYOUT_TYPE.SIGNAL_PORTAN,
    enabled: features.signal_attribution,
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.SIGNAL_PORTAN],
  },
  {
    name: innerTabNames[ts.enums.PREFERENCES_INNER_TABS_ENUM.ALPHA_MODEL_TEMPLATE],
    resourceType: ts.enums.UI_RESOURCE_TYPE_ENUM.ALPHA_MODEL,
    layout: ts.enums.LAYOUT_TYPE.ALPHA_MODEL,
    enabled: true,
    tags: [
      ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.COMMON_ALPHA_MODEL,
      ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.LINEAR_ALPHA_MODEL,
      ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.OPTIMIZED_ALPHA_MODEL,
    ],
  },
  {
    name: innerTabNames[ts.enums.PREFERENCES_INNER_TABS_ENUM.BACKTEST_TEMPLATE],
    resourceType: ts.enums.UI_RESOURCE_TYPE_ENUM.BACKTEST,
    layout: ts.enums.LAYOUT_TYPE.BACKTEST,
    enabled: features.backtest,
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.BACKTEST],
  },
  {
    name: innerTabNames[ts.enums.PREFERENCES_INNER_TABS_ENUM.BACKTEST_PREVIEW_TEMPLATE],
    resourceType: ts.enums.UI_RESOURCE_TYPE_ENUM.BACKTEST,
    layout: ts.enums.LAYOUT_TYPE.BACKTEST_DETAILED_VIEW,
    enabled: features.backtest,
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.BACKTEST_DETAILS],
  },
  {
    name: innerTabNames[ts.enums.PREFERENCES_INNER_TABS_ENUM.BACKTEST_PORTFOLIO_RISK_TEMPLATE],
    resourceType: ts.enums.UI_RESOURCE_TYPE_ENUM.BACKTEST,
    layout: ts.enums.LAYOUT_TYPE.BACKTEST_PORTFOLIO_RISK,
    enabled: features.backtest,
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.BACKTEST_PORTFOLIO_RISK],
  },
  {
    name: innerTabNames[ts.enums.PREFERENCES_INNER_TABS_ENUM.BACKTEST_ATTRIBUTION_TEMPLATE],
    resourceType: ts.enums.UI_RESOURCE_TYPE_ENUM.BACKTEST,
    layout: ts.enums.LAYOUT_TYPE.BACKTEST_PORTAN,
    enabled: features.backtest,
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.BACKTEST_PORTAN],
    onlyFullscreen: true,
  },
  {
    name: innerTabNames[ts.enums.PREFERENCES_INNER_TABS_ENUM.TIME_SERIES_TEMPLATE],
    resourceType: ts.enums.UI_RESOURCE_TYPE_ENUM.TIME_SERIES,
    layout: ts.enums.LAYOUT_TYPE.TIME_SERIES,
    enabled: features.time_series,
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.TIME_SERIES],
  },
  {
    name: innerTabNames[ts.enums.PREFERENCES_INNER_TABS_ENUM.BASKET_TEMPLATE],
    resourceType: ts.enums.UI_RESOURCE_TYPE_ENUM.BASKET,
    layout: ts.enums.LAYOUT_TYPE.BASKET,
    enabled: features.basket,
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.BASKET],
  },
  {
    name: innerTabNames[ts.enums.PREFERENCES_INNER_TABS_ENUM.BASKET_PREVIEW_TEMPLATE],
    resourceType: ts.enums.UI_RESOURCE_TYPE_ENUM.BASKET,
    layout: ts.enums.LAYOUT_TYPE.BASKET_DETAILED_VIEW,
    enabled: features.basket,
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.BASKET_DETAILS],
  },
  {
    name: innerTabNames[ts.enums.PREFERENCES_INNER_TABS_ENUM.BASKET_ATTRIBUTION_TEMPLATE],
    resourceType: ts.enums.UI_RESOURCE_TYPE_ENUM.BASKET,
    layout: ts.enums.LAYOUT_TYPE.BASKET_ATTRIBUTION,
    enabled: features.basket,
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.SIGNAL_PORTAN],
    onlyFullscreen: true,
  },
  {
    name: innerTabNames[ts.enums.PREFERENCES_INNER_TABS_ENUM.BASKET_COMPARE_TEMPLATE],
    resourceType: ts.enums.UI_RESOURCE_TYPE_ENUM.BASKET,
    layout: ts.enums.LAYOUT_TYPE.BASKET_COMPARE,
    enabled: features.basket,
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.BASKET_COMPARE],
  },
  {
    name: innerTabNames[ts.enums.PREFERENCES_INNER_TABS_ENUM.BASKET_PORTFOLIO_RISK_TEMPLATE],
    resourceType: ts.enums.UI_RESOURCE_TYPE_ENUM.BASKET,
    layout: ts.enums.LAYOUT_TYPE.BASKET_PORTFOLIO_RISK,
    enabled: features.basket,
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.BASKET_PORTFOLIO_RISK],
  },
  {
    name: innerTabNames[ts.enums.PREFERENCES_INNER_TABS_ENUM.BACKTEST_SET_TEMPLATE],
    resourceType: ts.enums.UI_RESOURCE_TYPE_ENUM.BACKTEST_SET,
    layout: ts.enums.LAYOUT_TYPE.BACKTEST_SET,
    enabled: features.backtest_set,
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.BACKTEST_SET],
  },
  {
    name: innerTabNames[ts.enums.PREFERENCES_INNER_TABS_ENUM.SIGNAL_SET_TEMPLATE],
    resourceType: ts.enums.UI_RESOURCE_TYPE_ENUM.SIGNAL_SET,
    layout: ts.enums.LAYOUT_TYPE.SIGNAL_SET,
    enabled: features.signal_set,
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.SIGNAL_SET],
  },
  {
    name: innerTabNames[ts.enums.PREFERENCES_INNER_TABS_ENUM.SIGNAL_SET_REPORT_TEMPLATE],
    resourceType: ts.enums.UI_RESOURCE_TYPE_ENUM.SIGNAL_SET,
    layout: ts.enums.LAYOUT_TYPE.SIGNAL_SET_REPORT,
    enabled: features.signal_set,
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.SIGNAL_SET_REPORT],
    onlyFullscreen: true,
  },
  {
    name: innerTabNames[ts.enums.PREFERENCES_INNER_TABS_ENUM.RISK_MODEL_TEMPLATE],
    resourceType: ts.enums.UI_RESOURCE_TYPE_ENUM.RISK_MODEL,
    layout: ts.enums.LAYOUT_TYPE.RISK_MODEL,
    enabled: true,
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.RISK_MODEL],
  },

  // {
  //   name: innerTabNames[ts.enums.PREFERENCES_INNER_TABS_ENUM.REBALANCE_PORTFOLIO_RISK_TEMPLATE],
  //   resourceType: ts.enums.UI_RESOURCE_TYPE_ENUM.PORTFOLIO,
  //   layout: ts.enums.LAYOUT_TYPE.REBALANCE_PORTFOLIO_RISK,
  //   enabled: features.production_mode,
  //   tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.REBALANCE_PORTFOLIO_RISK],
  // },
  {
    name: innerTabNames[ts.enums.PREFERENCES_INNER_TABS_ENUM.REBALANCE_PORTFOLIO_HOLDINGS_TEMPLATE],
    resourceType: ts.enums.UI_RESOURCE_TYPE_ENUM.PORTFOLIO,
    layout: ts.enums.LAYOUT_TYPE.REBALANCE_PORTFOLIO_HOLDINGS,
    enabled: features.production_mode,
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.REBALANCE_PORTFOLIO_HOLDINGS],
    onlyFullscreen: true,
  },
  {
    name: innerTabNames[ts.enums.PREFERENCES_INNER_TABS_ENUM.REBALANCE_PORTFOLIO_TRADELIST_TEMPLATE],
    resourceType: ts.enums.UI_RESOURCE_TYPE_ENUM.PORTFOLIO,
    layout: ts.enums.LAYOUT_TYPE.REBALANCE_PORTFOLIO_TRADELIST,
    enabled: features.production_mode,
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.REBALANCE_PORTFOLIO_TRADE_LIST],
    onlyFullscreen: true,
  },
];

export default getOptions;
