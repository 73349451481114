import { React, ts } from '_core';

import Chart from './chart';
import { Params } from './types';

type PortfolioHoldingsRiskSummaryProps = {
  id: string;
  readOnly: boolean;
  widgetKey: string;
  context?: ts.types.portfolioHoldings.PortfolioHoldingsAnalysisContext;
  loadWidgetData: (_payload: ts.types.widgets.WidgetGetDataParams) => Promise<ts.types.widgets.WidgetGetDataResponse>;
  widgetPaths?: Record<'portfolio_risk_summary_holdings', string>;
  params: Params;
  setParams: (_p: Params) => void;
  goFullScreen?: (_b: string) => void;
  fullScreen?: boolean;

  queryParams?: { initial?: string };
};

const PortfolioHoldingsRiskSummary: React.FC<PortfolioHoldingsRiskSummaryProps> = ({
  id,
  readOnly,
  widgetKey,
  loadWidgetData,
  params,
  setParams,
  widgetPaths,
  fullScreen,
  goFullScreen,
  queryParams,
}) => {
  return (
    <Chart
      id={id}
      readOnly={readOnly}
      widgetKey={widgetKey}
      params={params}
      setParams={setParams}
      loadWidgetData={loadWidgetData}
      summary={widgetPaths['portfolio_risk_summary_holdings']}
      fullScreen={fullScreen}
      queryParams={queryParams}
      goFullScreen={goFullScreen}
    />
  );
};

export default PortfolioHoldingsRiskSummary;
