import { helpers, Link, mui, React, ts, ui, useHistory, useSelector } from '_core';

import { FullJob } from '../jobber/jobber-context';
import JobLabel from '../jobber/jobber-menu/job-label';
import { consolidateJobs } from '../jobber/utils';

type JobNotificationProps = object;

/**
 * Component that adds an alert when the lastCompletedJob state changes.
 *
 * On each new lastCompletedJob adds a FullJob record to the notifiedJobs state (if we're not already on the page
 * for the job). An Alert is rendered for each notifiedJob. On action, the job is removed from the state.
 */
const JobNotifications: React.FC<JobNotificationProps> = () => {
  // Job is not null when we've received a message and cleared when the alert is dismissed.
  const [notifiedJobs, setNotifiedJobs] = React.useState([]);
  const lastCompletedJob = useSelector((state) => state.jobs.lastJobCompleted);
  const resources = useSelector((state) => state.resources);
  const history = useHistory();

  const jobLabelStyles = {
    labelText: {},
    icon: {
      color: '#7F8D93',
    },
    jobTitle: {
      color: '#627178',
      fontSize: '0.8rem',
    },
    resourceIconContainer: {},
  };

  React.useEffect(() => {
    const consolidateJob = async () => {
      if (lastCompletedJob.id > -1) {
        const fullJob = await consolidateJobs([lastCompletedJob], resources);
        const [, inResource] = helpers.jobs.jobReportUrl(fullJob[0] as FullJob, history);
        if (!inResource) {
          const newNotifiedJobs = notifiedJobs.concat(fullJob);
          setNotifiedJobs(newNotifiedJobs);
        }
      }
    };

    consolidateJob();
  }, [lastCompletedJob.id]);

  const clearNotification = (job: FullJob) => {
    const newNotifiedJobs = notifiedJobs.filter((otherJob) => otherJob.id != job.id);
    setNotifiedJobs(newNotifiedJobs);
  };

  const alertForJob = (job: FullJob) => {
    const [reportUrl, inResource] = helpers.jobs.jobReportUrl(job, history);
    const statusObj = helpers.jobs.jobStatus(job, true, job.analysis.name);
    const success = (statusObj as ts.types.job.JobStatusResponse).status == 'SUCCESS';

    const severity = success ? 'success' : 'error';
    return (
      <>
        <mui.core.Snackbar
          open
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          onClose={() => clearNotification(job)}
          autoHideDuration={6000}
        >
          <mui.lab.Alert
            severity={severity}
            variant="filled"
            action={
              <mui.core.Stack display="flex" alignItems="center" flexDirection="row" gap={2}>
                <Link to={reportUrl} onClick={() => clearNotification(job)}>
                  <ui.Button sx={{ color: '#fff' }}>View Report</ui.Button>
                </Link>
                <mui.core.Tooltip title="Close" arrow>
                  <mui.core.IconButton onClick={() => clearNotification(job)} size="small">
                    <mui.icons.Close sx={{ color: '#fff' }} />
                  </mui.core.IconButton>
                </mui.core.Tooltip>
              </mui.core.Stack>
            }
          >
            <JobLabel
              job={job}
              inResource={inResource}
              styles={jobLabelStyles}
              titleMessage="has completed"
              showDataset={false}
              hideIcon
            />
          </mui.lab.Alert>
        </mui.core.Snackbar>
      </>
    );
  };

  const alerts = notifiedJobs.map(alertForJob);
  if (alerts.length == 0) {
    return <></>;
  }
  return <mui.core.Box>{alerts}</mui.core.Box>;
};

export default JobNotifications;
