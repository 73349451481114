import { ts } from '_core';


export default [
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.GENERAL_LAST_OPENED,
    form: false,
    title: 'Last Opened',
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.GENERAL],
    helpLabel: 'b3pqn0hm',
  },
] satisfies ts.types.analysis.WidgetConfig[];
