/* eslint-disable no-unused-vars */
import { ts } from '_core';

import { PartialDeep } from 'type-fest';

export enum INTERACTION_PARAMS {
  TABLE_PARAMS = 'table_params',
  TABLE_DTYPES = 'table_dtypes',
  EXPANDED = 'expanded',
  CURRENT_SHEET = 'current_sheet',
}

export type SHEETS =
  | ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.MODEL
  | ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.FACTOR
  | ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.RISK;

export type InteractionParams = {
  [INTERACTION_PARAMS.EXPANDED]: Record<SHEETS, string[]>;
  [INTERACTION_PARAMS.CURRENT_SHEET]: SHEETS;
  [INTERACTION_PARAMS.TABLE_PARAMS]: ts.types.components.dataGrid.TableParamsSheet;
  [INTERACTION_PARAMS.TABLE_DTYPES]: ts.types.components.dataGrid.TableDtypesSheet;
};

export type JobParams = {
  analysis_pipelined_signals?: {
    signal_id: number;
    pipeline_id?: number;
    sp_pipeline_id?: number;
  }[];
};

export type Params = JobParams & InteractionParams & { fullWidth: boolean };
export type DraftParams = PartialDeep<Params>;
