import { BaseStore } from '../../../types/base-store';
import { SignalSet } from '../../../types/signal-set';
import { SignalSetExpanded } from '../../../types/signal-set';

const expandSignalSet = (signalSet: SignalSet & { resourceType: string }, resources: BaseStore['resources']) => {
  const expanded = (signalSet?.definition?.signals || []).map((set) =>
    resources.signals.find((s) => s.id == set.signal_id)
  );

  return {
    id: signalSet.id,
    name: signalSet.name,
    handle: signalSet.handle,
    resourceType: signalSet.resourceType,
    created_by_id: signalSet.created_by_id,
    label_ids: signalSet.label_ids,
    definition: expanded.flat(),
    is_deprecated: signalSet.is_deprecated,
    is_published: signalSet.is_published,
    revoked: signalSet.revoked || false,
    is_valid: signalSet.is_valid,
    created_at: signalSet.created_at,
    source_resource_id: signalSet.source_resource_id,
    organization_id: signalSet.organization_id,
    has_description: signalSet.has_description,
    short_description: signalSet.short_description,
    source_org_id: signalSet.source_org_id,
    shared: signalSet.shared,
  } as SignalSetExpanded;
};

export default expandSignalSet;
