import { _, mui, React, ts, useSelector } from '_core';

import { prepareBucketedEventStudyData } from './helpers';
import { Params } from './types';
import ChartWrapper from '../../common/chart-wrapper';
import createErrorChart from '../../common/charts/create-error-chart';
import createHeatMapChart from '../../common/charts/create-heat-map';
import createStackedBarChart from '../../common/charts/create-stacked-bar-chart';

type ChartProps = {
  id: string;
  readOnly: boolean;
  widgetKey: string;
  loadWidgetData: (_payload: ts.types.widgets.WidgetGetDataParams) => Promise<ts.types.widgets.WidgetGetDataResponse>;
  fileEvent: string;
  fileCount: string;
  fullScreen: boolean;
  params: Params;
  title: string;
};

const Chart: React.FC<ChartProps> = ({
  id,
  readOnly,
  widgetKey,
  loadWidgetData,
  fileEvent,
  params,
  fileCount,
  fullScreen,
  title,
}) => {
  const [data, setData] = React.useState<ts.types.widgets.TableData>(null);
  const [error, setError] = React.useState<ts.types.common.ApiError>(null);
  const [showLegend, setShowLegend] = React.useState(false);
  const [selectedTab, setSelectedTab] = React.useState<'error' | 'tstat' | 'count'>('error');

  const resources = useSelector((state) => state.resources);
  const [showError, setShowError] = React.useState(false);

  const loadData = async (selectedTab: 'error' | 'tstat' | 'count') => {
    try {
      const response = await loadWidgetData({
        data: {
          file: selectedTab == 'count' ? fileCount : fileEvent,
          rename: selectedTab == 'error' ? { avg_cum: 'average_cumulative_return', std_cum: 'error' } : {},
          columns: selectedTab == 'tstat' ? ['category', 'days_from_event', 'tstat'] : undefined,
        },
      });
      if (response) setData(response.data);
    } catch (err) {
      setError(err as ts.types.common.ApiError);
    }
  };

  React.useEffect(() => {
    if (fileEvent || fileCount) loadData(selectedTab);
    return () => setData(null);
  }, [fileEvent, fileCount, selectedTab]);

  React.useEffect(() => {
    if (!_.isNil(data)) {
      if (selectedTab == 'tstat')
        createHeatMapChart({
          id,
          data: data as ts.types.widgets.HeatMapData,
          fullScreen,
          xAxis: 'days_from_event',
          yAxis: 'category',
          exportTitle: title,
          prepareData: (heatMapData) =>
            prepareBucketedEventStudyData(heatMapData, selectedTab, params.buckets, resources) as any,
        });
      if (selectedTab == 'error') {
        createErrorChart({
          id,
          data,
          fullScreen,
          xAxis: 'days_from_event',
          yAxis: 'average_cumulative_return',
          setShowLegend,
          yAxisLabel: 'Days',
          xAxisLabel: 'Mean Cumulative Returns (%)',
          exportTitle: title,
          showError,
          bucket: 'category',
          showBucketOnTooltip: true,
          prepareData: (errorData) =>
            prepareBucketedEventStudyData(errorData, selectedTab, params.buckets, resources) as any,
        });
      }
      if (selectedTab == 'count')
        createStackedBarChart({
          id,
          data,
          fullScreen,
          setShowLegend,
          yAxisLabel: 'Event Count',
          category: 'build_date',
          exportTitle: title,
          rotatedLabels: true,
          prepareData: (stackedBarData) => ({
            ...stackedBarData,
            data: prepareBucketedEventStudyData(stackedBarData.data, selectedTab, params.buckets, resources) as any,
          }),
        });
    }
  }, [data, showError]);

  const getTabs = () => (
    <mui.core.Box display="flex">
      <mui.core.Box flexGrow={1} display="flex" alignItems="flex-end">
        <mui.core.Tabs
          indicatorColor="primary"
          textColor="primary"
          value={selectedTab}
          onChange={(_e, newTab) => setSelectedTab(newTab)}
        >
          <mui.core.Tab label="Cumulative Returns" value="error" />
          <mui.core.Tab label="T-stat" value="tstat" />
          <mui.core.Tab label="Event Count" value="count" />
        </mui.core.Tabs>
      </mui.core.Box>

      {selectedTab == 'error' && (
        <mui.core.Box display="flex" justifyContent="flex-end">
          <mui.core.FormControlLabel
            control={<mui.core.Switch size="small" checked={showError} onChange={() => setShowError((v) => !v)} />}
            label={showError ? 'Hide error bars' : 'Show error bars'}
          />
        </mui.core.Box>
      )}
    </mui.core.Box>
  );

  return (
    <ChartWrapper
      id={id}
      readOnly={readOnly}
      widgetKey={widgetKey}
      data={data}
      showLegend={showLegend}
      warning={data?.length === 0 ? ['Empty File'] : []}
      error={error}
      tabs={getTabs()}
      fullScreen={fullScreen}
    />
  );
};

export default Chart;
