import { ts } from '_core';

import backtestConstraintsColumns from './backtest-constraints-columns';
import backtestDatesComparisonColumns from './backtest-dates-comparison-columns';
import backtestPortfolioRiskColumns from './backtest-portfolio-risk-columns';
import backtestPreviewAlphaExposuresColumns from './backtest-preview-alpha-exposures-columns';
import backtestPreviewColumns from './backtest-preview-columns';
import backtestSetSummaryColumns from './backtest-set-summary-columns';
import backtestTabularOutputColumns from './backtest-tabular-output-columns';
import basketConstraintsColumns from './basket-constraints-columns';
import basketDatesComparisonColumns from './basket-dates-comparison-columns';
import basketPreviewColumns from './basket-preview-columns';
import basketSummaryStatsReportColumns from './basket-summary-stats-report-columns';
import basketTabularOutputColumns from './basket-tabular-output-columns';
import performanceAttributionContributorColumns from './performance-attribution-contributor-columns';
import portfolioConstraintsColumns from './portfolio-constraints-columns';
import portfolioInitialHoldingsColumns from './portfolio-initial-holdings-columns';
import portfolioRiskColumns from './portfolio-risk-columns';
import portfolioTabularOutputColumns from './portfolio-tabular-output-columns';
import portfolioTradeListBucketExposureColumns from './portfolio-trade-list-bucket-exposure-columns';
import portfolioTradeListColumns from './portfolio-trade-list-columns';
import portfolioTradeListFactorExposureColumns from './portfolio-trade-list-factor-exposure-columns';
import portfolioTradeListSummaryColumns from './portfolio-trade-list-summary-columns';
import portfolioTradelistHoldingsColumns from './portfolio-tradelist-holdings-columns';
import riskModelAssetPertinencePreviewColumns from './risk-model-asset-pertinence-preview-columns';
import riskModelPreviewColumns from './risk-model-preview-columns';
import signalDatesComparisonColumns from './signal-dates-comparison-columns';
import signalPreviewColumns from './signal-preview-columns';
import signalSetSummaryStatsColumns from './signal-set-summary-stats-columns';
import timeSeriesPreviewColumns from './time-series-preview-columns';
import timeSeriesSummaryStatsColumns from './time-series-summary-stats-report-columns';
import universePreviewColumns from './universe-preview-columns';

const getDefault = (cols: Record<string, ts.types.components.dataGrid.ColumnsData>, take = null as number) => {
  const localDefault = {} as ts.types.components.dataGrid.ColumnPreferences;
  Object.keys(cols).forEach((sheet) => {
    localDefault[sheet] = {
      columns: (take ? cols[sheet].slice(0, take) : cols[sheet]).map((x) => x.key),
    };
  });
  return localDefault;
};

const defaultUserPreferences: Record<ts.enums.PREFERENCES_KEY_ENUM, ts.types.components.dataGrid.ColumnPreferences> = {
  [ts.enums.PREFERENCES_KEY_ENUM.UNIVERSE_COMPARISON_COLS]: getDefault(universePreviewColumns),
  [ts.enums.PREFERENCES_KEY_ENUM.UNIVERSE_LINKED_ASSETS_COLS]: getDefault(universePreviewColumns),
  [ts.enums.PREFERENCES_KEY_ENUM.UNIVERSE_SNAPSHOT_COLS]: getDefault(universePreviewColumns),
  [ts.enums.PREFERENCES_KEY_ENUM.UNIVERSE_PERTINENC_COLS]: getDefault(universePreviewColumns),
  [ts.enums.PREFERENCES_KEY_ENUM.SIGNAL_PREVIEW_COLS]: getDefault(signalPreviewColumns),
  [ts.enums.PREFERENCES_KEY_ENUM.SIGNAL_DATE_COMPARISON_COLS]: getDefault(signalDatesComparisonColumns),
  [ts.enums.PREFERENCES_KEY_ENUM.BACKTEST_CONSTRAINTS_COLS]: getDefault(backtestConstraintsColumns),
  [ts.enums.PREFERENCES_KEY_ENUM.BACKTEST_PREVIEW_COLS]: getDefault(backtestPreviewColumns),
  [ts.enums.PREFERENCES_KEY_ENUM.BACKTEST_PREVIEW_ALPHA_EXPOSURES_COLS]:
    getDefault(backtestPreviewAlphaExposuresColumns),
  [ts.enums.PREFERENCES_KEY_ENUM.BACKTEST_TABULAR_COLS]: getDefault(backtestTabularOutputColumns),
  [ts.enums.PREFERENCES_KEY_ENUM.BACKTEST_SET_SUMMARY_COLS]: getDefault(backtestSetSummaryColumns),
  [ts.enums.PREFERENCES_KEY_ENUM.BACKTEST_DATE_COMPARISON_COLS]: getDefault(backtestDatesComparisonColumns),
  [ts.enums.PREFERENCES_KEY_ENUM.BACKTEST_PORTFOLIO_RISK_COLS]: getDefault(backtestPortfolioRiskColumns),
  [ts.enums.PREFERENCES_KEY_ENUM.BACKTEST_PERFORMANCE_ATTRIBUTION_CONTRIBUTOR_COLUMNS]: getDefault(
    performanceAttributionContributorColumns
  ),
  [ts.enums.PREFERENCES_KEY_ENUM.RISK_MODEL_SNAPSHOT_COLS]: {
    [ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.MAIN]: {
      columns: riskModelPreviewColumns[ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.MAIN]
        .map((x) => x.key).filter((x) => x !== 'asset'),
      frozenColumns: ['asset'],
    },
  },
  [ts.enums.PREFERENCES_KEY_ENUM.RISK_MODEL_ASSET_PERTINENCE_COLS]: {
    [ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.MAIN]: {
      columns: riskModelAssetPertinencePreviewColumns[ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.MAIN]
        .map((x) => x.key)
        .filter((x) => x !== 'asset'),
      frozenColumns: ['asset'],
    },
  },
  [ts.enums.PREFERENCES_KEY_ENUM.SIGNAL_SET_SUMMARY_STATS_COLS]: {
    [ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.SUMMARY]: {
      columns: signalSetSummaryStatsColumns[ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.SUMMARY]
        .map((x) => x.key)
        .filter((x) => !['signal', 'pipeline', 'portfolio_pipeline'].includes(x)),
      frozenColumns: ['signal', 'pipeline', 'portfolio_pipeline'],
    },
  },
  [ts.enums.PREFERENCES_KEY_ENUM.BASKET_COMPARE_SUMMARY_STATS_COLS]: {
    [ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.SUMMARY]: {
      columns: basketSummaryStatsReportColumns[ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.SUMMARY]
        .map((x) => x.key)
        .filter((x) => !['basket'].includes(x)),
      frozenColumns: ['basket'],
    },
  },
  [ts.enums.PREFERENCES_KEY_ENUM.PORTFOLIO_CONSTRAINTS_COLS]: getDefault(portfolioConstraintsColumns),
  [ts.enums.PREFERENCES_KEY_ENUM.BASKET_CONSTRAINTS_COLS]: getDefault(basketConstraintsColumns),
  [ts.enums.PREFERENCES_KEY_ENUM.BASKET_DATE_COMPARISON_COLS]: getDefault(basketDatesComparisonColumns),
  [ts.enums.PREFERENCES_KEY_ENUM.BASKET_PORTFOLIO_RISK_COLS]: getDefault(backtestPortfolioRiskColumns),
  [ts.enums.PREFERENCES_KEY_ENUM.BASKET_PREVIEW_COLS]: getDefault(basketPreviewColumns),
  [ts.enums.PREFERENCES_KEY_ENUM.BASKET_TABULAR_COLS]: getDefault(basketTabularOutputColumns),
  [ts.enums.PREFERENCES_KEY_ENUM.PORTFOLIO_TABULAR_COLS]: getDefault(portfolioTabularOutputColumns),
  [ts.enums.PREFERENCES_KEY_ENUM.BASKET_PERFORMANCE_ATTRIBUTION_CONTRIBUTOR_COLUMNS]: getDefault(
    performanceAttributionContributorColumns
  ),
  [ts.enums.PREFERENCES_KEY_ENUM.PORTFOLIO_RISK_COLS]: getDefault(portfolioRiskColumns),
  [ts.enums.PREFERENCES_KEY_ENUM.PORTFOLIO_TRADE_LIST_COLS]: getDefault(portfolioTradeListColumns, 17),
  [ts.enums.PREFERENCES_KEY_ENUM.PORTFOLIO_TRADE_LIST_SUMMARY_COLS]: getDefault(portfolioTradeListSummaryColumns),
  [ts.enums.PREFERENCES_KEY_ENUM.PORTFOLIO_TRADE_LIST_HOLDINGS_COLS]: getDefault(portfolioTradelistHoldingsColumns),
  [ts.enums.PREFERENCES_KEY_ENUM.PORTFOLIO_TRADE_LIST_BUCKET_EXPOSURE_COLS]:
    getDefault(portfolioTradeListBucketExposureColumns),
  [ts.enums.PREFERENCES_KEY_ENUM.PORTFOLIO_TRADE_LIST_FACTOR_EXPOSURE_COLS]:
    getDefault(portfolioTradeListFactorExposureColumns),
  [ts.enums.PREFERENCES_KEY_ENUM.PORTFOLIOS_HOLDINGS_COLS]: getDefault(portfolioInitialHoldingsColumns, 20),
  [ts.enums.PREFERENCES_KEY_ENUM.TIME_SERIES_PREVIEW_COLS]: getDefault(timeSeriesPreviewColumns),
  [ts.enums.PREFERENCES_KEY_ENUM.TIME_SERIES_COMPARE_SUMMARY_STATS_COLS]: getDefault(timeSeriesSummaryStatsColumns),
  [ts.enums.PREFERENCES_KEY_ENUM.SIGNAL_DATESET_FORM]: null,
};

export default defaultUserPreferences;
