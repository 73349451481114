import { helpers,ts } from '_core';

export default {
  [ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.MAIN]: [
    {
      key: 'constraint',
      name: 'Constraint',
      formatter: helpers.tableFormatters.formatTo('string', { align: 'left' }),
      filter: 'string',
    },
    {
      key: 'name',
      name: 'Name',
      formatter: helpers.tableFormatters.formatTo('string', { align: 'left' }),
      filter: 'string',
    },
    {
      key: 'ticker',
      name: 'Ticker',
      formatter: helpers.tableFormatters.formatTo('string', { align: 'left' }),
      filter: 'string',
    },
    {
      key: 'type',
      name: 'Type',
      formatter: helpers.tableFormatters.formatTo('string', { align: 'left' }),
      filter: 'string',
    },
    {
      key: 'value',
      name: 'Value',
      formatter: helpers.tableFormatters.formatTo('float', {
        align: 'right',
        roundDigits: 2,
        commasOnThousands: true,
      }),
      filter: 'number',
    },
    {
      key: 'lower_bound',
      name: 'Lower Bound',
      formatter: helpers.tableFormatters.formatTo('float', { align: 'right', roundDigits: 2 }),
      filter: 'number',
    },
    {
      key: 'upper_bound',
      name: 'Upper Bound',
      formatter: helpers.tableFormatters.formatTo('float', { align: 'right', roundDigits: 2 }),
      filter: 'number',
    },
    {
      key: 'lower_bound_source',
      name: 'Lower Bound Source',
      formatter: helpers.tableFormatters.formatTo('string', { align: 'left' }),
      filter: 'string',
    },
    {
      key: 'upper_bound_source',
      name: 'Upper Bound Source',
      formatter: helpers.tableFormatters.formatTo('string', { align: 'left' }),
      filter: 'string',
    },
    {
      key: 'binding',
      name: 'Binding',
      formatter: helpers.tableFormatters.formatTo('boolean', {
        align: 'center',
        booleanAsNumber: false,
      }),
      filter: 'number',
    },
  ] as ts.types.components.dataGrid.ColumnsData,
};
