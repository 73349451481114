import { _, helpers, ts } from '_core';

import { DraftParams } from './types';
import { getBenchmarkTitle } from '../../common/utils/get-name';

export const filterTabBarDataByReturnHorizon = (data: ts.types.widgets.TableData, tab: string) => {
  const respObj = data
    .filter((item) => item.return_horizon == tab)
    .map((item) => ({
      ..._.omit(item, ['return_horizon']),
    }));

  return respObj;
};

export const validate = (params: DraftParams) => {
  const rhErrors = helpers.validations.validSelectedReturnHorizons(params?.return_horizons);
  if (!_.isEmpty(rhErrors)) return rhErrors;
  if (_.isEmpty(params.histories)) return ['Histories is a required field.'];
  if (params.with_benchmark && !helpers.validations.validBenchmark(params.benchmark))
    return ['Benchmark is invalid.'];
  return [];
};

export const getName = (
  params: DraftParams,
  _context: ts.types.signal.SignalAnalysisContext,
  resources: ts.StoreState['resources']
) => {
  let widgetTitle = 'Annualized Returns';

  if (params.statistics_type === 'risk') widgetTitle = 'Annualized Risk';
  else if (params.statistics_type === 'adjusted') widgetTitle = 'Annualized Risk Adjusted Returns';

  if (params.benchmark) return getBenchmarkTitle(params.benchmark, widgetTitle, resources.universes);
  return widgetTitle;
};

export const prepareData = (data: ts.types.widgets.TableData, params: DraftParams) => {
  if (params.statistics_type === 'adjusted') {
    const filteredData = data.map((row) => {
      const newRow = {} as ts.types.widgets.TableData[0];
      Object.keys(row).forEach((key) => {
        if (!key.startsWith('portfolio_') && !key.startsWith('benchmark_')) {
          newRow[key] = row[key];
        }
      });
      return newRow;
    });
    return filteredData;
  }

  return data;
};
