import { _, hooks, mui, React, ts, ui } from '_core';

import FactorTable from './factor-table';

type MergeFactorProps = {
  merger: ts.types.riskModel.ExtendedCategoryFactor;
  factors: ts.types.riskModel.ExtendedCategoryFactor[];
  replaceInclusions: (_i: ts.types.riskModel.ExtendedCategoryFactorInclusions[]) => void;
  removeDestination: (_h: string) => void;
};

const MergeFactor: React.FC<MergeFactorProps> = ({
  merger,
  factors,
  replaceInclusions,
  removeDestination,
}): React.ReactElement => {
  const uiStyles = hooks.useUiStyles();

  const [selectedFactor, setSelectedFactor] = React.useState<ts.types.riskModel.ExtendedCategoryFactor>();
  const [openModal, setOpenModal] = React.useState(false);

  const options = React.useMemo(
    () => factors.filter((f) => !_.isEmpty(f.inclusions) && f.handle !== merger.handle),
    [factors]
  );

  const mergedFactor = React.useMemo(() => {
    if (!selectedFactor) return merger;
    return {
      ...merger,
      inclusions: _.unionBy(
        [...merger.inclusions, ...selectedFactor.inclusions.map((f) => ({ ...f, isNew: true }))],
        'value'
      ),
    };
  }, [selectedFactor]);

  const newFactorsDiff = React.useMemo(() => {
    if (!selectedFactor) return 0;
    return mergedFactor.inclusions?.length - merger.inclusions?.length;
  }, [mergedFactor]);

  const handleClose = () => {
    setSelectedFactor(null);
    setOpenModal(!openModal);
  };

  const handleMerge = () => {
    replaceInclusions(mergedFactor.inclusions);
    removeDestination(selectedFactor.handle);
    handleClose();
  };

  return (
    <>
      <mui.core.Dialog open={openModal} maxWidth="md" fullWidth onClose={handleClose}>
        <ui.DialogTitle closeAction={handleClose}>Merge {merger.name} with...</ui.DialogTitle>

        <>
          <mui.core.DialogContent>
            <mui.core.Stack gap={4}>
              <mui.core.Box py={2}>
                <mui.core.Autocomplete
                  options={options}
                  value={selectedFactor}
                  onChange={(_, val) => setSelectedFactor(val as typeof selectedFactor)}
                  getOptionLabel={(el) => (el as typeof selectedFactor).name}
                  isOptionEqualToValue={(option, val) => option?.handle == val?.handle}
                  renderInput={(params) => <mui.core.TextField {...params} label="Merge with..." />}
                />
              </mui.core.Box>

              {selectedFactor && (
                <>
                  <ui.Alert severity="info" rounded>
                    This operation will result in adding {newFactorsDiff} factors to &quot
                    {merger.name}&quot.
                    <br></br>&quot{selectedFactor.name}&quot will be removed.
                  </ui.Alert>

                  {selectedFactor && <FactorTable factor={mergedFactor} readOnly={true} />}
                </>
              )}
            </mui.core.Stack>
          </mui.core.DialogContent>
        </>

        <mui.core.DialogActions>
          <mui.core.Button variant="outlined" color="primary" onClick={handleClose}>
            Cancel
          </mui.core.Button>
          <mui.core.Button
            variant="outlined"
            color="primary"
            onClick={handleMerge}
            disabled={!selectedFactor || newFactorsDiff == 0}
          >
            Merge
          </mui.core.Button>
        </mui.core.DialogActions>
      </mui.core.Dialog>

      <mui.core.Tooltip title="Merge" arrow>
        <mui.core.IconButton onClick={() => setOpenModal(true)} size="small" sx={uiStyles.collapseHeaderButton}>
          <mui.icons.Merge />
        </mui.core.IconButton>
      </mui.core.Tooltip>
    </>
  );
};

export default MergeFactor;
