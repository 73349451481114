import { _, mui, React, ui } from '_core';

type AddIndustryFactorProps = {
  addFactor: (_v: string) => void;
};

const AddIndustryFactor: React.FC<AddIndustryFactorProps> = ({ addFactor }): React.ReactElement => {
  const [openAddModal, setOpenAddModal] = React.useState(false);
  const [name, setName] = React.useState('');

  return (
    <>
      <mui.core.Dialog open={openAddModal} maxWidth="sm" fullWidth onClose={() => setOpenAddModal(false)}>
        <ui.DialogTitle closeAction={() => setOpenAddModal(false)}>Add Factor</ui.DialogTitle>
        <mui.core.DialogContent>
          <mui.core.Box pt={3}>
            <mui.core.TextField
              value={name}
              onChange={(e) => setName(e.target.value)}
              label="Factor Name"
              variant="outlined"
              fullWidth
            />
            <mui.core.Box mt={3}>
              <mui.core.Button
                variant="contained"
                disabled={_.isEmpty(name)}
                onClick={() => {
                  addFactor(name);
                  setOpenAddModal(false);
                  setName('');
                }}
              >
                Save
              </mui.core.Button>
            </mui.core.Box>
          </mui.core.Box>
        </mui.core.DialogContent>
      </mui.core.Dialog>

      <mui.core.Button
        size="small"
        sx={{ mb: 5, position: 'absolute', left: '9rem', top: '-30px', zIndex: '1' }}
        variant="outlined"
        onClick={() => setOpenAddModal(true)}
      >
        + Add Factor
      </mui.core.Button>
    </>
  );
};

export default AddIndustryFactor;
