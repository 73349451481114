import { _, helpers, ts } from '_core';

import { DraftParams } from './types';
import { getBenchmarkTitle } from '../../common/utils/get-name';

// prepareAnnuStatsData
export const transform = (
  fullData: ts.types.widgets.NestedBarWidgetData,
  type: string,
  isBasket: boolean,
  hasBenchmark: boolean,
  isIndexBenchmark: boolean
) => {
  const prepareData = (group: Record<string, string | number>) => {
    const newGroup = {} as typeof group;

    if (group['category']) newGroup['category'] = group['category'];

    // Rename portfolio returns
    if (!_.isNil(group['portfolio_returns'])) {
      if (type == 'adjusted') newGroup[isBasket ? 'Basket IR' : 'Portfolio IR'] = group['portfolio_returns'];
      else newGroup[isBasket ? 'Basket Returns' : 'Portfolio Returns'] = group['portfolio_returns'];
    }

    if (!_.isNil(group['portfolio_risk']))
      newGroup[isBasket ? 'Basket Risk' : 'Portfolio Risk'] = group['portfolio_risk'];

    if (hasBenchmark) {
      if (!_.isNil(group.benchmark_returns)) {
        if (type == 'adjusted') newGroup[isBasket ? 'Target IR' : 'Benchmark IR'] = group['benchmark_returns'];
        else
          newGroup[isBasket && !isIndexBenchmark ? 'Target Returns' : 'Benchmark Returns'] = group['benchmark_returns'];
      }

      if (!_.isNil(group.active_returns)) {
        if (type == 'adjusted') newGroup['Active IR'] = group['active_returns'];
        else newGroup['Active Returns'] = group['active_returns'];
      }

      if (!_.isNil(group['benchmark_risk'])) {
        newGroup[isBasket ? 'Target Risk' : 'Benchmark Risk'] = group.benchmark_risk;
      }

      if (!_.isNil(group['active_risk'])) {
        newGroup['Active Risk'] = group.active_risk;
      }
    }

    return newGroup;
  };

  const localData = [] as ts.types.widgets.NestedBarData;

  fullData.data.forEach((group) => {
    const newGroup = prepareData(group);
    localData.push(newGroup);
  });

  const countData = {} as ts.types.widgets.CountData;

  Object.keys(fullData.count || []).forEach((key) => {
    const row = fullData.count[key];
    const newEl = prepareData(row);
    countData[key] = newEl as Record<string, number>;
  });

  return { ...fullData, data: localData, count: countData } as ts.types.widgets.NestedBarWidgetData;
};

export const validate = (params: DraftParams) => {
  if (_.isEmpty(params.histories)) return ['Histories is a required field.'];
  if (params.with_benchmark && !helpers.validations.validBenchmark(params.benchmark)) return ['Benchmark is invalid.'];
  return [];
};

export const getName = (
  params: DraftParams,
  _context: ts.types.signal.SignalAnalysisContext,
  resources: ts.StoreState['resources']
) => {
  let widgetTitle = 'Annualized Returns';

  if (params.statistics_type === 'risk') widgetTitle = 'Annualized Risk';
  else if (params.statistics_type === 'adjusted') widgetTitle = 'Annualized Risk Adjusted Returns';

  if (params.benchmark) return getBenchmarkTitle(params.benchmark, widgetTitle, resources.universes);
  return widgetTitle;
};
