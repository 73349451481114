import { React, ts } from '_core';

import AssetsField from './assets-field';
import CheckboxesField from './checkbox-field';
import DateField from './date-field';
import DateRangeField from './date-range-field';
import EtfField from './etf-field';
import RemoteSelectField from './remote-select-field';
import SelectField from './select-field';
import { DataSourceBrowseItem } from '../../types';

type POSSIBLE_VALUES_TYPE =
  | number
  | string
  | string[]
  | ts.types.dataSource.ASSETS_VALUE_TYPE
  | ts.types.dataSource.DATE_VALUE_TYPE;

type DataSourceBrowserFieldsProps = {
  value: POSSIBLE_VALUES_TYPE;
  setValue: (_d: POSSIBLE_VALUES_TYPE) => void;
  type: ts.enums.BROWSE_ITEM_TYPE_ENUM;
  label: string;
  date: string;
  minDate: string;
  maxDate: string;
  fieldData: DataSourceBrowseItem;
};

const DataSourceBrowserFields: React.FC<DataSourceBrowserFieldsProps> = ({
  label,
  type,
  value,
  setValue,
  date,
  minDate,
  maxDate,
  fieldData,
}): React.ReactElement => {
  if (label == 'As Of') return <></>;

  if (type == ts.enums.BROWSE_ITEM_TYPE_ENUM.DATE)
    return <DateField label={label} value={value as string} setValue={setValue} minDate={minDate} maxDate={maxDate} />;

  if (type == ts.enums.BROWSE_ITEM_TYPE_ENUM.DATE_RANGE)
    return (
      <DateRangeField
        label={label}
        value={value as ts.types.dataSource.DATE_VALUE_TYPE}
        setValue={setValue}
        minDate={minDate}
        maxDate={maxDate}
      />
    );

  if (type == ts.enums.BROWSE_ITEM_TYPE_ENUM.NUMBER_BOOLEAN)
    return <CheckboxesField label={label} value={value as number} setValue={setValue} />;

  if (type == ts.enums.BROWSE_ITEM_TYPE_ENUM.SELECT)
    return <SelectField label={label} value={value as string} setValue={setValue} options={fieldData.values || {}} />;

  if (type == ts.enums.BROWSE_ITEM_TYPE_ENUM.REMOTE_SELECT)
    return (
      <RemoteSelectField
        label={label}
        value={value as string | string[]}
        setValue={setValue}
        table={fieldData.remote_values_table}
        query={fieldData.remote_values_query}
        multiple={!!fieldData.multiple}
      />
    );

  if (type == ts.enums.BROWSE_ITEM_TYPE_ENUM.ASSET)
    return <AssetsField value={value as ts.types.dataSource.ASSETS_VALUE_TYPE} setValue={setValue} date={date} />;

  if (type == ts.enums.BROWSE_ITEM_TYPE_ENUM.ETF)
    return <EtfField value={value as string | string[]} setValue={setValue} multiple={!!fieldData.multiple} />;

  return <>Type {type} is not supported.</>;
};

export default DataSourceBrowserFields;
