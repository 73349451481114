import { _, React, ts, useSelector } from '_core';

import Form from './form';
import Table from './table';
import { DraftParams, Params } from './types';

type BacktestAttributionProps = {
  id: string;
  mode: ts.enums.CHART_MODE_ENUM;
  params: Params | DraftParams;
  setParams: (_p: DraftParams) => void;

  queryParams?: { initial?: string };
  goFullScreen?: (_v: string) => void;
  fullScreen?: boolean;
  context?: ts.types.backtest.BacktestAnaylsisContext;
  widgetPaths?: Record<
    'backtest_portan_details' | 'backtest_portan_summary' | 'portfolio_portan_details' | 'portfolio_portan_summary',
    string
  >;

  loadWidgetData: (_payload: ts.types.widgets.WidgetGetDataParams) => Promise<ts.types.widgets.WidgetGetDataResponse>;
};

const BacktestAttribution: React.FC<BacktestAttributionProps> = ({
  id,
  loadWidgetData,
  params,
  setParams,
  mode,
  context,
  widgetPaths,
}): React.ReactElement => {
  const riskModels = useSelector((state) => state.resources.risk_models);
  const strategies = useSelector((state) => state.resources.strategies);

  const currentStrategy = React.useMemo(() => {
    return _.find(
      strategies,
      (el) => el.id == (context?.definition as ts.types.portfolio.PortfolioAnalysisContext['definition'])?.strategy_id
    );
  }, [(context?.definition as ts.types.portfolio.PortfolioAnalysisContext['definition'])?.strategy_id]);

  const currentRiskModel = React.useMemo(() => {
    return _.find(
      riskModels,
      (el) => el.id == context?.definition?.risk_model_id || el.id == currentStrategy?.definition?.risk_model_id
    );
  }, [context?.definition?.risk_model_id]);

  const hasBenchmark = !_.isEmpty(context?.definition?.benchmark);

  const detailsFileKey = React.useMemo(
    () => Object.keys(widgetPaths || {}).find((k) => k.endsWith('_details')) as keyof typeof widgetPaths,
    [widgetPaths]
  );

  const summaryFileKey = React.useMemo(
    () => Object.keys(widgetPaths || {}).find((k) => k.endsWith('_summary')) as keyof typeof widgetPaths,
    [widgetPaths]
  );

  return mode == 'edit' ? (
    <Form
      params={params}
      setParams={setParams}
      hasBenchmark={hasBenchmark}
      contextRiskModel={currentRiskModel?.id}
      strategyType={currentStrategy && currentStrategy.type}
    />
  ) : (
    <Table
      id={id}
      loadWidgetData={loadWidgetData}
      detailsFile={widgetPaths[detailsFileKey]}
      summaryFile={widgetPaths[summaryFileKey]}
      frequency={context?.definition?.frequency as ts.enums.FREQUENCY_ENUM}
      startDate={context?.start_date}
      endDate={context?.end_date}
      params={params}
      histories={context.context_histories}
    />
  );
};

export default BacktestAttribution;
