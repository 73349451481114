import React from 'react';

import { mui } from '../libs';

/**
 * Props for the EmptyMessage component
 */
type EmptyMessageProps = {
  /** Content to display in the empty message */
  children: React.ReactNode;
  /** Optional title text to display */
  title?: string;

  /** Optional icon element to display above the content */
  icon?: React.ReactNode;
  /** Optional action element (like a button) to display below content */
  action?: React.ReactNode;
  /** Whether to hide the border and box shadow styling */
  hideBorder?: boolean;
};

/**
 * A component for displaying an empty state message with optional title, icon and action
 *
 * @param title - Optional title text
 * @param icon - Optional icon element
 * @param action - Optional action element
 * @param hideBorder - Whether to hide border styling
 * @param children - Content to display
 * @returns The rendered empty message component
 */
const EmptyMessage: React.FC<EmptyMessageProps> = ({
  title,
  icon,
  action,
  hideBorder = false,
  children,
}): React.ReactElement => {
  const [mounted, setMounted] = React.useState(false);

  React.useEffect(() => {
    setMounted(true);
  }, []);

  return (
    <mui.core.Box my={3}>
      <mui.core.Card
        variant="outlined"
        style={hideBorder ? { padding: 0, boxShadow: 'none', border: 0 } : {}}
        sx={{
          p: 5,
          textAlign: 'center',
          opacity: mounted ? 1 : 0,
          transition: 'opacity 0.5s ease-in-out',
        }}
      >
        {icon && <mui.core.Box mb={4}>{icon}</mui.core.Box>}
        {title && (
          <mui.core.Typography variant="h4" mb={3}>
            {title}
          </mui.core.Typography>
        )}

        <mui.core.Typography color="textSecondary">{children}</mui.core.Typography>

        {action && <mui.core.Box mt={4}>{action}</mui.core.Box>}
      </mui.core.Card>
    </mui.core.Box>
  );
};

export default EmptyMessage;
