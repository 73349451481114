import { helpers, mui, React, ts } from '_core';

const HistoryField = ({
  value,
  setValue,
  histories,
  startDate,
  endDate,
}: {
  value: ts.enums.HISTORY_ENUM;
  setValue: (_v: ts.enums.HISTORY_ENUM_EXTENDED) => void;
  histories: ts.enums.HISTORY_ENUM_EXTENDED[];
  startDate: string;
  endDate: string;
}) => {
  const getHistLabel = (h: ts.enums.HISTORY_ENUM_EXTENDED) => {
    return helpers.periods.isAnnualized(startDate, endDate, h) ? `${h} (Annualized)` : h;
  };

  return (
    <mui.core.Box display="inline-flex" width={200}>
      <mui.core.TextField
        select
        label="Selected History"
        variant="outlined"
        size="small"
        value={value}
        onChange={(e) => setValue(e.target.value as ts.enums.HISTORY_ENUM_EXTENDED)}
        fullWidth
      >
        {histories.map((h) => (
          <mui.core.MenuItem key={h} value={h}>
            {getHistLabel(h)}
          </mui.core.MenuItem>
        ))}
      </mui.core.TextField>
    </mui.core.Box>
  );
};

export default HistoryField;
