import { _, helpers, React, ts, ui, useHistory, useSelector } from '_core';

import { Filter } from '@local/finsera-core/src/_components/guided-search/search-types';
import { Search } from '@mui/icons-material';
import { Chip, Dialog } from '@mui/material';

import { getFilters } from './config';
import EmptyView from './empty-view';
import Item from './item';
import PinSearchForm from '../../workspaces/pin-search-form';

const GlobalSearch = (): React.ReactElement => {
  const history = useHistory();

  const resources = useSelector((state) => state.resources);
  const workspaces = useSelector((state) => state.resources.workspaces as ts.types.workspace.Workspace[]);
  const currentWorkspaceId = useSelector((state) => state.ui.currentWorkspaceId);

  const [searchQuery, setSearchQuery] = React.useState('');
  const [openPinSearchModal, setOpenPinSearchModal] = React.useState(false);
  const [searchOpen, setSearchOpen] = React.useState(false);

  const currTab = useSelector((state) => state.ui.currentTab);
  const wsContext: ts.types.workspace.Workspace['context'] = React.useMemo(() => {
    if (currTab == 'production') return 'production';
    return 'research';
  }, [currTab]);

  const getSearchQuery = React.useCallback(
    (
      searchQuery: string,
      resources: ts.StoreState['resources'],
      // eslint-disable-next-line @typescript-eslint/ban-types
      filter?: (_v: ts.types.common.Resource[]) => ts.types.common.Resource[]
    ) => {
      return helpers.resourcesSearch.search(searchQuery, resources, wsContext, filter, true);
    },
    [wsContext]
  );

  const performSearch = React.useCallback(
    (searchQuery: string) => {
      // Perform search
      const searchResults = getSearchQuery(searchQuery, resources);
      // there is no group, then data will have all results

      return searchResults;
    },
    [resources, wsContext]
  );

  const workspace = React.useMemo(() => workspaces.find((w) => w.id == currentWorkspaceId), [currentWorkspaceId]);

  const inUser = history.location.pathname.includes('/user-management');
  const inDash = history.location.pathname.includes('/dashboard');
  const inWS = !inDash && !inUser;

  const filters = React.useMemo(() => {
    return getFilters(resources.users, resources.labels, resources.published_organizations, wsContext);
  }, [resources, wsContext]);

  React.useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      const activeElement = document.activeElement;
      const isEditableElement =
        activeElement instanceof HTMLInputElement ||
        activeElement instanceof HTMLTextAreaElement ||
        activeElement?.hasAttribute('contenteditable');

      if (event.key === '/' && !isEditableElement) {
        event.preventDefault();
        setSearchOpen((prev) => !prev);
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, []);

  const manuallyClickOnResult = (item: ts.types.common.Resource) => {
    const plural = helpers.pluralize.plural(item.resourceType);
    history.push(`/${plural}/${item.id}/definition`);
    setSearchOpen(false);
  };

  const formatOptions = (options: helpers.resourcesSearch.ReturnItem[]) => {
    if (!options) return [];
    const a = options.map((o) => {
      if (o.shared)
        return {
          ...o,
          group_label: 'Shared',
        };

      if (o.is_deprecated)
        return {
          ...o,
          group_label: 'Archived',
        };
      return { ...o, group_label: '' };
    });
    return a;
  };

  return (
    <>
      <PinSearchForm
        open={openPinSearchModal}
        setOpen={setOpenPinSearchModal}
        workspace={workspace}
        searchQuery={searchQuery}
        setSearchOpen={setSearchOpen}
      />

      <ui.Button
        onClick={() => setSearchOpen(true)}
        variant="contained"
        startIcon={<Search />}
        endIcon={
          <Chip
            size="small"
            label={<>/</>}
            sx={{
              ml: 10,
              fontSize: '0.7rem!important',
              '& svg': { fontSize: '0.7rem' },
              background: 'rgba(255,255,255,.1)',
              border: '1px solid rgba(255,255,255, .1)',
              color: '#fff',
              pointerEvents: 'none',
              borderRadius: 1,
            }}
          />
        }
        sx={{
          background: 'rgba(255,255,255,.1)',
          '&:hover': {
            background: 'rgba(255,255,255,.2)',
          },
          px: 3,
          fontWeight: '500',
        }}
      >
        Search...
      </ui.Button>

      <Dialog
        open={searchOpen}
        onClose={() => setSearchOpen(false)}
        fullWidth
        PaperProps={{
          sx: {
            position: 'absolute',
            top: '5rem',
            margin: 0,
            p: 1,
            minHeight: 'calc(40vh + 105px)',
          },
        }}
      >
        <ui.GuidedSearch<helpers.resourcesSearch.ReturnItem>
          inputFilters={filters as Filter[]}
          formatOptions={formatOptions}
          filterOptions={(_opts, searchTerm) => performSearch(searchTerm)}
          renderOption={(opt, callback) => <Item item={opt} clickCallback={callback} source="search-results" />}
          onResultClick={manuallyClickOnResult}
          setOpened={setSearchOpen}
          emptyView={<EmptyView setOpened={setSearchOpen} />}
          inputStyle={{
            '.MuiOutlinedInput-root': {
              background: '#fff',
              padding: '1rem',
              fontSize: '1rem!important',
            },
            '.MuiAutocomplete-input': {
              fontSize: '1rem!important',
              '&::placeholder': {
                fontSize: '1rem',
                opacity: 0.5,
                fontWeight: '500',
              },
            },
          }}
          saveSearchLabel="Save search to workspace"
          saveSearch={
            inWS
              ? (searchTerm: string) => {
                  setSearchQuery(searchTerm);
                  setOpenPinSearchModal(true);
                }
              : undefined
          }
          sortBy={(options: any[]) => {
            return _.sortBy(options, 'group_label');
          }}
        />
      </Dialog>
    </>
  );
};

export default GlobalSearch;
