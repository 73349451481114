import { Link, mui, React, ts, ui, useSelector } from '_core';

import { ArrowBack } from '@mui/icons-material';
import { ReportsProps as ReportProviderProps } from 'views/report';

const ReportLazy = React.lazy(() => import('../../..'));

function ReportDeferred<TContext extends ts.types.signal.SignalAnalysisContext>(props: ReportProviderProps<TContext>) {
  return (
    <>
      <React.Suspense fallback={<ui.CenteredLoader />}>
        <ReportLazy {...props} />
      </React.Suspense>
    </>
  );
}

type SingleAnalysisProps = {
  jobId: number;
  startDate: string;
  endDate: string;
  defaultId: number;
  basePath: string;
  signalMetadata: Partial<ts.types.signalSet.SignalMetadata>;
};

const SingleAnalysis: React.FC<SingleAnalysisProps> = ({
  signalMetadata,
  basePath,
  defaultId,
  jobId,
  startDate,
  endDate,
}) => {
  const theme = mui.styles.useTheme() as mui.core.Theme;

  const resources = useSelector((state) => state.resources);

  const filterJobs = (jobPaths: ts.types.job.JobPaths) => {
    const jobs = {} as ts.types.job.JobPaths;
    Object.keys(jobPaths).forEach((key) => {
      if (key.includes(signalMetadata.id)) {
        const rawKey = key.replace(`_${signalMetadata.id}`, '');
        const splittedKey = rawKey.split('_');
        splittedKey.splice(splittedKey.length - 2, 1);
        const newKey = splittedKey.join('_');
        jobs[newKey] = jobPaths[key];
      }
    });

    return jobs;
  };

  const currentUser = useSelector((state) => state.auth.currentUser);

  const layout = React.useMemo(() => {
    return resources.report_defaults.find((rd) => rd.id == defaultId);
  }, [defaultId]);

  const signal = React.useMemo(() => {
    return resources.signals.find((s) => s.id == signalMetadata.signal_id);
  }, [signalMetadata.signal_id]);

  const universe = React.useMemo(() => {
    return resources.universes.find((u) => u.id == signalMetadata.universe_id);
  }, [signalMetadata.universe_id]);

  const riskModel = React.useMemo(() => {
    return resources.risk_models.find((s) => s.id == signalMetadata.risk_model_id);
  }, [signalMetadata.risk_model_id]);

  const pipeline = React.useMemo(() => {
    if (signalMetadata.pipeline_id) {
      return resources.pipelines.find((p) => p.id == signalMetadata.pipeline_id);
    }
    return null;
  }, [signalMetadata.pipeline_id]);

  const sppipeline = React.useMemo(() => {
    if (signalMetadata.sp_pipeline_id) {
      return resources.pipelines.find((p) => p.id == signalMetadata.sp_pipeline_id);
    }
    return null;
  }, [signalMetadata.sp_pipeline_id]);

  const listItem = (name: string, item: string) => (
    <mui.core.Stack>
      <mui.core.Typography variant="overline" color="textSecondary">
        {name}
      </mui.core.Typography>
      <mui.core.Typography variant="caption">{item}</mui.core.Typography>
    </mui.core.Stack>
  );

  return (
    <>
      <mui.core.Box p={1} pb={0}>
        <mui.core.Card sx={{ marginBottom: '0' }}>
          <mui.core.Box display="flex" p={3} gap={3}>
            <mui.core.Button
              variant="outlined"
              color="primary"
              size="small"
              to={`${basePath}`}
              component={Link}
              startIcon={<ArrowBack />}
            >
              Go back to Signals
            </mui.core.Button>
            <mui.core.Box
              sx={{
                display: 'flex',
                [theme.breakpoints.down('md')]: { flexWrap: 'nowrap' },
                gap: 4,
              }}
            >
              {listItem('Signal', signal.name)}
              {listItem('Universe', universe.name)}
              {listItem('Frequency', signalMetadata.frequency)}
              {listItem('Risk Model', riskModel?.name ?? 'None')}
              {listItem('Pipeline', pipeline?.name || 'None')}
              {listItem('Portfolio Pipeline', sppipeline?.name || 'None')}
            </mui.core.Box>
          </mui.core.Box>
        </mui.core.Card>
      </mui.core.Box>
      <mui.core.Box style={{ flex: '1' }}>
        <ReportDeferred
          key={signalMetadata.id}
          analysis={{
            id: -1,
            name: 'Browser analysis',
            report_definition: layout.definition,
            jobs: {
              master_job: {
                last: jobId,
                done: jobId,
              },
            },
            created_by_id: currentUser.id,
            organization_id: currentUser.organization_id,
          }}
          context={{
            ...signalMetadata,
            id: signalMetadata.id as unknown as number,
            definition: signal.definition,
            signal_published: signal.is_published,
            universe: universe,
            start_date: startDate,
            end_date: endDate,
          }}
          contextSingleton
          tags={[ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.SIGNAL]}
          reportAnalysis={ts.enums.REPORT_ENUMS.REPORT_ANALYSIS_TYPE_ENUM.SIGNAL_ANALYSIS}
          setAlert={(): undefined => undefined}
          updateAnalysis={(): undefined => undefined}
          reportTitle={'Analysis'}
          filterJobPaths={filterJobs}
          noLayoutChanges
          noEdit
          noHeader
          disableRegenerate
          disableWidgetHeaderUpdates
          disableWidgetTitleEdit
          pathReportRoot="single"
          depsTimestamp={[]}
          reportHelp={{
            document: ts.enums.UI_RESOURCE_TYPE_ENUM.SIGNAL,
            contentPath: '/analysis',
          }}
        />
      </mui.core.Box>
    </>
  );
};

export default SingleAnalysis;
