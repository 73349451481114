import { _, helpers, ts } from '_core';

import { DraftParams } from './types';
import { transform as cumRetTransform } from '../backtest-cumulative-returns/helpers';

// prepareLinearData
export const transform = (
  data: ts.types.widgets.LinearData,
  isBasket: boolean,
  hasBenchmark: boolean,
  timeSeries: ts.types.timeSeries.TimeSeries[] = []
) => {
  const newData = cumRetTransform(data, isBasket, hasBenchmark, false) as ts.types.widgets.LinearData;

  const tsHandles = timeSeries.map((ts) => ts.handle);
  Object.keys(data).forEach((key) => {
    if (tsHandles.includes(key)) {
      const foundTs = timeSeries.find((ts) => ts.handle == key);
      const newKey = helpers.resourcesUtils.getResourceLabel(foundTs);
      newData[newKey] = data[key];
    }
  });
  return newData;
};

export const validate = (params: DraftParams) => {
  if (_.isEmpty(params.analysis_time_series_ids)) return ['You need to add at least one time series.'];
  if (params.analysis_time_series_ids.length > 3) return ['You can only add a max of three time series.'];
  return [];
};
