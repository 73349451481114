import { _ } from '_core';

import { DraftParams } from './types';

export const validate = (params: DraftParams, _context: any, isGeneralAnalysis: boolean) => {
  const errors = [];

  if (isGeneralAnalysis && _.isNil(params.time_series_id)) errors.push('Time Series (Y axis) is a required field.');

  if (_.isNil(params.compare_time_series_id))
    if (isGeneralAnalysis) errors.push('Time Series (X axis) is a required field.');
    else errors.push('Time Series is a required field.');

  return errors;
};
