import { _, config, moment, mui, React, ts } from '_core';

import { FixedCostContext } from '../fixed-costs-context';

type CountriesProps = {
  row: ts.types.fixedCosts.FixedCostDraft;
};

const Countries: React.FC<CountriesProps> = ({ row }): React.ReactElement => {
  const [country, setCountry] = React.useState(_.find(config.countries, (c) => c.code == row.country_code) || null);

  const fixedCostsContext = React.useContext(FixedCostContext);
  const { updatePropertyById } = fixedCostsContext;

  const isDefault = row.country_code == 'DEFAULT';

  const isNew = moment(row.updated_at).isSame(moment(row.created_at));
  return (
    <>
      {!isDefault ? (
        <mui.core.Autocomplete
          openOnFocus
          fullWidth
          options={config.countries}
          groupBy={(c) => c.continent}
          disabled={!isNew}
          value={country}
          onChange={(e, val) => {
            setCountry(val as ts.types.country.Country);
            updatePropertyById(row.id, 'country_code', (val as ts.types.country.Country)?.code);
          }}
          getOptionLabel={(option) => (option as ts.types.country.Country).name}
          renderInput={(p) => <mui.core.TextField {...p} fullWidth variant="standard" required size="small" />}
        />
      ) : (
        <mui.core.MenuItem value="DEFAULT" sx={{ fontSize: '12px' }} disabled>
          DEFAULT
        </mui.core.MenuItem>
      )}
    </>
  );
};

export default Countries;
