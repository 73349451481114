/* eslint-disable max-len */
import { _, api, mui, React, ts, ui } from '_core';

import * as columns from 'views/preferences/columns-config';

import useGetPreferences from '_components/use-get-preferences';

import { Params } from './types';
import { getCSVColumns } from '../../common/charts/utils';

type ChartProps = {
  file: string;
  jobId: number;
  params: Params;
};

const Chart: React.FC<ChartProps> = ({ file, jobId, params }) => {
  const [downloading, setDownloading] = React.useState(false);

  const downloadJobData = async () => {
    const s3FileObject = {} as Record<string, string>;
    s3FileObject[`${jobId}`] = file;
    api.s3
      .signPaths(s3FileObject, jobId)
      .then((signed: { data: { jobs: any } }) => {
        const url = signed.data['jobs'][jobId][jobId];
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', url);
        document.body.appendChild(link);
        link.click();
      })
      .catch((e: any) => console.log(e));
  };

  const handleDownloadAllData = async () => {
    setDownloading(true);
    try {
      await downloadJobData();
      setDownloading(false);
    } catch {
      setDownloading(false);
    }
  };

  const [loadingPreferences, userColumnsPreferences, , selPref] = useGetPreferences(
    ts.enums.PREFERENCES_KEY_ENUM.SIGNAL_PREVIEW_COLS,
    params.current_view
  );

  const previewCols = React.useMemo(
    () =>
      getCSVColumns(
        (userColumnsPreferences as ts.types.components.dataGrid.ColumnPreferences)[
          ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.MAIN
        ],
        columns.signalPreviewColumns[ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.MAIN]
      ),
    [userColumnsPreferences]
  );

  const colsDiff = React.useMemo(() => !_.isEqual(previewCols, params.columns), [previewCols]);

  if (loadingPreferences) return <ui.CenteredLoader />;

  return (
    <mui.core.Box
      display="flex"
      marginTop="15vh"
      flexDirection="column"
      flex={1}
      alignItems="center"
      justifyContent="center"
    >
      <>
        {colsDiff ? (
          <ui.EmptyMessage>
            User preferences for <strong>{selPref?.title || 'Default'}</strong> changed. Please go to manager to update
            to latest.
          </ui.EmptyMessage>
        ) : (
          <ui.EmptyMessage>
            This downloadable data was generated using the <strong>{selPref?.title || 'Default'}</strong> preference.
          </ui.EmptyMessage>
        )}

        <ui.Button
          variant="outlined"
          color="primary"
          onClick={handleDownloadAllData}
          loading={downloading}
          progressText={'Downloading'}
          style={{ marginRight: '8px' }}
          disabled={downloading}
        >
          Download
        </ui.Button>
      </>
    </mui.core.Box>
  );
};

export default Chart;
