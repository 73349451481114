import React from 'react';

import ShareActionOptions from './share-modal-options';
import ShareActionUrl from './share-modal-url';
import api from '../../api';
import * as enums from '../../enums';
import { pluralize } from '../../helpers';
import { _, mui, useSelector } from '../../libs';
import * as types from '../../types';
import DialogTitle from '../dialog-title';
import EmptyMessage from '../empty-message';

type ShareActionProps = {
  resource: types.common.ResourceDraft;
  resourceType: enums.RESOURCES_TYPES_ENUM;
  updateResource: (_d: types.common.ResourceDraft) => Promise<void>;
  getUnshareDeps: (_sw?: number[]) => types.common.ResourceDeps[];
  customDisabled?: boolean;
};

/**
 * Component that handles sharing resources with other users in the organization
 *
 * @requires Redux Store:
 * - state.resources.users: List of users in the organization
 * - state.auth.currentUser.id: Current user ID
 * - state.resources: All resources data
 *
 * @param resource - The resource object containing information about the shared resource
 * @param resourceType - Type of resource being shared
 * @param updateResource - Function to update the resource
 * @param getUnshareDeps - Function to get dependencies that are not shared
 * @param customDisabled - Optional flag to disable the share button
 *
 * @returns Rendered component with share dialog and options
 */
const ShareAction: React.FC<ShareActionProps> = ({
  resource,
  resourceType,
  updateResource,
  getUnshareDeps,
  customDisabled,
}): React.ReactElement => {
  const [open, setOpen] = React.useState(false);
  const [notClosable, setNotClosable] = React.useState(false);

  const usersRedux = useSelector((state: types.BaseStore) => state.resources.users);
  const currUserId = useSelector((state: types.BaseStore) => state.auth.currentUser.id);
  const [alertMessage, setAlertMessage] = React.useState<types.common.Alert>();

  const resources = useSelector((state: types.BaseStore) => state.resources);

  // Handle resource open when we have unshared dependencies due to later changes
  React.useEffect(() => {
    if (_.isEmpty(resource.shared_with)) return;
    const localDeps = getUnshareDeps();
    if (!_.isEmpty(localDeps)) {
      setOpen(true);
      setNotClosable(true);
      setAlertMessage({
        severity: enums.ALERT_SEVERITY_ENUM.WARNING,
        message:
          // eslint-disable-next-line max-len
          'You are using dependencies that are not accessible by all the users this resource is shared with. Please update the permissions.',
      });
    }
    return () => setNotClosable(false);
  }, [resource, resources]);

  const users = React.useMemo(() => {
    return usersRedux.filter((u) => u.id !== currUserId && u.email !== 'superadmin@finsera.com');
  }, []);

  const [step, setStep] = React.useState(1);

  const handleClose = () => {
    setOpen(false);
    setStep(1);
  };

  const buildPromises = (selUsers: number[], deps: types.common.ResourceDeps[]) => {
    const promises = [] as JQuery.jqXHR<{ data: types.common.Resource }>[];
    deps.forEach((dep) => {
      promises.push(
        api[pluralize.plural(dep.resourceType) as 'universes'].update(dep.id, {
          shared_with: selUsers,
        })
      );
    });

    return promises;
  };

  const shareAction = async (selUsers: number[], deps: types.common.ResourceDeps[]) => {
    setAlertMessage(null);
    try {
      if (_.isEmpty(selUsers)) {
        await updateResource({ shared_with: [] });
        handleClose();
        return;
      }

      await Promise.all(buildPromises(selUsers, deps));
      await updateResource({ shared_with: selUsers });

      setStep(2);
    } catch {
      setAlertMessage({
        severity: enums.ALERT_SEVERITY_ENUM.ERROR,
        message: 'Unable to share the resource. Please try again later.',
      });
    }
  };

  return (
    <>
      <mui.core.Button
        onClick={() => setOpen(true)}
        variant="outlined"
        color="primary"
        size="small"
        disabled={_.isNull(customDisabled) ? !resource.is_valid : customDisabled}
      >
        Share
      </mui.core.Button>

      <mui.core.Dialog open={open} onClose={notClosable ? undefined : handleClose} maxWidth="xs" fullWidth>
        <DialogTitle closeAction={notClosable ? undefined : handleClose}>Share in organization</DialogTitle>
        {alertMessage && (
          <mui.lab.Alert
            onClose={notClosable ? undefined : () => setAlertMessage(null)}
            severity={alertMessage.severity}
            sx={{ marginTop: '-1rem' }}
          >
            {alertMessage.message}
          </mui.lab.Alert>
        )}
        {_.isEmpty(users) ? (
          <EmptyMessage>You are the only user in your organization.</EmptyMessage>
        ) : step == 1 ? (
          <ShareActionOptions
            resource={resource}
            users={users}
            shareAction={shareAction}
            getUnshareDeps={getUnshareDeps}
          />
        ) : (
          <ShareActionUrl resource={resource} resourceType={resourceType} />
        )}
      </mui.core.Dialog>
    </>
  );
};

export default ShareAction;

ShareAction.defaultProps = {
  customDisabled: null,
};
