import { _ } from '_core';

import { DraftParams } from './types';

export const validate = (params: DraftParams) => {
  if (_.isNil(params.critical_pvalue)) return ['Critical PValue is a required field.'];
  if (params.critical_pvalue < 0 || params.critical_pvalue > 1) return ['Critical PValue must be between 0 and 1.'];
  if (_.isEmpty(params.risk_factors)) return ['Risk Factors is a required field.'];
  return [];
};
