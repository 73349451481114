import { hooks, mui, React, ts, ui } from '_core';

import { DraftParams } from './types';
import Variables from './variables-field';
import { getBucketDefaultValue } from '../../common/utils/attribution-utils';

type FormProps = {
  params: DraftParams;
  setParams: (_p: DraftParams) => void;
  contextRiskModel?: number;
};

const Form: React.FC<FormProps> = ({ params, setParams, contextRiskModel }): React.ReactElement => {
  const uiStyles = hooks.useUiStyles();

  const BUCKET_TYPES_ENUM = ts.enums.BUCKET_TYPES_ENUM;

  const [buckets, setBuckets] = React.useState(
    // Check if params.buckets is an array for backward compatibility.
    Array.isArray(params?.buckets) ? params?.buckets[0] : params?.buckets
  );

  const [variables, setVariables] = React.useState(
    // Check if params.variables is an array for backward compatibility.
    Array.isArray(params?.variables) ? params?.variables[0] : params?.variables
  );

  const [removeResiduals, setRemoveResiduals] = React.useState(params.without_residuals || false);

  const bucketOptions = [
    {
      value: BUCKET_TYPES_ENUM.QUANTILES,
      title: 'Quantiles',
    },
    {
      value: BUCKET_TYPES_ENUM.INDUSTRY,
      title: 'Industry',
    },
    {
      value: BUCKET_TYPES_ENUM.RISK_MODEL,
      title: 'Risk Model',
    },
    {
      value: BUCKET_TYPES_ENUM.ASSET_MASTER,
      title: 'Asset Master',
    },
  ];

  const assetsOptions = ['quotation_country', 'issuer_country', 'currency'];

  const industryOptions = ['rbics_l1_name', 'rbics_l2_name', 'rbics_l3_name', 'rbics_l4_name'];

  React.useEffect(() => {
    const data = {
      buckets,
      variables,
      without_residuals: removeResiduals,
    };

    setParams({ ...params, ...data });
  }, [buckets, variables, removeResiduals]);

  const handleBucketsChange = (val: ts.types.widgets.common.BucketValue) => {
    setBuckets(val);
  };

  return (
    <mui.core.Box style={{ padding: '8px' }} sx={uiStyles.widgetEditMode}>
      <mui.core.Box mt={4}>
        <mui.core.Typography variant="body2" fontWeight="fontWeightBold">
          Bucket Analysis
        </mui.core.Typography>
      </mui.core.Box>

      <mui.core.Box mt={4}>
        <ui.Buckets
          value={buckets}
          setValue={handleBucketsChange}
          options={bucketOptions}
          assetMasterOptions={assetsOptions}
          industryOptions={industryOptions}
          getBucketDefaultValue={(value: ts.enums.BUCKET_TYPES_ENUM) => getBucketDefaultValue(value, contextRiskModel)}
        />
      </mui.core.Box>
      <mui.core.Box mt={3}>
        <mui.core.Typography variant="body2" fontWeight="fontWeightBold">
          Linear Decomposition
        </mui.core.Typography>
      </mui.core.Box>

      <mui.core.Box>
        <Variables
          variables={variables}
          setVariables={setVariables}
          variablesOptions={[ts.enums.EXPLANATORY_VARIABLE_TYPES_ENUM.RISK_FACTORS]}
        />
      </mui.core.Box>

      <mui.core.FormControlLabel
        control={
          <mui.core.Switch
            size="small"
            checked={removeResiduals}
            onChange={() => setRemoveResiduals(!removeResiduals)}
          />
        }
        label="Exclude Residuals"
      />
    </mui.core.Box>
  );
};
export default Form;
