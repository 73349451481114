/* eslint-disable no-unused-vars */
import { mui } from '../../libs';
import { GridFilterItem, GridLogicOperator } from '../../libs/mui/data-grid';
import { BaseStore } from '../../types/base-store';
import { OrganizationFeatures } from '../organization';

export type ColumnsData = {
  /** Unique identifier for the column */
  key: string;
  /** Display name of the column. Can be a string or React element */
  name: string | React.ReactElement;
  /** Optional alternate key for the column */
  columnKey?: string;
  /** Clean text version of the column name without formatting */
  cleanName?: string;
  /** Function that determines if column should be shown based on organization features */
  condition?: (_f: OrganizationFeatures) => boolean;
  /** Function to format the cell content as a React element */
  formatter?: (_props: any) => React.ReactElement;
  /** Function to format the column header name with context and resources */
  nameFormatter?: (_k: string, _context: any, _resources: BaseStore['resources']) => string | React.FunctionComponent;
  /** Function to get clean text version of column name with optional context and resources */
  cleanNameFormatter?: (_k: string, _context?: any, _resources?: BaseStore['resources']) => string;
  /** Function to determine if a key belongs to this column's members */
  members?: (_k?: string) => boolean;
  /** Custom cell renderer function */
  renderCell?: (_gridProps: mui.dataGrid.GridRenderCellParams) => (_props: any) => React.ReactElement;
  /** Function to get the display value for a cell */
  valueGetter?: mui.dataGrid.GridColDef['valueGetter'];
  /** Type of filter to apply to this column */
  filter?: string;
  /** Data type of the column */
  type?: string;
  /** Width of the column in pixels */
  width?: number;
  /** Start date for date range columns */
  start_date?: string;
  /** End date for date range columns */
  end_date?: string;
  /** Handle key for the column */
  key_handle?: string;
  /** Whether to ignore the value getter for this column */
  ignoreValueGetter?: boolean;
  /** Whether the column should be frozen (pinned) */
  frozen?: boolean;
  /** Whether this is an index column that should be styled like frozen columns */
  isIndex?: boolean;
  /** Whether this is a fixed column that cannot be deleted or moved */
  isFixed?: boolean;
  /** Category or group this column belongs to */
  category?: string;
  /** Custom sort comparator function for the column */
  defaultSortComparator?: (_a: any, _b: any) => number;
}[];

export enum FILTER_CONJUCTION_ENUM {
  AND = 'and',
  OR = 'or',
}

export type SortColumns = {
  columnKey: string;
  direction: 'ASC' | 'DESC';
};

export type Row = Record<string, any>;

export type OrderBy = {
  column: string;
  sort: 'asc' | 'desc';
};

export type GridSortBy = {
  columnKey: string;
  direction: 'ASC' | 'DESC';
}[];

export type ResponseLoadData = {
  data: Row[];
  columns?: string[];
  pagination: {
    limit?: number;
    total?: number;
    offset?: number;
    count?: number;
  };
};

export type ResponseDownloadFullData = {
  file_name?: string;
  path?: string;
};

export type LoadData = (
  _offset: number,
  _orderBy: OrderBy,
  _valueFilters: ValueFilters,
  _logicOperator?: mui.dataGrid.GridLogicOperator,
  _triggerLoadOnChange?: boolean
) => Promise<ResponseLoadData>;

export type DownloadFullData = (
  _rename: Record<string, string>,
  _colOrder: string[],
  _valueFilters?: ValueFilters
) => Promise<ResponseDownloadFullData>;

export type GenerateColumns = (_colKeys: string[], _userPreferences: ColumnPreferences) => ColumnsData;

export enum COLUMNS_TYPE_ENUM {
  STRING = 'string',
  BOOLEAN = 'boolean',
  NUMBER = 'number',
  DATE = 'date',
}

export type ValueFilters = {
  column: string;
  value: any;
  operator?: string;
  column_type?: string;
  replace_nans_to?: any;
}[];

export type ColumnsWidth = {
  columnKey: string;
  columnWidth: number;
}[];

export type TableParams = {
  sort_by: GridSortBy;
  cols_width: ColumnsWidth;
  current_view: number;
  filters?: ValueFilters;
  group_by?: string[];
  filter_conjuction?: mui.dataGrid.GridLogicOperator;
  fixed_width?: {
    fill: boolean;
    ignore_index: boolean;
    width: number;
  };
};

export type TableDtypes = Record<string, string>;

export type TableParamsSheet = Record<string, TableParams>;

export type TableDtypesSheet = Record<string, TableDtypes>;

export type GridFilterModel = {
  items: (GridFilterItem & { column_type: string })[];
  logicOperator?: GridLogicOperator;
};

export type HeatMapColors = {
  startColor?: number[];
  endColor?: number[];
  middleColor?: number[];
  minValue?: number;
  maxValue?: number;
};

export type ColumnPreferences = {
  [key: string]: {
    columns?: string[];
    names?: Record<string, string>;
    frozenColumns?: string[];
  };
};
