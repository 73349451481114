import { _, am4charts, am4core, helpers, ts } from '_core';

import { createImageHeader } from './create-image-header';

type CreateRadarChartProps = {
  id: string;
  data: ts.types.widgets.RadarData;
  fullScreen: boolean;
  labels: ts.types.widgets.RadarData;
  dimensions: string[];
  exportTitle: string;
};

const createRadarChart = ({ id, data, fullScreen, labels, dimensions, exportTitle }: CreateRadarChartProps) => {
  const chart = am4core.create(`chart-${id}-${fullScreen ? 'fs' : 'sw'}`, am4charts.RadarChart);

  chart.data = data;

  const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis() as any);
  categoryAxis.renderer.labels.template.fontSize = 11;
  (categoryAxis.dataFields as any).category = 'spoke';

  categoryAxis.renderer.labels.template.adapter.add('textOutput', function (text: string) {
    if (text == 'annualized_ir') return 'Annualized IR';
    return _.startCase(text);
  });

  const maxValue = _.max((data || []).map((el) => _.max(Object.values(_.omit(el, 'spoke')))));

  const valueAxis = chart.yAxes.push(new am4charts.ValueAxis() as any);
  valueAxis.min = 0;
  valueAxis.max = maxValue * 1.1;

  // eslint-disable-next-line no-underscore-dangle
  valueAxis.renderer.__disabled = true;
  dimensions.forEach((dim, index) => {
    const series = chart.series.push(new am4charts.RadarSeries());
    series.dataFields.valueY = dim;
    series.dataFields.categoryX = 'spoke';
    series.dataFields.customValue = labels && labels?.[0]?.[dim]?.toString();
    series.name = dim;
    series.fill = am4core.color(helpers.getColor(index));
    series.stroke = am4core.color(helpers.getColor(index));
    series.strokeWidth = 1;
    const bullets = series.bullets.push(new am4charts.CircleBullet());
    bullets.events.on('over', (ev) => {
      const obj = labels.find((item) => item.spoke == (ev.target.dataItem as any).categoryX);
      const spoke = _.startCase(obj['spoke']);
      const values = _.omit(obj, 'spoke');
      const value = typeof values[dim] === 'number' ? values[dim].toFixed(2) : values[dim];

      series.tooltipText = `${spoke}: ${value}`;
      series.tooltip.fontSize = 11;
      series.tooltip.background.fill = am4core.color('#3C5688');
      series.tooltip.background.cornerRadius = 3;
      series.tooltip.background.strokeWidth = 0;
    });
  });

  chart.cursor = new am4charts.RadarCursor();
  chart.cursor.lineY.disabled = true;
  chart.cursor.lineX.disabled = true;

  chart.xAxes.values[0].tooltip.disabled = true;

  chart.logo.disabled = true;
  chart.fontSize = 11;

  chart.legend = new am4charts.Legend();

  chart.exporting.menu = new am4core.ExportMenu();
  chart.exporting.menu.align = 'left';
  chart.exporting.filePrefix = 'chart-data';

  chart.exporting.menu.items = [
    {
      label: '...',
      menu: [
        { type: 'jpg', label: 'JPG' },
        { type: 'png', label: 'PNG' },
        { type: 'csv', label: 'CSV' },
      ],
    },
  ];

  createImageHeader({ chart, title: exportTitle, fullScreen, id });

  return chart;
};

export default createRadarChart;
