import { _, am4charts, am4core, helpers, ts } from '_core';

import { createSeries } from './create-series';
import { addExportAdapter } from './export';
import { createImageHeader } from '../create-image-header';
import { lineChartFormat } from '../formatters/line-chart';
import * as utils from '../utils';

type createLineChartTypes = {
  id: string;
  data: ts.types.widgets.TableData | ts.types.widgets.LinearData;
  fullScreen: boolean;
  setShowLegend: (_b: boolean) => void;
  exportTitle: string;

  exportSubTitle?: string;
  percent?: boolean;
  scale?: 'linear' | 'log';
  yAxisLabel?: string;
  xAxisLabel?: string;
  yAxis?: string;
  xAxis?: string;
  xIsDate?: boolean;
  dotted?: string[];
  title?: string;
  aggregation?: 'open' | 'close' | 'outlier' | 'min' | 'max';
  integerOnly?: boolean;
  xAxisMaxPrecision?: number;
  yAxisMaxPrecision?: number;
  showEndValueLabel?: boolean;

  seriesOrder?: (_c: string[]) => string[];
  customDownloadFormatting?: (_d: Record<string, string | number>[]) => Record<string, string | number>[];
  prepareData?: (_d: ts.types.widgets.LinearData) => ts.types.widgets.LinearData;
  setExternalEmbed?: (_json: ts.types.analysis.EmbedChartData) => void;

  // If data is already sent as linear data
  widgetIsEmbedded?: {
    download: boolean;
  };
  withoutSeries?: string[];
  addZeroOn?: string;
  skipFormatter?: boolean;
  showPeriodSelector?: boolean;
};

const EMPTY_ARRAY: any[] = [];

const createLineChart = (args: createLineChartTypes) => {
  const {
    id,
    data,
    fullScreen,
    setShowLegend,
    exportTitle,

    exportSubTitle = null,
    percent = true,
    scale = 'linear',
    yAxisLabel = '',
    xAxisLabel = '',
    xAxis = 'date',
    yAxis = 'value',
    xIsDate = true,
    dotted = EMPTY_ARRAY, // Keys for lines that we need as dotted
    title = null,
    aggregation = 'close', // open, close, outlier, min, max
    integerOnly = false, // Round numbers and display only integers
    xAxisMaxPrecision = undefined,
    yAxisMaxPrecision = undefined,
    showEndValueLabel = false,
    showPeriodSelector = false,
    seriesOrder = (c) => c,
    customDownloadFormatting = undefined,
    prepareData = undefined,
    setExternalEmbed = null,

    widgetIsEmbedded = undefined,
    withoutSeries = undefined,
    addZeroOn = undefined,
    skipFormatter = false,
  }: createLineChartTypes = args;
  // Casting as any in case this comes from an embbeded widget which already has the
  // data prepared.
  let linearData = data as any as ts.types.widgets.LinearData;

  if (!widgetIsEmbedded) {
    // Format table as line chart
    if (!skipFormatter)
      linearData = lineChartFormat(data as ts.types.widgets.TableData, scale, withoutSeries, xAxis, addZeroOn);

    // Prepare data
    if (prepareData) linearData = prepareData(linearData);

    // Add Real value
    linearData = utils.addReal(linearData, yAxis as keyof ts.types.widgets.LinearData[string][0]);

    // Apply sort by
    const entries = Object.entries(linearData ?? {});
    const sortedKeys = seriesOrder(entries.map(([key, _v]) => key));
    linearData = _(sortedKeys)
      .map((key) => entries.find(([k, _v]) => key == k))
      .fromPairs()
      .value();
  }

  const rawData = linearData;
  const chart = am4core.create(`chart-${id}-${fullScreen ? 'fs' : 'sw'}`, am4charts.XYChart);

  chart.paddingRight = 20;
  if (setExternalEmbed)
    setExternalEmbed({
      id,
      widgetTitle: exportTitle,
      widgetSubTitle: exportSubTitle,
      chart_type: 'line-chart',
      args: helpers.embed.argsToStringifiable({
        ...args,
        data: linearData,
      }),
    });

  const diff = utils.getLinearChartLimitsDiff(linearData);

  let numberFormatter = chart.numberFormatter.numberFormat; // Assigning the AMCharts default value for the numberFormat
  if (diff <= 1e-5 && !percent && !(diff == 0)) {
    numberFormatter = '#.##e';
  } else {
    if (percent) numberFormatter = '#.##%';
    if (integerOnly) {
      numberFormatter = '#.';
      if (percent) numberFormatter += '##%';
    }
  }

  chart.numberFormatter.numberFormat = numberFormatter;

  if (title) {
    const chartTitle = chart.titles.create();
    chartTitle.text = title;
    chartTitle.fontSize = 15;
    chartTitle.marginBottom = 20;
  }

  let valueAxisX = null;
  if (xIsDate) {
    valueAxisX = chart.xAxes.push(new am4charts.DateAxis());
    valueAxisX.baseInterval = { timeUnit: 'day', count: 1 };
    valueAxisX.tooltipDateFormat = 'MMM dd yyyy';
    valueAxisX.renderer.labels.template.adapter.add('text', function (text) {
      return text && text.replace(' ', '\n');
    });
    valueAxisX.renderer.labels.template.textAlign = 'middle';
  } else valueAxisX = chart.xAxes.push(new am4charts.ValueAxis());

  valueAxisX.renderer.grid.template.location = 0;
  valueAxisX.tooltip.fontSize = 11;
  valueAxisX.tooltip.background.fill = am4core.color('#3C5688');
  valueAxisX.tooltip.background.cornerRadius = 3;
  valueAxisX.tooltip.background.strokeWidth = 0;

  valueAxisX.title.text = xAxisLabel;
  valueAxisX.maxPrecision = xAxisMaxPrecision;
  if (!fullScreen) valueAxisX.renderer.minGridDistance = 40;

  const valueAxisY = chart.yAxes.push(new am4charts.ValueAxis());
  valueAxisY.tooltip.disabled = true;
  valueAxisY.tooltip.fontSize = 11;
  valueAxisY.tooltip.background.fill = am4core.color('#3C5688');
  valueAxisY.tooltip.background.cornerRadius = 3;
  valueAxisY.tooltip.background.strokeWidth = 0;
  valueAxisY.renderer.minWidth = 35;
  valueAxisY.title.text = yAxisLabel;
  valueAxisY.maxPrecision = yAxisMaxPrecision;
  valueAxisY.renderer.opposite = false;

  const endValueAxisY = chart.yAxes.push(new am4charts.ValueAxis());
  endValueAxisY.renderer.minWidth = 35;
  endValueAxisY.tooltip.fontSize = 11;
  endValueAxisY.tooltip.background.fill = am4core.color('#3C5688');
  endValueAxisY.tooltip.background.cornerRadius = 3;
  endValueAxisY.tooltip.background.strokeWidth = 0;
  endValueAxisY.renderer.opposite = true;
  endValueAxisY.renderer.grid.template.disabled = true;
  endValueAxisY.renderer.labels.template.disabled = true;
  endValueAxisY.renderer.axisFills.template.disabled = true;
  endValueAxisY.renderer.line.disabled = true;

  if (scale == 'log') {
    valueAxisY.renderer.labels.template.adapter.add('html', function (_l, target) {
      // if (percent) target.text.replace('00%', '');
      let power = target.text ? target.text.replace('%', '') : '';
      if (percent) power = `${parseInt(power) / 100 + 2}`;

      return percent ? `10<sup>${power}</sup> %` : `10<sup>${power}</sup>`;
    });
  }

  chart.logo.disabled = true;
  chart.cursor = new am4charts.XYCursor();
  chart.cursor.maxTooltipDistance = 0;

  addExportAdapter(chart, rawData, xAxis, customDownloadFormatting, widgetIsEmbedded);
  createImageHeader({ chart, title: exportTitle, fullScreen, id });
  createSeries(
    id,
    chart,
    linearData,
    aggregation,
    valueAxisY,
    endValueAxisY,
    xIsDate,
    xAxis,
    yAxis,
    dotted,
    showPeriodSelector,
    diff,
    percent,
    integerOnly,
    showEndValueLabel,
    fullScreen,
    setShowLegend
  );

  return chart;
};

export default createLineChart;
