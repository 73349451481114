import api from '../../../api';
import * as enums from '../../../enums';
import * as helpers from '../../../helpers';
import { _ } from '../../../libs';
import * as types from '../../../types';

/**
 * Loads holdings data from mapped and unmapped files
 * @param files - Object containing paths to mapped and unmapped files
 * @param files.mapped_file - Path to the mapped holdings file
 * @param files.unmapped_file - Path to the unmapped holdings file
 * @param resourceId - ID of the portfolio or other resource
 * @param holdingsType - Type of holdings (e.g. 'portfolioHoldings')
 * @returns Object containing holdings data and column definitions
 */
export const loadData = async (
  files: { mapped_file: string; unmapped_file: string },
  resourceId: number,
  resourceType: 'portfolio_holdings' | 'app_portfolio_holdings'
) => {
  const mappedFileSplit = files.mapped_file?.split('/');
  const mappedFile = mappedFileSplit?.slice(2, mappedFileSplit?.length).join('/');

  const unmappedFileSplit = files.unmapped_file?.split('/');
  const unmappedFile = unmappedFileSplit?.slice(2, unmappedFileSplit?.length).join('/');

  let holdingsColumns = [];

  const rebColumns = Object.values(enums.REBALANCE_MAP_COLS);
  const finseraColumns = ['finsera_cusip', 'finsera_country', 'finsera_isin', 'finsera_fid', 'finsera_ticker'];

  holdingsColumns = [...rebColumns, ...finseraColumns];

  const resp = await api.uiApi.getRebalanceData({
    data: {
      mapped_file: mappedFile,
      unmapped_file: unmappedFile,
      columns: holdingsColumns,
      resource_id: resourceId,
      ignore_cache: _.cookie('debug') && _.cookie('debug') === '1234', // TODO temp replace,
      // ignore_cache: debug,
      resource: resourceType,
    },
  });

  return {
    data: _.isEmpty(resp?.data) ? [] : resp?.data,
    columns: resp?.columns,
  } as { data: types.widgets.TableData; columns: string[] };
};

export const submitHoldings = async (
  holdingsId: number,
  holdingsColumns: { title: string; field: string }[],
  holdingsData: types.rebalance.HoldingsData,
  navUpdate: { nav: number; cash: number },
  token: string,
  resourceType: 'portfolio_holdings' | 'app_portfolio_holdings'
) => {
  const csv = helpers.csv
    .toCsv(
      holdingsData,
      holdingsColumns.map((c) => ({ key: c.field })),
      enums.SEPARATORS_ENUM.COMMA,
      false
    )
    .join('');

  const column_map = {} as Record<string, string>;
  holdingsColumns.forEach((c) => (column_map[c.field] = c.field));

  let holdingsParamsData = { column_map, csv } as Record<string, any>;

  holdingsParamsData = { ...holdingsParamsData, ...navUpdate, confirmed: true };

  if (resourceType === 'portfolio_holdings') {
    await api.user_api.portfolio_holdings_post_holdings(token, holdingsId, holdingsParamsData);
  } else {
    await api.user_api.app_portfolio_holdings_post_holdings(token, holdingsId, holdingsParamsData);
  }

  if (resourceType === 'portfolio_holdings') {
    const resp = await api.portfolio_holdings.get(holdingsId);
    return resp['data'];
  }

  const resp = await api.app_portfolio_holdings.get(holdingsId);
  return resp['data'];
};
