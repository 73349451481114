import { _, api, config, helpers, ts } from '_core';

type SignalApiResponse = {
  data: ts.types.signal.Signal;
};

type DatasetApiResponse = {
  data: ts.types.signal.Dataset;
};

type DerivedUniverseApiResponse = {
  data: ts.types.universe.Universe;
};

export const _createDatasetsCombined = async (signalId: number, datasets: ts.types.signal.Dataset[]) => {
  const parsedDatasets = datasets.map((ds) => {
    return { ..._.omit(ds, ['id', 'signal_id']), signal_id: signalId };
  });

  const res = await api.combined.run({
    signal_datasets: {
      create: parsedDatasets,
    },
  });

  return res.signal_datasets?.create;
};

export const createSignal = async (
  newData: ts.types.signal.SignalDraft,
  callback: (_data: ts.types.signal.Signal, _datasets: { data: ts.types.signal.Dataset }[]) => void,
  errorCallback: (_error: string) => void,
  datasets: ts.types.signal.Dataset[] | undefined
) => {
  try {
    const resp: SignalApiResponse = await api.signals.create(newData);

    let respDatasets = [];
    if (datasets) respDatasets = await _createDatasetsCombined(resp.data.id, datasets);
    callback(resp.data, respDatasets);
  } catch (error) {
    if (_.isEmpty(datasets))
      errorCallback(`Error creating signal - ${helpers.parseApiError(error as ts.types.common.ApiError)}`);
    else {
      // eslint-disable-next-line max-len
      errorCallback(
        `Error creating signal and/or datasets - ${helpers.parseApiError(error as ts.types.common.ApiError)}`
      );
    }
  }
};

export const updateSignal = async (
  id: number,
  newData: ts.types.signal.SignalDraft,
  callback: (_data: ts.types.signal.Signal) => void,
  errorCallback: (_error: string) => void
) => {
  try {
    const resp: SignalApiResponse = await api.signals.update(id, { ...newData });
    callback(resp.data);
  } catch (error) {
    errorCallback(`Error updating signal - ${helpers.parseApiError(error as ts.types.common.ApiError)}`);
  }
};

export const updateExplorationPreview = async (
  id: number,
  signal: ts.types.signal.Signal,
  newData: ts.types.signal.ExplorationPreviewRecordDraft,
  callback: (_data: ts.types.signal.ExplorationPreviewRecord) => void,
  errorCallback: (_error: string) => void
) => {
  try {
    if (signal.shared) return;
    const resp = await api.signal_exploration_previews.update(id, newData);
    callback(resp.data);
  } catch (error) {
    errorCallback(`Error updating signal - ${helpers.parseApiError(error as ts.types.common.ApiError)}`);
  }
};

export const updateDataset = async (
  id: number,
  signal: ts.types.signal.Signal,
  newData: ts.types.signal.DatasetDraft,
  callback: (_data: ts.types.signal.Dataset) => void,
  errorCallback: (_error: string) => void
) => {
  try {
    if (signal.shared) return;
    const resp: DatasetApiResponse = await api.signal_datasets.update(id, newData);
    callback(resp.data);
  } catch (error) {
    errorCallback(`Error updating signal parameters - ${helpers.parseApiError(error as ts.types.common.ApiError)}`);
  }
};

export const keepCurrent = async (
  signal: ts.types.signal.Signal,
  ds: ts.types.signal.DatasetDraft,
  callback: (_data: ts.types.signal.Dataset, _universe?: ts.types.universe.Universe) => void,
  errorCallback: (_error: string) => void
) => {
  try {
    if (signal.shared) return;
    const resp = await api.dates.keep_current({
      resource_id: ds.id,
      resource_type: 'signal_datasets',
      frequency: ds.frequency as ts.enums.FREQUENCY_ENUM,
      // Until we don't have a universe, our keep current will be just the max date we can have
      universe_id: ds.universe_id || undefined,
      max_date: config.features.end_date,
    });
    callback(resp.resource, resp.universe);
  } catch (error) {
    errorCallback(`Error updating signal dates - ${helpers.parseApiError(error as ts.types.common.ApiError)}`);
  }
};

export const combinedDatasets = async (
  datasetsQuery: Record<string, any>,
  callback: (_data: Record<string, { data: ts.types.signal.Dataset }[]>) => void,
  errorCallback: (_error: string) => void
) => {
  try {
    const respDatasets = await api.combined.run({
      signal_datasets: datasetsQuery,
    });

    callback(respDatasets?.['signal_datasets']);
  } catch (error) {
    errorCallback(`Error running combined on datasets - ${helpers.parseApiError(error as ts.types.common.ApiError)}`);
  }
};

export const updateDefinition = async (
  id: number,
  definition: ts.types.signal.DefinitionDraft,
  isValid: boolean,
  callback: (_data: ts.types.signal.Signal) => void,
  errorCallback: (_error: string) => void
) => {
  try {
    const updateData = {
      definition,
      is_valid: isValid,
      skip_signature_check: !isValid,
    } as ts.types.signal.SignalDraft;
    const resp = await api.signals.update(id, updateData, true);
    callback(resp.data);
  } catch (error) {
    errorCallback(`Error updating the definition - ${helpers.parseApiError(error as ts.types.common.ApiError)}`);
  }
};

export const updateUiMetadata = async (
  signal: ts.types.signal.Signal,
  uiMetaData: ts.types.signal.UiMetaData,
  callback: (_data: ts.types.signal.Signal) => void,
  errorCallback: (_error: string) => void
) => {
  try {
    if (signal.shared) return;
    const updateData = {
      ui_metadata: uiMetaData,
      skip_signature_check: true,
    } as ts.types.signal.SignalDraft;
    const resp = await api.signals.update(signal.id, updateData);
    callback(resp.data);
  } catch (error) {
    errorCallback(`Error updating the resource metadata - ${helpers.parseApiError(error as ts.types.common.ApiError)}`);
  }
};

export const loadArtifacts = async (
  signal: ts.types.signal.Signal,
  setLoading: (_loading: boolean) => void,
  callback: (_datasets: ts.types.signal.Dataset[], _exp: ts.types.signal.ExplorationPreviewRecord) => void,
  errorCallback: (_error: string) => void,
  withExplorationPreview = true
) => {
  try {
    setLoading(true);

    const promises = [
      api.signal_datasets.search({
        ignore_user_scope: signal.shared,
        query: ['$and', ['signal_id', '=', signal.id]],
      }),
      api.signal_exploration_previews.search({
        ignore_user_scope: signal.shared,
        query: ['$and', ['signal_id', '=', signal.id]],
      }),
    ];

    if (!withExplorationPreview) promises.pop();

    const resp = await Promise.all(promises);

    if (!withExplorationPreview) {
      callback(resp[0].data as ts.types.signal.Dataset[], undefined);
    } else if (resp[1].data?.[0])
      callback(
        resp[0].data as ts.types.signal.Dataset[],
        resp[1].data?.[0] as ts.types.signal.ExplorationPreviewRecord
      );
    else {
      const newExpPreview = await api.signal_exploration_previews.create({
        signal_id: signal.id,
        exploration_preview: {},
      });
      callback(
        resp[0].data as ts.types.signal.Dataset[],
        newExpPreview.data as ts.types.signal.ExplorationPreviewRecord
      );
    }
  } catch (error) {
    errorCallback(`Error loading datasets - ${helpers.parseApiError(error as ts.types.common.ApiError)}`);
  }
  setLoading(false);
};

export const createDataset = async (
  newData: ts.types.signal.DatasetDraft,
  signal: ts.types.signal.SignalDraft,
  callback: (_data: ts.types.signal.Dataset) => void,
  errorCallback: (_error: string) => void
) => {
  try {
    const newDataset = {
      signal_id: signal.id,
      ...newData,
      order_index: 0,
      is_preferred: false,
      bookmarked: false,
    };
    const resp = await api.signal_datasets.create({ ...newDataset });
    callback(resp.data);
  } catch (error) {
    errorCallback(`Error loading datasets - ${helpers.parseApiError(error as ts.types.common.ApiError)}`);
  }
};

export const deleteDataset = async (
  datasetId: number,
  callback: () => void,
  errorCallback: (_error: string) => void
) => {
  try {
    await api.signal_datasets.remove(datasetId);
    callback();
  } catch (error) {
    errorCallback(`Error deleting dataset - ${helpers.parseApiError(error as ts.types.common.ApiError)}`);
  }
};

export const updatePreferredDataset = async (
  newId: number,
  datasets: ts.types.signal.Dataset[],
  attribute: 'is_preferred' | 'ws_is_latest',
  currWsId: number,
  signal: ts.types.signal.Signal,
  deleteStorePreferredSignalDatasets: (_ds: ts.types.signal.Dataset, _attr: string) => void,
  updateStorePreferredSignalDatasets: (_ds: ts.types.signal.Dataset, _attr: string) => void,
  setDatasets: (_datasets: ts.types.signal.Dataset[]) => void,
  errorCallback: (_error: string) => void,

  forceUpdateNew = false
) => {
  try {
    if (signal.shared) return;
    let newDatasets = [...datasets];
    const currentPreferredIdx = newDatasets.findIndex((ds) =>
      attribute == 'is_preferred' ? ds[attribute] : ds[attribute]?.[currWsId]
    );

    const oldId = newDatasets[currentPreferredIdx]?.id;
    const updatingNew = (newId && (!oldId || oldId !== newId)) || forceUpdateNew;

    if (oldId && oldId !== newId) {
      const data = {
        [attribute]: newDatasets[currentPreferredIdx][attribute],
      } as ts.types.signal.DatasetDraft;

      if (attribute == 'is_preferred') data[attribute] = false;
      else delete data[attribute]?.[currWsId];

      const resOld = await api.signal_datasets.update(oldId, data);
      newDatasets[currentPreferredIdx] = resOld.data;
      deleteStorePreferredSignalDatasets(resOld.data, attribute);
      if (!updatingNew) setDatasets(newDatasets);
    }

    if (updatingNew) {
      const data = {
        [attribute]: datasets.find((ds) => ds.id == newId)[attribute],
      } as ts.types.signal.DatasetDraft;

      if (attribute == 'is_preferred') data[attribute] = true;
      else data[attribute] = { ...(data[attribute] || {}), [currWsId]: true };

      const resNew = await api.signal_datasets.update(newId, data);
      newDatasets = newDatasets.map((ds) => (ds.id == resNew.data.id ? resNew.data : ds));

      updateStorePreferredSignalDatasets(resNew.data, attribute);
      setDatasets(newDatasets);
    }
  } catch (error) {
    errorCallback(`Error updating dataset - ${helpers.parseApiError(error as ts.types.common.ApiError)}`);
  }
};

export const updateBookmark = async (
  signal: ts.types.signal.Signal,
  dataset_id: number,
  datasets: ts.types.signal.Dataset[],
  setDatasets: (_datasets: ts.types.signal.Dataset[]) => void,
  errorCallback: (_error: string) => void
) => {
  try {
    if (signal.shared) return;
    const newDatasets = [...datasets];
    const currentIdx = newDatasets.findIndex((ds) => ds.id == dataset_id);

    // Optimistic change
    newDatasets[currentIdx].bookmarked = !datasets[currentIdx].bookmarked;
    setDatasets(newDatasets);

    // Api call
    await api.signal_datasets.update(dataset_id, {
      bookmarked: newDatasets[currentIdx].bookmarked,
    });
  } catch (error) {
    errorCallback(`Error updating dataset - ${helpers.parseApiError(error as ts.types.common.ApiError)}`);
    setDatasets([...datasets]);
  }
};

const createAnalyses = async (datasetRecord: ts.types.signal.Dataset, names: ts.enums.SIGNAL_ANALYSIS_TYPE_ENUM[]) => {
  const analyses = [];
  for (const name of names) {
    let analysisDefinition = { widgets: [] } as ts.types.analysis.AnalysisDefinition;

    if (name == ts.enums.SIGNAL_ANALYSIS_TYPE_ENUM.PORTAN) {
      analysisDefinition = {
        mode: ts.enums.ANALYSIS_MODE_ENUM.FULLSCREEN,
        widgets: [],
      };
    }

    if (name == ts.enums.SIGNAL_ANALYSIS_TYPE_ENUM.ADHOC) {
      analysisDefinition = {
        mode: ts.enums.ANALYSIS_MODE_ENUM.ADHOC,
        widgets: [],
      };
    }

    if (name == ts.enums.SIGNAL_ANALYSIS_TYPE_ENUM.PREVIEW) {
      // default preview value
      analysisDefinition = {
        mode: ts.enums.ANALYSIS_MODE_ENUM.FULLSCREEN,
        widgets: [],
      };
    }

    // create analysis
    const response = await api.signal_analyses.create({
      report_definition: analysisDefinition,
      signal_dataset_id: datasetRecord.id,
      name,
    });
    analyses.push(response.data.name);
  }
  return analyses;
};

// Load analyses, and if we don't have them, create them
export const loadAnalyses = async (
  signal: ts.types.signal.Signal,
  datasetRecord: ts.types.signal.Dataset,
  setAnalysesNames: (_data: ts.enums.SIGNAL_ANALYSIS_TYPE_ENUM[]) => void,
  errorCallback: (_error: string) => void
) => {
  const analyses = Object.values(ts.enums.SIGNAL_ANALYSIS_TYPE_ENUM);
  try {
    const resp = await api.signal_analyses.search({
      ignore_user_scope: signal.shared,
      query: ['$and', ['signal_dataset_id', '=', datasetRecord.id]],
      include: 'id,name',
    });

    let localNames = (resp.data || []).map(
      (a: ts.types.analysis.ResourceAnalysis) => a.name
    ) as ts.enums.SIGNAL_ANALYSIS_TYPE_ENUM[];

    const toCreate = _.without(analyses, ...localNames);
    if (!_.isEmpty(toCreate)) {
      const newNames = (await createAnalyses(datasetRecord, toCreate)) as ts.enums.SIGNAL_ANALYSIS_TYPE_ENUM[];
      localNames = localNames.concat(newNames);
    }

    setAnalysesNames(localNames);
  } catch (error) {
    errorCallback(`Error updating signal - ${helpers.parseApiError(error as ts.types.common.ApiError)}`);
  }
};

export const createDerivedUniverse = async (
  signalData: { signalHandle: string; signalName: string },
  callback: (_data: ts.types.universe.Universe) => void,
  errorCallback: (_error: string) => void
) => {
  const { signalHandle, signalName } = signalData;
  try {
    const resp: DerivedUniverseApiResponse = await api.universes.create({
      handle: `derived_from_${signalHandle}`,
      name: `Derived from ${signalName}`,
      definition: { source: { type: 'signal', values: [signalHandle] } },
      start_date: config.features.start_date,
      end_date: config.features.end_date,
    });
    callback(resp.data);
  } catch (error) {
    errorCallback(`Error creating derived universe - ${helpers.parseApiError(error as ts.types.common.ApiError)}`);
  }
};

export const updateDerivedUniverseSignature = async (
  dataSourceId: number,
  incrementData: Record<string, any>,
  callback: () => void,
  errorCallback: (_error: string) => void
) => {
  try {
    await api.data_sources.increment(dataSourceId, incrementData);
    callback();
  } catch (error) {
    errorCallback(`Error updating derived universe - ${helpers.parseApiError(error as ts.types.common.ApiError)}`);
  }
};
