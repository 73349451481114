import { hooks, mui, React, ts, ui } from '_core';

import { DraftParams } from './types';

type FormProps = {
  params: DraftParams;
  setParams: (_p: DraftParams) => void;
  context?: ts.types.signal.SignalAnalysisContext;
};

const Form: React.FC<FormProps> = ({ params, setParams, context }) => {
  const [returnHorizon, setReturnHorizon] = React.useState<ts.types.returnHorizon.ReturnHorizon>(params.return_horizon);
  const [scale, setScale] = React.useState<'linear' | 'log'>(params.scale || 'linear');
  const [buckets, setBuckets] = React.useState(
    params.buckets || ({ type: 'LONG_SHORT' } as ts.types.widgets.common.BucketValue)
  );
  const [joint, setJoint] = React.useState(params.joint || false);

  const uiStyles = hooks.useUiStyles();

  React.useEffect(() => {
    const data = {
      ...params,
      return_horizon: returnHorizon,
      buckets,
      scale,
      joint,
    };
    setParams({ ...params, weights: params.weights, ...data });
  }, [returnHorizon, buckets, scale, joint]);

  return (
    <mui.core.Box sx={uiStyles.widgetEditMode}>
      <mui.core.Box mt={2}>
        <mui.core.TextField
          select
          label="Type"
          variant="outlined"
          size="small"
          value={scale}
          onChange={(e) => setScale(e.target.value as 'linear' | 'log')}
          fullWidth
        >
          <mui.core.MenuItem value="linear">Linear scale</mui.core.MenuItem>
          {/* <mui.core.MenuItem value="log">Log scale</mui.core.MenuItem> */}
        </mui.core.TextField>
      </mui.core.Box>
      <mui.core.Box mt={4}>
        <ui.WidgetReturnHorizon<false>
          value={returnHorizon}
          setValue={(val) => setReturnHorizon(val)}
          context={context}
        />
      </mui.core.Box>

      <mui.core.Box mt={2}>
        <mui.core.FormControlLabel
          control={<mui.core.Switch size="small" checked={joint} onChange={() => setJoint(!joint)} />}
          label="Compound jointly"
        />
      </mui.core.Box>
      <mui.core.Box mt={4}>
        <ui.Buckets value={buckets} setValue={setBuckets} />
      </mui.core.Box>
    </mui.core.Box>
  );
};

export default Form;
