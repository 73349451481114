/* eslint-disable no-unused-vars */
import { ts } from '_core';

export enum INTERACTION_PARAMS {
  CURRENT_SHEET = 'current_sheet',
  TABLE_PARAMS = 'table_params',
  TABLE_DTYPES = 'table_dtypes',
}

export type SHEETS =
  | ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.TRUE_RETURNS
  | ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.RETURNS
  | ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.WEIGHTS;

export type InteractionParams = {
  [INTERACTION_PARAMS.TABLE_PARAMS]: ts.types.components.dataGrid.TableParamsSheet;
  [INTERACTION_PARAMS.TABLE_DTYPES]: ts.types.components.dataGrid.TableDtypesSheet;
  [INTERACTION_PARAMS.CURRENT_SHEET]: SHEETS;
};

export type Params = InteractionParams;
