import React from 'react';

import { _, mui } from '../../../libs';
import * as types from '../../../types';


type AssetMasterSelectorProps = {
  value: types.widgets.common.BucketValue;
  setValue: (_v: types.widgets.common.BucketValue) => void;
  options: string[];
  disabled?: boolean;
  error?: boolean;
};

const AssetMasterSelector: React.FC<AssetMasterSelectorProps> = ({
  value,
  setValue,
  options,
  disabled,
  error,
}): React.ReactElement => {
  return (
    <mui.core.Grid item xs={12} md={6}>
      <mui.core.TextField
        select
        label="Asset Master"
        variant="outlined"
        size="small"
        value={value.value}
        onChange={(e) => setValue({ ...value, value: e.target.value })}
        fullWidth
        required
        disabled={disabled}
        error={error}
      >
        {options.map((o) => (
          <mui.core.MenuItem key={o} value={o}>
            {_.startCase(o)}
          </mui.core.MenuItem>
        ))}
      </mui.core.TextField>
    </mui.core.Grid>
  );
};

export default AssetMasterSelector;
