import React from 'react';

import ContextSingleton from '../../__singletons__/context-singleton';
import api from '../../api';
import { helpItemEntry } from '../../api/docs';
import * as hooks from '../../hooks';
import { _, mui, useDispatch, useSelector } from '../../libs';
import * as types from '../../types';
import { BaseStore } from '../../types';
import Button from '../button';
import DialogTitle from '../dialog-title';

type HelpContentDialogProps = {
  isOpen: boolean;
  helpContent: types.helpItem.HelpItemContent;
  helpItem: types.helpItem.HelpItem;
  setIsOpen: (_v: boolean) => void;
  openInApp: (_u: string) => void;
};

const HelpContentDialog: React.FC<HelpContentDialogProps> = ({
  isOpen,
  setIsOpen,
  openInApp,
  helpContent,
  helpItem,
}): React.ReactElement => {
  const uiStyles = hooks.useUiStyles();
  const currentUser = useSelector((state: BaseStore) => state.auth.currentUser);

  const dispatch = useDispatch();
  const actions = ContextSingleton.getInstance().actions;

  const [loading, setLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [editableUrls, setEditableUrls] = React.useState({ url: '', edit_url: '' });
  const [originalUrls, setOriginalUrls] = React.useState({ url: '', edit_url: '' });

  const loadEntry = async (reset?: boolean) => {
    setLoading(true);
    try {
      const resp = await helpItemEntry({
        label: helpContent.helpLabel,
        document: helpContent.document,
        content_path: helpContent.contentPath ? `/${_.snakeCase(helpContent.contentPath)}` : undefined,
        content_title: helpContent.contentTitle,
        reset: reset ?? false,
      });
      setEditableUrls(resp);
      setOriginalUrls(resp);
    } catch {
      setErrorMessage('There was an error while trying to get the doc entry. Please try again');
    } finally {
      setLoading(false);
    }
  };

  const deleteEntry = async () => {
    setLoading(true);
    try {
      await api.help_items.remove(helpItem.id);
      dispatch(actions.resources.deleteHelpItem(helpItem));
      setIsOpen(false);
    } catch (e) {
      setErrorMessage('There was an error while trying to delete the doc entry. Please try again');
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    if (isOpen) loadEntry();
  }, [isOpen]);

  const updateUrl = async (url: string, edit_url: string) => {
    try {
      const id = (
        await api.help_items.search({
          query: ['$and', ['label', '=', helpContent.helpLabel]],
        })
      )['data'][0].id;
      await api.help_items.update(id, {
        url,
        edit_url,
      });
    } catch (e) {
      setErrorMessage('There was an error while trying to update the doc entry. Please try again');
    }
  };

  const handleSave = async () => {
    setLoading(true);
    await updateUrl(editableUrls.url, editableUrls.edit_url);
    setOriginalUrls(editableUrls);
    setLoading(false);
  };

  const handlereset = () => {
    loadEntry(true);
  };

  const handleRevert = () => {
    setEditableUrls(originalUrls);
  };

  const handleClose = () => {
    setIsOpen(false);
    setEditableUrls({ url: '', edit_url: '' });
    setOriginalUrls({ url: '', edit_url: '' });
    setErrorMessage('');
  };

  const isUrlChanged = editableUrls.url !== originalUrls.url || editableUrls.edit_url !== originalUrls.edit_url;

  return (
    <mui.core.Dialog open={isOpen} onClose={handleClose} maxWidth="sm" fullWidth>
      {errorMessage && (
        <mui.lab.Alert onClose={() => setErrorMessage('')} severity="error">
          {errorMessage}
        </mui.lab.Alert>
      )}

      <DialogTitle closeAction={handleClose}>Help Documentation</DialogTitle>

      <mui.core.DialogContent>
        <mui.core.Box
          mt={4}
          display="flex"
          alignContent="center"
          justifyContent="center"
          flexDirection="column"
          height="100%"
          width="100%"
        >
          <mui.core.Box mb={3} sx={uiStyles.helpBox}>
            <mui.core.Typography variant="body2">
              <strong>Edit Rules:</strong>
              <ul style={{ marginBottom: 0 }}>
                <li>When editing, you can move sections to any location within the same page.</li>
                <li>Never change the section headers; development work is required for such changes.</li>
                <li>
                  The document is created and accessible to organizations with the help icons enabled. Please update it
                  as soon as possible!
                </li>
              </ul>
            </mui.core.Typography>
          </mui.core.Box>

          <mui.core.Box mb={2}>
            <mui.core.TextField
              fullWidth
              label={loading ? 'Loading...' : 'View URL'}
              value={editableUrls.url}
              onChange={(e) => setEditableUrls((prev) => ({ ...prev, url: e.target.value }))}
              InputProps={{
                endAdornment: (
                  <mui.core.Tooltip title="Open Doc in Finsera">
                    <mui.core.IconButton onClick={() => openInApp(editableUrls.url)} disabled={loading}>
                      <mui.icons.OpenInBrowser />
                    </mui.core.IconButton>
                  </mui.core.Tooltip>
                ),
              }}
              disabled={loading}
            />
          </mui.core.Box>

          <mui.core.Box mb={2}>
            <mui.core.TextField
              fullWidth
              label={loading ? 'Loading...' : 'Edit URL'}
              value={editableUrls.edit_url}
              onChange={(e) => setEditableUrls((prev) => ({ ...prev, edit_url: e.target.value }))}
              InputProps={{
                endAdornment: (
                  <mui.core.Tooltip title="Open Doc Editor">
                    <mui.core.IconButton
                      onClick={() => window.open(editableUrls.edit_url, '_blank')}
                      disabled={loading}
                    >
                      <mui.icons.OpenInNew />
                    </mui.core.IconButton>
                  </mui.core.Tooltip>
                ),
              }}
              disabled={loading}
            />
          </mui.core.Box>
        </mui.core.Box>
      </mui.core.DialogContent>

      <mui.core.DialogActions>
        <mui.core.Box display="flex" flexDirection="column" width="100%" gap={2}>
          <mui.core.Box display="flex" justifyContent="space-between" width="100%">
            <mui.core.Box>
              {currentUser.id === helpItem?.created_by_id && (
                <Button
                  variant="text"
                  color="error"
                  onClick={deleteEntry}
                  disabled={loading}
                  size="small"
                  sx={{ opacity: 0.7, mt: 1 }}
                >
                  Delete Entry
                </Button>
              )}
            </mui.core.Box>

            <mui.core.Box display="flex" gap={1}>
              <mui.core.Button variant="outlined" onClick={handlereset} disabled={loading}>
                Reset to Default
              </mui.core.Button>
              <Button variant="outlined" color="primary" onClick={handleRevert} disabled={!isUrlChanged || loading}>
                Revert
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSave}
                disabled={!isUrlChanged || loading}
                loading={loading}
                progressText="Saving"
              >
                Save
              </Button>
              <mui.core.Button variant="contained" color="primary" onClick={handleClose} disabled={loading}>
                Close
              </mui.core.Button>
            </mui.core.Box>
          </mui.core.Box>
        </mui.core.Box>
      </mui.core.DialogActions>
    </mui.core.Dialog>
  );
};

export default HelpContentDialog;
