import { React, ts } from '_core';

import Chart from './chart';
import Form from './form';
import { DraftParams, Params } from './types';

type SignalPreviewDownloadAllDataProps = {
  mode: ts.enums.CHART_MODE_ENUM;
  params: Params | DraftParams;
  setParams: (_p: DraftParams) => void;
  jobId: number;
  context: ts.types.signal.SignalPreviewContext;
  widgetPaths?: Record<'main_data_zip', string>;
};

const SignalPreviewDownloadAllData: React.FC<SignalPreviewDownloadAllDataProps> = ({
  widgetPaths,
  mode,
  params,
  setParams,
  jobId,
}) => {
  const renderJobComponent = () => (
    <>
      <Chart file={widgetPaths['main_data_zip']} params={params as Params} jobId={jobId} />
    </>
  );

  return <>{mode == 'edit' ? <Form params={params} setParams={setParams} /> : renderJobComponent()}</>;
};

export default SignalPreviewDownloadAllData;
