import { am4charts, am4core } from '_core';

import { createSelectAllButton } from '../create-select-all-button';

export const createLegend = (
  chart: am4charts.XYChart,
  id: string,
  fullScreen: boolean,
  setShowLegend: (_show: boolean) => void
) => {
  function resizeLegend() {
    const legendContainer = document.getElementById(`legend-${id}-${fullScreen ? 'fs' : 'sw'}`);
    const legend = chart.legend;
    if (legendContainer && legend) {
      legendContainer.style.height = `${legend.contentHeight + 16}px`;
    }
  }

  let showLegendLocal = false;
  const minimumSeriesLength = 7;

  // We need to multiply by 2 because we are creating 2 series at a time,
  // due to creating a hidden one for the end value label
  if (chart.series.values.length <= minimumSeriesLength * 2 || fullScreen) {
    showLegendLocal = true;
    setShowLegend(true);
  } else {
    setShowLegend(false);
  }

  if (showLegendLocal) {
    const legendContainer = am4core.create(`legend-${id}-${fullScreen ? 'fs' : 'sw'}`, am4core.Container);
    legendContainer.width = am4core.percent(100);
    legendContainer.logo.disabled = true;

    chart.legend = new am4charts.Legend();
    chart.legend.parent = legendContainer;
    chart.legend.marginTop = 8;
    chart.legend.marginBottom = 8;

    if (chart.series.values.length > 2) {
      createSelectAllButton(chart);
    }

    chart.legend.scrollable = true;
    chart.legend.itemContainers.template.paddingTop = 1;
    chart.legend.itemContainers.template.paddingBottom = 1;
    chart.legend.markers.template.width = 10;
    chart.legend.markers.template.height = 10;
    chart.legend.markers.template.strokeWidth = 10;
    chart.legend.fontSize = 11;

    chart.legend.events.on('maxsizechanged', resizeLegend);

    // Add legend to the chart.
    chart.exporting.extraSprites.push(chart.legend);
  } else {
    document.getElementById(`legend-${id}-${fullScreen ? 'fs' : 'sw'}`).innerHTML = '';
  }
};
