import { config, hooks, mui, React, ts } from '_core';

import MoveColumns from '../move-columns';

type SheetSetupProps = {
  sheetDefinition: ts.types.components.dataGrid.ColumnPreferences[0];
  setSheetDefinition: (_v: ts.types.components.dataGrid.ColumnPreferences[0]) => void;
  configColumns: ts.types.components.dataGrid.ColumnsData;
  defaultPref: ts.types.components.dataGrid.ColumnPreferences[0];
};

const SheetSetup: React.FC<SheetSetupProps> = ({ sheetDefinition, setSheetDefinition, configColumns, defaultPref }) => {
  const getColumns = (objKey: 'frozenColumns' | 'columns', sheetDef = sheetDefinition, getFixed = false) => {
    const cols = [] as ts.types.components.dataGrid.ColumnsData;
    const pref = sheetDef;
    // For old definitions, let's filter the ones that are not part of default columns anymore
    if (pref[objKey] || []) {
      (pref[objKey] || []).forEach((key) => {
        const el = configColumns.find((c) => c.key == key);
        if (el && (!el.isFixed || getFixed) && (!el.condition || el.condition(config.features))) cols.push(el);
      });
    }
    return cols;
  };

  const [columns, setColumns] = React.useState<ts.types.components.dataGrid.ColumnsData>(() => getColumns('columns'));

  const [names, setNames] = React.useState(sheetDefinition.names ?? ({} as Record<string, string>));

  const [frozenColumns, setFrozenColumns] = React.useState<ts.types.components.dataGrid.ColumnsData>(() =>
    getColumns('frozenColumns')
  );

  const fixedColumns = React.useMemo(
    () => getColumns('columns', sheetDefinition, true).filter((el) => el.isFixed),
    [sheetDefinition]
  );

  hooks.useEffectWithoutFirst(() => {
    setSheetDefinition({
      columns: [...columns, ...fixedColumns].map((x) => x.key),
      names,
      frozenColumns: frozenColumns.map((x) => x.key),
    });
  }, [columns, frozenColumns, names]);

  const resetToDefault = () => {
    setColumns(getColumns('columns', defaultPref));
    setNames({});
    setFrozenColumns(getColumns('frozenColumns', defaultPref));
  };

  return (
    <>
      <MoveColumns
        columns={columns}
        names={names}
        setColumns={(newColumnsVal) => {
          setColumns(newColumnsVal);
        }}
        setNames={(newNames) => {
          setNames(newNames);
        }}
        frozenColumns={frozenColumns || []}
        setFrozenColumns={(newColumnsVal) => {
          setFrozenColumns(newColumnsVal);
        }}
        configColumns={configColumns}
        topAction={
          <mui.core.Button variant="outlined" onClick={resetToDefault} color="primary">
            Reset to Finsera Default
          </mui.core.Button>
        }
      />
    </>
  );
};

SheetSetup.defaultProps = {
  sheetDefinition: {},
};

export default SheetSetup;
