import React from 'react';

import { mui } from '../../libs';

type SearchPopperProps = {

  emptyQuery: boolean;
  showFilters: boolean;
  isLoading: boolean;
  totalResults: number;
  options: any[];
  saveSearch?: (_val: string) => void;
  saveSearchLabel?: string;
  inputValue: string;
  uiFilters: string;
  children: React.ReactNode;
};

const SearchPopper: React.FC<SearchPopperProps> = ({
  emptyQuery,
  showFilters,
  isLoading,
  totalResults,
  options,
  saveSearch,
  saveSearchLabel,
  inputValue,
  uiFilters,
  children,
}) => {
  const theme = mui.styles.useTheme() as mui.core.Theme;

  if (emptyQuery || showFilters) return <></>;
  if (isLoading) return <></>;

  return (
    <mui.core.Paper
      sx={{
        'li:nth-child(1) .MuiAutocomplete-groupLabel': {
          display: 'none',
        },
        'li .Mui-focused.Mui-focusVisible': {
          backgroundColor: `${(theme.palette as any).background.default}`,
        },
        boxShadow: 'none',
      }}
    >
      <mui.core.Box display="flex" pb={1}>
        <mui.core.Box flexGrow={1} pl={1} pt={2}>
          <mui.core.Typography align="left" variant="body1" style={{ color: '#7F8D93' }}>
            {totalResults > 100 ? `${options.length} out of ${totalResults} results` : `${options.length} results`}
          </mui.core.Typography>
        </mui.core.Box>

        <mui.core.Box pr={1} pt={1}>
          {saveSearch && (inputValue || uiFilters) && (
            <mui.core.Button
              fullWidth
              color="primary"
              onClick={() => {
                saveSearch(`${uiFilters} ${inputValue}`.trim());
              }}
              size="small"
              startIcon={<mui.icons.SaveAlt />}
            >
              {saveSearchLabel}
            </mui.core.Button>
          )}
        </mui.core.Box>
      </mui.core.Box>
      {children}
    </mui.core.Paper>
  );
};

export default SearchPopper;
