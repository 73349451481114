import { ts } from '_core';

import { getGeneralAnalysisPrefix, validate } from './helpers';
import { DraftParams, INTERACTION_PARAMS } from './types';

export default [
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.SIGNAL_SET_PERFORMANCE,
    title: 'Performance',
    helpLabel: '9p9yu9zu',
    generalAnalysisReportHelp: {
      document: ts.enums.UI_RESOURCE_TYPE_ENUM.SIGNAL_SET,
      contentPath: `/${ts.enums.SIGNAL_SET_ANALYSIS_TYPE_ENUM.ANALYSIS}`,
    },
    interactionParams: Object.values(INTERACTION_PARAMS),
    form: true,
    neededFiles: ['signal_set_annualized_statistics'],
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.GENERAL, ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.SIGNAL_SET_REPORT],
    generalAnalysisPrefix: 'Signal Set ',
    defaultParams: {
      daily_reporting: true,
      histories: Object.values(ts.enums.HISTORY_ENUM_EXTENDED),
      statistics_types: ['cumulative_returns', 'return', 'risk', 'adjusted'],
      return_horizons: [
        {
          periods: { frequency: 'DAILY', amount: 1 },
          skip: null,
          pipeline_ids: [],
          signature: '1_day(s)',
        },
      ],
    } as DraftParams,
    validate: validate,
    getGeneralAnalysisPrefix: getGeneralAnalysisPrefix as any,
  },
] satisfies ts.types.analysis.WidgetConfig[];
