import { _, api, ts } from '_core';

export const loadPublicFeatures = async (
  callback: (_data: ts.types.organization.OrganizationFeatures) => void,
  errorCallback: (_error: string) => void
) => {
  try {
    const resp = await api.auth.publicFeatures();
    callback(resp);
  } catch (error) {
    errorCallback('Unable to fetch public features');
  }
};

export const serviceLogin = async (
  service: string,
  external: boolean,
  callback: (_data: { uri: string }) => void,
  errorCallback: (_error: string) => void
) => {
  try {
    const resp = await api.auth.serviceLogin(service, external);
    callback(resp);
  } catch (error) {
    errorCallback(`Unable to login with ${_.startCase(_.toLower(service))}`);
  }
};
