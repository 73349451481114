import { hooks, mui, React, ts, ui } from '_core';

import { DraftParams } from './types';

type FormProps = {
  params: DraftParams;
  setParams: (_p: DraftParams) => void;
  context: ts.types.signalSet.SignalSetAnalysisContext;
};

const Form: React.FC<FormProps> = ({ params, setParams, context }) => {
  const [comparets, setcomparets] = React.useState(params.analysis_time_series_ids || []);

  const [dailyReporting, setDailyReporting] = React.useState(params?.daily_reporting || false);
  const [returnHorizons, setReturnHorizons] = React.useState(params?.return_horizons || []);

  React.useEffect(() => {
    setParams({
      ...params,
      daily_reporting: dailyReporting,
      return_horizons: returnHorizons,
      analysis_time_series_ids: comparets,
      frequency: dailyReporting ? ts.enums.FREQUENCY_ENUM.DAILY : undefined,
      set_params: [
        {
          analysis_time_series_ids: comparets,
          id: 'report', // Fixed id to get the file from payload on index
          file: 'sp_returns',
          file_params: {
            return_horizons: returnHorizons,
            agg_frequency: dailyReporting ? ts.enums.FREQUENCY_ENUM.DAILY : undefined,
          },
          aggregation_params: {},
          aggregation: 'fin-gen-sp-returns',
        },
      ],
    });
  }, [returnHorizons, dailyReporting, comparets]);

  const uiStyles = hooks.useUiStyles();
  return (
    <mui.core.Box sx={uiStyles.widgetEditMode}>
      <mui.core.Box mt={4}>
        <ui.ResourceAutocomplete<ts.types.timeSeries.TimeSeriesExpanded, true>
          value={comparets}
          setValue={(ids) => setcomparets(ids)}
          type={ts.enums.RESOURCES_TYPES_ENUM.TIME_SERIES}
          autocompleteProps={{
            size: 'small',
            multiple: true,
            disableCloseOnSelect: true,
          }}
          inputProps={{
            label: 'Select Time Series',
            variant: 'outlined',
            size: 'small',
            required: true,
          }}
        />
      </mui.core.Box>

      <ui.WidgetReturnHorizon<true>
        value={returnHorizons}
        setValue={(val) => setReturnHorizons(val)}
        dailyReporting={dailyReporting}
        setDailyReporting={(val) => setDailyReporting(val)}
        multiple
        enableDailyReporting
        context={context}
      />
    </mui.core.Box>
  );
};

export default Form;
