/* eslint-disable max-len */
import React from 'react';

import Button from './button';
import { mui } from '../libs';

/**
 * Component that displays an offline modal dialog
 * 
 * @param needReload - Whether to display a reload button
 * 
 * @returns Rendered component with an offline modal dialog
 */
const OfflineModal = ({ needReload = false }: { needReload?: boolean }) => {
  if (needReload)
    return (
      <mui.core.Dialog
        open
        maxWidth="sm"
        fullWidth
        disableEscapeKeyDown
        sx={{
          '& .MuiDialog-paper': {
            maxHeight: '60vh',
            padding: '16px',
            backgroundColor: '#fdfdfd',
          },
        }}
      >
        <mui.core.DialogTitle sx={{ display: 'flex', justifyContent: 'center' }}>
          <mui.core.Typography sx={{ fontWeight: 'bold', textAlign: 'center' }} variant="h5">
            Connection Lost
          </mui.core.Typography>
        </mui.core.DialogTitle>

        <mui.core.DialogContent dividers sx={{ padding: '16px' }}>
          <mui.core.Typography color="textPrimary" variant="body1" sx={{ mb: 2, textAlign: 'center' }}>
            You have exceeded the allowed number of open tabs or your connection to{' '}
            <span style={{ fontWeight: 'bold' }}>Finsera</span> has been closed due to inactivity.
          </mui.core.Typography>
          <mui.core.Typography color="textSecondary" variant="body2" sx={{ textAlign: 'center' }}>
            Please <span style={{ fontWeight: 'bold', color: '#1976d2' }}>reload this page</span> to continue using this
            tab.
          </mui.core.Typography>
        </mui.core.DialogContent>

        <mui.core.DialogActions sx={{ justifyContent: 'center', paddingBottom: '16px' }}>
          <mui.core.Button
            onClick={() => location.reload()}
            color="primary"
            variant="contained"
            sx={{
              marginTop: '10px',
            }}
          >
            Reconnect
          </mui.core.Button>
        </mui.core.DialogActions>
      </mui.core.Dialog>
    );

  return (
    <mui.core.Stack
      flexDirection="row"
      gap={1}
      alignItems="center"
      sx={{
        position: 'absolute',
        top: 0,
        zIndex: '10000',
        background: '#FFD931',
        left: 'calc(50vw - 215px)',
        padding: '0.33rem 0.66rem',
        color: '#202541',
        fontWeight: 'bold',
        borderRadius: '0 0 4px 4px',
        boxShadow: '0 10px 30px rgba(0,0,0,.25)',
      }}
    >
      <mui.icons.WifiOff sx={{ fontSize: '1rem' }} />
      Unable to connect to Finsera
      <Button
        variant="outlined"
        size="small"
        sx={{
          borderColor: 'rgba(0,0,0,.1)',
          color: '#202541',
          fontWeight: '500!important',
          textTransform: 'initial',
          letterSpacing: '0px!important',
        }}
      >
        <span className="loading">Trying to reconnect ...</span>
      </Button>
    </mui.core.Stack>
  );
};

export default OfflineModal;
