import { am5xy } from '@local/finsera-core/src/libs';

export const handleZoom = (chart: am5xy.XYChart) => {
  chart.plotContainer.events.on('wheel', function (ev) {
    if (ev.originalEvent.ctrlKey) {
      ev.originalEvent.preventDefault();
      chart.set('wheelX', 'panX');
      chart.set('wheelY', 'zoomX');
    } else {
      chart.set('wheelX', 'none');
      chart.set('wheelY', 'none');
    }
  });
};
