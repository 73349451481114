import { _, helpers, React, ts, useSelector } from '_core';

import { transform } from './helpers';
import { Params } from './types';
import ChartWrapper from '../../common/chart-wrapper';
import createMultiScaleLineChart from '../../common/charts/create-multi-scale-line-chart';

type ChartProps = {
  id: string;
  readOnly: boolean;
  widgetKey: string;
  portfolios: string;
  fullScreen: boolean;
  hasBenchmark: boolean;
  params: Params;
  loadWidgetData: (_payload: ts.types.widgets.WidgetGetDataParams) => Promise<ts.types.widgets.WidgetGetDataResponse>;
  title: string;
};

const Chart: React.FC<ChartProps> = ({
  id,
  readOnly,
  widgetKey,
  loadWidgetData,
  portfolios,
  fullScreen,
  params,
  hasBenchmark,
  title,
}): React.ReactElement => {
  const [data, setData] = React.useState(null);
  const [error, setError] = React.useState(null);
  const [showLegend, setShowLegend] = React.useState(false);

  const timeSeries = useSelector((state) => state.resources.time_series);
  const selectedTimeSeries = React.useMemo(() => {
    return params.analysis_time_series_ids.map((tid) => timeSeries.find((ts) => ts.id == tid));
  }, []);

  const loadData = async () => {
    try {
      const response = await loadWidgetData({
        data: {
          file: portfolios,
        },
      });
      if (response) {
        const resp = response.data.map((el) => ({
          date: el.end_date,
          ..._.omit(el, ['start_date', 'end_date']),
        }));
        setData(resp);
      }
    } catch (err) {
      setError(JSON.stringify(err));
    }
  };
  React.useEffect(() => {
    if (portfolios) loadData();
    return () => setData(null);
  }, [portfolios]);

  React.useEffect(() => {
    // Create line chart on data and scale changes
    if (!_.isNil(data)) {
      const inSameScale = _.without(
        Object.keys(_.omit(data[0], 'date')),
        ...selectedTimeSeries.map(helpers.resourcesUtils.getResourceLabel)
      );

      createMultiScaleLineChart({
        id,
        data,
        fullScreen,
        percentLeft: true,
        setShowLegend,
        exportTitle: title,
        inSameScale,
        prepareData: (linearData) => transform(linearData, params.isBasket, hasBenchmark, selectedTimeSeries),
      });
    }
  }, [data]);

  return (
    <ChartWrapper
      id={id}
      readOnly={readOnly}
      widgetKey={widgetKey}
      data={data}
      showLegend={showLegend}
      error={error}
      fullScreen={fullScreen}
    />
  );
};

Chart.defaultProps = {
  hasBenchmark: false,
};

export default Chart;
