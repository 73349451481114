import { _, helpers, ts } from '_core';

import { DraftParams, WIDGET_TYPE } from './types';
import { getBucketsTitle } from '../../common/utils/get-name';
import { prepareBarChartBucketedNames, prepareBarChartCountNames } from '../../common/utils/get-series-name';

export const prepareBucketedData = (
  widgetData: ts.types.widgets.NestedBarWidgetData,
  bucketType: ts.types.widgets.common.BucketValue['type'],
  bucketValue: ts.types.widgets.common.BucketValue['value'],
  resources: ts.StoreState['resources']
): ts.types.widgets.NestedBarWidgetData => {
  const newData = {
    ...widgetData,
    data: prepareBarChartBucketedNames(widgetData.data, bucketType, bucketValue, resources),
    count: prepareBarChartCountNames(widgetData.count, bucketType, bucketValue, resources),
  };

  return newData;
};

export const validate = (params: DraftParams) => {
  if (params.selected_type == 'return_horizon') {
    const rhErrors = helpers.validations.validSelectedReturnHorizon(params?.return_horizon);
    if (!_.isEmpty(rhErrors)) return rhErrors;
  } else if (params.selected_type == 'signal') {
    if (!params.analysis_signal_id) return ['Signal is a required field.'];
  }
  if (_.isEmpty(params.histories)) return ['Histories is a required field.'];
  if (!helpers.validations.validBuckets(params.buckets)) return ['Buckets are invalid.'];
  return [];
};

export const getName = (
  params: DraftParams,
  _context: ts.types.signal.SignalAnalysisContext,
  resources: ts.StoreState['resources']
) => {
  let widgetTitle = 'Average Signal Exposure by Bucket';
  if (params.type === WIDGET_TYPE.CORRELATION) widgetTitle = 'Average Correlation by Bucket';

  const init = getBucketsTitle(params.buckets, widgetTitle, resources);
  const selectedSignal = resources.signals.find((s) => s.id == params?.analysis_signal_id);
  const selectedPipeline = resources.pipelines.find((p) => p.id == params?.pipeline_id);
  const suffixName =
    params.selected_type == 'return_horizon'
      ? helpers.returnHorizon.getLabel(params?.return_horizon)
      : selectedSignal.name;

  const suffixPipeline = params.pipeline_id ? `(${selectedPipeline.name})` : '';
  return `${init} for ${suffixName} ${suffixPipeline}`;
};
