import { ts } from '_core';

import { validate } from './helpers';
import { DraftParams, INTERACTION_PARAMS } from './types';

export default [
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.BACKTEST_SIGNAL_EXPOSURES_OVER_TIME,
    title: 'Signal Exposures Over Time',
    helpLabel: '7smtoe3e',
    form: true,
    defaultParams: {
      isBasket: false,
    } as DraftParams,
    interactionParams: Object.values(INTERACTION_PARAMS),
    neededFiles: ['backtest_signal_exposure_analysis'],
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.BACKTEST],

    validate,
  },
] satisfies ts.types.analysis.WidgetConfig[];
