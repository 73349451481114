import { ts } from '_core';

import { getName, validate } from './helpers';
import { DraftParams } from './types';

export default [
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.BASKET_BUCKETED_CUMULATIVE_RETURNS,
    title: 'Bucketed Cumulative Returns',
    helpLabel: 'cfqyrgsz',
    form: true,
    defaultParams: { isBasket: true, format_for_ui: true } as DraftParams,
    neededFiles: ['bucketed_returns_backtest'],
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.BASKET],

    getName,
    validate,
  },
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.BACKTEST_BUCKETED_CUMULATIVE_RETURN,
    title: 'Bucketed Cumulative Returns',
    helpLabel: 'jnoxgtp8',
    form: true,
    defaultParams: { isBasket: false, format_for_ui: true } as DraftParams,
    neededFiles: ['bucketed_returns_backtest'],
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.BACKTEST],

    getName,
    validate,
  },
] satisfies ts.types.analysis.WidgetConfig[];
