import { DraftParams } from './types';

export const validate = (params: DraftParams, _c: any, _i: boolean) => {
  if (!params?.industry_min_prob && params?.industry_min_prob != 0)
    return ['Minimum Clustering Probability is a required field.'];

  if (params?.industry_min_prob <= 0 || params?.industry_min_prob > 1)
    return ['Minimum Clustering Probability has to be between 0 and 1.'];

  if (params?.industry_estimation_periods <= 0) return ['Industry estimation periods must be greater than zero.'];
};
