import { _, ts } from '_core';

export const replaceNans = (data: ts.types.widgets.TableData): ts.types.widgets.TableData => {
  return data.map((row) => {
    const newRow: ts.types.widgets.TableData[number] = {};
    for (const key in row) {
      if (row[key] === Infinity || row[key] === -Infinity || _.isNaN(row[key])) {
        newRow[key] = null;
      } else {
        newRow[key] = row[key];
      }
    }
    return newRow;
  });
};
