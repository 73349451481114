import { _, React } from '_core';

import BenchmarkNeutralView from './custom-filters/benchmark-neutral';
import History from './custom-filters/history-field';
import ReturnType from './custom-filters/return-type';
import FractionalRiskField from './custom-filters/risk-decomposition/select-fractional-risk-field';
import RiskMetricField from './custom-filters/risk-decomposition/select-risk-metric-field';
import View from './custom-filters/select-view-field';
import StatisticsField from './custom-filters/statistics-field';
import { getIndustryName, getRbicsIndustryName, getTitleByStatistics } from '../../../common/utils/attribution-utils';
import { DraftParams } from '../types';

const getSheets = (params: DraftParams, bucketOrder: string[]) => {
  const bucketsValue = params?.buckets?.value || 'all';
  const bucketsType = params?.buckets?.type;

  let formattedBucketsValue = bucketsValue as string;

  if (_.checkIsNumber(formattedBucketsValue)) formattedBucketsValue = 'Quantiles';

  if (formattedBucketsValue.includes('gics')) formattedBucketsValue = getIndustryName(formattedBucketsValue);

  if (formattedBucketsValue.includes('rbics')) {
    formattedBucketsValue = getRbicsIndustryName(formattedBucketsValue);
  }

  const sheets = {
    variables: 'Variables Details',
    [`${bucketsType}:${bucketsValue}`]: `${_.startCase(formattedBucketsValue)} Details`,
    [`${bucketOrder[1]}_AND_${bucketOrder[0]}`]: `Variables & ${_.startCase(formattedBucketsValue)} Details`,
    summary_variables: 'Variables Summary',
    [`summary_${bucketsType}:${bucketsValue}`]: `${_.startCase(formattedBucketsValue)} Summary`,
    [`summary_${bucketOrder[1]}_AND_${bucketOrder[0]}`]: `Variables & ${_.startCase(formattedBucketsValue)} Summary`,
  } as Record<string, string>;

  const customWidth = {} as Record<string, number>;
  const customRowHeight = {} as Record<string, number>;
  const customHeaderHeight = {} as Record<string, number>;

  Object.keys(sheets).forEach((key) => {
    if (key.split('AND').length > 1) {
      customWidth[key] = 100;
      customHeaderHeight[key] = 80;
    } else {
      customWidth[key] = 97;
      customHeaderHeight[key] = 60;
    }

    customRowHeight[key] = 20;
  });

  return { sheets, sheetsStyles: { customWidth, customHeaderHeight, customRowHeight } };
};

const renderToolbar = (views: React.ReactElement[]) => <>{views}</>;

const getCustomToolbars = (props: Record<string, any>) => {
  const bucketsValue = props.params?.buckets?.[0]?.value || 'all';
  const bucketsType = props.params?.buckets[0]?.type;
  const benchmarkNeutral = props.params?.benchmark_neutral;

  const suffix = ''; // Leaving this here in case we want to add new suffixes in the future
  const variableTitle = props.selectedViewType != 'factor_exposures' ? 'Period Return' : '';

  const statisticsName = getTitleByStatistics(props.selectedStatistics);

  const customToolbarByAttributionType = {
    risk: {
      [props.bucketOrder[0]]: () =>
        renderToolbar([
          <RiskMetricField
            key="selectedRiskMetric"
            value={props.selectedRiskMetric}
            setValue={props.setSelectedRiskMetric}
          />,
          <FractionalRiskField
            key="selectedFractionalRisk"
            value={props.selectedFractionalRisk}
            setValue={props.setSelectedFractionalRisk}
          />,
          <BenchmarkNeutralView key="benchmarkNeutral" benchmarkNeutral={benchmarkNeutral} />,
        ]),
      variables: () =>
        renderToolbar([
          <RiskMetricField
            key="selectedRiskMetric"
            value={props.selectedRiskMetric}
            setValue={props.setSelectedRiskMetric}
          />,
          <FractionalRiskField
            key="selectedFractionalRisk"
            value={props.selectedFractionalRisk}
            setValue={props.setSelectedFractionalRisk}
          />,
          <BenchmarkNeutralView key="benchmarkNeutral" benchmarkNeutral={benchmarkNeutral} />,
        ]),
      [`variables_AND_${props.bucketOrder[0]}`]: () =>
        renderToolbar([
          <RiskMetricField
            key="selectedRiskMetric"
            value={props.selectedRiskMetric}
            setValue={props.setSelectedRiskMetric}
          />,
          <FractionalRiskField
            key="selectedFractionalRisk"
            value={props.selectedFractionalRisk}
            setValue={props.setSelectedFractionalRisk}
          />,
          <BenchmarkNeutralView key="benchmarkNeutral" benchmarkNeutral={benchmarkNeutral} />,
        ]),
      summary_variables: () =>
        renderToolbar([
          <RiskMetricField
            key="selectedRiskMetric"
            value={props.selectedRiskMetric}
            setValue={props.setSelectedRiskMetric}
          />,
          <FractionalRiskField
            key="selectedFractionalRisk"
            value={props.selectedFractionalRisk}
            setValue={props.setSelectedFractionalRisk}
          />,
          <BenchmarkNeutralView key="benchmarkNeutral" benchmarkNeutral={benchmarkNeutral} />,
        ]),
      [`summary_${bucketsType}:${bucketsValue}`]: () =>
        renderToolbar([
          <RiskMetricField
            key="selectedRiskMetric"
            value={props.selectedRiskMetric}
            setValue={props.setSelectedRiskMetric}
          />,
          <FractionalRiskField
            key="selectedFractionalRisk"
            value={props.selectedFractionalRisk}
            setValue={props.setSelectedFractionalRisk}
          />,
          <BenchmarkNeutralView key="benchmarkNeutral" benchmarkNeutral={benchmarkNeutral} />,
        ]),
      [`summary_${props.bucketOrder[1]}_AND_${props.bucketOrder[0]}`]: () =>
        renderToolbar([
          <RiskMetricField
            key="selectedRiskMetric"
            value={props.selectedRiskMetric}
            setValue={props.setSelectedRiskMetric}
          />,
          <FractionalRiskField
            key="selectedFractionalRisk"
            value={props.selectedFractionalRisk}
            setValue={props.setSelectedFractionalRisk}
          />,
          <History
            key="selectedHistory"
            value={props.selectedHistory}
            setValue={props.setSelectedHistory}
            histories={props.histories}
            startDate={props.startDate}
            endDate={props.endDate}
          />,
          <BenchmarkNeutralView key="benchmarkNeutral" benchmarkNeutral={benchmarkNeutral} />,
        ]),
    },
  };

  let customToolBars = {
    [props.bucketOrder[0]]: () =>
      renderToolbar([
        <ReturnType key="returnType" returnLabel="Period Return" suffix={suffix} />,
        <BenchmarkNeutralView key="benchmarkNeutral" benchmarkNeutral={benchmarkNeutral} />,
      ]),
    variables: () =>
      renderToolbar([
        <View key="selectedViewType" value={props.selectedViewType} setValue={props.setSelectedViewType} />,
        <ReturnType key="returnType" returnLabel={variableTitle} suffix={suffix} />,

        <BenchmarkNeutralView key="benchmarkNeutral" benchmarkNeutral={benchmarkNeutral} />,
      ]),
    [`variables_AND_${props.bucketOrder[0]}`]: () =>
      renderToolbar([<ReturnType key="returnType" returnLabel={variableTitle} suffix={suffix} />]),
    summary_variables: () =>
      renderToolbar([
        <StatisticsField
          key="selectedStatistics"
          value={props.selectedStatistics}
          setValue={props.setSelectedStatistics}
        />,
        <ReturnType key="returnType" returnLabel={statisticsName} suffix={suffix} />,
        <BenchmarkNeutralView key="benchmarkNeutral" benchmarkNeutral={benchmarkNeutral} />,
      ]),
    [`summary_${bucketsType}:${bucketsValue}`]: () =>
      renderToolbar([
        <StatisticsField
          key="selectedStatistics"
          value={props.selectedStatistics}
          setValue={props.setSelectedStatistics}
        />,
        <ReturnType key="returnType" returnLabel={statisticsName} suffix={suffix} />,
        <BenchmarkNeutralView key="benchmarkNeutral" benchmarkNeutral={benchmarkNeutral} />,
      ]),
    [`summary_${props.bucketOrder[1]}_AND_${props.bucketOrder[0]}`]: () =>
      renderToolbar([
        <History
          key="selectedHistory"
          value={props.selectedHistory}
          setValue={props.setSelectedHistory}
          histories={props.histories}
          startDate={props.startDate}
          endDate={props.endDate}
        />,
        <StatisticsField
          key="selectedStatistics"
          value={props.selectedStatistics}
          setValue={props.setSelectedStatistics}
        />,
        <ReturnType key="returnType" returnLabel={statisticsName} suffix={suffix} />,
        <BenchmarkNeutralView key="benchmarkNeutral" benchmarkNeutral={benchmarkNeutral} />,
      ]),
  };

  // eslint-disable-next-line max-len
  const customToolBarByType =
    customToolbarByAttributionType[props.attributionType as keyof typeof customToolbarByAttributionType];

  if (customToolBarByType) customToolBars = customToolBarByType;

  return customToolBars as Record<string, () => JSX.Element>;
};

export { getSheets };
export { getCustomToolbars };
