import { _, api, mui, React, ts, ui } from '_core';

type RemoteSelectFieldProps = {
  value: string[] | string;
  setValue: (_d: string[] | string) => void;
  label?: string;
  table?: string;
  query?: string[];
  multiple?: boolean;
};


const RemoteSelectField: React.FC<RemoteSelectFieldProps> = ({
  value,
  setValue,
  label,
  table,
  query,
  multiple,
}): React.ReactElement => {
  const searchTable = async (localQuery: string[], searchTerm: string, limit: number, offset: number) => {
    try {
      const response = await (api[table as keyof typeof api] as ts.types.ApiTypes<unknown>).search({
        query: localQuery.concat([
          ['$or', ['label', 'icontains', searchTerm], ['value', 'icontains', searchTerm]],
        ] as any),
        limit,
        offset,
      });

      return response['data'];
    } catch (e) {
      return [];
    }
  };

  const getValues = async (searchTerm = '', limit: number, offset: number) => {
    const localQuery = query;

    const responseData = (await searchTable(localQuery, searchTerm, limit, offset)) as unknown as any[];
    return responseData;
  };

  const setValueLocal = (val: Record<string, any>) => {
    if (_.isArray(val)) setValue((val || []).map((x) => x.value));
    else setValue(val?.value);
  };

  const localValue = React.useMemo(() => {
    if (multiple)
      return ((value || []) as string[]).map((v) => ({
        value: v,
      })) as ts.types.components.autocomplete.AutoCompleteValue[];
    return { value } as ts.types.components.autocomplete.AutoCompleteValue;
  }, [value]);

  return (
    <>
      {/* eslint-disable-next-line max-len */}
      <ui.Autocomplete<
        ts.types.components.autocomplete.AutoCompleteValue,
        ts.types.components.autocomplete.AutoCompleteValue,
        typeof multiple
      >
        multiple={multiple}
        label={label}
        variant="outlined"
        limit={20}
        query={getValues}
        value={localValue}
        setValue={(val) => setValueLocal(val)}
        createId={(el) => el.value}
        createTitle={(el) => el.label || el.value}
        example="Search by Label or Value"
        optionRenderer={(option) => (
          <mui.core.Grid container alignItems="center">
            <mui.core.Grid item xs>
              {option.label}
              <mui.core.Box display="flex" style={{ justifyContent: 'space-between' }}>
                <mui.core.Typography variant="body2" color="textSecondary">
                  {option.value}
                </mui.core.Typography>
              </mui.core.Box>
            </mui.core.Grid>
          </mui.core.Grid>
        )}
      />
    </>
  );
};

export default RemoteSelectField;
