import { api, helpers, mui, React, ts, ui } from '_core';

type ChartDendrogramProps = {
  file: string;
  jobId: number;
};

const ChartDendrogram: React.FC<ChartDendrogramProps> = ({ file, jobId }) => {
  const [dendrogram, setDendrogram] = React.useState<string>(null);
  const [error, setError] = React.useState<ts.types.common.ApiError>(null);

  const loadData = async () => {
    try {
      const dendrogramHtml = await api.s3.getHtmlContent(file, jobId);
      setDendrogram(dendrogramHtml);
    } catch (err) {
      setError(err as ts.types.common.ApiError);
    }
  };

  React.useEffect(() => {
    if (file) loadData();
    return () => setDendrogram(null);
  }, [file]);

  return (
    <mui.core.Box sx={{ height: '100%' }}>
      {error && (
        <mui.core.Box display="flex" padding="1rem">
          <mui.lab.Alert severity="error" style={{ width: '100%' }}>
            {helpers.parseApiError(error)}
          </mui.lab.Alert>
        </mui.core.Box>
      )}
      {!dendrogram ? (
        <mui.core.Box style={{ padding: 30 }}>
          <ui.CenteredLoader label="Loading dendrogram..." top="8%" />
        </mui.core.Box>
      ) : (
        <ui.DangerouslySetHtmlContent html={dendrogram} />
      )}
    </mui.core.Box>
  );
};

export default ChartDendrogram;
