import { _ } from '@local/finsera-core/src/libs/lodash';
import { Organization } from '@local/finsera-core/src/types/organization';
import { User } from '@local/finsera-core/src/types/user';

import {
  INVALID_PASSCODE,
  LOGOUT,
  PERFORM_PASSWORD_RESET,
  REQUEST_PASSWORD_RESET,
  SET_AUTH_TOKEN,
  SET_USER,
  SET_VERIFIED,
} from '../actions/types';

const INITIAL_STATE = {
  currentUser: null as User,
  organization: null as Organization,
  authToken: null as string,
  authenticated: false,
  invalidPasscode: false,
  verified: false,
};

export type SetUserType = {
  type: typeof SET_USER;
  payload: {
    organization?: Organization;
    user?: User;
  };
};

export type RequestPasswordResetType = {
  type: typeof REQUEST_PASSWORD_RESET;
};

export type PerformPasswordResetType = {
  type: typeof PERFORM_PASSWORD_RESET;
};

export type LogoutType = {
  type: typeof LOGOUT;
};

export type VerifiedType = {
  type: typeof SET_VERIFIED;
  payload: {
    verified: boolean;
  };
};

export type InvalidPasscodeType = {
  type: typeof INVALID_PASSCODE;
  payload: {
    invalidPasscode: boolean;
  };
};

export type SetAuthTokenType = {
  type: typeof SET_AUTH_TOKEN;
  payload: {
    authToken: string;
  };
};

export default (
  state = INITIAL_STATE,
  action: SetUserType | LogoutType | InvalidPasscodeType | VerifiedType | SetAuthTokenType
) => {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        authenticated: true,
        organization: (action as SetUserType).payload.organization,
        currentUser: _.omit((action as SetUserType).payload.user, ['workspaces_metadata']),
      };
    case LOGOUT:
      return { ...state, authenticated: false, currentUser: null };
    case SET_VERIFIED:
      return { ...state, verified: (action as VerifiedType).payload.verified };
    case INVALID_PASSCODE:
      return { ...state, invalidPasscode: (action as InvalidPasscodeType).payload.invalidPasscode };
    case SET_AUTH_TOKEN:
      return { ...state, authToken: (action as SetAuthTokenType).payload.authToken };
    default:
      return state;
  }
};
