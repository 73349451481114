import React from 'react';

import { mui } from '../libs';

type WidgetSetFullWidthProps = {
  /** Current widget parameters */
  params: Record<string, any>;
  /** Callback to update widget parameters */
  setParams: (_params: Record<string, any>) => Promise<void>;
};

/**
 * Component that renders a button to toggle full width mode for widgets
 * 
 * @param params Current widget parameters
 * @param setParams Callback to update widget parameters
 * @returns Rendered component
 */
const WidgetSetFullWidth: React.FC<WidgetSetFullWidthProps> = ({ params, setParams }): React.ReactElement => {
  const [fullWidth, setFullWidth] = React.useState(params.fullWidth || false);

  const handleClick = () => {
    const localFullWidth = !fullWidth;
    setFullWidth(localFullWidth);
    setParams({ ...params, fullWidth: localFullWidth });
  };

  return (
    <mui.core.Box>
      {fullWidth ? (
        <mui.core.Tooltip title="Collapse Width" arrow>
          <mui.core.Box onClick={handleClick} style={{ marginRight: '0.5rem', cursor: 'pointer' }} pt={1}>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style={{ width: '0.9rem' }}
            >
              <path
                fill="#7F8D93"
                // eslint-disable-next-line max-len
                d="M1.5396 10.7329L6.86922 10.7329L6.81842 9.01693C6.81842 8.20552 7.79938 7.7992 8.37311 8.37293L11.3783 11.3781C11.734 11.7338 11.7327 12.3091 11.377 12.6648L8.37182 15.67C7.79809 16.2437 6.81842 15.8387 6.81842 15.0273L6.86922 13.3088L1.5396 13.3088C1.2043 13.3088 0.932465 13.0369 0.932465 12.7016L0.932465 11.34C0.932465 11.0047 1.2043 10.7329 1.5396 10.7329V10.7329ZM22.5024 13.3088L17.1728 13.3088L17.2236 15.0247C17.2236 15.8361 16.2426 16.2424 15.6689 15.6687L12.6637 12.6635C12.308 12.3078 12.3093 11.7325 12.665 11.3768L15.6702 8.37164C16.2439 7.79791 17.2236 8.20295 17.2236 9.0143L17.1728 10.7329L22.5024 10.7329C22.8377 10.7329 23.1095 11.0047 23.1095 11.34L23.1095 12.7016C23.1095 13.0369 22.8377 13.3088 22.5024 13.3088V13.3088Z"
              />
            </svg>
          </mui.core.Box>
        </mui.core.Tooltip>
      ) : (
        <mui.core.Tooltip title="Set Full Width" arrow>
          <mui.core.Box onClick={handleClick} style={{ marginRight: '0.5rem', cursor: 'pointer' }} pt={1}>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style={{ width: '0.9rem' }}
            >
              <path
                fill="#7F8D93"
                // eslint-disable-next-line max-len
                d="M10.1259 13.3088H5.15196L5.20275 15.0247C5.20275 15.8361 4.22179 16.2424 3.64807 15.6687L0.642865 12.6635C0.287205 12.3078 0.288493 11.7325 0.644153 11.3768L3.64936 8.37164C4.22308 7.79791 5.20275 8.20295 5.20275 9.0143L5.15196 10.7329H10.1259C10.4612 10.7329 10.733 11.0047 10.733 11.34L10.733 12.7016C10.7331 13.037 10.4612 13.3088 10.1259 13.3088V13.3088ZM13.9161 10.7329H18.89L18.8392 9.01693C18.8392 8.20552 19.8202 7.7992 20.3939 8.37293L23.3991 11.3781C23.7548 11.7338 23.7535 12.3091 23.3978 12.6648L20.3926 15.67C19.8189 16.2437 18.8392 15.8387 18.8392 15.0273L18.89 13.3088H13.9161C13.5808 13.3088 13.309 13.0369 13.309 12.7016L13.309 11.34C13.3089 11.0047 13.5808 10.7329 13.9161 10.7329V13.3088Z"
              />
            </svg>
          </mui.core.Box>
        </mui.core.Tooltip>
      )}
    </mui.core.Box>
  );
};

export default WidgetSetFullWidth;
