import React from 'react';

import ContextSingleton from '../__singletons__/context-singleton';
import { mui } from '../libs';

type UiWrapperProps = {
  children: React.ReactNode;
};

/**
 * Component that wraps the UI and handles sandbox environment banner display
 * 
 * @requires Features:
 * - sandbox: Whether to show sandbox environment banner
 * 
 * @param children React.ReactNode - Children to render within the wrapper
 * @returns Rendered component with optional sandbox banner and children
 */
const UiWrapper: React.FC<UiWrapperProps> = ({ children }): React.ReactElement => {
  const theme = mui.styles.useTheme() as mui.core.Theme;
  const features = ContextSingleton.getInstance()?.features;
  return (
    <>
      <mui.core.Box
        sx={{
          display: 'grid',
          height: '100%',
          gridTemplateRows: features.sandbox ? '30px auto' : 'auto',
          '@media print': {
            height: 'auto',
          },
        }}
      >
        {features.sandbox && (
          <mui.core.Box
            sx={{
              background: (theme.palette as any).warningLight.main,
              color: (theme.palette as any).warning.dark,
              minHeight: '30px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: 1000,
              width: '100%',
              fontSize: 13,
              fontWeight: 500,
              padding: '4px',
            }}
          >
            <mui.icons.InfoOutlined
              style={{
                fontSize: 16,
                marginRight: 4,
              }}
            />
            Sandbox enviroment: This enviroment is accessible by Finsera and is temporary. Please use it only as a
            playground.
          </mui.core.Box>
        )}
        <mui.core.Box
          sx={{
            position: 'relative',
            overflow: 'hidden',
            '@media print': {
              overflow: 'initial',
            },
          }}
        >
          {children}
        </mui.core.Box>
      </mui.core.Box>
    </>
  );
};

export default UiWrapper;
