import { ts } from '_core';

import { validate } from './helpers';
import { DraftParams, INTERACTION_PARAMS } from './types';

export default [
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.REBALANCE_PORTFOLIO_SUMMARY_BUCKET_EXPOSURE,
    form: true,
    title: 'Bucket Exposure Summary',
    defaultParams: {
      buckets: [{ type: ts.enums.BUCKET_TYPES_ENUM.RISK_MODEL_FACTORS, value: 5 }],
    } as DraftParams,
    interactionParams: Object.values(INTERACTION_PARAMS),
    neededFiles: ['portfolio_summary_bucket_exposures'],
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.REBALANCE_PORTFOLIO_HOLDINGS],

    validate,
    helpLabel: 'xmi6pdwj',
  },
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.PORTFOLIO_SUMMARY_BUCKET_EXPOSURE,
    form: true,
    title: 'Bucket Exposure Summary',
    defaultParams: {
      buckets: [{ type: ts.enums.BUCKET_TYPES_ENUM.RISK_MODEL_FACTORS, value: 5 }],
    } as DraftParams,
    interactionParams: Object.values(INTERACTION_PARAMS),
    neededFiles: ['portfolio_summary_bucket_exposures_holdings'],
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.PORTFOLIO_HOLDINGS_ANALYSIS],

    validate,
    helpLabel: 'ygbyqj71',
  },
] satisfies ts.types.analysis.WidgetConfig[];
