import { hooks, mui, React } from '_core';

import { DraftParams } from './types';

type DraftWidgetParams = DraftParams;

type FormProps = {
  params: DraftWidgetParams;
  setParams: (_p: DraftWidgetParams) => void;
};

const Form: React.FC<FormProps> = ({ params, setParams }): React.ReactElement => {
  const [selectedType, setSelectedType] = React.useState(params.selectedType || 'show_total');

  const uiStyles = hooks.useUiStyles();

  React.useEffect(() => {
    const data = { selectedType };
    setParams({ ...data });
  }, [selectedType]);

  return (
    <mui.core.Box sx={uiStyles.widgetEditMode}>
      <mui.core.Box mt={4}>
        <mui.core.FormControl component="fieldset">
          <mui.core.RadioGroup
            row
            aria-label="position"
            name="position"
            value={selectedType}
            onChange={(e) => {
              setSelectedType(e.target.value);
            }}
          >
            <mui.core.FormControlLabel
              value="show_total"
              control={<mui.core.Radio color="primary" />}
              label="Show Total Turnover"
            />
            <mui.core.FormControlLabel
              value="long_short"
              control={<mui.core.Radio color="primary" />}
              label="Show Long and Short Turnover"
            />
          </mui.core.RadioGroup>
        </mui.core.FormControl>
      </mui.core.Box>
    </mui.core.Box>
  );
};

export default Form;
