import { _, helpers, React, ts, ui,useSelector } from '_core';

const LastOpened = () => {
  const recent = useSelector((state) => state.ui.workspacesMetadata?.recent) || [];
  const resources = useSelector((state) => state.resources);

  const renderLink = (itemType: ts.enums.RESOURCES_TYPES_ENUM, itemId: number) => {
    const resourcesIndex = helpers.pluralize.plural(itemType) as keyof typeof resources;
    // Ready to handle in redux
    const resource = (resources[resourcesIndex] as ts.types.common.Resource[])?.find((r) => r.id == itemId);
    if (resource) {
      return (
        <ui.InlineItem
          resource={resource}
          resourceType={itemType}
          to={`/${helpers.pluralize.plural(_.snakeCase(itemType))}/${itemId}`}
        />
      );
    }
    return <></>;
  };

  const list = () => (
    <>
      {recent.map((r) => (
        <div key={`${r.itemType}-${r.itemId}`}>{renderLink(r.itemType, r.itemId)}</div>
      ))}
    </>
  );

  return (
    <>
      {_.isEmpty(recent) ? (
        <ui.EmptyMessage hideBorder>You {"don't"} have anything recently opened.</ui.EmptyMessage>
      ) : (
        list()
      )}
    </>
  );
};

export default LastOpened;
