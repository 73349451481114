import { mui, React, ts } from '_core';

type RiskModelCategoryProps = {
  value: ts.types.pipeline.NeutralizeDraft['factors'][0];
  setValue: (_value: ts.types.pipeline.NeutralizeDraft['factors'][0]) => void;
};

const RiskModelCategory: React.FC<RiskModelCategoryProps> = ({ value, setValue }): React.ReactElement => {
  const categories = {
    [ts.enums.RISK_MODEL_CATEGORIES_ENUM.INDUSTRY]: 'Industry',
  };

  return (
    <mui.core.TextField
      select
      label="Category"
      variant="outlined"
      size="small"
      value={value.value}
      onChange={(e) => setValue({ ...value, value: e.target.value })}
      fullWidth
      required
    >
      {Object.keys(categories).map((key) => (
        <mui.core.MenuItem value={key} key={key}>
          {categories[key as ts.enums.RISK_MODEL_CATEGORIES_ENUM.INDUSTRY]}
        </mui.core.MenuItem>
      ))}

      {/* Custom logic for old risk model categories */}
      {value && !categories[value.value as keyof typeof categories] && (
        <mui.core.MenuItem value={value.value} key={value.value}>
          {value.value}
        </mui.core.MenuItem>
      )}
    </mui.core.TextField>
  );
};

export default RiskModelCategory;
