import { React, ts } from '_core';

import Chart from './chart';
import { Params } from './types';

type LinearAlphaTabularSignificanceProps = {
  fullScreen?: boolean;
  loadWidgetData: (_payload: ts.types.widgets.WidgetGetDataParams) => Promise<ts.types.widgets.WidgetGetDataResponse>;
  params: Params;
  setParams: (_p: Params) => void;
  context?: ts.types.signal.SignalAnalysisContext;
  widgetPaths: Record<'linear_alpha_model_stats', string>;
  goFullScreen: (_q: string) => void;
  queryParams?: { initial?: string };
};

const LinearAlphaTabularSignificance: React.FC<LinearAlphaTabularSignificanceProps> = ({
  loadWidgetData,
  params,
  setParams,
  widgetPaths,
  fullScreen,
  goFullScreen,
  queryParams,
}) => {
  return (
    <Chart
      loadWidgetData={loadWidgetData}
      file={widgetPaths['linear_alpha_model_stats']}
      fullScreen={fullScreen}
      queryParams={queryParams}
      goFullScreen={goFullScreen}
      params={params}
      setParams={setParams}
    />
  );
};

export default LinearAlphaTabularSignificance;
