import { hooks, mui, React, ts, ui } from '_core';

import { DraftParams } from './types';
import { getBucketDefaultValue } from '../../common/utils/attribution-utils';

type FormProps = {
  params: DraftParams;
  setParams: (_p: DraftParams) => void;
  contextRiskModel?: number;
  context?: ts.types.backtest.BacktestAnaylsisContext | ts.types.basket.BasketAnalysisContext;
};

const Form: React.FC<FormProps> = ({ params, setParams, context, contextRiskModel }): React.ReactElement => {
  const uiStyles = hooks.useUiStyles();

  const BUCKET_TYPES_ENUM = ts.enums.BUCKET_TYPES_ENUM;

  const [benchmark, setBenchmark] = React.useState(
    params.strategy_benchmark ? { type: 'BACKTEST' } : params.benchmark || { type: 'NONE' }
  );

  const [bucket, setBucket] = React.useState(
    params?.bucket || {
      type: ts.enums.BUCKET_TYPES_ENUM.QUANTILES,
      value: 5,
    }
  );

  const bucketOptions = [
    {
      value: BUCKET_TYPES_ENUM.QUANTILES,
      title: 'Quantiles',
    },
    {
      value: BUCKET_TYPES_ENUM.INDUSTRY,
      title: 'Industry',
    },
    {
      value: BUCKET_TYPES_ENUM.RISK_MODEL,
      title: 'Risk Model',
    },
    {
      value: BUCKET_TYPES_ENUM.ASSET_MASTER,
      title: 'Asset Master',
    },
  ];

  const benchmarkOptions = [{ value: 'BACKTEST', title: `${params.is_portfolio ? 'Strategy' : 'Backtest'} Benchmark` }];

  const assetsOptions = ['quotation_country', 'issuer_country', 'currency'];

  const industryOptions = ['rbics_l1_name', 'rbics_l2_name', 'rbics_l3_name', 'rbics_l4_name'];

  React.useEffect(() => {
    const data = {
      bucket,
      benchmark: benchmark.type === 'NONE' || benchmark.type === 'BACKTEST' ? undefined : benchmark,
      strategy_benchmark: benchmark.type === 'BACKTEST',
    };

    setParams({ ...params, ...data });
  }, [bucket, benchmark]);

  return (
    <mui.core.Box style={{ padding: '8px' }} sx={uiStyles.widgetEditMode}>
      <mui.core.Box mt={4}>
        <mui.core.Typography variant="body2" fontWeight="fontWeightBold">
          Bucket Analysis
        </mui.core.Typography>
      </mui.core.Box>
      <mui.core.Box mt={4}>
        <ui.Buckets
          value={bucket}
          setValue={setBucket}
          options={bucketOptions}
          assetMasterOptions={assetsOptions}
          industryOptions={industryOptions}
          getBucketDefaultValue={(value: ts.enums.BUCKET_TYPES_ENUM) => getBucketDefaultValue(value, contextRiskModel)}
        />
      </mui.core.Box>

      <mui.core.Box mt={4}>
        <mui.core.Typography variant="body2" fontWeight="fontWeightBold">
          Benchmark
        </mui.core.Typography>
      </mui.core.Box>

      <mui.core.Box mt={1}>
        <ui.Benchmark
          value={benchmark || undefined}
          setValue={setBenchmark}
          universeContextPath={'$universe.handle'}
          context={context}
          extraOptions={benchmarkOptions}
          universeIdPath="definition.universe_id"
          enableNone
        />
      </mui.core.Box>
    </mui.core.Box>
  );
};

export default Form;
