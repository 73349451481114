import React from 'react';

import Modal from './modal';
import { datesFmt } from '../../data';
import * as helpers from '../../helpers';
import { useEffectWithoutFirst } from '../../hooks';
import { _, mui } from '../../libs';
import * as types from '../../types';

const CHUNK_SIZE = 2 * 1048576;

type UploadCsvInBatchesProps = {
  requiredCols?: string[];
  postToApi: (_p: any, _b: string) => Promise<void>;
  buttonProps?: React.ComponentProps<typeof mui.core.Button>;
  buttonLabel?: string;
  example?: string;
  instructions?: React.ReactElement;
  createBatch?: (_n: string, _t: number) => Promise<string>;
  batchName?: string;
  performBatchUpdate?: (_b: string) => Promise<{ message: string; name: string }>;
  enableDateFormat?: boolean;
  enableFileDate?: boolean;
  customAction?: JSX.Element;
  successPath?: string;
};

/**
 * Component that handles uploading and processing large CSV files in chunks
 *
 * @param requiredCols - Optional array of required column names that must be present in CSV header
 * @param postToApi - Function to post each chunk to API endpoint
 * @param buttonProps - Optional props to pass to upload button component
 * @param buttonLabel - Optional label text for upload button
 * @param example - Optional example CSV content to show
 * @param instructions - Optional instructions element to display
 * @param createBatch - Optional function to create a new batch
 * @param batchName - Optional name for the batch
 * @param performBatchUpdate - Optional function to update batch after upload
 * @param enableDateFormat - Optional flag to enable date format selection
 * @param enableFileDate - Optional flag to enable file date selection
 * @param customAction - Optional custom action element to render
 * @param successPath - Optional path to redirect after successful upload
 *
 * @returns Rendered component for CSV batch upload
 */
const UploadCsvInBatches: React.FC<UploadCsvInBatchesProps> = ({
  requiredCols,
  buttonProps,
  postToApi,
  buttonLabel,
  example,
  instructions,
  createBatch,
  performBatchUpdate,
  enableDateFormat,
  enableFileDate,
  customAction,
  batchName,
  successPath,
}): React.ReactElement => {
  const [openModal, setOpenModal] = React.useState(false);
  const [error, setError] = React.useState('');
  const [chunks, setChunks] = React.useState(null);
  const [chunksReady, setChunksReady] = React.useState(null);

  const trailing = React.useRef('');
  const header = React.useRef('');
  const firstChunk = React.useRef(true);
  const lastChunk = React.useRef(false);

  const validHeader = () => {
    if (_.isEmpty(requiredCols)) return true;
    for (const column of requiredCols) if (!header.current.includes(column)) return false;
    return true;
  };

  const processChunk = (chunk: string) => {
    chunk = chunk.replace('\r', '');
    if (firstChunk.current) header.current = chunk.split('\n')[0];
    if (firstChunk.current && !validHeader())
      return {
        success: false,
        error: 'First row must have all the column names.',
      };

    // If we have a trailing from prev rows, let's attach at first of this chunk
    if (trailing.current) {
      chunk = trailing.current + chunk;
      trailing.current = '';
    }
    // let's attach the header to following chunks
    if (!firstChunk.current) chunk = header.current + '\n' + chunk;
    // If last character is not endline or last chunk, then we have a splitted row
    if (chunk[chunk.length - 1] != '\n' && !lastChunk.current) {
      trailing.current = chunk.slice(chunk.lastIndexOf('\n') + 1);
      chunk = chunk.slice(0, chunk.lastIndexOf('\n') + 1);
    }

    firstChunk.current = false;
    return { success: true, data: chunk };
  };

  const processUpload = async (file: File, dateFormat: string, fileDate: string) => {
    trailing.current = '';
    firstChunk.current = true;
    lastChunk.current = false;
    header.current = '';

    const localChunks = Math.ceil(file.size / CHUNK_SIZE);
    let localChunksReady = 0;
    setChunks(localChunks);
    setChunksReady(localChunksReady);

    const fr = new FileReader();
    let start = 0;
    let end = 0;
    const readNextChunk = () => {
      end += CHUNK_SIZE;
      if (end >= file.size) lastChunk.current = true;
      const slice = file.slice(start, end);
      fr.readAsArrayBuffer(slice);
      start = end;
    };

    fr.onload = () => {
      const buffer = new Uint8Array(fr.result as ArrayBuffer);
      const snippet = new TextDecoder('utf-8').decode(buffer);
      const processed = processChunk(snippet);

      if (processed.success) {
        const post = async () => {
          try {
            const payload = { csv: processed.data, dates_fmt: dateFormat };
            if (fileDate) {
              _.set(payload, 'file_date', fileDate);
            }
            await postToApi(payload, batchName);
            // Clear references to large data by forcing garbage collection
            processed.data = null;
            // Explicitly clear buffer
            buffer.fill(0);
          } catch (err) {
            setError(
              helpers.parseApiError(err as types.common.ApiError, (s: string) => {
                Object.keys(datesFmt).forEach((key) => {
                  s = s.replaceAll(key, datesFmt[key as keyof typeof datesFmt]);
                });
                return s;
              })
            );
          }
          if (end <= file.size) readNextChunk();
          localChunksReady += 1;
          setChunksReady(localChunksReady);
        };
        post();
      } else {
        setChunksReady(null);
        setChunks(null);
        setError(processed.error);
      }
    };

    readNextChunk();
  };

  useEffectWithoutFirst(() => {
    const triggerBatch = async () => {
      if (createBatch && batchName) {
        try {
          await createBatch(batchName, chunks);
          if (performBatchUpdate) await performBatchUpdate(batchName);
        } catch {
          console.log('Unable to create/update batch.');
        }
      }
    };

    if (chunks == chunksReady && _.isEmpty(error) && chunks && chunksReady) {
      triggerBatch();
    }
  }, [chunks, chunksReady, error]);

  return (
    <>
      <mui.core.Grid container flexDirection="column">
        <mui.core.Button
          {...buttonProps}
          onClick={() => {
            setOpenModal(true);
          }}
        >
          {buttonLabel}
        </mui.core.Button>
        {customAction && customAction}
      </mui.core.Grid>
      <Modal
        open={openModal}
        close={() => setOpenModal(false)}
        error={error}
        processUpload={processUpload}
        clearProgress={() => {
          setChunksReady(null);
          setChunks(null);
          setError('');
        }}
        chunksReady={chunksReady}
        chunks={chunks}
        example={example}
        instructions={instructions}
        enableDateFormat={enableDateFormat}
        enableFileDate={enableFileDate}
        successPath={successPath}
      />
    </>
  );
};

UploadCsvInBatches.defaultProps = {
  buttonProps: {},
  buttonLabel: 'Upload csv',
  requiredCols: [],
  example: '',
  createBatch: null,
  performBatchUpdate: null,
  enableDateFormat: false,
};

export default UploadCsvInBatches;
