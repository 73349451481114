import { hooks, mui, React, ts, ui } from '_core';

import { DraftParams, optionLabels } from './types';

type FormProps = {
  params: DraftParams;
  setParams: (_p: DraftParams) => void;
  context?: ts.types.timeSeries.TimeSeriesAnalysisContext;
};

const Form: React.FC<FormProps> = ({ params, setParams, context }) => {
  const [comparets, setcomparets] = React.useState(params.analysis_time_series_ids || []);
  const [statisticsTypes, setStatisticsType] = React.useState(params.statistics_types || []);
  const uiStyles = hooks.useUiStyles();

  const statisticsOptions = ['cumulative_returns', 'return', 'risk', 'adjusted'];

  React.useEffect(() => {
    const data = {
      ...params,
      time_series_id: context?.id ? undefined : comparets?.[0],
      analysis_time_series_ids: comparets,
      statistics_types: statisticsTypes,
    };
    setParams(data);
  }, [comparets, statisticsTypes]);

  return (
    <mui.core.Box sx={uiStyles.widgetEditMode}>
      <mui.core.Box mt={2}>
        <mui.core.FormControl fullWidth>
          <mui.core.Autocomplete
            size="small"
            multiple
            options={statisticsOptions}
            value={statisticsTypes}
            onChange={(_event, newValue) => {
              setStatisticsType(newValue as typeof statisticsTypes);
            }}
            disableCloseOnSelect
            renderInput={(params) => <mui.core.TextField {...params} variant="outlined" label="Statistics" />}
            getOptionLabel={(opt) => optionLabels[opt as (typeof statisticsTypes)[0]]}
          />
        </mui.core.FormControl>
      </mui.core.Box>

      <mui.core.Box mt={2}>
        <ui.ResourceAutocomplete<ts.types.timeSeries.TimeSeriesExpanded, true>
          value={comparets}
          setValue={(ids) => setcomparets(ids)}
          type={ts.enums.RESOURCES_TYPES_ENUM.TIME_SERIES}
          autocompleteProps={{
            size: 'small',
            multiple: true,
            disableCloseOnSelect: true,
          }}
          inputProps={{
            label: 'Select Time Series',
            variant: 'outlined',
            size: 'small',
            required: true,
          }}
          filter={context ? (ts) => ts.id !== context.time_series_id : undefined}
        />
      </mui.core.Box>
    </mui.core.Box>
  );
};

export default Form;
