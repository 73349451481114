import { mui, React, ts, ui } from '_core';

import RelaxOrder from './relax-order';

type MaximumRiskProps = {
  params: ts.types.optimizer.MaximumRiskDraft;
  setParams: (_params: ts.types.optimizer.MaximumRiskDraft) => void;
  readOnly: boolean;
  validationDef: ts.types.common.ValidationErrors;
  optimizerType: 'basket' | 'backtest';
};

const MaximumRisk: React.FC<MaximumRiskProps> = ({
  params,
  setParams,
  readOnly,
  validationDef,
  optimizerType,
}): React.ReactElement => {
  React.useEffect(() => {
    if (optimizerType == 'basket' && params.benchmark_relative == null) {
      setParams({ benchmark_relative: true });
    }
  }, []);

  return (
    <mui.core.Box p={2}>
      <mui.core.Grid container spacing={4}>
        <mui.core.Grid item xs={12} md={4}>
          <ui.NumericField
            textFieldProps={{
              label: 'Maximum Annualized Risk',
              fullWidth: true,
              variant: 'outlined',
              size: 'small',
              required: true,
              error: validationDef?.fields?.includes('maximum_annualized_risk'),
            }}
            disabled={readOnly}
            value={params.upper_bound}
            setValue={(v) => setParams({ ...params, upper_bound: v })}
            suffix="%"
            dividerOption={ts.enums.NUMBER_FORMATTERS_ENUM.HUNDRED}
          />
        </mui.core.Grid>

        <mui.core.Grid item xs={12} md={4}>
          <RelaxOrder params={params} setParams={setParams} disabled={readOnly} />
        </mui.core.Grid>

        <mui.core.Grid item xs={12} md={4}>
          <mui.core.Box px={3}>
            <mui.core.FormControlLabel
              control={
                <mui.core.Switch
                  size="small"
                  checked={params.benchmark_relative}
                  onChange={() =>
                    setParams({
                      ...params,
                      benchmark_relative: !params.benchmark_relative,
                    })
                  }
                  disabled={readOnly}
                />
              }
              label={optimizerType == 'backtest' ? 'Benchmark Relative' : 'Relative to Target Weights'}
            />
          </mui.core.Box>
        </mui.core.Grid>
      </mui.core.Grid>
    </mui.core.Box>
  );
};

export default MaximumRisk;
