import { React, ts } from '_core';

import Chart from './chart';

type OptimizedAlphaSignalWeightsVsTimeProps = {
  id: string;
  readOnly: boolean;
  widgetKey: string;
  fullScreen?: boolean;
  loadWidgetData: (_payload: ts.types.widgets.WidgetGetDataParams) => Promise<ts.types.widgets.WidgetGetDataResponse>;
  context?: ts.types.signal.SignalAnalysisContext;
  widgetPaths: Record<'optimized_alpha_model_data', string>;
  title: string;
};

const OptimizedAlphaSignalWeightsVsTime: React.FC<OptimizedAlphaSignalWeightsVsTimeProps> = ({
  id,
  readOnly,
  widgetKey,
  loadWidgetData,
  widgetPaths,
  context,
  fullScreen,
  title,
}) => {
  return (
    <Chart
      id={id}
      readOnly={readOnly}
      widgetKey={widgetKey}
      loadWidgetData={loadWidgetData}
      file={widgetPaths['optimized_alpha_model_data']}
      definition={
        (context.definition as ts.types.signal.AlphaDefinition)?.json_definition as ts.types.signal.OptimizedModel
      }
      fullScreen={fullScreen}
      title={title}
    />
  );
};

export default OptimizedAlphaSignalWeightsVsTime;
