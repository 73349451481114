import { _, ts } from '_core';

export const prepareDistributionData = (
  data: ts.types.widgets.LinearData,
  tab: 'signal_' | 'pipeline_' | 'sp_pipeline_'
): ts.types.widgets.LinearData => {
  const newData: ts.types.widgets.LinearData = {};
  Object.keys(data).forEach((key) => {
    let newKey = key.replace(tab, '');
    newKey = newKey.startsWith('Q') ? newKey : _.startCase(newKey);
    newData[newKey] = data[key];
  });
  return newData;
};
