import { ts } from '_core';

const getDefaultValue = (factorName: ts.enums.N_FACTOR_TYPE_ENUM): ts.types.pipeline.NeutralizeDraft['factors'][0] => {
  if (factorName == ts.enums.N_FACTOR_TYPE_ENUM.RISK_MODEL_STYLE) {
    return {
      type: factorName,
      value: 'risk_model_style',
    };
  }
  if (factorName == ts.enums.N_FACTOR_TYPE_ENUM.RISK_MODEL_CATEGORY) {
    return {
      type: factorName,
      value: 'industry',
    };
  }
  if (factorName == ts.enums.N_FACTOR_TYPE_ENUM.INDUSTRY)
    return {
      type: factorName,
      value: 'rbics_l1_id',
    };
  if (factorName == ts.enums.N_FACTOR_TYPE_ENUM.ASSET_MASTER)
    return {
      type: factorName,
      value: 'quotation_country',
    };
  if (factorName == ts.enums.N_FACTOR_TYPE_ENUM.SIGNAL)
    return {
      type: factorName,
      value: 'signal',
    };
  if (factorName == ts.enums.N_FACTOR_TYPE_ENUM.MARKET) {
    return {
      type: factorName,
    };
  }
};

export default getDefaultValue;
