import React from 'react';

import * as hooks from '../../hooks';
import { mui } from '../../libs';
import * as types from '../../types';
import ConfirmationDialog from '../confirmation-dialog';

type ResourceActionsProps = {
  resource: types.common.ResourceDraft;
  updateResource: (_d: types.common.ResourceDraft) => Promise<void>;
  openTour?: () => void;
  archivable?: boolean;
};

const ResourceActions: React.FC<ResourceActionsProps> = ({
  updateResource,
  resource,
  openTour,
  archivable,
}): React.ReactElement => {
  const uiStyles = hooks.useUiStyles();
  const [open, setOpen] = React.useState(false);

  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: MouseEvent | TouchEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const [handleArchiveSuccess, setHandleArchiveSuccess] = React.useState<'archive' | 'unarchive'>(null);
  const [loadingArchive, setLoadingArchive] = React.useState(false);
  const handleArchive = async (action: 'archive' | 'unarchive') => {
    setLoadingArchive(true);
    const data = {} as { id: number; is_deprecated: boolean };
    data.id = resource.id;
    data.is_deprecated = action == 'archive';
    await updateResource(data);
    setHandleArchiveSuccess(null);
    setLoadingArchive(false);
    setOpen(false);
  };

  function handleListKeyDown(event: any) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  return (
    <mui.core.Grid item sx={uiStyles.hidePrint}>
      <ConfirmationDialog
        open={!!handleArchiveSuccess}
        title="Are you sure?"
        content={
          handleArchiveSuccess == 'archive'
            ? 'Are you sure you want to archive this resource? It will be only accessible when searching for it.'
            : 'Are you sure you want to un-archive this resource?'
        }
        onSuccess={() => handleArchive(handleArchiveSuccess)}
        onCancel={() => {
          setHandleArchiveSuccess(null);
          setOpen(false);
        }}
        successLabel={handleArchiveSuccess == 'archive' ? 'Archive' : 'Un-archive'}
        loading={loadingArchive}
      />

      <mui.core.IconButton
        size="small"
        ref={anchorRef}
        onClick={(e) => {
          e.stopPropagation();
          handleToggle();
        }}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
        }}
      >
        <mui.icons.MoreVert />
      </mui.core.IconButton>
      <mui.core.Popper open={open} anchorEl={anchorRef.current} transition style={{ zIndex: '1000' }}>
        {({ TransitionProps, placement }) => (
          <mui.core.Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <mui.core.Paper>
              <mui.core.ClickAwayListener onClickAway={handleClose}>
                <mui.core.MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                  {archivable && resource.is_deprecated ? (
                    <mui.core.MenuItem
                      onClick={(e) => {
                        e.stopPropagation();
                        setHandleArchiveSuccess('unarchive');
                      }}
                    >
                      Un-archive
                    </mui.core.MenuItem>
                  ) : (
                    <mui.core.MenuItem
                      onClick={(e) => {
                        e.stopPropagation();
                        setHandleArchiveSuccess('archive');
                      }}
                    >
                      Archive
                    </mui.core.MenuItem>
                  )}
                  {openTour && <mui.core.MenuItem onClick={() => openTour()}>Open Tour</mui.core.MenuItem>}
                </mui.core.MenuList>
              </mui.core.ClickAwayListener>
            </mui.core.Paper>
          </mui.core.Grow>
        )}
      </mui.core.Popper>
    </mui.core.Grid>
  );
};

export default ResourceActions;
