import React from 'react';
import { useSelector } from 'react-redux';

import DateDayRender from './date-day-render';
import ContextSingleton from '../__singletons__/context-singleton';
import api from '../api';
import * as enums from '../enums';
import * as helpers from '../helpers';
import { _, moment, mui } from '../libs';
import { BaseStore } from '../types';

type SmartDateSelectorProps = {
  value: string;
  setValue?: (_v: string) => void;
  disabled?: boolean;
  minDate?: string;
  maxDate?: string;
  universeId?: number;
  countries?: string[];
  frequency?: enums.FREQUENCY_ENUM;
  label?: string;
  dateRef?: React.MutableRefObject<HTMLDivElement>;
  setExternalError?: (_e: string) => void;
  showError?: boolean;
  pickerProps?: { [key: string]: any };
  inputProps?: { [key: string]: any };
  forceReload?: boolean;
  hideMessage?: boolean;
  fullRange?: boolean;
  untilLateTradeDate?: boolean;
  setExternalValidDates?: (_d: string[]) => void;
};

/**
 * Footer component for the date picker showing weekend and holiday indicators
 */
export const DatePickerFooter = () => {
  const legendItemStyle = {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 5,
  };

  const legendCircleStyle = {
    width: 12,
    height: 12,
    borderRadius: '50%',
    marginRight: 8,
  };

  return (
    <mui.core.Box
      mt={4}
      width="100%"
      textAlign="center"
      display="flex"
      alignItems="center"
      columnGap={4}
      justifyContent="center"
    >
      <div style={legendItemStyle}>
        <div style={{ ...legendCircleStyle, backgroundColor: '#F4D3D6' }} />
        <mui.core.Typography variant="caption" color="textSecondary">
          Weekends
        </mui.core.Typography>
      </div>
      <div style={legendItemStyle}>
        <div style={{ ...legendCircleStyle, backgroundColor: '#fffcc2' }} />
        <mui.core.Typography variant="caption" color="textSecondary">
          Holidays
        </mui.core.Typography>
      </div>
    </mui.core.Box>
  );
};

/**
 * Smart date selector component that handles valid trading dates based on universe or countries
 *
 * @requires Redux Store:
 * - state.resources.universes: Array of universe objects with id and handle properties
 *
 * @requires Features:
 * - start_date: Default start date if not provided
 * - end_date: Default end date if not provided
 *
 * @param universeId - The ID of the universe to filter valid dates.
 * @param countries - An array of country codes to filter valid dates.
 * @param value - The currently selected date value.
 * @param setValue - Function to update the selected date value.
 * @param disabled - Flag to disable the date selector.
 * @param dateRef - Reference to the date input element.
 * @param maxDate - The maximum selectable date.
 * @param minDate - The minimum selectable date.
 * @param frequency - Frequency of the date selection (e.g., daily, weekly).
 * @param label - Label for the date input field.
 * @param setExternalError - Function to set external error state.
 * @param showError - Flag to show error messages.
 * @param pickerProps - Additional props for the date picker component.
 * @param inputProps - Additional props for the input field.
 * @param forceReload - Flag to force reload of valid dates.
 * @param hideMessage - Flag to hide messages related to date selection.
 * @param fullRange - Flag to indicate if the full range of dates should be shown.
 * @param setExternalValidDates - Function to set external valid dates.
 * @param untilLateTradeDate - Flag to include dates until the late trade date.
 *
 * @returns React component for selecting a date with validation
 */
const SmartDateSelector: React.FC<SmartDateSelectorProps> = ({
  universeId,
  countries,
  value,
  setValue = () => undefined,
  disabled = false,
  dateRef,
  maxDate,
  minDate,
  frequency = undefined,
  label = 'Date',
  setExternalError = () => undefined,
  showError = false,
  pickerProps = {},
  inputProps = {},
  forceReload = false,
  hideMessage = false,
  fullRange = false,
  setExternalValidDates = () => undefined,
  untilLateTradeDate = false,
}): React.ReactElement => {
  // As countries is an array, it could change the memo space with updates, but without changing the inner data
  // We will manage an inner state that will only be updated if there is a change

  const features = ContextSingleton.getInstance().features;

  const [innerCountries, setInnerCountries] = React.useState<string[]>();

  const universes = useSelector((state: BaseStore) => state?.resources?.universes ?? []);

  const universeHandle = React.useMemo(
    () => universes.find((uni) => uni.id == universeId),
    [universes, universeId]
  )?.handle;

  React.useEffect(() => {
    if (!_.isEqual(countries, innerCountries)) setInnerCountries(countries);
  }, [countries]);

  const [loading, setLoading] = React.useState(!!universeId || !!innerCountries);
  const [validDates, setValidDates] = React.useState<string[]>([]);
  const [allValidDailyDates, setAllValidDailyDates] = React.useState<string[]>([]);
  const [maxUniverseDate, setMaxUniverseDate] = React.useState<moment.Moment>();
  const [minUniverseDate, setMinUniverseDate] = React.useState<moment.Moment>();

  const [localValue, setLocalValue] = React.useState(value);

  // If we have changes from the outside, we need to update the localValue
  React.useEffect(() => {
    if (localValue !== value) setLocalValue(value);
  }, [value]);

  const loadUniverseValidDates = async (localFrequency = frequency) => {
    try {
      let getValidDatesPayload;
      if (universeId)
        getValidDatesPayload = {
          universe_id: universeId,
        };
      if (innerCountries)
        getValidDatesPayload = {
          countries: innerCountries,
          start_date: minDate || features.start_date,
          end_date: maxDate || features.end_date,
        };

      const resp = await api.dates.get_valid_dates({
        frequency: localFrequency,
        until_latest_trade_date: untilLateTradeDate,
        ...getValidDatesPayload,
      });
      const dates = resp.data || [];
      return dates;
    } catch {
      throw 'Error while loading the universe valid dates.';
    }
  };

  React.useEffect(() => {
    const loadValidDates = async () => {
      try {
        setLoading(true);

        const datesCalls = [loadUniverseValidDates()];

        if (frequency !== enums.FREQUENCY_ENUM.DAILY)
          datesCalls.push(loadUniverseValidDates(enums.FREQUENCY_ENUM.DAILY));

        const [dates, allDailyDates] = await Promise.all(datesCalls);

        setMinUniverseDate(dates?.[0] ? moment(moment(dates[0])) : undefined);
        setMaxUniverseDate(dates?.[dates.length - 1] ? moment(moment(dates[dates.length - 1])) : undefined);

        setValidDates(dates);
        setAllValidDailyDates(allDailyDates ?? dates);
        setExternalValidDates(dates);
      } catch {
        console.log('Error while loading the universe valid dates.');
      }
      setLoading(false);
    };

    if (universeId || innerCountries) loadValidDates();
  }, [universeId, forceReload, fullRange, frequency]);

  const minSelectorDate = React.useMemo(
    () =>
      minUniverseDate && moment(minDate).isBefore(minUniverseDate)
        ? moment(minUniverseDate).format('YYYY-MM-DD')
        : minDate
          ? moment(minDate).format('YYYY-MM-DD')
          : undefined,
    [minDate, minUniverseDate]
  );

  const maxSelectorDate = React.useMemo(
    () =>
      maxUniverseDate && moment(maxDate).isAfter(maxUniverseDate)
        ? moment(maxUniverseDate).format('YYYY-MM-DD')
        : maxDate
          ? moment(maxDate).format('YYYY-MM-DD')
          : undefined,
    [maxDate, maxUniverseDate]
  );

  const errorMessage = React.useMemo(() => {
    if (loading || hideMessage) return undefined;
    if (moment(value).isAfter(maxDate)) return 'Selected date should not be outside valid dates.';
    if (moment(value).isBefore(minDate)) return 'Selected date should not be outside valid dates.';
    if (universeHandle && !validDates.includes(value))
      return `${value} is not a trade date in universe ${universeHandle}`;
    return undefined;
  }, [value, validDates, minDate, maxDate, loading, hideMessage]);

  React.useEffect(() => {
    setExternalError(errorMessage);
  }, [errorMessage]);

  const loadingAdornment = loading ? (
    <mui.core.Box ml={1} mt={1} mr={2}>
      <mui.core.CircularProgress size={13} color="primary" sx={{ svg: { display: 'block!important' } }} />
    </mui.core.Box>
  ) : undefined;

  return (
    <>
      <mui.date.LocalizationProvider
        dateAdapter={mui.date.AdapterDateFns}
        localeText={{
          start: 'Start Date',
          end: 'End Date',
        }}
      >
        <mui.date.DatePicker
          {...(pickerProps || {})}
          defaultCalendarMonth={maxSelectorDate ? moment(maxSelectorDate).toDate() : undefined}
          disabled={loading || disabled}
          inputFormat="yyyy-MM-dd"
          label={label}
          renderInput={(props) => (
            <mui.core.TextField
              {...props}
              inputRef={helpers.mergeRefs([props.inputRef, dateRef])}
              helperText={errorMessage}
              sx={{ width: '100%' }}
            />
          )}
          // we will send moment of the string to remove timestamp
          maxDate={maxSelectorDate ? moment(maxSelectorDate).toDate() : undefined}
          minDate={minSelectorDate ? moment(minSelectorDate).toDate() : undefined}
          value={localValue ? moment(localValue).toDate() : null}
          onChange={(d) => {
            setLocalValue(moment(d).format('YYYY-MM-DD'));
            setValue(moment(d).format('YYYY-MM-DD'));
          }}
          components={{
            ActionBar: () => (
              <mui.core.Box pb={3}>
                {!_.isEmpty(validDates) && (
                  <mui.core.Box flex={1} mt={-2}>
                    <DatePickerFooter />
                  </mui.core.Box>
                )}
              </mui.core.Box>
            ),
          }}
          shouldDisableDate={
            !_.isEmpty(validDates)
              ? (date) => {
                  return !validDates.includes(moment(date).format('YYYY-MM-DD'));
                }
              : undefined
          }
          renderDay={(date, _dates, dayProps) => (
            <DateDayRender
              date={date}
              dayProps={dayProps}
              validDates={validDates}
              allValidDailyDates={allValidDailyDates}
            />
          )}
          InputProps={{
            ...inputProps,
            ...{
              disableUnderline: true,
              error: showError,
              size: 'small',
              startAdornment: loadingAdornment,
            },
          }}
        />
      </mui.date.LocalizationProvider>
    </>
  );
};

export default SmartDateSelector;
