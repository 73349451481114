import React from 'react';
import { Checkbox, FormControl, FormControlLabel, FormGroup } from '@mui/material';

import { _, mui } from '../../libs';
import * as types from '../../types';
import Button from '../button';
import CenteredLoader from '../centered-loader';

type ShareActionOptionsProps = {
  users: types.user.User[];
  resource: types.common.ResourceDraft;
  getUnshareDeps: (_sw: number[]) => types.common.ResourceDeps[];
  shareAction: (_selUsers: number[], _deps: types.common.ResourceDeps[]) => Promise<void>;
};

const ShareActionOptions: React.FC<ShareActionOptionsProps> = ({
  users,
  resource,
  shareAction,
  getUnshareDeps,
}): React.ReactElement => {
  const [loading, setLoading] = React.useState(false);
  const theme = mui.styles.useTheme() as mui.core.Theme;

  const [loadingDeps, setLoadingDeps] = React.useState(false);
  const [notSharedDeps, setNotSharedDeps] = React.useState([]);

  const getInitSelOption = () => {
    if (_.isEmpty(resource.shared_with)) return 'none';
    if (
      _.without(
        users.map((u) => u.id),
        ...resource.shared_with
      ).length == 0
    )
      return 'all';
    return 'single';
  };

  const [selUsers, setSelUsers] = React.useState(resource.shared_with);
  const [selOption, setSelOption] = React.useState<'all' | 'single' | 'none'>(getInitSelOption);

  const isValid = React.useMemo(() => {
    if (selOption == 'none' || selOption == 'all') return true;
    return !_.isEmpty(selUsers);
  }, [selUsers, selOption]);

  const handleShare = async () => {
    setLoading(true);
    await shareAction(selUsers, notSharedDeps);
    setLoading(false);
  };

  const debouncedSetNotSharedDeps = React.useCallback(
    _.debounce((localUsers) => {
      setNotSharedDeps(getUnshareDeps(localUsers));
      setLoadingDeps(false);
    }, 750),
    []
  );

  React.useEffect(() => {
    if (_.isEmpty(selUsers)) setNotSharedDeps([]);
    else {
      setLoadingDeps(true);
      debouncedSetNotSharedDeps(selUsers);
    }
  }, [selUsers]);

  return (
    <>
      <mui.core.DialogContent style={{ paddingTop: 0 }}>
        <mui.core.Box my={2}>
          <mui.core.FormControl component="fieldset">
            <mui.core.RadioGroup
              aria-label="withAll"
              value={selOption}
              sx={{
                '& .MuiFormControlLabel-label': {
                  fontSize: '0.9rem!important',
                },
              }}
              onChange={(e) => {
                if (e.target.value == 'all') setSelUsers(users.map((u) => u.id));
                if (e.target.value == 'none') setSelUsers([]);
                setSelOption(e.target.value as typeof selOption);
              }}
            >
              <mui.core.FormControlLabel
                value="none"
                control={<mui.core.Radio />}
                label={
                  <mui.core.Stack display="flex" flexDirection="row" gap={2} alignItems="center" my={2}>
                    <mui.core.IconButton
                      size="small"
                      sx={{
                        background: (theme.palette as any).gray.main,
                        pointerEvents: 'none',
                      }}
                    >
                      <mui.icons.Lock />
                    </mui.core.IconButton>
                    <mui.core.Typography>Private</mui.core.Typography>
                  </mui.core.Stack>
                }
              />
              <mui.core.FormControlLabel
                value="single"
                control={<mui.core.Radio />}
                label={
                  <mui.core.Stack display="flex" flexDirection="row" gap={2} alignItems="center" my={2}>
                    <mui.core.IconButton
                      size="small"
                      sx={{
                        background: (theme.palette as any).gray.main,
                        pointerEvents: 'none',
                      }}
                    >
                      <mui.icons.Person />
                    </mui.core.IconButton>
                    <mui.core.Typography>Share with specific users</mui.core.Typography>
                  </mui.core.Stack>
                }
              />

              {selOption == 'single' && (
                <mui.core.Box
                  sx={{
                    maxHeight: '200px',
                    overflow: 'auto',
                  }}
                  p={1}
                  px={4}
                  ml={4}
                >
                  <FormControl required component="fieldset" variant="standard" sx={{ width: '100%' }}>
                    <FormGroup>
                      {users.map((u) => (
                        <FormControlLabel
                          key={u.email}
                          sx={{
                            mb: 3,
                            background: (theme.palette as any).background.default,
                            borderRadius: '4px',
                            width: '100%',
                          }}
                          control={
                            <Checkbox
                              checked={selUsers.includes(u.id)}
                              onChange={() => {
                                if (selUsers.includes(u.id)) setSelUsers(selUsers.filter((f) => f !== u.id));
                                else setSelUsers([...selUsers, u.id]);
                              }}
                              name={u.email}
                            />
                          }
                          label={`${u.name} (${u.email})`}
                        />
                      ))}
                    </FormGroup>
                  </FormControl>
                </mui.core.Box>
              )}

              <mui.core.FormControlLabel
                value="all"
                control={<mui.core.Radio />}
                label={
                  <mui.core.Stack display="flex" flexDirection="row" gap={2} alignItems="center" my={2}>
                    <mui.core.IconButton
                      size="small"
                      sx={{
                        background: (theme.palette as any).gray.main,
                        pointerEvents: 'none',
                      }}
                    >
                      <mui.icons.Business />
                    </mui.core.IconButton>
                    <mui.core.Typography>Share with all</mui.core.Typography>
                  </mui.core.Stack>
                }
              />
            </mui.core.RadioGroup>
          </mui.core.FormControl>
          {(loadingDeps || (!loadingDeps && !_.isEmpty(notSharedDeps))) && (
            <mui.core.Box
              mt={3}
              sx={{
                border: '1px dashed #ddd',
                borderRadius: '4px',
                display: 'block',
                p: 3,
              }}
            >
              {loadingDeps && <CenteredLoader label="Loading dependencies..." />}
              {!loadingDeps && !_.isEmpty(notSharedDeps) && (
                <mui.core.Typography variant="body2" color="textSecondary">
                  These dependencies will also be shared with all users:
                  <ul>
                    {notSharedDeps.map((dep) => (
                      <li key={dep.handle}>{`${dep.name} (${dep.handle})`}</li>
                    ))}
                  </ul>
                </mui.core.Typography>
              )}
            </mui.core.Box>
          )}
        </mui.core.Box>
      </mui.core.DialogContent>
      <mui.core.DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={handleShare}
          style={{ margin: '0rem 1rem 1rem' }}
          fullWidth
          disabled={!isValid || loading || loadingDeps}
          loading={loading}
        >
          Update
        </Button>
      </mui.core.DialogActions>
    </>
  );
};

export default ShareActionOptions;
