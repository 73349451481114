/* eslint-disable no-unused-vars */
import { ts } from '_core';

export enum INTERACTION_PARAMS {
  TABLE_PARAMS = 'table_params',
  TABLE_DTYPES = 'table_dtypes',
}

export type InteractionParams = {
  [INTERACTION_PARAMS.TABLE_PARAMS]: ts.types.components.dataGrid.TableParamsSheet;
  [INTERACTION_PARAMS.TABLE_DTYPES]: ts.types.components.dataGrid.TableDtypesSheet;
};

export type JobParams = {
  overall_stats?: boolean;
};

export type Params = JobParams & InteractionParams;
export type DraftParams = Partial<Params>;
