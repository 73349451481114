import { RESOURCES_TYPES_ENUM } from '../../../enums';
import { Basket } from '../../../types/basket';
import { BasketExpanded } from '../../../types/basket';

const expandBasket = (basket: Basket & { resourceType: string }): BasketExpanded => {
  return {
    id: basket.id,
    name: basket.name,
    handle: basket.handle,
    type: basket.type,
    resourceType: RESOURCES_TYPES_ENUM.BASKET,
    created_by_id: basket.created_by_id,
    label_ids: basket.label_ids,
    definition: [],
    is_deprecated: basket.is_deprecated,
    revoked: basket.revoked || false,
    is_valid: basket.is_valid,
    is_published: basket.is_published,
    created_at: basket.created_at,
    source_resource_id: basket.source_resource_id,
    organization_id: basket.organization_id,
    has_description: basket.has_description,
    short_description: basket.short_description,
    source_org_id: basket.source_org_id,
    shared: basket.shared,
  } as BasketExpanded;
};

export default expandBasket;
