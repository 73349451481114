import { ts } from '_core';

import { validate } from './helpers';
import { INTERACTION_PARAMS } from './types';

export default [
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.BACKTEST_DETAILED_VIEW_CONSTRAINTS,
    form: true,
    helpLabel: 'xe9qth5z',
    title: 'Constraints',
    neededFiles: ['backtest_preview_constraints'],
    interactionParams: Object.values(INTERACTION_PARAMS),
    tags: [
      ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.BACKTEST_DETAILS,
      ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.BASKET_DETAILS,
    ],
    clearInteractionOnRegenerate: ['table_dtypes'],

    validate,
  },
] satisfies ts.types.analysis.WidgetConfig[];
