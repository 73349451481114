import { _, helpers, ts } from '_core';

import { DraftParams } from './types';

export const formatSeriesName = (key: string, baskets: ts.types.basket.Basket[]) => {
  const currentBasket = baskets.find((b) => b.handle == key);
  if (currentBasket) return currentBasket.name;

  return key;
};

export const prepareLinearData = (
  localData: ts.types.widgets.LinearData,
  isPortfolio = false,
  baskets: ts.types.basket.Basket[] = []
) => {
  const newData = {} as ts.types.widgets.LinearData;
  const keyMap = {} as Record<string, string>;

  // First create mapping of keys
  Object.keys(localData).forEach((key) => {
    let entityKey = key.split('-')[0];
    let historyType = '';
    Object.values(ts.enums.HISTORY_ENUM_EXTENDED).forEach((historyEnum) => {
      if (entityKey.startsWith(historyEnum)) {
        historyType = `${historyEnum}_`;
        entityKey = entityKey.replace(historyType, '');
      }
    });

    let formattedKey = formatSeriesName(entityKey, baskets);
    formattedKey = `${historyType}${formattedKey}`;
    keyMap[key] = formattedKey;
  });

  // Use cached key mapping to populate data
  Object.keys(localData).forEach((key) => {
    if (isPortfolio) {
      if (key.includes('portfolio_returns')) newData[keyMap[key]] = localData[key];
    } else if (!key.includes('portfolio_returns')) newData[keyMap[key]] = localData[key];
  });

  return newData;
};

export const validate = (params: DraftParams) => helpers.validations.validBasketCompare(params);

export const formatData = (
  data: ts.types.widgets.TableData,
  periods: ts.enums.PERIOD_ENUM[]
): ts.types.widgets.TableData => {
  const groupedData = _.groupBy(data, 'end_date');

  const entriesByEndDate = _.map(groupedData, (items, date) => {
    const formattedEntry: ts.types.widgets.TableData[number] = { date };

    items.forEach((item) => {
      const { backtest_handle, return: portfolio_returns, benchmark_returns, active_returns } = item;

      formattedEntry[`${backtest_handle}-portfolio_returns`] = portfolio_returns;
      formattedEntry[`${backtest_handle}-benchmark_returns`] = benchmark_returns;
      formattedEntry[`${backtest_handle}-active_returns`] = active_returns;

      periods.forEach((history) => {
        if (`${history}_active_returns` in item)
          formattedEntry[`${history}_${backtest_handle}-active_returns`] = item[`${history}_active_returns`];
        if (`${history}_benchmark_returns` in item)
          formattedEntry[`${history}_${backtest_handle}-benchmark_returns`] = item[`${history}_benchmark_returns`];
        if (`${history}_return` in item)
          formattedEntry[`${history}_${backtest_handle}-portfolio_returns`] = item[`${history}_return`];
      });
    });

    return formattedEntry;
  });
  return _.sortBy(entriesByEndDate, 'date');
};
