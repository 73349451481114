
import { FREQUENCY_ENUM } from '../enums';
import { _ } from '../libs';
import { Pipeline } from '../types/pipeline';
import { FrequencyAmount, ReturnHorizon } from '../types/return-horizon';

/**
 * Converts a frequency enum value to a human readable label
 * @param f - The frequency enum value to convert
 * @returns The human readable label for the frequency
 */
export const _freqParseLabel = (f: FREQUENCY_ENUM): string => {
  if (f == FREQUENCY_ENUM.DAILY) return 'Day';
  if (f == FREQUENCY_ENUM.MONTHLY) return 'Month';
  if (f == FREQUENCY_ENUM.QUARTERLY) return 'Quarter';
  if (f == FREQUENCY_ENUM.SEMIANNUAL) return 'Semiyear';
  if (f == FREQUENCY_ENUM.ANNUAL) return 'Year';
  if (f == FREQUENCY_ENUM.WEEKLY) return 'Week';
};

/**
 * Generates a human readable label for a return horizon record
 * @param record - The return horizon record to generate a label for
 * @returns The generated label string
 */
export const getLabel = (record: ReturnHorizon) => {
  if (record.is_signal) {
    return record.signature;
  }

  const periods = record.periods;
  const skip = record.skip;

  const pipeline_handles = record.pipeline_handles ? record.pipeline_handles.reverse() : [];
  let label = '';
  if (periods?.amount && periods?.frequency) label += `${periods.amount}-${_freqParseLabel(periods.frequency)} Return`;
  if (skip?.amount && skip?.frequency) label += `, ${skip.amount}-${_freqParseLabel(skip.frequency)} Lead`;

  _.reverse(pipeline_handles).forEach((pipeline) => {
    label = pipeline + `(${label})`;
  });

  return label;
};

/**
 * Converts a frequency signature string to a frequency enum value
 * @param f - The frequency signature string to convert
 * @returns The corresponding frequency enum value
 */
export const _signatureToFreq = (f: string): FREQUENCY_ENUM => {
  if (f == 'day(s)') return FREQUENCY_ENUM.DAILY;
  if (f == 'month(s)') return FREQUENCY_ENUM.MONTHLY;
  if (f == 'quarter(s)') return FREQUENCY_ENUM.QUARTERLY;
  if (f == 'semiyear(s)') return FREQUENCY_ENUM.SEMIANNUAL;
  if (f == 'year(s)') return FREQUENCY_ENUM.ANNUAL;
  if (f == 'week(s)') return FREQUENCY_ENUM.WEEKLY;
};

/**
 * Parses a signature string into a return horizon object
 * @param signature - The signature string to parse
 * @returns The parsed return horizon object
 */
export const getObjectFromSignature = (signature: string): ReturnHorizon => {
  let periods = { frequency: FREQUENCY_ENUM.WEEKLY, amount: 1 };
  let skip = undefined;
  const pipeline_handles: string[] = [];
  const partialSignature = signature.split('-');
  partialSignature.forEach((pSignature, index) => {
    const isPeriod = !isNaN(pSignature[0] as any);
    if (isPeriod) {
      const elems = pSignature.split('_');
      // This is the rh period (not skip)
      if (index == 0) periods = { frequency: _signatureToFreq(elems[1]), amount: parseInt(elems[0]) };
      else skip = { frequency: _signatureToFreq(elems[1]), amount: parseInt(elems[0]) };
    } else {
      // Pipelines
      pipeline_handles.push(pSignature);
    }
  });

  return { signature, skip, periods, pipeline_handles };
};

/**
 * Converts a frequency enum value to a signature string
 * @param f - The frequency enum value to convert
 * @returns The signature string representation
 */
export const _freqToSignature = (f: FREQUENCY_ENUM): string => {
  if (f == FREQUENCY_ENUM.DAILY) return 'day(s)';
  if (f == FREQUENCY_ENUM.MONTHLY) return 'month(s)';
  if (f == FREQUENCY_ENUM.QUARTERLY) return 'quarter(s)';
  if (f == FREQUENCY_ENUM.SEMIANNUAL) return 'semiyear(s)';
  if (f == FREQUENCY_ENUM.ANNUAL) return 'year(s)';
  return 'week(s)';
};

/**
 * Generates a unique signature string for return horizon attributes
 * @param periods - The frequency amount configuration
 * @param skip - Optional skip configuration
 * @param pipeline_ids - Optional array of pipeline IDs
 * @param pipelines - Optional array of pipeline objects
 * @returns The generated signature string
 */
export const getSignature = (
  periods: FrequencyAmount,
  skip: FrequencyAmount | null | undefined,
  pipeline_ids: number[] | undefined,
  pipelines: Pipeline[] | null | undefined
): string => {
  let signature = '';
  if (!_.isEmpty(periods)) signature += `${periods.amount}_${_freqToSignature(periods.frequency)}`;
  if (skip?.amount && skip?.frequency) signature += `-${skip.amount}_${_freqToSignature(skip.frequency)}`;
  (pipeline_ids || []).forEach((pId) => {
    const pipeline = (pipelines || []).find((p) => p.id == pId);
    if (pipeline) signature += `-${pipeline.handle}`;
  });

  return signature;
};

/**
 * Gets a human readable label from a signature string
 * @param signature - The signature string to convert to a label
 * @returns The human readable label
 */
export const getLabelFromSignature = (signature: string) => {
  return getLabel(getObjectFromSignature(signature));
};

/**
 * Validates a return horizon configuration
 * @param returnHorizon - The return horizon configuration to validate
 * @param frequency - Optional frequency to validate against
 * @returns True if the configuration is valid, false otherwise
 */
export const validReturnHorizon = (returnHorizon: ReturnHorizon, frequency?: FREQUENCY_ENUM) => {
  const { periods, skip } = returnHorizon;

  if (!periods.frequency || !periods.amount) return false;
  if (periods.amount < 1) return false;

  if (skip && (!skip.frequency || !skip.amount)) return false;
  if (skip && skip.amount < 1) return false;
  if (skip && periods.frequency !== skip.frequency && skip.frequency !== 'DAILY') return false;
  if (frequency && periods.frequency !== frequency) return false;
  return true;
};
