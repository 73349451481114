import { _, helpers } from '_core';

import { DraftParams } from './types';

export const validate = (params: DraftParams) => {
  const rhErrors = helpers.validations.validSelectedReturnHorizon(params?.return_horizon);
  if (!_.isEmpty(rhErrors)) return rhErrors;
  if (_.isEmpty(params.histories)) return ['At least one history is required.'];

  // Risk aversion

  if (_.isNil(params.upper)) return ['Upper bound is a required field.'];
  if (_.isNil(params.lower)) return ['Lower bound is a required field.'];
  if (params.lower >= params.upper) return ['Upper bound needs to be greater than Lower bound.'];

  if (params.log) {
    if (params.lower < 1) return ['Lower bound needs to be greater than or equal to 1.'];
    if (_.isNil(params.n)) return ['Number of values is a required field.'];
    if (params.n <= 0) return ['The number of risk aversion values needs to be greater than zero.'];
    if (params.n % 1 != 0) return ['The number of risk aversion values needs to be an integer.'];
    if (params.n > 10) return ['The number of risk aversion values needs to be at most 10.'];
  } else {
    if (params.lower < 0) return ['Lower bound needs to be greater than or equal to 0.'];
    if (_.isNil(params.step)) return ['Step is a required field.'];
    if (params.step <= 0) return ['Step needs to be greater than zero.'];
    const numberOfEls = Math.ceil((params.upper + params.step / 2 - params.lower) / params.step);
    if (numberOfEls > 10) return ['The number of risk aversion values needs to be at most 10.'];
  }

  return [];
};
