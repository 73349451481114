import { ts } from '_core';

import { DraftParams, INTERACTION_PARAMS, Params } from './types';

export default [
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.SIGNAL_SET_BROWSER,
    title: 'Browser',
    helpLabel: 'n14u06uc',
    interactionParams: Object.values(INTERACTION_PARAMS),
    defaultParams: { bookmarked: [] } as Params,
    form: true,
    neededFiles: ['signal_set_browser'],
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.SIGNAL_SET_BROWSER],

    validate: (params: DraftParams, _c: any, _g: any, resources: ts.StoreState['resources']) => {
      const defaultAnalysis = resources.report_defaults.find((rd) => rd.id == params.report_default_id);
      if (defaultAnalysis) return [];
      return ['Signal Analysis Layout is required.'];
    },
  },
] satisfies ts.types.analysis.WidgetConfig[];
