import { mui, React, ts, ui, useSelector } from '_core';

import Bounds from '../bounds';
import SharedConstraintFields from '../shared-fields';

type TradeListSignalExposureConstraintProps = {
  id: string;
  params: ts.types.optimizer.TradeListSignalExposureConstraintDraft;
  setParams: (_params: ts.types.optimizer.TradeListSignalExposureConstraintDraft) => void;
  readOnly: boolean;
  validationDef: ts.types.common.ValidationErrors;
};

const TradeListSignalExposureConstraint: React.FC<TradeListSignalExposureConstraintProps> = ({
  id,
  params,
  setParams,
  readOnly,
  validationDef,
}): React.ReactElement => {
  const [showMore, setShowMore] = React.useState(false);

  const signals = useSelector((state) => state.resources.signals);

  return (
    <>
      <mui.core.Box p={2}>
        <mui.core.Box pb={3}>
          <mui.core.TextField
            select
            label="Constraint Type"
            variant="outlined"
            value={params.trade_list_constraint_type}
            onChange={(e) =>
              setParams({
                ...params,
                trade_list_constraint_type: e.target
                  .value as ts.types.optimizer.TradeListConstraint['trade_list_constraint_type'],
              })
            }
            fullWidth
            disabled={readOnly}
            size="small"
            error={validationDef?.fields?.includes(`constraint_type_${id}`)}
          >
            <mui.core.MenuItem value={'DONT_TRADE'}>{"Don't Trade"}</mui.core.MenuItem>
            <mui.core.MenuItem value={'NO_INCREASE'}>{'No Increase'}</mui.core.MenuItem>
            <mui.core.MenuItem value={'DONT_BUY'}>{"Don't Buy"}</mui.core.MenuItem>
            <mui.core.MenuItem value={'DONT_SELL'}>{"Don't Sell"}</mui.core.MenuItem>
            <mui.core.MenuItem value={'DONT_SHORT'}>{"Don't Short"}</mui.core.MenuItem>
            <mui.core.MenuItem value={'DONT_COVER'}>{"Don't Cover"}</mui.core.MenuItem>
            <mui.core.MenuItem value={'NO_HOLD'}>{'No Hold'}</mui.core.MenuItem>
          </mui.core.TextField>
        </mui.core.Box>

        <mui.core.Box pb={3}>
          <ui.ResourceAutocomplete<ts.types.signal.SignalExpanded>
            value={params.signal_id}
            setValue={(id) =>
              setParams({
                ...params,
                signal_id: id,
                signal_handle: signals.find((s) => s.id == id).handle,
              })
            }
            type={ts.enums.RESOURCES_TYPES_ENUM.SIGNAL}
            disabled={readOnly}
            inputProps={{
              fullWidth: true,
              label: 'Signal',
              variant: 'outlined',
              size: 'small',
              required: true,
              error: validationDef?.fields?.includes(`signal_${id}`),
            }}
          />
        </mui.core.Box>

        <mui.core.Box pb={3}>
          <Bounds id={id} params={params} setParams={setParams} readOnly={readOnly} validationDef={validationDef} />
        </mui.core.Box>

        {showMore && (
          <SharedConstraintFields
            id={id}
            params={params}
            setParams={setParams}
            showRelaxation={false}
            readOnly={readOnly}
            validationDef={validationDef}
          />
        )}
      </mui.core.Box>
      <mui.core.Box p={3}>
        <mui.core.Button
          onClick={() => {
            setShowMore(!showMore);
          }}
          color="primary"
        >
          {showMore ? (
            <>
              <mui.icons.Remove style={{ fontSize: '14px', marginRight: '0.5rem' }} />
              Show Less
            </>
          ) : (
            <>
              <mui.icons.Add style={{ fontSize: '14px', marginRight: '0.5rem' }} />
              Show More
            </>
          )}
        </mui.core.Button>
      </mui.core.Box>
    </>
  );
};

export default TradeListSignalExposureConstraint;
