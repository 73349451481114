import React from 'react';

import { _, moment } from '../libs';

type Align = 'center' | 'left' | 'right';
type ColType = 'string' | 'integer' | 'float' | 'date' | 'boolean' | 'raw' | 'inferred';

/**
 * Formats a string value with optional max length and capitalization
 * @param value - The string to format
 * @param maxLength - Optional maximum length to truncate to
 * @param capitalize - Whether to capitalize the string
 * @returns The formatted string
 */
export const _formatString = ({
  value,
  maxLength,
  capitalize,
}: {
  value: string;
  maxLength?: number;
  capitalize?: boolean;
}) => {
  if (capitalize) value = _.startCase(value);
  if (maxLength) return value.slice(0, maxLength);
  return value;
};

/**
 * Formats a boolean value with options for output format
 * @param value - The boolean value to format
 * @param toValue - Whether to return the raw boolean value
 * @param booleanAsNumber - Whether to return 1/0 instead of TRUE/FALSE
 * @returns The formatted boolean value
 */
export const _formatBoolean = ({
  value,
  toValue,
  booleanAsNumber,
}: {
  value: boolean;
  toValue?: boolean;
  booleanAsNumber?: boolean;
}) => {
  if (toValue) return value;
  if (booleanAsNumber) return value ? 1 : 0;
  return _.upperCase(value.toString());
};

/**
 * Formats a date value with optional format string
 * @param value - The date string/number to format
 * @param toValue - Whether to return a Date object
 * @param format - Optional date format string (default: 'YYYY-MM-DD')
 * @returns The formatted date string or Date object
 */
export const _formatDate = ({
  value,
  toValue,
  format = 'YYYY-MM-DD',
}: {
  value: string | number;
  toValue?: boolean;
  format?: string;
}) => {
  const newDate = moment(value);
  if (!newDate.isValid()) return '';

  if (toValue) return newDate.toDate();
  return newDate.format(format);
};

/**
 * Adds commas as thousand separators to a number string
 * @param n - The number string to format
 * @returns The formatted number string with commas
 */
export const _commify = (n: string) => {
  const parts = n.split('.');
  const numberPart = parts[0];
  const decimalPart = parts[1];
  const thousands = /\B(?=(\d{3})+(?!\d))/g;
  return numberPart.replace(thousands, ',') + (decimalPart ? '.' + decimalPart : '');
};

/**
 * Adds a numeric prefix to a value, handling negative numbers
 * @param value - The number string to prefix
 * @param numericPrefix - The prefix to add
 * @returns The prefixed number string
 */
export const _addNumericPrefix = ({ value, numericPrefix }: { value: string; numericPrefix: string }) => {
  if (_.isEqual(value.slice(0, 1), '-')) {
    value = value.substring(1);
    return `-${numericPrefix}${value}`;
  }
  return `${numericPrefix}${value}`;
};

/**
 * Formats a float value with various formatting options
 * @param value - The float value to format
 * @param toValue - Whether to return the raw number
 * @param roundDigits - Number of decimal places to round to
 * @param multiplier - Optional multiplier to apply
 * @param suffix - Optional suffix to append
 * @param prefix - Optional prefix to prepend
 * @param numericPrefix - Optional numeric prefix to add
 * @param commasOnThousands - Whether to add thousand separators
 * @returns The formatted float value
 */
export const _formatFloat = ({
  value,
  toValue,
  roundDigits,
  multiplier,
  suffix,
  prefix,
  numericPrefix,
  commasOnThousands,
}: {
  value: string | number;
  toValue?: boolean;
  roundDigits?: number;
  multiplier?: number;
  suffix?: string;
  prefix?: string;
  numericPrefix?: string;
  commasOnThousands?: boolean;
}) => {
  let newVal = parseFloat(value as string);
  if (multiplier) newVal *= multiplier;

  if (toValue) return newVal;

  let returnValue = newVal.toString();
  if (roundDigits) returnValue = newVal.toFixed(roundDigits);
  if (isNaN(newVal)) return '';

  if (commasOnThousands) returnValue = _commify(returnValue);
  if (suffix) returnValue = `${returnValue}${suffix}`;
  if (prefix) returnValue = `${prefix}${returnValue}`;

  if (numericPrefix) returnValue = `${_addNumericPrefix({ value: returnValue, numericPrefix: numericPrefix })}`;

  return returnValue;
};

/**
 * Formats an integer value with various formatting options
 * @param value - The integer value to format
 * @param toValue - Whether to return the raw number
 * @param commasOnThousands - Whether to add thousand separators
 * @param prefix - Optional prefix to prepend
 * @param numericPrefix - Optional numeric prefix to add
 * @returns The formatted integer value
 */
export const _formatInteger = ({
  value,
  toValue,
  commasOnThousands,
  prefix,
  numericPrefix,
}: {
  value: string | number;
  toValue?: boolean;
  commasOnThousands?: boolean;
  prefix?: string;
  numericPrefix?: string;
}) => {
  const newVal = parseInt(value as string);
  if (isNaN(newVal)) return '';

  if (toValue) return newVal;

  let returnValue = newVal.toString();

  if (commasOnThousands) returnValue = _commify(returnValue);
  if (prefix) returnValue = `${prefix}${returnValue}`;
  if (numericPrefix) returnValue = `${_addNumericPrefix({ value: returnValue, numericPrefix: numericPrefix })}`;
  return returnValue;
};

/**
 * Infers the type of a value and formats it accordingly
 * @param value - The value to format
 * @param args - Additional formatting arguments
 * @returns The formatted value based on inferred type
 */
export const _formatInferred = ({ value, ...args }: { value: string | number }) => {
  if (value === '') return '';

  const inferredValueType = _.inferType(value);

  if (inferredValueType == 'boolean') return _formatBoolean({ value, ...args } as { value: any });

  if (inferredValueType == 'integer') return _formatInteger({ value, ...args });

  if (inferredValueType == 'number') return _formatFloat({ value, roundDigits: 4, ...args });

  if (inferredValueType == 'date') return _formatDate({ value, format: 'YYYY-MM-DD', ...args });

  return _formatString({ value: value as string, ...args });
};

type FormatArgs = {
  align?: Align;
  toValue?: boolean;
  [key: string]: string | number | boolean;
};

/**
 * Creates a formatter function for a specific column type
 * @param colType - The type of column to format
 * @param args - Formatting arguments
 * @param customFormatter - Optional custom formatter function
 * @returns A formatter function that returns a React element
 */
export const formatTo =
  (colType: ColType, args: FormatArgs = { align: 'left', showBlanks: false }, customFormatter?: (_v: any) => any) =>
  ({
    row,
    column,
    toValue,
  }: {
    row: Record<string, any>;
    column: { key: string };
    toValue?: boolean;
  }): React.ReactElement => {
    let value = row[column.key];

    if (args.showBlanks && !value && !toValue) {
      return <div style={{ textAlign: args.align, width: '100%' }}>- - -</div>;
    }

    if (customFormatter) {
      value = customFormatter(value);
    }

    if (colType == 'string') value = _formatString({ value: value, toValue, ...args });
    if (colType == 'date') value = _formatDate({ value: value, toValue, ...args });
    if (colType == 'float') value = _formatFloat({ value: value, toValue, ...args });
    if (colType == 'integer') value = _formatInteger({ value: value, toValue, ...args });
    if (colType == 'boolean') value = _formatBoolean({ value: value, toValue, ...args });
    if (colType == 'inferred') {
      if (_.inferType(value) == 'number' || _.inferType(value) == 'integer') args = { align: 'right', ...args };

      if (_.inferType(value) == 'string') args = { align: 'left', ...args };
      if (_.inferType(value) == 'date') args = { align: 'right', ...args };

      value = _formatInferred({ value: value, toValue, ...args });
    }

    if (args.align && !toValue) return <div style={{ textAlign: args.align, width: '100%' }}>{value}</div>;

    return value;
  };

type SingleFormat = {
  colType: ColType;
  args?: FormatArgs;
};

/**
 * Creates a dynamic formatter based on column configurations
 * @param columns - Object containing column format configurations
 * @returns A formatter function that returns a React element
 */
export const formatDynamicTo =
  (columns: { other: SingleFormat; [key: string]: SingleFormat }) =>
  ({
    row,
    column,
    toValue,
  }: {
    row: Record<string, any>;
    column: { key: string };
    toValue?: boolean;
  }): React.ReactElement => {
    if (columns[column.key]) {
      const colType = columns[column.key].colType;
      const args = columns[column.key].args || ({} as FormatArgs);

      return formatTo(colType, args)({ row, column, toValue });
    }

    const colType = columns['other'].colType;
    const args = columns['other'].args || ({} as FormatArgs);
    return formatTo(colType, args)({ row, column, toValue });
  };
