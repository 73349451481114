import { _, custom, helpers, moment, ts } from '_core';

import { getBucketName, quantileLabel } from './get-series-name';
import { getCountryName, getCurrencyName, getIndustryName } from './get-series-name';

export const getDynamicName = (key: string) => {
  const newKey = key.replaceAll('=>', ' » ');
  if (newKey.startsWith('_handle_')) return newKey.replaceAll('_handle_', '');
  if (newKey.startsWith('_debug_')) return newKey.replaceAll('_debug_', '');
  if (newKey.startsWith('_index_')) return _.startCase(newKey.replaceAll('_index_', ''));
  return _.startCase(newKey);
};

export const getPipelineNames = (key: string, resources: ts.StoreState['resources'], cleanName = false) => {
  const pipeline = resources.pipelines.find((p) => p.handle == key.split('=>')[1])?.name ?? 'None';
  const sppipeline = resources.pipelines.find((p) => p.handle == key.split('=>')[2])?.name ?? 'None';
  if (cleanName) return `${pipeline} - ${sppipeline}`;
  return <custom.MultilineTableHeader name={[`P: ${pipeline}`, `PP: ${sppipeline}`]} />;
};

export const getPipelinesNameComparison = (
  key: string,
  resources: ts.StoreState['resources'],
  cleanName = false
) => {
  const pipeline = resources.pipelines.find((p) => p.handle == key.split('=>')[1])?.name ?? 'None';
  const sppipeline = resources.pipelines.find((p) => p.handle == key.split('=>')[2])?.name ?? 'None';

  if (key.endsWith('_date1')) {
    if (cleanName) return `${pipeline} - ${sppipeline} - First Date`;
    return <custom.MultilineTableHeader name={[`P: ${pipeline}`, `PP: ${sppipeline}`, 'First Date']} />;
  }

  if (key.endsWith('_date2')) {
    if (cleanName) return `${pipeline} - ${sppipeline} - Second Date`;
    return <custom.MultilineTableHeader name={[`P: ${pipeline}`, `PP: ${sppipeline}`, 'Second Date']} />;
  }

  if (key.endsWith('_difference')) {
    if (cleanName) return `${pipeline} - ${sppipeline} - Difference`;
    return <custom.MultilineTableHeader name={[`P: ${pipeline}`, `PP: ${sppipeline}`, 'Difference']} />;
  }
};

export const getFileName = (
  resources: ts.StoreState['resources'],
  context: ts.types.signal.SignalAnalysisContext,
  start_date: string,
  end_date: string,
  key: string,
  isDebug = false
) => {
  let name = resources.signals.find((s) => s.id == context.signal_id)?.handle;

  name += `-${resources.pipelines.find((p) => p.id == context.pipeline_id)?.handle || 'no-op'}`;
  name += `-${resources.pipelines.find((p) => p.id == context.sp_pipeline_id)?.handle || 'sp-no-op'}`;
  name += `-${resources.universes.find((u) => u.id == context.universe_id)?.handle || 'no-uni'}`;
  if (start_date) name += `-${moment(start_date).format('yyyyMMDD')}`;
  if (end_date) name += `-${moment(end_date).format('yyyyMMDD')}`;
  name += `-${key}`;
  if (isDebug) name += '-debug';
  return name;
};

export const prepareBucketedRegimeData = (
  data: ts.types.widgets.HeatMapData,
  discrete: boolean,
  numberOfQuantiles: number,
  buckets: ts.types.widgets.common.BucketValue,
  resources: ts.StoreState['resources']
): ts.types.widgets.HeatMapData => {
  const newData = data.map((el) => {
    const q = discrete ? (el['bucket1'] as string) : quantileLabel(`${el['bucket1']}` as string, numberOfQuantiles);
    const b = getBucketName(el['bucket2'] as string, buckets.type, buckets.value, resources);

    return {
      bucket1: q,
      bucket2: b,
      value: el.value,
    } as ts.types.widgets.HeatMapData[0];
  });

  const bucket1Values = _.uniq(newData.map((el) => el.bucket1));
  const bucket2Values = _.uniq(newData.map((el) => el.bucket2));

  // If we are missing values for the heatmap, we will need to add them
  // before we sort
  if (newData.length < bucket1Values.length * bucket2Values.length) {
    for (const bucket1Val of bucket1Values) {
      for (const bucket2Val of bucket2Values) {
        const found = newData.find((el) => el.bucket1 === bucket1Val && el.bucket2 === bucket2Val);
        if (!found) {
          newData.push({
            bucket1: bucket1Val,
            bucket2: bucket2Val,
            value: 0,
          } as ts.types.widgets.HeatMapData[0]);
        }
      }
    }
  }

  if (buckets.type == ts.enums.BUCKET_TYPES_ENUM.QUANTILES || buckets.type == ts.enums.BUCKET_TYPES_ENUM.SIGNAL) {
    let sortedData = [] as ts.types.widgets.HeatMapData;

    Object.values(_.groupBy(newData, (el) => el.bucket2)).forEach((group) => {
      sortedData = _.sortBy(group, 'bucket1').concat(sortedData);
    });
    return sortedData;
  }
  return newData;
};

export const prepareExposureData = (
  data: ts.types.widgets.LinearData,
  primaryTab: 'raw_signal' | 'pipeline' | 'sp_pipeline',
  secondaryTab: string,
  resources: ts.StoreState['resources']
) => {
  const newData = {} as ts.types.widgets.LinearData;
  Object.keys(data).forEach((key) => {
    let newKey = key.replace(`${primaryTab}|${secondaryTab}|`, '');
    if (secondaryTab.includes('signal|')) {
      const signalHandle = key.replace(`${primaryTab}|signal|`, '');
      newKey = resources.signals.find((el) => el.handle == signalHandle)?.name;
    }

    if (secondaryTab.includes('market')) newKey = _.startCase(newKey.replace(`${primaryTab}|`, ''));
    if (secondaryTab.includes('country')) newKey = getCountryName(newKey);
    if (secondaryTab.includes('currency')) newKey = getCurrencyName(newKey, resources);
    if (secondaryTab.includes('|l1')) newKey = getIndustryName(newKey, 'rbics_l1_id', resources);
    if (secondaryTab.includes('|l2')) newKey = getIndustryName(newKey, 'rbics_l2_id', resources);
    if (secondaryTab.includes('|l3')) newKey = getIndustryName(newKey, 'rbics_l3_id', resources);
    if (secondaryTab.includes('|l4')) newKey = getIndustryName(newKey, 'rbics_l4_id', resources);

    newData[newKey] = data[key];
  });

  return newData;
};

export const prepareRhDataLinear = (
  data: ts.types.widgets.LinearData,
  frequency: ts.enums.FREQUENCY_ENUM,
  timeSeries: ts.types.timeSeries.TimeSeries[] = []
) => {
  const newData = {} as any;
  const tsHandles = timeSeries.map((ts) => ts.handle);
  Object.keys(data).forEach((key) => {
    const periodReplacedKey = key.includes('Period')
      ? key.replaceAll('Period', helpers.returnHorizon._freqParseLabel(frequency))
      : key;

    if (tsHandles.includes(periodReplacedKey)) {
      const newKey = helpers.resourcesUtils.getResourceLabel(timeSeries.find((ts) => ts.handle == periodReplacedKey));
      newData[newKey] = data[key];
    } else {
      const newKey = key.includes('Period') ? periodReplacedKey : _.startCase(key);
      newData[newKey] = data[key];
    }
  });
  return newData;
};

export const prepareRhDataBarChart = (
  data: ts.types.widgets.NestedBarWidgetData,
  frequency: ts.enums.FREQUENCY_ENUM
) => {
  const newData = { ...data };

  const replaceLabel = (row: Record<string, string | number>) => {
    const newEl = {} as Record<string, string | number>;

    Object.keys(row).forEach((key) => {
      const newKey = key.includes('Period')
        ? key.replaceAll('Period', helpers.returnHorizon._freqParseLabel(frequency))
        : key != 'category'
          ? _.startCase(key)
          : key;
      newEl[newKey] = row[key];
    });

    return newEl;
  };

  newData.data = data.data.map((el) => replaceLabel(el));

  Object.keys(data?.count || {}).forEach((key) => {
    const row = data.count[key];
    const newEl = replaceLabel(row);
    newData.count[key] = newEl as Record<string, number>;
  });

  return newData;
};

export const getLabel = (tab: string, frequency?: ts.enums.FREQUENCY_ENUM) => {
  // If signature is the final name (latest refactor)
  if (tab.includes(' ')) return tab.replaceAll('Period', helpers.returnHorizon._freqParseLabel(frequency));
  return helpers.returnHorizon.getLabelFromSignature(tab);
};

export const prepareNameCountData = (data: ts.types.widgets.LinearData) => {
  const newData = {} as ts.types.widgets.LinearData;

  const labelMapping: { [key: string]: string } = {
    raw_signal_cov: 'Raw Signal',
    filtered_signal_cov: 'After Pipeline',
    sp_filter_signal_cov: 'After Portfolio Pipeline',
    universe_size: 'Universe',
  };

  Object.keys(data).forEach((key) => {
    const newKey = labelMapping[key] || key;
    newData[newKey] = data[key];
  });

  return newData;
};
