import { inferTabular, splitLine } from './infer-type';
import { SEPARATORS_ENUM, SHEET_CONVERSION_TYPE_ENUM } from '../enums';
import { _ } from '../libs';

type ArrayOfArrays = any[][];
/**
 * Converts spreadsheet data string into array of arrays format
 * @param data - Raw spreadsheet data as string
 * @param sep - Separator character, defaults to tab
 * @returns Array of arrays containing spreadsheet data
 */
const spreadsheetToArrayOfArrays = (data: string, sep = SEPARATORS_ENUM.TAB): ArrayOfArrays => {
  if (_.isEmpty(data)) return [];
  const lines = data.split('\n');
  const parsedData = [];
  for (let i = 0; i < lines.length; i += 1) {
    const currentline = splitLine(lines[i], sep);

    parsedData.push(currentline);
  }

  return parsedData;
};

type ArrayOfObjects = Record<string, any>[];
/**
 * Converts spreadsheet data string into array of objects format
 * @param data - Raw spreadsheet data as string
 * @param sep - Separator character, defaults to tab
 * @returns Array of objects containing spreadsheet data with column headers as keys
 */
const spreadsheetToArrayOfObjects = (data: string, sep = SEPARATORS_ENUM.TAB): ArrayOfObjects => {
  const lines = data.split('\n');
  const parsedData = [];
  const headers = splitLine(lines[0], sep);

  const inferred = inferTabular(data, sep, true);
  // convert inferred to map
  const convertTo = {} as Record<string, (_val: any) => any>;
  inferred.forEach((infEl) => {
    if (infEl.dtype == 'NUMBER') convertTo[infEl.column_name] = (val: string) => Number(val);
    else convertTo[infEl.column_name] = (val: string) => val;
  });

  for (let i = 1; i < lines.length; i += 1) {
    const obj: Record<string, any> = {};
    const currentline = splitLine(lines[i], sep);
    if (!_.isEmpty(_.filter(currentline))) {
      for (let j = 0; j < headers.length; j += 1) {
        obj[headers[j] || 'index'] = convertTo[headers[j] || 'index'](currentline[j]);
      }
      parsedData.push(obj);
    }
  }

  return parsedData;
};

/**
 * Converts spreadsheet data string into either array of arrays or array of objects format
 * @param data - Raw spreadsheet data as string
 * @param convType - Conversion type enum specifying output format
 * @returns Spreadsheet data in specified format (array of arrays or array of objects)
 */
const spreadsheetToJson = (data: string, convType: SHEET_CONVERSION_TYPE_ENUM): ArrayOfObjects | ArrayOfArrays => {
  const lines = data.split('\n');
  let isTsv = true;

  if (lines[0].indexOf('\t') < 0) isTsv = false;

  if (convType == SHEET_CONVERSION_TYPE_ENUM.ARRAY) {
    if (!isTsv) return spreadsheetToArrayOfArrays(data, SEPARATORS_ENUM.COMMA);
    return spreadsheetToArrayOfArrays(data);
  }
  if (!isTsv) return spreadsheetToArrayOfObjects(data, SEPARATORS_ENUM.COMMA);
  return spreadsheetToArrayOfObjects(data);
};

export { spreadsheetToJson };
