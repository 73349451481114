import { _, React, ts, useSelector } from '_core';

import { prepareData } from './helpers';
import ChartWrapper from '../../common/chart-wrapper';
import createLineChart from '../../common/charts/create-line-chart';

type ChartProps = {
  id: string;
  readOnly: boolean;
  widgetKey: string;
  loadWidgetData: (_payload: ts.types.widgets.WidgetGetDataParams) => Promise<ts.types.widgets.WidgetGetDataResponse>;
  file: string;
  fullScreen: boolean;
  title: string;
  subtitle?: string;
  setExternalEmbed: (_json: ts.types.analysis.EmbedChartData) => void;
};

const Chart: React.FC<ChartProps> = ({
  id,
  readOnly,
  widgetKey,
  loadWidgetData,
  file,
  fullScreen,
  title,
  subtitle,
  setExternalEmbed,
}) => {
  const [data, setData] = React.useState<ts.types.widgets.TableData>(null);
  const [error, setError] = React.useState<ts.types.common.ApiError>(null);
  const [showLegend, setShowLegend] = React.useState(false);
  const resources = useSelector((state) => state.resources);

  const loadData = async () => {
    try {
      const response = await loadWidgetData({
        data: {
          file,
          rename: { build_date: 'date' },
        },
      });
      if (response) setData(response.data);
    } catch (err) {
      setError(err as ts.types.common.ApiError);
    }
  };

  React.useEffect(() => {
    if (file) loadData();
    return () => setData(null);
  }, [file]);

  React.useEffect(() => {
    // Create line chart on data and scale changes
    if (!_.isNil(data))
      createLineChart({
        id,
        scale: 'linear',
        data,
        fullScreen,
        percent: false,
        setShowLegend,
        showEndValueLabel: true,
        exportTitle: title,
        exportSubTitle: subtitle,
        prepareData: (data) => prepareData(resources, data),
        setExternalEmbed,
        showPeriodSelector: true,
      });
  }, [data]);

  return (
    <ChartWrapper
      id={id}
      readOnly={readOnly}
      widgetKey={widgetKey}
      data={data}
      showLegend={showLegend}
      error={error}
      fullScreen={fullScreen}
      showPeriodSelector={true}
    />
  );
};

export default Chart;
