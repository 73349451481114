import { ts } from '_core';

import { DraftParams } from './types';

export const validate = (params: DraftParams) => {
  if (!params.weights) return ['Weights is a required field.'];
  if (params.weights == ts.enums.REPORT_ENUMS.WEIGHTS_ENUM.MARKET_NEUTRAL && params.gross_leverage === null)
    return ['Gross Leverage is a required field.'];
  return [];
};

export const getName = (params: DraftParams) => {
  const weightsTitle = {
    [ts.enums.REPORT_ENUMS.WEIGHTS_ENUM.MINIMUM_VOLATILITY]: 'Minimum Volatility',
    [ts.enums.REPORT_ENUMS.WEIGHTS_ENUM.MARKET_CAP]: 'Market Capitalization',
    [ts.enums.REPORT_ENUMS.WEIGHTS_ENUM.EQUAL]: 'Equal',
    [ts.enums.REPORT_ENUMS.WEIGHTS_ENUM.MARKET_NEUTRAL]: 'Market Neutral',
  };

  const label = 'Bias Statistic';
  const weightsLabel = weightsTitle[params.weights];
  if (weightsLabel) {
    return `${label} (${weightsLabel})`;
  }

  return label;
};
