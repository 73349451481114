import { mui, React, ts } from '_core';

import Tabular from 'views/report/tables/tabular';

type TableComponentProps = {
  data: ts.types.widgets.TableData;
  columns: ts.types.components.dataGrid.ColumnsData;
  sheets: Record<string, string>;
  customHeaderHeight: Record<string, number>;
  customWidth: Record<string, number>;
  customRowHeight: Record<string, number>;
  customToolBars: Record<string, () => React.ReactElement>;
  loading: boolean;
  currentSheet: string;
  error: ts.types.common.Alert;
  setCurrentSheet: (_sheet: string) => void;
};

const TableComponent: React.FC<TableComponentProps> = ({
  data,
  columns,
  sheets,
  customToolBars,
  loading,
  error,
  currentSheet,
  setCurrentSheet,
}): React.ReactElement => {
  const container = React.createRef();

  const tableData = React.useMemo(
    () => Object.fromEntries(Object.keys(sheets).map((sh) => [sh, data])),
    [sheets, data]
  );

  const tableColumns = React.useMemo(
    () => Object.fromEntries(Object.keys(sheets).map((sh) => [sh, () => columns])),
    [sheets, columns]
  );

  return (
    <mui.core.Box display="flex" flexDirection="column" ref={container} style={{ height: 'calc(100% - 1px)' }}>
      <Tabular
        data={tableData}
        getColumns={tableColumns}
        sheetTitles={sheets}
        fullScreen={true}
        goFullScreen={() => undefined}
        customToolbarBySheet={customToolBars}
        loading={loading}
        customError={error}
        currentSheet={currentSheet}
        setCurrentSheet={setCurrentSheet}
        triggerWidthChange
      />
    </mui.core.Box>
  );
};

export default TableComponent;
