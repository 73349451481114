import { hooks, mui, React, ts } from '_core';

import Tabular from 'views/report/tables/tabular';

import { summaryColumns } from './columns-config';
import { transform } from './helpers';
import { Params } from './types';
import { getColumns } from '../../common/charts/utils';

type ChartProps = {
  id: string;
  readOnly: boolean;
  widgetKey: string;
  loadWidgetData: (_payload: ts.types.widgets.WidgetGetDataParams) => Promise<ts.types.widgets.WidgetGetDataResponse>;
  fullScreen: boolean;
  goFullScreen: (_v: string) => void;
  summary: string;
  queryParams?: { initial?: string };
  context?: ts.types.portfolio.PortfolioAnalysisContext;
  params: Params;
};

const Chart: React.FC<ChartProps> = ({
  id,
  readOnly,
  widgetKey,
  loadWidgetData,
  summary,
  goFullScreen,
  fullScreen,
  params,
}) => {
  const [error, setError] = React.useState<ts.types.common.ApiError>(null);
  const [data, setData] = React.useState<Record<string, ts.types.widgets.TableData>>();

  const [containerHeight, setContainerHeight] = React.useState(undefined);
  const { height } = hooks.useWindowDimensions();

  const container = React.createRef<HTMLDivElement>();

  React.useEffect(() => {
    if (container.current) {
      setContainerHeight(container.current.offsetHeight - 40);
    }
  }, [height]);

  const loadData = async () => {
    try {
      const resp = await loadWidgetData({
        data: { file: summary },
      });
      if (resp) {
        const data = transform(resp.data);
        setData({ summary: data });
      }
    } catch (err) {
      setError(err as ts.types.common.ApiError);
    }
  };

  React.useEffect(() => {
    if (summary) loadData();
    return () => setData(null);
  }, [summary]);

  return (
    <mui.core.Box
      id={id}
      key={widgetKey}
      sx={{
        height: 'calc(100% - 1px)',
        '& .rdg-light': { paddingBottom: '60px' },
      }}
      ref={container}
    >
      <Tabular<'summary'>
        data={data}
        getColumns={{
          summary: (summaryCols) => {
            return getColumns(summaryCols, null, summaryColumns);
          },
        }}
        sheetTitles={{
          summary: 'Summary',
        }}
        fullScreen={fullScreen}
        goFullScreen={goFullScreen}
        hideFilters
        alwaysShowBottom
        apiError={error}
        overrideHeight={containerHeight}
        triggerWidthChange={params.fullWidth}
        readOnly={readOnly}
      />
    </mui.core.Box>
  );
};

export default Chart;
