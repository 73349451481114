import { ts } from '_core';

import { validate } from './helpers';
import { DraftParams } from './types';

export default [
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.SIGNAL_MAX_DRAWDOWN,
    title: 'Max Drawdown by Year',
    helpLabel: 'yybnn6uo',
    form: true,
    defaultParams: { aggregation: 'ANNUAL' } as DraftParams,
    neededFiles: ['signal_max_drawdown'],
    tags: [
      ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.SIGNAL,
      ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.ALPHA_MODEL,
      ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.COMMON_ALPHA_MODEL,
    ],

    validate,
  },
] satisfies ts.types.analysis.WidgetConfig[];
