/* eslint-disable no-unused-vars */

import { ColumnPreferences } from './components/data-grid';
import { DatasetDraft } from './signal';
import { PREFERENCES_KEY_ENUM } from '../enums';

export enum UserPreferenceTypes {
  COLUMNS = 'COLUMNS',
  DATASETPARAMS = 'DATASET_PARAMS',
}

export type DatasetPreferences = DatasetDraft & { name?: string };

export type UserPreference = {
  title: string;
  id: number;
  key: PREFERENCES_KEY_ENUM;
  type: UserPreferenceTypes;
  definition: ColumnPreferences | DatasetPreferences;
  is_latest: boolean;
  is_preferred?: boolean;
};

export type UserPreferenceDraft = Partial<UserPreference>;
