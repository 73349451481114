import { _, api, config, helpers, hooks, mui, React, ts } from '_core';

import { GridRowParams } from '@mui/x-data-grid-premium';

import * as datagridUtils from './utils';
import { apiProcedures } from './utils';
import { TabularContext } from '../../tabular-context';
import { convertMuiXFilters } from '../../utils/muix-converter';

export type ProviderProps = {
  loadData: ts.types.components.dataGrid.LoadData;
  customHeight?: number;
  downloadFullData?: ts.types.components.dataGrid.DownloadFullData;
  customToolbar?: React.FC<{ columns: string[] }>;
  getColumns?: ts.types.components.dataGrid.GenerateColumns;
  headerHeight?: number;
  hideFilters?: boolean;
  hideDownload?: boolean;
  hideToolbar?: boolean;
  hideDensity?: boolean;
  hideColumnWidth?: boolean;
  overrideHeight?: number;
  loadOnX?: boolean;
  loadColumnsOnChange?: boolean;
  triggerLoadOnChange?: boolean;
  rowClass?: (_row: ts.types.components.dataGrid.Row) => string;
  onRowClick?: (_p: GridRowParams<any>) => void;
  prepareData?: (_data: ts.types.components.dataGrid.Row[]) => ts.types.components.dataGrid.Row[];
  onDataChange?: (_data: ts.types.components.dataGrid.Row[]) => void;
  rowHeight?: number;
  rowWidth?: number;
  hideDataCount?: boolean;
  sheetFilters?: ts.types.components.dataGrid.ValueFilters;
  initialTableDtypes?: ts.types.components.dataGrid.TableDtypes;
  updateTableDtypes?: (_v: ts.types.components.dataGrid.TableDtypes) => void;
  customStyles?: mui.core.SxProps<mui.core.Theme>;
  disableDefaultStyling?: boolean;
};

type AsyncDataGridContextTypes = {
  alert: ts.types.common.Alert;
  formattedRows: ts.types.components.dataGrid.Row[];
  firstPrefLoad: boolean;
  preferenceOptions: ts.types.userPreference.UserPreferenceDraft[];
  selectedPreference: ts.types.userPreference.UserPreferenceDraft;
  setCurrentView: (_v: number) => void;
  openPreferences: () => void;
  filters: ts.types.components.dataGrid.GridFilterModel;
  setFilters: (_v: ts.types.components.dataGrid.GridFilterModel) => void;
  handleDownload: () => void;
  downloading: boolean;
  mappedColumns: any[];
  pinnedColumns: { left: string[] };
  handleColumnsResize: (_key: string, _width: number) => void;
  handleCopyToClipboard: () => Promise<void>;
  loading: boolean;
  loadingPreferences: boolean;
  sortColumns: mui.dataGrid.GridSortModel;
  setSortColumns: (_v: mui.dataGrid.GridSortModel) => void;
  handleScrollX: (_v: React.UIEvent<HTMLDivElement>) => void;
  handleScrollY: (_v: number) => void;
  customHeight: number;
  customToolbar: React.FC<{ columns: string[] }>;
  headerHeight: number;
  hideFilters: boolean;
  hideDownload: boolean;
  hideToolbar: boolean;
  hideDensity: boolean;
  hideColumnWidth: boolean;
  overrideHeight: number;
  loadOnX: boolean;
  rowClass: (_row: ts.types.components.dataGrid.Row) => string;
  onRowClick: (_p: GridRowParams<any>) => void;
  rowHeight: number;
  preferenceKey: ts.enums.PREFERENCES_KEY_ENUM;
  responseColumns: React.MutableRefObject<string[]>;
  dataCount: number;
  hideDataCount: boolean;
  customStyles: mui.core.SxProps<mui.core.Theme>;
  disableDefaultStyling: boolean;
  columnsWidth: ts.types.components.dataGrid.ColumnsWidth;
  setColumnsWidth: (_v: ts.types.components.dataGrid.ColumnsWidth) => void;
  fixedWidth?: ts.types.components.dataGrid.TableParams['fixed_width'];
  setFixedWidth: (_v: ts.types.components.dataGrid.TableParams['fixed_width']) => void;
  tableWidth?: number;
  setTableWidth?: (_v: number) => void;
};

const AsyncDataGridContext = React.createContext<AsyncDataGridContextTypes>(null);

// This context provider is passed to any component requiring the context
const Provider: React.FC<ProviderProps & { children: React.ReactElement }> = ({
  children,
  customHeight,
  customToolbar,
  downloadFullData,
  getColumns,
  headerHeight,
  hideDownload,
  hideFilters,
  hideToolbar,
  hideDensity,
  hideColumnWidth,
  loadColumnsOnChange,
  loadData,
  loadOnX,
  onDataChange,
  overrideHeight,
  prepareData,
  rowClass,
  onRowClick,
  rowHeight,
  rowWidth,
  sheetFilters,
  initialTableDtypes,
  updateTableDtypes,
  triggerLoadOnChange,
  hideDataCount,
  customStyles,
  disableDefaultStyling,
}): React.ReactElement => {
  const tabularContext = React.useContext(TabularContext);
  const {
    sortColumns,
    filters,
    userColumns,
    columnsWidth,
    fixedWidth,
    preferenceKey,
    firstPrefLoad,
    setColumnsWidth,
    setSortColumns,
    setFilters,
    setCurrentView,
    openPreferences,
    setFixedWidth,
    handleColumnsResize,
    loadingPreferences,
    preferenceOptions,
    selectedPreference,
  } = tabularContext;

  const responseColumns = React.useRef<string[]>([]);

  const [alert, setAlert] = React.useState<ts.types.common.Alert>();
  const [tableWidth, setTableWidth] = React.useState<number>(null);

  // Data States
  const [rows, setRows] = React.useState<ts.types.components.dataGrid.Row[]>();
  const [formattedRows, setformattedRows] = React.useState<ts.types.components.dataGrid.Row[]>();
  const [dataColumns, setDataColumns] = React.useState<ts.types.components.dataGrid.ColumnsData>([]);
  const [dataCount, setDataCount] = React.useState(0);

  const [tableDtypes, setTableDtypes] = React.useState(initialTableDtypes);

  const [loading, setLoading] = React.useState(false);
  const [downloading, setDownloading] = React.useState(false);

  hooks.useEffectWithoutFirst(() => {
    setformattedRows(rows.map((row, idx) => ({ ...row, id: idx })));
  }, [rows]);

  hooks.useEffectWithoutFirst(() => {
    if (updateTableDtypes) updateTableDtypes(tableDtypes);
  }, [tableDtypes]);

  const localLoadData = async (
    offset: number,
    localSortColumns = sortColumns,
    localFilters = filters,
    localSheetFilters = (sheetFilters || []) as ts.types.components.dataGrid.ValueFilters,
    localUserColumns = userColumns as ts.types.components.dataGrid.ColumnPreferences,
    localTriggerLoadOnChange = triggerLoadOnChange
  ) => {
    let valueFilters = convertMuiXFilters(localFilters);
    const logicOperator = localFilters.logicOperator;
    const orderBy = _.isEmpty(localSortColumns)
      ? undefined
      : ({
          column: localSortColumns[0].field,
          sort: localSortColumns[0].sort,
        } as ts.types.components.dataGrid.OrderBy);

    if (localSheetFilters) valueFilters = [...valueFilters, ...localSheetFilters];

    try {
      const response = await loadData(offset, orderBy, valueFilters, logicOperator, localTriggerLoadOnChange);
      if (_.isEmpty(response.data))
        setAlert({
          severity: ts.enums.ALERT_SEVERITY_ENUM.WARNING,
          message: 'No data found',
        });
      else setAlert(null);

      let responseData = response?.data;
      let localResponseColumns = response?.columns;

      if (prepareData) {
        responseData = prepareData(responseData);
        localResponseColumns = Object.keys(responseData[0] || {});
      }

      responseColumns.current = localResponseColumns;
      const tableColumns = getColumns(localResponseColumns, localUserColumns).filter(
        (el) => !el.condition || el.condition(config.features)
      );

      if (!_.isEmpty(responseData) && _.isEmpty(filters.items)) {
        const colsDTypes = datagridUtils.helpers.getColumnsType(responseData, tableColumns);
        setTableDtypes(colsDTypes);
      }

      return {
        ...response,
        pagination: {
          ...response.pagination,
          count: response.pagination.count ?? response.pagination.total ?? responseData.length,
        },
        data: responseData,
        columns: tableColumns,
      };
    } catch (err) {
      console.log(err);
      setAlert({
        severity: ts.enums.ALERT_SEVERITY_ENUM.ERROR,
        message: helpers.parseApiError(err as { responseJSON: any }),
      });
    }
    return { data: [], columns: [], pagination: { count: 0 } };
  };

  // Handle scrolling

  const isAtEdge = ({ currentTarget }: React.UIEvent<HTMLDivElement>): boolean => {
    return currentTarget?.scrollLeft >= currentTarget?.scrollWidth - currentTarget?.clientWidth;
  };

  const handleScrollY = async (newRowLength: number) => {
    if (loading) return;
    if (dataCount === newRowLength) return;
    setLoading(true);
    const response = await localLoadData(newRowLength);
    const { data, pagination } = response;
    setRows([...rows, ...data]);
    setDataCount(pagination.count);

    setLoading(false);
  };

  // Consider that the loading data will work differently
  const handleScrollX = async (event: React.UIEvent<HTMLDivElement>) => {
    if (loading || !isAtEdge(event)) return;
    if (dataCount === dataColumns.length) return;
    setLoading(true);
    const response = await localLoadData(dataColumns.length);
    const { data, pagination, columns } = response;

    setRows(rows.map((r, idx) => ({ ...r, ...data[idx] })));
    setDataColumns([...dataColumns, ...columns]);

    setDataCount(pagination.count);
    setLoading(false);
  };

  // Initial Loading, sorting and filtering

  const debouncedLoadInitialRows = React.useCallback(
    _.debounce(
      async (
        localDataColumns: typeof dataColumns,
        localSortColumns: typeof sortColumns,
        localFilters: typeof filters,
        localSheetFilters: typeof sheetFilters,
        localUserColumns: typeof userColumns,
        localTriggerLoadOnChange: typeof triggerLoadOnChange
      ) => {
        const response = await localLoadData(
          0,
          localSortColumns,
          localFilters,
          localSheetFilters,
          localUserColumns as ts.types.components.dataGrid.ColumnPreferences,
          localTriggerLoadOnChange
        );
        const { data, columns, pagination } = response;
        setDataColumns(columns);

        setRows(data);
        setDataCount(pagination.count);

        setLoading(false);
      },
      750
    ),
    []
  );

  const loadInitialRows = (
    localDataColumns: typeof dataColumns,
    localSortColumns: typeof sortColumns,
    localFilters: typeof filters,
    localSheetFilters: typeof sheetFilters,
    localUserColumns: typeof userColumns
  ) => {
    setLoading(true);
    debouncedLoadInitialRows(
      localDataColumns,
      localSortColumns,
      localFilters,
      localSheetFilters,
      localUserColumns,
      triggerLoadOnChange
    );
  };

  React.useEffect(() => {
    if (firstPrefLoad) return;
    loadInitialRows(dataColumns, sortColumns, filters, sheetFilters, userColumns);
  }, [filters, sheetFilters, userColumns, firstPrefLoad]);

  const sortRowsOnState = () => {
    setRows(
      datagridUtils.helpers.sortData(
        rows || [],
        _.isEmpty(sortColumns) ? [{ field: 'index', sort: 'asc' }] : sortColumns
      )
    );
  };

  React.useEffect(() => {
    if (loadOnX) sortRowsOnState();
  }, []);

  hooks.useEffectWithoutFirst(() => {
    loadInitialRows(dataColumns, sortColumns, filters, sheetFilters, userColumns);
  }, [triggerLoadOnChange]);

  hooks.useEffectWithoutFirst(() => {
    // if sort columns change and we are loading X, we will perform the sorting on state
    if (loadOnX) sortRowsOnState();
    // if not we will load using api
    else loadInitialRows(dataColumns, sortColumns, filters, sheetFilters, userColumns);
  }, [sortColumns]);

  // Columns and filters
  const mappedColumns = React.useMemo(
    () =>
      helpers.datagrid.buildMappedColumns({
        dataColumns,
        data: rows,
        baseRowWidth: rowWidth,
        indexClass: 'data-grid-index-header',
        columnsWidth,
        tableDtypes,
        fixedWidth,
        tableWidth,
        isAsync: true,
      }),
    [dataColumns, rows, fixedWidth, tableWidth]
  );

  const pinnedColumns = React.useMemo(
    () => ({
      left: mappedColumns.filter((col) => col.frozen).map((col) => col.field),
    }),
    [mappedColumns]
  );

  const getDownloadLink = async () => {
    try {
      let valueFilters = convertMuiXFilters(filters);
      // Get renames and call the chart download method
      const rename = {} as Record<string, string>;

      for (const col of dataColumns) {
        rename[col.key] = (col.cleanName || col.name) as string;
      }

      const columnOrder = dataColumns.map((col) => (col.cleanName || col.name) as string);

      if (sheetFilters) valueFilters = [...valueFilters, ...sheetFilters];

      const response = await downloadFullData(rename, columnOrder, valueFilters);

      return await apiProcedures.signDownloadS3Path(response);
    } catch {
      setAlert({
        severity: ts.enums.ALERT_SEVERITY_ENUM.ERROR,
        message: 'Unable to get the data to download',
      });
    }
  };

  const handleDownload = async () => {
    setDownloading(true);

    const url = await getDownloadLink();
    const link = document.createElement('a');
    document.body.appendChild(link);
    link.href = url;
    link.click();

    setDownloading(false);
  };

  const handleCopyToClipboard = async () => {
    setDownloading(true);
    const url = await getDownloadLink();

    const data = (
      (await api.s3.getS3Data({
        data: url,
      })) as any
    )['data'];

    const csvData = helpers.csv.toCsv(
      data,
      Object.keys(data?.[0] ?? []).map((c) => ({ key: c })),
      ts.enums.SEPARATORS_ENUM.TAB,
      false,
      false
    );

    await navigator.clipboard.writeText(csvData.join(''));
    setDownloading(false);
  };

  // When setting the fixed width we need to update all of the stored columns widths
  hooks.useEffectWithoutFirst(() => {
    if (fixedWidth?.width) {
      const affectedCols = fixedWidth.ignore_index ? dataColumns.filter((c) => !c.frozen) : dataColumns;

      setColumnsWidth(affectedCols.map((col) => ({ columnKey: col.key, columnWidth: fixedWidth?.width })));
    }
  }, [fixedWidth]);

  // When getColumns change
  React.useEffect(() => {
    if (firstPrefLoad) return;
    // We don't update the columns order when loading on X
    if (!_.isEmpty(responseColumns.current) && !loadOnX && loadColumnsOnChange)
      setDataColumns(
        getColumns(responseColumns.current, userColumns as ts.types.components.dataGrid.ColumnPreferences).filter(
          (el) => !el.condition || el.condition(config.features)
        )
      );
  }, [getColumns, userColumns, firstPrefLoad]);

  hooks.useEffectWithoutFirst(() => {
    if (onDataChange) onDataChange(rows);
  }, [rows]);

  return (
    <AsyncDataGridContext.Provider
      value={{
        alert,
        customHeight,
        customStyles,
        customToolbar,
        dataCount,
        disableDefaultStyling,
        downloading,
        filters,
        firstPrefLoad,
        formattedRows,
        handleColumnsResize,
        handleCopyToClipboard,
        handleDownload,
        handleScrollX,
        handleScrollY,
        headerHeight,
        hideDataCount,
        hideDensity,
        hideDownload,
        hideFilters,
        hideColumnWidth,
        hideToolbar,
        loading,
        loadingPreferences,
        loadOnX,
        mappedColumns,
        onRowClick,
        openPreferences,
        overrideHeight,
        pinnedColumns,
        preferenceKey,
        preferenceOptions,
        responseColumns,
        rowClass,
        rowHeight,
        selectedPreference,
        setCurrentView,
        setFilters,
        setSortColumns,
        sortColumns,
        columnsWidth,
        setColumnsWidth,
        fixedWidth,
        setFixedWidth,
        tableWidth,
        setTableWidth,
      }}
    >
      {children}
    </AsyncDataGridContext.Provider>
  );
};

const AsyncDataGridContextProvider = Provider;

Provider.defaultProps = {
  customHeight: null,
  downloadFullData: null,
  customToolbar: null,
  getColumns: (colKeys: string[]) => colKeys.map((key) => ({ key, name: key })),
  headerHeight: 45,
  hideFilters: false,
  hideDownload: false,
  hideToolbar: false,
  hideDensity: false,
  hideColumnWidth: false,
  overrideHeight: null,
  rowClass: null,
  prepareData: null,
  onDataChange: null,
  loadOnX: false,
  loadColumnsOnChange: true,
  rowHeight: 24,
  rowWidth: 150,
  hideDataCount: false,
  customStyles: undefined,
  disableDefaultStyling: false,
};

export { AsyncDataGridContext };
export { AsyncDataGridContextProvider };
