import React from 'react';

import { mui } from '../libs';

/**
 * Component that renders a 404 error page
 * 
 * @returns Card component displaying 404 error message with styling
 */

const NotFound = (): React.ReactElement => {
  const theme = mui.styles.useTheme() as mui.core.Theme;

  return (
    <mui.core.Box style={{ width: '100%' }}>
      <mui.core.Card
        sx={{
          textAlign: 'center',
          padding: '3rem 6rem',
          m: 1,
        }}
      >
        <mui.core.Typography variant="h3" component="h3" fontWeight="fontWeightMedium" color="textSecondary">
          404!
        </mui.core.Typography>
        <mui.core.Typography style={{ color: (theme.palette as any).text.secondary }}>
          The page you requested could not be found...
        </mui.core.Typography>
      </mui.core.Card>
    </mui.core.Box>
  );
};

export default NotFound;
