import { api, ts } from '_core';

export const loadSignalDatasets = async (id: number) => {
  const resp = await api.signal_datasets.search({ query: ['$and', ['signal_id', '=', id]] });

  return resp.data;
};

export const createSignalDataset = async (newData: ts.types.signal.DatasetDraft) => {
  const newDataset = { ...newData, order_index: 0, is_preferred: false, bookmarked: false };
  const resp = await api.signal_datasets.create({ ...newDataset });

  return resp.data;
};

export const bulkUpdateDatasets = async (datasets: ts.types.signal.Dataset[]) => {
  await api.combined.run({
    signal_datasets: { update: [...datasets] },
  });
};
