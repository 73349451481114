import { React, ts } from '_core';

import Chart from './chart';
import Form from './form';
import { DraftParams, Params } from './types';

type UniversePreviewSnapshotProps = {
  id: string;
  mode: ts.enums.CHART_MODE_ENUM;
  params: Params | DraftParams;
  setParams: (_p: DraftParams) => void;

  loadWidgetData: (_payload: ts.types.widgets.WidgetGetDataParams) => Promise<ts.types.widgets.WidgetGetDataResponse>;
  widgetPaths?: Record<'universe_snapshot', string>;
  goFullScreen?: (_b: string) => void;
  fullScreen?: boolean;
  context?: ts.types.universe.UniversePreviewContext;
  queryParams?: { initial?: string };
};

const UniversePreviewSnapshot: React.FC<UniversePreviewSnapshotProps> = ({
  id,
  loadWidgetData,
  widgetPaths,
  mode,
  context,
  params,
  setParams,
  fullScreen,
  goFullScreen,
  queryParams,
}) => {
  const renderJobComponent = () => (
    <>
      <Chart
        id={id}
        loadWidgetData={loadWidgetData}
        snapshot={widgetPaths['universe_snapshot']}
        fullScreen={fullScreen}
        context={context}
        queryParams={queryParams}
        goFullScreen={goFullScreen}
        params={params as Params}
        setParams={setParams}
      />
    </>
  );

  return (
    <>
      {mode == 'edit' ? (
        <Form
          params={params}
          setParams={setParams}
          frequency={context?.frequency as ts.enums.FREQUENCY_ENUM}
          universeStartDate={context?.start_date}
          universeEndDate={context?.end_date}
        />
      ) : (
        renderJobComponent()
      )}
    </>
  );
};

export default UniversePreviewSnapshot;
