import { _, helpers, hooks, Link, moment, mui, React, ts, ui, useHistory, useSelector } from '_core';

import { datasetExists } from 'views/signals/signal-utils/dataset-procedures';

import { bulkUpdateDatasets, createSignalDataset, loadSignalDatasets } from './helpers';
import { Params } from './types';
import { getBrowserWidgets } from './utils';

const TableCell = mui.styles.styled(mui.core.TableCell)({ padding: '12px' });

type BrowserListProps = {
  basePath: string;
  signalsMetadata: Partial<ts.types.signalSet.SignalMetadata>[];
  params: Params;
  setParams: (_p: Params) => void;
  startDate: string;
  endDate: string;
  jobCreatedAt: string;
};

type ItemType = {
  signal: string;
  universe: string;
  frequency: ts.enums.FREQUENCY_ENUM;
  risk_model: string;
  pipeline: string;
  sp_pipeline: string;
  id: string;

  signal_id: number;
  pipeline_id: number;
  sp_pipeline_id: number;
  risk_model_id: number;
  universe_id: number;
};

const BrowserList: React.FC<BrowserListProps> = ({
  basePath,
  signalsMetadata,
  params,
  setParams,
  startDate,
  endDate,
  jobCreatedAt,
}) => {
  const history = useHistory();
  const resources = useSelector((state) => state.resources);
  const theme = mui.styles.useTheme() as mui.core.Theme;

  const reporDefault = resources.report_defaults.find((r) => r.id == params.report_default_id);

  React.useEffect(() => {
    // If the report default has been updated after the job was created, we need to update the params
    // which will invalidate the widget and require a regeneration
    if (reporDefault && jobCreatedAt && moment(reporDefault.updated_at).isAfter(moment(jobCreatedAt))) {
      setParams({
        ...params,
        report_default_id: reporDefault?.id,
        set_params: reporDefault?.id ? getBrowserWidgets(reporDefault.definition) : [],
      });
    }
  }, [reporDefault]);

  const [showBookmarked, setShowBookmarked] = React.useState(false);
  const [bookmarkedIds, setBookmarkedIds] = React.useState<string[]>(params.bookmarked || []);

  const [sortBy, setSortBy] = React.useState<keyof ItemType>('signal');
  const [sortOrder, setSortOrder] = React.useState<'asc' | 'desc'>('asc');

  const [errorMessage, setErrorMessage] = React.useState('');
  const [loadingDatasets, setLoadingDatasets] = React.useState(true);
  const [exportingDatasetAtIndex, setExportingDatasetAtIndex] = React.useState<number[]>([]);
  const [signalsDatasets, setSignalsDatasets] = React.useState<Record<number, ts.types.signal.Dataset[]>>({});

  const sortData = (localSortBy: keyof ItemType, localSortOrder: 'asc' | 'desc', data: ItemType[]) => {
    const localData = [...data];

    const sortedItems = _.orderBy(localData, (localData) => localData[localSortBy], [localSortOrder]);
    return sortedItems;
  };

  const getSignalData = () => {
    const signalData: ItemType[] = (signalsMetadata || []).map((el) => {
      const signal = resources.signals.find((s) => s.id == el.signal_id);
      const universe = resources.universes.find((u) => u.id == el.universe_id);
      const riskModel = resources.risk_models.find((r) => r.id == el.risk_model_id);

      const getPipeline = (elId: number) => {
        try {
          const pipeline = resources.pipelines.find((p) => p.id == elId);
          return pipeline.name;
        } catch {
          return 'Not set';
        }
      };

      return {
        signal: signal.name,
        universe: universe.name,
        frequency: el.frequency as ts.enums.FREQUENCY_ENUM,
        risk_model: riskModel?.name ?? 'Not set',
        pipeline: getPipeline(el.pipeline_id),
        sp_pipeline: getPipeline(el.sp_pipeline_id),
        id: el.id,
        signal_id: signal.id,
        pipeline_id: el.pipeline_id,
        sp_pipeline_id: el.sp_pipeline_id,
        risk_model_id: riskModel?.id,
        universe_id: universe.id,
      };
    });

    return sortData(sortBy, sortOrder, signalData);
  };

  const [signalsData, setSignalsData] = React.useState(getSignalData());

  hooks.useEffectWithoutFirst(() => {
    const signalData = getSignalData();
    setSignalsData(signalData);
  }, [signalsMetadata]);

  const getSignalDatasets = async () => {
    setLoadingDatasets(true);
    const loadedSignalDatasets = {} as Record<number, ts.types.signal.Dataset[]>;

    try {
      for (const signalMetadata of _.uniqBy(signalsMetadata, 'signal_id')) {
        const signalDatasets = await loadSignalDatasets(signalMetadata.signal_id);

        loadedSignalDatasets[signalMetadata.signal_id] = signalDatasets;
      }
      setSignalsDatasets(loadedSignalDatasets);
    } catch (error) {
      setErrorMessage(`Error loading signal datasets - ${helpers.parseApiError(error as ts.types.common.ApiError)}`);
    }

    setLoadingDatasets(false);
  };

  React.useEffect(() => {
    getSignalDatasets();
  }, [signalsMetadata]);

  const triggerSort = (triggeredSortBy: keyof ItemType) => {
    let localSortBy = sortBy;
    let localSortOrder = sortOrder;

    if (triggeredSortBy === sortBy) {
      localSortOrder = sortOrder === 'asc' ? 'desc' : 'asc';
    } else {
      localSortBy = triggeredSortBy;
      localSortOrder = 'asc';
    }

    const sortedItems = sortData(localSortBy, localSortOrder, signalsData);
    setSignalsData(sortedItems);
    setSortOrder(localSortOrder);
    setSortBy(localSortBy);
  };

  React.useEffect(() => {
    if (!showBookmarked) setSignalsData(getSignalData());
    else {
      const items = [] as ItemType[];
      // mantain signalsData order
      signalsData.forEach((booked) => {
        if (bookmarkedIds.includes(booked.id)) items.push(booked);
      });
      setSignalsData(items);
    }
  }, [showBookmarked, bookmarkedIds]);

  const handleBookmark = (id: string) => {
    if (bookmarkedIds.includes(id)) setBookmarkedIds(bookmarkedIds.filter((bid) => bid !== id));
    else setBookmarkedIds([...bookmarkedIds, id]);
  };

  // We will debounce the save on the params
  const debouncedSave = React.useCallback(
    _.debounce(
      // IMPORTANT - Check signal-set-browser.tsx, we are adding fixed parameters there. We need to spread them here
      (bookmarkedIdsLocal) => setParams({ ...params, bookmarked: bookmarkedIdsLocal }),
      2000
    ),
    []
  );
  hooks.useEffectWithoutFirst(() => debouncedSave(bookmarkedIds), [bookmarkedIds]);

  const exportDataset = async (rowEl: ItemType, currentDatasets: ts.types.signal.Dataset[], rowIndex: number) => {
    setExportingDatasetAtIndex((el) => [...el, rowIndex]);

    try {
      const formattedNewSignalDataset = _.pick(rowEl, [
        'signal_id',
        'pipeline_id',
        'sp_pipeline_id',
        'risk_model_id',
        'universe_id',
        'frequency',
      ]);

      const newSignalDataset = await createSignalDataset({
        ...formattedNewSignalDataset,
        start_date: startDate,
        end_date: endDate,
      });

      const newDatasets = _.incBy<ts.types.signal.Dataset>(currentDatasets, 'order_index');

      setSignalsDatasets({
        ...signalsDatasets,
        [rowEl.signal_id]: [...signalsDatasets[rowEl.signal_id], newSignalDataset],
      });

      await bulkUpdateDatasets(newDatasets);
    } catch (error) {
      setErrorMessage(
        `${rowIndex + 1} Error creating a new dataset - ${helpers.parseApiError(error as ts.types.common.ApiError)}`
      );
    }

    setExportingDatasetAtIndex((el) => _.remove(el, rowIndex));
  };

  const renderExportButton = (rowEl: ItemType, rowIndex: number) => {
    const currSignalDatasets = _.get(signalsDatasets, rowEl.signal_id, []);

    const ds = datasetExists(rowEl as any, currSignalDatasets);

    return (
      <ui.Button
        size="small"
        color={ds ? 'success' : 'primary'}
        loading={exportingDatasetAtIndex.includes(rowIndex)}
        progressText="Exporting"
        onClick={() =>
          !ds
            ? exportDataset(rowEl, currSignalDatasets, rowIndex)
            : history.push(`/signals/${rowEl.signal_id}/dataset/${ds.id}/definition`)
        }
      >
        {ds ? (
          <>
            Exported
            <mui.icons.CheckCircleOutline sx={{ marginLeft: '5px' }} />
          </>
        ) : (
          'Export'
        )}
      </ui.Button>
    );
  };

  return (
    <mui.core.Box p={1}>
      <mui.core.Card>
        <mui.core.Box p={2} display="flex" gap={2}>
          <mui.core.Button
            size="small"
            variant="outlined"
            onClick={() => setShowBookmarked(!showBookmarked)}
            startIcon={showBookmarked ? undefined : <mui.icons.Bookmark style={{ fontSize: 14 }} />}
          >
            {showBookmarked ? 'Show all' : 'Show only bookmarks'}
          </mui.core.Button>
          {errorMessage && (
            <mui.lab.Alert onClose={() => setErrorMessage('')} severity="error">
              {errorMessage}
            </mui.lab.Alert>
          )}
        </mui.core.Box>

        <mui.core.TableContainer>
          <mui.core.Table aria-label="Signal Set Generate Table" size="small">
            <mui.core.TableHead>
              <mui.core.TableRow>
                <TableCell>
                  <mui.core.TableSortLabel
                    active={sortBy === 'signal'}
                    direction={sortOrder}
                    onClick={() => triggerSort('signal')}
                  >
                    Signal
                  </mui.core.TableSortLabel>
                </TableCell>
                <TableCell>
                  <mui.core.TableSortLabel
                    active={sortBy === 'universe'}
                    direction={sortOrder}
                    onClick={() => triggerSort('universe')}
                  >
                    Universe
                  </mui.core.TableSortLabel>
                </TableCell>
                <TableCell>
                  <mui.core.TableSortLabel
                    active={sortBy === 'frequency'}
                    direction={sortOrder}
                    onClick={() => triggerSort('frequency')}
                  >
                    Frequency
                  </mui.core.TableSortLabel>
                </TableCell>
                <TableCell>
                  <mui.core.TableSortLabel
                    active={sortBy === 'risk_model'}
                    direction={sortOrder}
                    onClick={() => triggerSort('risk_model')}
                  >
                    Risk Model
                  </mui.core.TableSortLabel>
                </TableCell>
                <TableCell>
                  <mui.core.TableSortLabel
                    active={sortBy === 'pipeline'}
                    direction={sortOrder}
                    onClick={() => triggerSort('pipeline')}
                  >
                    Pipeline
                  </mui.core.TableSortLabel>
                </TableCell>
                <TableCell>
                  <mui.core.TableSortLabel
                    active={sortBy === 'sp_pipeline'}
                    direction={sortOrder}
                    onClick={() => triggerSort('sp_pipeline')}
                  >
                    Portfolio Pipeline
                  </mui.core.TableSortLabel>
                </TableCell>
                <TableCell sx={{ fontSize: '0.7rem' }}>
                  Report {loadingDatasets ? <mui.core.CircularProgress size={12} /> : <></>}
                </TableCell>
              </mui.core.TableRow>
            </mui.core.TableHead>
            {(signalsData || []).map((el, index) => {
              return (
                <mui.core.TableRow key={index}>
                  <TableCell>{el.signal}</TableCell>
                  <TableCell>{el.universe}</TableCell>
                  <TableCell>{el.frequency}</TableCell>
                  <TableCell>{el.risk_model}</TableCell>
                  <TableCell>{el.pipeline}</TableCell>
                  <TableCell>{el.sp_pipeline}</TableCell>
                  <TableCell>
                    <mui.core.Grid container spacing={1}>
                      <mui.core.Grid item>
                        <mui.core.Button
                          size="small"
                          color="primary"
                          component={Link}
                          to={`${basePath}/single/${el.id}`}
                        >
                          View
                        </mui.core.Button>
                      </mui.core.Grid>

                      <mui.core.Grid item>{!loadingDatasets && renderExportButton(el, index)}</mui.core.Grid>
                      <mui.core.Grid item>
                        <mui.core.IconButton onClick={() => handleBookmark(el.id)}>
                          {bookmarkedIds.includes(el.id) ? (
                            <mui.icons.Bookmark
                              style={{
                                fontSize: 17,
                                color: theme.palette.primary.main,
                              }}
                            />
                          ) : (
                            <mui.icons.BookmarkBorder
                              style={{
                                fontSize: 17,
                                color: theme.palette.text.secondary,
                              }}
                            />
                          )}
                        </mui.core.IconButton>
                      </mui.core.Grid>
                    </mui.core.Grid>
                  </TableCell>
                </mui.core.TableRow>
              );
            })}
          </mui.core.Table>
        </mui.core.TableContainer>
      </mui.core.Card>
    </mui.core.Box>
  );
};

export default BrowserList;
