import { ts } from '_core';

import { validate } from './helpers';
import { DraftParams, INTERACTION_PARAMS } from './types';

export default [
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.BASKET_RISK_REPORT,
    title: 'Portfolio Risk Report',
    helpLabel: '5yxfhcpd',
    form: true,
    defaultParams: {
      isBasket: true,
    } as DraftParams,
    interactionParams: Object.values(INTERACTION_PARAMS),
    clearInteractionOnRegenerate: ['table_dtypes'],
    neededFiles: [
      'backtest_risk_report_summary',
      'backtest_factors_contribution_to_total_risk',
      'backtest_security_contribution_to_total_risk',
      'backtest_factor_exposure_risk_report',
    ],
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.BASKET_PORTFOLIO_RISK],

    validate,
  },
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.BACKTEST_RISK_REPORT,
    title: 'Portfolio Risk Report',
    helpLabel: '08vmxrzp',
    form: true,
    defaultParams: {
      isBasket: true,
    } as DraftParams,
    interactionParams: Object.values(INTERACTION_PARAMS),
    clearInteractionOnRegenerate: ['table_dtypes'],
    neededFiles: [
      'backtest_risk_report_summary',
      'backtest_factors_contribution_to_total_risk',
      'backtest_factor_exposure_risk_report',
      'backtest_security_contribution_to_total_risk',
    ],
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.BACKTEST_PORTFOLIO_RISK],

    validate,
  },
] satisfies ts.types.analysis.WidgetConfig[];
