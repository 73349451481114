import React from 'react';

import UiButton from './button';
import * as hooks from '../hooks';
import { mui } from '../libs';

type SaveBarProps = {
  saveAs?: () => void;
  customAction?: JSX.Element;
  noBackground?: boolean;
};

/**
 * Component that provides a save bar with save as button and custom action support
 *
 * @param saveAs - Function for save as action
 * @param customAction - Custom action element
 * @param noBackground - Flag to disable background
 * @returns Rendered save bar component
 */

const SaveBar: React.FC<SaveBarProps> = ({ saveAs, customAction, noBackground }): React.ReactElement => {
  const uiStyles = hooks.useUiStyles();
  const theme = mui.styles.useTheme() as mui.core.Theme;

  return (
    <mui.core.Box
      sx={uiStyles.definitionSaveBar}
      display="flex"
      style={{
        background: noBackground ? '#fff' : (theme.palette as any).background.default,
      }}
    >
      {saveAs && (
        <mui.core.Box mr={2}>
          <UiButton onClick={saveAs} variant="outlined" color="primary">
            Save as
          </UiButton>
        </mui.core.Box>
      )}
      {customAction && (
        <mui.core.Box mr={2} flexGrow={1}>
          {' '}
          {customAction}{' '}
        </mui.core.Box>
      )}
    </mui.core.Box>
  );
};

SaveBar.defaultProps = {
  customAction: null,
  noBackground: false,
};

export default SaveBar;
