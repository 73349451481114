import { _, ts } from '_core';

import { DraftParams } from './types';

export const validate = (params: DraftParams) => {
  if (!params.analysis_universe_id) return ['Universe is a required field.'];
  return [];
};

export const prepareData = (
  data: ts.types.widgets.TableData,
  universe: ts.types.universe.Universe,
  riskModel: ts.types.riskModel.RiskModel
) =>
  data.map((row) => {
    const newRow = {} as typeof row;
    Object.keys(row).forEach((key) => {
      if (key == universe.handle) newRow[universe.name] = row[key];
      else if (key == riskModel.handle) newRow[riskModel.name] = row[key];
      else if (key == 'date') newRow[key] = row[key];
      else newRow[_.startCase(key)] = row[key];
    });
    return newRow;
  });
