import { api, config, helpers, ts } from '_core';

type GeneralAnalysisApiResponse = {
  data: ts.types.analysis.GeneralAnalysis;
};

export const createGeneralAnalysis = async (
  newData: ts.types.analysis.GeneralAnalysis,
  callback: (_data: ts.types.analysis.GeneralAnalysis) => void,
  errorCallback: (_error: string) => void
) => {
  try {
    const resp: GeneralAnalysisApiResponse = await api.general_analyses.create(newData);
    if (resp) callback(resp.data);
  } catch (error) {
    errorCallback(`Error creating report - ${helpers.parseApiError(error as ts.types.common.ApiError)}`);
  }
};

export const updateGeneralAnalysis = async (
  id: number,
  newData: ts.types.analysis.GeneralAnalysisDraft,
  callback: (_data: ts.types.analysis.GeneralAnalysis) => void,
  errorCallback: (_error: string) => void
) => {
  try {
    const resp: GeneralAnalysisApiResponse = await api.general_analyses.update(id, newData);
    callback(resp.data);
  } catch (error) {
    errorCallback(`Error updating report - ${helpers.parseApiError(error as ts.types.common.ApiError)}`);
  }
};

export const keepCurrent = async (
  analysis: ts.types.analysis.GeneralAnalysisDraft,
  callback: (_data: ts.types.analysis.GeneralAnalysis) => void,
  errorCallback: (_error: string) => void
) => {
  try {
    const resp = await api.dates.keep_current({
      resource_id: analysis.id,
      resource_type: 'general_analyses',
      max_date: config.features.end_date,
    });
    callback(resp.resource);
  } catch (error) {
    errorCallback(`Error updating analysis dates - ${helpers.parseApiError(error as ts.types.common.ApiError)}`);
  }
};
