import { _, moment, ts } from '_core';

import { DraftParams } from './types';

export const getRiskReportMetricName = (colKey: string) => {
  if (colKey == 'mctr') return 'Marginal Contribution to Total Risk';
  if (colKey == 'pctr') return 'Percent Contribution to Total Risk';
  if (colKey.startsWith('_handle_')) return colKey.replace('_handle_', '');
  if (colKey.startsWith('_industry_')) return colKey.replace('_industry_', '');

  return _.startCase(colKey);
};

export const getRiskReportValue = (colKey: string, value: number) => {
  const localValue = (value * 100).toFixed(3);
  if (colKey.includes('variance')) return localValue;
  return localValue + '%';
};

export const validate = (
  params: DraftParams,
  context: ts.types.backtest.BacktestAnaylsisContext | ts.types.basket.BasketAnalysisContext
) => {
  const date = params.start_date || params.end_date;
  if (_.isEmpty(date)) return ['Report date is a required field.'];

  const resource = params.isBasket ? 'basket' : 'backtest';
  if (context) {
    if (moment(date).isBefore(moment(context.start_date))) return [`Report date must be inside the ${resource} dates.`];
    if (moment(date).isAfter(moment(context.end_date))) return [`Report date must be inside the ${resource} dates.`];
  }
  return [];
};
