
import React from 'react';

import { _, mui } from '../../libs';

export type ItemsType = {

  name: string;
  key: string;
  created_at?: string;
  [key: string]: any;
};

type SortByProps = {
  items: ItemsType[];
  sortedItems: ItemsType[];
  setSortedItems: (_i: ItemsType[]) => void;
  sortBy: 'name' | 'creation' | 'custom';
  setSortBy: (_s: 'name' | 'creation' | 'custom') => void;
};

const SortBy: React.FC<SortByProps> = ({ items, sortedItems, setSortedItems, sortBy, setSortBy }) => {
  const [sortDirection, setSortDirection] = React.useState<'asc' | 'desc'>('asc');

  const getInitialSortValue = (): ['name' | 'creation' | 'custom', 'asc' | 'desc'] => {
    // Check if sorted by name
    const [isSortedByName, nameSortDirection] = _.compareArr(items, sortedItems, (item) => _.lowerCase(item.name));
    if (isSortedByName) return ['name', nameSortDirection];

    // Check if sorted by creation
    const [isSortedByCreation, creationSortDirection] = _.compareArr(items, sortedItems, (item) => item.created_at);
    if (isSortedByCreation) return ['creation', creationSortDirection];

    return ['custom', 'asc'];
  };

  React.useEffect(() => {
    const [localWorkspacesSort, localSortDirection] = getInitialSortValue();
    setSortBy(localWorkspacesSort);
    setSortDirection(localSortDirection);
  }, []);

  const sortItems = (sortBy: 'name' | 'creation' | 'custom', sortDir: 'asc' | 'desc' = sortDirection) => {
    let localSortedRawItems = [...items];

    if (sortBy == 'name') localSortedRawItems = _.orderBy(localSortedRawItems, (i) => _.lowerCase(i.name), sortDir);
    else localSortedRawItems = _.orderBy(localSortedRawItems, 'created_at', sortDir);

    setSortedItems(localSortedRawItems);
  };

  return (
    <mui.core.Box display="flex" justifyContent="flex-end" gap={2} p={3}>
      <mui.core.TextField
        select
        variant="outlined"
        color="primary"
        size="small"
        label="Sort by"
        value={sortBy}
        onChange={(e) => {
          const newSort = e.target.value as 'name' | 'creation' | 'custom';
          sortItems(newSort);
          setSortBy(newSort);
        }}
      >
        <mui.core.MenuItem value={'name'}>Name</mui.core.MenuItem>
        <mui.core.MenuItem value={'creation'}>Creation date</mui.core.MenuItem>
        {sortBy == 'custom' && <mui.core.MenuItem value={'custom'}>Custom</mui.core.MenuItem>}
      </mui.core.TextField>
      {sortBy != 'custom' && (
        <mui.core.Button
          size="small"
          variant="outlined"
          onClick={() => {
            const newSortDirection = sortDirection == 'asc' ? 'desc' : 'asc';
            setSortDirection(newSortDirection);
            sortItems(sortBy, newSortDirection);
          }}
        >
          {sortBy == 'name' ? (
            <mui.icons.SortByAlpha sx={{ fontSize: '1rem' }} />
          ) : (
            <mui.icons.SwapVert sx={{ fontSize: '1rem' }} />
          )}
          {sortDirection == 'asc' ? (
            <mui.icons.ExpandMore sx={{ fontSize: '1rem' }} />
          ) : (
            <mui.icons.ExpandLess sx={{ fontSize: '1rem' }} />
          )}
        </mui.core.Button>
      )}
    </mui.core.Box>
  );
};

export default SortBy;
