import { am5, am5percent, am5xy } from '@local/finsera-core/src/libs';

const eyeOpen =
  // eslint-disable-next-line max-len
  'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PHBhdGggZD0iTTEyIDRDNCw0IDEsMTIgMSwxMkMxLDEyIDQsMjAgMTIsMjBDMjAsMjAgMjMsMTIgMjMsMTJDMjMsMTIgMjAsNCAxMiw0TTEyLDdBNSw1IDAgMCwxIDE3LDEyQTUsNSAwIDAsMSAxMiwxN0E1LDUgMCAwLDEgNywxMkE1LDUgMCAwLDEgMTIsN00xMiw5QTMsMyAwIDAsMCA5LDEyQTMsMyAwIDAsMCAxMiwxNUEzLDMgMCAwLDAgMTUsMTJBMywzIDAgMCwwIDEyLDlaIiBmaWxsPSIjODA4MDgwIi8+PC9zdmc+';
const eyeClosed =
  // eslint-disable-next-line max-len
  'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PHBhdGggZD0iTTExLjgzLDkgTDE1LDEyLjE2QzE1LDEyLjExIDE1LDEyLjA1IDE1LDEyQTMsMyAwIDAsMCAxMiw5QzExLjk0LDkgMTEuODksOSAxMS44Myw5TTcuNTMsOS44TDkuMDgsMTEuMzVDOS4wMywxMS41NiA5LDExLjc3IDksMTJBMywzIDAgMCwwIDEyLDE1QzEyLjIyLDE1IDEyLjQ0LDE0Ljk3IDEyLjY1LDE0LjkyTDE0LjIsMTYuNDdDMTMuNTMsMTYuOCAxMi43OSwxNyAxMiwxN0E1LDUgMCAwLDEgNywxMkM3LDExLjIxIDcuMiwxMC40NyA3LjUzLDkuOE0yLDMuMjdMNC4yOCw1LjU1TDQuNzMsNkM0LjczLDYgMiw4LjUgMiwxMkMyLDE1LjUgNC43MywxOCA0LjczLDE4SDVMMTUuNzMsMjguNzNMMTcsMjdMMywyLjI3TDIsNC==';

export const createBaseLegend = ({
  root,
  chart,
  yAxis,
}: {
  root: am5.Root;
  chart: am5xy.XYChart | am5percent.PieChart;
  yAxis: am5xy.Axis<any>;
}) => {
  const legend = chart.children.push(
    am5.Legend.new(root, {
      nameField: 'name',
      fillField: 'color',
      strokeField: 'color',
      centerX: am5.percent(50),
      x: am5.percent(50),
      useDefaultMarker: true,
      paddingTop: 20,
      paddingBottom: 20,
      maxHeight: 420,
      layout: root.gridLayout,
      verticalScrollbar: am5.Scrollbar.new(root, {
        orientation: 'vertical',
      }),
    })
  );

  yAxis.events.on('boundschanged', function () {
    legend.set('dx', yAxis.width());
  });

  legend.labels.template.setAll({
    fontSize: 11,
  });

  legend.itemContainers.template.setAll({
    marginTop: 0,
    marginBottom: 0,
    paddingTop: 2,
    paddingBottom: 2,
    paddingLeft: 5,
    paddingRight: 5,
  });

  legend.markerRectangles.template.setAll({
    cornerRadiusTL: 0,
    cornerRadiusTR: 0,
    cornerRadiusBL: 0,
    cornerRadiusBR: 0,
  });

  legend.markers.template.setAll({
    width: 8,
    height: 8,
  });

  let hidden = false;
  legend.itemContainers.template.events.on('click', function (e) {
    const dataContext = e.target.dataItem.dataContext as { key: string; name: string };
    if (dataContext.key === 'hide-show') {
      hidden = !hidden;
      if (hidden) {
        chart.series.each((s) => s.hide());
        dataContext.name = 'Show All';
      } else {
        chart.series.each((s) => s.show());
        dataContext.name = 'Hide All';
      }
      legend.data.events.dispatch('valuechanged' as any, {});
    }
  });

  legend.data.setAll(chart.series.values);

  if (chart.series.values.length > 2) {
    legend.data.setAll([
      ...chart.series.values,
      {
        key: 'hide-show',
        name: 'Hide/Show All',
        color: am5.color(0x808080),
        markerRectangle: {
          fillOpacity: 0,
          strokeOpacity: 0,
        },
      },
    ]);

    // Create custom icon for hide/show button
    const hideShowContainer = legend.itemContainers.getIndex(legend.itemContainers.length - 1);
    const marker = hideShowContainer?.children.getIndex(0) as am5.Container;

    if (marker) {
      // Ensure the default marker is not visible
      marker.setAll({
        width: 0,
        height: 0,
        paddingLeft: 4,
        marginRight: 10,
      });

      // Add custom icon
      const icon = marker.children.push(
        am5.Picture.new(root, {
          width: 14,
          height: 14,
          centerX: am5.p50,
          centerY: am5.p50,
          src: eyeOpen,
        })
      );

      // Update icon when state changes
      legend.itemContainers.template.events.on('click', function (e) {
        const dataContext = e.target.dataItem.dataContext as { key: string; name: string };
        if (dataContext.key === 'hide-show') {
          if (hidden) {
            icon.set('src', eyeClosed);
          } else {
            icon.set('src', eyeOpen);
          }
        }
      });
    }
  }

  // Add cursor pointer to hide/show button
  legend.itemContainers.template.set('cursorOverStyle', 'pointer');

  return legend;
};
