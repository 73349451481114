import { React, ts } from '_core';

import Chart from './chart';

type PortfolioRiskFactorExposureProps = {
  id: string;
  readOnly: boolean;
  widgetKey: string;
  loadWidgetData: (_payload: ts.types.widgets.WidgetGetDataParams) => Promise<ts.types.widgets.WidgetGetDataResponse>;
  widgetPaths: Record<
    'backtest_factor_exposure_analysis' | 'rebalance_factor_exposure_analysis' | 'portfolio_factor_exposure_analysis',
    string
  >;
  title: string;
  fullScreen: boolean;
};

const PortfolioRiskFactorExposure: React.FC<PortfolioRiskFactorExposureProps> = ({
  id,
  readOnly,
  widgetKey,
  loadWidgetData,
  widgetPaths,
  fullScreen,
  title,
}) => {
  const fileKey = React.useMemo(
    () =>
      Object.keys(widgetPaths || {}).find((k) => k.endsWith('_factor_exposure_analysis')) as keyof typeof widgetPaths,
    [widgetPaths]
  );

  return (
    <>
      <Chart
        id={id}
        readOnly={readOnly}
        widgetKey={widgetKey}
        loadWidgetData={loadWidgetData}
        file={widgetPaths[fileKey]}
        fullScreen={fullScreen}
        title={title}
      />
    </>
  );
};

export default PortfolioRiskFactorExposure;
