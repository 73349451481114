import React from 'react';

import * as hooks from '../../hooks';
import { _, mui } from '../../libs';
import * as types from '../../types';

/**
 * Component that provides async autocomplete functionality with configurable options and rendering
 *
 * @param label - Input label text
 * @param variant - MUI input variant (outlined, filled, standard)
 * @param value - Currently selected value(s)
 * @param setValue - Callback to update selected value(s)
 * @param query - Function to fetch options asynchronously
 * @param createTitle - Function to generate display title for each option
 * @param example - Example/placeholder text
 * @param createId - Function to generate unique ID for each option
 * @param limitTags - Maximum number of tags to show when multiple selection is enabled
 * @param disabled - Whether the input is disabled
 * @param optionRenderer - Custom renderer for option items
 * @param tableView - Whether to show options in table format
 * @param multiple - Whether multiple selection is enabled
 * @param fullWidth - Whether the input should take full width
 * @param limit - Maximum number of options per page
 * @param disableCloseOnSelect - Whether to keep dropdown open after selection
 * @param initialOptions - Initial options to show before any search is performed
 *
 * @returns Rendered async autocomplete component
 */
const AutoComplete = <TValue, TOptions, Multiple, UseOption extends boolean | undefined = undefined>(
  props: types.components.autocomplete.IAutoCompleteProps<TValue, TOptions, Multiple, UseOption>
): React.ReactElement => {
  const uiStyles = hooks.useUiStyles();

  const {
    label,
    variant,
    value,
    setValue,
    query,
    createTitle,
    example,
    createId,
    limitTags,
    disabled,
    optionRenderer,
    tableView,
    multiple,
    fullWidth,
    limit,
    disableCloseOnSelect,
    textFieldProps,
    initialOptions,
    sx,
  } = props;

  const [loading, setLoading] = React.useState(false);
  const [options, setOptions] = React.useState(initialOptions || []);
  const [open, setOpen] = React.useState(false);
  const [noOptionsText, setOptionsText] = React.useState(`${example}`);
  const [searchTerm, setSearchTerm] = React.useState<{
    text: string;
    load?: boolean;
  }>(multiple ? { text: '' } : { text: value ? createTitle(value as TValue) : '' });
  const offset = React.useRef(0);
  const scrollFinished = React.useRef(false);

  const debouncedSearch = React.useCallback(
    _.debounce(async (queryTerm) => {
      // Set default states for search
      scrollFinished.current = false;
      offset.current = 0;
      setOpen(true);
      setOptionsText(`Your result didn't return any result. 
        ${example}`);

      // Perform search
      try {
        const response = await query(queryTerm, limit, offset.current);
        if (response.length < limit) scrollFinished.current = true;
        setOptions(response);
        setLoading(false);
      } catch {
        setLoading(false);
      }
    }, 1000),
    [query]
  );

  React.useEffect(() => {
    if (initialOptions && !options.length) {
      setOptions(initialOptions);
    }
  }, [initialOptions]);

  const loadOnScroll = async () => {
    if (!limit) return; // if we don't have a limit, then we are not paginating
    if (loading) return; // if we are already loading do nothing
    if (scrollFinished.current) return; // if we have no more results do nothing
    offset.current += limit;
    setLoading(true);
    // Perform search
    try {
      const response = await query(searchTerm.text, limit, offset.current);
      if (response.length < limit) scrollFinished.current = true;
      const localOptions = [...options, ...response];
      setOptions(localOptions);
      setLoading(false);
    } catch {
      offset.current -= limit;
      setLoading(false);
    }
  };

  React.useEffect(() => {
    setOptions(initialOptions || []);
    if (searchTerm.text && searchTerm.load) {
      setLoading(true);
      debouncedSearch(searchTerm.text);
    } else {
      setLoading(false);
      setOptionsText(example);
    }
  }, [searchTerm]);

  const AutoCompleteComponent = () => (
    <mui.core.Autocomplete<any, typeof multiple, typeof tableView, false>
      multiple={multiple}
      forcePopupIcon={false}
      disableClearable={tableView}
      renderTags={tableView ? () => null : undefined}
      noOptionsText={noOptionsText}
      selectOnFocus={false}
      getOptionLabel={(option) => createTitle(option)}
      filterOptions={(x) => x}
      options={options}
      inputValue={searchTerm.text}
      autoComplete
      loading={loading}
      includeInputInList
      filterSelectedOptions
      isOptionEqualToValue={(option, val) => createId(option) == createId(val)}
      disabled={disabled}
      limitTags={limitTags}
      size="small"
      fullWidth={fullWidth}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      value={value}
      sx={sx}
      disableCloseOnSelect={disableCloseOnSelect}
      onChange={(e, val) => {
        setValue(val);
        setOptions([]);

        if (multiple) setSearchTerm({ text: '', load: false });
        // Fill the text for the selection. When multiple we create chips
        else
          setSearchTerm({
            text: val ? createTitle(val) : '',
            load: false,
          });
      }}
      onInputChange={(_e, newInputValue, action) => {
        if (action != 'reset') setSearchTerm({ text: newInputValue, load: true });
      }}
      renderInput={(params) => (
        <mui.core.TextField
          {...params}
          {...textFieldProps}
          variant={variant}
          label={label}
          onClick={() => setOpen(true)}
          disabled={loading || disabled}
          fullWidth
          placeholder={example}
          size="small"
        />
      )}
      renderOption={(props, option) => <li {...props}>{optionRenderer(option)}</li>}
      getOptionDisabled={() => loading}
      ListboxProps={{
        onScroll: (event: React.UIEvent<HTMLElement>) => {
          const listboxNode = event.currentTarget;
          if (listboxNode.scrollTop + listboxNode.clientHeight + 50 >= listboxNode.scrollHeight) {
            loadOnScroll();
          }
        },
        style: { minHeight: '300px' },
      }}
    />
  );

  return (
    <mui.core.Box sx={uiStyles.inputHelp} style={fullWidth ? { width: '100%' } : {}}>
      {AutoCompleteComponent()}
    </mui.core.Box>
  );
};

export default AutoComplete;
