import React from 'react';

import NumericField from './numeric-field';
import ResourceAutocomplete from './resource-autocomplete';
import * as enums from '../enums';
import * as helpers from '../helpers';
import * as hooks from '../hooks';
import { _,mui, useSelector } from '../libs';
import * as types from '../types';
import { BaseStore } from '../types';

type ReturnHorizonProps = {
  disabled?: boolean;
  value: types.returnHorizon.ReturnHorizonDraft;
  setValue: (_value: types.returnHorizon.ReturnHorizonDraft) => void;
  validationDef?: string[];
};

/**
 * Component for configuring return horizon settings including periods, frequency, lead time, and pipelines
 * 
 * @requires Redux Store:
 * - store.resources.pipelines: Available pipelines data
 * 
 * @param disabled - Flag to disable the component
 * @param value - The current return horizon draft value
 * @param setValue - Function to update the return horizon value
 * @param validationDef - Optional validation definitions
 */
const ReturnHorizon: React.FC<ReturnHorizonProps> = ({ value, setValue, disabled, validationDef }) => {
  const [periods, setPeriods] = React.useState(value?.periods?.amount);
  const [frequency, setFrequency] = React.useState(value.periods?.frequency || enums.FREQUENCY_ENUM.DAILY);
  const [skipPeriods, setSkipPeriods] = React.useState(value?.skip?.amount);
  const [skipFrequency, setSkipFrequency] = React.useState(value.skip?.frequency || enums.FREQUENCY_ENUM.DAILY);
  const [pipelineIds, setPipelineIds] = React.useState(value?.pipeline_ids || []);

  const pipelines = useSelector((store:  BaseStore) => store.resources.pipelines);

  hooks.useEffectWithoutFirst(() => {
    const rh = {} as types.returnHorizon.ReturnHorizon;
    if (periods && frequency) {
      rh.periods = { amount: periods, frequency };
    }
    if (skipPeriods && skipFrequency) {
      rh.skip = { amount: skipPeriods, frequency: skipFrequency };
    }
    if (!_.isEmpty(pipelineIds)) rh.pipeline_ids = pipelineIds;

    setValue({
      ...rh,
      signature: helpers.returnHorizon.getSignature(rh.periods, rh.skip, rh.pipeline_ids, pipelines),
    });
  }, [periods, frequency, skipPeriods, skipFrequency, pipelineIds]);

  return (
    <>
      <mui.core.Grid container spacing={2}>
        <mui.core.Grid item xs={6}>
          <NumericField
            disabled={disabled}
            textFieldProps={{
              label: 'Periods',
              fullWidth: true,
              variant: 'outlined',
              size: 'small',
              required: true,
              error: validationDef?.includes('rh_amount'),
            }}
            value={periods}
            setValue={(v) => setPeriods(v)}
          />
        </mui.core.Grid>

        <mui.core.Grid item xs={6}>
          <mui.core.TextField
            select
            variant="outlined"
            size="small"
            value={frequency}
            onChange={(e) => setFrequency(e.target.value as enums.FREQUENCY_ENUM)}
            fullWidth
            required
            disabled={disabled}
          >
            {Object.values(enums.FREQUENCY_ENUM).map((freq) => (
              <mui.core.MenuItem key={freq} value={freq}>
                {helpers.returnHorizon._freqToSignature(freq) || 'Periods'}
              </mui.core.MenuItem>
            ))}
          </mui.core.TextField>
        </mui.core.Grid>
      </mui.core.Grid>

      <mui.core.Box mt={2}>
        <mui.core.Grid container spacing={2}>
          <mui.core.Grid item xs={6}>
            <NumericField
              disabled={disabled}
              textFieldProps={{
                label: 'Lead',
                fullWidth: true,
                variant: 'outlined',
                size: 'small',
                error: validationDef?.includes('rh_skip_amount'),
              }}
              value={skipPeriods}
              setValue={(v) => setSkipPeriods(v)}
            />
          </mui.core.Grid>

          <mui.core.Grid item xs={6}>
            <mui.core.TextField
              select
              variant="outlined"
              size="small"
              value={skipFrequency}
              onChange={(e) => setSkipFrequency(e.target.value as enums.FREQUENCY_ENUM)}
              fullWidth
              disabled={disabled}
            >
              {Object.values(enums.FREQUENCY_ENUM).map((freq) => (
                <mui.core.MenuItem key={freq} value={freq}>
                  {helpers.returnHorizon._freqToSignature(freq) || 'Periods'}
                </mui.core.MenuItem>
              ))}
            </mui.core.TextField>
          </mui.core.Grid>
        </mui.core.Grid>

        <mui.core.Box mt={2}>
          <mui.core.Grid container spacing={2}>
            <mui.core.Grid item xs={12}>
              <ResourceAutocomplete<types.pipeline.PipelineExpanded, true, false>
                value={pipelineIds}
                setValue={(ids) => setPipelineIds(ids)}
                type={enums.RESOURCES_TYPES_ENUM.PIPELINE}
                disabled={disabled}
                inputProps={{
                  fullWidth: true,
                  label: 'Pipelines',
                  variant: 'outlined',
                  size: 'small',
                }}
                autocompleteProps={{
                  multiple: true,
                  size: 'small',
                }}
              />
            </mui.core.Grid>
          </mui.core.Grid>
        </mui.core.Box>
      </mui.core.Box>
    </>
  );
};

export default ReturnHorizon;

ReturnHorizon.defaultProps = {
  disabled: false,
  validationDef: [],
};
