import { _, hooks, mui, React, ts } from '_core';

import * as columns from 'views/preferences/columns-config';
import Tabular from 'views/report/tables/tabular';

import { Params, SHEETS } from './types';
import { getColumns } from '../../common/charts/utils';

type ChartProps = {
  id: string;
  readOnly: boolean;
  widgetKey: string;
  loadWidgetData: (_payload: ts.types.widgets.WidgetGetDataParams) => Promise<ts.types.widgets.WidgetGetDataResponse>;
  params: Params;
  setParams: (_p: Params) => void;
  fullScreen: boolean;
  goFullScreen: (_v: string) => void;
  model: string;
  factor: string;
  risk: string;
  queryParams?: { initial?: string };
  context?: ts.types.portfolio.PortfolioAnalysisContext;
};

const Chart: React.FC<ChartProps> = ({
  id,
  readOnly,
  widgetKey,
  loadWidgetData,
  params,
  model,
  factor,
  risk,
  goFullScreen,
  setParams,
  fullScreen,
}) => {
  const [error, setError] = React.useState<ts.types.common.ApiError>(null);
  const [data, setData] = React.useState<Record<string, ts.types.widgets.TableData>>();

  const [containerHeight, setContainerHeight] = React.useState(undefined);
  const { height } = hooks.useWindowDimensions();

  const container = React.createRef<HTMLDivElement>();

  React.useEffect(() => {
    if (container.current) {
      setContainerHeight(container.current.offsetHeight - 40);
    }
  }, [height]);

  const [expandedGroupIds, setExpandedGroupIds] = React.useState<Record<string, ReadonlySet<unknown>>>(
    params.expanded
      ? Object.fromEntries(Object.entries(params.expanded).map(([key, val]) => [key, new Set(val)]))
      : null
  );

  const [tableParams, setTableParams] = React.useState<ts.types.components.dataGrid.TableParamsSheet>(
    params.table_params
  );

  const [currentSheet, setCurrentSheet] = React.useState(params.current_sheet || ('model' as SHEETS));

  const debouncedParamsSave = React.useCallback(
    _.debounce(async (exp: typeof expandedGroupIds, ct: SHEETS, tbP: ts.types.components.dataGrid.TableParamsSheet) => {
      setParams({
        ...params,
        expanded: Object.fromEntries(
          Object.entries(exp).map(([key, val]) => [key, [...val] as string[]])
        ) as Params['expanded'],
        current_sheet: ct,
        table_params: { ...tbP },
      });
    }, 1_000),
    []
  );

  hooks.useEffectWithoutFirst(() => {
    debouncedParamsSave(expandedGroupIds, currentSheet, tableParams);
  }, [expandedGroupIds, currentSheet, tableParams]);

  const loadData = async () => {
    try {
      const resp = await Promise.all([
        loadWidgetData({ data: { file: model } }),
        loadWidgetData({ data: { file: risk } }),
        loadWidgetData({ data: { file: factor } }),
      ]);

      if (resp) {
        if (!_.isEmpty(resp[2].data))
          setData({
            model: resp[0].data,
            risk: resp[1].data,
            factor: resp[2].data,
          });
        else
          setData({
            model: resp[0].data,
            risk: resp[1].data,
          });

        if (_.isEmpty(expandedGroupIds)) {
          const modelLabels = new Set(resp[0].data.map((x) => x.label));
          const riskLabels = new Set(resp[1].data.map((x) => x.label));
          const factorLabels = new Set(resp[2].data.map((x) => x.label));
          setExpandedGroupIds({
            model: modelLabels,
            factor: factorLabels,
            risk: riskLabels,
          });
        }
      }
    } catch (err) {
      setError(err as ts.types.common.ApiError);
    }
  };

  React.useEffect(() => {
    if (model && factor) loadData();
    return () => setData(null);
  }, [model, factor]);

  return (
    <mui.core.Box
      id={id}
      key={widgetKey}
      sx={{
        height: 'calc(100% - 1px)',
        '& .rdg-light': { paddingBottom: '60px' },
      }}
      ref={container}
    >
      <Tabular<SHEETS>
        data={data}
        apiError={error}
        readOnly={readOnly}
        getColumns={{
          model: (modelKeys, preferences) =>
            getColumns(modelKeys, preferences?.model, columns.portfolioTradeListFactorExposureColumns['model']),
          risk: (riskKeys, preferences) =>
            getColumns(riskKeys, preferences?.risk, columns.portfolioTradeListFactorExposureColumns['risk']),
          factor: (factorKeys, preferences) =>
            getColumns(factorKeys, preferences?.factor, columns.portfolioTradeListFactorExposureColumns['factor']),
        }}
        sheetTitles={{
          model: 'Model Summary',
          risk: 'Risk Factor Exposure Summary',
          factor: 'User Factor Exposure Summary',
        }}
        fullScreen={fullScreen}
        goFullScreen={goFullScreen}
        hideFilters
        alwaysShowBottom
        groupBy={{ model: ['label'], risk: ['label'], factor: ['label'] }}
        expandedGroupIds={expandedGroupIds}
        setExpandedGroupIds={{
          model: (v) => setExpandedGroupIds((exp) => ({ ...exp, model: v })),
          risk: (v) => setExpandedGroupIds((exp) => ({ ...exp, risk: v })),
          factor: (v) => setExpandedGroupIds((exp) => ({ ...exp, factor: v })),
        }}
        preferences={{
          preferencesSourceWidgetId: id,
          preferenceKey: ts.enums.PREFERENCES_KEY_ENUM.PORTFOLIO_TRADE_LIST_FACTOR_EXPOSURE_COLS,
          preferenceTab: ts.enums.PREFERENCES_INNER_TABS_ENUM.PORTFOLIO_TRADE_LIST_FACTOR_EXPOSURE_COLS,
        }}
        tableParams={tableParams}
        setTableParams={{
          model: (v) => setTableParams((t) => ({ ...t, model: v })),
          risk: (v) => setTableParams((t) => ({ ...t, risk: v })),
          factor: (v) => setTableParams((t) => ({ ...t, factor: v })),
        }}
        currentSheet={currentSheet}
        setCurrentSheet={setCurrentSheet}
        overrideHeight={containerHeight}
        triggerWidthChange={params.fullWidth}
      />
    </mui.core.Box>
  );
};

export default Chart;
