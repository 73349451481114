import { mui, React, ts, ui } from '_core';

type SignalType = {
  signal_id: number;
  pipeline_id?: number;
  sp_pipeline_id?: number;
  signal_label?: string;
};

type PipelineSignalsSelectorProps = {
  signals: SignalType[];
  setSignals: (_params: SignalType[]) => void;
  hide_labels?: boolean;
  enforce_single?: boolean;
};

const PipelineSignalsSelector = ({
  signals,
  setSignals,
  hide_labels,
  enforce_single,
}: PipelineSignalsSelectorProps) => {
  const handleSetValue = (v: SignalType, idx: number) => {
    if (enforce_single) {
      setSignals([v]);
    } else {
      setSignals(signals.map((s, i) => (i == idx ? v : s)));
    }
  };

  const handleRemove = (idx: number) => {
    if (enforce_single) {
      setSignals([]);
    } else {
      setSignals(signals.filter((_, i) => idx !== i));
    }
  };

  return (
    <>
      <mui.core.Stack flexDirection="row" gap={4} mb={3}>
        <mui.core.Typography variant="body2" paragraph sx={{ margin: 'auto 0' }}>
          <strong>Signals *</strong>
        </mui.core.Typography>

        {!enforce_single && (
          <ui.Button
            onClick={() => setSignals([...signals, {} as any])}
            variant="outlined"
            color="primary"
            size="small"
            startIcon={<mui.icons.Add />}
          >
            Add Value
          </ui.Button>
        )}
      </mui.core.Stack>

      {signals.map((signal, idx) => (
        <mui.core.Box my={2} key={idx}>
          <mui.core.Grid container key={idx} spacing={2}>
            <mui.core.Grid item xs={3}>
              <ui.ResourceAutocomplete<ts.types.signal.SignalExpanded>
                value={signal.signal_id}
                setValue={(v) => handleSetValue({ ...signal, signal_id: v }, idx)}
                type="signal_based"
                inputProps={{
                  label: 'Signal / Alpha M.',
                  variant: 'outlined',
                  size: 'small',
                  required: true,
                }}
              />
            </mui.core.Grid>

            <mui.core.Grid item xs={3}>
              <ui.ResourceAutocomplete<ts.types.pipeline.PipelineExpanded>
                value={signal.pipeline_id}
                setValue={(v) => handleSetValue({ ...signal, pipeline_id: v }, idx)}
                type={ts.enums.RESOURCES_TYPES_ENUM.PIPELINE}
                enableNone
                inputProps={{
                  label: 'Pipeline',
                  variant: 'outlined',
                  size: 'small',
                }}
              />
            </mui.core.Grid>

            <mui.core.Grid item xs={3}>
              <ui.ResourceAutocomplete<ts.types.pipeline.PipelineExpanded>
                value={signal.sp_pipeline_id}
                setValue={(v) => handleSetValue({ ...signal, sp_pipeline_id: v }, idx)}
                type="sp_pipeline"
                enableNone
                inputProps={{
                  label: 'Portfolio Pipeline',
                  variant: 'outlined',
                  size: 'small',
                }}
              />
            </mui.core.Grid>

            {!hide_labels && (
              <mui.core.Grid item xs={2}>
                <mui.core.TextField
                  autoFocus
                  id="name"
                  label="Signal Label"
                  value={signal.signal_label}
                  variant="outlined"
                  onChange={(e) => handleSetValue({ ...signal, signal_label: e.target.value }, idx)}
                  fullWidth
                  size="small"
                  inputProps={{
                    autocomplete: 'off',
                  }}
                />
              </mui.core.Grid>
            )}

            <mui.core.Grid item>
              {!enforce_single && (
                <mui.core.IconButton onClick={() => handleRemove(idx)} size="small">
                  <mui.core.Tooltip arrow title="Remove value">
                    <mui.icons.DeleteOutline />
                  </mui.core.Tooltip>
                </mui.core.IconButton>
              )}
            </mui.core.Grid>
          </mui.core.Grid>
        </mui.core.Box>
      ))}
    </>
  );
};

export default PipelineSignalsSelector;
