import React from 'react';

/**
 * Hook that creates a ref containing the current values of multiple state getters
 * 
 * @param states - Array of functions that return state values
 * @returns React ref containing array of current state values
 * 
 * @example
 * ```tsx
 * const states = [
 *   () => someState,
 *   () => otherState
 * ];
 * const stateRefs = useProfilerRefs(states);
 * ```
 */
const useProfilerRefs = (states: (() => any)[]) => {
  const refs = React.useRef(states.map((s) => s()));
  refs.current = states.map((s) => s());
  return refs;
};

export default useProfilerRefs;
