import { _, moment, ts } from '_core';

import { DraftParams } from './types';

const industryMap = {
  l1: 'levelOne',
  l2: 'levelTwo',
  l3: 'levelThree',
  l4: 'levelFour',
} as const;

const industryNameMap = {
  l1: 'Sector',
  l2: 'Sub Sector',
  l3: 'Industry',
  l4: 'Sub Industry',
};

// prepareConstraintsPreviewData
export const transform = (
  data: ts.types.widgets.TableData,
  rbicsData: ts.StoreState['resources']['rbics_data']
) => {
  const newData = data.map((el) => {
    let newConstraint = el['constraint'] as string;

    if (newConstraint.includes('industry|')) {
      const industryData = newConstraint.split('|');
      const industryValue = industryData[2];
      const industryLevel = industryMap[industryData[1] as keyof typeof industryMap];

      newConstraint = `${industryNameMap[industryData[1] as keyof typeof industryNameMap]}; 
      ${rbicsData[industryLevel].find((el) => el.value == industryValue)?.name || newConstraint}`;
    }

    return { ...el, constraint: newConstraint };
  });

  return newData;
};

export const validate = (
  params: DraftParams,
  context: ts.types.backtest.BacktestAnaylsisContext | ts.types.basket.BasketAnalysisContext
) => {
  const date = params.details_start_date || params.details_end_date;

  if (_.isEmpty(date)) return ['Date is a required field.'];

  if (context) {
    if (moment(date).isBefore(moment(context.start_date))) return ['Date must be inside the backtest dates.'];
    if (moment(date).isAfter(moment(context.end_date))) return ['Date must be inside the backtest dates.'];
  }

  if (!_.isEmpty(params.component_errors)) return params.component_errors;

  return [];
};
