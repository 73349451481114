import { React, ts } from '_core';

import Chart from './chart';
import Form from './form';
import { DraftParams, Params } from './types';

type LinearAlphaStatisticsVsTimeProps = {
  id: string;
  readOnly: boolean;
  widgetKey: string;
  mode: ts.enums.CHART_MODE_ENUM;
  params: Params | DraftParams;
  setParams: (_p: DraftParams) => void;
  title: string;

  fullScreen?: boolean;
  context?: ts.types.signal.SignalAnalysisContext;
  loadWidgetData: (_payload: ts.types.widgets.WidgetGetDataParams) => Promise<ts.types.widgets.WidgetGetDataResponse>;
  widgetPaths?: Record<'linear_alpha_model_stats', string>;
};

const LinearAlphaStatisticsVsTime: React.FC<LinearAlphaStatisticsVsTimeProps> = ({
  id,
  readOnly,
  widgetKey,
  loadWidgetData,
  widgetPaths,
  mode,
  params,
  setParams,
  fullScreen,
  context,
  title,
}) => {
  const renderJobComponent = () => (
    <Chart
      id={id}
      readOnly={readOnly}
      widgetKey={widgetKey}
      loadWidgetData={loadWidgetData}
      file={widgetPaths['linear_alpha_model_stats']}
      params={params as Params}
      fullScreen={fullScreen}
      chartType={
        ((context.definition as ts.types.signal.AlphaDefinition)?.json_definition as ts.types.signal.Lasso)?.mode ==
        'time_series'
          ? 'linear'
          : 'scatter'
      }
      definition={(context.definition as ts.types.signal.AlphaDefinition)?.json_definition as ts.types.signal.Lasso}
      title={title}
    />
  );

  return <>{mode == 'edit' ? <Form params={params} setParams={setParams} /> : renderJobComponent()}</>;
};

export default LinearAlphaStatisticsVsTime;
