import { AddErrorActionType, ClearErrorsActionType, PopErrorActionType } from '_core/reducers/api-errors';

import { Dispatch } from 'react';
import { ApiBannerError } from '@local/finsera-core/src/types/common';

import { ADD_ERROR, CLEAR_ERRORS, POP_ERROR } from './types';

export const addError =
  (url: string, status: string, statusMessage: string, response: Record<string, any>) =>
  (dispatch: Dispatch<AddErrorActionType>) => {
    return dispatch({
      type: ADD_ERROR,
      payload: {
        error: {
          url,
          status,
          statusMessage,
          response,
        } as ApiBannerError,
      },
    });
  };

export const popError = (dispatch: Dispatch<PopErrorActionType>) => dispatch({ type: POP_ERROR });

export const clearErrors = (dispatch: Dispatch<ClearErrorsActionType>) => dispatch({ type: CLEAR_ERRORS });
