import { ts } from '_core';

const normalize = (series: number[]): number[] => {
  if (series.length <= 1) {
    return series; // Return the single value as-is if length <= 1
  }

  const minValue = Math.min(...series);

  // Shift the series to ensure no negative values
  if (minValue < 0) {
    series = series.map((value) => value - minValue);
  }

  let maxValue = Math.max(...series);
  const margin = maxValue * 0.2;
  maxValue += margin; // Adding 20% margin

  // Normalize the series by dividing with the max value
  return series.map((value) => (value + margin) / maxValue);
};

export const radarChartFormat = (data: ts.types.widgets.TableData) => {
  const statisticsAsPercent = ['annualized_return', 'annualized_risk', 'max_drowdown'];
  const histMap = {
    FIVE_TRADING_DAYS: ts.enums.HISTORY_ENUM_EXTENDED.FIVE_TRADING_DAYS,
    ONE_MONTH: ts.enums.HISTORY_ENUM_EXTENDED.ONE_MONTH,
    THREE_MONTHS: ts.enums.HISTORY_ENUM_EXTENDED.THREE_MONTHS,
    SIX_MONTHS: ts.enums.HISTORY_ENUM_EXTENDED.SIX_MONTHS,
    ONE_YEAR: ts.enums.HISTORY_ENUM_EXTENDED.ONE,
    THREE_YEARS: ts.enums.HISTORY_ENUM_EXTENDED.THREE,
    FIVE_YEARS: ts.enums.HISTORY_ENUM_EXTENDED.FIVE,
    FULL: ts.enums.HISTORY_ENUM_EXTENDED.FULL,
    MTD: ts.enums.HISTORY_ENUM_EXTENDED.MTD,
    QTD: ts.enums.HISTORY_ENUM_EXTENDED.QTD,
    YTD: ts.enums.HISTORY_ENUM_EXTENDED.YTD,
  } as Record<string, ts.enums.HISTORY_ENUM_EXTENDED>;

  const chart = data.map((item) => {
    const { spoke, ...rest } = item;

    const numericValues = Object.values(rest).map((value) => value as number);
    const normalizedValues = normalize(numericValues);
    const mappedRest = Object.keys(rest).reduce(
      (acc, key, index) => {
        acc[histMap[key] || key] = normalizedValues[index];
        return acc;
      },
      {} as Record<string, number>
    );

    return {
      spoke,
      ...mappedRest,
    };
  });

  const table = data.map((item) => {
    const { spoke, ...rest } = item;

    const formattedRest = Object.keys(rest).reduce(
      (acc, key) => {
        const value = rest[key] as number;
        acc[histMap[key] || key] = statisticsAsPercent.includes(spoke as string)
          ? `${(value * 100)?.toFixed(2)}%`
          : value?.toFixed(2);

        return acc;
      },
      {} as Record<string, string>
    );

    return {
      spoke,
      ...formattedRest,
    };
  });

  return { chart, table } as {
    chart: ts.types.widgets.RadarData;
    table: ts.types.widgets.TableData;
  };
};
