import { _, am4core, ts } from '_core';

export const aggregate = (
  data: {
    date?: string;
    real?: number;
    value?: number;
  }[],
  agg: string,
  yAxis: string
) => {
  if (agg == 'open') return data[0];
  if (agg == 'close') return data[data.length - 1];
  if (agg == 'avg')
    return {
      ...data[0],
      [yAxis]: _.mean(data.map((d) => d[yAxis as 'value'])),
      real: _.mean(data.map((d) => d[yAxis as 'value'])),
    };
  if (agg == 'max') return _.maxBy(data, (d) => d[yAxis as 'value']);
  if (agg == 'min') return _.minBy(data, (d) => d[yAxis as 'value']);
  if (agg == 'outlier') {
    const dataMean = _.mean(data.map((d) => d[yAxis as 'value']));
    let value = null as number;
    let maxDiff = -Infinity;
    data.forEach((el) => {
      if (!_.isNil(el[yAxis as 'value']) && Math.abs(el[yAxis as 'value'] - dataMean) > maxDiff) {
        maxDiff = Math.abs(el[yAxis as 'value'] - dataMean);
        value = el[yAxis as 'value'];
      }
    });

    return data.find((d) => d[yAxis as 'value'] == value);
  }
  return data[0];
};

export const addReal = (data: ts.types.widgets.LinearData, yAxis: keyof ts.types.widgets.LinearData[string][0]) => {
  Object.keys(data ?? {}).forEach((key: Exclude<string, 'date'>) => {
    data[key] = data[key]?.map((el) => ({ ...el, real: el['real'] || (el[yAxis] as number) })) || [];
  });
  return data;
};

export const groupData = (
  data: ts.types.widgets.LinearData,
  aggregation: 'open' | 'close' | 'outlier' | 'min' | 'max',
  yAxis: keyof ts.types.widgets.LinearData[string][0],
  maxNumberOfDatapoints: number
) => {
  const groupedData = {} as ts.types.widgets.LinearData;

  const keyWithValues = Object.entries(data).find(([_key, values]) => values.length > 0)?.[0];
  const groupSize = Math.ceil((data[keyWithValues]?.length || 0) / maxNumberOfDatapoints);

  Object.keys(data).forEach((key) => {
    const seriesdata = data[key];
    if (seriesdata.length > 0) {
      groupedData[key] = [seriesdata[0]]; // Always keep the first value
      if (seriesdata.length > 1) {
        const remainingData = seriesdata.slice(1);
        groupedData[key] = groupedData[key].concat(
          _.chunk(remainingData, groupSize).map((group) => {
            return aggregate(group, aggregation, yAxis);
          })
        );
      }
    } else {
      groupedData[key] = [];
    }
  });

  return groupedData;
};

const EMPTY_ARRAY: any[] = [];

export const getNestedBarLimits = (
  data: ts.types.widgets.NestedBarData,
  omitBy: string[] = EMPTY_ARRAY,
  percentPadding = 0.15
) => {
  let maxLimit = Number.MAX_VALUE * -1;
  let minLimit = Number.MAX_VALUE;

  data.forEach((el) => {
    maxLimit = _.max([maxLimit, _.max(Object.values(_.omit(el, ['category', ...omitBy])) as any)]);
    minLimit = _.min([minLimit, _.min(Object.values(_.omit(el, ['category', ...omitBy])) as any)]);
  });

  minLimit += minLimit * percentPadding;
  maxLimit += maxLimit * percentPadding;

  if (minLimit < 0.0 && maxLimit > 0.0) {
    minLimit += minLimit * percentPadding;
    maxLimit += maxLimit * percentPadding;
  } else if (minLimit >= 0.0) {
    minLimit = 0.0;
    maxLimit += maxLimit * percentPadding;
  } else if (maxLimit < 0.0) {
    minLimit += minLimit * percentPadding;
    maxLimit = 0.0;
  }

  if (maxLimit == 0 && minLimit == 0) maxLimit = 0.2;

  return { maxLimit, minLimit };
};

export const getPosNegLimits = (data: ts.types.widgets.PositiveNegativeData) => {
  let maxLimit = Number.MAX_VALUE * -1;
  let minLimit = Number.MAX_VALUE;

  data.forEach((el) => {
    maxLimit = _.max([maxLimit, el.value]);
    minLimit = _.min([minLimit, el.value]);
  });

  minLimit += (Math.abs(minLimit) + Math.abs(maxLimit)) * 0.08 * (minLimit < 0 ? -1 : 1);
  maxLimit += (Math.abs(minLimit) + Math.abs(maxLimit)) * 0.08;

  if (maxLimit >= 0 && minLimit >= 0) minLimit = 0;
  if (maxLimit <= 0 && minLimit <= 0) maxLimit = 0;

  return { maxLimit, minLimit: minLimit };
};

export const getLinearChartLimitsDiff = (data: ts.types.widgets.LinearData) => {
  let maxLimit = Number.MAX_VALUE * -1;
  let minLimit = Number.MAX_VALUE;

  Object.keys(data).forEach((key) => {
    maxLimit = _.max([maxLimit, _.maxBy(data[key], 'value')?.['value']]);
    minLimit = _.min([minLimit, _.minBy(data[key], 'value')?.['value']]);
  });

  const diff = Math.abs(maxLimit - minLimit);
  return diff;
};

export const getColumns = (
  columnKeys: string[],
  userColumnsPreferences: ts.types.preferences.BASE_PREFERENCES,
  previewColumns: ts.types.components.dataGrid.ColumnsData,
  context: Record<string, any> = null,
  resources: ts.StoreState['resources'] = null,
  removeColumns: string[] = [],
  columnValidation: (_c: ts.types.components.dataGrid.ColumnsData) => ts.types.components.dataGrid.ColumnsData = null,
  globalColumnRename: (_c: ts.types.components.dataGrid.ColumnsData) => ts.types.components.dataGrid.ColumnsData = null
) => {
  const userColumns = userColumnsPreferences?.columns;
  const userFrozenColumns = userColumnsPreferences?.frozenColumns;
  const userNames = userColumnsPreferences?.names ?? {};

  const localColumns = [] as ts.types.components.dataGrid.ColumnsData;

  const dynamicColumnsData = previewColumns.filter((c) => c.members);

  // Add the preview columns that are part of our config
  columnKeys.forEach((key) => {
    const columnData = previewColumns.find((c) => c.key === key);

    if (columnData) {
      if (columnData.filter == 'date' || (columnData.key.includes('build_date') && columnData.filter == 'inferred')) {
        localColumns.push({
          ...columnData,
          columnKey: key,
          start_date: context?.start_date,
          end_date: context?.end_date,
        });
      } else localColumns.push({ ...columnData, columnKey: key });
    } else {
      dynamicColumnsData.forEach((dynamicColumnData) => {
        if (!removeColumns.includes(key)) {
          if (dynamicColumnData.members(key) && !_.some(localColumns, (col) => col.key === key)) {
            if (
              dynamicColumnData.filter == 'date' ||
              (key.includes('build_date') && dynamicColumnData.filter == 'inferred')
            ) {
              localColumns.push(
                _.omitBy(
                  {
                    ...dynamicColumnData,
                    key,
                    columnKey: dynamicColumnData.key,
                    name: dynamicColumnData.nameFormatter(key, context, resources) as any,
                    cleanName: dynamicColumnData.cleanNameFormatter
                      ? dynamicColumnData.cleanNameFormatter(key, context, resources)
                      : undefined,
                    filter: 'date',
                    start_date: context?.start_date,
                    end_date: context?.end_date,
                  },
                  _.isUndefined
                ) as any
              );
            } else {
              localColumns.push(
                _.omitBy(
                  {
                    ...dynamicColumnData,
                    key,
                    columnKey: dynamicColumnData.key,
                    name: dynamicColumnData.nameFormatter(key, context, resources) as any,
                    cleanName: dynamicColumnData.cleanNameFormatter
                      ? dynamicColumnData.cleanNameFormatter(key, context, resources)
                      : undefined,
                  },
                  _.isUndefined
                ) as any
              );
            }
          }
        }
      });
    }
  });

  let tableColumns = [] as ts.types.components.dataGrid.ColumnsData;

  if (!userColumns) {
    const indexColumns = localColumns.filter((col) => col.isIndex);
    const regularColumns = localColumns.filter((col) => !col.isIndex);
    tableColumns = [...indexColumns, ...regularColumns];
  } else {
    // Filter by the columns the user has selected
    userColumns.filter((colKey) => {
      const columnData = localColumns.filter((col) => col.columnKey == colKey);
      if (columnData) tableColumns = tableColumns.concat(columnData);
    });

    if (userFrozenColumns) {
      let frozenColumns = [] as ts.types.components.dataGrid.ColumnsData;

      userFrozenColumns.filter((colKey) => {
        const columnData = localColumns
          .filter((col) => col.columnKey == colKey)
          .map((colData) => ({ ...colData, frozen: true }));

        if (columnData) frozenColumns = frozenColumns.concat(columnData);
      });

      tableColumns = [...frozenColumns, ...tableColumns];
    }
  }

  if (columnValidation) tableColumns = columnValidation(tableColumns);
  if (globalColumnRename) tableColumns = globalColumnRename(tableColumns);
  return tableColumns.map((c) => ({
    ...c,
    name: userNames[c.key] ?? c.name,
  }));
};

export const formatNumberToStringLabel = (diff: number, percent: boolean, integerOnly: boolean, value: number) => {
  if (_.isNil(value)) return '';
  if (diff <= 1e-5 && !percent && !(diff == 0)) {
    return '' + value.toExponential();
  }
  if (integerOnly) {
    if (percent) return (value * 100).toFixed(0) + '%';
    return '' + _.toInteger(value);
  }
  if (!integerOnly && percent) return '' + (value * 100).toFixed(2) + '%';
  return '' + value.toFixed(2);
};

export const getCSVColumns = (
  userColumnsPreferences: ts.types.preferences.BASE_PREFERENCES = null,
  previewColumns: ts.types.components.dataGrid.ColumnsData
): { name: string; key: string }[] => {
  if (_.isEmpty(userColumnsPreferences))
    return previewColumns.map((c) => ({ name: c.cleanName || (c.name as string), key: c.key }));

  const colsPref = [
    ...userColumnsPreferences.columns,
    ..._.without(
      previewColumns.map((c) => c.key),
      ...userColumnsPreferences.columns
    ),
  ];

  return colsPref
    .map((key) => previewColumns.find((c) => c.key == key))
    .filter((x) => x)
    .map((c) => ({ name: c.cleanName || (c.name as string), key: c.key }));
};

export const getChart = <T>(id: string): T => {
  const allCharts = am4core.registry.baseSprites;

  for (let i = 0; i < allCharts.length; i++) {
    if (id == allCharts[i].htmlContainer.id) {
      return allCharts[i] as T;
    }
  }
  return null;
};
