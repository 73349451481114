import { ts } from '_core';

import { getName, validate } from './helpers';
import { DraftParams, INTERACTION_PARAMS } from './types';

export default [
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.BASKET_ANNUALIZED_STATISTICS_RETURN,
    title: 'Annualized Returns',
    helpLabel: 'pnri6b3c',
    form: true,
    defaultParams: {
      isBasket: true,
      statistics_type: 'return',
      histories: ['1 Year', '3 Years', '5 Years', 'Full', 'Year to Date'],
    } as DraftParams,
    interactionParams: Object.values(INTERACTION_PARAMS),
    neededFiles: ['backtest_annualized_statistics'],
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.BASKET],

    validate,
    getName,
  },
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.BASKET_ANNUALIZED_STATISTICS_RISK,
    title: 'Annualized Risk',
    helpLabel: '4hawyiya',
    form: true,
    defaultParams: {
      isBasket: true,
      statistics_type: 'risk',
      histories: ['1 Year', '3 Years', '5 Years', 'Full', 'Year to Date'],
    } as DraftParams,
    interactionParams: Object.values(INTERACTION_PARAMS),
    neededFiles: ['backtest_annualized_statistics'],
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.BASKET],

    validate,
    getName,
  },
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.BASKET_ANNUALIZED_STATISTICS_ADJ,
    title: 'Annualized Risk Adjusted Returns',
    helpLabel: 'd8x7edsx',
    form: true,
    defaultParams: {
      isBasket: true,
      statistics_type: 'adjusted',
      histories: ['1 Year', '3 Years', '5 Years', 'Full', 'Year to Date'],
    } as DraftParams,
    interactionParams: Object.values(INTERACTION_PARAMS),
    neededFiles: ['backtest_annualized_statistics'],
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.BASKET],

    validate,
    getName,
  },
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.BACKTEST_ANNUALIZED_STATISTICS_RETURN,
    title: 'Annualized Returns',
    helpLabel: '7gkskinx',
    form: true,
    defaultParams: {
      isBasket: false,
      statistics_type: 'return',
      histories: ['1 Year', '3 Years', '5 Years', 'Full'],
    } as DraftParams,
    interactionParams: Object.values(INTERACTION_PARAMS),
    neededFiles: ['backtest_annualized_statistics'],
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.BACKTEST],

    validate,
    getName,
  },
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.BACKTEST_ANNUALIZED_STATISTICS_RISK,
    title: 'Annualized Risk',
    helpLabel: 'm1ebkn0h',
    form: true,
    defaultParams: {
      isBasket: false,
      statistics_type: 'risk',
      histories: ['1 Year', '3 Years', '5 Years', 'Full'],
    } as DraftParams,
    interactionParams: Object.values(INTERACTION_PARAMS),
    neededFiles: ['backtest_annualized_statistics'],
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.BACKTEST],

    validate,
    getName,
  },
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.BACKTEST_ANNUALIZED_STATISTICS_ADJ,
    title: 'Annualized Risk Adjusted Returns',
    helpLabel: 'tnxdtgd1',
    form: true,
    defaultParams: {
      isBasket: false,
      statistics_type: 'adjusted',
      histories: ['1 Year', '3 Years', '5 Years', 'Full'],
    } as DraftParams,
    interactionParams: Object.values(INTERACTION_PARAMS),
    neededFiles: ['backtest_annualized_statistics'],
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.BACKTEST],

    validate,
    getName,
  },
] satisfies ts.types.analysis.WidgetConfig[];
