// CAUTION - THIS WIDGET IS ALSO RENDERED ON GENERAL ANALYSIS */

import { React, ts } from '_core';

import Chart from './chart';
import Form from './form';
import { DraftParams, Params } from './types';

type TimeSeriesScatterCompareProps = {
  id: string;
  readOnly: boolean;
  widgetKey: string;
  mode: ts.enums.CHART_MODE_ENUM;
  params: Params | DraftParams;
  setParams: (_p: DraftParams) => void;
  context: ts.types.timeSeries.TimeSeriesAnalysisContext;
  isGeneralAnalysis: boolean;
  title: string;
  setExternalEmbed: (_json: ts.types.analysis.EmbedChartData) => void;

  fullScreen?: boolean;
  loadWidgetData: (_payload: ts.types.widgets.WidgetGetDataParams) => Promise<ts.types.widgets.WidgetGetDataResponse>;
  widgetPaths?: Record<'time_series_comparison', string>;
};

const TimeSeriesScatterCompare: React.FC<TimeSeriesScatterCompareProps> = ({
  id,
  readOnly,
  widgetKey,
  loadWidgetData,
  widgetPaths,
  mode,
  params,
  setParams,
  fullScreen,
  context,
  title,
  isGeneralAnalysis,
  setExternalEmbed,
}) => {
  const renderJobComponent = () => (
    <>
      <Chart
        id={id}
        readOnly={readOnly}
        widgetKey={widgetKey}
        loadWidgetData={loadWidgetData}
        file={widgetPaths['time_series_comparison']}
        params={params as Params}
        context={context}
        fullScreen={fullScreen}
        widgetTitle={title}
        setExternalEmbed={setExternalEmbed}
      />
    </>
  );

  return (
    <>
      {mode == 'edit' ? (
        <Form params={params} setParams={setParams} context={context} isGeneralAnalysis={isGeneralAnalysis} />
      ) : (
        renderJobComponent()
      )}
    </>
  );
};

export default TimeSeriesScatterCompare;
