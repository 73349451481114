import React from 'react';

import ReportError from './report-error';
import { captureException, withScope } from '../../libs/sentry';

type ErrorBoundaryFeedbackProps = {
  relative?: boolean;
  children: React.ReactElement;
};

type ErrorBoundaryFeedbackState = {
  hasError: boolean;
  errorMessage: string | null;
  errorLog: string | null;
};

/**
 * Component that handles React error boundaries and displays error feedback UI
 *
 * @param props.relative - Whether to show relative positioning (default: true)
 * @param props.children - Child components to render
 *
 * @returns Error boundary component that catches errors and displays feedback UI
 */
class ErrorBoundaryFeedback extends React.Component<ErrorBoundaryFeedbackProps, ErrorBoundaryFeedbackState> {
  constructor(props: ErrorBoundaryFeedbackProps) {
    super(props);

    this.state = {
      hasError: false,
      errorMessage: null,
      errorLog: null,
    };
  }

  componentDidCatch(error: Error, log: { componentStack: string }): void {
    // If the error is due to a failure fetching a dynamically imported module, reload the page
    // This is a workaround for browsers that are configured to discard cache
    // and to keep the cdn cache boosting the performance of the app
    // The issue is happening because the cached signature of the cdn might have changed after a deploy
    if (error.toString().includes('Failed to fetch dynamically imported module')) {
      window.location.reload();
    }

    // Add manual tag when capturing and send to sentry
    withScope((scope) => {
      scope.setTag('manual', 'true');
      captureException(error);
    });

    this.setState({
      hasError: true,
      errorMessage: error.toString(),
      errorLog: log.componentStack,
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        <ReportError
          relative={this.props.relative}
          errorMessage={this.state.errorMessage}
          errorLog={this.state.errorLog}
        />
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundaryFeedback;
