import { hooks, mui, React, ts, ui } from '_core';

import { DraftParams, Params } from './types';

type FormProps = {
  params: Params;
  setParams: (_p: DraftParams) => void;
  frequency: ts.enums.FREQUENCY_ENUM;
  startDate: string;
  endDate: string;
};

const Form: React.FC<FormProps> = ({ params, setParams }) => {
  const uiStyles = hooks.useUiStyles();
  const [basket, setBasket] = React.useState(params.basket_ids?.[0]);

  React.useEffect(() => {
    const data = { basket_ids: [basket] };
    setParams({ ...params, ...data });
  }, [basket]);

  return (
    <mui.core.Box sx={uiStyles.widgetEditMode}>
      <mui.core.Box mt={2}>
        <ui.ResourceAutocomplete<ts.types.universe.UniverseExpanded>
          value={basket || null}
          setValue={(id) => setBasket(id)}
          autocompleteProps={{
            disableClearable: true,
          }}
          type={ts.enums.RESOURCES_TYPES_ENUM.BASKET}
          inputProps={{
            fullWidth: true,
            label: 'Benchmark',
            variant: 'outlined',
            size: 'small',
          }}
        />
      </mui.core.Box>
    </mui.core.Box>
  );
};

export default Form;
