import { _, helpers,ts } from '_core';

import { DraftParams, Params } from './types';
import { getBenchmarkTitle } from '../../common/utils/get-name';

export const validate = (params: DraftParams) => {
  const rhErrors = helpers.validations.validSelectedReturnHorizons(params?.return_horizons);
  if (!_.isEmpty(rhErrors)) return rhErrors;
  if (!helpers.validations.validBenchmark(params.benchmark)) return ['Benchmark is invalid.'];
  return [];
};

export const getName = (
  params: Params,
  _context: ts.types.signalSet.SignalSetAnalysisContext,
  resources: ts.StoreState['resources']
) => getBenchmarkTitle(params.benchmark, 'Cumulative Returns', resources.universes);
