/**
 * Converts an object into a sorted array of key-value pairs
 * @param obj - Object to convert to array
 * @returns Array of key-value pairs sorted by key
 */
const convertObjToArray = (obj: Record<string, any>): unknown[] => {
  const arr = Object.keys(obj).map((key) => [key, obj[key]]);
  return arr.sort((a, b) => a[0].localeCompare(b[0]));
};

/**
 * Generates a hash value from a string using DJB2 algorithm
 * @param text - String to hash
 * @returns 32-bit unsigned integer hash value
 */
const hash = (text: string): number => {
  let hash = 5381;
  let index = text.length;
  while (index) {
    hash = (hash * 33) ^ text.charCodeAt(--index);
  }
  return hash >>> 0;
};

/**
 * Creates a unique signature for an object by converting it to a sorted array,
 * stringifying it, and generating a hash
 * @param obj - Object to generate signature for
 * @returns String representation of the hash value
 */
const createSignature = (obj: Record<string, unknown>): string => {
  const s = JSON.stringify(convertObjToArray(obj));
  return hash(s).toString();
};

export default createSignature;
