import React from 'react';

import ResourceActions from './resource-actions';
import ContextSingleton from '../../__singletons__/context-singleton';
import * as enums from '../../enums';
import * as hooks from '../../hooks';
import { mui } from '../../libs';
import * as types from '../../types';
import ArchivedDepsCmp from '../archived-deps';
import LabelsAutocomplete from '../labels-autocomplete';
import PageTitle from '../page-title';
import ResourceIcon from '../resource-icon';

type ResourceHeaderProps = {
  children: React.ReactNode;
  extraElements?: React.ReactNode;
  resourceType?: enums.UI_RESOURCE_TYPE_ENUM;
  resource?: types.common.ResourceDraft;
  updateResource?: (_d: types.common.ResourceDraft) => Promise<void>;
  deps?: types.common.ArchivedDeps;
  openTour?: () => void;
  publish?: React.FunctionComponent;
  share?: React.FunctionComponent;
  labels?: boolean;
  hideActions?: boolean;
};

/**
 * Component that displays a header for a resource with various actions and metadata
 *
 * @requires Features:
 * - publish: Feature flag for publishing functionality
 *
 * @param children - React children to render in the header
 * @param extraElements - Optional additional elements to render
 * @param resourceType - Type of the resource being displayed
 * @param resource - The resource object containing metadata
 * @param updateResource - Callback to update the resource
 * @param deps - Optional archived dependencies
 * @param openTour - Optional callback to open tour
 * @param publish - Optional publish component
 * @param share - Optional share component
 * @param labels - Whether to show labels
 * @param hideActions - Whether to hide action buttons
 *
 * @returns Rendered header component with resource metadata and actions
 */
const ResourceHeader: React.FC<ResourceHeaderProps> = ({
  extraElements,
  children,
  updateResource,
  resourceType,
  resource,
  openTour,
  deps,
  publish,
  labels,
  share,
  hideActions,
}): React.ReactElement => {
  const uiStyles = hooks.useUiStyles();
  const features = ContextSingleton.getInstance().features;

  const archivable = resource.organization_id != 0 && resourceType != enums.UI_RESOURCE_TYPE_ENUM.GENERAL_ANALYSIS;

  return (
    <>
      {resource.name && <PageTitle title={resource.name} />}
      <mui.core.Grid
        spacing={1}
        container
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          padding: '0.25rem 0.75rem 0.25rem',
          alignItems: 'center',
          '@media print': {
            mb: 1,
          },
        }}
      >
        <mui.core.Grid item>
          <mui.core.Box id={`${resourceType}-icon`} pt={1}>
            <ResourceIcon resource={resourceType} hasTooltip />
          </mui.core.Box>
        </mui.core.Grid>
        <mui.core.Grid
          item
          xs
          zeroMinWidth
          sx={{
            position: 'relative',
            '&:after': {
              content: '" "',
              background: 'linear-gradient(0.25turn, rgba(255,255,255,0), #fff)',
              width: '50px',
              height: '100%',
              position: 'absolute',
              right: 0,
              pointerEvents: 'none',
              top: 0,
            },
          }}
        >
          <mui.core.Box sx={{ overflow: 'auto', height: '100%' }}>
            <mui.core.Grid container style={{ flexWrap: 'nowrap' }}>
              <mui.core.Grid item zeroMinWidth>
                {children}
              </mui.core.Grid>
            </mui.core.Grid>
          </mui.core.Box>
        </mui.core.Grid>

        {deps && (
          <mui.core.Grid item sx={uiStyles.hidePrint}>
            <ArchivedDepsCmp deps={deps} />
          </mui.core.Grid>
        )}
        {extraElements && (
          <mui.core.Box display="flex" alignContent="right" mr={2} sx={uiStyles.hidePrint}>
            {extraElements}
          </mui.core.Box>
        )}

        {!resource.shared && resource.organization_id != 0 && labels && (
          <mui.core.Hidden smDown>
            <mui.core.Grid item sx={{ ...uiStyles.hidePrint, minWidth: '280px' }}>
              <LabelsAutocomplete
                selectedLabelIds={resource.label_ids}
                submitData={async (ids) => {
                  updateResource({ label_ids: ids });
                }}
              />
            </mui.core.Grid>
          </mui.core.Hidden>
        )}

        {publish && !resource.shared && (
          <mui.core.Grid item sx={uiStyles.hidePrint}>
            {resource.is_deprecated || !features.publish ? (
              <>{resource.is_published && <mui.core.Chip label="Published" color="success" />}</>
            ) : (
              <>
                {resource.is_published ? (
                  <mui.core.Chip size="small" label="Published" color="success" />
                ) : (
                  React.createElement(publish)
                )}
              </>
            )}
          </mui.core.Grid>
        )}

        {resource.is_deprecated && (
          <mui.core.Grid item sx={uiStyles.hidePrint}>
            <mui.core.Chip size="small" label="Archived" />
          </mui.core.Grid>
        )}

        {resource.shared && (
          <mui.core.Grid item>
            <mui.core.Chip size="small" label="Shared" color="primary" />
          </mui.core.Grid>
        )}

        {share && !resource.is_published && !resource.shared && (
          <mui.core.Grid item sx={uiStyles.hidePrint}>
            {React.createElement(share)}
          </mui.core.Grid>
        )}

        {!resource.shared && !hideActions && (
          <ResourceActions
            resource={resource}
            updateResource={updateResource}
            openTour={openTour}
            archivable={archivable}
          />
        )}
      </mui.core.Grid>
    </>
  );
};

ResourceHeader.defaultProps = {
  hideActions: false,
};

export default ResourceHeader;
