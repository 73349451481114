import { ts } from '_core';

import { getName, validate } from './helpers';
import { DraftParams } from './types';

export default [
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.SIGNAL_AVERAGE_BUCKETED_EXPOSURE,
    title: 'Average Signal Exposure by Bucket',
    helpLabel: 'cjncpw0i',
    form: true,
    defaultParams: {
      weights: 'equal',
      histories: ['1 Year', '3 Years', '5 Years', 'Full'],
      file: 'bucketed_average_exposure',
      type: 'exposure',
      ui_transformation: 'AVERAGE',
    } as DraftParams,
    neededFiles: ['bucketed_average_exposure'],
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.SIGNAL],

    getName,
    validate,
  },
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.SIGNAL_AVERAGE_BUCKETED_CORRELATION,
    title: 'Average Correlation by Bucket',
    helpLabel: 'muwz0dch',
    form: true,
    defaultParams: {
      weights: 'equal',
      histories: ['1 Year', '3 Years', '5 Years', 'Full'],
      file: 'bucketed_correlation',
      type: 'correlation',
      ui_transformation: 'AVERAGE',
    } as DraftParams,
    neededFiles: ['bucketed_correlation'],
    // Hide this widget from all analyses
    tags: [],

    getName,
    validate,
  },
] satisfies ts.types.analysis.WidgetConfig[];
