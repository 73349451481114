import { React, ts } from '_core';

import Chart from './chart';
import Form from './form';
import { DraftParams, Params } from './types';

type SignalPerformanceEventStudyProps = {
  id: string;
  readOnly: boolean;
  widgetKey: string;
  mode: ts.enums.CHART_MODE_ENUM;
  params: Params | DraftParams;
  setParams: (_p: DraftParams) => void;
  title: string;

  fullScreen?: boolean;
  context?: ts.types.signal.SignalAnalysisContext;
  loadWidgetData: (_payload: ts.types.widgets.WidgetGetDataParams) => Promise<ts.types.widgets.WidgetGetDataResponse>;
  widgetPaths?: Record<'backtest_time_series_event_study' | 'signal_performance_event_study', string>;
};

const SignalPerformanceEventStudy: React.FC<SignalPerformanceEventStudyProps> = ({
  id,
  readOnly,
  widgetKey,
  loadWidgetData,
  widgetPaths,
  mode,
  params,
  setParams,
  fullScreen,
  title,
}) => {
  const isBasket = params.is_basket || false;

  const renderJobComponent = () => (
    <>
      <Chart
        id={id}
        readOnly={readOnly}
        widgetKey={widgetKey}
        loadWidgetData={loadWidgetData}
        fileEvent={
          isBasket ? widgetPaths['backtest_time_series_event_study'] : widgetPaths['signal_performance_event_study']
        }
        params={params as Params}
        fullScreen={fullScreen}
        title={title}
      />
    </>
  );

  return <>{mode == 'edit' ? <Form params={params} setParams={setParams} /> : renderJobComponent()}</>;
};

export default SignalPerformanceEventStudy;
