import { SEPARATORS_ENUM } from '../enums';
import { _ } from '../libs';
import { DataColumn } from '../types/data-source';

/**
 * Splits a line of text into an array of values based on a separator, handling quoted values
 * @param line - The line of text to split
 * @param separator - The separator character to split on
 * @returns Array of split values preserving quoted content
 */
export const splitLine = (line: string, separator: SEPARATORS_ENUM): string[] => {
  const values: string[] = [];
  let currentValue = '';
  let insideQuotes = false;

  for (let i = 0; i < line.length; i++) {
    const char = line[i];

    if (char === '"') {
      insideQuotes = !insideQuotes;
      currentValue += char;
    } else if (char === separator && !insideQuotes) {
      values.push(currentValue);
      currentValue = '';
    } else {
      currentValue += char;
    }
  }

  values.push(currentValue);
  return values;
};

/**
 * Infers the data type of a single element
 * @param el - The element to infer type for
 * @returns The inferred data type as DataColumn['dtype']
 */
export const inferElement = (el: any): DataColumn['dtype'] => {
  if (el == '0' || el == '1') return 'BOOLEAN';
  if (typeof el == 'string' && (el.toLowerCase() == 'true' || el.toLowerCase() == 'false')) return 'BOOLEAN';

  if (!isNaN(el)) return 'NUMBER';

  const timestamp = Date.parse(el);
  if (!isNaN(timestamp)) return 'DATE';

  return 'STRING';
};

/**
 * Infers the common data type for an array of elements
 * @param arr - Array of elements to infer type for
 * @returns The inferred common data type as DataColumn['dtype']
 */
export const inferArray = (arr: any[]): DataColumn['dtype'] => {
  let arrType: DataColumn['dtype'] = 'STRING';

  for (const el of arr) {
    const localArrType = inferElement(el);

    // If  we have a number, but then we encounter a boolean
    // we need to stay as number
    if (arrType == 'NUMBER' && localArrType == 'BOOLEAN') {
      arrType = 'NUMBER';
    } else arrType = localArrType;

    // If string there can't be a change on the type
    if (arrType == 'STRING' || arrType == 'DATE') break;
  }
  return arrType;
};

type InferTabularType = {
  dtype: DataColumn['dtype'];
  column_name: string;
  name: string;
};

/**
 * Infers column types from tabular string data
 * @param tabularString - String containing tabular data
 * @param separator - Separator character used in the data (default: tab)
 * @param withHeader - Whether the first line contains headers (default: true)
 * @returns Array of column type definitions including name and data type
 */
export const inferTabular = (
  tabularString: string,
  separator: SEPARATORS_ENUM = SEPARATORS_ENUM.TAB,
  withHeader = true
): InferTabularType[] => {
  try {
    const obj: Record<string, any> = {};
    const lines = tabularString.split(/\r?\n/);
    let columnNames: string[];
    if (withHeader) columnNames = splitLine(lines[0], separator);
    else columnNames = splitLine(tabularString, separator).map((_c, idx) => `column_${idx + 1}`);

    lines.forEach((line, lineidx) => {
      if (!(withHeader && lineidx == 0)) {
        splitLine(line, separator).forEach((val, idx) => {
          if (obj[columnNames[idx]]) obj[columnNames[idx]].push(val);
          else obj[columnNames[idx]] = [val];
        });
      }
    });

    return Object.keys(obj).map((key) => ({
      dtype: inferArray(obj[key]),
      column_name: key || 'index',
      name: _.startCase(key) || 'index',
    }));
  } catch {
    return [];
  }
};
