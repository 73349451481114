import { hooks, mui, React, ts, ui } from '_core';

import MoveColumns from 'views/preferences/common/move-columns';

import * as utils from '../data-source-browser-utils';

type EditColumnsProps = {
  columns: {
    columns?: string[];
    frozenColumns?: string[];
    names?: Record<string, string>;
  };
  setColumns: (_v: { columns?: string[]; frozenColumns?: string[]; names?: Record<string, string> }) => void;
  context: ts.types.dataSource.DataSourceBrowserContext;
  dataKeys: string[];
};

const EditColumns: React.FC<EditColumnsProps> = ({ columns, setColumns, context, dataKeys }) => {
  const dataSourceColumns = React.useMemo(() => utils.helpers.getDataSourceColumns(context, dataKeys), [dataKeys]);

  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const uiStyles = hooks.useUiStyles();

  const getColumns = (objKey: 'frozenColumns' | 'columns') => {
    const cols = [] as ts.types.components.dataGrid.ColumnsData;
    const pref = columns;
    // For old definitions, let's filter the ones that are not part of default columns anymore
    if (pref[objKey] || []) {
      (pref[objKey] || []).forEach((key) => {
        const el = dataSourceColumns.find((c) => c.key == key);
        if (el) cols.push(el);
      });
    }
    return cols;
  };

  const [localColumns, setLocalColumns] = React.useState(() => getColumns('columns'));
  const [frozenColumns, setFrozenColumns] = React.useState(() => getColumns('frozenColumns'));
  const [localNames, setLocalNames] = React.useState(columns.names as Record<string, string>);

  const applyColumns = () => {
    setLoading(true);
    setColumns({
      columns: localColumns.map((c) => c.key),
      frozenColumns: frozenColumns.map((c) => c.key),
      names: localNames,
    });
    setLoading(false);

    setOpen(false);
  };

  return (
    <>
      <mui.core.Box display="flex" width="100%">
        <mui.core.Box display="flex" flexGrow={1}></mui.core.Box>
        <mui.core.Button variant="outlined" color="primary" onClick={() => setOpen(true)}>
          Edit Columns
        </mui.core.Button>
      </mui.core.Box>

      <mui.core.Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="md">
        <ui.DialogTitle closeAction={() => setOpen(false)}>Edit Columns</ui.DialogTitle>
        <mui.core.Box display="flex" height="100%" flexDirection="column">
          <mui.core.Box p={3} sx={uiStyles.settingsContainer}>
            <MoveColumns
              frozenColumns={frozenColumns}
              columns={localColumns}
              setColumns={setLocalColumns}
              setFrozenColumns={setFrozenColumns}
              configColumns={dataSourceColumns}
              names={localNames}
              setNames={setLocalNames}
            />
          </mui.core.Box>
          <mui.core.Box sx={uiStyles.settingsFooter}>
            <ui.Button variant="outlined" color="primary" onClick={applyColumns} loading={loading} progressText="Apply">
              Apply
            </ui.Button>
          </mui.core.Box>
        </mui.core.Box>
      </mui.core.Dialog>
    </>
  );
};
export default EditColumns;
