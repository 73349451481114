import { React, ts, ui } from '_core';

type AssetsProps = {
  value?: ts.types.dataSource.ASSETS_VALUE_TYPE;
  setValue: (_d: ts.types.dataSource.ASSETS_VALUE_TYPE) => void;
  date?: string;
};

const Assets: React.FC<AssetsProps> = ({ value, setValue, date }): React.ReactElement => (
  <ui.AssetSelector
    value={value.list}
    setValue={(val) => {
      setValue({
        ...value,
        list: val as string[],
      });
    }}
    dates={{ start: date, end: date }}
    multiple
  />
);

Assets.defaultProps = {
  date: '',
  value: {},
};

export default Assets;
