import { _, mui, React, ts, useSelector } from '_core';

import { getDataTabs, transform } from './helpers';
import ChartWrapper from '../../common/chart-wrapper';
import createLineChart from '../../common/charts/create-line-chart';

type ChartProps = {
  id: string;
  readOnly: boolean;
  widgetKey: string;
  file: string;
  fullScreen: boolean;
  scale: ts.enums.SCALE_ENUM;
  loadWidgetData: (_payload: ts.types.widgets.WidgetGetDataParams) => Promise<ts.types.widgets.WidgetGetDataResponse>;
  title: string;
};

const Chart: React.FC<ChartProps> = ({
  id,
  readOnly,
  widgetKey,
  file,
  fullScreen,
  scale,
  loadWidgetData,
  title,
}): React.ReactElement => {
  const tabs = [
    'industry',
    'active_industry',
    'country',
    'active_country',
    'riskfactor',
    'active_riskfactor',
    'risk',
    'active_risk',
    'signal',
    'active_signal',
    'tcost_constraint',
    'turnover_constraint',
    'gross_leverage_constraint',
    'net_leverage_constraint',
  ];

  const [data, setData] = React.useState<ts.types.widgets.TableData>(null);
  const [error, setError] = React.useState<ts.types.common.ApiError>(null);
  const [showLegend, setShowLegend] = React.useState(false);

  const [displayTabs, setDisplayTabs] = React.useState<string[]>();
  const [selectedTab, setSelectedTab] = React.useState<string>();

  const resources = useSelector((state) => state.resources);

  // Let's use the name count endpoint as it will get the data and
  // prepare it for a linear chart
  const loadData = async () => {
    try {
      const response = await loadWidgetData({
        data: {
          file,
          rename: { build_date: 'date' },
        },
      });
      if (response) {
        const newTabs = getDataTabs(response.data[0], tabs);

        setSelectedTab(newTabs[0]);
        setDisplayTabs(newTabs);
        setData(response.data);
      }
    } catch (err) {
      setError(err as ts.types.common.ApiError);
    }
  };

  React.useEffect(() => {
    if (file) loadData();
    return () => setData(null);
  }, [file]);

  React.useEffect(() => {
    // Create line chart on data and scale changes
    if (!_.isNil(data)) {
      createLineChart({
        id,
        scale,
        data,
        fullScreen,
        setShowLegend,
        percent: false,
        yAxisLabel: getYAxisLabel(selectedTab),
        prepareData: (linearData) => transform(linearData, tabs, resources)[selectedTab],
        exportTitle: title,
      });
    }
  }, [data, selectedTab]);

  const getLabel = (tab: string) => {
    if (tab == 'riskfactor') return 'Risk Factor';
    if (tab == 'active_riskfactor') return 'Active Risk Factor';
    if (tab == 'tcost_constraint') return 'Max TCost';
    if (tab == 'turnover_constraint') return 'Max Turnover';
    if (tab == 'risk') return 'Max Risk';
    if (tab == 'gross_leverage_constraint') return 'Gross';
    if (tab == 'net_leverage_constraint') return 'Net';

    return _.startCase(tab);
  };

  const getYAxisLabel = (tab: string) => {
    if (tab == 'riskfactor') return 'Risk Factor Exposure';
    if (tab == 'active_riskfactor') return 'Active Risk Factor Exposure';
    if (tab == 'tcost_constraint') return 'Transaction cost (bps)';
    if (tab == 'turnover_constraint') return 'Turnover';
    if (tab == 'risk') return 'Risk %';
    if (tab == 'gross_leverage_constraint') return 'Gross Leverage';
    if (tab == 'net_leverage_constraint') return 'Net Leverage';

    return _.startCase(tab) + ' Exposure';
  };

  const getTabs = () => {
    if (displayTabs) {
      return (
        <mui.core.Tabs
          indicatorColor="primary"
          textColor="primary"
          value={selectedTab}
          onChange={(e, newTab) => setSelectedTab(newTab)}
          aria-label="Tabs"
        >
          {displayTabs.map((tab) => (
            <mui.core.Tab key={tab} label={getLabel(tab)} value={tab} />
          ))}
        </mui.core.Tabs>
      );
    }
    return null;
  };

  return (
    <ChartWrapper
      id={id}
      readOnly={readOnly}
      widgetKey={widgetKey}
      data={data}
      showLegend={showLegend}
      error={error}
      tabs={getTabs()}
      fullScreen={fullScreen}
    />
  );
};

export default Chart;
