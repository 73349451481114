import { _, config, ts } from '_core';

export const quantileLabel = (s: number | string, v: number) => {
  if (String(s).toLowerCase() == 'unknown') return 'Unknown';
  const parsedS = parseInt(String(s));
  if (_.isNaN(parsedS)) return s;

  if (parsedS == 1) return 'Q' + parsedS + ' (lowest)';
  if (parsedS == v) return 'Q' + parsedS + ' (highest)';
  return 'Q' + parsedS;
};

export const lagsLabel = (f: ts.enums.FREQUENCY_ENUM, s: string) => {
  let freq = '';
  if (f == 'WEEKLY') freq = s == '1' ? 'week' : 'weeks';
  if (f == 'DAILY') freq = s == '1' ? 'day' : 'days';
  if (f == 'MONTHLY') freq = s == '1' ? 'month' : 'months';
  return s + ' ' + freq;
};

export const prepareLinearBucketedNames = (
  data: ts.types.widgets.LinearData,
  type: ts.enums.BUCKET_TYPES_ENUM,
  value: string | number,
  resources: ts.StoreState['resources']
) => {
  const newData = {} as ts.types.widgets.LinearData;
  Object.keys(data).forEach((key) => {
    const newKey = getBucketName(key, type, value, resources);
    newData[newKey] = data[key];
  });
  return newData;
};

export const prepareBarChartBucketedNames = (
  data: ts.types.widgets.NestedBarData,
  type: ts.enums.BUCKET_TYPES_ENUM,
  value: string | number,
  resources: ts.StoreState['resources'] = undefined as ts.StoreState['resources']
) => {
  data = sortBarChartData(data, type);

  return data.map((row) => {
    const newRow = {} as ts.types.widgets.NestedBarData[0];
    Object.keys(row).forEach((key) => {
      if (key == 'category') newRow[key] = row[key];
      else {
        const newKey = getBucketName(key, type, value, resources);
        newRow[newKey] = row[key];
      }
    });
    return newRow;
  }) as ts.types.widgets.NestedBarData;
};

export const prepareBarChartCountNames = (
  data: ts.types.widgets.CountData,
  type: ts.enums.BUCKET_TYPES_ENUM,
  value: string | number,
  resources: ts.StoreState['resources'] = undefined as ts.StoreState['resources']
) => {
  const newData = {} as ts.types.widgets.CountData;

  Object.keys(data || []).forEach((key) => {
    const row = data[key];

    Object.keys(row).forEach((rKey) => {
      const newKey = getBucketName(rKey, type, value, resources);
      if (!_.has(newData, key)) newData[key] = {};
      newData[key][newKey] = row[rKey];
    });
  });

  return newData;
};

export const sortBarChartData = (data: ts.types.widgets.NestedBarData, type: ts.enums.BUCKET_TYPES_ENUM) => {
  const sortedData = data.map((_d) => ({})) as typeof data;
  const series = Object.keys(data?.[0] ?? {});

  const numbers = series.filter((key) => _.checkIsNumber(key)).sort((a, b) => Number(a) - Number(b));
  const strings = series.filter((key) => !_.checkIsNumber(key)).sort();

  data.forEach((row, idx) => {
    if (type == ts.enums.BUCKET_TYPES_ENUM.QUANTILES)
      numbers.forEach((key) => (sortedData[idx][`${Number(key)}`] = row[key]));
    else if (type == ts.enums.BUCKET_TYPES_ENUM.FIXED_FLOAT)
      numbers.forEach((key) => (sortedData[idx][Number(key).toFixed(4)] = row[key]));
    else numbers.forEach((key) => (sortedData[idx][key] = row[key]));

    strings.forEach((key) => (sortedData[idx][key] = row[key]));
  });

  return sortedData;
};

export const getCountryName = (key: string) => {
  const item = config.countries.find((g) => g.code == key);
  return item ? item.name : key;
};

export const getCurrencyName = (key: string, resources: ts.StoreState['resources']) => {
  const item = resources.currencies.find((g) => g.alpha_code == key);
  return item ? item.name : key;
};

export const getIndustryName = (key: string, value: string, resources: ts.StoreState['resources']) => {
  if (value == 'rbics_l1_id' || value == 'rbics_l1_id_code' || value == 'levelOne') {
    const item = resources.rbics_data.levelOne.find((g) => g.value == key);
    return item ? item.name : key;
  }
  if (value == 'rbics_l2_id' || value == 'rbics_l2_id_code' || value == 'levelTwo') {
    const item = resources.rbics_data.levelTwo.find((g) => g.value == key);
    return item ? item.name : key;
  }
  if (value == 'rbics_l3_id' || value == 'rbics_l3_id_code' || value == 'levelThree') {
    const item = resources.rbics_data.levelThree.find((g) => g.value == key);
    return item ? item.name : key;
  }
  if (value == 'rbics_l4_id' || value == 'rbics_l4_id_code' || value == 'levelFour') {
    const item = resources.rbics_data.levelFour.find((g) => g.value == key);
    return item ? item.name : key;
  }
  return key;
};

export const getBucketName = (
  key: string,
  type: ts.enums.BUCKET_TYPES_ENUM,
  value: string | number,
  resources: ts.StoreState['resources']
) => {
  if (key == 'BENCHMARK') return 'Benchmark';

  if (type == ts.enums.BUCKET_TYPES_ENUM.ASSET_MASTER) {
    if (value == 'quotation_country') return getCountryName(key);
    if (value == 'currency') return getCurrencyName(key, resources);
  }
  if (type == ts.enums.BUCKET_TYPES_ENUM.QUANTILES) return quantileLabel(String(key), value as number);
  if (type == ts.enums.BUCKET_TYPES_ENUM.SIGNAL) return quantileLabel(String(key), value as number);
  if (type == ts.enums.BUCKET_TYPES_ENUM.INDUSTRY) return getIndustryName(String(key), String(value), resources);
  return key;
};
