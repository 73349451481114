import React from 'react';

import { mui } from '../../libs';
import * as types from '../../types';

type TableProps<TValue, TOptions, Multiple> = {
  value: types.components.autocomplete.AutoCompleteValueType<TValue, Multiple, TOptions>;
  setValue: (_v: types.components.autocomplete.AutoCompleteValueType<TValue, Multiple, TOptions>) => void;
  createId?: (_el: TOptions) => string;
  disabled?: boolean;
  fields?: types.components.autocomplete.TableField[];
  metaDataKey?: string;
};

/**
 * Component that displays a table view of selected autocomplete values with deletion capability
 *
 * @param value - Currently selected value(s) to display in table
 * @param setValue - Callback to update selected value(s)
 * @param createId - Function to generate unique ID for each option
 * @param disabled - Whether the table is disabled/read-only
 * @param fields - Column definitions for the table
 * @param metaDataKey - Key to access metadata fields
 *
 * @returns Rendered table component showing selected values
 */
const AsyncTable = <TValue, TOptions, Multiple>(props: TableProps<TValue, TOptions, Multiple>): React.ReactElement => {
  const { value, setValue, fields, createId, disabled, metaDataKey } = props;

  const headers = fields.map((field) => (
    <mui.core.TableCell key={field.key}>
      <strong>{field.title}</strong>
    </mui.core.TableCell>
  ));

  const renderWithMetaDataKey = (obj: any, field: types.components.autocomplete.TableField) => (
    <>
      {obj[metaDataKey as keyof any]?.[field.key]}
      {field.key == 'ticker' &&
        (!obj[metaDataKey as keyof any] || !obj[metaDataKey as keyof any]?.ticker) &&
        (obj.fid || obj.assets)}
    </>
  );

  const renderWithoutMetaDataKey = (obj: any, field: types.components.autocomplete.TableField) => (
    <>
      {obj?.[field.key as keyof any]}
      {field.key == 'ticker' && (!obj || !obj?.ticker) && (obj.fid || obj.assets)}
    </>
  );

  const items = (value as TValue[]).map((obj: any) => (
    <mui.core.TableRow key={createId(obj)}>
      {fields.map((field) => (
        <mui.core.TableCell key={field.key}>
          {metaDataKey != '' ? renderWithMetaDataKey(obj, field) : renderWithoutMetaDataKey(obj, field)}
        </mui.core.TableCell>
      ))}
      {!disabled && (
        <mui.core.TableCell
          style={{
            textAlign: 'right',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
            justifyContent: 'center',
          }}
        >
          <mui.icons.Close
            sx={{
              color: 'lightgrey',
              cursor: 'pointer',
              '&:hover': {
                color: 'inherit',
              },
            }}
            onClick={() =>
              setValue(
                (value as TValue[]).filter(
                  (val: any) => createId(val) != createId(obj)
                ) as types.components.autocomplete.AutoCompleteValueType<TValue, Multiple, TOptions>
              )
            }
          />
        </mui.core.TableCell>
      )}
    </mui.core.TableRow>
  ));

  const data = (
    <>
      {items.length > 0 && (
        <mui.core.TableContainer>
          <mui.core.Table size="small">
            <mui.core.TableHead>
              <mui.core.TableRow>
                {headers}
                <mui.core.TableCell />
              </mui.core.TableRow>
            </mui.core.TableHead>
            <mui.core.TableBody>{items}</mui.core.TableBody>
          </mui.core.Table>
        </mui.core.TableContainer>
      )}
    </>
  );

  return (
    <mui.core.Box color={disabled ? 'text.disabled' : 'null'} style={{ maxWidth: '600px', marginTop: 10 }}>
      {data}
    </mui.core.Box>
  );
};

export default AsyncTable;
