import { endpoint } from './config';
import { $ } from '../libs';
import { User, UserDraft } from '../types/user';

export const getMe = (): JQuery.jqXHR<User> =>
  $.ajax({
    method: 'GET',
    url: `${endpoint}user/me`,
  } as JQueryAjaxSettings);

export const updateMe = (data: UserDraft): JQuery.jqXHR<User> =>
  $.ajax({
    method: 'PUT',
    url: `${endpoint}user/me`,
    json: data,
  } as JQueryAjaxSettings);

export const getAuthToken = (): JQuery.jqXHR<{ 'X-AuthToken': string }> =>
  $.ajax({
    method: 'GET',
    url: `${endpoint}user/authtoken`,
  });

export const resetAuthToken = (): JQuery.jqXHR<{ 'X-AuthToken': string }> =>
  $.ajax({
    method: 'PUT',
    url: `${endpoint}user/authtoken`,
  });
