import { mui, React, ui } from '_core';

import { columns } from './config';
import { FixedCostContext } from './fixed-costs-context';

const FixedCostsTable: React.FC = (): React.ReactElement => {
  const fixedCostsContext = React.useContext(FixedCostContext);
  const { rows } = fixedCostsContext;
  const [, setContainerHeight] = React.useState(undefined);
  const container = React.createRef<HTMLDivElement>();

  React.useEffect(() => {
    if (container.current) {
      setContainerHeight(container.current.offsetHeight);
    }
  }, [rows]);

  return (
    <mui.core.Box
      mb={3}
      id="fixed-costs-table"
      style={{ height: '100%', width: '100%' }}
      {...({ ref: container } as any)}
    >
      <ui.FinDataGrid
        rows={rows.rows || []}
        columns={columns}
        autoHeight
        disableColumnMenu
        slots={{
          toolbar: null,
        }}
      />
    </mui.core.Box>
  );
};

export default FixedCostsTable;
