import { _, actions, api, hooks, mui, React, ts, ui, useDispatch } from '_core';

import { PreferencesContext } from 'views/preferences/preferences-context';

import PreferencesTable from './preferences-table';

const DefaultPreference: Partial<ts.types.userPreference.UserPreference> = {
  title: 'Default',
  key: ts.enums.PREFERENCES_KEY_ENUM.SIGNAL_DATESET_FORM,
  is_latest: false,
  is_preferred: true,
  definition: {},
  type: ts.types.userPreference.UserPreferenceTypes.DATASETPARAMS,
};

const SignalDatasetFormIndex = () => {
  const uiStyles = hooks.useUiStyles();
  const preferencesContext = React.useContext(PreferencesContext);
  const { closePreferences, setAlertError } = preferencesContext;

  const [preferences, setPreferences] = React.useState<ts.types.userPreference.UserPreference[]>([]);
  const [deletedPreferencesIds, setDeletedPreferencesIds] = React.useState<number[]>([]);

  const [loading, setLoading] = React.useState(true);
  const [loadingSave, setLoadingSave] = React.useState(false);

  const dispatch = useDispatch();

  React.useEffect(() => {
    const loadPreferences = async () => {
      try {
        setLoading(true);
        const resp = await api.user_preferences.search({
          query: ['key', '=', 'signalDatasetForm'],
        });

        // If there is no preference, let's create the default
        if (_.isEmpty(resp.data)) {
          const respNew = await api.user_preferences.create(DefaultPreference);
          setPreferences([respNew.data]);
        } else {
          setPreferences(resp.data);
        }
      } catch {
        setAlertError('Unable to load preferences.');
      }
      setLoading(false);
    };

    loadPreferences();
  }, []);

  const handleSave = async () => {
    try {
      setLoadingSave(true);
      const promises = [] as any[];
      preferences.forEach((p) => promises.push(api.user_preferences.update(p.id, { ...p })));

      const deletedPromises = [] as any[];
      deletedPreferencesIds.forEach((dP) => deletedPromises.push(api.user_preferences.remove(dP)));

      const resp = await Promise.all(promises);
      await Promise.all(deletedPromises);

      const updatedPrefs = resp.map((r) => r.data);

      setPreferences(
        preferences.map((p) => {
          if (updatedPrefs.map((x) => x.id).includes(p.id)) return updatedPrefs.find((x) => x.id == p.id);
          return p;
        })
      );

      if (preferences?.[0].id)
        dispatch(
          actions.ui.setPreferencesLastUpdated({
            preferenceKey: ts.enums.PREFERENCES_KEY_ENUM.SIGNAL_DATESET_FORM,
            preferenceView: preferences[0].id,
          })
        );

      setLoadingSave(false);
    } catch (err) {
      setAlertError('Unable to save preference.');
      setLoadingSave(false);
    }
  };

  const handleSaveAndClose = async () => {
    await handleSave();
    closePreferences();
  };

  if (loading) return <ui.CenteredLoader />;

  return (
    <mui.core.Box p={7} display="flex" flexDirection="column" height="100%">
      <PreferencesTable
        localPreferences={preferences}
        setLocalPreferences={setPreferences}
        deletedPreferencesIds={deletedPreferencesIds}
        setDeletedPreferencesIds={setDeletedPreferencesIds}
      />

      <mui.core.Box display="flex" sx={uiStyles.bottomBarSticky} style={{ background: '#fff' }}>
        <mui.core.Box mr={2}>
          <ui.Button
            variant="contained"
            color="primary"
            onClick={handleSave}
            loading={loadingSave}
            disabled={loadingSave}
          >
            Save
          </ui.Button>
        </mui.core.Box>
        <ui.Button
          variant="outlined"
          color="primary"
          onClick={handleSaveAndClose}
          loading={loadingSave}
          disabled={loadingSave}
        >
          Save and close
        </ui.Button>
      </mui.core.Box>
    </mui.core.Box>
  );
};

export default SignalDatasetFormIndex;
