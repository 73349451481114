import { React, ts } from '_core';

import Chart from './chart';
import Form from './form';
import { DraftParams, Params } from './types';

type SignalPreviewSelectedAssetsProps = {
  id: string;
  mode: ts.enums.CHART_MODE_ENUM;
  params: Params | DraftParams;
  setParams: (_p: DraftParams) => void;

  loadWidgetData: (_payload: ts.types.widgets.WidgetGetDataParams) => Promise<ts.types.widgets.WidgetGetDataResponse>;
  widgetPaths?: Record<'main_data', string>;
  goFullScreen?: (_q: string) => void;
  fullScreen?: boolean;

  context?: ts.types.signal.SignalAnalysisContext;
  queryParams?: { initial?: string };
};

const SignalPreviewSelectedAssets: React.FC<SignalPreviewSelectedAssetsProps> = ({
  id,
  loadWidgetData,
  widgetPaths,
  mode,
  context,
  params,
  setParams,
  fullScreen,
  goFullScreen,
  queryParams,
}) => {
  const renderJobComponent = () => (
    <>
      <Chart
        id={id}
        loadWidgetData={loadWidgetData}
        file={widgetPaths['main_data']}
        fullScreen={fullScreen}
        context={context}
        queryParams={queryParams}
        goFullScreen={goFullScreen}
        params={params as Params}
        setParams={setParams}
      />
    </>
  );

  return (
    <>
      {mode == 'edit' ? (
        <Form
          params={params}
          setParams={setParams}
          contextStartDate={context?.start_date}
          contextEndDate={context?.end_date}
          universeId={context?.universe_id}
        />
      ) : (
        renderJobComponent()
      )}
    </>
  );
};

export default SignalPreviewSelectedAssets;
