import { _, config, mui, React, ts, ui, useSelector } from '_core';

import BacktestSetForm from 'views/backtest-sets/backtest-set-form';
import PortfolioForm from 'views/portfolios/portfolio-form';
import SignalSetForm from 'views/signal-sets/signal-set-form';

import { addMenuOptions } from './config';
import BacktestForm from '../backtests/backtest-form';
import BasketForm from '../baskets/basket-form';
import DataSourceForm from '../data-sources/data-source-form';
import GeneralAnalysisForm from '../general-analyses/general-analysis-form';
import PipelineForm from '../pipelines/pipeline-form';
import RiskModelForm from '../risk-models/risk-model-form';
import SignalForm from '../signals/signal-form';
import StrategyForm from '../strategies/strategy-form';
import TimeSeriesForm from '../time-series/time-series-form';
import UniverseForm from '../universes/universe-form';
import WorkspaceForm from '../workspaces/workspace-form';

const AddMenu = (): React.ReactElement => {
  const theme = mui.styles.useTheme() as mui.core.Theme;

  const [open, setOpen] = React.useState<string>(null);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const resources = useSelector((state) => state.resources);

  const currentTab = useSelector((state) => state.ui.currentTab);

  const addMenu = React.useMemo(() => {
    let localAddMenu = [...addMenuOptions];

    if (!config.features.signal_set)
      localAddMenu = localAddMenu.filter((val) => val.type != ts.enums.UI_RESOURCE_TYPE_ENUM.SIGNAL_SET);

    if (!config.features.custom_data_source)
      localAddMenu = localAddMenu.filter((val) => val.type != ts.enums.UI_RESOURCE_TYPE_ENUM.DATA_SOURCE);

    if (!config.features.custom_risk_model)
      localAddMenu = localAddMenu.filter((val) => val.type != ts.enums.UI_RESOURCE_TYPE_ENUM.RISK_MODEL);

    if (!config.features.alpha_model)
      localAddMenu = localAddMenu.filter((val) => val.type != ts.enums.UI_RESOURCE_TYPE_ENUM.ALPHA_MODEL);

    if (!config.features.basket)
      localAddMenu = localAddMenu.filter((val) => val.type != ts.enums.UI_RESOURCE_TYPE_ENUM.BASKET);

    if (!config.features.backtest || !config.features.alpha_model)
      localAddMenu = localAddMenu.filter((val) => val.type != ts.enums.UI_RESOURCE_TYPE_ENUM.BACKTEST);

    if (!config.features.backtest || !config.features.alpha_model || !config.features.backtest_set)
      localAddMenu = localAddMenu.filter((val) => val.type != ts.enums.UI_RESOURCE_TYPE_ENUM.BACKTEST_SET);

    if (!config.features.time_series)
      localAddMenu = localAddMenu.filter((val) => val.type != ts.enums.UI_RESOURCE_TYPE_ENUM.TIME_SERIES);

    if (!config.features.production_mode)
      localAddMenu = localAddMenu.filter((val) => val.type != ts.enums.UI_RESOURCE_TYPE_ENUM.STRATEGY);

    if (!config.features.production_mode)
      localAddMenu = localAddMenu.filter((val) => val.type != ts.enums.UI_RESOURCE_TYPE_ENUM.PORTFOLIO);

    if (_.isEmpty(resources.strategies))
      localAddMenu = localAddMenu.filter((val) => val.type != ts.enums.UI_RESOURCE_TYPE_ENUM.PORTFOLIO);

    if (currentTab == 'production') return localAddMenu.filter((val) => config.productionResources.includes(val.type));

    return localAddMenu.filter((val) => config.researchResources.includes(val.type));
  }, [currentTab, config.features, resources]);

  return (
    <>
      {open === ts.enums.UI_RESOURCE_TYPE_ENUM.SIGNAL && <SignalForm open setOpen={() => setOpen(null)} />}

      {open === ts.enums.UI_RESOURCE_TYPE_ENUM.ALPHA_MODEL && (
        <SignalForm type={ts.enums.SIGNAL_TYPE_ENUM.ALPHA_MODEL} open setOpen={() => setOpen(null)} />
      )}

      {open === ts.enums.UI_RESOURCE_TYPE_ENUM.BASKET && <BasketForm open setOpen={() => setOpen(null)} />}

      {open === ts.enums.UI_RESOURCE_TYPE_ENUM.WORKSPACE && <WorkspaceForm open setOpen={() => setOpen(null)} />}

      {open === ts.enums.UI_RESOURCE_TYPE_ENUM.UNIVERSE && <UniverseForm open setOpen={() => setOpen(null)} />}

      {open === ts.enums.UI_RESOURCE_TYPE_ENUM.BACKTEST && <BacktestForm open setOpen={() => setOpen(null)} />}

      {open === ts.enums.UI_RESOURCE_TYPE_ENUM.PIPELINE && <PipelineForm open setOpen={() => setOpen(null)} />}

      {open === ts.enums.UI_RESOURCE_TYPE_ENUM.RISK_MODEL && <RiskModelForm open setOpen={() => setOpen(null)} />}

      {open === ts.enums.UI_RESOURCE_TYPE_ENUM.DATA_SOURCE && <DataSourceForm open setOpen={() => setOpen(null)} />}

      {open === ts.enums.UI_RESOURCE_TYPE_ENUM.GENERAL_ANALYSIS && (
        <GeneralAnalysisForm open setOpen={() => setOpen(null)} />
      )}

      {open === ts.enums.UI_RESOURCE_TYPE_ENUM.SIGNAL_SET && <SignalSetForm open setOpen={() => setOpen(null)} />}

      {open === ts.enums.UI_RESOURCE_TYPE_ENUM.BACKTEST_SET && <BacktestSetForm open setOpen={() => setOpen(null)} />}

      {open === ts.enums.UI_RESOURCE_TYPE_ENUM.TIME_SERIES && <TimeSeriesForm open setOpen={() => setOpen(null)} />}

      {open === ts.enums.UI_RESOURCE_TYPE_ENUM.STRATEGY && <StrategyForm open setOpen={() => setOpen(null)} />}

      {open === ts.enums.UI_RESOURCE_TYPE_ENUM.PORTFOLIO && <PortfolioForm open setOpen={() => setOpen(null)} />}

      <mui.core.Box id="create-new-button">
        <mui.core.Tooltip title="New Resource" arrow>
          <mui.core.IconButton
            aria-controls="add-menu"
            aria-haspopup="true"
            onClick={(e) => setAnchorEl(e.currentTarget)}
            sx={{
              minWidth: '0',
              background: '#E1925D !important',
              height: '30.5px',
              width: '30.5px',
              borderRadius: '4px',
            }}
          >
            <mui.icons.Add sx={{ color: '#fff' }} />
          </mui.core.IconButton>
        </mui.core.Tooltip>
      </mui.core.Box>
      <mui.core.Menu
        id="add-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <mui.core.Box sx={{ minWidth: 180, p: 1 }}>
          <mui.core.MenuItem disabled>Create New</mui.core.MenuItem>
          {addMenu.map((menuItem) => (
            <div key={menuItem.type}>
              <mui.core.MenuItem
                key={menuItem.name}
                onClick={() =>
                  menuItem.onClick((resourceName) => {
                    setOpen(resourceName);
                    setAnchorEl(null);
                  })
                }
                disabled={menuItem.disabled}
                sx={{ borderRadius: theme.spacing(1) }}
              >
                <mui.core.Box pr={2} display="flex" alignItems="center">
                  <ui.ResourceIcon resource={menuItem.type} />
                </mui.core.Box>
                <mui.core.ListItemText>{menuItem.name}</mui.core.ListItemText>
              </mui.core.MenuItem>
              {menuItem.sectionEnd && <mui.core.Divider />}
            </div>
          ))}
        </mui.core.Box>
      </mui.core.Menu>
    </>
  );
};

export default AddMenu;
