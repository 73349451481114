import { ts } from '_core';

export const transform = (
  data: ts.types.widgets.TableData,
  hasBenchmark: boolean,
  isBasket: boolean
): ts.types.widgets.TableData => {
  return data.map((row) => {
    if (hasBenchmark)
      return {
        end_date: row['end_date'],
        [isBasket ? 'Basket Returns' : 'Portfolio Returns']: row['return'],
        [isBasket ? 'Target Returns' : 'Benchmark Returns']: row['benchmark_returns'],
      };
    return {
      end_date: row['end_date'],
      [isBasket ? 'Basket Returns' : 'Portfolio Returns']: row['return'],
    };
  });
};
