import { _, api, helpers, ts } from '_core';

export const getJobsApi = (userId: number) =>
  api.jobs.search({
    limit: 50,
    offset: 0,
    query: [
      '$and',
      ['type', '=', 'master_job'],
      [
        'item_type',
        'IN',
        [
          'backtest_analysis',
          'backtest_set_analysis',
          'basket_analysis',
          'general_analysis',
          'risk_model_analysis',
          'signal_analysis',
          'signal_set_analysis',
          'time_series_analysis',
          'universe_analysis',
        ],
      ],
      ['created_by_id', '=', userId],
    ],
    include: 'started_at,completed_at,error,item_id_ref,item_type,progress,warnings',
    order_by: [{ column: 'created_at', sort: 'desc' }],
  });

export const consolidateJobs = async (
  jobsList: ts.types.job.DraftJob[],
  resources: ts.StoreState['resources']
) => {
  const uniqJobs = _.uniqBy(jobsList, (job) => job.item_type + job.item_id_ref);
  // get analysis info
  const combinedQuery = {
    backtest_analyses: { get: [] as { id: number; include?: string }[] },
    backtest_set_analyses: { get: [] as { id: number; include?: string }[] },
    basket_analyses: { get: [] as { id: number; include?: string }[] },
    general_analyses: { get: [] as { id: number; include?: string }[] },
    risk_model_analyses: { get: [] as { id: number; include?: string }[] },
    signal_analyses: { get: [] as { id: number; include?: string }[] },
    signal_set_analyses: { get: [] as { id: number; include?: string }[] },
    time_series_analyses: { get: [] as { id: number; include?: string }[] },
    universe_analyses: { get: [] as { id: number; include?: string }[] },
  };

  uniqJobs.forEach((job) => {
    const table = helpers.pluralize.plural(job.item_type);
    let include = 'id,name';
    if (job.item_type == 'backtest_analysis') include = 'id,name,backtest_id';
    if (job.item_type == 'backtest_set_analysis') include = 'id,name,backtest_set_id';
    if (job.item_type == 'basket_analysis') include = 'id,name,basket_id';
    if (job.item_type == 'risk_model_analysis') include = 'id,name,risk_model_id';
    if (job.item_type == 'signal_analysis') include = 'id,name,signal_dataset(id,signal_id,frequency,universe_id)';
    if (job.item_type == 'signal_set_analysis') include = 'id,name,signal_set_id';
    if (job.item_type == 'time_series_analysis') include = 'id,name,time_series_id';
    if (job.item_type == 'universe_analysis') include = 'id,name,universe_id';

    combinedQuery[table as keyof typeof combinedQuery]?.get?.push({
      id: Number(job.item_id_ref),
      include,
    });
  });

  const combinedRes = await api.combined.run(combinedQuery);

  const jobsFinal = uniqJobs.map((job) => {
    let table = helpers.pluralize.plural(job.item_type);
    if (job.item_type == 'signal_preview_analysis') {
      table = 'signal_datasets';
    }

    let analysis = combinedRes[table].get.find(
      (req: { data: { id: number } }) => req?.data?.id == Number(job.item_id_ref)
    )?.data;

    if (table == 'universe_analyses') {
      analysis = {
        ...analysis,
        resource: resources.universes.find((u) => u.id == analysis?.universe_id),
      };
    }
    if (table == 'signal_analyses') {
      analysis = {
        ...analysis,
        resource: resources.signals.find((u) => u.id == analysis?.signal_dataset?.signal_id),
      };
    }
    if (table == 'signal_set_analyses') {
      analysis = {
        ...analysis,
        resource: resources.signal_sets.find((ss) => ss.id == analysis?.signal_set_id),
      };
    }
    if (table == 'backtest_analyses') {
      analysis = {
        ...analysis,
        resource: resources.backtests.find((u) => u.id == analysis?.backtest_id),
      };
    }
    if (table == 'backtest_set_analyses') {
      analysis = {
        ...analysis,
        resource: resources.backtest_sets.find((u) => u.id == analysis?.backtest_set_id),
      };
    }
    if (table == 'general_analyses') {
      analysis = {
        ...analysis,
        resource: resources.general_analyses.find((u) => u.id == analysis?.general_analysis_id),
      };
    }
    if (table == 'risk_model_analyses') {
      analysis = {
        ...analysis,
        resource: resources.risk_models.find((r) => r.id == analysis?.risk_model_id),
      };
    }
    if (table == 'basket_analyses') {
      analysis = {
        ...analysis,
        resource: resources.baskets.find((b) => b.id == analysis?.basket_id),
      };
    }
    if (table == 'time_series_analyses') {
      analysis = {
        ...analysis,
        resource: resources.time_series.find((ts) => ts.id == analysis?.time_series_id),
      };
    }

    return { ...job, analysis };
  });

  return jobsFinal.filter((job) => !_.isEmpty(job.analysis?.resource));
};
