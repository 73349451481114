import { mui, React, ts, ui,useSelector } from '_core';

import OrganizationZeroFields from './organization-zero-fields';
import DataSourceInferData from '../data-source-infer-data';

type DataSourceFormSchemaProps = {
  params: ts.types.dataSource.DataSourceFormParamsDraft;
  setParams: (_p: ts.types.dataSource.DataSourceFormParamsDraft) => void;
};

const DataSourceFormSchema: React.FC<DataSourceFormSchemaProps> = ({ params, setParams }): React.ReactElement => {
  const organization = useSelector((state) => state.auth.organization);

  const dataSetup = () => (
    <>
      <mui.core.FormControlLabel
        control={
          <mui.core.Switch
            size="small"
            checked={params.hasAssets}
            color="primary"
            onChange={() => {
              const newVal = !params.hasAssets;
              let hasFID = params.hasFID;
              let isIndex = params.is_index;

              if (!newVal) {
                hasFID = false;
                isIndex = false;
              }

              setParams({
                ...params,
                hasAssets: newVal,
                hasFID: hasFID,
                is_index: isIndex,
              });
            }}
          />
        }
        label="Has assets"
      />

      {params.hasAssets && (
        <mui.core.FormControlLabel
          control={
            <mui.core.Switch
              size="small"
              checked={params.is_index}
              color="primary"
              onChange={() => {
                const indexWeight = params.index_weight;
                setParams({
                  ...params,
                  is_index: !params.is_index,
                  index_weight: params.is_index ? undefined : indexWeight,
                });
              }}
            />
          }
          label="Is index"
        />
      )}

      {organization.id == 0 && <OrganizationZeroFields params={params} setParams={setParams} />}
    </>
  );

  return (
    <>
      <ui.Card title="Setup Data">{dataSetup()}</ui.Card>
      <ui.Card title="Setup Rows">
        <DataSourceInferData params={params} setParams={setParams} />
      </ui.Card>
    </>
  );
};

export default DataSourceFormSchema;
