import { helpers, ts } from '_core';

export const getNewPipeline = (name: string, handle: string, template: ts.types.pipeline.Pipeline = null) => {
  const nullDefinition: ts.types.pipeline.Definition = {
    filters: [],
  };

  // Check if we will create the pipeline with a premade definition
  const newPipeline: ts.types.pipeline.PipelineDraft = {
    name,
    handle,
    definition: nullDefinition,
    is_valid: false,
    signature: '',
  };

  if (template && template.id) {
    newPipeline.definition = template.definition;
    newPipeline.signature = template.signature;
    newPipeline.is_valid = template.is_valid;
    newPipeline.label_ids = template.label_ids;
  }

  return newPipeline;
};

export const getArchivedDeps = (
  pipeline: ts.types.pipeline.Pipeline,
  resources: ts.StoreState['resources']
): ts.types.common.ArchivedDeps => {
  const deps = helpers.resourceGraph.getDeps(
    { ...pipeline, resourceType: ts.enums.RESOURCES_TYPES_ENUM.PIPELINE },
    resources
  );
  return helpers.resourceGraph.getArchiveDeps(deps);
};

export const getUnpublishedDeps = (
  pipeline: ts.types.pipeline.Pipeline,
  resources: ts.StoreState['resources']
): ts.types.common.ResourceDeps[] => {
  return helpers.resourceGraph
    .getDeps({ ...pipeline, resourceType: ts.enums.RESOURCES_TYPES_ENUM.PIPELINE }, resources)
    .filter((d) => !d.is_published);
};

export const getUnsharedDeps = (
  pipeline: ts.types.pipeline.Pipeline,
  resources: ts.StoreState['resources'],
  sharedWith?: number[]
): ts.types.common.ResourceDeps[] => {
  if (!sharedWith) sharedWith = pipeline.shared_with || [];

  return helpers.resourceGraph
    .getDeps({ ...pipeline, resourceType: ts.enums.RESOURCES_TYPES_ENUM.PIPELINE }, resources)
    .filter((d) => !helpers.resourcesUtils.isShared(d, sharedWith));
};
