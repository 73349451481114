import { _, React, ts, useSelector } from '_core';

import ChartWrapper from '../../common/chart-wrapper';
import createLineChart from '../../common/charts/create-line-chart';
import { getSignalsObj, mapName } from '../../common/utils/signal-set-utils';

type ChartProps = {
  id: string;
  readOnly: boolean;
  widgetKey: string;
  loadWidgetData: (_payload: ts.types.widgets.WidgetGetDataParams) => Promise<ts.types.widgets.WidgetGetDataResponse>;
  file: string;
  fullScreen: boolean;
  context: ts.types.signalSet.SignalSetAnalysisContext;
  title: string;
  subtitle?: string;
  setExternalEmbed: (_json: ts.types.analysis.EmbedChartData) => void;
};

const Chart: React.FC<ChartProps> = ({
  id,
  readOnly,
  widgetKey,
  loadWidgetData,
  file,
  fullScreen,
  context,
  title,
  subtitle,
  setExternalEmbed,
}) => {
  const [data, setData] = React.useState<ts.types.widgets.TableData>(null);
  const [error, setError] = React.useState<ts.types.common.ApiError>(null);
  const [showLegend, setShowLegend] = React.useState(false);

  const resources = useSelector((state) => state.resources);
  const signalsObj = React.useMemo(() => getSignalsObj(context), [context]);

  const prepareData = (data: ts.types.widgets.LinearData) => {
    const newData = {} as ts.types.widgets.LinearData;

    Object.keys(data).forEach((key) => {
      if (key != 'period_start') {
        newData[mapName(key, resources, signalsObj)] = data[key];
      }
    });

    return newData;
  };

  const loadData = async () => {
    try {
      const response = await loadWidgetData({
        data: {
          file: file,
        },
      });

      if (response) {
        setData(response.data);
      }
    } catch (err) {
      setError(err as ts.types.common.ApiError);
    }
  };

  React.useEffect(() => {
    if (file) loadData();
    return () => setData(null);
  }, [file]);

  React.useEffect(() => {
    // Create line chart on data and scale changes
    if (!_.isNil(data)) {
      createLineChart({
        id,
        data,
        xAxis: 'period_end',
        fullScreen,
        setShowLegend,
        exportTitle: title,
        exportSubTitle: subtitle,
        setExternalEmbed,
        prepareData: (linearData) => prepareData(linearData),
        addZeroOn: 'period_start',
      });
    }
  }, [data, title, subtitle]);

  return (
    <ChartWrapper
      id={id}
      readOnly={readOnly}
      widgetKey={widgetKey}
      data={data}
      showLegend={showLegend}
      error={error}
      fullScreen={fullScreen}
    />
  );
};

export default Chart;
