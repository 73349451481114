import { hooks, mui, React, ts, ui } from '_core';

import { DraftParams } from './types';
import BasketSelector from '../../common/forms/baskets-selector';

type FormProps = {
  params: DraftParams;
  setParams: (_p: DraftParams) => void;
  context: ts.types.basket.BasketAnalysisContext;
};

const Form: React.FC<FormProps> = ({ params, setParams, context }) => {
  const [benchmark, setBenchmark] = React.useState(params.benchmark || null);
  const [selectedBasket, setSelectedBasket] = React.useState(params.basket_ids?.[0]);

  const [selectedType, setSelectedType] = React.useState(params.selected_type || 'basket');

  const uiStyles = hooks.useUiStyles();

  React.useEffect(() => {
    const localBenchmark = selectedType == 'benchmark' ? benchmark : undefined;
    const localBasketId = selectedType == 'basket' && selectedBasket ? [selectedBasket] : undefined;

    const data = {
      ...params,
      selected_type: selectedType,
      benchmark: localBenchmark,
      basket_ids: localBasketId,
    };
    setParams(data);
  }, [benchmark, selectedBasket, selectedType]);

  return (
    <mui.core.Box sx={uiStyles.widgetEditMode}>
      <mui.core.Box>
        <mui.core.FormControl component="fieldset">
          <mui.core.RadioGroup
            row
            aria-label="position"
            name="position"
            value={selectedType}
            onChange={(e) => setSelectedType(e.target.value as 'benchmark' | 'basket')}
          >
            <mui.core.FormControlLabel value="basket" control={<mui.core.Radio color="primary" />} label="Basket" />
            <mui.core.FormControlLabel
              value="benchmark"
              control={<mui.core.Radio color="primary" />}
              label="Benchmark"
            />
          </mui.core.RadioGroup>
        </mui.core.FormControl>
      </mui.core.Box>

      <mui.core.Box mt={2}>
        {selectedType == 'basket' ? (
          <BasketSelector<false> basketIds={selectedBasket} setBasketIds={setSelectedBasket} multiple={false} />
        ) : (
          <ui.Benchmark
            value={benchmark || undefined}
            setValue={setBenchmark}
            universeContextPath={'$universe.handle'}
            context={context}
            universeIdPath={'definition.universe_id'}
          />
        )}
      </mui.core.Box>
    </mui.core.Box>
  );
};

export default Form;
