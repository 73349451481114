import { hooks, mui, React, ts, ui } from '_core';

import { getConstraintType } from '../../optimizer-utils/constraint-type';
import Bounds from '../bounds';
import SharedConstraintFields from '../shared-fields';
import Target from '../target';

type SpecificAssetsProps = {
  id: string;
  params: ts.types.optimizer.SpecificAssetsDraft;
  setParams: (_params: ts.types.optimizer.SpecificAssetsDraft) => void;
  readOnly: boolean;
  validationDef: ts.types.common.ValidationErrors;
  optimizerType: 'basket' | 'backtest';
  dates: {
    start: string;
    end: string;
  };
};

const SpecificAssetsConstraint: React.FC<SpecificAssetsProps> = ({
  id,
  params,
  setParams,
  readOnly,
  dates,
  optimizerType,
  validationDef,
}): React.ReactElement => {
  const [showMore, setShowMore] = React.useState(false);
  const [constraint, setConstraint] = React.useState(params);

  const theme = mui.styles.useTheme() as mui.core.Theme;

  React.useEffect(() => {
    if (optimizerType == 'basket' && params.benchmark_relative == null) {
      setConstraint({ ...constraint, benchmark_relative: true });
    }
  }, []);

  hooks.useEffectWithoutFirst(() => {
    setParams(constraint);
  }, [constraint]);

  const form = () => {
    if (getConstraintType(constraint) == 'BOUNDS') {
      if (showMore) {
        return (
          <Bounds
            id={id}
            params={constraint}
            setParams={setConstraint}
            readOnly={readOnly}
            validationDef={validationDef}
          >
            <mui.core.Grid container spacing={4} style={{ marginTop: theme.spacing(1) }}>
              <mui.core.Grid item xs={12} md={6}>
                <ui.NumericField
                  textFieldProps={{
                    label: 'Grand Father Lower Bound %',
                    fullWidth: true,
                    variant: 'outlined',
                    size: 'small',
                    error: validationDef?.fields?.includes(`grand_father_lower_bound_${id}`),
                  }}
                  disabled={readOnly}
                  suffix="%"
                  dividerOption={ts.enums.NUMBER_FORMATTERS_ENUM.HUNDRED}
                  value={constraint.grandfathered_lower_bound}
                  setValue={(v) =>
                    setConstraint({
                      ...constraint,
                      grandfathered_lower_bound: v,
                    })
                  }
                />
              </mui.core.Grid>
              <mui.core.Grid item xs={12} md={6}>
                <ui.NumericField
                  textFieldProps={{
                    label: 'Grand Father Upper Bound %',
                    fullWidth: true,
                    variant: 'outlined',
                    size: 'small',
                    error: validationDef?.fields?.includes(`grand_father_upper_bound_${id}`),
                  }}
                  disabled={readOnly}
                  suffix="%"
                  dividerOption={ts.enums.NUMBER_FORMATTERS_ENUM.HUNDRED}
                  value={constraint.grandfathered_upper_bound}
                  setValue={(v) =>
                    setConstraint({
                      ...constraint,
                      grandfathered_upper_bound: v,
                    })
                  }
                />
              </mui.core.Grid>
            </mui.core.Grid>

            <SharedConstraintFields
              params={constraint}
              setParams={setConstraint}
              showRelaxation={false}
              readOnly={readOnly}
              validationDef={validationDef}
            />
          </Bounds>
        );
      }

      return (
        <Bounds
          id={id}
          params={constraint}
          setParams={setConstraint}
          readOnly={readOnly}
          validationDef={validationDef}
        />
      );
    }

    if (showMore) {
      return (
        <Target params={constraint} setParams={setConstraint} readOnly={readOnly}>
          <mui.core.Grid item xs={12} md={6}>
            <ui.NumericField
              textFieldProps={{
                label: 'Grand Father Lower Bound %',
                fullWidth: true,
                variant: 'outlined',
                size: 'small',
                error: validationDef?.fields?.includes(`grand_father_lower_bound_${id}`),
              }}
              disabled={readOnly}
              value={constraint.grandfathered_lower_bound}
              suffix="%"
              dividerOption={ts.enums.NUMBER_FORMATTERS_ENUM.HUNDRED}
              setValue={(v) =>
                setConstraint({
                  ...constraint,
                  grandfathered_lower_bound: v,
                })
              }
            />
          </mui.core.Grid>

          <mui.core.Grid item xs={12} md={6}>
            <ui.NumericField
              textFieldProps={{
                label: 'Grand Father Upper Bound %',
                fullWidth: true,
                variant: 'outlined',
                size: 'small',
                error: validationDef?.fields?.includes(`grand_father_upper_bound_${id}`),
              }}
              disabled={readOnly}
              suffix="%"
              dividerOption={ts.enums.NUMBER_FORMATTERS_ENUM.HUNDRED}
              value={constraint.grandfathered_upper_bound}
              setValue={(v) =>
                setConstraint({
                  ...constraint,
                  grandfathered_upper_bound: v,
                })
              }
            />
          </mui.core.Grid>

          <SharedConstraintFields
            params={constraint}
            setParams={setConstraint}
            showRelaxation={false}
            readOnly={readOnly}
            validationDef={validationDef}
          />
        </Target>
      );
    }

    return <Target params={constraint} setParams={setConstraint} readOnly={readOnly} />;
  };

  return (
    <>
      {/* {!readOnly && (
        <ui.Button
          sx={uiStyles.fullCardButton}
          color="primary"
          size="small"
          fullWidth
          disabled={readOnly}
          onClick={() => setConstraint({ 
            type: constraint.type == 'BOUNDS' ? 'TARGET' : 'BOUNDS', 
            target_penalty: constraint.type == 'BOUNDS' ? 1.0 : undefined,
          })}
        >
          { constraint.type == 'BOUNDS' ? 'Change to target mode' : 'Change to bounds mode' }
        </ui.Button>
      )} */}
      <mui.core.Box px={3}>
        <mui.core.Box p={3}>
          <mui.core.FormControlLabel
            control={
              <mui.core.Switch
                size="small"
                checked={constraint.benchmark_relative}
                onChange={() =>
                  setConstraint({
                    ...constraint,
                    benchmark_relative: !constraint.benchmark_relative,
                  })
                }
                disabled={readOnly}
              />
            }
            label={optimizerType == 'backtest' ? 'Benchmark Relative' : 'Relative to Target Weights'}
          />
        </mui.core.Box>
        <mui.core.Box pb={3}>
          <ui.AssetSelector
            value={constraint.asset_fids as string[]}
            setValue={(val: ts.types.assetSelector.AssetSelectorValueObject) => {
              setConstraint({
                ...constraint,
                asset_fids: val as string[],
              });
            }}
            createId={(a) => `${a.fid}`}
            disabled={readOnly}
            dates={dates}
            fields={[
              { title: 'Ticker', key: 'ticker' },
              { title: 'Name', key: 'name' },
              { title: 'Country', key: 'quotation_country' },
            ]}
            tableView={true}
            multiple
            errorState={validationDef?.fields?.includes(`asset_${id}`)}
          />
        </mui.core.Box>
        {form()}
        <mui.core.Button
          onClick={() => {
            setShowMore(!showMore);
          }}
          color="primary"
          style={{ marginTop: '0.5rem' }}
        >
          {showMore ? (
            <>
              <mui.icons.Remove style={{ fontSize: '14px', marginRight: '0.5rem' }} />
              Show Less
            </>
          ) : (
            <>
              <mui.icons.Add style={{ fontSize: '14px', marginRight: '0.5rem' }} />
              Show More
            </>
          )}
        </mui.core.Button>
      </mui.core.Box>
    </>
  );
};

export default SpecificAssetsConstraint;
