import { helpers, mui, React, ts, ui,useHistory, useSelector } from '_core';

import * as utils from './pipeline-utils';

type PipelineFormProps = {
  open: boolean;
  setOpen: (_open: boolean) => void;
};

const PipelineForm: React.FC<PipelineFormProps> = ({ open, setOpen }): React.ReactElement => {
  const pipelines = useSelector((state) => state.resources.pipelines as ts.types.pipeline.Pipeline[]);
  const workspaces = useSelector((state) => state.resources.workspaces as ts.types.workspace.Workspace[]);
  const currentWorkspaceId = useSelector((state) => state.ui.currentWorkspaceId as number);

  const history = useHistory();

  const [errorMessage, setErrorMessage] = React.useState('');
  const [name, setName] = React.useState('');
  const [handle, setHandle] = React.useState('');
  const [template, setTemplate] = React.useState<ts.types.pipeline.Pipeline>();
  const [loadingCreate, setLoadingCreate] = React.useState(false);
  const [workspace, setWorkspace] = React.useState(workspaces.find((w) => w.id == currentWorkspaceId) || workspaces[0]);

  // Create handle while typing name
  React.useEffect(() => {
    setHandle(helpers.createHandle(name, 'p'));
  }, [name]);

  const handleSubmit = async () => {
    setErrorMessage('');

    // Validation
    if (!name || !handle) {
      setErrorMessage('All attributes are required.');
      setLoadingCreate(false);
      return;
    }

    setLoadingCreate(true);

    const newPipeline = utils.definition.getNewPipeline(name, handle, template);
    utils.apiProcedures.createPipeline(
      newPipeline,
      async (data) => {
        if (workspace)
          await helpers.resourcesUtils.addToWorkspace(data.id, ts.enums.UI_RESOURCE_TYPE_ENUM.PIPELINE, workspace);
        setOpen(false);
        history.push(`/pipelines/${data.id}`);
      },
      (err) => {
        setErrorMessage(err);
        setLoadingCreate(false);
      }
    );
  };

  const formContent = () => (
    <mui.core.Box pt={3}>
      <mui.core.Grid container spacing={3}>
        <mui.core.Grid item xs={12} sm={6}>
          <mui.core.TextField
            autoFocus
            id="name"
            label="Pipeline name"
            value={name}
            variant="outlined"
            onChange={(e) => setName(e.target.value)}
            fullWidth
            required
            size="small"
            inputProps={{
              autocomplete: 'off',
            }}
          />
        </mui.core.Grid>
        <mui.core.Grid item xs={12} sm={6}>
          <mui.core.TextField
            id="handle"
            label="Pipeline handle"
            value={handle}
            variant="outlined"
            onChange={(e) => setHandle(e.target.value)}
            fullWidth
            size="small"
            required
          />
        </mui.core.Grid>
      </mui.core.Grid>
      <mui.core.Box py={4}>
        <ui.ResourceAutocomplete<ts.types.pipeline.PipelineExpanded>
          enableNone
          value={template?.id}
          setValue={(id) => setTemplate(pipelines.find((u) => u.id == id))}
          type={ts.enums.RESOURCES_TYPES_ENUM.PIPELINE}
          filter={(p) => !p.source_resource_id}
          inputProps={{
            fullWidth: true,
            label: 'Template',
            variant: 'outlined',
            required: true,
            size: 'small',
          }}
        />
      </mui.core.Box>
      <ui.WorkspaceSelector value={workspace} setValue={setWorkspace} />
    </mui.core.Box>
  );

  return (
    <mui.core.Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="sm"
      fullWidth
    >
      {errorMessage && (
        <mui.lab.Alert onClose={() => setErrorMessage('')} severity="error">
          {errorMessage}
        </mui.lab.Alert>
      )}
      <ui.DialogTitle closeAction={() => setOpen(false)}>Create Pipeline</ui.DialogTitle>

      <mui.core.DialogContent>{formContent()}</mui.core.DialogContent>
      <mui.core.DialogActions>
        <ui.Button
          variant="contained"
          color="primary"
          loading={loadingCreate}
          onClick={handleSubmit}
          fullWidth
          style={{ margin: '0rem 1rem 1rem' }}
        >
          Create Pipeline
        </ui.Button>
      </mui.core.DialogActions>
    </mui.core.Dialog>
  );
};

export default PipelineForm;
