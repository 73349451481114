import { ts } from '_core';

import { DraftParams, INTERACTION_PARAMS } from './types';

export default [
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.ALPHA_MODEL_LINEAR_STATISTICS_TIME,
    title: 'Statistics Vs. Time',
    helpLabel: 'k3scb5hd',
    defaultParams: {
      statisticType: 'regression',
    } as DraftParams,
    interactionParams: Object.values(INTERACTION_PARAMS),
    form: true,
    tags: [
      ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.ALPHA_MODEL,
      ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.LINEAR_ALPHA_MODEL,
    ],
    neededFiles: ['linear_alpha_model_stats'],
  },
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.ALPHA_MODEL_OVERALL_STATISTICS_TIME,
    title: 'Overall Statistics Vs. Time',
    helpLabel: 'cxfiilur',
    defaultParams: {
      statisticType: 'r2',
      overall_stats: true,
    } as DraftParams,
    interactionParams: Object.values(INTERACTION_PARAMS),
    form: false,
    tags: [
      ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.ALPHA_MODEL,
      ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.LINEAR_ALPHA_MODEL,
    ],
    neededFiles: ['linear_alpha_model_stats'],
  },
] satisfies ts.types.analysis.WidgetConfig[];
