import { React, ts } from '_core';

import Chart from './chart';

type BacktestExposuresOverTimeProps = {
  id: string;
  readOnly: boolean;
  widgetKey: string;
  fullScreen?: boolean;
  loadWidgetData: (_payload: ts.types.widgets.WidgetGetDataParams) => Promise<ts.types.widgets.WidgetGetDataResponse>;
  widgetPaths: Record<'backtest_portfolio_exposures', string>;
  title: string;
};

const BacktestExposuresOverTime: React.FC<BacktestExposuresOverTimeProps> = ({
  id,
  readOnly,
  widgetKey,
  loadWidgetData,
  widgetPaths,
  fullScreen,
  title,
}) => {
  return (
    <Chart
      id={id}
      readOnly={readOnly}
      widgetKey={widgetKey}
      loadWidgetData={loadWidgetData}
      file={widgetPaths['backtest_portfolio_exposures']}
      fullScreen={fullScreen}
      title={title}
    />
  );
};

export default BacktestExposuresOverTime;
