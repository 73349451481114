import { hooks, mui, React, ts } from '_core';

import * as prefColumns from 'views/preferences/columns-config';
import Tabular from 'views/report/tables/tabular';

import { Params } from './types';
import { getColumns } from '../../common/charts/utils';

type ChartProps = {
  id: string;
  params: Params;
  setParams: (_v: Params) => void;
  loadWidgetData: (_payload: ts.types.widgets.WidgetGetDataParams) => Promise<ts.types.widgets.WidgetGetDataResponse>;
  summary: string;
  fullScreen: boolean;
  goFullScreen: (_v: string) => void;

  queryParams?: { initial?: string };
};

const Chart: React.FC<ChartProps> = ({
  id,
  loadWidgetData,
  params,
  setParams,
  summary,
  fullScreen,
  goFullScreen,
  queryParams,
}) => {
  const [data, setData] = React.useState<ts.types.widgets.TableData>(null);
  const [error, setError] = React.useState<ts.types.common.Alert>(null);

  const container = React.createRef();

  const [tableParams, setTableParams] = React.useState<ts.types.components.dataGrid.TableParamsSheet>(
    params.table_params
  );

  hooks.useEffectWithoutFirst(() => {
    setParams({
      ...params,
      table_params: { ...tableParams },
    });
  }, [tableParams]);

  const loadData = async () => {
    try {
      const response = await loadWidgetData({
        data: {
          file: summary,
        },
      });

      if (response) {
        setData(response.data);
      }
    } catch (err) {
      setError({
        severity: ts.enums.ALERT_SEVERITY_ENUM.ERROR,
        message: JSON.stringify(err),
      });
    }
  };

  React.useEffect(() => {
    if (summary) loadData();
    return () => setData(null);
  }, [summary]);

  const getSummaryColumns = (colkeys: string[], preferencesColumns: ts.types.components.dataGrid.ColumnPreferences) => {
    const columns = getColumns(
      colkeys,
      preferencesColumns?.[ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.SUMMARY],
      prefColumns.backtestSetSummaryColumns[ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.SUMMARY]
    );
    return columns;
  };

  return (
    <mui.core.Box display="flex" flexDirection="column" style={{ height: '100%' }} ref={container}>
      <Tabular<ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.SUMMARY>
        data={
          data
            ? {
                [ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.SUMMARY]: data,
              }
            : null
        }
        getColumns={{
          [ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.SUMMARY]: getSummaryColumns,
        }}
        sheetTitles={{
          [ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.SUMMARY]: ' Summary',
        }}
        fullScreen={fullScreen}
        goFullScreen={goFullScreen}
        queryParams={queryParams}
        customError={error}
        preferences={{
          preferencesSourceWidgetId: id,
          preferenceKey: ts.enums.PREFERENCES_KEY_ENUM.BACKTEST_SET_SUMMARY_COLS,
          preferenceTab: ts.enums.PREFERENCES_INNER_TABS_ENUM.BACKTEST_SET_SUMMARY_COLS,
        }}
        tableParams={tableParams}
        setTableParams={{
          [ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.SUMMARY]: (v) =>
            setTableParams((t) => ({
              ...t,
              [ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.SUMMARY]: v,
            })),
        }}
        triggerWidthChange={params.fullWidth}
      />
    </mui.core.Box>
  );
};

export default Chart;
