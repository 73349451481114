import { React, ts } from '_core';

import Chart from './chart';
import { Params } from './types';

type RiskModelTabularSignificanceProps = {
  id: string;
  params: Params;
  setParams: (_p: Params) => void;

  fullScreen?: boolean;
  queryParams?: { initial?: string };
  widgetPaths?: Record<'risk_model_regression_stats', string>;

  goFullScreen?: (_q: string) => void;

  loadWidgetData: (_payload: ts.types.widgets.WidgetGetDataParams) => Promise<ts.types.widgets.WidgetGetDataResponse>;
};

const RiskModelTabularSignificance: React.FC<RiskModelTabularSignificanceProps> = ({
  id,
  loadWidgetData,
  params,
  setParams,
  widgetPaths,
  fullScreen,
  goFullScreen,
  queryParams,
}): React.ReactElement => {
  return (
    <Chart
      id={id}
      loadWidgetData={loadWidgetData}
      file={widgetPaths['risk_model_regression_stats']}
      fullScreen={fullScreen}
      queryParams={queryParams}
      goFullScreen={goFullScreen}
      params={params}
      setParams={setParams}
    />
  );
};

export default RiskModelTabularSignificance;
