/* eslint-disable no-unused-vars */
export enum INTERACTION_PARAMS {
  PLOT_TYPE = 'plot_type',
}

export type InteractionParams = {
  [INTERACTION_PARAMS.PLOT_TYPE]: 'line' | 'bar';
};

export type Params = InteractionParams;
export type DraftParams = Partial<Params>;
