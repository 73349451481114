import { ts } from '_core';

const defaultAlpha: ts.types.signal.LassoDraft = {
  return_horizon: {
    periods: { frequency: 'FREQUENCY', amount: 1 },
  },
  training_sample: {
    frequency: ts.enums.FREQUENCY_ENUM.WEEKLY,
    number: 1,
  },
  n_alphas: 100,
  max_iter: 1000,
  cv: 5,
  signals: [],
  intercept: true,
  mode: 'pooled',
  timeWeighting: 'equal',
  predictionValueType: 'return_horizon',
};

export default defaultAlpha;
