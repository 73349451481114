import { _,helpers, ts } from '_core';

export default {
  [ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.MAIN]: [
    {
      key: 'asset',
      name: 'Asset',
      formatter: helpers.tableFormatters.formatTo('inferred'),
      isIndex: true,
      filter: 'string',
    },
    {
      key: 'name',
      name: 'Name',
      formatter: helpers.tableFormatters.formatTo('string', { align: 'left' }),
      filter: 'string',
    },
    {
      key: 'asset_type',
      name: 'Type',
      formatter: helpers.tableFormatters.formatTo('string', { align: 'left' }),
      filter: 'string',
    },
    {
      key: 'ticker',
      name: 'Ticker',
      formatter: helpers.tableFormatters.formatTo('string', { align: 'left' }),
      filter: 'string',
    },
    {
      key: 'rbics_l1_id',
      name: 'Sector Code',
      formatter: helpers.tableFormatters.formatTo('integer', { align: 'right' }),
      filter: 'string',
    },
    {
      key: 'rbics_l2_id',
      name: 'Sub-Sector Code',
      formatter: helpers.tableFormatters.formatTo('integer', { align: 'right' }),
      filter: 'string',
    },
    {
      key: 'rbics_l3_id',
      name: 'Industry Code',
      formatter: helpers.tableFormatters.formatTo('integer', { align: 'right' }),
      filter: 'string',
    },
    {
      key: 'rbics_l4_id',
      name: 'Sub-Industry Code',
      formatter: helpers.tableFormatters.formatTo('integer', { align: 'right' }),
      filter: 'string',
    },
    {
      key: 'rbics_l1_name',
      name: 'Sector Name',
      formatter: helpers.tableFormatters.formatTo('string', { align: 'left' }),
      filter: 'string',
    },
    {
      key: 'rbics_l2_name',
      name: 'Sub-Sector Name',
      formatter: helpers.tableFormatters.formatTo('string', { align: 'left' }),
      filter: 'string',
    },
    {
      key: 'rbics_l3_name',
      name: 'Industry Name',
      formatter: helpers.tableFormatters.formatTo('string', { align: 'left' }),
      filter: 'string',
    },
    {
      key: 'rbics_l4_name',
      name: 'Sub-Industry Name',
      formatter: helpers.tableFormatters.formatTo('string', { align: 'left' }),
      filter: 'string',
    },
    {
      key: 'cusip',
      name: 'Cusip',
      formatter: helpers.tableFormatters.formatTo('string', { align: 'left' }),
      filter: 'string',
      condition: (features: ts.types.organization.OrganizationFeatures) => !features.hide_cusip_col,
    },
    {
      key: 'isin',
      name: 'ISIN',
      formatter: helpers.tableFormatters.formatTo('string', { align: 'left' }),
      filter: 'string',
      condition: (features: ts.types.organization.OrganizationFeatures) => !features.hide_isin_col,
    },
    {
      key: 'issuer_country',
      name: 'Issuer Country',
      formatter: helpers.tableFormatters.formatTo('string', { align: 'center' }),
      filter: 'string',
    },
    {
      key: 'risk_model_industry',
      name: 'Industry',
      formatter: helpers.tableFormatters.formatTo('string', { align: 'center' }),
      filter: 'string',
    },
    {
      key: 'quotation_country',
      name: 'Risk Model Quotation Country',
      formatter: helpers.tableFormatters.formatTo('string', { align: 'center' }),
      filter: 'string',
    },
    {
      key: 'currency',
      name: 'Risk Model Quotation Currency',
      formatter: helpers.tableFormatters.formatTo('string', { align: 'center' }),
      filter: 'string',
    },
    {
      key: 'dynamic',
      name: 'Risk Model Specific',
      formatter: helpers.tableFormatters.formatTo('inferred'),
      members: () => true,
      cleanNameFormatter: (key) => _.startCase(key),
      nameFormatter: (key) => _.startCase(key),
      filter: 'inferred',
    },
  ] as ts.types.components.dataGrid.ColumnsData,
};
