// Core
import {
  api,
  createTypedSelector,
  helpers,
  hooks,
  ui,
  uitheme,
  useDispatch as baseUseDispatch,
} from '@local/finsera-core';

import * as actions from './actions';
import { default as config } from './config/index';
import { RootState } from './store';
import * as ts from './typescript-definitions';

export const useSelector = createTypedSelector<RootState>();
export const useDispatch = () => baseUseDispatch<(_v: any) => any>();

export * from './libs';
export { default as PropTypes } from 'prop-types';
export { default as React } from 'react';
export { actions };
export { api };
export { config };
export { helpers };
export { hooks };
export { _t, t } from './i18n';
export { ts };
export { uitheme };
export { ui };
