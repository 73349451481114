import { ts } from '_core';


export default [
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.METRIC_BASKET_RETURNS,
    title: 'Basket Returns',
    helpLabel: 'nj0wixzd',
    form: false,
    neededFiles: ['portfolios'],
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.BASKET_ADHOC],

    getAdhocData: (pathFile) => {
      return {
        name: 'Basket Returns',
        file_name: pathFile,
        description: 'This file contains the returns of the basket.',
        columns: [
          {
            key: 'period_start',
            name: 'start',
            description: 'Start date of the period.',
            type: 'date',
          },
          { key: 'period_end', name: 'end', description: 'End date of the period.', type: 'date' },
          {
            key: 'return',
            name: 'returns',
            description: 'Returns of the portfolio.',
            type: 'float',
          },
        ],
        web_api_args: {
          values_filter: [
            {
              column: 'stage',
              value: 'after_costs',
              operator: 'equals',
            },
          ],
        },
      };
    },
  },
] satisfies ts.types.analysis.WidgetConfig[];
