import React from 'react';

import * as enums from '../../enums';
import { returnHorizon } from '../../helpers';
import { useEffectWithoutFirst } from '../../hooks';
import { _, mui } from '../../libs';
import * as types from '../../types';
import NumericField from '../numeric-field';


type SingleReturnHorizonProps = {
  rh: types.returnHorizon.ReturnHorizon;
  setRh: (_value: types.returnHorizon.ReturnHorizon) => void;
  remove?: () => void;
  dailyReporting: boolean;
  inferredWildcard: string;
  context?: Record<string, any>;
};

const SingleReturnHorizon: React.FC<SingleReturnHorizonProps> = ({
  rh,
  setRh,
  dailyReporting,
  inferredWildcard,
  remove,
  context,
}): React.ReactElement => {
  const freqDefault = dailyReporting ? 'DAILY' : inferredWildcard;

  const [periods, setPeriods] = React.useState(rh.periods?.amount || 1);
  const [frequency, setFrequency] = React.useState(rh.periods?.frequency || freqDefault);
  const [skipPeriods, setSkipPeriods] = React.useState(rh.skip?.amount || 0);
  const [skipFrequency, setSkipFrequency] = React.useState(rh.skip?.frequency || 'DAILY');

  useEffectWithoutFirst(() => {
    if (dailyReporting) {
      setSkipFrequency('DAILY');
      setFrequency('DAILY');
    } else {
      setFrequency(inferredWildcard);
    }
  }, [dailyReporting]);

  const periodsLabel = React.useMemo(() => {
    if (_.isEmpty(context)) return 'period(s)';
    const contextFreq = _.get(context, inferredWildcard.replace('$', ''));
    if (contextFreq) return returnHorizon._freqToSignature(contextFreq);
    return 'period(s)';
  }, [context]);

  const getLocalSignature = (
    rhPeriods: types.returnHorizon.FrequencyAmount,
    rhSkip: types.returnHorizon.FrequencyAmount
  ) => {
    let label = '';
    if (rhPeriods?.amount && rhPeriods?.frequency)
      if (rhPeriods.frequency == 'DAILY') label += `${rhPeriods.amount} Day Return`;
      else label += `${rhPeriods.amount} Period Return`;
    if (rhSkip?.amount && rhSkip?.frequency)
      if (rhSkip.frequency == 'DAILY') label += `, ${rhSkip.amount} Day Lead`;
      else label += `, ${rhSkip.amount} Period Lead`;

    return label;
  };

  useEffectWithoutFirst(() => {
    const rhPeriods: types.returnHorizon.FrequencyAmount = {
      amount: periods,
      frequency: frequency as enums.FREQUENCY_ENUM,
    };
    let rhSkip: types.returnHorizon.FrequencyAmount = undefined;

    if (skipPeriods > 0) {
      rhSkip = {
        amount: skipPeriods,
        frequency: skipFrequency as enums.FREQUENCY_ENUM,
      };
    }

    const returnHorizon: types.returnHorizon.ReturnHorizon = {
      periods: rhPeriods,
      skip: rhSkip,
      signature: getLocalSignature(rhPeriods, rhSkip),
    };

    setRh(returnHorizon);
  }, [periods, frequency, skipPeriods, skipFrequency]);

  return (
    <mui.core.Box>
      <mui.core.Grid container spacing={2}>
        <mui.core.Grid item xs={4}>
          <NumericField
            disabled={dailyReporting}
            textFieldProps={{
              label: 'Periods',
              fullWidth: true,
              variant: 'outlined',
              size: 'small',
              required: true,
            }}
            value={periods}
            setValue={(v) => setPeriods(v)}
          />
        </mui.core.Grid>

        <mui.core.Grid item xs={4}>
          <mui.core.TextField
            select
            variant="outlined"
            size="small"
            value={frequency}
            onChange={(e) => setFrequency(e.target.value)}
            fullWidth
            required
            disabled
          >
            {!dailyReporting && <mui.core.MenuItem value={inferredWildcard}>{periodsLabel}</mui.core.MenuItem>}
            <mui.core.MenuItem value="DAILY">day(s)</mui.core.MenuItem>
          </mui.core.TextField>
        </mui.core.Grid>

        {remove && (
          <mui.core.Grid item xs={2} style={{ display: 'flex', alignItems: 'center' }}>
            <mui.core.IconButton onClick={remove} size="small" style={{ opacity: 0.9 }}>
              <mui.icons.Close />
            </mui.core.IconButton>
          </mui.core.Grid>
        )}
      </mui.core.Grid>
      <mui.core.Box mt={2}>
        <mui.core.Grid container spacing={2}>
          <mui.core.Grid item xs={4}>
            <NumericField
              textFieldProps={{
                label: 'Lead',
                fullWidth: true,
                variant: 'outlined',
                size: 'small',
              }}
              value={skipPeriods}
              setValue={(v) => setSkipPeriods(v)}
            />
          </mui.core.Grid>

          <mui.core.Grid item xs={4}>
            <mui.core.TextField
              select
              variant="outlined"
              size="small"
              value={skipFrequency}
              onChange={(e) => setSkipFrequency(e.target.value)}
              fullWidth
            >
              {periodsLabel != returnHorizon._freqToSignature('DAILY' as enums.FREQUENCY_ENUM) && (
                <mui.core.MenuItem value="DAILY">day(s)</mui.core.MenuItem>
              )}
              {!dailyReporting && <mui.core.MenuItem value={inferredWildcard}>{periodsLabel}</mui.core.MenuItem>}
            </mui.core.TextField>
          </mui.core.Grid>

          {remove && <mui.core.Grid item xs={2} />}
        </mui.core.Grid>
      </mui.core.Box>
    </mui.core.Box>
  );
};

export default SingleReturnHorizon;
