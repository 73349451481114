import { _, helpers, hooks, mui, React, ts } from '_core';

import { getSignalsInfo } from './helpers';
import ChartWrapper from '../../common/chart-wrapper';
import createScatterChart from '../../common/charts/create-scatter-chart';

type SignalsInfo = { handle: string; realHandle: string; theme: string }[];

type ChartProps = {
  id: string;
  readOnly: boolean;
  widgetKey: string;
  fullScreen: boolean;
  loadWidgetData: (_payload: ts.types.widgets.WidgetGetDataParams) => Promise<ts.types.widgets.WidgetGetDataResponse>;
  definition: ts.types.signal.OptimizedModel;
  file: string;
  widgetTitle: string;
};

const Chart: React.FC<ChartProps> = ({
  id,
  readOnly,
  widgetKey,
  loadWidgetData,
  definition,
  fullScreen,
  file,
  widgetTitle,
}) => {
  const [data, setData] = React.useState<ts.types.widgets.ScatterData>(null);
  const [formattedData, setFormattedData] = React.useState<ts.types.widgets.ScatterData>(null);
  const [error, setError] = React.useState<ts.types.common.ApiError>(null);

  const [selectedThemeTab, setSelectedThemeTab] = React.useState<string>();
  const [themesDisplayTab, setThemesDisplayTab] = React.useState<string[]>();

  const [selectedSignalTab, setSelectedSignalTab] = React.useState<string>();
  const [signalsDisplayTab, setSignalsDisplayTab] = React.useState<string[]>();

  const [selectedVariable, setSelectedVariable] = React.useState<string>();

  const [signalsInfo, setSignalsInfo] = React.useState<SignalsInfo>();

  const updateThemeTabs = (localSignalsInfo: SignalsInfo) => {
    const themesTabs = _.uniq(localSignalsInfo.map((el) => el.theme));

    setThemesDisplayTab(themesTabs);
    setSelectedThemeTab(themesTabs[0]);
  };

  const loadData = async () => {
    try {
      const response = await loadWidgetData({
        data: {
          file,
        },
      });
      if (response.data) {
        setData(response.data as ts.types.widgets.ScatterData);
        const variables = _(response.data)
          .map((el) => el.variable as unknown as string)
          .uniq()
          .value();

        if (!selectedSignalTab) {
          const signalsInfo = getSignalsInfo(variables, definition?.signals);
          setSignalsInfo(signalsInfo);

          updateThemeTabs(signalsInfo);
        }
      }
    } catch (err) {
      setError(err as ts.types.common.ApiError);
    }
  };

  React.useEffect(() => {
    if (file) loadData();
    return () => setData(null);
  }, [file]);

  React.useEffect(() => {
    if (!_.isNil(data)) {
      const localFormattedData = data.filter((el) => (el.variable as unknown as string) == selectedVariable);
      setFormattedData(localFormattedData);
    }
  }, [data, selectedVariable]);

  hooks.useEffectWithoutFirst(() => {
    const signalsTabs = signalsInfo.filter((el) => el.theme == selectedThemeTab).map((el) => el.handle);

    setSignalsDisplayTab(signalsTabs);
    setSelectedSignalTab(signalsTabs[0]);
  }, [selectedThemeTab]);

  hooks.useEffectWithoutFirst(() => {
    const realHandle = signalsInfo.find((el) => el.handle == selectedSignalTab).realHandle;
    setSelectedVariable(realHandle);
  }, [selectedSignalTab]);

  React.useEffect(() => {
    if (!_.isNil(formattedData) && selectedSignalTab) {
      const corr = formattedData?.[0]?.['corr'] || null;
      const m = formattedData?.[0]?.['m'] || null;
      const b = formattedData?.[0]?.['b'] || null;
      const eq = helpers.buildName.getEquationStr(m, b);

      const corrLabel = `[bold]Correlation:[/] ${corr ? _.round(corr, 4) : 'Unable to process it.'}`;
      const equationLabel = `[bold]${!_.isEmpty(eq) ? 'y = ' + eq : 'Unable to process the equation.'}[/]`;

      const signalTabLabel = signalsDisplayTab.length <= 1 ? `[bold]${selectedSignalTab}[/]\n\n` : '';

      const title = signalTabLabel + corrLabel + '\u00A0\u00A0\u00A0\u00A0' + equationLabel;
      createScatterChart({
        id,
        data: formattedData,
        fullScreen,
        xAxis: `returns|${selectedVariable}`,
        yAxis: `true_returns|${selectedVariable}`,
        xAxisLabel: 'Predicted Value',
        yAxisLabel: 'True Value',
        tooltip: 'Predicted: [Bold]{valueX}[/] - True: [Bold]{valueY}[/]',
        trendline: formattedData?.[0]?.fit ? 'fit' : undefined,
        title,
        exportTitle: widgetTitle,
      });
    }
  }, [formattedData]);

  const getTabs = () => {
    if (themesDisplayTab) {
      const tabLabelRenderer = (tabLabel: string) => (
        <mui.core.Tooltip title={tabLabel} arrow>
          <div
            style={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              width: '100%',
            }}
          >
            {tabLabel}
          </div>
        </mui.core.Tooltip>
      );

      return (
        <>
          <mui.core.Tabs
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            value={selectedThemeTab}
            onChange={(_e, newTab) => setSelectedThemeTab(newTab)}
          >
            {themesDisplayTab.map((tab) => (
              <mui.core.Tab key={tab} label={tabLabelRenderer(tab)} value={tab} style={{ textTransform: 'none' }} />
            ))}
          </mui.core.Tabs>
          {signalsDisplayTab?.length > 1 && (
            <mui.core.Tabs
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
              value={selectedSignalTab}
              onChange={(_e, newTab) => setSelectedSignalTab(newTab)}
            >
              {signalsDisplayTab.map((tab) => (
                <mui.core.Tab key={tab} label={tabLabelRenderer(tab)} value={tab} style={{ textTransform: 'none' }} />
              ))}
            </mui.core.Tabs>
          )}
        </>
      );
    }
    return null;
  };

  return (
    <ChartWrapper
      id={id}
      readOnly={readOnly}
      widgetKey={widgetKey}
      data={data}
      error={error}
      tabs={getTabs()}
      fullScreen={fullScreen}
    />
  );
};

export default Chart;
