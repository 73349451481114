import { ts } from '_core';

import { INTERACTION_PARAMS } from './types';

export default [
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.PORTFOLIO_CONSTRAINTS,
    form: false,
    title: 'Constraints',
    interactionParams: Object.values(INTERACTION_PARAMS),
    neededFiles: ['rebalance_constraints_info'],
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.REBALANCE_PREVIEW],

    disableAdd: true,
    helpLabel: 'm44q0vq0',
  },
] satisfies ts.types.analysis.WidgetConfig[];
