import { _, helpers, hooks, mui, React, ts } from '_core';

import {
  getSignalsInfo,
  prepareStatisticsVsTimeData,
  prepareStatisticsVsTimeLinear,
  prepareStatisticsVsTimeScatter,
} from './helpers';
import { Params } from './types';
import ChartWrapper from '../../common/chart-wrapper';
import createLineChart from '../../common/charts/create-line-chart';
import createScatterChart from '../../common/charts/create-scatter-chart';

type SignalsInfo = { handle: string; realHandle: string; theme: string }[];

type ChartProps = {
  id: string;
  readOnly: boolean;
  widgetKey: string;
  loadWidgetData: (_payload: ts.types.widgets.WidgetGetDataParams) => Promise<ts.types.widgets.WidgetGetDataResponse>;
  file: string;
  fullScreen: boolean;
  definition: ts.types.signal.Lasso;
  chartType: 'scatter' | 'linear';
  params: Params;
  title: string;
};

const Chart: React.FC<ChartProps> = ({
  id,
  readOnly,
  widgetKey,
  loadWidgetData,
  fullScreen,
  params,
  file,
  chartType,
  definition,
  title,
}) => {
  const [data, setData] = React.useState<ts.types.widgets.TableData>(null);

  const [error, setError] = React.useState<ts.types.common.ApiError>(null);
  const [showLegend, setShowLegend] = React.useState(false);

  const [selectedThemeTab, setSelectedThemeTab] = React.useState<string>();
  const [themesDisplayTab, setThemesDisplayTab] = React.useState<string[]>();

  const [selectedSignalTab, setSelectedSignalTab] = React.useState<string>();
  const [signalsDisplayTab, setSignalsDisplayTab] = React.useState<string[]>();

  const [selectedVariable, setSelectedVariable] = React.useState<string>();

  const [signalsInfo, setSignalsInfo] = React.useState<SignalsInfo>();

  const updateThemeTabs = (localSignalsInfo: SignalsInfo) => {
    const themesTabs = _.uniq(localSignalsInfo.map((el) => el.theme));

    setThemesDisplayTab(themesTabs);
    setSelectedThemeTab(themesTabs[0]);
  };

  const loadData = async () => {
    try {
      const response = await loadWidgetData({
        data: { file },
      });

      if (response.data) {
        const newData = prepareStatisticsVsTimeData(response.data, params.statisticType);
        setData(newData.data);

        const signalsInfo = getSignalsInfo(newData.variables, definition?.signals);
        setSignalsInfo(signalsInfo);

        updateThemeTabs(signalsInfo);
      }
    } catch (err) {
      setError(err as ts.types.common.ApiError);
    }
  };

  React.useEffect(() => {
    if (file) loadData();
    return () => {
      setData(null);
      setData(null);
    };
  }, [file]);

  hooks.useEffectWithoutFirst(() => {
    const signalsTabs = signalsInfo.filter((el) => el.theme == selectedThemeTab).map((el) => el.handle);

    setSignalsDisplayTab(signalsTabs);
    setSelectedSignalTab(signalsTabs[0]);
  }, [selectedThemeTab]);

  hooks.useEffectWithoutFirst(() => {
    const realHandle = signalsInfo.find((el) => el.handle == selectedSignalTab).realHandle;
    setSelectedVariable(realHandle);
  }, [selectedSignalTab]);

  const statisticTypeLabel = params.statisticType == 'regression' ? 'Regression Coefficient' : params.statisticType;

  React.useEffect(() => {
    // Create line chart on data and scale changes
    if (!_.isNil(data) && selectedVariable) {
      let tabData;
      if (chartType == 'linear') tabData = prepareStatisticsVsTimeLinear(data, params.statisticType, selectedVariable);
      else tabData = prepareStatisticsVsTimeScatter(data, params.statisticType, selectedVariable);

      const key = selectedVariable.replace('tstats|', '').replace('pvalues|', '');
      let chartName;
      if (key.includes('var')) chartName = helpers.buildName.getSignalDef(key.replace('var', ''), definition);

      if (chartType == 'linear')
        createLineChart({
          id,
          data: tabData as ts.types.widgets.TableData,
          fullScreen,
          percent: false,
          setShowLegend,
          skipFormatter: true,
          xAxis: 'date',
          yAxis: params.statisticType,
          yAxisLabel: params.overall_stats ? selectedVariable : statisticTypeLabel,
          xIsDate: true,
          dotted: ['Q1', 'Q2', 'Q4', 'Q5'],
          title: params.overall_stats ? null : chartName,
          exportTitle: title,
        });
      else
        createScatterChart({
          id,
          data: tabData as ts.types.widgets.ScatterData,
          fullScreen,
          percent: false,
          xAxis: 'date',
          yAxis: params.statisticType,
          yAxisLabel: params.overall_stats ? selectedVariable : statisticTypeLabel,
          xIsDate: true,
          title: params.overall_stats ? null : chartName,
          exportTitle: title,
        });
    }
  }, [data, selectedVariable]);

  const getTabs = () => {
    if (themesDisplayTab) {
      const tabLabelRenderer = (tabLabel: string) => (
        <mui.core.Tooltip title={tabLabel} arrow>
          <div
            style={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              width: '100%',
            }}
          >
            {tabLabel}
          </div>
        </mui.core.Tooltip>
      );

      return (
        <>
          <mui.core.Tabs
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            value={selectedThemeTab}
            onChange={(_e, newTab) => setSelectedThemeTab(newTab)}
          >
            {themesDisplayTab.map((tab) => (
              <mui.core.Tab key={tab} label={tabLabelRenderer(tab)} value={tab} />
            ))}
          </mui.core.Tabs>
          {signalsDisplayTab?.length > 1 && (
            <mui.core.Tabs
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
              value={selectedSignalTab}
              onChange={(_e, newTab) => setSelectedSignalTab(newTab)}
            >
              {signalsDisplayTab.map((tab) => (
                <mui.core.Tab key={tab} label={tabLabelRenderer(tab)} value={tab} />
              ))}
            </mui.core.Tabs>
          )}
        </>
      );
    }
    return null;
  };

  return (
    <ChartWrapper
      id={id}
      readOnly={readOnly}
      widgetKey={widgetKey}
      data={data}
      showLegend={showLegend}
      error={error}
      tabs={getTabs()}
      fullScreen={fullScreen}
    />
  );
};

export default Chart;
