import { _, React } from '_core';

import { PreferencesContext } from 'views/preferences/preferences-context';

import SignalDatasetForm from './signal-dataset-form';
import SelectColumns from '../common/select-columns';
import columnsSelectConfig from '../common/select-columns-config';

const SignalsPreferences: React.FC = () => {
  const preferencesContext = React.useContext(PreferencesContext);
  const { preferencesSelectedInnerTab } = preferencesContext;

  const selectedColumnsConfig = React.useMemo(
    () => columnsSelectConfig[preferencesSelectedInnerTab],
    [preferencesSelectedInnerTab]
  );

  return (
    <>
      {_.isEmpty(selectedColumnsConfig) ? ( // We are on signal dataset
        <SignalDatasetForm />
      ) : (
        <SelectColumns />
      )}
    </>
  );
};

export default SignalsPreferences;
