import { _ } from '_core';

import { Params } from './types';

export const validate = (params: Params) => {
  const errors = [];
  if (_.isNil(params.window)) errors.push('Window is required field.');
  return errors;
};

export const getName = (params: Params) => {
  const label = `Predicted vs. Realized Risk over Time - ${params.window} Day${params.window == 1 ? '' : 's'} Return`;
  if (params.unlevered) return `${label} (Unlevered)`;
  return label;
};
