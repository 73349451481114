import { _, helpers, ts } from '_core';

import { DraftParams } from './types';

export const validate = (params: DraftParams) => {
  if (_.isNil(params.quantiles)) return ['Quantiles is a required parameter.'];
  if (!_.isNil(params.quantiles) && (params.quantiles <= 0 || params.quantiles > 20))
    return ['Quantiles must be between 0 and 21.'];

  const rhErrors = helpers.validations.validSelectedReturnHorizon(
    params?.return_horizon || ({} as ts.types.returnHorizon.ReturnHorizon)
  );
  if (!helpers.validations.validBuckets(params.buckets)) return ['Buckets are invalid.'];
  if (!_.isEmpty(rhErrors)) return rhErrors;
  return [];
};
