import React from 'react';

import { useEffectWithoutFirst, useWatchCookie } from '../hooks';
import { _, useSelector } from '../libs';
import { BaseStore } from '../types';

/**
 * Component that handles authentication state changes by monitoring the fauthIsSet cookie
 * and reloading the page when authentication state changes.
 *
 * Required store state:
 * - ui.fauthIsSet: boolean - Indicates if user is authenticated
 *
 * @returns Empty React fragment
 */
const HandleAuthenticationChanges = () => {
  const fauthIsSet = useSelector((state: BaseStore) => state.ui.fauthIsSet);
  const fauth = useWatchCookie('fauthIsSet');

  const debouncedFauthCheck = React.useCallback(
    _.debounce((lfauth, lfauthIsSet) => {
      if (!!lfauth !== !!lfauthIsSet) window.location.reload();
    }, 500),
    []
  );

  useEffectWithoutFirst(() => {
    // We will debounce, as there could be the case in which the cookie polling takes 250ms to update
    debouncedFauthCheck(fauth, fauthIsSet);
  }, [fauth, fauthIsSet]);

  return <></>;
};

export default HandleAuthenticationChanges;
