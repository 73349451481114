import { _, hooks, mui, React } from '_core';

import { DraftParams } from './types';

type FormProps = {
  params: DraftParams;
  setParams: (_p: DraftParams) => void;
};

const Form: React.FC<FormProps> = ({ params, setParams }) => {
  const aggregationOptions = [{ value: 'ANNUAL', label: 'yearly' }];

  const [aggregation, setAggregation] = React.useState(params.aggregation || '');

  const uiStyles = hooks.useUiStyles();

  React.useEffect(() => {
    const data = { aggregation };
    setParams({ ...data });
  }, [aggregation]);

  return (
    <mui.core.Box sx={uiStyles.widgetEditMode}>
      <mui.core.Box mt={2}>
        <mui.core.TextField
          select
          label="Rebalance Frequency"
          variant="outlined"
          size="small"
          value={aggregation}
          onChange={(e) => setAggregation(e.target.value)}
          disabled
          fullWidth
          required
        >
          {aggregationOptions.map((opt) => (
            <mui.core.MenuItem key={opt.value} value={opt.value}>
              {_.startCase(opt.label)}
            </mui.core.MenuItem>
          ))}
        </mui.core.TextField>
      </mui.core.Box>
    </mui.core.Box>
  );
};

export default Form;
