import { hooks, mui, React, ts, ui } from '_core';

import { DraftParams, Params } from './types';

type FormProps = {
  params: DraftParams;
  setParams: (_p: Params) => void;
  context: ts.types.riskModel.RiskModelAnalysisContext;
  noContext: boolean;
};

const Form: React.FC<FormProps> = ({ params, setParams, context, noContext }): React.ReactElement => {
  const uiStyles = hooks.useUiStyles();

  const getInitialState = () => {
    if (params.analysis_universe_id) return params.analysis_universe_id;
    if (noContext) return null;

    return context.universe_id;
  };

  const [selectedUniverse, setSelectedUniverse] = React.useState(getInitialState);

  React.useEffect(() => {
    const data = { analysis_universe_id: selectedUniverse };
    setParams({ ...params, ...data });
  }, [selectedUniverse]);

  return (
    <mui.core.Box sx={uiStyles.widgetEditMode}>
      <mui.core.Box mt={2}>
        <ui.ResourceAutocomplete<ts.types.universe.UniverseExpanded>
          value={selectedUniverse || null}
          setValue={(id) => setSelectedUniverse(id)}
          autocompleteProps={{
            disableClearable: true,
          }}
          type={ts.enums.RESOURCES_TYPES_ENUM.UNIVERSE}
          inputProps={{
            fullWidth: true,
            label: 'Universe',
            variant: 'outlined',
            size: 'small',
          }}
        />
      </mui.core.Box>
    </mui.core.Box>
  );
};

Form.defaultProps = {
  noContext: false,
};

export default Form;
