import { _, hooks, mui, React, ts, ui } from '_core';

import { DraftParams } from './types';

type FormProps = {
  params: DraftParams;
  setParams: (_p: DraftParams) => void;
  context: ts.types.signalSet.SignalSetAnalysisContext;
  isGeneralAnalysis: boolean;
};

const Form: React.FC<FormProps> = ({ params, setParams, context, isGeneralAnalysis }) => {
  const [dailyReporting, setDailyReporting] = React.useState(params?.daily_reporting || false);
  const [returnHorizons, setReturnHorizons] = React.useState(params?.return_horizons || []);
  const [pipelinesDisplay, setPipelinesDisplay] = React.useState(
    params?.pipelines_display ?? { pipeline: true, sp_pipeline: true }
  );

  React.useEffect(() => {
    setParams(
      _.omit(
        {
          ...params,
          ...(isGeneralAnalysis ? context : {}),
          daily_reporting: dailyReporting,
          return_horizons: returnHorizons,
          pipelines_display: pipelinesDisplay,
          set_params: [
            {
              id: 'report', // Fixed id to get the file from payload on index
              file: 'sp_returns',
              file_params: {
                return_horizons: returnHorizons,
                agg_frequency: dailyReporting ? ts.enums.FREQUENCY_ENUM.DAILY : undefined,
              },
              aggregation_params: {},
              aggregation: 'fin-gen-sp-returns',
            },
          ],
          // For old widgets
        },
        ['report_definition'] as any
      )
    );
  }, [returnHorizons, dailyReporting, context, pipelinesDisplay]);

  const uiStyles = hooks.useUiStyles();
  return (
    <mui.core.Box sx={uiStyles.widgetEditMode}>
      <ui.WidgetReturnHorizon
        value={returnHorizons?.[0]}
        setValue={(val) => setReturnHorizons([val])}
        dailyReporting={dailyReporting}
        setDailyReporting={(val) => setDailyReporting(val)}
        multiple={false}
        enableDailyReporting
        context={context}
      />

      <mui.core.Box mt={4}>
        <h5 style={{ margin: '10px 0px' }}>Display</h5>
        <mui.core.Box display="flex" flexDirection="column" ml={3}>
          <mui.core.FormControlLabel
            control={
              <mui.core.Switch
                size="small"
                checked={pipelinesDisplay.pipeline}
                onChange={() => setPipelinesDisplay({ ...pipelinesDisplay, pipeline: !pipelinesDisplay.pipeline })}
              />
            }
            label="Show Pipeline in Name"
          />

          <mui.core.FormControlLabel
            control={
              <mui.core.Switch
                size="small"
                checked={pipelinesDisplay.sp_pipeline}
                onChange={() =>
                  setPipelinesDisplay({
                    ...pipelinesDisplay,
                    sp_pipeline: !pipelinesDisplay.sp_pipeline,
                  })
                }
              />
            }
            label="Show Portfolio Pipeline in Name"
          />
        </mui.core.Box>
      </mui.core.Box>
    </mui.core.Box>
  );
};

export default Form;
