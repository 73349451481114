/* eslint-disable no-unused-vars */
import { ts } from '_core';

export enum INTERACTION_PARAMS {
  TABLE_PARAMS = 'table_params',
}

export type InteractionParams = {
  [INTERACTION_PARAMS.TABLE_PARAMS]: ts.types.components.dataGrid.TableParamsSheet;
};

export type SHEETS =
  | ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.PORTFOLIO_TABULAR_YEARLY_SUMMARY
  | ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.PORTFOLIO_TABULAR_MONTHLY_SUMMARY
  | ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.PORTFOLIO_TABULAR_DETAILS
  | ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.PORTFOLIO_TABULAR_ALPHA_DETAILS;

export type Params = InteractionParams & { fullWidth: boolean };
export type DraftParams = Partial<Params>;
