import React from 'react';

import { mui, useSelector } from '../libs';
import * as types from '../types';

/**
 * AppOutdated component for displaying an outdated version of Finsera
 * 
 * @returns A component for displaying an outdated version of Finsera
 */
const AppOutdated = (): React.ReactElement => {
  const currentUiVersion = useSelector((state: types.BaseStore) => state.ui.currentUiVersion);
  const runningUiVersion = useSelector((state: types.BaseStore) => state.ui.runningUiVersion);
  const finseraOutdated = currentUiVersion !== runningUiVersion;

  return (
    <>
      {finseraOutdated && (
        <mui.core.Dialog open={finseraOutdated}>
          <mui.core.DialogContent>
            <mui.core.Box mb={3} textAlign="center">
              <mui.core.Typography variant="h6" paragraph>
                You are running an outdated version of Finsera
              </mui.core.Typography>
              <mui.core.Typography variant="body1" paragraph>
                Please update the page to load new features and prevent any issues.
              </mui.core.Typography>
              <mui.core.Box mt={2}>
                <mui.core.Button variant="contained" onClick={() => window.location.reload()} color="primary">
                  Update page
                </mui.core.Button>
              </mui.core.Box>
            </mui.core.Box>
          </mui.core.DialogContent>
        </mui.core.Dialog>
      )}
    </>
  );
};

export default AppOutdated;
