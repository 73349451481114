import { _, dnd, mui, React, ui, uitheme } from '_core';

import { ManagerContext } from '../manager-context';

const CollapsedManager = (): React.ReactElement => {
  const LARGE_DRAG_DROP_HEIGHT = uitheme.layoutSize.LARGE_DRAG_DROP_HEIGHT;
  const managerContext = React.useContext(ManagerContext);
  const { cards, moveCard, removeCard } = managerContext;

  const emptyCards = [
    <mui.core.Grid key="empty-cards" container sx={{ justifyContent: 'center' }}>
      <mui.core.Grid item xs={12} sm={6} lg={5}>
        <ui.EmptyMessage>Search for an item to include it</ui.EmptyMessage>
      </mui.core.Grid>
    </mui.core.Grid>,
  ];

  return (
    <mui.core.Box p={4}>
      <mui.core.Box mt={1}>
        <dnd.GridContextProvider onChange={moveCard}>
          <dnd.GridDropZone id="items" boxesPerRow={2} rowHeight={LARGE_DRAG_DROP_HEIGHT} style={{ height: 750 }}>
            {_.isEmpty(cards)
              ? // GridDropZone typing requires children to be an array, so emptyCards has to be a list
                emptyCards.map((emptyCard) => emptyCard)
              : cards.map((widget, index) => (
                  <ui.DragItem
                    title={widget.title}
                    index={index}
                    key={widget.id}
                    removeColumn={() => removeCard(widget.id)}
                    size="large"
                  />
                ))}
          </dnd.GridDropZone>
        </dnd.GridContextProvider>
      </mui.core.Box>
    </mui.core.Box>
  );
};

export default CollapsedManager;
