import { helpers, ts } from '_core';

/*
 * Return an object with the array of information extracted from var:
 * Variables: ['var0', 'var1']
 * Returns: [{ handle: 'signalHandle', realHandle: 'var0', theme: 'signalTheme' }]
 * The handle field can be the signal handle, signal handle + pipeline handle or sHandle + pHandle + spHandle,
 * depending if the signal or signal + pipeline appears more than once on the alpha definition
 */
export const getSignalsInfo = (variables: string[], signals: ts.types.signal.AlphaSignalEnhanced[]) => {
  const handles = helpers.buildName.signalVarNameUniq(signals || []);
  return variables.map((variable) => {
    if (variable.includes('intercept')) return { handle: variable, realHandle: variable, theme: 'Intercept' };

    const varSplitted = variable.split('var');
    const varIndex = parseInt(varSplitted.at(-1));
    const signal = signals?.[varIndex] as ts.types.signal.AlphaSignalEnhanced;
    return {
      handle: handles[varIndex],
      realHandle: variable,
      theme: signal.signal_theme || 'Other',
    };
  });
};
