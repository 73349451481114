import { _, helpers, mui, React, ts, useSelector } from '_core';

import { prepare } from './helpers';
import { Params } from './types';
import ChartWrapper from '../../common/chart-wrapper';
import createMultiScaleLineChart from '../../common/charts/create-multi-scale-line-chart';
import { getSignalsObj } from '../../common/utils/signal-set-utils';
import { getLabel } from '../../common/utils/signal-set-utils';

type ChartProps = {
  id: string;
  readOnly: boolean;
  widgetKey: string;
  loadWidgetData?: (_payload: ts.types.widgets.WidgetGetDataParams) => Promise<ts.types.widgets.WidgetGetDataResponse>;
  file: string;
  fullScreen: boolean;
  params: Params;
  context: ts.types.signalSet.SignalSetAnalysisContext;
  title: string;
  subtitle?: string;
  setExternalEmbed: (_json: ts.types.analysis.EmbedChartData) => void;
};

const Chart: React.FC<ChartProps> = ({
  id,
  readOnly,
  widgetKey,
  loadWidgetData,
  file,
  fullScreen,
  context,
  params,
  title,
  subtitle,
  setExternalEmbed,
}) => {
  const [data, setData] = React.useState<Record<string, ts.types.widgets.TableData>>(null);
  const [error, setError] = React.useState<ts.types.common.ApiError>(null);
  const [showLegend, setShowLegend] = React.useState(false);
  const [displayTabs, setDisplayTabs] = React.useState<string[]>();
  const [selectedTab, setSelectedTab] = React.useState<string>();

  const resources = useSelector((state) => state.resources);
  const signalsObj = React.useMemo(() => getSignalsObj(context), [context]);

  const timeSeries = useSelector((state) => state.resources.time_series);
  const selectedTimeSeries = React.useMemo(() => {
    return params.analysis_time_series_ids.map((tid) => timeSeries.find((ts) => ts.id == tid));
  }, []);

  const setTabs = (lData: ts.types.widgets.TableData[number]) => {
    const tabs = new Set<string>();
    Object.keys(lData).forEach((key) => {
      if (key != 'date') {
        const tab = key.split('|')[1];
        if (tab) tabs.add(tab);
      }
    });
    const tabsArr = Array.from(tabs) as string[];
    setSelectedTab(tabsArr?.[0]);
    setDisplayTabs(tabsArr);
  };

  const loadData = async () => {
    try {
      const response = await loadWidgetData({
        data: {
          file,
        },
      });
      if (response) {
        setTabs(response.data[0]);
        setData(prepare(response.data, resources, signalsObj, selectedTimeSeries));
      }
    } catch (err) {
      setError(err as ts.types.common.ApiError);
    }
  };

  React.useEffect(() => {
    if (file) loadData();
    return () => setData(null);
  }, [file]);

  React.useEffect(() => {
    // Create line chart on data and scale changes
    if (!_.isNil(data?.[selectedTab]?.[0])) {
      const inSameScale = _.without(
        Object.keys(data?.[selectedTab]?.[0]),
        ...selectedTimeSeries.map(helpers.resourcesUtils.getResourceLabel)
      );

      createMultiScaleLineChart({
        id,
        data: data[selectedTab],
        fullScreen,
        setShowLegend,
        percentLeft: true,
        exportTitle: title,
        inSameScale,
        exportSubTitle: subtitle,
        setExternalEmbed,
      });
    }
  }, [data, selectedTab, title, subtitle]);

  const getTabs = () => {
    if (displayTabs) {
      return (
        <mui.core.Tabs
          indicatorColor="primary"
          textColor="primary"
          value={selectedTab}
          onChange={(e, newTab) => setSelectedTab(newTab)}
          aria-label="Return Horizon Tabs"
        >
          {displayTabs.map((tab) => (
            <mui.core.Tab key={tab} label={getLabel(tab, context.frequency)} value={tab} />
          ))}
        </mui.core.Tabs>
      );
    }
    return null;
  };

  return (
    <ChartWrapper
      id={id}
      readOnly={readOnly}
      widgetKey={widgetKey}
      data={data}
      showLegend={showLegend}
      error={error}
      tabs={getTabs()}
      fullScreen={fullScreen}
    />
  );
};

export default Chart;
