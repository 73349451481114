import React from 'react';

import { mui } from '../../../libs';
import { Filter, Option } from '../search-types';

type SelectOptionProps = {
  selectedFilter: Filter;
  setSelectedOption: (_v: Option) => void;
  clearFilter: () => void;
};

const SelectOption: React.FC<SelectOptionProps> = ({
  selectedFilter,
  setSelectedOption,
  clearFilter,
}): React.ReactElement => {
  const theme = mui.styles.useTheme() as mui.core.Theme;

  const [posibleOption, setPosibleOption] = React.useState<string>();

  const filterOptions = React.useMemo(() => {
    if (selectedFilter.sort) return selectedFilter.sort(selectedFilter.options);
    return selectedFilter.options;
  }, [selectedFilter.options]);

  return (
    <mui.core.Box>
      <mui.core.Box p={2} display="flex">
        <mui.core.Chip
          label={selectedFilter.label}
          size="small"
          color="primary"
          sx={{ marginRight: '5px' }}
          onClick={clearFilter}
          variant="outlined"
        />
        <mui.core.Chip
          label="="
          size="small"
          color="primary"
          sx={{ marginRight: '5px' }}
          onClick={clearFilter}
          variant="outlined"
        />

        {posibleOption && <mui.core.Chip label={posibleOption} size="small" color="primary" variant="outlined" />}
      </mui.core.Box>

      {filterOptions.map((opt, index) => (
        <mui.core.MenuItem
          key={index}
          onClick={() => setSelectedOption(opt)}
          onMouseOver={() => setPosibleOption(opt.key)}
          onMouseOut={() => setPosibleOption(undefined)}
          sx={{ borderRadius: theme.spacing(1) }}
        >
          <mui.core.Typography variant="body2">
            <mui.core.Box display="flex" alignItems="center">
              <mui.core.Box
                sx={{
                  mr: 2,
                  mt: 1,
                  '& svg': {
                    fontSize: '0.9rem',
                  },
                }}
              >
                {opt.icon && opt.icon({})}
              </mui.core.Box>
              {opt.label}
            </mui.core.Box>
          </mui.core.Typography>
        </mui.core.MenuItem>
      ))}
    </mui.core.Box>
  );
};

export default SelectOption;
