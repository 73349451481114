import { helpers,ts } from '_core';

// prepareSummaryData
export const transform = (data: ts.types.widgets.TableData) => {
  const percentRows = [
    'Risk',
    'Forecast Risk',
    'Long Days ADV Average',
    'Short Days ADV Average',
    'Total Days ADV Average',
    'Long Days ADV Median',
    'Short Days ADV Median',
    'Total Days ADV Median',
  ];
  const dollarRows = ['Cash Value', 'Long Value', 'Short Value', 'Total Value'];
  const decimalRows = [
    'Holdings Correl. w/Implied Holdings',
    'Long Leverage',
    'Short Leverage',
    'Total Leverage',
    'Long Alpha Exposure',
    'Short Alpha Exposure',
    'Total Alpha Exposure',
  ];

  percentRows.forEach((metric) => {
    const idx = data.findIndex((r) => r.metric == metric);
    if (idx !== -1)
      data[idx].value = helpers.tableFormatters._formatFloat({
        value: data[idx].value as string,
        roundDigits: 2,
        multiplier: 100,
        suffix: '%',
      });
  });

  dollarRows.forEach((metric) => {
    const idx = data.findIndex((r) => r.metric == metric);
    if (idx !== -1)
      data[idx].value = helpers.tableFormatters._formatInteger({
        value: data[idx].value as string,
        numericPrefix: '$ ',
        commasOnThousands: true,
      });
  });

  decimalRows.forEach((metric) => {
    const idx = data.findIndex((r) => r.metric == metric);
    if (idx !== -1)
      data[idx].value = helpers.tableFormatters._formatFloat({
        value: data[idx].value as string,
        roundDigits: 3,
        commasOnThousands: true,
      });
  });

  const toMergeList = [
    {
      metric: 'Alpha Exposure',
      rows: [
        { metric: 'Long Alpha Exposure', column: 'long' },
        { metric: 'Short Alpha Exposure', column: 'short' },
        { metric: 'Total Alpha Exposure', column: 'value' },
      ],
    },
    {
      metric: 'Days ADV Median',
      rows: [
        { metric: 'Long Days ADV Median', column: 'long' },
        { metric: 'Short Days ADV Median', column: 'short' },
        { metric: 'Total Days ADV Median', column: 'value' },
      ],
    },
    {
      metric: 'Days ADV Average',
      rows: [
        { metric: 'Long Days ADV Average', column: 'long' },
        { metric: 'Short Days ADV Average', column: 'short' },
        { metric: 'Total Days ADV Average', column: 'value' },
      ],
    },
    {
      metric: 'Value',
      rows: [
        { metric: 'Long Value', column: 'long' },
        { metric: 'Short Value', column: 'short' },
        { metric: 'Total Value', column: 'value' },
      ],
    },
    {
      metric: 'Leverage',
      rows: [
        { metric: 'Long Leverage', column: 'long' },
        { metric: 'Short Leverage', column: 'short' },
        { metric: 'Total Leverage', column: 'value' },
      ],
    },
    {
      metric: 'Positions',
      rows: [
        { metric: 'Long Positions', column: 'long' },
        { metric: 'Short Positions', column: 'short' },
        { metric: 'Total Positions', column: 'value' },
      ],
    },
  ];

  toMergeList.forEach((toMerge) => {
    const newRow = { metric: toMerge.metric } as (typeof data)[0];

    toMerge.rows.forEach((row) => {
      const rowValue = data.find((d) => d.metric == row.metric)?.value;
      data = data.filter((d) => d.metric != row.metric);
      newRow[row.column] = rowValue;
    });

    data.push(newRow);
  });

  return data;
};
