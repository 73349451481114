import { ts } from '_core';

import { validate } from './helpers';

export default [
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.RISK_MODEL_CATEGORY_SELECTOR,
    form: true,
    title: 'Category Selection',
    neededFiles: ['risk_model_category_analysis', 'risk_model_dendrogram'],
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.RISK_MODEL_CATEGORY],
    defaultParams: {
      industry_category: true,
      currency_category: true,
      quotation_country_category: true,
      industry_min_prob: 0.3,
      industry_estimation_periods: 1,
    },

    validate,
    helpLabel: 'iri14z9r',
  },
] satisfies ts.types.analysis.WidgetConfig[];
