import { _, moment, ts } from '_core';

import { DraftParams } from './types';

export const validate = (params: DraftParams, context: ts.types.universe.UniversePreviewContext) => {
  let errors = [];
  if (_.isEmpty(params.start_date)) errors.push('Start Date is a required field.');
  if (_.isEmpty(params.end_date)) errors.push('End Date is a required field.');
  if (_.isEmpty(params.asset_filter)) errors.push('Assets is a required field.');

  if (!_.isEmpty(params.start_date) && !_.isEmpty(params.end_date)) {
    if (moment(params.start_date).isAfter(moment(params.end_date))) errors.push('Start Date must be before End Date.');
  }

  if (context) {
    if (
      !_.isEmpty(params.start_date) &&
      (moment(params.start_date).isBefore(moment(context.start_date)) ||
        moment(params.start_date).isAfter(moment(context.end_date)))
    )
      errors.push('Start Date must be inside the universe dates.');
    if (
      !_.isEmpty(params.end_date) &&
      (moment(params.end_date).isAfter(moment(context.end_date)) ||
        moment(params.end_date).isBefore(moment(context.start_date)))
    )
      errors.push('End Date must be inside the universe dates.');
  }

  if (!_.isEmpty(params.component_errors)) errors = errors.concat(params.component_errors);

  return errors;
};
