import { useTheme } from '@mui/material/styles';
import { SxProps, Theme } from '@mui/system';

import { REPORT_BAR_HEIGHT, TAB_HEIGHT } from '../theme/layout-size';

type UiStylesCollection = Record<string, SxProps<Theme>>;

const useUiStyles = (): UiStylesCollection => {
  const theme = useTheme();

  const uiStyles: UiStylesCollection = {
    hidePrint: {
      '@media print': {
        display: 'none!important',
      },
    },
    showPrint: {
      display: 'none',
      '@media print': {
        display: 'block!important',
      },
    },
    ellipsis: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      minWidth: '0',
    },
    uppercase: {
      textTransform: 'uppercase',
      letterSpacing: '1px',
    },
    helpBox: {
      background: (theme.palette as any).primaryLight.light,
      borderRadius: theme.spacing(1),
      color: (theme.palette as any).primary.main,
      padding: theme.spacing(3),
    },
    emptyBox: {
      padding: '1.5rem',
      margin: '1rem',
      borderRadius: '8px',
      textAlign: 'center',
      border: `1px solid ${(theme.palette as any).gray.light}`,
      boxShadow: '0 3px 10px rgba(0,0,0,.05)',
    },
    emptyBoxInline: {
      background: '#fff',
      border: `1px dashed ${(theme.palette as any).gray.main}`,
      padding: '0.5rem',
      borderRadius: '4px',
    },
    emptyBoxTitle: {
      borderRadius: '4px',
      fontWeight: '600',
    },
    emptyBoxContent: {
      color: (theme.palette as any).text.secondary,
      fontSize: '0.8rem',
    },
    resourceTitle: {
      fontWeight: '600',
      whiteSpace: 'nowrap',
      letterSpacing: '-.5px',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      maxWidth: '300px',
      [theme.breakpoints.down('md')]: {
        maxWidth: 240,
      },
    },
    sectionHandle: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      maxWidth: '400px',
      [theme.breakpoints.down('sm')]: {
        maxWidth: 240,
      },
    },
    editorTitle: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      minWidth: '0',
      maxWidth: '85%',
    },
    successIndicator: {
      backgroundColor: (theme.palette as any).success.main,
      width: '8px',
      height: '8px',
      borderRadius: '50%',
    },
    infoIndicator: {
      backgroundColor: (theme.palette as any).info.main,
      width: '8px',
      height: '8px',
      borderRadius: '50%',
    },
    warningIndicator: {
      backgroundColor: (theme.palette as any).warning.main,
      width: '8px',
      height: '8px',
      borderRadius: '50%',
    },
    dangerIndicator: {
      backgroundColor: (theme.palette as any).danger.main,
      width: '8px',
      height: '8px',
      borderRadius: '50%',
    },
    readOnlyMode: {
      cursor: 'not-allowed',
      pointerEvents: 'none',
    },
    pipelineItem: {
      paddingTop: '0.75rem',
      paddingBottom: '0.66rem',
      borderBottom: `1px solid ${(theme.palette as any).gray.main}`,
      '& h6': {
        fontSize: '0.7rem',
        fontWeight: '600',
        color: (theme.palette as any).text.secondary,
      },
    },
    validating: {
      position: 'absolute',
      bottom: '0.5rem',
      right: '0.5rem',
      zIndex: 100,
      display: 'flex',
      backgroundColor: (theme.palette as any).primaryLight.main,
      borderRadius: '6px',
      padding: '6px',
      fontWeight: '600',
      textTransform: 'uppercase',
      letterSpacing: '1px',
      color: (theme.palette as any).primary.main,
      boxShadow: 'none',
      alignItems: 'center',
    },
    alertRounded: {
      borderRadius: '8px!important',
    },
    alertInfo: {
      background: 'rgba(69, 116, 186, 0.05)',
      textAlign: 'center',
      color: (theme.palette as any).secondary.light,
      fontSize: '0.7rem',
      padding: '8px',
    },
    widgetEditMode: {
      paddingTop: '0.5rem',
      paddingBottom: '0.5rem',
    },
    widgetContent: {
      height: '100%',
      background: '#fff',
      flex: 1,
      alignSelf: 'stretch',
      '& .am5exporting-icon': {
        transform: 'scale(0.7)',
        background: '#F5F5F5',
      },
      '& .am5exporting-icon:hover': {
        background: '#E0E0E0',
      },
    },
    widgetWithTabsContent: {
      height: `calc(100% - ${TAB_HEIGHT} - 1px)`,
    },
    widgetLegend: {
      paddingLeft: '2rem',
    },
    widgetLegendFullscreen: {
      maxHeight: '100px',
      paddingLeft: '2rem',
      '& svg': {
        overflow: 'auto!important',
      },
    },
    titleDivider: {
      borderBottom: `1px solid ${(theme.palette as any).gray.main}`,
      margin: '2rem 0',
      textAlign: 'center',
      '& span': {
        background: (theme.palette as any).background.default,
        textTransform: 'uppercase',
        fontWeight: 'bold',
        letterSpacing: '1px',
        transform: 'translate(0px, 11px)',
        display: 'inline-block',
        padding: '4px',
        color: (theme.palette as any).text.secondary,
      },
    },
    inputHelp: {
      position: 'relative',
    },
    autocompleteWithButton: {
      background: '#fff',
      boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
      borderRadius: '4px',
      zIndex: '10',
      '& .MuiButton-label': {
        fontWeight: 'initial',
        textTransform: 'none',
        letterSpacing: '0',
        fontSize: '0.9rem',
        padding: '0 0.5rem',
        display: 'block',
        textAlign: 'left',
      },
      '& > div': {
        padding: 0,
        margin: 0,
        borderRadius: 0,
        boxShadow: 'none',
        '& > ul': {
          padding: 0,
        },
      },
    },
    headerOptimizer: {
      background: (theme.palette as any).primaryLight.light,
      padding: '0.5rem',
      fontSize: '0.7rem',
    },
    uploadBox: {
      background: '#ECF1F8',
      border: '1px solid #CBD8EC',
      borderRadius: 4,
      padding: '1rem 2rem',
      margin: '2rem 0 ',
    },
    fileBox: {
      display: 'inline-flex',
      border: `1px solid ${(theme.palette as any).background.default}`,
      padding: '0.5rem',
      boxShadow: '0 5px 10px rgba(0,0,0,.05)',
    },
    fileBoxFull: {
      color: (theme.palette as any).text.secondary,
      fontSize: '0.8rem',
      display: 'flex',
      border: `1px solid ${(theme.palette as any).background.default}80`, // Adding transparency to make it lighter
      padding: '0.5rem',
      boxShadow: '0 5px 10px rgba(0,0,0,.05)',
    },
    settingsContainer: {
      flex: 1,
      padding: '1rem',
      overflow: 'auto',
    },
    settingsFooter: {
      borderTop: '1px solid #f2f5f7',
      padding: '1rem',
      background: '#fff',
      zIndex: '1',
      display: 'flex',
    },
    bottomBarSticky: {
      position: 'absolute',
      left: 0,
      zIndex: 100,
      padding: '1rem',
      bottom: 0,
      borderTop: '1px solid #f2f5f7',
      width: '100%',
    },
    // save bar
    definitionSaveBar: {
      padding: '0 4px 8px',
      position: 'sticky',
      bottom: '0',
      zIndex: '100',
      width: '100%',
    },
    headerTabsContainer: {
      display: 'flex',
      '@media print': {
        display: 'none',
      },
    },
    headerTabs: {
      flex: 1,
    },
    tabChip: {
      height: '18px!important',
      marginLeft: '4px',
      '& span': {
        padding: '0 4px!important',
        fontSize: '0.6rem',
      },
    },
    tabsButton: {
      padding: '0px 8px!important',
    },
    uppercaseButton: {
      textTransform: 'uppercase',
      fontSize: '0.6rem',
      letterSpacing: '1px',
    },
    // replace this with smallest button style
    collapseHeaderButton: {
      background: '#f2f4fb',
      '& svg': {
        fontSize: '14px',
      },
    },
    reportContainer: {
      background: '#fff',
      borderRadius: '4px',
      boxShadow: theme.shadows[1],
      marginLeft: '4px',
      marginRight: '4px',
      minHeight: REPORT_BAR_HEIGHT,
      zIndex: 100,
      position: 'sticky',
      top: 0,
      overflow: 'hidden',
    },
    reportTitle: {
      fontSize: '0.9rem',
      textTransform: 'capitalize',
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    scrollableSection: {
      height: '100%',
      overflow: 'auto',
    },
    formItem: {
      margin: theme.spacing(1),
      width: '20ch',
    },
    dangerouslyHTML: {
      height: '100%',
      '& > div, .plotly-graph-div, .svg-container': {
        height: '100%',
      },
    },
    emptyChatContainer: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '1rem',
    },
    codeBlock: {
      backgroundColor: '#f0f4f9',
      borderRadius: '5px',
      width: '100%',
      color: '#1f1f1f',
      position: 'relative',
    },
    codeBlockContent: {
      overflowX: 'auto',
      fontSize: '12px',
      opacity: 0.9,
      width: '100%',
      maxHeight: '350px',
      padding: '1rem',
      margin: 0,
      paddingRight: '40px',
    },
    codeBlockCopy: {
      position: 'absolute',
      right: '8px',
      top: '4px',
      border: 'none',
      cursor: 'pointer',
      zIndex: 4,
      background: '#F9FAFB',
      '&:hover': {
        background: '#f2f4fb',
      },
    },
    finTable: {},
  };

  return uiStyles;
};

export default useUiStyles;
