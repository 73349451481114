import { _, hooks, mui, React, ts } from '_core';

import FactorComponent from './factor-component';
import getDefaultValue from './get-factor-default-value';

type FactorAutoCompleteProps = {
  params: ts.types.widgets.common.RiskFactorsType[];
  setParams: (_params: ts.types.widgets.common.RiskFactorsType[]) => void;
  styleOptions: string[];
};

const FactorAutoComplete: React.FC<FactorAutoCompleteProps> = ({
  params,
  setParams,
  styleOptions,
}): React.ReactElement => {
  const FTYPE_ENUM = ts.types.widgets.common.FACTOR_TYPE_ENUM;

  const [selectedRiskFactorsType, setSelectedRiskFactorsType] = React.useState(_.uniq(_.map(params, 'type')) || []);
  const [riskFactorsValue, setRiskFactorsValue] = React.useState(params || []);

  const factorNames = {
    [FTYPE_ENUM.RISK_MODEL]: 'All Risk Model Factors',
    [FTYPE_ENUM.RISK_MODEL_ALL_STYLE]: 'All Risk Model Style Factors',
    [FTYPE_ENUM.RISK_MODEL_STYLE]: 'Risk Model Style Factor',
    [FTYPE_ENUM.RISK_MODEL_CATEGORY]: 'Risk Model Category',
  };

  const riskFactors = [
    FTYPE_ENUM.RISK_MODEL,
    FTYPE_ENUM.RISK_MODEL_ALL_STYLE,
    FTYPE_ENUM.RISK_MODEL_CATEGORY,
    FTYPE_ENUM.RISK_MODEL_STYLE,
  ];

  const defaultValues = {
    [FTYPE_ENUM.RISK_MODEL]: { value: 'risk_model', isArray: false },
    [FTYPE_ENUM.RISK_MODEL_ALL_STYLE]: { value: styleOptions, isArray: true },
    [FTYPE_ENUM.RISK_MODEL_STYLE]: { value: styleOptions[0], isArray: false },
    [FTYPE_ENUM.RISK_MODEL_CATEGORY]: { value: 'industry', isArray: false },
  };

  const handleSelectedRiskChange = (value: ts.types.widgets.common.FACTOR_TYPE_ENUM[], reason: string) => {
    if (reason == 'selectOption') {
      setSelectedRiskFactorsType(value);
      const selectedValue = value[value.length - 1];
      setRiskFactorsValue([...riskFactorsValue, ...getDefaultValue(selectedValue, defaultValues[selectedValue])]);
    } else if (reason == 'removeOption') {
      setSelectedRiskFactorsType(value);
      const filteredFactors: ts.types.widgets.common.RiskFactorsType[] = [];
      riskFactorsValue.forEach((f) => {
        if (value.includes(f.type)) filteredFactors.push(f);
      });
      setRiskFactorsValue(filteredFactors);
    }
  };

  const getOptionDisabled = (option: ts.types.widgets.common.FACTOR_TYPE_ENUM, selected: string[]) => {
    // If all is selected, then there are no options
    if (selected.includes(FTYPE_ENUM.RISK_MODEL)) return true;
    // If we are here and all is not selected, then all is disabled
    if (!_.isEmpty(selected) && option == FTYPE_ENUM.RISK_MODEL) return true;

    // If all styles is selected, then risk model style is disabled
    if (selected.includes(FTYPE_ENUM.RISK_MODEL_ALL_STYLE) && option == FTYPE_ENUM.RISK_MODEL_STYLE) return true;

    // If there are no style factors selected on the definition or we are on report layout,
    // then risk_model_style is disabled
    if (option == FTYPE_ENUM.RISK_MODEL_STYLE && _.isEmpty(styleOptions)) return true;

    return false;
  };

  hooks.useEffectWithoutFirst(() => {
    setParams(riskFactorsValue);
  }, [riskFactorsValue]);

  return (
    <>
      <mui.core.Box mt={2}>
        <mui.core.Autocomplete
          getOptionLabel={(option) => factorNames[option as ts.types.widgets.common.FACTOR_TYPE_ENUM]}
          multiple
          size="small"
          value={selectedRiskFactorsType}
          options={riskFactors}
          onChange={(e, val, reason) => {
            handleSelectedRiskChange(val as ts.types.widgets.common.FACTOR_TYPE_ENUM[], reason);
          }}
          getOptionDisabled={(option) => getOptionDisabled(option, selectedRiskFactorsType)}
          renderInput={(p) => (
            <mui.core.TextField {...p} fullWidth label="Risk Factors" variant="outlined" size="small" required />
          )}
        />
        {selectedRiskFactorsType?.map((risk) => (
          <FactorComponent
            key={risk}
            factorType={risk}
            factor={riskFactorsValue}
            setFactor={setRiskFactorsValue}
            styleOptions={styleOptions}
          />
        ))}
      </mui.core.Box>
    </>
  );
};

FactorAutoComplete.defaultProps = {
  styleOptions: [],
};

export default FactorAutoComplete;
