import { _, mui, React, Route, Switch, ts, ui, useLocation } from '_core';

import { loadPublicFeatures } from './authentication/authentication-utils/api-procedures';

const RegisterLazy = React.lazy(() => import('./authentication/register'));
const LoginLazy = React.lazy(() => import('./authentication/login'));
const SignUpLazy = React.lazy(() => import('./authentication/sign-up'));
const ForgotPasswordLazy = React.lazy(() => import('./authentication/forgot-password'));
const PasswordResetLazy = React.lazy(() => import('./authentication/password-reset'));
const TermsLazy = React.lazy(() => import('./authentication/terms'));

const RegisterDeferred: React.FC<{
  features: ts.types.organization.OrganizationFeaturesDraft;
}> = ({ features }) => {
  return (
    <React.Suspense fallback={<ui.CenteredLoader top="-100px" />}>
      <RegisterLazy features={features} />
    </React.Suspense>
  );
};

const LoginDeferred: React.FC<{
  features: ts.types.organization.OrganizationFeaturesDraft;
}> = ({ features }) => {
  return (
    <React.Suspense fallback={<ui.CenteredLoader top="-100px" />}>
      <LoginLazy features={features} />
    </React.Suspense>
  );
};

const SignUpDeferred: React.FC<{
  features: ts.types.organization.OrganizationFeaturesDraft;
}> = ({ features }) => {
  return (
    <React.Suspense fallback={<ui.CenteredLoader top="-100px" />}>
      <SignUpLazy features={features} />
    </React.Suspense>
  );
};

const ForgotPasswordDeferred: React.FC<{
  features: ts.types.organization.OrganizationFeaturesDraft;
}> = ({ features }) => (
  <React.Suspense fallback={<ui.CenteredLoader top="-100px" />}>
    <ForgotPasswordLazy features={features} />
  </React.Suspense>
);

const PasswordResetDeferred: React.FC<{
  features: ts.types.organization.OrganizationFeaturesDraft;
}> = ({ features }) => {
  return (
    <React.Suspense fallback={<ui.CenteredLoader top="-100px" />}>
      <PasswordResetLazy features={features} />
    </React.Suspense>
  );
};

const TermsDeferred = () => {
  return (
    <React.Suspense fallback={<ui.CenteredLoader top="-100px" />}>
      <TermsLazy />
    </React.Suspense>
  );
};

const LoggedOutRouter = () => {
  const [loading, setLoading] = React.useState(true);
  const [publicFeatures, setPublicFeatures] = React.useState<ts.types.organization.OrganizationFeatures>({} as any);

  const initialLoad = () =>
    loadPublicFeatures(
      (resp: ts.types.organization.OrganizationFeatures) => {
        setLoading(true);
        setPublicFeatures(resp);
        setLoading(false);
      },
      () => {
        setLoading(false);
      }
    );

  React.useEffect(() => {
    initialLoad();
  }, []);

  const loc = useLocation();

  if (loading) return <ui.CenteredLoader />;

  return (
    <>
      {_.isEmpty(publicFeatures) && !loc.pathname.includes('/register') && (
        <mui.lab.Alert severity="warning" sx={{ position: 'fixed', width: '100%', zIndex: 1000 }}>
          Unable to load public features for the organization. Please review the subdomain.
        </mui.lab.Alert>
      )}
      <ui.NonAuthLayout>
        <Switch>
          <Route path="/register" render={() => <RegisterDeferred features={publicFeatures} />} />
          <Route path="/password-reset" render={() => <PasswordResetDeferred features={publicFeatures} />} />
          <Route path="/forgot-password" render={() => <ForgotPasswordDeferred features={publicFeatures} />} />
          <Route path="/terms" render={() => <TermsDeferred />} />
          {publicFeatures.free_sign_up && (
            <Route path="/sign-up" render={() => <SignUpDeferred features={publicFeatures} />} />
          )}
          <Route path="/" render={() => <LoginDeferred features={publicFeatures} />} />
        </Switch>
      </ui.NonAuthLayout>
    </>
  );
};

export default LoggedOutRouter;
