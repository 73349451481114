import React from 'react';

import { mui } from '../../../libs';
import { Filter } from '../search-types';

type SelectFilterProps = {
  filters: Filter[];
  setSelectedFilter: (_v: Filter) => void;
  selectedFilters: string[];
};

const SelectFilter: React.FC<SelectFilterProps> = ({
  filters,
  setSelectedFilter,
  selectedFilters,
}): React.ReactElement => {
  const theme = mui.styles.useTheme() as mui.core.Theme;

  return (
    <mui.core.Box>
      {filters.map((filter, index) => (
        <mui.core.MenuItem
          key={index}
          onClick={() => {
            if (selectedFilters.includes(filter.key)) return;
            setSelectedFilter(filter);
          }}
          sx={{ borderRadius: theme.spacing(1) }}
        >
          <mui.core.Typography
            color="textSecondary"
            sx={{
              mr: 2,
              mt: 1,
              '& svg': {
                fontSize: '0.9rem',
              },
            }}
          >
            {filter.icon({})}
          </mui.core.Typography>
          <mui.core.Typography variant="body2">{filter.label}</mui.core.Typography>
        </mui.core.MenuItem>
      ))}
    </mui.core.Box>
  );
};

export default SelectFilter;
