import { endpoint } from './config';
import { SHEET_CONVERSION_TYPE_ENUM } from '../enums';
import * as parse from '../helpers/parse-to-json';
import { _, $, ls } from '../libs';
import { Job, JobPaths } from '../types/job';

const S3_STACK_SIZE = 25;

const setToLs = async (data: unknown, fileName: string, jobId: number) => {
  const key = `${jobId}-${fileName}`;
  try {
    // Add key to ls
    await ls.set({ data, key });
    // Mantain ls stack
    const dropped = await ls.pushAndDropLast({
      data: key,
      stackSize: S3_STACK_SIZE,
      key: 'lastFiles',
    });
    // if we have a dropped element, then drop the key from ls
    if (dropped) {
      await ls.clear({ key: dropped });
    }
    return true;
  } catch (e) {
    return false;
  }
};

const getFromLs = async (fileName: string, jobId: number) => {
  try {
    const response = await ls.get({ key: `${jobId}-${fileName}` });
    return response;
  } catch {
    return null;
  }
};

// Usage: let fileData = await getJobFile(jobData:Object, pathKey:string)
export const getJobFile = async (job: Job, pathKey: string) => {
  const fileName = job?.result?.paths?.[pathKey] as string;
  if (!fileName) return null;
  // Check if we have the file already loaded on ls and return
  const s3Memo = await getFromLs(fileName, job.id);
  if (s3Memo) return s3Memo;
  // If we don't have it already loaded, load and store on ls
  const payload = { jobs: {} } as { jobs: Record<number, { file: string }> };
  payload.jobs[job.id] = { file: fileName };
  const signedUrlData = await $.ajax({
    method: 'POST',
    url: `${endpoint}s3sign`,
    json: payload,
  } as JQueryAjaxSettings);
  const signedUrl = _.get(signedUrlData, `data.jobs.${job.id}.file`);
  if (!signedUrl) return null;
  let data = await $.get(signedUrl);
  if (fileName.match(/\.[ct]sv$/)) data = parse.spreadsheetToJson(data, SHEET_CONVERSION_TYPE_ENUM.OBJECT);
  await setToLs(data, fileName, job.id);
  return data;
};

// filename = {key: fileName, key2: fileName2 ...}
export const signPaths = (
  filenames: JobPaths = {},
  resourceId: number = null,
  resource = 'jobs',
  isSubmission = false
): JQuery.jqXHR<{ data: { jobs: any; [key: string]: any } }> => {
  const payload = {} as Record<string, Record<string, JobPaths | string>>;
  payload[resource] = {};
  if (resourceId) payload[resource][resourceId] = filenames;
  else payload[resource] = filenames;

  return $.ajax({
    method: 'POST',
    url: `${endpoint}s3sign?is_submission=${isSubmission}`,
    json: payload,
  } as JQueryAjaxSettings);
};

export const getS3Data = (urls: Record<string, any>) => {
  const keys = Object.keys(urls);
  const promises = [] as JQueryXHR[];
  const response = {} as Record<string, Record<string, any>[] | string>;

  // add all call promises to promises array
  keys.forEach((key) => {
    const url = urls[key];
    promises.push(
      $.ajax({
        method: 'GET',
        url,
      })
    );
  });

  return new Promise((resolve, reject) => {
    Promise.all(promises)
      .then((values) => {
        // Rebuild responses object with the keys
        // {key: value, key2: value2}
        values.forEach((value, index) => {
          const url = urls[keys[index]];
          // If url is empty it means we tried to fetch the data from a null url
          // (this won't make the ajax promise to fail)

          // For the moment as the only data we are retrieving is csv, will use always the parser

          response[keys[index]] = parse.spreadsheetToJson(values[index], SHEET_CONVERSION_TYPE_ENUM.OBJECT);
          if (!url) response[keys[index]] = "File couldn't be retrieved";
        });
        resolve(response);
      })
      .catch(() => {
        reject(new Error("Couldn't fetch any file"));
      });
  });
};

// filename = {key: fileName, key2: fileName2 ...}
// resourceType => universe, signal, etc
export const getDataFromPaths = (filenames: Record<string, string> = {}, jobId: number) =>
  new Promise((resolve, reject) => {
    signPaths(filenames, jobId)
      .then((response) => {
        const { data } = response;
        // Parse data
        const s3Urls = data.jobs[jobId];
        getS3Data(s3Urls).then(resolve).catch(reject);
      })
      .catch(reject);
  });

export const getImage = async (filename: string, jobId: number) => {
  const resp = await signPaths({ filename }, jobId);
  const url = resp['data']['jobs'][jobId].filename as string;

  // Fetch the image data
  const imageResponse = await fetch(url);
  const imageBlob = await imageResponse.blob();

  // Create a URL for the blob
  const imageObjectURL = URL.createObjectURL(imageBlob);

  return imageObjectURL;
};

export const getHtmlContent = async (filename: string, jobId: number) => {
  const resp = await signPaths({ filename }, jobId);
  const signedUrl = resp['data']['jobs'][jobId].filename as string;

  // Fetch the HTML content
  const htmlContent = await $.get(signedUrl);

  return htmlContent;
};
