import { _, api, config, helpers, ts } from '_core';

import { generateSignalsFromVisual } from './definition';

type SignalSetApiResponse = {
  data: ts.types.signalSet.SignalSet;
};

export const createSignalSet = async (
  newData: ts.types.signalSet.SignalSetDraft,
  callback: (_data: ts.types.signalSet.SignalSet) => void,
  errorCallback: (_error: string) => void
) => {
  try {
    const resp: SignalSetApiResponse = await api.signal_sets.create(newData);
    callback(resp.data);
  } catch (error) {
    errorCallback(`Error creating signal set - ${helpers.parseApiError(error as ts.types.common.ApiError)}`);
  }
};

export const updateSignalSet = async (
  id: number,
  newData: ts.types.signalSet.SignalSetDraft,
  callback: (_data: ts.types.signalSet.SignalSet) => void,
  errorCallback: (_error: string) => void
) => {
  try {
    const resp: SignalSetApiResponse = await api.signal_sets.update(id, { ...newData });
    callback(resp.data);
  } catch (error) {
    errorCallback(`Error updating signal set - ${helpers.parseApiError(error as ts.types.common.ApiError)}`);
  }
};

export const keepCurrent = async (
  signalSet: ts.types.signalSet.SignalSetDraft,
  callback: (_data: { resource: ts.types.signalSet.SignalSet; universe?: ts.types.universe.Universe }) => void,
  errorCallback: (_error: string) => void
) => {
  try {
    if (signalSet.shared) return;
    const resp = await api.dates.keep_current({
      resource_id: signalSet.id,
      resource_type: 'signal_sets',
      frequency: signalSet?.ui_metadata?.visual_def?.frequency,
      // Until we don't have a universe, our keep current will be just the max date we can have
      universe_id: signalSet?.ui_metadata?.visual_def?.universe_id || undefined,
      max_date: config.features.end_date,
    });
    callback(resp);
  } catch (error) {
    errorCallback(`Error updating signal set dates - ${helpers.parseApiError(error as ts.types.common.ApiError)}`);
  }
};

export const updateDefinition = async (
  signalSet: ts.types.signalSet.SignalSet,
  definition: ts.types.signalSet.DefinitionDraft,
  isValid: boolean,
  callback: (_data: ts.types.signalSet.SignalSet, _signals: ts.types.signalSet.SignalMetadata[]) => void,
  errorCallback: (_error: string) => void
) => {
  try {
    const signals = generateSignalsFromVisual(definition as ts.types.signalSet.Definition);

    const resp = await api.signal_sets.update(
      signalSet.id,
      {
        definition: {
          signals,
        },
        ui_metadata: {
          ...signalSet.ui_metadata,
          visual_def: definition,
        },
        is_valid: isValid,
        skip_signature_check: !isValid,
      },
      true
    );

    callback(resp.data, signals);
  } catch (error) {
    errorCallback(`Error updating the definition - ${helpers.parseApiError(error as ts.types.common.ApiError)}`);
  }
};

export const updateUiMetadata = async (
  signalSet: ts.types.signalSet.SignalSet,
  uiMetaData: ts.types.signalSet.UiMetaData,
  callback: (_data: ts.types.signalSet.SignalSet) => void,
  errorCallback: (_error: string) => void
) => {
  try {
    if (signalSet.shared) return;
    const updateData = {
      ui_metadata: uiMetaData,
      skip_signature_check: true,
    } as ts.types.signalSet.SignalSetDraft;
    const resp = await api.signal_sets.update(signalSet.id, updateData);
    callback(resp.data);
  } catch (error) {
    errorCallback(`Error updating the resource metadata - ${helpers.parseApiError(error as ts.types.common.ApiError)}`);
  }
};

const createAnalyses = async (id: number, names: ts.enums.SIGNAL_SET_ANALYSIS_TYPE_ENUM[]) => {
  const analyses = [];
  for (const name of names) {
    let analysisDefinition = { widgets: [] } as ts.types.analysis.AnalysisDefinition;

    if (name == ts.enums.SIGNAL_SET_ANALYSIS_TYPE_ENUM.BROWSER) {
      // default summary value
      analysisDefinition = {
        mode: ts.enums.ANALYSIS_MODE_ENUM.FULLSCREEN,
        widgets: [
          {
            id: helpers.gibberishGenerator.stringGenerator(11),
            key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.SIGNAL_SET_BROWSER,
            params: {}, // Params will be managed on the analysis render
          },
        ],
      };
    }

    // create analysis
    const response = await api.signal_set_analyses.create({
      report_definition: analysisDefinition,
      signal_set_id: id,
      name,
    });

    analyses.push(response.data.name);
  }
  return analyses;
};

// Load analyses, and if we don't have them, create them
export const loadAnalyses = async (
  signalSet: ts.types.signalSet.SignalSet,
  setAnalysesNames: (_data: ts.enums.SIGNAL_SET_ANALYSIS_TYPE_ENUM[]) => void,
  errorCallback: (_error: string) => void
) => {
  const analyses = Object.values(ts.enums.SIGNAL_SET_ANALYSIS_TYPE_ENUM);
  try {
    const resp = await api.signal_set_analyses.search({
      ignore_user_scope: signalSet.shared,
      query: ['$and', ['signal_set_id', '=', signalSet.id]],
      include: 'id,name',
    });

    let localNames = (resp.data || []).map(
      (a: ts.types.analysis.ResourceAnalysis) => a.name
    ) as ts.enums.SIGNAL_SET_ANALYSIS_TYPE_ENUM[];

    const toCreate = _.without(analyses, ...localNames);
    if (!_.isEmpty(toCreate)) {
      const newNames = (await createAnalyses(signalSet.id, toCreate)) as ts.enums.SIGNAL_SET_ANALYSIS_TYPE_ENUM[];
      localNames = localNames.concat(newNames);
    }

    setAnalysesNames(localNames);
  } catch (error) {
    errorCallback(`Error loading signal set analyses - ${helpers.parseApiError(error as ts.types.common.ApiError)}`);
  }
};
