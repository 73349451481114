import { ts } from '_core';

import { getName, validate } from './helpers';

export default [
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.SIGNAL_BUCKETED_CUMULATIVE_RETURNS,
    title: 'Portfolio-weighted Cumulative Returns by Bucket',
    helpLabel: 'r4qi2jmo',
    form: true,
    defaultParams: {
      ui_transformation: 'CUMULATIVE',
    },
    neededFiles: ['bucketed_returns'],
    tags: [
      ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.SIGNAL,
      ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.ALPHA_MODEL,
      ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.COMMON_ALPHA_MODEL,
    ],

    getName,
    validate,
  },
] satisfies ts.types.analysis.WidgetConfig[];
