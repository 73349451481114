import { ts } from '_core';

import { getName } from './helpers';
import { DraftParams, INTERACTION_PARAMS } from './types';

export default [
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.RISK_MODEL_CROSS_SECTIONAL_CORRELATION,
    title: 'Cross-sectional Correlation',
    helpLabel: 'xahu3nz3',
    form: true,
    interactionParams: Object.values(INTERACTION_PARAMS),
    defaultParams: {
      tab: 'pearson_correlation',
      window: 21,
    } as DraftParams,
    neededFiles: ['asset_risk_return_correlation'],
    tags: [
      ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.RISK_MODEL,
      ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.COMMON_RISK_MODEL,
      ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.STATISTICAL_RISK_MODEL,
    ],

    getName,
  },
] satisfies ts.types.analysis.WidgetConfig[];
