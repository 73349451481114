import { ts } from '_core';

import { Params } from './types';

export default [
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.GENERAL_LAST_CREATED_UNIVERSES,
    form: false,
    title: 'Last Created Universes',
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.GENERAL],
    defaultParams: { by: 'universes' } as Params,

    helpLabel: 'rvvy5lyb',
  },
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.GENERAL_LAST_CREATED_SIGNALS,
    form: false,
    title: 'Last Created Signals',
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.GENERAL],
    defaultParams: { by: 'signals' } as Params,

    helpLabel: 'mkiizzln',
  },
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.GENERAL_LAST_CREATED_ALPHA_MODELS,
    form: false,
    title: 'Last Created Alpha Models',
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.GENERAL],
    defaultParams: { by: 'alpha_models' } as Params,

    helpLabel: 'h7qspw39',
  },
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.GENERAL_LAST_CREATED_PIPELINES,
    form: false,
    title: 'Last Created Pipelines',
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.GENERAL],
    defaultParams: { by: 'pipelines' } as Params,

    helpLabel: '3sqq2g4t',
  },
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.GENERAL_LAST_CREATED_BACKTESTS,
    form: false,
    title: 'Last Created Backtests',
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.GENERAL],
    defaultParams: { by: 'backtests' } as Params,

    helpLabel: 'iflxogvw',
  },
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.GENERAL_LAST_CREATED_DATASOURCES,
    form: false,
    title: 'Last Created Data Sources',
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.GENERAL],
    defaultParams: { by: 'data_sources' } as Params,

    helpLabel: '20yv5uc3',
  },
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.GENERAL_LAST_CREATED_SIGNAL_SETS,
    form: false,
    title: 'Last Created Signal Sets',
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.GENERAL],
    defaultParams: { by: 'signal_sets' } as Params,

    helpLabel: '8x0bnlex',
  },
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.GENERAL_LAST_CREATED_BACKTEST_SETS,
    form: false,
    title: 'Last Created Backtest Sets',
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.GENERAL],
    defaultParams: { by: 'backtest_sets' } as Params,

    helpLabel: 's1dhv1po',
  },
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.GENERAL_LAST_CREATED_RISK_MODELS,
    form: false,
    title: 'Last Created Risk Models',
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.GENERAL],
    defaultParams: { by: 'risk_models' } as Params,

    helpLabel: 'uc9kznzu',
  },
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.GENERAL_LAST_CREATED_BASKETS,
    form: false,
    title: 'Last Created Baskets',
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.GENERAL],
    defaultParams: { by: 'baskets' } as Params,

    helpLabel: '6xoc5cd9',
  },
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.GENERAL_LAST_CREATED_TIME_SERIES,
    form: false,
    title: 'Last Created Time Series',
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.GENERAL],
    defaultParams: { by: 'time_series' } as Params,

    helpLabel: 'ait12swk',
  },
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.GENERAL_LAST_CREATED_STRATEGIES,
    form: false,
    title: 'Last Created Strategies',
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.GENERAL],
    defaultParams: { by: 'strategies' } as Params,

    helpLabel: '3xiiock6',
  },
] satisfies ts.types.analysis.WidgetConfig[];
