import { ts } from '_core';

import { DraftParams, INTERACTION_PARAMS, Params } from './types';

export default [
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.SIGNAL_COVERAGE_BY_RBICS,
    form: true,
    title: 'Coverage by Industry',
    neededFiles: ['signal_industry_coverage'],
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.SIGNAL],
    defaultParams: { category: 'rbics_l1_id', chartType: 'rbics' } satisfies DraftParams,
    interactionParams: Object.values(INTERACTION_PARAMS),

    helpLabel: 'b2lwxlxf',
    getName: (params: Params) => {
      return `Coverage by ${ts.enums.MAPPED_RBICS_NAME[params.category]}`;
    },
  },
] satisfies ts.types.analysis.WidgetConfig[];
