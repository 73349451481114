import { _ } from '../../libs';
import { BaseStore } from '../../types/base-store';
import { Resource } from '../../types/common';

/* eslint-disable */

/* ############################# */
/*          ALL FILTERS          */
/* ############################# */

/**
 * Filters resources by label
 * @param arr - Array of resources to filter
 * @param labels - Array of label strings to match against
 * @param resources - Store containing all resources and labels
 * @returns Filtered array of resources that have matching labels
 */
const label = (arr: Resource[], labels: string[], resources: BaseStore['resources']): Resource[] => {
  const labelIds = resources.labels
    .filter((l) => {
      let included = false;
      labels.filter(Boolean).forEach((label) => {
        included = included || _.includes(l.title.toLowerCase(), label.toLowerCase());
      });
      return included;
    })
    .map((l) => l.id);

  return arr.filter((r) => r.label_ids?.some((i: number) => labelIds.indexOf(i) >= 0));
};

/**
 * Filters resources by author
 * @param arr - Array of resources to filter
 * @param authors - Array of author names to match against
 * @param resources - Store containing all resources and users
 * @returns Filtered array of resources created by matching authors
 */
const author = (arr: Resource[], authors: string[], resources: BaseStore['resources']): Resource[] => {
  const authorIds = resources.users
    .filter((u) => {
      let included = false;
      authors.filter(Boolean).forEach((author) => {
        included = included || _.includes(u.name.toLowerCase(), author.toLowerCase());
      });
      return included;
    })
    .map((a) => a.id);

  if (authors.includes('finsera')) {
    return arr.filter((r) => r.organization_id == 0 || authorIds.includes(r.created_by_id));
  }

  return arr.filter((r) => authorIds.includes(r.created_by_id));
};

/**
 * Filters resources by type
 * @param arr - Array of resources to filter
 * @param types - Array of resource types to match against
 * @returns Filtered array of resources matching the specified types
 */
const type = (arr: Resource[], types: string[]): Resource[] =>
  arr.filter((r) => {
    let included = false;
    types.filter(Boolean).forEach((type) => {
      included = included || r.resourceType?.toLowerCase() == type.toLowerCase();
    });
    return included;
  });

/**
 * Filters resources by organization
 * @param arr - Array of resources to filter
 * @param subdomains - Array of organization subdomains to match against
 * @param resources - Store containing all resources and organizations
 * @returns Filtered array of resources belonging to matching organizations
 */
const organization = (arr: Resource[], subdomains: [string], resources: BaseStore['resources']): Resource[] => {
  const orgIds = subdomains
    .map((s) => resources.published_organizations.find((o) => o.subdomain == s)?.id)
    .filter((x) => !_.isNil(x));

  return arr.filter(
    (r) => orgIds.includes(r.source_org_id) || (orgIds.includes(r.organization_id) && _.isNil(r.source_org_id))
  );
};

/**
 * Filters resources by definition content
 * @param arr - Array of resources to filter
 * @param terms - Array of terms to search for in resource definitions
 * @returns Filtered array of resources with matching definition content
 */
const definition = (arr: Resource[], terms: string[]): Resource[] =>
  arr.filter((r) => {
    const searchTerm = terms.join(',');
    try {
      const re = new RegExp(searchTerm, 'i');
      return JSON.stringify(r?.definition)?.match(re);
    } catch (err) {
      console.log(err);
      console.log('invalid regex on search');
    }
  });

/**
 * Filters resources by archived status
 * @param arr - Array of resources to filter
 * @param archivedFlagRaw - String 'true' or 'false' indicating desired archived status
 * @returns Filtered array of resources matching archived status
 */
const is_archived = (arr: Resource[], archivedFlagRaw: string): Resource[] => {
  const archivedFlag = archivedFlagRaw == 'true';
  return arr.filter((r) => r.is_deprecated == archivedFlag);
};

/**
 * Filters resources by published status
 * @param arr - Array of resources to filter
 * @param publishedFlagRaw - String 'true' or 'false' indicating desired published status
 * @returns Filtered array of resources matching published status
 */
const is_published = (arr: Resource[], publishedFlagRaw: string): Resource[] => {
  const publishedFlag = publishedFlagRaw == 'true';
  return arr.filter((r) => r.is_published == publishedFlag);
};

/**
 * Filters and sorts resources by name, handle and definition matches
 * @param arr - Array of resources to filter
 * @param searchTerm - Term to search for in resource names and handles
 * @returns Filtered and sorted array of resources matching search term
 */
const nameHandleAndDef = (arr: Resource[], searchTerm: string): Resource[] => {
  let re: RegExp;
  try {
    re = new RegExp(searchTerm, 'i');
  } catch (e) {
    console.log('invalid regex on search, escaping regex', e);
    _.escapeRegExp(searchTerm);
  }
  const nameArr = arr
    .filter((r) => {
      const def = JSON.stringify(r?.definition) || '';
      return r.name?.match(re) || r.handle?.match(re);
    })
    .map((r) => {
      if (r.name?.match(re)) return { ...r, order: 1 };
      if (r.handle?.match(re)) return { ...r, order: 2 };
      return { ...r, order: 3 };
    });
  return _.sortBy(nameArr, [(el) => (el as Resource).name.length, 'order']);
};

/**
 * Helper function to filter resources by reference to another resource
 * @param arr - Array of resources to filter
 * @param terms - Array of terms to match against referenced resources
 * @param filteredResources - Array of potential reference resources
 * @param resourceKey - Key identifying the reference relationship
 * @returns Filtered array of resources with matching references
 */
const referenceResource = (
  arr: Resource[],
  terms: string[],
  filteredResources: Resource[],
  resourceKey: string
): Resource[] =>
  arr.filter((r) => {
    if (!r[resourceKey]) return false;
    const resource = filteredResources.find((res) => res.id == r[resourceKey]);

    if (!resource) return false;
    let included = false;
    terms.filter(Boolean).forEach((term) => {
      included =
        included ||
        _.startsWith(resource.name.toLowerCase(), term.toLowerCase()) ||
        _.startsWith(resource.handle.toLowerCase(), term.toLowerCase());
    });
    return included;
  });

/**
 * Filters resources by referenced universe
 * @param arr - Array of resources to filter
 * @param universes - Array of universe names/handles to match against
 * @param resources - Store containing all resources including universes
 * @returns Filtered array of resources referencing matching universes
 */
const universe = (arr: Resource[], universes: string[], resources: BaseStore['resources']): Resource[] => {
  return referenceResource(arr, universes, resources.universes, 'universe_id');
};

/**
 * Filters resources by referenced risk model
 * @param arr - Array of resources to filter
 * @param models - Array of risk model names/handles to match against
 * @param resources - Store containing all resources including risk models
 * @returns Filtered array of resources referencing matching risk models
 */
const risk_model = (arr: Resource[], models: string[], resources: BaseStore['resources']): Resource[] => {
  return referenceResource(arr, models, resources.risk_models, 'risk_model_id');
};

/* ############################# */
/*       SIGNAL FILTERS        */
/* ############################# */

/**
 * Filters resources by theme
 * @param arr - Array of resources to filter
 * @param themes - Array of theme names to match against
 * @returns Filtered array of resources with matching themes
 */
const theme = (arr: Resource[], themes: string[]): Resource[] =>
  arr.filter((r) => {
    let included = false;
    themes.filter(Boolean).forEach((theme) => {
      if (!r.theme) included = false;
      else included = included || _.includes(r.theme.toLowerCase(), theme.toLowerCase());
    });
    return included;
  });

/* ############################# */
/*       DATASOURCE FILTER       */
/* ############################# */

/**
 * Filters resources by provider
 * @param arr - Array of resources to filter
 * @param providers - Array of provider names to match against
 * @returns Filtered array of resources with matching providers
 */
const provider = (arr: Resource[], providers: string[]): Resource[] =>
  arr.filter((r) => {
    let included = false;
    providers.filter(Boolean).forEach((provider) => {
      const localProvider = provider.toLowerCase();

      if (!r.data_provider_label && localProvider == 'unknown') included = true;
      else if (!r.data_provider_label) included = false;
      else included = included || _.includes(r.data_provider_label.toLowerCase(), localProvider);
    });
    return included;
  });

/* ############################# */
/*       UNIVERSE FILTERS        */
/* ############################# */

/**
 * Helper function to filter universes by classifier
 * @param arr - Array of resources to filter
 * @param classifiers - Array of classifier strings to match
 * @param classifiersRedux - Array of classifier resources
 * @param regex - Regular expression for extracting classifier data
 * @param nameAttr - Name attribute to match against (default: 'name')
 * @param codeAttr - Code attribute to match against (default: 'code')
 * @returns Filtered array of universes with matching classifiers
 */
const universeClassifier = (
  arr: Resource[],
  classifiers: string[],
  classifiersRedux: Resource[],
  regex: RegExp,
  nameAttr = 'name',
  codeAttr = 'code'
): Resource[] => {
  const resultsArr = type(arr, ['universe']);
  return resultsArr.filter((u) => {
    const def = JSON.stringify(u.definition?.assets);
    const rxMatch = regex.exec(def);
    let resourceClassifiers: any = [];
    if (rxMatch && rxMatch[1]) resourceClassifiers = JSON.parse(rxMatch[1]);

    let included = false;
    classifiers.filter(Boolean).forEach((classifier) => {
      const classifierObjects = classifiersRedux.filter(
        (c) =>
          classifier.toLowerCase() == c[codeAttr]?.toLowerCase() ||
          _.startsWith(c[nameAttr]?.toLowerCase(), classifier.toLowerCase())
      );

      if (!_.isEmpty(classifierObjects)) {
        included =
          included ||
          !_.isEmpty(
            _.intersection(
              classifierObjects.map((c) => c[codeAttr]),
              resourceClassifiers
            )
          );
      }
    });
    return included;
  });
};

/**
 * Object containing all available filter functions
 */
const filters: Record<string, any> = {
  author,
  definition,
  is_archived,
  is_published,
  label,
  nameHandleAndDef,
  organization,
  theme,
  type,
  provider,
};

export default filters;
