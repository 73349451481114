import React from 'react';

/**
 * Hook that works like useEffect but skips the first render
 * 
 * @param func - Effect callback function to run
 * @param deps - Array of dependencies that trigger the effect
 * 
 * @example
 * ```tsx
 * useEffectWithoutFirst(() => {
 *   console.log('This will not run on first render');
 * }, [someValue]);
 * ```
 */
const useEffectWithoutFirst = (func: React.EffectCallback, deps: React.DependencyList) => {
  const didMount = React.useRef(false);

  React.useEffect(() => {
    if (didMount.current) func();
    else didMount.current = true;
  }, deps);
};

export default useEffectWithoutFirst;
