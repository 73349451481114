import { hooks, mui, React, ts, ui } from '_core';

import { DraftParams } from './types';
import PipelineSignalsSelector from '../../common/forms/pipelined-signals-selector';

type FormProps = {
  params: DraftParams;
  setParams: (_p: DraftParams) => void;
  context?: ts.types.signal.SignalAnalysisContext;
};

const Form: React.FC<FormProps> = ({ params, setParams, context }) => {
  const [returnHorizon, setReturnHorizon] = React.useState<ts.types.returnHorizon.ReturnHorizon>(params.return_horizon);
  const [benchmark, setBenchmark] = React.useState(params.benchmark || null);
  const [signals, setSignals] = React.useState(params.analysis_pipelined_signals || [{} as any]);

  const [selectedType, setSelectedType] = React.useState(params.selected_type || 'signal');

  const uiStyles = hooks.useUiStyles();

  React.useEffect(() => {
    const localBenchmark = selectedType == 'benchmark' ? benchmark : undefined;
    const data = {
      ...params,
      selected_type: selectedType,
      return_horizon: returnHorizon,
      benchmark: localBenchmark,
      analysis_pipelined_signals: signals,
    };
    setParams(data);
  }, [returnHorizon, benchmark, signals, selectedType]);

  return (
    <mui.core.Box sx={uiStyles.widgetEditMode}>
      <mui.core.Box>
        <mui.core.FormControl component="fieldset">
          <mui.core.RadioGroup
            row
            aria-label="position"
            name="position"
            value={selectedType}
            onChange={(e) => setSelectedType(e.target.value as 'benchmark' | 'signal')}
          >
            <mui.core.FormControlLabel value="signal" control={<mui.core.Radio color="primary" />} label="Signal" />
            <mui.core.FormControlLabel
              value="benchmark"
              control={<mui.core.Radio color="primary" />}
              label="Benchmark"
            />
          </mui.core.RadioGroup>
        </mui.core.FormControl>
      </mui.core.Box>

      <mui.core.Box mt={2}>
        {selectedType == 'signal' ? (
          <PipelineSignalsSelector signals={signals} setSignals={setSignals} hide_labels={true} enforce_single={true} />
        ) : (
          <ui.Benchmark
            value={benchmark || undefined}
            setValue={setBenchmark}
            universeContextPath={'$universe.handle'}
            context={context}
            universeIdPath="universe_id"
          />
        )}
      </mui.core.Box>

      <mui.core.Box mt={4}>
        <ui.WidgetReturnHorizon<false>
          value={returnHorizon}
          setValue={(val) => setReturnHorizon(val)}
          context={context}
        />
      </mui.core.Box>
    </mui.core.Box>
  );
};

export default Form;
