/* eslint-disable no-unused-vars */
export enum INTERACTION_PARAMS {
  COMPONENT_ERRORS = 'component_errors',
}

export type InteractionParams = {
  [INTERACTION_PARAMS.COMPONENT_ERRORS]: string[];
};

export type JobParams = {
  details_start_date: string;
  details_end_date: string;
  type: 'show_all';
  basket_id: number;
};

export type Params = JobParams & InteractionParams;
export type DraftParams = Partial<Params>;
