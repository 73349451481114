import { config, ts } from '_core';

import { validate } from './utils';

export default [
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.METRIC_SIGNAL_BENCHMARK,
    title: 'Benchmark',
    helpLabel: 'idew0ixb',
    form: true,
    neededFiles: ['benchmark_returns'],
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.SIGNAL_ADHOC],

    defaultParams: {
      return_horizon: {
        periods: {
          amount: 1,
          frequency: '$frequency',
        },
        signature: '1 Period Return',
      },
    },
    validate,
    getAdhocData: (pathFile, params, _context) => {
      const benchMark = params.benchmark as {
        code: string;
        type: ts.enums.BENCHMARK_TYPES_ENUM;
      };

      let benchmarkName = '';
      if (benchMark.type == ts.enums.BENCHMARK_TYPES_ENUM.INDEX)
        benchmarkName = config.etfCodes.find((etf) => etf.FSYM_ID == benchMark.code).name;
      else benchmarkName = benchMark.code;

      return {
        name: benchmarkName,
        file_name: pathFile,
        description: `This file contains the returns of the \`${benchmarkName}\` benchmark`,
        columns: [
          {
            key: 'period_start',
            name: 'start',
            description: 'Start date of the period.',
            type: 'date',
          },
          { key: 'period_end', name: 'end', description: 'End date of the period.', type: 'date' },
          {
            key: 'benchmark_returns',
            name: 'returns',
            description: `Returns for ${benchmarkName}.`,
            type: 'float',
          },
        ],
      };
    },
  },
] satisfies ts.types.analysis.WidgetConfig[];
