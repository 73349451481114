import { _, ts } from '_core';

const validateRows = (rows: ts.types.fixedCosts.FixedCostDraft[]) => {
  const errors: string[] = [];

  const index = _.findIndex(rows, (r) => r.country_code == null);

  if (index != -1) errors.push(`Row ${index} - Country is a required field.`);

  if (_.isEmpty(errors)) {
    return { valid: true };
  }
  return { valid: false, errors };
};

export default validateRows;
