import React from 'react';

import AssetSelector from './field-values/asset-selector';
import IndustrySelector from './field-values/industry-selector';
import QuantileSelector from './field-values/quantiles-selector';
import RiskModelCategory from './field-values/risk-category';
import RiskSelector from './field-values/risk-selector';
import RiskModelStyleFactors from './field-values/risk-style-factors';
import SignalSelector from './field-values/signal-selector';
import * as enums from '../../enums';
import { mui } from '../../libs';
import * as types from '../../types';

type BucketsProps = {
  id?: string;
  setValue: (_v: types.widgets.common.BucketValue) => void;
  value: types.widgets.common.BucketValue;
  options?: types.widgets.common.BucketOptions;
  assetMasterOptions?: string[];
  industryOptions?: string[];
  disabled?: boolean;
  getBucketDefaultValue?: (_b: enums.BUCKET_TYPES_ENUM) => types.widgets.common.BucketValue;
  riskModelCategoryOnly?: boolean;
  skipWorkspaceCheck?: boolean;
  validationDef?: types.common.ValidationErrors;
};

/**
 * Component that provides bucket configuration options for various classification types
 * 
 * @param id - Optional ID for the component
 * @param setValue - Callback function to update bucket value
 * @param value - Current bucket configuration value
 * @param options - Available bucket type options
 * @param assetMasterOptions - Available asset master field options
 * @param industryOptions - Available industry classification options  
 * @param disabled - Whether the component is disabled
 * @param getBucketDefaultValue - Function to get default value for bucket type
 * @param riskModelCategoryOnly - Whether to only show risk model category
 * @param skipWorkspaceCheck - Whether to skip workspace validation
 * @param validationDef - Validation error definitions
 * 
 * @returns Rendered bucket configuration component
 */
const Buckets: React.FC<BucketsProps> = ({
  id,
  value,
  setValue,
  options = [
    {
      value: enums.BUCKET_TYPES_ENUM.LONG_SHORT,
      title: 'Long Short Classifier',
    },
    {
      value: enums.BUCKET_TYPES_ENUM.QUANTILES,
      title: 'Quantiles',
    },
    {
      value: enums.BUCKET_TYPES_ENUM.INDUSTRY,
      title: 'Industry',
    },
    {
      value: enums.BUCKET_TYPES_ENUM.RISK_MODEL,
      title: 'Risk Model',
    },
    {
      value: enums.BUCKET_TYPES_ENUM.ASSET_MASTER,
      title: 'Asset Master',
    },
    {
      value: enums.BUCKET_TYPES_ENUM.SIGNAL,
      title: 'Signal',
    },

  ],
  industryOptions = Object.values(enums.BUCKET_INDUSTRY_VALUE),
  assetMasterOptions = ['quotation_country', 'currency'],
  getBucketDefaultValue = (value: enums.BUCKET_TYPES_ENUM) => {
    if (value == enums.BUCKET_TYPES_ENUM.QUANTILES) return { value: 5 };

    if (value == enums.BUCKET_TYPES_ENUM.INDUSTRY) return { value: 'rbics_l1_name' };
    if (value == enums.BUCKET_TYPES_ENUM.SIGNAL) return { value: 5 };
    if (value == enums.BUCKET_TYPES_ENUM.RISK_MODEL_FACTORS) return { value: 5 };
    if (value == enums.BUCKET_TYPES_ENUM.ASSET_MASTER) return { value: 'quotation_country' };
    return {};
  },
  disabled = false,

  riskModelCategoryOnly,
  skipWorkspaceCheck = false,
  validationDef,

}): React.ReactElement => {
  const selectOptionMenu = () => {
    switch (value?.type) {
      case enums.BUCKET_TYPES_ENUM.QUANTILES:
        return (
          <QuantileSelector
            error={id ? validationDef?.fields?.includes(`bucket_quantiles_f${id}`) : false}
            value={value}
            setValue={setValue}
            disabled={disabled}
          />
        );
      case enums.BUCKET_TYPES_ENUM.INDUSTRY:
        return (
          <IndustrySelector
            value={value}
            setValue={setValue}
            options={industryOptions}
            disabled={disabled}
            error={id ? validationDef?.fields?.includes(`bucket_industry_f${id}`) : false}
          />
        );
      case enums.BUCKET_TYPES_ENUM.RISK_MODEL:
        if (riskModelCategoryOnly) {
          return (
            <RiskModelCategory
              value={value}
              setValue={setValue}
              disabled={disabled}
              error={id ? validationDef?.fields?.includes(`bucket_risk_f${id}`) : false}
            />
          );
        }
        return <RiskSelector value={value} setValue={setValue} />;
      case enums.BUCKET_TYPES_ENUM.RISK_MODEL_FACTORS:
        return <RiskModelStyleFactors value={value} setValue={setValue} />;
      case enums.BUCKET_TYPES_ENUM.ASSET_MASTER:
        return (
          <AssetSelector
            value={value}
            setValue={setValue}
            options={assetMasterOptions}
            disabled={disabled}
            error={id ? validationDef?.fields?.includes(`bucket_assets_f${id}`) : false}
          />
        );
      case enums.BUCKET_TYPES_ENUM.SIGNAL:
        return (
          <SignalSelector
            value={value}
            setValue={setValue}
            skipWorkspaceCheck={skipWorkspaceCheck}
            disabled={disabled}
            error={id ? validationDef?.fields?.includes(`bucket_signal_f${id}`) : false}
          />
        );
      default:
        break;
    }
  };

  return (
    <mui.core.Grid container spacing={4}>
      <mui.core.Grid item xs={12} md={6}>
        <mui.core.TextField
          select
          label="Buckets"
          variant="outlined"
          size="small"
          value={value?.type}
          onChange={(e) =>
            setValue({
              type: e.target.value as enums.BUCKET_TYPES_ENUM,
              ...getBucketDefaultValue(e.target.value as enums.BUCKET_TYPES_ENUM),
            })
          }
          disabled={disabled}
          fullWidth
        >
          {options.map((o) => (
            <mui.core.MenuItem key={o.value} value={o.value}>
              {o.title}
            </mui.core.MenuItem>
          ))}
        </mui.core.TextField>
      </mui.core.Grid>
      {selectOptionMenu()}
    </mui.core.Grid>
  );
};

export default Buckets;
