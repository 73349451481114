import { ts } from '_core';

import { getName, validate } from './helpers';
import { DraftParams, INTERACTION_PARAMS } from './types';

export default [
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.BASKET_PERFORMANCE_EVENT_STUDY,
    title: 'Time Series Event Study',
    helpLabel: '7k7l9xje',
    form: true,
    defaultParams: {
      days_before_event: 10,
      days_after_event: 21,
      is_basket: true,
      format_for_ui: true,
    } as DraftParams,
    interactionParams: Object.values(INTERACTION_PARAMS),
    neededFiles: ['backtest_time_series_event_study'],
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.BASKET],

    getName,
    validate,
  },
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.SIGNAL_PERFORMANCE_EVENT_STUDY,
    title: 'Time Series Event Study',
    helpLabel: '4jyxgwpl',
    form: true,
    defaultParams: {
      days_before_event: 10,
      days_after_event: 21,
      format_for_ui: true,
    } as DraftParams,
    interactionParams: Object.values(INTERACTION_PARAMS),
    neededFiles: ['signal_performance_event_study'],
    tags: [
      ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.SIGNAL,
      ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.ALPHA_MODEL,
      ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.COMMON_ALPHA_MODEL,
    ],

    getName,
    validate,
  },
] satisfies ts.types.analysis.WidgetConfig[];
