import { custom, helpers, ts } from '_core';

import { comparators } from 'views/report/tables/tabular/data-grid/utils';

const dollar = helpers.tableFormatters.formatTo('float', {
  align: 'right',
  roundDigits: 2,
  commasOnThousands: true,
  numericPrefix: '$',
});
const float = helpers.tableFormatters.formatTo('float', { align: 'right', roundDigits: 3, commasOnThousands: true });

export default {
  [ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.BACKTEST_TABULAR_DETAILS]: [
    {
      key: 'start_date',
      name: 'Start Date',
      filter: 'date',
      formatter: helpers.tableFormatters.formatTo('date', { align: 'right', format: 'YYYY-MM-DD' }),
    },
    {
      key: 'end_date',
      name: 'End Date',
      filter: 'date',
      formatter: helpers.tableFormatters.formatTo('date', { align: 'right', format: 'YYYY-MM-DD' }),
    },
    {
      key: 'optimal_portfolio_value',
      name: 'Initial Value',
      formatter: dollar,
      filter: 'number',
    },
    {
      key: 'optimal_cash_value',
      name: 'Initial Cash Value',
      formatter: dollar,
      filter: 'number',
    },
    {
      key: 'optimal_long_value',
      name: 'Optimal Long Value',
      formatter: dollar,
      filter: 'number',
    },
    {
      key: 'optimal_short_value',
      name: 'Optimal Short Value',
      formatter: dollar,
      filter: 'number',
    },
    {
      key: 'optimal_gross_leverage',
      name: 'Optimal Gross Leverage',
      formatter: helpers.tableFormatters.formatTo('float', { align: 'right', roundDigits: 2 }),
      filter: 'number',
    },
    {
      key: 'optimal_net_leverage',
      name: 'Optimal Net Leverage',
      formatter: helpers.tableFormatters.formatTo('float', { align: 'right', roundDigits: 2 }),
      filter: 'number',
    },
    {
      key: 'optimal_cash_weight',
      name: 'Optimal Cash Weight',
      formatter: helpers.tableFormatters.formatTo('float', { align: 'right', roundDigits: 2 }),
      filter: 'number',
    },
    {
      key: 'optimal_alpha_exposure',
      name: 'Optimal Alpha Exposure',
      formatter: helpers.tableFormatters.formatTo('float', { align: 'right', roundDigits: 2 }),
      filter: 'number',
    },
    {
      key: 'annualized_risk_forecast',
      name: 'Annualized Risk Forecast',
      formatter: helpers.tableFormatters.formatTo('float', {
        align: 'right',
        roundDigits: 3,
        multiplier: 100,
        suffix: '%',
      }),
      filter: 'number',
    },
    {
      key: 'turnover',
      name: 'Total Turnover (%)',
      formatter: helpers.tableFormatters.formatTo('float', {
        align: 'right',
        roundDigits: 2,
        multiplier: 100,
        suffix: '%',
      }),
      filter: 'number',
    },
    {
      key: 'turnover_usd',
      name: 'Total Turnover ($)',
      formatter: helpers.tableFormatters.formatTo('float', {
        align: 'right',
        roundDigits: 2,
        numericPrefix: '$',
        commasOnThousands: true,
      }),
      filter: 'number',
    },
    {
      key: 'turnover_long',
      name: 'Long Turnover (%)',
      formatter: helpers.tableFormatters.formatTo('float', {
        align: 'right',
        roundDigits: 2,
        multiplier: 100,
        suffix: '%',
      }),
      filter: 'number',
    },
    {
      key: 'turnover_long_usd',
      name: 'Long Turnover ($)',
      formatter: helpers.tableFormatters.formatTo('float', {
        align: 'right',
        roundDigits: 2,
        numericPrefix: '$',
        commasOnThousands: true,
      }),
      filter: 'number',
    },
    {
      key: 'turnover_short',
      name: 'Short Turnover (%)',
      formatter: helpers.tableFormatters.formatTo('float', {
        align: 'right',
        roundDigits: 2,
        multiplier: 100,
        suffix: '%',
      }),
      filter: 'number',
    },
    {
      key: 'turnover_short_usd',
      name: 'Short Turnover ($)',
      formatter: helpers.tableFormatters.formatTo('float', {
        align: 'right',
        roundDigits: 2,
        numericPrefix: '$',
        commasOnThousands: true,
      }),
      filter: 'number',
    },
    {
      key: 'transaction_cost_per_turnover',
      name: 'Transaction Cost Per Turnover (BPS)',
      formatter: helpers.tableFormatters.formatTo('float', { align: 'right', roundDigits: 2 }),
      filter: 'number',
    },
    {
      key: 'universe_name_count',
      name: 'Universe Name Count',
      formatter: helpers.tableFormatters.formatTo('integer', { align: 'right', commasOnThousands: true }),
      filter: 'number',
    },
    {
      key: 'name_count_for_long_names',
      name: 'Name Count For Long Names',
      formatter: helpers.tableFormatters.formatTo('integer', { align: 'right', commasOnThousands: true }),
      filter: 'number',
    },
    {
      key: 'name_count_for_short_names',
      name: 'Name Count For Short Names',
      formatter: helpers.tableFormatters.formatTo('integer', { align: 'right', commasOnThousands: true }),
      filter: 'number',
    },
    {
      key: 'total_number_of_binding_constraints',
      name: 'Total Number Of Binding Constraints',
      formatter: helpers.tableFormatters.formatTo('integer', { align: 'right', commasOnThousands: true }),
      filter: 'number',
    },
    {
      key: 'number_of_binding_asset_constraints',
      name: 'Number Of Binding Asset Constraints',
      formatter: helpers.tableFormatters.formatTo('integer', { align: 'right', commasOnThousands: true }),
      filter: 'number',
    },
    {
      key: 'number_of_binding_net_gross_constraints',
      name: 'Number Of Binding Leverage Constraints',
      formatter: helpers.tableFormatters.formatTo('integer', { align: 'right', commasOnThousands: true }),
      filter: 'number',
    },
    {
      key: 'number_of_binding_factor_constraints',
      name: 'Number Of Binding Portfolio Constraints',
      formatter: helpers.tableFormatters.formatTo('integer', { align: 'right', commasOnThousands: true }),
      filter: 'number',
    },
    {
      key: 'before_costs_portfolio_return',
      name: 'Before Costs Return',
      formatter: helpers.tableFormatters.formatTo('float', {
        align: 'right',
        roundDigits: 2,
        multiplier: 100,
        suffix: '%',
      }),
      filter: 'number',
    },
    {
      key: 'after_costs_portfolio_return',
      name: 'After Costs Return',
      formatter: helpers.tableFormatters.formatTo('float', {
        align: 'right',
        roundDigits: 2,
        multiplier: 100,
        suffix: '%',
      }),
      filter: 'number',
    },
    {
      key: 'before_costs_value',
      name: 'Before Costs Final Portfolio Value',
      formatter: dollar,
      filter: 'number',
    },
    {
      key: 'after_costs_value',
      name: 'After Costs Final Portfolio Value',
      formatter: dollar,
      filter: 'number',
    },
    {
      key: 'transaction_cost',
      name: 'Transaction Cost (BPS)',
      formatter: helpers.tableFormatters.formatTo('float', { align: 'right', roundDigits: 2 }),
      filter: 'number',
    },
    {
      key: 'benchmark_return',
      name: 'Benchmark Return',
      formatter: helpers.tableFormatters.formatTo('float', {
        align: 'right',
        roundDigits: 2,
        multiplier: 100,
        suffix: '%',
      }),
      filter: 'number',
    },
    {
      key: 'before_costs_after_returns',
      name: 'Before Costs Active Returns',
      formatter: helpers.tableFormatters.formatTo('float', {
        align: 'right',
        roundDigits: 2,
        multiplier: 100,
        suffix: '%',
      }),
      filter: 'number',
    },
    {
      key: 'after_costs_active_returns',
      name: 'After Costs Active Returns',
      formatter: helpers.tableFormatters.formatTo('float', {
        align: 'right',
        roundDigits: 2,
        multiplier: 100,
        suffix: '%',
      }),
      filter: 'number',
    },
    {
      key: 'annualized_active_risk_forecast',
      name: 'Annualized Active Risk Forecast',
      formatter: helpers.tableFormatters.formatTo('float', {
        align: 'right',
        roundDigits: 3,
        multiplier: 100,
        suffix: '%',
      }),
      filter: 'number',
    },
    {
      key: 'benchmark_correlation',
      name: 'Correlation(Optimal Holdings, Benchmark Holdings)',
      formatter: helpers.tableFormatters.formatTo('float', { align: 'right', roundDigits: 2, commasOnThousands: true }),
      filter: 'number',
    },
    {
      key: 'alpha_correlation',
      name: 'Correlation(Optimal holdings, Alpha:Pipelines)',
      formatter: helpers.tableFormatters.formatTo('float', { align: 'right', roundDigits: 2, commasOnThousands: true }),
      filter: 'number',
    },
    {
      key: 'cp_correlation',
      name: 'Correlation(Optimal Holdings, characteristicPortfolio(Alpha:Pipelines, grossLeverage = 1.0))',
      formatter: helpers.tableFormatters.formatTo('float', { align: 'right', roundDigits: 2, commasOnThousands: true }),
      filter: 'number',
    },
    {
      key: 'optimizer_error',
      name: 'Optimizer Error',
      formatter: helpers.tableFormatters.formatTo('string', { align: 'left' }),
      filter: 'string',
    },
  ] as ts.types.components.dataGrid.ColumnsData,
  [ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.BACKTEST_TABULAR_YEARLY_SUMMARY]: [
    {
      key: 'year',
      name: 'Year',
      formatter: helpers.tableFormatters.formatTo('string', { align: 'left' }),
      filter: 'string',
      defaultSortComparator: comparators.compareHistoryPeriods,
    },
    {
      key: 'start_date',
      name: 'Start Date',
      filter: 'date',
      formatter: helpers.tableFormatters.formatTo('date', { align: 'right', format: 'YYYY-MM-DD' }),
    },
    {
      key: 'end_date',
      name: 'End Date',
      filter: 'date',
      formatter: helpers.tableFormatters.formatTo('date', { align: 'right', format: 'YYYY-MM-DD' }),
    },
    {
      key: 'average_optimal_gross_leverage',
      name: 'Average Optimal Gross Leverage',
      formatter: helpers.tableFormatters.formatTo('float', { align: 'right', roundDigits: 2 }),
      filter: 'number',
    },
    {
      key: 'average_optimal_net_leverage',
      name: 'Average Optimal Net Leverage',
      formatter: helpers.tableFormatters.formatTo('float', { align: 'right', roundDigits: 2 }),
      filter: 'number',
    },
    {
      key: 'average_optimal_cash_weight',
      name: 'Average Optimal Cash Weight',
      formatter: helpers.tableFormatters.formatTo('float', { align: 'right', roundDigits: 2 }),
      filter: 'number',
    },
    {
      key: 'cumulative_after_costs_portfolio_return',
      name: 'Cumulative After Costs Return',
      formatter: helpers.tableFormatters.formatTo('float', {
        align: 'right',
        roundDigits: 2,
        multiplier: 100,
        suffix: '%',
      }),
      filter: 'number',
    },
    {
      key: 'cumulative_before_costs_portfolio_return',
      name: 'Cumulative Before Costs Return',
      formatter: helpers.tableFormatters.formatTo('float', {
        align: 'right',
        roundDigits: 2,
        multiplier: 100,
        suffix: '%',
      }),
      filter: 'number',
    },
    {
      key: 'annualized_before_costs_portfolio_return',
      name: 'Annualized Before Costs Return',
      formatter: helpers.tableFormatters.formatTo('float', {
        align: 'right',
        roundDigits: 2,
        multiplier: 100,
        suffix: '%',
      }),
      filter: 'number',
    },
    {
      key: 'annualized_after_costs_portfolio_return',
      name: 'Annualized After Costs Return',
      formatter: helpers.tableFormatters.formatTo('float', {
        align: 'right',
        roundDigits: 2,
        multiplier: 100,
        suffix: '%',
      }),
      filter: 'number',
    },
    {
      key: 'annualized_after_costs_portfolio_risk',
      name: 'Annualized After Costs Risk',
      formatter: helpers.tableFormatters.formatTo('float', {
        align: 'right',
        roundDigits: 3,
        multiplier: 100,
        suffix: '%',
      }),
      filter: 'number',
    },
    {
      key: 'annualized_after_costs_portfolio_risk_adjusted_return',
      name: 'Annualized After Costs Risk Adjusted Return',
      formatter: helpers.tableFormatters.formatTo('float', { align: 'right', roundDigits: 2 }),
      filter: 'number',
    },
    {
      key: 'cumulative_benchmark_return',
      name: 'Cumulative Benchmark Return',
      formatter: helpers.tableFormatters.formatTo('float', {
        align: 'right',
        roundDigits: 2,
        multiplier: 100,
        suffix: '%',
      }),
      filter: 'number',
    },
    {
      key: 'annualized_benchmark_risk',
      name: 'Annualized Benchmark Risk',
      formatter: helpers.tableFormatters.formatTo('float', {
        align: 'right',
        roundDigits: 3,
        multiplier: 100,
        suffix: '%',
      }),
      filter: 'number',
    },
    {
      key: 'annualized_benchmark_risk_adjusted_return',
      name: 'Annualized Benchmark Risk Adjusted Return',
      formatter: helpers.tableFormatters.formatTo('float', { align: 'right', roundDigits: 2 }),
      filter: 'number',
    },
    {
      key: 'average_turnover',
      name: 'Average Turnover',
      formatter: helpers.tableFormatters.formatTo('float', { align: 'right', roundDigits: 2 }),
      filter: 'number',
    },
    {
      key: 'average_transaction_cost_per_turnover',
      name: 'Average Transaction Cost Per Turnover (BPS)',
      formatter: helpers.tableFormatters.formatTo('float', { align: 'right', roundDigits: 2 }),
      filter: 'number',
    },
    {
      key: 'average_universe_name_count',
      name: 'Average Universe Name Count',
      formatter: helpers.tableFormatters.formatTo('integer', { align: 'right', commasOnThousands: true }),
      filter: 'number',
    },
    {
      key: 'average_name_count_for_long_names',
      name: 'Average Name Count For Long Names',
      formatter: helpers.tableFormatters.formatTo('integer', { align: 'right', commasOnThousands: true }),
      filter: 'number',
    },
    {
      key: 'average_name_count_for_short_names',
      name: 'Average Name Count For Short Names',
      formatter: helpers.tableFormatters.formatTo('integer', { align: 'right', commasOnThousands: true }),
      filter: 'number',
    },
    {
      key: 'average_benchmark_correlation',
      name: 'Average Correlation(Optimal Holdings, Benchmark Holdings)',
      formatter: helpers.tableFormatters.formatTo('float', { align: 'right', roundDigits: 2, commasOnThousands: true }),
      filter: 'number',
    },
    {
      key: 'average_alpha_correlation',
      name: 'Average Correlation(Optimal holdings, Alpha:Pipelines)',
      formatter: helpers.tableFormatters.formatTo('float', { align: 'right', roundDigits: 2, commasOnThousands: true }),
      filter: 'number',
    },
    {
      key: 'average_cp_correlation',
      name: 'Average Correlation(Optimal Holdings, characteristicPortfolio(Alpha:Pipelines, grossLeverage = 1.0))',
      formatter: helpers.tableFormatters.formatTo('float', { align: 'right', roundDigits: 2, commasOnThousands: true }),
      filter: 'number',
    },
    {
      key: 'max_drawdown',
      name: 'Max Drawdown',
      formatter: helpers.tableFormatters.formatTo('float', { align: 'right', roundDigits: 2 }),
      filter: 'number',
    },
    {
      key: 'optimizer_error_count',
      name: 'Optimizer Error Count',
      formatter: helpers.tableFormatters.formatTo('integer', { align: 'right', commasOnThousands: true }),
      filter: 'string',
    },
  ] as ts.types.components.dataGrid.ColumnsData,
  [ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.BACKTEST_TABULAR_MONTHLY_SUMMARY]: [
    {
      key: 'month',
      name: 'Month',
      filter: 'date',
      formatter: helpers.tableFormatters.formatTo('date', { align: 'right', format: 'YYYY-MM' }),
    },
    {
      key: 'start_date',
      name: 'Start Date',
      filter: 'date',
      formatter: helpers.tableFormatters.formatTo('date', { align: 'right', format: 'YYYY-MM-DD' }),
    },
    {
      key: 'end_date',
      name: 'End Date',
      filter: 'date',
      formatter: helpers.tableFormatters.formatTo('date', { align: 'right', format: 'YYYY-MM-DD' }),
    },
    {
      key: 'average_optimal_gross_leverage',
      name: 'Average Optimal Gross Leverage',
      formatter: helpers.tableFormatters.formatTo('float', { align: 'right', roundDigits: 2 }),
      filter: 'number',
    },
    {
      key: 'average_optimal_net_leverage',
      name: 'Average Optimal Net Leverage',
      formatter: helpers.tableFormatters.formatTo('float', { align: 'right', roundDigits: 2 }),
      filter: 'number',
    },
    {
      key: 'average_optimal_cash_weight',
      name: 'Average Optimal Cash Weight',
      formatter: helpers.tableFormatters.formatTo('float', { align: 'right', roundDigits: 2 }),
      filter: 'number',
    },
    {
      key: 'cumulative_after_costs_portfolio_return',
      name: 'Cumulative After Costs Return',
      formatter: helpers.tableFormatters.formatTo('float', {
        align: 'right',
        roundDigits: 2,
        multiplier: 100,
        suffix: '%',
      }),
      filter: 'number',
    },
    {
      key: 'cumulative_before_costs_portfolio_return',
      name: 'Cumulative Before Costs Return',
      formatter: helpers.tableFormatters.formatTo('float', {
        align: 'right',
        roundDigits: 2,
        multiplier: 100,
        suffix: '%',
      }),
      filter: 'number',
    },
    {
      key: 'cumulative_benchmark_return',
      name: 'Cumulative Benchmark Return',
      formatter: helpers.tableFormatters.formatTo('float', {
        align: 'right',
        roundDigits: 2,
        multiplier: 100,
        suffix: '%',
      }),
      filter: 'number',
    },
    {
      key: 'average_turnover',
      name: 'Average Turnover',
      formatter: helpers.tableFormatters.formatTo('float', { align: 'right', roundDigits: 2 }),
      filter: 'number',
    },
    {
      key: 'average_transaction_cost_per_turnover',
      name: 'Average Transaction Cost Per Turnover (BPS)',
      formatter: helpers.tableFormatters.formatTo('float', { align: 'right', roundDigits: 2 }),
      filter: 'number',
    },
    {
      key: 'average_universe_name_count',
      name: 'Average Universe Name Count',
      formatter: helpers.tableFormatters.formatTo('integer', { align: 'right', commasOnThousands: true }),
      filter: 'number',
    },
    {
      key: 'average_name_count_for_long_names',
      name: 'Average Name Count For Long Names',
      formatter: helpers.tableFormatters.formatTo('integer', { align: 'right', commasOnThousands: true }),
      filter: 'number',
    },
    {
      key: 'average_name_count_for_short_names',
      name: 'Average Name Count For Short Names',
      formatter: helpers.tableFormatters.formatTo('integer', { align: 'right', commasOnThousands: true }),
      filter: 'number',
    },
    {
      key: 'average_benchmark_correlation',
      name: 'Average Correlation(Optimal Holdings, Benchmark Holdings)',
      formatter: helpers.tableFormatters.formatTo('float', { align: 'right', roundDigits: 2, commasOnThousands: true }),
      filter: 'number',
    },
    {
      key: 'average_alpha_correlation',
      name: 'Average Correlation(Optimal holdings, Alpha:Pipelines)',
      formatter: helpers.tableFormatters.formatTo('float', { align: 'right', roundDigits: 2, commasOnThousands: true }),
      filter: 'number',
    },
    {
      key: 'average_cp_correlation',
      name: 'Average Correlation(Optimal Holdings, characteristicPortfolio(Alpha:Pipelines, grossLeverage = 1.0))',
      formatter: helpers.tableFormatters.formatTo('float', { align: 'right', roundDigits: 2, commasOnThousands: true }),
      filter: 'number',
    },
    {
      key: 'optimizer_error_count',
      name: 'Optimizer Error Count',
      formatter: helpers.tableFormatters.formatTo('integer', { align: 'right', commasOnThousands: true }),
      filter: 'string',
    },
  ] as ts.types.components.dataGrid.ColumnsData,
  [ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.BACKTEST_TABULAR_ALPHA_DETAILS]: [
    {
      key: 'build_date',
      name: 'Date',
      filter: 'date',
      formatter: helpers.tableFormatters.formatTo('date', { align: 'right', format: 'YYYY-MM-DD' }),
    },
    {
      key: 'alpha_exposure',
      name: 'Alpha Exposure',
      formatter: float,
      filter: 'number',
    },
    {
      key: 'transfer_coefficient',
      name: 'Transfer Coefficient',
      formatter: float,
      filter: 'number',
    },
    {
      key: 'market',
      name: 'Market',
      formatter: float,
      filter: 'number',
    },
    {
      key: '_handle_', // Dynamic
      name: 'Risk Style Factors',
      formatter: float,
      filter: 'inferred',
      members: (key) => key.startsWith('_handle_'),
      cleanNameFormatter: (key, _c, resources: ts.StoreState['resources']) => {
        const handle = key.split('_handle_')[1];
        const signal = resources.signals.find((s) => s.handle === handle);
        return `Exposure to Risk Factors: ${signal?.name ?? handle}`;
      },
      nameFormatter: (key, _c, resources: ts.StoreState['resources']) => {
        const handle = key.split('_handle_')[1];
        const signal = resources.signals.find((s) => s.handle === handle);
        return <custom.MultilineTableHeader name={['Exposure to Risk Factors:', signal?.name ?? handle]} />;
      },
    },
    {
      key: '_industry_', // Dynamic
      name: 'Industry Factors',
      formatter: float,
      filter: 'inferred',
      members: (key) => key.startsWith('_industry_'),
      cleanNameFormatter: (key) => `Exposure to Industry Factors: ${key.split('_industry_')[1]}`,
      nameFormatter: (key) => (
        <custom.MultilineTableHeader name={['Exposure to Industry Factors:', key.split('_industry_')[1]]} />
      ),
    },
    {
      key: '_alpha_exposures_', // Dynamic
      name: 'Alpha Factor Exposures',
      formatter: float,
      filter: 'inferred',
      members: (key) => key.startsWith('_alpha_exposures_'),
      cleanNameFormatter: (key) => `Exposure to Alpha Signals: ${key.split('_alpha_exposures_')[1]}`,
      nameFormatter: (key) => (
        <custom.MultilineTableHeader name={['Exposure to Alpha Signals:', key.split('_alpha_exposures_')[1]]} />
      ),
    },
    {
      key: '_alpha_weights_', // Dynamic
      name: 'Alpha Weight',
      formatter: float,
      filter: 'inferred',
      members: (key) => key.startsWith('_alpha_weights_'),
      cleanNameFormatter: (key) => `Weight: ${key.split('_alpha_weights_')[1]}`,
      nameFormatter: (key) => <custom.MultilineTableHeader name={['Weight:', key.split('_alpha_weights_')[1]]} />,
    },
  ] as ts.types.components.dataGrid.ColumnsData,
};
