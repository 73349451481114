import { ts } from '_core';

// prepareRiskFactorExposureData
const transform = (data: ts.types.widgets.LinearData, tab: 'risk_factor' | 'industries' | 'other') => {
  const newData: ts.types.widgets.LinearData = {};

  const riskFactorCols = ['specific_risk'];

  Object.keys(data).forEach((key) => {
    if (tab == 'industries' && key.startsWith('_industry_')) newData[key.replace('_industry_', '')] = data[key];
    if (tab == 'risk_factor' && riskFactorCols.includes(key)) newData['Specific Risk'] = data[key];
    if (tab == 'other' && !riskFactorCols.includes(key) && !key.startsWith('_industry_'))
      newData[key.replace('handle', '')] = data[key];
  });

  return newData;
};

export { transform };
