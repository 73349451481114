import { am4charts, am4core } from '_core';

export const createSelectAllButton = (chart: am4charts.XYChart) => {
  let isAllHidden = false;

  const eyeOpenPath = `M12 5C8.24 5 4.83 7.35 3 11c1.83 3.65 5.24 6 9 6s7.17-2.35 9-6c-1.83-3.65-5.24-6-9-6zm0 
  10c-2.48 0-4.5-2.02-4.5-4.5S9.52 6 12 6s4.5 2.02 4.5 4.5S14.48 15 12 
  15zm0-7.5c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z`;
  const eyeClosedPath = `M12 5c-.7 0-1.4.1-2 .3l1.2 1.2c.3-.1.5-.1.8-.1 2.5 0 4.5 2 4.5 4.5 0 .3 0 .5-.1.8l1.2 
  1.2c.2-.6.3-1.3.3-2 0-3.6-2.9-6.5-6.5-6.5zm-1 7.9c.2.1.3.1.5.1 1.7 0 3-1.3 3-3 0-.2 0-.3-.1-.5l-3.4 3.4zM4 
  4.7l2.8 2.8C5.6 8.7 4.3 9.7 3 11c1.8 3.6 5.2 6 9 6 1.2 0 2.3-.2 3.4-.5l2.9 2.9 1.3-1.3L5.3 3.4 4 4.7zm4.7 4.7l1.5 
  1.5c0-.3-.1-.5-.1-.8 0-1.7 1.3-3 3-3 .3 0 .5 0 .8.1l1.5-1.5C14.9 5.2 13.5 5 12 5c-2.9 
  0-5.5 1.4-7.3 3.5l2.2 2.2c.5-.6 1.1-1 1.8-1.3z`;

  chart.legend.data = [...chart.legend.data, { name: 'Hide All' }];

  chart.legend.itemContainers.template.events.on('hit', function (ev) {
    const item = ev.target as any;
    const itemName = item.dataItem?.name;

    if (itemName?.includes('Show All') || itemName?.includes('Hide All')) {
      isAllHidden = !isAllHidden;

      chart.legend.data = [...chart.legend.data.slice(0, -1), { name: isAllHidden ? 'Show All' : 'Hide All' }];

      chart.series.each((series) => {
        if (!series.hiddenInLegend) {
          isAllHidden ? series.hide() : series.show();
        }
      });
    }
  });

  const marker = chart.legend.markers.template.children.getIndex(0);

  marker.adapter.add('path', (path, target) => {
    if (!target.dataItem) {
      return path;
    }
    const item = target.dataItem as any;

    if (item.name.includes('Hide All')) {
      return eyeOpenPath;
    }
    if (item.name.includes('Show All')) {
      return eyeClosedPath;
    }
    return path;
  });

  marker.adapter.add('scale', (scale, target) => {
    if (!target.dataItem) {
      return scale;
    }
    const item = target.dataItem as any;

    if (item.name.includes('Hide All') || item.name.includes('Show All')) {
      return 0.6;
    }
    return scale;
  });

  marker.horizontalCenter = 'middle';
  marker.verticalCenter = 'middle';

  marker.adapter.add('fill', (fill, target) => {
    if (!target.dataItem) {
      return fill;
    }
    const item = target.dataItem as any;

    if (item.name.includes('Hide All') || item.name.includes('Show All')) {
      return am4core.color('#4574BA');
    }
    return fill;
  });
};
