import { hooks, mui, React } from '_core';

import { DraftParams } from './types';

type FormProps = {
  params: DraftParams;
  setParams: (_p: DraftParams) => void;
};

const Form: React.FC<FormProps> = ({ params, setParams }) => {
  const [statisticType, setStatisticType] = React.useState(params.statisticType || 'regression');

  const uiStyles = hooks.useUiStyles();

  React.useEffect(() => {
    const data = { ...params, statisticType };
    setParams(data);
  }, [statisticType]);

  return (
    <mui.core.Box sx={uiStyles.widgetEditMode}>
      {!params.overall_stats && (
        <mui.core.Box mt={4}>
          <mui.core.TextField
            select
            fullWidth
            value={statisticType}
            onChange={(e) => {
              setStatisticType(e.target.value as DraftParams['statisticType']);
            }}
            variant="outlined"
            size="small"
          >
            <mui.core.MenuItem value={'regression'} style={params.overall_stats ? { display: 'none' } : {}}>
              Regression Coefficient
            </mui.core.MenuItem>
            <mui.core.MenuItem value={'tStatistic'} style={params.overall_stats ? { display: 'none' } : {}}>
              T-Statistics
            </mui.core.MenuItem>
            <mui.core.MenuItem value={'pValue'} style={params.overall_stats ? { display: 'none' } : {}}>
              P-Value
            </mui.core.MenuItem>
          </mui.core.TextField>
        </mui.core.Box>
      )}
    </mui.core.Box>
  );
};

export default Form;
