import { ts } from '_core';

import { validate } from './helpers';
import { INTERACTION_PARAMS, optionLabels, Params } from './types';
import { DraftParams } from '../linear-alpha-tabular-significance/types';

export default [
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.BASKET_COMPARE_PERFORMANCE,
    title: 'Performance',
    form: true,
    defaultParams: {
      tabular_view: true,
      histories: Object.values(ts.enums.HISTORY_ENUM_EXTENDED),
      statistics_types: ['cumulative_returns', 'return', 'risk', 'adjusted'],
    } as DraftParams,
    generalAnalysisPrefix: 'Basket ',
    interactionParams: Object.values(INTERACTION_PARAMS),
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.GENERAL, ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.BASKET_COMPARE],
    neededFiles: ['backtest_annualized_statistics'],

    getSubtitle: (params: Params) => {
      if ((params.statistics_types ?? []).length == 1) return optionLabels[params.statistics_types[0]];
      return '';
    },
    validate,
    helpLabel: 'e6u6p93a',
    generalAnalysisReportHelp: {
      document: ts.enums.UI_RESOURCE_TYPE_ENUM.BASKET,
      contentPath: `/${ts.enums.BASKET_ANALYSIS_TYPE_ENUM.COMPARE}`,
    },
  },
] satisfies ts.types.analysis.WidgetConfig[];
