import { hooks, React, ts } from '_core';

import { TABLE_SHEET_KEY_ENUM } from '@local/finsera-core/src/enums/report';
import Tabular from 'views/report/tables/tabular';

import defaultColumns from './columns';
import StatisticsField from './statistics-field';
import { DraftParams, Params, SHEETS } from './types';
import { getColumns } from '../../common/charts/utils';

type ChartProps = {
  file: string;
  fullScreen: boolean;
  loadWidgetData: (_payload: ts.types.widgets.WidgetGetDataParams) => Promise<ts.types.widgets.WidgetGetDataResponse>;
  params: Params & DraftParams;
  setParams: (_p: DraftParams) => void;

  goFullScreen?: (_q: string) => void;
  queryParams?: { initial?: string };
};

const Chart: React.FC<ChartProps> = ({
  loadWidgetData,
  params,
  setParams,
  file,
  fullScreen,
  goFullScreen,
  queryParams,
}): React.ReactElement => {
  const [error, setError] = React.useState<ts.types.common.ApiError>(null);
  const [data, setData] = React.useState<Record<string, ts.types.widgets.TableData>>();
  const [tableParams, setTableParams] = React.useState<ts.types.components.dataGrid.TableParamsSheet>(
    params.table_params
  );

  const [selectedStatistics, setSelectedStatistics] = React.useState<
    ts.enums.REPORT_ENUMS.ANNU_STATISTICS_ENUM | 'cumulative_returns'
  >(ts.enums.REPORT_ENUMS.ANNU_STATISTICS_ENUM.RETURNS);

  hooks.useEffectWithoutFirst(() => {
    setParams({
      ...params,
      table_params: { ...tableParams },
    });
  }, [tableParams]);

  const loadData = async () => {
    try {
      const resp = await loadWidgetData({
        data: {
          file,
        },
      });

      if (resp) {
        setData({
          [ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.BACKTEST_BUCKET_ANALYSIS_RETURN_DETAILS]: resp.data.filter(
            (el) => el.tab == 'return_details'
          ),
          [ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.BACKTEST_BUCKET_ANALYSIS_BUCKET_ALLOCATION_DETAILS]:
            resp.data.filter((el) => el.tab == 'bucket_allocation_details'),
          [TABLE_SHEET_KEY_ENUM.BACKTEST_BUCKET_ANALYSIS_BUCKET_STOCK_SELECTION_DETAILS]: resp.data.filter(
            (el) => el.tab == 'bucket_stock_selection_details'
          ),
          [TABLE_SHEET_KEY_ENUM.BACKTEST_BUCKET_ANALYSIS_RETURN_SUMMARY]: resp.data.filter(
            (el) => el.tab == 'return_summary' && el.statistics_type == selectedStatistics
          ),
          [TABLE_SHEET_KEY_ENUM.BACKTEST_BUCKET_ANALYSIS_BUCKET_ALLOCATION_SUMMARY]: resp.data.filter(
            (el) => el.tab == 'bucket_allocation_summary' && el.statistics_type == selectedStatistics
          ),
          [TABLE_SHEET_KEY_ENUM.BACKTEST_BUCKET_ANALYSIS_BUCKET_STOCK_SELECTION_SUMMARY]: resp.data.filter(
            (el) => el.tab == 'bucket_stock_selection_summary' && el.statistics_type == selectedStatistics
          ),
        });
      }
    } catch (err) {
      setError(err as ts.types.common.ApiError);
    }
  };

  React.useEffect(() => {
    if (file) loadData();
    return () => setData(null);
  }, [file, selectedStatistics]);

  const createColumnGetter = (key: SHEETS) => (colKeys: string[]) =>
    getColumns(
      colKeys,
      {
        columns: defaultColumns[key].map((el) => el.key),
      },
      defaultColumns[key]
    );

  return (
    <Tabular<SHEETS>
      key={file}
      data={data}
      apiError={error}
      getColumns={{
        [TABLE_SHEET_KEY_ENUM.BACKTEST_BUCKET_ANALYSIS_RETURN_DETAILS]: createColumnGetter(
          TABLE_SHEET_KEY_ENUM.BACKTEST_BUCKET_ANALYSIS_RETURN_DETAILS
        ),
        [TABLE_SHEET_KEY_ENUM.BACKTEST_BUCKET_ANALYSIS_BUCKET_ALLOCATION_DETAILS]: createColumnGetter(
          TABLE_SHEET_KEY_ENUM.BACKTEST_BUCKET_ANALYSIS_BUCKET_ALLOCATION_DETAILS
        ),
        [TABLE_SHEET_KEY_ENUM.BACKTEST_BUCKET_ANALYSIS_BUCKET_STOCK_SELECTION_DETAILS]: createColumnGetter(
          TABLE_SHEET_KEY_ENUM.BACKTEST_BUCKET_ANALYSIS_BUCKET_STOCK_SELECTION_DETAILS
        ),
        [TABLE_SHEET_KEY_ENUM.BACKTEST_BUCKET_ANALYSIS_RETURN_SUMMARY]: createColumnGetter(
          TABLE_SHEET_KEY_ENUM.BACKTEST_BUCKET_ANALYSIS_RETURN_SUMMARY
        ),
        [TABLE_SHEET_KEY_ENUM.BACKTEST_BUCKET_ANALYSIS_BUCKET_ALLOCATION_SUMMARY]: createColumnGetter(
          TABLE_SHEET_KEY_ENUM.BACKTEST_BUCKET_ANALYSIS_BUCKET_ALLOCATION_SUMMARY
        ),
        [TABLE_SHEET_KEY_ENUM.BACKTEST_BUCKET_ANALYSIS_BUCKET_STOCK_SELECTION_SUMMARY]: createColumnGetter(
          TABLE_SHEET_KEY_ENUM.BACKTEST_BUCKET_ANALYSIS_BUCKET_STOCK_SELECTION_SUMMARY
        ),
      }}
      sheetTitles={{
        [TABLE_SHEET_KEY_ENUM.BACKTEST_BUCKET_ANALYSIS_RETURN_SUMMARY]: 'Returns Summary',
        [TABLE_SHEET_KEY_ENUM.BACKTEST_BUCKET_ANALYSIS_BUCKET_ALLOCATION_SUMMARY]: 'Bucket Allocation',
        [TABLE_SHEET_KEY_ENUM.BACKTEST_BUCKET_ANALYSIS_BUCKET_STOCK_SELECTION_SUMMARY]: 'Bucket Stock Selection',
        [TABLE_SHEET_KEY_ENUM.BACKTEST_BUCKET_ANALYSIS_RETURN_DETAILS]: 'Returns Details',
        [TABLE_SHEET_KEY_ENUM.BACKTEST_BUCKET_ANALYSIS_BUCKET_ALLOCATION_DETAILS]: 'Bucket Allocation Details',
        [TABLE_SHEET_KEY_ENUM.BACKTEST_BUCKET_ANALYSIS_BUCKET_STOCK_SELECTION_DETAILS]:
          'Bucket Stock Selection Details',
      }}
      fullScreen={fullScreen}
      goFullScreen={goFullScreen}
      queryParams={queryParams}
      currentSheet={params.current_sheet}
      setCurrentSheet={(sheet) => setParams({ ...params, current_sheet: sheet })}
      tableParams={tableParams}
      customToolbarBySheet={{
        [TABLE_SHEET_KEY_ENUM.BACKTEST_BUCKET_ANALYSIS_RETURN_DETAILS]: () => <></>,
        [TABLE_SHEET_KEY_ENUM.BACKTEST_BUCKET_ANALYSIS_BUCKET_ALLOCATION_DETAILS]: () => <></>,
        [TABLE_SHEET_KEY_ENUM.BACKTEST_BUCKET_ANALYSIS_BUCKET_STOCK_SELECTION_DETAILS]: () => <></>,
        [TABLE_SHEET_KEY_ENUM.BACKTEST_BUCKET_ANALYSIS_RETURN_SUMMARY]: () => (
          <StatisticsField value={selectedStatistics} setValue={setSelectedStatistics} />
        ),
        [TABLE_SHEET_KEY_ENUM.BACKTEST_BUCKET_ANALYSIS_BUCKET_ALLOCATION_SUMMARY]: () => (
          <StatisticsField value={selectedStatistics} setValue={setSelectedStatistics} />
        ),
        [TABLE_SHEET_KEY_ENUM.BACKTEST_BUCKET_ANALYSIS_BUCKET_STOCK_SELECTION_SUMMARY]: () => (
          <StatisticsField value={selectedStatistics} setValue={setSelectedStatistics} />
        ),
      }}
      setTableParams={{
        [TABLE_SHEET_KEY_ENUM.BACKTEST_BUCKET_ANALYSIS_RETURN_SUMMARY]: (v) =>
          setTableParams((t) => ({
            ...t,
            [TABLE_SHEET_KEY_ENUM.BACKTEST_BUCKET_ANALYSIS_RETURN_SUMMARY]: v,
          })),
        [TABLE_SHEET_KEY_ENUM.BACKTEST_BUCKET_ANALYSIS_BUCKET_ALLOCATION_SUMMARY]: (v) =>
          setTableParams((t) => ({
            ...t,
            [TABLE_SHEET_KEY_ENUM.BACKTEST_BUCKET_ANALYSIS_BUCKET_ALLOCATION_SUMMARY]: v,
          })),
        [TABLE_SHEET_KEY_ENUM.BACKTEST_BUCKET_ANALYSIS_BUCKET_STOCK_SELECTION_SUMMARY]: (v) =>
          setTableParams((t) => ({
            ...t,
            [TABLE_SHEET_KEY_ENUM.BACKTEST_BUCKET_ANALYSIS_BUCKET_STOCK_SELECTION_SUMMARY]: v,
          })),
        [TABLE_SHEET_KEY_ENUM.BACKTEST_BUCKET_ANALYSIS_RETURN_DETAILS]: (v) =>
          setTableParams((t) => ({
            ...t,
            [TABLE_SHEET_KEY_ENUM.BACKTEST_BUCKET_ANALYSIS_RETURN_DETAILS]: v,
          })),
        [TABLE_SHEET_KEY_ENUM.BACKTEST_BUCKET_ANALYSIS_BUCKET_ALLOCATION_DETAILS]: (v) =>
          setTableParams((t) => ({
            ...t,
            [TABLE_SHEET_KEY_ENUM.BACKTEST_BUCKET_ANALYSIS_BUCKET_ALLOCATION_DETAILS]: v,
          })),
        [TABLE_SHEET_KEY_ENUM.BACKTEST_BUCKET_ANALYSIS_BUCKET_STOCK_SELECTION_DETAILS]: (v) =>
          setTableParams((t) => ({
            ...t,
            [TABLE_SHEET_KEY_ENUM.BACKTEST_BUCKET_ANALYSIS_BUCKET_STOCK_SELECTION_DETAILS]: v,
          })),
      }}
      triggerWidthChange={params.fullWidth}
    />
  );
};

export default Chart;
