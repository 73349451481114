
import React from 'react';

import Button from './button';
import NumericField from './numeric-field';
import * as enums from '../enums';
import { useEffectWithoutFirst } from '../hooks';
import { mui } from '../libs';

type Props = {
  value: number[];
  setValue: (_val: number[]) => void;
  disabled?: boolean;
  percent?: boolean;
};

/**
 * Breakpoints component for displaying a list of breakpoints
 * 
 * @param value - The value of the breakpoints
 * @param setValue - The function to set the value of the breakpoints
 * @param disabled - Whether the breakpoints are disabled
 * @param percent - Whether the breakpoints are in percent
 * 
 * @returns A list of breakpoints
 */


const Breakpoints = ({ value, setValue, disabled, percent }: Props) => {
  const inputsRef = React.useRef<HTMLElement>(null);
  const [localValue, setLocalValue] = React.useState(value || [null, null]);
  const [sortDirection, setSortDirection] = React.useState<'asc' | 'desc'>('asc');

  useEffectWithoutFirst(() => {
    setValue(localValue);
  }, [localValue]);

  useEffectWithoutFirst(() => {
    if (inputsRef.current) {
      inputsRef.current.scrollTo({
        top: 0,
        left: inputsRef.current.scrollWidth,
        behavior: 'smooth',
      });
    }
  }, [localValue?.length ?? 0]);

  const handleAdd = (idx: number) => {
    const newLocalValue = [...localValue];
    newLocalValue.splice(idx, 1);
    setLocalValue(newLocalValue);
  };

  const handleSort = () => {
    const sortedValue = [...localValue].sort((a, b) => {
      if (a === null) return 1;
      if (b === null) return -1;
      return sortDirection === 'asc' ? a - b : b - a;
    });
    setLocalValue(sortedValue);
    setSortDirection((prevDirection) => (prevDirection === 'asc' ? 'desc' : 'asc'));
  };

  return (
    <mui.core.Box
      ref={inputsRef}
      display="flex"
      gap={2}
      alignItems="center"
      sx={{ overflow: 'auto', maxWidth: 'min-content' }}
    >
      {localValue.map((val, i) => (
        <mui.core.Box key={i} display="flex" gap={2}>
          <NumericField
            disabled={disabled}
            textFieldProps={{
              variant: 'outlined',
              size: 'small',
              sx: {
                '&:hover .MuiIconButton-root': {
                  visibility: 'visible',
                },
                minWidth: '125px',
              },
              InputProps:
                localValue.length <= 1 || disabled
                  ? undefined
                  : {
                      endAdornment: (
                        <mui.core.Tooltip arrow title="Delete Column">
                          <mui.core.IconButton size="small" sx={{ visibility: 'hidden' }} onClick={() => handleAdd(i)}>
                            <mui.icons.Delete fontSize="small" />
                          </mui.core.IconButton>
                        </mui.core.Tooltip>
                      ),
                    },
            }}
            suffix={percent ? '%' : ''}
            dividerOption={percent ? enums.NUMBER_FORMATTERS_ENUM.HUNDRED : undefined}
            value={val}
            setValue={(val) => {
              setLocalValue((currLocalValue: any) => {
                const newLocalValue = [...currLocalValue];
                newLocalValue[i] = val;
                return newLocalValue;
              });
            }}
          />
          {i !== localValue.length - 1 && <span style={{ marginTop: '2px', fontSize: '20px' }}>-</span>}
        </mui.core.Box>
      ))}

      <mui.core.Box
        sx={{
          position: 'sticky',
          right: '0px',
          width: '100%',
          padding: '4px 4px 4px 8px',
          background: '#fff',
          zIndex: 1,
        }}
      >
        {!disabled && (
          <mui.core.Box display="flex">
            <Button
              onClick={() => setLocalValue([...localValue, null])}
              variant="outlined"
              color="primary"
              size="small"
              disabled={disabled}
            >
              <mui.core.Tooltip arrow title="Add value">
                <mui.icons.Add />
              </mui.core.Tooltip>
            </Button>

            {localValue?.length > 1 && (
              <Button
                onClick={handleSort}
                variant="outlined"
                color="primary"
                size="small"
                disabled={disabled}
                style={{ marginLeft: '5px' }}
              >
                <mui.core.Tooltip arrow title={`Sort ${sortDirection}`}>
                  <mui.icons.Sort />
                </mui.core.Tooltip>
              </Button>
            )}
          </mui.core.Box>
        )}
      </mui.core.Box>
    </mui.core.Box>
  );
};

export default Breakpoints;
