import { _, helpers, ts } from '_core';

import { DraftParams } from './types';
import { getBenchmarkTitle } from '../../common/utils/get-name';

export const prepareLagData = (linearData: ts.types.widgets.LinearData, isBenchmark = false) => {
  if (!isBenchmark) return linearData;

  const activeData = _.pickBy(linearData, (_, key) => key.endsWith('_active'));
  const cleanedData = _.mapKeys(activeData, (_, key) => key.replace('_active', ''));
  return cleanedData;
};

export const validate = (params: DraftParams) => {
  const rhErrors = helpers.validations.validSelectedReturnHorizon(params?.return_horizon);
  if (!_.isEmpty(rhErrors)) return rhErrors;
  if (!params.lags || params.lags < 1 || params.lags > 12) return ['Lags is invalid.'];
  if (params.with_benchmark && !helpers.validations.validBenchmark(params.benchmark)) return ['Benchmark is invalid.'];
  return [];
};

export const getName = (
  params: DraftParams,
  _context: ts.types.signal.SignalAnalysisContext,
  resources: ts.StoreState['resources']
) => {
  if (params.benchmark)
    return getBenchmarkTitle(params.benchmark, `${'Cumulative Returns With Lags'}`, resources.universes);
  return 'Cumulative Returns With Lags';
};
