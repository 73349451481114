import { helpers, ts } from '_core';

export default {
  [ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.MAIN]: [
    {
      key: 'build_date',
      name: 'Universe Date',
      filter: 'date',
      formatter: helpers.tableFormatters.formatTo('date', { align: 'right', format: 'YYYY-MM-DD' }),
      category: 'Dates',
    },
    {
      key: 'ticker',
      name: 'Ticker',
      formatter: helpers.tableFormatters.formatTo('string', { align: 'left' }),
      filter: 'string',
      category: 'Asset Information',
    },
    {
      key: 'name',
      name: 'Security Name',
      formatter: helpers.tableFormatters.formatTo('string', { align: 'left' }),
      filter: 'string',
      category: 'Asset Information',
    },
    {
      key: 'asset',
      name: 'Asset',
      formatter: helpers.tableFormatters.formatTo('string', { align: 'left' }),
      filter: 'string',
      category: 'Asset Information',
    },
    {
      key: 'isin',
      name: 'ISIN',
      formatter: helpers.tableFormatters.formatTo('string', { align: 'left' }),
      filter: 'string',
      condition: (features: ts.types.organization.OrganizationFeatures) => !features.hide_isin_col,
      category: 'Asset Information',
    },
    {
      key: 'cusip',
      name: 'CUSIP',
      formatter: helpers.tableFormatters.formatTo('string', { align: 'left' }),
      filter: 'string',
      condition: (features: ts.types.organization.OrganizationFeatures) => !features.hide_cusip_col,
      category: 'Asset Information',
    },
    {
      key: 'asset_type',
      name: 'Asset Type',
      formatter: helpers.tableFormatters.formatTo('string', { align: 'center' }),
      filter: 'string',
      category: 'Asset Information',
    },
    {
      key: 'quotation_country',
      name: 'Quotation Country',
      formatter: helpers.tableFormatters.formatTo('string', { align: 'center' }),
      filter: 'string',
      category: 'Geographic Data',
    },
    {
      key: 'issuer_country',
      name: 'Issuer Country',
      formatter: helpers.tableFormatters.formatTo('string', { align: 'center' }),
      filter: 'string',
      category: 'Geographic Data',
    },
    {
      key: 'rbics_l1_name',
      name: 'Sector',
      formatter: helpers.tableFormatters.formatTo('string', { align: 'left' }),
      filter: 'string',
      category: 'Industry',
    },
    {
      key: 'rbics_l2_name',
      name: 'Sub-Sector',
      formatter: helpers.tableFormatters.formatTo('string', { align: 'left' }),
      filter: 'string',
      category: 'Industry',
    },
    {
      key: 'rbics_l3_name',
      name: 'Industry',
      formatter: helpers.tableFormatters.formatTo('string', { align: 'left' }),
      filter: 'string',
      category: 'Industry',
    },
    {
      key: 'rbics_l4_name',
      name: 'Sub-Industry',
      formatter: helpers.tableFormatters.formatTo('string', { align: 'left' }),
      filter: 'string',
      category: 'Industry',
    },
    {
      key: 'currency',
      name: 'Currency',
      formatter: helpers.tableFormatters.formatTo('string', { align: 'center' }),
      filter: 'string',
      category: 'Financial Data',
    },
    {
      key: 'close_usd',
      name: 'Close Price',
      formatter: helpers.tableFormatters.formatTo('float', { align: 'right', roundDigits: 2 }),
      filter: 'number',
      category: 'Financial Data',
    },
    {
      key: 'volume_usd',
      name: 'Median Volume ($M USD)',
      formatter: helpers.tableFormatters.formatTo('float', { align: 'right', roundDigits: 3, commasOnThousands: true }),
      filter: 'number',
      category: 'Financial Data',
    },
    {
      key: 'marketcap_usd',
      name: 'Market Cap  ($M USD)',
      formatter: helpers.tableFormatters.formatTo('float', { align: 'right', roundDigits: 3, commasOnThousands: true }),
      filter: 'number',
      category: 'Financial Data',
    },
    {
      key: '_handle_', // Dynamic
      name: 'Signal Filters',
      formatter: helpers.tableFormatters.formatTo('float', { align: 'right', roundDigits: 3, commasOnThousands: true }),
      filter: 'inferred',
      members: (key) => key.startsWith('_handle_'),
      category: 'Filters',
      cleanNameFormatter: (key, _c, resources: ts.StoreState['resources']) => {
        const handle = key.split('_handle_')[1];
        const signal = resources.signals.find((s) => s.handle === handle);
        return `${signal?.name ?? handle}`;
      },
      nameFormatter: (key, _c, resources: ts.StoreState['resources']) => {
        const handle = key.split('_handle_')[1];
        const signal = resources.signals.find((s) => s.handle === handle);
        return `${signal?.name ?? handle}`;
      },
    },
  ] as ts.types.components.dataGrid.ColumnsData,
};
