import { api, helpers, mui, React, ts, ui } from '_core';

type LayoutCopyProps = {
  preferences: ts.types.userPreference.UserPreference[];
  setPreferences: (_v: ts.types.userPreference.UserPreference[]) => void;
  setSelectedPreference: (_v: ts.types.userPreference.UserPreference) => void;
  selectedPreference?: ts.types.userPreference.UserPreference;
  open: boolean;
  setOpen: (_v: boolean) => void;
};

const LayoutCopy: React.FC<LayoutCopyProps> = ({
  selectedPreference,
  preferences,
  setPreferences,
  setSelectedPreference,
  open,
  setOpen,
}) => {
  const [errorMessage, setErrorMessage] = React.useState('');
  const [title, setTitle] = React.useState(`${preferences.find((p) => p.id == selectedPreference.id)?.title} copy`);
  const [loadingRename, setLoadingRename] = React.useState(false);

  React.useEffect(() => {
    setTitle(`${preferences.find((p) => p.id == selectedPreference.id)?.title} copy`);
  }, [selectedPreference, preferences]);

  const handleCopy = async () => {
    setErrorMessage('');

    // Validation
    if (!title) {
      setErrorMessage('Name is a required field.');
      setLoadingRename(false);
      return;
    }

    setLoadingRename(true);
    try {
      const newPref = await api.user_preferences.create({
        ...preferences.find((p) => p.id == selectedPreference.id),
        title,
      });

      setPreferences([...preferences, newPref.data]);
      setSelectedPreference(newPref.data);

      setLoadingRename(false);
      setOpen(false);
    } catch (err) {
      setErrorMessage(helpers.parseApiError(err as any));
      setLoadingRename(false);
    }
  };

  return (
    <mui.core.Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="sm"
      fullWidth
    >
      {errorMessage && (
        <mui.lab.Alert onClose={() => setErrorMessage('')} severity="error">
          {errorMessage}
        </mui.lab.Alert>
      )}

      <ui.DialogTitle closeAction={() => setOpen(false)}>Create Copy of a View</ui.DialogTitle>

      <mui.core.DialogContent>
        <mui.core.Box py={2}>
          <mui.core.TextField
            id="Name"
            label={'Name'}
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            fullWidth
            variant="outlined"
            required
            size="small"
          />
        </mui.core.Box>
      </mui.core.DialogContent>

      <mui.core.DialogActions>
        <ui.Button
          variant="contained"
          color="primary"
          loading={loadingRename}
          onClick={() => handleCopy()}
          style={{ margin: '0rem 1rem 1rem' }}
          fullWidth
        >
          Create Copy
        </ui.Button>
      </mui.core.DialogActions>
    </mui.core.Dialog>
  );
};

export default LayoutCopy;
