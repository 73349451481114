import React from 'react';

import ConfirmStep from './confirm-step';
import HeaderStepper from './header-stepper';
import InitialStep from './initial-step';
import MapColumnsStep from './map-columns-step';
import * as enums from '../../../enums';
import { mui } from '../../../libs';
import DialogTitle from '../../dialog-title';
import { UploadContext } from '../upload-context';

const UploadPortfolio = (): React.ReactElement => {
  const uploadContext = React.useContext(UploadContext);
  const { open, step, errorMessage, setOpen, setErrorMessage } = uploadContext;

  const HOLDINGS_UPLOAD_STEPS_ENUM = enums.HOLDINGS_UPLOAD_STEPS_ENUM;

  const renderStep = () => {
    if (step == HOLDINGS_UPLOAD_STEPS_ENUM.MAPPING_COLUMNS) return <MapColumnsStep />;
    if (step == HOLDINGS_UPLOAD_STEPS_ENUM.HOLDINGS_CONFIRM) return <ConfirmStep />;
    return <InitialStep />;
  };

  return (
    <mui.core.Dialog
      open={open}
      onClose={(_event, reason) => {
        if (reason !== 'backdropClick') {
          setOpen(false);
        }
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="md"
      fullWidth
    >
      <DialogTitle
        closeAction={
          [HOLDINGS_UPLOAD_STEPS_ENUM.HOLDINGS_CREATION, HOLDINGS_UPLOAD_STEPS_ENUM.MAPPING_COLUMNS].includes(step)
            ? () => setOpen(false)
            : undefined
        }
      >
        <HeaderStepper />
      </DialogTitle>
      {errorMessage && (
        <mui.lab.Alert onClose={() => setErrorMessage('')} severity="error">
          {errorMessage}
        </mui.lab.Alert>
      )}
      {renderStep()}
    </mui.core.Dialog>
  );
};

export default UploadPortfolio;
