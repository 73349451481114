import { mui, React, ts, ui } from '_core';

const CodeBlock = ({ message, maxWidth }: { message: ts.types.aiAssistant.MessageDraft; maxWidth?: string }) => {
  const codeLines = message.content.text.split('\n').map((line, index) => {
    const lineNumber = index + 1;
    const numDigits = Math.floor(Math.log10(lineNumber)) + 1;

    let spaces = '';
    if (numDigits < 4) {
      spaces = ' '.repeat(4 - numDigits);
    }

    return (
      <div key={index} style={{ display: 'flex' }}>
        <div style={{ paddingRight: '10px', color: '#999' }}>
          {spaces}
          {lineNumber}
        </div>
        <div>{line}</div>
      </div>
    );
  });

  return (
    <mui.core.Box
      sx={{
        borderRadius: 2,
        marginLeft: message.role == 'user' ? 2 : 0,
        marginTop: '10px',
        marginBottom: '10px',
        width: maxWidth,
        maxWidth: maxWidth,
        wordBreak: 'break-word',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
      }}
    >
      <ui.CodeBlock content={message.content.text} lines={codeLines} />
    </mui.core.Box>
  );
};

CodeBlock.defaultProps = {
  maxWidth: '95%',
};

export default CodeBlock;
