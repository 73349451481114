import { config, moment, mui, React, ui } from '_core';

import { DraftParams } from './types';

type FormProps = {
  params: DraftParams;
  setParams: (_p: DraftParams) => void;

  signalStartDate?: string;
  signalEndDate?: string;
  universeId?: number;
};

const Form: React.FC<FormProps> = ({ params, setParams, signalStartDate, signalEndDate, universeId }) => {
  const getInitialStartDate = () => {
    if (params.comparison_date_1) return params.comparison_date_1;

    const subtractedStartDate = moment(signalEndDate).subtract(1, 'years').format('YYYY-MM-DD');
    if (subtractedStartDate < signalStartDate) return signalStartDate;

    return subtractedStartDate;
  };

  const [firstDate, setFirstDate] = React.useState(getInitialStartDate);
  const [secondDate, setSecondDate] = React.useState(params.comparison_date_2 || signalEndDate);
  const [debug] = React.useState(params.debug);

  const [componentErrors, setComponentErrors] = React.useState([]);

  React.useEffect(() => {
    const data = {
      comparison_date_1: firstDate,
      comparison_date_2: secondDate,
      debug,
      component_errors: componentErrors,
    };

    setParams({ ...params, ...data });
  }, [firstDate, secondDate, debug, componentErrors]);

  const rangeValue = React.useMemo(() => {
    return [firstDate, secondDate] as string[];
  }, [firstDate, secondDate]);

  return (
    <mui.core.Box mt={4}>
      {/* <mui.core.Box mt={2}>
        <mui.core.FormControlLabel
          control={
            <mui.core.Switch
              checked={debug}
              onChange={() => {
                setDebug(!debug);
              }}
            />
          }
          label="Debug"
        />
      </mui.core.Box> */}

      <mui.core.Grid container spacing={2}>
        <mui.core.Grid item xs={12}>
          <ui.SmartDateRangeSelector
            startText="First date"
            endText="Second date"
            value={rangeValue}
            onChange={(v) => {
              setFirstDate(v[0]);
              setSecondDate(v[1]);
            }}
            InputProps={{ disableUnderline: true, disable_size: 'small' }}
            minDate={signalStartDate || config.features.start_date}
            maxDate={signalEndDate || config.features.end_date}
            universeId={universeId}
            setExternalError={(e) => setComponentErrors(e ? [e] : [])}
          />
        </mui.core.Grid>
      </mui.core.Grid>
    </mui.core.Box>
  );
};

Form.defaultProps = {
  signalStartDate: null,
  signalEndDate: null,
};

export default Form;
