import { ts } from '_core';

import { validate } from './helpers';
import { DraftParams, INTERACTION_PARAMS } from './types';

export default [
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.BASKET_COMPARE_ANNU_TRACKING_ERROR,
    title: 'Annualized Tracking Error',
    form: true,
    helpLabel: '41dbcutd',
    defaultParams: {
      histories: ['1 Year', '3 Years', '5 Years', 'Full', 'Year to Date'],
    } as DraftParams,
    interactionParams: Object.values(INTERACTION_PARAMS),
    neededFiles: ['annualized_tracking_error'],
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.BASKET_COMPARE],

    validate,
  },
] satisfies ts.types.analysis.WidgetConfig[];
