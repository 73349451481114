import { _, mui, React, ts, useSelector } from '_core';

import { getExposureFactorName } from './helpers';
import { Params } from './types';
import ChartWrapper from '../../common/chart-wrapper';
import createLineChart from '../../common/charts/create-line-chart';
import { prepareExposureData } from '../../common/utils/signal-utils';

type ChartProps = {
  id: string;
  readOnly: boolean;
  widgetKey: string;
  loadWidgetData: (_payload: ts.types.widgets.WidgetGetDataParams) => Promise<ts.types.widgets.WidgetGetDataResponse>;
  file: string;
  hasSPPipeline: boolean;
  hasPipeline: boolean;
  fullScreen: boolean;
  params: Params;
  title: string;
};

type TabTypes = 'raw_signal' | 'pipeline' | 'sp_pipeline';

const getFactorKey = (f: ts.types.pipeline.NeutralizeDraft['factors'][0]) => {
  if (f.type == 'market') return 'market';
  const localFactor = { ...f };
  // The backend files uses country instead of quotation country,
  // and uses l1,l2,l3,l4 instead of rbics_lx_id
  if (localFactor.type == 'risk_model_style') localFactor.value = 'model';
  return `${localFactor.type}|${localFactor.value}`
    .replace('quotation_country', 'country')
    .replace('rbics_', '')
    .replace('_id', '');
};

const Chart: React.FC<ChartProps> = ({
  id,
  readOnly,
  widgetKey,
  loadWidgetData,
  file,
  params,
  hasSPPipeline,
  hasPipeline,
  fullScreen,
  title,
}) => {
  let tabTypes = ['raw_signal'] as TabTypes[];
  if (hasPipeline) tabTypes = ['pipeline'].concat(tabTypes) as TabTypes[];
  if (hasSPPipeline) tabTypes = ['sp_pipeline'].concat(tabTypes) as TabTypes[];
  const hasPipelines = hasPipeline && hasSPPipeline;

  const [data, setData] = React.useState<ts.types.widgets.TableData>(null);
  const [error, setError] = React.useState<ts.types.common.ApiError>(null);
  const [showLegend, setShowLegend] = React.useState(false);

  const [primaryTab, setPrimaryTab] = React.useState(tabTypes[0]);

  const [secondaryTab, setSecondaryTab] = React.useState(() => getFactorKey(params.factors[0]));
  const resources = useSelector((state) => state.resources);

  const loadData = async (prefix: string) => {
    try {
      const response = await loadWidgetData({
        data: {
          file,
          columns: ['build_date', prefix],
          columns_inclusion: 'STARTS_WITH',
        },
      });
      if (response)
        setData(
          response.data.map((el) => {
            const newEl: typeof el = {};
            Object.entries(el).forEach(([key, val]) => {
              newEl[key.replace('gross_', '')] = val;
            });

            return newEl;
          })
        );
    } catch (err) {
      setError(err as ts.types.common.ApiError);
    }
  };

  React.useEffect(() => {
    if (file) {
      const prefix = params.is_gross ? `gross_${primaryTab}|${secondaryTab}` : `${primaryTab}|${secondaryTab}`;
      loadData(prefix);
    }
    return () => setData(null);
  }, [file, primaryTab, secondaryTab, params.is_gross]);

  React.useEffect(() => {
    if (!_.isNil(data))
      createLineChart({
        id,
        scale: 'linear',
        data: data,
        fullScreen,
        setShowLegend,
        xAxis: 'build_date',
        percent: false,
        yAxisLabel: 'Exposures',
        exportTitle: title,
        prepareData: (linearData) => prepareExposureData(linearData, primaryTab, secondaryTab, resources),
      });
  }, [data]);

  const getMainTabTitle = (tab: TabTypes) => {
    if (tab == 'raw_signal') return 'Raw';
    if (tab == 'pipeline') return 'After Pipeline';
    return 'After Portfolio Pipeline';
  };

  const getTabs = () => (
    <>
      {hasPipelines && params.detailed_output && tabTypes.length > 1 ? (
        <mui.core.Tabs
          indicatorColor="primary"
          textColor="primary"
          value={primaryTab}
          onChange={(_e, newTab) => setPrimaryTab(newTab as TabTypes)}
        >
          {tabTypes.map((f) => (
            <mui.core.Tab key={f} label={getMainTabTitle(f)} value={f} />
          ))}
        </mui.core.Tabs>
      ) : (
        <></>
      )}

      <mui.core.Tabs
        indicatorColor="primary"
        textColor="primary"
        value={secondaryTab}
        onChange={(_e, newTab) => setSecondaryTab(newTab as ts.enums.N_FACTOR_TYPE_ENUM)}
      >
        {params.factors.map((f, idx) => (
          <mui.core.Tab key={idx} label={getExposureFactorName(f)} value={getFactorKey(f)} />
        ))}
      </mui.core.Tabs>
    </>
  );

  return (
    <ChartWrapper
      id={id}
      readOnly={readOnly}
      widgetKey={widgetKey}
      data={data}
      showLegend={showLegend}
      error={error}
      tabs={getTabs()}
      fullScreen={fullScreen}
    />
  );
};

export default Chart;
