import { React, ts } from '_core';

import { loadData } from './api-helpers';
import TableComponent from './table';
import { getCustomToolbars, getSheets } from './table-helpers';
import { getBucketOrder } from '../../../common/utils/attribution-utils';
import { DraftParams, FRACTIONAL_RISK_ENUM, Params, ParamsArgs, RISK_METRIC_ENUM } from '../types';

type TableProps = {
  id: string;
  params: Params | DraftParams;
  detailsFile: string;
  summaryFile: string;
  frequency: ts.enums.FREQUENCY_ENUM;
  startDate: string;
  endDate: string;
  loadWidgetData: (_payload: ts.types.widgets.WidgetGetDataParams) => Promise<ts.types.widgets.WidgetGetDataResponse>;
  histories: ts.enums.HISTORY_ENUM_EXTENDED[];
};

const Table: React.FC<TableProps> = ({
  loadWidgetData,
  detailsFile,
  summaryFile,
  params,
  startDate,
  endDate,
  histories,
}): React.ReactElement => {
  const removeResiduals = React.useMemo(() => params?.without_residuals || false, [params?.without_residuals]);

  const [data, setData] = React.useState<{
    details: ts.types.widgets.TableData;
    summary: ts.types.widgets.TableData;
    detailsColumns: ts.types.components.dataGrid.ColumnsData;
    summaryColumns: ts.types.components.dataGrid.ColumnsData;
  }>({ details: null, summary: null, detailsColumns: [], summaryColumns: [] });
  const [error, setError] = React.useState<ts.types.common.Alert>(null);
  const [loading, setLoading] = React.useState(false);
  const [selectedHistory, setSelectedHistory] = React.useState(histories[0]);
  const [selectedViewType, setSelectedViewType] = React.useState('attributed_returns');
  const [selectedFractionalRisk, setSelectedFractionalRisk] = React.useState(FRACTIONAL_RISK_ENUM.FRACTION);
  const [selectedStatistics, setSelectedStatistics] = React.useState<ts.enums.REPORT_ENUMS.ANNU_STATISTICS_ENUM>(
    ts.enums.REPORT_ENUMS.ANNU_STATISTICS_ENUM.RETURNS
  );
  const [selectedRiskMetric, setSelectedRiskMetric] = React.useState<RISK_METRIC_ENUM>(RISK_METRIC_ENUM.TOTAL);

  const dates = { start: startDate, end: endDate };

  const bucketOrder = React.useMemo(
    () => getBucketOrder({ buckets: params.buckets, variables: params.variables }, 'selected_buckets'),
    []
  );

  const { sheets, sheetsStyles } = React.useMemo(() => getSheets(params, bucketOrder), []);

  const [currentSheet, setCurrentSheet] = React.useState(Object.keys(sheets)[0]);

  const customToolBars = getCustomToolbars({
    params,
    bucketOrder,
    attributionType: params.attribution_type,
    selectedViewType,
    selectedStatistics,
    selectedHistory,
    selectedRiskMetric,
    selectedFractionalRisk,
    setSelectedViewType,
    setSelectedStatistics,
    setSelectedHistory,
    setSelectedRiskMetric,
    setSelectedFractionalRisk,
    histories,
    startDate,
    endDate,
  });

  const callLoadData = async (sheet: string) => {
    setLoading(true);
    setError(null);

    const riskMetric = `${selectedRiskMetric} ${selectedFractionalRisk}` as any;

    try {
      const { details, summary } = await loadData(
        async (p) => await loadWidgetData({ data: p }),
        sheet,
        dates,
        params.buckets,
        detailsFile,
        summaryFile,
        {
          comb: bucketOrder,
          history: selectedHistory,
          statistics_type: selectedStatistics,
          without_residuals: removeResiduals,
          risk_metric: params.attribution_type == 'risk' ? riskMetric : null,
          view_type: selectedViewType as ParamsArgs['view_type'],
        }
      );

      setData({
        details: details.data,
        summary: summary.data,
        detailsColumns: details.columns,
        summaryColumns: summary.columns,
      });
    } catch (err: any) {
      let errorMessage = JSON.stringify(err);
      if (err.responseJSON?.detail) errorMessage = err.responseJSON?.detail;
      setError({
        severity: ts.enums.ALERT_SEVERITY_ENUM.ERROR,
        message: errorMessage,
      });
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    if (detailsFile && summaryFile && currentSheet) callLoadData(currentSheet);
  }, [
    detailsFile,
    summaryFile,
    currentSheet,
    selectedRiskMetric,
    selectedFractionalRisk,
    selectedViewType,
    selectedHistory,
    selectedStatistics,
  ]);

  // Pass the appropriate data and columns based on whether it's a summary sheet
  const currentData = React.useMemo(() => (currentSheet.includes('summary') ? data.summary : data.details), [data]);
  const currentColumns = React.useMemo(
    () => (currentSheet.includes('summary') ? data.summaryColumns : data.detailsColumns),
    [data]
  );

  return (
    <TableComponent
      data={currentData}
      columns={currentColumns}
      sheets={sheets}
      customHeaderHeight={sheetsStyles.customHeaderHeight}
      customWidth={sheetsStyles.customWidth}
      customRowHeight={sheetsStyles.customRowHeight}
      customToolBars={customToolBars}
      loading={loading}
      error={error}
      currentSheet={currentSheet}
      setCurrentSheet={setCurrentSheet}
    />
  );
};

export default Table;
