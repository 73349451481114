import { mui, React, ts } from '_core';

import showdown from 'showdown';

const TextBox: React.FC<{
  message: ts.types.aiAssistant.MessageDraft;
  loading: boolean;
  error?: boolean;
  maxWidth?: string;
}> = ({ message, loading, error, maxWidth }) => {
  const theme = mui.styles.useTheme() as mui.core.Theme;
  const converter = new showdown.Converter({ tables: true });

  const parsedMessage = React.useMemo(() => {
    if (!message.content.text) return '';
    if (message.role == 'assistant') return converter.makeHtml(message.content.text);
    return message.content.text.trim().replaceAll('\n', '<br />');
  }, [message.content.text]);

  return (
    <mui.core.Box
      p={message.purpose == 'action' ? 0 : 2}
      mr={message.role === 'user' ? 2 : undefined}
      display="flex"
      sx={{
        color: error
          ? theme.palette.danger.dark
          : message.purpose == 'action'
            ? theme.palette.text.secondary
            : theme.palette.text.primary,
        borderRadius: 2,
        marginLeft: message.role == 'user' ? 1 : 0,
        maxWidth: maxWidth,
        wordBreak: 'break-word',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        position: 'relative',
        padding: 0,
        paddingTop: 2,

        '& p': {
          margin: 0,
          lineHeight: '1.2!important',
          display: 'block',
        },
      }}
    >
      {message.purpose == 'action' && <mui.icons.AutoAwesome sx={{ fontSize: 13, mr: 1 }} />}
      <mui.core.Typography component="div" sx={{ fontSize: '0.75rem' }}>
        {loading && (
          <mui.core.Box className="bouncing-loader">
            <div></div>
            <div></div>
            <div></div>
          </mui.core.Box>
        )}

        {!loading && (
          <div
            className="content"
            dangerouslySetInnerHTML={{
              __html: parsedMessage,
            }}
          ></div>
        )}
      </mui.core.Typography>
    </mui.core.Box>
  );
};

TextBox.defaultProps = {
  maxWidth: '95%',
};

export default TextBox;
