import { _, React, ts } from '_core';

import { transform } from './helpers';
import ChartWrapper from '../../common/chart-wrapper';
import createHeatMapChart from '../../common/charts/create-heat-map';

type ChartProps = {
  id: string;
  readOnly: boolean;
  widgetKey: string;
  fullScreen: boolean;
  loadWidgetData: (_payload: ts.types.widgets.WidgetGetDataParams) => Promise<ts.types.widgets.WidgetGetDataResponse>;
  file: string;
  title: string;
};

const Chart: React.FC<ChartProps> = ({ id, readOnly, widgetKey, fullScreen, loadWidgetData, file, title }) => {
  const [data, setData] = React.useState<ts.types.widgets.HeatMapData>(null);
  const [error, setError] = React.useState<ts.types.common.ApiError>(null);

  const loadData = async () => {
    try {
      const response = await loadWidgetData({
        data: {
          file: file,
        },
      });

      if (response) {
        setData(response.data as ts.types.widgets.HeatMapData);
      }
    } catch (err) {
      setError(err as ts.types.common.ApiError);
    }
  };

  React.useEffect(() => {
    if (file) loadData();
    return () => setData(null);
  }, [file]);

  React.useEffect(() => {
    // Create line chart on data and scale changes
    if (!_.isNil(data)) {
      createHeatMapChart({
        id,
        data,
        fullScreen,
        xAxis: 'factor_x',
        yAxis: 'factor_y',
        rotateLabels: true,
        exportTitle: title,
        prepareData: transform,
      });
    }
  }, [data]);

  return (
    <ChartWrapper
      id={id}
      readOnly={readOnly}
      widgetKey={widgetKey}
      data={data}
      warning={data?.length === 0 ? ['Empty File'] : []}
      error={error}
      fullScreen={fullScreen}
    />
  );
};

export default Chart;
