import { _, api, helpers, mui, React, ts, ui } from '_core';

import SignalDatasetForm from './signal-dataset-form';

type CreateDatasetFormModalProps = {
  open: boolean;
  setOpen: (_val: boolean) => void;
  createDatasetDefault: (_val: ts.types.preferences.SIGNAL_DATASET_FORM_WITH_DEFINITION) => void;
  preferencesDefaults: ts.types.preferences.SIGNAL_DATASET_FORM[];
};

const CreateDatasetFormModal: React.FC<CreateDatasetFormModalProps> = ({
  open,
  setOpen,
  createDatasetDefault,
  preferencesDefaults,
}) => {
  const [localDataset, setLocalDataset] = React.useState<ts.types.preferences.SIGNAL_DATASET_FORM>({});
  const [isValid, setIsValid] = React.useState(false);

  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<ts.types.common.Alert>();

  const handleClose = () => {
    setLocalDataset({});
    setOpen(false);
  };

  const handleCreateDatasetDefault = async () => {
    setLoading(true);

    if (!localDataset.name) {
      setError({
        severity: ts.enums.ALERT_SEVERITY_ENUM.ERROR,
        message: 'Dataset Default Name is a required field.',
      });
      return;
    }

    const nameAlreadyInUse = _.some(preferencesDefaults, (d) => d.name == localDataset.name);
    if (nameAlreadyInUse) {
      setError({
        severity: ts.enums.ALERT_SEVERITY_ENUM.ERROR,
        message: 'Dataset Default Name must be unique.',
      });
      return;
    }

    const formattedDataset = {
      type: ts.types.userPreference.UserPreferenceTypes.DATASETPARAMS,
      key: ts.enums.PREFERENCES_KEY_ENUM.SIGNAL_DATESET_FORM,
      title: localDataset.name,
      definition: { ...localDataset },
      is_latest: false,
      is_preferred: false,
    };

    try {
      const respNewDataset = await api.user_preferences.create(formattedDataset);

      createDatasetDefault(respNewDataset.data);
    } catch (err) {
      setError({
        severity: ts.enums.ALERT_SEVERITY_ENUM.ERROR,
        message: helpers.parseApiError(err as any),
      });
    }

    setLoading(false);
    setOpen(false);
    setLocalDataset({});
  };

  return (
    <mui.core.Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
      <mui.core.DialogTitle>Create Dataset Default</mui.core.DialogTitle>
      <mui.core.DialogContent>
        {error && (
          <mui.lab.Alert onClose={() => setError(null)} severity="error">
            {error.message}
          </mui.lab.Alert>
        )}
        <SignalDatasetForm
          selectedDatasetPreference={localDataset}
          updateSelectedDataset={setLocalDataset}
          setIsValid={setIsValid}
        />
      </mui.core.DialogContent>
      <mui.core.DialogActions>
        <ui.Button color="primary" variant="outlined" onClick={() => setOpen(false)}>
          Cancel
        </ui.Button>
        <ui.Button
          color="primary"
          variant="contained"
          disabled={!isValid || loading}
          loading={loading}
          onClick={() => handleCreateDatasetDefault()}
        >
          Create
        </ui.Button>
      </mui.core.DialogActions>
    </mui.core.Dialog>
  );
};

export default CreateDatasetFormModal;
