import { PREFERENCES_INNER_TABS_ENUM, PREFERENCES_TABS_ENUM } from '@local/finsera-core/src/enums';
import { _ } from '@local/finsera-core/src/libs/lodash';
import { CurrentTab } from '@local/finsera-core/src/types/common';
import { LAST_UPDATED_PREFERENCES, OPEN_PREFERENCES, PREFERENCES } from '@local/finsera-core/src/types/preferences';
import { WorkspacesMetadata } from '@local/finsera-core/src/types/workspace';

import {
  SET_API_OPEN,
  SET_API_SELECTED_TAB,
  SET_CHATBOT_OPEN,
  SET_CHATBOT_SHOW,
  SET_CURRENT_TAB,
  SET_DOCUMENTS_SIDEBAR_OPEN,
  SET_DOCUMENTS_SIDEBAR_URL,
  SET_PREFERENCES_LAST_UPDATED,
  SET_PREFERENCES_OPEN,
  SET_PREFERENCES_SELECTED_TAB,
  SET_SCHEDULE_DEMO_OPEN,
  SET_USER_PREFERENCES,
  SET_WORKSPACES_METADATA,
  UPDATE_CURRENT_WORKSPACE,
  UPDATE_FAUTH_IS_SET,
  UPDATE_UI_VERSION,
} from '../actions/types';

const INITIAL_STATE = {
  fauthIsSet: !!_.cookie('fauth'),
  currentTab: 'dashboard' as CurrentTab,
  currentWorkspaceId: null as number,
  workspacesMetadata: {} as WorkspacesMetadata,

  preferencesOpen: { open: false } as OPEN_PREFERENCES,
  preferencesSelectedTab: PREFERENCES_TABS_ENUM.OTHER,
  preferencesSelectedInnerTab: undefined as PREFERENCES_INNER_TABS_ENUM,
  preferencesSelectedId: undefined as number,
  preferencesLastUpdated: {} as LAST_UPDATED_PREFERENCES,
  preferences: {} as PREFERENCES,

  documentSidebarOpen: false,
  documentSidebarUrl: '',

  chatbotOpen: false,
  chatbotShow: true,

  apiOpen: false,
  scheduleDemoOpen: false,
  apiSelectedTab: 0,
  runningUiVersion: '',
  currentUiVersion: '',
};

export type UpdateFauthIsSetType = {
  type: typeof UPDATE_FAUTH_IS_SET;
  payload: boolean;
};

export type SetCurrentTabType = {
  type: typeof SET_CURRENT_TAB;
  payload: { currentTab: CurrentTab };
};

export type UpdateCurrentWorkspaceType = {
  type: typeof UPDATE_CURRENT_WORKSPACE;
  payload: { id: number };
};

export type SetWorkspaceMetadataType = {
  type: typeof SET_WORKSPACES_METADATA;
  payload: { workspacesMetadata: WorkspacesMetadata };
};

export type SetUserPreferencesType = {
  type: typeof SET_USER_PREFERENCES;
  payload: { preferences: PREFERENCES };
};

export type SetPreferencesOpenType = {
  type: typeof SET_PREFERENCES_OPEN;
  payload: { preferencesOpen: OPEN_PREFERENCES };
};

export type SetPreferencesSelectedTabType = {
  type: typeof SET_PREFERENCES_SELECTED_TAB;
  payload: {
    preferencesSelectedTab: PREFERENCES_TABS_ENUM;
    preferencesSelectedInnerTab: PREFERENCES_INNER_TABS_ENUM;
    preferencesSelectedId?: number;
    source_widget_id?: string;
  };
};

export type SetPreferencesLastUpdated = {
  type: typeof SET_PREFERENCES_LAST_UPDATED;
  payload: {
    preferencesLastUpdated: LAST_UPDATED_PREFERENCES;
  };
};

export type SetDocumentSidebarOpenType = {
  type: typeof SET_DOCUMENTS_SIDEBAR_OPEN;
  payload: { documentOpen: boolean };
};

export type SetDocumentSidebarUrlType = {
  type: typeof SET_DOCUMENTS_SIDEBAR_URL;
  payload: { documentUrl: string };
};

export type SetApiOpenType = {
  type: typeof SET_API_OPEN;
  payload: { apiOpen: boolean };
};

export type SetScheduleDemoOpenType = {
  type: typeof SET_SCHEDULE_DEMO_OPEN;
  payload: { scheduleDemoOpen: boolean };
};

export type SetApiSelectedTabType = {
  type: typeof SET_API_SELECTED_TAB;
  payload: { apiSelectedTab: number };
};

export type UpdateUiVersionType = {
  type: typeof UPDATE_UI_VERSION;
  payload: { version: string };
};

export type SetChatBotOpenType = {
  type: typeof SET_CHATBOT_OPEN;
  payload: { chatbotOpen: boolean };
};

export type SetChatBotShowType = {
  type: typeof SET_CHATBOT_SHOW;
  payload: { chatbotShow: boolean };
};

type ReducerAction =
  | UpdateFauthIsSetType
  | SetCurrentTabType
  | UpdateCurrentWorkspaceType
  | SetWorkspaceMetadataType
  | SetUserPreferencesType
  | SetPreferencesOpenType
  | SetPreferencesSelectedTabType
  | SetPreferencesLastUpdated
  | SetApiOpenType
  | SetApiSelectedTabType
  | UpdateUiVersionType
  | SetScheduleDemoOpenType
  | SetDocumentSidebarOpenType
  | SetDocumentSidebarUrlType
  | SetChatBotOpenType
  | SetChatBotShowType;

export default (state = INITIAL_STATE, action: ReducerAction) => {
  switch (action.type) {
    case UPDATE_FAUTH_IS_SET:
      return { ...state, fauthIsSet: (action as UpdateFauthIsSetType).payload };
    case SET_CURRENT_TAB:
      return { ...state, currentTab: (action as SetCurrentTabType).payload.currentTab };
    case UPDATE_CURRENT_WORKSPACE:
      return { ...state, currentWorkspaceId: (action as UpdateCurrentWorkspaceType).payload.id };
    case SET_WORKSPACES_METADATA:
      return {
        ...state,
        workspacesMetadata: (action as SetWorkspaceMetadataType).payload.workspacesMetadata,
      };
    case SET_USER_PREFERENCES:
      return { ...state, preferences: (action as SetUserPreferencesType).payload.preferences };
    case SET_PREFERENCES_OPEN:
      return {
        ...state,
        preferencesOpen: (action as SetPreferencesOpenType).payload.preferencesOpen,
      };
    case SET_PREFERENCES_SELECTED_TAB:
      return {
        ...state,
        preferencesSelectedTab: (action as SetPreferencesSelectedTabType).payload.preferencesSelectedTab,
        preferencesSelectedInnerTab: (action as SetPreferencesSelectedTabType).payload.preferencesSelectedInnerTab,
        preferencesSelectedId: (action as SetPreferencesSelectedTabType).payload.preferencesSelectedId,
      };
    case SET_PREFERENCES_LAST_UPDATED:
      return {
        ...state,
        preferencesLastUpdated: (action as SetPreferencesLastUpdated).payload.preferencesLastUpdated,
      };
    case SET_DOCUMENTS_SIDEBAR_OPEN:
      return {
        ...state,
        documentSidebarOpen: (action as SetDocumentSidebarOpenType).payload.documentOpen,
      };
    case SET_DOCUMENTS_SIDEBAR_URL:
      return {
        ...state,
        documentSidebarUrl: (action as SetDocumentSidebarUrlType).payload.documentUrl,
      };
    case SET_API_OPEN:
      return { ...state, apiOpen: (action as SetApiOpenType).payload.apiOpen };
    case SET_SCHEDULE_DEMO_OPEN:
      return {
        ...state,
        scheduleDemoOpen: (action as SetScheduleDemoOpenType).payload.scheduleDemoOpen,
      };
    case SET_API_SELECTED_TAB:
      return { ...state, apiSelectedTab: (action as SetApiSelectedTabType).payload.apiSelectedTab };
    case SET_CHATBOT_OPEN:
      return { ...state, chatbotOpen: (action as SetChatBotOpenType).payload.chatbotOpen };
    case SET_CHATBOT_SHOW:
      return { ...state, chatbotShow: (action as SetChatBotShowType).payload.chatbotShow };
    case UPDATE_UI_VERSION:
      // eslint-disable-next-line no-case-declarations
      const payload = (action as UpdateUiVersionType).payload;
      if (!state.runningUiVersion)
        return { ...state, runningUiVersion: payload.version, currentUiVersion: payload.version };
      return { ...state, currentUiVersion: payload.version };
    default:
      return state;
  }
};
