import { ts } from '_core';

import { getGeneralAnalysisPrefix, validate } from './helpers';
import { DraftParams, INTERACTION_PARAMS } from './types';

export default [
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.SIGNAL_SET_CUMULATIVE_RETURNS,
    title: 'Cumulative Returns over Time',
    helpLabel: '7e5j4epg',
    generalAnalysisReportHelp: {
      document: ts.enums.UI_RESOURCE_TYPE_ENUM.SIGNAL_SET,
      contentPath: `/${ts.enums.SIGNAL_SET_ANALYSIS_TYPE_ENUM.ANALYSIS}`,
    },
    form: true,
    neededFiles: ['signal_set_cumulative_returns'],
    tags: [
      ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.SIGNAL_SET,
      ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.GENERAL,
    ],

    interactionParams: Object.values(INTERACTION_PARAMS),
    defaultParams: {
      daily_reporting: true,
      return_horizons: [{ periods: { frequency: 'DAILY', amount: 1 }, signature: '1 Day Return' }],
      histories: Object.values(ts.enums.PERIOD_ENUM),
    } as DraftParams,
    generalAnalysisPrefix: 'Signal Set ',
    getGeneralAnalysisPrefix: getGeneralAnalysisPrefix as any,
    validate,
  },
] satisfies ts.types.analysis.WidgetConfig[];
