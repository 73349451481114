import { _, config, hooks, mui, React, ts, ui, useSelector } from '_core';

import DefinitionIndustryCategoryFactors from './industry-category';
import UnusedSubInd from './industry-category/unused-subind';

type DefinitionCategoryFactorsProps = {
  definition: ts.types.riskModel.DefinitionCategoryDraft;
  setDefinition: (_d: ts.types.riskModel.DefinitionCategoryDraft) => void;
  uiMetadata: ts.types.riskModel.UiMetaData;
  setUiMetadata: (_u: ts.types.riskModel.UiMetaData) => void;
  readOnly: boolean;
  validationDef: ts.types.common.ValidationErrors;
  showEmptyCategories?: boolean;
  helpContentPath?: `/${string}`;
};

const DefinitionCategoryFactors: React.FC<DefinitionCategoryFactorsProps> = ({
  definition,
  setDefinition,
  uiMetadata,
  setUiMetadata,
  readOnly,
  validationDef,
  showEmptyCategories,
  helpContentPath = '/definition',
}): React.ReactElement => {
  const uiStyles = hooks.useUiStyles();

  const currencyStore = useSelector((state) => state.resources.currencies);

  const industryCategory = definition.find((d) => d.handle == 'industry');
  const countryCategory = definition.find((d) => d.handle == 'country');
  const currencyCategory = definition.find((d) => d.handle == 'currency');

  const [openModal, setOpenModal] = React.useState('');

  const deleteCategory = (catHandle: string) => {
    setDefinition(definition.filter((c) => c.handle !== catHandle));
  };

  const countries = React.useMemo(() => {
    const countryCategory = definition.find((d) => d.handle == 'country');
    if (_.isEmpty(countryCategory?.factors)) return 'Single country universe';
    return countryCategory.factors
      .map((c) => {
        return config.countries.find((country) => country.code == c.name)?.name ?? 'Unknown Country';
      })
      .join(', ');
  }, [definition]);

  const currencies = React.useMemo(() => {
    const currencyCategory = definition.find((d) => d.handle == 'currency');
    if (_.isEmpty(currencyCategory?.factors)) return 'Single currency universe';
    return currencyCategory.factors
      .map((c) => {
        return currencyStore.find((currency) => currency.alpha_code == c.name)?.name ?? c.name;
      })
      .join(', ');
  }, [definition]);

  const collapseAll = () => {
    const localFactors: Record<string, boolean> = {};

    industryCategory.factors.forEach((industry) => {
      localFactors[`${industry.handle}_collapsed`] = true;
    });

    setUiMetadata({ ...uiMetadata, sections: localFactors });
  };

  return (
    <mui.core.Box>
      {(countryCategory || showEmptyCategories) && (
        <mui.core.Box>
          <ui.ConfirmationDialog
            open={openModal == 'country'}
            title="Are you sure?"
            content="Are you sure you want to delete the Country Category?"
            onSuccess={() => deleteCategory('country')}
            onCancel={() => {
              setOpenModal('');
            }}
            successLabel="Delete"
          />
          <ui.DefCardContainer
            mb={0}
            title="Country Factors"
            helpContent={{
              helpLabel: 'ttrjo75o',
              document: ts.enums.UI_RESOURCE_TYPE_ENUM.RISK_MODEL,
              contentPath: helpContentPath,
              contentTitle: 'Country Factors (Factor Based)',
            }}
          >
            <mui.core.Box>
              <mui.core.Box sx={uiStyles.emptyBoxInline} display="flex" alignItems="center">
                <mui.core.Typography sx={uiStyles.emptyBoxContent} flex="1">
                  {countries}
                </mui.core.Typography>
                <mui.core.Box ml={4}>
                  {!readOnly && (
                    <mui.core.Button
                      variant="outlined"
                      onClick={() => deleteCategory('country')}
                      size="small"
                      sx={{ fontSize: '12px' }}
                    >
                      Delete Country Category
                    </mui.core.Button>
                  )}
                </mui.core.Box>
              </mui.core.Box>
            </mui.core.Box>
          </ui.DefCardContainer>
        </mui.core.Box>
      )}
      {(currencyCategory || showEmptyCategories) && (
        <mui.core.Box>
          <ui.ConfirmationDialog
            open={openModal == 'currency'}
            title="Are you sure?"
            content="Are you sure you want to delete the Currency Category?"
            onSuccess={() => deleteCategory('currency')}
            onCancel={() => {
              setOpenModal('');
            }}
            successLabel="Delete"
          />
          <ui.DefCardContainer
            mb={0}
            title="Currency Factors"
            helpContent={{
              helpLabel: '0no8a7hc',
              document: ts.enums.UI_RESOURCE_TYPE_ENUM.RISK_MODEL,
              contentPath: helpContentPath,
              contentTitle: 'Currency Factors (Factor Based)',
            }}
          >
            <mui.core.Box>
              <mui.core.Box sx={uiStyles.emptyBoxInline} display="flex" alignItems="center">
                <mui.core.Typography sx={uiStyles.emptyBoxContent} flex="1">
                  {currencies}
                </mui.core.Typography>
                <mui.core.Box ml={4}>
                  {!readOnly && (
                    <mui.core.Button
                      variant="outlined"
                      onClick={() => deleteCategory('currency')}
                      size="small"
                      sx={{ fontSize: '12px' }}
                    >
                      Delete Currency Category
                    </mui.core.Button>
                  )}
                </mui.core.Box>
              </mui.core.Box>
            </mui.core.Box>
          </ui.DefCardContainer>
        </mui.core.Box>
      )}
      {(industryCategory || showEmptyCategories) && (
        <mui.core.Box mt={5}>
          <mui.core.Box display="flex" alignItems="center">
            <mui.core.Typography variant="h6" sx={{ flex: 1 }}>
              Industry Factors
            </mui.core.Typography>
            <mui.core.Stack flexDirection="row" gap={2}>
              <mui.core.Button sx={{ fontSize: '12px' }} size="small" onClick={collapseAll} variant="outlined">
                Collapse all
              </mui.core.Button>
              <UnusedSubInd
                definition={industryCategory}
                readOnly={readOnly}
                setDefinition={(indCat) => setDefinition(definition.map((d) => (d.handle == 'industry' ? indCat : d)))}
              />
              {!readOnly && (
                <mui.core.Box>
                  <mui.core.Button
                    variant="outlined"
                    onClick={() => setOpenModal('industry')}
                    size="small"
                    sx={{ fontSize: '12px' }}
                  >
                    Delete Industry Category
                  </mui.core.Button>
                </mui.core.Box>
              )}
            </mui.core.Stack>
          </mui.core.Box>
          <ui.ConfirmationDialog
            open={openModal == 'industry'}
            title="Are you sure?"
            content="Are you sure you want to delete the Industry Category?"
            onSuccess={() => deleteCategory('industry')}
            onCancel={() => {
              setOpenModal('');
            }}
            successLabel="Delete"
          />
          <DefinitionIndustryCategoryFactors
            definition={industryCategory}
            setDefinition={(indCat) => setDefinition(definition.map((d) => (d.handle == 'industry' ? indCat : d)))}
            sectionsMetadata={uiMetadata?.sections ?? {}}
            setSectionsMetadata={(v) => setUiMetadata({ ...uiMetadata, sections: v })}
            readOnly={readOnly}
            validationDef={validationDef}
          />
        </mui.core.Box>
      )}
    </mui.core.Box>
  );
};

export default DefinitionCategoryFactors;
