import React from 'react';

import * as hooks from '../../../hooks';
import { Link, mui } from '../../../libs';
import Button from '../../button';
import DragDropFiles from '../../drag-drop-files';
import { UploadContext } from '../upload-context';

const InitialStep = (): React.ReactElement => {
  const uploadContext = React.useContext(UploadContext);
  const { handleCreateHoldings, processUpload, setErrorMessage } = uploadContext;
  const uiStyles = hooks.useUiStyles();

  const [file, setFile] = React.useState<File>();
  const [loadingCreate, setLoadingCreate] = React.useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);

  function handleClick(event: { currentTarget: any }) {
    if (anchorEl !== event.currentTarget) {
      setAnchorEl(event.currentTarget);
    }
  }

  function handleClose() {
    setAnchorEl(null);
  }

  const handleSubmit = async () => {
    setErrorMessage('');
    setLoadingCreate(true);
    await handleCreateHoldings();
    setLoadingCreate(false);
  };

  React.useEffect(() => {
    if (file) processUpload(file);
  }, [file]);

  const fileContent = () => (
    <>
      <mui.core.Box display="flex" mb={3} alignItems="center">
        <mui.core.Typography color="textSecondary">We accept .csv</mui.core.Typography>

        <Link
          to="#"
          aria-owns={anchorEl ? 'simple-menu' : undefined}
          aria-haspopup="true"
          onClick={handleClick}
          onMouseOver={handleClick}
          style={{
            marginLeft: '0.33rem',
            display: 'flex',
          }}
        >
          <mui.icons.HelpOutline
            sx={{
              fontSize: '1rem',
            }}
          />
        </Link>
      </mui.core.Box>

      {file ? (
        <>
          <mui.core.Box sx={uiStyles.fileBoxFull} key={file.name}>
            <mui.core.Typography variant="body1" style={{ marginRight: '1rem', flexGrow: '1' }}>
              {file?.name}
            </mui.core.Typography>
            <mui.core.IconButton
              size="small"
              onClick={() => {
                setFile(undefined);
              }}
            >
              <mui.icons.Close style={{ fontSize: '1rem' }} />
            </mui.core.IconButton>
          </mui.core.Box>
        </>
      ) : (
        <>
          <DragDropFiles setFile={setFile} multiple={false} />
        </>
      )}

      <mui.core.Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        MenuListProps={{ onMouseLeave: handleClose }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
          },
        }}
      >
        <mui.core.Box sx={{ padding: '1.5rem 1rem 1rem', fontSize: '14px' }}>
          <mui.core.IconButton
            onClick={handleClose}
            size="small"
            sx={{
              opacity: 0.9,
              position: 'absolute',
              right: '0.5rem',
              top: '0.66rem',
            }}
          >
            <mui.icons.Close />
          </mui.core.IconButton>
          <mui.core.Stack px={3} display="flex" gap={3}>
            <mui.core.Box>
              <mui.core.Typography paragraph variant="h6" sx={{ fontWeight: 500 }}>
                Column Rules
              </mui.core.Typography>
              <mui.core.Stack flexDirection="row" gap={2} alignItems="center" mb={3}>
                One of
                <mui.core.Chip label="CUSIP" size="small" color="primary" />
                ,
                <mui.core.Chip label="ISIN" size="small" color="primary" />
                ,
                <mui.core.Chip label="SEDOL" size="small" color="primary" />
                ,
                <mui.core.Chip label="Bloomberg Ticker" size="small" color="primary" />
                or
                <mui.core.Chip label="Ticker + Country" size="small" color="primary" />
                is required.
              </mui.core.Stack>
              <mui.core.Stack flexDirection="row" gap={2} alignItems="center" mb={4}>
                If
                <mui.core.Chip label="Ticker" size="small" color="primary" />
                is selected,
                <mui.core.Chip label="Country" size="small" color="primary" />
                is a required column.
              </mui.core.Stack>
              <mui.core.Stack flexDirection="row" gap={2} alignItems="center" mb={4}>
                One of
                <mui.core.Chip label="Share Count" size="small" color="primary" />
                or
                <mui.core.Chip label="Weights" size="small" color="primary" />
                is a required column.
              </mui.core.Stack>
            </mui.core.Box>
            <strong style={{ fontWeight: '500' }}>Example:</strong>
            <img src={(window as any).CUSTOM_URL + '/holdings-example.png'} width="828px" height="348px" />
          </mui.core.Stack>
        </mui.core.Box>
      </mui.core.Menu>
    </>
  );

  return (
    <mui.core.DialogContent>
      <mui.core.Box maxWidth={800} margin="auto">
        {fileContent()}

        <mui.core.Box mt={4}>
          <Button
            variant="contained"
            color="primary"
            loading={loadingCreate}
            disabled={!file || loadingCreate}
            onClick={handleSubmit}
            fullWidth
          >
            Map Columns
          </Button>
        </mui.core.Box>
      </mui.core.Box>
    </mui.core.DialogContent>
  );
};

export default InitialStep;
