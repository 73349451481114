import { helpers, ts } from '_core';

import { DraftParams } from './types';

export const formatSeriesName = (key: string, baskets: ts.types.basket.Basket[]) => {
  const currentBasket = baskets.find((b) => b.handle == key);
  if (currentBasket) return currentBasket.name;

  return key;
};

export const prepareLinearData = (localData: ts.types.widgets.LinearData, baskets: ts.types.basket.Basket[] = []) => {
  const newData = {} as ts.types.widgets.LinearData;
  Object.keys(localData).forEach((key) => {
    const formattedKey = formatSeriesName(key.split('-')[0], baskets);
    if (key.includes('-portfolio_count')) newData[formattedKey] = localData[key];
  });

  return newData;
};

export const setUserDefinedOrderLinear = (
  data: ts.types.widgets.LinearData,
  basketId: number,
  basketIds: number[],
  baskets: ts.types.basket.Basket[]
) => {
  if (basketIds.includes(basketId)) {
    const newData = {} as ts.types.widgets.LinearData;
    basketIds.map((bid) => {
      const currentBasket = baskets.find((basket) => basket.id === bid)?.name;
      if (currentBasket) newData[currentBasket] = data[currentBasket];
    });
    return newData;
  }
  return data;
};

export const validate = (params: DraftParams) => helpers.validations.validBasketCompare(params);
