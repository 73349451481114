import { ts } from '_core';

import { getName, validate } from './helpers';
import { INTERACTION_PARAMS } from './types';

export default [
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.SIGNAL_SET_RELATIVE_RETURNS,
    title: 'Relative Returns',
    helpLabel: 'pvdw1dw2',
    form: true,
    interactionParams: Object.values(INTERACTION_PARAMS),
    neededFiles: ['signal_set'],
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.SIGNAL_SET],

    getName,
    validate,
  },
] satisfies ts.types.analysis.WidgetConfig[];
