import { ts } from '_core';

import { INTERACTION_PARAMS } from './types';

export default [
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.PORTFOLIO_RISK_SUMMARY,
    title: 'Risk',
    helpLabel: 't9eajs2q',
    form: false,
    interactionParams: Object.values(INTERACTION_PARAMS),
    clearInteractionOnRegenerate: ['table_dtypes'],
    neededFiles: ['portfolio_risk_summary'],
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.REBALANCE_PORTFOLIO_RISK],
  },
] satisfies ts.types.analysis.WidgetConfig[];
