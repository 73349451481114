/* eslint-disable no-unused-vars */
import { ts } from '_core';

export enum INTERACTION_PARAMS {
  WIDGET_TYPE = 'widgetType',
}

export type InteractionParams = {
  [INTERACTION_PARAMS.WIDGET_TYPE]?: 'benchmark' | 'regular';
};

export type JobParams = {
  return_horizons?: ts.types.returnHorizon.ReturnHorizon[];
  with_benchmark: boolean;
  benchmark?: Record<string, ts.enums.BENCHMARK_TYPES_ENUM | string | number>;
};

export type Params = JobParams & InteractionParams;
export type DraftParams = Partial<Params>;
