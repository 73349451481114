import { config, helpers, ts } from '_core';

export const getDefaultDefinition = (
  longOption: ts.enums.LONG_OPTION_ENUM,
  optimizerType: ts.types.optimizer.OptimizerConfig['optimizer_type']
): ts.types.backtest.DefinitionDraft => {
  const defaultDefinition = {
    optimizer_rebalance: true,
    minimum_weight: 1e-4,
    min_data_coverage: 0.8,
    frequency: ts.enums.FREQUENCY_ENUM.MONTHLY,
    initial_value: 100000000,
    tcost_multiplier: 1,
    tcost_strategy: 'None',
  };

  let optimizer_config: ts.types.optimizer.OptimizerConfig = {
    optimizer_type: optimizerType,
    objective_function: {
      id: helpers.gibberishGenerator.stringGenerator(11),
      mean_variance_type: 'default',
      tcost_multiplier: null,
      benchmark_relative: false,
      lambda_: optimizerType == 'CVXPY' ? 2.0 : 1 / 100,
    },
    constraints: [
      {
        constraint_type: ts.enums.OPTIMIZER_CONSTRAINT_TYPE_ENUM.GROSS_LEVERAGE_CONSTRAINT,
        id: helpers.gibberishGenerator.stringGenerator(11),
        lower_bound: 1.98,
        upper_bound: 2.02,
        relax_order: 'NEVER',
        target_penalty: 1,
      },
      {
        constraint_type: ts.enums.OPTIMIZER_CONSTRAINT_TYPE_ENUM.NET_LEVERAGE_CONSTRAINT,
        id: helpers.gibberishGenerator.stringGenerator(11),
        lower_bound: -0.01,
        upper_bound: 0.01,
        relax_order: 'NEVER',
        target_penalty: 1,
      },
    ],
    keep_all_linked_assets: true,
  };

  if (longOption == ts.enums.LONG_OPTION_ENUM.LONG_ONLY) {
    optimizer_config = {
      optimizer_type: optimizerType,
      objective_function: {
        id: helpers.gibberishGenerator.stringGenerator(11),
        mean_variance_type: 'default',
        tcost_multiplier: null,
        benchmark_relative: false,
        lambda_: optimizerType == 'CVXPY' ? 2.0 : 1 / 100,
      },
      constraints: [
        {
          constraint_type: ts.enums.OPTIMIZER_CONSTRAINT_TYPE_ENUM.GROSS_LEVERAGE_CONSTRAINT,
          id: helpers.gibberishGenerator.stringGenerator(11),
          lower_bound: 1,
          upper_bound: 1,
          target_penalty: 1,
          relax_order: 'NEVER',
        },
        {
          constraint_type: ts.enums.OPTIMIZER_CONSTRAINT_TYPE_ENUM.ALL_ASSETS_CONSTRAINT,
          precedence: 0,
          lower_bound: 0,
          relax_order: 'NEVER',
          benchmark_relative: false,
          benchmark_relative_mode: 'PLUS',
          type: 'AllAssets',
        } as ts.types.optimizer.AssetAll,
      ],
      keep_all_linked_assets: true,
    };
  }

  return { ...defaultDefinition, optimizer_config };
};

export const getNewBacktest = (
  name: string,
  handle: string,
  longOption: ts.enums.LONG_OPTION_ENUM = ts.enums.LONG_OPTION_ENUM.LONG_ONLY,
  optimizerType: ts.types.optimizer.OptimizerConfig['optimizer_type'] = 'CVXPY',
  template: ts.types.backtest.Backtest = null
) => {
  const nullDefinition = getDefaultDefinition(longOption, optimizerType);

  const newBacktest: ts.types.backtest.BacktestDraft = {
    name,
    definition: nullDefinition,
    ui_metadata: { longOption },
    start_date: config.features.start_date,
    end_date: config.features.end_date,
    handle,
    is_valid: false,
  };

  // Check if we will create the backtest with a premade definition
  if (template && template.id) {
    newBacktest.definition = template.definition;
    newBacktest.definition.weights_from_file = false;
    newBacktest.start_date = template.start_date;
    newBacktest.end_date = template.end_date;
    newBacktest.end_date_latest = template.end_date_latest;
    newBacktest.signature = template.signature;
    newBacktest.is_valid = template.is_valid;
    newBacktest.label_ids = template.label_ids;
    newBacktest.definition.optimizer_config.optimizer_type = optimizerType;
    newBacktest.definition.use_portfolio = false;
  }

  return newBacktest;
};

export const getArchivedDeps = (
  backtest: ts.types.backtest.Backtest,
  resources: ts.StoreState['resources']
): ts.types.common.ArchivedDeps => {
  const deps = helpers.resourceGraph.getDeps(
    { ...backtest, resourceType: ts.enums.RESOURCES_TYPES_ENUM.BACKTEST },
    resources
  );
  return helpers.resourceGraph.getArchiveDeps(deps);
};

export const getUnpublishedDeps = (
  backtest: ts.types.backtest.Backtest,
  resources: ts.StoreState['resources']
): ts.types.common.ResourceDeps[] => {
  return helpers.resourceGraph
    .getDeps({ ...backtest, resourceType: ts.enums.RESOURCES_TYPES_ENUM.BACKTEST }, resources)
    .filter((d) => !d.is_published);
};

export const getUnsharedDeps = (
  backtest: ts.types.backtest.Backtest,
  resources: ts.StoreState['resources'],
  sharedWith?: number[]
): ts.types.common.ResourceDeps[] => {
  if (!sharedWith) sharedWith = backtest.shared_with || [];

  return helpers.resourceGraph
    .getDeps({ ...backtest, resourceType: ts.enums.RESOURCES_TYPES_ENUM.BACKTEST }, resources)
    .filter((d) => !helpers.resourcesUtils.isShared(d, sharedWith));
};
