import React from 'react';

import { SEPARATORS_ENUM } from '../enums';
import { _ } from '../libs';

/**
 * Replaces null or empty values in data with empty strings
 * @param data - Array of objects containing string/number/boolean values
 * @returns The data array with null/empty values replaced with empty strings
 */
const replaceNulls = (data: Record<string, string | number | boolean>[]) => {
  data.forEach((d) => {
    Object.keys(d).forEach((key) => {
      if (_.isNil(d[key]) || !d[key]?.toString()) d[key] = '';
    });
  });
  return data;
};

type Column = {
  key: string | number;
  name?: string | React.ReactElement; // This won't be printed because we have clean name if name is an element
  cleanName?: string;
};

/**
 * Converts data to CSV format
 * @param oData - Array of objects containing the data to convert
 * @param columns - Array of column definitions specifying keys and names
 * @param separator - Separator character to use (default: comma)
 * @param forUpload - Whether to include index column (default: true)
 * @param wrapString - Whether to wrap values in quotes (default: true)
 * @returns Array of CSV formatted strings, one per row
 */
const toCsv = (
  oData: Record<string, string | number | boolean>[],
  columns: Column[],
  separator = SEPARATORS_ENUM.COMMA,
  forUpload = true,
  wrapString = true
): string[] => {
  const data = replaceNulls(oData);

  const mapColName = (c: Column) => {
    if (wrapString) return `"${c.cleanName || c.name || c.key}"`;
    return c.cleanName || c.name || c.key;
  };

  const rows = forUpload
    ? [`${separator}${columns.map(mapColName).join(separator)}\n`]
    : [`${columns.map(mapColName).join(separator)}\n`];

  if (!_.isEmpty(data)) {
    data.forEach((rowData) => {
      const rowAttrs: Array<string | number | boolean> = [];

      columns.forEach((col) => {
        if (wrapString) rowAttrs.push(`"${rowData[col.key]?.toString() || ''}"`);
        else rowAttrs.push(`${rowData[col.key]?.toString() || ''}`);
      });

      rows.push(`${rowAttrs.join(separator)}\n`);
    });
  }
  return rows;
};

/**
 * Transposes data array by converting columns to rows based on a key
 * @param oData - Array of objects containing the data to transpose
 * @param transposeOn - Key to use as the basis for transposition
 * @param transposedNewName - New name for the transposed column (default: '')
 * @returns Array of transposed data objects
 */
const transpose = (oData: Record<string, string | number>[], transposeOn: string, transposedNewName = '') => {
  const transposedData = [] as Record<string, string | number>[];
  Object.keys(oData?.[0] || {}).forEach((key) => {
    if (key !== 'date') {
      const newRow = {} as any;
      oData.forEach((obj) => {
        newRow[obj[transposeOn]] = obj[key];
      });
      transposedData.push({
        [transposedNewName]: key,
        ...newRow,
      });
    }
  });
  return transposedData;
};

export { toCsv, transpose };
