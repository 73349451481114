import { userapiendpoint } from './config';
import { $, gzip } from '../libs';
import { PortfolioHoldings as AppPortfolioHoldings } from '../types/app-portfolio';
import { Submission } from '../types/batch';
import { PortfolioHoldings } from '../types/portfolio-holdings';

type SubmissionResponse = {
  message: string;
  submission_id: string;
};

// we will send this data compressed
export const universe_post_submission = (
  token: string,
  handle: string,
  data: Submission,
  batch: string = null
): JQuery.jqXHR<SubmissionResponse> => {
  const compressed = gzip(JSON.stringify(data));
  return $.ajax({
    method: 'POST',
    url: `${userapiendpoint}universe/${handle}`,
    data: compressed,
    processData: false,
    headers: {
      'x-authtoken': token,
      batch: batch || undefined,
      'Content-Encoding': 'identity',
      'Content-Type': 'application/octet-stream+json',
      'Gzip-Encoded': true,
      'Content-Length': compressed.length,
    },
  } as any);
};

export const signal_post_submission = (
  token: string,
  handle: string,
  data: Submission,
  batch: string = null
): JQuery.jqXHR<SubmissionResponse> =>
  $.ajax({
    method: 'POST',
    url: `${userapiendpoint}signal/${handle}`,
    json: { ...data, history: true },
    headers: { 'x-authtoken': token, batch: batch || undefined },
  } as JQueryAjaxSettings);

export const signal_update_batch = (
  token: string,
  handle: string,
  batch: string
): JQuery.jqXHR<{ message: string; name: string }> =>
  $.ajax({
    method: 'POST',
    url: `${userapiendpoint}signal/${handle}/batch?submit=true&name=${batch}`,
    headers: { 'x-authtoken': token },
  } as JQueryAjaxSettings);

export const data_source_post_submission = (
  token: string,
  handle: string,
  data: Submission,
  batch: string = null
): JQuery.jqXHR<SubmissionResponse> =>
  $.ajax({
    method: 'POST',
    url: `${userapiendpoint}data_source/${handle}`,
    json: { ...data, history: true },
    headers: { 'x-authtoken': token, batch: batch || undefined },
  } as JQueryAjaxSettings);

export const data_source_update_batch = (
  token: string,
  handle: string,
  batch: string
): JQuery.jqXHR<{ message: string; name: string }> =>
  $.ajax({
    method: 'POST',
    url: `${userapiendpoint}data_source/${handle}/batch?submit=true&name=${batch}`,
    headers: { 'x-authtoken': token },
  } as JQueryAjaxSettings);

// we will send this data compressed
export const portfolio_holdings_post_holdings = (
  token: string,
  holdingId: number,
  data: any
): JQuery.jqXHR<{ mapped_file?: string; unmapped_file?: string }> => {
  return $.ajax({
    method: 'POST',
    url: `${userapiendpoint}portfolio_holdings/${holdingId}/holdings`,
    json: data,
    headers: {
      'x-authtoken': token,
    },
  } as any);
};

export const app_portfolio_trigger_jobs = (token: string, handle: string, date: string): JQuery.jqXHR<any> => {
  return $.ajax({
    method: 'POST',
    url: `${userapiendpoint}app_portfolio_holdings/${handle}/trigger-portfolio-jobs`,
    json: { date },
    headers: {
      'x-authtoken': token,
    },
  } as any);
};

// we will send this data compressed
export const app_portfolio_holdings_post_holdings = (
  token: string,
  holdingId: number,
  data: any
): JQuery.jqXHR<{ mapped_file?: string; unmapped_file?: string }> => {
  return $.ajax({
    method: 'POST',
    url: `${userapiendpoint}app_portfolio_holdings/${holdingId}/holdings`,
    json: data,
    headers: {
      'x-authtoken': token,
    },
  } as any);
};

// we will send this data compressed
export const backtest_portfolio_post_holdings = (
  token: string,
  holdingsId: number,
  data: any
): JQuery.jqXHR<{ mapped_file?: string; unmapped_file?: string }> => {
  const compressed = gzip(JSON.stringify(data));
  return $.ajax({
    method: 'POST',
    url: `${userapiendpoint}backtest/${holdingsId}/holdings`,
    data: compressed,
    processData: false,
    headers: {
      'x-authtoken': token,
      'Content-Encoding': 'identity',
      'Content-Type': 'application/octet-stream+json',
      'Gzip-Encoded': true,
      'Content-Length': compressed.length,
    },
  } as any);
};

export const portfolio_multi_holdings = (
  token: string,
  handle: string,
  data: {
    column_map: Record<string, string>;
    csv: string;
    confirmed?: boolean;
    cash_identifier?: string;
    cash_value?: string;
  }
): JQuery.jqXHR<{ data: PortfolioHoldings[]; dates: string[] }> => {
  return $.ajax({
    method: 'POST',
    url: `${userapiendpoint}portfolio_holdings/${handle}/multi-holdings`,
    json: data,
    headers: {
      'x-authtoken': token,
    },
  } as any);
};

export const app_portfolio_multi_holdings = (
  token: string,
  handle: string,
  data: {
    column_map: Record<string, string>;
    csv: string;
    confirmed?: boolean;
    cash_identifier?: string;
    cash_value?: string;
  }
): JQuery.jqXHR<{ data: AppPortfolioHoldings[]; dates: string[] }> => {
  return $.ajax({
    method: 'POST',
    url: `${userapiendpoint}app_portfolio_holdings/${handle}/multi-holdings`,
    json: data,
    headers: {
      'x-authtoken': token,
    },
  } as any);
};
