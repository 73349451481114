import { _, hooks, mui, React, ts, useSelector } from '_core';

import { prepareBarData, prepareData } from './helpers';
import { DraftParams, Params } from './types';
import ChartWrapper from '../../common/chart-wrapper';
import createLineChart from '../../common/charts/create-line-chart';
import createNestedBarChart from '../../common/charts/create-nested-bar-chart';

type ChartProps = {
  id: string;
  readOnly: boolean;
  widgetKey: string;
  loadWidgetData: (_payload: ts.types.widgets.WidgetGetDataParams) => Promise<ts.types.widgets.WidgetGetDataResponse>;
  file: string;
  fullScreen: boolean;
  params: Params | DraftParams;
  setParams: (_p: DraftParams) => void;
  title: string;
};

const Chart: React.FC<ChartProps> = ({
  id,
  readOnly,
  widgetKey,
  loadWidgetData,
  file,
  fullScreen,
  title,
  params,
  setParams,
}) => {
  const uiStyles = hooks.useUiStyles();

  const [data, setData] = React.useState<ts.types.widgets.TableData>(null);
  const [error, setError] = React.useState<ts.types.common.ApiError>(null);
  const [showLegend, setShowLegend] = React.useState(false);

  const [plotType, setPlotType] = React.useState(params.plot_type || 'line');

  const resources = useSelector((state) => state.resources);

  const loadData = async () => {
    try {
      const response = await loadWidgetData({
        data: {
          file,
          rename: { build_date: 'date' },
        },
      });

      if (response) setData(response.data);
    } catch (err) {
      setError(err as ts.types.common.ApiError);
    }
  };

  React.useEffect(() => {
    if (file) loadData();
    return () => setData(null);
  }, [file]);

  React.useEffect(() => {
    // Create line chart on data and scale changes
    if (!_.isNil(data)) {
      if (plotType == 'line') {
        createLineChart({
          id,
          scale: 'linear',
          data,
          fullScreen,
          percent: false,
          setShowLegend,
          exportTitle: title,
          prepareData: (linearData) => prepareData(resources, linearData),
        });
      } else {
        createNestedBarChart({
          id,
          data,
          fullScreen,
          setShowLegend,
          category: 'date',
          exportTitle: title,
          rotatedLabels: true,
          prepareData: (nestedBarData) => ({
            ...nestedBarData,
            data: prepareBarData(resources, nestedBarData.data),
          }),
        });
      }
    }
  }, [data, plotType]);

  const debouncedSaveParams = React.useCallback(
    _.debounce((plotType) => {
      setParams({
        ...params,
        plot_type: plotType,
      });
    }, 1000),
    []
  );

  hooks.useEffectWithoutFirst(() => {
    debouncedSaveParams(plotType);
  }, [plotType]);

  return (
    <mui.core.Box display="flex" flexDirection="column" style={{ height: '100%' }}>
      <mui.core.Box px={4} mt={2} sx={uiStyles.hidePrint}>
        <mui.core.FormControl component="fieldset">
          <mui.core.RadioGroup
            row
            aria-label="position"
            name="position"
            value={plotType}
            onChange={(e) => {
              setPlotType(e.target.value as 'line' | 'bar');
            }}
          >
            <mui.core.FormControlLabel
              value="line"
              control={<mui.core.Radio color="primary" />}
              label="Plot as Line Chart"
            />
            <mui.core.FormControlLabel
              value="bar"
              control={<mui.core.Radio color="primary" />}
              label="Plot as Bar Chart"
            />
          </mui.core.RadioGroup>
        </mui.core.FormControl>
      </mui.core.Box>

      <mui.core.Box p={3} flex="1">
        <ChartWrapper
          id={id}
          readOnly={readOnly}
          widgetKey={widgetKey}
          data={data}
          showLegend={showLegend}
          error={error}
          fullScreen={fullScreen}
        />
      </mui.core.Box>
    </mui.core.Box>
  );
};

export default Chart;
