import { hooks, mui, React, ts, ui } from '_core';

import { Params } from './types';

type DraftWidgetParams = Params;

type FormProps = {
  params: DraftWidgetParams;
  setParams: (_p: DraftWidgetParams) => void;
  context?: ts.types.signal.SignalAnalysisContext;
};

const Form: React.FC<FormProps> = ({ params, setParams }) => {
  const [comparets, setcomparets] = React.useState(params.analysis_time_series_ids || []);

  const uiStyles = hooks.useUiStyles();

  React.useEffect(() => {
    const data = {
      ...params,
      analysis_time_series_ids: comparets,
    } as DraftWidgetParams;
    setParams(data);
  }, [comparets]);

  return (
    <mui.core.Box sx={uiStyles.widgetEditMode}>
      <mui.core.Box mt={4}>
        <ui.ResourceAutocomplete<ts.types.timeSeries.TimeSeriesExpanded, false>
          value={comparets[0]}
          setValue={(id) => setcomparets([id])}
          type={ts.enums.RESOURCES_TYPES_ENUM.TIME_SERIES}
          autocompleteProps={{
            size: 'small',
            multiple: false,
            disableCloseOnSelect: true,
          }}
          inputProps={{
            label: 'Select Time Series',
            variant: 'outlined',
            size: 'small',
            required: true,
          }}
        />
      </mui.core.Box>
    </mui.core.Box>
  );
};

export default Form;
