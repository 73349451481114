import { ts } from '_core';

import { validate } from './helpers';
import { INTERACTION_PARAMS } from './types';

export default [
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.UNIVERSE_PREVIEW_DATE_COMPARISON,
    form: true,
    title: 'Date Comparison',
    neededFiles: ['universe_date_comparison'],
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.UNIVERSE_PREVIEW],
    interactionParams: Object.values(INTERACTION_PARAMS),
    clearInteractionOnRegenerate: ['table_dtypes'],

    disableAdd: true,
    validate,
    helpLabel: 'iyyqc1mw',
  },
] satisfies ts.types.analysis.WidgetConfig[];
