import { _, React, ts } from '_core';

import Chart from './chart';
import Form from './form';
import { DraftParams, Params } from './types';

type BacktestAnnualizedStatisticsProps = {
  id: string;
  readOnly: boolean;
  widgetKey: string;
  mode: ts.enums.CHART_MODE_ENUM;
  params: Params;
  setParams: (_p: DraftParams) => void;
  title: string;

  fullScreen?: boolean;
  context?: ts.types.backtest.BacktestAnaylsisContext;
  loadWidgetData: (_payload: ts.types.widgets.WidgetGetDataParams) => Promise<ts.types.widgets.WidgetGetDataResponse>;
  widgetPaths?: Record<'backtest_annualized_statistics', string>;
};

const BacktestAnnualizedStatistics: React.FC<BacktestAnnualizedStatisticsProps> = ({
  id,
  readOnly,
  widgetKey,
  loadWidgetData,
  widgetPaths,
  mode,
  params,
  setParams,
  fullScreen,
  context,
  title,
}): React.ReactElement => {
  const hasBenchmark = React.useMemo(
    () => (params.isBasket ? context?.definition?.optimizer_rebalance : !_.isEmpty(context?.definition?.benchmark)),
    []
  );

  const renderJobComponent = () => (
    <>
      <Chart
        id={id}
        readOnly={readOnly}
        widgetKey={widgetKey}
        loadWidgetData={loadWidgetData}
        portfolios={widgetPaths['backtest_annualized_statistics']}
        fullScreen={fullScreen}
        params={params}
        hasBenchmark={hasBenchmark}
        title={title}
      />
    </>
  );

  return (
    <>
      {mode == 'edit' ? (
        <Form params={params} setParams={setParams} context={context} hasBenchmark={hasBenchmark} />
      ) : (
        renderJobComponent()
      )}
    </>
  );
};

export default BacktestAnnualizedStatistics;
