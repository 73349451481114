type econDevelopmentFilterType = {
  code: string;
  label: string;
};

export const econDevelopmentFilter: econDevelopmentFilterType[] = [
  {
    code: 'Developed Markets',
    label: 'Developed Markets',
  },
  {
    code: 'Emerging Markets',
    label: 'Emerging Markets',
  },
  {
    code: 'Frontier Markets',
    label: 'Frontier Markets',
  },
  {
    code: 'Blended Development',
    label: 'Blended Development',
  },
];
