/**
 * Component that renders a numeric input field with formatting options
 */

import React from 'react';

import * as enums from '../enums';
import { _, mui } from '../libs';
import * as types from '../types';

type MultiplierProps = {
  multiplierOption?: enums.NUMBER_FORMATTERS_ENUM;
  dividerOption?: never;
};

type DividerProps = {
  multiplierOption?: never;
  dividerOption?: enums.NUMBER_FORMATTERS_ENUM;
};

type NumberFormatProps = React.ComponentProps<typeof mui.NumberFormat>;

type NumericFieldProps = {
  textFieldProps?: types.common.PartialRecord<keyof NumberFormatProps & 'label', any>;
  value?: number;
  setValue: (_v: number) => void;
  showExponentialLabel?: boolean;
  onlyPositive?: boolean;
  onlyInteger?: boolean;
  prefix?: string;
  suffix?: string;
  decimalScale?: number;
  disabled?: boolean;
} & (MultiplierProps | DividerProps);

const FORMATTERS = {
  h: 1e2,
  t: 1e3,
  m: 1e6,
  b: 1e9,
};

/**
 * Component that renders a numeric input field with formatting options
 * 
 * @param textFieldProps - Props for the text field
 * @param value - The value of the numeric field
 * @param setValue - The function to set the value of the numeric field
 * @param multiplierOption - The multiplier option for the numeric field
 * @param dividerOption - The divider option for the numeric field
 * @param showExponentialLabel - Whether to show the exponential label
 * @param onlyPositive - Whether to allow only positive values
 * @param onlyInteger - Whether to allow only integer values
 * @param prefix - The prefix to be added to the numeric value
 * @param suffix - The suffix to be added to the numeric value
 * @param decimalScale - The number of decimal places to display
 * @param disabled - Whether the input field is disabled
 * 
 * @returns Rendered component with a numeric input field
 */
const NumericField: React.FC<NumericFieldProps> = ({
  textFieldProps,
  value,
  setValue,
  multiplierOption,
  dividerOption,
  showExponentialLabel,
  onlyPositive,
  onlyInteger,
  prefix,
  suffix,
  decimalScale,
  disabled,
}): React.ReactElement => {
  const actualDecimalScale = onlyInteger ? 0 : decimalScale || 20;
  const multiplier = React.useMemo(() => FORMATTERS[multiplierOption], [multiplierOption]);
  const divider = React.useMemo(() => FORMATTERS[dividerOption], [dividerOption]);

  const [fieldValue, setFieldValue] = React.useState(_.numericValue(value, multiplier, divider, actualDecimalScale));

  React.useEffect(() => {
    if (value != _.numericField(fieldValue, multiplier, divider)) {
      setFieldValue(_.numericValue(value, multiplier, divider, actualDecimalScale));
    }
  }, [value]);

  return (
    <>
      <mui.NumberFormat
        {...textFieldProps}
        InputProps={
          showExponentialLabel
            ? {
                endAdornment: (
                  <mui.core.InputAdornment position="end">{value?.toExponential()}</mui.core.InputAdornment>
                ),
                disabled: disabled,
              }
            : {
                ...(textFieldProps as any).InputProps,
                disabled: disabled,
              }
        }
        value={fieldValue}
        customInput={mui.core.TextField}
        type="text"
        suffix={suffix}
        prefix={prefix}
        thousandSeparator={','}
        decimalScale={actualDecimalScale}
        allowNegative={!onlyPositive}
        onValueChange={({ value: v }) => {
          setFieldValue(v);
          setValue(_.numericField(v, multiplier, divider));
        }}
        disabled={disabled}
      />
    </>
  );
};

NumericField.defaultProps = {
  value: null,
  textFieldProps: {},
  showExponentialLabel: false,
  onlyPositive: false,
  onlyInteger: false,
  prefix: '',
  suffix: '',
};

export default NumericField;
