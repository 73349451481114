import { _, helpers, ts } from '_core';

export const prepareStatisticsVsTimeData = (
  data: ts.types.widgets.TableData,
  statisticType: 'r2' | 'regression' | 'tStatistic' | 'pValue'
) => {
  let keys = _.without(Object.keys(data[0] || {}), 'build_date', 'quantiles');
  const varPattern = /var\d+/g;
  if (statisticType == 'regression')
    keys = keys
      .filter((k) => !k.includes('pvalues|') && !k.includes('tstats|'))
      .map((k, i) => k.replace(varPattern, `var${i}`));

  if (statisticType == 'tStatistic')
    keys = keys.filter((k) => k.includes('tstats|')).map((k, i) => k.replace(varPattern, `var${i}`));

  if (statisticType == 'pValue')
    keys = keys.filter((k) => k.includes('pvalues|')).map((k, i) => k.replace(varPattern, `var${i}`));

  return { data, variables: keys };
};

export const prepareStatisticsVsTimeLinear = (
  data: ts.types.widgets.TableData,
  statisticType: 'r2' | 'regression' | 'tStatistic' | 'pValue',
  selectedTab: string
): ts.types.widgets.LinearData => {
  const quantileLabel = (q: number) => {
    if (q == 0.1) return 'Q1';
    if (q == 0.25) return 'Q2';
    if (q == 0.5) return 'Q3 (median)';
    if (q == 0.75) return 'Q4';
    return 'Q5';
  };

  const lineData = data.map((el) => {
    const newEl = {
      date: el.build_date as string,
      quantiles: quantileLabel(el.quantiles as number),
    } as Record<string, number | string>;

    newEl[statisticType] = (el[selectedTab] as number) || 0;
    return newEl;
  });
  return _.groupBy(lineData, 'quantiles') as ts.types.widgets.LinearData;
};

export const prepareStatisticsVsTimeScatter = (
  data: ts.types.widgets.TableData,
  statisticType: 'r2' | 'regression' | 'tStatistic' | 'pValue',
  selectedTab: string
): ts.types.widgets.ScatterData => {
  return data.map((el) => {
    const newEl = { date: el.build_date as string } as ts.types.widgets.ScatterData[0];
    newEl[statisticType] = (el[selectedTab] as number) || 0;
    return newEl;
  });
};

/*
 * Return an object with the array of information extracted from var:
 * Variables: ['var0', 'var1']
 * Returns: [{ handle: 'signalHandle', realHandle: 'var0', theme: 'signalTheme' }]
 * The handle field can be the signal handle, signal handle + pipeline handle or sHandle + pHandle + spHandle,
 * depending if the signal or signal + pipeline appears more than once on the alpha definition
 */
export const getSignalsInfo = (variables: string[], signals: ts.types.signal.AlphaSignalEnhanced[]) => {
  const handles = helpers.buildName.signalVarNameUniq(signals || []);
  return variables.map((variable) => {
    if (variable.includes('intercept')) return { handle: variable, realHandle: variable, theme: 'Intercept' };

    const varSplitted = variable.split('var');
    const varIndex = parseInt(varSplitted.at(-1));
    const signal = signals?.[varIndex] as ts.types.signal.AlphaSignalEnhanced;
    return {
      handle: handles[varIndex] || variable,
      realHandle: variable,
      theme: signal?.signal_theme || 'Other',
    };
  });
};
