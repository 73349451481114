import { hooks, mui, React, ts, ui } from '_core';

import { DraftParams } from './types';
import BasketsSelector from '../../common/forms/baskets-selector';

type FormProps = {
  params: DraftParams;
  setParams: (_p: DraftParams) => void;
  context?: ts.types.basket.BasketAnalysisContext;
};

const Form: React.FC<FormProps> = ({ params, setParams, context }) => {
  const [basketIds, setBasketIds] = React.useState(params.basket_ids || []);
  const [selectedHistories, setSelectedHistories] = React.useState(params.histories || []);

  const uiStyles = hooks.useUiStyles();

  React.useEffect(() => {
    const data = { histories: selectedHistories };
    setParams({ ...params, ...data, basket_ids: basketIds });
  }, [selectedHistories, basketIds]);

  return (
    <mui.core.Box sx={uiStyles.widgetEditMode}>
      <mui.core.Box mt={2}>
        <ui.WidgetHistories
          context={context}
          value={selectedHistories?.[0]}
          setValue={(v) => setSelectedHistories([v])}
        />
      </mui.core.Box>

      <mui.core.Box mt={2}>
        <mui.core.Box mt={2}>
          <BasketsSelector basketIds={basketIds} setBasketIds={setBasketIds} />
        </mui.core.Box>
      </mui.core.Box>
    </mui.core.Box>
  );
};

export default Form;
