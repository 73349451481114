import React from 'react';
import { MaterialTableProps } from '@material-table/core/types';

import { mui } from '../libs';

type CompleteMaterialTableProps = MaterialTableProps<any>;

const tableIcons = {
  Add: React.forwardRef((props: CompleteMaterialTableProps, ref: React.Ref<SVGSVGElement>) => (
    <mui.icons.AddCircle {...props} ref={ref} />
  )),
  Check: React.forwardRef((props: CompleteMaterialTableProps, ref: React.Ref<SVGSVGElement>) => (
    <mui.icons.Check {...props} ref={ref} />
  )),
  Clear: React.forwardRef((props: CompleteMaterialTableProps, ref: React.Ref<SVGSVGElement>) => (
    <mui.icons.Clear {...props} ref={ref} />
  )),
  Delete: React.forwardRef((props: CompleteMaterialTableProps, ref: React.Ref<SVGSVGElement>) => (
    <mui.icons.DeleteOutline {...props} ref={ref} />
  )),
  DetailPanel: React.forwardRef((props: CompleteMaterialTableProps, ref: React.Ref<SVGSVGElement>) => (
    <mui.icons.ChevronRight {...props} ref={ref} />
  )),
  Edit: React.forwardRef((props: CompleteMaterialTableProps, ref: React.Ref<SVGSVGElement>) => (
    <mui.icons.Edit {...props} ref={ref} />
  )),
  Export: React.forwardRef((props: CompleteMaterialTableProps, ref: React.Ref<SVGSVGElement>) => (
    <mui.icons.SaveAlt {...props} ref={ref} />
  )),
  Filter: React.forwardRef((props: CompleteMaterialTableProps, ref: React.Ref<SVGSVGElement>) => (
    <mui.icons.FilterList {...props} ref={ref} />
  )),
  FirstPage: React.forwardRef((props: CompleteMaterialTableProps, ref: React.Ref<SVGSVGElement>) => (
    <mui.icons.FirstPage {...props} ref={ref} />
  )),
  LastPage: React.forwardRef((props: CompleteMaterialTableProps, ref: React.Ref<SVGSVGElement>) => (
    <mui.icons.LastPage {...props} ref={ref} />
  )),
  NextPage: React.forwardRef((props: CompleteMaterialTableProps, ref: React.Ref<SVGSVGElement>) => (
    <mui.icons.ChevronRight {...props} ref={ref} />
  )),
  PreviousPage: React.forwardRef((props: CompleteMaterialTableProps, ref: React.Ref<SVGSVGElement>) => (
    <mui.icons.ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: React.forwardRef((props: CompleteMaterialTableProps, ref: React.Ref<SVGSVGElement>) => (
    <mui.icons.Clear {...props} ref={ref} />
  )),
  Search: React.forwardRef((props: CompleteMaterialTableProps, ref: React.Ref<SVGSVGElement>) => (
    <mui.icons.Search {...props} ref={ref} />
  )),
  SortArrow: React.forwardRef((props: CompleteMaterialTableProps, ref: React.Ref<SVGSVGElement>) => (
    <mui.icons.ArrowDownward {...props} ref={ref} />
  )),
  ThirdStateCheck: React.forwardRef((props: CompleteMaterialTableProps, ref: React.Ref<SVGSVGElement>) => (
    <mui.icons.Remove {...props} ref={ref} />
  )),
  ViewColumn: React.forwardRef((props: CompleteMaterialTableProps, ref: React.Ref<SVGSVGElement>) => (
    <mui.icons.ViewColumn {...props} ref={ref} />
  )),
};

/**
 * CompleteMaterialTable component that renders a Material Table with custom icons.
 * 
 * @param props - The properties passed to the CompleteMaterialTable component.
 * 
 * @returns Rendered CompleteMaterialTable component displaying a Material Table.
 */

const CompleteMaterialTable: React.FC<CompleteMaterialTableProps> = (props): React.ReactElement => (
  <div className="material-table">
    <mui.MaterialTable icons={tableIcons} {...props} />
  </div>
);

export default CompleteMaterialTable;
