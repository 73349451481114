import { api, hooks, React, ts, useHistory } from '_core';

import * as utils from './ai-assistant-utils';

const POLL_ID = 'conversation_poll';

export type ProviderProps = {
  children?: React.ReactNode;
  service: ts.types.aiAssistant.ThreadService;
  autofocus?: boolean;
  visible?: boolean;
  setThreadId?: (_v: number) => void;
  initialThreadId?: number;
  extraActions?: () => React.ReactElement;
};

type AIAssistantContextProps = {
  hasError: boolean;
  loadingResponse: boolean;
  starting: boolean;
  parentContainerRef: React.MutableRefObject<HTMLDivElement>;
  hookedAtBottomRef: React.MutableRefObject<boolean>;
  scrollToTheBottom: (_skipBottomHook?: boolean) => void;
  sendMessage: (_messageText: string) => Promise<void>;
  conversationStarted: boolean;
  service: ts.types.aiAssistant.ThreadService;
  reload: () => void;
  autofocus: boolean;
  conversation: ts.types.aiAssistant.Conversation;
  extraActions?: () => React.ReactElement;
  visible: boolean;
};

const AIAssistantContext = React.createContext<AIAssistantContextProps>(null);

// This context provider is passed to any component requiring the context
const Provider: React.FC<ProviderProps> = ({
  children,
  autofocus,
  service,
  setThreadId,
  initialThreadId,
  extraActions,
  visible = true,
}): React.ReactElement => {
  const [conversation, setConversation] = React.useState<ts.types.aiAssistant.Conversation>(null);
  const prevConversation = hooks.usePrevious(conversation);

  const [hasError, setHasError] = React.useState(false);
  const [starting, setStarting] = React.useState(true);

  const parentContainerRef = React.useRef<HTMLDivElement>(null);
  const hookedAtBottomRef = React.useRef(true);
  const conversationStarted = React.useRef(false);
  const messageIdRef = React.useRef<number>();
  const ignorePollRef = React.useRef(false);

  const history = useHistory();

  const stopPoll = () => {
    // Setting this flag to ignore any current call that could be performing while we stop the polling
    ignorePollRef.current = true;
    if (messageIdRef.current) {
      api.poll.stop(messageIdRef.current, POLL_ID);
    }
  };

  const initialize = (initThreadId?: number) => {
    stopPoll();

    setStarting(true);
    setConversation(null);
    setHasError(false);
    utils.createThread(
      service,
      initThreadId,
      (c) => {
        if (setThreadId) setThreadId(c.id);

        setConversation(c);
        setStarting(false);
        if (c.status == 'busy') pollConversation(c);
      },
      () => {
        setHasError(true);
        setStarting(false);
      }
    );
  };

  React.useEffect(() => {
    initialize(initialThreadId);
    return () => stopPoll();
  }, []);

  React.useEffect(() => {
    if (hasError) stopPoll();
  }, [hasError]);

  const loadingResponse = React.useMemo(() => {
    if (!conversation) return false;
    return conversation.status == 'busy';
  }, [conversation]);

  const scrollToTheBottom = (skipBottomHook = false) => {
    if (skipBottomHook || hookedAtBottomRef.current) {
      parentContainerRef.current?.scrollTo({
        top: parentContainerRef.current?.scrollHeight,
        behavior: 'smooth',
      });
    }
  };

  React.useEffect(() => {
    scrollToTheBottom(true);
  }, [conversation]);

  const randomInt = (min: number, max: number) => Math.floor(Math.random() * (max - min + 1)) + min;
  const pollConversation = (currentConversation = conversation) => {
    ignorePollRef.current = false;
    messageIdRef.current = randomInt(1, 1_000_000);

    api.poll.tillDone(
      messageIdRef.current,
      POLL_ID,
      (c) => c?.status == 'active',
      () => undefined,
      () =>
        utils.loadConversation(
          currentConversation.id,
          (c) => {
            !ignorePollRef.current && setConversation(c);
          },
          () => {
            !ignorePollRef.current && setHasError(true);
          }
        ),
      false,
      0.1
    );
  };

  const sendMessage = async (messageText: string) => {
    conversationStarted.current = true;

    utils.sendMessage(messageText, conversation, setConversation, pollConversation, () => setHasError(true));
  };

  const performAction = async (action: ts.types.aiAssistant.Message['action']) => {
    if (action.action_type == 'open') history.push(`/${action.action_resource_type}/${action.action_resource_id}`);

    return;
  };

  // Perform incoming actions
  React.useEffect(() => {
    const messages = conversation?.messages ?? [];
    const prevMessages = prevConversation?.messages ?? [];

    const newMessages = messages.slice(prevMessages.length, messages.length);
    newMessages.forEach((msg) => {
      if (msg.purpose == 'action') performAction(msg.action);
    });
  }, [conversation]);

  return (
    <AIAssistantContext.Provider
      value={{
        hasError,
        loadingResponse,
        starting,
        parentContainerRef,
        hookedAtBottomRef,
        scrollToTheBottom,
        sendMessage,
        autofocus,
        conversationStarted: conversationStarted.current,
        reload: () => initialize(),
        conversation,
        extraActions,
        service,
        visible,
      }}
    >
      {children}
    </AIAssistantContext.Provider>
  );
};

const AIAssistantContextProvider = Provider;

export { AIAssistantContext };
export { AIAssistantContextProvider };
