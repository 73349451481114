import { hooks, mui, React, ts, useSelector } from '_core';

import * as prefColumns from 'views/preferences/columns-config';
import Tabular from 'views/report/tables/tabular';

import { Params, SHEETS } from './types';
import { getColumns } from '../../common/charts/utils';

type ChartProps = {
  id: string;
  params: Params;
  setParams: (_v: Params) => void;
  portfolios: string;
  alphaExposures: string;
  fullScreen: boolean;
  queryParams: { initial?: string };
  goFullScreen: (_v: string) => void;
  loadWidgetData: (_payload: ts.types.widgets.WidgetGetDataParams) => Promise<ts.types.widgets.WidgetGetDataResponse>;
};

const Chart: React.FC<ChartProps> = ({
  id,
  params,
  setParams,
  portfolios,
  alphaExposures,
  fullScreen,
  queryParams,
  goFullScreen,
  loadWidgetData,
}): React.ReactElement => {
  const resources = useSelector((state) => state.resources);

  const yearlySummarySheetKey = ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.PORTFOLIO_TABULAR_YEARLY_SUMMARY;
  const monthlySummarySheetKey = ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.PORTFOLIO_TABULAR_MONTHLY_SUMMARY;
  const detailsSheetKey = ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.PORTFOLIO_TABULAR_DETAILS;
  const alphaDetailsSheetKey = ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.PORTFOLIO_TABULAR_ALPHA_DETAILS;

  const yearlySummarySheetName = ts.enums.REPORT_ENUMS.TABLE_SHEET_PREF_NAME_ENUM.PORTFOLIO_TABULAR_YEARLY_SUMMARY;
  const monthlySummarySheetName = ts.enums.REPORT_ENUMS.TABLE_SHEET_PREF_NAME_ENUM.PORTFOLIO_TABULAR_MONTHLY_SUMMARY;
  const detailsSheetName = ts.enums.REPORT_ENUMS.TABLE_SHEET_PREF_NAME_ENUM.PORTFOLIO_TABULAR_DETAILS;
  const alphaDetailsSheetName = ts.enums.REPORT_ENUMS.TABLE_SHEET_PREF_NAME_ENUM.PORTFOLIO_TABULAR_ALPHA_DETAILS;

  const [data, setData] = React.useState(null);
  const [error, setError] = React.useState(null);

  const [tableParams, setTableParams] = React.useState<ts.types.components.dataGrid.TableParamsSheet>(
    params.table_params
  );

  hooks.useEffectWithoutFirst(() => {
    setParams({
      ...params,
      table_params: { ...tableParams },
    });
  }, [tableParams]);

  const container = React.createRef();

  const loadAlphaExposuresData = async () => {
    const response = await loadWidgetData({
      data: {
        file: alphaExposures,
      },
    });
    if (response) {
      return response.data;
    }

    return null;
  };

  const loadPortfoliosData = async () => {
    const response = await loadWidgetData({
      data: {
        file: portfolios,
      },
    });
    if (response) {
      const localData = response.data;

      return {
        [yearlySummarySheetKey]: localData.filter((el) => el.tab == yearlySummarySheetKey),
        [monthlySummarySheetKey]: localData.filter((el) => el.tab == monthlySummarySheetKey),
        [detailsSheetKey]: localData.filter((el) => el.tab == detailsSheetKey),
        [alphaDetailsSheetKey]: localData.filter((el) => el.tab == alphaDetailsSheetKey),
      };
    }
  };

  const loadData = async () => {
    try {
      const portfoliosDataResp = await loadPortfoliosData();
      const alphaExposuresData = await loadAlphaExposuresData();
      if (portfoliosDataResp && alphaExposuresData) {
        setData({ ...portfoliosDataResp, [alphaDetailsSheetKey]: alphaExposuresData });
      }
    } catch (err) {
      setError(JSON.stringify(err));
    }
  };

  React.useEffect(() => {
    if (portfolios && alphaExposures) loadData();
    return () => {
      setData(null);
    };
  }, [portfolios, alphaExposures]);

  const columns = prefColumns.portfolioTabularOutputColumns;

  return (
    <mui.core.Box display="flex" flexDirection="column" style={{ height: '100%' }} ref={container}>
      <Tabular<SHEETS>
        data={
          data
            ? {
                [yearlySummarySheetKey]: data?.[yearlySummarySheetKey],
                [monthlySummarySheetKey]: data?.[monthlySummarySheetKey],
                [detailsSheetKey]: data?.[detailsSheetKey],
                [alphaDetailsSheetKey]: data?.[alphaDetailsSheetKey],
              }
            : null
        }
        getColumns={{
          [yearlySummarySheetKey]: (_k, preferences) =>
            getColumns(
              columns[yearlySummarySheetKey].map((el) => el.key),
              preferences?.[yearlySummarySheetKey],
              columns[yearlySummarySheetKey],
              null,
              null,
              []
            ),
          [monthlySummarySheetKey]: (_k, preferences) =>
            getColumns(
              columns[monthlySummarySheetKey].map((el) => el.key),
              preferences?.[monthlySummarySheetKey],
              columns[monthlySummarySheetKey],
              null,
              null,
              []
            ),
          [detailsSheetKey]: (_k, preferences) =>
            getColumns(
              columns[detailsSheetKey].map((el) => el.key),
              preferences?.[detailsSheetKey],
              columns[detailsSheetKey],
              null,
              null,
              []
            ),
          [alphaDetailsSheetKey]: (colKeys, preferences) =>
            getColumns(colKeys, preferences?.[alphaDetailsSheetKey], columns[alphaDetailsSheetKey], null, resources),
        }}
        sheetTitles={{
          [yearlySummarySheetKey]: yearlySummarySheetName,
          [monthlySummarySheetKey]: monthlySummarySheetName,
          [detailsSheetKey]: detailsSheetName,
          [alphaDetailsSheetKey]: alphaDetailsSheetName,
        }}
        fullScreen={fullScreen}
        goFullScreen={goFullScreen}
        queryParams={queryParams}
        customError={error}
        preferences={{
          preferencesSourceWidgetId: id,
          preferenceKey: ts.enums.PREFERENCES_KEY_ENUM.PORTFOLIO_TABULAR_COLS,
          preferenceTab: ts.enums.PREFERENCES_INNER_TABS_ENUM.PORTFOLIO_SUMMARY_AND_DETAILS_COLS,
        }}
        tableParams={tableParams}
        setTableParams={{
          [yearlySummarySheetKey]: (v) => setTableParams((t) => ({ ...t, [yearlySummarySheetKey]: v })),
          [monthlySummarySheetKey]: (v) => setTableParams((t) => ({ ...t, [monthlySummarySheetKey]: v })),
          [detailsSheetKey]: (v) => setTableParams((t) => ({ ...t, [detailsSheetKey]: v })),
          [alphaDetailsSheetKey]: (v) => setTableParams((t) => ({ ...t, [alphaDetailsSheetKey]: v })),
        }}
        triggerWidthChange={params.fullWidth}
      />
    </mui.core.Box>
  );
};

export default Chart;
