import { actions, mui, React, ts, ui, useDispatch } from '_core';

import * as prefColumns from 'views/preferences/columns-config';

import useGetPreferences from '_components/use-get-preferences';

import { DraftParams } from './types';
import { getCSVColumns } from '../../common/charts/utils';

type FormProps = {
  params: DraftParams;
  setParams: (_p: DraftParams) => void;
};

const Form: React.FC<FormProps> = ({ params, setParams }) => {
  const [debug, setDebug] = React.useState(params.debug);
  const [currentView, setCurrentView] = React.useState(params.current_view);

  const dispatch = useDispatch();

  const [loadingPreferences, userColumnsPreferences, preferenceOptions, selectedPreference] = useGetPreferences(
    ts.enums.PREFERENCES_KEY_ENUM.SIGNAL_PREVIEW_COLS,
    currentView
  );

  React.useEffect(() => {
    let columns = null as { name: string; key: string }[];
    if (userColumnsPreferences) {
      columns = getCSVColumns(
        (userColumnsPreferences as ts.types.components.dataGrid.ColumnPreferences)[
          ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.MAIN
        ],
        prefColumns.signalPreviewColumns[ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.MAIN]
      );
    }
    const data = { debug, columns, current_view: currentView };

    setParams({
      ...params,
      ...data,
    });
  }, [debug, userColumnsPreferences]);

  if (loadingPreferences) return <ui.CenteredLoader label="Loading preferences." />;

  const openPreferences = () => {
    dispatch(
      actions.ui.setPreferencesSelectedInnerTab(
        ts.enums.PREFERENCES_INNER_TABS_ENUM.SIGNAL_PREVIEW_COLS,
        selectedPreference?.id
      )
    );
  };

  return (
    <>
      <mui.core.Box mt={2}>
        <mui.core.Box display="flex">
          <mui.core.Box pt={2} sx={{ flex: '1' }}>
            <mui.core.Autocomplete<ts.types.userPreference.UserPreferenceDraft, false, true>
              options={preferenceOptions}
              value={selectedPreference ?? { id: null, title: 'Default' }}
              getOptionLabel={(v) => (v as ts.types.userPreference.UserPreferenceDraft).title}
              onChange={(_e, val) => {
                setCurrentView((val as ts.types.userPreference.UserPreferenceDraft).id);
              }}
              isOptionEqualToValue={(opt, val) => opt.id == val.id}
              disableClearable
              renderInput={(params) => (
                <mui.core.TextField {...params} variant="outlined" required size="small" fullWidth />
              )}
            />
          </mui.core.Box>

          <mui.core.Button color="primary" variant="outlined" onClick={openPreferences}>
            Edit
          </mui.core.Button>
        </mui.core.Box>

        <mui.core.FormControlLabel
          control={
            <mui.core.Switch
              size="small"
              checked={debug}
              onChange={() => {
                setDebug(!debug);
              }}
            />
          }
          label="Debug"
        />
      </mui.core.Box>
    </>
  );
};

export default Form;
