import { React, ts } from '_core';

import Form from './form';
import { Params } from './types';

type CumulativeReturnsProps = {
  mode: ts.enums.CHART_MODE_ENUM;
  params: Params;
  setParams: (_p: Params) => void;
  context: ts.types.signal.SignalAnalysisContext;
};

const CumulativeReturns: React.FC<CumulativeReturnsProps> = ({ mode, params, setParams, context }) => {
  return <>{mode == 'edit' ? <Form params={params} setParams={setParams} context={context} /> : <>Render</>}</>;
};

export default CumulativeReturns;
