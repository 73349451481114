import { React } from '_core';

import AiAssistant from './ai-assistant';
import { AIAssistantContextProvider, ProviderProps } from './ai-assistant-context';

const AIAssistantIndex = (props: ProviderProps) => {
  return (
    <AIAssistantContextProvider {...props}>
      <AiAssistant />
    </AIAssistantContextProvider>
  );
};

export default AIAssistantIndex;
