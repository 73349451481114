import { ts } from '_core';


export default [
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.BASKET_COMPARE_PERCENT_CONTRIBUTION_TO_RISK,
    title: 'Percent Contribution To Risk',
    helpLabel: 'mcnb94r6',
    form: false,
    defaultParams: {
      format_for_ui: true,
    },
    neededFiles: ['backtest_factors_contribution_to_total_risk'],
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.BASKET_PORTFOLIO_RISK],
  },
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.BACKTEST_COMPARE_PERCENT_CONTRIBUTION_TO_RISK,
    title: 'Percent Contribution To Risk',
    helpLabel: '833er7ns',
    form: false,
    defaultParams: {
      format_for_ui: true,
    },
    neededFiles: ['backtest_factors_contribution_to_total_risk'],
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.BACKTEST_PORTFOLIO_RISK],
  },
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.PORTFOLIO_PERCENT_CONTRIBUTION_TO_RISK,
    title: 'Percent Contribution To Risk',
    helpLabel: 'hd6lvvhc',
    form: false,
    defaultParams: {
      format_for_ui: true,
    },
    neededFiles: ['portfolio_factors_contribution_to_total_risk'],
    tags: [
      ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.PORTFOLIO_RISK,
      ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.PORTFOLIO_STRAT_RISK,
    ],
  },
] satisfies ts.types.analysis.WidgetConfig[];
