import React from 'react';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import ErrorLog from './error-log';
import ReportErrorDialog from './report-error-dialog';
import config from '../../../config.local';
import { mui, useSelector } from '../../libs';
import { BaseStore } from '../../types';

const ReportError = ({
  errorMessage,
  errorLog,
  relative = true,
}: {
  errorMessage: string;
  errorLog: string;
  relative: boolean;
}) => {
  const [showFeedbackDialog, setShowFeedbackDialog] = React.useState(false);
  const [showErrorDialog, setShowErrorDialog] = React.useState(false);

  const theme = mui.styles.useTheme() as mui.core.Theme;
  const currentUser = useSelector((state: BaseStore) => state.auth.currentUser);

  const isProd = config.environment == 'production';

  return (
    <mui.core.Card
      sx={{
        m: 1,
        width: 'calc(100% - 8px)',
        height: 'calc(100% - 8px)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <mui.core.Box
        sx={{
          padding: 4,
          color: (theme.palette as any).error.contrastText,
          borderRadius: theme.shape.borderRadius,
          textAlign: 'center',
          maxWidth: '600px',
          width: '100%',
        }}
      >
        <ErrorOutlineIcon sx={{ fontSize: '2rem', marginBottom: 2, color: (theme.palette as any).danger.main }} />
        <mui.core.Typography color={(theme.palette as any).text.primary} variant="h6" mb={1}>
          Oops! Something went wrong.
        </mui.core.Typography>
        <mui.core.Typography color="textSecondary" sx={{ marginBottom: 3 }}>
          If this is the first time you saw this error, please report it.
        </mui.core.Typography>

        <mui.core.Box display="flex" justifyContent="center" gap={3}>
          {!relative && (
            <mui.core.Button
              onClick={() => {
                window.location.assign('/');
              }}
            >
              Go Home
            </mui.core.Button>
          )}

          {currentUser && (
            <mui.core.Button color="primary" onClick={() => setShowFeedbackDialog(true)}>
              Report
            </mui.core.Button>
          )}

          {!isProd && (
            <mui.core.Button color="primary" onClick={() => setShowErrorDialog(true)}>
              View Error
            </mui.core.Button>
          )}
        </mui.core.Box>
      </mui.core.Box>

      {currentUser && (
        <ReportErrorDialog
          isOpen={showFeedbackDialog}
          handleClose={() => setShowFeedbackDialog(false)}
          error={errorMessage}
          errorLog={errorLog}
          currentUser={currentUser}
        />
      )}

      <ErrorLog
        isOpen={showErrorDialog}
        handleClose={() => setShowErrorDialog(false)}
        errorMessage={errorMessage}
        errorLog={errorLog}
      />
    </mui.core.Card>
  );
};

export default ReportError;
