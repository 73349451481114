/* eslint-disable no-unused-vars */
import React from 'react';

import SortBy, { ItemsType } from './sort-by';
import { useEffectWithoutFirst } from '../../hooks';
import { dnd, mui } from '../../libs';
import { LARGE_DRAG_DROP_HEIGHT } from '../../theme/layout-size';
import UiButton from '../button';
import DialogTitle from '../dialog-title';
import DragItem from '../drag-item';

type ItemsSortModalProps = {
  open: boolean;
  setOpen: (_o: boolean) => void;
  title: string;
  items: ItemsType[];
  saveItemsOrder: (_items: ItemsType[]) => void;

  actionHeader?: React.ReactElement;
  onMove?: () => void;
  disableRemove?: boolean;
  toggleSortBy?: boolean;
  reset?: ItemsType[];
};

/**
 * Component that allows users to sort and reorder items in a modal dialog
 *
 * @param props - The component props
 * @param props.open - Whether the modal is open
 * @param props.setOpen - Function to set modal open state
 * @param props.title - Title text for the modal
 * @param props.items - Array of items to sort
 * @param props.saveItemsOrder - Callback function to save the sorted items
 * @param props.actionHeader - Optional header content above the sort UI
 * @param props.onMove - Optional callback when items are moved
 * @param props.disableRemove - Whether to disable item removal
 * @param props.toggleSortBy - Whether to show sort options
 * @param props.reset - The items to reset to
 *
 * @returns Rendered modal component for sorting items
 */
const ItemsSortModal = ({
  open,
  setOpen,
  title,
  items,
  saveItemsOrder,
  actionHeader,
  onMove,
  disableRemove,
  toggleSortBy,
  reset,
}: ItemsSortModalProps): React.ReactElement => {
  const [sortedItems, setSortedItems] = React.useState(items);
  const [sortBy, setSortBy] = React.useState<'name' | 'creation' | 'custom'>('name');

  const dragGridHeight = (sortedItems.length + 1) * 32 + 15;

  useEffectWithoutFirst(() => setSortedItems(items), [items]);

  const handleSubmit = (event: React.UIEvent<HTMLElement>) => {
    event.stopPropagation();

    saveItemsOrder(sortedItems);
    setOpen(false);
  };

  const handleClose = (event: React.UIEvent<HTMLElement>) => {
    event.stopPropagation();
    setSortedItems(items);
    setOpen(false);
  };

  const moveItem = (sourceId: string, sourceIndex: number, targetIndex: number) => {
    const nextState = dnd.swap(sortedItems, sourceIndex, targetIndex);
    if (onMove) onMove();
    setSortBy('custom');
    setSortedItems(nextState);
  };

  const removeItem = (index: number) => {
    const newItems = [...sortedItems];
    newItems.splice(index, 1);
    setSortedItems(newItems);
  };

  return (
    <mui.core.Dialog
      open={open}
      onClose={handleClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: { minHeight: '60vh' },
      }}
    >
      <DialogTitle closeAction={(e) => handleClose(e)}>Edit {title} Order</DialogTitle>
      <mui.core.DialogContent>
        <mui.core.Box mt={4}>
          {actionHeader && actionHeader}

          {toggleSortBy && (
            <SortBy
              items={items}
              sortedItems={sortedItems}
              setSortedItems={setSortedItems}
              sortBy={sortBy}
              setSortBy={setSortBy}
            />
          )}

          <dnd.GridContextProvider onChange={moveItem}>
            <dnd.GridDropZone
              id="items"
              boxesPerRow={1}
              rowHeight={LARGE_DRAG_DROP_HEIGHT}
              style={{ height: dragGridHeight }}
            >
              {sortedItems.map((item, index) => (
                <div key={item.key}>
                  <DragItem
                    title={item.name}
                    index={index}
                    removeColumn={() => removeItem(index)}
                    disableRemove={disableRemove}
                    size="large"
                  />
                </div>
              ))}
            </dnd.GridDropZone>
          </dnd.GridContextProvider>
        </mui.core.Box>
      </mui.core.DialogContent>

      <mui.core.DialogActions sx={{ p: 2, display: 'flex', gap: 2 }}>
        {reset && (
          <UiButton
            color="primary"
            onClick={() => setSortedItems(reset)}
            // Adding this to fix the variant error
            // which is caused by the UiButton component when importing from this context
            variant={'light' as any}
          >
            Restore to default
          </UiButton>
        )}
        <UiButton
          variant="contained"
          color="primary"
          onClick={(e: React.UIEvent<HTMLElement>) => handleSubmit(e)}
          disabled={sortedItems.length === 0}
        >
          Apply Order
        </UiButton>
      </mui.core.DialogActions>
    </mui.core.Dialog>
  );
};

ItemsSortModal.defaultProps = {
  toggleSortBy: false,
};

export default ItemsSortModal;
