import { mui } from '_core';

import ComissionUnit from './formatters/comission-unit';
import Countries from './formatters/countries';
import Numeric from './formatters/numeric';

const columns: mui.dataGrid.GridColDef[] = [
  {
    field: 'country_code',
    headerName: 'Country',
    renderCell: (params) => Countries({ row: params.row }),
    width: 200,
  },
  {
    field: 'commission_value',
    headerName: 'Commission',
    renderCell: (params) => Numeric({ row: params.row, col_key: 'commission_value' }),
    width: 150,
  },
  {
    field: 'commission_unit',
    headerName: 'Commission Unit',
    renderCell: (params) => ComissionUnit({ row: params.row }),
    width: 200,
  },
  {
    field: 'buy_tax',
    headerName: 'Buy Tax (BPS)',
    renderCell: (params) => Numeric({ row: params.row, col_key: 'buy_tax' }),
    width: 100,
  },
  {
    field: 'sell_tax',
    headerName: 'Sell Tax (BPS)',
    renderCell: (params) => Numeric({ row: params.row, col_key: 'sell_tax' }),
    width: 100,
  },
];

export { columns };
