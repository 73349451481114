import React from 'react';

import { InitialSetup, UploadContextProvider as UploadContextProviderType } from './holdings-types';
import * as utils from './utils';
import { app_portfolio_multi_holdings, portfolio_multi_holdings } from '../../api/user-api';
import * as enums from '../../enums';
import { gibberishGenerator } from '../../helpers';
import { _, moment, useSelector } from '../../libs';
import * as types from '../../types';

type ProviderProps = UploadContextProviderType & {
  children: React.ReactNode;

  open: boolean;
  setOpenModal: (_v: boolean) => void;
};

type UploadContextTypes = {
  open: boolean;
  closeModal: () => void;

  step: enums.HOLDINGS_UPLOAD_STEPS_ENUM;
  setStep: (_step: enums.HOLDINGS_UPLOAD_STEPS_ENUM) => void;

  errorMessage: string;
  setErrorMessage: (_error: string) => void;

  mappedColumns: Record<string, string>;
  setMappedColumns: (_mapCol: Record<string, string>) => void;
  columnsToMap: string[];
  availableColumns: string[];

  initialSetup: InitialSetup;
  setInitialSetup: (_initialSetup: InitialSetup) => void;

  createHoldings: (_fileData: InitialSetup['filesData'][0]) => Promise<void>;
  loadHoldingData: (
    _holding: types.portfolioHoldings.PortfolioHoldings | types.appPortfolio.PortfolioHoldings
  ) => Promise<{
    holdingsData: types.rebalance.HoldingsData;
    holdingsColumns: { title: string; field: string }[];
  }>;
  submitHoldings: (
    _holding: types.portfolioHoldings.PortfolioHoldings | types.appPortfolio.PortfolioHoldings,
    _holdingsData: types.rebalance.HoldingsData,
    _holdingsColumns: { title: string; field: string }[],
    _navUpdate: { nav: number; cash: number }
  ) => Promise<void>;
  finishProcess: () => void;

  datesInUse: string[];

  holdings: types.portfolioHoldings.PortfolioHoldings[] | types.appPortfolio.PortfolioHoldings[];

  universeId?: number;
  holdingToEdit?: types.portfolioHoldings.PortfolioHoldings;
  skipReview?: boolean;
};

const EMPTY_ARRAY: any[] = [];

const UploadContext = React.createContext<UploadContextTypes>(null);

// This context provider is passed to any component requiring the context
const HoldingsFormProvider = ({
  children,
  confirmStepCallback,
  holdingToEdit,
  defaultMappedColumns,
  defaultNav,
  onModalClose,
  open,
  portfolio,
  setOpenModal,
  universeId,
  unavailableHoldingsDates = EMPTY_ARRAY,
  resourceType = 'portfolio_holdings',
  skipReview = false,
}: ProviderProps): React.ReactElement => {
  const HOLDINGS_UPLOAD_STEPS_ENUM = enums.HOLDINGS_UPLOAD_STEPS_ENUM;
  const authToken = useSelector((state: types.BaseStore) => state.auth.authToken);

  const [step, setStep] = React.useState(
    holdingToEdit ? HOLDINGS_UPLOAD_STEPS_ENUM.HOLDINGS_CONFIRM : HOLDINGS_UPLOAD_STEPS_ENUM.HOLDINGS_CREATION
  );
  const [errorMessage, setErrorMessage] = React.useState('');

  const [initialSetup, setInitialSetup] = React.useState({
    nav: defaultNav ?? {
      cashIdentifier: 'isin',
      cashValue: 'SHORTERM',
    },
    files: [] as File[],
    filesData: [] as { data: string; parsedData: Record<string, string>[]; name: string; file: File }[],
  });

  // Mapped columns are the columns that the user has mapped to the file columns
  const [mappedColumns, setMappedColumns] = React.useState<Record<string, string>>(defaultMappedColumns ?? {});

  // These are the holdings that are created (or the one that is the current one that is being edited)
  const [holdings, setHoldings] = React.useState<
    types.portfolioHoldings.PortfolioHoldings[] | types.appPortfolio.PortfolioHoldings[]
  >(holdingToEdit ? [holdingToEdit] : []);

  /**
   * Closes the modal dialog and triggers any onModalClose callback if provided
   */

  const closeModal = () => {
    if (onModalClose) onModalClose();
    setOpenModal(false);
  };

  /**
   * Determines which columns need to be mapped based on the holdings type
   * @returns Array of column names that need to be mapped
   *
   * If a holdings date is set in initial setup, filters out the date column since it won't be needed
   */
  const columnsToMap = React.useMemo(() => {
    return Object.values(enums.REBALANCE_MAP_COLS);
  }, [initialSetup]);

  /**
   * Analyzes the first uploaded file to extract column names and attempts to automatically map them
   * to required columns based on name matching. Preserves any existing column mappings.
   * @returns Array of available column names from the file
   */
  const availableColumns = React.useMemo(() => {
    if (_.isEmpty(initialSetup.filesData)) return [];
    const columnNames = utils.getAvailableColumns(initialSetup.filesData[0].data);
    const filledColumns = {} as Record<string, string>;

    // Sort required columns by length descending to match longer names first
    const sortedColumnsToMap = columnsToMap.sort((a, b) => b.length - a.length);
    columnNames.forEach((col) => {
      const lowerCaseCol = _.lowerCase(col);

      let mappedCol = '';
      // Preserve any existing column mappings
      if (!_.isEmpty(mappedColumns)) {
        mappedCol = _.findKey(mappedColumns, (val) => val == col);
      }

      // Try to find a required column name within the file column name
      if (!mappedCol) {
        mappedCol = _.findKey(enums.COLUMN_ALIASES, (val) => val.some((alias) => lowerCaseCol.includes(alias)));
        if (!mappedCol) {
          mappedCol = sortedColumnsToMap.find((c) => lowerCaseCol.includes(c.replace('_', ' ')));
        }
      }

      if (mappedCol) {
        filledColumns[mappedCol] = col;
      }
    });

    // Set the initial mapped columns to help the user
    setMappedColumns(filledColumns);
    return columnNames;
  }, [initialSetup.filesData]);

  /**
   * Creates holdings for a file
   * The catch process will be handled in the creating holdings component
   * @param fileData - The file data to create holdings from
   */
  const createHoldings = async (fileData: (typeof initialSetup.filesData)[0]) => {
    const endpoint = resourceType === 'portfolio_holdings' ? portfolio_multi_holdings : app_portfolio_multi_holdings;
    const callingTime = moment().subtract(1, 'seconds').toISOString();

    const resp = await endpoint(authToken, portfolio.handle, {
      column_map: mappedColumns,
      csv: fileData.data,
      cash_identifier: mappedColumns[initialSetup.nav?.cashIdentifier] ?? initialSetup.nav?.cashIdentifier,
      cash_value: initialSetup.nav?.cashValue,
    });

    if (!skipReview) {
      setHoldings((h) => _.orderBy(_.uniqBy([...h, ...resp.data], 'id'), ['date'], ['asc']) as typeof holdings);
    } else {
      const dates = resp.dates;

      const createdHoldings = holdings.filter((h) => dates.includes(h.date));
      const nonCreatedDates = dates.filter((d) => !createdHoldings.some((h) => h.date === d));

      setHoldings((prevHoldings) => {
        const newHoldings = nonCreatedDates.map((d) => ({
          id: Math.floor(gibberishGenerator.numberGenerator(-1000, 0)),
          date: d,
          mapped_file: '',
          unmapped_file: '',
          created_at: callingTime,
          updated_at: callingTime,
        }));
        return _.orderBy(_.uniqBy([...prevHoldings, ...newHoldings], 'id'), ['date'], ['asc']) as typeof holdings;
      });
    }
  };

  const loadHoldingData = async (
    holding: types.portfolioHoldings.PortfolioHoldings | types.appPortfolio.PortfolioHoldings
  ) => {
    const { data, columns } = await utils.apiProcedures.loadData(
      {
        mapped_file: holding.mapped_file,
        unmapped_file: holding.unmapped_file,
      },
      portfolio.id,
      resourceType
    );

    const localColumns = _.uniq(['error_message', ...columns]);
    const holdingsColumns = localColumns.map((col) => ({
      title: _.startCase(col.replace('finsera', 'mapped')),
      field: col,
    }));

    const holdingsData = data as types.rebalance.HoldingsData;

    return {
      holdingsData,
      holdingsColumns,
    };
  };

  /**
   * Uploads the updated holdings data after user review
   * @param holding - The holding to update
   * @param holdingsData - The updated holdings data after user review/modifications
   * @param holdingsColumns - The columns configuration for the holdings data
   */
  const submitHoldings = async (
    holding: types.portfolioHoldings.PortfolioHoldings | types.appPortfolio.PortfolioHoldings,
    holdingsData: types.rebalance.HoldingsData,
    holdingsColumns: { title: string; field: string }[],
    navUpdate: { nav: number; cash: number }
  ) => {
    const newHolding = await utils.apiProcedures.submitHoldings(
      holding.id,
      holdingsColumns,
      holdingsData,
      navUpdate,
      authToken,
      resourceType
    );

    setHoldings(
      (h) =>
        _.orderBy(
          h.map((holding) => (holding.id === newHolding.id ? newHolding : holding)),
          ['date'],
          ['asc']
        ) as typeof holdings
    );
  };

  const datesInUse = React.useMemo(() => {
    if (initialSetup.filesData.length > 0 && mappedColumns[enums.REBALANCE_MAP_COLS.DATE]) {
      const dates = _.flatten(
        initialSetup.filesData.map((file) =>
          // Skip the first row since it's the header
          file.parsedData.map((row) => row[mappedColumns[enums.REBALANCE_MAP_COLS.DATE]])
        )
      );
      return _.sortBy(_.intersection(_.uniq(dates), unavailableHoldingsDates));
    }

    return [];
  }, [initialSetup, mappedColumns, unavailableHoldingsDates]);

  /**
   * Finishes the holdings upload process by calling the confirmation callback and closing the modal
   *
   * @remarks
   * If a confirmStepCallback was provided, it will be called with:
   * - The final mapped columns configuration
   * - The NAV settings from initial setup
   * - Array of uploaded filenames
   * - Array of created holdings IDs
   */
  const finishProcess = () => {
    if (confirmStepCallback)
      confirmStepCallback(
        mappedColumns,
        initialSetup.nav,
        initialSetup.files.map((f) => f.name),
        holdings.map((h) => h.id),
        holdings
      );
    closeModal();
  };

  return (
    <UploadContext.Provider
      value={{
        open,
        closeModal,

        step,
        setStep,

        errorMessage,
        setErrorMessage,

        availableColumns,

        columnsToMap,
        mappedColumns,
        setMappedColumns,

        initialSetup,
        setInitialSetup,

        datesInUse,

        createHoldings,
        submitHoldings,
        loadHoldingData,

        universeId,

        holdings,
        finishProcess,
        holdingToEdit,
        skipReview,
      }}
    >
      {children}
    </UploadContext.Provider>
  );
};

const UploadContextProvider = HoldingsFormProvider;

export { UploadContext };
export { UploadContextProvider };
