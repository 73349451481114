import { RESET_STORE } from '_core/actions/types';

import { combineReducers } from '@local/finsera-core';

// Reducers
import apiErrors from './api-errors';
import auth from './auth';
import jobs from './jobs';
import resources from './resources';
import ui from './ui';

const appReducer = combineReducers({
  auth,
  resources,
  ui,
  apiErrors,
  jobs,
});

type ReducerArgs = Parameters<typeof appReducer>; // [string, number]

const rootReducer = (state: ReducerArgs[0], action: ReducerArgs[1]) => {
  // This state is a special case used to reset the store
  if (action.type == (RESET_STORE as undefined)) {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

export default rootReducer;
