import { mui, React, ui } from '_core';

const ErrorComponent = () => {
  const theme = mui.styles.useTheme() as mui.core.Theme;

  return (
    <mui.core.Box height="100%" display="flex" justifyContent="center" flexDirection="column">
      <ui.EmptyMessage>
        <mui.core.Box display="flex" flexDirection="column">
          <mui.icons.ErrorOutline sx={{ color: theme.palette.danger.main, fontSize: '60px', margin: 'auto' }} />
          <mui.core.Box mt={2}>Something happened and we were unable to load the widget...</mui.core.Box>
        </mui.core.Box>
      </ui.EmptyMessage>
    </mui.core.Box>
  );
};

export default ErrorComponent;
