/* eslint-disable max-len */

import { endpoint } from './config';
import { $, moment } from '../libs';
import { ApiTypes, batch, organization } from '../types';
import { Message, Thread } from '../types/ai-assistant';
import { GeneralAnalysis, ReportDefault, ResourceAnalysis } from '../types/analysis';
import { Portfolio as AppPortfolio } from '../types/app-portfolio';
import { PortfolioHoldings as AppPortfolioHoldings } from '../types/app-portfolio';
import { AuditLog } from '../types/audit-logs';
import { Backtest } from '../types/backtest';
import { BacktestSet } from '../types/backtest-set';
import { Basket } from '../types/basket';
import { Submission } from '../types/batch';
import { ResourceDraft } from '../types/common';
import { Country } from '../types/country';
import { Currency } from '../types/currency';
import { DataSource } from '../types/data-source';
import { FixedCost } from '../types/fixed-costs';
import { HelpItem } from '../types/help-item';
import { Job } from '../types/job';
import { Label } from '../types/label';
import { Log as PerformanceLog } from '../types/performance-logs';
import { Pipeline } from '../types/pipeline';
import { Portfolio } from '../types/portfolio';
import { PortfolioHoldings } from '../types/portfolio-holdings';
import { Rebalance } from '../types/rebalance';
import { RiskModel } from '../types/risk-model';
import { Signal } from '../types/signal';
import { Dataset } from '../types/signal';
import { ExplorationPreviewRecord } from '../types/signal';
import { SignalSet } from '../types/signal-set';
import { Strategy } from '../types/strategy';
import { TimeSeries } from '../types/time-series';
import { Universe } from '../types/universe';
import { User } from '../types/user';
import { UserPreference } from '../types/user-preference';
import { Workspace } from '../types/workspace';

function buildApis<T extends ResourceDraft>(resource: string): ApiTypes<T> {
  return {
    get: (id, query = {}) =>
      $.ajax({
        method: 'GET',
        url: `${endpoint}${resource}/${id}`,
        data: { ...(query ?? {}), allow_revoked: true },
        contentType: 'application/json',
      }),
    update: (id, data, setDefUpdatedAt = false) =>
      $.ajax({
        method: 'PUT',
        url: `${endpoint}${resource}/${id}`,
        json: setDefUpdatedAt ? { ...data, definition_updated_at: `${moment().valueOf()}` } : { ...data },
      } as JQueryAjaxSettings),
    create: (data) =>
      $.ajax({
        method: 'POST',
        url: endpoint + resource,
        json: data,
      } as JQueryAjaxSettings),
    remove: (id) =>
      $.ajax({
        method: 'DELETE',
        url: `${endpoint + resource}/${id}`,
        contentType: 'application/json',
      } as JQueryAjaxSettings),
    find: (query) =>
      $.ajax({
        method: 'GET',
        url: `${endpoint + resource}`,
        data: { ...(query ?? {}), allow_revoked: true },
        contentType: 'application/json',
      } as JQueryAjaxSettings),
    search: (data) =>
      $.ajax({
        method: 'POST',
        url: `${endpoint + resource}/search`,
        json: { ...(data ?? {}), allow_revoked: true },
      } as JQueryAjaxSettings),
    listAdd: (id: number, column: string, values: any) =>
      $.ajax({
        method: 'POST',
        url: `${endpoint + resource}/${id}/${column}`,
        json: { values },
      } as JQueryAjaxSettings),
    listRemove: (id: number, column: string, values: any) =>
      $.ajax({
        method: 'DELETE',
        url: `${endpoint + resource}/${id}/${column}`,
        json: { values },
      } as JQueryAjaxSettings),
    increment: (id: number, incrementRecord: Record<string, any>) =>
      $.ajax({
        method: 'PUT',
        url: `${endpoint + resource}/increment/${id}`,
        json: { update: {}, increment: incrementRecord },
      } as JQueryAjaxSettings),
  };
}

const apis = {
  audit_logs: buildApis<AuditLog>('audit_logs'),
  countries: buildApis<Country>('countries'),
  currencies: buildApis<Currency>('currencies'),
  exchanges: buildApis<Record<string, any>>('exchanges'),

  assistant_messages: buildApis<Message>('assistant_messages'),
  assistant_threads: buildApis<Thread>('assistant_threads'),
  backtest_sets: buildApis<BacktestSet>('backtest_sets'),
  backtests: buildApis<Backtest>('backtests'),
  baskets: buildApis<Basket>('baskets'),
  data_sources: buildApis<DataSource>('data_sources'),
  fixed_costs: buildApis<FixedCost>('fixed_costs'),
  performance_logs: buildApis<PerformanceLog>('ui_performance_logs'),
  pipelines: buildApis<Pipeline>('pipelines'),
  portfolio_holdings: buildApis<PortfolioHoldings>('portfolio_holdings'),
  portfolios: buildApis<Portfolio>('portfolios'),
  rebalances: buildApis<Rebalance>('rebalances'),
  risk_models: buildApis<RiskModel>('risk_models'),
  signal_datasets: buildApis<Dataset>('signal_datasets'),
  signal_exploration_previews: buildApis<ExplorationPreviewRecord>('signal_exploration_previews'),
  signal_sets: buildApis<SignalSet>('signal_sets'),
  signals: buildApis<Signal>('signals'),
  strategies: buildApis<Strategy>('strategies'),
  time_series: buildApis<TimeSeries>('time_series'),
  universes: buildApis<Universe>('universes'),
  user_preferences: buildApis<UserPreference>('user_preferences'),
  app_portfolios: buildApis<AppPortfolio>('app_portfolios'),
  app_portfolio_holdings: buildApis<AppPortfolioHoldings>('app_portfolio_holdings'),

  backtest_analyses: buildApis<ResourceAnalysis>('backtest_analyses'),
  backtest_set_analyses: buildApis<ResourceAnalysis>('backtest_set_analyses'),
  basket_analyses: buildApis<ResourceAnalysis>('basket_analyses'),
  data_source_analyses: buildApis<ResourceAnalysis>('data_source_analyses'),
  general_analyses: buildApis<GeneralAnalysis>('general_analyses'),
  industry_analysis: buildApis<ResourceAnalysis>('industry_analysis'),
  risk_model_analyses: buildApis<ResourceAnalysis>('risk_model_analyses'),
  signal_analyses: buildApis<ResourceAnalysis>('signal_analyses'),
  signal_set_analyses: buildApis<ResourceAnalysis>('signal_set_analyses'),
  strategy_analyses: buildApis<ResourceAnalysis>('strategy_analyses'),
  time_series_analyses: buildApis<ResourceAnalysis>('time_series_analyses'),
  universe_analyses: buildApis<ResourceAnalysis>('universe_analyses'),
  portfolio_analyses: buildApis<ResourceAnalysis>('portfolio_analyses'),
  rebalance_analyses: buildApis<ResourceAnalysis>('rebalance_analyses'),
  portfolio_holdings_analyses: buildApis<ResourceAnalysis>('portfolio_holdings_analyses'),

  data_source_column_metadatas: buildApis<Record<string, any>>('data_source_column_metadatas'),

  jobs: buildApis<Job>('jobs'),
  reports: buildApis<GeneralAnalysis>('reports'),
  report_defaults: buildApis<ReportDefault>('report_defaults'),
  submission_batches: buildApis<batch.Batch>('submission_batches'),
  submissions: buildApis<Submission | batch.Submission>('submissions'),
  data_loader_logs: buildApis<Submission>('data_loader_logs'),

  workspaces: buildApis<Workspace>('workspaces'),
  organizations: buildApis<organization.Organization>('organizations'),
  organization_features: buildApis<organization.OrganizationFeatures>('organization_features'),
  users: buildApis<User>('users'),
  labels: buildApis<Label>('labels'),
  help_items: buildApis<HelpItem>('help_items'),
};

export default apis;
