/* eslint-disable no-unused-vars */
import { ts } from '_core';

export enum SHEETS_ENUM {
  CUM_RET = 'CUMULATIVE_RETURNS',
  RET = 'RETURN_TYPE',
  RISK = 'RISK_TYPE',
  ADJ = 'ADJUSTED_TYPE',
}

export const sheetTitles = {
  [SHEETS_ENUM.CUM_RET]: 'Cumulative Returns',
  [SHEETS_ENUM.RET]: 'Annualized Returns',
  [SHEETS_ENUM.RISK]: 'Annualized Risk',
  [SHEETS_ENUM.ADJ]: 'Annualized Risk Adjusted Returns',
};

export type SHEETS = 'CUMULATIVE_RETURNS' | 'RISK_TYPE' | 'ADJUSTED_TYPE' | 'RETURN_TYPE';

export type JobParams = {
  basket_ids: number[];
  tabular_view: boolean;
  histories: ts.enums.HISTORY_ENUM_EXTENDED[];
  daily_reporting: boolean;
};

export type statType = 'risk' | 'adjusted' | 'cumulative_returns' | 'return';

export const optionLabels = {
  risk: sheetTitles['RISK_TYPE'],
  return: sheetTitles['RETURN_TYPE'],
  adjusted: sheetTitles['ADJUSTED_TYPE'],
  cumulative_returns: sheetTitles['CUMULATIVE_RETURNS'],
} as Record<statType, string>;

export enum INTERACTION_PARAMS {
  TABLE_PARAMS = 'table_params',
  STATISTICS_TYPES = 'statistics_types',
}

export type InteractionParams = {
  [INTERACTION_PARAMS.STATISTICS_TYPES]: statType[];
  [INTERACTION_PARAMS.TABLE_PARAMS]: ts.types.components.dataGrid.TableParamsSheet;
};

export type Params = JobParams & InteractionParams & { fullWidth: boolean };
export type DraftParams = Partial<Params>;
