import { _, ts } from '_core';

import { DraftParams, Params } from './types';

export const validate = (params: DraftParams) => {
  if (_.isNil(params.time_series_id)) return ['Time Series is a required parameter.'];
  if (_.isNil(params.lower_bound) && _.isNil(params.upper_bound))
    return ['Lower Bound or Upper Bound is a required field.'];
  if (!_.isNil(params.lower_bound) && !_.isNil(params.upper_bound))
    if (params.lower_bound > params.upper_bound) return ['Lower Bound must be less than Upper Bound.'];
  if (params.days_before_event < 1 || params.days_before_event > 21)
    return ['Days Before Event must be between 1 and 21.'];
  if (params.days_after_event < 0 || params.days_after_event > 63)
    return ['Days After Event must be between 0 and 63.'];
  return [];
};

export const getName = (
  params: Params,
  _context: ts.types.basket.BasketAnalysisContext,
  resources: ts.StoreState['resources']
) => {
  const ts = resources.time_series.find((lt) => lt.id == params.time_series_id);
  return `Time Series Event Study for ${ts.name}`;
};
