import { ts } from '_core';

import { getName, validate } from './helpers';
import { DraftParams, INTERACTION_PARAMS } from './types';

export default [
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.BASKET_CUMULATIVE_RETURNS,
    title: 'Cumulative Returns',
    form: true,
    helpLabel: '293g4dav',
    defaultParams: {
      isBasket: true,
      type: 'cumulative',
      add_zero: true,
    } as DraftParams,
    interactionParams: Object.values(INTERACTION_PARAMS),
    neededFiles: ['backtest_returns'],
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.BASKET],
    validate,
    getName,
  },
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.BACKTEST_CUMULATIVE_RETURNS,
    title: 'Cumulative Returns',
    form: false,
    defaultParams: {
      isBasket: false,
      type: 'cumulative',
      add_zero: true,
    } as DraftParams,
    interactionParams: Object.values(INTERACTION_PARAMS),
    neededFiles: ['backtest_returns'],
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.BACKTEST],

    getName,
    helpLabel: 'v8lf40nd',
  },
] satisfies ts.types.analysis.WidgetConfig[];
