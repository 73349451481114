import { _, api, helpers, ts } from '_core';

type FixedCostsApiResponse = {
  data: ts.types.fixedCosts.FixedCost;
};

export const createFixedCostRow = async (
  preload: () => void,
  callback: (_data: ts.types.fixedCosts.FixedCost) => void,
  errorCallback: (_error: string) => void
) => {
  try {
    preload();

    const newRow = {
      country_code: null as string,
      comission_value: 0.0,
      comission_unit: 'bps',
      buy_tax: 0.0,
      sell_tax: 0.0,
    };

    const resp: FixedCostsApiResponse = await api.fixed_costs.create(newRow);
    callback(resp.data);
  } catch (error) {
    errorCallback(`Unable to add new row - ${helpers.parseApiError(error as ts.types.common.ApiError)}`);
  }
};

export const saveChangedRows = async (
  id: number,
  row: ts.types.fixedCosts.FixedCostDraft,
  callback: (_data: ts.types.fixedCosts.FixedCost) => void,
  errorCallback: (_error: string) => void
) => {
  try {
    const resp = await api.fixed_costs.update(
      id,
      _.pick(row, ['country_code', 'commission_unit', 'commission_value', 'buy_tax', 'sell_tax'])
    );
    callback(resp.data);
  } catch (error) {
    errorCallback(`Unable to save row - ${helpers.parseApiError(error as ts.types.common.ApiError)}`);
  }
};

export const deleteFixedCosts = async (id: number, callback: () => void, errorCallback: (_error: string) => void) => {
  try {
    await api.fixed_costs.remove(id);
    callback();
  } catch (error) {
    errorCallback(`Unable to delete row - ${helpers.parseApiError(error as ts.types.common.ApiError)}`);
  }
};
