import { _, React, ts } from '_core';

import Chart from './chart';
import Form from './form';
import { DraftParams, Params } from './types';
import ContextForm from '../../common/forms/signal-set-context-form';

/*
    This widget uses jobpaths instead of widget paths due to different
    response payload
*/

type CumulativeReturnsProps = {
  id: string;
  readOnly: boolean;
  mode: ts.enums.CHART_MODE_ENUM;
  params: Params | DraftParams;
  setParams: (_p: DraftParams) => void;
  title: string;
  setExternalEmbed: (_json: ts.types.analysis.EmbedChartData) => void;

  subtitle?: string;
  fullScreen?: boolean;
  context: ts.types.signalSet.SignalSetAnalysisContext | ts.types.analysis.GeneralAnalysisContext;
  loadWidgetData?: (_payload: ts.types.widgets.WidgetGetDataParams) => Promise<ts.types.widgets.WidgetGetDataResponse>;
  getSignature?: (_v: string) => string;
  jobPaths?: Record<string, string>;

  isGeneralAnalysis?: boolean;
};

const CumulativeReturns: React.FC<CumulativeReturnsProps> = ({
  id,
  readOnly,
  loadWidgetData,
  jobPaths,
  mode,
  getSignature,
  params,
  setParams,
  fullScreen,
  context,
  title,
  subtitle,
  isGeneralAnalysis,
  setExternalEmbed,
}) => {
  const [localContext, setLocalContext] = React.useState(context as ts.types.signalSet.SignalSetAnalysisContext);

  // Let's select the file that has the report data
  const file = React.useMemo(() => {
    if (!_.isEmpty(jobPaths)) {
      // Fixed key report (check form to see how we send report as id)
      return jobPaths[`signal_set_cumulative_returns_${getSignature('signal_set_cumulative_returns')}_report`];
    }
    return null;
  }, [jobPaths]);

  const renderJobComponent = () => {
    if (!localContext?.signal_set_id) return <></>;
    return (
      <>
        <Chart
          id={id}
          readOnly={readOnly}
          loadWidgetData={loadWidgetData}
          file={file}
          fullScreen={fullScreen}
          context={localContext as ts.types.signalSet.SignalSetAnalysisContext}
          params={params as Params}
          title={title}
          subtitle={subtitle}
          setExternalEmbed={setExternalEmbed}
        />
      </>
    );
  };

  return (
    <>
      <ContextForm
        params={params}
        setParams={setParams}
        setExternalContext={setLocalContext}
        originalContext={context}
        mode={mode}
        isGeneralAnalysis={isGeneralAnalysis}
      />
      {mode == 'edit' ? (
        <Form
          params={params}
          setParams={setParams}
          context={localContext as ts.types.signalSet.SignalSetAnalysisContext}
          isGeneralAnalysis={isGeneralAnalysis}
        />
      ) : (
        renderJobComponent()
      )}
    </>
  );
};

export default CumulativeReturns;
