import { mui, React, ts } from '_core';

import Form from './form';
import { DraftReportSectionParams, ReportSectionParams } from './types';

type ReportSectionProps = {
  id: string;
  mode: ts.enums.CHART_MODE_ENUM;
  params: ReportSectionParams | DraftReportSectionParams;
  setParams: (_p: DraftReportSectionParams) => void;
};

const ReportSection: React.FC<ReportSectionProps> = ({ mode, params, setParams }) => {
  const theme = mui.styles.useTheme();

  const chart = () => (
    <mui.core.Box
      py={2}
      px={3}
      width="100%"
      sx={{
        background: '#fff',
        borderBottom: `1px solid ${theme.palette.gray.main}`,
        zIndex: 1,
        borderLeft: `5px solid ${theme.palette.primary.main}`,
      }}
    >
      <mui.core.Typography variant="h6" sx={{ marginBottom: '10px' }}>
        {params.title}
      </mui.core.Typography>
      <mui.core.Typography variant="body2" color="textSecondary">
        {params.description}
      </mui.core.Typography>
    </mui.core.Box>
  );

  return <>{mode == 'edit' ? <Form params={params} setParams={setParams} /> : <>{chart()}</>}</>;
};

export default ReportSection;
