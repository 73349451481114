import { config, hooks, mui, React, ts, ui, useSelector } from '_core';

import { PreferencesContext } from 'views/preferences/preferences-context';

import DefaultsTable from './defaults-table';
import getOptions from './template-options';

const ReportTemplates = () => {
  const uiStyles = hooks.useUiStyles();

  const preferencesContext = React.useContext(PreferencesContext);
  const { closePreferences, preferencesSelectedInnerTab, setAlertError } = preferencesContext;

  const storeDefaults = useSelector((state) => state.resources.report_defaults);

  const [layoutType, setLayoutType] = React.useState(preferencesSelectedInnerTab as any);
  const [defaults, setDefaults] = React.useState([]);

  React.useEffect(() => {
    if (layoutType) setDefaults(storeDefaults.filter((x) => x.type == layoutType));
  }, [layoutType, storeDefaults]);

  const [openEditModal, setOpenEditModal] = React.useState(false);

  const menuItem = (name: string, type: ts.enums.UI_RESOURCE_TYPE_ENUM, value: ts.enums.LAYOUT_TYPE) => (
    <mui.core.MenuItem value={value} key={name}>
      <mui.core.Box display="flex" alignItems="center">
        <mui.core.Box display="flex" style={{ marginRight: 4 }}>
          <ui.ResourceIcon resource={type} />
        </mui.core.Box>
        {name}
      </mui.core.Box>
    </mui.core.MenuItem>
  );

  const templateOptions = React.useMemo(() => getOptions(config.features), []);
  return (
    <mui.core.Box display="flex" flexDirection="column" height="100%">
      <mui.core.Box sx={uiStyles.settingsContainer}>
        <mui.core.Box display="flex">
          <mui.core.TextField
            size="small"
            variant="outlined"
            select
            label="Select report type"
            value={layoutType}
            onChange={(e) => setLayoutType(e.target.value)}
            style={{ minWidth: 300 }}
          >
            {templateOptions
              .filter((opt) => opt.enabled)
              .map((opt) => menuItem(opt.name, opt.resourceType, opt.layout))}
          </mui.core.TextField>

          {layoutType && (
            <mui.core.Box ml={3}>
              <ui.Button color="primary" variant="outlined" onClick={() => setOpenEditModal(!openEditModal)}>
                Create Layout
              </ui.Button>
            </mui.core.Box>
          )}
        </mui.core.Box>

        <mui.core.Box mt={2}>
          {layoutType ? (
            <DefaultsTable
              defaults={defaults}
              layoutType={layoutType}
              openEditModal={openEditModal}
              setOpenEditModal={setOpenEditModal}
              setAlert={setAlertError}
            />
          ) : (
            <mui.core.Grid container sx={{ justifyContent: 'center' }}>
              <mui.core.Grid item xs={12} sm={6} lg={5}>
                <ui.EmptyMessage>Select a report type</ui.EmptyMessage>
              </mui.core.Grid>
            </mui.core.Grid>
          )}
        </mui.core.Box>
      </mui.core.Box>

      <mui.core.Box display="flex" sx={{ ...uiStyles.settingsFooter, marginBottom: '-0.9rem' }}>
        <ui.Button variant="outlined" color="primary" onClick={closePreferences}>
          Close
        </ui.Button>
      </mui.core.Box>
    </mui.core.Box>
  );
};

export default ReportTemplates;
