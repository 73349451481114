import React from 'react';

import { mui } from '../libs';

type SuccessMessageProps = {
  closeAction?: () => void;
  children: React.ReactNode;
};

/**
 * Component that displays a success message with an optional close button
 * 
 * @param closeAction Optional callback function when close button is clicked
 * @param children Content to display in the success message
 * @returns Rendered success message component
 */
const SuccessMessage: React.FC<SuccessMessageProps> = ({ closeAction, children }): React.ReactElement => {
  const theme = mui.styles.useTheme() as mui.core.Theme;

  return (
    <mui.core.Box
      p={6}
      textAlign="center"
      display="flex"
      flexDirection="column"
      alignItems="center"
      mt={2}
      sx={{
        background: (theme.palette as any).primaryLight.light,
        borderRadius: theme.spacing(1),
      }}
    >
      <mui.core.Box
        mb={5}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '2.5rem',
          height: '2.5rem',
          borderRadius: '2.5rem',
          background: (theme.palette as any).primaryLight.main,
        }}
      >
        <mui.icons.Check color="primary" />
      </mui.core.Box>
      {children}
      {closeAction && (
        <mui.core.Box mt={4}>
          <mui.core.Button variant="contained" color="primary" onClick={closeAction}>
            Close
          </mui.core.Button>
        </mui.core.Box>
      )}
    </mui.core.Box>
  );
};

export default SuccessMessage;
