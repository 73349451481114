import { helpers, mui, React, ts, useSelector } from '_core';

import AddIndustryFactor from './add-industry-factor';
import IndustryFactorItem from './industry-factor-item';
import { renameIndustryFactors } from '../../definition-utils';

type DefinitionIndustryCategoryFactorsProps = {
  definition: ts.types.riskModel.DefinitionCategoryDraft[0];
  setDefinition: (_d: ts.types.riskModel.DefinitionCategoryDraft[0]) => void;
  sectionsMetadata: ts.types.riskModel.UiMetaData['sections'];
  setSectionsMetadata: (_u: ts.types.riskModel.UiMetaData['sections']) => void;
  readOnly: boolean;
  validationDef: ts.types.common.ValidationErrors;
};

const DefinitionIndustryCategoryFactors: React.FC<DefinitionIndustryCategoryFactorsProps> = ({
  definition,
  setDefinition,
  sectionsMetadata,
  setSectionsMetadata,
  readOnly,
  validationDef,
}): React.ReactElement => {
  const rbicsData = useSelector((state) => state.resources.rbics_data);
  const theme = mui.styles.useTheme() as mui.core.Theme;

  const extendedFactors = React.useMemo(() => {
    return renameIndustryFactors(
      (definition?.factors as ts.types.riskModel.CategoryFactor[]) || ([] as ts.types.riskModel.CategoryFactor[]),
      rbicsData
    );
  }, [definition?.factors]);

  const editFactor = async (factorHandle: string, newData: Partial<ts.types.riskModel.ExtendedCategoryFactor>) => {
    const idx = extendedFactors.findIndex((r) => r.handle == factorHandle);
    const localFactors = extendedFactors;

    localFactors[idx] = {
      ...localFactors[idx],
      ...newData,
    };

    setDefinition({ ...definition, factors: localFactors });
  };

  const addFactor = (name: string) => {
    const localFactors = [
      {
        inclusions: [],
        name,
        // Handle will be replaced on save
        // to be derived from the name
        handle: helpers.gibberishGenerator.stringGenerator(10),
      },
      ...extendedFactors,
    ];
    setDefinition({ ...definition, factors: localFactors });
  };

  const removeFactor = (factorHandle: string) => {
    const localFactors = extendedFactors.filter((f) => f.handle !== factorHandle);
    setDefinition({ ...definition, factors: localFactors });
  };

  return (
    <mui.core.Box sx={{ position: 'relative', pt: 3 }}>
      {!readOnly && <AddIndustryFactor addFactor={addFactor} />}

      <mui.core.TableContainer sx={{ overflow: 'hidden' }}>
        <mui.core.Table
          size="small"
          aria-label="simple table"
          sx={{
            background: theme.palette.grayLight.light,
            marginLeft: '23vw',
            width: 'calc(100% - 23vw)',
            '& .MuiTableCell-root': {
              width: '25%',
              fontSize: '12px',
              padding: '2px 8px!important',
            },
          }}
        >
          <mui.core.TableHead>
            <mui.core.TableRow>
              <mui.core.TableCell
                sx={{
                  minWidth: '48px!important',
                  width: 'auto!important',
                }}
              ></mui.core.TableCell>
              <mui.core.TableCell>Sector</mui.core.TableCell>
              <mui.core.TableCell>Sub Sector</mui.core.TableCell>
              <mui.core.TableCell>Industry</mui.core.TableCell>
              <mui.core.TableCell>Sub Industry</mui.core.TableCell>
            </mui.core.TableRow>
          </mui.core.TableHead>
        </mui.core.Table>
      </mui.core.TableContainer>
      <mui.core.Box sx={{ height: 500, overflow: 'auto' }}>
        {extendedFactors.map((i) => {
          return (
            <IndustryFactorItem
              key={i.handle}
              factor={i}
              editFactor={(h, d) => editFactor(h, d)}
              removeFactor={(handle) => removeFactor(handle)}
              readOnly={readOnly}
              isOpen={!sectionsMetadata[`${i.handle}_collapsed`]}
              validationDef={validationDef}
              setIsOpen={(v: boolean) =>
                setSectionsMetadata({
                  ...sectionsMetadata,
                  [`${i.handle}_collapsed`]: !v,
                })
              }
              extendedFactors={extendedFactors}
            />
          );
        })}
      </mui.core.Box>
    </mui.core.Box>
  );
};

export default DefinitionIndustryCategoryFactors;
