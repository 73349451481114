import React, { useEffect, useState } from 'react';

import { mui } from '../libs';

type FullPageLoaderProps = {
  label?: string;
};

/**
 * FullLoader component for displaying a centered loader
 *
 * @param label - Label text for the loader
 *
 * @returns A centered loader component
 */

const FullPageLoader: React.FC<FullPageLoaderProps> = ({ label = 'Loading experience...' }): React.ReactElement => {
  const theme = mui.styles.useTheme() as mui.core.Theme;

  // State for opacity
  const [opacity, setOpacity] = useState(0);

  // Fade-in effect
  useEffect(() => {
    const timer = setTimeout(() => {
      setOpacity(1);
    }, 0);
    return () => clearTimeout(timer);
  }, []);

  return (
    <mui.core.Box
      sx={{
        height: '100vh',
        background: theme?.palette?.secondary?.dark,
        color: '#fff',
        opacity: opacity,
        transition: 'opacity 0.5s ease-in-out',
      }}
      display="flex"
      flexDirection="column"
      gap={4}
      alignItems="center"
      justifyContent="center"
    >
      <mui.core.CircularProgress sx={{ color: '#fff' }} size={24} />
      {label}
    </mui.core.Box>
  );
};

export default FullPageLoader;
