import { config, mui, React, ts, ui } from '_core';

import RelaxOrder from './relax-order';

type SharedConstraintsProps = {
  params: ts.types.optimizer.ConstraintDraft;
  showRelaxation?: boolean;
  setParams: (_params: ts.types.optimizer.ConstraintDraft & { constraint_type?: any }) => void;
  disableAll?: boolean;
  id?: string;
  readOnly: boolean;
  validationDef: ts.types.common.ValidationErrors;
};

const SharedConstraintFields: React.FC<SharedConstraintsProps> = ({
  params,
  setParams,
  showRelaxation = true,
  disableAll,
  id,
  readOnly,
  validationDef,
}): React.ReactElement => {
  const theme = mui.styles.useTheme() as mui.core.Theme;

  return (
    <>
      <mui.core.Grid container spacing={3} style={{ marginTop: theme.spacing(1) }}>
        <mui.core.Grid item xs={12} md={6}>
          <ui.FinDatePicker
            label="Start Date"
            clearable
            fullWidth
            value={params.start}
            onChange={(date: string) => setParams({ ...params, start: date })}
            disabled={readOnly || disableAll}
            inputVariant="outlined"
            size="small"
            minDate={config.features.start_date}
            maxDate={params.end ? params.end : config.features.end_date}
            error={validationDef?.fields?.includes(`start_date_${id}`)}
          />
        </mui.core.Grid>

        <mui.core.Grid item xs={12} md={6}>
          <ui.FinDatePicker
            label="End Date"
            clearable
            fullWidth
            value={params.end}
            onChange={(date: string) => setParams({ ...params, end: date })}
            disabled={readOnly || disableAll}
            inputVariant="outlined"
            size="small"
            minDate={params.start ? params.start : config.features.start_date}
            maxDate={config.features.end_date}
            error={validationDef?.fields?.includes(`end_date_${id}`)}
          />
        </mui.core.Grid>
      </mui.core.Grid>
      {showRelaxation && (
        <mui.core.Grid container spacing={4} style={{ marginTop: '0.25rem' }}>
          <RelaxOrder params={params} setParams={setParams} disabled={readOnly || disableAll} />
        </mui.core.Grid>
      )}
    </>
  );
};

export default SharedConstraintFields;
