import { hooks, mui, React, ts } from '_core';

import Chart from './chart';
import Form from './form';
import { DraftParams, Params } from './types';

type BacktestSummaryStatisticsProps = {
  id: string;
  readOnly: boolean;
  widgetKey: string;
  mode: ts.enums.CHART_MODE_ENUM;
  params: Params;
  setParams: (_p: DraftParams) => void;
  title: string;

  fullScreen?: boolean;
  context?: ts.types.backtest.BacktestAnaylsisContext;
  loadWidgetData: (_payload: ts.types.widgets.WidgetGetDataParams) => Promise<ts.types.widgets.WidgetGetDataResponse>;
  widgetPaths?: Record<'backtest_summarized_statistics_analysis', string>;
};

const BacktestSummaryStatistics: React.FC<BacktestSummaryStatisticsProps> = ({
  id,
  readOnly,
  widgetKey,
  loadWidgetData,
  widgetPaths,
  mode,
  params,
  fullScreen,
  setParams,
  context,
  title,
}): React.ReactElement => {
  const uiStyles = hooks.useUiStyles();
  const theme = mui.styles.useTheme() as mui.core.Theme;

  const [visualization, setVisualization] = React.useState(0);

  const chart = () => {
    return (
      <Chart
        id={id}
        readOnly={readOnly}
        widgetKey={widgetKey}
        loadWidgetData={loadWidgetData}
        fullScreen={fullScreen}
        portfolios={widgetPaths['backtest_summarized_statistics_analysis']}
        params={params}
        visualization={visualization === 0 ? 'chart' : 'table'}
        title={title}
      />
    );
  };

  const renderJobComponent = () => (
    <>
      <mui.core.Box style={{ borderBottom: `1px solid ${theme.palette.gray.main}` }}>
        <mui.core.Tabs
          value={visualization}
          indicatorColor="primary"
          textColor="primary"
          onChange={(e, v) => setVisualization(v)}
          aria-label="Chart/Table Tabs"
        >
          <mui.core.Tab label="Chart" />
          <mui.core.Tab label="Table" />
        </mui.core.Tabs>
      </mui.core.Box>
      <mui.core.Box sx={uiStyles.widgetWithTabsContent}> {chart()} </mui.core.Box>
    </>
  );

  return (
    <>{mode == 'edit' ? <Form params={params} setParams={setParams} context={context} /> : renderJobComponent()}</>
  );
};

export default BacktestSummaryStatistics;
