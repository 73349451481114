import { _, api, helpers, ts } from '_core';

type BacktestSetApiResponse = {
  data: ts.types.backtestSet.BacktestSet;
};

export const createBacktestSet = async (
  newData: ts.types.backtestSet.BacktestSetDraft,
  callback: (_data: ts.types.backtestSet.BacktestSet) => void,
  errorCallback: (_error: string) => void
) => {
  try {
    const resp: BacktestSetApiResponse = await api.backtest_sets.create({
      ...newData,
      skip_signature_check: true,
    });
    callback(resp.data);
  } catch (error) {
    errorCallback(`Error creating backtest set - ${helpers.parseApiError(error as ts.types.common.ApiError)}`);
  }
};

export const updateBacktestSet = async (
  id: number,
  newData: ts.types.backtestSet.BacktestSetDraft,
  callback: (_data: ts.types.backtestSet.BacktestSet) => void,
  errorCallback: (_error: string) => void
) => {
  try {
    const resp: BacktestSetApiResponse = await api.backtest_sets.update(id, { ...newData });
    callback(resp.data);
  } catch (error) {
    errorCallback(`Error updating backtest set - ${helpers.parseApiError(error as ts.types.common.ApiError)}`);
  }
};

export const updateDefinition = async (
  backtestSet: ts.types.backtestSet.BacktestSet,
  definition: ts.types.backtestSet.DefinitionDraft,
  isValid: boolean,
  callback: (_data: ts.types.backtestSet.BacktestSet) => void,
  errorCallback: (_error: string) => void
) => {
  try {
    const resp = await api.backtest_sets.update(
      backtestSet.id,
      {
        definition,
        ui_metadata: { ...backtestSet.ui_metadata },
        is_valid: isValid,
        skip_signature_check: !isValid,
      },
      true
    );

    callback(resp.data);
  } catch (error) {
    errorCallback(`Error updating the definition - ${helpers.parseApiError(error as ts.types.common.ApiError)}`);
  }
};

export const updateUiMetadata = async (
  backtestSet: ts.types.backtestSet.BacktestSet,
  uiMetaData: ts.types.backtestSet.UiMetaData,
  callback: (_data: ts.types.backtestSet.BacktestSet) => void,
  errorCallback: (_error: string) => void
) => {
  try {
    if (backtestSet.shared) return;
    const updateData = {
      ui_metadata: uiMetaData,
      skip_signature_check: true,
    } as ts.types.backtestSet.BacktestSetDraft;
    const resp = await api.backtest_sets.update(backtestSet.id, updateData);
    callback(resp.data);
  } catch (error) {
    errorCallback(`Error updating the resource metadata - ${helpers.parseApiError(error as ts.types.common.ApiError)}`);
  }
};

const createAnalyses = async (id: number, names: ts.enums.BACKTEST_SET_ANALYSIS_TYPE_ENUM[]) => {
  const analyses = [];
  for (const name of names) {
    let analysisDefinition = { widgets: [] } as ts.types.analysis.AnalysisDefinition;

    if (name == ts.enums.BACKTEST_SET_ANALYSIS_TYPE_ENUM.SUMMARY) {
      // default portan value
      analysisDefinition = {
        mode: ts.enums.ANALYSIS_MODE_ENUM.FULLSCREEN,
        widgets: [
          {
            id: helpers.gibberishGenerator.stringGenerator(11),
            key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.BACKTEST_SET_ALL_SUMMARY,
            params: {},
          },
        ],
      };
    }

    // create analysis
    const response = await api.backtest_set_analyses.create({
      report_definition: analysisDefinition,
      backtest_set_id: id,
      name,
    });
    analyses.push(response.data.name);
  }
  return analyses;
};

// Load analyses, and if we don't have them, create them
export const loadAnalyses = async (
  backtestSet: ts.types.backtestSet.BacktestSet,
  setAnalysesNames: (_data: ts.enums.BACKTEST_SET_ANALYSIS_TYPE_ENUM[]) => void,
  errorCallback: (_error: string) => void
) => {
  const analyses = Object.values(ts.enums.BACKTEST_SET_ANALYSIS_TYPE_ENUM);
  try {
    const resp = await api.backtest_set_analyses.search({
      ignore_user_scope: backtestSet.shared,
      query: ['$and', ['backtest_set_id', '=', backtestSet.id]],
      include: 'id,name',
    });

    let localNames = (resp.data || []).map(
      (a: ts.types.analysis.ResourceAnalysis) => a.name
    ) as ts.enums.BACKTEST_SET_ANALYSIS_TYPE_ENUM[];

    const toCreate = _.without(analyses, ...localNames);
    if (!_.isEmpty(toCreate)) {
      const newNames = (await createAnalyses(backtestSet.id, toCreate)) as ts.enums.BACKTEST_SET_ANALYSIS_TYPE_ENUM[];
      localNames = localNames.concat(newNames);
    }

    setAnalysesNames(localNames);
  } catch (error) {
    errorCallback(`Error updating backtest set - ${helpers.parseApiError(error as ts.types.common.ApiError)}`);
  }
};

export const loadAnalysis = async (
  backtestSet: ts.types.backtestSet.BacktestSet,
  setAnalysis: (_data: ts.types.analysis.ResourceAnalysis) => void,
  analysisName: ts.enums.BACKTEST_SET_ANALYSIS_TYPE_ENUM,
  errorCallback: (_error: string) => void
) => {
  try {
    const resp = await api.backtest_set_analyses.search({
      query: ['$and', ['backtest_set_id', '=', backtestSet.id], ['name', '=', analysisName]],
    });
    const tempAnalysis = _.get(resp, 'data.0');
    setAnalysis(tempAnalysis);
  } catch (err) {
    errorCallback(`Unable to load the ${analysisName} analysis`);
  }
};
