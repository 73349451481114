/**
 * Creates a URL-friendly handle from a name string by converting to lowercase and replacing special characters
 * @param name - The string to convert into a handle
 * @param numberPrefix - Prefix to add if name starts with a number (default: 'prefix')
 * @returns A URL-friendly handle string with words separated by underscores
 */
const createHandle = (name: string, numberPrefix = 'prefix'): string => {
  let handle = '';

  const words = name.replace(/[^a-zA-Z0-9_]/g, ' ').split(/\s+/);
  words.forEach((word, index) => {
    handle += word.toLowerCase();
    if (index != words.length - 1) handle += '_';
  });

  if (name.match(/^\d/)) {
    return numberPrefix + '_' + handle;
  }
  return handle;
};

export default createHandle;
