import { _, actions, mui, React, ts, ui,useDispatch } from '_core';

import { ManagerContext } from './manager-context';
import * as utils from './report-manager-utils';

const ManagerSaveAsDefault = (): React.ReactElement => {
  const dispatch = useDispatch();

  const managerContext = React.useContext(ManagerContext);
  const { cards, mode, defaults, layoutType } = managerContext;

  const [title, setTitle] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState<string>();
  const [loading, setLoading] = React.useState(false);

  const [showOverrideConfirmation, setShowOverrideConfirmation] = React.useState(false);
  const [defaultToOverride, setDefaultToOverride] = React.useState<ts.types.analysis.ReportDefault>();

  const overrideDefault = (defaultToOverride: ts.types.analysis.ReportDefault) =>
    utils.apiProcedures.overrideReportDefault(
      defaultToOverride.id as number,
      { definition: { widgets: cards, mode } },
      () => setLoading(true),
      (updatedReportDefault) => {
        dispatch(actions.resources.updateReportDefault(updatedReportDefault));
        setOpen(false);
        setLoading(false);
      },
      (err) => {
        setError(err);
        setLoading(false);
      }
    );

  const createNewDefault = () =>
    utils.apiProcedures.createNewReportDefault(
      { title: title, definition: { widgets: cards, mode }, type: layoutType },
      () => setLoading(true),
      (updatedReportDefault) => {
        dispatch(actions.resources.updateReportDefault(updatedReportDefault));
        setOpen(false);
        setLoading(false);
      },
      (err) => {
        setError(err);
        setLoading(false);
      }
    );

  return (
    <>
      {/* Confirmation for override*/}
      <ui.ConfirmationDialog
        open={showOverrideConfirmation}
        title={'Are you sure?'}
        content={`You are about to override the layout ${defaultToOverride?.title}. Are you sure you want to continue?`}
        onSuccess={() => {
          overrideDefault(defaultToOverride);
          setShowOverrideConfirmation(false);
          setDefaultToOverride(null);
        }}
        onCancel={() => {
          setShowOverrideConfirmation(false);
          setDefaultToOverride(null);
        }}
        successLabel="Override"
      />
      <ui.Button variant="outlined" color="primary" onClick={() => setOpen(true)}>
        Save as layout
      </ui.Button>
      <mui.core.Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
        fullWidth
      >
        <ui.DialogTitle closeAction={() => setOpen(false)}>Save Layout</ui.DialogTitle>
        {error && (
          <mui.lab.Alert onClose={() => setError(null)} severity="error">
            {error}
          </mui.lab.Alert>
        )}
        <mui.core.DialogContent>
          {defaults.length >= 1 && (
            <mui.core.Box mb={2}>
              <mui.core.Box pt={4} mb={3}>
                <mui.core.Typography variant="h6">Override existing layout...</mui.core.Typography>
              </mui.core.Box>
              {defaults.map((d) => (
                <mui.core.Box
                  p={3}
                  key={d.id}
                  style={{ borderBottom: '1px solid #f2f4f7' }}
                  display="flex"
                  alignItems="center"
                >
                  <mui.core.Box flex="1">
                    <mui.core.Typography>{d.title}</mui.core.Typography>
                  </mui.core.Box>
                  <ui.Button
                    onClick={() => {
                      setDefaultToOverride(d);
                      setShowOverrideConfirmation(true);
                    }}
                    variant="outlined"
                    color="primary"
                    size="small"
                  >
                    Override
                  </ui.Button>
                </mui.core.Box>
              ))}
            </mui.core.Box>
          )}
          <mui.core.Box mb={3}>
            <mui.core.Box pt={4} mb={5}>
              <mui.core.Typography variant="h6">
                {defaults.length >= 1 && '... or '} Create new layout
              </mui.core.Typography>
            </mui.core.Box>
            <mui.core.Box mb={3}>
              <mui.core.TextField
                autoFocus
                id="name"
                label="Layout title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                fullWidth
                variant="outlined"
                required
                inputProps={{
                  autocomplete: 'off',
                }}
              />
            </mui.core.Box>
            <ui.Button
              onClick={createNewDefault}
              disabled={_.isEmpty(title)}
              fullWidth
              variant="contained"
              color="primary"
              loading={loading}
            >
              Create layout
            </ui.Button>
          </mui.core.Box>
        </mui.core.DialogContent>
      </mui.core.Dialog>
    </>
  );
};

export default ManagerSaveAsDefault;
