import { _, helpers, ts } from '_core';

export const _getDynamicName = (key: string) => {
  if (key.includes('basket_')) {
    key = key.replace('basket_', '').replace('=>', ' ');
    return key;
  }

  if (key.includes('handle_')) {
    key = key.replace('handle_', '').replace('=>', ' ');
    return key;
  }

  return _.startCase(key);
};

export const _getStyleName = (key: string, signals: ts.StoreState['resources']['signals']) => {
  const newKey = key.replace('_style_', '');
  const signal = signals.find((s) => s.handle == newKey);
  if (signal) return signal.name;
  return newKey;
};

export const _getCustomInputName = (key: string) => {
  if (key.includes('=>')) {
    return key;
  }

  if (key.includes('adv_traded')) {
    return 'ADV Traded %';
  }

  return _.startCase(key);
};

export default {
  [ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.MAIN]: [
    {
      key: 'period_start',
      name: 'Period Start',
      filter: 'date',
      formatter: helpers.tableFormatters.formatTo('date', { align: 'right', format: 'YYYY-MM-DD' }),
    },
    {
      key: 'period_end',
      name: 'Period End',
      filter: 'date',
      formatter: helpers.tableFormatters.formatTo('date', { align: 'right', format: 'YYYY-MM-DD' }),
    },
    {
      key: 'name',
      name: 'Name',
      formatter: helpers.tableFormatters.formatTo('string', { align: 'left' }),
      filter: 'string',
    },
    {
      key: 'asset',
      name: 'Asset',
      formatter: helpers.tableFormatters.formatTo('string', { align: 'left' }),
      filter: 'string',
    },
    {
      key: 'asset_type',
      name: 'Type',
      formatter: helpers.tableFormatters.formatTo('string', { align: 'left' }),
      filter: 'string',
    },
    {
      key: 'ticker',
      name: 'Ticker',
      formatter: helpers.tableFormatters.formatTo('string', { align: 'left' }),
      filter: 'string',
    },
    {
      key: 'rbics_l1_id',
      name: 'Sector Code',
      formatter: helpers.tableFormatters.formatTo('integer', { align: 'right' }),
      filter: 'string',
    },
    {
      key: 'rbics_l2_id',
      name: 'Sub-Sector Code',
      formatter: helpers.tableFormatters.formatTo('integer', { align: 'right' }),
      filter: 'string',
    },
    {
      key: 'rbics_l3_id',
      name: 'Industry Code',
      formatter: helpers.tableFormatters.formatTo('integer', { align: 'right' }),
      filter: 'string',
    },
    {
      key: 'rbics_l4_id',
      name: 'Sub-Industry Code',
      formatter: helpers.tableFormatters.formatTo('integer', { align: 'right' }),
      filter: 'string',
    },
    {
      key: 'rbics_l1_name',
      name: 'Sector Name',
      formatter: helpers.tableFormatters.formatTo('string', { align: 'left' }),
      filter: 'string',
    },
    {
      key: 'rbics_l2_name',
      name: 'Sub-Sector Name',
      formatter: helpers.tableFormatters.formatTo('string', { align: 'left' }),
      filter: 'string',
    },
    {
      key: 'rbics_l3_name',
      name: 'Industry Name',
      formatter: helpers.tableFormatters.formatTo('string', { align: 'left' }),
      filter: 'string',
    },
    {
      key: 'rbics_l4_name',
      name: 'Sub-Industry Name',
      formatter: helpers.tableFormatters.formatTo('string', { align: 'left' }),
      filter: 'string',
    },
    {
      key: 'cusip',
      name: 'Cusip',
      formatter: helpers.tableFormatters.formatTo('string', { align: 'left' }),
      filter: 'string',
      condition: (features: ts.types.organization.OrganizationFeatures) => !features.hide_cusip_col,
    },
    {
      key: 'isin',
      name: 'ISIN',
      formatter: helpers.tableFormatters.formatTo('string', { align: 'left' }),
      filter: 'string',
      condition: (features: ts.types.organization.OrganizationFeatures) => !features.hide_isin_col,
    },
    {
      key: 'currency',
      name: 'Currency',
      formatter: helpers.tableFormatters.formatTo('string', { align: 'center' }),
      filter: 'string',
    },
    {
      key: 'issuer_country',
      name: 'Issuer Country',
      formatter: helpers.tableFormatters.formatTo('string', { align: 'center' }),
      filter: 'string',
    },
    {
      key: 'quotation_country',
      name: 'Quotation Country',
      formatter: helpers.tableFormatters.formatTo('string', { align: 'center' }),
      filter: 'string',
    },
    {
      key: 'main_initial',
      name: 'Initial Basket',
      formatter: helpers.tableFormatters.formatTo('float', {
        align: 'right',
        roundDigits: 3,
        multiplier: 100,
        suffix: '%',
      }),
      filter: 'number',
    },
    {
      key: 'main_optimal',
      name: 'Optimal Basket',
      formatter: helpers.tableFormatters.formatTo('float', {
        align: 'right',
        roundDigits: 3,
        multiplier: 100,
        suffix: '%',
      }),
      filter: 'number',
    },
    {
      key: 'main_after_returns',
      name: 'Basket After Returns',
      formatter: helpers.tableFormatters.formatTo('float', {
        align: 'right',
        roundDigits: 3,
        multiplier: 100,
        suffix: '%',
      }),
      filter: 'number',
    },
    {
      key: 'period_returns',
      name: 'Period Returns',
      formatter: helpers.tableFormatters.formatTo('float', {
        align: 'right',
        roundDigits: 2,
        multiplier: 100,
        suffix: '%',
      }),
      filter: 'number',
    },
    {
      key: '_source_',
      name: 'Sources (For Dynamic Baskets)',
      formatter: helpers.tableFormatters.formatTo('float', { align: 'right', roundDigits: 4 }),
      members: (key) => key.startsWith('_source_'),
      nameFormatter: (key, context: ts.types.basket.BasketAnalysisContext) => {
        const id = key.replace('_source_', '');
        let name = id;
        ((context.definition as ts.types.basket.DefinitionCombined)?.sources || []).forEach((source) => {
          if (source.etf_fid == id) name = source.etf_name;
        });
        return `Source: ${name}`;
      },
      filter: 'number',
    },
    {
      key: '_condition_',
      name: 'Conditions (For Dynamic Baskets)',
      formatter: helpers.tableFormatters.formatTo('float', { align: 'right', roundDigits: 4 }),
      members: (key) => key.startsWith('_condition_'),
      cleanNameFormatter: (key) => `Condition: ${key.replace('_condition_', '')}`,
      nameFormatter: (key) => `Condition: ${key.replace('_condition_', '')}`,
      filter: 'number',
    },
    {
      key: '_input_filtered_basket',
      name: 'Filtered Basket',
      formatter: helpers.tableFormatters.formatTo('inferred'),
      filter: 'inferred',
    },
    {
      key: '_input_', // Dynamic
      name: 'Basket Inputs',
      formatter: helpers.tableFormatters.formatDynamicTo({
        _input_median_daily_volume_60: {
          colType: 'integer',
          args: {
            align: 'center',
            commasOnThousands: true,
            numericPrefix: '$',
          },
        },
        _input_market_cap: {
          colType: 'integer',
          args: {
            align: 'center',
            commasOnThousands: true,
            numericPrefix: '$',
          },
        },
        _input_market: {
          colType: 'float',
          args: { align: 'center', roundDigits: 4 },
        },
        _input_specific_risk: {
          colType: 'float',
          args: { align: 'center', roundDigits: 4 },
        },
        _input_adv_traded_pct: {
          colType: 'float',
          args: { align: 'right', roundDigits: 2, multiplier: 100, suffix: '%' },
        },
        other: {
          colType: 'inferred',
        },
      }),
      members: (key) => {
        if (key.startsWith('_input_')) {
          if (key.startsWith('_input_market')) {
            return false;
          }
          return true;
        }
        return false;
      },
      cleanNameFormatter: (key) => key.replace('_input_', ''),
      nameFormatter: (key) => _getCustomInputName(key.replace('_input_', '')),

      filter: 'inferred',
    },
    {
      key: 'alpha',
      name: 'Alpha',
      formatter: helpers.tableFormatters.formatTo('float', { align: 'right', roundDigits: 4 }),
      filter: 'number',
    },
    {
      key: 'traded_value',
      name: 'Traded Value',
      formatter: helpers.tableFormatters.formatTo('float', {
        align: 'right',
        roundDigits: 2,
        numericPrefix: '$ ',
        commasOnThousands: true,
      }),
      filter: 'number',
    },
    {
      key: 'benchmark_initial',
      name: 'Target Weights',
      formatter: helpers.tableFormatters.formatTo('float', {
        align: 'right',
        roundDigits: 3,
        multiplier: 100,
        suffix: '%',
      }),
      filter: 'number',
    },
    {
      key: 'benchmark_after_returns',
      name: 'Target After Returns',
      formatter: helpers.tableFormatters.formatTo('float', {
        align: 'right',
        roundDigits: 3,
        multiplier: 100,
        suffix: '%',
      }),
      filter: 'number',
    },
    {
      key: 'active_optimal',
      name: 'Active Optimal',
      formatter: helpers.tableFormatters.formatTo('float', {
        align: 'right',
        roundDigits: 3,
        multiplier: 100,
        suffix: '%',
      }),
      filter: 'number',
    },
    {
      key: 'active_after_returns',
      name: 'Active After Returns',
      formatter: helpers.tableFormatters.formatTo('float', {
        align: 'right',
        roundDigits: 3,
        multiplier: 100,
        suffix: '%',
      }),
      filter: 'number',
    },
    {
      key: 'asset_master_linked_assets',
      name: 'Linked Assets',
      formatter: helpers.tableFormatters.formatTo('string', { align: 'left' }),
      filter: 'string',
    },
    {
      key: '_style_', // Dynamic
      name: 'Risk Model Style Factors',
      formatter: helpers.tableFormatters.formatTo('inferred'),
      members: (key) => key.startsWith('_style_'),
      cleanNameFormatter: (key, _c, resources: ts.StoreState['resources']) =>
        _getStyleName(key, resources.signals as ts.types.signal.Signal[]),
      nameFormatter: (key, _c, resources: ts.StoreState['resources']) =>
        _getStyleName(key, resources.signals as ts.types.signal.Signal[]),
      filter: 'inferred',
    },
    {
      key: '_category_', // Dynamic
      name: 'Risk Model Category Factors',
      formatter: helpers.tableFormatters.formatTo('inferred'),
      members: (key) => key.startsWith('_category_'),
      cleanNameFormatter: (key) => _getDynamicName(key.replace('_category_', '')),
      nameFormatter: (key) => _getDynamicName(key.replace('_category_', '')),
      filter: 'inferred',
      // }, {
      //   key: 'dynamic',
      //   name: 'Basket Specific',
      //   formatter: formatDynamicTo({
      //     'other': {
      //       colType: 'inferred'
      //     },
      //   })
    },
    {
      key: 'tcost_weight',
      name: 'T-cost',
      formatter: helpers.tableFormatters.formatTo('float', {
        align: 'right',
        roundDigits: 4,
        multiplier: 100,
        suffix: '%',
      }),
      filter: 'number',
    },
    {
      key: 'market_cap',
      name: 'Market Cap',
      formatter: helpers.tableFormatters.formatTo('float', {
        align: 'right',
        roundDigits: 4,
        multiplier: 100,
        suffix: '%',
      }),
      filter: 'number',
    },
  ] as ts.types.components.dataGrid.ColumnsData,
};
