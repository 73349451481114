import { hooks, mui, React, ts, ui } from '_core';

import { DraftParams } from './types';

type FormProps = {
  params: DraftParams;
  setParams: (_p: DraftParams) => void;
  context?: ts.types.signal.SignalAnalysisContext;
};

const Form: React.FC<FormProps> = ({ params, setParams, context }) => {
  const [quantiles, setQuantiles] = React.useState(params.quantiles || 5);
  const [returnHorizon, setReturnHorizon] = React.useState<ts.types.returnHorizon.ReturnHorizon>(params.return_horizon);
  const [buckets, setBuckets] = React.useState(
    params.buckets || ({ type: 'LONG_SHORT' } as ts.types.widgets.common.BucketValue)
  );
  const [showUnknown, setShowUnknown] = React.useState(params.show_unknown || false);
  const [marketRelativeReturns, setmarketRelativeReturns] = React.useState(params.market_relative_returns || false);
  const uiStyles = hooks.useUiStyles();

  const bucketOptions = [
    {
      value: ts.enums.BUCKET_TYPES_ENUM.LONG_SHORT,
      title: 'Long Short Classifier',
    },
    {
      value: ts.enums.BUCKET_TYPES_ENUM.INDUSTRY,
      title: 'Industry',
    },
    {
      value: ts.enums.BUCKET_TYPES_ENUM.RISK_MODEL,
      title: 'Risk Model Category',
    },
    {
      value: ts.enums.BUCKET_TYPES_ENUM.ASSET_MASTER,
      title: 'Asset Master',
    },
    {
      value: ts.enums.BUCKET_TYPES_ENUM.SIGNAL,
      title: 'Signal',
    },
  ];

  React.useEffect(() => {
    const data = {
      ...params,
      return_horizon: returnHorizon,
      buckets,
      quantiles,
      show_unknown: showUnknown,
      market_relative_returns: marketRelativeReturns,
    };
    setParams(data);
  }, [returnHorizon, quantiles, buckets, showUnknown, marketRelativeReturns]);

  return (
    <mui.core.Box sx={uiStyles.widgetEditMode}>
      <mui.core.Box mt={4}>
        <ui.NumericField
          textFieldProps={{
            label: 'Signal Quantiles (1 - 20)',
            fullWidth: true,
            variant: 'outlined',
            size: 'small',
            required: 'true',
          }}
          value={quantiles}
          setValue={(v) => setQuantiles(v)}
        />
      </mui.core.Box>

      <mui.core.Box mt={4}>
        <ui.Buckets value={buckets} setValue={setBuckets} options={bucketOptions} />
      </mui.core.Box>

      <mui.core.Box mt={2}>
        <mui.core.FormControlLabel
          control={<mui.core.Switch size="small" checked={showUnknown} onChange={() => setShowUnknown(!showUnknown)} />}
          label="Show statistics for missing Signal values"
        />
      </mui.core.Box>

      <mui.core.Box mt={4}>
        <ui.WidgetReturnHorizon<false>
          value={returnHorizon}
          setValue={(val) => setReturnHorizon(val)}
          context={context}
        />
      </mui.core.Box>

      <mui.core.Box mt={2}>
        <mui.core.FormControlLabel
          control={
            <mui.core.Switch
              size="small"
              checked={marketRelativeReturns}
              onChange={() => setmarketRelativeReturns(!marketRelativeReturns)}
            />
          }
          label="Market Relative Returns"
        />
      </mui.core.Box>
    </mui.core.Box>
  );
};

export default Form;
