import { assetMaster } from '../../api/asset-master';
import * as types from '../../types';

/**
 * Queries the asset master API for assets based on the provided parameters.
 * 
 * @param params - The parameters for the asset search.
 * @param limit - The maximum number of assets to return.
 * @param offset - The starting index of the assets to return.
 * @param dates - The date range for the asset search.
 * 
 * @returns An object containing the error status and the response from the asset master API.
 */
export const queryAsset = async (
  params: types.assetSelector.AssetParams,
  limit: number,
  offset: number,
  dates?: types.assetSelector.AssetSelectorDates

) => {
  try {
    const resultAssets = await assetMaster({ params, limit, offset, dates });
    return { error: false, response: resultAssets };
  } catch (e) {
    return { error: true, response: 'Unable to search for Assets, please try again.' };
  }
};
