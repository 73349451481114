import { ts } from '_core';

import { validate } from './utils';

export default [
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.METRIC_SIGNAL_TIME_SERIES,
    title: 'Time Series',
    helpLabel: '09i7zfkb',
    form: true,
    neededFiles: ['sp_returns'],
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.SIGNAL_ADHOC],

    defaultParams: {
      return_horizons: [
        {
          periods: {
            amount: 1,
            frequency: '$frequency',
          },
          signature: '1 Period Return',
        },
      ],
    },
    validate,
    getAdhocData: (pathFile, params, _context, resources: ts.StoreState['resources']) => {
      const selectedTimeSeries = resources.time_series.find((ts) => ts.id == params.analysis_time_series_ids[0]);

      return {
        name: selectedTimeSeries.name,
        file_name: pathFile,
        description: `This file contains the values of the \`${selectedTimeSeries.name}\` time series.`,
        columns: [
          {
            key: 'period_start',
            name: 'start',
            description: 'Start date of the period.',
            type: 'date',
          },
          { key: 'period_end', name: 'end', description: 'End date of the period.', type: 'date' },
          {
            key: selectedTimeSeries.handle,
            name: 'value',
            description: `Value for ${selectedTimeSeries.name}.`,
            type: 'float',
          },
        ],
      };
    },
  },
] satisfies ts.types.analysis.WidgetConfig[];
