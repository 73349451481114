import { _, React, ts } from '_core';

import Chart from './chart';
import Form from './form';
import { DraftParams, Params } from './types';

/*
    This widget uses jobpaths instead of widget paths due to different
    response payload
*/

type CumulativeReturnsWithTimeSeriesProps = {
  id: string;
  readOnly: boolean;
  widgetKey: string;
  mode: ts.enums.CHART_MODE_ENUM;
  params: Params | DraftParams;
  setParams: (_p: DraftParams) => void;
  title: string;
  setExternalEmbed: (_json: ts.types.analysis.EmbedChartData) => void;

  subtitle: string;
  fullScreen?: boolean;
  context?: ts.types.signalSet.SignalSetAnalysisContext;
  loadWidgetData?: (_payload: ts.types.widgets.WidgetGetDataParams) => Promise<ts.types.widgets.WidgetGetDataResponse>;
  getSignature?: (_v: string) => string;
  jobPaths?: Record<string, string>;
};

const CumulativeReturnsWithTimeSeries: React.FC<CumulativeReturnsWithTimeSeriesProps> = ({
  id,
  readOnly,
  widgetKey,
  loadWidgetData,
  jobPaths,
  mode,
  getSignature,
  params,
  setParams,
  fullScreen,
  context,
  title,
  subtitle,
  setExternalEmbed,
}) => {
  // Let's select the file that has the report data
  const file = React.useMemo(() => {
    if (!_.isEmpty(jobPaths)) {
      // Fixed key report (check form to see how we send report as id)
      return jobPaths[`signal_set_cumulative_returns_${getSignature('signal_set_cumulative_returns')}_report`];
    }
    return null;
  }, [jobPaths]);

  const renderJobComponent = () => (
    <>
      <Chart
        id={id}
        readOnly={readOnly}
        widgetKey={widgetKey}
        loadWidgetData={loadWidgetData}
        file={file}
        fullScreen={fullScreen}
        context={context}
        params={params as Params}
        title={title}
        subtitle={subtitle}
        setExternalEmbed={setExternalEmbed}
      />
    </>
  );

  return (
    <>{mode == 'edit' ? <Form params={params} setParams={setParams} context={context} /> : renderJobComponent()}</>
  );
};

export default CumulativeReturnsWithTimeSeries;
