import { _, moment, React, ui } from '_core';

type DateFieldProps = {
  value: string;
  setValue: (_d: string) => void;
  label: string;
  minDate: string;
  maxDate: string;
};

const DateField: React.FC<DateFieldProps> = ({ value, setValue, label, minDate, maxDate }): React.ReactElement => {
  const today = moment().format('YYYY-MM-DD');

  React.useEffect(() => {
    if (_.isEmpty(value)) setValue(today);
  }, []);

  return (
    <ui.FinDatePicker
      label={label}
      value={value}
      onChange={(date) => {
        setValue(date);
      }}
      minDate={minDate ? minDate : undefined}
      maxDate={maxDate ? maxDate : undefined}
      inputProps={{
        disableUnderline: true,
        size: 'small',
      }}
    />
  );
};

DateField.defaultProps = {
  minDate: null,
  maxDate: null,
};

export default DateField;
