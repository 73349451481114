import React from 'react';

import { mui } from '../libs';

type InlineItemProps = {
  selected: boolean;
  name: string | JSX.Element;
  help?: string;
  onChange?: () => void;
};

/**
 * Checkbox item component for inline selection
 * 
 * @param selected - Whether the item is selected
 * @param name - Display name of the item
 * @param help - Help text for the item
 * @param onChange - Callback for when the item is clicked
 * 
 * @returns A checkbox item component for inline selection
 */

const CheckboxItem: React.FC<InlineItemProps> = ({ selected, name, help, onChange }): React.ReactElement => {
  return (
    <>
      <mui.core.Checkbox
        style={{ marginRight: 8, height: '1rem', width: '1rem' }}
        checked={selected}
        onChange={onChange}
        size="small"
      />
      <mui.core.Typography variant="body2" onClick={onChange} sx={{ cursor: 'pointer' }}>
        {name}
      </mui.core.Typography>
      {help && (
        <mui.core.Tooltip title={help}>
          <mui.core.IconButton size="small" sx={{ ml: 1 }}>
            <mui.icons.HelpOutline sx={{ fontSize: '16px' }} />
          </mui.core.IconButton>
        </mui.core.Tooltip>
      )}
    </>
  );
};

export default CheckboxItem;
