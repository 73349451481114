import { _, moment, ts } from '_core';

import { DraftParams } from './types';

export const validate = (params: DraftParams, context: ts.types.riskModel.RiskModelAnalysisContext, _i: boolean) => {
  if (!params.analysis_universe_id) return ['Universe is a required field.'];
  if (_.isEmpty(params.preview_date)) return ['Date is a required field.'];

  if (context) {
    if (moment(params.preview_date).isBefore(moment(context.start_date)))
      return ['Date must be inside the risk model dates.'];
    if (moment(params.preview_date).isAfter(moment(context.end_date)))
      return ['Date must be inside the risk model dates.'];
  }
  if (!_.isEmpty(params.component_errors)) return params.component_errors;
  return [];
};
