import { ts } from '_core';

export const prepareBarData = (
  resources: ts.StoreState['resources'],
  data: ts.types.widgets.NestedBarData
) => {
  const newNestedBarData: ts.types.widgets.NestedBarData = [];

  data.forEach((item) => {
    const newItem: Record<string, number | string> = {};

    Object.keys(item).forEach((key) => {
      if (key === 'category') {
        newItem[key] = item[key];
      } else {
        const newKey = resources.time_series.find((ts) => ts.handle === key)?.name;
        if (newKey) {
          newItem[newKey] = item[key];
        }
      }
    });

    newNestedBarData.push(newItem);
  });

  return newNestedBarData;
};

export const prepareData = (resources: ts.StoreState['resources'], data: ts.types.widgets.LinearData) => {
  const newData = {} as ts.types.widgets.LinearData;

  Object.keys(data).forEach((key) => {
    let newKey = key;
    newKey = resources.time_series.find((ts) => ts.handle === key)?.name;
    newData[newKey] = data[key];
  });

  return newData;
};
