import React from 'react';
import { _, mui } from '@local/finsera-core/src/libs';
import * as types from '@local/finsera-core/src/types';

const buttonOptions = [{ label: 'None' }, { label: 'Fixed' }, { label: 'Fill' }];
interface ColumnWidthProps {
  setFixedWidth: (_v: { fill: boolean; ignore_index: boolean; width: number }) => void;
  fixedWidth: types.components.dataGrid.TableParams['fixed_width'];
}

const ColumnWidth: React.FC<ColumnWidthProps> = ({ setFixedWidth, fixedWidth }): React.ReactElement => {
  const theme = mui.styles.useTheme() as mui.core.Theme;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [widthError, setWidthError] = React.useState<string>(null);

  const [localValue, setLocalValue] = React.useState(fixedWidth);

  const handleOpenColumnWidth = (event: { currentTarget: any }) => {
    setLocalValue(fixedWidth);
    setAnchorEl(event.currentTarget);
  };

  const handleCloseColumnWidth = () => {
    setAnchorEl(null);
  };

  const openColumnWidth = Boolean(anchorEl);
  const id = openColumnWidth ? 'simple-popover' : undefined;

  const selOption = React.useMemo(() => {
    if (!localValue) return 'None';
    if (localValue.fill) return 'Fill';
    return 'Fixed';
  }, [localValue]);

  const savedOption = React.useMemo(() => {
    if (!fixedWidth) return 'None';
    if (fixedWidth.fill) return 'Fill';
    return 'Fixed';
  }, [fixedWidth]);

  React.useEffect(() => {
    if (selOption !== 'Fixed') return;
    if (localValue.width <= 50 || localValue?.width > 500) {
      setWidthError('Please enter a valid width (50-500).');
    } else {
      setWidthError(null);
    }
  }, [localValue, selOption]);

  return (
    <>
      <mui.core.Button
        size="small"
        onClick={handleOpenColumnWidth}
        startIcon={
          <mui.core.Box position="relative">
            {savedOption !== 'None' && (
              <mui.core.Box
                sx={{
                  position: 'absolute',
                  bottom: 12,
                  right: -10,
                  width: 20,
                  height: 20,
                  backgroundColor: theme.palette.primary.main,
                  color: 'white',
                  borderRadius: 10,
                  fontSize: 10,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                1
              </mui.core.Box>
            )}
            <mui.icons.SyncAlt />
          </mui.core.Box>
        }
      >
        Col. Width
      </mui.core.Button>
      <mui.core.Popover
        id={id}
        open={openColumnWidth}
        anchorEl={anchorEl}
        onClose={handleCloseColumnWidth}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        sx={{ '& .MuiPopover-paper': { width: '400px' } }}
      >
        <mui.core.Box p={4}>
          <mui.core.Typography variant="body2" paragraph sx={{ fontWeight: '500' }}>
            Setup a desired width for all columns
          </mui.core.Typography>
          <mui.core.Box display="grid" gap={4} sx={{ mb: 3 }}>
            <mui.core.ButtonGroup variant="outlined">
              {buttonOptions.map((option) => (
                <mui.core.Button
                  key={option.label}
                  variant={selOption == option.label ? 'contained' : 'outlined'}
                  size="small"
                  onClick={() => {
                    if (option.label == 'Fill') {
                      setLocalValue({ ...localValue, fill: true });
                    } else if (option.label == 'None') {
                      setLocalValue(null);
                    } else {
                      setLocalValue({ ...localValue, fill: false, width: 150 });
                    }
                  }}
                  sx={{ flex: '1' }}
                  disableElevation
                >
                  {option.label}
                </mui.core.Button>
              ))}
            </mui.core.ButtonGroup>
          </mui.core.Box>

          {selOption == 'Fixed' && (
            <mui.core.TextField
              label="Fixed Width (in px)"
              variant="outlined"
              value={localValue?.width}
              onChange={(e) =>
                setLocalValue({
                  ...localValue,
                  width: Number(e.target.value),
                })
              }
              fullWidth
              sx={{ mb: 3 }}
              error={!_.isEmpty(widthError)}
              helperText={widthError}
            />
          )}

          {selOption !== 'None' && (
            <mui.core.Box mb={2} ml={2}>
              <mui.core.FormControlLabel
                control={
                  <mui.core.Checkbox
                    size="small"
                    checked={localValue?.ignore_index}
                    onChange={() => setLocalValue({ ...localValue, ignore_index: !localValue?.ignore_index })}
                  />
                }
                label="Ignore index"
              />
            </mui.core.Box>
          )}

          <mui.core.Button
            aria-describedby={id}
            variant="contained"
            disableElevation
            onClick={() => {
              setFixedWidth(localValue);
              handleCloseColumnWidth();
            }}
            disabled={!_.isEmpty(widthError)}
          >
            Apply to all columns
          </mui.core.Button>
        </mui.core.Box>
      </mui.core.Popover>
    </>
  );
};

export default ColumnWidth;
