import { ts } from '_core';

import constraintsNamesMap from 'views/views-config/optimizer-config/constraints/constraints-name-map';

const longOnlyRangeCheck = (
  field: string,
  range: ts.types.backtestSet.RangeParameterValue,
  c: ts.types.optimizer.Constraint
) => {
  if (c?.benchmark_relative) {
    if (range.min < 0)
      return `${field} min value must be greater or equal than zero when the constraint is benchmark-relative.`;
    if (range.max < 0)
      return `${field} max value must be greater or equal than zero when the constraint is benchmark-relative.`;
  }
  return null;
};

const multipierRangeCheck = (field: string, range: ts.types.backtestSet.RangeParameterValue) => {
  if (range.min < 1) return `${field} min value must be greater or equal than 1.`;
  if (range.max < 1) return `${field} max value must be greater or equal than 1.`;
  return null;
};

const multipierListCheck = (field: string, choices: ts.types.backtestSet.CategoricalParameterValue['choices']) => {
  const invalidChoices = choices.some((choice) => Number(choice) < 1);
  if (invalidChoices) return `${field} must be greater or equal than 1.`;
};

const longOnlyListCheck = (
  field: string,
  choices: ts.types.backtestSet.CategoricalParameterValue['choices'],
  c: ts.types.optimizer.Constraint
) => {
  let invalidChoices = false;
  if (c?.benchmark_relative) {
    invalidChoices = choices.some((choice) => Number(choice) < 0);
  }
  if (invalidChoices) return `${field} must be greater or equal than zero when the constraint is benchmark-relative.`;
  return null;
};

export type GroupType =
  | 'objective_function'
  | 'gross_leverage_constraint'
  | ts.enums.OPTIMIZER_CONSTRAINT_TYPE_ENUM.NET_LEVERAGE_CONSTRAINT
  | ts.enums.OPTIMIZER_CONSTRAINT_TYPE_ENUM.SPECIFIC_ASSETS_CONSTRAINT
  | ts.enums.OPTIMIZER_CONSTRAINT_TYPE_ENUM.RISK_FACTOR_CONSTRAINT
  | ts.enums.OPTIMIZER_CONSTRAINT_TYPE_ENUM.FACTOR_CONSTRAINT
  | ts.enums.OPTIMIZER_CONSTRAINT_TYPE_ENUM.TRADE_LIST_SIGNAL_EXPOSURE_CONSTRAINT
  | ts.enums.OPTIMIZER_CONSTRAINT_TYPE_ENUM.ALL_ASSETS_CONSTRAINT
  | ts.enums.OPTIMIZER_CONSTRAINT_TYPE_ENUM.INDUSTRY_CONSTRAINT
  | ts.enums.OPTIMIZER_CONSTRAINT_TYPE_ENUM.COUNTRY_CONSTRAINT;

export const groupLabel: Record<GroupType, string> = {
  objective_function: 'Objective Function',
  ...constraintsNamesMap,
};

const fields: {
  group_types: GroupType[];
  label: string;
  property: string;
  helpLabel: string;
  isPercentField: boolean;
  condition: (_v: ts.types.backtest.Definition) => boolean;
  rangeValidation: (_v: any, _c: ts.types.optimizer.Constraint) => string | null;
  listValidation: (_v: any, _c: ts.types.optimizer.Constraint) => string | null;
}[] = [
  {
    group_types: ['objective_function'],
    label: 'Risk Aversion',
    property: 'lambda_',
    helpLabel: 'j73g9hyw',
    isPercentField: false,
    condition: (backtestDef: ts.types.backtest.Definition) =>
      backtestDef && backtestDef.optimizer_config?.objective_function?.mean_variance_type == 'default',
    rangeValidation: (range: ts.types.backtestSet.RangeParameterValue) => {
      if (range.min < 0) return 'Risk Aversion min must be greater or equal than zero.';
      if (range.max < 0) return 'Risk Aversion max must be greater or equal than zero.';
      return null;
    },
    listValidation: (choices: ts.types.backtestSet.CategoricalParameterValue['choices']) => {
      const invalidChoices = choices.some((choice) => Number(choice) < 0);
      if (invalidChoices) return 'Risk Aversion must be greater than 0.';
      return null;
    },
  },
  {
    group_types: ['objective_function'],
    label: 'TCost Multiplier',
    property: 'tcost_multiplier',
    helpLabel: '73sfknmv',
    isPercentField: false,
    condition: (backtestDef: ts.types.backtest.Definition) => backtestDef && backtestDef.tcost_strategy != 'None',
    rangeValidation: (range: ts.types.backtestSet.RangeParameterValue) => {
      if (range.min < 0) return 'TCost Multiplier min must be greater or equal than zero.';
      if (range.max < 0) return 'TCost Multiplier max must be greater or equal than zero.';
      return null;
    },
    listValidation: (choices: ts.types.backtestSet.CategoricalParameterValue['choices']) => {
      const invalidChoices = choices.some((choice) => Number(choice) < 0);
      if (invalidChoices) return 'TCost Multiplier must be greater than 0.';
      return null;
    },
  },

  // NOT PERCENT BOUNDS
  {
    group_types: [
      'gross_leverage_constraint',
      ts.enums.OPTIMIZER_CONSTRAINT_TYPE_ENUM.NET_LEVERAGE_CONSTRAINT,
      ts.enums.OPTIMIZER_CONSTRAINT_TYPE_ENUM.SPECIFIC_ASSETS_CONSTRAINT,
      ts.enums.OPTIMIZER_CONSTRAINT_TYPE_ENUM.RISK_FACTOR_CONSTRAINT,
      ts.enums.OPTIMIZER_CONSTRAINT_TYPE_ENUM.FACTOR_CONSTRAINT,
      ts.enums.OPTIMIZER_CONSTRAINT_TYPE_ENUM.TRADE_LIST_SIGNAL_EXPOSURE_CONSTRAINT,
    ],
    label: 'Lower Bound',
    property: 'lower_bound',
    helpLabel: 'sni90r8w',
    isPercentField: false,
    condition: () => true,
    rangeValidation: (choices, c) => longOnlyRangeCheck('Lower Bound', choices, c),
    listValidation: (choices, c) => longOnlyListCheck('Lower Bound', choices, c),
  },
  {
    group_types: [
      'gross_leverage_constraint',
      ts.enums.OPTIMIZER_CONSTRAINT_TYPE_ENUM.NET_LEVERAGE_CONSTRAINT,
      ts.enums.OPTIMIZER_CONSTRAINT_TYPE_ENUM.SPECIFIC_ASSETS_CONSTRAINT,
      ts.enums.OPTIMIZER_CONSTRAINT_TYPE_ENUM.RISK_FACTOR_CONSTRAINT,
      ts.enums.OPTIMIZER_CONSTRAINT_TYPE_ENUM.FACTOR_CONSTRAINT,
      ts.enums.OPTIMIZER_CONSTRAINT_TYPE_ENUM.TRADE_LIST_SIGNAL_EXPOSURE_CONSTRAINT,
    ],
    label: 'Upper Bound',
    property: 'upper_bound',
    helpLabel: 'u0q8e1t1',
    isPercentField: false,
    condition: () => true,
    rangeValidation: (choices, c) => longOnlyRangeCheck('Upper Bound', choices, c),
    listValidation: (choices, c) => longOnlyListCheck('Upper Bound', choices, c),
  },

  // PERCENT BOUNDS
  {
    group_types: [
      ts.enums.OPTIMIZER_CONSTRAINT_TYPE_ENUM.ALL_ASSETS_CONSTRAINT,
      ts.enums.OPTIMIZER_CONSTRAINT_TYPE_ENUM.INDUSTRY_CONSTRAINT,
      ts.enums.OPTIMIZER_CONSTRAINT_TYPE_ENUM.COUNTRY_CONSTRAINT,
    ],
    label: 'Lower Bound',
    property: 'lower_bound',
    helpLabel: 's66j2q8o',
    isPercentField: true,
    condition: () => true,
    rangeValidation: (choices, c) => longOnlyRangeCheck('Upper Bound', choices, c),
    listValidation: (choices, c) => longOnlyListCheck('Upper Bound', choices, c),
  },
  {
    group_types: [
      ts.enums.OPTIMIZER_CONSTRAINT_TYPE_ENUM.ALL_ASSETS_CONSTRAINT,
      ts.enums.OPTIMIZER_CONSTRAINT_TYPE_ENUM.INDUSTRY_CONSTRAINT,
      ts.enums.OPTIMIZER_CONSTRAINT_TYPE_ENUM.COUNTRY_CONSTRAINT,
    ],
    label: 'Upper Bound',
    property: 'upper_bound',
    helpLabel: 'x7c5tlll',
    isPercentField: true,
    condition: () => true,
    rangeValidation: (choices, c) => longOnlyRangeCheck('Upper Bound', choices, c),
    listValidation: (choices, c) => longOnlyListCheck('Upper Bound', choices, c),
  },
  {
    group_types: [
      ts.enums.OPTIMIZER_CONSTRAINT_TYPE_ENUM.ALL_ASSETS_CONSTRAINT,
      ts.enums.OPTIMIZER_CONSTRAINT_TYPE_ENUM.SPECIFIC_ASSETS_CONSTRAINT,
    ],
    label: 'Grand Father Size',
    property: 'grandfathered_size',
    helpLabel: 'o2nybobg',
    isPercentField: false,
    condition: () => true,
    rangeValidation: (choices) => multipierRangeCheck('Grand Father Size', choices),
    listValidation: (choices) => multipierListCheck('Grand Father Size', choices),
  },
];

export default fields;
