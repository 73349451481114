import React from 'react';

/**
 * Hook that returns the previous value of a variable from the last render
 * 
 * @template T The type of the value to track
 * @param value - The current value to track
 * @returns The previous value from the last render
 * 
 * @example
 * ```tsx
 * const [count, setCount] = useState(0);
 * const prevCount = usePrevious(count);
 * 
 * // prevCount will be undefined on first render
 * // then will contain previous count value after updates
 * ```
 */
const usePrevious = <T>(value: T) => {
  const ref = React.useRef() as React.MutableRefObject<T>;
  React.useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export default usePrevious;
