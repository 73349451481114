import { actions, BrowserRouter, config, mui, React, Route, Sentry, Switch, ui, useSelector } from '_core';
import { store } from '_core/store';

import { FinseraCoreProvider, uitheme } from '@local/finsera-core';
import { LicenseInfo } from '@mui/x-license';
import Global from 'global';
import InApp from 'in-app';

if (!config.environment.match(/local/)) {
  Sentry.init({
    dsn: 'https://0492e029ccf5431d888e10f88174eab6@o408418.ingest.sentry.io/5279214',
    environment: config.environment,
    beforeSend(event) {
      // Only allow errors that are manually captured
      const isManualCapture = event.tags?.manual || false;
      return isManualCapture ? event : null;
    },
  });
}
// Init muix
// eslint-disable-next-line max-len
LicenseInfo.setLicenseKey(
  'f99f7bb8da6efd2cebef47aa962f21e8Tz05MjQzOCxFPTE3NDk5MjIyMjkwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y'
);

const App = (): React.ReactElement => {
  const productionMode = useSelector((state) => state.ui.currentTab == 'production');
  const authenticated = useSelector((state) => state.auth.authenticated);
  const features = React.useMemo(() => {
    return config.features;
  }, [authenticated]);

  return (
    <BrowserRouter>
      <FinseraCoreProvider store={store} features={features} actions={actions}>
        <mui.core.ThemeProvider theme={productionMode ? uitheme.green : uitheme.blue}>
          <ui.OnlineCheck.Offline
            polling={
              {
                url: '/api/v1/healthz',
                enabled: true,
              } as any
            }
          >
            <ui.OfflineModal />
          </ui.OnlineCheck.Offline>

          <Switch>
            <Route path={['/_global', '/_global/*']} render={() => <Global />} />
            <Route path="*">
              <InApp />
            </Route>
          </Switch>
        </mui.core.ThemeProvider>
      </FinseraCoreProvider>
    </BrowserRouter>
  );
};

export default App;
