import { _, helpers, hooks, mui, React, ts, ui } from '_core';

import * as prefColumns from 'views/preferences/columns-config';
import AsyncTabular from 'views/report/tables/async-tabular';

import { getRiskReportMetricName, getRiskReportValue } from './helpers';
import type { Params, SHEETS } from './types';
import { getColumns } from '../../common/charts/utils';

type ChartProps = {
  id: string;
  loadWidgetData: (_payload: ts.types.widgets.WidgetGetDataParams) => Promise<ts.types.widgets.WidgetGetDataResponse>;
  params: Params;
  setParams: (_p: Params) => void;
  fullScreen: boolean;
  goFullScreen: (_v: string) => void;
  riskSummary: string;
  factorContribution: string;
  securityContribution: string;
  reportDate: string;
  factorExposure: string;
  isBasket: boolean;
  queryParams?: { initial?: string };
};

const Chart: React.FC<ChartProps> = ({
  id,
  loadWidgetData,
  params,
  setParams,
  riskSummary,
  factorContribution,
  securityContribution,
  reportDate,
  factorExposure,
  fullScreen,
  goFullScreen,
  queryParams,
  isBasket,
}) => {
  const riskSummarySheetKey = ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.BACKTEST_PORTFOLIO_RISK_SUMMARY;
  const factorContributionSheetKey =
    ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.BACKTEST_PORTFOLIO_RISK_FACTOR_CONTRIBUTION;
  const factorExposureSheetKey = ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.BACKTEST_PORTFOLIO_RISK_FACTOR_EXPOSURE;
  const securityContributionSheetKey =
    ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.BACKTEST_PORTFOLIO_RISK_SECURITY_CONTRIBUTION;

  const riskSummarySheetName = ts.enums.REPORT_ENUMS.TABLE_SHEET_PREF_NAME_ENUM.BACKTEST_PORTFOLIO_RISK_SUMMARY;
  const factorContributionSheetName =
    ts.enums.REPORT_ENUMS.TABLE_SHEET_PREF_NAME_ENUM.BACKTEST_PORTFOLIO_RISK_FACTOR_CONTRIBUTION;
  const factorExposureSheetName =
    ts.enums.REPORT_ENUMS.TABLE_SHEET_PREF_NAME_ENUM.BACKTEST_PORTFOLIO_RISK_FACTOR_EXPOSURE;
  const securityContributionSheetName =
    ts.enums.REPORT_ENUMS.TABLE_SHEET_PREF_NAME_ENUM.BACKTEST_PORTFOLIO_RISK_SECURITY_CONTRIBUTION;

  const container = React.createRef();

  const [dataDate, setDataDate] = React.useState<string>(reportDate);

  const [tableParams, setTableParams] = React.useState<ts.types.components.dataGrid.TableParamsSheet>(
    params.table_params
  );

  const [tableDtypes, setTableDtypes] = React.useState<ts.types.components.dataGrid.TableDtypesSheet>(
    params.table_dtypes
  );

  hooks.useEffectWithoutFirst(() => {
    setParams({
      ...params,
      table_params: { ...tableParams },
      table_dtypes: { ...tableDtypes },
    });
  }, [tableParams, tableDtypes]);

  const loadData = async (
    file: string,
    offset: number,
    orderBy: ts.types.components.dataGrid.OrderBy,
    filters: ts.types.components.dataGrid.ValueFilters,
    logicOperator: mui.dataGrid.GridLogicOperator,
    sheet: string
  ) => {
    const response = await loadWidgetData({
      data: {
        file,
        pagination: { offset, limit: 100 },
        order_by: orderBy,
        values_filter: filters,
        filter_conjunction: logicOperator,
      },
    });

    if (sheet == factorExposureSheetKey) {
      const possibleDate = _.without(Object.keys(response?.data?.[0] || {}), 'index')?.[0];
      setDataDate(possibleDate);
    }
    return response;
  };

  const downloadData = async (file: string, rename: Record<string, string>, columnOrder: string[]) => {
    const response = await loadWidgetData({
      data: {
        file,
        column_order: columnOrder,
        rename,
      },
      download: true,
    });

    return response;
  };

  const riskSummaryCols = React.useMemo(
    () => [
      {
        key: 'metric',
        name: 'Metric',
        formatter: ({ row }: { row: { metric: string } }) => <>{getRiskReportMetricName(row['metric'])}</>,
      },
      {
        key: 'initial',
        name: 'Initial',
        formatter: ({ row }: { row: { metric: string; initial: number } }) => (
          <>{getRiskReportValue(row['metric'], row['initial'])}</>
        ),
      },
      {
        key: 'optimal',
        name: 'Optimal',
        formatter: ({ row }: { row: { metric: string; optimal: number } }) => (
          <>{getRiskReportValue(row['metric'], row['optimal'])}</>
        ),
      },
      {
        key: 'difference',
        name: 'Difference',
        formatter: ({ row }: { row: { metric: string; difference: number } }) => (
          <>{getRiskReportValue(row['metric'], row['difference'])}</>
        ),
      },
    ],
    [dataDate]
  );

  const factorExposureCols = React.useMemo(
    () => [
      {
        key: 'metric',
        name: 'Metric',
        formatter: ({ row }: { row: { metric: string } }) => <>{getRiskReportMetricName(row['metric'])}</>,
      },
      {
        key: 'value',
        name: 'Value',
        formatter: helpers.tableFormatters.formatTo('float', { align: 'right', roundDigits: 4 }),
      },
    ],
    [dataDate]
  );

  const tableHeader = () => (
    <>
      <mui.core.Box mt={-1} flex={1}>
        <ui.InfoRow title="Report For">{reportDate}</ui.InfoRow>
      </mui.core.Box>
    </>
  );

  const renderChart = () => {
    const columns = prefColumns.backtestPortfolioRiskColumns;
    return (
      <>
        <AsyncTabular<SHEETS>
          key={riskSummary}
          loadData={{
            [riskSummarySheetKey]: (offset, orderBy, filters, logicOperator) =>
              loadData(riskSummary, offset, orderBy, filters, logicOperator, riskSummarySheetKey),
            [factorContributionSheetKey]: (offset, orderBy, filters, logicOperator) =>
              loadData(factorContribution, offset, orderBy, filters, logicOperator, factorContributionSheetKey),
            [factorExposureSheetKey]: (offset, orderBy, filters, logicOperator) =>
              loadData(factorExposure, offset, orderBy, filters, logicOperator, factorExposureSheetKey),
            [securityContributionSheetKey]: (offset, orderBy, filters, logicOperator) =>
              loadData(securityContribution, offset, orderBy, filters, logicOperator, securityContributionSheetKey),
          }}
          getColumns={{
            [riskSummarySheetKey]: () => riskSummaryCols,
            [factorContributionSheetKey]: (_c, preferencesColumns) =>
              getColumns(
                columns[factorContributionSheetKey].map((el) => el.key),
                preferencesColumns?.[factorContributionSheetKey],
                prefColumns.backtestPortfolioRiskColumns[factorContributionSheetKey]
              ),
            [factorExposureSheetKey]: () => factorExposureCols,
            [securityContributionSheetKey]: (_c, preferencesColumns) =>
              getColumns(
                columns[securityContributionSheetKey].map((el) => el.key),
                preferencesColumns?.[securityContributionSheetKey],
                prefColumns.backtestPortfolioRiskColumns[securityContributionSheetKey]
              ),
          }}
          sheetTitles={{
            [riskSummarySheetKey]: riskSummarySheetName,
            [factorContributionSheetKey]: factorContributionSheetName,
            [factorExposureSheetKey]: factorExposureSheetName,
            [securityContributionSheetKey]: securityContributionSheetName,
          }}
          customToolBar={{
            [riskSummarySheetKey]: tableHeader,
            [factorContributionSheetKey]: undefined,
            [factorExposureSheetKey]: tableHeader,
            [securityContributionSheetKey]: undefined,
          }}
          fullScreen={fullScreen}
          goFullScreen={goFullScreen}
          queryParams={queryParams}
          currentSheet={params.current_sheet}
          setCurrentSheet={(sheet) => setParams({ ...params, current_sheet: sheet })}
          rowWidth={{
            [riskSummarySheetKey]: 200,
            [factorContributionSheetKey]: 150,
            [factorExposureSheetKey]: 150,
            [securityContributionSheetKey]: 150,
          }}
          downloadFullData={{
            [riskSummarySheetKey]: (rename: Record<string, string>, columnOrder: string[]) =>
              downloadData(riskSummary, rename, columnOrder),
            [factorContributionSheetKey]: (rename: Record<string, string>, columnOrder: string[]) =>
              downloadData(factorContribution, rename, columnOrder),
            [factorExposureSheetKey]: (rename: Record<string, string>, columnOrder: string[]) =>
              downloadData(factorExposure, rename, columnOrder),
            [securityContributionSheetKey]: (rename: Record<string, string>, columnOrder: string[]) =>
              downloadData(securityContribution, rename, columnOrder),
          }}
          preferences={{
            preferencesSourceWidgetId: id,
            preferenceKey: isBasket
              ? ts.enums.PREFERENCES_KEY_ENUM.BASKET_PORTFOLIO_RISK_COLS
              : ts.enums.PREFERENCES_KEY_ENUM.BACKTEST_PORTFOLIO_RISK_COLS,
            preferenceTab: isBasket
              ? ts.enums.PREFERENCES_INNER_TABS_ENUM.BASKET_PORTFOLIO_RISK_COLS
              : ts.enums.PREFERENCES_INNER_TABS_ENUM.BACKTEST_PORTFOLIO_RISK_COLS,
          }}
          tableParams={tableParams}
          setTableParams={{
            [riskSummarySheetKey]: (v) =>
              setTableParams((t) => ({
                ...t,
                [riskSummarySheetKey]: v,
              })),
            [factorContributionSheetKey]: (v) =>
              setTableParams((t) => ({
                ...t,
                [factorContributionSheetKey]: v,
              })),
            [factorExposureSheetKey]: (v) =>
              setTableParams((t) => ({
                ...t,
                [factorExposureSheetKey]: v,
              })),
            [securityContributionSheetKey]: (v) =>
              setTableParams((t) => ({
                ...t,
                [securityContributionSheetKey]: v,
              })),
          }}
          tableDtypes={tableDtypes}
          setTableDtypes={{
            [riskSummarySheetKey]: (v) =>
              setTableDtypes((t) => ({
                ...t,
                [riskSummarySheetKey]: v,
              })),
            [factorContributionSheetKey]: (v) =>
              setTableDtypes((t) => ({
                ...t,
                [factorContributionSheetKey]: v,
              })),
            [factorExposureSheetKey]: (v) =>
              setTableDtypes((t) => ({
                ...t,
                [factorExposureSheetKey]: v,
              })),
            [securityContributionSheetKey]: (v) =>
              setTableDtypes((t) => ({
                ...t,
                [securityContributionSheetKey]: v,
              })),
          }}
        />
      </>
    );
  };

  return (
    <mui.core.Box display="flex" flexDirection="column" style={{ height: '100%' }} ref={container}>
      {!_.isNil(riskSummary) && renderChart()}
    </mui.core.Box>
  );
};

export default Chart;
