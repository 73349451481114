import React from 'react';

import * as enums from '../../../enums';
import { mui } from '../../../libs';
import { UploadContext } from '../upload-context';

const HeaderStepper = () => {
  const uploadContext = React.useContext(UploadContext);
  const { step } = uploadContext;

  const activeStep = React.useMemo(() => {
    if (step == enums.HOLDINGS_UPLOAD_STEPS_ENUM.MAPPING_COLUMNS) return 1;
    if (step == enums.HOLDINGS_UPLOAD_STEPS_ENUM.HOLDINGS_CONFIRM) return 2;
    return 0;
  }, [step]);

  return (
    <mui.core.Box>
      <mui.core.Box mt={3}>
        <mui.core.Stepper activeStep={activeStep}>
          {['Basic Info', 'Map Columns', 'Review and Submit'].map((label: string, index: number) => (
            <mui.core.Step key={index}>
              <mui.core.StepLabel>{label}</mui.core.StepLabel>
            </mui.core.Step>
          ))}
        </mui.core.Stepper>
      </mui.core.Box>
    </mui.core.Box>
  );
};

export default HeaderStepper;
