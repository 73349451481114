import { ts } from '_core';
import { _ } from '_core';

import { validate } from './helpers';

export default [
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.REPORT_SECTION,
    title: 'Report Section',
    form: true,
    tags: _.without(
      Object.values(ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM),
      ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.SIGNAL_ADHOC,
      ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.BASKET_ADHOC
    ),
    fullWidth: true,
    excludeWrapper: true,
    disableTitleEdit: true,
    validate,
    helpLabel: '9cg8az7c',
  },
] satisfies ts.types.analysis.WidgetConfig[];
