import { ts } from '_core';

import { getName, validate } from './helpers';
import { DraftParams } from './types';

export default [
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.SIGNAL_ANNUALIZED_BUCKETED_RETURN,
    title: 'Portfolio-weighted Annualized Returns by Bucket',
    helpLabel: 'dijsi77c',
    form: true,
    defaultParams: {
      statistics_type: 'return',
      histories: ['1 Year', '3 Years', '5 Years', 'Full'],
      ui_transformation: 'ANNUALIZED',
    } as DraftParams,
    neededFiles: ['bucketed_returns'],
    tags: [
      ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.SIGNAL,
      ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.ALPHA_MODEL,
      ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.COMMON_ALPHA_MODEL,
    ],

    getName,
    validate,
  },
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.SIGNAL_ANNUALIZED_BUCKETED_RISK,
    title: 'Portfolio-weighted Annualized Risk by Bucket',
    helpLabel: 'prs22ga5',
    form: true,
    defaultParams: {
      statistics_type: 'risk',
      histories: ['1 Year', '3 Years', '5 Years', 'Full'],
      ui_transformation: 'ANNUALIZED',
    } as DraftParams,
    neededFiles: ['bucketed_returns'],
    tags: [
      ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.SIGNAL,
      ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.ALPHA_MODEL,
      ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.COMMON_ALPHA_MODEL,
    ],

    getName,
    validate,
  },
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.SIGNAL_ANNUALIZED_BUCKETED_ADJUSTED,
    title: 'Portfolio-weighted Annualized Risk Adjusted Returns by Bucket',
    helpLabel: 'c4uqqdqu',
    form: true,
    defaultParams: {
      statistics_type: 'adjusted',
      histories: ['1 Year', '3 Years', '5 Years', 'Full'],
      ui_transformation: 'ANNUALIZED',
    } as DraftParams,
    neededFiles: ['bucketed_returns'],
    tags: [
      ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.SIGNAL,
      ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.ALPHA_MODEL,
      ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.COMMON_ALPHA_MODEL,
    ],

    getName,
    validate,
  },
] satisfies ts.types.analysis.WidgetConfig[];
