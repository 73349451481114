import { _, helpers,ts } from '_core';

import { DraftParams, Params } from './types';
import { getBenchmarkTitle } from '../../common/utils/get-name';

export const validate = (params: DraftParams) => {
  const rhErrors = helpers.validations.validSelectedReturnHorizon(params?.return_horizon);
  if (!_.isEmpty(rhErrors)) return rhErrors;

  if (params.selected_type == 'benchmark') {
    if (!helpers.validations.validBenchmark(params.set_params?.[0].file_params?.benchmark))
      return ['Benchmark is invalid.'];
  } else {
    if (
      _.isEmpty(params.analysis_pipelined_signals) ||
      !_.some(params.analysis_pipelined_signals, (el) => el.signal_id)
    )
      return ['Signal is a required field.'];
  }

  return [];
};

export const getName = (
  params: Params,
  _context: ts.types.signalSet.SignalSetAnalysisContext,
  resources: ts.StoreState['resources']
) => {
  if (params.selected_type == 'benchmark') {
    return getBenchmarkTitle(params.set_params[0].file_params?.benchmark, 'Relative Returns', resources.universes);
  }

  const signalsInfo = params?.analysis_pipelined_signals?.[0];

  const selectedSignal = resources.signals.find((s) => s.id == signalsInfo?.signal_id);
  const selectedPipeline = resources.pipelines.find((p) => p.id == signalsInfo?.pipeline_id);
  const selectedSpPipeline = resources.pipelines.find((p) => p.id == signalsInfo?.sp_pipeline_id);
  const suffixName = selectedSignal?.name;

  const suffixPipeline = signalsInfo.pipeline_id ? `(${selectedPipeline.name})` : '';
  const suffixSpPipeline = signalsInfo.sp_pipeline_id ? `(${selectedSpPipeline.name})` : '';

  return `Relative Returns Relative to ${suffixName} ${suffixPipeline} ${suffixSpPipeline}`;
};
