import { mui, React, ts } from '_core';

import Chart from './chart';
import Form from './form';
import { DraftParams, Params } from './types';

type PortfolioFactorExposureProps = {
  id: string;
  readOnly: boolean;
  widgetKey: string;
  mode: ts.enums.CHART_MODE_ENUM;
  context?: ts.types.portfolioHoldings.PortfolioHoldingsAnalysisContext;
  loadWidgetData: (_payload: ts.types.widgets.WidgetGetDataParams) => Promise<ts.types.widgets.WidgetGetDataResponse>;
  params: Params;
  setParams: (_p: DraftParams) => void;
  widgetPaths?: Record<
    | 'portfolio_alpha_factor_exposure'
    | 'portfolio_list_user_factor_exposure'
    | 'portfolio_alpha_factor_exposure_holdings'
    | 'portfolio_list_user_factor_exposure_holdings',
    string
  >;
  goFullScreen?: (_b: string) => void;
  fullScreen?: boolean;

  queryParams?: { initial?: string };
};

const PortfolioFactorExposure: React.FC<PortfolioFactorExposureProps> = ({
  id,
  readOnly,
  widgetKey,
  mode,
  loadWidgetData,
  params,
  setParams,
  widgetPaths,
  fullScreen,
  goFullScreen,
  queryParams,
  context,
}) => {
  const renderJobComponent = () => (
    <mui.core.Box sx={{ height: '100%' }}>
      <Chart
        id={id}
        readOnly={readOnly}
        widgetKey={widgetKey}
        loadWidgetData={loadWidgetData}
        model={
          widgetPaths['portfolio_alpha_factor_exposure'] ?? widgetPaths['portfolio_alpha_factor_exposure_holdings']
        }
        factor={
          widgetPaths['portfolio_list_user_factor_exposure'] ??
          widgetPaths['portfolio_list_user_factor_exposure_holdings']
        }
        fullScreen={fullScreen}
        queryParams={queryParams}
        goFullScreen={goFullScreen}
        params={params}
        setParams={setParams}
      />
    </mui.core.Box>
  );

  return (
    <>{mode == 'edit' ? <Form params={params} setParams={setParams} context={context} /> : renderJobComponent()}</>
  );
};

export default PortfolioFactorExposure;
