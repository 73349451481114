import { _ } from '@local/finsera-core/src/libs/lodash';
import { ApiBannerError } from '@local/finsera-core/src/types/common';

import { ADD_ERROR, CLEAR_ERRORS, POP_ERROR } from '../actions/types';

const INITIAL_STATE = {
  stack: [] as ApiBannerError[],
};

export type AddErrorActionType = {
  type: typeof ADD_ERROR;
  payload: { error: ApiBannerError };
};

export type PopErrorActionType = {
  type: typeof POP_ERROR;
};

export type ClearErrorsActionType = {
  type: typeof CLEAR_ERRORS;
};

export default (state = INITIAL_STATE, action: AddErrorActionType | PopErrorActionType | ClearErrorsActionType) => {
  switch (action.type) {
    case ADD_ERROR:
      return { ...state, stack: _.uniqBy([action.payload.error, ...state.stack], 'url') };
    case POP_ERROR:
      return { ...state, stack: state.stack.slice(1) };
    case CLEAR_ERRORS:
      return { ...state, stack: [] };
    default:
      return state;
  }
};
