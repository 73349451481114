import React from 'react';

import ErrorModal from './job-status/error-modal';
import api from '../api';
import * as enums from '../enums';
import { _, mui } from '../libs';
import * as types from '../types';

type MessageBarProps = {
  isStale?: boolean;
  job?: types.job.Job;
  customMessage?: string;
  customIcon?: any;
  isWarning?: boolean;
};

/**
 * MessageBar component that displays messages related to job status.
 * 
 * @param isStale - Optional flag indicating if the job data is stale.
 * @param job - The job object containing information about the current job.
 * @param customMessage - Optional custom message to display.
 * @param customIcon - Optional custom icon to display.
 * @param isWarning - Optional flag indicating if the message is a warning.
 * 
 * @returns Rendered MessageBar component displaying job status messages.
 */

const MessageBar: React.FC<MessageBarProps> = ({
  job,
  customMessage,
  isStale,
  customIcon,
  isWarning,
}): React.ReactElement => {
  const theme = mui.styles.useTheme() as mui.core.Theme;

  const dangerStyles = {
    display: 'flex',
    alignItems: 'center',
    background: (theme.palette as any).dangerLight.light,
    padding: '0.2rem 1rem 0.2rem',
    fontWeight: '500',
    fontSize: '0.8rem',
    color: (theme.palette as any).danger.main,
  };

  const warningStyles = {
    display: 'flex',
    alignItems: 'center',
    background: (theme.palette as any).warningLight.main,
    padding: '0.2rem 1rem 0.2rem',
    fontWeight: '500',
    fontSize: '0.8rem',
    color: (theme.palette as any).warning.dark,
  };

  const [errorModal, setErrorModal] = React.useState<enums.JOB_ERROR_SEVERITY_ENUM>(null);
  const customMessageStyle = isWarning ? warningStyles : dangerStyles;

  const openErrorModal = (severityVar: enums.JOB_ERROR_SEVERITY_ENUM) => {
    setErrorModal(severityVar);
  };

  const mapData = (errorData: types.job.JobError) => {
    return (
      <>
        <strong>
          {errorData.type}
          {': '}
        </strong>
        {errorData.message}
      </>
    );
  };

  const downloadFile = (paths: types.job.JobPaths, fileKey: string) => {
    api.s3.signPaths(paths, job.id).then((signed) => {
      const url = signed.data.jobs[job.id][fileKey];
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', url);
      document.body.appendChild(link);
      link.click();
    });
  };

  const renderResults = (paths: types.job.JobPaths) =>
    Object.keys(paths).map((key, idx) => {
      return (
        <>
          <mui.core.Button
            style={{ color: (theme.palette as any).danger.main }}
            size="small"
            onClick={() => downloadFile(paths, key)}
          >
            {key}
          </mui.core.Button>
          {idx !== Object.keys(paths).length - 1 ? '/' : ''}
        </>
      );
    });

  const renderOptimizerResults = (paths: types.job.JobPaths) =>
    Object.keys(paths).map((key, idx) => {
      const getLabel = (key: string) => {
        if (key == 'error') return 'Assets';
        if (key == 'input') return 'Portfolio';
      };

      return (
        <>
          <mui.core.Button
            size="small"
            style={{ color: (theme.palette as any).danger.main }}
            onClick={() => downloadFile(paths, key)}
          >
            {getLabel(key)}
          </mui.core.Button>
          {idx !== Object.keys(paths).length - 1 ? '/' : ''}
        </>
      );
    });

  const getError = () => {
    if (job?.error) {
      return (
        <mui.core.Box sx={dangerStyles}>
          <mui.core.Box flex="1" display="flex" alignItems="center">
            <mui.icons.ErrorOutline style={{ fontSize: '1.1rem', marginRight: '8px' }} />
            <mui.core.Box>
              <mui.core.Typography
                variant="body2"
                sx={{
                  maxWidth: 'calc(100vw - 500px)',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  minWidth: '0',
                }}
              >
                {mapData(job?.error)}
              </mui.core.Typography>
              {!_.isEmpty(job?.result?.paths) && (
                <>
                  {job?.error.type == 'OptimizerInternalErrorException' ? (
                    <>
                      <strong>Binding/infeasible constraints reports:</strong>
                      {renderOptimizerResults(job.result.paths)}
                    </>
                  ) : (
                    <>
                      <strong>Log Files: </strong>
                      {renderResults(job.result.paths)}
                    </>
                  )}
                </>
              )}
            </mui.core.Box>
          </mui.core.Box>
          <mui.core.Button
            onClick={() => {
              openErrorModal(enums.JOB_ERROR_SEVERITY_ENUM.ERROR);
            }}
            size="small"
            style={{ color: (theme.palette as any).danger.main }}
          >
            View full log
          </mui.core.Button>
        </mui.core.Box>
      );
    }

    return '';
  };

  if (isStale) {
    return <></>;
  }

  return (
    <>
      {customMessage ? (
        <>
          <mui.core.Box sx={customMessageStyle}>
            {customIcon}
            {customMessage}
          </mui.core.Box>
        </>
      ) : (
        <>{job?.error ? getError() : <>{job?.warnings && getError()}</>}</>
      )}

      {errorModal && (
        <ErrorModal
          handleClose={() => setErrorModal(null)}
          data={errorModal == enums.JOB_ERROR_SEVERITY_ENUM.ERROR ? [job.error] : job.warnings}
          severity={errorModal}
        />
      )}
    </>
  );
};

MessageBar.defaultProps = {
  customMessage: null,
  job: null,
  isWarning: false,
  customIcon: null,
};

export default MessageBar;
