import { hooks, mui, React, ts, ui } from '_core';

import { DraftParams } from './types';

type FormProps = {
  params: DraftParams;
  setParams: (_p: DraftParams) => void;
  hasBenchmark: boolean;
  context?: ts.types.backtest.BacktestAnaylsisContext;
};

const Form: React.FC<FormProps> = ({ params, setParams, context, hasBenchmark }): React.ReactElement => {
  const [selectedHistories, setSelectedHistories] = React.useState(
    params.histories || ([] as ts.enums.HISTORY_ENUM_EXTENDED[])
  );

  const [benchmark, setBenchmark] = React.useState(params.benchmark || null);
  const [withBenchmark, setWithBenchmark] = React.useState(params.with_benchmark || false);

  const uiStyles = hooks.useUiStyles();

  React.useEffect(() => {
    const data = { ...params, histories: selectedHistories, with_benchmark: withBenchmark };
    if (withBenchmark) data.benchmark = benchmark;
    else if (data.benchmark) delete data.benchmark;

    setParams(data);
  }, [selectedHistories, withBenchmark, benchmark]);

  return (
    <mui.core.Box sx={uiStyles.widgetEditMode}>
      <mui.core.Box mt={2}>
        <ui.WidgetHistories<true>
          multiple
          context={context}
          value={selectedHistories}
          setValue={setSelectedHistories}
        />
      </mui.core.Box>

      {hasBenchmark && (
        <>
          <mui.core.Box mt={3}>
            <mui.core.FormControlLabel
              control={
                <mui.core.Switch
                  size="small"
                  checked={withBenchmark}
                  onChange={() => setWithBenchmark(!withBenchmark)}
                />
              }
              label="Has benchmark"
            />
          </mui.core.Box>

          {withBenchmark && (
            <mui.core.Box mt={2}>
              <ui.Benchmark
                value={benchmark || undefined}
                setValue={setBenchmark}
                universeContextPath={'$universe.handle'}
                enableAnnualizedSignals
                universeIdPath={'definition.universe_id'}
              />
            </mui.core.Box>
          )}
        </>
      )}
    </mui.core.Box>
  );
};

export default Form;
