import { ts } from '_core';

import { DraftParams } from './types';

export const prepareData = (resources: ts.StoreState['resources'], data: ts.types.widgets.LinearData) => {
  const newData = {} as ts.types.widgets.LinearData;
  Object.keys(data).forEach((key) => {
    // If the key starts with a history type, remove it
    let newKey = key;
    let historyType = '';

    Object.values(ts.enums.PERIOD_ENUM).forEach((historyEnum) => {
      if (key.startsWith(historyEnum)) {
        historyType = `${historyEnum}_`;
        newKey = key.replace(historyType, '');
      }
    });

    newKey = resources.time_series.find((ts) => ts.handle === newKey)?.name;

    // Add history type back if we have one
    if (newKey) newData[`${historyType}${newKey}`] = data[key];
  });

  return newData;
};

export const validate = (params: DraftParams) => {
  if ((params.analysis_time_series_ids || []).length < 1) return ['You need to add at least one time series.'];
  if ((params.analysis_time_series_ids || []).length > 5) return ['You can only add a max of five time series.'];
  return [];
};
