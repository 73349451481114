import React from 'react';

import { _ } from '../libs';

/**
 * Type extending the base definition type with validation flags
 */
type DefinitionNeededType<TDefinition> = TDefinition & {
  /** Flag to force validation regardless of definition changes */
  forceValidate?: boolean;
  /** Flag to skip validation */
  noValidate?: boolean;
};

/**
 * Configuration options for useDefinitionValidate hook
 */
interface IUseDefinitionValidate<TDefinition> {
  /** ID of the resource being validated */
  id: number;
  /** Function to perform validation */
  validate: () => void;
  /** Callback to set validating state */
  setValidating: (_v: boolean) => void;
  /** Definition object to watch for changes */
  definition?: DefinitionNeededType<TDefinition>;
  /** Optional debounce delay in milliseconds */
  delay?: number;
}

/**
 * Hook that automatically validates a resource definition when it changes
 * 
 * @template TDefinition Type of the definition being watched
 * @param options - Configuration options
 * @param options.id - ID of the resource
 * @param options.validate - Function to perform validation
 * @param options.definition - Definition object to watch for changes
 * @param options.setValidating - Callback to set validating state
 * @param options.delay - Optional debounce delay in milliseconds (default: 2000ms)
 * 
 * @example
 * ```tsx
 * useDefinitionValidate({
 *   id: resourceId,
 *   validate: () => validateResource(resourceId),
 *   definition: resourceDefinition,
 *   setValidating: (validating) => setIsValidating(validating),
 *   delay: 1000
 * });
 * ```
 */
const useDefinitionValidate = <TDefinition>({
  id,
  validate,
  definition,
  setValidating,
  delay = 2000,
}: IUseDefinitionValidate<TDefinition>) => {
  const [definitionChanges, setDefinitionChanges] = React.useState(0);

  const debouncedValidate = React.useCallback(
    _.debounce((validateLocal) => {
      validateLocal();
      setValidating(false);
    }, delay),
    []
  );

  React.useEffect(() => {
    // number of times the definition will change before we are ready to start
    // checking the definition changes. (1. null,2. set resource)
    if ((definitionChanges >= 2 || definition?.forceValidate) && !definition?.noValidate) {
      // Every time that there is a change in the dependency array
      setValidating(true);
      setDefinitionChanges(definitionChanges + 1);

      debouncedValidate(validate);
    } else setDefinitionChanges(definitionChanges + 1);
    delete definition?.noValidate;
    delete definition?.forceValidate;
  }, [definition]);

  React.useEffect(() => {
    // If the id changes, it means we are in the same type of resource but in another id.
    // This means that first render has been triggered and we'll wait
    // for the setnull in context and then the set resource
    setDefinitionChanges(1);
  }, [id]);
};

export default useDefinitionValidate;
