/* eslint-disable no-unused-vars */
import React from 'react';

import { mui } from '../../../libs';
import * as types from '../../../types';

enum CONJUCTION_ENUM {
  AND = '$and',
  OR = '$or',
}

type GroupActionsProps = {
  conjunction: CONJUCTION_ENUM;
  config: types.components.queryBuilder.QbConfig & { oneLevel?: boolean };
  updateInnerElement: (_i: number, _v: any) => void;
  addGroup: () => void;

  remove?: () => void;
};

const GroupActions: React.FC<GroupActionsProps> = ({
  conjunction,
  updateInnerElement,
  addGroup,
  remove,
  config,
}): React.ReactElement => (
  <mui.core.Box display="flex" alignItems="center">
    {/* Conjunction */}
    <mui.core.Box flexGrow={1}>
      <mui.core.ToggleButtonGroup
        exclusive
        value={conjunction}
        onChange={(e, newConjunction) => {
          updateInnerElement(0, newConjunction ? newConjunction : conjunction);
        }}
        size="small"
        disabled={config.readOnly}
      >
        <mui.lab.ToggleButton value="$and">And</mui.lab.ToggleButton>
        <mui.lab.ToggleButton value="$or">Or</mui.lab.ToggleButton>
      </mui.core.ToggleButtonGroup>
    </mui.core.Box>
    {/* Add rule */}
    {!config.oneLevel && (
      <div>
        <mui.core.Button
          variant="outlined"
          color="primary"
          startIcon={<mui.icons.Add />}
          onClick={addGroup}
          size="small"
          disabled={config.readOnly}
        >
          Group
        </mui.core.Button>
      </div>
    )}
    {remove && (
      <mui.core.Tooltip arrow title="Delete group">
        <mui.core.IconButton size="small" onClick={remove} style={{ marginLeft: '6px' }}>
          <mui.icons.Delete />
        </mui.core.IconButton>
      </mui.core.Tooltip>
    )}
  </mui.core.Box>
);

GroupActions.defaultProps = {
  remove: null,
};

export default GroupActions;
