import { _, React, ts } from '_core';

import Chart from './chart';
import Form from './form';
import { DraftParams, Params } from './types';

type BacktestSignalExposuresOverTimeProps = {
  id: string;
  readOnly: boolean;
  widgetKey: string;
  mode: ts.enums.CHART_MODE_ENUM;
  params: Params;
  setParams: (_p: DraftParams) => void;
  title: string;

  context?: ts.types.backtest.BacktestAnaylsisContext;
  fullScreen?: boolean;
  loadWidgetData: (_payload: ts.types.widgets.WidgetGetDataParams) => Promise<ts.types.widgets.WidgetGetDataResponse>;
  widgetPaths?: Record<'backtest_signal_exposure_analysis', string>;
};

const BacktestSignalExposuresOverTime: React.FC<BacktestSignalExposuresOverTimeProps> = ({
  id,
  readOnly,
  widgetKey,
  loadWidgetData,
  widgetPaths,
  mode,
  params,
  setParams,
  fullScreen,
  title,
  context,
}): React.ReactElement => {
  const hasBenchmark = React.useMemo(
    () => (params.isBasket ? context?.definition?.optimizer_rebalance : !_.isEmpty(context?.definition?.benchmark)),
    []
  );

  const renderJobComponent = () => (
    <>
      <Chart
        id={id}
        readOnly={readOnly}
        widgetKey={widgetKey}
        fullScreen={fullScreen}
        loadWidgetData={loadWidgetData}
        file={widgetPaths['backtest_signal_exposure_analysis']}
        hasBenchmark={hasBenchmark}
        title={title}
      />
    </>
  );

  return <>{mode == 'edit' ? <Form params={params} setParams={setParams} /> : <>{renderJobComponent()}</>}</>;
};

export default BacktestSignalExposuresOverTime;
