// basic info of the resources accesible for the user for forms and displays
import { api } from '@local/finsera-core';
import { parseIndustries, resourcesUtils } from '@local/finsera-core/src/helpers';
import { Resource } from '@local/finsera-core/src/types/common';
import { OrganizationFeatures } from '@local/finsera-core/src/types/organization';
import _ from 'lodash';

const _mapWithShared = (resources: Resource[], userId: number) => {
  return resources.map((r) => ({
    ...r,
    shared: !r.is_published && r.shared_with.includes(userId) && r.created_by_id !== userId,
  }));
};

export const loadCurrencies = async (_features: OrganizationFeatures, _userId: number) => {
  return { currencies: (await api.currencies.search({ include: 'id,name,alpha_code' })).data };
};

export const loadGeneralAnalyses = async (_features: OrganizationFeatures, _userId: number) => {
  return { general_analyses: (await api.general_analyses.search({})).data };
};

export const loadDataSources = async (features: OrganizationFeatures, userId: number) => {
  const dataSources = (
    await api.data_sources.search({
      query: [
        '$and',
        ['is_signal_source', '=', false],
        ['$or', ['is_published', '=', true], ['created_by_id', '=', userId], ['shared_with', 'includesid', userId]],
      ],
      include:
        // eslint-disable-next-line max-len
        'id,name,handle,ds_type,data_provider_label,data_set_label,organization_id,is_published,is_deprecated,created_by_id,definition_locked,allow_preview,shared_with,is_index,countries,has_assets,description,has_description,short_description,updated_at',
    })
  ).data;

  const filteredDataSources = resourcesUtils.handleDataSourcesSubscriptions(
    dataSources,
    features.data_sources_whitelist,
    features.id
  );

  return {
    data_sources: _mapWithShared(filteredDataSources?.subscribed, userId),
    unsubscribed_data_sources: _mapWithShared(filteredDataSources?.unsubscribed, userId),
  };
};

export const loadBaskets = async (_features: OrganizationFeatures, userId: number) => {
  return { baskets: _mapWithShared((await api.baskets.search({})).data, userId) };
};

export const loadUniverses = async (_features: OrganizationFeatures, userId: number) => {
  return { universes: _mapWithShared((await api.universes.search({})).data, userId) };
};

export const loadFixedCosts = async (_features: OrganizationFeatures, _userId: number) => {
  return { fixed_costs: (await api.fixed_costs.search({})).data };
};

export const loadSignals = async (_features: OrganizationFeatures, userId: number) => {
  return { signals: _mapWithShared((await api.signals.search({})).data, userId) };
};

export const loadSignalSets = async (_features: OrganizationFeatures, userId: number) => {
  return { signal_sets: _mapWithShared((await api.signal_sets.search({})).data, userId) };
};

export const loadPipelines = async (_features: OrganizationFeatures, userId: number) => {
  return { pipelines: _mapWithShared((await api.pipelines.search({})).data, userId) };
};

export const loadRiskModels = async (_features: OrganizationFeatures, userId: number) => {
  return { risk_models: _mapWithShared((await api.risk_models.search({})).data, userId) };
};

export const loadBacktests = async (_features: OrganizationFeatures, userId: number) => {
  return { backtests: _mapWithShared((await api.backtests.search({})).data, userId) };
};

export const loadBacktestSets = async (_features: OrganizationFeatures, userId: number) => {
  return { backtest_sets: _mapWithShared((await api.backtest_sets.search({})).data, userId) };
};

export const loadArtifacts = async (_features: OrganizationFeatures, _userId: number) => {
  const storeDatasets = (await api.signal_datasets.search({ include: 'id,signal_id,is_preferred,ws_is_latest' })).data;

  return { signal_datasets: storeDatasets };
};

export const loadReportDefaults = async (_features: OrganizationFeatures, _userId: number) => {
  return {
    report_defaults: (
      await api.report_defaults.search({
        include: 'id,title,type,definition,is_preferred,updated_at',
      })
    ).data,
  };
};

export const loadUsers = async (_features: OrganizationFeatures, _userId: number) => {
  return { users: (await api.users.search({ include: 'id,name,email' })).data };
};

export const loadLabels = async (_features: OrganizationFeatures, _userId: number) => {
  const rawLabels = (await api.labels.search({ include: 'id,title' })).data;
  // Adding uniq in case we have more than one label with the same title
  // (should not be possible using the ui)
  return { labels: _.uniqBy(rawLabels, 'title') };
};

export const loadHelpItems = async (_features: OrganizationFeatures, _userId: number) => {
  return { help_items: (await api.help_items.search({})).data };
};

export const publishedOrgs = async () => {
  return { published_organizations: (await api.orgPublish.getPublishedOrgs()).organizations };
};

export const loadWorkspaces = async (_features: OrganizationFeatures, _userId: number) => {
  return { workspaces: (await api.workspaces.search({})).data };
};

export const loadTimeSeries = async (_features: OrganizationFeatures, userId: number) => {
  return { time_series: _mapWithShared((await api.time_series.search({})).data, userId) };
};

export const loadStrategies = async (_features: OrganizationFeatures, _userId: number) => {
  return { strategies: (await api.strategies.search({})).data };
};

export const loadPortfolios = async (_features: OrganizationFeatures, _userId: number) => {
  return { portfolios: (await api.portfolios.search({})).data };
};

export const loadGics = async (_features: OrganizationFeatures, _userId: number) => {
  const gicsResponse = await api.assetMaster.getGics();
  return { gics_data: parseIndustries.parseGics(gicsResponse.assets) };
};

export const loadRbics = async (_features: OrganizationFeatures, _userId: number) => {
  const rbicsResponse = await api.assetMaster.getRbics();
  return { rbics_data: parseIndustries.parseRbics(rbicsResponse.assets) };
};
