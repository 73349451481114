import { hooks, mui, React, ts, ui } from '_core';

import { DraftParams } from './types';

type FormProps = {
  params: DraftParams;
  setParams: (_p: DraftParams) => void;
  context?: ts.types.signal.SignalAnalysisContext;
};

const Form: React.FC<FormProps> = ({ params, setParams, context }) => {
  const [daysBeforeEvent, setDaysBeforeEvent] = React.useState(params.days_before_event);
  const [daysAfterEvent, setDaysAfterEvent] = React.useState(params.days_after_event);
  const [getHistories, setGetHistories] = React.useState(params.get_histories ?? false);
  const [histories, setHistories] = React.useState(params.histories || Object.values(ts.enums.HISTORY_ENUM_EXTENDED));

  const uiStyles = hooks.useUiStyles();

  React.useEffect(() => {
    const data = {
      ...params,
      days_before_event: daysBeforeEvent,
      days_after_event: daysAfterEvent,
      histories: getHistories ? histories : undefined,
      get_histories: getHistories,
    };
    setParams(data);
  }, [daysBeforeEvent, daysAfterEvent, getHistories, histories]);

  return (
    <mui.core.Box sx={uiStyles.widgetEditMode}>
      <mui.core.Typography
        variant="body2"
        fontWeight="fontWeightMedium"
        sx={{
          marginBottom: '10px',
          marginTop: '5px',
        }}
      ></mui.core.Typography>

      <mui.core.Typography
        variant="body2"
        fontWeight="fontWeightMedium"
        sx={{
          marginBottom: '10px',
          marginTop: '5px',
        }}
      >
        Returns Window
      </mui.core.Typography>
      <mui.core.Grid container spacing={2}>
        <mui.core.Grid item xs={6}>
          <ui.NumericField
            textFieldProps={{
              label: 'Days Before Build Date (1 - 21)',
              fullWidth: true,
              variant: 'outlined',
              size: 'small',
              required: 'true',
            }}
            value={daysBeforeEvent}
            setValue={(v) => setDaysBeforeEvent(v)}
          />
        </mui.core.Grid>

        <mui.core.Grid item xs={6}>
          <ui.NumericField
            textFieldProps={{
              label: 'Days After Build Date (0 - 63)',
              fullWidth: true,
              variant: 'outlined',
              size: 'small',
            }}
            value={daysAfterEvent}
            setValue={(v) => setDaysAfterEvent(v)}
          />
        </mui.core.Grid>
      </mui.core.Grid>

      <mui.core.Box mt={4}>
        <mui.core.FormControlLabel
          control={
            <mui.core.Switch size="small" checked={getHistories} onChange={() => setGetHistories(!getHistories)} />
          }
          label="With Histories"
        />

        {getHistories && (
          <mui.core.Box sx={{ mt: 2 }}>
            <ui.WidgetHistories<true>
              multiple
              context={context}
              value={histories}
              setValue={setHistories}
              label="Histories (Only applied to Cumulative Returns and T-Stat"
            />
          </mui.core.Box>
        )}
      </mui.core.Box>
    </mui.core.Box>
  );
};

export default Form;
