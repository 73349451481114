import { am4charts, am4core, helpers, ts } from '_core';

import { createImageHeader } from './create-image-header';

type createLayeredChartTypes = {
  id: string;
  data: ts.types.widgets.TableData;
  fullScreen: boolean;
  exportTitle: string;

  leftTitle?: string;
  rightTitle?: string;

  xAxis: string;
  innerBar: string;
  innerBarTitle: string;
  outerBar: string;
  outerBarTitle: string;
  line: string;
  lineTitle: string;
  prepareData?: (_d: ts.types.widgets.TableData) => ts.types.widgets.TableData;
};

const createLayeredChart = ({
  id,
  data,
  fullScreen,
  exportTitle,

  leftTitle = undefined,
  rightTitle = undefined,

  xAxis,
  innerBar,
  innerBarTitle,
  outerBar,
  outerBarTitle,
  line,
  lineTitle,
  prepareData = undefined,
}: createLayeredChartTypes) => {
  let layeredData = data;
  if (prepareData) layeredData = prepareData(data);

  const chart = am4core.create(`chart-${id}-${fullScreen ? 'fs' : 'sw'}`, am4charts.XYChart);
  chart.paddingRight = 20;

  chart.data = layeredData;

  chart.logo.disabled = true;

  // Create axes
  const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
  categoryAxis.dataFields.category = xAxis;
  categoryAxis.renderer.grid.template.location = 0;
  categoryAxis.renderer.minGridDistance = 30;

  categoryAxis.renderer.labels.template.rotation = 315;
  categoryAxis.renderer.labels.template.verticalCenter = 'middle';
  categoryAxis.renderer.labels.template.horizontalCenter = 'right';
  const label = categoryAxis.renderer.labels.template;
  label.truncate = true;
  label.maxWidth = 150;

  const valueAxis1 = chart.yAxes.push(new am4charts.ValueAxis());
  valueAxis1.title.text = leftTitle;

  const valueAxis2 = chart.yAxes.push(new am4charts.ValueAxis());
  valueAxis2.title.text = rightTitle;
  valueAxis2.renderer.opposite = true;
  valueAxis2.renderer.grid.template.disabled = true;

  // Create series
  const series1 = chart.series.push(new am4charts.ColumnSeries());
  series1.dataFields.valueY = innerBar;
  series1.dataFields.categoryX = xAxis;
  series1.clustered = false;
  series1.name = innerBarTitle;
  series1.tooltipText = '{name}\n[bold]{valueY}[/]';
  series1.fill = am4core.color(helpers.getColor(1)).lighten(0.5);
  series1.strokeWidth = 0;
  series1.columns.template.width = am4core.percent(40);

  const series2 = chart.series.push(new am4charts.ColumnSeries());
  series2.dataFields.valueY = outerBar;
  series2.dataFields.categoryX = xAxis;
  series2.clustered = false;
  series2.name = outerBarTitle;
  series2.tooltipText = '{name}\n[bold]{valueY}[/]';
  series2.fill = am4core.color(helpers.getColor(1));
  series2.strokeWidth = 0;
  series2.toBack();

  const series3 = chart.series.push(new am4charts.LineSeries());
  series3.dataFields.valueY = line;
  series3.dataFields.categoryX = xAxis;
  series3.name = lineTitle;
  series3.strokeWidth = 2;
  series3.tensionX = 0.7;
  series3.yAxis = valueAxis2;
  series3.tooltipText = '{name}\n[bold]{valueY}[/]';

  const bullet3 = series3.bullets.push(new am4charts.CircleBullet());
  bullet3.circle.radius = 3;
  bullet3.circle.strokeWidth = 2;
  bullet3.circle.fill = am4core.color('#fff');

  // Add legend
  chart.legend = new am4charts.Legend();

  // Add cursor
  chart.cursor = new am4charts.XYCursor();

  // Export
  chart.exporting.menu = new am4core.ExportMenu();
  chart.exporting.menu.align = 'left';
  chart.fontSize = 11;

  chart.exporting.menu.items = [
    {
      label: '...',
      menu: [
        { type: 'jpg', label: 'JPG' },
        { type: 'png', label: 'PNG' },
        { type: 'csv', label: 'CSV' },
      ],
    },
  ];
  chart.exporting.filePrefix = 'chart-data';

  createImageHeader({ chart, title: exportTitle, fullScreen, id });

  return chart;
};

export default createLayeredChart;
