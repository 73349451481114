import { _, helpers, mui, React, ts, useSelector } from '_core';

type InteractionFormProps<
  T extends {
    chartType: 'top' | 'rbics';
    parent?: keyof ts.StoreState['resources']['rbics_data'];
    selectedRbics?: ts.types.industries.IndustryData[];
  },
> = {
  params: T;
  setParams: (_p: T) => void;
  fullScreen: boolean;
};

const InteractionForm = <
  T extends {
    chartType: 'top' | 'rbics';
    parent?: keyof ts.StoreState['resources']['rbics_data'];
    selectedRbics?: ts.types.industries.IndustryData[];
  },
>({
  params,
  setParams,
  fullScreen,
}: InteractionFormProps<T>): React.ReactElement => {
  const resources = useSelector((state) => state.resources);

  const getLabel = (val: T['parent']) => {
    if (val.includes('levelTwo')) return 'Sub Sector';
    return 'Industry';
  };

  return (
    <mui.core.Box p={3}>
      <mui.core.Grid container spacing={3}>
        <mui.core.Grid item xs={12} md={4}>
          <mui.core.Box>
            <mui.core.TextField
              select
              required
              size="small"
              label="Type"
              variant="outlined"
              value={params.chartType}
              onChange={(e) =>
                setParams({
                  ...params,
                  chartType: e.target.value as 'top' | 'rbics',
                })
              }
              fullWidth
            >
              <mui.core.MenuItem value="top">Show Top 20</mui.core.MenuItem>
              <mui.core.MenuItem value="rbics">Select {getLabel(params.parent)}</mui.core.MenuItem>
            </mui.core.TextField>
          </mui.core.Box>
        </mui.core.Grid>
        {params.chartType == 'rbics' && (
          <mui.core.Grid item xs={12} md={8}>
            <mui.core.Box>
              <mui.core.Autocomplete
                fullWidth
                limitTags={fullScreen ? 6 : 3}
                size="small"
                options={_.sortBy(resources.rbics_data[params.parent], 'value')}
                value={params.selectedRbics?.[0]}
                onChange={(e, val) => {
                  setParams({
                    ...params,
                    selectedRbics: val ? [val as ts.types.industries.IndustryData] : [],
                  });
                }}
                disableCloseOnSelect
                getOptionLabel={(option) => helpers.parseIndustries
                  .generateIndustryName(option as ts.types.industries.IndustryData)}
                isOptionEqualToValue={(option, val) => option?.value == val?.value}
                renderInput={(p) => (
                  <mui.core.TextField {...p} label={getLabel(params.parent)} variant="outlined" size="small" />
                )}
                renderTags={(tagValue, getTagProps) =>
                  tagValue.map((option, index) => (
                    <mui.core.Chip
                      key={option.name}
                      label={option ? option.shortName || option.name : 'value not in config'}
                      {...getTagProps({ index })}
                    />
                  ))
                }
              />
            </mui.core.Box>
          </mui.core.Grid>
        )}
      </mui.core.Grid>
    </mui.core.Box>
  );
};

export default InteractionForm;
