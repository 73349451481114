import { _, hooks, mui, React, ui } from '_core';

import { DraftParams } from './types';

type FormProps = {
  params: DraftParams;
  setParams: (_p: DraftParams) => void;
};

const Form: React.FC<FormProps> = ({ params, setParams }) => {
  const [withDropValue, setWithDropValue] = React.useState(!_.isNil(params.drop_values_under));
  const [dropValuesUnder, setDropValuesUnder] = React.useState(params.drop_values_under);

  const uiStyles = hooks.useUiStyles();

  React.useEffect(() => {
    if (!withDropValue && _.isNil(dropValuesUnder)) setDropValuesUnder(1e-10);
  }, [withDropValue]);

  React.useEffect(() => {
    const data = {
      ...params,
      drop_values_under: withDropValue ? dropValuesUnder : undefined,
    };

    setParams(data);
  }, [dropValuesUnder, withDropValue]);

  return (
    <mui.core.Box sx={uiStyles.widgetEditMode}>
      <mui.core.Box mb={2}>
        <mui.core.FormControlLabel
          control={
            <mui.core.Switch size="small" checked={withDropValue} onChange={() => setWithDropValue(!withDropValue)} />
          }
          label={'Drop values under threshold'}
        />
      </mui.core.Box>

      {withDropValue && (
        <ui.NumericField
          textFieldProps={{
            label: 'Drop Zeroes in Signal Portfolio',
            fullWidth: true,
            variant: 'outlined',
            size: 'small',
          }}
          showExponentialLabel
          value={Number(dropValuesUnder)}
          setValue={(v) => setDropValuesUnder(v)}
        />
      )}
    </mui.core.Box>
  );
};

export default Form;
