import { React, ts } from '_core';

import Chart from './chart';
import Form from './form';
import { DraftParams, Params } from './types';

type BacktestTabularOutputProps = {
  id: string;
  readOnly: boolean;
  widgetKey: string;
  title: string;
  subtitle?: string;
  mode: ts.enums.CHART_MODE_ENUM;
  params: Params;
  setParams: (_p: DraftParams) => void;
  widgetPaths?: Record<'backtest_annualized_statistics', string>;
  setExternalEmbed: (_json: ts.types.analysis.EmbedChartData) => void;

  loadWidgetData: (_payload: ts.types.widgets.WidgetGetDataParams) => Promise<ts.types.widgets.WidgetGetDataResponse>;
};

const BacktestTabularOutput: React.FC<BacktestTabularOutputProps> = ({
  id,
  readOnly,
  widgetKey,
  title,
  subtitle,
  loadWidgetData,
  widgetPaths,
  mode,
  params,
  setParams,
  setExternalEmbed,
}) => {
  const renderJobComponent = () => (
    <Chart
      id={id}
      readOnly={readOnly}
      widgetKey={widgetKey}
      title={title}
      subtitle={subtitle}
      loadWidgetData={loadWidgetData}
      file={widgetPaths['backtest_annualized_statistics']}
      params={params}
      setParams={setParams}
      setExternalEmbed={setExternalEmbed}
    />
  );

  return <>{mode == 'edit' ? <Form params={params} setParams={setParams} /> : <>{renderJobComponent()}</>}</>;
};

export default BacktestTabularOutput;
