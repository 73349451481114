import { _, hooks, mui, React, ts, ui } from '_core';

import Full from './full';
import Small from '../small-table';
import { GenerateHeatMappedColumns } from '../tabular-context';

type TabularProps<SHEETS extends string> = {
  // Sheet name -> DataRow
  data: Record<SHEETS, ts.types.widgets.TableData>;
  // Get Columns -> DataRow
  getColumns: Record<string, ts.types.components.dataGrid.GenerateColumns>;

  sheetTitles?: Record<SHEETS, string>;
  sheetInfo?: Record<SHEETS, React.ReactElement>;
  fullScreen?: boolean;
  customError?: ts.types.common.Alert;
  apiError?: ts.types.common.ApiError;
  // To handle go fullscreen we keep track of tab to open via query params
  goFullScreen?: (_queryParams: string) => void;
  queryParams?: { initial?: string };

  currentSheet?: SHEETS;
  setCurrentSheet?: (_sheet: SHEETS) => void;

  loading?: boolean;
  rowClass?: (_row: Record<SHEETS, any>) => string;

  customToolbarBySheet?: Record<SHEETS, () => React.ReactElement>;
  alwaysShowBottom?: boolean;
  hideFilters?: boolean;
  hideDensity?: boolean;
  hideToolbar?: boolean;

  groupBy?: Record<SHEETS, string[]>;
  expandedGroupIds?: Record<SHEETS, ReadonlySet<unknown>>;
  setExpandedGroupIds?: Record<SHEETS, (_v: ReadonlySet<unknown>) => void>;

  overrideHeight?: number;

  preferences?: {
    preferencesSourceWidgetId: string;
    preferenceKey: ts.enums.PREFERENCES_KEY_ENUM;
    preferenceTab: ts.enums.PREFERENCES_INNER_TABS_ENUM;
    openPreferencesBySheet?: Record<SHEETS, () => void>;
  };

  tableParams?: Record<string, ts.types.components.dataGrid.TableParams>;
  setTableParams?: Record<SHEETS, (_v: ts.types.components.dataGrid.TableParams) => void>;
  getHeatMappedCols?: Record<string, GenerateHeatMappedColumns>;
  heatMapColors?: Record<string, ts.types.components.dataGrid.HeatMapColors>;
  setExternalEmbed?: (_json: Record<string, ts.types.analysis.SyncTableEmbedMetadata>) => void;
  triggerWidthChange?: boolean;
  readOnly?: boolean;
};

const Tabular = <T extends string>({
  data,
  getColumns,
  sheetTitles,
  sheetInfo,
  fullScreen,
  customError,
  apiError,
  goFullScreen,
  queryParams,
  loading,
  rowClass,
  customToolbarBySheet,
  hideFilters,
  hideDensity,
  hideToolbar,
  alwaysShowBottom,

  preferences,

  groupBy,
  expandedGroupIds,
  setExpandedGroupIds,
  overrideHeight,

  currentSheet,
  setCurrentSheet,

  tableParams,
  setTableParams,
  getHeatMappedCols,
  heatMapColors,
  triggerWidthChange,

  setExternalEmbed,
  readOnly,
}: TabularProps<T>): React.ReactElement => {
  const uiStyles = hooks.useUiStyles();
  const initialTab = (queryParams?.initial || Object.keys(getColumns)[0]) as T;
  const [cmpCurrentSheet, setCmpCurrentSheet] = React.useState(
    currentSheet && data?.[currentSheet] ? currentSheet : initialTab
  );

  hooks.useEffectWithoutFirst(() => {
    if (setCurrentSheet) setCurrentSheet(cmpCurrentSheet);
  }, [cmpCurrentSheet]);

  if (apiError && !customError) {
    return (
      <mui.core.Box sx={uiStyles.widgetContent}>
        <ui.ChartError error={apiError} readOnly={readOnly} />
      </mui.core.Box>
    );
  }

  if (_.isNil(data) || _.isEmpty(getColumns)) {
    return (
      <mui.core.Box sx={uiStyles.widgetContent}>
        <ui.CenteredLoader label="Loading widget data..." top="20px" />
      </mui.core.Box>
    );
  }

  return (
    <>
      {fullScreen ? (
        <Full
          data={data}
          getColumns={getColumns}
          sheetTitles={sheetTitles}
          sheetInfo={sheetInfo}
          // Adding the ?? to prevent the component from crashing when the data is not loaded
          // and the columns are dynamically generated from the data
          currentSheet={cmpCurrentSheet ?? initialTab}
          setCurrentSheet={setCmpCurrentSheet as any}
          customToolbarBySheet={customToolbarBySheet}
          openPreferencesBySheet={preferences?.openPreferencesBySheet}
          loading={loading}
          rowClass={rowClass}
          customError={customError}
          hideFilters={hideFilters}
          hideDensity={hideDensity}
          alwaysShowBottom={alwaysShowBottom}
          groupBy={groupBy}
          overrideHeight={overrideHeight}
          expandedGroupIds={expandedGroupIds}
          setExpandedGroupIds={setExpandedGroupIds}
          preferenceKey={preferences?.preferenceKey}
          preferenceTab={preferences?.preferenceTab}
          preferencesSourceWidgetId={preferences?.preferencesSourceWidgetId}
          tableParams={tableParams}
          setTableParams={setTableParams}
          getHeatMappedCols={getHeatMappedCols}
          heatMapColors={heatMapColors}
          hideToolbar={hideToolbar}
          setExternalEmbed={setExternalEmbed}
          triggerWidthChange={triggerWidthChange}
        />
      ) : (
        <Small
          sheetKeys={Object.keys(data)}
          sheetTitles={sheetTitles}
          sheetInfo={sheetInfo}
          goFullScreen={goFullScreen}
        />
      )}
    </>
  );
};

Tabular.defaultProps = {
  queryParams: {} as Record<string, undefined>,
  sheetTitles: {},
  sheetInfo: {},
  customToolbarBySheet: {} as Record<string, () => React.ReactElement>,
  alwaysShowBottom: false,
  loadingPreferences: false,
  tableParams: {},
  setTableParams: () => undefined as any,
  goFullScreen: () => undefined as any,
};

export default Tabular;
