import React from 'react';

import * as enums from '../../enums';
import { resourcesUtils } from '../../helpers';
import { _, mui, useHistory, useSelector } from '../../libs';
import * as types from '../../types';

interface ResourceContextMenuProps {
  onClose: () => void;
  option: types.common.Resource;
}

const ResourceContextMenu = ({ onClose, option }: ResourceContextMenuProps) => {
  const [isAdding, setIsAdding] = React.useState(false);
  const history = useHistory();
  const resourceType = enums.MAP_RESOURCE_WITH_TABLE[option.resourceType as enums.RESOURCES_TYPES_ENUM];
  const workspaces = useSelector((state: types.BaseStore) => state.resources.workspaces as types.workspace.Workspace[]);
  const currentWorkspaceId = useSelector((state: types.BaseStore) => state.ui.currentWorkspaceId);
  const currentWorkspace = workspaces.find((w) => w.id == currentWorkspaceId);

  const partOfWorkspace = React.useMemo(() => {
    if (!currentWorkspace) return false;
    return _.get(currentWorkspace, `${option.resourceType}_ids`, []).includes(option.id);
  }, [currentWorkspace, resourceType, option.id]);

  const handleGoToDefinition = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (option) {
      history.push(`/${resourceType}/${option.id}`);
    }
    onClose();
  };

  const handleGoToDefinitionNewTab = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (option) {
      window.open(`/${resourceType}/${option.id}`, '_blank');
    }
    onClose();
  };

  const handleAddToWorkspace = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (option) {
      setIsAdding(true);
      try {
        await resourcesUtils.addToWorkspace(
          option.id,
          option.resourceType as unknown as enums.UI_RESOURCE_TYPE_ENUM,
          currentWorkspace
        );
      } finally {
        setIsAdding(false);
        onClose();
      }
    }
  };

  return (
    <mui.core.Stack flexDirection="row" gap="2" mr={3}>
      <mui.core.Tooltip title="Open" placement="bottom" arrow>
        <mui.core.IconButton onClick={handleGoToDefinition} size="small">
          <mui.icons.ArrowForward sx={{ fontSize: '1rem' }} />
        </mui.core.IconButton>
      </mui.core.Tooltip>
      <mui.core.Tooltip title="Open in new tab" placement="bottom" arrow>
        <mui.core.IconButton onClick={handleGoToDefinitionNewTab} size="small">
          <mui.icons.OpenInNew sx={{ fontSize: '1rem' }} />
        </mui.core.IconButton>
      </mui.core.Tooltip>
      <mui.core.Tooltip title={isAdding ? 'Adding to workspace...' : 'Add to workspace'} placement="bottom" arrow>
        <mui.core.IconButton
          onClick={handleAddToWorkspace}
          disabled={!currentWorkspace || partOfWorkspace || isAdding}
          size="small"
        >
          {isAdding ? <mui.core.CircularProgress size={16} /> : <mui.icons.PlaylistAdd sx={{ fontSize: '1rem' }} />}
        </mui.core.IconButton>
      </mui.core.Tooltip>
    </mui.core.Stack>
  );
};

export default ResourceContextMenu;
