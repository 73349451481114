import { _, React, ts } from '_core';

import { percentColumns, transform } from './helpers';
import { Params } from './types';
import ChartWrapper from '../../common/chart-wrapper';
import createScatterChart from '../../common/charts/create-scatter-chart';

type ChartProps = {
  id: string;
  readOnly: boolean;
  widgetKey: string;
  loadWidgetData: (_payload: ts.types.widgets.WidgetGetDataParams) => Promise<ts.types.widgets.WidgetGetDataResponse>;
  summary: string;
  params: Params;
  fullScreen: boolean;
  title: string;
  formOptions: { key: string; label: string }[];
};

const Chart: React.FC<ChartProps> = ({
  id,
  readOnly,
  widgetKey,
  loadWidgetData,
  summary,
  fullScreen,
  params,
  title,
  formOptions,
}) => {
  const [data, setData] = React.useState<ts.types.widgets.TableData>(null);
  const [error, setError] = React.useState<ts.types.common.ApiError>(null);

  const loadData = async () => {
    try {
      const response = await loadWidgetData({
        data: {
          file: summary,
        },
      });

      if (response) {
        setData(response.data);
      }
    } catch (err) {
      setError(err as ts.types.common.ApiError);
    }
  };

  React.useEffect(() => {
    if (summary) loadData();
    return () => setData(null);
  }, [summary]);

  React.useEffect(() => {
    if (!_.isNil(data)) {
      const currField = formOptions.find((opt) => opt.key == params.selectedParam);
      let tooltip = '';

      tooltip += `[Bold]${currField.label}:[/] {${currField.key}} \n`;
      if (percentColumns.includes(params.metric)) tooltip += `[Bold]Metric:[/] {${params.metric}_percent}%`;
      else tooltip += `[Bold]Metric:[/] {${params.metric}}`;

      createScatterChart({
        id,
        data: data,
        fullScreen,
        xAxis: currField.key,
        yAxis: params.metric,
        lineSeriesName: _.startCase(params.selectedParam),
        tooltip,
        yAxisFormatter: percentColumns.includes(params.metric) ? '#.##%' : null,
        exportTitle: title,
        prepareData: (d) => transform(d, params.metric),
      });
    }
  }, [data]);

  return (
    <ChartWrapper id={id} readOnly={readOnly} widgetKey={widgetKey} data={data} error={error} fullScreen={fullScreen} />
  );
};

export default Chart;
