import { _, mui, React, ts } from '_core';

import { transform } from './helpers';
import { Params } from './types';
import ChartWrapper from '../../common/chart-wrapper';
import createNestedBarChart from '../../common/charts/create-nested-bar-chart';
import { prepareBarChartBucketedNames, prepareBarChartCountNames } from '../../common/utils/get-series-name';

type ChartProps = {
  id: string;
  readOnly: boolean;
  widgetKey: string;
  loadWidgetData: (_payload: ts.types.widgets.WidgetGetDataParams) => Promise<ts.types.widgets.WidgetGetDataResponse>;
  returns: string;
  fullScreen: boolean;
  params: Params;
  title: string;
};

const Chart: React.FC<ChartProps> = ({
  id,
  readOnly,
  widgetKey,
  loadWidgetData,
  returns,
  fullScreen,
  params,
  title,
}) => {
  const [data, setData] = React.useState<ts.types.widgets.TableData>(null);
  const [error, setError] = React.useState<ts.types.common.ApiError>(null);
  const [showLegend, setShowLegend] = React.useState(false);
  const [selectedTab, setSelectedTab] = React.useState<'performance' | 'count' | 'volatility' | 'information_ratio'>(
    'performance'
  );

  const loadData = async (tab: 'performance' | 'count' | 'volatility' | 'information_ratio') => {
    try {
      const response = await loadWidgetData({
        data: {
          file: returns,
          query: ['tab', 'equals', tab],
        },
      });

      if (response) {
        setData(response.data);
      }
    } catch (err) {
      setError(err as ts.types.common.ApiError);
    }
  };

  React.useEffect(() => {
    if (returns) loadData(selectedTab);
    return () => setData(null);
  }, [returns, selectedTab]);

  const getTabName = (t = selectedTab) => {
    if (t == 'count') return 'Avg Count';
    return _.startCase(t);
  };

  const getTabs = () => {
    return (
      <mui.core.Tabs
        indicatorColor="primary"
        textColor="primary"
        value={selectedTab}
        onChange={(e, newTab) => setSelectedTab(newTab)}
        aria-label="Tabs"
      >
        {['performance', 'count', 'volatility', 'information_ratio'].map((tab) => (
          <mui.core.Tab key={tab} label={getTabName(tab as any)} value={tab} />
        ))}
      </mui.core.Tabs>
    );
  };

  React.useEffect(() => {
    // Create line chart on data and scale changes
    if (!_.isNil(data)) {
      const localData = transform(data);

      createNestedBarChart({
        id,
        data: localData,
        fullScreen,
        setShowLegend,
        format: selectedTab == 'performance' || selectedTab == 'volatility' ? '#.##%' : '#.##',
        exportTitle: title,
        prepareData: (nestedBarData) => ({
          data: prepareBarChartBucketedNames(
            nestedBarData.data,
            params.discrete ? ts.enums.BUCKET_TYPES_ENUM.FIXED_FLOAT : ts.enums.BUCKET_TYPES_ENUM.QUANTILES,
            params.quantiles
          ),
          count: prepareBarChartCountNames(
            nestedBarData.count,
            params.discrete ? ts.enums.BUCKET_TYPES_ENUM.FIXED_FLOAT : ts.enums.BUCKET_TYPES_ENUM.QUANTILES,
            params.quantiles
          ),
        }),
      });
    }
  }, [data]);

  return (
    <>
      <ChartWrapper
        id={id}
        readOnly={readOnly}
        widgetKey={widgetKey}
        data={data}
        showLegend={showLegend}
        warning={data?.length === 0 ? ['Empty File'] : []}
        error={error}
        fullScreen={fullScreen}
        tabs={getTabs()}
      />
    </>
  );
};

export default Chart;
