import React from 'react';

import CenteredLoader from './centered-loader';
import OfflineModal from './offline-modal';
import config from '../../config.local';
import ContextSingleton from '../__singletons__/context-singleton';
import { useInactivityCheck } from '../hooks';
import { ungzip, useDispatch, useSelector } from '../libs';
import { BaseStore } from '../types';

/**
 * Component that maintains a websocket connection to web-api and handles real-time updates
 * 
 * @requires Redux Store:
 * - state.auth.authToken: Authentication token for websocket connection
 * 
 * @requires Redux Store Action:
 * - websocket.receiveMessage: Action to dispatch incoming websocket messages
 * 
 * @param children React.ReactNode - Children to render within the websocket component
 * @returns Rendered component with websocket connection management
 */
const Websocket: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const actions = ContextSingleton.getInstance().actions;

  const { authToken } = useSelector((state: BaseStore) => state.auth);
  const activityStatus = useInactivityCheck(1000 * 60 * 60 * 5);

  React.useEffect(() => {
    if (activityStatus === 'inactive') {
      websocketRef.current?.close(1000, 'invalid');
    }
  }, [activityStatus]);

  const dispatch = useDispatch();

  const websocketRef = React.useRef<WebSocket>();
  const timerRef = React.useRef<NodeJS.Timeout>();
  const pingTimeoutRef = React.useRef<NodeJS.Timeout>();
  const [connEstablished, setConnEstablished] = React.useState<
    'invalid' | 'lost' | 'closed' | 'connected' | 'mounting'
  >('mounting');

  const log = (msg: any) => {
    if (config.environment === 'local') console.log(msg);
    return;
  };

  const endpointUrl = React.useMemo(() => {
    if (config.environment === 'production') return 'websocket.finsera.com';
    if (config.environment === 'staging') return 'websocket.finserastg.net';
    if (config.environment === 'development') return 'websocket.finseradev.net';
    const dom = process.env.FINSERA_PROXY_API.split('//')[1].split('/')[0].split('.').slice(1).join('.');
    return `websocket.${dom}`;
  }, []);

  const base64ToBytes = (base64String: string) => {
    const binaryString = atob(base64String);
    const byteArray = new Uint8Array(binaryString.length);

    for (let i = 0; i < binaryString.length; i++) {
      byteArray[i] = binaryString.charCodeAt(i);
    }

    return byteArray;
  };

  const onmessage = async (msg: MessageEvent) => {
    const binary = base64ToBytes(msg.data as string);
    const decompressedData = ungzip(binary, { to: 'string' });
    const data = JSON.parse(decompressedData);

    dispatch(actions.websocket.receiveMessage(data));
    if (data?.type === 'pong') {
      // Clear ping timeout since pong was received
      if (pingTimeoutRef.current) clearTimeout(pingTimeoutRef.current);
    }

    if (data?.type === 'invalid') {
      // Clear ping timeout since response was received
      if (pingTimeoutRef.current) clearTimeout(pingTimeoutRef.current);
      websocketRef.current?.close(1000, 'invalid');
    }
  };

  const onclose = async (e: CloseEvent) => {
    log('Closing the socket');

    if (e.reason === 'unmount') {
      setConnEstablished('closed');
      return;
    }

    if (e.reason === 'invalid') {
      setConnEstablished('invalid');
      return;
    }

    log('Restarting websocket connection');
    setConnEstablished('lost');

    if (timerRef.current) clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => setNewWebsocket(), 1_000);
  };

  const onopen = async () => {
    log('Connection opened');
    setConnEstablished('connected');

    // Send ping every 60 seconds and expect a pong
    timerRef.current = setInterval(() => {
      if (websocketRef.current?.readyState === WebSocket.OPEN) {
        websocketRef.current.send(JSON.stringify({ type: 'ping' }));

        // Set timeout to detect missing pong
        pingTimeoutRef.current = setTimeout(() => {
          log('No pong received, reconnecting...');
          websocketRef.current?.close(1000, 'lost');
        }, 5_000); // 5 seconds timeout for pong response
      }
    }, 60_000);
  };

  const setNewWebsocket = async () => {
    try {
      const client = new WebSocket(`wss://${endpointUrl}/?token=${authToken}`);

      client.onopen = onopen;
      client.onclose = onclose;
      client.onmessage = onmessage;

      websocketRef.current = client;
    } catch {
      log('Unable to open the connection');
      setConnEstablished('lost');

      if (timerRef.current) clearTimeout(timerRef.current);
      timerRef.current = setTimeout(() => setNewWebsocket(), 1_000);
    }
  };

  React.useEffect(() => {
    setNewWebsocket();
    return () => {
      if (timerRef.current) clearInterval(timerRef.current);
      if (pingTimeoutRef.current) clearTimeout(pingTimeoutRef.current);
      websocketRef.current?.close(1000, 'unmount');
    };
  }, [authToken]);

  if (connEstablished == 'mounting') return <CenteredLoader />;
  return (
    <>
      {connEstablished === 'lost' && <OfflineModal />}
      {connEstablished === 'invalid' && <OfflineModal needReload />}
      {children}
    </>
  );
};

export default Websocket;
