import { am4charts, am4core } from '_core';

import * as utils from '../utils';

export const createRanges = (
  chart: am4charts.XYChart,
  endValueAxisY: am4charts.ValueAxis,
  diff: number,
  percent: boolean,
  integerOnly: boolean
) => {
  endValueAxisY.axisRanges.clear();

  chart.series.each((series) => {
    if (!series.isHidden) {
      const range = endValueAxisY.axisRanges.create();
      const rangeValue = series.data.filter((x) => x?.value).at(-1)?.value;

      range.value = rangeValue;
      range.grid.stroke = am4core.color('396478');
      range.grid.strokeOpacity = 0;
      range.label.text = utils.formatNumberToStringLabel(diff, percent, integerOnly, range.value);
      range.label.opacity = 1;
      range.label.zIndex = Infinity;
      range.label.background.fill = series.stroke;
      range.label.fill = am4core.color('fff');
      range.label.padding(2, 2, 2, 2);
    }
  });
};
