import { _, helpers,ts } from '_core';

// prepareSummaryData
export const transform = (data: ts.types.widgets.TableData) => {
  const integerRows = ['Long Positions', 'Short Positions', 'Total Positions'];

  const percentRows = ['Risk', 'Factor Exposures'];

  const dollarRows = ['Long Value (USD)', 'Short Value (USD)'];

  const percentFormatter = (idx: number, field: string) =>
    helpers.tableFormatters._formatFloat({
      value: data[idx][field] as string,
      roundDigits: 2,
      commasOnThousands: true,
      suffix: '%',
      multiplier: 100,
    });

  const decimalFormatter = (idx: number, field: string) =>
    helpers.tableFormatters._formatFloat({
      value: data[idx][field] as string,
      roundDigits: 3,
      commasOnThousands: true,
    });

  const dollarFormatter = (idx: number, field: string) =>
    helpers.tableFormatters._formatInteger({
      value: data[idx][field] as string,
      commasOnThousands: true,
      numericPrefix: '$ ',
    });

  const integerFormatter = (idx: number, field: string) =>
    helpers.tableFormatters._formatInteger({
      value: data[idx][field] as string,
      commasOnThousands: true,
    });

  data.forEach((_v, idx) => {
    if (percentRows.includes(data[idx].metric as string)) {
      data[idx].optimal = percentFormatter(idx, 'optimal') as any;
      data[idx].initial = percentFormatter(idx, 'initial') as any;
    } else if (integerRows.includes(data[idx].metric as string)) {
      data[idx].optimal = integerFormatter(idx, 'optimal') as any;
      data[idx].initial = integerFormatter(idx, 'initial') as any;
    } else if (dollarRows.includes(data[idx].metric as string)) {
      data[idx].optimal = dollarFormatter(idx, 'optimal') as any;
      data[idx].initial = dollarFormatter(idx, 'initial') as any;
    } else {
      data[idx].optimal = decimalFormatter(idx, 'optimal') as any;
      data[idx].initial = decimalFormatter(idx, 'initial') as any;
    }
  });

  return data;
};

const getBucketLabel = (label: string) => {
  const splittedLabel = label.split(':');
  const bucketType = splittedLabel[0] as ts.enums.BUCKET_TYPES_ENUM;

  if (bucketType == ts.enums.BUCKET_TYPES_ENUM.INDUSTRY) {
    const map = { ...ts.enums.MAPPED_RBICS_NAME };
    return map[splittedLabel[1] as keyof typeof map];
  }

  if (bucketType == ts.enums.BUCKET_TYPES_ENUM.ASSET_MASTER || bucketType == ts.enums.BUCKET_TYPES_ENUM.RISK_MODEL)
    return _.startCase(splittedLabel[1]);

  return splittedLabel.slice(1).join(':');
};

export const prepareExposureData = (data: ts.types.widgets.TableData, bucketName = 'label') => {
  return data.map((d) => ({
    ...d,
    [bucketName]: getBucketLabel(d[bucketName] as string),
  }));
};
