import { _, React, ts } from '_core';

import ChartWrapper from '../../common/chart-wrapper';
import createNestedBarChart from '../../common/charts/create-nested-bar-chart';

type ChartProps = {
  id: string;
  readOnly: boolean;
  widgetKey: string;
  file: string;
  factors: string[];
  fullScreen: boolean;
  title: string;
  loadWidgetData: (_payload: ts.types.widgets.WidgetGetDataParams) => Promise<ts.types.widgets.WidgetGetDataResponse>;
};

const Chart: React.FC<ChartProps> = ({
  id,
  loadWidgetData,
  readOnly,
  widgetKey,
  file,
  factors,
  fullScreen,
  title,
}): React.ReactElement => {
  const [data, setData] = React.useState<ts.types.widgets.TableData>(null);
  const [filteredData, setFilteredData] = React.useState<ts.types.widgets.TableData>(null);
  const [error, setError] = React.useState(null);
  const [showLegend, setShowLegend] = React.useState(false);

  const loadData = async () => {
    try {
      const response = await loadWidgetData({
        data: {
          file: file,
        },
      });

      if (response) setData(response.data);
    } catch (err) {
      setError(JSON.stringify(err));
    }
  };

  React.useEffect(() => {
    if (file) loadData();
    return () => setData(null);
  }, [file]);

  React.useEffect(() => {
    if (!_.isNil(data)) {
      if (!factors.includes('risk_model')) {
        setFilteredData(
          data.filter((el) => factors.includes(el.category as string) || factors.includes(el.type as string))
        );

        return;
      }

      setFilteredData(data);
    }
  }, [data]);

  React.useEffect(() => {
    const format = '#.##%';

    if (!_.isNil(filteredData)) {
      createNestedBarChart({
        id,
        data: filteredData,
        fullScreen,
        setShowLegend,
        format,
        rotatedLabels: true,
        yAxisLabel: 'Percent Significance',
        exportTitle: title,
      });
    }
  }, [filteredData]);

  return (
    <ChartWrapper
      id={id}
      readOnly={readOnly}
      widgetKey={widgetKey}
      data={data}
      showLegend={showLegend}
      error={error}
      fullScreen={fullScreen}
    />
  );
};

Chart.defaultProps = {
  factors: [],
};

export default Chart;
