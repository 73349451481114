import { _, am4charts, am4core, helpers, ts } from '_core';

import { createImageHeader } from './create-image-header';

type CreateHeatMapChartProps = {
  id: string;
  data: ts.types.widgets.HeatMapData;
  fullScreen: boolean;
  exportTitle: string;
  yAxis: string;
  xAxis: string;
  rotateLabels?: boolean;
  yAxisLabel?: string;
  xAxisLabel?: string;
  format?: string;
  headDataField?: string;
  showHeatLegend?: boolean;
  minColor?: string;
  maxColor?: string;
  labelValues?: boolean;
  prepareData?: (_d: ts.types.widgets.HeatMapData) => ts.types.widgets.HeatMapData;
};

const lColor = '#FF0000';
const mColor = '#7F7F00';
const rColor = '#00FF00';

const createHeatMapChart = ({
  id,
  data,
  fullScreen,
  yAxis,
  xAxis,
  exportTitle,
  format = '#.####',
  rotateLabels = false,
  yAxisLabel = null,
  xAxisLabel = null,
  showHeatLegend = true,
  labelValues = false,
  prepareData = undefined,
}: CreateHeatMapChartProps) => {
  let heatMapData = data;
  if (prepareData) heatMapData = prepareData(data);

  const chart = am4core.create(`chart-${id}-${fullScreen ? 'fs' : 'sw'}`, am4charts.XYChart);
  const { heatMinValue, heatMaxValue } = helpers.getHeatColorBoundaries(heatMapData);

  const minColor = heatMinValue == 0 ? mColor : lColor;
  const maxColor = heatMaxValue == 0 ? mColor : rColor;

  chart.data = heatMapData;

  chart.maskBullets = false;
  chart.logo.disabled = true;
  chart.paddingRight = 20;

  chart.scrollbarX = new am4core.Scrollbar();
  chart.scrollbarY = new am4core.Scrollbar();

  const xCategory = chart.xAxes.push(new am4charts.CategoryAxis());
  const yCategory = chart.yAxes.push(new am4charts.CategoryAxis());

  xCategory.title.text = xAxisLabel ? `[font-size: 11px]${xAxisLabel}[/]` : undefined;
  yCategory.title.text = yAxisLabel ? `[font-size: 11px]${yAxisLabel}[/]` : undefined;

  xCategory.dataFields.category = xAxis;
  yCategory.dataFields.category = yAxis;

  xCategory.renderer.grid.template.disabled = true;
  xCategory.renderer.minGridDistance = 40;
  xCategory.fontSize = 10;
  chart.numberFormatter.numberFormat = format;

  if (rotateLabels) {
    xCategory.renderer.labels.template.rotation = 45;
    xCategory.renderer.labels.template.verticalCenter = 'middle';
    xCategory.renderer.labels.template.horizontalCenter = 'left';
    const label = xCategory.renderer.labels.template;
    label.truncate = true;
    label.maxWidth = 100;
  }

  yCategory.renderer.grid.template.disabled = true;
  yCategory.renderer.inversed = true;
  yCategory.renderer.minGridDistance = 10;
  yCategory.fontSize = 10;

  const series = chart.series.push(new am4charts.ColumnSeries());
  series.dataFields.categoryX = xAxis;
  series.dataFields.categoryY = yAxis;
  series.dataFields.value = 'value';
  series.dataFields.valueX = 'heatValue';
  series.sequencedInterpolation = true;
  series.defaultState.transitionDuration = 3000;
  series.tooltip.fontSize = 11;

  const columnTemplate = series.columns.template;
  columnTemplate.strokeWidth = 2;
  columnTemplate.strokeOpacity = 1;
  columnTemplate.stroke = am4core.color('#ffffff');
  columnTemplate.tooltipText = `{${xAxis}}, {${yAxis}}: {value}`;
  columnTemplate.width = am4core.percent(100);
  columnTemplate.height = am4core.percent(100);

  series.heatRules.push({
    target: columnTemplate,
    property: 'fill',
    dataField: _.isEmpty(heatMapData) ? '' : 'heatValue' in heatMapData[0] ? 'valueX' : 'value',
    minValue: heatMinValue,
    maxValue: heatMaxValue,
    min: am4core.color(minColor),
    max: am4core.color(maxColor),
  });

  // heat legend
  const heatLegend = chart.bottomAxesContainer.createChild(am4charts.HeatLegend);
  heatLegend.width = am4core.percent(100);
  heatLegend.marginTop = 10;
  heatLegend.series = series;
  heatLegend.valueAxis.renderer.labels.template.fontSize = 9;
  heatLegend.valueAxis.renderer.minGridDistance = 30;
  heatLegend.valueAxis.tooltip.fontSize = 11;
  heatLegend.valueAxis.tooltip.background.fill = am4core.color('#3C5688');
  heatLegend.valueAxis.tooltip.background.cornerRadius = 3;
  heatLegend.valueAxis.tooltip.background.strokeWidth = 0;
  heatLegend.minValue = heatMinValue;
  heatLegend.maxValue = heatMaxValue;
  heatLegend.visible = showHeatLegend;

  // heat legend behavior
  series.columns.template.events.on('over', (event) => {
    handleHover(event.target);
  });

  series.columns.template.events.on('hit', (event) => {
    handleHover(event.target);
  });

  if (labelValues) {
    const bullet = series.bullets.push(new am4charts.LabelBullet());
    bullet.label.text = '{value}';
    bullet.label.fill = am4core.color('#fff');
    bullet.label.fontSize = '0.9rem';
    bullet.zIndex = 1;
    bullet.interactionsEnabled = false;
  }

  const handleHover = (column: any) => {
    if (!isNaN(column.dataItem.value)) {
      heatLegend.valueAxis.showTooltipAt(column.dataItem.value);
    } else {
      heatLegend.valueAxis.hideTooltip();
    }
  };

  series.columns.template.events.on('out', (_event) => {
    heatLegend.valueAxis.hideTooltip();
  });

  chart.exporting.menu = new am4core.ExportMenu();
  chart.exporting.menu.align = 'left';
  chart.fontSize = 11;

  chart.exporting.menu.items = [
    {
      label: '...',
      menu: [
        { type: 'jpg', label: 'JPG' },
        { type: 'png', label: 'PNG' },
        { type: 'csv', label: 'CSV' },
      ],
    },
  ];
  chart.exporting.filePrefix = 'chart-data';

  document.getElementById(`legend-${id}-${fullScreen ? 'fs' : 'sw'}`).innerHTML = '';

  createImageHeader({ chart, title: exportTitle, fullScreen, id });

  return chart;
};

export default createHeatMapChart;
