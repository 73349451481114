import { hooks, mui, React } from '_core';

const ReturnType = ({ returnLabel, suffix }: { returnLabel: string; suffix: string }) => {
  const uiStyles = hooks.useUiStyles();

  let label = returnLabel;

  if (suffix) label += ` (${suffix})`;

  return <>{label && <mui.core.Chip label={label} sx={uiStyles.sectionHandle} />}</>;
};

export default ReturnType;
