import { _ } from '_core';

import { DraftParams } from './types';

export const validate = (params: DraftParams) => {
  if (_.isEmpty(params.histories)) return ['Histories is a required field.'];
  if (_.isNil(params.n_performers)) return ['Number of Top and Bottom Performers is a required field.'];
  if (params.n_performers < 0 || params.n_performers > 20)
    return ['Number of Top and Bottom Performers must be between 0 and 20.'];

  return [];
};
