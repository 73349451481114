import { React, ts } from '_core';

import Chart from './chart';
import Form from './form';
import { DraftParams, Params } from './types';

type DataSourceBrowserSnapshotProps = {
  mode: ts.enums.CHART_MODE_ENUM;
  params: Params | DraftParams;
  setParams: (_p: DraftParams) => void;

  loadWidgetData: (_payload: ts.types.widgets.WidgetGetDataParams) => Promise<ts.types.widgets.WidgetGetDataResponse>;
  widgetPaths?: Record<'datasource_generate', string>;
  goFullScreen?: (_b: string) => void;
  fullScreen?: boolean;

  context?: ts.types.dataSource.DataSourceBrowserContext;
  queryParams?: { initial?: string };
};

const DataSourceBrowserSnapshot: React.FC<DataSourceBrowserSnapshotProps> = ({
  loadWidgetData,
  widgetPaths,
  mode,
  context,
  params,
  setParams,
  fullScreen,
  goFullScreen,
  queryParams,
}) => {
  const renderJobComponent = () => (
    <>
      <Chart
        loadWidgetData={loadWidgetData}
        data={widgetPaths['datasource_generate']}
        fullScreen={fullScreen}
        context={context}
        queryParams={queryParams}
        goFullScreen={goFullScreen}
        params={params as Params}
        setParams={setParams}
      />
    </>
  );

  return (
    <>{mode == 'edit' ? <Form params={params} context={context} setParams={setParams} /> : renderJobComponent()}</>
  );
};

export default DataSourceBrowserSnapshot;
