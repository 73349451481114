import React from 'react';

import HelpContentDialog from './help-content-dialog';
import ContextSingleton from '../../__singletons__/context-singleton';
import * as hooks from '../../hooks';
import { _, mui, useDispatch, useSelector } from '../../libs';
import * as types from '../../types';

type HelpContentProps = {
  helpContent: types.helpItem.HelpItemContent;

  title?: string;
  children?: React.ReactNode;
};

/**
 * Component that displays help content with an icon or button that opens documentation
 *
 * @requires Redux Store:
 * - state.ui.documentSidebarUrl: URL of currently open documentation
 * - state.resources.help_items: List of help items
 * - state.auth.organization.id: Current organization ID
 * - state.ui.preferences: UI preferences
 * - state.auth.currentUser: Current user
 *
 * @requires Redux Store Actions:
 * - ui.setDocumentSidebarOpen: Action to open/close documentation sidebar
 * - ui.setDocumentSidebarUrl: Action to set documentation URL
 * - resources.deleteHelpItem: Action to delete help item
 *
 * @requires Features:
 * - enable_help_edit: Feature flag for help content editing
 *
 * @param helpContent - Content configuration for the help item
 * @param title - Optional title to show in button (shows icon only if omitted)
 * @param children - Optional child elements to render alongside help button
 *
 * @returns Rendered help content component
 */
const HelpContent: React.FC<HelpContentProps> = ({ children, title, helpContent }): React.ReactElement => {
  const theme = mui.styles.useTheme() as mui.core.Theme;
  const uiStyles = hooks.useUiStyles();
  const actions = ContextSingleton.getInstance().actions;
  const features = ContextSingleton.getInstance().features;

  const dispatch = useDispatch();
  const documentationUrl = useSelector((state: types.BaseStore) => state.ui.documentSidebarUrl);

  const helpItems = useSelector((state: types.BaseStore) => state.resources.help_items);
  const orgId = useSelector((state: types.BaseStore) => state.auth.organization.id);
  const preferences = useSelector((state: types.BaseStore) => state.ui.preferences);
  const helpItem = helpItems?.find((el) => el.label == helpContent.helpLabel && el.document == helpContent.document);

  // Enable the edition of the help icon if org has the feature or is org_o and preference is on
  const enableHelpEdit = (features.enable_help_edit || orgId === 0) && preferences.helpIconEditMode;

  const [modalOpen, setModalOpen] = React.useState(false);

  const isActive = React.useMemo(() => {
    return documentationUrl === helpItem?.url && documentationUrl;
  }, [helpItem?.url, documentationUrl]);

  const openDocs = (url = helpItem.url) => {
    dispatch(actions.ui.setDocumentSidebarOpen(true));
    dispatch(actions.ui.setDocumentSidebarUrl(url));
    setModalOpen(false);
  };

  const closeDocs = () => {
    dispatch(actions.ui.setDocumentSidebarOpen(false));
    dispatch(actions.ui.setDocumentSidebarUrl(null));
  };

  const handleIconClick = (args: { forceOpenDocs: boolean; url?: string } = { forceOpenDocs: false }) => {
    if (isActive) closeDocs();
    else if (enableHelpEdit && !args.forceOpenDocs) {
      closeDocs();
      setModalOpen(true);
    } else openDocs(args.url);
  };

  const color = React.useMemo(() => {
    if (isActive) return (theme.palette as any).primary.main;
    if (!_.isEmpty(helpItem)) return (theme.palette as any).grey[500];
    return (theme.palette as any).dangerLight.dark;
  }, [isActive, enableHelpEdit, helpItem]);

  if (!helpItem?.url && !enableHelpEdit)
    return (
      <mui.core.Box display="flex" alignItems="center">
        {children && <mui.core.Box flex="1">{children}</mui.core.Box>}
      </mui.core.Box>
    );

  return (
    <mui.core.Box display="flex" alignItems="center">
      {enableHelpEdit && (
        <HelpContentDialog
          isOpen={modalOpen}
          setIsOpen={setModalOpen}
          openInApp={(url) => handleIconClick({ forceOpenDocs: true, url })}
          helpContent={helpContent}
          helpItem={helpItem}
        />
      )}

      {children && <mui.core.Box flex="1">{children}</mui.core.Box>}
      {title ? (
        <mui.core.Button
          startIcon={<mui.icons.HelpOutline />}
          onClick={() => handleIconClick()}
          sx={{ color, ...uiStyles.tabsButton }}
          size="small"
        >
          {title}
        </mui.core.Button>
      ) : (
        <mui.core.IconButton
          onClick={() => handleIconClick()}
          sx={{
            marginLeft: '0.25rem',
          }}
        >
          <mui.icons.HelpOutlineRounded
            sx={{
              color,
              fontSize: '1rem',
            }}
          />
        </mui.core.IconButton>
      )}
    </mui.core.Box>
  );
};

export default HelpContent;
