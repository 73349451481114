import React from 'react';

import { _ } from '../libs';

/**
 * Interface representing window dimensions
 */
interface IWindowDimensions {
  /** Width of the window in pixels */
  width: number;
  /** Height of the window in pixels */
  height: number;
}

/**
 * Hook that returns the current window dimensions and updates when window is resized
 * @param debounceTime - Time in milliseconds to debounce the resize event (default: 300ms)
 * @returns Object containing current window width and height
 */
const useWindowDimensions = (debounceTime = 300): IWindowDimensions => {
  const [windowDimensions, setWindowDimensions] = React.useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const handleResize = React.useCallback(
    _.debounce((width: number, height: number) => {
      setWindowDimensions({ width, height });
    }, debounceTime),
    []
  );

  const handleResizeInit = () => {
    handleResize(window.innerWidth, window.innerHeight);
  };

  React.useEffect(() => {
    window.addEventListener('resize', handleResizeInit);
    return () => {
      window.removeEventListener('resize', handleResizeInit);
    };
  }, []);

  return windowDimensions;
};

export default useWindowDimensions;
