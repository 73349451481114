import { mui, React, ts } from '_core';

import AlphaModelForm from './alpha-form';
import SignalForm from './custom-form';

type SignalFormProps = {
  open: boolean;
  setOpen: (_open: boolean) => void;
  type?: ts.enums.SIGNAL_TYPE_ENUM;
};

const MainSignalForm: React.FC<SignalFormProps> = ({ open, setOpen, type }) => {
  const [errorMessage, setErrorMessage] = React.useState('');

  return (
    <mui.core.Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="sm"
      fullWidth
    >
      {errorMessage && (
        <mui.lab.Alert onClose={() => setErrorMessage('')} severity="error">
          {errorMessage}
        </mui.lab.Alert>
      )}
      {type == 'ALPHA_MODEL' && <AlphaModelForm setErrorMessage={setErrorMessage} close={() => setOpen(false)} />}
      {type == 'SIGNAL' && <SignalForm setErrorMessage={setErrorMessage} close={() => setOpen(false)} />}
    </mui.core.Dialog>
  );
};

MainSignalForm.defaultProps = {
  type: ts.enums.SIGNAL_TYPE_ENUM.SIGNAL,
};

export default MainSignalForm;
