/* eslint-disable max-len */
import { ts } from '_core';

const innerTabNames: Record<ts.enums.PREFERENCES_INNER_TABS_ENUM, string> = {
  // BACKTEST_SETS
  [ts.enums.PREFERENCES_INNER_TABS_ENUM.BACKTEST_SET_SUMMARY_COLS]: 'Summary',
  // BACKTEST
  [ts.enums.PREFERENCES_INNER_TABS_ENUM.BACKTEST_CONSTRAINTS_COLS]: 'Constraints',
  [ts.enums.PREFERENCES_INNER_TABS_ENUM.BACKTEST_DATE_COMPARISON]: 'Date Comparison',
  [ts.enums.PREFERENCES_INNER_TABS_ENUM.BACKTEST_PORTFOLIO_RISK_COLS]: 'Portfolio Risk',
  [ts.enums.PREFERENCES_INNER_TABS_ENUM.BACKTEST_PREVIEW_COLS]: 'Preview',
  [ts.enums.PREFERENCES_INNER_TABS_ENUM.BACKTEST_PREVIEW_ALPHA_EXPOSURES_COLS]: 'Alpha Exposures',
  [ts.enums.PREFERENCES_INNER_TABS_ENUM.BACKTEST_SUMMARY_AND_DETAILS_COLS]: 'Summaries',
  [ts.enums.PREFERENCES_INNER_TABS_ENUM.BACKTEST_PERFORMANCE_ATTRIBUTION_CONTRIBUTOR_COLS]:
    'Performance Attribution Contributors',
  // BASKET
  [ts.enums.PREFERENCES_INNER_TABS_ENUM.BASKET_CONSTRAINTS_COLS]: 'Constraints',
  [ts.enums.PREFERENCES_INNER_TABS_ENUM.BASKET_DATE_COMPARISON]: 'Dates Comparison',
  [ts.enums.PREFERENCES_INNER_TABS_ENUM.BASKET_PORTFOLIO_RISK_COLS]: 'Portfolio Risk',
  [ts.enums.PREFERENCES_INNER_TABS_ENUM.BASKET_PREVIEW_COLS]: 'Preview',
  [ts.enums.PREFERENCES_INNER_TABS_ENUM.BASKET_SUMMARY_AND_DETAILS_COLS]: 'Summary and Details',
  [ts.enums.PREFERENCES_INNER_TABS_ENUM.BASKET_COMPARE_SUMMARY_STATS_COLS]: 'Summary Statistics',
  [ts.enums.PREFERENCES_INNER_TABS_ENUM.BASKET_PERFORMANCE_ATTRIBUTION_CONTRIBUTOR_COLS]:
    'Performance Attribution Contributors',

  // PORTFOLIOS
  [ts.enums.PREFERENCES_INNER_TABS_ENUM.PORTFOLIO_SUMMARY_AND_DETAILS_COLS]: 'Report',
  [ts.enums.PREFERENCES_INNER_TABS_ENUM.PORTFOLIO_TRADE_LIST_COLS]: 'Trade List',
  [ts.enums.PREFERENCES_INNER_TABS_ENUM.PORTFOLIO_TRADE_LIST_SUMMARY_COLS]: 'Trade List Summary',
  [ts.enums.PREFERENCES_INNER_TABS_ENUM.PORTFOLIO_TRADE_LIST_BUCKET_EXPOSURE_COLS]:
    'Trade List Bucket Exposure Summary',
  [ts.enums.PREFERENCES_INNER_TABS_ENUM.PORTFOLIO_TRADE_LIST_FACTOR_EXPOSURE_COLS]:
    'Trade List Factor Exposure Summary',
  [ts.enums.PREFERENCES_INNER_TABS_ENUM.PORTFOLIO_TRADE_LIST_HOLDINGS_COLS]: 'Trade List Holdings',
  [ts.enums.PREFERENCES_INNER_TABS_ENUM.PORTFOLIO_CONSTRAINTS_COLS]: 'Constraints',
  [ts.enums.PREFERENCES_INNER_TABS_ENUM.PORTFOLIOS_HOLDINGS_COLS]: 'Holdings',

  // RISK MODELS
  [ts.enums.PREFERENCES_INNER_TABS_ENUM.RISK_MODEL_PREVIEW_COLS]: 'Preview',
  [ts.enums.PREFERENCES_INNER_TABS_ENUM.RISK_MODEL_ASSET_PERTINENCE_PREVIEW_COLS]: 'Asset Pertinence Preview',
  // SIGNALS
  [ts.enums.PREFERENCES_INNER_TABS_ENUM.SIGNAL_DATASET_FORM]: 'Parameters',
  [ts.enums.PREFERENCES_INNER_TABS_ENUM.SIGNAL_PREVIEW_COLS]: 'Preview',
  [ts.enums.PREFERENCES_INNER_TABS_ENUM.SIGNAL_DATE_COMPARISON_COLS]: 'Date Comparison',
  // SIGNAL SETS
  [ts.enums.PREFERENCES_INNER_TABS_ENUM.SIGNAL_SET_SUMMARY_STATS_COLS]: 'Summary Statistics',
  // TIME SERIES
  [ts.enums.PREFERENCES_INNER_TABS_ENUM.TIME_SERIES_PREVIEW_COLS]: 'Preview',
  [ts.enums.PREFERENCES_INNER_TABS_ENUM.TIME_SERIES_COMPARE_SUMMARY_STATS_COLS]: 'Compare',
  // UNIVERSES
  [ts.enums.PREFERENCES_INNER_TABS_ENUM.UNI_ASSET_PERTINENCE_COLS]: 'Asset Pertinence',
  [ts.enums.PREFERENCES_INNER_TABS_ENUM.UNI_DATE_COMPARISON_COLS]: 'Dates Comparison',
  [ts.enums.PREFERENCES_INNER_TABS_ENUM.UNI_LINKED_ASSETS_COLS]: 'Linked Assets',
  [ts.enums.PREFERENCES_INNER_TABS_ENUM.UNI_SNAPSHOT_COLS]: 'Snapshot',

  [ts.enums.PREFERENCES_INNER_TABS_ENUM.UNI_TEMPLATE]: 'Universe',

  [ts.enums.PREFERENCES_INNER_TABS_ENUM.SIGNAL_TEMPLATE]: 'Signal',
  [ts.enums.PREFERENCES_INNER_TABS_ENUM.SIGNAL_PREVIEW_TEMPLATE]: 'Signal Preview',
  [ts.enums.PREFERENCES_INNER_TABS_ENUM.SIGNAL_ATTRIBUTION_TEMPLATE]: 'Signal Attribution',

  [ts.enums.PREFERENCES_INNER_TABS_ENUM.ALPHA_MODEL_TEMPLATE]: 'Alpha Model',

  [ts.enums.PREFERENCES_INNER_TABS_ENUM.BACKTEST_TEMPLATE]: 'Backtest',
  [ts.enums.PREFERENCES_INNER_TABS_ENUM.BACKTEST_PREVIEW_TEMPLATE]: 'Backtest Detailed View',
  [ts.enums.PREFERENCES_INNER_TABS_ENUM.BACKTEST_PORTFOLIO_RISK_TEMPLATE]: 'Backtest Portfolio Risk',
  [ts.enums.PREFERENCES_INNER_TABS_ENUM.BACKTEST_ATTRIBUTION_TEMPLATE]: 'Backtest Attribution',

  [ts.enums.PREFERENCES_INNER_TABS_ENUM.TIME_SERIES_TEMPLATE]: 'Time Series',

  [ts.enums.PREFERENCES_INNER_TABS_ENUM.BASKET_TEMPLATE]: 'Basket',
  [ts.enums.PREFERENCES_INNER_TABS_ENUM.BASKET_PREVIEW_TEMPLATE]: 'Basket Detailed View',
  [ts.enums.PREFERENCES_INNER_TABS_ENUM.BASKET_ATTRIBUTION_TEMPLATE]: 'Basket Attribution',
  [ts.enums.PREFERENCES_INNER_TABS_ENUM.BASKET_COMPARE_TEMPLATE]: 'Basket Compare',
  [ts.enums.PREFERENCES_INNER_TABS_ENUM.BASKET_PORTFOLIO_RISK_TEMPLATE]: 'Basket Portfolio Risk',

  [ts.enums.PREFERENCES_INNER_TABS_ENUM.BACKTEST_SET_TEMPLATE]: 'Backtest Set',

  [ts.enums.PREFERENCES_INNER_TABS_ENUM.SIGNAL_SET_TEMPLATE]: 'Signal Set',
  [ts.enums.PREFERENCES_INNER_TABS_ENUM.SIGNAL_SET_REPORT_TEMPLATE]: 'Signal Set Report',

  [ts.enums.PREFERENCES_INNER_TABS_ENUM.RISK_MODEL_TEMPLATE]: 'Risk Model',

  [ts.enums.PREFERENCES_INNER_TABS_ENUM.REBALANCE_PORTFOLIO_RISK_TEMPLATE]: 'Portfolio Risk',
  [ts.enums.PREFERENCES_INNER_TABS_ENUM.REBALANCE_PORTFOLIO_TRADELIST_TEMPLATE]: 'Portfolio Tradelist',
  [ts.enums.PREFERENCES_INNER_TABS_ENUM.REBALANCE_PORTFOLIO_HOLDINGS_TEMPLATE]: 'Portfolio Holdings',
};

export default innerTabNames;
