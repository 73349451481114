import { _, helpers, hooks, mui, React, ts, ui } from '_core';

type SyncTableProps = { tableData: ts.types.analysis.SyncTableEmbedData[0]; download: boolean };
const SyncTable = ({ tableData, download }: SyncTableProps) => {
  const { rows, columns, pinnedColumns, heatMappedCols, heatMapColors, headerEls, formatter, fixedWidth } = tableData;
  const boxRef = React.useRef(null);

  const [tableWidth, setTableWidth] = React.useState(null);

  const { width } = hooks.useWindowDimensions();
  const updateTableDim = React.useCallback(() => {
    if (boxRef.current) {
      setTableWidth(boxRef.current.offsetWidth);
    }
  }, []);

  React.useEffect(() => {
    updateTableDim();
  }, [width]);

  const rowHeight = 20;
  const headerHeight = 35;

  const heatMapClasses = React.useMemo(() => {
    if (_.isEmpty(heatMappedCols)) return {};

    let localHeatMapColors = heatMapColors;
    // Legacy code for old structure (already embedded widgets)
    if (_.isArray(heatMapColors as any)) {
      localHeatMapColors = {
        startColor: (heatMapColors as any)?.[1],
        endColor: (heatMapColors as any)?.[0],
      };
    }

    const localInterpolate = !localHeatMapColors
      ? () => helpers.interpolateColors({})
      : () => helpers.interpolateColors(localHeatMapColors);

    const newClasses = {} as Record<string, Record<string, string>>;
    localInterpolate().forEach((v, idx) => {
      newClasses[`& .heatmapclass-${idx}`] = {
        backgroundColor: `rgb(${v.join(',')})`,
      };
    });
    return newClasses;
  }, [heatMappedCols]);

  const assignToColorsBin = React.useMemo(() => {
    if (_.isEmpty(heatMappedCols)) return null;
    let maxVal = -Infinity;

    heatMappedCols.forEach((col) => {
      const rowVals = rows.map((r: any) => r[col]).map((v: any) => Math.abs(v));
      maxVal = _.max([...rowVals, maxVal]);
    });
    return (value: number) =>
      helpers.assignValueToColorBin(value, {
        maxValue: maxVal,
        minValue: maxVal * -1,
        ...(heatMapColors ?? {}),
      });
  }, [rows, heatMappedCols]);

  const tableHeader = _.isEmpty(headerEls)
    ? undefined
    : () => (
        <mui.core.Box display="flex" justifyContent="space-between" p={2} py={5}>
          <mui.core.Box display="flex">
            {headerEls.map(({ key, value }) => (
              <React.Fragment key={key}>
                <ui.InfoRow title={key}>{value}</ui.InfoRow>

                <mui.core.Box px={4}>
                  <mui.core.Divider orientation="vertical" />
                </mui.core.Box>
              </React.Fragment>
            ))}
          </mui.core.Box>

          <mui.core.Box>
            <mui.dataGrid.GridToolbarDensitySelector />
            <mui.dataGrid.GridToolbarFilterButton />
            {download && (
              <mui.dataGrid.GridToolbarExportContainer>
                {download && <mui.core.MenuItem onClick={() => handleDownload()}>Download as CSV</mui.core.MenuItem>}
              </mui.dataGrid.GridToolbarExportContainer>
            )}
          </mui.core.Box>
        </mui.core.Box>
      );

  const customValueGetter: mui.dataGrid.GridColDef['valueGetter'] = (value, row, column) => {
    const data = { row: row, column: { key: column.field }, toValue: true };
    const ctype = column.type as any as keyof typeof formatter;
    return _.isEmpty(formatter?.[ctype])
      ? helpers.tableFormatters.formatTo('inferred')(data)
      : helpers.tableFormatters.formatTo(formatter[ctype].to, { ...formatter[ctype] })(data);
  };

  const localCols = React.useMemo(
    () =>
      (columns || []).map(
        (c) =>
          ({
            ...c,
            width: helpers.datagrid.getColumnWidth(c, columns, [], 100, fixedWidth, tableWidth, true),
            headerClassName: (c.headerClassName as string)?.includes('index')
              ? 'data-grid-index-header'
              : c.headerClassName,
            renderCell: (params: mui.dataGrid.GridCellParams) => {
              const data = { row: params.row, column: { key: params.field } };
              const ctype = c.type as any as keyof typeof formatter;
              return _.isEmpty(formatter?.[ctype])
                ? helpers.tableFormatters.formatTo('inferred')(data)
                : helpers.tableFormatters.formatTo(formatter[ctype].to, { ...formatter[ctype] })(data);
            },
            valueGetter: customValueGetter,
          }) as mui.dataGrid.GridColDef
      ),
    [tableWidth]
  );

  const handleDownload = () => {
    try {
      const csvData = helpers.csv.toCsv(rows, localCols as any, ts.enums.SEPARATORS_ENUM.COMMA, false, true).join('');
      // Create a Blob from the CSV data
      const blob = new Blob([csvData], { type: 'text/csv' });

      // Create a link element
      const link = document.createElement('a');

      // Set the link's properties
      link.href = URL.createObjectURL(blob);
      link.download = 'data.csv';

      // Append the link to the document
      document.body.appendChild(link);

      // Trigger a click event on the link
      link.click();

      // Remove the link from the document
      document.body.removeChild(link);
    } catch {
      console.log('Unable to dowlonad!');
    }
  };

  return (
    <mui.core.Box
      height="100%"
      display="flex"
      sx={{
        height: '100%',
        position: 'relative',
        background: '#fff',
        '@media print': {
          maxWidth: '100vw',
        },
        '& .react-grid-Grid .react-grid-Cell': {
          height: '200px',
        },
        '& .react-grid-Toolbar .tools': {
          width: '100%',
          display: 'flex',
          justifyContent: 'flex-end',
        },
        '& .MuiDataGrid-virtualScroller': {
          scrollbarWidth: 'thin',
        },
      }}
      flexDirection="column"
      bgcolor="white"
      ref={boxRef}
    >
      {tableWidth ? (
        <mui.dataGrid.DataGridPremium
          rows={rows || []}
          columns={localCols}
          pinnedColumns={pinnedColumns}
          groupingColDef={{
            hideDescendantCount: true,
          }}
          rowHeight={rowHeight}
          columnHeaderHeight={headerHeight}
          slots={{
            toolbar: tableHeader,
            columnsManagement: () => <></>,
          }}
          sx={{
            ...heatMapClasses,
          }}
          className={'data-grid-default'}
          showCellVerticalBorder
          showColumnVerticalBorder
          hideFooter
          hideFooterPagination
          hideFooterRowCount
          disableColumnReorder
          disableRowSelectionOnClick
          disableColumnMenu
          disableColumnResize
          cellSelection
          getCellClassName={(params: mui.dataGrid.GridCellParams) => {
            if (heatMappedCols.includes(params.field)) {
              return `heatmapclass-${assignToColorsBin(params.row[params.field] as number)}`;
            }
          }}
        />
      ) : (
        <ui.CenteredLoader label="Loading table..." />
      )}
    </mui.core.Box>
  );
};

export default SyncTable;
