/* eslint-disable no-unused-vars */
import { ts } from '_core'; 

export enum WIDGET_TYPE {
  CORRELATION = 'correlation',
  EXPOSURE = 'exposure',
}

export type JobParams = {
  buckets: ts.types.widgets.common.BucketValue;
  weights: 'equal';
  return_horizon?: ts.types.returnHorizon.ReturnHorizon;
  analysis_signal_id?: number;
  pipeline_id?: number;
  histories: ts.enums.HISTORY_ENUM_EXTENDED[];
  type: WIDGET_TYPE;
  selected_type: string;
  file: 'bucketed_correlation' | 'bucketed_average_exposure';
  ui_transformation: 'AVERAGE' | 'CORRELATION';
};

export type Params = JobParams;
export type DraftParams = Partial<Params>;
