import { _ } from '_core';

import { default as selectHistoryRender } from './custom-filters/history-field';
import { default as returnTypeView } from './custom-filters/return-type';
import { default as selectViewRender } from './custom-filters/select-view-field';
import { default as selectStatisticsRender } from './custom-filters/statistics-field';
import { getIndustryName, getRbicsIndustryName, getTitleByStatistics } from '../../../common/utils/attribution-utils';
import { DraftParams } from '../types';

const getSheets = (params: DraftParams, bucketOrder: string[]) => {
  const bucketsValue = params?.buckets?.value || 'all';
  const bucketsType = params?.buckets?.type;

  let formattedBucketsValue = bucketsValue as string;

  if (_.checkIsNumber(formattedBucketsValue)) formattedBucketsValue = 'Quantiles';

  if (formattedBucketsValue.includes('gics')) formattedBucketsValue = getIndustryName(formattedBucketsValue);

  if (formattedBucketsValue.includes('rbics')) formattedBucketsValue = getRbicsIndustryName(formattedBucketsValue);

  const sheets = {
    variables: 'Variables Details',
    [`${bucketsType}:${bucketsValue}`]: `${_.startCase(formattedBucketsValue)} Details`,
    [`${bucketOrder[1]}_AND_${bucketOrder[0]}`]: `Variables & ${_.startCase(formattedBucketsValue)} Details`,
    summary_variables: 'Variables Summary',
    [`summary_${bucketsType}:${bucketsValue}`]: `${_.startCase(formattedBucketsValue)} Summary`,
    [`summary_${bucketOrder[1]}_AND_${bucketOrder[0]}`]: `Variables & ${_.startCase(formattedBucketsValue)} Summary`,
  } as Record<string, string>;

  const customWidth = {} as Record<string, number>;
  const customRowHeight = {} as Record<string, number>;
  const customHeaderHeight = {} as Record<string, number>;

  Object.keys(sheets).forEach((key) => {
    if (key.split('AND').length > 1) {
      customWidth[key] = 100;
      customHeaderHeight[key] = 80;
    } else {
      customWidth[key] = 97;
      customHeaderHeight[key] = 60;
    }

    customRowHeight[key] = 20;
  });

  return { sheets, sheetsStyles: { customWidth, customHeaderHeight, customRowHeight } };
};

const renderToolbar = (views: React.ReactElement[]) => <>{views}</>;

const getCustomToolbars = (props: Record<string, any>) => {
  const bucketsValue = props.params?.buckets?.[0]?.value || 'all';
  const bucketsType = props.params?.buckets[0]?.type;

  const suffix = ''; // Leaving this here in case we want to add new suffixes in the future
  const variableTitle = props.selectedViewType != 'factor_exposures' ? 'Period Return' : '';

  const statisticsName = getTitleByStatistics(props.selectedStatistics);

  const customToolBars = {
    [props.bucketOrder[0]]: () => renderToolbar([returnTypeView('Period Return', suffix)]),
    variables: () =>
      renderToolbar([
        selectViewRender(props.selectedViewType, props.setSelectedViewType),
        returnTypeView(variableTitle, suffix),
      ]),
    [`variables_AND_${props.bucketOrder[0]}`]: () => renderToolbar([returnTypeView(variableTitle, suffix)]),
    summary_variables: () =>
      renderToolbar([
        selectStatisticsRender(props.selectedStatistics, props.setSelectedStatistics),
        returnTypeView(statisticsName, suffix),
      ]),
    [`summary_${bucketsType}:${bucketsValue}`]: () =>
      renderToolbar([
        selectStatisticsRender(props.selectedStatistics, props.setSelectedStatistics),
        returnTypeView(statisticsName, suffix),
      ]),
    [`summary_${props.bucketOrder[1]}_AND_${props.bucketOrder[0]}`]: () =>
      renderToolbar([
        selectHistoryRender(
          props.selectedHistory,
          props.setSelectedHistory,
          props.histories,
          props.startDate,
          props.endDate
        ),
        selectStatisticsRender(props.selectedStatistics, props.setSelectedStatistics),
        returnTypeView(statisticsName, suffix),
      ]),
  };

  return customToolBars as Record<string, () => JSX.Element>;
};

export { getSheets };
export { getCustomToolbars };
