import { ts } from '_core';

import { INTERACTION_PARAMS } from './types';

export default [
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.PORTFOLIO_HOLDINGS_RISK_SUMMARY,
    title: 'Risk',
    helpLabel: 'hy3idety',
    form: false,
    interactionParams: Object.values(INTERACTION_PARAMS),
    clearInteractionOnRegenerate: ['table_dtypes'],
    neededFiles: ['portfolio_risk_summary_holdings'],
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.PORTFOLIO_HOLDINGS_ANALYSIS],
  },
] satisfies ts.types.analysis.WidgetConfig[];
