import { hooks, mui, React, ts, ui } from '_core';

import { DraftParams } from './types';

type FormProps = {
  params: DraftParams;
  setParams: (_p: DraftParams) => void;
  context?: ts.types.signal.SignalAnalysisContext;
};

const Form: React.FC<FormProps> = ({ params, setParams, context }) => {
  const [history, setHistory] = React.useState(params.history);
  const [bins, setBins] = React.useState(params.num_bins);
  const [percent, setPercent] = React.useState(params.percent);

  const uiStyles = hooks.useUiStyles();

  React.useEffect(() => {
    const data = { ...params, history, num_bins: bins, percent };
    setParams(data);
  }, [history, bins, percent]);

  return (
    <mui.core.Box sx={uiStyles.widgetEditMode}>
      <mui.core.Grid container spacing={2}>
        <mui.core.Grid item xs={6}>
          <mui.core.Box mt={2}>
            <mui.core.FormControlLabel
              control={<mui.core.Switch size="small" checked={percent} onChange={() => setPercent(!percent)} />}
              label="Percent?"
            />
          </mui.core.Box>
        </mui.core.Grid>
        <mui.core.Grid item xs={6}></mui.core.Grid>
        <mui.core.Grid item xs={6}>
          <ui.NumericField
            textFieldProps={{
              label: 'Bins (1 - 100)',
              fullWidth: true,
              variant: 'outlined',
              size: 'small',
              required: true,
            }}
            value={bins}
            setValue={(v) => setBins(v)}
          />
        </mui.core.Grid>
        <mui.core.Grid item xs={6}>
          <ui.WidgetHistories context={context} value={history} setValue={setHistory} />
        </mui.core.Grid>
      </mui.core.Grid>
    </mui.core.Box>
  );
};

export default Form;
