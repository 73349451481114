export default [
  { group: 'US ETF', name: 'Dow Jones Industrial Average', ticker: 'DIA', FSYM_ID: 'PRH9B4-R' },
  { group: 'US ETF', name: 'S&P 500', ticker: 'IVV', FSYM_ID: 'SJT2N9-R' },
  { group: 'US ETF', name: 'S&P 500 Value', ticker: 'IVE', FSYM_ID: 'NPQQD1-R' },
  { group: 'US ETF', name: 'S&P 500 Growth', ticker: 'IVW', FSYM_ID: 'XMZ76X-R' },
  { group: 'US ETF', name: 'S&P MidCap', ticker: 'IJH', FSYM_ID: 'F3PSGC-R' },
  { group: 'US ETF', name: 'S&P SmallCap', ticker: 'IJR', FSYM_ID: 'XN3MM1-R' },
  { group: 'US ETF', name: 'S&P SmallCap 600', ticker: 'SPSM', FSYM_ID: 'HCNT9Z-R' },
  { group: 'US ETF', name: 'Nasdaq 100', ticker: 'QQQ', FSYM_ID: 'RXHGZJ-R' },
  { group: 'US ETF', name: 'Russell 1000', ticker: 'IWB', FSYM_ID: 'JWN4VP-R' },
  { group: 'US ETF', name: 'Russell 1000 Growth', ticker: 'IWF', FSYM_ID: 'HQ9TPN-R' },
  { group: 'US ETF', name: 'Russell 1000 Value', ticker: 'IWD', FSYM_ID: 'R2JKV4-R' },
  { group: 'US ETF', name: 'Russell 2000', ticker: 'IWM', FSYM_ID: 'M79T5R-R' },
  { group: 'US ETF', name: 'Russell 2000 Growth', ticker: 'IWO', FSYM_ID: 'L1ZH0Q-R' },
  { group: 'US ETF', name: 'Russell 2000 Value', ticker: 'IWN', FSYM_ID: 'FCN5CL-R' },
  { group: 'US ETF', name: 'Russell 3000', ticker: 'IWV', FSYM_ID: 'PKZGHT-R' },
  { group: 'Canada', name: 'S&P/TSX 60', ticker: 'XIU', FSYM_ID: 'XP3D8X-R' },
  { group: 'Canada', name: 'MSCI Canada', ticker: 'EWC', FSYM_ID: 'WB7HXW-R' },
  { group: 'Europe', name: 'Euro STOXX 50', ticker: 'EUE', FSYM_ID: 'T8J5J2-R' },
  { group: 'Europe', name: 'S&P Europe 350', ticker: 'IEV', FSYM_ID: 'FL9YRN-R' },
  { group: 'Europe', name: 'DAX', ticker: 'DAX', FSYM_ID: 'MBVSFG-R' },
  { group: 'UK', name: 'FTSE 100', ticker: 'ISF', FSYM_ID: 'R96HHB-R' },
  { group: 'UK', name: 'FTSE 250', ticker: 'MIDD', FSYM_ID: 'RBHCJR-R' },
  { group: 'Global', name: 'MSCI World Index', ticker: 'URTH', FSYM_ID: 'PG7Z7F-R' },
  { group: 'Global', name: 'MSCI All Country World Index', ticker: 'ACWI', FSYM_ID: 'S0WMNB-R' },
  { group: 'Global', name: 'S&P Global 100', ticker: 'IOO', FSYM_ID: 'V0CD7Q-R' },
  { group: 'Global', name: 'S&P Global Dividend', ticker: 'WDIV', FSYM_ID: 'WG8D87-R' },
  { group: 'Global', name: 'FTSE All-World Index Series', ticker: 'VWRL', FSYM_ID: 'JYJ3D2-R' },
  { group: 'Emerging Markets', name: 'MSCI Emerging Markets', ticker: 'EEM', FSYM_ID: 'KQLGWJ-R' },
  { group: 'Emerging Markets', name: 'S&P Latin America 40', ticker: 'ILF', FSYM_ID: 'N3KK9J-R' },
  {
    group: 'Emerging Markets',
    name: 'S&P Emerging Asia Pacific',
    ticker: 'GMF',
    FSYM_ID: 'D1VRVZ-R',
  },
  { group: 'Asia', name: 'TOPIX', ticker: '1475', FSYM_ID: 'DZRVJK-R' },
  { group: 'Asia', name: 'MSCI Japan', ticker: 'EWJ', FSYM_ID: 'H40870-R' },
  { group: 'Asia', name: 'Nikkei 250', ticker: 'CNKY', FSYM_ID: 'JNWMC5-R' },
  { group: 'Asia', name: 'MSCI Pacific ex Japan', ticker: 'EPP', FSYM_ID: 'HF2V88-R' },
  { group: 'Asia', name: 'MSCI Pacific', ticker: 'IPAC', FSYM_ID: 'DSGDS4-R' },
];
