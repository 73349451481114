import React from 'react';

import * as types from '../types';

// Lazy load SwaggerUI
const SwaggerUI = React.lazy(() => import('swagger-ui-react'));

type SwaggerDeferredProps = {
  /** URL to fetch Swagger/OpenAPI spec from */
  url?: string;
  /** Swagger/OpenAPI spec object */
  spec?: types.common.SpecType;
  /** Callback function when Swagger UI finishes loading */
  onComplete?: () => void;
};

/**
 * Component that renders Swagger UI documentation with lazy loading
 *
 * @param url - URL to fetch Swagger/OpenAPI spec from
 * @param spec - Swagger/OpenAPI spec object
 * @param onComplete - Callback function when Swagger UI finishes loading
 * @returns Rendered Swagger UI component
 */
const SwaggerDeferred: React.FC<SwaggerDeferredProps> = ({ url, spec, onComplete }) => {
  // Ensure spec has a valid version field if provided
  const validatedSpec = React.useMemo(() => {
    if (!spec) return undefined;

    // Add default version if missing
    return {
      ...spec,
      openapi: '3.0.0',
    };
  }, [spec]);

  // Memoize the SwaggerUI component to prevent unnecessary re-renders
  const memoizedSwagger = React.useMemo(
    () => (
      <React.Suspense fallback={<div>Loading Swagger UI...</div>}>
        <SwaggerUI url={url} spec={validatedSpec} onComplete={onComplete} defaultModelsExpandDepth={-1} />
      </React.Suspense>
    ),
    [url, validatedSpec, onComplete]
  );

  return memoizedSwagger;
};

export default SwaggerDeferred;
