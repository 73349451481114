import { _, hooks, mui, React, ts } from '_core';

import Tabular from 'views/report/tables/tabular';

import { factorColumns, modelColumns } from './columns-config';
import { Params, SHEETS } from './types';
import { getColumns } from '../../common/charts/utils';

type ChartProps = {
  id: string;
  readOnly: boolean;
  widgetKey: string;
  loadWidgetData: (_payload: ts.types.widgets.WidgetGetDataParams) => Promise<ts.types.widgets.WidgetGetDataResponse>;
  params: Params;
  setParams: (_p: Params) => void;
  fullScreen: boolean;
  goFullScreen: (_v: string) => void;
  model: string;
  factor: string;
  queryParams?: { initial?: string };
};

const Chart: React.FC<ChartProps> = ({
  id,
  readOnly,
  widgetKey,
  loadWidgetData,
  params,
  model,
  factor,
  goFullScreen,
  setParams,
  fullScreen,
}) => {
  const [error, setError] = React.useState<ts.types.common.ApiError>(null);
  const [data, setData] = React.useState<Record<string, ts.types.widgets.TableData>>();

  const [containerHeight, setContainerHeight] = React.useState(undefined);
  const { height } = hooks.useWindowDimensions();

  const container = React.createRef<HTMLDivElement>();

  React.useEffect(() => {
    if (container.current) {
      setContainerHeight(container.current.offsetHeight - 40);
    }
  }, [height]);

  const [tableParams, setTableParams] = React.useState<ts.types.components.dataGrid.TableParamsSheet>(
    params.table_params
  );

  hooks.useEffectWithoutFirst(() => {
    setParams({
      ...params,
      table_params: { ...tableParams },
    });
  }, [tableParams]);

  const [currentSheet, setCurrentSheet] = React.useState(params.current_sheet || ('model' as SHEETS));

  const debouncedParamsSave = React.useCallback(
    _.debounce(async (ct: SHEETS) => {
      setParams({
        ...params,
        current_sheet: ct,
      });
    }, 1_000),
    []
  );

  hooks.useEffectWithoutFirst(() => {
    debouncedParamsSave(currentSheet);
  }, [currentSheet]);

  const loadData = async () => {
    try {
      const resp = await Promise.all([
        loadWidgetData({ data: { file: model } }),
        loadWidgetData({ data: { file: factor } }),
      ]);

      if (resp) {
        const localData = {} as typeof data;
        if (!_.isEmpty(resp[0].data)) localData['model'] = resp[0].data;
        if (!_.isEmpty(resp[1].data)) localData['factor'] = resp[1].data;
        setData(localData);
      }
    } catch (err) {
      setError(err as ts.types.common.ApiError);
    }
  };

  React.useEffect(() => {
    if (model && factor) loadData();
    return () => setData(null);
  }, [model, factor]);

  return (
    <mui.core.Box
      id={id}
      key={widgetKey}
      sx={{
        height: 'calc(100% - 1px)',
        '& .rdg-light': { paddingBottom: '60px' },
      }}
      ref={container}
    >
      <Tabular<SHEETS>
        data={data}
        apiError={error}
        readOnly={readOnly}
        getColumns={{
          model: (modelKeys) => getColumns(modelKeys, null, modelColumns),
          factor: (factorKeys) => getColumns(factorKeys, null, factorColumns),
        }}
        sheetTitles={{
          model: 'Model Summary',
          factor: 'User Factor Exposure Summary',
        }}
        fullScreen={fullScreen}
        goFullScreen={goFullScreen}
        hideFilters
        alwaysShowBottom
        currentSheet={currentSheet}
        setCurrentSheet={setCurrentSheet}
        overrideHeight={containerHeight}
        tableParams={tableParams}
        setTableParams={{
          model: (v) => setTableParams((t) => ({ ...t, model: v })),
          factor: (v) => setTableParams((t) => ({ ...t, factor: v })),
        }}
        triggerWidthChange={params.fullWidth}
      />
    </mui.core.Box>
  );
};

export default Chart;
