import { ts } from '_core';

import { getName, validate } from './helpers';
import { DraftParams } from './types';

export default [
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.SIGNAL_ANNUALIZED_RISK_ADJUSTED_LAGS,
    title: 'Annualized Risk Adjusted Returns With Lags',
    helpLabel: '557obc8l',
    form: true,
    defaultParams: {
      statistics_type: 'adjusted',
      histories: ['Full'],
    } as DraftParams,
    neededFiles: ['signal_annualized_returns'],
    tags: [
      ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.SIGNAL,
      ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.ALPHA_MODEL,
      ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.COMMON_ALPHA_MODEL,
    ],

    getName,
    validate,
  },
] satisfies ts.types.analysis.WidgetConfig[];
