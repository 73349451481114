import { _, custom, helpers, ts } from '_core';

export const getTabularSignificanceColumns = (columnKeys: string[]) => {
  const localColumns = [] as ts.types.components.dataGrid.ColumnsData;
  columnKeys.forEach((dataKey) => {
    if (dataKey == 'build_date')
      localColumns.push({
        name: _.startCase(dataKey),
        key: dataKey,
        filter: 'date',
        formatter: helpers.tableFormatters.formatTo('date', { align: 'right', format: 'YYYY-MM-DD' }),
      });
    else if (dataKey == 'rsquared') localColumns.push({ name: 'Overall R²', key: dataKey });
    else if (dataKey == 'fstat')
      localColumns.push({
        key: dataKey,
        name: <custom.MultilineTableHeader name={['Overall', 'F-Statistic']} />,
        cleanName: 'Overall F-Statistics',
      });
    else if (dataKey.startsWith('_pvalue_')) {
      const name = dataKey.replace('_pvalue_', '');
      localColumns.push({
        key: dataKey,
        cleanName: `${name} P-Values`,
        name: <custom.MultilineTableHeader name={[name, 'P-Values']} />,
      });
    } else if (dataKey.startsWith('_coeff_')) {
      const name = dataKey.replace('_coeff_', '');
      localColumns.push({
        key: dataKey,
        cleanName: `${name} Coefficient`,
        name: <custom.MultilineTableHeader name={[name, 'Coefficient']} />,
      });
    } else if (dataKey.startsWith('_stderr_')) {
      const name = dataKey.replace('_stderr_', '');
      localColumns.push({
        key: dataKey,
        cleanName: `${name} Standard Error`,
        name: <custom.MultilineTableHeader name={[name, 'Standard Error']} />,
      });
    } else {
      localColumns.push({ key: dataKey, name: dataKey });
    }
  });

  return localColumns;
};
