import { ts } from '_core';

import { validate } from './helpers';
import { INTERACTION_PARAMS } from './types';

export default [
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.PORTFOLIO_TRADE_LIST_FACTOR_EXPOSURE_SUMMARY,
    form: true,
    title: 'Factor Exposure Summary',
    interactionParams: Object.values(INTERACTION_PARAMS),
    neededFiles: [
      'trade_list_alpha_factor_exposure',
      'trade_list_user_factor_exposure',
      'portfolio_risk_factor_exposure',
      'rebalance_analysis',
    ],
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.REBALANCE_PORTFOLIO_TRADE_LIST],

    validate,
    helpLabel: 'iov36hcm',
  },
] satisfies ts.types.analysis.WidgetConfig[];
