import { moment, ts } from '_core';

export const getFileName = (
  resources: ts.StoreState['resources'],
  context: ts.types.timeSeries.TimeSeriesAnalysisContext,
  key: string,
  isDebug = false
) => {
  let name = resources.time_series.find((s) => s.id == context.time_series_id)?.name;

  name += `-${moment(context.start_date).format('yyyyMMDD')}`;
  name += `-${moment(context.end_date).format('yyyyMMDD')}`;
  name += `-${key}`;
  if (isDebug) name += '-debug';
  return name;
};
