import { ts } from '_core';

import { DraftParams, INTERACTION_PARAMS } from './types';

export default [
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.BASKET_NAME_COUNT,
    title: 'Name Count',
    helpLabel: '4imq9zuw',
    defaultParams: {
      isBasket: true,
    } as DraftParams,
    // Adding scale for legacy purposes
    interactionParams: Object.values(INTERACTION_PARAMS),
    form: false,
    neededFiles: ['backtest_name_count'],
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.BASKET],
  },
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.BACKTEST_NAME_COUNT,
    title: 'Name Count',
    helpLabel: '7w7ysyo3',
    defaultParams: {
      isBasket: false,
    } as DraftParams,
    // Adding scale for legacy purposes
    interactionParams: Object.values(INTERACTION_PARAMS),
    form: false,
    neededFiles: ['backtest_name_count'],
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.BACKTEST],
  },
] satisfies ts.types.analysis.WidgetConfig[];
