import React from 'react';

import * as enums from '../../../enums';
import { mui } from '../../../libs';
import * as types from '../../../types';

type RiskModelCategoryProps = {
  value: types.widgets.common.BucketValue;
  setValue: (_v: types.widgets.common.BucketValue) => void;
  disabled?: boolean;
  error?: boolean;
};

interface Categories {
  [key: string]: string;
}

const RiskModelCategory: React.FC<RiskModelCategoryProps> = ({
  value,
  setValue,
  disabled,
  error,
}): React.ReactElement => {
  const categories: Categories = {
    [enums.RISK_MODEL_CATEGORIES_ENUM.INDUSTRY]: 'Industry',
    [enums.RISK_MODEL_CATEGORIES_ENUM.CURRENCY]: 'Currency',
    [enums.RISK_MODEL_CATEGORIES_ENUM.COUNTRY]: 'Country',
  };

  return (
    <mui.core.Grid item xs={12} md={6}>
      <mui.core.TextField
        select
        label="Category"
        variant="outlined"
        size="small"
        value={value.value}
        onChange={(e) => setValue({ ...value, value: e.target.value })}
        fullWidth
        required
        disabled={disabled}
        error={error}
      >
        {Object.keys(categories).map((key) => (
          <mui.core.MenuItem key={key} value={key}>
            {categories[key as enums.RISK_MODEL_CATEGORIES_ENUM]}
          </mui.core.MenuItem>
        ))}

        {/* Custom logic for old risk model categories */}
        {value && !categories[value.value as keyof typeof categories] && (
          <mui.core.MenuItem value={value.value} key={value.value}>
            {value.value}
          </mui.core.MenuItem>
        )}
      </mui.core.TextField>
    </mui.core.Grid>
  );
};

export default RiskModelCategory;
