import { mui, React } from '_core';

type SelectFieldProps = {
  value: string;
  setValue: (_d: string) => void;
  label: string;
  options: Record<string, string>;
};

const SelectField: React.FC<SelectFieldProps> = ({ value, setValue, label, options }): React.ReactElement => (
  <mui.core.TextField
    select
    label={label}
    variant="outlined"
    size="small"
    value={value}
    onChange={(e) => setValue(e.target.value)}
    fullWidth
  >
    {Object.values(options).map((opt) => (
      <mui.core.MenuItem key={opt} value={opt}>
        {opt}
      </mui.core.MenuItem>
    ))}
  </mui.core.TextField>
);

export default SelectField;
