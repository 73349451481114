import React from 'react';

import { mui } from '../libs';

type CenteredLoaderProps = {
  top?: string;
  label?: string;
  height?: string;
  size?: number;
  fullPage?: boolean;
  color?: string;
};

/**
 * CenteredLoader component for displaying a centered loader
 *
 * @param top - Top margin of the loader
 * @param label - Label text for the loader
 * @param height - Height of the loader
 * @param size - Size of the loader
 * @param color - Optional color of the loader
 *
 * @returns A centered loader component
 */

const CenteredLoader: React.FC<CenteredLoaderProps> = ({
  top = '0px',
  label = 'Loading...',
  height = '100%',
  size = 14,
  fullPage = false,
  color = undefined,
}): React.ReactElement => {
  const theme = mui.styles.useTheme() as mui.core.Theme;
  const [opacity, setOpacity] = React.useState(0);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setOpacity(1);
    }, 0);
    return () => clearTimeout(timer);
  }, []);

  if (fullPage) {
    return (
      <mui.core.Box
        sx={{
          height: '100vh',
          background: theme.palette.secondary.dark,
          color: '#fff',
          opacity: opacity,
          transition: 'opacity 1s ease-in-out',
        }}
        display="flex"
        flexDirection="column"
        gap={4}
        alignItems="center"
        justifyContent="center"
      >
        <mui.core.CircularProgress sx={{ color: '#fff' }} size={24} />
        {label}
      </mui.core.Box>
    );
  }

  return (
    <mui.core.Box
      display="flex"
      marginTop={top}
      flex={1}
      alignItems="center"
      justifyContent="center"
      style={{ height: height }}
      pb={6}
      gap={2}
      sx={{
        opacity: opacity,
        transition: 'opacity 1s ease-in-out',
      }}
    >
      <mui.core.CircularProgress size={size} sx={{ color: color }} />
      <mui.core.Box>
        <mui.core.Typography
          color="textSecondary"
          variant="body2"
          sx={{
            fontWeight: '500',
            color: color ?? 'text.secondary',
          }}
        >
          {label}
        </mui.core.Typography>
      </mui.core.Box>
    </mui.core.Box>
  );
};

export default CenteredLoader;
