/* eslint-disable no-unused-vars */
import { ts } from '_core';
export enum INTERACTION_PARAMS {
  BY = 'by',
  TAB_VALUE = 'tabValue',
}

export type InteractionParams = {
  [INTERACTION_PARAMS.BY]: ts.enums.REPORT_ENUMS.UNIVERSE_COVERAGE_BY_ENUM;
  [INTERACTION_PARAMS.TAB_VALUE]?: number;
};

export type Params = InteractionParams;
export type DraftParams = Partial<Params>;
