import { _, ts } from '_core';

import { prepareDefinitionIndustries } from './definition';

const _getRepeatedByKey = (arr: any[], key = 'value'): any[] => {
  const grouped = _.groupBy(arr, key);
  const repeatedValues = _.pickBy(grouped, (group) => group.length > 1);
  return Object.values(repeatedValues) as any[];
};

const _validateIndustryFactors = (definition: ts.types.riskModel.DefinitionDraft) => {
  const errors = [] as string[];
  const fields: ts.types.riskModel.RiskModelErrorFields[] = [];

  const industryFactors = definition.categories?.find((c) => c.handle == 'industry') ?? {};

  // Validate repeated handles
  Object.values(_getRepeatedByKey(industryFactors.factors ?? [], 'handle')).forEach((repeated) => {
    errors.push(`${repeated[0].name} name is used more than once.`);
  });

  // Validate not empty factor
  (industryFactors.factors ?? []).forEach((f) => {
    if (_.isEmpty(f.inclusions)) errors.push(`Industry factor ${f.name} can't be empty.`);
  });

  // Validate not repeated subindustries
  const allIndustries = (industryFactors.factors ?? []).reduce((prev, curr) => {
    return prev.concat(curr.inclusions ?? []);
  }, []);

  Object.values(_getRepeatedByKey(allIndustries)).forEach((repeated) => {
    errors.push(`${repeated[0].levelFour} is used in more than one factor.`);
    fields.push(`subind_${repeated[0].value}`);
  });

  return { errors, fields };
};

export const _validateFactorDefinition = (newDefinition: ts.types.riskModel.DefinitionDraft) => {
  const definition = prepareDefinitionIndustries(newDefinition)[0];

  let errors = [];
  let fields: ts.types.riskModel.RiskModelErrorFields[] = [];

  const parameters = definition?.parameters as ts.types.riskModel.FactorDefinitionParameters;

  if (!definition?.universe_id) {
    errors.push('Universe is a required field.');
    fields.push('universe');
  }

  if (_.isNil(parameters?.window)) {
    errors.push('Num of return days is a required field.');
    fields.push('window');
  }

  const indFactorsVal = _validateIndustryFactors(definition);
  errors = errors.concat(indFactorsVal.errors);
  fields = fields.concat(indFactorsVal.fields);

  return { errors, fields };
};

export const _validateStatDefinition = (definition: ts.types.riskModel.DefinitionDraft) => {
  const errors = [];
  const fields: ts.types.riskModel.RiskModelErrorFields[] = [];

  const parameters = definition?.parameters as ts.types.riskModel.StatDefinitionParameters;

  if (!definition?.universe_id) {
    errors.push('Universe is a required field.');
    fields.push('universe');
  }

  if (_.isNil(parameters?.window)) {
    errors.push('Num of return days is a required field.');
    fields.push('window');
  }

  if (_.isNil(parameters?.asset_returns_min_periods)) {
    errors.push('Asset returns min days is a required field.');
    fields.push('asset_returns_min_periods');
  }

  const factorType =
    (_.isNil(parameters?.num_factors) && _.isNil(parameters?.max_factors) && _.isNil(parameters?.selection)) ||
    !_.isNil(parameters?.num_factors)
      ? 'fixed'
      : 'dynamic';

  if (factorType == 'fixed') {
    if (_.isNil(parameters?.num_factors)) errors.push('Num of factors is a required field.');
    else if (parameters?.num_factors < 1) errors.push('Num of factors must be greater than 0');
    else if (parameters?.num_factors > 100) errors.push('Num of factors must be less than 100');

    fields.push('num_factors');
  }

  if (factorType == 'dynamic') {
    if (_.isNil(parameters?.max_factors)) errors.push('Max number of factors is a required field.');
    else if (parameters?.max_factors < 1) errors.push('Max number of factors must be greater than 0');
    else if (parameters?.max_factors > 100) errors.push('Num of factors must be less than 100');
    fields.push('max_factors');
  }

  return { errors, fields };
};

const validateDefinition = (definition: ts.types.riskModel.DefinitionDraft) => {
  let globalValidation: any;
  if (definition?.parameters?.type == 'STAT') globalValidation = _validateStatDefinition(definition);
  else globalValidation = _validateFactorDefinition(definition);

  if (_.isEmpty(globalValidation.errors)) {
    return { valid: true };
  }
  return {
    valid: false,
    errors: globalValidation.errors,
    fields: globalValidation.fields,
  };
};

export default validateDefinition;
