import { _, mui, React, ts } from '_core';

import ChartWrapper from '../../common/chart-wrapper';
import createNestedBarChart from '../../common/charts/create-nested-bar-chart';

type ChartProps = {
  id: string;
  readOnly: boolean;
  widgetKey: string;
  loadWidgetData: (_payload: ts.types.widgets.WidgetGetDataParams) => Promise<ts.types.widgets.WidgetGetDataResponse>;
  returns: string;
  fullScreen: boolean;
  title: string;
};

const Chart: React.FC<ChartProps> = ({ id, readOnly, widgetKey, loadWidgetData, returns, fullScreen, title }) => {
  const [tab, setTab] = React.useState<'return' | 'risk' | 'adjusted'>('return');
  const [data, setData] = React.useState<ts.types.widgets.TableData>(null);
  const [error, setError] = React.useState<ts.types.common.ApiError>(null);
  const [showLegend, setShowLegend] = React.useState(false);

  const loadData = async () => {
    try {
      const response = await loadWidgetData({
        data: {
          file: returns,
          query: ['tab', 'equals', tab],
        },
      });

      if (response) {
        setData(_.map(response.data, (el) => _.omit(el, 'tab')));
      }
    } catch (err) {
      setError(err as ts.types.common.ApiError);
    }
  };

  React.useEffect(() => {
    if (returns) loadData();
    return () => setData(null);
  }, [returns, tab]);

  React.useEffect(() => {
    const format = tab !== 'adjusted' ? '#.##%' : '#.##';

    // Create line chart on data and scale changes
    if (!_.isNil(data)) {
      createNestedBarChart({
        id,
        data,
        fullScreen,
        setShowLegend,
        seriesOrder: (series) => _.sortBy(series.map((s) => Number(s) || 'category')).map((s) => s.toString()),
        format,
        exportTitle: title,
      });
    }
  }, [data]);

  const getTabs = () => {
    return (
      <mui.core.Tabs
        value={tab}
        indicatorColor="primary"
        textColor="primary"
        onChange={(e, newTab) => setTab(newTab)}
        aria-label="Return Horizon Tabs"
      >
        <mui.core.Tab label="Returns" value="return" />
        <mui.core.Tab label="Risk" value="risk" />
        <mui.core.Tab label="Risk Adjusted Returns" value="adjusted" />
      </mui.core.Tabs>
    );
  };

  return (
    <>
      <ChartWrapper
        id={id}
        readOnly={readOnly}
        widgetKey={widgetKey}
        data={data}
        showLegend={showLegend}
        warning={data?.length === 0 ? ['Empty File'] : []}
        error={error}
        tabs={getTabs()}
        fullScreen={fullScreen}
      />
    </>
  );
};

export default Chart;
