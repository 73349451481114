import { React, ts } from '_core';

import Chart from './chart';
import Form from './form';
import { DraftParams, Params } from './types';

type LinearAlphaPercentSignificanceProps = {
  id: string;
  readOnly: boolean;
  widgetKey: string;
  mode: ts.enums.CHART_MODE_ENUM;
  params: Params | DraftParams;
  setParams: (_p: DraftParams) => void;

  fullScreen?: boolean;
  context?: ts.types.signal.SignalAnalysisContext;
  loadWidgetData: (_payload: ts.types.widgets.WidgetGetDataParams) => Promise<ts.types.widgets.WidgetGetDataResponse>;
  widgetPaths?: Record<'linear_alpha_model_critical_pvalue', string>;

  title: string;
};

const LinearAlphaPercentSignificance: React.FC<LinearAlphaPercentSignificanceProps> = ({
  id,
  readOnly,
  widgetKey,
  loadWidgetData,
  widgetPaths,
  mode,
  params,
  setParams,
  fullScreen,
  context,
  title,
}) => {
  const renderJobComponent = () => (
    <Chart
      id={id}
      readOnly={readOnly}
      widgetKey={widgetKey}
      loadWidgetData={loadWidgetData}
      file={widgetPaths['linear_alpha_model_critical_pvalue']}
      fullScreen={fullScreen}
      definition={(context.definition as ts.types.signal.AlphaDefinition)?.json_definition as ts.types.signal.Lasso}
      title={title}
    />
  );

  return <>{mode == 'edit' ? <Form params={params} setParams={setParams} /> : renderJobComponent()}</>;
};

export default LinearAlphaPercentSignificance;
