import { _, config, helpers, hooks, mui, React, ts } from '_core';

import { GridRowParams } from '@mui/x-data-grid-premium';

import { GenerateColumns, GenerateHeatMappedColumns, TabularContext } from '../../tabular-context';
import { format } from '../../utils/helpers';

export type ProviderProps = {
  data?: Record<string, any>[];
  getColumns?: GenerateColumns;
  customRow?: () => React.ReactElement;
  rowClass?: (_row: Record<string, any>) => string;
  onRowClick?: (_p: GridRowParams<any>) => void;
  rowWidth?: number;
  rowHeight?: number;
  headerHeight?: number;
  customToolbar?: React.FC<{ columns: string[] }>;
  loading?: boolean;
  customHeight?: number;
  hideFilters?: boolean;
  hideDensity?: boolean;
  hideToolbar?: boolean;
  hideColumnWidth?: boolean;
  hideDownload?: boolean;
  alert?: ts.types.common.Alert;
  overrideHeight?: number;
  groupBy?: string[];
  expandedGroupIds?: ReadonlySet<unknown>;
  setExpandedGroupIds?: (_v: ReadonlySet<unknown>) => void;
  openPreferences?: () => void;
  initialTableParams?: ts.types.components.dataGrid.TableParams;
  updateTableParams?: (_v: ts.types.components.dataGrid.TableParams) => void;
  getHeatMappedCols?: GenerateHeatMappedColumns;
  heatMapColors?: ts.types.components.dataGrid.HeatMapColors;
  customStyles?: mui.core.SxProps<mui.core.Theme>;
  disableDefaultStyling?: boolean;
  setExternalEmbed?: (_json: ts.types.analysis.SyncTableEmbedMetadata) => void;
  triggerWidthChange: boolean;
};

type RegularDataGridContextTypes = {
  data: Record<string, any>[];
  getColumns: GenerateColumns;
  customRow: () => React.ReactElement;
  rowClass: (_row: Record<string, any>) => string;
  onRowClick: (_p: GridRowParams<any>) => void;
  rowWidth: number;
  rowHeight: number;
  headerHeight: number;
  customToolbar: React.FC<{ columns: string[] }>;
  loading: boolean;
  customHeight: number;
  hideFilters: boolean;
  hideDensity: boolean;
  hideToolbar: boolean;
  hideColumnWidth: boolean;
  hideDownload: boolean;
  alert: ts.types.common.Alert;
  overrideHeight: number;
  groupBy: string[];
  setGroupBy: (_v: string[]) => void;
  expandedGroupIds: ReadonlySet<unknown>;
  setExpandedGroupIds: (_v: ReadonlySet<unknown>) => void;
  preferenceKey: ts.enums.PREFERENCES_KEY_ENUM;
  preferenceTab: ts.enums.PREFERENCES_INNER_TABS_ENUM;
  firstPrefLoad: boolean;
  preferenceOptions: ts.types.userPreference.UserPreferenceDraft[];
  selectedPreference: ts.types.userPreference.UserPreferenceDraft;
  setCurrentView: (_v: number) => void;
  openPreferences: () => void;
  mappedColumns: any[];
  filteredRows: any;
  pinnedColumns: { left: string[] };
  sortColumns: mui.dataGrid.GridSortModel;
  setSortColumns: (_v: mui.dataGrid.GridSortModel) => void;
  loadingPreferences: boolean;
  handleColumnsResize: (_key: string, _width: number) => void;
  filterModel: mui.dataGrid.GridFilterModel;
  setFilterModel: (_v: mui.dataGrid.GridFilterModel) => void;
  heatMapColors?: ts.types.components.dataGrid.HeatMapColors;
  customStyles?: mui.core.SxProps<mui.core.Theme>;
  disableDefaultStyling?: boolean;
  heatMappedCols: string[];
  columnsWidth: ts.types.components.dataGrid.ColumnsWidth;
  setColumnsWidth: (_v: ts.types.components.dataGrid.ColumnsWidth) => void;
  fixedWidth?: ts.types.components.dataGrid.TableParams['fixed_width'];
  setFixedWidth: (_v: ts.types.components.dataGrid.TableParams['fixed_width']) => void;
  tableWidth?: number;
  setTableWidth: (_v: number) => void;
  triggerWidthChange: boolean;
};

const RegularDataGridContext = React.createContext<RegularDataGridContextTypes>(null);

// This context provider is passed to any component requiring the context
const Provider: React.FC<ProviderProps & { children: React.ReactElement }> = ({
  children,
  data,
  getColumns,
  customRow,
  rowClass,
  onRowClick,
  headerHeight,
  rowWidth,
  rowHeight,
  customToolbar,
  loading,
  customHeight,
  hideFilters,
  hideDensity,
  hideToolbar,
  hideColumnWidth,
  hideDownload,
  alert,
  overrideHeight,
  getHeatMappedCols,
  heatMapColors,
  customStyles,
  disableDefaultStyling,
  setExternalEmbed,
  triggerWidthChange,
}): React.ReactElement => {
  const tabularContext = React.useContext(TabularContext);
  const {
    sortColumns,
    filters,
    tableWidth,
    setTableWidth,
    userColumns,
    columnsWidth,
    fixedWidth,
    preferenceKey,
    firstPrefLoad,
    setColumnsWidth,
    setSortColumns,
    setFilters,
    setCurrentView,
    openPreferences,
    setFixedWidth,
    handleColumnsResize,
    loadingPreferences,
    preferenceOptions,
    selectedPreference,
    expandedGroupIds,
    setExpandedGroupIds,
    groupBy,
    setGroupBy,
    preferenceTab,
  } = tabularContext;

  const [dataColumns, setDataColumns] = React.useState<ts.types.components.dataGrid.ColumnsData>();
  const [filteredRows, setFilteredRows] = React.useState([]);

  const dataKeys = React.useMemo(() => {
    const localData = data || [];
    // Get the keys from the item of data that has all keys
    const numberOfKeys = _.max(localData.map((row) => Object.keys(row).length));
    return Object.keys(localData.find((row) => Object.keys(row).length == numberOfKeys) ?? {});
  }, [data]);

  // set columns by changes
  React.useEffect(() => {
    setDataColumns(
      getColumns(dataKeys, userColumns as ts.types.components.dataGrid.ColumnPreferences).filter(
        (el) => !el.condition || el.condition(config.features)
      )
    );
  }, [dataKeys, userColumns]);

  // Set heatmapped cols by changes
  const [heatMappedCols, setHeatMappedCols] = React.useState<string[]>([]);
  React.useEffect(() => {
    if (getHeatMappedCols)
      setHeatMappedCols(getHeatMappedCols(dataKeys, userColumns as ts.types.components.dataGrid.ColumnPreferences));
  }, [dataKeys, userColumns]);

  // When setting the fixed width we need to update all of the stored columns widths
  hooks.useEffectWithoutFirst(() => {
    if (fixedWidth?.width) {
      const affectedCols = fixedWidth.ignore_index ? dataColumns.filter((c) => !c.frozen) : dataColumns;

      setColumnsWidth(affectedCols.map((col) => ({ columnKey: col.key, columnWidth: fixedWidth?.width })));
    }
  }, [fixedWidth]);

  const mappedColumns = React.useMemo(
    () =>
      helpers.datagrid.buildMappedColumns({
        dataColumns,
        data,
        baseRowWidth: rowWidth,
        indexClass: 'data-grid-index-header',
        columnsWidth,
        fixedWidth,
        tableWidth,
      }),
    [dataColumns, columnsWidth, fixedWidth, tableWidth]
  );

  const pinnedColumns = React.useMemo(
    () => ({
      left: mappedColumns?.filter((col) => col.frozen).map((col) => col.field),
    }),
    [mappedColumns]
  );

  React.useEffect(() => {
    let formattedData = format(data);
    formattedData = formattedData?.map((row, idx) => {
      return {
        id: idx,
        ...row,
      };
    });

    if (!sortColumns?.length && dataColumns?.length) {
      const columnWithDefaultSort = dataColumns.find((col) => col.defaultSortComparator);
      if (columnWithDefaultSort) {
        const sortedData = [...formattedData].sort((a, b) =>
          columnWithDefaultSort.defaultSortComparator(a[columnWithDefaultSort.key], b[columnWithDefaultSort.key])
        );
        setFilteredRows(sortedData);
        return;
      }
    }
    setFilteredRows(formattedData);
  }, [data, dataColumns]);

  React.useEffect(() => {
    if (setExternalEmbed && !_.isEmpty(filteredRows)) {
      setExternalEmbed({
        fixedWidth,
        rows: filteredRows || [],
        columns: (mappedColumns || []) as mui.dataGrid.GridColDef[],
        pinnedColumns,
        heatMappedCols,
        heatMapColors,
      } as ts.types.analysis.SyncTableEmbedMetadata);
    }
  }, [filteredRows, mappedColumns, pinnedColumns, fixedWidth]);

  return (
    <RegularDataGridContext.Provider
      value={{
        data,
        getColumns,
        customRow,
        rowClass,
        onRowClick,
        headerHeight,
        rowWidth,
        rowHeight,
        customToolbar,
        loading,
        customHeight,
        hideFilters,
        hideDensity,
        hideColumnWidth,
        hideDownload,
        alert,
        overrideHeight,
        groupBy,
        setGroupBy,
        expandedGroupIds,
        setExpandedGroupIds,
        preferenceKey,
        preferenceTab,
        firstPrefLoad,
        preferenceOptions,
        selectedPreference,
        setCurrentView,
        openPreferences,
        mappedColumns,
        filteredRows,
        sortColumns,
        loadingPreferences,
        pinnedColumns,
        handleColumnsResize,
        filterModel: filters,
        setFilterModel: setFilters as (_v: mui.dataGrid.GridFilterModel) => void,
        setSortColumns,
        hideToolbar,
        heatMappedCols,
        heatMapColors,
        customStyles,
        disableDefaultStyling,
        columnsWidth,
        setColumnsWidth,
        fixedWidth,
        setFixedWidth,
        tableWidth,
        setTableWidth,
        triggerWidthChange,
      }}
    >
      {children}
    </RegularDataGridContext.Provider>
  );
};

const RegularDataGridContextProvider = Provider;

Provider.defaultProps = {
  data: [],
  customRow: null,
  rowClass: null,
  rowHeight: 20,
  rowWidth: 150,
  headerHeight: 35,
  customToolbar: null,
  loading: false,
  customHeight: null,
  hideFilters: false,
  hideDensity: false,
  hideToolbar: false,
  hideColumnWidth: false,
  alert: null,
  overrideHeight: null,
  groupBy: undefined,
  getColumns: (colKeys: string[]) => colKeys.map((key) => ({ key, name: key })),
  expandedGroupIds: undefined,
  setExpandedGroupIds: undefined,
  customStyles: undefined,
  disableDefaultStyling: false,
};

export { RegularDataGridContext };
export { RegularDataGridContextProvider };
