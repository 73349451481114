import { React, ui } from '_core';

type ETFFieldProps = {
  value?: string | string[];
  setValue: (_d: string | string[]) => void;
  multiple?: boolean;
};

const ETFField: React.FC<ETFFieldProps> = ({ value, setValue, multiple }): React.ReactElement => (
  <ui.ETFSelector
    value={value}
    setValue={(val) => {
      setValue(val);
    }}
    multiple={multiple}
  />
);

ETFField.defaultProps = {
  value: '',
  multiple: false,
};

export default ETFField;
