import { config, mui, React, ts, ui } from '_core';

import { PreferencesContext } from 'views/preferences/preferences-context';

const PreferencesMainMenu = () => {
  const preferencesContext = React.useContext(PreferencesContext);
  const { preferencesSelectedTab, handleTabChange } = preferencesContext;

  const theme = mui.styles.useTheme() as mui.core.Theme;

  const menuItem = {
    marginBottom: '5px',
    padding: '10px',
    maxWidth: 'none',
    borderRadius: '4px!important',
    textAlign: 'left',
    alignItems: 'flex-start',
    fontSize: '14px',
    fontWeight: 500,
    color: '#202541',
    '&.Mui-selected': {
      background: '#E8ECF3',
      borderRight: '0!important',
    },
    '& svg': {
      fontSize: '15px',
    },
  };

  return (
    <mui.core.Box
      sx={{
        background: theme.palette.background.default,
        height: '100%',
        width: '100%',
        padding: '20px',
        '& .MuiTabs-indicator': {
          display: 'none',
        },
      }}
    >
      <mui.core.Typography variant="h5" mb={5}>
        User Preferences
      </mui.core.Typography>
      <mui.core.Tabs
        value={preferencesSelectedTab}
        aria-label="Preferences tabs"
        orientation="vertical"
        onChange={handleTabChange}
      >
        <mui.core.Tab
          label={
            <mui.core.Box display="flex" alignItems="center">
              <ui.ResourceIcon resource={ts.enums.UI_RESOURCE_TYPE_ENUM.UNIVERSE} />
              <mui.core.Box ml={2}>Universes</mui.core.Box>
            </mui.core.Box>
          }
          value={ts.enums.PREFERENCES_TABS_ENUM.UNIVERSES}
          sx={menuItem}
        />
        <mui.core.Tab
          label={
            <mui.core.Box display="flex" alignItems="center">
              <ui.ResourceIcon resource={ts.enums.UI_RESOURCE_TYPE_ENUM.SIGNAL} />
              <mui.core.Box ml={2}>Signals</mui.core.Box>
            </mui.core.Box>
          }
          value={ts.enums.PREFERENCES_TABS_ENUM.SIGNALS}
          sx={menuItem}
        />
        {config.features.backtest && (
          <mui.core.Tab
            label={
              <mui.core.Box display="flex" alignItems="center">
                <ui.ResourceIcon resource={ts.enums.UI_RESOURCE_TYPE_ENUM.BACKTEST} />
                <mui.core.Box ml={2}>Backtests</mui.core.Box>
              </mui.core.Box>
            }
            value={ts.enums.PREFERENCES_TABS_ENUM.BACKTESTS}
            sx={menuItem}
          />
        )}
        <mui.core.Tab
          label={
            <mui.core.Box display="flex" alignItems="center">
              <ui.ResourceIcon resource={ts.enums.UI_RESOURCE_TYPE_ENUM.TIME_SERIES} />
              <mui.core.Box ml={2}>Time Series</mui.core.Box>
            </mui.core.Box>
          }
          value={ts.enums.PREFERENCES_TABS_ENUM.TIME_SERIES}
          sx={menuItem}
          style={{ marginBottom: '0.5rem', borderBottom: `1px solid ${theme.palette.gray.main}` }}
        />

        <mui.core.Tab
          label={
            <mui.core.Box display="flex" alignItems="center">
              <ui.ResourceIcon resource={ts.enums.UI_RESOURCE_TYPE_ENUM.SIGNAL_SET} />
              <mui.core.Box ml={2}>Signal Sets</mui.core.Box>
            </mui.core.Box>
          }
          value={ts.enums.PREFERENCES_TABS_ENUM.SIGNAL_SETS}
          sx={menuItem}
        />
        <mui.core.Tab
          label={
            <mui.core.Box display="flex" alignItems="center">
              <ui.ResourceIcon resource={ts.enums.UI_RESOURCE_TYPE_ENUM.BACKTEST_SET} />
              <mui.core.Box ml={2}>Backtest Sets</mui.core.Box>
            </mui.core.Box>
          }
          value={ts.enums.PREFERENCES_TABS_ENUM.BACKTEST_SETS}
          sx={menuItem}
        />
        {config.features.basket && (
          <mui.core.Tab
            label={
              <mui.core.Box display="flex" alignItems="center">
                <ui.ResourceIcon resource={ts.enums.UI_RESOURCE_TYPE_ENUM.BASKET} />
                <mui.core.Box ml={2}>Baskets</mui.core.Box>
              </mui.core.Box>
            }
            value={ts.enums.PREFERENCES_TABS_ENUM.BASKETS}
            sx={menuItem}
            style={{ marginBottom: '0.5rem', borderBottom: `1px solid ${theme.palette.gray.main}` }}
          />
        )}

        <mui.core.Tab
          label={
            <mui.core.Box display="flex" alignItems="center">
              <ui.ResourceIcon resource={ts.enums.UI_RESOURCE_TYPE_ENUM.RISK_MODEL} />
              <mui.core.Box ml={2}>Risk Models</mui.core.Box>
            </mui.core.Box>
          }
          value={ts.enums.PREFERENCES_TABS_ENUM.RISK_MODELS}
          sx={menuItem}
        />
        {config.features.production_mode && config.productionResources && (
          <mui.core.Tab
            label={
              <mui.core.Box display="flex" alignItems="center">
                <ui.ResourceIcon resource={ts.enums.UI_RESOURCE_TYPE_ENUM.PORTFOLIO} />
                <mui.core.Box ml={2}>Portfolios</mui.core.Box>
              </mui.core.Box>
            }
            value={ts.enums.PREFERENCES_TABS_ENUM.PORTFOLIOS}
            sx={menuItem}
            style={{ marginBottom: '0.5rem', borderBottom: `1px solid ${theme.palette.gray.main}` }}
          />
        )}

        {config.features.backtest && (
          <mui.core.Tab
            label={
              <mui.core.Box display="flex" alignItems="center">
                <ui.ResourceIcon resource={ts.enums.PREFERENCES_SECTION_ENUM.FIXED_COST} />
                <mui.core.Box ml={2}>Fixed Costs</mui.core.Box>
              </mui.core.Box>
            }
            value={ts.enums.PREFERENCES_TABS_ENUM.FIXED_COSTS}
            sx={menuItem}
          />
        )}
        <mui.core.Tab
          label={
            <mui.core.Box display="flex" alignItems="center">
              <ui.ResourceIcon resource={ts.enums.PREFERENCES_SECTION_ENUM.REPORT_LAYOUT} />
              <mui.core.Box ml={2}>Report Layouts</mui.core.Box>
            </mui.core.Box>
          }
          value={ts.enums.PREFERENCES_TABS_ENUM.REPORT_LAYOUTS}
          sx={menuItem}
          style={{ marginBottom: '0.5rem', borderBottom: `1px solid ${theme.palette.gray.main}` }}
        />

        <mui.core.Tab
          label={
            <mui.core.Box display="flex" alignItems="center">
              <ui.ResourceIcon resource={ts.enums.PREFERENCES_SECTION_ENUM.OTHER} />
              <mui.core.Box ml={2}>Other</mui.core.Box>
            </mui.core.Box>
          }
          value={ts.enums.PREFERENCES_TABS_ENUM.OTHER}
          sx={menuItem}
        />
      </mui.core.Tabs>
    </mui.core.Box>
  );
};

export default PreferencesMainMenu;
