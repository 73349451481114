import { ts } from '_core';

import { DraftParams, INTERACTION_PARAMS } from './types';

export default [
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.BACKTEST_SET_ALL_SUMMARY,
    title: 'Summary',
    helpLabel: 'ptw6uqlq',
    form: false,
    defaultParams: {
      format_for_ui: true,
      file: 'portfolios',
      aggregation_params: {
        force_all_row: true,
      },
    } as DraftParams,
    interactionParams: Object.values(INTERACTION_PARAMS),
    clearInteractionOnRegenerate: ['table_dtypes'],
    neededFiles: ['backtest_set_summary'],
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.BACKTEST_SET],

    disableAdd: true,
  },
] satisfies ts.types.analysis.WidgetConfig[];
