import { helpers, ts } from '_core';

export default {
  [ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.MAIN]: [
    {
      key: 'performers',
      name: 'Performers',
      formatter: helpers.tableFormatters.formatTo('string', { align: 'left' }),
      filter: 'string',
      isFixed: true,
    },
    {
      key: 'pnl',
      name: 'PNL',
      formatter: helpers.tableFormatters.formatTo('float', {
        align: 'right',
        roundDigits: 2,
        multiplier: 100,
        suffix: '%',
      }),
      filter: 'number',
    },
    {
      key: 'portfolio_return',
      name: 'Asset Return',
      formatter: helpers.tableFormatters.formatTo('float', {
        align: 'right',
        roundDigits: 2,
        multiplier: 100,
        suffix: '%',
      }),
      filter: 'number',
    },
    {
      key: 'days_held_in_portfolio',
      name: 'Days Held in Portfolio',
      formatter: helpers.tableFormatters.formatTo('integer', { align: 'right' }),
      filter: 'number',
    },
    {
      key: 'days_long',
      name: 'Days Long',
      formatter: helpers.tableFormatters.formatTo('integer', { align: 'right' }),
      filter: 'number',
    },
    {
      key: 'days_short',
      name: 'Days Short',
      formatter: helpers.tableFormatters.formatTo('integer', { align: 'right' }),
      filter: 'number',
    },
    {
      key: 'avg_weight',
      name: 'Average Weight',
      formatter: helpers.tableFormatters.formatTo('float', {
        align: 'right',
        roundDigits: 2,
        multiplier: 100,
        suffix: '%',
      }),
      filter: 'number',
    },
    {
      key: 'name',
      name: 'Name',
      formatter: helpers.tableFormatters.formatTo('string', { align: 'left' }),
      filter: 'string',
    },
    {
      key: 'asset',
      name: 'Asset',
      formatter: helpers.tableFormatters.formatTo('string', { align: 'left' }),
      filter: 'string',
    },
    {
      key: 'asset_type',
      name: 'Type',
      formatter: helpers.tableFormatters.formatTo('string', { align: 'left' }),
      filter: 'string',
    },
    {
      key: 'ticker',
      name: 'Ticker',
      formatter: helpers.tableFormatters.formatTo('string', { align: 'left' }),
      filter: 'string',
    },
    {
      key: 'rbics_l1_name',
      name: 'Sector Name',
      formatter: helpers.tableFormatters.formatTo('string', { align: 'left' }),
      filter: 'string',
    },
    {
      key: 'rbics_l2_name',
      name: 'Sub-Sector Name',
      formatter: helpers.tableFormatters.formatTo('string', { align: 'left' }),
      filter: 'string',
    },
    {
      key: 'rbics_l3_name',
      name: 'Industry Name',
      formatter: helpers.tableFormatters.formatTo('string', { align: 'left' }),
      filter: 'string',
    },
    {
      key: 'rbics_l4_name',
      name: 'Sub-Industry Name',
      formatter: helpers.tableFormatters.formatTo('string', { align: 'left' }),
      filter: 'string',
    },
    {
      key: 'rbics_l1_id',
      name: 'Sector Code',
      formatter: helpers.tableFormatters.formatTo('integer', { align: 'right' }),
      filter: 'string',
    },
    {
      key: 'rbics_l2_id',
      name: 'Sub-Sector Code',
      formatter: helpers.tableFormatters.formatTo('integer', { align: 'right' }),
      filter: 'string',
    },
    {
      key: 'rbics_l3_id',
      name: 'Industry Code',
      formatter: helpers.tableFormatters.formatTo('integer', { align: 'right' }),
      filter: 'string',
    },
    {
      key: 'rbics_l4_id',
      name: 'Sub-Industry Code',
      formatter: helpers.tableFormatters.formatTo('integer', { align: 'right' }),
      filter: 'string',
    },
    {
      key: 'cusip',
      name: 'Cusip',
      formatter: helpers.tableFormatters.formatTo('string', { align: 'left' }),
      filter: 'string',
      condition: (features: ts.types.organization.OrganizationFeatures) => !features.hide_cusip_col,
    },
    {
      key: 'isin',
      name: 'ISIN',
      formatter: helpers.tableFormatters.formatTo('string', { align: 'left' }),
      filter: 'string',
      condition: (features: ts.types.organization.OrganizationFeatures) => !features.hide_isin_col,
    },
    {
      key: 'currency',
      name: 'Currency',
      formatter: helpers.tableFormatters.formatTo('string', { align: 'center' }),
      filter: 'string',
    },
    {
      key: 'issuer_country',
      name: 'Issuer Country',
      formatter: helpers.tableFormatters.formatTo('string', { align: 'center' }),
      filter: 'string',
    },
    {
      key: 'quotation_country',
      name: 'Quotation Country',
      formatter: helpers.tableFormatters.formatTo('string', { align: 'center' }),
      filter: 'string',
    },
  ] as ts.types.components.dataGrid.ColumnsData,
};
