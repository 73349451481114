import React from 'react';

import AssetSelectorSearchForm from './search-form';
import AssetSelectorSearchResults from './search-results';
import { countries } from '../../../data';
import { useEffectWithoutFirst } from '../../../hooks';
import { _, mui } from '../../../libs';
import * as types from '../../../types';
import Button from '../../button';
import DialogTitle from '../../dialog-title';
import * as assetsUtils from '../asset-selector-utils';

type AssetSelectorModalProps = {
  assets: types.assetSelector.AssetSelectorMetaData[];
  setAssets: (_value: types.assetSelector.AssetSelectorMetaData[]) => void;
  openModal: boolean;
  setOpenModal: (_openModal: boolean) => void;
  dates: types.assetSelector.AssetSelectorDates;
  anchorEl: HTMLAnchorElement;
  customCountries?: string[];
  multiple?: boolean;
  initialSearch?: {
    searchTerm: string;
    countries?: string[];
    identifier?: types.assetSelector.AssetParams['identifier'];
  };
};

const PAGINATION_LIMIT = 20;

const AssetSelectorModal: React.FC<AssetSelectorModalProps> = ({
  assets,
  setAssets,
  openModal,
  setOpenModal,
  anchorEl,
  multiple,
  customCountries,
  dates,
  initialSearch,
}): React.ReactElement => {
  const filterValidCountries = (localCountries: string[]) => {
    return localCountries.filter((lc) => countries.map((c) => c.code).includes(lc));
  };

  const [params, setParams] = React.useState<types.assetSelector.AssetParams>({
    search_text: initialSearch?.searchTerm || '',
    quotation_countries: filterValidCountries(initialSearch?.countries || []),
    identifier: initialSearch?.identifier || undefined,
  });

  // Pagination variables
  const offset = React.useRef(0);
  const scrollFinished = React.useRef(false);

  const [assetOptions, setAssetOptions] = React.useState<types.assetSelector.AssetObj[]>(null);
  const [selectedAssets, setSelectedAssets] = React.useState(assets);

  const [loadingAssets, setLoadingAssets] = React.useState(initialSearch ? true : false);

  const [infiniteScrollLoading, setInfiniteScrollLoading] = React.useState(false);

  const [error, setError] = React.useState<string>(null);

  const debounceAssetSearch = React.useCallback(
    _.debounce(async (params) => {
      setLoadingAssets(true);
      setAssetOptions([] as types.assetSelector.AssetObj[]);
      setError(null);

      scrollFinished.current = false;
      offset.current = 0;

      const queryResult = await assetsUtils.queryAsset(params, PAGINATION_LIMIT, offset.current, dates);
      setLoadingAssets(false);

      if (queryResult.error) setError(queryResult.response);
      else if (!queryResult.error) {
        const resultAssets = queryResult.response;
        if (resultAssets.length < PAGINATION_LIMIT) scrollFinished.current = true;
        setAssetOptions(resultAssets.assets);
      }
    }, 750),
    []
  );

  React.useEffect(() => {
    if (initialSearch)
      debounceAssetSearch({
        identifier: initialSearch.identifier || null,
        search_text: initialSearch.searchTerm || '',
        quotation_countries: initialSearch.countries || [],
      });
  }, []);

  useEffectWithoutFirst(() => {
    if (!_.isEmpty(params.search_text) || !_.isEmpty(params.quotation_countries)) debounceAssetSearch(params);
  }, [params]);

  const loadOnScroll = async () => {
    if (infiniteScrollLoading || scrollFinished.current) return;

    offset.current += PAGINATION_LIMIT;
    setInfiniteScrollLoading(true);

    const queryResult = await assetsUtils.queryAsset(params, PAGINATION_LIMIT, offset.current, dates);
    setInfiniteScrollLoading(false);

    if (queryResult.error) {
      offset.current -= PAGINATION_LIMIT;
      setError(queryResult.response);
    } else if (!queryResult.error) {
      const resultAssets = queryResult.response.assets || [];
      if (resultAssets.length < PAGINATION_LIMIT) {
        scrollFinished.current = true;
      }
      const localOptions: types.assetSelector.AssetObj[] = [...assetOptions, ...resultAssets];
      setAssetOptions(localOptions);
    }
  };

  // Everytime we open the modal, we need to set the selected assets with the ones already added.
  React.useEffect(() => {
    if (openModal) setSelectedAssets(assets);
  }, [openModal]);

  const handleSubmit = () => {
    setAssets(selectedAssets);
    setAssetOptions(null);
    setSelectedAssets(null);
    setOpenModal(false);
    setParams({
      search_text: '',
      quotation_countries: [],
    });
  };

  const count = React.useMemo(() => {
    const assetsFids = _.map(assets || [], 'fid');

    const selAssetsFids = _.map(selectedAssets || [], 'fid');

    if (_.isEqual(assetsFids.sort(), selAssetsFids.sort())) return 0;
    return selAssetsFids.length;
  }, [assets, selectedAssets]);

  const innerForm = () => (
    <mui.core.Stack sx={{ minWidth: '800px', height: '520px' }} p={4} pt={6}>
      <AssetSelectorSearchForm
        params={params}
        setParams={setParams}
        customCountries={customCountries}
        openModal={openModal}
      />
      <mui.core.Box flex={1}>
        <AssetSelectorSearchResults
          options={assetOptions}
          loadingAssets={loadingAssets}
          error={error}
          selectedAssets={selectedAssets}
          multiple={multiple}
          setSelectedAssets={(v) => setSelectedAssets(v)}
          loadOnScroll={loadOnScroll}
          infiniteScrollLoading={infiniteScrollLoading}
        />
      </mui.core.Box>
      <mui.core.Box>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          loading={loadingAssets}
          disabled={loadingAssets || !count}
          sx={{ whiteSpace: 'nowrap' }}
        >
          {count
            ? multiple
              ? `Select ${count} Asset(s)`
              : 'Select Asset'
            : multiple
              ? 'Select Assets'
              : 'Select Asset'}
        </Button>
      </mui.core.Box>
    </mui.core.Stack>
  );

  if (anchorEl)
    return (
      <mui.core.Popover
        open={openModal}
        anchorEl={anchorEl}
        onClose={() => setOpenModal(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        {innerForm()}
      </mui.core.Popover>
    );

  return (
    <mui.core.Dialog open={openModal} onClose={() => setOpenModal(false)} fullWidth maxWidth={'md'}>
      <DialogTitle closeAction={() => setOpenModal(false)}>Manual Map</DialogTitle>
      {innerForm()}
    </mui.core.Dialog>
  );
};

export default AssetSelectorModal;
