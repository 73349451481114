import React from 'react';

import ETFSearchForm from './search-form';
import ETFSearchResults from './search-results';
import { useEffectWithoutFirst } from '../../../hooks';
import { _, mui } from '../../../libs';
import * as types from '../../../types';
import Button from '../../button';
import CenteredLoader from '../../centered-loader';
import * as etfUtils from '../etf-selector-utils';

const PopoverContainer = (props: mui.core.BoxProps) => (
  <mui.core.Box
    {...props}
    sx={{
      minWidth: 1000,
      maxWidth: 1000,
      ...props.sx,
    }}
  >
    {props.children}
  </mui.core.Box>
);

const FiltersContainer = (props: mui.core.BoxProps) => {
  const theme = mui.styles.useTheme() as mui.core.Theme;

  return (
    <mui.core.Box
      {...props}
      sx={{
        background: (theme.palette as any).background.default,
        height: '100%',
        ...props.sx,
      }}
    >
      {props.children}
    </mui.core.Box>
  );
};

type EtfModalProps = {
  etfs: types.etf.ETFAssetMetaData | types.etf.ETFAssetMetaData[];
  setEtfs: (_value: types.etf.ETFAssetMetaData | types.etf.ETFAssetMetaData[]) => void;
  openModal: boolean;
  setOpenModal: (_openModal: boolean) => void;
  anchorEl: HTMLAnchorElement;
  multiple: boolean;
  filtersOptions: types.etf.ETFFiltersOptions;
  loadingFilterOptions: boolean;
};

const PAGINATION_LIMIT = 20;

const EtfModal: React.FC<EtfModalProps> = ({
  etfs,
  setEtfs,
  openModal,
  setOpenModal,
  anchorEl,
  multiple,
  filtersOptions,
  loadingFilterOptions,
}): React.ReactElement => {
  const [params, setParams] = React.useState<types.etf.ETFParams>({
    search_text: '',
    filters: {} as types.etf.ETFFilters,
    uiFilters: {} as types.etf.ETFFilters,
  });

  // Pagination variables
  const offset = React.useRef(0);
  const scrollFinished = React.useRef(false);
  const [etfOptions, setEtfOptions] = React.useState<types.etf.ETFObj[]>(null);
  const [selectedETFs, setSelectedETFs] = React.useState(etfs);

  const [loadingEtf, setLoadingEtf] = React.useState(false);
  const [infiniteScrollLoading, setInfiniteScrollLoading] = React.useState(false);
  const [error, setError] = React.useState<string>(null);

  const debounceETFSearch = React.useCallback(
    _.debounce(async (params) => {
      setLoadingEtf(true);
      setEtfOptions([] as types.etf.ETFObj[]);
      setError(null);

      scrollFinished.current = false;
      offset.current = 0;

      const queryResult = await etfUtils.queryETF(params, PAGINATION_LIMIT, offset.current);
      setLoadingEtf(false);

      if (queryResult.error) setError(queryResult.response);
      else if (!queryResult.error) {
        const resultEtfs = queryResult.response;
        if (resultEtfs.length < PAGINATION_LIMIT) scrollFinished.current = true;
        setEtfOptions(resultEtfs.assets);
      }
    }, 750),
    []
  );

  useEffectWithoutFirst(() => {
    if (!_.isEmpty(params.search_text) || !_.isEmpty(params.filters)) debounceETFSearch(params);
  }, [params]);

  // Everytime we open the modal, we need to set the selected etfs with the ones already added.
  React.useEffect(() => {
    if (openModal) setSelectedETFs(etfs);
  }, [openModal]);

  const handleSubmit = () => {
    setEtfs(selectedETFs);
    setEtfOptions(null);
    setOpenModal(false);
    setSelectedETFs(null);
    setParams({
      search_text: '',
      filters: {} as types.etf.ETFFilters,
      uiFilters: {} as types.etf.ETFFilters,
    });
  };

  const count = React.useMemo(() => {
    const etfsFids = multiple
      ? _.map((etfs || []) as types.etf.ETFAssetMetaData[], 'fid')
      : !_.isEmpty(etfs)
        ? [(etfs as types.etf.ETFAssetMetaData).fid]
        : [];

    const selETFsFids = multiple
      ? _.map(selectedETFs as types.etf.ETFAssetMetaData[], 'fid')
      : !_.isEmpty(selectedETFs)
        ? [(selectedETFs as types.etf.ETFAssetMetaData).fid]
        : [];

    return _.without(selETFsFids, ...etfsFids).length;
  }, [etfs, selectedETFs]);

  const loadOnScroll = async () => {
    if (infiniteScrollLoading || scrollFinished.current) return;

    offset.current += PAGINATION_LIMIT;
    setInfiniteScrollLoading(true);

    const queryResult = await etfUtils.queryETF(params, PAGINATION_LIMIT, offset.current);
    setInfiniteScrollLoading(false);

    if (queryResult.error) {
      offset.current -= PAGINATION_LIMIT;
      setError(queryResult.response);
    } else if (!queryResult.error) {
      const resultEtfs = queryResult.response.assets || [];
      if (resultEtfs.length < PAGINATION_LIMIT) {
        scrollFinished.current = true;
      }
      const localOptions: types.etf.ETFObj[] = [...etfOptions, ...resultEtfs];
      setEtfOptions(localOptions);
    }
  };

  return (
    <mui.core.Popover
      open={openModal}
      anchorEl={anchorEl}
      onClose={() => setOpenModal(false)}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <PopoverContainer>
        {loadingFilterOptions ? (
          <CenteredLoader label="Loading filters..." top="100px" />
        ) : (
          <mui.core.Grid container>
            <mui.core.Grid item md={5}>
              <FiltersContainer p={3}>
                <ETFSearchForm
                  params={params}
                  setParams={setParams}
                  filtersOptions={filtersOptions}
                  loadingFilterOptions={loadingFilterOptions}
                  openModal={openModal}
                />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                  loading={loadingEtf}
                  disabled={loadingEtf || !count}
                  fullWidth
                >
                  {count ? (multiple ? `Add ${count} ETF(s)` : 'Select ETF') : multiple ? 'Add ETFs' : 'Select ETF'}
                </Button>
              </FiltersContainer>
            </mui.core.Grid>

            <mui.core.Grid item md={7}>
              <ETFSearchResults
                options={etfOptions}
                loadingEtf={loadingEtf}
                error={error}
                selectedETFs={selectedETFs}
                multiple={multiple}
                setSelectedETFs={setSelectedETFs}
                loadOnScroll={loadOnScroll}
                infiniteScrollLoading={infiniteScrollLoading}
              />
            </mui.core.Grid>
          </mui.core.Grid>
        )}
      </PopoverContainer>
    </mui.core.Popover>
  );
};

export default EtfModal;
