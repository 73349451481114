import { hooks, mui, React, ts, ui } from '_core';

import { DraftParams } from './types';

type FormProps = {
  params: DraftParams;
  setParams: (_p: DraftParams) => void;
  context?: ts.types.signal.SignalAnalysisContext;
};

const Form: React.FC<FormProps> = ({ params, setParams, context }) => {
  const [returnHorizons, setReturnHorizons] = React.useState<ts.types.returnHorizon.ReturnHorizon[]>(
    params?.return_horizons || []
  );
  const [benchmark, setBenchmark] = React.useState(params.benchmark || null);
  const [withBenchmark, setWithBenchmark] = React.useState(params.with_benchmark || false);
  const [selectedHistories, setSelectedHistories] = React.useState(params.histories || []);

  const uiStyles = hooks.useUiStyles();

  React.useEffect(() => {
    const data = {
      ...params,
      return_horizons: returnHorizons,
      histories: selectedHistories,
      with_benchmark: withBenchmark,
    };

    if (withBenchmark) data.benchmark = benchmark;
    else if (data.benchmark) delete data.benchmark;

    setParams({ statistics_type: params.statistics_type, ...data });
  }, [returnHorizons, withBenchmark, selectedHistories, benchmark]);

  return (
    <mui.core.Box sx={uiStyles.widgetEditMode}>
      <mui.core.Box mt={4}>
        <ui.WidgetReturnHorizon<true>
          value={returnHorizons}
          setValue={(val) => setReturnHorizons(val)}
          multiple
          context={context}
        />
      </mui.core.Box>
      <mui.core.Box mt={5}>
        <ui.WidgetHistories<true>
          multiple
          value={selectedHistories}
          setValue={setSelectedHistories}
          context={context}
        />
      </mui.core.Box>
      <mui.core.Box mt={2}>
        <mui.core.FormControlLabel
          control={
            <mui.core.Switch size="small" checked={withBenchmark} onChange={() => setWithBenchmark(!withBenchmark)} />
          }
          label="Has benchmark"
        />
      </mui.core.Box>

      {withBenchmark && (
        <mui.core.Box mt={2}>
          <ui.Benchmark
            value={benchmark || undefined}
            setValue={setBenchmark}
            universeContextPath={'$universe.handle'}
            enableAnnualizedSignals
            universeIdPath="universe_id"
          />
        </mui.core.Box>
      )}
    </mui.core.Box>
  );
};

export default Form;
