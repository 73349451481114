/* eslint-disable no-unused-vars */

import { BENCHMARK_TYPES_ENUM } from '@local/finsera-core/src/enums';

export enum INTERACTION_PARAMS {
  IS_BASKET = 'isBasket',
}

export type JobParams = {
  type: 'cumulative';
  add_zero: true;
  benchmark?: Record<string, BENCHMARK_TYPES_ENUM | string | number>;
  with_benchmark: boolean;
};

export type InteractionParams = {
  [INTERACTION_PARAMS.IS_BASKET]: boolean;
};

export type Params = JobParams & InteractionParams;
export type DraftParams = Partial<Params>;
