import { hooks, mui, React } from '_core';

import { getFieldKeys } from 'views/backtest-sets/backtest-set-utils/definition';
import * as prefColumns from 'views/preferences/columns-config';

import { DraftParams } from './types';

type FormProps = {
  params: DraftParams;
  setParams: (_p: DraftParams) => void;
  formOptions: { key: string; label: string }[];
};

const Form: React.FC<FormProps> = ({ params, setParams, formOptions }) => {
  const [metric, setMetric] = React.useState(params.metric);
  const [selectedParam, setSelectedParam] = React.useState(params.selectedParam);

  const uiStyles = hooks.useUiStyles();

  const excludedColumns = [
    'year',
    'start_date',
    'end_date',
    'cumulative_benchmark_return',
    'annualized_benchmark_risk',
    'annualized_benchmark_risk_adjusted_return',
    'average_universe_name_count',
  ];

  const columns = prefColumns.backtestTabularOutputColumns['summary'].filter((c) => !excludedColumns.includes(c.key));

  React.useEffect(() => {
    const data = { metric, selectedParam };
    setParams({ ...params, ...data });
  }, [metric, selectedParam]);

  const fieldKeys = React.useMemo(getFieldKeys, []);

  // Handle options adding one in case we had a value that is not
  // supported anymore on the current backtest config
  const localFormOptions = React.useMemo(() => {
    if (selectedParam && !formOptions.map((f) => f.key).includes(selectedParam)) {
      const foundSelectedParam = fieldKeys.find((f) => f.key == selectedParam)?.label;
      return [
        ...formOptions,
        {
          key: selectedParam,
          label: foundSelectedParam ? `Not in current definition (${foundSelectedParam})` : 'Not in current definition',
        },
      ];
    }

    return formOptions;
  }, [selectedParam]);

  return (
    <mui.core.Box sx={uiStyles.widgetEditMode}>
      <mui.core.Grid container spacing={4}>
        <mui.core.Grid item xs={12} md={4}>
          <mui.core.TextField
            select
            label="Parameter"
            variant="outlined"
            size="small"
            value={selectedParam}
            onChange={(e) => setSelectedParam(e.target.value)}
            fullWidth
            required
          >
            {localFormOptions.map((opt) => (
              <mui.core.MenuItem key={opt.key} value={opt.key}>
                {opt.label}
              </mui.core.MenuItem>
            ))}
          </mui.core.TextField>
        </mui.core.Grid>

        <mui.core.Grid item xs={12} md={8}>
          <mui.core.TextField
            select
            label="Metric"
            variant="outlined"
            size="small"
            value={metric}
            onChange={(e) => setMetric(e.target.value)}
            fullWidth
            required
          >
            {columns.map((opt) => (
              <mui.core.MenuItem key={opt.key} value={opt.key}>
                {opt.name}
              </mui.core.MenuItem>
            ))}
          </mui.core.TextField>
        </mui.core.Grid>
      </mui.core.Grid>
    </mui.core.Box>
  );
};

export default Form;
