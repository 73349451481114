import { hooks, mui, React, ts, ui } from '_core';

import { DraftParams } from './types';

type FormProps = {
  params: DraftParams;
  setParams: (_p: DraftParams) => void;
  context?: ts.types.basket.BasketAnalysisContext;
};

const Form: React.FC<FormProps> = ({ params, setParams, context }) => {
  const [timeSeriesId, setTimeSeriesId] = React.useState(params.time_series_id);
  const [quantiles, setQuantiles] = React.useState(params.quantiles || 5);
  const [selectedHistories, setSelectedHistories] = React.useState(params.histories || []);
  const [showUnknown, setShowUnknown] = React.useState(params.show_unknown || false);

  const uiStyles = hooks.useUiStyles();

  React.useEffect(() => {
    const data = {
      ...params,
      time_series_id: timeSeriesId,
      histories: selectedHistories,
      quantiles,
      show_unknown: showUnknown,
    };
    setParams(data);
  }, [timeSeriesId, quantiles, selectedHistories, showUnknown]);

  return (
    <mui.core.Box sx={uiStyles.widgetEditMode}>
      <ui.ResourceAutocomplete<ts.types.common.ResourceExpanded>
        value={timeSeriesId}
        setValue={(id) => setTimeSeriesId(id)}
        type={ts.enums.RESOURCES_TYPES_ENUM.TIME_SERIES}
        inputProps={{
          fullWidth: true,
          label: 'Time Series',
          variant: 'outlined',
          required: true,
          size: 'small',
        }}
      />

      <mui.core.Box mt={4}>
        <ui.NumericField
          textFieldProps={{
            label: 'Quantiles (1 - 20)',
            fullWidth: true,
            variant: 'outlined',
            size: 'small',
            required: 'true',
          }}
          value={quantiles}
          setValue={(v) => setQuantiles(v)}
        />
      </mui.core.Box>

      <mui.core.Box mt={5}>
        <ui.WidgetHistories<true>
          multiple
          context={context}
          value={selectedHistories}
          setValue={setSelectedHistories}
        />
      </mui.core.Box>

      <mui.core.Box mt={2}>
        <mui.core.FormControlLabel
          control={<mui.core.Switch size="small" checked={showUnknown} onChange={() => setShowUnknown(!showUnknown)} />}
          label="Show statistics for missing Time Series values"
        />
      </mui.core.Box>
    </mui.core.Box>
  );
};

export default Form;
