import { _, ts } from '_core';

export const errorChartFormat = (
  data: ts.types.widgets.TableData,
  xCol: string,
  yCol: string,
  errorCol?: string,
  bucketCol?: string,
  isScaled = false
): [ts.types.widgets.ErrorLinearData, number, number] => {
  if (!bucketCol) {
    // Handle data without bucketCol
    const scaledData = !isScaled
      ? data.map((d) => ({
          ...d,
          [yCol]: (d[yCol] as number) * 100,
          [errorCol]: errorCol ? (d[errorCol] as number) * 100 : 0,
        }))
      : data;

    const maxValue = Math.max(
      ...scaledData.map((d) => (d[yCol] as number) + (errorCol ? (d[errorCol] as number) / 2 : 0))
    );
    const minValue = Math.min(
      ...scaledData.map((d) => (d[yCol] as number) - (errorCol ? (d[errorCol] as number) / 2 : 0))
    );

    const adjustedMaxValue = maxValue + Math.abs(maxValue * 0.05);
    const adjustedMinValue = minValue - Math.abs(minValue * 0.05);

    return [
      { error: scaledData.map((d) => _.pick(d, [xCol, yCol, 'error'])) as any },
      adjustedMinValue,
      adjustedMaxValue,
    ];
  }

  // Handle data with bucketCol
  const result: ts.types.widgets.ErrorLinearData = {};
  let overallMax = -Infinity;
  let overallMin = Infinity;

  const uniqueQuantiles = _.uniq(data.map((d) => d[bucketCol] as string));

  uniqueQuantiles.forEach((quantile) => {
    const filteredData = data.filter((d) => d[bucketCol] === quantile);
    const [quantileResult, qmin, qmax] = errorChartFormat(filteredData, xCol, yCol, errorCol, null, isScaled);

    result[quantile] = quantileResult['error'];
    overallMax = Math.max(overallMax, qmax);
    overallMin = Math.min(overallMin, qmin);
  });

  return [result, overallMin, overallMax];
};
