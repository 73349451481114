import React from 'react';

import * as enums from '../../enums';
import { resourcesSearch } from '../../helpers';
import { useEffectWithoutFirst } from '../../hooks';
import { mui, useSelector } from '../../libs';
import * as types from '../../types';
import ResourceAutocomplete from '../resource-autocomplete';

type SignalReturnHorizonProps = {
  rh: types.returnHorizon.ReturnHorizon;
  setRh: (_value: types.returnHorizon.ReturnHorizon) => void;
  remove?: () => void;
};

const SignalReturnHorizon: React.FC<SignalReturnHorizonProps> = ({ rh, setRh, remove }): React.ReactElement => {
  const [signalId, setSignalId] = React.useState(rh.signal_id);
  const [pipelineIds, setPipelineIds] = React.useState(rh.pipeline_ids || []);
  const resources = useSelector((state: types.BaseStore) => state.resources);

  const getLocalSignature = (signalId: number, pipelineIds: number[]) => {
    const signalNames = resourcesSearch
      .search(`type:${enums.RESOURCES_TYPES_ENUM.SIGNAL}`, resources, 'research', null, false, false, true)
      .filter((r) => r.id == signalId)
      .map((r) => r.name);

    let label = signalNames.length > 0 ? signalNames[0] : 'Returns signal';
    if (pipelineIds && pipelineIds.length > 0) {
      const pipelineNames = resourcesSearch
        .search(`type:${enums.RESOURCES_TYPES_ENUM.PIPELINE}`, resources, 'research', null, false, false, true)
        .filter((r) => pipelineIds.indexOf(r.id) > 0)
        .map((r) => r.name);
      label += ` => ${pipelineNames.join(' => ')}`;
    }
    return label;
  };

  useEffectWithoutFirst(() => {
    const rhPeriods: types.returnHorizon.FrequencyAmount = {
      amount: 1,
      frequency: enums.FREQUENCY_ENUM.DAILY,
    };

    const returnHorizon: types.returnHorizon.ReturnHorizon = {
      periods: rhPeriods,
      signal_id: signalId,
      pipeline_ids: pipelineIds,
      signature: getLocalSignature(signalId, pipelineIds),
      is_signal: true,
    };

    setRh(returnHorizon);
  }, [signalId, pipelineIds]);

  return (
    <mui.core.Box>
      <mui.core.Grid container spacing={2}>
        <mui.core.Grid item xs={5}>
          <ResourceAutocomplete<types.signal.SignalExpanded>
            value={signalId}
            setValue={setSignalId}
            type={enums.RESOURCES_TYPES_ENUM.SIGNAL}
            inputProps={{
              fullWidth: true,
              label: 'Return Signal',
              variant: 'outlined',
              size: 'small',
            }}
          />
        </mui.core.Grid>
        <mui.core.Grid item xs={5}>
          <ResourceAutocomplete<types.pipeline.PipelineExpanded, true, false>
            value={pipelineIds || []}
            setValue={setPipelineIds}
            type={enums.RESOURCES_TYPES_ENUM.PIPELINE}
            autocompleteProps={{
              limitTags: 2,
              multiple: true,
              size: 'small',
            }}
            inputProps={{
              fullWidth: true,
              label: 'Return Signal Pipelines',
              variant: 'outlined',
              size: 'small',
            }}
          />
        </mui.core.Grid>
        {remove && (
          <mui.core.Grid item xs={2} style={{ display: 'flex', alignItems: 'center' }}>
            <mui.core.IconButton onClick={remove} size="small" style={{ opacity: 0.9 }}>
              <mui.icons.Close />
            </mui.core.IconButton>
          </mui.core.Grid>
        )}
      </mui.core.Grid>
    </mui.core.Box>
  );
};

export default SignalReturnHorizon;
