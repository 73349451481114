import { _, ts } from '_core';

import { DraftParams } from './types';
import { getBucketName } from '../../common/utils/get-series-name';

export const prepareBucketedEventStudyData = (
  data: ts.types.widgets.ErrorLinearData | ts.types.widgets.NestedBarData | ts.types.widgets.HeatMapData,
  tab: 'error' | 'tstat',
  params: DraftParams,
  resources: ts.StoreState['resources']
): ts.types.widgets.ErrorLinearData | ts.types.widgets.NestedBarData | ts.types.widgets.HeatMapData => {
  if (tab == 'error') {
    return Object.fromEntries(
      Object.entries(data).map(([key, val]) => [
        getBucketName(key as string, ts.enums.BUCKET_TYPES_ENUM.QUANTILES, params.quantiles, resources),
        val,
      ])
    );
  }

  if (tab == 'tstat') {
    return (data as ts.types.widgets.HeatMapData).map((d) => ({
      days_from_event: d['days_from_event'],
      value: d['tstat'] || 0,
      category: getBucketName(
        d['category'] as string,
        ts.enums.BUCKET_TYPES_ENUM.QUANTILES,
        params.quantiles,
        resources
      ),
    }));
  }

  return data;
};

export const validate = (params: DraftParams) => {
  if (params.days_before_event < 1 || params.days_before_event > 21)
    return ['Days Before Event must be between 1 and 21.'];
  if (params.days_after_event < 0 || params.days_after_event > 63)
    return ['Days After Event must be between 0 and 63.'];
  if (params.quantiles < 1 || params.quantiles > 20) return ['Quantiles must be between 1 and 20.'];
  if (_.isNil(params.quantiles)) return ['Quantiles is required.'];
  return [];
};
