import { hooks, mui, React } from '_core';

import NeutralizeFactors from './neutralize';
import { DraftParams } from './types';

type FormProps = {
  params: DraftParams;
  setParams: (_p: DraftParams) => void;
  isBasket?: boolean;
};

const Form: React.FC<FormProps> = ({ params, setParams, isBasket }) => {
  const uiStyles = hooks.useUiStyles();

  return (
    <mui.core.Box sx={uiStyles.widgetEditMode}>
      <mui.core.Box p={2}>
        <mui.core.FormControl component="fieldset">
          <mui.core.RadioGroup
            row
            value={params.is_gross ? 'gross' : 'net'}
            onChange={(e) => setParams({ ...params, is_gross: e.target.value == 'gross' })}
          >
            <mui.core.FormControlLabel value="net" control={<mui.core.Radio />} label="Show Net Exposure" />
            <mui.core.FormControlLabel value="gross" control={<mui.core.Radio />} label="Show Gross Exposure" />
          </mui.core.RadioGroup>
        </mui.core.FormControl>
      </mui.core.Box>

      <mui.core.Divider />

      <NeutralizeFactors params={params} setParams={setParams} isBasket={isBasket} />
    </mui.core.Box>
  );
};

Form.defaultProps = {
  isBasket: false,
};

export default Form;
