import { React, ts } from '_core';

import Chart from './chart';
import { DraftParams, Params } from './types';

type TimeSeriesPreviewEntireUniverseProps = {
  id: string;
  mode: ts.enums.CHART_MODE_ENUM;
  params: Params | DraftParams;
  setParams: (_p: DraftParams) => void;

  loadWidgetData: (_payload: ts.types.widgets.WidgetGetDataParams) => Promise<ts.types.widgets.WidgetGetDataResponse>;
  widgetPaths?: Record<'ts_main_data', string>;
  goFullScreen?: (_q: string) => void;
  fullScreen?: boolean;

  context: ts.types.timeSeries.TimeSeriesAnalysisContext;
  queryParams?: { initial?: string };
};

const TimeSeriesPreviewEntireUniverse: React.FC<TimeSeriesPreviewEntireUniverseProps> = ({
  id,
  loadWidgetData,
  widgetPaths,
  context,
  params,
  setParams,
  fullScreen,
  goFullScreen,
  queryParams,
}) => {
  return (
    <>
      <Chart
        id={id}
        loadWidgetData={loadWidgetData}
        file={widgetPaths['ts_main_data']}
        fullScreen={fullScreen}
        context={context}
        params={params as Params}
        setParams={setParams}
        queryParams={queryParams}
        goFullScreen={goFullScreen}
      />
    </>
  );
};

export default TimeSeriesPreviewEntireUniverse;
