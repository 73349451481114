import { Link, mui, React, ui } from '_core';

import { useMediaQuery } from '@mui/material';

type PrimaryNavLinkProps = {
  id: string;
  tooltip: string;
  to: string;
  onClick: () => void;
  icon: JSX.Element;
  selected?: boolean;
  disabled?: boolean;
  disabledTooltip?: JSX.Element;
  dark?: boolean;
};

const PrimaryNavLink: React.FC<PrimaryNavLinkProps> = ({
  id,
  tooltip,
  disabledTooltip,
  to,
  onClick,
  selected,
  disabled,
  icon,
  dark,
}): React.ReactElement => {
  // const currentTab = useSelector((state) => state.ui.currentTab);
  const theme = mui.styles.useTheme() as mui.core.Theme;
  const showTooltip = useMediaQuery(theme.breakpoints.down('md'));
  const [anchorEl, setAnchorEl] = React.useState(null);

  function handleClick(event: { currentTarget: any }) {
    if (anchorEl !== event.currentTarget) {
      setAnchorEl(event.currentTarget);
    }
  }

  function handleClose() {
    setAnchorEl(null);
  }

  const classes = {
    navLink: {
      color: dark ? 'rgba(0,0,0,.87)' : '#fff',
      fontWeight: '500',
      textDecoration: 'none',
      fontSize: dark ? '0.9rem' : '14.5px',
      padding: '4px 8px',
      borderRadius: theme.spacing(1),
      display: 'flex',
      alignItems: 'center',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap' as const,
    },
    navLinkActive: {
      background: 'rgba(255,255,255,.1)',
    },
    navLinkDisabled: {
      opacity: 0.5,
    },
  };

  const linkStyle = React.useMemo(() => {
    if (disabled) return { ...classes.navLink, ...classes.navLinkDisabled };
    if (selected) return { ...classes.navLink, ...classes.navLinkActive };
    return classes.navLink;
  }, [selected, disabled]);

  if (disabled)
    return (
      <>
        <Link
          id={id}
          to="#"
          style={linkStyle}
          aria-owns={anchorEl ? 'simple-menu' : undefined}
          aria-haspopup="true"
          onClick={handleClick}
          onMouseOver={handleClick}
        >
          {tooltip}
        </Link>
        <mui.core.Menu
          id="demo-positioned-menu"
          aria-labelledby="demo-positioned-button"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          MenuListProps={{ onMouseLeave: handleClose }}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
            },
          }}
        >
          <mui.core.Box sx={{ maxWidth: '300px', padding: '1.5rem 1rem 1rem' }}>
            <mui.core.IconButton
              onClick={handleClose}
              size="small"
              sx={{
                opacity: 0.9,
                position: 'absolute',
                right: '0.5rem',
                top: '0.66rem',
              }}
            >
              <mui.icons.Close />
            </mui.core.IconButton>
            {disabledTooltip}
          </mui.core.Box>
        </mui.core.Menu>
      </>
    );

  return (
    <Link id={id} to={to} onClick={onClick} style={linkStyle}>
      <ui.ConditionalTooltip title={tooltip} condition={showTooltip}>
        <mui.core.Box
          sx={{
            display: { md: 'none' },
            ...(dark && {
              color: 'rgba(0, 0, 0, 0.54)',
              textAlign: 'center',
              padding: '8px 4px',
              mr: '8px',
              width: '30px',
              borderRadius: '8px',
            }),
          }}
        >
          {icon}
        </mui.core.Box>
      </ui.ConditionalTooltip>
      <mui.core.Box sx={{ display: { sm: 'none', md: 'block' } }}>{tooltip}</mui.core.Box>
    </Link>
  );
};

PrimaryNavLink.defaultProps = {
  disabled: false,
  disabledTooltip: <></>,
  selected: false,
};

export default PrimaryNavLink;
