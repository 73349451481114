import { _, helpers, ts } from '_core';

import { SignalSetParams } from '@finsera/finsera/dist/resource/signal_set';

export const getSignalsObj = (signalSet: ts.types.signalSet.SignalSetAnalysisContext) => {
  const signals = {} as Record<string, Partial<ts.types.signalSet.SignalMetadata>>;
  signalSet.signals.forEach((signal) => {
    signals[signal.id] = signal;
  });
  return signals;
};

export const mapName = (
  key: string,
  resources: ts.StoreState['resources'],
  signalsObj: Record<string, Partial<ts.types.signalSet.SignalMetadata>>,
  pipelinesDisplay: { pipeline: boolean; sp_pipeline: boolean } = {
    pipeline: true,
    sp_pipeline: true,
  }
) => {
  if (key.includes('date')) return 'Date';
  let name = '';

  const combId = key.split('|')[0];
  const signalObj = signalsObj[combId];

  if (!signalObj) return `Signal ${combId} (regenerate)`;

  if (signalObj.signal_id) name += resources.signals.find((s) => s.id == signalObj.signal_id).name;
  if (signalObj.pipeline_id && pipelinesDisplay.pipeline)
    name += ` - ${resources.pipelines.find((p) => p.id == signalObj.pipeline_id).name}`;
  if (signalObj.sp_pipeline_id && pipelinesDisplay.sp_pipeline)
    name += ` - ${resources.pipelines.find((p) => p.id == signalObj.sp_pipeline_id).name}`;

  return name;
};

export const getLabel = (tab: string, frequency: ts.enums.FREQUENCY_ENUM) => {
  // If signature is the final name (latest refactor)
  if (tab.includes(' ')) return tab.replaceAll('Period', helpers.returnHorizon._freqParseLabel(frequency));
  return helpers.returnHorizon.getLabelFromSignature(tab);
};

/**
 * Prepares data for line chart visualization by removing return horizon information.
 * This function expects only one return horizon to be present in the data, as multiple
 * return horizons would increase the data with the histories to something that is not manageable.
 *
 * @param data - Raw table data from the API
 * @param resources - Store resources containing signals and pipelines information
 * @param signalsObj - Object containing signal metadata
 * @param pipelinesDisplay - Configuration for displaying pipeline information
 * @returns TableData
 */

export const prepareLinearData = (
  data: ts.types.widgets.TableData,
  resources: ts.StoreState['resources'],
  signalsObj: Record<string, Partial<SignalSetParams>>,
  pipelinesDisplay?: {
    pipeline: boolean;
    sp_pipeline: boolean;
  }
): ts.types.widgets.TableData => {
  // First we will get the keys on the first row so we can use it from a dict to fill the full data
  const firstRow = data?.[0] ?? {};
  const { date, ...rest } = firstRow;
  const entitiesMap = {} as Record<string, string>;

  _.forOwn(rest, (_, key) => {
    // Remove return horizon from key
    let entityKey = key.split('|')[0];

    // Look for history before finding the set name
    let historyType = '';
    Object.values(ts.enums.HISTORY_ENUM_EXTENDED).forEach((historyEnum) => {
      if (entityKey.startsWith(historyEnum)) {
        historyType = `${historyEnum}_`;
        entityKey = entityKey.replace(historyType, '');
      }
    });

    let mappedEntity = mapName(entityKey, resources, signalsObj, pipelinesDisplay);
    if (key.split('|')[0].includes('benchmark')) mappedEntity = 'benchmark';

    // Add history if we have one
    mappedEntity = `${historyType}${mappedEntity}`;
    entitiesMap[key] = mappedEntity;
  });

  return data.map((row) => {
    const newRow: Record<string, string | number | boolean> = { date: row.date };
    _.forOwn(row, (value, key) => {
      newRow[entitiesMap[key] ?? key] = value;
    });
    return newRow;
  });
};
