import { getColor } from '@local/finsera-core/src/helpers';
import { _, am5, am5xy } from '@local/finsera-core/src/libs';
import * as types from '@local/finsera-core/src/types';

const EMPTY_ARRAY: any[] = [];

export const createSeries = ({
  root,
  chart,
  data,
  category,
  xAxisRenderer,
  yAxisRenderer,
  tooltipText = '[bold]{name}:[/] {valueY}',
  hideSeries = EMPTY_ARRAY,
  seriesOrder = (c: string[]) => c,
  forceColor = {},
}: {
  root: am5.Root;
  chart: am5xy.XYChart;
  data: types.widgets.TableData;
  category: string;
  xAxisRenderer: am5xy.CategoryAxis<am5xy.AxisRenderer>;
  yAxisRenderer: am5xy.ValueAxis<am5xy.AxisRenderer>;
  tooltipText: string;
  hideSeries: string[];
  seriesOrder: (_c: string[]) => string[];
  forceColor: Record<string, string>;
}) => {
  const createSingleSeries = (value: string, name: string, color: number) => {
    const series = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        name: name,
        xAxis: xAxisRenderer,
        yAxis: yAxisRenderer,
        valueYField: value,
        categoryXField: category,
        tooltip: am5.Tooltip.new(root, {
          pointerOrientation: 'horizontal',
          labelText: tooltipText,
        }),
      })
    );

    series.columns.template.setAll({
      fillOpacity: 1,
      fill: am5.color(forceColor[name] || getColor(color)),
      stroke: am5.color(forceColor[name] || getColor(color)),
    });

    series.get('tooltip')?.label.setAll({
      fontSize: 11,
    });

    return series;
  };

  const firstObj = data?.[0] || {};
  const series = Object.keys(_.omit(firstObj, [category])).filter((el) => el);

  let hidden = 0;
  seriesOrder(series).forEach((serieKey, index) => {
    if (!hideSeries.includes(serieKey)) {
      const seriesObj = createSingleSeries(serieKey, serieKey, index - hidden);
      seriesObj.data.setAll(data);
    } else {
      hidden += 1;
    }
  });
};
