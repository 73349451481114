import React from 'react';

import DialogTitle from './dialog-title';
import ContextSingleton from '../__singletons__/context-singleton';
import { mui, useDispatch, useSelector } from '../libs';
import { BaseStore } from '../types';

/**
 * Component that displays a modal with a Calendly scheduling iframe
 * 
 * @requires Redux Store:
 * - state.ui.scheduleDemoOpen: Boolean indicating if modal is open
 * 
 * @requires Redux Store Action:
 * - ui.setScheduleDemoOpen: Action to control modal visibility
 * 
 * @returns Rendered modal component with Calendly iframe
 */
const ScheduleDemoModal = () => {
  const actions = ContextSingleton.getInstance().actions;
  const dispatch = useDispatch();
  const scheduleDemoOpen = useSelector((state: BaseStore) => state.ui.scheduleDemoOpen);

  return (
    <mui.core.Dialog
      open={scheduleDemoOpen}
      fullWidth
      maxWidth="lg"
      onClose={() => dispatch(actions.ui.setScheduleDemoOpen(false))}
      sx={{
        '& .MuiDialog-paper': {
          height: '80vh',
        },
      }}
    >
      <DialogTitle closeAction={() => dispatch(actions.ui.setScheduleDemoOpen(false))}>Schedule a Demo</DialogTitle>
      <iframe
        title="Schedule a Demo"
        // eslint-disable-next-line max-len
        src="https://calendly.com/finsera/demo?embed_domain=calendly-embed.com&amp;embed_type=Inline&amp;primary_color=844cb0"
        width="100%"
        height="100%"
        style={{ border: '0' }}
      ></iframe>
    </mui.core.Dialog>
  );
};

export default ScheduleDemoModal;
