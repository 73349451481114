
import { api, coreData, enums, finseraConfig, types } from '@local/finsera-core';

import preferencesMenu from './preferences-menu';
import { _, deepmerge, moment } from '../libs';

const conf = {
  environment: 'development' as 'local' | 'development' | 'staging' | 'production',
  ASSISTANT_ID: 'SECRET',
  OPEN_AI_KEY: 'SECRET',
  logsPollingDuration: 10, //seconds
  jobberPollingDuration: 10, //seconds
  debug: false,
  preferencesMenu: preferencesMenu,
  themes: coreData.themes,
  brokers: coreData.brokers,
  styleFactorThemes: coreData.styleFactorThemes,
  universe_definition: {
    include_assets: true,
    exclude_assets: true,
    include_universes: false,
    exclude_universes: true,
    signals: false,
  },
  maxWidgetsPerPage: 25,
  adhocmaxWidgetsPerPage: 3,
  assetTypes: coreData.assetTypes,
  availableStrategies: coreData.availableStrategies,
  productionResources: [enums.UI_RESOURCE_TYPE_ENUM.STRATEGY, enums.UI_RESOURCE_TYPE_ENUM.PORTFOLIO],
  researchResources: _.without(
    Object.values(enums.UI_RESOURCE_TYPE_ENUM),
    enums.UI_RESOURCE_TYPE_ENUM.STRATEGY,
    enums.UI_RESOURCE_TYPE_ENUM.PORTFOLIO
  ),
  outsideWorkspace: [enums.UI_RESOURCE_TYPE_ENUM.WORKSPACE, enums.UI_RESOURCE_TYPE_ENUM.STRATEGY],
  datesFmt: coreData.datesFmt,
  datasets: coreData.datasets,
  identifierTypes: ['FID', 'CUSIP', 'TICKER', 'ISIN', 'COUNTRY', 'SEDOL', 'BLOOMBERG_TICKER'],
  dateTypes: ['DATA_DATE', 'RECEIVED_DATE'],
  etfCodes: coreData.etfCodes,
  // Features will be overriden on boot
  features: {} as types.organization.OrganizationFeatures,
  superAdminUser: 'superadmin@finsera.com',
  countries: coreData.countries,
  customUrl: (window as any).CUSTOM_URL || '',

};

if (_.cookie('debug') && _.cookie('debug') === '1234') {
  console.log('Enabling debug mode');
  conf.debug = true;
}

Object.assign(conf, deepmerge(conf, finseraConfig));

const customOverrides = async ({ features, userData }: 
  { features: types.organization.OrganizationFeatures; userData: types.user.User }) => {
  if (userData.server_time_offset) {
    moment.now = function () {
      return userData.server_time_offset + Date.now();
    };
  }

  const start_date = features.start_date ?? moment().format('YYYY-MM-DD');
  const end_date = features.end_date || moment().format('YYYY-MM-DD');
  const validDates = (await api.dates.get_valid_dates({ 
    frequency: enums.FREQUENCY_ENUM.DAILY, start_date, end_date 
  })).data;

  return {
    features: {
      ...features,
      start_date: validDates?.[0] ?? start_date,
      end_date: validDates?.at(-1) ?? end_date,
    },
  };
};

const override = async (obj: { features: types.organization.OrganizationFeatures; userData: types.user.User }) => {
  const newObj = await customOverrides(obj);
  Object.assign(conf, deepmerge(conf, newObj));
};

export default conf;
export { override };
