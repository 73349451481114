import { endpoint } from './config';
import { $ } from '../libs';
import { OrgPublishResource, ResourceError, ResourceRef } from '../types/org-publish';
import { PublishedOrg } from '../types/organization';

export const getPublished = (destinationOrg: number): JQuery.jqXHR<{
  published: OrgPublishResource[];
  warnings: ResourceError[];
}> =>
  $.ajax({
    method: 'POST',
    url: `${endpoint}org_publish`,
    json: {
      destination_org: destinationOrg,
      action: 'get',
    },
  } as JQueryAjaxSettings);

export const sync = (
  destinationOrg: number,
  resources: ResourceRef[],
  action: 'publish' | 'update' | 'revoke' | 'restate'
): JQuery.jqXHR<{
  published: OrgPublishResource[];
  warnings: ResourceError[];
}> =>
  $.ajax({
    method: 'POST',
    url: `${endpoint}org_publish`,
    json: {
      resources,
      destination_org: destinationOrg,
      action,
    },
  } as JQueryAjaxSettings);

export const getPublishedOrgs = (): JQuery.jqXHR<{ organizations: PublishedOrg[] }> =>
  $.ajax({
    method: 'GET',
    url: `${endpoint}org_publish/organizations`,
  } as JQueryAjaxSettings);

export const getPrefix = (): JQuery.jqXHR<{ prefix: string }> =>
  $.ajax({
    method: 'GET',
    url: `${endpoint}org_publish/prefix`,
  } as JQueryAjaxSettings);

export const updatePrefix = (newPrefix?: string): JQuery.jqXHR<{ prefix: string }> =>
  $.ajax({
    method: 'PUT',
    url: !newPrefix ? `${endpoint}org_publish/prefix` : `${endpoint}org_publish/prefix?new_prefix=${newPrefix}`,
  } as JQueryAjaxSettings);
