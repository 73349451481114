import { helpers, mui, React, ts, ui, useHistory, useSelector } from '_core';

import * as utils from './signal-set-utils';

type SignalSetFormProps = {
  open: boolean;
  setOpen: (_open: boolean) => void;
};

const SignalSetForm: React.FC<SignalSetFormProps> = ({ open, setOpen }): React.ReactElement => {
  const signalSets = useSelector((state) => state.resources.signal_sets);
  const workspaces = useSelector((state) => state.resources.workspaces);
  const currentWorkspaceId = useSelector((state) => state.ui.currentWorkspaceId);

  const history = useHistory();

  const [errorMessage, setErrorMessage] = React.useState('');
  const [name, setName] = React.useState('');
  const [handle, setHandle] = React.useState('');
  const [template, setTemplate] = React.useState<ts.types.signalSet.SignalSet>();
  const [loadingCreate, setLoadingCreate] = React.useState(false);
  const [workspace, setWorkspace] = React.useState(workspaces.find((w) => w.id == currentWorkspaceId) || workspaces[0]);

  // Create handle while typing name
  React.useEffect(() => {
    setHandle(helpers.createHandle(name, 'ss'));
  }, [name]);

  const handleSubmit = async () => {
    setErrorMessage('');

    // Validation
    if (!name || !handle) {
      setErrorMessage('All attributes are required.');
      setLoadingCreate(false);
      return;
    }

    setLoadingCreate(true);

    const newSignalSet = utils.definition.getNewSignalSet(name, handle, template);
    utils.apiProcedures.createSignalSet(
      newSignalSet,
      async (data) => {
        if (workspace)
          await helpers.resourcesUtils.addToWorkspace(data.id, ts.enums.UI_RESOURCE_TYPE_ENUM.SIGNAL_SET, workspace);
        setOpen(false);
        history.push(`/signal_sets/${data.id}`);
      },
      async (err) => {
        setErrorMessage(err);
        setLoadingCreate(false);
      }
    );
  };

  const formContent = () => (
    <mui.core.Box mt={3}>
      <mui.core.Grid container spacing={3}>
        <mui.core.Grid item xs={12} sm={6}>
          <mui.core.TextField
            autoFocus
            id="name"
            label="Signal Set Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            fullWidth
            variant="outlined"
            required
            size="small"
            inputProps={{
              autocomplete: 'off',
            }}
          />
        </mui.core.Grid>
        <mui.core.Grid item xs={12} sm={6}>
          <mui.core.Box pb={4}>
            <mui.core.TextField
              id="handle"
              label="Signal Set Handle"
              value={handle}
              onChange={(e) => setHandle(e.target.value)}
              fullWidth
              variant="outlined"
              required
              size="small"
            />
          </mui.core.Box>
        </mui.core.Grid>
      </mui.core.Grid>
      <mui.core.Box pb={4}>
        <ui.ResourceAutocomplete<ts.types.signalSet.SignalSetExpanded>
          enableNone
          value={template?.id}
          setValue={(id) => setTemplate(signalSets.find((u) => u.id == id))}
          type={ts.enums.RESOURCES_TYPES_ENUM.SIGNAL_SET}
          filter={(ss) => !ss.source_resource_id}
          inputProps={{
            fullWidth: true,
            label: 'Template',
            variant: 'outlined',
            required: true,
            size: 'small',
          }}
        />
      </mui.core.Box>
      <ui.WorkspaceSelector value={workspace} setValue={setWorkspace} />
    </mui.core.Box>
  );

  return (
    <mui.core.Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="sm"
      fullWidth
    >
      {errorMessage && (
        <mui.lab.Alert onClose={() => setErrorMessage('')} severity="error">
          {errorMessage}
        </mui.lab.Alert>
      )}
      <ui.DialogTitle closeAction={() => setOpen(false)}>Create Signal Set</ui.DialogTitle>
      <mui.core.DialogContent>{formContent()}</mui.core.DialogContent>
      <mui.core.DialogActions>
        <ui.Button
          variant="contained"
          color="primary"
          loading={loadingCreate}
          onClick={handleSubmit}
          style={{ margin: '0rem 1rem 1rem' }}
          fullWidth
        >
          Create Signal Set
        </ui.Button>
      </mui.core.DialogActions>
    </mui.core.Dialog>
  );
};

export default SignalSetForm;
