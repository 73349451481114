
import React from 'react';

import { mui } from '../../../libs';
import * as types from '../../../types';

type IndustrySelectorProps = {
  value: types.widgets.common.BucketValue;

  setValue: (_v: types.widgets.common.BucketValue) => void;
  options: string[];
  disabled?: boolean;
  error?: boolean;
};

const IndustrySelector: React.FC<IndustrySelectorProps> = ({
  value,
  setValue,
  options,
  disabled,
  error,
}): React.ReactElement => {
  let localOptions = [...options];

  const getLabel = (val: string) => {
    // For GICS backward compability
    if (val.includes('sector')) return 'Sectors';
    if (val.includes('group')) return 'Groups';
    if (val.includes('gics_ind')) return 'Industries';
    if (val.includes('subind')) return 'Sub-industries';

    if (val.includes('rbics_l1')) return 'Sector';
    if (val.includes('rbics_l2')) return 'Sub Sector';
    if (val.includes('rbics_l3')) return 'Industry';

    return 'Sub Industry';
  };

  // Add GICS options if value has gics
  if ((value?.value as string)?.includes('gics'))
    localOptions = localOptions.concat(['gics_sector', 'gics_group', 'gics_ind', 'gics_subind']);

  const localValue = React.useMemo(() => {
    const v = (value?.value as string) ?? '';
    return v;
  }, [value]);

  return (
    <mui.core.Grid item xs={12} md={6}>
      <mui.core.TextField
        select
        label="Industry"
        variant="outlined"
        size="small"
        value={localValue}
        onChange={(e) => setValue({ ...value, value: e.target.value })}
        fullWidth
        disabled={disabled}
        error={error}
      >
        {localOptions.map((o) => (
          <mui.core.MenuItem key={o} disabled={o.includes('gics')} value={o}>
            {getLabel(o)}
          </mui.core.MenuItem>
        ))}
      </mui.core.TextField>
    </mui.core.Grid>
  );
};

export default IndustrySelector;
