import { _ } from '_core';

const HISTORY_ORDER = {
  '5 Trading Days': 1,
  'Month to Date': 2,
  '1 Month': 3,
  'Quarter to Date': 4,
  '3 Months': 5,
  '6 Months': 6,
  'Year to Date': 7,
  '1 Year': 8,
  '3 Years': 9,
  '5 Years': 10,
  ALL: 11,
} as const;

const FORMAT_ALIASES = {
  'Last 1 Year': '1 Year',
  'Last 3 Years': '3 Years',
  'Last 5 Years': '5 Years',
  Full: 'ALL',
  MTD: 'Month to Date',
  QTD: 'Quarter to Date',
  YTD: 'Year to Date',
} as const;

const normalizeHistoryPeriod = (period: string): string => {
  if (period in FORMAT_ALIASES) {
    return FORMAT_ALIASES[period as keyof typeof FORMAT_ALIASES];
  }

  return period;
};

/**
 * Compares two history periods for sorting purposes. Handles both numeric years and predefined period strings.
 *
 * @param {string} a - First history period to compare
 * @param {string} b - Second history period to compare
 * @returns {number}
 *  - Negative number if 'a' should be sorted before 'b'
 *  - Positive number if 'a' should be sorted after 'b'
 *  - Zero if 'a' and 'b' are equal
 *
 * @example
 * compareHistoryPeriods('2020', '2021') // returns -1
 * compareHistoryPeriods('1 Month', '3 Months') // returns -2
 * compareHistoryPeriods('MTD', 'YTD') // returns -5
 */
export const compareHistoryPeriods = (a: string, b: string): number => {
  // Handle the numeric periods (For year columns 2020, 20XX, ...)
  const aIsYear = _.checkIsNumber(a);
  const bIsYear = _.checkIsNumber(b);

  if (aIsYear && bIsYear) {
    return parseInt(a) - parseInt(b);
  }

  if (aIsYear) return -1;
  if (bIsYear) return 1;

  const normalizedA = normalizeHistoryPeriod(a);
  const normalizedB = normalizeHistoryPeriod(b);

  const aOrder = HISTORY_ORDER[normalizedA as keyof typeof HISTORY_ORDER] || 999;
  const bOrder = HISTORY_ORDER[normalizedB as keyof typeof HISTORY_ORDER] || 999;
  return aOrder - bOrder;
};
