import { config, mui, React, ts, ui } from '_core';

import { DraftParams } from './types';

type FormProps = {
  params: DraftParams;
  setParams: (_p: DraftParams) => void;
  context?: ts.types.backtest.BacktestAnaylsisContext | ts.types.basket.BasketAnalysisContext;
};

const Form: React.FC<FormProps> = ({ params, setParams, context }) => {
  const endDate = context?.end_date || config.features.end_date;
  const startDate = context?.start_date || config.features.start_date;

  const [reportDate, setPreviewDate] = React.useState(params.end_date || endDate);

  React.useEffect(() => {
    const data = {
      start_date: reportDate,
      end_date: reportDate,
    };

    setParams({ ...params, ...data });
  }, [reportDate]);

  return (
    <>
      <mui.core.Box mt={2}>
        <ui.SmartDateSelector
          label="Date"
          value={reportDate}
          setValue={(date) => setPreviewDate(date)}
          minDate={startDate}
          maxDate={endDate}
          // Context related
          universeId={context?.definition?.universe_id}
          countries={
            ((context as ts.types.basket.BasketAnalysisContext)?.definition as ts.types.basket.DefinitionCommon)
              ?.etf_definition?.quotation_countries as string[]
          }
          frequency={context?.definition?.frequency as ts.enums.FREQUENCY_ENUM}
        />
      </mui.core.Box>
    </>
  );
};

Form.defaultProps = {
  context: null,
};

export default Form;
