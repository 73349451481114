import React from 'react';
import html2canvas from 'html2canvas';

import Button from './button';
import DialogTitle from './dialog-title';
import DragDropFiles from './drag-drop-files';
import SuccessMessage from './success-message';
import api from '../api';
import { parseApiError } from '../helpers';
import { useUiStyles } from '../hooks';
import { mui } from '../libs';
import * as types from '../types';

type FeedbackDialogProps = {
  isOpen: boolean;
  handleClose: () => void;
  currentUser: types.user.User;
};

type apiError = {
  responseJSON: any;
};

/**
 * FeedbackDialog component that allows users to submit feedback along with optional file uploads.
 *
 *
 * @param isOpen - Boolean flag to control the visibility of the dialog.
 * @param handleClose - Function to call when the dialog should be closed.
 * @param currentUser - The current user object containing user information.
 *
 * @returns A React component that displays a feedback submission dialog.
 */

const FeedbackDialog: React.FC<FeedbackDialogProps> = ({ isOpen, handleClose, currentUser }): React.ReactElement => {
  const uiStyles = useUiStyles();

  const [errorMessage, setErrorMessage] = React.useState('');
  const [feedback, setFeedback] = React.useState('');
  const [loadingCreate, setLoadingCreate] = React.useState(false);
  const [formSuccess, setFormSuccess] = React.useState(false);

  const [uploadOption, setUploadOption] = React.useState<'FILE' | 'SCREENSHOT'>('FILE');
  const [file, setFile] = React.useState<File>();

  const url = window.location.href;
  const userEmail = currentUser.email;
  const browser = navigator.userAgent;

  const theme = mui.styles.useTheme() as mui.core.Theme;

  const handleSubmit = async () => {
    setErrorMessage('');

    // Validation
    if (!feedback) {
      setErrorMessage('Feedback is required');
      return;
    }

    setLoadingCreate(true);

    try {
      let screenshotFile = file;
      if (uploadOption == 'SCREENSHOT') screenshotFile = await captureScreenshot();
      const formData = new FormData();
      formData.append('feedback', feedback);
      formData.append('url', url);
      formData.append('email', userEmail);
      formData.append('browser', browser);

      if (screenshotFile) formData.append('files', screenshotFile);

      await api.feedback.create(formData);

      setFormSuccess(true);
    } catch (error) {
      setErrorMessage(parseApiError(error as apiError));
    } finally {
      setLoadingCreate(false);
    }
  };

  const close = () => {
    handleClose();
    setFormSuccess(false);
    setFeedback('');
    setFile(null);
    setUploadOption('FILE');
  };

  const captureScreenshot = async () => {
    try {
      const canvas = await html2canvas(document.body, {
        foreignObjectRendering: true,
        onclone: (doc) => {
          const elementToRemove = doc.querySelector('#feedback-dialog');
          if (elementToRemove) {
            elementToRemove.remove();
          }
        },
      });

      const imageBlob = await new Promise<Blob | null>((resolve) => {
        canvas.toBlob(resolve, 'image/png');
      });

      if (imageBlob) {
        const newScreenshotFile = new File([imageBlob], `${new Date().getTime()}.png`, {
          type: 'image/png',
        });

        return newScreenshotFile;
      }
    } catch (error) {
      setErrorMessage('Error capturing screenshot: ' + error);
    }
  };

  const formContent = () => (
    <>
      <DialogTitle closeAction={close}>Send Feedback</DialogTitle>
      <mui.core.DialogContent>
        <mui.core.Box mt={4}>
          <mui.core.TextField
            required
            variant="outlined"
            id="description"
            label="Feedback"
            fullWidth
            value={feedback}
            multiline
            rows={4}
            onChange={(e) => {
              setFeedback(e.target.value);
            }}
          />
        </mui.core.Box>
        <mui.core.Box mt={4}>
          {file ? (
            <mui.core.Box sx={uiStyles.fileBoxFull}>
              <mui.core.Typography variant="body1" style={{ marginRight: '1rem', flexGrow: '1' }}>
                {file?.name}
              </mui.core.Typography>
              <mui.core.IconButton
                size="small"
                onClick={() => {
                  setFile(null);
                }}
              >
                <mui.icons.Close style={{ fontSize: '1rem' }} />
              </mui.core.IconButton>
            </mui.core.Box>
          ) : (
            <>
              <mui.core.FormControl component="fieldset">
                <mui.core.RadioGroup
                  row
                  value={uploadOption}
                  onChange={(e) => setUploadOption(e.target.value as 'FILE' | 'SCREENSHOT')}
                >
                  <mui.core.FormControlLabel value="FILE" control={<mui.core.Radio />} label="Upload Screenshot" />
                  <mui.core.FormControlLabel value="SCREENSHOT" control={<mui.core.Radio />} label="Take Screenshot" />
                </mui.core.RadioGroup>
              </mui.core.FormControl>

              {uploadOption == 'FILE' ? (
                <DragDropFiles
                  setFile={setFile}
                  subtitleText="Drag and Drop your screenshot here"
                  acceptedFileTypes={{ 'image/*': ['.png', '.jpg', 'jpeg'] }}
                />
              ) : (
                <mui.lab.Alert severity="info">
                  Screenshot will be taken upon submission (Modal will be hidden)
                </mui.lab.Alert>
              )}
            </>
          )}
        </mui.core.Box>
        <mui.core.Box mt={4}>
          <mui.core.Typography variant="body1" fontWeight="fontWeightBold">
            Additionally, the following information will be submitted -
          </mui.core.Typography>
          <mui.core.Typography variant="body2" sx={{ marginTop: theme.spacing(1) }}>
            URL: {url}
          </mui.core.Typography>
          <mui.core.Typography variant="body2" sx={{ marginTop: theme.spacing(1) }}>
            Email: {userEmail}
          </mui.core.Typography>
          <mui.core.Typography variant="body2" sx={{ marginTop: theme.spacing(1) }}>
            Browser: {browser}
          </mui.core.Typography>
        </mui.core.Box>
      </mui.core.DialogContent>
      <mui.core.DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          loading={loadingCreate}
          style={{ margin: '0rem 1rem 1rem' }}
          fullWidth
        >
          Submit
        </Button>
      </mui.core.DialogActions>
    </>
  );

  return (
    <>
      <mui.core.Dialog open={isOpen} onClose={close} maxWidth="sm" fullWidth id="feedback-dialog">
        {errorMessage && (
          <mui.lab.Alert onClose={() => setErrorMessage('')} severity="error">
            {errorMessage}
          </mui.lab.Alert>
        )}
        {formSuccess ? (
          <>
            <SuccessMessage closeAction={close}>
              <mui.core.Typography>
                <b>Your feedback was submitted succesfully</b>
                <br />
                If required, we will follow up with you on the same.
              </mui.core.Typography>
            </SuccessMessage>
          </>
        ) : (
          <>{formContent()}</>
        )}
      </mui.core.Dialog>
    </>
  );
};

export default FeedbackDialog;
