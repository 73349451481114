import { _, api, config, helpers, ts } from '_core';

type BasketApiResponse = {
  data: ts.types.basket.Basket;
};

export const createBasket = async (
  newData: ts.types.basket.BasketDraft,
  callback: (_data: ts.types.basket.Basket) => void,
  errorCallback: (_error: string) => void
) => {
  try {
    const resp: BasketApiResponse = await api.baskets.create(newData);
    callback(resp.data);
  } catch (error) {
    errorCallback(`Error creating basket - ${helpers.parseApiError(error as ts.types.common.ApiError)}`);
  }
};

export const updateBasket = async (
  id: number,
  newData: ts.types.basket.BasketDraft,
  callback: (_data: ts.types.basket.Basket) => void,
  errorCallback: (_error: string) => void
) => {
  try {
    const resp: BasketApiResponse = await api.baskets.update(id, { ...newData });
    callback(resp.data);
  } catch (error) {
    errorCallback(`Error updating basket - ${helpers.parseApiError(error as ts.types.common.ApiError)}`);
  }
};

export const updateDefinition = async (
  id: number,
  definition: ts.types.basket.DefinitionDraft,
  isValid: boolean,
  callback: (_data: ts.types.basket.Basket) => void,
  errorCallback: (_error: string) => void
) => {
  try {
    const resp = await api.baskets.update(
      id,
      {
        definition,
        is_valid: isValid,
        skip_signature_check: !isValid,
      },
      true
    );
    if (resp.data?.validation_error && !resp.data?.is_valid) errorCallback(resp.data?.validation_error);
    else callback(resp.data);
  } catch (error) {
    errorCallback(`Error updating the definition - ${helpers.parseApiError(error as ts.types.common.ApiError)}`);
  }
};

export const updateUiMetadata = async (
  basket: ts.types.basket.Basket,
  uiMetaData: ts.types.basket.UiMetaData,
  callback: (_data: ts.types.basket.Basket) => void,
  errorCallback: (_error: string) => void
) => {
  try {
    if (basket.shared) return;
    const updateData = {
      ui_metadata: uiMetaData,
      skip_signature_check: true,
    } as ts.types.basket.BasketDraft;
    const resp = await api.baskets.update(basket.id, updateData);
    callback(resp.data);
  } catch (error) {
    errorCallback(`Error updating the resource metadata - ${helpers.parseApiError(error as ts.types.common.ApiError)}`);
  }
};

const createAnalyses = async (id: number, names: ts.enums.BASKET_ANALYSIS_TYPE_ENUM[]) => {
  const analyses = [];
  for (const name of names) {
    let analysisDefinition = { widgets: [] } as ts.types.analysis.AnalysisDefinition;

    if (name == ts.enums.BASKET_ANALYSIS_TYPE_ENUM.PORTFOLIO_REPORT) {
      // default portan value
      analysisDefinition = {
        mode: ts.enums.ANALYSIS_MODE_ENUM.FULLSCREEN,
        widgets: [
          {
            id: helpers.gibberishGenerator.stringGenerator(11),
            key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.BACKTEST_TABULAR_OUTPUT,
            params: { isBasket: true },
          },
        ],
      };
    }

    if (name == ts.enums.BASKET_ANALYSIS_TYPE_ENUM.ADHOC) {
      analysisDefinition = {
        mode: ts.enums.ANALYSIS_MODE_ENUM.ADHOC,
        widgets: [],
      };
    }

    // create analysis
    const response = await api.basket_analyses.create({
      report_definition: analysisDefinition,
      basket_id: id,
      name,
    });
    analyses.push(response.data.name);
  }
  return analyses;
};

// Load analyses, and if we don't have them, create them
export const loadAnalyses = async (
  basket: ts.types.basket.Basket,
  setAnalysesNames: (_data: ts.enums.BASKET_ANALYSIS_TYPE_ENUM[]) => void,
  errorCallback: (_error: string) => void
) => {
  const analyses = Object.values(ts.enums.BASKET_ANALYSIS_TYPE_ENUM);
  try {
    const resp = await api.basket_analyses.search({
      ignore_user_scope: basket.shared,
      query: ['$and', ['basket_id', '=', basket.id]],
      include: 'id,name',
    });

    let localNames = (resp.data || []).map(
      (a: ts.types.analysis.ResourceAnalysis) => a.name
    ) as ts.enums.BASKET_ANALYSIS_TYPE_ENUM[];

    const toCreate = _.without(analyses, ...localNames);
    if (!_.isEmpty(toCreate)) {
      const newNames = (await createAnalyses(basket.id, toCreate)) as ts.enums.BASKET_ANALYSIS_TYPE_ENUM[];
      localNames = localNames.concat(newNames);
    }

    setAnalysesNames(localNames);
  } catch (error) {
    errorCallback(`Error updating basket - ${helpers.parseApiError(error as ts.types.common.ApiError)}`);
  }
};

export const loadAnalysis = async (
  basket: ts.types.basket.Basket,
  setAnalysis: (_data: ts.types.analysis.ResourceAnalysis) => void,
  analysisName: ts.enums.BASKET_ANALYSIS_TYPE_ENUM,
  errorCallback: (_error: string) => void
) => {
  try {
    const resp = await api.basket_analyses.search({
      query: ['$and', ['basket_id', '=', basket.id], ['name', '=', analysisName]],
    });
    const tempAnalysis = _.get(resp, 'data.0');
    setAnalysis(tempAnalysis);
  } catch {
    errorCallback(`Unable to load the ${analysisName} analysis`);
  }
};

export const keepCurrent = async (
  basket: ts.types.basket.BasketDraft,
  callback: (_data: { resource: ts.types.basket.Basket; universe?: ts.types.universe.Universe }) => void,
  errorCallback: (_error: string) => void
) => {
  try {
    if (basket.shared) return;
    const resp = await api.dates.keep_current({
      resource_id: basket.id,
      resource_type: 'baskets',
      universe_id: basket.definition?.universe_id || undefined,
      countries:
        (basket.definition as ts.types.basket.DefinitionCommon)?.etf_definition?.quotation_countries || undefined,
      frequency: (basket.definition?.frequency as ts.enums.FREQUENCY_ENUM) || undefined,
      max_date: config.features.end_date,
    });
    callback(resp);
  } catch (error) {
    errorCallback(`Error updating basket dates - ${helpers.parseApiError(error as ts.types.common.ApiError)}`);
  }
};
