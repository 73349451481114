import { _, ts } from '_core';

import { DraftParams } from './types';

export const validate = (params: DraftParams) => {
  if (_.isEmpty(params.basket_ids)) return ['You need to add at least one basket.'];
  if (_.isEmpty(params.analysis_pipelined_signals)) return ['You need to add at least one signal.'];

  if (params.run_on_date && !params.basket_date) return ['You need to provide a valid date'];
  if (params.run_on_date && params.basket_date.toLowerCase().includes('invalid'))
    return ['You need to provide a valid date'];

  let errors = [] as string[];
  params.analysis_pipelined_signals.forEach((signal, idx) => {
    if (!signal.signal_id) {
      errors.push(`Signal ${idx + 1} - Signal is a required field.`);
    }
  });

  if (!_.isEmpty(params.component_errors)) errors = errors.concat(params.component_errors);
  return errors;
};

export const prepareData = (data: ts.types.widgets.TableData, resources: ts.StoreState['resources']) => {
  const prepareRow = (row: ts.types.widgets.TableData[0]) => {
    const newRow = {} as ts.types.widgets.TableData[0];
    Object.keys(row).forEach((col) => {
      if (col == 'basket') {
        const basket = resources.baskets.find((b) => b.handle == row[col])?.name ?? row[col];
        newRow['Basket'] = basket;
      } else {
        const els = col.split('=>');
        const signal = resources.signals.find((s) => s.handle == els[0])?.name ?? els[0];
        const pipe = els?.[1] ? (resources.pipelines.find((s) => s.handle == els[1])?.name ?? els[1]) : null;
        const sppipe = els?.[2] ? (resources.pipelines.find((s) => s.handle == els[2])?.name ?? els[2]) : null;

        const newCol = [signal, pipe, sppipe].filter((el) => el).join(' => ');
        newRow[newCol] = row[col];
      }
    });
    return newRow;
  };

  return data.map((row) => prepareRow(row));
};
