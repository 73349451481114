/* eslint-disable no-unused-vars */
import { ts } from '_core';

export type SHEETS =
  | ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.BACKTEST_BUCKET_ANALYSIS_RETURN_DETAILS
  | ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.BACKTEST_BUCKET_ANALYSIS_BUCKET_ALLOCATION_DETAILS
  | ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.BACKTEST_BUCKET_ANALYSIS_BUCKET_STOCK_SELECTION_DETAILS
  | ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.BACKTEST_BUCKET_ANALYSIS_RETURN_SUMMARY
  | ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.BACKTEST_BUCKET_ANALYSIS_BUCKET_ALLOCATION_SUMMARY
  | ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.BACKTEST_BUCKET_ANALYSIS_BUCKET_STOCK_SELECTION_SUMMARY;

export enum INTERACTION_PARAMS {
  TABLE_PARAMS = 'table_params',
  CURRENT_SHEET = 'current_sheet',
  IS_PORTFOLIO = 'is_portfolio',
}

export type InteractionParams = {
  [INTERACTION_PARAMS.TABLE_PARAMS]: ts.types.components.dataGrid.TableParamsSheet;
  [INTERACTION_PARAMS.CURRENT_SHEET]: SHEETS;
  [INTERACTION_PARAMS.IS_PORTFOLIO]: boolean;
};

export type JobParams = {
  bucket: ts.types.widgets.common.BucketValue;
  benchmark: Record<string, ts.enums.BENCHMARK_TYPES_ENUM | string | number>;
  strategy_benchmark: boolean;
};

export type Params = JobParams & InteractionParams & { fullWidth: boolean };
export type DraftParams = Partial<Params>;
