import React from 'react';

import { mui } from '../libs';

/**
 * Styled TreeItem component with custom styling for tree nodes
 */
const TreeItemComp = mui.styles.styled(mui.TreeItem)({
  padding: 0,
  position: 'relative!important' as 'relative',
  background: '#F4F7FB!important',
  '& .MuiTreeItem-group': {
    marginLeft: '4px !important',
  },
  '& .MuiTreeItem-label': {
    fontWeight: '500 !important',
    fontSize: '0.7rem !important',
    display: 'flex !important',
    alignItems: 'center !important',
    paddingLeft: '8px !important',
  },
  '& .MuiTreeItem-iconContainer': {
    position: 'absolute!important' as 'absolute',
    left: 0,
  },
  '& > .MuiTreeItem-group': {
    marginLeft: '12px',
  },
});

type FinTreeItemProps = {
  /** Child nodes to render under this tree item */
  children?: React.ReactNode;
  /** Label content to display for this tree item */
  label: React.ReactElement;
  /** Unique ID for this tree node */
  nodeId: string;
  /** Optional custom styles to apply */
  sx?: Record<string, React.CSSProperties>;
};

/**
 * Component that renders a customized tree item node with consistent styling
 *
 * @param children - Child nodes to render under this tree item
 * @param label - Label content to display for this tree item
 * @param nodeId - Unique ID for this tree node
 * @param sx - Optional custom styles to apply
 * @returns Rendered tree item component
 */
const FinTreeItem: React.FC<FinTreeItemProps> = ({ children, label, nodeId, sx }): React.ReactElement => (
  <TreeItemComp label={label} nodeId={nodeId} sx={sx}>
    {children}
  </TreeItemComp>
);

export default FinTreeItem;
