import { _, ts } from '_core';

import { getIndustryName } from '../../common/utils/get-series-name';

export const getTabName = (tab: 'signal_count' | 'pipeline_count' | 'sp_pipeline_count') => {
  if (tab == 'signal_count') return 'Signal';
  if (tab == 'pipeline_count') return 'Pipeline';
  if (tab == 'sp_pipeline_count') return 'SP Pipeline';
};

export const prepareNames = (
  data: ts.types.widgets.LinearData,
  by: 'rbics_l1_id' | 'rbics_l2_id' | 'rbics_l3_id' | 'rbics_l4_id',
  resources: ts.StoreState['resources']
) => {
  const newData = {} as ts.types.widgets.LinearData;

  Object.keys(data).forEach((key) => {
    let newKey = key;
    if (key != 'other') {
      newKey = getIndustryName(key, by, resources);
      newData[_.startCase(newKey)] = data[key];
    }
    return [];
  });

  return newData;
};
