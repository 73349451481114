import { React, ts } from '_core';

import Chart from './chart';
import { DraftParams, Params } from './types';

type RiskModelVolatilityVsTimeProps = {
  id: string;
  readOnly: boolean;
  widgetKey: string;
  mode: ts.enums.CHART_MODE_ENUM;
  params: Params | DraftParams;
  setParams: (_p: Params | DraftParams) => void;

  fullScreen?: boolean;
  context?: ts.types.riskModel.RiskModelAnalysisContext;
  loadWidgetData: (_payload: ts.types.widgets.WidgetGetDataParams) => Promise<ts.types.widgets.WidgetGetDataResponse>;
  widgetPaths?: Record<'risk_model_factor_volatility', string>;

  title: string;
};

const VolatilityVsTime: React.FC<RiskModelVolatilityVsTimeProps> = ({
  id,
  readOnly,
  widgetKey,
  loadWidgetData,
  widgetPaths,
  fullScreen,
  context,
  params,
  setParams,
  title,
}): React.ReactElement => {
  const renderJobComponent = () => (
    <>
      <Chart
        id={id}
        readOnly={readOnly}
        widgetKey={widgetKey}
        loadWidgetData={loadWidgetData}
        volatility={widgetPaths['risk_model_factor_volatility']}
        fullScreen={fullScreen}
        params={params}
        setParams={setParams}
        context={context}
        title={title}
      />
    </>
  );

  return renderJobComponent();
};

export default VolatilityVsTime;
