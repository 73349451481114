import React from 'react';
import { am5, am5themes_Animated, am5xy, mui } from '@local/finsera-core/src/libs';
import * as types from '@local/finsera-core/src/types';

import { createAxes } from './methods/create-axes';
import { createSeries } from './methods/create-series';
import { addLabels } from '../shared/add-labels';
import { createBaseLegend } from '../shared/create-base-legend';
import { createBaseTooltips } from '../shared/create-base-tooltips';
import { createDownloadButton } from '../shared/create-download-button';
import { handleZoom } from '../shared/handle-zoom';

export type ChartProps = {
  id: string;
  data: types.widgets.TableData;
  fullScreen: boolean;
  xAxisField: string;
  download: boolean;
  yAxisLabel: string | undefined;
  xAxisLabel: string | undefined;
};

const Chart = ({ id, data, fullScreen, xAxisField, download, yAxisLabel, xAxisLabel }: ChartProps) => {
  const widgetId = `chart-${id}-${fullScreen ? 'fs' : 'sw'}`;
  const theme = mui.styles.useTheme() as mui.core.Theme;
  const dataLength = Object.keys(data?.[0] ?? {}).length;
  const shouldShowLegend = dataLength > 1 && (fullScreen || dataLength <= 6);

  React.useEffect(() => {
    // Create root element
    const root = am5.Root.new(widgetId);

    // Remove amCharts logo
    root._logo?.dispose();

    // Set themes
    root.setThemes([am5themes_Animated.new(root)]);

    // Create chart
    const chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        wheelX: 'panX',
        wheelY: 'zoomX',
        pinchZoomX: true,
        layout: root.verticalLayout,
        maxTooltipDistance: 0,
        paddingLeft: 30,
        paddingBottom: shouldShowLegend ? 0 : 20,
      })
    );

    // Root settings
    chart.appear(1000, 100);

    // Create elements
    const { xAxis, yAxis } = createAxes({ root, chart, data, xAxisField });
    createSeries({ root, chart, data, xAxisField, xAxisRenderer: xAxis, yAxisRenderer: yAxis });
    if (shouldShowLegend) createBaseLegend({ root, chart, yAxis });
    createBaseTooltips({ root, chart, xAxis: xAxis as any, yAxis, theme });
    handleZoom(chart);
    if (download) createDownloadButton(root, xAxisField, data);
    addLabels({ root, yAxis, xAxis, yAxisLabel, xAxisLabel });

    return () => {
      root.dispose();
    };
  }, [data]);

  return <div id={widgetId} style={{ width: '100%', height: '100%' }} />;
};

export default Chart;
