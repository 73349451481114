import { ts } from '_core';

import { validate } from './helpers';
import { DraftParams, INTERACTION_PARAMS } from './types';

export default [
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.BASKET_SUMMARY_STATISTICS,
    title: 'Summary Statistics',
    helpLabel: 'tuqnffkm',
    form: true,
    defaultParams: {
      isBasket: true,
      histories: ['1 Year', '3 Years', '5 Years', 'Full', 'Year to Date'],
    } as DraftParams,
    interactionParams: Object.values(INTERACTION_PARAMS),
    neededFiles: ['backtest_summarized_statistics_analysis'],
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.BASKET],

    validate,
  },
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.BACKTEST_SUMMARY_STATISTICS,
    title: 'Summary Statistics',
    helpLabel: '9vjw2jyt',
    form: true,
    defaultParams: {
      isBasket: false,
      histories: ['1 Year', '3 Years', '5 Years', 'Full'],
    } as DraftParams,
    interactionParams: Object.values(INTERACTION_PARAMS),
    neededFiles: ['backtest_summarized_statistics_analysis'],
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.BACKTEST],

    validate,
  },
] satisfies ts.types.analysis.WidgetConfig[];
