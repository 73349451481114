import { mui, React, ts } from '_core';

type OrganizationZeroFieldsProps = {
  params: ts.types.dataSource.DataSourceFormParamsDraft;
  setParams: (_p: ts.types.dataSource.DataSourceFormParamsDraft) => void;
};

const OrganizationZeroFields: React.FC<OrganizationZeroFieldsProps> = ({ params, setParams }): React.ReactElement => (
  <>
    {params.hasAssets && (
      <mui.core.Box pb={2}>
        <mui.core.FormControlLabel
          control={
            <mui.core.Switch
              size="small"
              checked={params.hasFID}
              color="primary"
              onChange={() => setParams({ ...params, hasFID: !params.hasFID })}
            />
          }
          label="Has FID (Only available on organization 0)"
        />
      </mui.core.Box>
    )}

    <mui.core.Box pb={3}>
      <mui.core.TextField
        size="small"
        label="Data Provider"
        value={params.dataProvider}
        onChange={(e) => setParams({ ...params, dataProvider: e.target.value })}
        fullWidth
        variant="outlined"
        required
        inputProps={{
          autocomplete: 'off',
        }}
      />
    </mui.core.Box>

    <mui.core.Box pb={3}>
      <mui.core.TextField
        size="small"
        label="Data Set"
        value={params.dataSet}
        onChange={(e) => setParams({ ...params, dataSet: e.target.value })}
        fullWidth
        variant="outlined"
        required
        inputProps={{
          autocomplete: 'off',
        }}
      />
    </mui.core.Box>
  </>
);

export default OrganizationZeroFields;
