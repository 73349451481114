import { custom, helpers, ts } from '_core';

import { getDynamicName, getPipelineNames } from 'views/report/widgets/common/utils/signal-utils';

export default {
  [ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.MAIN]: [
    {
      key: 'index',
      name: 'Index',
      formatter: helpers.tableFormatters.formatTo('inferred'),
      members: (key: string) => {
        if (['build_date', 'data_date', 'asset'].includes(key)) return true; // This is for old analyses
        if (key.startsWith('_index_')) return true;
        return false;
      },
      cleanNameFormatter: (key) => getDynamicName(key),
      nameFormatter: (key) => getDynamicName(key),
      isIndex: true,
      filter: 'inferred',
    },
    {
      key: 'value',
      name: 'Value',
      formatter: helpers
        .tableFormatters.formatTo('inferred', { align: 'right', commasOnThousands: true, roundDigits: 4 }),
      filter: 'inferred',
    },
    {
      key: 'name',
      name: 'Name',
      formatter: helpers.tableFormatters.formatTo('string', { align: 'left' }),
      filter: 'string',
    },
    {
      key: 'asset_type',
      name: 'Type',
      formatter: helpers.tableFormatters.formatTo('string', { align: 'left' }),
      filter: 'string',
    },
    {
      key: 'ticker',
      name: 'Ticker',
      formatter: helpers.tableFormatters.formatTo('string', { align: 'left' }),
      filter: 'string',
    },
    {
      key: 'rbics_l1_id',
      name: 'Sector Code',
      formatter: helpers.tableFormatters.formatTo('integer', { align: 'right' }),
      filter: 'string',
    },
    {
      key: 'rbics_l2_id',
      name: 'Sub-Sector Code',
      formatter: helpers.tableFormatters.formatTo('integer', { align: 'right' }),
      filter: 'string',
    },
    {
      key: 'rbics_l3_id',
      name: 'Industry Code',
      formatter: helpers.tableFormatters.formatTo('integer', { align: 'right' }),
      filter: 'string',
    },
    {
      key: 'rbics_l4_id',
      name: 'Sub-Industry Code',
      formatter: helpers.tableFormatters.formatTo('integer', { align: 'right' }),
      filter: 'string',
    },
    {
      key: 'rbics_l1_name',
      name: 'Sector Name',
      formatter: helpers.tableFormatters.formatTo('string', { align: 'left' }),
      filter: 'string',
    },
    {
      key: 'rbics_l2_name',
      name: 'Sub-Sector Name',
      formatter: helpers.tableFormatters.formatTo('string', { align: 'left' }),
      filter: 'string',
    },
    {
      key: 'rbics_l3_name',
      name: 'Industry Name',
      formatter: helpers.tableFormatters.formatTo('string', { align: 'left' }),
      filter: 'string',
    },
    {
      key: 'rbics_l4_name',
      name: 'Sub-Industry Name',
      formatter: helpers.tableFormatters.formatTo('string', { align: 'left' }),
      filter: 'string',
    },
    {
      key: 'cusip',
      name: 'Cusip',
      formatter: helpers.tableFormatters.formatTo('string', { align: 'left' }),
      filter: 'string',
      condition: (features: ts.types.organization.OrganizationFeatures) => !features.hide_cusip_col,
    },
    {
      key: 'isin',
      name: 'ISIN',
      formatter: helpers.tableFormatters.formatTo('string', { align: 'left' }),
      filter: 'string',
      condition: (features: ts.types.organization.OrganizationFeatures) => !features.hide_isin_col,
    },
    {
      key: 'issuer_country',
      name: 'Issuer Country',
      formatter: helpers.tableFormatters.formatTo('string', { align: 'center' }),
    },
    {
      key: 'quotation_country',
      name: 'Quotation Country',
      formatter: helpers.tableFormatters.formatTo('string', { align: 'center' }),
    },
    {
      key: 'currency',
      name: 'Currency',
      formatter: helpers.tableFormatters.formatTo('string', { align: 'center' }),
    },
    {
      key: '1-period-return',
      name: '1-Period Forward Return',
      formatter: helpers.tableFormatters.formatTo('float', {
        align: 'right',
        roundDigits: 2,
        multiplier: 100,
        suffix: '%',
      }),
      filter: 'number',
    },
    {
      key: 'pipelines',
      name: 'Pipelines',
      members: (key) => key.startsWith('_handle_'),
      formatter: helpers.tableFormatters.formatTo('inferred'),
      cleanNameFormatter: (key, _c, resources: ts.StoreState['resources']) => getPipelineNames(key, resources, true),
      nameFormatter: (key, _c, resources: ts.StoreState['resources']) => getPipelineNames(key, resources, false),
      filter: 'inferred',
    },
    {
      key: 'alpha_signals',
      name: 'Alpha Signals',
      formatter: helpers.tableFormatters.formatTo('inferred'),
      members: (key) => key.startsWith('_alpha_exposures_'),
      cleanNameFormatter: (key) => `Alpha Signals: ${key.split('_alpha_exposures_')[1]}`,
      nameFormatter: (key) => {
        const handle = key.split('_alpha_exposures_')[1];
        const signal = handle.split('=>')[0];
        const pipeline = handle.split('=>')[1];
        return <custom.MultilineTableHeader name={['Alpha Signals:', signal, pipeline]} />;
      },
      filter: 'inferred',
    },
    {
      key: 'dynamic', // debug
      name: 'Signal Specific',
      members: () => true,
      formatter: helpers.tableFormatters.formatTo('inferred'),
      cleanNameFormatter: (key) => getDynamicName(key),
      nameFormatter: (key) => getDynamicName(key),
      filter: 'inferred',
    },
  ] as ts.types.components.dataGrid.ColumnsData,
};
