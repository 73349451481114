/* eslint-disable no-unused-vars */
export enum INTERACTION_PARAMS {
  SELECTED_PARAM = 'selectedParam',
}

export type InteractionParams = {
  [INTERACTION_PARAMS.SELECTED_PARAM]: string;
};

export type JobParams = {
  file: 'portfolios';
  metric: string;
  aggregation_params: {
    force_all_row: true;
  };
};

export type Params = JobParams & InteractionParams;
export type DraftParams = Partial<Params>;
