import { _, helpers } from '_core';

import { DraftParams } from './types';

export const validate = (params: DraftParams) => {
  const rhErrors = helpers.validations.validSelectedReturnHorizons(params?.return_horizons);
  if (!_.isEmpty(rhErrors)) return rhErrors;
  if (params.with_benchmark && !helpers.validations.validBenchmark(params.benchmark))
    return ['Benchmark is invalid.'];
  return [];
};
