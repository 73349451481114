import { ts } from '_core';

import { getName, validate } from './helpers';
import { DraftParams } from './types';

export default [
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.SIGNAL_ANNUALIZED_RETURN,
    title: 'Annualized Returns',
    helpLabel: 'r528hes2',
    form: true,
    defaultParams: {
      statistics_type: 'return',
      histories: ['1 Year', '3 Years', '5 Years', 'Full'],
    } as DraftParams,
    neededFiles: ['signal_annualized_returns'],
    tags: [
      ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.SIGNAL,
      ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.ALPHA_MODEL,
      ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.COMMON_ALPHA_MODEL,
    ],

    getName,
    validate,
  },
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.SIGNAL_ANNUALIZED_RISK,
    title: 'Annualized Risk',
    helpLabel: 'dpen4ssx',
    form: true,
    defaultParams: {
      statistics_type: 'risk',
      histories: ['1 Year', '3 Years', '5 Years', 'Full'],
    } as DraftParams,
    neededFiles: ['signal_annualized_returns'],
    tags: [
      ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.SIGNAL,
      ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.ALPHA_MODEL,
      ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.COMMON_ALPHA_MODEL,
    ],

    getName,
    validate,
  },
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.SIGNAL_ANNUALIZED_ADJUSTED,
    title: 'Annualized Risk Adjusted Returns',
    helpLabel: 'bawjco48',
    form: true,
    defaultParams: {
      statistics_type: 'adjusted',
      histories: ['1 Year', '3 Years', '5 Years', 'Full'],
    } as DraftParams,
    neededFiles: ['signal_annualized_returns'],
    tags: [
      ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.SIGNAL,
      ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.ALPHA_MODEL,
      ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.COMMON_ALPHA_MODEL,
    ],

    getName,
    validate,
  },
] satisfies ts.types.analysis.WidgetConfig[];
