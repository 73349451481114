import { _, mui, React, ts, useSelector } from '_core';

import { Params } from './types';
import ChartWrapper from '../../common/chart-wrapper';
import createHeatMapChart from '../../common/charts/create-heat-map';
import { prepareBucketedRegimeData } from '../../common/utils/signal-utils';

type ChartProps = {
  id: string;
  readOnly: boolean;
  widgetKey: string;
  fullScreen: boolean;
  loadWidgetData: (_payload: ts.types.widgets.WidgetGetDataParams) => Promise<ts.types.widgets.WidgetGetDataResponse>;
  file: string;
  params: Params;
  context?: ts.types.signal.SignalAnalysisContext;
  title: string;
};

const Chart: React.FC<ChartProps> = ({
  id,
  readOnly,
  widgetKey,
  fullScreen,
  loadWidgetData,
  file,
  params,
  context,
  title,
}) => {
  const [data, setData] = React.useState<ts.types.widgets.HeatMapData>(null);
  const [error, setError] = React.useState<ts.types.common.ApiError>(null);
  const [selectedTab, setSelectedTab] = React.useState<'returns' | 'count' | 'risk' | 'risk_adjusted_returns'>(
    'returns'
  );

  const resources = useSelector((state) => state.resources);

  const signals = useSelector((state) => state.resources.signals);

  const currSig = React.useMemo(() => {
    return signals.find((s) => s.id == context.signal_id);
  }, [context.signal_id]);

  const loadData = async (tab: 'returns' | 'count' | 'risk' | 'risk_adjusted_returns') => {
    try {
      const response = await loadWidgetData({
        data: {
          file,
          query: ['tab', 'equals', tab],
        },
      });

      if (response) {
        setData(response.data as ts.types.widgets.HeatMapData);
      }
    } catch (err) {
      setError(err as ts.types.common.ApiError);
    }
  };

  React.useEffect(() => {
    if (file) loadData(selectedTab);
    return () => setData(null);
  }, [file, selectedTab]);

  const yAxisLabel = React.useMemo(() => {
    if (params.buckets?.type == ts.enums.BUCKET_TYPES_ENUM.SIGNAL)
      return signals.find((s) => s.id == params.buckets.signal_id).name;
    return undefined;
  }, []);

  React.useEffect(() => {
    // Create line chart on data and scale changes
    const format = selectedTab === 'returns' || selectedTab === 'risk' ? '#.##%' : '#.##';
    if (!_.isNil(data)) {
      createHeatMapChart({
        id,
        data: data,
        prepareData: (heatMapData) =>
          prepareBucketedRegimeData(heatMapData, false, params.quantiles, params.buckets, resources),
        fullScreen,
        xAxis: 'bucket1',
        yAxis: 'bucket2',
        yAxisLabel,
        xAxisLabel: currSig.name,
        rotateLabels: false,
        format,
        exportTitle: title,
      });
    }
  }, [data]);

  const getTabName = (t = selectedTab) => {
    if (t == 'count') return 'Avg Count';
    if (t == 'returns') return 'Annualized Returns';
    if (t == 'risk') return 'Annualized Risk';
    if (t == 'risk_adjusted_returns') return 'Annualized Risk Adjusted Return';
    return _.startCase(t);
  };

  const getTabs = () => {
    return (
      <mui.core.Tabs
        indicatorColor="primary"
        textColor="primary"
        value={selectedTab}
        onChange={(e, newTab) => setSelectedTab(newTab)}
        aria-label="Tabs"
      >
        {['returns', 'count', 'risk', 'risk_adjusted_returns'].map((tab) => (
          <mui.core.Tab key={tab} label={getTabName(tab as any)} value={tab} />
        ))}
      </mui.core.Tabs>
    );
  };

  return (
    <ChartWrapper
      id={id}
      readOnly={readOnly}
      widgetKey={widgetKey}
      data={data}
      warning={data?.length === 0 ? ['Empty File'] : []}
      error={error}
      fullScreen={fullScreen}
      tabs={getTabs()}
    />
  );
};

export default Chart;
