import { ts } from '_core';


export default [
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.REBALANCE_PORTFOLIO_SUMMARY,
    form: false,
    title: 'Summary',
    neededFiles: ['portfolio_summary_analysis'],
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.REBALANCE_PORTFOLIO_HOLDINGS],

    helpLabel: 'npu0sa5z',
  },
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.PORTFOLIO_SUMMARY,
    form: false,
    title: 'Summary',
    neededFiles: ['portfolio_summary_analysis_holdings'],
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.PORTFOLIO_HOLDINGS_ANALYSIS],

    helpLabel: '76zuql4k',
  },
] satisfies ts.types.analysis.WidgetConfig[];
