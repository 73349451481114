import { _, helpers, ts } from '_core';

import { DraftParams, Params } from './types';
import { getBenchmarkTitle } from '../../common/utils/get-name';

export const validate = (params: DraftParams) => {
  if (params.selected_type == 'benchmark') {
    if (!helpers.validations.validBenchmark(params.benchmark)) return ['Benchmark is invalid.'];
  } else {
    const basketCompareErrors = helpers.validations.validBasketCompare(params);
    if (!_.isEmpty(basketCompareErrors)) return basketCompareErrors;
  }

  return [];
};

export const getName = (
  params: Params,
  _context: ts.types.signal.SignalAnalysisContext,
  resources: ts.StoreState['resources']
) => {
  if (params.selected_type == 'benchmark') {
    return getBenchmarkTitle(params.benchmark, 'Relative Returns', resources.universes);
  }

  const selectedBasket = resources.baskets.find((s) => s.id == params?.basket_ids?.[0]);
  const suffixName = selectedBasket.name;

  return `Relative Returns Relative to ${suffixName}`;
};
