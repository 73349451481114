import { ts } from '_core';

import { validate } from './helpers';
import { DraftParams } from './types';

export default [
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.BASKET_COMPARE_CUMULATIVE_RETURNS,
    title: 'Cumulative Returns over Time',
    form: true,
    helpLabel: '394kvwq7',
    generalAnalysisReportHelp: {
      document: ts.enums.UI_RESOURCE_TYPE_ENUM.BASKET,
      contentPath: `/${ts.enums.BASKET_ANALYSIS_TYPE_ENUM.COMPARE}`,
    },
    neededFiles: ['backtest_returns'],
    defaultParams: {
      histories: Object.values(ts.enums.PERIOD_ENUM),
    } as DraftParams,
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.BASKET_COMPARE, ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.GENERAL],

    validate,
    generalAnalysisPrefix: 'Baskets ',
  },
] satisfies ts.types.analysis.WidgetConfig[];
