import React from 'react';
import { PickersDayProps } from '@mui/x-date-pickers';

import { DatePickerFooter } from './smart-date-selector';
import { mergeRefs } from '../helpers';
import { moment, mui } from '../libs';

type FinDatePickerProps = {
  value: string;
  onChange?: (_d: string) => void;
  minDate?: string;
  maxDate?: string;
  label?: string;
  dateRef?: React.MutableRefObject<HTMLDivElement>;
  variant?: 'filled' | 'outlined' | 'standard';
  [key: string]: any;
  error?: boolean;
  renderDay?: (_day: Date, _selectedDays: Date[], _pickersDayProps: PickersDayProps<Date>) => JSX.Element;
};

/**
 * Date picker component for selecting dates
 * 
 * @param value - Current date value in string format
 * @param onChange - Function to handle date change
 * @param minDate - Minimum selectable date in string format
 * @param maxDate - Maximum selectable date in string format
 * @param label - Label text for the date picker
 * @param dateRef - Ref for the date picker input
 * @param variant - Variant of the date picker ('filled', 'outlined', 'standard')
 * @param inputProps - Additional input props for the date picker
 * @param error - Error state indicator
 * @param renderDay - Custom render function for the date picker day
 * 
 * @returns The FinDatePicker component.
 */

const FinDatePicker: React.FC<FinDatePickerProps> = ({ ...props }): React.ReactElement => {
  const { value, onChange, minDate, maxDate, label, dateRef, disabled, variant, inputProps, error, renderDay } = props;

  const [openDatePicker, setOpenDatePicker] = React.useState(false);

  return (
    <>
      <mui.date.LocalizationProvider
        dateAdapter={mui.date.AdapterDateFns as any}
        localeText={{
          start: 'Start Date',
          end: 'End Date',
        }}
      >
        <mui.date.DatePicker
          {...props}
          open={openDatePicker}
          onOpen={() => setOpenDatePicker(true)}
          onClose={() => setOpenDatePicker(false)}
          defaultCalendarMonth={maxDate ? moment(maxDate).toDate() : undefined}
          inputFormat="yyyy-MM-dd"
          value={value ? moment(value).toDate() : null}
          onChange={(d) => {
            const date = moment(d);
            if (date.isValid()) onChange(date.format('YYYY-MM-DD'));
            else onChange(null);
          }}
          maxDate={maxDate ? moment(maxDate).toDate() : undefined}
          minDate={minDate ? moment(minDate).toDate() : undefined}
          label={label}
          renderDay={renderDay}
          InputProps={{
            ...{
              disableUnderline: true,
              size: 'small',
              startAdornment: (
                <mui.core.InputAdornment position="start">
                  <mui.icons.CalendarToday />
                </mui.core.InputAdornment>
              ),
            },
            ...inputProps,
          }}
          components={{
            ActionBar: () => {
              if (renderDay) {
                return (
                  <mui.core.Box pb={3}>
                    <mui.core.Box flex={1} mt={-2}>
                      <DatePickerFooter />
                    </mui.core.Box>
                  </mui.core.Box>
                );
              }
            },
          }}
          renderInput={(props) => (
            <mui.core.TextField
              {...props}
              // eslint-disable-next-line react/prop-types
              inputRef={mergeRefs([props.inputRef, dateRef])}
              style={{ width: '100%' }}
              variant={variant || 'outlined'}
              onClick={() => !disabled && setOpenDatePicker(true)}
              error={error}
            />
          )}
        />
      </mui.date.LocalizationProvider>
    </>
  );
};

export default FinDatePicker;
