import { _, helpers } from '_core';

import { DraftParams } from './types';

export const validate = (params: DraftParams) => {
  const rhErrors = helpers.validations.validSelectedReturnHorizons(params?.return_horizons);
  if (!_.isEmpty(rhErrors)) return rhErrors;
  if (params.variant == 'histories' && _.isEmpty(params.histories)) return ['Histories is a required field.'];
  return [];
};
