import { _, mui, React, ts } from '_core';

import { Params } from './types';
import ChartWrapper from '../../common/chart-wrapper';
import createNestedBarChart from '../../common/charts/create-nested-bar-chart';

type ChartProps = {
  id: string;
  readOnly: boolean;
  widgetKey: string;
  loadWidgetData: (_payload: ts.types.widgets.WidgetGetDataParams) => Promise<ts.types.widgets.WidgetGetDataResponse>;
  file: string;
  hasSPPipeline: boolean;
  hasPipeline: boolean;
  fullScreen: boolean;
  params: Params;
  title: string;
};

const Chart: React.FC<ChartProps> = ({
  id,
  readOnly,
  widgetKey,
  loadWidgetData,
  file,
  params,
  hasSPPipeline,
  hasPipeline,
  fullScreen,
  title,
}) => {
  const [data, setData] = React.useState<Record<'raw' | 'pipeline' | 'sp_pipeline', ts.types.widgets.TableData>>(null);
  const [formattedData, setFormattedData] = React.useState<ts.types.widgets.TableData>(null);
  const [error, setError] = React.useState<ts.types.common.ApiError>(null);
  const [showLegend, setShowLegend] = React.useState(false);
  const [selectedTab, setSelectedTab] = React.useState<'raw' | 'pipeline' | 'sp_pipeline'>('raw');

  const loadData = async () => {
    try {
      const response = await loadWidgetData({
        data: {
          file,
        },
      });
      if (response) {
        const renamedData = response.data.map((item) => {
          return {
            ..._.omit(item, ['histogram', 'history']),
            category: item.histogram,
          };
        });

        const groupedData = _.groupBy(renamedData, 'histogram_type') as any;
        setData(groupedData);
      }
    } catch (err) {
      setError(err as ts.types.common.ApiError);
    }
  };

  React.useEffect(() => {
    if (file) loadData();
    return () => setData(null);
  }, [file]);

  React.useEffect(() => {
    if (!_.isNil(data)) {
      const localData = data?.[selectedTab];
      setFormattedData(localData);
    }
  }, [data, selectedTab]);

  React.useEffect(() => {
    if (!_.isNil(formattedData))
      createNestedBarChart({
        id,
        data: formattedData,
        fullScreen,
        setShowLegend,
        format: null,
        hideLegend: true,
        yAxisLabel: params.percent ? 'Count (%)' : 'Count',
        tooltipText: params.percent
          ? 'Count: {value}% \n Min: {min} \n Max: {max}'
          : 'Count: {value} \n Min: {min} \n Max: {max}',
        rotatedLabels: true,
        hideSeries: ['min', 'max', 'histogram_type'],
        exportTitle: title,
      });
  }, [formattedData]);

  const getTabs = () => (
    <mui.core.Tabs
      indicatorColor="primary"
      textColor="primary"
      value={selectedTab}
      onChange={(e, newTab) => setSelectedTab(newTab)}
    >
      <mui.core.Tab label="Raw" value="raw" />
      {hasPipeline && <mui.core.Tab label="After Pipeline" value="pipeline" />}
      {hasSPPipeline && <mui.core.Tab label="After Portfolio Pipeline" value="sp_pipeline" />}
    </mui.core.Tabs>
  );

  return (
    <ChartWrapper
      id={id}
      readOnly={readOnly}
      widgetKey={widgetKey}
      data={data}
      showLegend={showLegend}
      error={error}
      tabs={getTabs()}
      fullScreen={fullScreen}
    />
  );
};

export default Chart;
