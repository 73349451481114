import React from 'react';

import IndexSelector from './index-selector';
import UniverseSelector from './universe-selector';
import * as enums from '../../enums';
import { useEffectWithoutFirst } from '../../hooks';
import { mui } from '../../libs';
import * as types from '../../types';

type BenchmarkProps = {
  value: types.benchmark.Benchmark;
  setValue?: (_v: types.benchmark.Benchmark) => void;
  universeIdPath?: string;
  readOnlyType?: boolean;
  readOnlyUniverse?: boolean;
  hideContext?: boolean;
  universeContextPath?: string;
  context?: Record<string, any>;
  isError?: boolean;
  extraOptions?: { value: string; title: string }[];
  enableNone?: boolean;
  enableAnnualizedSignals?: boolean;
};

const EMPTY_ARRAY: any[] = [];

/**
 * Component that provides a user interface for selecting and configuring benchmark types
 *
 * @param value - Current benchmark configuration object
 * @param setValue - Callback to update benchmark configuration
 * @param readOnlyType - Whether benchmark type selection is read-only
 * @param readOnlyUniverse - Whether universe selection is read-only
 * @param universeIdPath - Path to universe ID in context
 * @param universeContextPath - Path to universe context
 * @param hideContext - Whether to hide context selector
 * @param context - Context object containing universe data
 * @param isError - Whether there is a validation error
 * @param extraOptions - Additional benchmark type options
 * @param enableNone - Whether to enable "None" as a benchmark type
 * @param enableAnnualizedSignals - Whether to enable annualized signals options
 *
 * @returns Rendered benchmark selector component
 */
const Benchmark = ({
  value,
  setValue,
  context,
  isError,
  universeIdPath,

  extraOptions = EMPTY_ARRAY,
  readOnlyType = false,
  readOnlyUniverse = false,
  universeContextPath = null,
  hideContext = false,
  enableNone = false,
  enableAnnualizedSignals = false,
}: BenchmarkProps): React.ReactElement => {
  const [localValue, setLocalValue] = React.useState(value ?? { type: 'INDEX' });

  useEffectWithoutFirst(() => {
    setValue(localValue);
  }, [localValue]);

  const selectedType = React.useMemo(() => {
    if (localValue.type == enums.BENCHMARK_TYPES_ENUM.ANNUALIZED_RETURNS_SIGNAL) {
      return localValue.source;
    }
    return localValue.type;
  }, [localValue]);

  const handleTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value == 'federal_funds_effective_rate.value') {
      setLocalValue({ type: enums.BENCHMARK_TYPES_ENUM.ANNUALIZED_RETURNS_SIGNAL, source: e.target.value });
    } else {
      setLocalValue({ type: e.target.value });
    }
  };

  return (
    <mui.core.Box mt={2} width="100%">
      <mui.core.Grid container spacing={4}>
        <mui.core.Grid item xs={12} md={4}>
          <mui.core.TextField
            disabled={readOnlyType}
            select
            label="Benchmark Type"
            variant="outlined"
            size="small"
            value={selectedType}
            onChange={handleTypeChange}
            fullWidth
            required
          >
            {extraOptions.map((option) => (
              <mui.core.MenuItem key={option.value} value={option.value}>
                {option.title}
              </mui.core.MenuItem>
            ))}
            <mui.core.MenuItem value={enums.BENCHMARK_TYPES_ENUM.INDEX}>Index</mui.core.MenuItem>
            <mui.core.MenuItem value={enums.BENCHMARK_TYPES_ENUM.UNIVERSE}>Universe</mui.core.MenuItem>

            {/* Options here are part of ANNUALIZED_RETURNS_SIGNAL type 
            and will be handled directly on the type selector*/}

            {enableAnnualizedSignals && (
              <mui.core.MenuItem value="federal_funds_effective_rate.value">
                Federal Funds Effective Rate
              </mui.core.MenuItem>
            )}

            {enableNone && <mui.core.MenuItem value="NONE">None</mui.core.MenuItem>}
          </mui.core.TextField>
        </mui.core.Grid>

        {localValue.type == enums.BENCHMARK_TYPES_ENUM.INDEX && (
          <IndexSelector value={localValue} onChange={setLocalValue} isError={isError} />
        )}

        {localValue.type == enums.BENCHMARK_TYPES_ENUM.UNIVERSE && (
          <>
            <UniverseSelector
              value={localValue}
              onChange={setLocalValue}
              context={context}
              universeContextPath={universeContextPath}
              isError={isError}
              readOnlyUniverse={readOnlyUniverse}
              universeIdPath={universeIdPath}
              hideContext={hideContext}
            />
          </>
        )}
      </mui.core.Grid>
    </mui.core.Box>
  );
};

export default Benchmark;
