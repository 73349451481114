import { _, helpers,ls, mui, React, ui } from '_core';

import Item from './item';

const EmptyView: React.FC<{ setOpened: (_opened: boolean) => void }> = ({ setOpened }) => {
  const theme = mui.styles.useTheme() as mui.core.Theme;
  const [recentlyOpened, setRecentlyOpened] = React.useState<helpers.resourcesSearch.ReturnItem[]>(
    ls.get({ path: 'recently-opened' })
  );

  const handleClearRecentlyOpened = () => {
    ls.clear({ path: 'recently-opened' });
    setRecentlyOpened([]);
  };

  if (_.isEmpty(recentlyOpened)) {
    return (
      <ui.EmptyMessage>
        Search resources by name, type, label, owner or use advanced filters to refine your search.
      </ui.EmptyMessage>
    );
  }

  return (
    <mui.core.Box sx={{ p: 2 }}>
      <mui.core.Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
        <mui.core.Typography
          variant="body2"
          sx={{
            color: theme.palette.text.disabled,
          }}
        >
          Recently Opened
        </mui.core.Typography>

        <mui.core.Typography
          variant="caption"
          sx={{
            color: theme.palette.text.disabled,
            textDecoration: 'underline',
            cursor: 'pointer',
            '&:hover': {
              textDecoration: 'underline',
            },
          }}
          onClick={handleClearRecentlyOpened}
        >
          Clear
        </mui.core.Typography>
      </mui.core.Box>

      {recentlyOpened.map((item) => (
        <Item
          key={`${item.resourceType}-${item.id}`}
          item={item}
          clickCallback={() => setOpened(false)}
          source="recently-opened"
        />
      ))}
    </mui.core.Box>
  );
};

export default EmptyView;
