import { _, ts } from '_core';

import widgetsOrder from './get-order';
/**
 * Creates a validate method for the given widget key.
 */

export const filterByTags = (
  reportTags: ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM[],
  widgets: ts.types.analysis.WidgetExtended[] | ts.types.analysis.WidgetConfig[]
): ts.types.analysis.WidgetExtended[] | ts.types.analysis.WidgetConfig[] => {
  return widgets.filter((w) => !_.isEmpty(_.intersection(reportTags, w.tags)));
};

export const getOptions = (
  widgets: ts.types.analysis.WidgetConfig[],
  reportTags: ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM[],
  mode: ts.enums.ANALYSIS_MODE_ENUM,
  layoutType = null as ts.enums.LAYOUT_TYPE,
  isGeneralAnalysys = false
) => {
  // Get the widget keys for the report tags
  const options = filterByTags(reportTags, widgets).map((w) => w.key);

  let sortedKeys = options;
  if (layoutType && widgetsOrder?.[layoutType]) {
    // Let's add the layout type order
    // [...order[layoutType], ...options] => combines all the keys
    // the intersection respects the order of the first join but get's only the option keys
    sortedKeys = _.intersection(
      [...widgetsOrder[layoutType], ...options],
      options
    ) as ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM[];
  }

  const sortedOptions = [] as ts.types.analysis.WidgetConfig[];
  sortedKeys.forEach((key) => {
    const widget = widgets.find((w) => w.key === key);
    if (widget) sortedOptions.push(widget);
    else throw Error(`The key: ${key} is not on the widgets list.`);
  });

  if (isGeneralAnalysys)
    return sortedOptions
      .filter((w) => !w.disableAdd)
      .map((w) => ({ ...w, title: `${w.getGeneralAnalysisPrefix() || ''}${w.title}` }));

  return sortedOptions.filter((w) => !w.disableAdd);
};
