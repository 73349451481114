import { ts } from '_core';

import { getName, validate } from './helpers';
import { INTERACTION_PARAMS } from './types';

export default [
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.BASKET_RELATIVE_RETURNS,
    title: 'Relative Returns',
    helpLabel: 't1e3d5vm',
    form: true,
    defaultParams: {
      format_for_ui: true,
    },
    interactionParams: Object.values(INTERACTION_PARAMS),
    neededFiles: ['backtest_relative_returns'],
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.BASKET],

    getName,
    validate,
  },
] satisfies ts.types.analysis.WidgetConfig[];
