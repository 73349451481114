import { _, helpers, ts } from '_core';

import { DraftParams } from './types';
import { getBucketsTitle } from '../../common/utils/get-name';
import { prepareBarChartBucketedNames, prepareBarChartCountNames } from '../../common/utils/get-series-name';

export const prepareBucketedData = (
  widgetData: ts.types.widgets.NestedBarWidgetData,
  bucketType: ts.types.widgets.common.BucketValue['type'],
  bucketValue: ts.types.widgets.common.BucketValue['value'],
  resources: ts.StoreState['resources']
): ts.types.widgets.NestedBarWidgetData => {
  const newData = {
    ...widgetData,
    data: prepareBarChartBucketedNames(widgetData.data, bucketType, bucketValue, resources),
    count: prepareBarChartCountNames(widgetData.count, bucketType, bucketValue, resources),
  };

  return newData;
};

export const validate = (params: DraftParams) => {
  const rhErrors = helpers.validations.validSelectedReturnHorizon(params?.return_horizon);
  if (!_.isEmpty(rhErrors)) return rhErrors;
  if (_.isEmpty(params.histories)) return ['Histories is a required field.'];
  if (!helpers.validations.validBuckets(params.buckets)) return ['Buckets are invalid.'];
  return [];
};

export const getName = (
  params: DraftParams,
  _context: ts.types.signal.SignalAnalysisContext,
  resources: ts.StoreState['resources']
) => {
  let widgetTitle = 'Portfolio-weighted Annualized Returns by Bucket';

  if (params.statistics_type === 'risk') widgetTitle = 'Portfolio-weighted Annualized Risk by Bucket';
  else if (params.statistics_type === 'adjusted')
    widgetTitle = 'Portfolio-weighted Annualized Risk Adjusted Returns by Bucket';

  const init = getBucketsTitle(params.buckets, widgetTitle, resources);
  return `${init} for ${helpers.returnHorizon.getLabel(params?.return_horizon)}`;
};
