import { ts } from '_core';

import { validate } from './helpers';
import { DraftParams, INTERACTION_PARAMS } from './types';

export default [
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.SIGNAL_SET_RETURNS_REPORT,
    title: 'Returns',
    helpLabel: '2yvb9odp',
    interactionParams: Object.values(INTERACTION_PARAMS),
    clearInteractionOnRegenerate: ['table_dtypes'],
    form: true,
    neededFiles: ['signal_set_returns'],
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.SIGNAL_SET_REPORT],

    defaultParams: {
      daily_reporting: true,
      return_horizons: [
        {
          periods: { frequency: 'DAILY', amount: 1 },
          skip: null,
          pipeline_ids: [],
          signature: '1_day(s)',
        },
      ],
    } as DraftParams,
    validate,
  },
] satisfies ts.types.analysis.WidgetConfig[];
