import React from 'react';

import * as enums from '../../enums';
import { useEffectWithoutFirst } from '../../hooks';
import { _, mui, useSelector } from '../../libs';
import * as types from '../../types';
import ResourceAutocomplete from '../resource-autocomplete';

type UniverseSelectorProps = {
  value: types.benchmark.Benchmark;
  onChange: (_updates: Partial<types.benchmark.Benchmark>) => void;
  universeIdPath?: string;
  context: Record<string, any>;
  readOnlyUniverse?: boolean;
  hideContext: boolean;
  universeContextPath?: string;
  isError: boolean;
};

/**
 * Component that provides universe selection functionality for benchmarks
 * 
 * @requires Redux Store:
 * - state.resources.universes: Array of available universes
 * 
 * @param value - Current benchmark configuration object
 * @param onChange - Callback to update benchmark configuration
 * @param universeIdPath - Path to universe ID in context
 * @param context - Context object containing universe data
 * @param readOnlyUniverse - Whether universe selection is read-only
 * @param hideContext - Whether to hide context selector
 * @param universeContextPath - Path to universe context
 * @param isError - Whether there is a validation error
 * 
 * @returns Rendered universe selector component
 */
const UniverseSelector: React.FC<UniverseSelectorProps> = ({
  value,
  onChange,
  universeIdPath,
  context,
  readOnlyUniverse,
  hideContext,
  universeContextPath,
  isError,
}): React.ReactElement => {
  const [universeFromContext, setUniverseFromContext] = React.useState(
    (value.type == 'UNIVERSE' && value.handle == universeContextPath) || false
  );
  const universes = useSelector((state: types.BaseStore) => state.resources.universes);

  useEffectWithoutFirst(() => updateUniverse(), [universeFromContext]);

  const updateUniverse = (id?: number) => {
    if (id) onChange({ ...value, handle: universes.find((u) => u.id == id)?.handle });
    else if (universeFromContext)
      if (context)
        onChange({
          ...value,
          handle: universes.find((u) => u.id == _.get(context, universeIdPath))?.handle,
        });
      else onChange({ ...value, handle: universeContextPath });
    else onChange({ ...value, handle: null });
  };

  return (
    <>
      {!hideContext && universeContextPath && (
        <mui.core.Grid item xs={12} md={8}>
          <mui.core.FormControlLabel
            control={
              <mui.core.Switch
                size="small"
                checked={universeFromContext}
                onChange={() => setUniverseFromContext(!universeFromContext)}
              />
            }
            label="Use analysis universe"
          />
        </mui.core.Grid>
      )}

      {!universeFromContext && (
        <mui.core.Grid item xs={12} md={4}>
          <ResourceAutocomplete<types.universe.UniverseExpanded>
            value={universes.find((u) => u.handle == value.handle)?.id}
            setValue={updateUniverse}
            type={enums.RESOURCES_TYPES_ENUM.UNIVERSE}
            disabled={readOnlyUniverse}
            inputProps={{
              fullWidth: true,
              label: 'Benchmark Universe',
              variant: 'outlined',
              size: 'small',
              required: true,
              error: isError,
            }}
          />
        </mui.core.Grid>
      )}

      <mui.core.Grid item xs={12} md={4}>
        <mui.core.TextField
          select
          label="Benchmark Weighting"
          variant="outlined"
          size="small"
          value={value.weighting}
          onChange={(e) => onChange({ ...value, weighting: e.target.value })}
          error={isError}
          fullWidth
          required
        >
          <mui.core.MenuItem value="MARKETCAP">Market Capitalization</mui.core.MenuItem>
          <mui.core.MenuItem value="EQUAL">Equal</mui.core.MenuItem>
        </mui.core.TextField>
      </mui.core.Grid>
    </>
  );
};

export default UniverseSelector;
