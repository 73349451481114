import { _, ts } from '_core';

import { DataSourceBrowseItem } from '../types';

const getPartitionIndex = (pIndex: Partial<ts.types.dataSource.Partition>, dsId: number) => {
  if (pIndex.column == 'etf_fsym_id')
    return {
      name: pIndex['column'],
      type: ts.enums.BROWSE_ITEM_TYPE_ENUM.ETF,
      label: pIndex['column'],
      parent: 'partitions',
      ui_processing: true,
      required: true,
    };

  if (_.isEmpty(pIndex.values))
    return {
      name: pIndex['column'],
      type: ts.enums.BROWSE_ITEM_TYPE_ENUM.REMOTE_SELECT,
      label: _.startCase(pIndex['column']),
      remote_values_table: 'data_source_column_metadatas',
      remote_values_query: ['$and', ['column', 'like', pIndex['column']], ['data_source_id', '=', dsId]],
      parent: 'partitions',
      ui_processing: true,
      required: true,
    };

  return {
    name: pIndex['column'],
    type: ts.enums.BROWSE_ITEM_TYPE_ENUM.SELECT,
    label: _.startCase(pIndex['column']),
    values: pIndex['values'],
    parent: 'partitions',
    ui_processing: true,
    required: true,
  };
};

export const getBrowseItems = (ds: ts.types.dataSource.DataSourceBrowserContext) => {
  const def = ds.definition;
  let browseItems = [] as DataSourceBrowseItem[];
  const columns = _.get(def, 'columns', []);

  const hasDateColumn = !!columns.find((c) => c.dtype == 'DATA_DATE');

  // Partitions
  const partitionIndex = def.partition_index || [];
  partitionIndex.forEach((pIndex) => {
    browseItems.push(getPartitionIndex(pIndex, ds.id));
  });

  // Other dynamic columns
  columns.forEach((c) => {
    if (c.in_column_metadata && !_.map(partitionIndex, 'column').includes(c.name))
      browseItems.push({
        name: c.column_name,
        type: ts.enums.BROWSE_ITEM_TYPE_ENUM.REMOTE_SELECT,
        label: _.startCase(c.column_name),
        multiple: true,
        remote_values_table: 'data_source_column_metadatas',
        remote_values_query: ['$and', ['column', 'like', c.column_name], ['data_source_id', '=', ds.id]],
        parent: 'filters',
        ui_processing: false,
        required: false,
      });
  });

  // Assets field
  if (ds['has_assets'])
    browseItems.push({
      name: 'assets',
      type: ts.enums.BROWSE_ITEM_TYPE_ENUM.ASSET,
      label: 'Assets',
      required: true,
      parent: 'filters',
      ui_processing: false,
    });

  if (hasDateColumn)
    if (def.date_partition_frequency == 'DAILY') {
      browseItems = browseItems.concat([
        {
          name: 'date',
          type: ts.enums.BROWSE_ITEM_TYPE_ENUM.DATE,
          label: 'Date',
          required: true,
          parent: 'root',
          // Root is always ui processing
          ui_processing: true,
        },
      ]);
    } else {
      browseItems = browseItems.concat([
        {
          name: 'start_and_end_dates',
          type: ts.enums.BROWSE_ITEM_TYPE_ENUM.DATE_RANGE,
          // Filter added on index.
        },
      ]);
    }

  return browseItems;
};
