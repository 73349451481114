import { ts } from '_core';

import { INTERACTION_PARAMS } from './types';

export default [
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.UNIVERSE_PREVIEW_LINKED_ASSETS,
    form: false,
    title: 'Linked Assets',
    neededFiles: ['universe_linked_assets'],
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.UNIVERSE_PREVIEW],
    interactionParams: Object.values(INTERACTION_PARAMS),

    disableAdd: true,
    helpLabel: 'zmaptunb',
  },
] satisfies ts.types.analysis.WidgetConfig[];
