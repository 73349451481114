import React from 'react';

import SignalReturnHorizon from './signal-return-horizon';
import SingleReturnHorizon from './single-return-horizon';
import * as enums from '../../enums';
import { _, mui } from '../../libs';
import * as types from '../../types';

type ReturnHorizonValueType<TMultiple> = TMultiple extends false | undefined
  ? types.returnHorizon.ReturnHorizon
  : types.returnHorizon.ReturnHorizon[];

interface IWidgetReturnHorizonProps<TMultiple> {
  value: ReturnHorizonValueType<TMultiple>;
  setValue: (_value: ReturnHorizonValueType<TMultiple>) => void;

  setDailyReporting?: (_value: boolean) => void;
  dailyReporting?: boolean;
  multiple?: boolean;
  inferredWildcard?: string;
  enableDailyReporting?: boolean;
  noSignal?: boolean;
  context?: Record<string, any>;
}

/**
 * Component that allows users to configure return horizon settings for single or multiple periods
 * 
 * @requires Redux Store:
 * - state.resources: Resources data for signals and pipelines
 * 
 * @param value - Current return horizon value(s)
 * @param setValue - Callback to update return horizon value(s)
 * @param setDailyReporting - Optional callback to update daily reporting setting
 * @param dailyReporting - Optional flag for daily reporting mode
 * @param multiple - Optional flag to allow multiple return horizons
 * @param inferredWildcard - Optional wildcard string for frequency inference
 * @param enableDailyReporting - Optional flag to show daily reporting toggle
 * @param noSignal - Optional flag to disable signal return horizons
 * @param context - Optional context object for additional data
 * 
 * @returns Rendered component for configuring return horizons
 */
const WidgetReturnHorizon = <TMultiple extends boolean | undefined = undefined>({
  value,
  setValue,
  noSignal,
  context,
  
  multiple = false,
  inferredWildcard = '$frequency',
  enableDailyReporting = false,
  setDailyReporting = () => undefined as void,
  dailyReporting = false,
}: IWidgetReturnHorizonProps<TMultiple>): React.ReactElement => {
  React.useEffect(() => {
    const defaultrh: types.returnHorizon.ReturnHorizon = {
      signature: '1 Period Return',
      periods: {
        amount: 1,
        frequency: inferredWildcard as enums.FREQUENCY_ENUM,
      },
    };
    if (_.isEmpty(value) && multiple) setValue([defaultrh] as ReturnHorizonValueType<TMultiple>);
    if (_.isEmpty(value) && !multiple) setValue(defaultrh as ReturnHorizonValueType<TMultiple>);
  }, []);

  const renderSingle = () => {
    if (_.isEmpty(value)) return <></>; // This is until use effect defined above takes effect
    const rh = value as types.returnHorizon.ReturnHorizon;
    return (
      <mui.core.Box mt={4}>
        {rh.is_signal ? (
          <SignalReturnHorizon
            rh={rh}
            setRh={(v) => {
              setValue(v as ReturnHorizonValueType<TMultiple>);
            }}
          />
        ) : (
          <SingleReturnHorizon
            rh={rh}
            setRh={(v) => {
              setValue(v as ReturnHorizonValueType<TMultiple>);
            }}
            dailyReporting={dailyReporting}
            inferredWildcard={inferredWildcard}
            context={context}
          />
        )}
      </mui.core.Box>
    );
  };

  const renderMultiple = () => {
    const localValue = (value as types.returnHorizon.ReturnHorizon[]) || [];
    return localValue.map((rh, idx) => (
      <mui.core.Box mt={4} key={idx}>
        {rh.is_signal ? (
          <SignalReturnHorizon
            rh={rh}
            setRh={(v) => {
              setValue(localValue.map((r, lidx) => (idx === lidx ? v : r)) as ReturnHorizonValueType<TMultiple>);
            }}
            remove={() => {
              setValue(localValue.filter((r, lidx) => idx !== lidx) as ReturnHorizonValueType<TMultiple>);
            }}
          />
        ) : (
          <SingleReturnHorizon
            rh={rh}
            setRh={(v) => {
              setValue(localValue.map((r, lidx) => (idx === lidx ? v : r)) as ReturnHorizonValueType<TMultiple>);
            }}
            dailyReporting={dailyReporting}
            inferredWildcard={inferredWildcard}
            remove={() => {
              setValue(localValue.filter((r, lidx) => idx !== lidx) as ReturnHorizonValueType<TMultiple>);
            }}
            context={context}
          />
        )}
        <mui.core.Box my={4}>
          <mui.core.Divider />
        </mui.core.Box>
      </mui.core.Box>
    ));
  };

  const addReturnHorizon = () => {
    const localValue = (value as types.returnHorizon.ReturnHorizon[]) || [];
    if (dailyReporting)
      setValue([
        ...localValue,
        {
          periods: {
            amount: 1,
            frequency: 'DAILY' as enums.FREQUENCY_ENUM,
          },
          signature: '1 Day Return',
        },
      ] as ReturnHorizonValueType<TMultiple>);
    else
      setValue([
        ...localValue,
        {
          periods: {
            amount: 1,
            frequency: inferredWildcard as enums.FREQUENCY_ENUM,
          },
          signature: '1 Period Return',
        },
      ] as ReturnHorizonValueType<TMultiple>);
  };

  const addSignalHorizon = () => {
    const localValue = (value as types.returnHorizon.ReturnHorizon[]) || [];
    setValue([
      ...localValue,
      {
        periods: {
          amount: 1,
          frequency: inferredWildcard as enums.FREQUENCY_ENUM,
        },
        signal_id: null,
        signature: 'Unknown Signal Return',
        is_signal: true,
      },
    ] as ReturnHorizonValueType<TMultiple>);
  };

  return (
    <>
      {enableDailyReporting && (
        <mui.core.FormControlLabel
          control={
            <mui.core.Switch
              size="small"
              checked={dailyReporting}
              onChange={() => {
                const newDailyReporting = !dailyReporting;
                setDailyReporting(newDailyReporting);
              }}
            />
          }
          label="Daily Reporting"
        />
      )}
      <mui.core.Box mt={2} mb={3}>
        <mui.core.Typography variant="body2" fontWeight="fontWeightMedium">
          {multiple ? 'Return Horizons' : 'Return Horizon'}
        </mui.core.Typography>
      </mui.core.Box>

      <mui.core.Box key={multiple ? ((value as types.returnHorizon.ReturnHorizon[]) || []).length : 'fixed'}>
        {multiple ? renderMultiple() : renderSingle()}
      </mui.core.Box>

      {!multiple && !noSignal && (
        <mui.core.Box mt={2} mb={3}>
          <mui.core.FormControlLabel
            control={
              <mui.core.Switch
                size="small"
                checked={(value as types.returnHorizon.ReturnHorizon)?.is_signal}
                onChange={() => {
                  const currentValue = value as types.returnHorizon.ReturnHorizon;
                  const newValue = { ...currentValue, is_signal: !currentValue.is_signal };
                  setValue(newValue as ReturnHorizonValueType<TMultiple>);
                }}
              />
            }
            label="Use Return Signal"
          />
        </mui.core.Box>
      )}

      {multiple && (
        <mui.core.Grid container>
          <mui.core.Grid item>
            <mui.core.Button
              color="primary"
              variant="outlined"
              aria-controls="constraint-menu"
              aria-haspopup="true"
              onClick={addReturnHorizon}
            >
              <mui.icons.Add style={{ fontSize: '15.3px', marginRight: '0.5rem' }} />
              Add Horizon
            </mui.core.Button>
          </mui.core.Grid>
          {!noSignal && (
            <mui.core.Grid item pl={2}>
              <mui.core.Button
                color="primary"
                variant="outlined"
                aria-controls="constraint-menu"
                aria-haspopup="true"
                onClick={addSignalHorizon}
              >
                <mui.icons.Add style={{ fontSize: '15.3px', marginRight: '0.5rem' }} />
                Add Signal
              </mui.core.Button>
            </mui.core.Grid>
          )}
        </mui.core.Grid>
      )}
    </>
  );
};

export default WidgetReturnHorizon;
