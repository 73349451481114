import { _, actions, config, helpers, hooks, mui, React, ts, ui, useDispatch, useHistory, useSelector } from '_core';

import { alphaParentTypes, innerSubtypes } from '../alpha-defaults/alpha-subtypes';
import * as utils from '../signal-utils';

type AlphaFormProps = {
  close: () => void;
  setErrorMessage: (_message: string) => void;
};

const AlphaForm: React.FC<AlphaFormProps> = ({ close, setErrorMessage }) => {
  const signals = useSelector((state) => state.resources.signals as ts.types.signal.Signal[]);
  const workspaces = useSelector((state) => state.resources.workspaces as ts.types.workspace.Workspace[]);
  const currentWorkspaceId = useSelector((state) => state.ui.currentWorkspaceId);

  const dispatch = useDispatch();
  const updateSignalDatasets = (ds: ts.types.signal.Dataset) => dispatch(actions.resources.updateSignalDataset(ds));

  const history = useHistory();

  const [name, setName] = React.useState('');
  const [handle, setHandle] = React.useState('');
  const [template, setTemplate] = React.useState<ts.types.signal.Signal>();
  const [loadingCreate, setLoadingCreate] = React.useState(false);
  const [workspace, setWorkspace] = React.useState(
    () => workspaces.find((w) => w.id == currentWorkspaceId) || workspaces[0]
  );

  // To copy signal datasets
  const [loadingDatasets, setLoadingDatasets] = React.useState(false);
  const [signalDatasets, setSignalDatasets] = React.useState([] as ts.types.signal.Dataset[]);
  const [createDatasets, setCreateDatasets] = React.useState(true);

  const [modelType, setModelType] = React.useState<ts.types.signal.AlphaDefinition['alpha_type']>(null);
  const [parentType, setParentType] = React.useState<ts.enums.ALPHA_MODEL_PARENT_ENUM>(
    ts.enums.ALPHA_MODEL_PARENT_ENUM.CUSTOM
  );

  // Load datasets
  React.useEffect(() => {
    if (template?.id) {
      setLoadingDatasets(true);
      utils.apiProcedures.loadArtifacts(
        template,
        setLoadingDatasets,
        (dsts) => setSignalDatasets(dsts),
        () => setErrorMessage('Error loading datasets'),
        false
      );
    } else {
      setSignalDatasets([] as ts.types.signal.Dataset[]);
    }
  }, [template]);

  // Create handle while typing name
  React.useEffect(() => {
    setHandle(helpers.createHandle(name, 'a'));
  }, [name]);

  const filterInnerSubtypes = (parentType: ts.enums.ALPHA_MODEL_PARENT_ENUM) =>
    innerSubtypes
      .filter((item) => item.parentType === parentType)
      .filter((item) => !config.features.hide_alpha_types.includes(item.type));

  hooks.useEffectWithoutFirst(() => {
    const filteredInnerSubtypes = filterInnerSubtypes(parentType);

    if (filteredInnerSubtypes.length > 1) {
      const possibleSubtypes = filteredInnerSubtypes.filter((el) => !el.disabled);
      setModelType(possibleSubtypes[0].type as ts.types.signal.AlphaDefinition['alpha_type']);
    }
  }, [parentType]);

  const subtypeSelector = (parentType: ts.enums.ALPHA_MODEL_PARENT_ENUM) => {
    const filtered = filterInnerSubtypes(parentType);

    if (filtered.length > 1) {
      return (
        <mui.core.Box mb={4}>
          <mui.core.TextField
            select
            required
            label="Subtype"
            variant="outlined"
            value={modelType}
            InputLabelProps={{ shrink: true }}
            onChange={(e) => {
              setModelType(e.target.value as typeof modelType);
            }}
            fullWidth
            size="small"
          >
            {filtered.map((el) => (
              <mui.core.MenuItem key={el.type} value={el.type} disabled={el.disabled}>
                {el.name}
              </mui.core.MenuItem>
            ))}
          </mui.core.TextField>
        </mui.core.Box>
      );
    }
    return;
  };

  const handleSubmit = async () => {
    setErrorMessage('');

    // Validation
    if (!name || !handle) {
      setErrorMessage('All attributes are required.');
      setLoadingCreate(false);
      return;
    }

    setLoadingCreate(true);

    const newSignal = utils.definition.getNewAlphaModel(name, handle, modelType, parentType, template);
    utils.apiProcedures.createSignal(
      newSignal,
      async (data, datasets) => {
        if (createDatasets && !_.isEmpty(datasets)) {
          await Promise.all(datasets.map(async (ds) => await updateSignalDatasets(ds.data)));
        }

        if (workspace)
          await helpers.resourcesUtils.addToWorkspace(data.id, ts.enums.UI_RESOURCE_TYPE_ENUM.SIGNAL, workspace);
        close();
        history.push(`/signals/${data.id}`);
      },
      async (err) => {
        setErrorMessage(err);
        setLoadingCreate(false);
      },
      createDatasets && !_.isEmpty(signalDatasets) ? signalDatasets : undefined
    );
  };

  const formContent = () => (
    <mui.core.Box mt={3}>
      <mui.core.Grid container spacing={3}>
        <mui.core.Grid item xs={12} sm={6}>
          <mui.core.TextField
            autoFocus
            id="name"
            label={'Alpha Model name'}
            value={name}
            onChange={(e) => setName(e.target.value)}
            fullWidth
            variant="outlined"
            required
            size="small"
            inputProps={{
              autocomplete: 'off',
            }}
          />
        </mui.core.Grid>
        <mui.core.Grid item xs={12} sm={6}>
          <mui.core.TextField
            id="handle"
            label={'Alpha Model handle'}
            value={handle}
            onChange={(e) => setHandle(e.target.value)}
            fullWidth
            size="small"
            variant="outlined"
            required
          />
        </mui.core.Grid>
      </mui.core.Grid>

      <mui.core.Box py={4} display="flex">
        <mui.core.Box flexGrow={1}>
          <ui.ResourceAutocomplete<ts.types.signal.SignalExpanded>
            enableNone
            value={template?.id}
            setValue={(id) => setTemplate(signals.find((u) => u.id == id))}
            filter={(s) => !s.source_resource_id}
            type="alpha_model"
            inputProps={{
              fullWidth: true,
              label: 'Template',
              variant: 'outlined',
              required: true,
              size: 'small',
            }}
          />
        </mui.core.Box>

        {template?.id && (
          <mui.core.Box ml={2}>
            <mui.core.FormControlLabel
              control={
                <mui.core.Switch
                  size="small"
                  checked={createDatasets}
                  onChange={() => setCreateDatasets((cd) => !cd)}
                  disabled={loadingDatasets || _.isEmpty(signalDatasets)}
                />
              }
              label={
                loadingDatasets
                  ? 'Loading datasets'
                  : _.isEmpty(signalDatasets)
                    ? 'No datasets found for template'
                    : `Create ${signalDatasets.length} found signal parameters.`
              }
            />
          </mui.core.Box>
        )}
      </mui.core.Box>

      {!template?.id && (
        <>
          <mui.core.Box pb={4}>
            <mui.core.TextField
              select
              label="Alpha Model Type"
              variant="outlined"
              value={parentType}
              onChange={(e) => {
                setParentType(e.target.value as ts.enums.ALPHA_MODEL_PARENT_ENUM);
                setModelType(null);
              }}
              fullWidth
              size="small"
            >
              {alphaParentTypes
                .filter((el) => !config.features.hide_alpha_types.includes(el.type))
                .map((el) => (
                  <mui.core.MenuItem key={el.type} value={el.type} disabled={el.disabled}>
                    {el.name}
                  </mui.core.MenuItem>
                ))}
            </mui.core.TextField>
          </mui.core.Box>
          {subtypeSelector(parentType)}
        </>
      )}

      <ui.WorkspaceSelector value={workspace} setValue={setWorkspace} />
    </mui.core.Box>
  );

  return (
    <>
      <ui.DialogTitle closeAction={close}>Create Alpha Model</ui.DialogTitle>

      <mui.core.DialogContent>{formContent()}</mui.core.DialogContent>

      <mui.core.DialogActions>
        <ui.Button
          variant="contained"
          color="primary"
          loading={loadingCreate}
          onClick={handleSubmit}
          style={{ margin: '0rem 1rem 1rem' }}
          fullWidth
          disabled={loadingDatasets}
        >
          {'Create Alpha Model'}
        </ui.Button>
      </mui.core.DialogActions>
    </>
  );
};

export default AlphaForm;
