import { _, React, ts } from '_core';

import Chart from './chart';
import { DraftParams, Params } from './types';

type BacktestNameCountProps = {
  id: string;
  readOnly: boolean;
  widgetKey: string;
  mode: ts.enums.CHART_MODE_ENUM;
  params: Params;
  setParams: (_p: DraftParams) => void;
  title: string;

  fullScreen?: boolean;
  context?: ts.types.backtest.BacktestAnaylsisContext | ts.types.basket.BasketAnalysisContext;
  loadWidgetData: (_payload: ts.types.widgets.WidgetGetDataParams) => Promise<ts.types.widgets.WidgetGetDataResponse>;
  widgetPaths?: Record<'backtest_name_count', string>;
};

const BacktestNameCount: React.FC<BacktestNameCountProps> = ({
  id,
  readOnly,
  widgetKey,
  loadWidgetData,
  widgetPaths,
  params,
  fullScreen,
  context,
  title,
}): React.ReactElement => {
  const hasBenchmark = React.useMemo(
    () => (params.isBasket ? context?.definition?.optimizer_rebalance : !_.isEmpty(context?.definition?.benchmark)),
    []
  );

  return (
    <Chart
      id={id}
      readOnly={readOnly}
      widgetKey={widgetKey}
      fullScreen={fullScreen}
      loadWidgetData={loadWidgetData}
      portfolios={widgetPaths['backtest_name_count']}
      scale={ts.enums.SCALE_ENUM.LINEAR}
      isBasket={!!(context as ts.types.basket.BasketAnalysisContext).basket_id}
      isCombinedBasket={(context as ts.types.basket.BasketAnalysisContext).type == ts.enums.BASKET_TYPE_ENUM.COMBINED}
      isLongOnly={
        (context as ts.types.backtest.BacktestAnaylsisContext).ui_metadata?.longOption ==
        ts.enums.LONG_OPTION_ENUM.LONG_ONLY
      }
      hasBenchmark={hasBenchmark}
      title={title}
    />
  );
};

export default BacktestNameCount;
