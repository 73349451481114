import React from 'react';

/**
 * Hook that tracks user activity/inactivity based on browser events
 * 
 * @param timeout - Time in milliseconds before user is considered inactive (default: 5000ms)
 * @returns Current activity status ('active' | 'inactive')
 * 
 * @example
 * ```tsx
 * const activityStatus = useInactivityCheck(10000); // 10 second timeout
 * 
 * if (activityStatus === 'inactive') {
 *   console.log('User has been inactive');
 * }
 * ```
 */
const useInactivityCheck = (timeout = 5_000) => {
  const [activityStatus, setActivityStatus] = React.useState<'active' | 'inactive'>('active');
  const timerRef = React.useRef<NodeJS.Timeout | null>(null);

  const resetTimer = () => {
    // If there's user activity, reset the inactivity timer and set status to active
    if (timerRef.current) clearTimeout(timerRef.current);
    setActivityStatus('active');

    // Set a new timeout to mark inactivity after the specified timeout period
    timerRef.current = setTimeout(() => {
      setActivityStatus('inactive');
    }, timeout);
  };

  React.useEffect(() => {
    // Event listeners to detect user activity
    const events = ['mousemove', 'click', 'keypress', 'scroll', 'touchstart'];

    events.forEach((event) => window.addEventListener(event, resetTimer));

    // Start the timer on mount
    resetTimer();

    return () => {
      // Cleanup on unmount
      if (timerRef.current) clearTimeout(timerRef.current);
      events.forEach((event) => window.removeEventListener(event, resetTimer));
    };
  }, [timeout]);

  return activityStatus;
};

export default useInactivityCheck;
