import React from 'react';
import { SxProps } from '@mui/system';

import { mui } from '../libs';

type UiCardProps = {
  children: React.ReactNode;
  title?: string;
  fullWidth?: boolean;
  variant?: 'elevation' | 'outlined';
  onClick?: () => void;
  customTitleAction?: React.ReactElement;
  withAction?: boolean;
  withActionOnClick?: () => void;
  withActionDisableValidation?: () => boolean;
  color?: string;
  size?: 'default' | 'small';
  sx?: SxProps; // Allow sx prop
  noPadding?: boolean;
};

type RenderChildrenActionAreaProps = {
  children: React.ReactElement;
  withAction?: boolean;
  withActionOnClick?: () => void;
  withActionDisableValidation?: () => boolean;
};

const RenderChildrenActionArea: React.FC<RenderChildrenActionAreaProps> = ({
  children,
  withAction,
  withActionOnClick,
  withActionDisableValidation,
}): React.ReactElement => {
  if (withAction)
    return (
      <mui.core.CardActionArea onClick={withActionOnClick} disabled={withActionDisableValidation?.()}>
        {children}
      </mui.core.CardActionArea>
    );

  return children;
};

const UiCard: React.FC<UiCardProps> = ({
  children,
  title,
  fullWidth,
  variant,
  onClick,
  customTitleAction,
  withAction,
  withActionOnClick,
  withActionDisableValidation,
  color,
  size,
  sx, // Accept sx as a prop
  noPadding,
}): React.ReactElement => {
  const theme = mui.styles.useTheme() as mui.core.Theme;
  const isDisabled = withAction && withActionDisableValidation?.();

  return (
    <mui.core.Card
      variant={variant}
      onClick={onClick}
      sx={{
        mb: size === 'small' ? 0 : 3,
        background: isDisabled ? '#F4F7FB' : 'inherit',
        ...(noPadding && { '& .MuiCardContent-root': { padding: '0!important' } }),
        ...sx, // Merge external sx prop
      }}
    >
      {fullWidth ? (
        <RenderChildrenActionArea
          withAction={withAction}
          withActionOnClick={withActionOnClick}
          withActionDisableValidation={withActionDisableValidation}
        >
          <>
            <mui.core.Box display="flex" justifyContent="space-between">
              {title && (
                <mui.core.CardContent>
                  {color ? (
                    <mui.core.Box
                      mt={1}
                      sx={{
                        position: 'relative',
                        padding: '6px 12px',
                      }}
                    >
                      <mui.core.Typography fontWeight="fontWeightMedium" color={color}>
                        {title}
                      </mui.core.Typography>
                      <mui.core.Box
                        sx={{
                          backgroundColor: color,
                          position: 'absolute',
                          width: '100%',
                          height: '100%',
                          left: 0,
                          top: 0,
                          opacity: 0.1,
                          padding: '4px',
                          borderRadius: '4px',
                        }}
                      />
                    </mui.core.Box>
                  ) : (
                    <mui.core.Typography fontWeight="fontWeightMedium">{title}</mui.core.Typography>
                  )}
                </mui.core.CardContent>
              )}
              {customTitleAction && customTitleAction}
            </mui.core.Box>
            {children}
          </>
        </RenderChildrenActionArea>
      ) : (
        <RenderChildrenActionArea
          withAction={withAction}
          withActionOnClick={withActionOnClick}
          withActionDisableValidation={withActionDisableValidation}
        >
          <mui.core.CardContent
            sx={{
              paddingTop: title ? theme.spacing(3) : theme.spacing(4),
              ...(size === 'small' && { padding: '0.5rem 1rem' }),
            }}
          >
            <mui.core.Box display="flex" justifyContent="space-between">
              {title && (
                <mui.core.Typography
                  fontWeight="fontWeightMedium"
                  mb={size == 'small' ? 1 : 2}
                  fontSize={size == 'small' ? '12px' : '15px'}
                >
                  {title}
                </mui.core.Typography>
              )}

              {customTitleAction && customTitleAction}
            </mui.core.Box>
            {children}
          </mui.core.CardContent>
        </RenderChildrenActionArea>
      )}
    </mui.core.Card>
  );
};

export default UiCard;
