import { _, helpers, ts } from '_core';

import { DraftParams, Params } from './types';
import { getBenchmarkTitle } from '../../common/utils/get-name';

export const formatSeriesName = (key: string, baskets: ts.types.basket.Basket[]) => {
  const currentBasket = baskets.find((b) => b.handle == key);
  if (currentBasket) return currentBasket.name;

  return key;
};

export const setUserDefinedOrderBar = (
  data: ts.types.widgets.NestedBarData,
  basketId: number,
  basketIds: number[],
  baskets: ts.types.basket.Basket[]
) => {
  if (basketIds.includes(basketId)) {
    return data.map((group) => {
      const groupData = {} as ts.types.widgets.NestedBarData[0];
      groupData['category'] = group['category'];
      basketIds.map((bid) => {
        const currentBasket = baskets.find((basket) => basket.id === bid)?.name;
        if (currentBasket) groupData[currentBasket] = group[currentBasket];
      });
      return groupData;
    });
  }
  return data;
};

export const prepareAnnuStatsData = (
  fullData: ts.types.widgets.NestedBarWidgetData,
  params: Pick<Params, 'basket_ids'>,
  context: ts.types.basket.BasketAnalysisContext,
  baskets: ts.types.basket.Basket[]
) => {
  const localData = fullData.data;
  let newData = localData.map((group) => {
    const groupData = {} as ts.types.widgets.NestedBarData[0];
    Object.keys(group).forEach((innerKey) => {
      if (innerKey != 'category') {
        const formattedInnerKey = formatSeriesName(innerKey.split('-')[0], baskets);
        groupData[formattedInnerKey] = group[innerKey];
      }
    });
    groupData['category'] = group['category'];
    return groupData;
  });

  newData = setUserDefinedOrderBar(newData, context.basket_id, params.basket_ids, baskets);
  return {
    ...fullData,
    data: newData,
  } as ts.types.widgets.NestedBarWidgetData;
};

export const validate = (
  params: DraftParams,
  context: ts.types.backtestSet.BacktestSetAnalysisContext,
  _g: any,
  resources: ts.StoreState['resources']
) => {
  const basketCompareErrors = helpers.validations.validBasketCompare(params);
  if (!_.isEmpty(basketCompareErrors)) return basketCompareErrors;
  if (_.isEmpty(params.histories)) return ['Histories is a required field.'];
  if (params.with_benchmark && !helpers.validations.validBenchmark(params.benchmark)) return ['Benchmark is invalid.'];

  if (params.with_benchmark) {
    const baskets = resources.baskets.filter((b) => params.basket_ids.includes(b.id));

    const basketFrequency = baskets[0]?.definition?.frequency;
    const allSameFrequency = baskets.every((basket) => basket.definition.frequency === basketFrequency);

    if (!allSameFrequency) {
      return ['Selected baskets must have the same frequency.'];
    }
  }
  return [];
};

export const getName = (
  params: DraftParams,
  _context: ts.types.signal.SignalAnalysisContext,
  resources: ts.StoreState['resources']
) => {
  let widgetTitle = 'Annualized Returns';

  if (params.statistics_type === 'risk') widgetTitle = 'Annualized Risk';
  else if (params.statistics_type === 'adjusted') widgetTitle = 'Annualized Risk Adjusted Returns';

  if (params.benchmark) return getBenchmarkTitle(params.benchmark, widgetTitle, resources.universes);
  return widgetTitle;
};
