import { _, helpers, ts } from '_core';

import { DraftParams } from './types';

export const validate = (params: DraftParams, context: ts.types.basket.BasketAnalysisContext) => {
  if (_.isEmpty(params?.buckets) && _.isEmpty(params?.variables))
    return ['At least one bucket or variable is required.'];
  if (!_.isEmpty(params?.buckets) 
    && !helpers.validations.validBuckets(params?.buckets)) return ['Buckets are invalid.'];

  if (
    params?.variables?.type === ts.enums.EXPLANATORY_VARIABLE_TYPES_ENUM.RISK_FACTORS &&
    !context?.definition?.risk_model_id
  )
    return ['Risk Model is required when variables are of type RISK_FACTORS.'];

  return [];
};
