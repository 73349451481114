import { ts } from '_core';

import { getName } from './helpers';
import { DraftParams } from './types';
import { INTERACTION_PARAMS } from '../signal-drawdown/types';

export default [
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.BASKET_TURNOVER_TIME,
    title: 'Turnover',
    helpLabel: '33wdcskk',
    form: true,
    defaultParams: {
      isBasket: true,
      selectedType: 'show_total',
    } as DraftParams,
    interactionParams: Object.values(INTERACTION_PARAMS),
    neededFiles: ['backtest_turnover_analysis'],
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.BASKET],
  },
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.BACKTEST_TURNOVER_TIME,
    title: 'Turnover',
    helpLabel: 'gos53v04',
    form: true,
    defaultParams: {
      isBasket: false,
      selectedType: 'show_total',
    } as DraftParams,
    interactionParams: Object.values(INTERACTION_PARAMS),
    neededFiles: ['backtest_turnover_analysis'],
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.BACKTEST],

    getName,
  },
] satisfies ts.types.analysis.WidgetConfig[];
