import { _, helpers, hooks, mui, React, ts, useSelector } from '_core';

import Tabular from 'views/report/tables/tabular';

import { exposureColumns } from './columns-config';
import { getBucketKey, transform } from './helpers';
import { Params } from './types';
import { getColumns } from '../../common/charts/utils';

type ChartProps = {
  id: string;
  readOnly: boolean;
  widgetKey: string;
  loadWidgetData: (_payload: ts.types.widgets.WidgetGetDataParams) => Promise<ts.types.widgets.WidgetGetDataResponse>;
  params: Params;
  setParams: (_p: Params) => void;
  fullScreen: boolean;
  goFullScreen: (_v: string) => void;
  exposure: string;
  queryParams?: { initial?: string };
  context?: ts.types.portfolio.PortfolioAnalysisContext;
};

const Chart: React.FC<ChartProps> = ({
  id,
  widgetKey,
  readOnly,
  loadWidgetData,
  params,
  exposure,
  goFullScreen,
  setParams,
  fullScreen,
}) => {
  const signals = useSelector((state: ts.StoreState) => state.resources.signals);
  const [error, setError] = React.useState<ts.types.common.ApiError>(null);
  const [data, setData] = React.useState<Record<string, ts.types.widgets.TableData>>();

  const [containerHeight, setContainerHeight] = React.useState(undefined);
  const { height } = hooks.useWindowDimensions();

  const container = React.createRef<HTMLDivElement>();

  React.useEffect(() => {
    if (container.current) {
      setContainerHeight(container.current.offsetHeight - 40);
    }
  }, [height]);

  const [expandedGroupIds, setExpandedGroupIds] = React.useState<Record<string, ReadonlySet<unknown>>>(
    params.expanded
      ? Object.fromEntries(Object.entries(params.expanded).map(([key, val]) => [key, new Set(val)]))
      : null
  );

  const [tableParams, setTableParams] = React.useState<ts.types.components.dataGrid.TableParamsSheet>(
    params.table_params
  );

  const debouncedParamsSave = React.useCallback(
    _.debounce(async (exp: typeof expandedGroupIds, tbP: ts.types.components.dataGrid.TableParamsSheet) => {
      setParams({
        ...params,
        expanded: Object.fromEntries(Object.entries(exp).map(([key, val]) => [key, [...val] as string[]])),
        table_params: { ...tbP },
      });
    }, 1_000),
    []
  );

  hooks.useEffectWithoutFirst(() => {
    debouncedParamsSave(expandedGroupIds, tableParams);
  }, [expandedGroupIds, tableParams]);

  const loadData = async () => {
    try {
      const resp = await loadWidgetData({
        data: { file: exposure },
      });
      if (resp) {
        const groupedData = _.groupBy(resp.data, (item) => item.bucket.toString().split(':')[0]);

        Object.keys(groupedData).forEach((key) => {
          groupedData[key] = transform(groupedData[key], 'bucket');
        });

        setData(groupedData);

        if (_.isEmpty(expandedGroupIds) && groupedData['STYLE_FACTORS_QUANTILES']) {
          const exposureLabels = new Set(groupedData['STYLE_FACTORS_QUANTILES'].map((x) => x.bucket));
          setExpandedGroupIds({ STYLE_FACTORS_QUANTILES: exposureLabels });
        }
      }
    } catch (err) {
      setError(err as ts.types.common.ApiError);
    }
  };

  React.useEffect(() => {
    if (exposure) loadData();
    return () => setData(null);
  }, [exposure]);

  return (
    <mui.core.Box
      id={id}
      key={widgetKey}
      sx={{
        height: 'calc(100% - 1px)',
        '& .rdg-light': { paddingBottom: '60px' },
      }}
      ref={container}
    >
      <Tabular
        data={data}
        apiError={error}
        readOnly={readOnly}
        getColumns={Object.fromEntries(
          Object.keys(data || {}).map((key) => [
            key,
            (dataKeys: string[], _pref: any) =>
              key === 'STYLE_FACTORS_QUANTILES'
                ? getColumns(dataKeys, null, [
                    {
                      key: 'bucket',
                      name: '',
                      formatter: helpers.tableFormatters.formatTo('string', { align: 'left' }),
                      filter: 'string',
                    },
                    ...exposureColumns,
                  ])
                : getColumns(dataKeys, null, exposureColumns),
          ])
        )}
        sheetTitles={Object.fromEntries(
          Object.entries(data || {}).map(([key, _]) => [key, getBucketKey(params, key, signals)])
        )}
        fullScreen={fullScreen}
        goFullScreen={goFullScreen}
        hideFilters
        alwaysShowBottom
        groupBy={{
          STYLE_FACTORS_QUANTILES: ['bucket'],
        }}
        expandedGroupIds={expandedGroupIds}
        setExpandedGroupIds={{
          STYLE_FACTORS_QUANTILES: (v) =>
            setExpandedGroupIds((exp) => ({
              ...exp,
              STYLE_FACTORS_QUANTILES: v,
            })),
        }}
        tableParams={tableParams}
        setTableParams={Object.fromEntries(
          Object.keys(data || {}).map((key) => [
            key,
            (v: ts.types.components.dataGrid.TableParams) =>
              setTableParams((t) => ({
                ...t,
                [key]: v,
              })),
          ])
        )}
        overrideHeight={containerHeight}
        triggerWidthChange={params.fullWidth}
      />
    </mui.core.Box>
  );
};

export default Chart;
