import { createTheme, Theme } from '@mui/material/styles';

import { TAB_HEIGHT } from './layout-size';

// eslint-disable-next-line max-len

const customShadows: Record<number, string> = {
  1: '0 1px 2px rgba(0,0,0,0.03)', // Card Shadow
  3: '0 8px 16px rgba(0, 0, 0, 0.15)', // Dropdown shadow
  24: '0 10px 30px rgba(0, 0, 0, 0.2)', // Modal shadow
};

// Create a default theme to get the default shadows
const theme: Theme = createTheme();

// Merge custom shadows with defaults, explicitly typing `index` as `number`
const mergedShadows = theme.shadows.map(
  // Apply custom shadow only for specified indexes
  (shadow: string, index: number): string => customShadows[index] || shadow
);

const baseConfigTheme = {
  typography: {
    fontFamily:
      // eslint-disable-next-line max-len
      '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
    fontSize: 13,
    htmlFontSize: 17,
    h1: {
      fontSize: '3rem',
      fontWeight: 500,
      lineHeight: 1.2,
    },
    h2: {
      fontSize: '2.5rem',
      fontWeight: 500,
      lineHeight: 1.2,
    },
    h3: {
      fontSize: '2rem',
      fontWeight: 500,
      lineHeight: 1.2,
    },
    h4: {
      fontSize: '1.5rem',
      fontWeight: 500,
      lineHeight: 1.2,
    },
    h5: {
      fontSize: '1.25rem',
      fontWeight: 500,
      lineHeight: 1.2,
    },
    h6: {
      fontSize: '0.9rem',
      fontWeight: 500,
      lineHeight: 1.2,
    },
    subtitle1: {
      fontSize: '1rem',
      lineHeight: 1.5,
    },
    body1: {
      fontSize: '0.9rem',
      lineHeight: 1.5,
    },
    body2: {
      fontSize: '0.8rem',
      lineHeight: 1.5,
    },
    caption: {
      fontSize: '0.7rem',
      lineHeight: 1,
    },
    overline: {
      fontSize: '0.6rem',
      lineHeight: 1.5,
      textTransform: 'none',
    },
  },
  spacing: 4,
  shadows: mergedShadows,
  components: {
    MuiContainer: {
      styleOverrides: {
        root: {
          paddingLeft: '1rem!important',
          paddingRight: '1rem!important',
        },
      },
    },
    MuiButton: {
      variants: [
        {
          props: { variant: 'light', color: 'primary' },
          style: {
            backgroundColor: '#EFF3F9',
            color: '#4574BA',
            '&:hover': {
              backgroundColor: '#DFE7F3',
            },
          },
        },
        {
          props: { variant: 'light', color: 'success' },
          style: {
            backgroundColor: '#F5FBF7',
            color: '#45BA58',
            '&:hover': {
              backgroundColor: '#ECF8EE',
            },
          },
        },
        {
          props: { variant: 'light', color: 'error' },
          style: {
            backgroundColor: '#F5EDEE',
            color: '#B81F2A',
            '&:hover': {
              backgroundColor: '#FAD8DB',
            },
          },
        },
        {
          props: { variant: 'light', color: 'warning' },
          style: {
            backgroundColor: '#FCF6E5',
            color: '#B0913B',
            '&:hover': {
              backgroundColor: '#F0E9D5',
            },
          },
        },
        {
          props: { variant: 'outlined', color: 'primary' },
          style: {
            border: '1px solid #DFE7F3',
            '&:hover': {
              border: '1px solid #DFE7F3',
              backgroundColor: '#EFF3F9',
            },
          },
        },
        {
          props: { variant: 'outlined', color: 'success' },
          style: {
            border: '1px solid #ECF2EE',
            '&:hover': {
              border: '1px solid #ECF2EE',
              backgroundColor: '#F5FBF7',
            },
          },
        },
        {
          props: { variant: 'outlined', color: 'warning' },
          style: {
            border: '1px solid #F8F2E1',
            '&:hover': {
              border: '1px solid #F8F2E1',
              backgroundColor: '#FCF6E5',
            },
          },
        },
        {
          props: { variant: 'outlined', color: 'error' },
          style: {
            border: '1px solid #FAD8DB',
            color: '#DA6974',
            '&:hover': {
              border: '1px solid #FAD8DB',
              backgroundColor: '#F5EDEE',
            },
          },
        },
      ],
      styleOverrides: {
        root: {
          padding: '4px 0.5rem',
          minHeight: '32px',
          fontWeight: '600',
          letterSpacing: '-0.5px',
          textTransform: 'initial',
          fontSize: '0.8rem',
          minWidth: 0,
        },
        contained: {
          color: '#fff',
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none',
          },
        },
        outlined: {
          padding: '4px 0.5rem',
        },
        sizeSmall: {
          padding: '2px 0.5rem!important',
          minHeight: '26.5px!important',
          fontSize: '0.7rem',
        },
        startIcon: {
          marginRight: '4px',
          lineHeight: '1rem',
        },
        endIcon: {
          marginLeft: '4px',
          lineHeight: '1rem',
        },
        textSecondary: {
          color: '#627178',
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          fontSize: '0.8rem',
          textTransform: 'initial',
          fontWeight: '600',
          letterSpacing: '-0.5px',
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: '#7F8D93',
        },
        colorWarning: {
          color: '#DABC69',
        },
        colorSuccess: {
          color: '#45BA58',
        },
        colorError: {
          color: '#DA6974',
        },
        colorInfo: {
          color: '#4EB9D4',
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '14px',
          fontWeight: '500',
        },
      },
    },
    MuiBadge: {
      styleOverrides: {
        badge: {
          padding: 0,
          minWidth: '16px',
          height: '16px',
          fontWeight: '600',
          fontSize: '10px',
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          height: 'auto',
          padding: '0',
          margin: '8px',
          width: '27px',
          '& .MuiSwitch-thumb': {
            width: '12px',
            height: '12px',
            boxShadow: 'none!important',
          },
          '& .MuiSwitch-track': {
            height: '16px',
            borderRadius: '100px',
          },
          '& .MuiSwitch-switchBase': {
            padding: '2px',
            '&.Mui-checked': {
              transform: 'translateX(10px)',
            },
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontSize: '0.7rem',
          paddingLeft: '0.4rem',
          paddingRight: '0.4rem',
        },
        sizeSmall: {
          padding: '1px 0.4rem',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          paddingTop: '6px',
          paddingBottom: '6px',
        },
        notchedOutline: {
          borderColor: '#E7EBEF',
        },
        root: {
          '&:not(.Mui-error):hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#D0E1FB!important',
          },
          '&:not(.Mui-error):active .MuiOutlinedInput-notchedOutline': {
            borderColor: '#D0E1FB!important',
          },
          '&.Mui-error .MuiOutlinedInput-notchedOutline': {
            borderColor: '#F4D3D6!important',
          },
          '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
            borderColor: '#E7EBEF!important',
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          '&.Mui-disabled': {
            color: '#202541!important',
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: '0.7rem',
          transform: 'translate(14px, 8px) scale(1)',
        },
        shrink: {
          transform: 'translate(14px, -8px) scale(0.75)!important',
        },
        outlined: {
          padding: '0!important',
        },
        '& .Mui-error': {
          color: '#D44E5C',
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          padding: '0.33rem',
          '& .MuiSvgIcon-root': {
            height: '1rem',
            width: '1rem',
          },
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          padding: '6px 0.75rem',
          fontWeight: 600,
          letterSpacing: '0.5px',
          fontSize: '0.65rem',
          minWidth: 0,
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          padding: '6px 0',
          fontWeight: '500',
          height: '30.5px',
          letterSpacing: 0,
          background: '#F4F7FB',
          fontSize: '12px',
          '&.MuiChip-clickableColorError:hover': {
            background: '#E9BDC1',
          },
          '&.MuiChip-clickableColorPrimary:hover': {
            background: '#D0E1FB',
          },
          '&.MuiChip-clickableColorWarning:hover': {
            background: '#ECE1C2',
          },
          '&.MuiChip-clickableColorSuccess:hover': {
            background: '#D9F1DD',
          },
        },
        label: {
          display: 'flex',
          alignItems: 'center',
        },
        sizeSmall: {
          height: '24px !important',
          fontSize: '12px',
        },
        colorPrimary: {
          background: '#EFF3F9',
          color: '#3B639E',
          '& svg': {
            color: '#3B639E!important',
          },
        },
        colorSuccess: {
          background: '#ECF8EE',
          color: '#3B9E4B',
          '& svg': {
            color: '#3B9E4B!important',
          },
        },
        colorWarning: {
          background: '#F8F2E1',
          color: '#B49642',
          '& svg': {
            color: '#B49642!important',
          },
        },
        colorError: {
          background: '#F5EDEE',
          color: '#B81F2A',
          '& svg': {
            color: '#B81F2A!important',
          },
        },
        colorSecondary: {
          background: '#F9FAFB',
          color: '#627178',
          '& svg': {
            color: '#627178 !important',
          },
        },
        outlined: {
          background: 'none',
          borderColor: '#f2f4fb',
          '& svg': {
            fontSize: '14px!important',
          },
        },
        outlinedPrimary: {
          '& svg': {
            color: '#6189C4 !important',
          },
        },
        outlinedSecondary: {
          color: '#627178',
          '& svg': {
            color: '#627178 !important',
          },
        },
        outlinedError: {
          border: '1px solid #FAD8DB',
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          width: '0.9em',
          height: '0.9em',
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          minHeight: TAB_HEIGHT,
        },
        indicator: {
          height: '1.5px',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'capitalize',
          fontWeight: '600',
          color: '#627178',
          opacity: 1,
          padding: '6px 8px',
          minWidth: '0 !important',
          minHeight: TAB_HEIGHT,
          letterSpacing: '-.5px',
          '&:hover': {
            color: '#202541',
          },
          '&.Mui-selected': {
            color: '#4574BA',
          },
          button: {
            opacity: 0,
            transition: 'opacity 0.4s ease, visibility 0s 0.2s',
          },
          '&:hover button': {
            opacity: 1,
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          letterSpacing: 0,
          fontSize: '14px',
          padding: '0.33rem 0.66rem',
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        standardInfo: {
          backgroundColor: '#DAE3F1 !important',
          color: '#3B639E',
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        popper: {
          zIndex: 1400,
        },
        inputRoot: {
          fontSize: '.7rem!important',
          paddingTop: '2px',
          paddingBottom: '2px',
        },
        input: {
          minWidth: '15px!important',
          fontSize: '.7rem!important',
          width: '100%',
        },
        clearIndicator: {
          marginTop: '4px',
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          '&.Mui-expanded': {
            margin: 0,
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          letterSpacing: '0',
        },
        content: {
          margin: '0!important',
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: '4px',
          boxShadow: '0 1px 2px rgba(0,0,0,0.03)',
          marginBottom: '0.66rem',
          background: '#fff',
          overflow: 'hidden',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        outlined: {
          border: '1px solid #EDF0F3',
          boxShadow: 'none!important',
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: '0.7rem 0.9rem',
          paddingBottom: '12px!important',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: '1px solid #EDF0F3',
        },
      },
    },
    MuiTableSortLabel: {
      styleOverrides: {
        root: {
          padding: '0.5rem 0 !important',
          letterSpacing: '0',
          lineHeight: '100%',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& > .Mui-disabled:not(.MuiInputLabel-root)': {
            background: '#F9FAFB',
            border: 0,
            '& input, & select, .MuiSelect-select.Mui-disabled': {
              '-webkit-text-fill-color': '#202541',
            },
            '.Mui-disabled': {
              color: '#202541!important',
              opacity: '1',
            },
            '& svg': {
              display: 'none',
            },
          },
          '& .Mui-disabled fieldset': {
            border: '0px!important',
          },
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: '#f2f5f7',
          fontSize: '12px',
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          background: 'rgba(255, 255, 255, 0.01)',
          backdropFilter: 'blur(4px)',
        },
      },
    },
  },
};

export default baseConfigTheme;
