import { moment, React, ts, ui } from '_core';

type DateRangeFieldProps = {
  value?: ts.types.dataSource.DATE_VALUE_TYPE;
  setValue: (_d: ts.types.dataSource.DATE_VALUE_TYPE) => void;
  label: string;
  minDate: string;
  maxDate: string;
};

const DateRangeField: React.FC<DateRangeFieldProps> = ({
  value,
  setValue,
  label,
  minDate,
  maxDate,
}): React.ReactElement => {
  const today = moment().format('YYYY-MM-DD');
  const oneMonthAgo = moment().subtract(1, 'months').format('YYYY-MM-DD');

  React.useEffect(() => {
    if (!value?.start && !value?.end)
      setValue({
        start: oneMonthAgo,
        end: today,
      });
  }, []);

  return (
    <ui.FinDateRangePicker
      label={label}
      maxDate={maxDate}
      minDate={minDate}
      value={[value?.start ?? oneMonthAgo, value?.end ?? today]}
      onChange={(v) => setValue({ start: v?.[0], end: v?.[1] })}
    />
  );
};

DateRangeField.defaultProps = {
  minDate: null,
  maxDate: null,
};

export default DateRangeField;
