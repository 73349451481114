import { store } from '_core/store';

import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from '@local/finsera-core';

import './index.css';
import './swagger-ui-theme.css';
import 'swagger-ui-react/swagger-ui.css';

import App from './app';

const root = createRoot(document.getElementById('root'));

store &&
  root.render(
    <Provider store={store}>
      <App />
    </Provider>
  );
