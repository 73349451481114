import { ts } from '_core';


export default [
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.BACKTEST_SET_PORTFOLIOS,
    title: 'Portfolios',
    helpLabel: 'uagyylp2',
    form: false,
    neededFiles: ['backtest_set'],
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.BACKTEST_SET],
    development: true,
  },
] satisfies ts.types.analysis.WidgetConfig[];
