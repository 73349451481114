import { mui, React, ts, ui } from '_core';

import SharedConstraintFields from './shared-fields';

type StockLoanAvailabilityProps = {
  params: ts.types.optimizer.StockLoanAvailabilityDraft;
  setParams: (_params: ts.types.optimizer.StockLoanAvailabilityDraft) => void;
  readOnly: boolean;
  validationDef: ts.types.common.ValidationErrors;
};

const StockLoanAvailability: React.FC<StockLoanAvailabilityProps> = ({
  params,
  setParams,
  readOnly,
  validationDef,
}): React.ReactElement => {
  const [showMore, setShowMore] = React.useState(false);
  return (
    <mui.core.Box p={2}>
      <mui.core.Grid container spacing={4}>
        <mui.core.Grid item xs={12} md={4}>
          <ui.NumericField
            textFieldProps={{
              label: 'Default Availability',
              fullWidth: true,
              variant: 'outlined',
              size: 'small',
              required: true,
            }}
            disabled={readOnly}
            value={params.default_available_shares}
            setValue={(v) => setParams({ ...params, default_available_shares: v })}
          />
        </mui.core.Grid>

        <mui.core.Grid item xs={12} md={12}>
          {showMore && (
            <SharedConstraintFields
              params={params}
              setParams={setParams}
              showRelaxation={false}
              readOnly={readOnly}
              validationDef={validationDef}
            />
          )}
          <mui.core.Button
            onClick={() => {
              setShowMore(!showMore);
            }}
            color="primary"
            style={{ marginTop: '0.5rem' }}
          >
            {showMore ? (
              <>
                <mui.icons.Remove style={{ fontSize: '14px', marginRight: '0.5rem' }} />
                Show Less
              </>
            ) : (
              <>
                <mui.icons.Add style={{ fontSize: '14px', marginRight: '0.5rem' }} />
                Show More
              </>
            )}
          </mui.core.Button>
        </mui.core.Grid>
      </mui.core.Grid>
    </mui.core.Box>
  );
};

export default StockLoanAvailability;
