import { WSJob } from '@local/finsera-core/src/types/job';

import { JOB_UPDATE } from '../actions/types';

const INITIAL_STATE = {
  lastJobCompleted: { id: -1 } as WSJob,
  runningJobs: [] as WSJob[],
};

export type JobCompletedActionType = {
  type: typeof JOB_UPDATE;
  job: WSJob;
};

export default (state = INITIAL_STATE, action: JobCompletedActionType) => {
  switch (action.type) {
    case JOB_UPDATE: {
      const newState = { ...state };
      newState.runningJobs = newState.runningJobs.filter((j) => j.id !== action.job.id);

      if (!action.job.canceled) {
        if (action.job.completed_at) {
          newState.lastJobCompleted = action.job;
        } else {
          newState.runningJobs = [...newState.runningJobs, action.job];
        }
      }

      return newState;
    }
    default:
      return state;
  }
};
