import { mui, React } from '_core';

import { FRACTIONAL_RISK_ENUM } from '../../../types';

const SelectFractionalRiskField = ({
  value,
  setValue,
}: {
  value: FRACTIONAL_RISK_ENUM;
  setValue: (_v: FRACTIONAL_RISK_ENUM) => void;
}) => (
  <mui.core.Box display="inline-flex" width={200} ml={2} mr={2}>
    <mui.core.TextField
      select
      label="Units"
      variant="outlined"
      size="small"
      value={value}
      onChange={(e) => setValue(e.target.value as FRACTIONAL_RISK_ENUM)}
      fullWidth
    >
      <mui.core.MenuItem value={FRACTIONAL_RISK_ENUM.FRACTION}>Percent Contribution</mui.core.MenuItem>
      <mui.core.MenuItem value={FRACTIONAL_RISK_ENUM.RISK}>Marginal Contribution</mui.core.MenuItem>
    </mui.core.TextField>
  </mui.core.Box>
);

export default SelectFractionalRiskField;
