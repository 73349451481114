import { mui } from '@local/finsera-core/src/libs';
import { GridFilterInputDateProps } from '@mui/x-data-grid-premium';

import FinDatePicker from '../date-picker';

const DateFilterInput = (props: GridFilterInputDateProps) => {
  const { item, applyValue, apiRef } = props;

  const currentCol = apiRef.current.state.columns.lookup[item.field];
  const minDate = (currentCol as any).start_date;
  const maxDate = (currentCol as any).end_date;

  const handleFilterChange = (newValue: string) => {
    applyValue({ ...item, value: newValue });
  };

  return (
    <mui.core.Box
      sx={{
        display: 'inline-flex',
        flexDirection: 'row',
        alignItems: 'center',
        height: 62,
      }}
    >
      <FinDatePicker
        value={item.value}
        onChange={handleFilterChange}
        minDate={minDate}
        maxDate={maxDate}
        variant="standard"
        inputProps={{
          disableUnderline: false,
        }}
      />
      <span style={{ opacity: 0 }}>a</span>
    </mui.core.Box>
  );
};

export default DateFilterInput;
