import React from 'react';

import * as hooks from '../../hooks';
import { mui } from '../../libs';
import * as types from '../../types';

/**
 * Component that provides custom autocomplete functionality with configurable options and rendering
 *
 * @param label - Input label text
 * @param variant - MUI input variant (outlined, filled, standard)
 * @param value - Currently selected value(s)
 * @param setValue - Callback to update selected value(s)
 * @param options - Array of options to select from
 * @param createTitle - Function to generate display title for each option
 * @param example - Example/placeholder text
 * @param createId - Function to generate unique ID for each option
 * @param limitTags - Maximum number of tags to show when multiple selection is enabled
 * @param disabled - Whether the input is disabled
 * @param optionRenderer - Custom renderer for option items
 * @param tableView - Whether to show options in table format
 * @param multiple - Whether multiple selection is enabled
 * @param disableCloseOnSelect - Whether to keep dropdown open after selection
 *
 * @returns Rendered autocomplete component
 */
const CustomAutoComplete = <TValue, TOptions, Multiple, UseOption extends boolean | undefined = undefined>(
  props: types.components.autocomplete.IAutoCompleteProps<TValue, TOptions, Multiple, UseOption>
): React.ReactElement => {
  const uiStyles = hooks.useUiStyles();

  const {
    label,
    variant,
    value,
    setValue,
    options,
    createTitle,
    example,
    createId,
    limitTags,
    disabled,
    optionRenderer,
    tableView,
    multiple,
    disableCloseOnSelect,
    textFieldProps,
    sx,
    fullWidth = true,
  } = props;
  const AutoCompleteComponent = () => (
    <mui.core.Autocomplete<any, typeof multiple, typeof tableView, false>
      multiple={multiple}
      forcePopupIcon={false}
      disableClearable={tableView}
      renderTags={tableView ? () => null : undefined}
      selectOnFocus={false}
      getOptionLabel={(option) => createTitle(option)}
      options={options}
      autoComplete
      disableCloseOnSelect={disableCloseOnSelect}
      isOptionEqualToValue={(option) =>
        multiple
          ? !!(value as any).find((v: any) => createId(v) == createId(option))
          : createId(value as any) == createId(option)
      }
      disabled={disabled}
      limitTags={limitTags}
      size="small"
      sx={sx}
      value={value}
      onChange={(e, val) => {
        setValue(val);
      }}
      renderInput={(params) => (
        <mui.core.TextField
          {...params}
          {...textFieldProps}
          variant={variant}
          label={label}
          fullWidth={fullWidth}
          placeholder={example}
          disabled={disabled}
          size="small"
        />
      )}
      renderOption={(props, option) => <li {...props}>{optionRenderer(option)}</li>}
    />
  );

  return <mui.core.Box sx={uiStyles.inputHelp}>{AutoCompleteComponent()}</mui.core.Box>;
};

export default CustomAutoComplete;
