import { ts } from '_core';

import { getName, validate } from './helpers';
import { DraftParams } from './types';

export default [
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.SIGNAL_EXPOSURE_EVENT_STUDY,
    title: 'Signal Exposure Event Study',
    helpLabel: '3hephtfm',
    form: true,
    defaultParams: {
      days_before_event: 10,
      days_after_event: 21,
      get_histories: false,
      with_volatility: false,
      format_for_ui: true,
      event_study_type: 'EXPOSURE',
    } as DraftParams,
    neededFiles: ['signal_event_study', 'signal_event_study_count'],
    tags: [
      ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.SIGNAL,
      ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.ALPHA_MODEL,
      ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.COMMON_ALPHA_MODEL,
    ],

    getName,
    validate,
  },
] satisfies ts.types.analysis.WidgetConfig[];
