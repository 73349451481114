import { helpers, ts } from '_core';

export default {
  [ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.SUMMARY]: [
    {
      key: 'basket',
      name: 'Basket',
      formatter: helpers.tableFormatters.formatTo('string', { align: 'left' }),
      filter: 'string',
    },
    {
      key: 'analysis_start_date',
      name: 'Start Date',
      filter: 'date',
      formatter: helpers.tableFormatters.formatTo('date', { align: 'right', format: 'YYYY-MM-DD' }),
    },
    {
      key: 'analysis_end_date',
      name: 'End Date',
      filter: 'date',
      formatter: helpers.tableFormatters.formatTo('date', { align: 'right', format: 'YYYY-MM-DD' }),
    },
    {
      key: '5 Trading Days',
      name: '5 Trading Days',
      formatter: helpers.tableFormatters.formatTo('float', {
        align: 'right',
        roundDigits: 2,
        multiplier: 100,
        suffix: '%',
      }),
      filter: 'number',
    },
    {
      key: '1 Month',
      name: '1 Month',
      formatter: helpers.tableFormatters.formatTo('float', {
        align: 'right',
        roundDigits: 2,
        multiplier: 100,
        suffix: '%',
      }),
      filter: 'number',
    },
    {
      key: '3 Months',
      name: '3 Months',
      formatter: helpers.tableFormatters.formatTo('float', {
        align: 'right',
        roundDigits: 2,
        multiplier: 100,
        suffix: '%',
      }),
      filter: 'number',
    },
    {
      key: '6 Months',
      name: '6 Months',
      formatter: helpers.tableFormatters.formatTo('float', {
        align: 'right',
        roundDigits: 2,
        multiplier: 100,
        suffix: '%',
      }),
      filter: 'number',
    },
    {
      key: '1 Year',
      name: '1 Year',
      formatter: helpers.tableFormatters.formatTo('float', {
        align: 'right',
        roundDigits: 2,
        multiplier: 100,
        suffix: '%',
      }),
      filter: 'number',
    },
    {
      key: '3 Years',
      name: '3 Years',
      formatter: helpers.tableFormatters.formatTo('float', {
        align: 'right',
        roundDigits: 2,
        multiplier: 100,
        suffix: '%',
      }),
      filter: 'number',
    },
    {
      key: '5 Years',
      name: '5 Years',
      formatter: helpers.tableFormatters.formatTo('float', {
        align: 'right',
        roundDigits: 2,
        multiplier: 100,
        suffix: '%',
      }),
      filter: 'number',
    },
    {
      key: 'Full',
      name: 'Full',
      formatter: helpers.tableFormatters.formatTo('float', {
        align: 'right',
        roundDigits: 2,
        multiplier: 100,
        suffix: '%',
      }),
      filter: 'number',
    },
    {
      key: 'Year to Date',
      name: 'Year to Date',
      formatter: helpers.tableFormatters.formatTo('float', {
        align: 'right',
        roundDigits: 2,
        multiplier: 100,
        suffix: '%',
      }),
      filter: 'number',
    },
  ] as ts.types.components.dataGrid.ColumnsData,
};
