import React from 'react';

import * as enums from '../../../enums';
import { _, mui } from '../../../libs';
import Button from '../../button';
import CenteredLoader from '../../centered-loader';
import { UploadContext } from '../upload-context';
import * as utils from '../utils';

const MapColumnsStep = () => {
  const uploadContext = React.useContext(UploadContext);
  const {
    setErrorMessage,
    setStep,
    mappedColumns,
    setMappedColumns,
    availableColumns,
    handleUploadData,
    columnsToMap,
  } = uploadContext;

  const columnGroups = {
    Identifiers: [
      enums.BACKTEST_HOLDINGS_MAP_COLS.TICKER,
      enums.BACKTEST_HOLDINGS_MAP_COLS.COUNTRY,
      enums.BACKTEST_HOLDINGS_MAP_COLS.CUSIP,
      enums.BACKTEST_HOLDINGS_MAP_COLS.ISIN,
      enums.BACKTEST_HOLDINGS_MAP_COLS.SEDOL,
      enums.BACKTEST_HOLDINGS_MAP_COLS.BLOOMBERG_TICKER,
    ],
    Details: [enums.BACKTEST_HOLDINGS_MAP_COLS.NAME, enums.BACKTEST_HOLDINGS_MAP_COLS.WEIGHTS],
  };

  const [loadingMapping, setLoadingMapping] = React.useState(false);

  const handleSubmit = async () => {
    setErrorMessage('');

    const error = utils.validateColumns(mappedColumns);

    if (error) {
      setErrorMessage(error);
      return;
    }

    setLoadingMapping(true);
    await handleUploadData(mappedColumns);
    setLoadingMapping(false);
  };

  const renderColumnGroup = (groupName: string, columns: string[]) => {
    const filteredColumns = columns.filter((col) => columnsToMap.includes(col));
    if (filteredColumns.length === 0) return null;

    return (
      <React.Fragment key={groupName}>
        <mui.core.Grid item xs={12}>
          <mui.core.Grid container spacing={2}>
            {filteredColumns.map((col) => (
              <React.Fragment key={col}>
                <mui.core.Grid item xs={5} md={4} sx={{ display: 'flex', alignItems: 'center' }}>
                  <mui.core.Typography variant="body1" fontWeight="500">
                    {_.startCase(col)}
                  </mui.core.Typography>
                </mui.core.Grid>
                <mui.core.Grid item xs={7} md={8}>
                  <mui.core.TextField
                    select
                    placeholder="Select column"
                    variant="outlined"
                    value={mappedColumns[col] ?? ''}
                    onChange={(e) => {
                      setMappedColumns({ ...mappedColumns, [col]: e.target.value });
                    }}
                    required
                    size="small"
                    fullWidth
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        backgroundColor: 'background.paper',
                      },
                    }}
                  >
                    <mui.core.MenuItem value="None">&nbsp;</mui.core.MenuItem>
                    {availableColumns.map((avCol) => (
                      <mui.core.MenuItem
                        key={avCol}
                        value={avCol}
                        disabled={Object.values(mappedColumns).includes(avCol)}
                      >
                        {avCol}
                      </mui.core.MenuItem>
                    ))}
                  </mui.core.TextField>
                </mui.core.Grid>
              </React.Fragment>
            ))}
          </mui.core.Grid>
        </mui.core.Grid>
      </React.Fragment>
    );
  };

  if (loadingMapping)
    return (
      <mui.core.Box height="450px">
        <CenteredLoader label="Mapping to Asset Master." />
      </mui.core.Box>
    );

  return (
    <>
      <mui.core.DialogContent>
        <mui.core.Grid container spacing={4}>
          {Object.entries(columnGroups).map(([groupName, columns]) => (
            <React.Fragment key={groupName}>
              {renderColumnGroup(groupName, columns)}
              <mui.core.Grid item xs={12}>
                <mui.core.Divider />
              </mui.core.Grid>
            </React.Fragment>
          ))}
        </mui.core.Grid>
      </mui.core.DialogContent>

      <mui.core.DialogActions
        sx={{
          justifyContent: 'space-between',
        }}
      >
        <Button
          variant="outlined"
          color="primary"
          onClick={() => setStep(enums.HOLDINGS_UPLOAD_STEPS_ENUM.HOLDINGS_CREATION)}
          style={{ margin: '0rem 0 1rem 1rem' }}
          startIcon={<mui.icons.ArrowBack style={{ fontSize: '1rem' }} />}
        >
          Back
        </Button>
        <Button variant="contained" color="primary" onClick={handleSubmit} style={{ margin: '0rem 1rem 1rem' }}>
          Verify Data
        </Button>
      </mui.core.DialogActions>
    </>
  );
};

export default MapColumnsStep;
