import { _, React, ts, useSelector } from '_core';

import { prepareAnnuStatsData } from './helpers';
import { Params } from './types';
import ChartWrapper from '../../common/chart-wrapper';
import createNestedBarChart from '../../common/charts/create-nested-bar-chart';

type ChartProps = {
  id: string;
  readOnly: boolean;
  widgetKey: string;
  loadWidgetData: (_payload: ts.types.widgets.WidgetGetDataParams) => Promise<ts.types.widgets.WidgetGetDataResponse>;
  portfolios: string;
  fullScreen: boolean;
  params: Params;
  context: ts.types.basket.BasketAnalysisContext;
  title: string;
};

const Chart: React.FC<ChartProps> = ({
  id,
  readOnly,
  widgetKey,
  fullScreen,
  loadWidgetData,
  portfolios,
  params,
  context,
  title,
}) => {
  const baskets = useSelector((state) => state.resources.baskets);

  const [data, setData] = React.useState<ts.types.widgets.TableData>(null);
  const [error, setError] = React.useState<ts.types.common.ApiError>(null);
  const [showLegend, setShowLegend] = React.useState(false);

  const loadData = async () => {
    try {
      const response = await loadWidgetData({
        data: {
          file: portfolios,
        },
      });
      if (response) {
        const newData = _(response.data)
          .groupBy((item) => `${item.history}_${item.type}`)
          .map((group, key) => {
            const [history, type] = key.split('_');
            const result = group.reduce((acc, item) => {
              acc[item.handle as string] =
                params.statistics_type === 'risk'
                  ? params.with_benchmark
                    ? item.active_risk
                    : item.portfolio_risk
                  : params.with_benchmark
                    ? item.active_returns
                    : item.portfolio_returns;
              return acc;
            }, {});

            return {
              ...result,
              type,
              history,
            };
          })
          .value();

        setData(newData);
      }
    } catch (err) {
      setError(err as ts.types.common.ApiError);
    }
  };
  React.useEffect(() => {
    if (portfolios) loadData();
    return () => setData(null);
  }, [portfolios]);

  React.useEffect(() => {
    // Create line chart on data and scale changes
    const format = params.statistics_type !== 'adjusted' ? '#.##%' : '#.##';
    if (!_.isNil(data))
      createNestedBarChart({
        id,
        data,
        fullScreen,
        setShowLegend,
        format,
        exportTitle: title,
        prepareData: (nestedBarData) => prepareAnnuStatsData(nestedBarData, params, context, baskets),
      });
  }, [data]);

  return (
    <ChartWrapper
      id={id}
      readOnly={readOnly}
      widgetKey={widgetKey}
      data={data}
      showLegend={showLegend}
      error={error}
      fullScreen={fullScreen}
    />
  );
};

export default Chart;
