import { React, ts } from '_core';

import Chart from './chart';
import { Params } from './types';

type OptimizedAlphaTabularDataProps = {
  id: string;
  fullScreen?: boolean;
  loadWidgetData: (_payload: ts.types.widgets.WidgetGetDataParams) => Promise<ts.types.widgets.WidgetGetDataResponse>;
  params: Params;
  setParams: (_p: Params) => void;
  context?: ts.types.signal.SignalAnalysisContext;
  widgetPaths: Record<'optimized_alpha_tabular_data', string>;
  goFullScreen: (_q: string) => void;
  queryParams?: { initial?: string };
};

const OptimizedAlphaTabularData: React.FC<OptimizedAlphaTabularDataProps> = ({
  id,
  loadWidgetData,
  params,
  setParams,
  widgetPaths,
  fullScreen,
  context,
  goFullScreen,
  queryParams,
}) => {
  return (
    <Chart
      id={id}
      loadWidgetData={loadWidgetData}
      params={params}
      setParams={setParams}
      file={widgetPaths['optimized_alpha_tabular_data']}
      fullScreen={fullScreen}
      definition={
        (context.definition as ts.types.signal.AlphaDefinition)?.json_definition as ts.types.signal.OptimizedModel
      }
      queryParams={queryParams}
      goFullScreen={goFullScreen}
    />
  );
};

export default OptimizedAlphaTabularData;
