/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-ignore
import { _, hooks, mui, React, ts } from '_core';

import { prepareData } from './helpers';
import { DraftParams, Params } from './types';
import ChartWrapper from '../../common/chart-wrapper';
import createLineChart from '../../common/charts/create-line-chart';

type CategoryOptionType = ts.types.riskModel.CategoryFactor & {
  group?: string;
};

type ChartProps = {
  id: string;
  readOnly: boolean;
  widgetKey: string;
  params: Params | DraftParams;
  volatility: string;
  fullScreen: boolean;
  context: ts.types.riskModel.RiskModelAnalysisContext;
  setParams: (_p: Params) => void;
  loadWidgetData: (_payload: ts.types.widgets.WidgetGetDataParams) => Promise<ts.types.widgets.WidgetGetDataResponse>;
  title: string;
};

const Chart: React.FC<ChartProps> = ({
  id,
  readOnly,
  widgetKey,
  loadWidgetData,
  volatility,
  params,
  setParams,
  fullScreen,
  context,
  title,
}): React.ReactElement => {
  const isStatRiskModel = context.definition?.parameters?.type === 'STAT';

  const [data, setData] = React.useState<ts.types.widgets.TableData>(null);
  const [error, setError] = React.useState<ts.types.common.ApiError>(null);
  const [showLegend, setShowLegend] = React.useState(false);

  const [categoryOptions, setCategoryOptions] = React.useState<CategoryOptionType[]>([]);

  // This will be debounced
  const [categoryFactors, setCategoryFactors] = React.useState<ts.types.riskModel.CategoryFactor[]>();

  // This will track the instant interaction
  const [instantCategoryFactors, setInstantCategoryFactors] = React.useState<ts.types.riskModel.CategoryFactor[]>();

  const [tab, setTab] = React.useState(params.tab);
  const [instantTab, setInstantTab] = React.useState(params.tab);

  const getStyleFactors = () => (context.definition?.style || []).map((f) => f.handle);

  const loadCategoryOptions = async () => {
    try {
      const response = await loadWidgetData({
        data: {
          file: volatility,
          pagination: { offset: 0, limit: 0 },
          rename: { build_date: 'date' },
        },
      });
      if (response) {
        const { columns } = response;
        const localCategoryOptions = columns.filter((el) => el.includes('_industry_'));

        const marketOpt = {
          name: 'Market',
          handle: 'market',
          group: 'Market',
        } as CategoryOptionType;

        const opts = [marketOpt];

        localCategoryOptions.forEach((cat) => {
          const formattedName = cat.replace('_industry_', '');
          opts.push({
            name: formattedName,
            handle: formattedName,
            group: 'Industry',
          });
        });

        setCategoryOptions(opts);
        setInstantCategoryFactors(_.isEmpty(params.category_factors) ? opts.slice(0, 10) : params.category_factors);
      }
    } catch (err) {
      setError(err as ts.types.common.ApiError);
    }
  };

  const loadData = async () => {
    try {
      const response = await loadWidgetData({
        data: {
          file: volatility,
          rename: { build_date: 'date' },
          columns:
            tab == 'style_factors'
              ? ['date', ...getStyleFactors().map((f) => `_style_${f}`)]
              : ['date', ...categoryFactors.map((f) => (f.handle == 'market' ? 'market' : `_industry_${f.handle}`))],
          dropna: true,
        },
      });
      if (response) setData(response.data);
    } catch (err) {
      setError(err as ts.types.common.ApiError);
    }
  };

  React.useEffect(() => {
    if (volatility) {
      if (isStatRiskModel) loadData();
      else loadCategoryOptions();
    }
    return () => setData(null);
  }, [volatility]);

  hooks.useEffectWithoutFirst(() => {
    if (volatility && !isStatRiskModel) loadData();
    return () => setData(null);
  }, [tab, categoryFactors]);

  // Save parameters on instant changes (debounced)
  const debouncedSaveParams = React.useCallback(
    _.debounce((instantTab, instantCategoryFactors) => {
      setParams({ tab: instantTab, category_factors: instantCategoryFactors });
      setCategoryFactors(instantCategoryFactors);
      setTab(instantTab);
    }, 2000),
    []
  );

  hooks.useEffectWithoutFirst(() => {
    setData(null);
    debouncedSaveParams(instantTab, instantCategoryFactors);
  }, [instantTab, instantCategoryFactors]);

  React.useEffect(() => {
    // Create line chart on data and scale changes
    if (!_.isNil(data))
      createLineChart({
        id,
        data,
        fullScreen,
        setShowLegend,
        percent: false,
        aggregation: 'outlier',
        yAxisLabel: 'Annualized Factor Risk',
        exportTitle: title,
        prepareData: (linearData) => prepareData(linearData, context.definition?.style),
      });
  }, [data]);

  const selectCategory = () => {
    return (
      <mui.core.Autocomplete<CategoryOptionType, true>
        fullWidth
        multiple
        limitTags={fullScreen ? 6 : 3}
        size="small"
        groupBy={(option) => option.group}
        getOptionDisabled={() => instantCategoryFactors.length >= 10}
        options={categoryOptions}
        value={instantCategoryFactors}
        onChange={(e, val) => {
          if (!_.isEmpty(val)) setInstantCategoryFactors(val as CategoryOptionType[]);
        }}
        disableCloseOnSelect
        getOptionLabel={(option) => (option as CategoryOptionType).name}
        isOptionEqualToValue={(option, val) => option.handle == val.handle}
        renderInput={(p) => <mui.core.TextField {...p} label="Factor" variant="outlined" size="small" />}
      />
    );
  };

  return (
    <ChartWrapper
      id={id}
      readOnly={readOnly}
      widgetKey={widgetKey}
      data={data}
      showLegend={showLegend}
      error={error}
      fullScreen={fullScreen}
      tabs={
        !isStatRiskModel ? (
          <>
            <mui.core.Tabs
              value={instantTab}
              indicatorColor="primary"
              textColor="primary"
              onChange={(e, newTab) => setInstantTab(newTab)}
              aria-label="Return Horizon Tabs"
            >
              <mui.core.Tab label={'Style'} value={'style_factors'} />
              <mui.core.Tab label={'Category'} value={'category_factors'} />
            </mui.core.Tabs>
            <mui.core.Box p={3}>
              {instantTab !== 'style_factors' && !_.isEmpty(categoryOptions) && selectCategory()}
            </mui.core.Box>
          </>
        ) : null
      }
    />
  );
};

export default Chart;
