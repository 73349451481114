import { endpoint } from './config';
import { FREQUENCY_ENUM } from '../enums';
import { $ } from '../libs';
import { Universe } from '../types/universe';

type ValidDatesDataType = {
  frequency: FREQUENCY_ENUM;

  universe_id?: number;
  countries?: string[];
  start_date?: string;
  end_date?: string;
  ignore_cache?: boolean;
  until_latest_trade_date?: boolean;
};

type KeepCurrentDataType = {
  resource_id: number;
  resource_type: string;
  max_date: string;

  countries?: string[];
  universe_id?: number;
  frequency?: FREQUENCY_ENUM;
};

export const get_valid_dates = (data: ValidDatesDataType): JQuery.jqXHR<{ data: string[] }> =>
  $.ajax({
    method: 'POST',
    url: `${endpoint}get_valid_dates`,
    json: { ...data },
  } as JQueryAjaxSettings);

export const keep_current = (data: KeepCurrentDataType): JQuery.jqXHR<{ resource: any; universe?: Universe }> =>
  $.ajax({
    method: 'POST',
    url: `${endpoint}resource_keep_current`,
    json: { ...data },
  } as JQueryAjaxSettings);
