/* eslint-disable no-unused-vars */
export enum INTERACTION_PARAMS {
  TAB = 'tab',
}

export type InteractionParams = {
  [INTERACTION_PARAMS.TAB]?: string;
};

export type JobParams = {
  window: number;
};

export type Params = JobParams & InteractionParams;
export type DraftParams = Partial<Params>;
