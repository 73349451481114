/* eslint-disable no-unused-vars */

import { ts } from '_core';

export enum INTERACTION_PARAMS {
  HISTORIES = 'histories',
}

export type InteractionParams = {
  [INTERACTION_PARAMS.HISTORIES]: ts.enums.HISTORY_ENUM_EXTENDED[];
};

export type JobParams = {
  basket_ids: number[];
  benchmark?: Record<string, ts.enums.BENCHMARK_TYPES_ENUM | string | number>;
  with_benchmark: boolean;
};

export type Params = JobParams & InteractionParams;
export type DraftParams = Partial<Params>;
