import { userapiendpoint } from './config';
import { $ } from '../libs';
import { StoreDataSource } from '../types/data-source';

export const expressionBuilder = (data: {
  expression: string;
  cache_data_sources?: StoreDataSource[];
}) => {
  return $.ajax({
    method: 'POST',
    url: `${userapiendpoint}fin-gen/expressions_builder`,
    json: data,
  } as JQueryAjaxSettings);
};
export const validateSignal = (data: {
  handle: string;
  definition: {
    editor_mode: string;
    source: string;
  };
  organization_id: number;
}) =>
  $.ajax({
    method: 'POST',
    url: `${userapiendpoint}fin-gen/signals/validate`,
    json: data,
  } as JQueryAjaxSettings);
