import { api, helpers, mui, queryString, React, ts, ui, useLocation } from '_core';

import ChartRenderer from './chart-renderer';
import ErrorComponent from './error-component';
import SyncTableRenderer from './sync-table-renderer';

const EmbeddedWidget = ({ widgetKey }: { widgetKey: string }) => {
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(false);
  const [widgetData, setWidgetData] = React.useState<ts.types.analysis.EmbedChartData>();

  const location = useLocation();
  const fullScreen = queryString.parse(location.search)?.fullscreen == 'true';
  const download = queryString.parse(location.search)?.download == 'true';

  React.useEffect(() => {
    const loadWidgetData = async () => {
      setLoading(true);
      try {
        const resp = await api.embedWidgets.loadData(widgetKey);
        setWidgetData(helpers.embed.getChartData(resp));
        setLoading(false);
      } catch (err) {
        console.log(err);
        setError(true);
      }
    };

    loadWidgetData();
  }, []);

  const renderInner = () => {
    if (error) return <ErrorComponent />;
    if (loading) return <ui.CenteredLoader />;
    if (widgetData.chart_type == 'sync-table') return <SyncTableRenderer widgetData={widgetData} download={download} />;
    return <ChartRenderer widgetData={widgetData} fullScreen={fullScreen} download={download} />;
  };

  return (
    <mui.core.Box height="100vh" width="100vw" sx={{ background: '#fff' }}>
      {renderInner()}
    </mui.core.Box>
  );
};

export default EmbeddedWidget;
