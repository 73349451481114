import { ts } from '_core';

import { validate } from './helpers';
import { INTERACTION_PARAMS } from './types';

export default [
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.SIGNAL_PREVIEW_SELECTED_ASSETS,
    form: true,
    title: 'Selected Assets',
    interactionParams: Object.values(INTERACTION_PARAMS),
    clearInteractionOnRegenerate: ['table_dtypes'],
    neededFiles: ['main_data'],
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.SIGNAL_PREVIEW],

    validate,
    helpLabel: 'ddgr1qdk',
  },
] satisfies ts.types.analysis.WidgetConfig[];
