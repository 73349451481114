import { _, helpers, ts } from '_core';

import { DraftParams } from './types';

const getBucketLabel = (label: string) => {
  const splittedLabel = label.split(':');
  const bucketType = splittedLabel[0] as ts.enums.BUCKET_TYPES_ENUM;

  if (bucketType == ts.enums.BUCKET_TYPES_ENUM.INDUSTRY) {
    const map = { ...ts.enums.MAPPED_RBICS_NAME };
    return map[splittedLabel[1] as keyof typeof map];
  }

  if (bucketType == ts.enums.BUCKET_TYPES_ENUM.ASSET_MASTER || bucketType == ts.enums.BUCKET_TYPES_ENUM.RISK_MODEL)
    return _.startCase(splittedLabel[1]);

  return splittedLabel.slice(1).join(':');
};

// prepareExposureData
export const transform = (data: ts.types.widgets.TableData, bucketName = 'label') => {
  return data.map((d, _, arr) => {
    const bucketType = (d[bucketName] as string).split(':')[0] as ts.enums.BUCKET_TYPES_ENUM;
    let metric = d.metric as string;

    if (bucketType === 'STYLE_FACTORS_QUANTILES' || bucketType === 'SIGNAL') {
      const quantile = parseInt(d.metric as string);

      const highestQuantile = Math.max(...arr.map((item) => parseInt(item.metric as string)));
      let quantileLabel = `Q${quantile}`;

      if (quantile === 1) {
        quantileLabel += ' (Lowest)';
      } else if (quantile === highestQuantile) {
        quantileLabel += ' (Highest)';
      }

      metric = quantileLabel;
    }

    return {
      ...d,
      metric,
      [bucketName]: getBucketLabel(d[bucketName] as string),
    };
  });
};

export const getBucketKey = (
  params: DraftParams,
  bucketKey: string,
  signals: ts.StoreState['resources']['signals']
) => {
  const bucket = params.buckets.find((b) => b.type === bucketKey);
  if (!bucket) return '';

  switch (bucket.type) {
    case 'STYLE_FACTORS_QUANTILES':
      return 'Risk Model Style Factors';
    case 'RISK_MODEL':
      return `Risk Model ${_.startCase(bucket.value as string)}`;
    case 'ASSET_MASTER':
      return _.startCase(bucket.value as string);
    case 'INDUSTRY':
      return ts.enums.MAPPED_RBICS_NAME[bucket.value as keyof typeof ts.enums.MAPPED_RBICS_NAME];
    case 'SIGNAL':
      return signals.find((el) => el.id === bucket.signal_id)?.name || '';
    default:
      return '';
  }
};

export const validate = (params: DraftParams) => {
  if (_.isEmpty(params?.buckets)) return ['You need to provide at least one bucket.'];

  const bucketTypes = params.buckets.map((b) => `${b.type}-${b.value}`);
  if (_.uniq(bucketTypes).length !== bucketTypes.length) return ['Duplicate bucket types and values are not allowed'];

  if (_.some(params.buckets, 
    (b) => !helpers.validations.validBuckets(b))) return ['One of the buckets is not correctly defined.'];
  return [];
};
