import { helpers, ts } from '_core';

export default {
  [ts.enums.REPORT_ENUMS.TABLE_SHEET_KEY_ENUM.MAIN]: [
    {
      key: 'index',
      name: 'Index',
      formatter: helpers.tableFormatters.formatTo('inferred'),
      members: (key) => {
        if (key.startsWith('_index_')) return true;
        return false;
      },
      cleanNameFormatter: (key) => key.replace('_index_', ''),
      nameFormatter: (key) => key.replace('_index_', ''),
      isIndex: true,
      filter: 'inferred',
    },
    {
      key: 'dynamic',
      name: 'Columns',
      members: () => true,
      formatter: helpers.tableFormatters.formatDynamicTo({
        sedol: {
          colType: 'string',
          args: { align: 'left' },
        },
        Value: {
          colType: 'inferred',
          args: { align: 'right', roundDigits: 2, commasOnThousands: true },
        },
        other: {
          colType: 'inferred',
        },
      }),
      cleanNameFormatter: (key) => key,
      nameFormatter: (key) => key,
      filter: 'inferred',
    },
  ] as ts.types.components.dataGrid.ColumnsData,
};
