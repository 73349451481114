import { hooks, mui, React, ts, ui } from '_core';

import { DraftParams } from './types';

type FormProps = {
  params: DraftParams;
  setParams: (_p: DraftParams) => void;

  backtestStartDate?: string;
  backtestEndDate?: string;
  universeId?: number;
  frequency?: ts.enums.FREQUENCY_ENUM;
};

const Form: React.FC<FormProps> = ({
  params,
  setParams,
  backtestStartDate,
  backtestEndDate,
  universeId,
  frequency,
}) => {
  const uiStyles = hooks.useUiStyles();

  const [firstDate, setFirstDate] = React.useState(params.comparison_date_1 || backtestStartDate);
  const [secondDate, setSecondDate] = React.useState(params.comparison_date_2 || backtestEndDate);

  const [componentErrors, setComponentErrors] = React.useState([]);

  React.useEffect(() => {
    const data = {
      comparison_date_1: firstDate,
      comparison_date_2: secondDate,
      component_errors: componentErrors,
    };

    setParams({ ...params, ...data });
  }, [firstDate, secondDate, componentErrors]);

  const rangeValue = React.useMemo(() => {
    return [firstDate, secondDate] as string[];
  }, [firstDate, secondDate]);

  return (
    <mui.core.Box sx={uiStyles.widgetEditMode}>
      <ui.SmartDateRangeSelector
        value={rangeValue}
        onChange={(v) => {
          setFirstDate(v[0]);
          setSecondDate(v[1]);
        }}
        minDate={backtestStartDate}
        maxDate={backtestEndDate}
        universeId={universeId}
        frequency={frequency}
        setExternalError={(e) => setComponentErrors(e ? [e] : [])}
      />
    </mui.core.Box>
  );
};

Form.defaultProps = {
  backtestStartDate: null,
  backtestEndDate: null,
  universeId: null,
  frequency: null,
};

export default Form;
