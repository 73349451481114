import { ts } from '_core';

import { DraftParams, INTERACTION_PARAMS } from './types';

export default [
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.UNIVERSE_TOTAL_COVERAGE,
    form: false,
    title: 'Total Coverage',
    neededFiles: ['all'],
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.UNIVERSE],
    defaultParams: { by: 'all' } as DraftParams,
    interactionParams: Object.values(INTERACTION_PARAMS),

    helpLabel: 'lwaaz1f7',
  },
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.UNIVERSE_COVERAGE_BY_COUNTRY,
    form: false,
    title: 'Coverage by Quotation Country',
    neededFiles: ['quotation_country'],
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.UNIVERSE],
    defaultParams: { by: 'quotation_country' } as DraftParams,
    interactionParams: Object.values(INTERACTION_PARAMS),

    helpLabel: 'a8kbn9pb',
  },
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.UNIVERSE_COVERAGE_BY_CURRENCY,
    form: false,
    title: 'Coverage by Currency',
    neededFiles: ['currency'],
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.UNIVERSE],
    defaultParams: { by: 'currency' } as DraftParams,
    interactionParams: Object.values(INTERACTION_PARAMS),

    helpLabel: 'sa0aoof0',
  },
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.UNIVERSE_COVERAGE_BY_ASSET_TYPE,
    form: false,
    title: 'Coverage by Asset Type',
    neededFiles: ['asset_type'],
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.UNIVERSE],
    defaultParams: { by: 'asset_type' } as DraftParams,
    interactionParams: Object.values(INTERACTION_PARAMS),

    helpLabel: 'jneapnpq',
  },
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.UNIVERSE_COVERAGE_BY_ISSUER_COUNTRY,
    form: false,
    title: 'Coverage by Issuer Country',
    neededFiles: ['issuer_country'],
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.UNIVERSE],
    defaultParams: { by: 'issuer_country' } as DraftParams,
    interactionParams: Object.values(INTERACTION_PARAMS),

    helpLabel: 'q9e4cga6',
  },
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.COVERAGE_BY_RBICS_LVL_ONE,
    form: false,
    title: 'Coverage by Sector',
    neededFiles: ['rbics_l1_id_code'],
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.UNIVERSE],
    defaultParams: { by: 'rbics_l1_id_code' } as DraftParams,
    interactionParams: Object.values(INTERACTION_PARAMS),

    helpLabel: '1tutt7q0',
  },
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.COVERAGE_BY_RBICS_LVL_TWO,
    form: false,
    title: 'Coverage by Sub Sector',
    neededFiles: ['rbics_l2_id_code'],
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.UNIVERSE],
    defaultParams: { by: 'rbics_l2_id_code' } as DraftParams,
    interactionParams: Object.values(INTERACTION_PARAMS),

    helpLabel: 'esn7zhp5',
  },
] satisfies ts.types.analysis.WidgetConfig[];
