import { _, ts } from '_core';

import { getCountryName, getCurrencyName, getIndustryName } from '../../common/utils/get-series-name';

export const getRbicsChildren = (
  parentType: keyof ts.types.industries.RBicsData,
  parentValue: string,
  rbicsData: ts.StoreState['resources']['rbics_data']
) => {
  let childrenType: keyof typeof rbicsData = 'levelFour';
  if (parentType == 'levelTwo') childrenType = 'levelThree';

  const elems = rbicsData[childrenType];
  return elems.filter((el) => _.startsWith(el.value, parentValue));
};

/**
 * Prepares and formats names for universe coverage data based on different classification types
 *
 * @param data - Table data containing coverage information
 * @param by - Classification type enum (country, currency, RBICS level etc)
 * @param resources - Store resources containing reference data for name mappings
 * @returns Formatted table data with proper display names for each classification
 *
 * @example
 * const data = [{date: '2023-01-01', US: 100, GB: 50}];
 * const formatted = prepareNames(data, UNIVERSE_COVERAGE_BY_ENUM.QUOTATION_COUNTRY, resources);
 * // Returns: [{date: '2023-01-01', 'United States': 100, 'United Kingdom': 50}]
 */
export const prepareNames = (
  data: ts.types.widgets.TableData,
  by:
    | ts.enums.REPORT_ENUMS.UNIVERSE_COVERAGE_BY_ENUM
    | ts.enums.REPORT_ENUMS.UNIVERSE_COVERAGE_BY_GICS_ENUM
    | ts.enums.REPORT_ENUMS.UNIVERSE_COVERAGE_BY_RBICS_ENUM,
  resources: ts.StoreState['resources']
) => {
  return data.map((row) => {
    const newRow: ts.types.widgets.TableData[number] = {};
    Object.keys(row).forEach((key) => {
      let newKey = key;
      if (key === 'date') {
        newRow[key] = row[key];
        return;
      }
      if (key != 'other') {
        if (by == ts.enums.REPORT_ENUMS.UNIVERSE_COVERAGE_BY_ENUM.QUOTATION_COUNTRY) newKey = getCountryName(key);
        if (by == ts.enums.REPORT_ENUMS.UNIVERSE_COVERAGE_BY_ENUM.CURRENCY) newKey = getCurrencyName(key, resources);
        if (by == ts.enums.REPORT_ENUMS.UNIVERSE_COVERAGE_BY_ENUM.ISSUER_COUNTRY) newKey = getCountryName(key);
        if (
          by == ts.enums.REPORT_ENUMS.UNIVERSE_COVERAGE_BY_ENUM.RBICS_L1 ||
          by == ts.enums.REPORT_ENUMS.UNIVERSE_COVERAGE_BY_ENUM.RBICS_L2 ||
          by == ts.enums.REPORT_ENUMS.UNIVERSE_COVERAGE_BY_RBICS_ENUM.RBICS_L3 ||
          by == ts.enums.REPORT_ENUMS.UNIVERSE_COVERAGE_BY_RBICS_ENUM.RBICS_L4
        )
          newKey = getIndustryName(key, by, resources);

        newRow[_.startCase(newKey)] = row[key] ?? 0;
      } else {
        newRow[key] = row[key] ?? 0;
      }
    });
    return newRow;
  });
};

export function getBuckets(data: ts.types.widgets.TableData): string[] {
  if (data.length === 0) return [];
  return Object.keys(data[0]).filter((key) => key !== 'date');
}

export function getTopBucketsAndMergeRest(data: ts.types.widgets.TableData, top: number): ts.types.widgets.TableData {
  if (data.length === 0) return [];

  const buckets = getBuckets(data);
  const lastRow = data[data.length - 1];

  // Get top buckets based on the last row
  const topBuckets = Object.entries(lastRow)
    .filter(([key]) => buckets.includes(key))
    .sort(([, a], [, b]) => Number(b) - Number(a))
    .slice(0, top)
    .map(([key]) => key);

  // Get excluded buckets
  const excludedBuckets = buckets.filter((bucket) => !topBuckets.includes(bucket));

  // Process data
  const newData = data.map((row) => {
    const newRow: ts.types.widgets.TableData[number] = { date: row.date };

    // Assign top buckets
    topBuckets.forEach((bucket) => {
      newRow[bucket] = row[bucket];
    });

    // Calculate 'other' bucket
    if (excludedBuckets.length > 0) {
      newRow['Other'] = excludedBuckets.reduce((sum, bucket) => sum + (Number(row[bucket]) || 0), 0);
    }

    return newRow;
  });

  return newData;
}
