import { config, hooks, mui, React, ui } from '_core';

import { DraftParams } from './types';

type FormProps = {
  params: DraftParams;
  setParams: (_p: DraftParams) => void;

  startDate?: string;
  endDate?: string;
  universeId?: number;
};

const Form: React.FC<FormProps> = ({
  params,
  setParams,
  startDate,
  endDate,

  universeId,
}): React.ReactElement => {
  const uiStyles = hooks.useUiStyles();

  const [snapshotDate, setSnapshotDate] = React.useState(params.preview_date);

  const [componentErrors, setComponentErrors] = React.useState([]);

  React.useEffect(() => {
    const data = {
      preview_date: snapshotDate,
      component_errors: componentErrors,
    };
    setParams({ ...params, ...data });
  }, [snapshotDate, componentErrors]);

  return (
    <mui.core.Box sx={uiStyles.widgetEditMode}>
      <ui.SmartDateSelector
        label="Snapshot Date"
        value={snapshotDate}
        setValue={(date) => setSnapshotDate(date)}
        minDate={startDate || config.features.start_date}
        maxDate={endDate || config.features.end_date}
        universeId={universeId}
        setExternalError={(e) => setComponentErrors(e ? [e] : [])}
      />
    </mui.core.Box>
  );
};

export default Form;
