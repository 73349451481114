import { _, helpers, ts } from '_core';

import { SignalSetParams } from '@finsera/finsera/dist/resource/signal_set';

import { DraftParams } from './types';
import { mapName } from '../../common/utils/signal-set-utils';

export const validate = (params: DraftParams) => {
  const rhErrors = helpers.validations.validSelectedReturnHorizons(params?.return_horizons);
  if (!_.isEmpty(rhErrors)) return rhErrors;
  if (_.isEmpty(params.analysis_time_series_ids)) return ['You need to add at least one time series.'];
  if (params.analysis_time_series_ids.length > 3) return ['You can only add a max of three time series.'];
  return [];
};

export const prepare = (
  data: ts.types.widgets.TableData,
  resources: ts.StoreState['resources'],
  signalsObj: Record<string, Partial<SignalSetParams>>,
  selectedTimeSeries: ts.types.timeSeries.TimeSeries[]
): Record<string, ts.types.widgets.TableData> => {
  const result: Record<string, ts.types.widgets.TableData> = {};

  data.forEach((entry) => {
    const { date, ...rest } = entry;

    _.forOwn(rest, (value, key) => {
      const [entityKey, metric] = key.includes('|') ? key.split('|') : [key, key];

      const foundTs = selectedTimeSeries.find((ts) => ts.handle === entityKey);

      if (foundTs) {
        const label = helpers.resourcesUtils.getResourceLabel(foundTs);

        _.forEach(result, (tabEntries) => {
          let dateEntry = _.find(tabEntries, { date });

          if (!dateEntry) {
            dateEntry = { date };
            tabEntries.push(dateEntry);
          }

          dateEntry[label] = value;
        });
      } else {
        const mappedEntity = mapName(entityKey, resources, signalsObj);

        if (!result[metric]) {
          result[metric] = [];
        }

        const dateEntry = _.find(result[metric], { date });

        if (dateEntry) {
          dateEntry[mappedEntity] = value;
        } else {
          result[metric].push({ date, [mappedEntity]: value });
        }
      }
    });
  });

  return result;
};
