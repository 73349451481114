import { am5, am5xy } from '@local/finsera-core/src/libs';

export const createAxes = ({
  root,
  chart,
  showEndValueLabel,
}: {
  root: am5.Root;
  chart: am5xy.XYChart;
  showEndValueLabel?: boolean;
}) => {
  // Create axes
  const xAxis = chart.xAxes.push(
    am5xy.DateAxis.new(root, {
      baseInterval: { timeUnit: 'day', count: 1 },
      renderer: am5xy.AxisRendererX.new(root, {
        minorGridEnabled: true,
      }),
      groupData: true,
      maxDeviation: 0,
    })
  );

  if (showEndValueLabel) {
    xAxis.setAll({
      width: chart.width() - 130,
    });
  }

  xAxis.get('renderer').labels.template.setAll({
    fontSize: 11,
    textAlign: 'center',
  });

  const yAxis = chart.yAxes.push(
    am5xy.ValueAxis.new(root, {
      renderer: am5xy.AxisRendererY.new(root, {}),
      extraMin: 0.1,
      extraMax: 0.1,
    })
  );

  yAxis.get('renderer').labels.template.setAll({
    fontSize: 11,
  });

  return { xAxis, yAxis };
};
