import { _, helpers, React, ts, ui, useSelector } from '_core';

import { prepareNames } from '../universe-coverage-by-rbics/helpers';

type ChartProps = {
  id: string;
  readOnly: boolean;
  widgetKey: string;
  loadWidgetData: (_payload: ts.types.widgets.WidgetGetDataParams) => Promise<ts.types.widgets.WidgetGetDataResponse>;
  file: string;
  fullScreen: boolean;
  visualization: 'count' | 'percent';
  by: ts.enums.REPORT_ENUMS.UNIVERSE_COVERAGE_BY_ENUM;
  title: string;
  subtitle?: string;
  setExternalEmbed: (_json: ts.types.analysis.EmbedChartData) => void;
};

const Chart: React.FC<ChartProps> = ({
  id,
  readOnly,
  loadWidgetData,
  file,
  fullScreen,
  visualization,
  by,
  title,
  subtitle,
  setExternalEmbed,
}) => {
  const [data, setData] = React.useState<ts.types.widgets.TableData>(null);
  const [alert, setAlert] = React.useState<{ type: 'error' | 'warning'; message: string } | null>(null);

  const resources = useSelector((state) => state.resources);

  const loadData = async () => {
    try {
      const response = await loadWidgetData({
        data: {
          file,
          values_filter: [
            {
              column: 'monthly_frequency',
              value: visualization == 'percent',
              operator: 'is',
              column_type: ts.types.components.dataGrid.COLUMNS_TYPE_ENUM.BOOLEAN,
            },
          ],
        },
      });
      if (response) {
        const dataWithoutFrequency = _.map(response.data, (obj) => _.omit(obj, 'monthly_frequency'));

        setData(prepareNames(dataWithoutFrequency, by, resources));
      }
    } catch (err) {
      setAlert({
        type: 'error',
        message: helpers.parseApiError(err as ts.types.common.ApiError),
      });
    }
  };

  React.useEffect(() => {
    setData(null);
    setExternalEmbed(null);

    if (file) {
      loadData();
    }

    return () => setData(null);
  }, [file, visualization]);

  if (_.isNil(data)) {
    return <ui.CenteredLoader label="Loading data..." />;
  }

  if (visualization == 'percent') {
    return (
      <ui.StackedChart
        id={id}
        data={data}
        fullScreen={fullScreen}
        readOnly={readOnly}
        alert={alert}
        exportTitle={title}
        exportSubTitle={subtitle}
        setExternalEmbed={setExternalEmbed}
        yAxisLabel="Percentage Coverage"
        xAxisLabel="Time"
      />
    );
  }

  return (
    <ui.LineChart
      id={id}
      data={data}
      fullScreen={fullScreen}
      readOnly={readOnly}
      alert={alert}
      exportTitle={title}
      exportSubTitle={subtitle}
      setExternalEmbed={setExternalEmbed}
      yAxisLabel="Asset Count"
      numberFormat="#"
      xAxisLabel="Time"
    />
  );
};

export default Chart;
