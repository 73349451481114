import _ from 'lodash';

import { DATA_GRID_TYPE_ENUM } from '../../../enums';

export const toFixedValue = (x: any): string => {
  if (Math.abs(x) < 1.0) {
    const e = parseInt(x.toString().split('e-')[1]);
    if (e) {
      x *= Math.pow(10, e - 1);
      x = '0.' + new Array(e).join('0') + x.toString().substring(2);
    }
  } else {
    let e = parseInt(x.toString().split('+')[1]);
    if (e > 20) {
      e -= 20;
      x /= Math.pow(10, e);
      x += new Array(e + 1).join('0');
    }
  }
  return x;
};

export const numericField = (val: number | string, multiplier?: number, divider?: number) => {
  if (val == '') return null;

  let newValue = +val;

  if (multiplier) newValue = +val * multiplier;
  if (divider) newValue = +val / divider;

  return newValue;
};

export const numericValue = (val: number | string, multiplier?: number, divider?: number, precision = 2) => {
  if (_.isNil(val)) return '';

  let newValue = toFixedValue(val);

  if (multiplier) newValue = String(_.round(+val / multiplier, precision));
  if (divider) newValue = String(_.round(+val * divider, precision));

  return newValue;
};

export const pickByNestedObj = <T>(obj: T, prop: any): any => {
  // If array
  if (_.isArray(obj)) return obj.map((el) => pickByNestedObj(el, prop));

  // If Object
  if (_.isObject(obj)) {
    const localObj: Record<string, any> = _.pickBy(obj, prop);
    Object.keys(localObj).forEach((key) => {
      localObj[key] = pickByNestedObj(localObj[key], prop);
    });
    return localObj;
  }

  return obj;
};

export const testDomains = (domain: string) => {
  return /^([a-zA-Z0-9][a-zA-Z0-9-_]*\.)*[a-zA-Z0-9]*[a-zA-Z0-9-_]*[[a-zA-Z0-9]+$/.test(domain);
};

export const checkIsNumber = (value: number | string) => {
  if (value == null || (!value && value !== 0) || typeof value == 'boolean') return false;
  const isNumber = +value;
  return !_.isNaN(isNumber);
};

export const getObjectDiff = <T extends Record<string, any | any[]>>(updated: T, base: T, addMissing = false) => {
  const changes = (object: T, base: T) => {
    return _.transform(object, (result: Record<string, unknown>, value, key) => {
      // If the key does not exist in base, add it to the result
      if (base) {
        if (addMissing && _.isObject(base) && !(key in base)) {
          result[key] = value;
        } else if (!_.isEqual(value, base[key])) {
          if (_.isArray(value) && _.isObject(value[0])) {
            result[key] = value.flatMap((elem, index) => changes(elem, base[key][index]));
          } else if (_.isObject(value) && _.isObject(base[key])) {
            const tempRes = changes(value as T, base[key] as T);
            if (!_.isEmpty(tempRes)) {
              result[key] = tempRes;
            }
          } else {
            result[key] = value;
          }
        }
      }
    });
  };

  return changes(updated, base);
};

export const getObjectPaths = <T extends Record<string, any>>(obj: T, parentKey = ''): string[] => {
  return _.flatMap(obj, (value, key) => {
    const currentPath = parentKey ? `${parentKey}.${key}` : key;

    if (_.isObject(value) && !Array.isArray(value) && !_.isEmpty(value)) {
      // If value is an object and not an empty array, recursively call getObjectPaths
      return getObjectPaths(value, currentPath);
    }

    if (Array.isArray(value) && (value as any[]).every((elem) => _.isObject(elem))) {
      // If value is an array and every element is an object, recursively call getObjectPaths
      return (value as any[]).flatMap((elem, index) => getObjectPaths(elem, `${currentPath}[${index}]`));
    }

    // Otherwise, return the currentPath as a single-element array
    return currentPath;
  });
};

export const incBy = <TArr extends Record<string, any>>(arr: TArr[], key: string, increment?: number): TArr[] => {
  const toIncrementBy = increment || 1;
  return _.map(arr, (el: TArr) => ({ ...el, [key]: el[key] + toIncrementBy }));
};

const checkIfArrSorted = <TArr>(arr1: TArr[], arr2: TArr[]): boolean => {
  let isSorted = true;

  for (const idx in arr1) {
    if (!_.isEqual(arr1[idx], arr2[idx])) {
      isSorted = false;
      break;
    }
  }

  return isSorted;
};

export const compareArr = <TArr>(
  toCompareArr: TArr[],
  comparedArr: TArr[],
  orderBy: (_v: TArr) => string
): [boolean, 'asc' | 'desc'] => {
  // Check if it is ordered by ascending
  let sortedCompareArr = _.orderBy(toCompareArr, orderBy, 'asc');
  if (checkIfArrSorted(sortedCompareArr, comparedArr)) return [true, 'asc'];

  // Check if it is ordered by descending
  sortedCompareArr = _.orderBy(toCompareArr, orderBy, 'desc');
  if (checkIfArrSorted(sortedCompareArr, comparedArr)) return [true, 'desc'];

  return [false, null];
};

// This is needed because Date.parse() accepts values like '7.15%' as a valid date,
// but with this, we are matching any letters or invalid symbols in the string
export const isValidDate = (value: string) => {
  const timestamp = Date.parse(value as string);
  return !isNaN(timestamp) && _.isEmpty((value as string).match(/[a-zA-Z%!#$]/));
};

export const inferType = (value: string | number | boolean | Date): DATA_GRID_TYPE_ENUM => {
  if (typeof value == DATA_GRID_TYPE_ENUM.BOOLEAN) return DATA_GRID_TYPE_ENUM.BOOLEAN;

  if (!isNaN(value as any) && !(typeof value == 'object')) {
    if (Number.isInteger(parseFloat(value as any))) return DATA_GRID_TYPE_ENUM.INTEGER;
    return DATA_GRID_TYPE_ENUM.NUMBER;
  }

  // Date as value
  if (value instanceof Date) return DATA_GRID_TYPE_ENUM.DATE;
  // Date as string
  if (isValidDate(value as string)) return DATA_GRID_TYPE_ENUM.DATE;

  return DATA_GRID_TYPE_ENUM.STRING;
};

export const inferArrayType = (arr: any[]): DATA_GRID_TYPE_ENUM => {
  let arrType = DATA_GRID_TYPE_ENUM.STRING;

  for (const el of arr) {
    const localArrType = inferType(el);

    // If  we have a number, but then we encounter a boolean
    // we need to stay as number
    if (arrType == DATA_GRID_TYPE_ENUM.NUMBER && localArrType == DATA_GRID_TYPE_ENUM.BOOLEAN) {
      arrType = DATA_GRID_TYPE_ENUM.NUMBER;
    } else arrType = localArrType;

    // If string there can't be a change on the type
    if (arrType == DATA_GRID_TYPE_ENUM.STRING) break;
  }
  return arrType;
};

export const formatNumberWithSuffix = (value: number, args: number) => {
  const suffixes = ['K', 'M', 'B', 'T', 'P', 'E'];

  if (!value) {
    return null;
  }

  if (Number.isNaN(value)) {
    return null;
  }

  if (value < 1000) {
    return value;
  }

  const exp = Math.floor(Math.log(value) / Math.log(1000));
  const returnValue = (value / Math.pow(1000, exp)).toFixed(args) + suffixes[exp - 1];

  return returnValue;
};
