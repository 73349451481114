import { _, hooks, mui, React, ts, ui } from '_core';

import AddIndustryInclusion from './add-industry-inclusion';
import FactorTable from './factor-table';
import MergeFactor from './merge-factor';

type IndustryFactorItemProps = {
  factor: ts.types.riskModel.ExtendedCategoryFactor;
  editFactor: (_h: string, _nd: Partial<ts.types.riskModel.ExtendedCategoryFactor>) => Promise<void>;
  readOnly: boolean;
  isOpen: boolean;
  setIsOpen: (_b: boolean) => void;
  removeFactor: (_h: string) => void;
  validationDef: ts.types.common.ValidationErrors;
  extendedFactors: ts.types.riskModel.ExtendedCategoryFactor[];
};

const IndustryFactorItem: React.FC<IndustryFactorItemProps> = ({
  factor,
  editFactor,
  readOnly,
  isOpen,
  setIsOpen,
  removeFactor,
  validationDef,
  extendedFactors,
}): React.ReactElement => {
  const theme = mui.styles.useTheme() as mui.core.Theme;
  const uiStyles = hooks.useUiStyles();
  const name = factor.name ? factor.name : _.startCase(factor.handle);
  const [showDeleteConfirm, setShowDeleteConfirm] = React.useState(false);

  const deleteInclusions = (inclusionValues: ts.types.riskModel.ExtendedCategoryFactorInclusions[]) => {
    editFactor(factor.handle, {
      inclusions: (factor.inclusions || []).filter((f) => !inclusionValues.find((el) => el.value == f.value)),
    });
  };

  const addInclusions = (inclusions: ts.types.riskModel.ExtendedCategoryFactorInclusions[]) => {
    editFactor(factor.handle, {
      inclusions: [...(factor.inclusions || []), ...inclusions],
    });
  };

  const replaceInclusions = (inclusions: ts.types.riskModel.ExtendedCategoryFactorInclusions[]) => {
    editFactor(factor.handle, { inclusions });
  };

  return (
    <ui.CollapsableTable
      expanded={isOpen ?? true}
      onChange={() => {
        setIsOpen(!isOpen);
      }}
      title={readOnly && <mui.core.Typography sx={{ fontSize: 12.5, fontWeight: 500 }}>{name}</mui.core.Typography>}
      headerContent={
        !readOnly && (
          <>
            <mui.core.Box
              sx={{
                fontSize: '12.5px',
                lineHeight: '12.5px',
                '& svg': { fontSize: '12px!important' },
              }}
            >
              <ui.EditableField
                value={name}
                setValue={(name) => editFactor(factor.handle, { name })}
                showEditIcon
                disabled={readOnly}
              >
                <strong>{name}</strong>
              </ui.EditableField>
            </mui.core.Box>
            {isOpen && (
              <mui.core.Stack flexDirection="row" gap={1}>
                <AddIndustryInclusion addInclusions={addInclusions} factor={factor} />
                {!_.isEmpty(factor.inclusions) && (
                  <MergeFactor
                    merger={factor}
                    factors={extendedFactors}
                    replaceInclusions={replaceInclusions}
                    removeDestination={removeFactor}
                  />
                )}
                <ui.ConfirmationDialog
                  open={showDeleteConfirm}
                  title="Are you sure you want to delete this factor?"
                  content="This will remove the factor from the definition"
                  onSuccess={() => removeFactor(factor.handle)}
                  onCancel={() => setShowDeleteConfirm(false)}
                  successLabel={'Delete'}
                />
                <mui.core.Tooltip title="Delete" arrow>
                  <mui.core.IconButton
                    size="small"
                    sx={uiStyles.collapseHeaderButton}
                    onClick={() => setShowDeleteConfirm(true)}
                  >
                    <mui.icons.DeleteOutline />
                  </mui.core.IconButton>
                </mui.core.Tooltip>
              </mui.core.Stack>
            )}
          </>
        )
      }
      closedHeader={
        <mui.core.Box
          display="flex"
          alignItems="center"
          gap={2}
          sx={{ fontSize: '0.8rem', color: theme.palette.text.secondary }}
        >
          {' '}
          <mui.core.Chip size="small" color="primary" sx={uiStyles.tabChip} label={factor.inclusions.length} />
          {factor.inclusions.length == 1 ? 'Sub Industry' : 'Sub Industries'} selected
        </mui.core.Box>
      }
    >
      <FactorTable
        factor={factor}
        readOnly={readOnly}
        deleteInclusions={deleteInclusions}
        validationDef={validationDef}
      />
    </ui.CollapsableTable>
  );
};

export default IndustryFactorItem;
