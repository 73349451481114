import { actions, api, React, ts, useDispatch, useSelector } from '_core';

type ProviderProps = {
  children: React.ReactNode;
};

type PreferencesContextTypes = {
  alertError: string;
  closePreferences: () => void;
  handleTabChange: (_event: React.SyntheticEvent<any>, _v: ts.enums.PREFERENCES_TABS_ENUM) => void;
  handleInnerTabChange: (_event: React.SyntheticEvent<any>, _nv: ts.enums.PREFERENCES_INNER_TABS_ENUM) => void;
  preferences: ts.types.preferences.PREFERENCES;
  preferencesOpen: ts.types.preferences.OPEN_PREFERENCES;
  preferencesSelectedInnerTab: ts.enums.PREFERENCES_INNER_TABS_ENUM;
  preferencesSelectedTab: ts.enums.PREFERENCES_TABS_ENUM;
  saveMultiplePreferences: (_np: Record<ts.enums.PREFERENCES_IN_USER_OBJ, any>) => Promise<void>;
  savePreferences: (_pt: ts.enums.PREFERENCES_IN_USER_OBJ, _np: any) => Promise<void>;
  setAlertError: (_v: string) => void;
  preferencesSelectedId: number;
};

const PreferencesContext = React.createContext<PreferencesContextTypes>(null);

// This context provider is passed to any component requiring the context
const Provider: React.FC<ProviderProps> = ({ children }): React.ReactElement => {
  const [alertError, setAlertError] = React.useState<string>();

  const dispatch = useDispatch();

  const preferences = useSelector((state) => state.ui.preferences);
  const preferencesOpen = useSelector((state) => state.ui.preferencesOpen);
  const preferencesSelectedTab = useSelector((state) => state.ui.preferencesSelectedTab);
  const preferencesSelectedInnerTab = useSelector((state) => state.ui.preferencesSelectedInnerTab);
  const preferencesSelectedId = useSelector((state) => state.ui.preferencesSelectedId);

  const handleTabChange = (_event: React.SyntheticEvent<any>, newValue: ts.enums.PREFERENCES_TABS_ENUM) => {
    setAlertError(null);
    dispatch(actions.ui.setPreferencesSelectedTab(newValue));
  };

  const handleInnerTabChange = (_event: React.SyntheticEvent<any>, newValue: ts.enums.PREFERENCES_INNER_TABS_ENUM) => {
    setAlertError(null);
    dispatch(actions.ui.setPreferencesSelectedInnerTab(newValue));
  };

  const savePreferences = async (preferenceType: ts.enums.PREFERENCES_IN_USER_OBJ, newSubPreferences: any) => {
    setAlertError(null);
    const newPreferences = { ...preferences };
    newPreferences[preferenceType] = newSubPreferences;
    try {
      await api.users.updateMe({ preferences: newPreferences });
    } catch (e) {
      setAlertError('Unable to update preferences. Try again later.');
    }
    dispatch(actions.ui.updatePreferences(newPreferences));
  };

  const saveMultiplePreferences = async (newPref: Record<ts.enums.PREFERENCES_IN_USER_OBJ, any>) => {
    setAlertError(null);
    const newPreferences = { ...preferences, ...newPref };

    try {
      await api.users.updateMe({ preferences: newPreferences });
    } catch (e) {
      setAlertError('Unable to update preferences. Try again later.');
    }
    dispatch(actions.ui.updatePreferences(newPreferences));
  };

  const closePreferences = () => {
    setAlertError(null);
    dispatch(actions.ui.setPreferencesOpen({ open: false }));
  };

  return (
    <PreferencesContext.Provider
      value={{
        alertError,
        closePreferences,
        handleInnerTabChange,
        handleTabChange,
        preferences,
        preferencesOpen,
        preferencesSelectedId,
        preferencesSelectedInnerTab,
        preferencesSelectedTab,
        saveMultiplePreferences,
        savePreferences,
        setAlertError,
      }}
    >
      {children}
    </PreferencesContext.Provider>
  );
};

const PreferencesContextProvider = Provider;

export { PreferencesContext };
export { PreferencesContextProvider };
