import { React, ts, useHistory } from '_core';

import BrowserList from './browser-list';
import Form from './form';
import SingleAnalysis from './single-analysis';
import { DraftParams, Params } from './types';

type BrowserProps = {
  id: string;
  mode: ts.enums.CHART_MODE_ENUM;
  context: ts.types.signalSet.SignalSetAnalysisContext;
  jobId: number;
  params: DraftParams;
  setParams: (_p: DraftParams) => void;
  jobCreatedAt?: string;
};

const Browser: React.FC<BrowserProps> = ({ id, context, jobId, params, setParams, mode, jobCreatedAt }) => {
  const signals = React.useMemo(() => context.signals, [context]);
  const startDate = React.useMemo(() => context.start_date, [context]);
  const endDate = React.useMemo(() => context.end_date, [context]);
  const basePath = React.useMemo(() => `/signal_sets/${context.signal_set_id}/g/browser/${id}`, [context, id]);

  const history = useHistory();

  for (const signal of signals) {
    const key = signal.id + jobId;
    if (history.location.pathname.includes(`${basePath}/single/${signal.id}`))
      return (
        <SingleAnalysis
          key={key}
          signalMetadata={signal}
          basePath={basePath}
          defaultId={params.report_default_id}
          startDate={startDate}
          endDate={endDate}
          jobId={jobId}
        />
      );
  }

  const chart = () => (
    <BrowserList
      signalsMetadata={signals}
      basePath={basePath}
      params={params as Params}
      setParams={setParams}
      startDate={startDate}
      endDate={endDate}
      jobCreatedAt={jobCreatedAt}
    />
  );

  return <>{mode == 'edit' ? <Form params={params} setParams={setParams} /> : chart()}</>;
};

export default Browser;
