import React from 'react';

import apis from '../../api/scrud';
import { pluralize } from '../../helpers';
import { _, moment, useSelector } from '../../libs';
import { BaseStore } from '../../types';
import * as types from '../../types';

export type ProviderProps = {
  _position?: string;

  resourceId: number;
  resourceTable: 'strategies';
  depsTimestamp: string;

  configurationObject: Record<
    string,
    (
      _newAttribute: unknown,
      _oldAttribute: unknown,
      _diffObject: unknown,
      _path: string,
      _resources: BaseStore['resources']
    ) => string
  >;

  children?: React.ReactNode;
};

type AuditLogsContextTypes = {
  loading: boolean;
  paginatinationLoading: boolean;
  logs: types.AuditLogs.AuditLog[];
  resource: string;
  allLoaded: boolean;
  logChanges: Record<string, string[]>;
  loadLogs: () => Promise<void>;
};

const PAGINATION_LIMIT = 10;

const AuditLogsContext = React.createContext<AuditLogsContextTypes>(null);

// This context provider is passed to any component requiring the context
const Provider: React.FC<ProviderProps> = ({
  resourceId,
  resourceTable,
  depsTimestamp,
  configurationObject,
  children,
}): React.ReactElement => {
  const [logs, setLogs] = React.useState([] as types.AuditLogs.AuditLog[]);
  const [logChanges, setLogChanges] = React.useState<Record<string, string[]>>({});
  const [loading, setLoading] = React.useState(true);
  const [paginatinationLoading, setPaginatinationLoading] = React.useState(false);

  const allLoaded = React.useRef<boolean>(false);
  const offset = React.useRef<number>(0);

  const resources = useSelector((state: BaseStore) => state.resources);
  const loadLogs = async () => {
    try {
      setPaginatinationLoading(true);
      // adding 1 second to the depsTimestamp
      // This is implemented to not show possible changes done to this resource
      // that could be done on another session (until websockets or polling is implemented)
      // The one second is because the depsTimestamp could have a small diff due to
      // the log being created on a post hook
      const ts = moment(depsTimestamp).add(1, 'second').toDate();
      const resp = await apis.audit_logs.search({
        query: [
          '$and',
          ['entity_name_ref', '=', resourceTable],
          ['entity_id_ref', '=', resourceId],
          ['created_at', '<=', ts],
        ],
        order_by: [{ column: 'created_at', sort: 'desc' }],
        limit: PAGINATION_LIMIT,
        offset: offset.current,
      });

      allLoaded.current = offset.current + resp.data.length == resp.pagination.total;
      offset.current += PAGINATION_LIMIT;

      const localLogChanges = {} as typeof logChanges;

      let newLogs = [...logs, ...resp.data];

      newLogs = newLogs.filter((log: types.AuditLogs.AuditLog) => {
        const changes = getChanges(log);
        localLogChanges[log.id.toString()] = changes;
        return !_.isEmpty(changes) || log.method !== 'update';
      });

      setLogs(newLogs);
      setLogChanges(localLogChanges);
      setLoading(false);
      setPaginatinationLoading(false);
    } catch (err) {
      setLoading(false);
      setPaginatinationLoading(false);
    }
  };

  React.useEffect(() => {
    loadLogs();
  }, []);

  const getChanges = (log: types.AuditLogs.AuditLog) => {
    const redeableChanges = [] as any[];

    if (!_.isNil(log.payload['definition']) && !_.isNil(log.old_data)) {
      const logDef = log.payload['definition'];
      const logOldDataDef = log.old_data['definition'];

      const diff = _.getObjectDiff(logDef, logOldDataDef);
      const paths = _.getObjectPaths(diff).filter((path) => !path.includes('.id'));

      const pathsWithoutIndexes = paths.map((path) => path.replace(/\[\d+\]/g, ''));
      pathsWithoutIndexes.forEach((path, idx) => {
        const pathWithIndex = paths[idx];
        const formattedPath = path.split('.').join(' | ');

        const newObj = _.get(diff, pathWithIndex);
        const oldObj = _.get(logOldDataDef, pathWithIndex);
        if (newObj === null && oldObj === null) return;

        const redeableDiff =
          configurationObject[path]?.(newObj, oldObj, logDef, pathWithIndex, resources) ??
          `${formattedPath} changed from ${JSON.stringify(oldObj)} to ${JSON.stringify(newObj)}`;
        redeableChanges.push(redeableDiff);
      });
    }

    return redeableChanges;
  };

  return (
    <AuditLogsContext.Provider
      value={{
        loading,
        paginatinationLoading,
        logs,
        resource: _.startCase(pluralize.singular(resourceTable)),
        allLoaded: allLoaded.current,
        logChanges,
        loadLogs,
      }}
    >
      {children}
    </AuditLogsContext.Provider>
  );
};

const AuditLogsContextProvider = Provider;

export { AuditLogsContext };
export { AuditLogsContextProvider };
