import { _, ts } from '_core';

import { Params } from './types';

// prepareSignalExposureVsTime
const transform = (data: ts.types.widgets.LinearData, hasBenchmark: boolean) => {
  const newData = {} as ts.types.widgets.LinearData;
  Object.keys(data).forEach((key) => {
    if (!key.startsWith('benchmark') || hasBenchmark) {
      const formattedKey = key.replaceAll('=>', ':');
      newData[formattedKey] = data[key];
    }
  });

  return newData;
};

const validate = (params: Params) => {
  if (_.isEmpty(params.analysis_pipelined_signals) || !_.some(params.analysis_pipelined_signals, (el) => el.signal_id))
    return ['Signals is a required field.'];
  return [];
};

export { transform };
export { validate };
