import config from '../../config.local';
const isBrowser = typeof window !== 'undefined' && typeof document !== 'undefined';

const mockWindow = {
  location: {
    protocol: 'http:',
    host: 'localhost',
    href: 'http://localhost'
  }
};

const win = isBrowser ? window : mockWindow;

/**
 * Gets the base path URL for a given subdomain
 * @param subdomain - The subdomain to use in the URL
 * @param onTest - Whether the environment is test/local
 * @returns The complete base path URL
 */
export const getBasePath = (subdomain: string, onTest = false): string => {
  const protocol = win.location.protocol || 'http:';
  const host = win.location.host || 'localhost';
  // If we are on localhost, let's send the subdomain on finseradev
  // Please note that this won't work if the proxy is on stg or prod
  if (host.includes('localhost') || onTest) return `https://${subdomain}.finseradev.net`;
  return `${protocol}//${host}`;
};

/**
 * Gets the current active tab based on URL location
 * @param location - The current URL path
 * @returns The name of the active tab
 */
export const getCurrentTab = (location: string) => {
  let currentTabLocal = '';
  if (location.includes('/dashboard') || location == '' || location == '/') currentTabLocal = 'dashboard';
  if (location.includes('/datasets')) currentTabLocal = 'datasets';
  if (location.includes('/user-management')) currentTabLocal = 'users';
  if (location.includes('/publish')) currentTabLocal = 'publish';
  if (location.includes('/reports')) currentTabLocal = 'jobber';
  if (location.includes('/production')) currentTabLocal = 'production';
  if (location.includes('/finsera-admin')) currentTabLocal = 'finsera-admin';
  if (location.includes('/help')) currentTabLocal = 'help';

  if (!currentTabLocal) currentTabLocal = 'research';

  return currentTabLocal;
};

/**
 * Gets the subdomain from the current URL
 * @returns The subdomain string
 */
export const getSubdomain = () => {
  if (win.location.href.includes('localhost')) {
    const target = process.env.FINSERA_PROXY_API;
    return target.split('.')[0].split('//')[1];
  }
  return win.location.href.split('.')[0].split('//')[1];
};

/**
 * Gets the organization path URL for a given subdomain
 * @param subdomain - The subdomain to use in the URL
 * @returns The complete organization path URL
 */
export const getOrgPath = (subdomain: string): string => {
  if (config.environment === 'local' || config.environment === 'development')
    return `https://${subdomain}.finseradev.net`;
  if (config.environment === 'staging') return `https://${subdomain}.finserastg.net`;

  return `https://${subdomain}.finsera.com`;
};
