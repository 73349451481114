import { React, ts } from '_core';

import Chart from './chart';
import { DraftParams, Params } from './types';

type PortfolioPercentContributionToRiskProps = {
  id: string;
  readOnly: boolean;
  widgetKey: string;
  loadWidgetData: (_payload: ts.types.widgets.WidgetGetDataParams) => Promise<ts.types.widgets.WidgetGetDataResponse>;
  params: Params | DraftParams;
  setParams: (_p: DraftParams) => void;
  widgetPaths: Record<'backtest_factors_contribution_to_total_risk', string>;
  title: string;
  fullScreen: boolean;
};

const PortfolioPercentContributionToRisk: React.FC<PortfolioPercentContributionToRiskProps> = ({
  id,
  readOnly,
  widgetKey,
  loadWidgetData,
  widgetPaths,
  fullScreen,
  title,
}) => {
  const fileKey = React.useMemo(
    () =>
      Object.keys(widgetPaths || {}).find((k) =>
        k.endsWith('_factors_contribution_to_total_risk')
      ) as keyof typeof widgetPaths,
    [widgetPaths]
  );

  return (
    <>
      <Chart
        id={id}
        readOnly={readOnly}
        widgetKey={widgetKey}
        loadWidgetData={loadWidgetData}
        file={widgetPaths[fileKey]}
        fullScreen={fullScreen}
        title={title}
      />
    </>
  );
};

export default PortfolioPercentContributionToRisk;
