import React from 'react';

import api from '../../api';
import { parseApiError } from '../../helpers';
import { mui } from '../../libs';
import * as types from '../../types';
import Button from '../button';
import DialogTitle from '../dialog-title';
import SuccessMessage from '../success-message';

type FeedbackDialogProps = {
  isOpen: boolean;
  handleClose: () => void;
  error: string;
  errorLog: string;
  currentUser: types.user.User;
};

type apiError = {
  responseJSON: any;
};

const ReportErrorDialog: React.FC<FeedbackDialogProps> = ({
  isOpen,
  handleClose,
  error,
  errorLog,
  currentUser,
}): React.ReactElement => {
  const [errorMessage, setErrorMessage] = React.useState('');
  const [report, setReport] = React.useState('');
  const [loadingCreate, setLoadingCreate] = React.useState(false);
  const [formSuccess, setFormSuccess] = React.useState(false);

  const url = window.location.href;
  const userEmail = currentUser.email;
  const browser = navigator.userAgent;

  const theme = mui.styles.useTheme() as mui.core.Theme;

  const handleSubmit = async () => {
    setErrorMessage('');

    // Validation
    if (!report) {
      setErrorMessage('Please provide a description of the issue.');
      return;
    }

    setLoadingCreate(true);

    try {
      const formData = new FormData();
      formData.append('feedback', report);
      formData.append('url', url);
      formData.append('email', userEmail);
      formData.append('browser', browser);
      formData.append('error', error);
      formData.append('error_log', errorLog);

      await api.feedback.create(formData);

      setFormSuccess(true);
    } catch (error) {
      setErrorMessage(parseApiError(error as apiError));
    } finally {
      setLoadingCreate(false);
    }
  };

  const close = () => {
    handleClose();
    setFormSuccess(false);
    setReport('');
  };

  const formContent = () => (
    <>
      <DialogTitle closeAction={close}>Help Us Improve Finsera</DialogTitle>
      <mui.core.DialogContent>
        <mui.core.Box mt={4}>
          <mui.core.TextField
            required
            variant="outlined"
            id="description"
            label="Describe the issue you encountered"
            fullWidth
            value={report}
            multiline
            rows={4}
            onChange={(e) => {
              setReport(e.target.value);
            }}
          />
        </mui.core.Box>

        <mui.core.Box mt={4}>
          <mui.core.Typography variant="body1" fontWeight="fontWeightBold">
            The following information will also be submitted:
          </mui.core.Typography>
          <mui.core.Typography variant="body2" sx={{ marginTop: theme.spacing(1) }}>
            URL: {url}
          </mui.core.Typography>
          <mui.core.Typography variant="body2" sx={{ marginTop: theme.spacing(1) }}>
            Email: {userEmail}
          </mui.core.Typography>
          <mui.core.Typography variant="body2" sx={{ marginTop: theme.spacing(1) }}>
            Browser: {browser}
          </mui.core.Typography>
        </mui.core.Box>
      </mui.core.DialogContent>
      <mui.core.DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          loading={loadingCreate}
          style={{ margin: '0rem 1rem 1rem' }}
          fullWidth
        >
          Submit
        </Button>
      </mui.core.DialogActions>
    </>
  );

  return (
    <>
      <mui.core.Dialog open={isOpen} onClose={close} maxWidth="sm" fullWidth id="report-dialog">
        {errorMessage && (
          <mui.lab.Alert onClose={() => setErrorMessage('')} severity="error">
            {errorMessage}
          </mui.lab.Alert>
        )}
        {formSuccess ? (
          <SuccessMessage closeAction={close}>
            <mui.core.Typography>
              <b>Your report has been submitted successfully. Thank you for your feedback!</b>
            </mui.core.Typography>
          </SuccessMessage>
        ) : (
          <>{formContent()}</>
        )}
      </mui.core.Dialog>
    </>
  );
};

export default ReportErrorDialog;
