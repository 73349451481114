import { _, helpers, React, ts } from '_core';

import { transform } from './helpers';
import ChartWrapper from '../../common/chart-wrapper';
import createLineChart from '../../common/charts/create-line-chart';

type ChartProps = {
  id: string;
  readOnly: boolean;
  widgetKey: string;
  loadWidgetData: (_payload: ts.types.widgets.WidgetGetDataParams) => Promise<ts.types.widgets.WidgetGetDataResponse>;
  definition: ts.types.signal.Lasso;
  file: string;
  fullScreen: boolean;
  title: string;
};

const Chart: React.FC<ChartProps> = ({
  id,
  readOnly,
  widgetKey,
  loadWidgetData,
  file,
  definition,
  fullScreen,
  title,
}) => {
  const [data, setData] = React.useState<ts.types.widgets.TableData>(null);
  const [error, setError] = React.useState<ts.types.common.ApiError>(null);
  const [showLegend, setShowLegend] = React.useState(false);

  const loadData = async () => {
    try {
      const response = await loadWidgetData({
        data: { file },
      });
      if (response) {
        const newData = _.map(response.data, (row) => ({
          ..._.omit(row, 'build_date'),
          date: row.build_date,
        }));
        setData(newData);
      }
    } catch (err) {
      setError(err as ts.types.common.ApiError);
    }
  };

  React.useEffect(() => {
    if (file) loadData();
    return () => setData(null);
  }, [file]);

  React.useEffect(() => {
    if (!_.isNil(data))
      createLineChart({
        id,
        data,
        fullScreen,
        setShowLegend,
        yAxisLabel: 'Signal Weights',
        xAxisLabel: 'Time',
        exportTitle: title,
        prepareData: (linearData) => transform(linearData, definition),
        customDownloadFormatting: (data) => {
          const localData = helpers.csv.transpose(data, 'date', 'var');
          return localData.map((row) => {
            const newRow = { var: row['var'] } as typeof row;
            // Add dates in order
            _.without(Object.keys(row), 'var')
              .reverse()
              .forEach((date) => {
                newRow[date] = row[date];
              });
            return newRow;
          });
        },
      });
  }, [data]);

  return (
    <ChartWrapper
      id={id}
      readOnly={readOnly}
      widgetKey={widgetKey}
      data={data}
      showLegend={showLegend}
      error={error}
      fullScreen={fullScreen}
    />
  );
};

export default Chart;
