import React from 'react';

import { _, mui } from '../libs';

type UiButtonProps = React.ComponentProps<typeof mui.core.Button> & {
  loading?: boolean;
  progressStyle?: React.CSSProperties;
  progressText?: string;
  enableTriggerOnLoad?: boolean;
  component?: string;
  variant?: 'text' | 'outlined' | 'contained' | 'light';
  rounded?: boolean; // Add rounded as a boolean to toggle border radius
};

/**
 * UiButton component for displaying a styled button with loading state
 *
 * @param loading - Whether to show loading spinner
 * @param progressStyle - Style overrides for loading spinner
 * @param progressText - Text to display during loading state
 * @param enableTriggerOnLoad - Whether to keep button clickable during loading
 * @param rounded - Whether to use rounded border radius
 * @param variant - MUI Button variant style
 * @param ...props - Inherited props from MUI Button component
 *
 * @returns A styled button component with optional loading state
 */

const UiButton: React.FC<UiButtonProps> = (props) => {
  const theme = mui.styles.useTheme() as mui.core.Theme;

  const buttonProps = _.omit(props, ['loading', 'children', 'progressText', 'enableTriggerOnLoad', 'rounded']);
  const {
    disabled = false,
    loading = false,
    children,
    enableTriggerOnLoad = false,
    progressStyle = enableTriggerOnLoad && buttonProps.variant === 'contained'
      ? { color: '#fff' }
      : enableTriggerOnLoad
        ? { color: (theme.palette as any).primary.main }
        : { color: '#999' },
    progressText = '',
    rounded = false,
    sx: parentSx = {},
    ...rest
  } = props;
  const disableOnLoading = !enableTriggerOnLoad && loading;

  return (
    <mui.core.Button
      {...buttonProps}
      disabled={!!disabled || disableOnLoading}
      sx={{
        minHeight: '36px',
        borderRadius: rounded ? theme.spacing(4) : theme.spacing(1),
        ...parentSx,
      }}
      {...rest}
    >
      {loading ? (
        <>
          <mui.core.CircularProgress size={12} style={progressStyle} />
          <mui.core.Typography variant="body2" style={{ marginLeft: '6px', fontWeight: '600' }}>
            {progressText}
          </mui.core.Typography>
        </>
      ) : (
        children
      )}
    </mui.core.Button>
  );
};

export default UiButton;
