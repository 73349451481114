// eslint-disable-next-line @typescript-eslint/ban-ts-comment

import { _ } from '../libs';
import { HeatMapColors } from '../types/components/data-grid';
import { HeatMapData } from '../types/widgets';

const BINS_N = 100;

/**
 * Gets a color from a predefined palette based on an index
 * @param index - Index to determine which color to return (defaults to -1)
 * @returns Hex color code string
 */
export const getColor = (index = -1) => {
  const num = Math.abs(index % 10);

  if (num % 10 == 0) return '#68C176';
  if (num % 10 == 1) return '#6BACC3';
  if (num % 10 == 2) return '#5985C1';
  if (num % 10 == 3) return '#8184BB';
  if (num % 10 == 4) return '#A6779D';
  if (num % 10 == 5) return '#D66063';
  if (num % 10 == 6) return '#E5965E';
  if (num % 10 == 7) return '#E8D261';
  if (num % 10 == 8) return '#B7D65A';
  if (num % 10 == 9) return '#EF7975';
};

/**
 * Generates a heat map color based on a value and its maximum
 * @param maximum - Maximum possible value to scale against
 * @param val - Actual value to generate color for
 * @returns RGB color string
 */
export const getHeatColor = (maximum: number, val: number) => {
  const value = val;

  const ratio = Math.abs(value) / maximum;

  let red = 255 * ratio;
  if (red > 255) red = 255;

  let r = 0;
  if (value < 0) r = Math.round(Math.max(0, red));

  let green = 255 * ratio;
  if (green > 255) green = 255;

  let g = 0;
  if (value >= 0) g = Math.round(Math.max(0, green));

  const b = 0;

  return `rgb(${r}, ${g}, ${b})`;
};

/**
 * Calculates the minimum and maximum values for heat map data
 * @param data - Array of heat map data objects
 * @returns Object containing min and max values for heat map
 */
export const getHeatColorBoundaries = (data: HeatMapData) => {
  if (_.isEmpty(data)) return { headMinValue: 0, heatMaxValue: 0 };

  const heatField = 'heatValue' in data[0] ? 'heatValue' : 'value';
  const minValue = _.minBy(data, heatField)[heatField];
  const maxValue = _.maxBy(data, heatField)[heatField];

  const maxAbsoluteValue = _.max([Math.abs(minValue), Math.abs(maxValue)]);

  let heatMinValue = 0;
  let heatMaxValue = 0;

  if (minValue < 0) heatMinValue = maxAbsoluteValue * -1;
  if (maxValue > 0) heatMaxValue = maxAbsoluteValue;

  return { heatMinValue, heatMaxValue };
};

/**
 * Calculates the minimum and maximum values for heat map data using 2 standard deviations
 * @param data - Array of heat map data objects
 * @returns Object containing min and max values for heat map based on 2 standard deviations
 */
export const getHeatColorBoundariesStdDev = (data: HeatMapData) => {
  if (_.isEmpty(data)) return { heatMinValue: 0, heatMaxValue: 0 };

  const heatField = 'heatValue' in data[0] ? 'heatValue' : 'value';
  const values = data.map((d) => d[heatField]);

  // Calculate standard deviation
  const mean = _.mean(values);
  const squareDiffs = values.map((value) => Math.pow(value - mean, 2));
  const stdDev = Math.sqrt(_.mean(squareDiffs));

  // Set bounds at 2 standard deviations, centered at 0
  const maxAbsValue = 2 * stdDev;
  const heatMinValue = -maxAbsValue;
  const heatMaxValue = maxAbsValue;

  return { heatMinValue, heatMaxValue };
};

/**
 * Generates an array of interpolated colors between start, middle and end colors
 * @param param0 - Object containing start, end and middle colors as RGB arrays
 * @param n - Number of color steps to generate (defaults to BINS_N)
 * @returns Array of RGB color arrays
 */
export const interpolateColors = (
  { startColor = [219, 110, 110], endColor = [120, 214, 145], middleColor = [255, 255, 255] }: HeatMapColors,
  n = BINS_N
) => {
  const colorArray = [];
  const firstHalfSteps = Math.floor(n / 2);
  const secondHalfSteps = n - firstHalfSteps;

  for (let i = 0; i < firstHalfSteps; i++) {
    const ratio = i / (firstHalfSteps - 1);
    const easedRatio = Math.pow(ratio, 2);
    const r = Math.round(startColor[0] + easedRatio * (middleColor[0] - startColor[0]));
    const g = Math.round(startColor[1] + easedRatio * (middleColor[1] - startColor[1]));
    const b = Math.round(startColor[2] + easedRatio * (middleColor[2] - startColor[2]));
    colorArray.push([r, g, b]);
  }

  for (let i = 0; i < secondHalfSteps; i++) {
    const ratio = i / (secondHalfSteps - 1);
    const easedRatio = 1 - Math.pow(1 - ratio, 2);
    const r = Math.round(middleColor[0] + easedRatio * (endColor[0] - middleColor[0]));
    const g = Math.round(middleColor[1] + easedRatio * (endColor[1] - middleColor[1]));
    const b = Math.round(middleColor[2] + easedRatio * (endColor[2] - middleColor[2]));
    colorArray.push([r, g, b]);
  }

  return colorArray;
};

/**
 * Assigns a value to a color bin based on its position between min and max values
 * @param value - Value to assign to a bin
 * @param param1 - Object containing minimum and maximum values
 * @param n - Number of bins (defaults to BINS_N)
 * @returns Bin index number
 */
export const assignValueToColorBin = (value: number, { minValue, maxValue }: HeatMapColors, n = BINS_N) => {
  if (value > maxValue) return n - 1;
  if (value < minValue) return 0;

  const binWidth = (maxValue - minValue) / n;
  const binIndex = Math.min(Math.floor((value - minValue) / binWidth), n - 1);

  return binIndex;
};
