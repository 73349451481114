import React from 'react';

import ErrorModal from './error-modal';
import * as enums from '../../enums';
import { jobs } from '../../helpers';
import { _, moment, mui } from '../../libs';
import * as types from '../../types';

const JobBox = (props: mui.core.BoxProps & { hideBorder?: boolean }) => {
  const theme = mui.styles.useTheme() as mui.core.Theme;

  return (
    <mui.core.Box
      {...props}
      sx={{
        margin: '4px',
        padding: '0 8px',
        borderRight: props.hideBorder ? 'none' : `1px solid ${(theme.palette as any).gray.main}`,
        display: 'flex',
        flexDirection: 'column',
        ...props.sx,
      }}
    >
      {props.children}
    </mui.core.Box>
  );
};

type JobStatusProps = {
  job: types.job.Job;
  reportTitle?: string;
  hideBorder?: boolean;
};

/**
 * Component that displays job status information including completion time, duration, and any errors/warnings
 *
 * @param job - The job object containing status information
 * @param reportTitle - Optional title of the report being generated
 * @param hideBorder - Optional flag to hide the border styling
 *
 * @returns Rendered component showing job status details
 */
const JobStatus: React.FC<JobStatusProps> = ({ job, reportTitle, hideBorder }): React.ReactElement => {
  const theme = mui.styles.useTheme() as mui.core.Theme;

  const [errorModal, setErrorModal] = React.useState<enums.JOB_ERROR_SEVERITY_ENUM>();
  const [generateAlert, setGenerateAlert] = React.useState<{ title: string; content: string }>();

  const completedAt = job?.completed_at ? moment(job.completed_at) : null;
  const startedAt = job?.started_at ? moment(job.started_at) : null;
  const duration = job?.completed_at ? completedAt.diff(startedAt) : null;
  const durationFormatted = moment(duration).format('HH:mm:ss');

  React.useEffect(() => {
    if (job) {
      const statusObj = jobs.jobStatus(job, true, reportTitle) as types.job.JobStatusResponse;
      const { status } = statusObj;

      if (status === enums.JOB_STATUS_ENUM.RUNNING) {
        setGenerateAlert({
          title: `Started ${moment(job.created_at).fromNow()}`,
          content: `${statusObj.message}`,
        });
      } else if (status === enums.JOB_STATUS_ENUM.SUCCESS) {
        setGenerateAlert({
          title: 'Last Generated',
          content: `${completedAt?.local()?.format?.('MM/DD/YYYY, hh:mm A')}`,
        });
      } else if (status === enums.JOB_STATUS_ENUM.ERROR) {
        setGenerateAlert({ title: 'Generating...', content: '' });
      } else {
        setGenerateAlert({ title: '', content: '' });
      }
    }
  }, [job]);

  const getError = () => {
    if (job?.error) {
      return (
        <mui.core.Box alignItems="center">
          <mui.core.Typography variant="caption" color="textSecondary" fontWeight="fontWeightMedium">
            Last generation
          </mui.core.Typography>
          <mui.core.Chip
            onClick={() => {
              setErrorModal(enums.JOB_ERROR_SEVERITY_ENUM.ERROR);
            }}
            color="error"
            size="small"
            icon={<mui.icons.ErrorOutline />}
            label="Failed"
            sx={{ marginLeft: '0.33rem' }}
          />
        </mui.core.Box>
      );
    }
    if (!_.isEmpty(job?.warnings)) {
      return (
        <>
          <mui.core.IconButton
            onClick={() => {
              setErrorModal(enums.JOB_ERROR_SEVERITY_ENUM.WARNING);
            }}
            size="small"
            color="warning"
          >
            <mui.icons.ErrorOutline style={{ fontSize: '14px' }} />
          </mui.core.IconButton>
        </>
      );
    }

    return '';
  };

  return (
    <mui.core.Box display="flex" alignItems="center">
      {job?.error ? (
        <JobBox hideBorder={hideBorder}>{getError()}</JobBox>
      ) : completedAt ? (
        <JobBox hideBorder={hideBorder}>
          <mui.core.Tooltip arrow title={`Duration: ${durationFormatted}`}>
            <mui.core.Typography fontWeight="fontWeightMedium" variant="overline" color="textSecondary">
              Last successful gen.
            </mui.core.Typography>
          </mui.core.Tooltip>

          <mui.core.Typography
            component="p"
            sx={{
              fontSize: '0.7rem',
              color: (theme.palette as any).secondary.main,
              fontWeight: '600',
              marginTop: '-2px',
            }}
          >
            {completedAt?.local()?.format?.('MM/DD/YYYY, hh:mm A')}

            {job?.warnings && getError()}
          </mui.core.Typography>
        </JobBox>
      ) : (
        <JobBox hideBorder={hideBorder}>
          <mui.core.Typography variant="overline" fontWeight="fontWeightMedium" color="textSecondary">
            {generateAlert?.title}
          </mui.core.Typography>
          <mui.core.Typography
            variant="caption"
            fontWeight="fontWeightBold"
            sx={{
              color: (theme.palette as any).secondary.main,
              marginTop: '-2px',
            }}
          >
            {generateAlert?.content}

            {getError()}
          </mui.core.Typography>
        </JobBox>
      )}

      {errorModal && (
        <ErrorModal
          handleClose={() => setErrorModal(null)}
          data={errorModal == enums.JOB_ERROR_SEVERITY_ENUM.ERROR ? [job.error] : job.warnings}
          severity={errorModal}
        />
      )}
    </mui.core.Box>
  );
};

JobStatus.defaultProps = {
  reportTitle: '',
  hideBorder: false,
};

export default JobStatus;
