import React from 'react';

import { mui } from '../libs';

type UserAvatarProps = {
  /** Username to display initials for */
  user: string;
  /** Size variant of the avatar */
  size?: 'default' | 'small';
};

/**
 * Component that displays a circular avatar with user initials
 * 
 * @param user - Username to display initials for
 * @param size - Size variant of the avatar ('default' or 'small')
 * @returns Rendered avatar component with user initials
 */
const UserAvatar: React.FC<UserAvatarProps> = ({ user, size }): React.ReactElement => {
  const theme = mui.styles.useTheme() as mui.core.Theme;

  const initials = user.charAt(0);
  return (
    <mui.core.Box
      sx={{
        minWidth: size == 'small' ? '1rem' : '2rem',
        maxWidth: size == 'small' ? '1rem' : '2rem',
        height: size == 'small' ? '1rem' : '2rem',
        background: (theme.palette as any).success.main,
        color: '#fff',
        borderRadius: '2rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontWeight: '600',
        fontSize: size == 'small' ? '0.5rem' : '0.9rem',
        textTransform: 'uppercase',
        '&:hover': {
          background: (theme.palette as any).success.dark,
          transition: 'all ease-in-out 0.15s',
          transform: 'translate(0, -1px)',
        },
      }}
    >
      {initials}
    </mui.core.Box>
  );
};

export default UserAvatar;
