import { BUCKET_TYPES_ENUM, EXPLANATORY_VARIABLE_TYPES_ENUM } from '../../enums';

/* eslint-disable no-unused-vars */
export enum FACTOR_TYPE_ENUM {
  RISK_MODEL = 'risk_model',
  RISK_MODEL_STYLE = 'risk_model_style',
  RISK_MODEL_ALL_STYLE = 'risk_model_all_style',
  RISK_MODEL_CATEGORY = 'risk_model_category',
}

export type RiskFactorsType = {
  id: string;
  type: FACTOR_TYPE_ENUM;
  value: string;
};

export type ExplanatoryVariablesValue = { type?: EXPLANATORY_VARIABLE_TYPES_ENUM; values?: string[] };

export type BucketValue = {
  type?: BUCKET_TYPES_ENUM;
  value?: string | number;
  risk_model_id?: number;
  signal_id?: number;
  pipeline_id?: number;
  sp_pipeline_id?: number;
};

export type BucketOptions = {
  value: string;
  title: string;
}[];

export enum DEBUG_FILES_INTERACTION_PARAMS {
  TEXT = 'text',
  IS_BASKET = 'isBasket',
}

export type DebugFilesParams = {
  [DEBUG_FILES_INTERACTION_PARAMS.TEXT]?: string;
  [DEBUG_FILES_INTERACTION_PARAMS.IS_BASKET]: boolean;
};

export type Pagination = { offset: number; limit: number; total?: number };

export enum FILTER_CONJUCTION_ENUM {
  AND = 'and',
  OR = 'or',
}
