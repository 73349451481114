import { _, ts } from '_core';

import { DraftParams } from './types';

export const validate = (params: DraftParams) => {
  const errors = [];
  if (_.isNil(params.window)) errors.push('Window is required field.');
  if (!params.weights) errors.push('Weights is a required field.');
  return errors;
};

export const getName = (params: DraftParams) => {
  const weightsTitle = {
    [ts.enums.REPORT_ENUMS.WEIGHTS_ENUM.MINIMUM_VOLATILITY]: 'Minimum Volatility',
    [ts.enums.REPORT_ENUMS.WEIGHTS_ENUM.MARKET_CAP]: 'Market Capitalization',
    [ts.enums.REPORT_ENUMS.WEIGHTS_ENUM.EQUAL]: 'Equal',
    [ts.enums.REPORT_ENUMS.WEIGHTS_ENUM.MARKET_NEUTRAL]: 'Market Neutral',
  };

  const label = 'Predicted and Realized Portfolio Risk vs. Time';
  const weightsLabel = weightsTitle[params.weights];
  if (weightsLabel) {
    return `${label} (${weightsLabel})`;
  }

  return label;
};
