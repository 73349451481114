import { helpers, ts } from '_core';

export const modelColumns = [
  {
    key: 'metric',
    name: 'Signal',
    formatter: helpers.tableFormatters.formatTo('string', { align: 'left' }),
    filter: 'string',
  },
  {
    key: 'pipeline',
    name: 'Pipeline',
    formatter: helpers.tableFormatters.formatTo('string', { align: 'left' }),
    filter: 'string',
  },
  {
    key: 'sp_pipeline',
    name: 'Portfolio Pipeline',
    formatter: helpers.tableFormatters.formatTo('string', { align: 'left' }),
    filter: 'string',
  },
  {
    key: 'long_exposure',
    name: 'Long Exposure',
    formatter: helpers.tableFormatters.formatTo('float', { align: 'right', roundDigits: 3 }),
    filter: 'number',
  },
  {
    key: 'short_exposure',
    name: 'Short Exposure',
    formatter: helpers.tableFormatters.formatTo('float', { align: 'right', roundDigits: 3 }),
    filter: 'number',
  },
  {
    key: 'net_exposure',
    name: 'Net Exposure',
    formatter: helpers.tableFormatters.formatTo('float', { align: 'right', roundDigits: 3 }),
    filter: 'number',
  },
] as ts.types.components.dataGrid.ColumnsData;

export const factorColumns = [
  {
    key: 'metric',
    name: 'Signal',
    formatter: helpers.tableFormatters.formatTo('string', { align: 'left' }),
    filter: 'string',
  },
  {
    key: 'long_exposure',
    name: 'Long Exposure',
    formatter: helpers.tableFormatters.formatTo('float', { align: 'right', roundDigits: 3 }),
    filter: 'number',
  },
  {
    key: 'short_exposure',
    name: 'Short Exposure',
    formatter: helpers.tableFormatters.formatTo('float', { align: 'right', roundDigits: 3 }),
    filter: 'number',
  },
  {
    key: 'net_exposure',
    name: 'Net Exposure',
    formatter: helpers.tableFormatters.formatTo('float', { align: 'right', roundDigits: 3 }),
    filter: 'number',
  },
] as ts.types.components.dataGrid.ColumnsData;
