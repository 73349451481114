import { config, hooks, mui, React, ui } from '_core';

import { AIAssistantContext } from '../../ai-assistant-context';

const EmptyChat = () => {
  const conversationContext = React.useContext(AIAssistantContext);
  const { starting, sendMessage } = conversationContext;
  const uiStyles = hooks.useUiStyles();

  if (starting) {
    return (
      <mui.core.Box
        display="flex"
        alignContent="center"
        justifyContent="center"
        flexDirection="column"
        height="100%"
        width="100%"
      >
        <ui.CenteredLoader />
      </mui.core.Box>
    );
  }

  const initialSuggestions = ['Open my latest universe', 'List the names of my most recent 5 signals'];

  return (
    <mui.core.Box sx={uiStyles.emptyChatContainer}>
      <mui.core.Box p={4} textAlign="center">
        <img
          src={config.customUrl + '/custom/finsera-logo.png'}
          alt="Finsera logo"
          style={{ height: 'auto', marginBottom: '50px', width: 50 }}
        />
        <mui.core.Typography variant="h5" textAlign="center" gutterBottom>
          Hello! How may I assist you today?
        </mui.core.Typography>

        <mui.core.Typography variant="body2" textAlign="center" gutterBottom color="textSecondary" my={2}>
          Welcome to {"Finsera's"} AI Assistant, start with one of these examples or type your question...
        </mui.core.Typography>
      </mui.core.Box>

      {initialSuggestions.map((init) => (
        <mui.core.Box key={init} display="flex" justifyContent="center" mb={3}>
          <mui.core.Button variant="outlined" onClick={() => sendMessage(init)}>
            {init}
          </mui.core.Button>
        </mui.core.Box>
      ))}
    </mui.core.Box>
  );
};

export default EmptyChat;
