import { hooks, mui, React, ts, ui } from '_core';

import { DraftParams } from './types';

type FormProps = {
  params: DraftParams;
  setParams: (_p: DraftParams) => void;
  context?: ts.types.signal.SignalAnalysisContext;
};

const Form: React.FC<FormProps> = ({ params, setParams, context }) => {
  const [returnHorizon, setReturnHorizon] = React.useState<ts.types.returnHorizon.ReturnHorizon>(params.return_horizon);
  const [lags, setLags] = React.useState(params.lags || 5);

  const [benchmark, setBenchmark] = React.useState(params.benchmark || null);
  const [withBenchmark, setWithBenchmark] = React.useState(params.with_benchmark || false);

  const uiStyles = hooks.useUiStyles();

  React.useEffect(() => {
    const data = {
      ...params,
      return_horizon: returnHorizon,
      with_benchmark: withBenchmark,
      lags,
    };
    if (withBenchmark) data.benchmark = benchmark;
    else if (data.benchmark) delete data.benchmark;

    setParams(data);
  }, [returnHorizon, lags, benchmark, withBenchmark]);

  return (
    <mui.core.Box sx={uiStyles.widgetEditMode}>
      <mui.core.Box mt={4}>
        <ui.WidgetReturnHorizon<false>
          value={returnHorizon}
          setValue={(val) => setReturnHorizon(val)}
          context={context}
        />
      </mui.core.Box>
      <mui.core.Box mt={4}>
        <ui.NumericField
          textFieldProps={{
            label: 'Number of lags (1 - 12)',
            fullWidth: true,
            variant: 'outlined',
            size: 'small',
            required: true,
          }}
          value={lags}
          setValue={(v) => setLags(v)}
        />
      </mui.core.Box>
      <mui.core.Box mt={3}>
        <mui.core.FormControlLabel
          control={
            <mui.core.Switch size="small" checked={withBenchmark} onChange={() => setWithBenchmark(!withBenchmark)} />
          }
          label="Has benchmark"
        />
      </mui.core.Box>

      {withBenchmark && (
        <mui.core.Box mt={2}>
          <ui.Benchmark
            value={benchmark || undefined}
            setValue={setBenchmark}
            universeContextPath={'$universe.handle'}
            enableAnnualizedSignals
            universeIdPath="universe_id"
          />
        </mui.core.Box>
      )}
    </mui.core.Box>
  );
};

export default Form;
