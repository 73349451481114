import { endpoint } from './config';
import { $ } from '../libs';

export const create = (payload: { data: any; dataHandle?: string }) => {
  $.ajax({
    method: 'POST',
    url: `${endpoint}loader/${payload.dataHandle}`,
    json: payload.data,
  } as JQueryAjaxSettings);
};
