import React from 'react';

import { _, mui } from '../../../libs';
import * as types from '../../../types';

type DeadbandType = { deadband: number };

type NumberWithDeadbandRuleProps = {
  operator: string;
  value?: any;
  deadband?: DeadbandType;
  setRule: (_o: string, _v: any, _d: DeadbandType) => void;
  config: types.components.queryBuilder.QbAttributes;
  readOnly?: boolean;
};

const NumberWithDeadbandRule: React.FC<NumberWithDeadbandRuleProps> = ({
  operator,
  value,
  deadband,
  setRule,
  config,
  readOnly,
}): React.ReactElement => {
  let multiplier = 1;
  if (config?.multiplier) multiplier = config.multiplier;
  const [numberValue, setNumberValue] = React.useState(value !== '' ? value / multiplier : '');
  const [deadbandValue, setDeadbandValue] = React.useState(
    deadband?.deadband ? _.round(deadband.deadband * 100, 2) : null
  );

  // eslint-disable-next-line no-nested-ternary
  const operatorValue = operator == '>' ? '>=' : operator == '<' ? '<=' : operator;
  return (
    <mui.core.Grid container spacing={2}>
      <mui.core.Grid item xs={2}>
        <mui.core.TextField
          value={operatorValue}
          onChange={(e) => setRule(e.target.value, value, deadband)}
          fullWidth
          variant="outlined"
          disabled={readOnly}
          select
          size="small"
          SelectProps={{
            displayEmpty: true,
          }}
        >
          <mui.core.MenuItem value="" disabled>
            Operator
          </mui.core.MenuItem>
          <mui.core.MenuItem value="<=">{'\u2264'}</mui.core.MenuItem>
          <mui.core.MenuItem value=">=">{'\u2265'}</mui.core.MenuItem>
        </mui.core.TextField>
      </mui.core.Grid>
      <mui.core.Grid item xs>
        <mui.core.TextField
          label={config.label}
          required
          type="number"
          variant="outlined"
          value={numberValue}
          disabled={readOnly}
          onChange={(e) => {
            setNumberValue(e.target.value);
            setRule(operator, e.target.value !== '' ? Number(e.target.value) * multiplier : '', deadband);
          }}
          size="small"
          fullWidth
        />
      </mui.core.Grid>
      <mui.core.Grid item xs>
        <mui.core.TextField
          label={config.deadbandLabel}
          type="number"
          variant="outlined"
          value={deadbandValue || ''}
          disabled={readOnly}
          onChange={(e) => {
            setDeadbandValue(Number(e.target.value) || null);
            setRule(operator, value, {
              deadband: e.target.value ? Number(e.target.value) / 100 : null,
            });
          }}
          size="small"
          fullWidth
        />
      </mui.core.Grid>
    </mui.core.Grid>
  );
};

NumberWithDeadbandRule.defaultProps = {
  value: null,
  deadband: { deadband: null },
  readOnly: false,
};

export default NumberWithDeadbandRule;
