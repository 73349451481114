import { config, hooks, mui, React, ts, ui } from '_core';

type OtherPreferencesProps = {
  save: (_val: Record<ts.enums.PREFERENCES_IN_USER_OBJ, any>) => Promise<void>;
  saveAndClose: (_val: Record<ts.enums.PREFERENCES_IN_USER_OBJ, any>) => Promise<void>;
  preferences: ts.types.preferences.PREFERENCES;
};

const OtherPreferences: React.FC<OtherPreferencesProps> = ({ save, saveAndClose, preferences }) => {
  const [loadingSave, setLoadingSave] = React.useState(false);
  const [loadingSaveAndClose, setLoadingSaveAndClose] = React.useState(false);
  const uiStyles = hooks.useUiStyles();
  const [localPreferences, setLocalPreferences] = React.useState({
    [ts.enums.PREFERENCES_IN_USER_OBJ.GENERATE_ON_OUTDATED]: preferences.generateOnOutdated,
    [ts.enums.PREFERENCES_IN_USER_OBJ.HELP_ICON_EDIT_MODE]: preferences.helpIconEditMode,
    [ts.enums.PREFERENCES_IN_USER_OBJ.HIDE_ARCHIVED_RESOURCES_WARNING]: preferences.hideArchivedResourcesWarning,
  } as Record<ts.enums.PREFERENCES_IN_USER_OBJ, any>);

  const handleSave = async () => {
    setLoadingSave(true);
    await save(localPreferences);
    setLoadingSave(false);
  };

  const handleSaveAndClose = async () => {
    setLoadingSaveAndClose(true);
    await saveAndClose(localPreferences);
    setLoadingSaveAndClose(false);
  };

  return (
    <mui.core.Box display="flex" height="100%" flexDirection="column">
      <mui.core.Box flex="1" sx={uiStyles.settingsContainer}>
        <mui.core.Typography variant="h6" color="textPrimary">
          Other Preferences
        </mui.core.Typography>

        <mui.core.Box p={4}>
          <mui.core.FormControlLabel
            control={
              <mui.core.Switch
                checked={localPreferences.generateOnOutdated}
                size="small"
                color="primary"
                onChange={() =>
                  setLocalPreferences({
                    ...localPreferences,
                    generateOnOutdated: !localPreferences.generateOnOutdated,
                  })
                }
              />
            }
            label="Automatically generate outdated reports"
          />
        </mui.core.Box>
        {config.features.enable_help_edit && (
          <mui.core.Box px={4}>
            <mui.core.FormControlLabel
              control={
                <mui.core.Switch
                  checked={localPreferences.helpIconEditMode}
                  size="small"
                  color="primary"
                  onChange={() =>
                    setLocalPreferences({
                      ...localPreferences,
                      helpIconEditMode: !localPreferences.helpIconEditMode,
                    })
                  }
                />
              }
              label="Toggle Help Icon Edit Mode"
            />
          </mui.core.Box>
        )}

        <mui.core.Box p={4}>
          <mui.core.FormControlLabel
            control={
              <mui.core.Switch
                checked={localPreferences.hideArchivedResourcesWarning}
                size="small"
                color="primary"
                onChange={() =>
                  setLocalPreferences({
                    ...localPreferences,
                    hideArchivedResourcesWarning: !localPreferences.hideArchivedResourcesWarning,
                  })
                }
              />
            }
            label="Hide archived resources warning on report generation"
          />
        </mui.core.Box>
      </mui.core.Box>

      <mui.core.Box sx={{ ...uiStyles.settingsFooter, marginBottom: '-0.9rem' }}>
        <mui.core.Box mr={2}>
          <ui.Button variant="contained" color="primary" onClick={handleSave} loading={loadingSave}>
            Save
          </ui.Button>
        </mui.core.Box>
        <ui.Button variant="outlined" color="primary" onClick={handleSaveAndClose} loading={loadingSaveAndClose}>
          Save and close
        </ui.Button>
      </mui.core.Box>
    </mui.core.Box>
  );
};

export default OtherPreferences;
