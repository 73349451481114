/* eslint-disable no-unused-vars */

import { ts } from '_core';

export enum INTERACTION_PARAMS {
  SELECTED_TYPE = 'selected_type',
}

export type InteractionParams = {
  [INTERACTION_PARAMS.SELECTED_TYPE]: 'basket' | 'benchmark';
};

export type JobParams = {
  benchmark?: Record<string, ts.enums.BENCHMARK_TYPES_ENUM | string | number>;
  basket_ids?: number[];
  format_for_ui: true;
};

export type Params = JobParams & InteractionParams;
export type DraftParams = Partial<Params>;
