import { api, mui, React, ts } from '_core';

import { PreferencesContext } from 'views/preferences/preferences-context';

import LayoutCopy from './copy';
import LayoutRename from './rename';

type LayoutActionsProps = {
  preferences: ts.types.userPreference.UserPreference[];
  setPreferences: (_v: ts.types.userPreference.UserPreference[]) => void;
  setSelectedPreference: (_v: ts.types.userPreference.UserPreference) => void;
  selectedPreference: ts.types.userPreference.UserPreference;
};

const LayoutActions: React.FC<LayoutActionsProps> = ({
  preferences,
  setPreferences,
  setSelectedPreference,
  selectedPreference,
}) => {
  const theme = mui.styles.useTheme() as mui.core.Theme;
  const preferencesContext = React.useContext(PreferencesContext);
  const { setAlertError } = preferencesContext;

  const [renameOpen, setRenameOpen] = React.useState(false);
  const [copyOpen, setCopyOpen] = React.useState(false);

  const [removing, setRemoving] = React.useState(false);

  const remove = async () => {
    try {
      setRemoving(true);
      await api.user_preferences.remove(selectedPreference.id);
      const newPreferences = preferences.filter((p) => p.id !== selectedPreference.id);
      setSelectedPreference(newPreferences[0]);
      setPreferences(newPreferences);
    } catch {
      setAlertError('Unable to load preferences.');
    }
    setRemoving(false);
  };

  return (
    <>
      <LayoutRename
        open={renameOpen}
        setOpen={setRenameOpen}
        preferences={preferences}
        setPreferences={setPreferences}
        selectedPreference={selectedPreference}
        setSelectedPreference={setSelectedPreference}
      />

      <LayoutCopy
        open={copyOpen}
        setOpen={setCopyOpen}
        preferences={preferences}
        setPreferences={setPreferences}
        setSelectedPreference={setSelectedPreference}
        selectedPreference={selectedPreference}
      />
      <mui.core.Box
        position="sticky"
        padding={3}
        sx={{
          zIndex: 100,
          background: theme.palette.background.default,
          borderBottom: `1px solid ${theme.palette.gray.main}`,
          borderTop: `1px solid ${theme.palette.gray.main}`,
        }}
        width="100%"
      >
        <mui.core.Stack gap={3} flexDirection="row" alignItems="center">
          <mui.core.Typography variant="body2" style={{ whiteSpace: 'nowrap', fontWeight: '600' }}>
            Select view:
          </mui.core.Typography>
          <mui.core.Box sx={{ width: '200px' }}>
            <mui.core.TextField
              select
              value={selectedPreference.id}
              disabled={removing}
              onChange={(e) => {
                setSelectedPreference(preferences.find((p) => p.id == Number(e.target.value)));
              }}
              variant="outlined"
              fullWidth
              size="small"
            >
              {preferences.map((pref, idx) => (
                <mui.core.MenuItem value={pref.id} key={idx}>
                  {pref.title}
                </mui.core.MenuItem>
              ))}
            </mui.core.TextField>
          </mui.core.Box>

          <mui.core.Button
            color="primary"
            variant="outlined"
            onClick={() => setCopyOpen(true)}
            disabled={removing}
            size="small"
          >
            Create Copy
          </mui.core.Button>
          <mui.core.Button
            color="primary"
            variant="outlined"
            onClick={() => setRenameOpen(true)}
            disabled={removing}
            size="small"
          >
            Rename
          </mui.core.Button>
          <mui.core.Button
            color="error"
            variant="outlined"
            disabled={preferences.length == 1 || removing}
            onClick={remove}
            size="small"
          >
            Delete
          </mui.core.Button>
        </mui.core.Stack>
      </mui.core.Box>
    </>
  );
};

export default LayoutActions;
