import React, { ChangeEvent, useRef, useState } from 'react';
import TextField, { TextFieldProps } from '@mui/material/TextField';

interface ResizableTextAreaProps extends Omit<TextFieldProps, 'multiline' | 'onChange' | 'value'> {
  // eslint-disable-next-line no-unused-vars
  onChange?: (value: string) => void;
  value?: string;
  variant?: 'standard' | 'filled' | 'outlined';
  isFocused?: boolean;
}

/**
 * Component that provides a resizable text area with controlled input behavior and focus management
 * 
 * @param onChange - Callback invoked when text content changes
 * @param value - Controlled input value
 * @param variant - MUI TextField variant style (standard/filled/outlined)
 * @param isFocused - Controls initial focus state of the textarea
 * @param otherProps - Additional TextFieldProps passed to underlying MUI component
 * 
 * @returns Multiline TextField component with dynamic height adjustment and focus control
 */
const ResizableTextArea: React.FC<ResizableTextAreaProps> = ({
  onChange,
  value,
  variant,
  isFocused,
  ...otherProps
}) => {
  const [text, setText] = useState(value || '');
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  React.useEffect(() => {
    if (isFocused) textareaRef.current.focus();
  });

  React.useEffect(() => {
    if (value !== text) setText(value || '');
  }, [value]);

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = event.target.value;
    setText(newValue);
    if (onChange) {
      onChange(newValue);
    }
  };

  return (
    <TextField
      multiline
      fullWidth
      rows={2} // We will just show one row to avoid performace issues until MUI fix the ref issues
      inputRef={textareaRef}
      value={text}
      onChange={handleChange}
      placeholder="Type something..."
      variant={variant}
      {...otherProps}
    />
  );
};

export default ResizableTextArea;
