import { _, mui, React, ts } from '_core';

type RiskModelCategoryProps = {
  id: string;
  value: string[];
  setValue: (_value: ts.types.widgets.common.RiskFactorsType[]) => void;
};

const RiskModelCategory: React.FC<RiskModelCategoryProps> = ({ value, setValue, id }): React.ReactElement => {
  const categories = ['industry', 'currency', 'country', 'market'];
  const disabledOptions = ['currency', 'country']; // These options are the ones that we have disabled on the definition

  const handleChange = (e: React.ChangeEvent<any>, changedValue: string[]) => {
    setValue(
      changedValue.map((val) => ({
        type: ts.types.widgets.common.FACTOR_TYPE_ENUM.RISK_MODEL_CATEGORY,
        value: val,
        id,
      }))
    );
  };

  return (
    <mui.core.Grid container spacing={4} alignItems="center">
      <mui.core.Grid item xs={12} sm={4}>
        <mui.core.Typography variant="h6">Category Factors</mui.core.Typography>
      </mui.core.Grid>

      <mui.core.Grid item xs={12} sm={8}>
        <mui.core.Box>
          <mui.core.Autocomplete
            options={categories}
            disableClearable
            multiple
            size="small"
            onChange={handleChange}
            getOptionLabel={(option) => _.startCase(option)}
            getOptionDisabled={(option) => disabledOptions.includes(option)}
            value={value}
            renderInput={(params) => <mui.core.TextField variant="outlined" {...params} fullWidth label="Category" />}
          />
        </mui.core.Box>
      </mui.core.Grid>
    </mui.core.Grid>
  );
};

export default RiskModelCategory;
