import React from 'react';

// Fields
import BoolRule from './bool-rule';
import Deprecated from './deprecated';
import MultiSelectRule from './multi-select-rule';
import NumberWithDeadbandRule from './number-with-deadband-rule';
import SignalRule from './signal-rule';
import { _, mui } from '../../../libs';
import * as types from '../../../types';

type RuleProps = {
  groupDef: any[];
  definition: any;
  setDefinition: (_v: any[]) => void;
  config: types.components.queryBuilder.QbConfig;
  remove?: () => void;
};

const Rule: React.FC<RuleProps> = ({ groupDef, definition, setDefinition, remove, config }): React.ReactElement => {
  // first element of array is the selected attribute
  const attribute = React.useMemo(() => {
    if (!definition[0]) return null;
    if (config.attributes.map((c) => c.key).includes(definition[0])) return definition[0];
    return 'signal';
  }, [config, definition[0]]);

  // second element of array is the selected attribute
  const operator = definition[1];
  // third element of array is the selected attribute
  const value = definition[2];
  // fourth element of array is the deadband (only number with deadband) {"deadband": 0}
  const deadband = definition[3];

  // attribute configuration
  const attributeConfig = config.attributes.find((attr) => attr.key === attribute);

  const { attributes } = config;

  const attributeOptions = React.useMemo(() => {
    // Filter by the rules that can't be used more than once in the same group
    return attributes.filter(
      (a) =>
        (a.type != 'multiselect' && a.type != 'bool') || attribute == a.key || !_.find(groupDef, (gd) => gd[0] == a.key)
    );
  }, [groupDef, attribute]);

  // change rule attribute and set the attribute config
  const changeAttribute = (e: any) => {
    const attrConf = config.attributes.find((attr) => attr.key === e.target.value);

    // default values
    if (attrConf.type === 'numberwithdeadband') setDefinition([e.target.value, '>=', '', { deadband: null }]);
    if (attrConf.type === 'multiselect') setDefinition([e.target.value, 'IN', []]);
    if (attrConf.type === 'bool') setDefinition([e.target.value, '==', true]);
    if (attrConf.type === 'signal') setDefinition([e.target.value, '>=', '', { deadband: null }]);

    if (attrConf.type === 'custom')
      setDefinition([e.target.value, attrConf.default?.operator || null, attrConf.default?.value || null]);
  };

  // set rule
  const setRule = (operator: string, val: any) => setDefinition([attribute || attributeConfig.key, operator, val]);

  // just for deadband cases
  const setDeadbandRule = (operator: string, val: number, deadband: { deadband: number }) =>
    setDefinition([attribute || attributeConfig.key, operator, val, deadband]);

  // just for signal
  const setSignalRule = (signal: string, operator: string, val: number, deadband: { deadband: number }) =>
    setDefinition([signal || attribute || attributeConfig.key, operator, val, deadband]);

  // render the component depending on the type
  const renderTypeFields = () => {
    if (attributeConfig.type === 'numberwithdeadband') {
      return (
        <NumberWithDeadbandRule
          operator={operator}
          value={value}
          deadband={deadband}
          setRule={setDeadbandRule}
          config={attributeConfig}
          readOnly={!!config.readOnly}
        />
      );
    }
    if (attributeConfig.type === 'signal') {
      return (
        <SignalRule
          operator={operator}
          value={value}
          deadband={deadband}
          setRule={setSignalRule}
          config={attributeConfig}
          readOnly={!!config.readOnly}
          signal={definition[0]}
        />
      );
    }
    if (attributeConfig.type === 'multiselect') {
      return (
        <MultiSelectRule
          operator={operator}
          value={value || []}
          setRule={setRule}
          config={attributeConfig}
          readOnly={!!config.readOnly}
        />
      );
    }
    if (attributeConfig.type === 'bool') {
      if (attributeConfig.fixed) {
        return <BoolRule operator={operator} value={value} setRule={setRule} readOnly={true} displayToggle={false} />;
      }
      return <BoolRule operator={operator} value={value} setRule={setRule} readOnly={!!config.readOnly} />;
    }
    if (attributeConfig.type == 'custom') {
      return attributeConfig.customField({
        operator: operator,
        value: value || attributeConfig.default || false,
        setRule: setRule,
        config: attributeConfig,
        readOnly: !!config.readOnly,
      });
    }
    if (attributeConfig.type == 'deprecated') {
      return <Deprecated value={value} config={attributeConfig} />;
    }
  };

  groupDef.forEach((md) => {
    if (_.isArray(md)) {
      const index = _.findIndex(attributes, (a) => a.key == md[0]);

      if (index != -1 && attributeOptions[index]?.onlyOneByGroup && attribute != attributeOptions[index]?.key)
        attributeOptions.splice(index, 1);
    }
  });

  return (
    <mui.core.Box display="flex" alignItems="center" style={{ margin: '0' }}>
      <mui.core.Box flexGrow={1}>
        <mui.core.Grid container spacing={2}>
          <mui.core.Grid item xs style={{ maxWidth: '170px', minWidth: '170px' }}>
            <mui.core.TextField
              value={attribute || ''}
              onChange={changeAttribute}
              disabled={(attributeConfig !== undefined && attributeConfig?.notDeletable) || config.readOnly}
              fullWidth
              variant="outlined"
              size="small"
              select
              SelectProps={{
                displayEmpty: true,
              }}
            >
              <mui.core.MenuItem value="" disabled>
                Select a rule
              </mui.core.MenuItem>
              {attributeOptions
                .filter((opt) => !(opt.hidden && attribute != opt.key))
                .map((option) => (
                  <mui.core.MenuItem key={option.key} value={option.key} disabled={option.disableAdd}>
                    {option.name}
                  </mui.core.MenuItem>
                ))}
            </mui.core.TextField>
          </mui.core.Grid>
          <mui.core.Grid item xs>
            {attributeConfig && renderTypeFields()}
          </mui.core.Grid>
        </mui.core.Grid>
      </mui.core.Box>
      <div style={{ width: '40px' }}>
        {!config.readOnly && !attributeConfig?.notDeletable && (
          <mui.core.Tooltip arrow title="Delete rule">
            <mui.core.IconButton size="small" onClick={remove} style={{ marginLeft: '6px' }}>
              <mui.icons.Delete />
            </mui.core.IconButton>
          </mui.core.Tooltip>
        )}
      </div>
    </mui.core.Box>
  );
};

Rule.defaultProps = {
  groupDef: [],
};

export default Rule;
