import { config, mui, React, ui } from '_core';

type EmbedHeaderProps = { title: string; fullScreen: boolean; subtitle: string };
const EmbedHeader = ({ title, fullScreen, subtitle }: EmbedHeaderProps) => {
  const theme = mui.styles.useTheme() as mui.core.Theme;
  return (
    <mui.core.Box p={2} display="flex">
      <mui.core.Box flex={1} display="flex" alignItems="center">
        {fullScreen ? (
          <h3 style={{ margin: 0, fontWeight: '500' }}>{title}</h3>
        ) : (
          <h5 style={{ margin: 0, fontWeight: '500' }}>{title}</h5>
        )}

        {subtitle && (
          <span
            style={{
              marginLeft: 5,
              fontSize: fullScreen ? '0.9rem' : '0.8rem',
              opacity: 0.8,
              color: theme.palette.text.secondary,
              margin: 'auto 10px',
            }}
          >
            {subtitle}
          </span>
        )}
      </mui.core.Box>
      <ui.Button
        sx={{
          flexDirection: 'row',
          alignItems: 'center',
        }}
        variant="light"
      >
        <img src={config.customUrl + '/custom/finsera-logo.png'} alt="Finsera logo" style={{ height: '12px' }} />
        <mui.core.Typography variant="body2" sx={{ fontWeight: '600', ml: 1 }}>
          Finsera
        </mui.core.Typography>
      </ui.Button>
    </mui.core.Box>
  );
};

export default EmbedHeader;
