import { _, helpers,ts } from '_core';

import { DraftParams, Params } from './types';
import { getBenchmarkTitle } from '../../common/utils/get-name';

export const validate = (params: DraftParams) => {
  const rhErrors = helpers.validations.validSelectedReturnHorizon(params?.return_horizon);
  if (!_.isEmpty(rhErrors)) return rhErrors;
  if (
    !params.analysis_pipelined_signals ||
    !Array.isArray(params.analysis_pipelined_signals) ||
    params.analysis_pipelined_signals.length === 0
  ) {
    return ['Analysis pipelined signals are required.'];
  }

  if (params.selected_type == 'benchmark') {
    if (!helpers.validations.validBenchmark(params.benchmark)) return ['Benchmark is invalid.'];
  } else {
    if (!params.analysis_pipelined_signals.some((signal) => signal.signal_id))
      return ['At least one signal is required.'];
  }

  return [];
};

export const getName = (
  params: Params,
  _context: ts.types.signal.SignalAnalysisContext,
  resources: ts.StoreState['resources']
) => {
  if (params.selected_type == 'benchmark') {
    return getBenchmarkTitle(params.benchmark, 'Relative Returns', resources.universes);
  }

  const signalDetails = params.analysis_pipelined_signals.map((signal) => {
    const selectedSignal = resources.signals.find((s) => s.id == signal.signal_id);
    const selectedPipeline = resources.pipelines.find((p) => p.id == signal.pipeline_id);
    const selectedSpPipeline = resources.pipelines.find((p) => p.id == signal.sp_pipeline_id);
    return {
      suffixName: selectedSignal?.name || signal.signal_label || '',
      suffixPipeline: signal.pipeline_id ? `(${selectedPipeline?.name ?? ''})` : '',
      suffixSpPipeline: signal.sp_pipeline_id ? `(${selectedSpPipeline?.name ?? ''})` : '',
    };
  });
  return signalDetails
    .map(
      ({ suffixName, suffixPipeline, suffixSpPipeline }) =>
        `Relative Returns Relative to ${suffixName} ${suffixPipeline} ${suffixSpPipeline}`
    )
    .join(', ');
};
