import { React, ts } from '_core';

import Manager from './manager';
import { ManagerContextProvider } from './manager-context';

type ManagerViewProps = {
  onSave?: () => void;
  onClose?: () => void;

  children?: React.ReactNode;

  updateDefinition: (
    _d: ts.types.analysis.WidgetExtended[],
    _m: ts.enums.ANALYSIS_MODE_ENUM,
    _r: boolean
  ) => Promise<void>;
  definition: ts.types.analysis.Widget[];
  definitionMode: ts.enums.ANALYSIS_MODE_ENUM;

  noDefault: boolean;
  onlyFullscreen?: boolean;
  hasAdhoc?: boolean;

  noLayoutChanges?: boolean;

  layoutType: ts.enums.LAYOUT_TYPE;

  context?: ts.types.common.ResourceDraft;
  tags: ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM[];
  errorMessage?: string;
  triggerOnWidgetAction?: (_v: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM) => void;
  isGeneralAnalysis?: boolean;
  isAdhoc?: boolean;
  disableWidgetTitleEdit?: boolean;
  maxWidgetsPerPage: number;

  reportHelp?: {
    document: ts.enums.UI_RESOURCE_TYPE_ENUM;
    contentPath?: `/${string}`;
  };
};

const ManagerView: React.FC<ManagerViewProps> = (props): React.ReactElement => {
  return (
    <ManagerContextProvider {...props}>
      <Manager />
    </ManagerContextProvider>
  );
};

export default ManagerView;
