import { ts } from '_core';

import { validate } from './helpers';
import { INTERACTION_PARAMS } from './types';

export default [
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.SIGNAL_PREVIEW_DATE_COMPARISON,
    form: true,
    title: 'Date Comparison',
    interactionParams: Object.values(INTERACTION_PARAMS),
    clearInteractionOnRegenerate: ['table_dtypes'],
    neededFiles: ['signal_date_comparison'],
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.SIGNAL_PREVIEW],

    validate,
    helpLabel: 'su12crd6',
  },
] satisfies ts.types.analysis.WidgetConfig[];
