import React from 'react';

import CheckboxItem from './checkbox-item';
import { useEffectWithoutFirst } from '../hooks';
import { _, mui } from '../libs';

interface ICheckboxesAutocompleteProps<T> {
  label: string;
  options: Record<string, T>[];
  setValue: (_v: T[]) => void;

  disabled?: boolean;
  chipKey?: string;
  objKey?: string;
  groupByKey?: string;
  chipColor?: string;
  required?: boolean;
  autocompleteProps?: Record<string, any>;
  value?: T[];
  optionLabel?: (_o: Record<string, T>) => string;
  error?: boolean;
}

/**
 * Component that provides a multi-select autocomplete interface with checkboxes
 *
 * @param label - Display label for the autocomplete
 * @param options - Array of objects representing selectable options
 * @param setValue - Callback to update selected values
 * @param disabled - Whether the control is disabled
 * @param chipKey - Key to use for displaying chip labels
 * @param objKey - Object key used for value comparison
 * @param groupByKey - Key to group options by
 * @param chipColor - Color variant for chips
 * @param required - Whether selection is required
 * @param autocompleteProps - Additional props for MUI Autocomplete
 * @param value - Currently selected values
 * @param optionLabel - Function to generate option labels
 * @param error - Whether to display error styling
 *
 * @returns A multi-select autocomplete component with checkbox functionality
 */

const CheckboxesAutocomplete = <T extends string | number | Record<string, any>>({
  value,
  setValue,
  disabled,
  options,
  autocompleteProps,
  label,
  optionLabel,
  objKey,
  groupByKey,
  chipKey,
  chipColor,
  required,
  error,
}: ICheckboxesAutocompleteProps<T>): React.ReactElement => {
  if (!chipKey) chipKey = objKey;

  const filteredOptions = React.useMemo(() => {
    return options?.filter((o) => value?.find((v) => v === o[objKey]));
  }, [options, value]);

  const [selected, setSelected] = React.useState(filteredOptions);

  const handleChange = (e: React.SyntheticEvent<Element, Event>, value: Record<string, T>[], reason: any) => {
    let currentValue = [...value];

    if (!!value?.find((v) => v[objKey] == 'select-all') && reason === 'selectOption') {
      currentValue = options?.filter((o) => o[objKey] != 'select-all').map((o) => o);
    }
    setSelected(currentValue);
  };

  useEffectWithoutFirst(() => {
    setValue(_.uniqBy(selected, objKey).map((o) => o[objKey]));
  }, [selected]);

  useEffectWithoutFirst(() => {
    if (!_.isEqual(filteredOptions, selected)) {
      setSelected(filteredOptions);
    }
  }, [filteredOptions]);

  return (
    <mui.core.Autocomplete<Record<string, T>, true>
      {...autocompleteProps}
      disabled={disabled}
      disableCloseOnSelect
      fullWidth
      multiple
      options={options}
      groupBy={(group) => (groupByKey ? (group[groupByKey] as string) : label)}
      getOptionLabel={(o) => optionLabel(o as Record<string, T>)}
      value={selected}
      onChange={(e, value, reason) => handleChange(e, value as Record<string, T>[], reason)}
      size="small"
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => (
          <mui.core.Chip
            key={index}
            label={option[chipKey] as string | number}
            size="small"
            {...getTagProps({ index })}
            style={{ backgroundColor: chipColor }}
          />
        ))
      }
      renderInput={(props) => (
        <mui.core.TextField
          {...props}
          label={label}
          required={required}
          variant="outlined"
          size="small"
          error={error}
        />
      )}
      renderOption={(props, option) => {
        const isSelected = !!selected?.find((s) => s[objKey] == option[objKey]);

        if (option?.[objKey] === 'select-all') {
          return (
            <li {...props}>
              <strong
                style={{
                  fontWeight: 500,
                  width: '100%',
                }}
              >
                Select All
              </strong>
            </li>
          );
        }

        return (
          <li {...props}>
            <CheckboxItem selected={isSelected} name={optionLabel(option)} />
          </li>
        );
      }}
    />
  );
};

CheckboxesAutocomplete.defaultProps = {
  autocompleteProps: {},
  disabled: false,
  optionLabel: () => '',
  value: [],
  objKey: 'value',
  chipKey: null,
  chipColor: '#f2f4fb',
  required: false,
};

export default CheckboxesAutocomplete;
