import React from 'react';

import HoldingsForm from './holdings-form';
import { UploadContextProvider as UploadContextProviderTypes } from './holdings-types';
import { UploadContextProvider } from './upload-context';
import { _, mui } from '../../libs';
import Button from '../button';

type UploadBacktestHoldingsProps = UploadContextProviderTypes & {
  disabled?: boolean;

  externalOpen?: boolean;
  setExternalOpen?: (_v: boolean) => void;
};

/**
 * Component that handles uploading initial holdings for backtests
 *
 * @param createHoldings - Function to create holdings when upload completes
 * @param defaultMappedColumns - Default column mappings for CSV
 * @param confirmStepCallback - Optional callback after confirmation step
 * @param disabled - Whether the upload button is disabled
 * @param externalOpen - External control of modal open state
 * @param setExternalOpen - External handler for setting modal open state
 *
 * @returns Upload button and modal for handling holdings uploads
 */
const UploadBacktestHoldings: React.FC<UploadBacktestHoldingsProps> = (props) => {
  const { disabled, externalOpen, setExternalOpen } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <>
      {_.isUndefined(externalOpen) && (
        <mui.core.Box display="flex" justifyContent={'start'}>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              setOpen(true);
            }}
            disabled={disabled}
          >
            Upload Initial Holdings
          </Button>
        </mui.core.Box>
      )}

      <UploadContextProvider open={externalOpen || open} setOpenModal={setExternalOpen || setOpen} {...props}>
        <HoldingsForm />
      </UploadContextProvider>
    </>
  );
};

UploadBacktestHoldings.defaultProps = {
  disabled: false,
};

export default UploadBacktestHoldings;
