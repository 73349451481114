import { React, ts } from '_core';

import Chart from './chart';
import Form from './form';
import { DraftParams, Params } from './types';

type SignalCoverageByRbicsProps = {
  id: string;
  readOnly: boolean;
  widgetKey: string;
  mode: ts.enums.CHART_MODE_ENUM;
  loadWidgetData: (_payload: ts.types.widgets.WidgetGetDataParams) => Promise<ts.types.widgets.WidgetGetDataResponse>;
  widgetPaths: Record<string, string>;
  fullScreen: boolean;
  params: DraftParams;
  setParams: (_p: DraftParams) => void;
  title: string;
  context?: ts.types.signal.SignalAnalysisContext;
};

const SignalCoverageByRbics: React.FC<SignalCoverageByRbicsProps> = ({
  id,
  readOnly,
  widgetKey,
  mode,
  loadWidgetData,
  widgetPaths,
  fullScreen,
  params,
  setParams,
  title,
  context,
}) => {
  const renderJobComponent = () => (
    <Chart
      id={id}
      readOnly={readOnly}
      widgetKey={widgetKey}
      loadWidgetData={loadWidgetData}
      file={widgetPaths['signal_industry_coverage']}
      fullScreen={fullScreen}
      params={params as Params}
      setParams={setParams}
      title={title}
      context={context}
    />
  );

  return <>{mode == 'edit' ? <Form params={params} setParams={setParams} /> : renderJobComponent()}</>;
};

export default SignalCoverageByRbics;
