import { helpers, mui, React, ts, ui,useHistory, useSelector } from '_core';

import * as utils from './portfolio-utils';

type PortfolioFormProps = {
  open: boolean;
  setOpen: (_open: boolean) => void;
};

const PortfolioForm: React.FC<PortfolioFormProps> = ({ open, setOpen }) => {
  const strategies = useSelector((state) => state.resources.strategies as ts.types.strategy.Strategy[]);
  const workspaces = useSelector((state) => state.resources.workspaces as ts.types.workspace.Workspace[]);
  const currentWorkspaceId = useSelector((state) => state.ui.currentWorkspaceId);

  const initialStrategy = React.useMemo(() => {
    const ws = workspaces.find((ws) => ws.id == currentWorkspaceId);
    const strategy = strategies.find((st) => st.id == ws.strategy_ids?.[0]);
    return strategy;
  }, []);

  const history = useHistory();

  const [errorMessage, setErrorMessage] = React.useState('');

  const [name, setName] = React.useState('');
  const [handle, setHandle] = React.useState('');
  const [strategy, setStrategy] = React.useState<ts.types.strategy.Strategy>(initialStrategy);
  const [loadingCreate, setLoadingCreate] = React.useState(false);

  // Create handle while typing name
  React.useEffect(() => {
    setHandle(helpers.createHandle(name, 'pt'));
  }, [name]);

  const handleSubmit = async () => {
    setErrorMessage('');

    // Validation
    if (!name || !handle) {
      setErrorMessage('All attributes are required.');
      setLoadingCreate(false);
      return;
    }

    setLoadingCreate(true);

    const newPortfolio = utils.definition.getNewPortfolio(name, handle, strategy);

    const ws = workspaces.find((ws) => ws.id == strategy.workspace_id);
    utils.apiProcedures.createPortfolio(
      newPortfolio,
      async (data) => {
        if (ws) await helpers.resourcesUtils.addToWorkspace(data.id, ts.enums.UI_RESOURCE_TYPE_ENUM.PORTFOLIO, ws);
        setOpen(false);
        history.push(`/portfolios/${data.id}`);
      },
      async (err) => {
        setErrorMessage(err);
        setLoadingCreate(false);
      }
    );
  };

  const formContent = () => (
    <mui.core.Box mt={3}>
      <mui.core.Box mb={4}>
        <mui.core.Grid container spacing={3}>
          <mui.core.Grid item xs={12} sm={6}>
            <mui.core.TextField
              autoFocus
              id="name"
              label={'Portfolio name'}
              value={name}
              onChange={(e) => setName(e.target.value)}
              fullWidth
              variant="outlined"
              required
              size="small"
              inputProps={{
                autocomplete: 'off',
              }}
            />
          </mui.core.Grid>
          <mui.core.Grid item xs={12} sm={6}>
            <mui.core.TextField
              id="handle"
              label={'Portfolio handle'}
              value={handle}
              onChange={(e) => setHandle(e.target.value)}
              fullWidth
              variant="outlined"
              required
              size="small"
            />
          </mui.core.Grid>
        </mui.core.Grid>
      </mui.core.Box>

      <mui.core.Box pb={4}>
        <ui.ResourceAutocomplete<ts.types.strategy.StrategyExpanded>
          value={strategy?.id}
          setValue={(id) => setStrategy(strategies.find((s) => s.id == id))}
          type={ts.enums.RESOURCES_TYPES_ENUM.STRATEGY}
          inputProps={{
            required: true,
            fullWidth: true,
            label: 'Add to strategy',
            variant: 'outlined',
            size: 'small',
          }}
        />
      </mui.core.Box>
    </mui.core.Box>
  );

  return (
    <mui.core.Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="sm"
      fullWidth
    >
      {errorMessage && (
        <mui.lab.Alert onClose={() => setErrorMessage('')} severity="error">
          {errorMessage}
        </mui.lab.Alert>
      )}
      <ui.DialogTitle closeAction={() => setOpen(false)}>Create Portfolio</ui.DialogTitle>

      <mui.core.DialogContent>{formContent()}</mui.core.DialogContent>

      <mui.core.DialogActions>
        <ui.Button
          variant="contained"
          color="primary"
          loading={loadingCreate}
          onClick={handleSubmit}
          style={{ margin: '0rem 1rem 1rem' }}
          fullWidth
        >
          Create Portfolio
        </ui.Button>
      </mui.core.DialogActions>
    </mui.core.Dialog>
  );
};

export default PortfolioForm;
