import React from 'react';

import EmptyMessage from './empty-message';
import ResourceEditableField from './resource-editable-field';
import ResourceHeader from './resource-header';
import * as enums from '../enums';
import { mui } from '../libs';
import * as types from '../types';


type SharedResourceCatcherProps = {
  resource: types.common.Resource;
  resourceName: string;
  resourceType: enums.UI_RESOURCE_TYPE_ENUM;
  children: React.ReactNode;
};

type SharedResourceCatcherState = {
  hasError: boolean;
};

/**
 * Component that catches errors when rendering shared resources and displays a message
 * if dependencies are not accessible
 * 
 * @param resource - The resource object containing information about the shared resource
 * @param resourceName - The name of the resource
 * @param resourceType - The type of the resource
 * @param children - The child components to render within this component
 * @returns Rendered component with error boundary for shared resources
 */
class SharedResourceCatcher extends React.Component<SharedResourceCatcherProps, SharedResourceCatcherState> {
  constructor(props: SharedResourceCatcherProps) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  componentDidCatch(err: any): void {
    if (this.props.resource?.shared) this.setState({ hasError: true });
    else throw err;
  }

  render() {
    if (this.state.hasError && this.props.resource?.shared) {
      return (
        <mui.core.Box height="100%" width="100%" sx={{ background: '#fff' }}>
          <ResourceHeader resource={this.props.resource} resourceType={this.props.resourceType}>
            <mui.core.Box display="flex" alignItems="center">
              <ResourceEditableField
                resource={this.props.resource}
                resourceType={null}
                selectedAttribute="name"
                updateResource={() => undefined}
                disabled
              >
                <mui.core.Typography variant="h5">{this.props.resource.name}</mui.core.Typography>
              </ResourceEditableField>
              <mui.core.Box pl={1}>
                <mui.core.Chip label={this.props.resource.handle} size="small" />
              </mui.core.Box>
            </mui.core.Box>
          </ResourceHeader>
          <mui.core.Box maxWidth="800px" sx={{ margin: '0 auto' }}>
            <EmptyMessage>
              <mui.core.Typography variant="h5" paragraph>
                This {this.props.resourceName} is read only.
              </mui.core.Typography>
              <mui.core.Typography>
                If the {this.props.resourceName} works on the owner account then it might reference a not
                published/shared resource. <br /> To view this resource,{' '}
                <strong>Please ask them to share the dependencies with you </strong> or to{' '}
                <strong>publish the resource</strong>.
              </mui.core.Typography>
            </EmptyMessage>
          </mui.core.Box>
        </mui.core.Box>
      );
    }

    return this.props.children;
  }
}

export default SharedResourceCatcher;
