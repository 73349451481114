import { _, helpers, ts } from '_core';

import { DraftParams, Params } from './types';

export const prepareExposureData = (
  data: ts.types.widgets.LinearData,
  context: ts.types.basket.BasketAnalysisContext,
  signalHandle: string,
  resources: ts.StoreState['resources'],
  basketId: number,
  basketIds: number[]
) => {
  const newData = {} as ts.types.widgets.LinearData;

  Object.keys(data).forEach((key) => {
    const dataKey = key.replace(`|signal|${signalHandle}`, '');

    let newKey = dataKey;
    if (dataKey == 'raw_signal') {
      newKey = resources.baskets.find((el) => el.id == context.id)?.name;
    } else {
      newKey = resources.baskets.find((el) => el.handle == dataKey)?.name;
    }

    newData[newKey] = data[key];
  });

  if (basketIds.includes(basketId)) {
    const responseData = {} as ts.types.widgets.LinearData;
    basketIds.forEach((id) => {
      const basket = resources.baskets.find((el) => el.id == id);
      responseData[basket.name] = newData[basket.name];
    });
    return responseData;
  }

  return newData;
};

export const validate = (params: DraftParams) => {
  const basketCompareErrors = helpers.validations.validBasketCompare(params);
  if (!_.isEmpty(basketCompareErrors)) return basketCompareErrors;
  if (!params.factors?.[0].value) return ['Signal is a required field.'];
  return [];
};

export const getName = (
  params: Params,
  _context: ts.types.basket.BasketAnalysisContext,
  resources: ts.StoreState['resources']
) => {
  const ts = resources.signals.find((s) => s.handle == params.factors?.[0]?.value);
  return `Signal Exposures vs. Time for ${ts.name}`;
};
