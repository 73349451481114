import { _, config, moment, mui, React, ts } from '_core';

import DataSourceBrowserFields from './data-source-browser-fields';
import DateField from './data-source-browser-fields/date-field';
import DateRangeField from './data-source-browser-fields/date-range-field';
import * as utils from '../data-source-browser-utils';
import { DraftParams } from '../types';

type FormProps = {
  params: DraftParams;
  setParams: (_v: DraftParams) => void;
  context: ts.types.dataSource.DataSourceBrowserContext;
};

const DataSourceForm: React.FC<FormProps> = ({ params, setParams, context }): React.ReactElement => {
  const [asOf, setAsOf] = React.useState(params.eff_ts);
  const [dateRange, setDateRange] = React.useState<ts.types.dataSource.DATE_VALUE_TYPE>({
    start: params.start_date,
    end: params.end_date,
  });
  const today = moment().format('YYYY-MM-DD');

  React.useEffect(() => {
    const newParams = {
      ...params,
      eff_ts: asOf,
    };

    if (dateRange.start && dateRange.end) {
      newParams.start_date = dateRange.start;
      newParams.end_date = dateRange.end;
    }
    setParams(newParams);
  }, [asOf, dateRange]);

  const browseItems = React.useMemo(() => {
    return utils.getBrowseItems(context);
  }, []);

  const filterField = (params: DraftParams, uiProcessing: boolean, fieldParent: string) => {
    const copyParams = { ...params };
    return uiProcessing
      ? utils.helpers.processingParentFields(copyParams, fieldParent)
      : (copyParams[fieldParent as keyof DraftParams] as Record<string, any>);
  };

  return (
    <mui.core.Grid container>
      <mui.core.Grid item md={6} xs={12}>
        {browseItems
          .filter((f) => f.type !== 'HIDDEN')
          .filter((f) => f.name !== 'start_and_end_dates')
          .map((fieldData) => {
            const fieldName = fieldData.name as keyof DraftParams;
            const fieldParent = fieldData.parent || 'filters';
            const uiProcessing = fieldData.ui_processing || false;

            const datasetValue = filterField(params, uiProcessing, fieldParent)?.[fieldName];

            const setValue = (v: any) => {
              const newParamsParent = filterField(params, uiProcessing, fieldParent) ?? ({} as DraftParams);

              if (!_.isEmpty(v)) newParamsParent[fieldName] = v;
              // If value is empty and the key existed, let's remove it from the object
              else if (newParamsParent[fieldName]) delete newParamsParent[fieldName];

              const parentField = utils.helpers.getParentField(uiProcessing, fieldParent);
              if (parentField) {
                if (uiProcessing) {
                  setParams({
                    ...params,
                    [parentField]: { ...newParamsParent },
                    [parentField.replace('_ui', '')]: Object.values(newParamsParent).flat(),
                  });
                } else {
                  setParams({
                    ...params,
                    [parentField]: { ...newParamsParent },
                  });
                }
              } else setParams({ ...newParamsParent });
            };

            return (
              <mui.core.Box key={fieldName} pt={4}>
                <DataSourceBrowserFields
                  label={fieldData.label}
                  type={fieldData.type}
                  value={datasetValue}
                  setValue={(v) => setValue(v)}
                  fieldData={fieldData}
                  date={moment().format('YYYY-MM-DD')}
                  minDate={config.features.start_date}
                  maxDate={config.features.end_date}
                />
              </mui.core.Box>
            );
          })}

        {
          // Add start and end dates
          browseItems.map((b) => b.name).includes('start_and_end_dates') && (
            <mui.core.Box pt={4}>
              <DateRangeField
                label="Range"
                value={dateRange}
                setValue={(v) => setDateRange(v)}
                minDate={moment(config.features.start_date).format()}
                maxDate={today}
              />
            </mui.core.Box>
          )
        }

        <mui.core.Box pt={4}>
          <DateField
            label="As Of"
            value={asOf}
            setValue={(v) => setAsOf(v)}
            minDate={moment(config.features.start_date).format()}
            maxDate={today}
          />
        </mui.core.Box>
      </mui.core.Grid>
    </mui.core.Grid>
  );
};

export default DataSourceForm;
