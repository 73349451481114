import { _, helpers, ts } from '_core';

import alphaExamples from '../alpha-defaults';
import { innerSubtypes } from '../alpha-defaults/alpha-subtypes';

export const getNewSignal = (
  name: string,
  handle: string,
  userProvided = false,
  userProvidedFrequency: ts.enums.FREQUENCY_ENUM = ts.enums.FREQUENCY_ENUM.DAILY,
  template: ts.types.signal.Signal = null
) => {
  // Add the type if different of custom
  let nullDefinition;
  if (userProvided) nullDefinition = { source: `loaded_signal('${handle}')` };
  else nullDefinition = { source: '' };

  // Check if we will create the signal with a premade definition
  const newSignal = {
    name,
    handle,
    definition: nullDefinition,
    is_valid: userProvided,
  } as ts.types.signal.SignalDraft;
  if (template && template.id) {
    newSignal.signal_ids = template.signal_ids;
    newSignal.definition = template.definition;
    newSignal.signature = template.signature;
    newSignal.errors = template.errors;
    newSignal.is_valid = template.is_valid;
    newSignal.label_ids = template.label_ids;
    newSignal.theme = template.theme;
  }

  return {
    ...newSignal,
    type: 'SIGNAL',
    user_provided: userProvided ? true : false,
    user_provided_frequency: userProvided ? userProvidedFrequency : undefined,
  } as ts.types.signal.Signal;
};

export const getNewAlphaModel = (
  name: string,
  handle: string,
  modelType: ts.types.signal.AlphaDefinition['alpha_type'],
  parentType: ts.enums.ALPHA_MODEL_PARENT_ENUM = ts.enums.ALPHA_MODEL_PARENT_ENUM.CUSTOM,
  template: ts.types.signal.Signal = null
) => {
  // Add the type if different of custom
  let nullDefinition: ts.types.signal.AlphaDefinitionDraft;

  const alphaType =
    modelType ||
    (innerSubtypes.find((st) => st.parentType == parentType).type as ts.types.signal.AlphaDefinition['alpha_type']);

  if (alphaType == 'custom') {
    nullDefinition = {
      source: '',
      json_definition: { source: '' },
      alpha_type: 'custom' as ts.types.signal.AlphaDefinition['alpha_type'],
    };
  } else {
    nullDefinition = {
      source: '',
      json_definition: alphaExamples[alphaType] as ts.types.signal.AlphaDefinitionDraft['json_definition'],
      alpha_type: alphaType,
    };
  }

  // Check if we will create the signal with a premade definition
  const newSignal = { name, handle, definition: nullDefinition } as ts.types.signal.SignalDraft;
  if (template && template.id) {
    newSignal.definition = template.definition;
    newSignal.signature = template.signature;
    newSignal.errors = template.errors;
    newSignal.is_valid = template.is_valid;
    newSignal.label_ids = template.label_ids;
  }

  return { ...newSignal, type: 'ALPHA_MODEL' } as ts.types.signal.Signal;
};

export const translateToCodeEditorShortcuts = (keys: string[]) => {
  const formattedKeyWin = _.map(_.map(keys, _.lowerCase), _.upperFirst).join('-');
  const formattedKeyMac = _.replace(formattedKeyWin, 'Ctrl', 'Cmd');

  return { win: formattedKeyWin, mac: formattedKeyMac };
};

export const getArchivedDeps = (
  signal: ts.types.signal.Signal,
  dataset: ts.types.signal.Dataset,
  resources: ts.StoreState['resources']
): ts.types.common.ArchivedDeps => {
  const datasetEls = [] as string[];

  if (!_.isEmpty(dataset)) {
    if (resources.universes.find((u) => u.id == dataset.universe_id)?.is_deprecated) {
      const uni = resources.universes.find((u) => u.id == dataset.universe_id);
      datasetEls.push(`${uni.name} (${uni.handle})`);
    }

    if (resources.risk_models.find((u) => u.id == dataset.risk_model_id)?.is_deprecated) {
      const rm = resources.risk_models.find((u) => u.id == dataset.risk_model_id);
      datasetEls.push(`${rm.name} (${rm.handle})`);
    }

    if (resources.pipelines.find((u) => u.id == dataset.pipeline_id)?.is_deprecated) {
      const pipe = resources.pipelines.find((u) => u.id == dataset.pipeline_id);
      datasetEls.push(`${pipe.name} (${pipe.handle})`);
    }

    if (resources.pipelines.find((u) => u.id == dataset.sp_pipeline_id)?.is_deprecated) {
      const pipe = resources.pipelines.find((u) => u.id == dataset.sp_pipeline_id);
      datasetEls.push(`${pipe.name} (${pipe.handle})`);
    }
  }

  const deps = [];
  if (!_.isEmpty(datasetEls))
    deps.push({
      label: 'On Dataset:',
      elements: datasetEls,
    });

  return deps.concat(
    helpers.resourceGraph.getArchiveDeps(
      helpers.resourceGraph.getDeps({ ...signal, resourceType: ts.enums.RESOURCES_TYPES_ENUM.SIGNAL }, resources)
    )
  );
};

export const getUnpublishedDeps = (
  signal: ts.types.signal.Signal,
  resources: ts.StoreState['resources']
): ts.types.common.ResourceDeps[] => {
  return helpers.resourceGraph
    .getDeps({ ...signal, resourceType: ts.enums.RESOURCES_TYPES_ENUM.SIGNAL }, resources)
    .filter((d) => !d.is_published);
};

export const getUnsharedDeps = (
  signal: ts.types.signal.Signal,
  datasets: ts.types.signal.Dataset[],
  resources: ts.StoreState['resources'],
  sharedWith: number[]
): ts.types.common.ResourceDeps[] => {
  if (!sharedWith) sharedWith = signal.shared_with || [];

  const signalDeps = helpers.resourceGraph.getDeps(
    { ...signal, resourceType: ts.enums.RESOURCES_TYPES_ENUM.SIGNAL },
    resources
  );

  datasets.forEach((ds) => {
    const uni = resources.universes.find((u) => u.id == ds.universe_id);
    if (uni) signalDeps.push({ resourceType: ts.enums.RESOURCES_TYPES_ENUM.UNIVERSE, ...uni });

    const rm = resources.risk_models.find((rm) => rm.id == ds.risk_model_id);
    if (rm) signalDeps.push({ resourceType: ts.enums.RESOURCES_TYPES_ENUM.RISK_MODEL, ...rm });

    const pipe = resources.pipelines.find((pipe) => pipe.id == ds.pipeline_id);
    if (pipe) signalDeps.push({ resourceType: ts.enums.RESOURCES_TYPES_ENUM.PIPELINE, ...pipe });

    const sppipe = resources.pipelines.find((sppipe) => sppipe.id == ds.sp_pipeline_id);
    if (sppipe) signalDeps.push({ resourceType: ts.enums.RESOURCES_TYPES_ENUM.PIPELINE, ...sppipe });
  });

  return _.uniqBy(
    signalDeps.filter((d) => !helpers.resourcesUtils.isShared(d, sharedWith)),
    (v) => [v.resourceType, v.handle].join()
  );
};
