import React from 'react';

import AutoComplete from './async-autocomplete';
import CustomAutoComplete from './custom-autocomplete';
import AsyncTable from './table';
import { mui } from '../../libs';
import * as types from '../../types';

/**
 * Component that provides autocomplete functionality with optional async loading and table view
 *
 * @param value - Current selected value(s)
 * @param setValue - Callback to update selected value(s)
 * @param createId - Function to generate unique ID for each option
 * @param createTitle - Function to generate display title for each option
 * @param label - Input label text
 * @param example - Example/placeholder text
 * @param limitTags - Maximum number of tags to show when multiple selection is enabled
 * @param variant - MUI input variant (outlined, filled, standard)
 * @param disabled - Whether the input is disabled
 * @param fullWidth - Whether the input should take full width
 * @param multiple - Whether multiple selection is enabled
 * @param options - Array of options to select from
 * @param optionRenderer - Custom renderer for option items
 * @param limit - Maximum number of options to show
 * @param query - Function to fetch options asynchronously
 * @param useAsync - Whether to use async loading of options
 * @param fields - Column definitions for table view
 * @param tableView - Whether to show options in table format
 * @param metaDataKey - Key for option metadata
 * @param disableCloseOnSelect - Whether to keep dropdown open after selection
 * @param customFilter - Custom filter component
 *
 * @returns Rendered autocomplete component with optional table view
 */
const Autocomplete = <
  TValue,
  TOptions extends types.components.autocomplete.AutoCompleteValue,
  Multiple extends boolean | undefined = undefined,
  UseOption extends boolean | undefined = undefined,
>(
  props: types.components.autocomplete.IAutoCompleteProps<TValue, TOptions, Multiple, UseOption>
): React.ReactElement => {
  const { useAsync = true } = props;

  const disabledTableView = props.tableView && props.disabled;

  return (
    <>
      {!disabledTableView && (
        <mui.core.Grid container width="auto">
          {props.customFilter && (
            <mui.core.Grid item md={3}>
              <props.customFilter />
            </mui.core.Grid>
          )}
          <mui.core.Grid item md={props.customFilter ? 9 : 12}>
            {useAsync ? (
              <AutoComplete<TValue, TOptions, Multiple, UseOption> {...(props as any)} />
            ) : (
              <CustomAutoComplete<TValue, TOptions, Multiple, UseOption> {...(props as any)} />
            )}
          </mui.core.Grid>
        </mui.core.Grid>
      )}

      {/* eslint-disable-next-line react/prop-types */}
      {props.tableView && <AsyncTable<TValue, TOptions, Multiple> {...(props as any)} />}
    </>
  );
};

export default Autocomplete;
