import { mui, React, ts, ui } from '_core';

import RelaxOrder from './relax-order';

type MaximumTurnoverProps = {
  params: ts.types.optimizer.MaximumTurnoverDraft;
  setParams: (_params: ts.types.optimizer.MaximumTurnoverDraft) => void;
  readOnly: boolean;
  validationDef: ts.types.common.ValidationErrors;
};

const MaximumTurnover: React.FC<MaximumTurnoverProps> = ({
  params,
  setParams,
  readOnly,
  validationDef,
}): React.ReactElement => {
  return (
    <mui.core.Box p={2}>
      <mui.core.Grid container spacing={4}>
        <mui.core.Grid item xs={12} md={4}>
          <ui.NumericField
            textFieldProps={{
              label: 'Maximum Turnover per Period',
              fullWidth: true,
              variant: 'outlined',
              size: 'small',
              required: true,
              error: validationDef?.fields?.includes('maximum_turnover_per_period'),
            }}
            disabled={readOnly}
            value={params.upper_bound}
            setValue={(v) => setParams({ ...params, upper_bound: v })}
          />
        </mui.core.Grid>

        <mui.core.Grid item xs={12} md={4}>
          <RelaxOrder params={params} setParams={setParams} disabled={readOnly} />
        </mui.core.Grid>
      </mui.core.Grid>
    </mui.core.Box>
  );
};

export default MaximumTurnover;
