import { ts } from '_core';

import { validate } from './helpers';
import { DraftParams, INTERACTION_PARAMS } from './types';

export default [
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.PERFORMANCE_ATTRIBUTION_CONTRIBUTORS,
    title: 'Top/Bottom Contributors',
    form: true,
    helpLabel: '6zm62w20',
    defaultParams: {
      n_performers: 20,
      histories: [
        ts.enums.HISTORY_ENUM_EXTENDED.MTD,
        ts.enums.HISTORY_ENUM_EXTENDED.ONE_MONTH,
        ts.enums.HISTORY_ENUM_EXTENDED.THREE_MONTHS,
        ts.enums.HISTORY_ENUM_EXTENDED.ONE,
        ts.enums.HISTORY_ENUM_EXTENDED.THREE,
        ts.enums.HISTORY_ENUM_EXTENDED.FIVE,
        ts.enums.HISTORY_ENUM_EXTENDED.FULL,
        ts.enums.HISTORY_ENUM_EXTENDED.YTD,
      ],
    } as DraftParams,
    interactionParams: Object.values(INTERACTION_PARAMS),
    clearInteractionOnRegenerate: ['table_dtypes'],
    neededFiles: ['performance_attribution_contributors'],
    tags: [
      ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.BACKTEST_PORTAN,
      ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.BASKET_ATTRIBUTION,
    ],

    excludeWrapper: true,
    validate,
  },
  {
    key: ts.enums.REPORT_ENUMS.WIDGET_KEY_ENUM.PORTFOLIO_PERFORMANCE_ATTRIBUTION_CONTRIBUTORS,
    title: 'Top/Bottom Contributors',
    form: true,
    helpLabel: '8kj65iwc',
    defaultParams: {
      n_performers: 20,
      histories: [
        ts.enums.HISTORY_ENUM_EXTENDED.MTD,
        ts.enums.HISTORY_ENUM_EXTENDED.ONE_MONTH,
        ts.enums.HISTORY_ENUM_EXTENDED.THREE_MONTHS,
        ts.enums.HISTORY_ENUM_EXTENDED.ONE,
        ts.enums.HISTORY_ENUM_EXTENDED.THREE,
        ts.enums.HISTORY_ENUM_EXTENDED.FIVE,
        ts.enums.HISTORY_ENUM_EXTENDED.FULL,
        ts.enums.HISTORY_ENUM_EXTENDED.YTD,
      ],
    } as DraftParams,
    interactionParams: Object.values(INTERACTION_PARAMS),
    clearInteractionOnRegenerate: ['table_dtypes'],
    neededFiles: ['portfolio_top_contributors'],
    tags: [ts.enums.REPORT_ENUMS.REPORT_TAG_ENUM.PORTFOLIO_ATTRIBUTION],

    excludeWrapper: true,
    validate,
  },
] satisfies ts.types.analysis.WidgetConfig[];
