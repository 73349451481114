import { _, hooks, mui, React, ts, ui, useSelector } from '_core';

import * as utils from '../../definition-utils';

type AddIndustryInclusionProps = {
  factor: ts.types.riskModel.ExtendedCategoryFactor;
  addInclusions: (_v: ts.types.riskModel.ExtendedCategoryFactorInclusions[]) => void;
};

const AddIndustryInclusion: React.FC<AddIndustryInclusionProps> = ({ factor, addInclusions }): React.ReactElement => {
  type IndData = ts.types.industries.IndustryData;
  const uiStyles = hooks.useUiStyles();

  const [openAddModal, setOpenAddModal] = React.useState(false);
  const rbicsData = useSelector((store) => store.resources.rbics_data);

  const [selSector, setSelSector] = React.useState<IndData>(null);
  const [selSubSector, setSelSubSector] = React.useState<IndData>(null);
  const [selIndustry, setSelIndustry] = React.useState<IndData>(null);
  const [selSubIndustry, setSelSubIndustry] = React.useState<IndData>(null);

  // Clear on changes
  React.useEffect(() => {
    setSelSubSector(null);
    setSelIndustry(null);
    setSelSubIndustry(null);
  }, [selSector]);
  React.useEffect(() => {
    setSelIndustry(null);
    setSelSubIndustry(null);
  }, [selSubSector]);
  React.useEffect(() => {
    setSelSubIndustry(null);
  }, [selIndustry]);

  const sectorOptions = React.useMemo(() => rbicsData.levelOne, []);

  const subSectorOptions = React.useMemo(() => {
    if (!selSector) return rbicsData.levelTwo;
    return rbicsData.levelTwo.filter((r) => r.value.startsWith(selSector.value));
  }, [selSector]);

  const industryOptions = React.useMemo(() => {
    if (!selSubSector) return rbicsData.levelThree;
    return rbicsData.levelThree.filter((r) => r.value.startsWith(selSubSector.value));
  }, [selSubSector]);

  const subIndustryOptions = React.useMemo(() => {
    if (!selIndustry) return rbicsData.levelFour;
    return rbicsData.levelFour.filter((r) => r.value.startsWith(selIndustry.value));
  }, [selIndustry]);

  const handleAdd = () => {
    const indString = (() => {
      if (selSubIndustry?.value) return selSubIndustry.value;
      if (selIndustry?.value) return selIndustry.value;
      if (selSubSector?.value) return selSubSector.value;
      return selSector.value;
    })();

    const rawInclusions = rbicsData.levelFour
      .filter((f) => f.value.startsWith(indString))
      .map((f) => ({
        table_handle: 'industry',
        item: 'rbics_l4_id',
        value: f.value,
      }));

    addInclusions(utils.renameIndustryInclusions(rawInclusions, factor.handle, rbicsData));
    setOpenAddModal(false);
    setSelSector(null);
  };

  return (
    <>
      <mui.core.Dialog open={openAddModal} maxWidth="xs" fullWidth onClose={() => setOpenAddModal(false)}>
        <ui.DialogTitle closeAction={() => setOpenAddModal(false)}>Add Sub Industry</ui.DialogTitle>

        <mui.core.DialogContent>
          <mui.core.Stack gap={4}>
            <ui.Alert severity="info" rounded>
              If deeper categories are not selected, then all the items from latest selection will be added
            </ui.Alert>
            <mui.core.Autocomplete
              options={sectorOptions}
              value={selSector}
              onChange={(_, val) => setSelSector(val as IndData)}
              getOptionLabel={(el) => (el as IndData).name}
              isOptionEqualToValue={(option, val) => option?.value == val?.value}
              renderInput={(params) => <mui.core.TextField {...params} label="Sector" />}
            />

            {selSector && (
              <mui.core.Autocomplete
                options={subSectorOptions}
                value={selSubSector}
                onChange={(_, val) => setSelSubSector(val as IndData)}
                getOptionLabel={(el) => (el as IndData).name}
                isOptionEqualToValue={(option, val) => option?.value == val?.value}
                renderInput={(params) => <mui.core.TextField {...params} label="Sub Sector" />}
              />
            )}

            {selSubSector && (
              <mui.core.Autocomplete
                options={industryOptions}
                value={selIndustry}
                onChange={(_, val) => setSelIndustry(val as IndData)}
                getOptionLabel={(el) => (el as IndData).name}
                isOptionEqualToValue={(option, val) => option?.value == val?.value}
                renderInput={(params) => <mui.core.TextField {...params} label="Industry" />}
              />
            )}

            {selIndustry && (
              <mui.core.Autocomplete
                options={subIndustryOptions}
                value={selSubIndustry}
                onChange={(_, val) => setSelSubIndustry(val as IndData)}
                getOptionLabel={(el) => (el as IndData).name}
                isOptionEqualToValue={(option, val) => option?.value == val?.value}
                renderInput={(params) => <mui.core.TextField {...params} label="Sub Industry" />}
              />
            )}

            <mui.core.Button disabled={_.isEmpty(selSector)} onClick={handleAdd} variant="contained">
              Add
            </mui.core.Button>
          </mui.core.Stack>
        </mui.core.DialogContent>
      </mui.core.Dialog>

      <mui.core.Tooltip title="Add Subindustry" arrow>
        <mui.core.IconButton sx={uiStyles.collapseHeaderButton} size="small" onClick={() => setOpenAddModal(true)}>
          <mui.icons.Add />
        </mui.core.IconButton>
      </mui.core.Tooltip>
    </>
  );
};

export default AddIndustryInclusion;
